import message from "antd/lib/message"
// import axios from "axios"
import Cookies from "js-cookie"
import { DataTableAction } from "../../../../component/table/action/data_table_action"
import { Tools, axios } from "../../../../config"
import { HotKeyLogic } from "../../../../logic/hotkey_quip_logic"
import { EquipmentLogic } from '../../../../logic/equip_logic'
class EquipAction extends DataTableAction {

    async delRow({id}) {
        const r = await new HotKeyLogic().delData(id);
        if (r.data.statusCode == 200) {
            message.success("删除成功")
        }else{
            message.error("删除失败")
        }
    }
    // async changeStatus(params){
    //     const r = await new HotKeyLogic().ChangeStatus(params)
    //     if (r.data.statusCode == 200) {
    //         message.success("终止成功")
    //     }else{
    //         message.error("终止失败")
    //     }
    // }
    delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {
       
    }
    async loadPage({ dispatch, pageIndex, pageSize, search,id }) {
        this.setLoading({dispatch,loading: true})
        const { SearchKey } = search;
        const params: any = {
            "pageIndex": pageIndex,
            "pageSize": 224,
            "seachKey":SearchKey,
            "orderBy":'',
            "sort":'',
            "barCodeScalesID":id
        }
        // 档案列表
        const r = await new HotKeyLogic().selList(params);
        if (r.data.statusCode == 200) {
            const dataList = r.data.data.dataSource || [];         
            dispatch({type:'DataList',payload: dataList})
            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: dataList, pageSize });
        }
        this.setLoading({dispatch,loading: false})

    }
    setCondition({ dispatch, key, value }) {
        const params = {
        }
            params[key] = value;
        dispatch({ type: "SetSearch", payload: params })
    }
  async getEquipment({dispatch}){
    const r = await new EquipmentLogic().getAll() 
    if(r.data.statusCode == 200){
        dispatch({ type: "Equipment", payload: r.data.data || []});
    }
   }
   async addSubmit({ params }) {
    // const {formData}  = state
    // const params ={
    //     KeyCode: formData.KeyCode,
    //     PluCode: formData.PluCode,
    //     BarCodeScalesID: formData.BarCodeScalesID
    // }
     const r =  await new HotKeyLogic().addOperation(params)
     if(r.data.statusCode == 200){
            message.success('添加成功')
            return true
     }else{
         message.error(r.data.msg)
         return false
     }
}

async updSubmit({ ID, PluCode }) {
    // const {formData}  = state
    // const params ={
    //     ID:id,
    //     PluCode: formData.PluCode,
    // }
     const r =  await new HotKeyLogic().updateOperation({ID,PluCode})
     if(r.data.statusCode == 200){
            message.success('修改成功')
            return true
     }else{
         message.error(r.data.msg)
         return false
     }
}

}
export const fileAction = new EquipAction();