import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ConfigApi, Tools } from "../../config";
import styles from "./style/index.module.scss";
import { Form } from "./view/form";
import form_bg from '../../assets/images/login/form.png'
import "./style/index.scss";

interface IInfo {
  style?;
}
export function Info({ style }: IInfo) {
  const [info, setInfo] = useState<any>({});

  useEffect(() => {
    (async function () {
      const r = await axios({
        method: "GET",
        url: Tools.getApiAddress("info_concat").replace(
          ConfigApi.serviceDomain,
          ConfigApi.operationDomain
        ),
      });

      setInfo(r.data.data);
    })();

    return () => {};
  }, []);

  return (
    <div className={styles.info_c} style={style}>
      <hr className={styles.line}></hr>
      <div className={styles.item}>
        <span>技术支持：智如客科技</span>
      </div>
      <div className={styles.item}>
        <span>当前版本：V1.1</span>
      </div>
      <div className={styles.item}>
        <span>服务电话：{info.landline}</span>
      </div>
      <div className={styles.item}>
        {/* <span>官网地址：<a target="_blank" href={info.SiteUrl}>{info.SiteUrl}</a></span> */}
        <a href="https://beian.miit.gov.cn/" target="_blank">
          备案号码：蜀ICP备19012303号-5
        </a>
      </div>
    </div>
  );
}

const Login = () => {
  const his = useHistory();
  return (
    <div className={styles.root_c}>
      <div className={styles.form_c}>
        <div className={styles.form}>
          <div className={styles.form_bg}>
            <img src={form_bg}/>
          </div>
          <div className={styles.logo_c}>
            <div className={styles.logo}></div>
          </div>

          <div className={styles.title_c}>
            <span>用户登录</span>
          </div>

          <Form />

          <Info />
        </div>
      </div>
    </div>
  );
};
export default Login;
