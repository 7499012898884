import { useBoolean, useRequest } from "ahooks";
import { Alert, Button, Checkbox, Input, message, Modal, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect, useState } from "react";
import { ButtonPanel } from "../../../../../component/button-panel/button_panel";
import { Mask } from "../../../../../component/mask";
import { Search } from "../../../../../component/search";
import { DataTable } from "../../../../../component/table";
import { DataTableStore } from "../../../../../component/table/store/data_table_store";
import { TipsGroup, Tips } from "../../../../../component/tips";
import { AuthLogic } from "../../../../../logic/auth_logic";
import { defualtPageSize, Progress } from "../../../../../logic/common_logic";

import img_edit from "../../../../assets/images/bianji.png";
import img_see from "../../../../assets/images/chakan1.png";
import img_del from "../../../../assets/images/shanchu.png";
import { fileRunwaterAction } from "../../../action/file/file_runwater_action";
import { FileRunwaterStore } from "../../../store/file/file_runwater_store";
import { FileRunwaterSearch } from "./runwater_search";

let editId = "";
let seeId = "";

export function FileRunwater(viewPower) {
  const [reportState, reportFunc] = useBoolean(false);
  const [searchState, searchFunc] = useBoolean(false);

  const { state, dispatch } = useContext(FileRunwaterStore);
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);

  const action = fileRunwaterAction;

  const [menuButton, setData] = useState([]);
  useEffect(() => {
    (async function () {
      let r = await new AuthLogic().selButton(viewPower.viewPower);
      if (!r) return;
      if (r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          r[i] = r[i].split("_")[r[i].split("_").length - 1];
        }
      }
      setData(r);
    })();

    return () => {};
  }, []);

  const columns: ColumnsType = [
    {
      title: "门店名称",
      dataIndex: "shopName",
      key: "1",
      align: "center",
      width: 100,
    },
    {
      title: "批次号",
      dataIndex: "storageBatchNumber",
      key: "0",
      align: "center",
      width: 190
    },
    {
      title: "商品编码",
      dataIndex: "goodsNo",
      key: "2",
      align: "center",
      width: 120
    },
    {
      title: "商品名称",
      dataIndex: "goodsName",
      key: "3",
      align: "center",
      width: 120
    },
    {
      title: "商品分类",
      dataIndex: "goodsClassName",
      key: "4",
      align: "center",
      width: 100
    },
    {
      title: "库存单位",
      dataIndex: "unitName",
      key: "5",
      align: "center",
      render: (v) => <Tag>{v}</Tag>,
      width: 80,
    },
    {
      title: "类型",
      dataIndex: "typeName",
      key: "6",
      align: "center",
      width: 80,
    },
    {
      title: "单号",
      dataIndex: "oddNumbers",
      key: "7",
      align: "center",
      width: 180,
    },
    {
      title: "商品单价",
      dataIndex: "putPrice",
      key: "8",
      align: "center",
      width: 100,
    },
    {
      title: "操作数量",
      dataIndex: "putNumber",
      key: "9",
      align: "center",
      width: 100,
    },
    {
      title: "添加时间",
      dataIndex: "addDateTime",
      key: "11",
      align: "center",
      width: 140,
    },
  ];

  const onSearch = async (pageIndex) => {
    await action.loadPage({
      pageIndex: pageIndex,
      pageSize: defualtPageSize(),
      dispatch: tableDispatch,
      search: state.search,
    });
  };

  useRequest(() => action.init({ dispatch }));

  return (
    <>
      <Search>
        <Input
          maxLength={20}
          allowClear
          placeholder="请输入商品编码，名称"
          onChange={(e) =>
            action.setCondition({
              dispatch,
              key: "keywords",
              value: e.target.value,
            })
          }
        />
        <input type="button" value="快速查找" onClick={() => onSearch(1)} />

        <TipsGroup>
          <input
            type="button"
            value="高级查找"
            onClick={() => searchFunc.toggle()}
          />
          <Tips width="536px" display={searchState}>
            <FileRunwaterSearch
              onSearch={(...arg: [any]) => {
                onSearch(...arg);
                searchFunc.setFalse();
              }}
            />
          </Tips>
          <Mask visible={searchState} onClick={() => searchFunc.setFalse()} />
        </TipsGroup>
      </Search>

      <ButtonPanel>
        {menuButton.indexOf("EXPORT") != -1 && (
          <Button
            onClick={async () => {
              await action.exportList({ search: state.search });
            }}
          >
            导出
          </Button>
        )}
      </ButtonPanel>

      <DataTable
        columns={columns}
        action={action}
        onLoad={async () => {
          await onSearch(1);
          Progress.done();
        }}
        onChange={(pageIndex) => {
          console.log(pageIndex);
          onSearch(pageIndex);
        }}
        scroll={{ scrollToFirstRowOnChange: true, autoY: true }}
        selectColumn={false}
        loading={tableState.loading}
      />
    </>
  );
}
