import { message } from "antd";
import { DataTableAction } from "../../../../component/table/action/data_table_action";
import { defualtPageSize } from "../../../../logic/common_logic";
import { GoodsLogic } from "../../../../logic/goods_logic";
import { InventoryLogic } from "../../../../logic/inventory_logic";
import { ShopLogic } from "../../../../logic/shop_logic";
import { UnitLogic } from "../../../../logic/unit_logic";
import { UserLogic } from "../../../../logic/user_logic";
import { CouponLogic } from "../../../../logic/coupon_logic";
import { ClassesLogic } from "../../../../logic/classes_logic";
import {initialArgs} from '../../store/coupon/form_store'
import Cookies from 'js-cookie'
type Init = {
    state;
    dispatch;
    form;
    id: string;
    method: string;
}
interface InitBasic {
    dispatch;
    search;
}
interface InitEdit {
    state;
    dispatch;
    form;
    id;
}
interface ISetValue {
    attribute: any;
    value: string | number | any[];
    dispatch;
}

class CouponFormAction {
    setValue(arg0: { dispatch: ({ type }: { type: any; payload: any; }) => void; value: any; attribute: string; }) {
        throw new Error("Method not implemented.");
    }

    private shopLgc = new ShopLogic();

    async init({ state, dispatch, id, method, form }: Init) {

        await this.initBasic({ dispatch, search: state.search });
        if ("Create" !== method && id) {
            await this.initEdit({ dispatch, state, id, form })
        }
    }
    private async initBasic({ search, dispatch }: InitBasic) {
        this.clearTableList({ dispatch });
    //     const list = await (await new ShopLogic().selListpost()).data.data.dataSource || []
    //     dispatch({type: 'LoadShop',payload: list})
    //     this.clearTableList({ dispatch });
    //    let defaultShop = list.filter(item=> item.isdefault == true)
    //    if(defaultShop.length>0){
    //        let shopinfo = defaultShop[0]
    //     this.setFormData({ dispatch, key: "shopID", value: shopinfo.id });
    //     this.setFormData({
    //       dispatch,
    //       key: "shopName",
    //       value: shopinfo.shopName,
    //     });
    //    }
    let { shopID, shopName } = JSON.parse(Cookies.get('loginMsg'))
       this.setFormData({ dispatch, key: "shopID", value: [] });
       this.setFormData({
         dispatch,
         key: "shopName",
         value: '',
       });

        // // 用户信息
        // {
        //     const r = await new UserLogic().selData();

        //     this.setFormData({ dispatch, key: "userName", value: r.userName })
        // }
        // // 商品搜索
        // {
        //     const { keywords, classesId, statusId, typeId, brandId } = search;

        //     const params = {
        //         pageIndex: 1, pageSize: defualtPageSize(), goodsBrandID: brandId, goodsClassesID: classesId, goodsType: typeId, status: statusId, goodsNameOrNo: keywords
        //     }
        //     if (undefined == keywords) {
        //         delete params.goodsNameOrNo;
        //     }
        //     if (undefined == brandId) {
        //         delete params.goodsBrandID;
        //     }
        //     if (undefined == classesId) {
        //         delete params.goodsClassesID;
        //     }
        //     if (undefined == typeId) {
        //         delete params.goodsType;
        //     }
        //     if (undefined == statusId) {
        //         delete params.status;
        //     }

        //     const r = await new GoodsLogic().selList(params);

        //     if (r.data.statusCode == 200) {
        //         const dataList = r.data.data.dataSource;

        //         const list = dataList.map((goods_v, i) => {
        //             // let clsItem = classifyList.filter(v => v.id == goods_v.goodsClassesID);
        //             // let uniItem = unitList.filter(v => v.id == goods_v.goodsUnitID);

        //             return {
        //                 ...goods_v,
        //                 id: goods_v.id,
        //                 value: goods_v.goodsName,
        //                 // goodsClassify: clsItem.length && clsItem[0].classesName,
        //                 // unit: uniItem.length && uniItem[0].goodsUnitName,
        //                 key: i + 1
        //             }
        //         })
        //         dispatch({ type: "SetSearchList", payload: list });

        //     }
        // }


    }
    private async initEdit({ dispatch, id, form }: InitEdit) {
        // 回填状态
        // 店铺列表
        const item = await new CouponLogic().selDataByWarehousing(id);
       if(!item){
        return false
       }

          //商品回填
          item['tableList'] = []
        if (item.range == 3) {
            let list = item.ranges || []
            list.length > 0 && list.forEach((v, i) => (v.serial = i));
            item['tableList'] = list
        }
        item['classifyList'] = []
        if (item.range == 2) {
            let list = item.ranges || []
            list.length > 0 && list.forEach((v, i) => (v.serial = i));
            item['classifyList'] = list
        }
        // 店铺
        if (item.shops && item.shops.length > 0) {           
            let shopname = item.shops.map(shop=>shop.shopName) || []
            item['shopName'] =  shopname.toString()
            item['shopID'] = item.shops.map(shop=>shop.shopID) || []
        }
        if(item.validType == 2){
            item['validEndDate']=''
            item['validStartDate']=''
        }
        dispatch({ type: "SetForm", payload: item });
    }
    async addSubmit({ state, status }) {
        if (state.formData.couponName == undefined) {
            message.error("请输入优惠券名称");
            return false;
        }

        if (state.formData.couponType == 2) {
            if (state.formData.subMoney == undefined || state.formData.subMoney == null) {
                message.error("请输入减免金额");
                return false;
            }else if(state.formData.subMoney <= 0){
                message.error("减免金额应该大于0");
                return false; 
            }

        }
        if (state.formData.couponType == undefined || state.formData.couponType == 1) {
            if (state.formData.discount == undefined || state.formData.discount == '' || !(/^[1-9]\d*$/).test(state.formData.discount) || state.formData.discount>99) {
                message.error("折扣请输入1~99的整数");
                return false;
            }
        }
        if (state.formData.getCouponType != 2) {
            // if (state.formData.couponNumber == undefined || state.formData.couponNumber == '') {
            //     message.error("请输入优惠券数量");
            //     return false;
            // }
            // if(!(/^[+]{0,1}(\d+)$/).test(state.formData.couponNumber)){
            //     message.error("优惠券数量应该是正整数");
            //     return false;
            // }

        }
        if (state.formData.validType == undefined || state.formData.validType == 1) {
            if (state.formData.validStartDate == undefined || state.formData.validStartDate == '' || state.formData.validEndDate == undefined || state.formData.validEndDate == '') {
                message.error("请选择有效期");
                return false;
            }

        }
        if (state.formData.validType == 2) {
            if (state.formData.validDay == undefined || state.formData.validDay == '') {
                message.error("请输入有效期");
                return false;
            }
            if(!(/^[1-9]\d*$/).test(state.formData.validDay)){
                message.error("有效时间应为大于0的整数");
                return false;
            }

        }
        if (state.formData.shopID == undefined || state.formData.shopID == '' || state.formData.shopID.length == 0) {
            message.error("请选择可用门店");
            return false;
        }

        if (state.formData.meetMoney == undefined || state.formData.meetMoney == '') {
            message.error("请输入指定金额");
            return false;
        }
        if (state.formData.meetMoney <=0) {
            message.error("指定金额应该大于0");
            return false;
        }
        if(state.formData.subMoney >= state.formData.meetMoney ){
            message.error("减免金额应该小于条件金额");
            return false;
        }

        let param = {}
        let activityRanges = []
        if (state.formData.range == 2) {
            if (state.classifyList==undefined||state.classifyList.length == 0) {
                message.error("请选择商品分类");
                return false;
            }
            for (let i = 0; i < state.classifyList.length; i++) {
                let obj = {}
                obj['targetID'] = state.classifyList[i].classCode
                obj['targetType'] = 1
                activityRanges.push(obj)
            }
        }
        if (state.formData.range == 3) {
            if (state.tableList==undefined||state.tableList.length == 0) {
                message.error("请选择商品");
                return false;
            }
            for (let i = 0; i < state.tableList.length; i++) {
                let obj = {}
                obj['targetID'] = state.tableList[i].barCode
                obj['targetType'] = 2
                activityRanges.push(obj)
            }
        }
        param['shopID'] = state.formData.shopID
        param['couponName'] = state.formData.couponName
        param['couponDetail'] = state.formData.couponDetail == undefined ? '' : state.formData.couponDetail
        param['couponType'] = state.formData.couponType == undefined ? 1 : state.formData.couponType
        param['couponNumber'] = state.formData.getCouponType == 2 ? '' : state.formData.couponNumber
        param['getCouponType'] = state.formData.getCouponType == undefined ? 1 : state.formData.getCouponType
        param['discount'] = state.formData.couponType != 2 ? state.formData.discount : ''
        param['subMoney'] = state.formData.couponType == 2 ? state.formData.subMoney : ''
        param['validType'] = state.formData.validType == undefined ? 1 : state.formData.validType
        param['validStartDate'] = state.formData.validStartDate == undefined || state.formData.validType == 2 ? '' : state.formData.validStartDate
        param['validEndDate'] = state.formData.validEndDate == undefined || state.formData.validType == 2 ? '' : state.formData.validEndDate
        param['validDay'] = state.formData.validType == undefined || state.formData.validType == 1 ? '' : state.formData.validDay
        param['meetMoney'] = state.formData.meetMoney
        param['useTargetType'] = state.formData.useTargetType == undefined ? 1 : state.formData.useTargetType
        param['useTargetType'] = state.formData.useTargetType == undefined ? 1 : state.formData.useTargetType
        param['isOverlay'] = state.formData.isOverlay == undefined ? 0 : state.formData.isOverlay
        param['couponUserType'] = ''
        param['range'] = state.formData.range == undefined ? 1 : state.formData.range
        param['ranges'] = activityRanges
        const r = await new CouponLogic().addOperation(param);
        if (r.data.statusCode == 200) {
            message.success("保存成功");
            return true;
        } else {
            message.error(r.data.message);
            return false;
        }

    }
    async updSubmit({ state, status }, id) {
        if (state.formData.couponName == undefined) {
            message.error("请输入优惠券名称");
            return false;
        }
        //console.log(state)
        if (state.formData.couponType == 2) {
            if (state.formData.subMoney == undefined || state.formData.subMoney == '' ) {
                message.error("请输入减免金额");
                return false;
            }else if(state.formData.subMoney <= 0){
                message.error("减免金额应该大于0");
                return false; 
            }

        }
        if (state.formData.couponType == undefined || state.formData.couponType == 1) {
            if (state.formData.discount == undefined || state.formData.discount == '' || !(/^[1-9]\d*$/).test(state.formData.discount) || state.formData.discount>99) {
                message.error("折扣请输入1~99的整数");
                return false;
            }        

        }
        if (state.formData.getCouponType != 2) {
            // if (state.formData.couponNumber == undefined || state.formData.couponNumber == '') {
            //     message.error("请输入优惠券数量");
            //     return false;
            // }
            // if(!(/^[+]{1}(\d+)$/).test(state.formData.couponNumber)){
            //     message.error("优惠券数量应该是正整数");
            //     return false;
            // }

        }
        if (state.formData.validType == undefined || state.formData.validType == 1) {
            if (state.formData.validStartDate == undefined || state.formData.validStartDate == '' || state.formData.validEndDate == undefined || state.formData.validEndDate == '') {
                message.error("请选择有效期");
                return false;
            }

        }
        if (state.formData.validType == 2) {
            if (state.formData.validDay == undefined || state.formData.validDay == '') {
                message.error("请输入有效期");
                return false;
            }
            if(!(/^[1-9]\d*$/).test(state.formData.validDay)){
                message.error("有效时间应为大于0的整数");
                return false;
            }
        }
        if (state.formData.shopID == undefined || state.formData.shopID == '') {
            message.error("请选择可用门店");
            return false;
        }

        if (state.formData.meetMoney == undefined || state.formData.meetMoney == '' || state.formData.meetMoney <= 0) {
            message.error("请输入指定金额");
            return false;
        }
        if(state.formData.subMoney >= state.formData.meetMoney ){
            message.error("减免金额应该小于条件金额");
            return false;
        }


        let param = {}
        let activityRanges = []
        if (state.formData.range == 2) {
            if (state.classifyList==undefined||state.classifyList.length == 0) {
                message.error("请选择商品分类");
                return false;
            }
            for (let i = 0; i < state.classifyList.length; i++) {
                let obj = {}
                obj['targetID'] = state.classifyList[i].classCode
                obj['targetType'] = 1
                activityRanges.push(obj)
            }
        }
        // //console.log(state.formData)
        // return false
        if (state.formData.range == 3) {
            if (state.tableList==undefined||state.tableList.length == 0) {
                message.error("请选择商品");
                return false;
            }
            for (let i = 0; i < state.tableList.length; i++) {
                let obj = {}
                obj['targetID'] = state.tableList[i].barCode
                obj['targetType'] = 2
                activityRanges.push(obj)
            }
        }
        param['shopID'] = state.formData.shopID
        param['couponName'] = state.formData.couponName
        param['couponDetail'] = state.formData.couponDetail == undefined ? '' : state.formData.couponDetail
        param['couponType'] = state.formData.couponType == undefined ? 1 : state.formData.couponType
        param['couponNumber'] = state.formData.getCouponType == 2 ? '' : state.formData.couponNumber
        param['getCouponType'] = state.formData.getCouponType == undefined ? 1 : state.formData.getCouponType
        param['discount'] = state.formData.couponType != 2 ? state.formData.discount : ''
        param['subMoney'] = state.formData.couponType == 2 ? state.formData.subMoney : ''
        param['validType'] = state.formData.validType == undefined ? 1 : state.formData.validType
        param['validStartDate'] = state.formData.validStartDate == undefined ? '' : state.formData.validStartDate
        param['validEndDate'] = state.formData.validEndDate == undefined ? '' : state.formData.validEndDate
        param['validDay'] = state.formData.validType == undefined || state.formData.validType == 1 ? '':state.formData.validDay
        param['meetMoney'] = state.formData.meetMoney
        param['useTargetType'] = state.formData.useTargetType == undefined ? 1 : state.formData.useTargetType
        param['useTargetType'] = state.formData.useTargetType == undefined ? 1 : state.formData.useTargetType
        param['isOverlay'] = state.formData.isOverlay == undefined ? 0 : state.formData.isOverlay
        param['couponUserType'] = ''
        param['range'] = state.formData.range == undefined ? 1 : state.formData.range
        param['ranges'] = activityRanges
        param['id'] = id;

        const r = await new CouponLogic().updOperation(param);
        if (r.data.statusCode == 200) {
            message.success("更新成功");
            return true;
        } else {
            message.error(r.data.message);
            return false;
        }

    }
    addTableItem({ dispatch, list }) {
        list.forEach(async v => {
            // const r = await new UnitLogic().selData(v.goodsUnitID);

            dispatch({ type: "AddTableItem", payload: v });
        })
    }
    AddclassTableItem({ dispatch, list }) {
        list.length > 0 && list.forEach(v => {
            dispatch({ type: "AddclassTableItem", payload: v });
        })
    }
    delTableItem(dispatch, index) {
        dispatch({ type: "DelTableItem", payload: index });
    }
    delTableclassItem(dispatch, index) {
        dispatch({ type: "DelTableclassItem", payload: index });
    }
    setFormData({ dispatch, key, value }) {
        const params = {
        }
        params[key] = value;

        dispatch({ type: "SetFormData", payload: params })
    }
    setKeywords({ dispatch, value, state }) {
        this.setFormData({ dispatch, key: "keyword", value: value });

        state.searchList.forEach(e => {
            if (e.id == value) {
                this.setFormData({ dispatch, key: "keyword", value: value });

                dispatch({ type: "SetFormData", payload: { inStockUnitPrice: e.putNewPrice } })
            }

        })
    }
    changeTableItem({ dispatch, key, value, index }) {
        dispatch({ type: "ChangeTableItem", payload: { key, value, index } });
    }
    clearTableList({ dispatch }) {
        dispatch({ type: "ClearTableList" });
    }
    reset({dispatch}){
        initialArgs.formData.couponDetail = ''
       dispatch({type:'SetForm',payload:{
            ...initialArgs,
            
        }})
    }
}
export const couponformAction = new CouponFormAction();