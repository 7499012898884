import { useBoolean, useRequest } from "ahooks";
import { Button, Col, Form, Input, Row, Select } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect, useState } from "react";
import { formAction } from "../../action/printing/file_action";
import { FormStore } from "../../store/printing/file_store";
import { PrintingLogic } from "../../../../logic/printing_logic";
import { Form as FormAntd } from "antd";
import { Scroll } from "../../../../component/scroll";
import { autoRem } from "../../../../global.style";
import { Progress } from "../../../../logic/common_logic";
import { AuthLogic } from "../../../../logic/auth_logic";
const { Option } = Select;
export function Printing(viewPower) {
  const [form] = FormAntd.useForm();
  const [] = useBoolean(false);
  const action = formAction;
  const { state, dispatch } = useContext(FormStore);
  const { formData } = state;

  useRequest(() => {
    formAction.init({ state: state, dispatch, form });
    Progress.done();
  });
  const [menuButton, setData] = useState([]);
  const [power, powerFunc] = useBoolean(false);
  useEffect(() => {
    (async function () {
      let r = await new AuthLogic().selButton(viewPower.viewPower);
      if (r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          r[i] = r[i].split("_")[r[i].split("_").length - 1];
        }
      }
      setData(r);
      if (r.indexOf("UPDATE") == -1) {
        powerFunc.setFalse();
      } else {
        powerFunc.setTrue();
      }
    })();

    return () => {};
  }, []);
  return (
    <div
      style={{
        margin: "1.9375rem " + autoRem(1.25) + " 0 0",
        padding: "1rem",
        boxShadow:
          "0px " +
          autoRem(0.3125) +
          " " +
          autoRem(1.25) +
          " 0px rgba(209, 206, 206, 0.3)",
      }}
    >
      <Scroll dif={80}>
        <div style={{ overflowX: "hidden" }}>
          <FormAntd form={form}>
            <Row gutter={10}>
              <Col span={10}>
                <Form.Item
                  name="print-state"
                  label="小票打印机设置"
                  labelCol={{ span: 9 }}
                >
                  <Select
                    disabled={!power}
                    onChange={(v) =>
                      action.setValue({
                        dispatch,
                        value: v,
                        attribute: "State",
                      })
                    }
                  >
                    <Option value={0}>启用</Option>
                    <Option value={1}>不启用</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={10}>
                <Form.Item
                  name="print-printType"
                  label="打印方式"
                  labelCol={{ span: 9 }}
                >
                  <Select
                    disabled={!power}
                    onChange={(v) =>
                      action.setValue({
                        dispatch,
                        value: v,
                        attribute: "PrintType",
                      })
                    }
                  >
                    <Option value={0}>标签打印</Option>
                    <Option value={1}>热点打印</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={10}>
                <Form.Item
                  name="print-left"
                  label="打印左边距"
                  labelCol={{ span: 9 }}
                >
                  <Input
                    disabled={!power}
                    autoComplete="off"
                    placeholder="打印内容距离纸张的左边距"
                    onChange={(v) =>
                      action.setValue({
                        dispatch,
                        value: v.target.value,
                        attribute: "Left",
                      })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={10}>
                <Form.Item
                  name="print-right"
                  label="打印右边距"
                  labelCol={{ span: 9 }}
                >
                  <Input
                    disabled={!power}
                    autoComplete="off"
                    placeholder="打印内容距离纸张的右边距"
                    onChange={(v) =>
                      action.setValue({
                        dispatch,
                        value: v.target.value,
                        attribute: "Right",
                      })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={10}>
                <Form.Item
                  name="print-lin"
                  label="走纸行数"
                  labelCol={{ span: 9 }}
                >
                  <Input
                    disabled={!power}
                    autoComplete="off"
                    placeholder="打印页面多打印的行数"
                    onChange={(v) =>
                      action.setValue({
                        dispatch,
                        value: v.target.value,
                        attribute: "Lin",
                      })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={10}>
                <Form.Item
                  name="print-shopConjunctions"
                  label="门店订单小票打印联数"
                  labelCol={{ span: 9 }}
                >
                  <Select
                    disabled={!power}
                    onChange={(v) =>
                      action.setValue({
                        dispatch,
                        value: v,
                        attribute: "Shop_Conjunctions",
                      })
                    }
                  >
                    <Option value={1}>1</Option>
                    <Option value={0}>0</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={10}>
                <Form.Item
                  name="print-shopingConjunctions"
                  label="商城订单小票打印联数"
                  labelCol={{ span: 9 }}
                >
                  <Select
                    disabled={!power}
                    onChange={(v) =>
                      action.setValue({
                        dispatch,
                        value: v,
                        attribute: "Shoping_Conjunctions",
                      })
                    }
                  >
                    <Option value={1}>1</Option>
                    <Option value={0}>0</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={10}>
                <Form.Item
                  name="print-shopingPreSaleConjunctions"
                  label="商城预售订单小票打印联数"
                  labelCol={{ span: 9 }}
                >
                  <Select
                    disabled={!power}
                    onChange={(v) =>
                      action.setValue({
                        dispatch,
                        value: v,
                        attribute: "ShopingPreSale_Conjunctions",
                      })
                    }
                  >
                    <Option value={1}>1</Option>
                    <Option value={0}>0</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={10}>
                <Form.Item
                  name="print-shopPayConjunctions"
                  label="到店支付订单小票打印联数"
                  labelCol={{ span: 9 }}
                >
                  <Select
                    disabled={!power}
                    onChange={(v) =>
                      action.setValue({
                        dispatch,
                        value: v,
                        attribute: "ShopPay_Conjunctions",
                      })
                    }
                  >
                    <Option value={1}>1</Option>
                    <Option value={0}>0</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={10}>
                <Form.Item
                  name="print-memberRecharge"
                  label="会员充值小票打印联数"
                  labelCol={{ span: 9 }}
                >
                  <Select
                    disabled={!power}
                    onChange={(v) =>
                      action.setValue({
                        dispatch,
                        value: v,
                        attribute: "Member_Recharge",
                      })
                    }
                  >
                    <Option value={1}>1</Option>
                    <Option value={0}>0</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={10}>
                <Form.Item
                  name="print-changeShiftsConjunctions"
                  label="会员充值小票打印联数"
                  labelCol={{ span: 9 }}
                >
                  <Select
                    disabled={!power}
                    onChange={(v) =>
                      action.setValue({
                        dispatch,
                        value: v,
                        attribute: "ChangeShifts_Conjunctions",
                      })
                    }
                  >
                    <Option value={1}>1</Option>
                    <Option value={0}>0</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </FormAntd>
          <Button
            type="primary"
            color="Red"
            style={{ marginLeft: "1.25rem" }}
            onClick={() => {
              if (!power) {
                return false;
              }
              action.addSubmit({
                states: state.formData,
                stateh: state,
                dispatch,
                form,
              });
            }}
          >
            保存设置
          </Button>
        </div>
      </Scroll>
    </div>
  );
}
