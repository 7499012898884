import { useBoolean } from "ahooks";
import { Button, Input, message, Modal, Tag, DatePicker } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect, useState } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { DataTable } from "../../../../component/table";

import img_check from "../../../../assets/images/icon/status/zhengque.png";
import no_check from "../../../../assets/images/icon/status/jinyong.png";

import { fileAction } from "../../action/share/list_action";
import { ListFormStoreProvider } from "../../store/share/list_form_store";
import { Form } from "./list_form";
import { formAction } from "../../action/distributionRecords/list_form_action";
import { DataTableStore } from "../../../../component/table/store/data_table_store";
import { Search } from "../../../../component/search";
import { ListStore } from "../../store/share/list_store";
import { defualtPageSize, Progress } from "../../../../logic/common_logic";
import { AuthLogic } from "../../../../logic/auth_logic";

import { Tips, TipsGroup } from "../../../../component/tips";
import { FileSearch } from "./file_search";
import { Mask } from "../../../../component/mask";
import {
  Control,
  Del,
  Edit,
  Lock,
  See,
} from "../../../../component/control-column";
import { ConfirmModal } from "../../../../component/modal";
import { Demo } from "../../../../component/treeData";

let editId = "";
let seeId = "";
let passwordId = "";
let isdefault = false;
const formParams = {
  method: "Create",
  id: "",
  item: null,
};
export function ShareList(viewPower) {
  const { RangePicker } = DatePicker;
  const [createState, createFunc] = useBoolean(false);
  const action = fileAction;
  const [confirmState, confirmFunc] = useBoolean(false);
  const [qiyongState, qiyongFunc] = useBoolean(false);
  const [searchState, searchFunc] = useBoolean(false);
  const [delState, delFunc] = useBoolean(false);
  const { state, dispatch } = useContext(ListStore);
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);
  const [] = useBoolean(false);

  const [menuButton, setData] = useState([]);
  useEffect(() => {
    dispatch({ type: "SetMember", payload: [] });

    (async function () {
      let r = await new AuthLogic().selButton(viewPower.viewPower);
      if (r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          r[i] = r[i].split("_")[r[i].split("_").length - 1];
        }
      }
      // console.log(r);

      setData(r);
    })();

    return () => {};
  }, []);

  const columns: ColumnsType = [
    {
      title: "操作",
      key: "1",
      align: "center",
      width: Control.width(2),
      render: Control.render(
        (v) =>
          menuButton.indexOf("VIEW") != -1 && (
            <See
              click={() => {
                if (v.id) {
                  formParams.id = v.id;
                  formParams.item = v;
                  createFunc.setTrue();
                }
              }}
            />
          )
      ),
    },
    {
      title: "会员姓名",
      dataIndex: "memberName",
      key: "4",
      align: "center",
    },
    {
      title: "手机号码",
      dataIndex: "phone",
      key: "5",
      align: "center",
      // width: 140,
    },
    {
      title: "佣金余额",
      key: "6",
      align: "center",
      dataIndex: "distributionMoney",
    },
    // {
    //   title: "总消费金额",
    //   dataIndex: "totalConsumption",
    //   key: "8",
    //   align: "center",
    // },
    {
      title: "一级分享人",
      dataIndex: "oneDistributionName",
      key: "2",
      align: "center",
      // width: 130,
    },
    {
      title: "二级分享人",
      dataIndex: "twoDistributionName",
      key: "3",
      align: "center",
      // width: 130,
    },
    {
      title: "加入时间",
      dataIndex: "addDateTime",
      key: "11",
      align: "center",
    },
  ];

  const onSearch = async (pageIndex) => {
    await action.loadPage({
      pageIndex: pageIndex,
      pageSize: defualtPageSize(),
      dispatch: tableDispatch,
      search: state.search,
      state: state,
    });
  };

  return (
    <>
      <Search>
        <Input
          maxLength={50}
          allowClear
          placeholder="请输入分享人手机号、姓名"
          onChange={(e) => {
            action.setCondition("Keywords", e.target.value, dispatch);
          }}
        />
        <input type="button" value="快速查找" onClick={() => onSearch(1)} />
        <TipsGroup>
          <input
            type="button"
            value="高级查找"
            onClick={() => searchFunc.toggle()}
          />
          <Tips width="536px" display={searchState} left="0">
            <FileSearch
              onSearch={(...arg: [any]) => {
                onSearch(...arg);
                searchFunc.setFalse();
              }}
            />
          </Tips>
          <Mask visible={searchState} onClick={() => searchFunc.setFalse()} />
        </TipsGroup>
      </Search>

      <DataTable
        columns={columns}
        action={action}
        onLoad={async () => {
          await onSearch(1);
          Progress.done();
        }}
        onChange={(pageIndex) => {
          onSearch(pageIndex);
        }}
        scroll={{ scrollToFirstRowOnChange: true, autoY: true }}
        // selectColumn={true}
        loading={tableState.loading}
      />

      {createState && (
        <Form
          close={(hasUpdate) => {
            if (hasUpdate) {
              onSearch(tableState.current);
            }

            createFunc.setFalse();
          }}
          editId={formParams.id}
          item={formParams.item}
        />
      )}

      <ConfirmModal
        visible={delState}
        title="温馨提示"
        subTitle="确认删除？"
        message="删除之后信息无法恢复，请慎重考虑"
        close={async (r) => {
          if (r) {
            await action.delSelectList({
              dispatch,
              selectList: tableState.selectList,
            });

            action.clearSelectedRowKeys({ dispatch: tableDispatch });

            await onSearch(tableState.current);
          }
          delFunc.setFalse();
        }}
      />
      {/* <Demo id={""} /> */}
    </>
  );
}
