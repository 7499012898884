import React, { useContext, useEffect } from "react";
import { Button } from "../../../../component/button";
import { Layer } from "../../../../component/layer/layer";
import { Form as FormAntd } from "antd";
import { ListFormStore } from "../../store/distributionRecords/list_form_store";

import { ListStore } from "../../store/distributionRecords/list_store";
import { fileAction } from "../../action/distributionRecords/list_action";
import { DataTableStore } from "../../../../component/table/store/data_table_store";
import { defualtPageSize } from "../../../../logic/common_logic";
import { FormBasic } from "./list_form_basic";
interface IForm {
  children?: any;
  close: (hasUpdate?: boolean) => void;
  editId?: string;
  item;
}
export function Form({ close, editId, item }: IForm) {
  const [form] = FormAntd.useForm();

  //   const { state, dispatch: formDispatch } = useContext(ListFormStore);

  //   const { state: fileState, dispatch: fileDispatch } = useContext(ListStore);
  //   const { state: tableState, dispatch: tableDispatch } =
  //     useContext(DataTableStore);

  useEffect(() => {
    // formAction.init({ state: state, dispatch: formDispatch, editId: editId, passwordId, seeId, form });

    return () => {};
  }, []);

  function title() {
    if (editId) {
      return "查看分销关系图";
    }
    // else if (seeId) {
    //     return "查看用户";
    // }
    // else if (passwordId) {
    //     return "重置密码";
    // }
    // else {
    //     return "新建用户";
    // }
  }
  //   const onSearch = async (pageIndex) => {
  //     await fileAction.loadPage({
  //       pageIndex: pageIndex,
  //       pageSize: defualtPageSize(),
  //       dispatch: tableDispatch,
  //       search: fileState.search,
  //     });
  //   };
  return (
    <FormAntd form={form}>
      <Layer layerWith={3}>
        <Layer.Title onClick={() => close()}>查看分销关系图</Layer.Title>
        <Layer.Body>
          <FormBasic
            disabled={title() == "查看分销关系图"}
            formData={item}
            id={editId}
          />
        </Layer.Body>

        {title() == "查看分销关系图" && (
          <>
            <Layer.Foot></Layer.Foot>
          </>
        )}
      </Layer>
    </FormAntd>
  );
}
