// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../config";
import { effeative } from "./common_logic";
interface IShopSetVale{
    isBusinessStatus: boolean  
    businessBeginHours: string
    businessEndHours: string    
    storeLimitBusinessHours:boolean  
    shippingRange: number //配送范围
}
export class ShopLogic {
    async selData(shopId: string) {
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("shop_query"),
            params: {
                shopId
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r.data.data;
    }
    async selList(params) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("shop_list"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    async selListpost() {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("shop_list"),
            data: {
                pageIndex: 1,
                pageSize: 999,
                seacherName: '',
                shopType: '',
                status: '',
                provincialCapital: '',
                city: '',
                district: '',
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    async addData(params) {
        return effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("shop_add"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
    }
    async updData(params) {
        return effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("shop_update"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
    }
    /**获取门店设置 */ 
async getShopSettings(){
    return effeative(await axios({
        method: "GET",
        url: Tools.getApiAddress("get_setting_shop"),
        headers: {
            SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
        },
    }))
}
 
/**设置门店配置
 * @param isBusinessStatus 营业状态(true正常营业/false关闭营业)
 * @param businessBeginHours 开门时间
 * @param businessEndHours 关门时间
 * @param storeLimitBusinessHours 限制门店营业时间段(非营业时间商城是否可以下单)
 * @param shippingRange 配送范围(千米)
 */
async setShopSetting(params:IShopSetVale){
    return effeative(await axios({
        method:'POST',
        url:Tools.getApiAddress('setting_shop'),
        data: params,
        headers: {
            SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
        },
    }))
}
async getShopAndChid(){
    return effeative(await axios({
        method:'GET',
        url:Tools.getApiAddress('shop_child_query'),
        headers: {
            SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
        },
    }))
}

    /**获取单个门店参数 */
    async getShopParam(ShopID){
        return effeative( await axios({
            method: 'GET',
            url: Tools.getApiAddress('shop_param_get'),
            params:{
                ShopID: ShopID
            }
        }))
    }
    /**设置单个门店参数 */
    async setShopParam(params){
        return effeative( await axios({
            method: 'POST',
            url: Tools.getApiAddress('shop_param_set'),
            data: params
        }))
    }

}