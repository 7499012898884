

class topicAction{
    add({dispatch, type}){
        let initData = null;
      
       dispatch({type:'ADD_COM',payload:{type:type,data:initData}})
    };

    saveName({dispatch,name}){
        dispatch({type:'SAVE_NAME',payload:name})
    }

    saveEditorColor({dispatch, bgColor}){
        dispatch({type:'SAVE_COLOR',payload:bgColor})
    };
    saveEditorDesc({ dispatch, description}){
        dispatch({type:'SAVE_DESC',payload:description})
    }
    changeNavImg({dispatch, id, img}){
        dispatch({type: 'CHANGE_NAVIMG',payload: {id,img}})
    };
    changeCommodityListLayout({dispatch,layout}){
        dispatch({type: 'CHANGE_Commodity_List_layout',payload: layout})
    };
    changeCommodityList({dispatch,list}){
       dispatch({type:'CAHNGE_Commodity_List',payload: list})
    };
    deleteCommodityList({dispatch,index}){
        dispatch({type:'DELETE_Commodity_List',payload: index})
    }

}
export const  topicActions = new topicAction();