import { message } from "antd";
import { IntegralRuleLogic } from "../../../../logic/integralRule_logic";
export class FormAction {
  async init({ dispatch, form }) {
    const res = await new IntegralRuleLogic().getInfo();
    if(res.data.statusCode === 200 ){
     let item = res.data.data
     if(item){
       dispatch({type:'SetForm',payload: res.data.data})
      form.setFieldsValue({giveIntegral:item.giveIntegral})
      form.setFieldsValue({money:item.money})
      form.setFieldsValue({status:item.status})
      form.setFieldsValue({integralType:item.integralType})
     }
       
    }else{
      message.error(res.data.returnMessage)
    }
  }
  setFormData({ dispatch, key, value }) {
    const params = {};
    params[key] = value;
    dispatch({ type: "Change_FormData", payload: params });
  }
  async save({ state, dispatch }) {
    const { formData } = state;
    let params = {
      integralType: formData.integralType,
      status: formData.status ,
      money: Number(formData.money),
      giveIntegral: Number(formData.giveIntegral),
    };
    if(formData.id){
        params['id'] = formData.id
    }
    dispatch({type:'Loading',payload: true})
    const res = await new IntegralRuleLogic().save(params);
    dispatch({type:'Loading',payload: false})
    if(res.data.statusCode === 200){
      message.success(res.data.returnMessage)
    }else{
      message.error(res.data.returnMessage)
    }
  }
}
export const formAction = new FormAction();
