import { Pie } from "./pie"
import { ILegend } from "./pie"
import { FormSubtitle } from "../../commodity/view/form_subtitle"
import styles from '../style/index.module.scss'
import { autoRem } from "../../../global.style"
import { Funnel } from "./funnel"
import { Line } from "./Line"
import { useContext, useEffect } from "react"
import { FileStore } from "../store"
import { Empty ,Skeleton} from "antd"

/**环形图 
 * @param orderTypePie 订单类型
 * @param classSalePie 商品分类
 * @param payWayPie 支付方式
*/
export function Sale({orderTypePie,classSalePie,payWayPie}){
      let title = {
        text:'饼图',
        color:'#262626',
        left:'left',
        fontSize:'18px'
      }
      let legend = new ILegend()
      useEffect(()=>{
        // console.log(orderTypePie,classSalePie,payWayPie)
      },[orderTypePie,payWayPie,classSalePie])
    return <div>
      <FormSubtitle style={{fontSize:autoRem(1.61),height:autoRem(2.125)}}>饼状图</FormSubtitle>
      <div className={styles.graphicReport}>
      {orderTypePie.length>0?<Pie data={orderTypePie} title={{...title,text:'订单类型'}}  radius={['40%','70%']} legend={legend} />:<EmptyData title={'订单类型'}/>}

      {payWayPie.length>0?<Pie data={payWayPie} title={{...title,text:'支付方式'}}  radius={['40%','70%']} legend={legend}/>:<EmptyData title={'支付方式'}/>}
      {classSalePie.length>0?<Pie data={classSalePie} title={{...title,text:'商品分类'}}  radius={['40%','70%']} legend={legend} />:<EmptyData title={'商品分类'}/>}
      </div>
      </div>
}
/**漏斗图 
 * @param list 商品销量TOP10
  * @param membersaleList 会员消费TOP10
  * @param memberStoreList 会员储值TOP10
*/
export function FunnelPart({list,membersaleList,memberStoreList}){
  let title = {
    text:'漏斗图',
    color:'#262626',
    left:'left',
    fontSize:'18px'
  }
  useEffect(()=>{
    // console.log(list,membersaleList)
  },[list,membersaleList,memberStoreList])
  return<div>
            <FormSubtitle style={{fontSize:autoRem(1.61),height:autoRem(2.125)}}>漏斗图</FormSubtitle>
            <div className={styles.graphicReport}>
            {/* <Skeleton.Avatar active={true} size={200} shape={'circle'} /> */}
            {memberStoreList.length?<Funnel data={memberStoreList} title={{...title,text:'会员储值TOP10'}}   divStyles={{height:'500px',width:autoRem(50)}}/>:<EmptyData title={'会员储值TOP10'}/>}
            { membersaleList.length>0?<Funnel data={membersaleList} title={{...title,text:'会员消费TOP10'}}  divStyles={{height:'500px',width:autoRem(50)}}/>:<EmptyData title={'会员消费TOP10'}/>}
            {list.length>0? <Funnel data={list} title={{...title,text:'商品销量TOP10'}}  divStyles={{height:'500px',width:autoRem(50)}}/>:<EmptyData title={'商品销量TOP10'}/>}
            </div>
         </div>
}

/**折线图
 * @param newMemberLine 新用户走势
 * @param memberIncomeExpend 会员收支
 * @param refundList 退款
 * @param saleList 销售额
 */
export function LinePart({newMemberLine,memberIncomeExpend,refundList,saleList}){
  const data = [
   [{name:'ll',value: 10},{name:'ll',value: 10},{name:'ll',value: 10}]
  ]
  const dataX = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  let title = {
    text:'折线图',
    color:'#262626',
    left:'left',
    fontSize:'18px'
  }
  useEffect(()=>{
    // console.log(newMemberLine,memberIncomeExpend,refundList,saleList)
  },[newMemberLine,memberIncomeExpend,refundList,saleList])
  return <div >
    <FormSubtitle style={{fontSize:autoRem(1.61),height:autoRem(2.125)}}>折线图</FormSubtitle>
    {saleList.length>0?<Line title={{...title,text:'销售额折线图'}} data={saleList}  />:<EmptyData title={'销售额折线图'}/>}
    {refundList.length>0?<Line title={{...title,text:'退款折线图'}} data={refundList} />:<EmptyData title={'退款折线图'}/>}
    {memberIncomeExpend.length>0?<Line title={{...title,text:'会员收支折线图'}} data={memberIncomeExpend}  />:<EmptyData title={'会员收支折线图'}/>}
    {newMemberLine.length>0?<Line title={{...title,text:'新用户走势图'}} data={newMemberLine} />:<EmptyData title={'新用户走势图'}/>}
  </div>
}
export function EmptyData({title}){
  return<div className={styles.emptyData}>
        <span className={styles.title}>{title}</span>
        <Empty/>
    </div>
}
interface IParam{
  type: 'avatar' | 'image' | '',
  active: boolean,
  size: number| 'default' | 'large' | 'small',
  shape: 'circle' | 'square',

}
export function LoadingStatus(params:IParam){
  switch(params.type){
    case 'avatar':
          return <Skeleton.Avatar active={params.active}  size={params.size} shape={params.shape} />
    default :
        return <Skeleton/>
  }
}
