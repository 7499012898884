import { TabKeys } from "../../tool/TabKeys";

export const memberTreeDataTablKey: (TabKeys )[] = [
    {
        key: 'mendian',
        title: '门店名称',
    },
 
];

export const memberDataTablKey: TabKeys[] = [
    // {
    //     key: 'shopName',
    //     title: '门店名称',
    // },
    {
        key: 'memberName',
        title: '会员姓名',
    },
    {
        key: 'balanceMoney',
        title: '会员余额',
    },
    {
        key: '',
        title: '收银端',
        child: [
            {
                key: 'shopConSumeMoney',
                title: '消费金额',
            },
            {
                key: 'shopStoreMoney',
                title: '充值金额',
            },
            {
                key: 'shopGiveMoney',
                title: '赠送金额',
            },
            {
                key: 'shopRefundMoney',
                title: '退款金额',
            },
        ],
    },
    {
        key: '',
        title: '商城',
        child: [
            {
                key: 'appConSumeMoney',
                title: '消费金额',
            },
            {
                key: 'appStoreMoney',
                title: '充值金额',
            },
            {
                key: 'appGiveMoney',
                title: '赠送金额',
            },
            {
                key: 'appRefundMoney',
                title: '退款金额',
            },
        ],
    },
    {
        key: '',
        title: '合计',
        child: [
            {
                key: 'totalConSumeMoney',
                title: '消费金额',
            },
            {
                key: 'totalStoreMoney',
                title: '充值金额',
            },
            {
                key: 'totalGiveMoney',
                title: '赠送金额',
            },
            {
                key: 'totalRefundMoney',
                title: '退款金额',
            },
        ],
    },
    
];