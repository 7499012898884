import { TabKeys } from "../../tool/TabKeys";

export const goodsTreeDataTablKey: (TabKeys & { subtotal: string })[] = [
    {
        key: 'dianpu',
        title: '门店名称',
        subtotal: '门店小计',
    },
    {
        key: 'leibie',
        title: '商品类别',
        subtotal: '',
    },
];

export const googsDataTablKey: TabKeys[] = [
    {
        key: 'goodsNo',
        title: "商品编码",
        styles:{
            color: '#2db7f5',
            cursor:'pointer'
        }
    },
    {
        key: 'goodsName',
        title: "商品名称"
    },
    {
        key: 'unitName',
        title: "商品单位"
    },
    {
        // key: 'saleTotoCount',
        // title: "商品总销售数量"
        key: 'outInventoryNumber',
        title: "出库数量"
    },
    // {
    //     key: 'realTime_Inventory',
    //     title: "实时库存"
    // },
    {
        key: 'saleTotoCount',
        title: "商品总销售数量"
       
    },
    {
        key: 'paymentAmountAvg',
        title: "销售均价"
    },
    {
        key: 'paymentAmount',
        title: "销售金额"
    },
    // {
    //     key: 'salePercentage',
    //     title: "销售占比(%)"
    // },
    // {
    //     key: 'inventoryPrice',
    //     title: "库存价"
    // },
    {
        key: 'salecost',
        title: "销售成本"
    },
    {
        key: 'saleprofit',
        title: "销售毛利"
    },
];