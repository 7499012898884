import React, { Children, cloneElement, useContext } from "react";
import { TabStore } from "../store/tab_store";

interface ITabList {
    children?: any;
}

export function TabList({ children }: ITabList) {
    const { selectIndex } = useContext(TabStore);

    let index = -1;
    return (
        <ul>
            {
                Children.map(children, v => {
                    if ("Tab" == v.type.nodetype) {
                        index++;
                        return cloneElement(v, { serial: index });
                    } else {
                        return v;
                    }
                })
            }
        </ul>
    );
}