import useRequest from "@ahooksjs/use-request";
import { Checkbox } from "antd";
import { useEffect, useState, useContext } from "react";
import { AuthLogic } from "../../../../logic/auth_logic";
import styles from "../../style/switch.module.scss";
import { fileAction } from "../../action/switch/file_action";
import { FileStore } from "../../store/switch/file_store";
import { Button } from "../../../../component/button";

import { Select as SelectAntd } from "antd";
import { Select } from "../../../../component/select";
export function SwitchControl(viewPower) {
  const action = fileAction;
  const [menuButton, setData] = useState([]);
  const { state, dispatch } = useContext(FileStore);
  useEffect(() => {
    (async function () {
      let r = await new AuthLogic().selButton(viewPower.viewPower);
      if (r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          r[i] = r[i].split("_")[r[i].split("_").length - 1];
        }
      }
      setData(r);
    })();

    return () => {};
  }, []);

  useRequest(() => {
    action.init({ dispatch });
  });
  return state.dataList && state.dataList.length ? (
    <div className={styles.root}>
      {menuButton.indexOf("VIEW") != -1 &&
        state.dataList &&
        state.dataList.length &&
        state.dataList.map((item, index) => {
          return (
            <div className={styles.container} key={index}>
              <div className={styles.title}>{item.group}</div>
              <div className={styles.checkbox_group}>
                {item.list &&
                  item.list.length &&
                  item.list.map((l) => {
                    if(l.key == 'PaymentType'){
                      return(
                        <div style={{display: 'flex',alignItems:'center',width: 300,}}>
                          <div style={{marginRight: 10, color:'#000000d9',fontSize: 14, fontWeight: 500}}>{l.settingName}</div>
                          <Select style={{flex: 1}}
                          onChange={(v) =>
                            action.Chagne( l,  v, dispatch, l.key)
                          }
                          value={l.value}
                        >
                          {/* 店铺支付类型 Values: 0：关闭线上支付 1：微信、支付宝平台直连 2：嘉联支付 3：国通支付; */}
                            <SelectAntd.Option key='0' value={'0'}>
                                关闭线上支付
                            </SelectAntd.Option>
                            <SelectAntd.Option key='1' value={'1'}>
                                平台直连(微信支付宝)
                            </SelectAntd.Option>
                            <SelectAntd.Option key='2' value={'2'}>
                              嘉联支付
                            </SelectAntd.Option>
                            <SelectAntd.Option key='3' value={'3'}>
                              国通支付
                            </SelectAntd.Option>
                            
                        </Select>
                        </div>
                      )
                    }else{
                      return (
                        <Checkbox
                          key={l.key}
                          checked={l.value == "1" || l.value == "Online"}
                          value={l.value}
                          style={{ width: "25%", margin: "20px 0" }}
                          onChange={(e) => {
                            action.Chagne(l, e.target.checked, dispatch, l.key);
                          }}
                          disabled={menuButton.indexOf("SET") == -1}
                        >
                          {l.settingName}
                        </Checkbox>
                      );
                    }                  
                  })}
                {/* <Checkbox value={1}  style={{width: '25%', margin: '15px 0'}}>线上支付</Checkbox> */}
              </div>
            </div>
          );
        })}
      {/* <Button type='primary' color='Red' style={{margin:' 20px 15px'}}>保存</Button> */}
    </div>
  ) : (
    <div style={{ textAlign: "center", marginTop: 100 }}>暂无可操作选项</div>
  );
}
