import { Form, Input, message, Modal as AntdModal, ModalProps, Radio } from "antd";
import React, { useState } from "react";
import styles from "../style/feedback.module.scss";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { useForm } from "antd/lib/form/Form";
import axios from "axios";
import { ConfigApi, Tools } from "../../../config";
import Cookies from "js-cookie";
import {stringify } from "querystring";

interface IModalStyle {
    children?: any;
}
const Edit = ({ onChange, value }) => {
    let reactQuillRef;
    const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],

        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],
        ['link', 'image', 'video'],
        ['clean']                                         // remove formatting button
    ]

    return (
        <div className="editor-wrapper" style={{ textAlign: "left" }}>
            <ReactQuill value={value} modules={{ toolbar: toolbarOptions }} style={{ height: 230 }} ref={(el) => { reactQuillRef = el }} theme="snow"
                onChange={v => onChange(v)}
            />
        </div>
    )
}
function Modal(params: IModalStyle & ModalProps) {
    const { children } = params;

    const rootStyle = (params.className) ? params.className : "";

    return <AntdModal {...params} {...{ className: rootStyle + " " + styles.root }} >{children}</AntdModal>
}

type IConfirmModal = {
    title,
    visible;
    close: (status: boolean) => void;
}
async function* fetchData({ title, type, info }) {

    let { shopID, shopName } = JSON.parse(Cookies.get('loginMsg'))

    return axios.post(Tools.getApiAddress("feedback_add").replace(ConfigApi.serviceDomain, ConfigApi.operationDomain),stringify({
        "QTitle": title,
        "QType": type,
        "QInfo": info,
        "ShopID": shopID,
        "ShopName": shopName
    }),{
        headers:{
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
    })
}

interface IContentInput {
    value?: any;
    onChange?: (value: any) => void;
    formProperty;
    setFormProperty;
}
function ContentInput({ formProperty, setFormProperty, value, onChange }: IContentInput) {

    const triggerChange = (changedValue) => {
        onChange?.({ ...value, ...changedValue });
    };

    return (
        <div className={styles.content}>
            <Edit value={formProperty.info} onChange={v => {

                setFormProperty({ ...formProperty, info: v });

                triggerChange({ number: v === '<p><br></p>' ? 0 : v.length });
            }} />
        </div>
    )
}

export function Feedback({ title, close, visible }: IConfirmModal) {
    const [form] = useForm();
    const [formProperty, setFormProperty] = useState<any>({ type: 1 });

    const send = async () => {
        await form.validateFields();

        const r = (await fetchData(formProperty).next()).value;

        if (r.data.code == 200) {
            setFormProperty({});

            setFormProperty({ type: 1, info: "", title: "" });
            form.setFieldsValue({ title: "", content: "" });

            message.success("提交成功");
        } else {
            message.error(r.data.returnMessagee);
        }
    }
    const checkPrice = (_: any, value: { number: number }) => {

        if (value?.number > 0) {
            return Promise.resolve();
        }
        return Promise.reject(new Error('Price must be greater than zero!'));
    };

    return (
        <Modal title={title} visible={visible} style={{ marginTop: -20 }} okText="提交" cancelText="取消" width={"65rem"} onOk={async () => await send()} onCancel={() => {

            setFormProperty({});

            setFormProperty({ type: 1, info: "", title: "" });
            form.setFieldsValue({ title: "", content: "" });

            close(false)
        }}>
            <Form form={form} className={styles.feedback}>
                <Form.Item
                    label="标题"
                    name="title"
                    labelCol={{ span: 2 }}
                    rules={[{ required: true, message: '请输入标题' }]}
                >
                    <Input value={formProperty.title} autoComplete="off" allowClear onChange={v => setFormProperty({ ...formProperty, title: v.target.value })} />
                </Form.Item>
                <Form.Item
                    label="内容"
                    name="content"
                    labelCol={{ span: 2 }}
                    rules={[{ required: true, message: '请输入内容', validator: checkPrice }]}
                    style={{ marginBottom: 0 }}
                >
                    <ContentInput formProperty={formProperty} setFormProperty={setFormProperty} />
                </Form.Item>
                <Form.Item
                    label=" "
                    labelCol={{ span: 2 }}
                    style={{ color: "transparent", marginBottom: 0 }}
                    className={styles.last}
                >
                    <Radio.Group value={formProperty.type} className={styles.radio_group} defaultValue={1} onChange={v => setFormProperty({ ...formProperty, type: v.target.value })}>
                        <Radio value={1}>正常</Radio>
                        <Radio value={2}>紧急</Radio>
                    </Radio.Group>
                </Form.Item>
            </Form>
        </Modal>
    )
}
