// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../config";
import { effeative } from "./common_logic";

export class MimiAdLogic {
 
    /**获取单条数据 */
    async selData(id: string) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("miniAdv.getById"),
            params: {
                id
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    /**分页查询 */
    async queryByPage(param) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("miniAdv.queryByPage"),
            data: param,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    /**添加开屏广告 */
    async addData(params) {
        return effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("miniAdv.add"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
    }
    async updData(params) {
        return effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("miniAdv.update"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
    }
        async delData(id: string) {
            const r = effeative(await axios({
                method: "POST",
                url: Tools.getApiAddress("miniAdv.delete"),
                data: {
                    id:id
                },
                headers: {
                    SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
                }
            }))
            return r;
        }
}