import { createContext, useContext, useReducer } from "react";

export const ScaleFileStore = createContext<IContextProps>(null);

interface IContextProps {
  state: IState;
  dispatch: ({ type }: Dispatch) => void;
}

type Dispatch = { type; payload };

class Search {
  seachKey: string;
  beginTime: string;
  endTime: string;
  barCodeID: any[];
}

interface IState {
  search: Search;
  dataList: any[];
  barCode: any[];
}

const initialArgs: IState = {
  search: new Search(),
  dataList: [],
  barCode: [],
};

function reducer(state: IState, { type, payload }: Dispatch) {
  switch (type) {
    case "Equipment":
      state.barCode = payload;
      return {
        ...state,
      };
    case "SetSearch":
      return {
        ...state,
        search: {
          ...state.search,
          ...payload,
        },
      };
    default:
      return state;
  }
}

export function ScaleFileStoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialArgs);

  const v = { state, dispatch };
  return (
    <ScaleFileStore.Provider value={v}>
      {props.children}
    </ScaleFileStore.Provider>
  );
}
