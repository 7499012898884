import { useBoolean, useRequest } from "ahooks";
import { Button, Input, message, Modal, Spin, Tag, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect, useState } from "react";
import { ButtonPanel } from "../../../component/button-panel/button_panel";
import { Search } from "../../../component/search";
import { DataTable } from "../../../component/table/view/data_table";
import { Tips, TipsGroup } from "../../../component/tips";
import { formAction } from "../action/form_action";
import { FormStoreProvider } from "../store/form_store";
import { PrintSearch } from "./print_search";
import { Form } from "./form";
import { DataTableStore } from "../../../component/table/store/data_table_store";
import { PrintStore } from "../store/print_store";
import { printAction } from "../action/print_action";
import { Tools } from "../../../config";
import { ConfirmModal } from "../../../component/modal";
import img_print from "../../../assets/images/print.png";
import { Mask } from "../../../component/mask";
import { defualtPageSize, Progress } from "../../../logic/common_logic";
import { Control, Del, Edit, See } from "../../../component/control-column";
import { AuthLogic } from "../../../logic/auth_logic";
// import { getLodop } from "../../../assets/print/LodopFuncs.js";
import { getGSCloudPlugin } from "../../../assets/js/GSCloudPluginFuncs";
import Cookies from "js-cookie";
const formParams = {
  method: "Print",
  id: "",
};
let GSCloudPlugin = getGSCloudPlugin();
export function Print(viewPower) {
  const [searchState, searchFunc] = useBoolean(false);
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);
  const { state, dispatch } = useContext(PrintStore);
  const [printState, printFunc] = useBoolean(false);
  const [printAllState, printAllFunc] = useBoolean(false);
  const [isPrinting, isPrintFunc] = useBoolean(false);
  const action = printAction;
  const [menuButton, setData] = useState([]);
  const [info, setInfo] = useState({
    goodsName: "商品名称",
    shopPrice: "0",
    unitName: "单位",
  });
  const quickSort = (arr) => {
    if (arr.length <= 1) {
      return arr;
    }
    let pivotIndex = Math.floor(arr.length / 2);
    let pivot = arr.splice(pivotIndex, 1)[0];
    let left = [];
    let right = [];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].key < pivot.key) {
        left.push(arr[i]);
      } else {
        right.push(arr[i]);
      }
    }
    return quickSort(left).concat([pivot], quickSort(right));
  };
  useEffect(() => {
    (async function () {
      let r = await new AuthLogic().selButton(viewPower.viewPower);
      if (r && r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          r[i] = r[i].split("_")[r[i].split("_").length - 1];
        }
      }
      setData(r);
    })();

    return () => {};
  }, []);

  const columns: ColumnsType = [
    {
      title: "操作",
      dataIndex: "id",
      key: "1",
      align: "center",
      width: Control.width(2),
      render: Control.render((v, r) => (
        <Tooltip title={"价签机打印"}>
          <img
            src={img_print}
            alt=""
            onClick={() => {
              formParams.id = r.id;
              setInfo(r);
              printFunc.setTrue();
            }}
          />
        </Tooltip>
      )),
    }, //第一列商品操作

    {
      title: "商品名称",
      dataIndex: "goodsName",
      key: "3",
      align: "center",
    },
    {
      title: "商品条码",
      dataIndex: "barCode",
      key: "4",
      align: "center",
    },
    {
      title: "商品单位",
      dataIndex: "unitName",
      key: "5",
      align: "center",
    },
    {
      title: "价格",
      dataIndex: "shopPrice",
      key: "6",
      align: "center",
    },
    // {
    //   title: "状态",
    //   dataIndex: "status",
    //   key: "7",
    //   align: "center",
    //   render: (v) => (
    //     <Tag color={1 === v ? "success" : "error"}>
    //       {1 === v ? "正常" : "停售"}
    //     </Tag>
    //   ),
    // },
  ];
  useRequest(() => action.init({ dispatch }));
  const onSearch = async (pageIndex) => {
    await action.loadPage({
      pageIndex: pageIndex,
      pageSize: defualtPageSize(),
      dispatch: tableDispatch,
      search: state.search,
    });
  };

  // c-lodop print -- start
  const printPageView = ({ goodsName, unitName, price }) => {
    // console.log(goodsName, price);
    // let printHtml =
    //   "<div    id='print'    style=' height: 80px; width: 220px;'  >" +
    //   "<div style='text-align: left;'> <span style='margin-left: 15px'>" +
    //   `${goodsName}` +
    //   "</span>" +
    //   //   { goodsName } +
    //   "</div>" +
    //   "<div style='padding-left:56px;font-size:16px;margin-top:10px'>" +
    //   `${unitName}` +
    //   "</div>" +
    //   "<div style='width:100%; margin-top:20px'>" +
    //   "<span style=' margin-left:130px;font-size: 28px'>" +
    //   `${price}` +
    //   "</span>" +
    //   //   { price } +
    //   "</div>" +
    //   "</div>";
    // let LODOP = getLodop();
    // LODOP.PRINT_INIT("react使用打印插件CLodop"); //打印初始化
    // // // let strStyle = `<style> 打印的样式需要写在这里，下面引入</style> `;
    // LODOP.SET_PRINT_PAGESIZE(0, 700, 380, "");
    // LODOP.SET_PRINT_MODE("POS_BASEON_PAPER", true);
    // LODOP.ADD_PRINT_HTM("9mm", "10mm", "100%", 380, printHtml);
    // // LODOP.ADD_PRINT_TEXT(0, 100, 700, 380, "打印");
    // // LODOP.ADD_PRINT_RECT(-100, -100, 700, 380, 0, 1);
    // // LODOP.PREVIEW(); //最后一个打印(预览)语句
    // LODOP.PREVIEW();
  };

  const PrintInfo = ({ msg }) => {
    // console.log(msg);
    return (
      <>
        <div
          id="print"
          style={{ height: "400px", width: "200px", display: "none" }}
        >
          <div style={{ textAlign: "left" }}>{msg.goodsName}</div>
          <div style={{ textAlign: "right", marginTop: "20px" }}>
            {msg.shopPrice}价格
          </div>
        </div>
      </>
    );
  };
  // c-lodpop print -- end
  // 光速云打印 -- start
  // 打印
  const printFunction = ({ goodsName, unitName, price }, dispatch) => {
    GSCloudPlugin.SetAppKey(
      "BC85333E6E2345A9AC2C39635C722682",
      "四川智如客科技有限公司"
    );
    GSCloudPlugin.GetPrintQueue({
      OnSuccess: function (result) {
        console.log(result);
      },
      OnError: function (result) {
        console.log(result);
      },
    });
    let that = this;
    dispatch({
      type: "isloading",
      payload: { isLoading: true, msg: "打印中..." },
    });
    var url = document.location.toString().split("/");
    // window.open(
    //   url[0] +
    //     "//" +
    //     url[1] +
    //     url[2] +
    //     "/printPriceTag.html?goodsName=" +
    //     goodsName +
    //     "&unitName=" +
    //     unitName +
    //     "&price=" +
    //     price
    // );
    // setTimeout(() => {
      GSCloudPlugin.PrintHtml({
        Title: "HTML0001",
        Width: 220,
        Height: 0,
        Url:
          url[0] +
          "//" +
          url[1] +
          url[2] +
          "/printPriceTag.html?goodsName=" +
          goodsName +
          "&unitName=" +
          unitName +
          "&price=" +
          price,
        PrinterName: "测试", // app.getPrinterName()方法仅用于在线演示
        OnSuccess: function (result) {
          dispatch({
            type: "isloading",
            payload: { isLoading: false, msg: "打印中..." },
          });
        },
        OnError: function (result) {
          dispatch({
            type: "isloading",
            payload: { isLoading: false, msg: "打印中..." },
          });
        },
      });
    // }, 2000);
  };
  // 光速云打印 -- end

  /**非价签纸 */
  const printFunctionFor = ({ imgurl }, dispatch) => {
    GSCloudPlugin.SetAppKey(
      "BC85333E6E2345A9AC2C39635C722682",
      "四川智如客科技有限公司"
    );
    GSCloudPlugin.GetPrintQueue({
      OnSuccess: function (result) {
        console.log(result);
      },
      OnError: function (result) {
        console.log(result);
      },
    });
    dispatch({
      type: "isloading",
      payload: { isLoading: true, msg: "打印中..." },
    });
    let ids = tableState.selectList.map((item) => item.id).toString();
    var url = document.location.toString().split("/");
    window.open(
      url[0] +
        "//" +
        url[1] +
        url[2] +
        "/printGrid.html?ids=" +
        ids +
        "&ticketType=4&token=" +
        Cookies.get("token")
    );

    // setTimeout(() => {
      GSCloudPlugin.PrintHtml({
        Title: "HTML0001",
        Width: 220,
        Height: 0,
        Url:
          url[0] +
          "//" +
          url[1] +
          url[2] +
          "/printGrid.html?ids=" +
          ids +
          "&ticketType=4&token=" +
          Cookies.get("token"),
        PrinterName: "测试", // app.getPrinterName()方法仅用于在线演示
        OnSuccess: function (result) {
          dispatch({
            type: "isloading",
            payload: { isLoading: false, msg: "打印中..." },
          });
        },
        OnError: function (result) {
          dispatch({
            type: "isloading",
            payload: { isLoading: false, msg: "打印中..." },
          });
        },
      });
    // }, 2000);
  };
  return (
    <div>
      <Search>
        <Input
          maxLength={20}
          allowClear
          placeholder="请输入商品名称、商品编码"
          onChange={(e) =>
            action.setCondition("Keywords", e.target.value, dispatch)
          }
        />
        <input type="button" value="快速查找" onClick={() => onSearch(1)} />

        <TipsGroup>
          <input
            type="button"
            value="高级查找"
            onClick={() => searchFunc.toggle()}
          />
          <Tips width="45rem" display={searchState}>
            <PrintSearch
              onSearch={(...arg: [any]) => {
                onSearch(...arg);
                searchFunc.setFalse();
              }}
            />
          </Tips>
          <Mask visible={searchState} onClick={() => searchFunc.setFalse()} />
        </TipsGroup>
      </Search>
      <ButtonPanel>
        {menuButton.indexOf("VIEW") != -1 && (
          <Button
            onClick={async () => {
              if (!tableState.selectList.length) {
                message.warning("请选择商品");
              } else {
                printAllFunc.setTrue();
              }
            }}
          >
            批量打印
          </Button>
        )}
        {menuButton.indexOf("VIEW") != -1 && (
          <Button
            onClick={async () => {
              if (!tableState.selectList.length) {
                message.warning("请选择商品");
              } else {
                // printAllFunc.setTrue();
                if (tableState.selectList.length > 10) {
                  message.warning("一次最多打印十张价签");
                } else {
                  printFunctionFor({ imgurl: "" }, dispatch);
                }
              }
            }}
          >
            58价签打印
          </Button>
        )}
      </ButtonPanel>
      {/* <Spin spinning={isPrinting} tip="打印中"> */}
      <DataTable
        rowKey="id"
        columns={columns}
        action={action}
        onLoad={async () => {
          await onSearch(1);
          Progress.done();
        }}
        onChange={(pageIndex) => {
          onSearch(pageIndex);
        }}
        scroll={{ scrollToFirstRowOnChange: true, autoY: true }}
        loading={tableState.loading}
        selectColumn={true}
        // saveKeys={true}
      />
      {/* </Spin> */}
      <PrintInfo msg={info} />
      <ConfirmModal
        visible={printState}
        title="温馨提示"
        subTitle="确认打印？"
        message="确认打印价签"
        close={async (r) => {
          if (r) {
            // printPageView({
            //   goodsName: info.goodsName,
            //   unitName: info.unitName,
            //   price: info.shopPrice,
            // });
            printFunction(
              {
                goodsName: info.goodsName,
                unitName: info.unitName,
                price: info.shopPrice,
              },
              dispatch
            );
          }
          printFunc.setFalse();
        }}
      />
      <ConfirmModal
        visible={printAllState}
        title="温馨提示"
        subTitle="确认打印？"
        message="确认批量打印价签"
        close={async (r) => {
          if (r) {
            isPrintFunc.setTrue();
            let arr = quickSort(tableState.selectList);
            // console.log(arr);
            arr.map((item) => {
              // console.log(item.key);
              printFunction(
                {
                  goodsName: item.goodsName,
                  unitName: item.unitName,
                  price: item.shopPrice,
                },
                dispatch
              );
            });
            // tableState.selectList.map((item) => {
            //   setInfo(item);
            // printPageView({
            //   goodsName: item.goodsName,
            //   price: item.shopPrice,
            //   unitName: item.unitName,
            // });
            // printFunction(
            //   {
            //     goodsName: item.goodsName,
            //     unitName: item.unitName,
            //     price: item.shopPrice,
            //   },
            //   dispatch
            // );
            // });
          }
          setTimeout(() => {
            action.clearSelectedRowKeys({ dispatch: tableDispatch });
            isPrintFunc.setFalse();
          }, 2000);
          printAllFunc.setFalse();
        }}
      />
    </div>
  );
}
