import React, { useEffect, useState } from "react";
import { Layout } from "../../component/layout";
import { TabsStyle, Tab, TabList, TabPanel } from "../../component/react-tabs";
import { Grade } from "./view/grade/grade";
import { Stored } from "./view/stored/stored";
import { Flow } from "./view/flowStored/flow";
import { Dossier } from "./view/dossier/dossier";
import { Integral } from "./view/integral/integral";
import { Unit } from "./view/tag/tag";
import { UnitStoreProvider } from "./store/unit_store";
import { unitAction } from "./action/unit_action";
import { FileStoreProvider } from "./store/dossier/file_store";
import { GradeStoreProvider } from "./store/grade/file_store";
import { IntegralStoreProvider } from "./store/integral/file_store";
import { FlowStoreProvider } from "./store/flowStored/file_store";
import { StoredStoreProvider } from "./store/stored/file_store";
import { DataTableStoreProvider } from "../../component/table/store/data_table_store";
import { MenuLogic } from "../../logic/menu_logic";
import { WalletFlow } from "./view/walletFlow";

import { ShortMessage } from "./view/message/index";
import { NoteTemplate } from "./view/noteTemplate/file";
import { NoteTemplateFileProvider } from "./store/noteTemplate/file_store";
import { MessageAccount } from "./view/message/account";
import { MessageAccountFileProvider } from "./store/message/message_store";
// 降级规则
import { ReduceGrade } from "./view/reduceGrade/file";
import { FileStoreProvider as ReduceGradeStoreProvider } from "./store/reduceGrade/file_store";
// 会员等级变更记录
import{ GradeChangeLogsStoreProvider } from './store/gradeChangeLogs/file_store'
import { GradeChangeLogs } from "./view/gradeChangeLogs/file";
function Member() {
  const [menuLists, setData] = useState([]);
  useEffect(() => {
    (async function () {
      let r = await new MenuLogic().menuData();
      var arr = [];
      if (r && r.length > 0) {
        r = r.filter(function (v) {
          return !v.hidden;
        });
        for (let i = 0; i < r.length; i++) {
          if (r[i].path == "/member" && r[i].children) {
            arr = r[i].children.filter(function (v) {
              return !v.hidden;
            });
          }
        }
      }
      // arr.push({
      //   component:'ReduceGrade',
      //   viewPower: 'ReduceGrade',
      //   name:'会员等级降级规则'
      // })
      setData(arr);
    })();

    return () => {};
  }, []);
  return (
    // <Layout fullWidth={true}>
    <TabsStyle style="Full">
      <TabList>
        {/* <Tab>短信通知</Tab> */}
        {menuLists.map((v, i) => (
          <Tab key={i}>{v.name}</Tab>
        ))}
      </TabList>

      {/* <TabPanel>
        <TabsStyle style="BottomLine">
          <TabList>
            <Tab>
              <span>通知短信</span>
              <hr />
            </Tab>
            <Tab>
              <span>账户信息</span>
              <hr />
            </Tab>
            <hr />
          </TabList>
          <TabPanel>
            <NoteTemplateFileProvider>
              <DataTableStoreProvider>
                <NoteTemplate viewPower={"PRIV_MEMBERLABLE_VIEW"} />
              </DataTableStoreProvider>
            </NoteTemplateFileProvider>
          </TabPanel>
          <TabPanel>
            <MessageAccountFileProvider>
              <MessageAccount />
            </MessageAccountFileProvider>
          </TabPanel>
          <TabPanel>
            <DataTableStoreProvider>
              <ShortMessage viewPower={"PRIV_MEMBERLABLE_VIEW"} />
            </DataTableStoreProvider>
          </TabPanel>
        </TabsStyle>
      </TabPanel> */}
      {menuLists.map((v) =>
        v.component == "Dossier" ? (
          //会员档案
          <TabPanel>
            <FileStoreProvider>
              <DataTableStoreProvider>
                <Dossier viewPower={v.viewPower} />
              </DataTableStoreProvider>
            </FileStoreProvider>
          </TabPanel>
        ) : v.component == "Grade" ? (
          //会员等级
          <TabPanel>
            <GradeStoreProvider>
              <DataTableStoreProvider>
                <Grade viewPower={v.viewPower} />
              </DataTableStoreProvider>
            </GradeStoreProvider>
          </TabPanel>
        ) : v.component == "Stored" ? (
          //会员储值规则
          <TabPanel>
            <StoredStoreProvider>
              <DataTableStoreProvider>
                <Stored viewPower={v.viewPower} />
              </DataTableStoreProvider>
            </StoredStoreProvider>
          </TabPanel>
        ) : v.component == "Flow" ? (
          //会员储值流水
          <TabPanel>
            <FlowStoreProvider>
              <DataTableStoreProvider>
                <Flow viewPower={v.viewPower} />
              </DataTableStoreProvider>
            </FlowStoreProvider>
          </TabPanel>
        ) : v.component == "Integral" ? (
          //会员积分
          <TabPanel>
            <IntegralStoreProvider>
              <DataTableStoreProvider>
                <Integral viewPower={v.viewPower} />
              </DataTableStoreProvider>
            </IntegralStoreProvider>
          </TabPanel>
        ) : v.component == "WalletFlow" ? (
          //余额流水
          <TabPanel>
            <WalletFlow menu={v} component={v.component} />
          </TabPanel>
        ) : v.component == "Unit" ? (
          //会员标签
          <TabPanel>
            <UnitStoreProvider>
              <Unit action={unitAction} viewPower={v.viewPower}/>
            </UnitStoreProvider>
          </TabPanel>
        ) : v.component === 'GradeChangeLogs'?(
          <TabPanel>
            <GradeChangeLogsStoreProvider>
              <DataTableStoreProvider>
                <GradeChangeLogs  viewPower={v.viewPower}/>
              </DataTableStoreProvider>
            </GradeChangeLogsStoreProvider>
          </TabPanel>
        ):
         v.component == 'ReduceGrade'?( //降级规则
          <TabPanel>
            <ReduceGradeStoreProvider>
              <DataTableStoreProvider>
                <ReduceGrade viewPower={v.viewPower}/>
              </DataTableStoreProvider>
            </ReduceGradeStoreProvider>
          </TabPanel>
        ):(
          <TabPanel>
            <div></div>
          </TabPanel>
        )
      )}
    </TabsStyle>
    // </Layout>
  );
}
export default Member;
