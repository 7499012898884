import { message } from "antd";
import axios, { AxiosPromise } from "axios";
import Cookies from "js-cookie";
import { Tools } from "../../../config";
import { maxPageSize } from "../../../logic/common_logic";

export abstract class UnitBaseAction {
    protected abstract addUnit({ unitName }): AxiosPromise<any>;
    protected abstract updateUnit({ id, unitName, status, sort, remark }): AxiosPromise<any>;
    protected abstract deleteUnit({ unitId }): AxiosPromise<any>;
    protected abstract getList(): AxiosPromise<any>;
    protected abstract callbackText(params): string;

    async fetchListAction({ state, dispatch }) {
        const { data, status } = await this.getList();

        if (200 == status) {
            dispatch({ type: "INIT_LIST", payload: { dataList: [data.data.dataSource], callbackText: this.callbackText } })
        }

    }
    toggoleTipsAction({ rowindex, cellindex, dispatch }) {
        dispatch({ type: "TOGGOLE_TIPS", payload: { rowindex: rowindex, cellindex: cellindex } })
    }
    addCallAction({ dispatch, rowindex }) {
        dispatch({ type: "ADD_CELL", payload: { rowindex: rowindex } })
    }
    async saveCellAction({ rowindex, cellindex, dispatch, state, his }) {
        const firstData = state.dataList[rowindex].rows[0] ? state.dataList[rowindex].rows[0].rawData : {};
        const call = state.dataList[rowindex].rows[cellindex];
        const entranceValue = call.entranceValue;

        // 新增
        if (!call.rawData) {
            const { data, status } = await this.addUnit({ ...call, parentRawData: firstData });

            if (200 == status) {
                // 401 登录失效
                if (401 == data.statusCode) {
                    message.error("登录失效，请重新登录")
                    his.replace("/login")
                } else if (200 == data.statusCode) {
                    message.success("保存成功")

                    dispatch({ type: "SAVE_INPUT", payload: { rowindex: rowindex, cellindex: cellindex, rawData: data.data } })
                } else {
                    message.error(data.returnMessage)
                }
            }
        }
        // 更新
        else {
            const { data, status } = await this.updateUnit(call);

            if (200 == status) {
                // 401 登录失效
                if (401 == data.statusCode) {
                    message.error("登录失效，请重新登录")
                    his.replace("/login")
                } else if (200 == data.statusCode) {
                    message.success("更新成功")

                    dispatch({ type: "SAVE_INPUT", payload: { rowindex: rowindex, cellindex: cellindex, rawData: call.rawData } })
                } else {
                    message.error(data.returnMessage)
                }
            }
        }

    }
    cancelCallAction({ rowindex, cellindex, dispatch }) {
        dispatch({ type: "CANCEL_INPUT", payload: { rowindex: rowindex, cellindex: cellindex, } })
    }
    editAction({ rowindex, cellindex, dispatch }) {
        dispatch({ type: "TOGGOLE_EDIT", payload: { rowindex: rowindex, cellindex: cellindex, } })
    }
    async deleteAction({ rowindex, cellindex, dispatch, state }) {
        const call = state.dataList[rowindex].rows[cellindex];

        const { data, status } = await this.deleteUnit(call);

        if (200 == status) {
            if (200 == data.statusCode) {
                message.success("删除成功")

                dispatch({ type: "TOGGOLE_DELETE", payload: { rowindex: rowindex, cellindex: cellindex, } })
            } else {
                message.error(data.returnMessage)
            }
        }


    }
    inputTextAction({ rowindex, cellindex, dispatch, value }) {
        dispatch({ type: "INPUT_TEXT", payload: { rowindex: rowindex, cellindex: cellindex, value: value } })
    }
    toggoleTextAction({ rowindex, cellindex, dispatch }) {
        dispatch({ type: "TOGGOLE_TEXT", payload: { rowindex: rowindex, cellindex: cellindex } })
    }
    addRow({ dispatch }) {
        dispatch({ type: "ADD_ROW", payload: {} })
    }
    async delRowAction({ dispatch, rowindex, state }) {
        const call = state.dataList[rowindex].rows[0];

        const { data, status } = await this.deleteUnit(call);

        if (200 == status) {
            if (200 == data.statusCode) {
                message.success("删除成功")

                dispatch({ type: "DELETE_ROW_ACTION", payload: { rowindex: rowindex } })
            } else {
                message.error(data.message)
            }
        }
    }
}

class UnitAction extends UnitBaseAction {
    protected callbackText(params: any): string {
        return params.labelName;
    }
    protected getList() {
        return axios({
            method: "POST",
            url: Tools.getApiAddress("menber_tag_list"),
            data: {
                labelName: '',
                pageIndex: 1,
                pageSize: maxPageSize(),
                orderBy: '',
                sort: ''
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })
    }
    protected addUnit(params) {
        return axios({
            method: "POST",
            url: Tools.getApiAddress("menber_tag_add"),
            data: {
                labelName: params.entranceValue,
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })
    }
    protected updateUnit(params) {
        return axios({
            method: "POST",
            url: Tools.getApiAddress("menber_tag_update"),
            data: {
                id: params.rawData.id,
                labelName: params.entranceValue
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })
    }
    protected deleteUnit(params) {
        return axios({
            method: "GET",
            url: Tools.getApiAddress("menber_tag_delete"),
            params: {
                id: params.rawData.id,
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })
    }
}

export const unitAction = new UnitAction();