import { useContext, useEffect, useRef } from "react"
import { DataList } from "../../../component/data-list"
import { autoRem } from "../../../global.style"
import { FileStore } from "../store"
import {ITitle} from './pie'
class IParam{
    divStyles?:{}
    title?: ITitle
    data: any[]
    dataX?:any[]
    name?:string 
}
declare var echarts: any;
export function Line({name,title,divStyles,data}:IParam){
  const {state,dispatch} = useContext(FileStore)
    const lineRef = useRef(null)
    let width = document.body.clientWidth - 879 - 130 -24
    // console.log(document.body.clientWidth)
    useEffect(()=>{
     

     var dom = lineRef.current;
     var myChart = echarts.init(dom);
     let dataS = []
     let lengdText = []
     if(data.length>0){
      data.map(item=>{  
        let textArr =    item instanceof Array? item.map(v=>v.text):[] 
        lengdText.push(textArr[0])
      // console.log(textArr[0])

        let name = textArr.length>0?textArr[0]:''
        dataS.push({
          symbolSize:10,
          name: name,
          type:'line',
          stack: 'Total',
          // smooth:true,
          data: item instanceof Array? item.map(v=>v.value):[]
        })
      })
     }
     var option;
        option = {
            title: {...title,left:50,textStyle:{
                color:title.color?title.color:'#333',
                fontSize:title.fontSize?title.fontSize:'18'
            }},
            tooltip: {
              type:'axis',
              formatter: function (params) {
                // console.log(params)
                let arr = data[0].map(v=>v.name)
                // do some thing
                let str = params.seriesName=='新增会员'?'数量：':'金额：'
                return  params.seriesName+'<br/>日期：' +arr[params.dataIndex]+'<br/>'+str + params.value
             }
            },
            legend: {
                // show: false,
              data: lengdText
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
           
            xAxis: {
              // name: '月',
              type: 'category',
              boundaryGap: false,
              data: data[0].map(v=>v.name)
            },
            yAxis: {type:'value'},
            series: dataS
          };
          option && myChart.setOption(option);
          return(()=>{
    
        })
       },[state.condition.dateType,state.condition.startTime,data,title])
       const styles = divStyles?divStyles:{height:'500px',width: `100%`}
    return <div ref={lineRef} style={{...styles,background:'#fff',margin:'4.5rem 0'}} ></div>
}