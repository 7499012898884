import styles from "./../style/goods.module.scss";
import { useContext, useState, useEffect } from "react";
import {
  Space,
  Input,
  Radio,
  Modal,
  Select,
  Table,
  Button,
  message,
} from "antd";
import { TopicDataAction } from "../action/TopicDataAction";
import { ITopicValue, TopicStore } from "../store/topic";
import { ChooseGoods } from "../../../component/choose-goods";
import { Link } from "react-router-dom";
import { ColumnsType } from "antd/lib/table";
import { topicActions } from "../action/topic_ation";
import { useBoolean } from "ahooks";
import { Tools } from "../../../config";
import img_120_120 from "../../../assets/images/mini/group_120_120.png";
import gwc from "../../../assets/images/mini/goods-cart.png";
interface IData {
  layout: number;
  showIcon: boolean;
  showName: boolean;
  showPrice: boolean;
  datalist: any[];
}
export function Goods({ id, con }) {
  const topicStoreState = useContext(TopicStore);
  const action = topicActions;
  const [value, setValue] = useState(1);
  const [list, setList] = useState(null);
  function unique(arr) {
    let result = {};
    let finalResult = [];
    for (let i = 0; i < arr.length; i++) {
      result[arr[i].id] = arr[i];
    }
    //现在result内部都是不重复的对象了，只需要将其键值取出来转为数组即可
    for (let item in result) {
      finalResult.push(result[item]);
    }
    return finalResult;
  }
  const handleChange = (e) => {
    console.log(e);
    setValue(e.target.value);
    data.layout = e.target.value;
    TopicDataAction.setData<IData>(...actionArg, data);
  };
  // 弹窗
  const [isModalVisible, isModalFunc] = useBoolean(false);
  const showModal = () => {
    isModalFunc.setTrue();
  };
  const handleOk = () => {
    isModalFunc.setFalse();
  };
  const handleCancel = () => {
    isModalFunc.setFalse();
  };
  //   初始化数据
  let goodslist = [];

  for (let i = 0; i < 4; i++) {
    goodslist.push({
      img: img_120_120,
      goodsName: "标题",
      storePrice: 188,
      unitName: "个",
      goodsSource: 1,
    });
  }
  const initData = {
    layout: 5,
    showIcon: true,
    showName: true,
    showPrice: true,
    datalist: [],
  };
  const del = (index) => {
    data.datalist.splice(index, 1);
    localStorage.removeItem("goods");
    TopicDataAction.setData<IData>(...actionArg, data);
  };
  let actionArg: [ITopicValue, number] = [topicStoreState, id];
  const data: IData = TopicDataAction.getData<IData>(...actionArg);
  useEffect(() => {
    if (!data) {
      TopicDataAction.setData<IData>(...actionArg, initData);
    }
  }, []);
  if (!data) {
    return <div></div>;
  }
  //注册数据提取方法
  TopicDataAction.setEF(topicStoreState, id, () => {
    return new Promise((r, e) => {
      if (data.datalist.length == 0) {
        message.error("没有选择商品");
      } else {
        r(data);
      }
    });
  });
  if (con == "show") {
    let flag = data && data.datalist.length > 0;
    let value = data.layout;
    return (
      <div className={styles.container}>
        <div className={styles.goods_list}>
          {value == 1 ? (
            <SmallList list={flag ? data.datalist : goodslist} />
          ) : value == 2 ? (
            <BigList list={flag ? data.datalist : goodslist} />
          ) : value == 3 ? (
            <OneBigTwoSmall list={flag ? data.datalist : goodslist} />
          ) : value == 4 ? (
            <List list={flag ? data.datalist : goodslist} />
          ) : value == 5 ? (
            <TwoList list={flag ? data.datalist : goodslist} />
          ) : null}
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className={styles.ctrl} id="search_ctrl_selected">
          <h2>商品设置</h2>
          <div style={{ padding: "20px 0", borderTop: "1px solid #eee" }}>
            <div className={styles.formitems}>
              <span className={styles.formitems_name}>显示方式：</span>
              <div className={styles.formitems_cols}>
                {/* <div className={styles.input_radio}>
                  <input
                    type="radio"
                    name="layout"
                    value={1}
                    id="layout1"
                    checked={data.layout == 1 ? true : false}
                    onChange={handleChange}
                  />
                  <label htmlFor="layout1">小图</label>
                </div> */}
                <div className={styles.input_radio}>
                  <input
                    type="radio"
                    name="layout"
                    value={2}
                    id="layout2"
                    checked={data.layout == 2 ? true : false}
                    onChange={handleChange}
                  />
                  <label htmlFor="layout2">大图</label>
                </div>
                <div className={styles.input_radio}>
                  <input
                    type="radio"
                    name="layout"
                    value={3}
                    id="layout3"
                    checked={data.layout == 3 ? true : false}
                    onChange={handleChange}
                  />
                  <label htmlFor="layout3">一大两</label>
                </div>
                <div className={styles.input_radio}>
                  <input
                    type="radio"
                    name="layout"
                    value={4}
                    id="layout4"
                    checked={data.layout == 4 ? true : false}
                    onChange={handleChange}
                  />
                  <label htmlFor="layout4">列表</label>
                </div>
                <div className={styles.input_radio}>
                  <input
                    type="radio"
                    name="layout"
                    value={5}
                    id="layout5"
                    checked={data.layout == 5 ? true : false}
                    onChange={handleChange}
                  />
                  <label htmlFor="layout5">双列有标题</label>
                </div>
              </div>
            </div>
            <div className={styles.formitems} style={{ marginTop: 15 }}>
              <span className={styles.formitems_name}>选择商品：</span>
              <div className={styles.formitems_cols}>
                <ul>
                  {data &&
                    data.datalist &&
                    data.datalist.map((good, index) => {
                      return (
                        <li className={styles.img_list} key={index}>
                          <img src={Tools.getImageAddress(good.goodsImage,good.goodsSource)} />
                          <span
                            className={styles.img_list_btndel}
                            onClick={() => del(index)}
                          >
                            删
                          </span>
                        </li>
                      );
                    })}
                  <li
                    className={styles.img_list_add}
                    style={{ marginLeft: 0 }}
                    onClick={() => showModal()}
                  >
                    +
                  </li>

                  <ChooseGoods
                    visibleModal={isModalVisible}
                    filter={{
                      isStore: true,
                      checkInvcount: true,
                      source: "WX",
                    }}
                    close={(status, r) => {
                      if (r) {
                        let arr = data.datalist || [];
                        console.log(r);
                        r.length &&
                          r.map((item) => {
                            if (arr.every((v, i) => v.id != item.id)) {
                              let spec = item.goods_SpecificationList[0]
                              if(spec){item['storePrice'] = spec.storePrice || 0}
                              arr.push(item);
                            }
                          });
                        data.datalist = arr;
                        TopicDataAction.setData<IData>(...actionArg, data);
                        // localStorage.removeItem('goods')
                      }
                      isModalFunc.setFalse();
                    }}
                  />
                  {/* </Modal> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
function Content({ good, isTitle }) {
  let spec = good.goods_SpecificationList
    ? good.goods_SpecificationList[0]
    : null;
  return (
    <>
      <img
        src={
          good.goodsImage ? Tools.getImageAddress(good.goodsImage,good.goodsSource) : good.img
        }
        style={{ width: "100%" }}
      />
      <div className={styles.good_title_price}>
        {isTitle ? (
          <span className={styles.title}>{good.goodsName}</span>
        ) : null}
        {spec && spec.lineationPrice > 0 ? (
          <span style={{ color: "red", textDecoration: "line-through" }}>
            ￥{spec.lineationPrice}
          </span>
        ) : null}
        <div
          className={styles.price}
          style={{
            padding: "5px 0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span style={{ color: "red" }}>￥{good.storePrice}</span>
          {/* {good.storePriceMin < good.storePriceMax ? (
            <span style={{ color: "red" }}>
              ￥{good.storePriceMin}~{good.storePriceMax}
            </span>
          ) : (
            <span style={{ color: "red" }}>
              ￥
              {good.storePriceMax
                ? good.storePriceMax
                : spec
                ? spec.storePrice
                : null}
              /{good.unitName}
            </span>
          )} */}
          {/* <i></i> */}
          <img
            src={gwc}
            style={{ width: "23px", height: "23px" }}
            className={styles.gwche}
            alt="gwc"
          />
        </div>
      </div>
    </>
  );
}

function BigList({ list }) {
  return (
    <ul>
      {list.length > 0 &&
        list.map((good, index) => {
          return (
            <li style={{ width: "100%", margin: "10px 0" }} key={index}>
              <Content good={good} isTitle={true} />
            </li>
          );
        })}
    </ul>
  );
}
const SmallList = ({ list }) => (
  <ul
    style={{
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      flexWrap: "wrap",
    }}
  >
    {list &&
      list.map((good, index) => {
        return (
          <li className={styles.smalllist} key={index}>
            <Content good={good} isTitle={false} />
          </li>
        );
      })}
  </ul>
);
const OneBigTwoSmall = ({ list }) => {
  return (
    <ul>
      {list.length > 0 &&
        list.map((good, index) => {
          return (
            <>
              {(index + 1) % 3 == 2 ? (
                <li
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    background: "#eee",
                  }}
                  key={index}
                >
                  <div className={styles.small_one}>
                    {" "}
                    <Content good={good} isTitle={true} />
                  </div>
                  {index + 1 < list.length ? (
                    <div className={styles.small_one}>
                      <Content good={list[index + 1]} isTitle={true} />
                    </div>
                  ) : null}
                </li>
              ) : (index + 1) % 3 == 2 ? null : (index + 1) % 3 == 1 ? (
                <li>
                  <Content good={good} isTitle={true} />
                </li>
              ) : null}
            </>
          );
        })}
    </ul>
  );
};
const List = ({ list }) => (
  <ul>
    {list.length > 0 &&
      list.map((good, index) => {
        return (
          <li className={styles.list} key={index}>
            <Content good={good} isTitle={true} />
          </li>
        );
      })}
  </ul>
);
const TwoList = ({ list }) => {
  return (
    <ul
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        flexWrap: "wrap",
      }}
    >
      {list.length > 0 &&
        list.map((good, index) => {
          return (
            <li
              style={{
                width: "48%",
                margin: "5px 0",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
              key={index}
            >
              <Content good={good} isTitle={true} />
            </li>
          );
        })}
    </ul>
  );
};
