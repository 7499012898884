// import styles from "../style/form.module.scss";
import { Form as AntdForm, Input } from "antd";
import React, { useState } from "react";
import { useRequest } from "ahooks";
import { useHistory } from "react-router-dom";
import { Button } from "../../../component/button";
import { fetchCodeAction, fetchLoginAction } from "../action/form_action";
import styles from "../../login/style/form.module.scss";


export function Form() {
    const [state, setData] = useState({ account: "", passwd: "", code: "", inputCode: "", rawCode: "" });
    useRequest(() => fetchCodeAction(state, setData))
    const his = useHistory();
    return (
        <div className={styles.root}>
            <AntdForm onFinish={() => fetchLoginAction(state, setData, his)}>
                <AntdForm.Item name="username" style={{ marginTop: '15px' }} rules={[{ required: true, message: "请输入账号" }]}>
                    <Input autoComplete="off" prefix={<i className={styles.account} style={{ height: 27 }}></i>} placeholder="请输入账号" onInput={(e) => setData({ ...state, account: e.currentTarget.value })} />
                </AntdForm.Item>

                <AntdForm.Item name="passworld" rules={[{ required: true, message: "请输入密码" }]}>
                    <Input type="password" autoComplete="off" prefix={<i className={styles.passwd} style={{ height: 27 }}></i>} placeholder="请输入密码" onInput={(e) => setData({ ...state, passwd: e.currentTarget.value })} />
                </AntdForm.Item>

                <div className={styles.item_group}>

                    <AntdForm.Item name="code" rules={[{ required: true, message: "请输入验证码" }]}>
                        <Input autoComplete="off" prefix={<i className={styles.safe} style={{ height: 27 }}></i>} placeholder="请输入验证码" onInput={(e) => setData({ ...state, inputCode: e.currentTarget.value })} maxLength={4} />
                    </AntdForm.Item>

                    <AntdForm.Item className={styles.code}>
                        <img src={state.code} onClick={() => fetchCodeAction(state, setData)} />
                    </AntdForm.Item>

                    <div style={{ clear: "both" }}></div>
                </div>

                <AntdForm.Item className="sure" style={{ marginBottom: 0, marginTop: ".8rem" }}>
                    <Button color="Green" htmlType="submit" >立即登录</Button>
                </AntdForm.Item>

                {/* <AntdForm.Item style={{ marginBottom: 5, marginTop: ".15rem" }} name="protocol">
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                    }}>
                        <span style={{ fontSize: 14, color: "#666666" }}>
                            没有账号前往
                            <span style={{
                                color: "#ff746d",
                                cursor: "pointer",
                                marginLeft: 5,
                            }} onClick={() => his.replace("register")}>注册</span>
                        </span>
                        <span style={{ color: "#ff746d", float: "right" }} onClick={() => his.replace("forgot")}>
                            <span style={{ fontSize: 14, color: "#666666", cursor: "pointer" }}>忘记密码？</span>
                        </span>
                    </div>
                </AntdForm.Item> */}
            </AntdForm>
        </div>
    )
}