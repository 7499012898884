import { message } from "antd";
// import axios from "axios";
import Cookies from "js-cookie";
import { DataTableAction } from "../../../../component/table/action/data_table_action";
import { Tools, axios } from "../../../../config";
import { GradeLogic } from "../../../../logic/grade_logic";

export class FileAction extends DataTableAction {
    async delRow({ dispatch, rowItem }) {
        const r = await axios({
            method: "GET",
            url: Tools.getApiAddress("menber_grade_delete"),
            params: {
                id: rowItem.id.id
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })

        if (r.data.statusCode == 200) {
            message.success(`【${rowItem.id.gradeName}】 删除成功`)
        }else{
            message.error(`【等级：${rowItem.id.gradeName}】${r.data.returnMessage}`)
        }
    }
    delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any}) {
        selectList.map(async ({ id, gradeName}) => {
            const r = await axios({
                method: "GET",
                url: Tools.getApiAddress("menber_grade_delete"),
                params: {
                    id: id
                },
                headers: {
                    SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
                },
            })

            if (r.data.statusCode == 200) {
                message.success("删除成功")
            }else{
                message.error(`【${gradeName}】${r.data.returnMessage}`)
            }
        })
        // this.loadPage({ dispatch, pageIndex, pageSize, search})
    }
    async loadPage({ dispatch, pageIndex, pageSize, search }) {
        this.setLoading({dispatch, loading:true})

        const { keywords } = search;
        let param = {
            gradeName: keywords,
            pageIndex: 1,
            pageSize,
            orderBy: '',
            sort: ''
        }
        // 等级列表
        const r = await new GradeLogic().querysList(param);

        if (r.data.statusCode == 200) {
            const dataList = r.data.data.dataSource;

            const list = dataList.map((v, i) => {
                v.key = i + 1;
                return v;
            })

            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: list, pageSize, loading: false });
        } else{
            this.setLoading({dispatch, loading: false})
        }

    }
    setCondition(method: "Keywords", value, dispatch) {

        if ("Keywords" == method) {
            dispatch({ type: "SetSearch", payload: { keywords: value } });
        }
    }
}
export const fileAction = new FileAction();