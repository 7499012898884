// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../config";
import { effeative } from "./common_logic";
export class IntegralRuleLogic{
    /**编辑保存积分规则 */
    async save(params) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("member_integral_rule_set"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
     /**查看积分规则 */
     async getInfo() {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("member_integral_rule_view"),
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
}