// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../config";
import { effeative } from "./common_logic";

export class UserRoleLogic {
    async querysList(roleId) {
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("userrole_list"),
            params: {
                roleId: roleId
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    async addData(params) {
        return effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("userrole_add"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))

    }
    async searchData(roleId) {
        return effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("userrole_search"),
            params: {
                roleId:roleId
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))

    }
    async updData(params) {
        return effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("menber_grade_update"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
    }

}