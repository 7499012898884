import { Checkbox, Form as AntdForm, Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useBoolean, useRequest } from "ahooks";
import { useHistory } from "react-router-dom";
import { Button } from "../../../component/button";
import { fetchCodeAction, fetchLoginAction } from "../action/form_action";
import styles from "../../login/style/form.module.scss";
import { message, Button as ButtonAnt } from "antd";
import axios from "axios";
import { ConfigApi, Tools } from "../../../config";
import { FuncLock, SetInterval } from "stb-tools";
import { AgreementLogin } from "../../../logic/agreement_login";

// 发送短信验证码
export async function getSendCodeStatus(stateTime, state, fun) {
  const { account, passwd, inputCode, rawCode } = state;
  if (state.account == "" || state.account == undefined) {
    message.error("请输入账号");
    return false;
  }
  if (state.passwd == "" || state.passwd == undefined) {
    message.error("请输入密码");
    return false;
  }
  if (state.inputCode == "" || state.inputCode == undefined) {
    message.error("请输入图形码");
    return false;
  }
  let time = 60;
  if (stateTime != "重新获取" && stateTime != "获取验证码") {
    return false;
  }
  const { data, status } = await axios({
    method: "GET",
    url: Tools.getApiAddress("phone_code"),
    params: {
      PhoneNumber: account,
      Uuid: rawCode.captchaGUID,
      Code: inputCode,
      CheckID: true,
    },
  });
  if (200 == status) {
    if (200 != data.statusCode) {
      message.error(data.data ? data.data : data.returnMessage);
      return false;
    } else {
      message.success("发送成功");
    }
  }
  // 倒计时
  let countDown = setInterval(() => {
    time = time - 1;
    if (time == 0) {
      clearInterval(countDown);
      fun("重新获取");
    } else {
      fun(time + "秒");
    }
  }, 1000);
}
//一个以秒为单位的计时器
const t = new SetInterval(1000);
function Protocol({ close }) {
  const [time, setTime] = useState(5);
  const [agreementContent, setAgreementContent] = useState<any>({});

  useEffect(() => {
    (async function () {
      //获取协议内容
      AgreementLogin.getRegister().then((data) => {
        setAgreementContent(data);
      });
    })();

    setTime(5);

    return () => {};
  }, []);

  useEffect(() => {
    t.enable(() => {
      if (time >= 1) {
        setTime(time - 1);
      } else {
        t.clear();
      }
    });

    return () => {
      t.clear();
    };
  }, [time]);

  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: agreementContent.Content }}></div>
      <div style={{ textAlign: "center" }}>
        {time ? (
          <ButtonAnt type="primary" disabled>{`剩余${time}秒`}</ButtonAnt>
        ) : null}
        {!time ? (
          <ButtonAnt type="primary" onClick={close}>
            已经阅读
          </ButtonAnt>
        ) : null}
      </div>
    </div>
  );
}

export function Form() {
  const [state, setData] = useState({
    account: "",
    passwd: "",
    code: "",
    inputCode: "",
    rawCode: "",
    phoneCode: "",
    isCheck: false,
  });
  const [isModalVisible, isModalFunc] = useBoolean(false);
  const [isProtocol, setIsProtocol] = useState(false);

  useRequest(() => fetchCodeAction(state, setData));
  const [stateTime, setTimeData] = useState("获取验证码");
  const his = useHistory();

  return (
    <div className={styles.root}>
      <AntdForm onFinish={() => fetchLoginAction(state, setData, his)}>
        <AntdForm.Item
          name="username"
          rules={[
            { required: true, message: "请输入账号" },
            // {
            //   pattern:
            //     /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            //   message: "请输入正确的手机号",
            // },
          ]}
        >
          <Input
            maxLength={20}
            autoComplete="off"
            prefix={<i className={styles.phone} style={{ height: 27 }}></i>}
            placeholder="请输入账号"
            onInput={(e) =>
              setData({ ...state, account: e.currentTarget.value })
            }
          />
        </AntdForm.Item>

        <AntdForm.Item
          name="passworld"
          rules={[{ required: true, message: "请输入密码" }]}
        >
          <Input
            maxLength={20}
            autoComplete="off"
            type="password"
            prefix={<i className={styles.passwd} style={{ height: 27 }}></i>}
            placeholder="请输入密码"
            onInput={(e) =>
              setData({ ...state, passwd: e.currentTarget.value })
            }
          />
        </AntdForm.Item>

        <div className={styles.item_group}>
          <AntdForm.Item
            name="graphCode"
            rules={[{ required: true, message: "请输入图形码" }]}
          >
            <Input
              autoComplete="off"
              prefix={<i className={styles.safe} style={{ height: 27 }}></i>}
              placeholder="请输入图形码"
              onInput={(e) =>
                setData({ ...state, inputCode: e.currentTarget.value })
              }
              maxLength={4}
            />
          </AntdForm.Item>

          <AntdForm.Item className={styles.code} style={{ marginBottom: 0 }}>
            <img
              src={state.code}
              onClick={() => fetchCodeAction(state, setData)}
            />
          </AntdForm.Item>

          <div style={{ clear: "both" }}></div>
        </div>

        <div className={styles.item_group} style={{ marginBottom: 0 }}>
          <AntdForm.Item style={{ marginBottom: 5 }} name="code">
            <Input
              autoComplete="off"
              prefix={<i className={styles.phone} style={{ height: 27 }}></i>}
              placeholder="请输入验证码"
              onInput={(e) =>
                setData({ ...state, phoneCode: e.currentTarget.value })
              }
              maxLength={6}
            />
          </AntdForm.Item>

          <div
            style={{ marginBottom: 0, lineHeight: "36px" }}
            className={styles.btn_code}
            onClick={async () => {
              const r = await getSendCodeStatus(stateTime, state, (time) => {
                setTimeData(time);
              });
              //   if (false == r) fetchCodeAction(state, setData);
            }}
          >
            <span>{stateTime}</span>
          </div>

          <div style={{ clear: "both" }}></div>
        </div>

        <AntdForm.Item
          className={styles.protocol}
          style={{ marginBottom: 0, marginTop: "5px2" }}
          name="protocol"
        >
          <Checkbox
            style={{ fontSize: 14, color: "#666666" }}
            checked={state.isCheck}
            onChange={(v) => {
              //可以直接取消但不能直接选择
              if (state.isCheck) {
                setData({
                  ...state,
                  isCheck: false,
                });
              } else {
                isModalFunc.setTrue();
              }
            }}
          >
            我已阅读并同意
          </Checkbox>
          <span
            style={{ color: "#ff746d", cursor: "pointer" }}
            onClick={() => isModalFunc.setTrue()}
          >
            《用户协议》
          </span>
        </AntdForm.Item>

        <AntdForm.Item className="sure" style={{ marginBottom: 0 }}>
          <Button color="Red" htmlType="submit">
            注册
          </Button>
        </AntdForm.Item>

        <AntdForm.Item style={{ marginBottom: 5 }} name="protocol">
          <span style={{ fontSize: 14, color: "#666666" }}>已有账号直接</span>{" "}
          <a
            href="#"
            style={{ color: "#ff746d" }}
            onClick={() => his.replace("login")}
          >
            登录
          </a>
        </AntdForm.Item>
      </AntdForm>

      {
        <Modal
          maskClosable={false}
          className={styles.user_protocol}
          title="用户协议"
          visible={isModalVisible}
          onOk={() => isModalFunc.setFalse()}
          onCancel={() => isModalFunc.setFalse()}
          centered
          width={680}
        >
          <Protocol
            close={() => {
              isModalFunc.setFalse();
              setData({
                ...state,
                isCheck: true,
              });
            }}
          />
        </Modal>
      }
    </div>
  );
}
