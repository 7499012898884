import { DataTableAction } from "../../../../component/table/action/data_table_action";
import { AdLogin } from "../../../../logic/ad_login";

export class TableAction extends DataTableAction {
    /** 删除列表 */
    delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {
        //
    }

    /**  查询数据 */
    queryData({ dispatch, name, isEnable, pageIndex, pageSize }) {
        AdLogin.QueryPages({
            name: name,
            isEnable: isEnable,
            pageIndex: pageIndex,
            pageSize: pageSize,
        }).then(({ data }) => {
            //console.log(data);
            this.setPaging({
                dispatch,
                pageIndex: pageIndex,
                total: data.totalCount,
                pageSize: pageSize,
                dataList: data.dataSource,
            });
        });
    }
}

export const tableAction = new TableAction();