import { message } from "antd";
import { defualtPageSize } from "../../../../logic/common_logic";
import { GoodsLogic } from "../../../../logic/goods_logic";
import { FullgiftLogic } from "../../../../logic/fullgift_logic";
import { ShopLogic } from "../../../../logic/shop_logic";
import { UnitLogic } from "../../../../logic/unit_logic";
import { UserLogic } from "../../../../logic/user_logic";
import { GradeLogic } from "../../../../logic/grade_logic";
import { ClassesLogic } from "../../../../logic/classes_logic";
import { PrecisionLogic } from "../../../../logic/precision_logic";
import { TagLogic } from '../../../../logic/tag_logic'
import Paragraph from "antd/lib/skeleton/Paragraph";
import { CouponLogic } from "../../../../logic/coupon_logic";
type Init = {
    state;
    dispatch;
    form;
    id: string;
    method: string;
}
interface InitBasic {
    dispatch;
    search;
}
interface InitEdit {
    state;
    dispatch;
    form;
    id;
}
interface ISetValue {
    attribute: any;
    value: string | number | any[];
    dispatch;
}

class PrecisionFormAction {

    private GradeLogic = new GradeLogic();
    private shopLgc = new ShopLogic();

    async init({ state, dispatch, id, method, form }: Init) {

        await this.initBasic({ dispatch, search: state.search });
        if ("Create" !== method && id) {
            await this.initEdit({ dispatch, state, id, form })
        }
    }
    private async initBasic({ search, dispatch }: InitBasic) {
        this.clearTableList({ dispatch });
        dispatch({ type: "LoadTag", payload: await new TagLogic().querysList() });
        dispatch({ type: "LoadGrade", payload: await new GradeLogic().gradeList() });
        // 用户信息
        {
            const r = await new UserLogic().selData();
         

            // dispatch()

           
        }
        // 营销活动搜索
        {
            const { keywords} = search;

            const params = {
                pageIndex: 1, pageSize: defualtPageSize(), seacherKey: keywords
            }
           

            const r = await new PrecisionLogic().selList(params);

            if (r.data.statusCode == 200) {
                const dataList = r.data.data.dataSource;             

                dispatch({ type: "SetSearchList", payload: dataList });

            }
        }


    }
    private async initEdit({ dispatch, id, form }: InitEdit) {
        // 回填状态
        const r = await (await new ShopLogic().selListpost()).data.data.dataSource;
        const tags = await new TagLogic().querysList() 
        const grades = await new GradeLogic().gradeList()
        const item = await new FullgiftLogic().selDataByWarehousing(id);
        const params = {
            pageIndex: 1, pageSize: 20, goodsBrandID: '', goodsClassesID: '', goodsType: '', status: '', goodsNameOrNo: ''
        }
        const shopList = await (await new GoodsLogic().selList(params)).data.data.dataSource;


        const p = await new PrecisionLogic().selData(id);
        if(!p){
            message.error('获取信息失败')
            return false;
        }
        let couponid = p.giveConpouVal;
        

        const param: any = {
            "pageIndex": 1,
            "pageSize": 999,
            "couponName":'',
            "couponType":'',
            "status":'',
            "orderBy":'',
            "sort":'',
        }
      
       


        
        // let  shopList  = []
       p['couponList'] = p.coupon;
      this.addCouponTableItem({dispatch,payload:item.coupon})
        let arr = []
        if(p.precisionSetting.lenght>0){
            p.precisionSetting.map(item=>{
                if(item.wType ==1){
                // shopList = await (await new GoodsLogic().selList(params)).data.data.dataSource;
                }
                else{

                }
            })
        }
        if(p.givePoints!=null){
            p['isGivePoints'] = true
        }
        interface p3{
            wtype:number,
            wid:any[]
        }
        let rr= [];
        let grade = [];
        let precision1 ;
        let precision2;
        let precision3:p3;
        let precision4:p3;
        let member = [];
        let outMember = []
         if(p.precisionUser.length>0){
            p.precisionUser.forEach((precision,i)=>{
                if(precision.wtype ==1){                    
                    rr.push(precision.wid)
                }
                if(precision.wtype ==2){
                    grade.push(precision.wid)
                }
                if(precision.wtype == 3){
                     member.push(precision.wid);
                }
                else if( precision.wtype == 4){
                    outMember.push( precision.wid);
                }
            })

            // p.precisionUser.forEach(precision=>{
            //     if(precision.wtype ==1){   
            //         precision1 = {wtype:1,wid:arr}
            //       }
            //       if(precision.wtype ==2){
            //         precision2 = {wtype:2,wid:grade}
            //     }
                
            // })
            for(let i=0;i<p.precisionUser.length;i++){
                if(p.precisionUser[i].wtype ==1){
                    precision1 = {wtype:1,wid:rr}
                    continue;
                }
                if(p.precisionUser[i].wtype ==2){
                    precision2 = {wtype:2,wid:grade}
                    continue;
                }
                if(p.precisionUser[i].wtype ==3){
                    precision3 = {wtype:3,wid:member}
                    continue;
                }
                if(p.precisionUser[i].wtype ==4){
                    precision4 = {wtype:4,wid:outMember}
                    continue;
                }
            }
        }
        let  precisionResult=[];
        if(precision1) {precisionResult.push(precision1); }
         if(precision2) {precisionResult.push(precision2); }
         if(precision3) {precisionResult.push(precision3); p['memberList'] = precision3.wid}
         if(precision4){precisionResult.push(precision4);p['OutMemberList'] = precision4.wid}

        let names= []
        if(tags&&tags.length>0){
            tags.forEach((tag,i)=>{
                if(rr.length>0){
                    rr.map(r=>{
                       if( r == tag.id){
                        names.push(tag.labelName)
                       }
                    })
                }
            })
        }
        let gradeList = [];
        if(grades&&grades.length>0){
            grades.forEach((g,i)=>{
                if(grade.length>0){
                    grade.map(r=>{
                       if( r == g.id){
                        gradeList.push(g.gradeName)
                       }
                    })
                }
            })
        }
        p['tagList'] = names
        p['gradeList'] = gradeList
       
             
       p['precisionUser'] = precisionResult;
        // p['precisionSettings'] = p.precisionSetting        

        let shop = [];
        let channel = [];
     if(p.precisionSetting &&[...p.precisionSetting].length>0){
            p.precisionSetting.forEach(pp=>{
                if(pp.wType == 1){
                    shop.push(pp.wid)
                }
                if(pp.wType == 2){
                    channel.push(pp.wid)
                }
            })
        }
        let shopli;
        let channelList;

     for(let i=0;i<p.precisionSetting.length;i++){
            if(p.precisionSetting[i].wType ==1){
                shopli = {wType:1,wid:shop}
                continue;
            }
            if(p.precisionSetting[i].wType == 2){
                channelList = {wType:2,wid: channel}
                continue;
            }
        }
        let res = [];
        if(shopli) {res.push(shopli);p['shopList'] = [...shop]}
        if(channelList) res.push(channelList)
       p['precisionSettings'] = res;
      
        dispatch({ type: "SetForm", payload: {p} });
    }

    
    // 提交活动
    async addSubmit({ state, status }) {
        const {formData} = state;
     
        // "actionName": "string",
        // "actionDescription": "string",
        // "taskType": 0,
        // "beginDate": "2021-08-13T06:42:53.776Z",
        // "endDate": "2021-08-13T06:42:53.776Z",
        // "scenarioType": 0,
        // "rechargeMinMoney": 0,
        // "rechargeMaxMoney": 0,
        // "givePoints": 0,
        // "isGiveConpou": true,
        // "giveConpouVal": "string",
        // "precisionUser": [
        //   {
        //     "wtype": 0,
        //     "wid": "string"
        //   }
        // ],
        // "precisionSettings": [
        //   {
        //     "wType": 0,
        //     "wid": "string"
        //   }
        // ]
        if(formData.actionName == undefined|| formData.actionName == ''){
            message.error('活动名称不能为空')
            return false;
        }
        if(formData.actionDescription == undefined|| formData.actionDescription == ''){
            message.error('活动描述不能为空')
            return false;
        }

        if(formData.taskType ==2){
            if(formData.beginDate == undefined|| formData.beginDate == ''||formData.endDate == undefined||formData.endDate == ''){
                message.error('请填写有效时间')
                return false;
            }
        }
        if(formData.channelType == 1){
            if(formData.shopList==undefined||formData.shopList.length == 0){
                message.error('请选择门店')
                return false;
            }
        }
        if(formData.channelType == 2){
            if(state.precisionSettings.filter(item=>{
                return item.wid=='POS'||item.wid == 'Shopp'
            }).length == 0){
                message.error("请选择渠道")
                return false
            }
        }
        if(formData.isGivePoints){
            if(formData.givePoints == null || formData.givePoints == undefined || formData.givePoints =='') {
                message.error('请输入赠送的积分值')
                return false;
            }
            else if(formData.givePoints<=0 || !(/^[1-9]\d*$/).test(formData.givePoints)){
                message.error('积分数应为大于0的整数')
                return false;
            } 
        }
        if(formData.scenarioType == 2){
            if(formData.rechargeMinMoney == undefined || formData.rechargeMinMoney == ''){
                message.error('请填写充值的金额')
                return false;
            }else if(formData.rechargeMinMoney <= 0 || formData.rechargeMaxMoney <=0){
                message.error('金额应该大于0')
                return false;
            }
            else{
                if(parseFloat(formData.rechargeMinMoney) > parseFloat(formData.rechargeMaxMoney)){
                    message.error('充值最小值不能大于充值最大值')
                    return false;
                }
            }
        }
        const couponList = formData.couponList || []
        if(formData.isGiveConpou){
            if(couponList.length == 0){
                message.error('请选择优惠券')
                return false;
            }
        }
       
        let gieCoupon = [];
        if(formData.isGiveConpou&&couponList.length>0){
            formData.couponList.map((item=>gieCoupon.push(item.id)))

        }
        let arr = []
        // if(state.formData.precisionUser&&state.formData.precisionUser.length>0){
        //     state.precisionUser.map(item=>{
        //         if(item.wid.length>0){
        //              item.wid.map(wid=>{
        //                  arr.push({wtype:item.wtype,wid:wid})
        //              })
        //         }
        //     })
        // }
        // console.log(state.formData.precisionUser)
        if(formData.scenarioType == 2 && state.formData.precisionUser && state.formData.precisionUser.length>0){
            state.formData.precisionUser.map(item=>{
                if(item.wid.length>0){
                     item.wid.map(wid=>{
                         arr.push({wtype:item.wtype,wid:wid})
                     })
                }
            })
        }
        let setting = []
        if(state.formData.precisionSettings&&state.formData.precisionSettings.length>0){
            state.precisionSettings.map(item=>{
                if(item.wType == 2){
                     item.wid.map(wid=>{
                         setting.push({wType:2,wid:wid})
                     })
                }
                if(item.wType == 1){
                    item.wid.map(wid=>{
                        setting.push({wType:1,wid:wid})
                    })
                }
            })
        }
        // console.log(arr)
        //console.log(setting)
        let points = formData.givePoints;
        let params = {
           actionName: formData.actionName,
           actionDescription: formData.actionDescription,
           taskType: formData.taskType,
           beginDate: formData.beginDate,
           endDate: formData.endDate,
           scenarioType: formData.scenarioType,
           rechargeMinMoney: formData.rechargeMinMoney==undefined?'':formData.rechargeMinMoney,
           rechargeMaxMoney: formData.rechargeMaxMoney==undefined?'':formData.rechargeMaxMoney,
           givePoints: points!=undefined?parseInt(points):'',
           isGiveConpou:formData.isGiveConpou,
           giveConpouVal: gieCoupon.toString(),
           precisionUser: arr,
           precisionSettings: setting

        }
        //console.log(params)
      
        const r = await new PrecisionLogic().addOperation(params)
        if (r.data.statusCode == 200) {
            message.success("保存成功");
            return true;
        } else {
            message.error(r.data.message);
            return false;
        }
    }
    async updSubmit({ state, status }, id) {
        const {formData} = state;
        if(formData.actionName == undefined|| formData.actionName == ''){
            message.error('活动名称不能为空')
            return false;
        }
        if(formData.taskType ==2){
            if(formData.beginDate == undefined|| formData.beginDate == ''||formData.endDate == undefined||formData.endDate == ''){
                message.error('请填写有效时间')
                return false;
            }
        }



        if(formData.scenarioType == 2){
           
            if(formData.rechargeMinMoney == undefined || formData.rechargeMinMoney == ''){
                message.error('请填写充值的金额')
                return false;
            }else if(formData.rechargeMinMoney <= 0 || formData.rechargeMaxMoney <=0){
                message.error('金额应该大于0')
                return false;
            }
            else{
                if(parseFloat(formData.rechargeMinMoney) > parseFloat(formData.rechargeMaxMoney)){
                    message.error('充值最小值不能大于充值最大值')
                    return false;
                }
            }
        }
        if(formData.channelType == 1){
            if(formData.shopList==undefined||formData.shopList.length == 0){
                message.error('请选择门店')
                return false;
            }
        }
        if(formData.channelType == 2){
            if(state.precisionSettings.filter(item=>{
                return item.wid=='POS'||item.wid == 'Shopp'
            }).length == 0){
                message.error("请选择渠道")
                return false
            }
        }
        if(formData.isGivePoints){
            if(formData.givePoints == null || formData.givePoints == undefined || formData.givePoints =='') {
                message.error('请输入赠送的积分值')
                return false;
            }
            else if(formData.givePoints<=0 || !(/^[1-9]\d*$/).test(formData.givePoints)){
                message.error('积分数应为大于0的整数')
                return false;
            } 
        }
        if(formData.taskType == 2){
            // if()
        }
        const couponList = formData.couponList || []
        if(formData.isGiveConpou){
            if(couponList.length == 0){
                message.error('请选择优惠券')
                return false;
            }
        }
      
        let gieCoupon = [];
        if(formData.isGiveConpou&&couponList.length>0){
            formData.couponList.map((item=>gieCoupon.push(item.id)))

        }
        let arr = []
        if(state.formData.precisionUser && state.formData.precisionUser.length>0){
            state.formData.precisionUser.map(item=>{
                if(item.wid.length>0){
                     item.wid.map(wid=>{
                         arr.push({wtype:item.wtype,wid:wid})
                     })
                }
            })
        }
        let setting = []
        if(state.formData.precisionSettings && state.formData.precisionSettings.length>0){
            state.formData.precisionSettings.map(item=>{
                if(item.wType == 2){
                     item.wid.map(wid=>{
                         setting.push({wType:2,wid:wid})
                     })
                }
                if(item.wType == 1){
                    item.wid.map(wid=>{
                        setting.push({wType:1,wid:wid})
                    })
                }
            })
        }
        let points = formData.givePoints;
        let params = {
            id:id,
           actionName: formData.actionName,
           actionDescription: formData.actionDescription,
           taskType: formData.taskType,
           beginDate: formData.beginDate,
           endDate: formData.endDate,
           scenarioType: formData.scenarioType,
           rechargeMinMoney: formData.rechargeMinMoney==undefined?'':formData.rechargeMinMoney,
           rechargeMaxMoney: formData.rechargeMaxMoney==undefined?'':formData.rechargeMaxMoney,
           givePoints: points!=undefined?parseInt(points):'',
           isGiveConpou:formData.isGiveConpou,
           giveConpouVal: gieCoupon.toString(),
           precisionUser: state.formData.precisionUser.length==0?[]:arr,
           precisionSettings: state.formData.precisionSettings.length ==0 ?[]:setting


        }
        const r = await new PrecisionLogic().updOperation(params);
        if (r.data.statusCode == 200) {
            message.success("更新成功");
            return true;
        } else {
            message.error(r.data.message);
            return false;
        }

    }
    setValue({ attribute, value, dispatch }: ISetValue) {
        dispatch({ type: "Change_FormData", payload: { attribute, value } });
    }
    addTableItem({ dispatch, list }) {
        list.forEach(async v => {
            const r = await new UnitLogic().selData(v.goodsUnitID);
            v.goodsUnitName = r.unitName;

            dispatch({ type: "AddTableItem", payload: v });
        })
    }
    addTableItemAdd({ dispatch, list }) {
        
        list.forEach(async v => {
          
            const r = await new UnitLogic().selData(v.goodsUnitID);
            v.goodsUnitName = r.unitName;
            dispatch({ type: "addTableItemAdd", payload: v });
        })
    }
    AddclassTableItem({ dispatch, list }) {
        dispatch({ type: "AddclassTableItem", payload: list });
    }
    delTableItem(dispatch, index) {
        dispatch({ type: "DelTableItem", payload: index });
    }
    delTableItemAdd(dispatch, index) {
        dispatch({ type: "DelTableItemAdd", payload: index });
    }
    delTableclassItem(dispatch, index) {
        dispatch({ type: "DelTableclassItem", payload: index });
    }
    addGradeItem(dispatch, text) {
        dispatch({ type: "AddGradeItem", payload: text });
    }



    setFormData({ dispatch, key, value }) {
        const params = {
        }
        params[key] = value;

        dispatch({ type: "SetFormData", payload: params })
    }
    setKeywords({ dispatch, value, state }) {
        this.setFormData({ dispatch, key: "keyword", value: value });

        state.searchList.forEach(e => {
            if (e.id == value) {
                this.setFormData({ dispatch, key: "keyword", value: value });

                dispatch({ type: "SetFormData", payload: { inStockUnitPrice: e.putNewPrice } })
            }

        })
    }
    changeTableItemAdd({ dispatch, key, value, index }) {
        dispatch({ type: "ChangeTableItemAdd", payload: { key, value, index } });
    }


    /////////
    clearTableList({ dispatch }) {
        dispatch({ type: "ClearTableList" });
    }

    addCouponTableItem({dispatch, payload}){
        dispatch({type:'AddCouponTableItem', payload})
    }
    delCouponTableItem(dispatch, index) {
        dispatch({ type: "DelCouponTableItem", payload: index });
    }
    changePrecisionUser({dispatch, payload}){        
        dispatch({type:'ChangePrecisionUser', payload})
    }
    delPrecisionUser({dispatch, index}){
        dispatch({type:'DelPrecisionUser', payload: index})
    }
    clearPrecisionUser({dispatch}){
        dispatch({type:'ClearPrecisionUser'})
    }
    clearPrecisionUserOne({dispatch,payload}){
        const {index,wtype,wid} = payload
       this.delPrecisionUser({dispatch,index})
       this.changePrecisionUser({dispatch,payload:{wtype:wtype,wid:wid}})
    }

    changeSetting({dispatch, payload}){
        dispatch({type:'ChangeSettings',payload})
    }
    clearSetting({dispatch}){
        dispatch({type:'ClearSettings'})
    }
    delSetting({dispatch, index}){
        dispatch({type:'DelSetting', payload: index})
    }
    clearSettingOne({dispatch,payload}){
        const { index,wType,wid} = payload
        this.delSetting({dispatch,index})
        this.changeSetting({dispatch,payload:{wType:wType,wid:wid}})
    }

}
export const precisionformAction = new PrecisionFormAction();