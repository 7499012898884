export var ConfigApi = {
  // operationDomain: "https://runapi.duodianduo.cn",
  // serviceDomain: 'https://testwebapi.duodianduo.cn',
  // imgDomain: 'https://testwebapi.duodianduo.cn/',


  operationDomain: "https://runapi.duodianduo.cn",
  serviceDomain: 'https://posapi.duodianduo.cn',
  imgDomain: 'https://posapi.duodianduo.cn/',

  // 胡
  // serviceDomain: "http://192.168.0.168:19999",
  // imgDomain: "http://192.168.0.168:19999",
  // operationDomain: "https://runapi.duodianduo.cn",


  whImgDomain: 'https://cangapi.duodianduo.cn/',

  apiPath: {
    Agreement: {
      Get: "api/Agreement/Get",
    },
   
   
    // 鉴权
    auth_code: "api/Auth/Code",
    login: "api/Auth/Login",
    register: "api/Users/UserRegister",
    menu_query: "api/Menus/Query",
    forgot: "api/Users/ResetPassword",
    UpdatePassword: "api/UserCenter/UpdatePassword",

    // 商品
    /**通过编码获取商品信息 扫码枪 */
    getcode_for_info: "api/BarCode/GetGoodsByBarCode",
    /**图库 */
    img_list: "api/Goods/SeacherGoodsImg",
    /**获取图片 */
    get_img_One: "api/GoodsManager/DownLoadOperateImg",
    /**商品打印 */
    goods_print: "api/GoodsManager/QueryPriceTagPage",
    /**商品导入模板 method: POST */
    goods_module: "api/GoodsManager/DowloadImpotExcel",
    goods_import: "api/GoodsManager/ImportGoods",

    goods_add: "api/GoodsManager/AddGoods",
    goods_sel_page: "api/GoodsManager/QueryPages",
    goods_select_page_by_zero_inventory:
      "api/InventoryManager/QueryInventoryGoods",
    goods_update_file: "api/GoodsManager/UploadImages",
    goods_del: "api/GoodsManager/DeleteGoods",
    goods_query: "api/GoodsManager/GetGoods",
    goods_update: "api/GoodsManager/UpdateGoods",
    goods_add_default: "api/GoodsManager/AddDefaultGoods",
    goods_convertToSimp: "api/GoodsManager/ConvertToSimp",
    inverntory_guige_list: "api/GoodsManager/QueryPagesGroupBySpecification", //查询商品规格层级
    goods_update_status: "api/GoodsManager/UpdateGoodsStatus", //上架下架    
    goods_info_bySpeId:'api/GoodsManager/QueryGoodsListBySepID',// 根据商品规格id获取详情
    goods_info_byBarCode:'api/GoodsManager/GetGoodsByBarCode',// 根据商品规格条码获取详情
    goods_speclist_query:'api/GoodsManager/QuerGoodsAllSpecsPage',// 商品规格列表

    // 商品分类
    goods_classes_queryrelation: "api/GoodsClasses/QueryRelation",
    goods_classes_select: "api/GoodsClasses/Querys",
    goods_classes_add: "api/GoodsClasses/AddGoodsClasses",
    goods_classes_remove: "api/GoodsClasses/DeleteGoodsClasses",
    goods_classes_update: "api/GoodsClasses/UpdateGoodsClasses",
    goods_classes_parent: "api/GoodsClasses/GetAllParentsByChild",
    ggoods_classes_hidden: "api/GoodsClasses/UpdateHidden",
    goods_classes_detail: 'api/GoodsClasses/GetGoodsClass',
    goods_classes_firstlevel: 'api/GoodsClasses/QeruyFirstLevenClass',

    // 商品单位
    goods_unit_add: "api/GoodsUnit/AddUnit",
    goods_unit_update: "api/GoodsUnit/UpdateUnit",
    goods_unit_sel_data: "api/GoodsUnit/Get",
    goods_unit_select_page: "api/GoodsUnit/QueryPages",
    goods_unit_select_delete: "api/GoodsUnit/DeleteUnit",

    // 商品品牌
    goods_brand_add: "api/GoodsBrand/AddBrand",
    goods_brand_delete: "api/GoodsBrand/DeleteBrand",
    goods_brand_update: "api/GoodsBrand/UpdateBrand",
    goods_brand_select_page: "api/GoodsBrand/QueryPages",

    // 商品改价
    goods_updateprice_add:'api/GoodsUpdatePrice/AddUpdatePrice',//post
    goods_updateprice_querypage:'api/GoodsUpdatePrice/QueryPage',//post
    goods_updateprice_del:'api/GoodsUpdatePrice/DeleteUpdatePrice',//get
    goods_updateprice_queryone:'api/GoodsUpdatePrice/GetFirstModel',//get
    goods_updateprice_download:'api/GoodsUpdatePrice/DowloadImpotUpdateGoodsExcel',//get
    goods_updateprice_import:'api/GoodsUpdatePrice/ImportUpdateGoods',//get
    goods_updateprice_queryResult: 'api/GoodsUpdatePrice/QueryGoodsUpdatePriceResult',//get ID

    // 税收编码配置
    tax_codeinfo_list_all:'api/TaxCodeInfo/QueryAll',
    tax_codeinfo_list:"api/TaxCodeInfo/QueryPage",
    tax_codeinfo_add:"api/TaxCodeInfo/AddTaxCode",
    tax_codeinfo_update:"api/TaxCodeInfo/UpdateTaxCode",
    tax_codeinfo_get:"api/TaxCodeInfo/Get",
    tax_codeinfo_del:"api/TaxCodeInfo/Detele",
    // 开票设置
    fapiao_setting_view: "api/ShopManager/GetNuonuoSetting",
    fapiao_setting_save: "api/ShopManager/UpdateNuonuoSetting",

    // 申请开票
    fapiao_apply: 'api/Order/NuonuoReceipt',
    // 检查订单是否已开票
    fapiao_apply_result: 'api/Order/CheckOrderIsInvoicing',
    // 根据订单号或者开票流水号查询开票记录
    fapiao_apply_logs:'api/Order/GetInvoicingLog',
    // 重发发票至邮箱
    fapiao_resend: 'api/Order/DeliveryInvoice',
    // pdf转png
    pdf_to_png: 'api/Order/GetPDFToPNG',

    // 会员管理
    member_reset_pwd:'api/MemberManager/ResetMemberPass',//重置支付密码为123465
    menber_query_page: "api/MemberManager/QueryPages", //会员列表
    menber_query_export: "api/Export/ExportMemberManager", //会员列表导出
    menber_query_update: "api/MemberManager/UpdateMemberManager", //更新会员档案
    menber_add: "api/MemberManager/AddMemberManager", //添加会员
    menber_delete: "api/MemberManager/DeleteMemBer", //删除会员
    menber_look: "api/MemberManager/Get", //查询单个会员
    menber_grade_page: "api/MemberClass/QueryPages", //会员等级列表
    menber_grade_update: "api/MemberClass/UpdateMemberClass", //更新会员等级
    menber_grade_add: "api/MemberClass/AddMemberClass", //添加会员等级
    menber_grade_look: "api/MemberClass/Get", //查看会员等级
    menber_grade_delete: "api/MemberClass/DeleteMemberClass", //查看会员删除
    member_grade_change_logs:'api/MemberClass/QueryMemberClassChangeLog',//会员等级变更记录
    menber_store_page: "api/MemberStoredValue/QueryPages", //会员储值
    menber_store_look: "api/MemberStoredValue/Get", //会员储值查看
    menber_store_add: "api/MemberStoredValue/AddMemberValue", //添加储值
    member_store_addPC:'api/MemberStoredValue/MemberStoredValue',//后台手动添加储值
    menber_store_export: "api/Export/ExportMemberStoredValue", //储值流水导出
    member_store_refund:'api/Payment/MemberRechageRefund',//储值退款
    member_flow_chargeoff:'api/MemberDisburseMoney/GetDeductionsPage',//余额流水 会员资金支出流水
    member_chargeoff_add:'api/MemberDisburseMoney/MemberDeductions', //扣款
    menber_rule_page: "api/MemberStoredValueRule/QueryPages", //储值规则列表
    menber_rule_add: "api/MemberStoredValueRule/AddMemberRule", //添加储值规则
    menber_rule_look: "api/MemberStoredValueRule/Get", //查看储值规则
    menber_rule_delete: "api/MemberStoredValueRule/DeleteMemberRule", //删除储值规则
    menber_rule_update: "api/MemberStoredValueRule/UpdateRule", //更新储值规则
    menber_point_list: "api/MemberPoints/QueryPages", //积分列表
    menber_point_add: "api/MemberPoints/AddMemberPoints", //添加积分
    menber_point_export: "api/Export/ExportMemberPoints", //积分导出
    menber_tag_list: "api/MemberLabel/QueryPages", //会员标签
    menber_tag_add: "api/MemberLabel/AddLabel", //添加会员标签
    menber_tag_update: "api/MemberLabel/UpdateLabel", //更新标签
    menber_tag_delete: "api/MemberLabel/DeleteLabel", //删除会员标签
    member_integral_rule_set:'api/MemberIntegralRule/MemberIntegralRuleAdd', //会员积分规则 添加修改
    member_integral_rule_view:'api/MemberIntegralRule/Get',//会员积分规则查看
    // 短信模板
    notetemplate_update_switch:'api/NoteTemplate/UpdateSwitch',
    notetemplate_list:'api/NoteTemplate/QueryNoteTemplateList',
    notetemplate_get_by_id:'api/NoteTemplate/GetId',
    notetemplate_add:'api/NoteTemplate/NoteTemplateCreate',
    notetemplate_update:'api/NoteTemplate/NoteTemplateUpdate',
    notetemplate_delete:'api/NoteTemplate/NoteTemplateDelete',
    notetemplate_send_test:'api/NoteTemplate/send',
    shortmsg_send:'api/NoteTemplate/SendMessage',
    shortmsg_remainder:'api/NoteTemplate/GetSMSRemaining',


    // 店铺
    shop_add: "api/ShopManager/AddShop",
    shop_list: "api/ShopManager/QueryPages",
    shop_query: "api/ShopManager/Get",
    shop_delete: "api/ShopManager/DeleteShop",
    shop_update: "api/ShopManager/UpdateShop",
    shop_child_query: 'api/ShopManager/GetShopAndChid',

    // 单个门店参数设置
    shop_param_set:'api/ShopManager/UpdateShopParm',
    shop_param_get:'api/ShopManager/GetShopParm',
    //交班
    shift_change_list: "api/Shop_ShiftManager/QueryShopShiftManager", //分页查询
    shift_change_add: "api/Shop_ShiftManager/ADDShop_ShiftBackData", //新增补班
    shift_change_sel: "api/Shop_ShiftManager/GetShop_ShiftBackData", //查看补班
    shift_change_getdetail: "api/Shop_ShiftManager/GetShop_ShiftBackData",

    //门店设置
    setting_shop:'api/StoreSetup/AddOrUpdateStoreSetup',
    get_setting_shop:'api/StoreSetup/Get',
    // 收银提成规则
    commissionrule_list: 'api/CashierCommission/QueryCashierCommissionRule',
    commissionrule_add: 'api/CashierCommission/AddCashierCommissionRule',
    commissionrule_update: 'api/CashierCommission/UpdateCashierCommissionRule',
    commissionrule_get: 'api/CashierCommission/GetCashierCommission',
    commissionrule_change_status: 'api/CashierCommission/UpdateCashierCommissionStatus',
    commissionrule_all_list: 'api/CashierCommission/QueryCashierCommissionAll',
    commission_logs: 'api/CashierCommission/QueryCashierCommissionLogPage',
    commission_cancel: 'api/CashierCommission/CanalCashierCommissionLog',
    // 角色用户
    userrole_list: "api/RoleUsers/Get",
    userrole_add: "api/RoleUsers/Create",
    userrole_delete: "api/RoleUsers/Delete",
    userrole_search: "api/RoleUsers/GetExcludeUsers",

    // 用户
    user_add: "api/Users/Create",
    user_sel: "api/Users/Get",
    user_sel_list: "api/Users/Query",
    user_upd: "api/Users/Update",
    user_delete: "api/Users/Delete",
    user_info: "api/Auth/GetUserInfo",
    user_look: "api/Users/Get", //查询单条用户
    user_psw_reset: "api/Users/ResetPassword", //重置密码
    user_enable: "api/Users/Enable", //批量禁用启用
    // 用户角色定义
    user_roles_list: "api/Roles/Query", //列表
    role_add: "api/Roles/Create",
    role_sel: "api/Roles/Get",
    role_upd: "api/Roles/Update",
    role_delete: "api/Roles/Delete", //删除
    // 角色权限
    auth_list: "api/RolePowers/GetPowersGroup", //列表
    auth_look: "api/RolePowers/GetRolePowers",
    auth_upd: "api/RolePowers/UpdateRolePowers",
    shop_user_list: "api/Users/QueryShopUserList",

    // 订单
    order_sel: "api/Order/GetOrderPages",
    order_sel_data: "api/Order/GetOrder",
    order_sel_prevConnectServer: "api/Auth/PrevConnectServer",
    order_sel_marketinginfo: "api/Order/GetOrderMarketingInfo",
    order_sel_export: "api/Export/ExportOrder", //导出
    order_change_state: "api/Order/MemberDelivery", //修改订单状态
    order_refunds: "api/Payment/Refund", //退款
    order_refund_qurey: "api/Payment/QueryRefundState",
    order_refund_audit: 'api/Payment/AppOrderReundAudit',// 退单申请审核
    order_refund_print: 'api/Order/PrintOrder',// 打印订单标记
    fapiao_logs_byorderid: 'api/Order/GetPartLogByExpenditureID',//根据订单id获取开票记录

    // 库存
    inventory_operation: "api/InventoryManager/OperationInventory",
    inventory_select_form_list: "api/InventoryManager/InQueryPages",
    inventory_instock_export: "api/Export/ExportInventoryManagerIn",

    inventory_out_select_list: "api/InventoryManager/OutQueryPages",
    inventory_outstock_export: "api/Export/ExportInventoryManagerOut",

    inventory_sel_data: "api/InventoryManager/GetInventoryInfo",
    inventory_in_data: "api/InventoryManager/GetInventory_Detail",
    inventory_update_operation: "api/InventoryManager/UpdateInInventory",
    inventory_del: "api/InventoryManager/DeleteInventory",
    inventory_select_list: "api/InventoryManager/QueryPageTJ",
    inventory_export: "api/Export/ExportInventoryManager",
    inventory_select_runwater_list: "api/InventoryManager/QueryInventory_flow",
    inventory_flow_export: "api/Export/ExportInventory_flow",

    // 商品入库 导入
    inventory_in_import:'api/InventoryManager/ImportInventory',
    inventory_in_template:'api/InventoryManager/DowloadImpotExcel',

    // 商品库存删除
    inventory_goods_delete: "api/InventoryManager/ClearGoodsInventory",

    // 预警值
    alarmvalue_select_list: "api/Inventory_EarlyWarning/QuerySetEarlyWarning",
    alarmvalue_update_warningvalue_data:
      "api/Inventory_EarlyWarning/SetEarlyWarning",
    alarm_select_list: "api/Inventory_EarlyWarning/QueryEarlyWarning",

    // 预警值设置   
    alarmset_list: "api/Inventory_EarlyWarning/QuerySetEarlyWarning", //  列表
    alarmset_add :'api/Inventory_EarlyWarning/SetEarlyWarning',
    alarmset_delete:'api/Inventory_EarlyWarning/DeleteEarlyWarning', //get
    // 生产预警
    expired_alarm_list:'api/InventoryManager/QueryInventoryExpired',

    // 盘点
    check_select_list: "api/InventoryCheck/PagesInventory",
    check_add_data: "api/InventoryCheck/AddInventoryCheck",
    check_update_data: "api/InventoryCheck/UpdateInventoryCheck",
    check_delete_data: "api/InventoryCheck/DeleteInventory",
    check_select_data: "api/InventoryCheck/GetInventory_Check",

    // 报损报溢
    report_add_data: "api/InventoryManager/LossAddReported",
    report_update_data: "api/InventoryManager/LossUpdateReported",
    report_select_data: "api/InventoryManager/GetInventory_LossReported",
    report_select_page: "api/InventoryManager/InQueryLossReportedPages",
    report_export: "api/Export/ExportInventoryLossReported",

    // 成本变更
    cost_add_data: "api/InventoryChangeTheCost/ChangeTheCost",
    cost_upd_data: "api/InventoryChangeTheCost/UpdateChangeTheCost",
    cost_select_list: "api/InventoryChangeTheCost/ChangeTheCostPages",
    cost_export: "api/Export/ExportChangeTheCost",

    cost_delete_data: "api/InventoryChangeTheCost/DeleteChangeTheCost",
    cost_select_data: "api/InventoryChangeTheCost/ChangeTheCostMode",

    // 向仓库采购
    purchase_select_list: 'api/Purchase/QueryPurchaseApplication', //采购单列表
    purchase_add_data: 'api/Purchase/AddPurchaseApplication',
    purchase_update_data: 'api/Purchase/UpdatePurchaseApplication',
    purchase_delete_data: '',
    purchase_select_data: 'api/Purchase/GetFreshPurchase',
    purchase_audit_data: 'api/Purchase/SubmitForReview',
    purchase_get_shipped:'api/Purchase/GetShippedData', //已发货数据
    purchase_get_shipped_detail:'api/Purchase/FreshGetPurchaseInvoice', //已发货详情
    purchase_confirm_reciept: 'api/Purchase/ConfirmReceipt', //确认收货
    wh_goods_list: 'api/Purchase/GetFreshStorageGoods',//仓库商品列表
    custom_address_list: 'api/Purchase/GetAddressByID',//门店在仓库收货地址

    // 字典定义
    dict_list: "api/Options/Query",
    dict_look: "api/Options/Get",
    dict_getoption: "api/Options/GetOption", //根据分组查询字典
    dict_add: "api/Options/Create",
    dict_update: "api/Options/Update",
    dict_delete: "api/Options/Delete",

    // 在线统计
    online_list: "api/Online/Query",
    online_delete: "api/Online/Delete",

    //任务调度
    tasks_list: "api/Tasks/Query",
    tasks_look: "api/Tasks/Get",
    tasks_add: "api/Tasks/Create",
    tasks_update: "api/Tasks/Update",
    tasks_delete: "api/Tasks/Delete",
    tasks_start: "api/Tasks/Start",
    tasks_stope: "api/Tasks/Stop",

    //  系统日志
    log_list: "api/Logs/Query",

    //  菜单
    menu_list: "api/Menus/Query",
    menu_look: "api/Menus/Get",
    menu_add: "api/Menus/Create",
    menu_update: "api/Menus/Update",
    menu_delete: "api/Menus/Delete",
    menu_user_list: "api/Menus/GetUserMenus",

    // 权限定义
    authxt_list: "api/Powers/Query",
    authxt_look: "api/Powers/Get",
    authxt_add: "api/Powers/Create",
    authxt_update: "api/Powers/Update",
    authxt_delete: "api/Powers/Delete",

    //功能开关
    switch_params_query: "api/Parameters/QueryAll",
    switch_params_set: "api/Parameters/SetParameter",

    //数据清理
    clear_data_by_id: "api/SysShopSetting/DeleteData",
    clear_data_query: "api/SysShopSetting/GetDataClearModuleList",

    // 首页统计
    statistics_transaction: "api/DataSummary/Sum_Transactions",
    statistics_ranking: "api/DataSummary/GoodsSalesTop",
    statistics_echartstj: "api/DataSummary/EchartsTJ",
    statistics_sale: "api/DataSummary/MemberOrder",

    // 电子秤
    steelyard_get: "api/SysShopSetting/GetElectronic_Setting",
    steelyard_param: "api/SysShopSetting/GetElJsonSetting",
    steelyard_set: "api/SysShopSetting/SetElectronic_Setting",

    // 打印
    Printing_get: "api/SysShopSetting/GetprintingSetting",
    Printing_set: "api/SysShopSetting/SetprintingSetting",

    // 价签、小票打印设置
    print_set_list:'api/SmallTicketsStencil/QueryPage',
    print_set_add:'api/SmallTicketsStencil/AddStencil',
    print_set_update:'api/SmallTicketsStencil/UpdateStencil',
    print_set_del:'api/SmallTicketsStencil/DeleteStencil',
    print_set_inuse:'api/SmallTicketsStencil/SetStencil',
    print_set_getByType:'api/SmallTicketsStencil/GetStencilByType',

    //菜单权限按钮
    menu_button: "api/RolePowers/GetUserRoleMenuPower",
    //短信验证码
    phone_code: "api/Auth/SendPhoneCode",
    //忘记密码
    reset_psw: "api/Auth/UserPasswordBack",

    // 信息
    info_concat: "api/Notice/GetContactU",
    info_message: "api/Notice/QueryPages",

    // 反馈
    feedback_add: "api/Notice/AddQFeedback",
    // 协议
    protocol: "api/Sysitems/Getuser_agreement",
    // 文件下载
    dowload_file: "api/Sysitems/DownloadClient",
    // 广告
    advertising: "api/Poster/QueryList",

    // 版本
    version: "api/Sysitems/DownloadClient",
    setmeal: "api/Sysitems/QuerySetMeal",

    // 折扣活动
    marketing_list: "api/MarketingDiscount/QueryPages",
    marketing_look: "api/MarketingDiscount/GetDiscount",
    marketing_type_update: "api/MarketingDiscount/UpdateStatus",
    marketing_update: "api/MarketingDiscount/UpdateDiscount",
    marketing_add: "api/MarketingDiscount/AddDiscount",
    // 商品秒杀
    seckill_list: "api/GoodsSnapped/QuerySnappedKill",
    seckill_add: "api/GoodsSnapped/AddGoods_Snapped",
    seckill_update: "api/GoodsSnapped/UpdateGoods_Snapped",
    seckill_shop_list: "api/GoodsSnapped/MemberSecondskill",
    seckill_look: "api/GoodsSnapped/Get",
    seckill_delete: "api/GoodsSnapped/TerminateSnapped",
    seckill_start: "api/GoodsSnapped/QuerySnappedStart",
    //优惠券
    coupon_add: "api/MarketingCoupon/AddCoupon",
    coupon_update: "api/MarketingCoupon/UpdateCoupon",
    coupon_delete: "api/MarketingCoupon/DeleteCoupon",
    coupon_status: "api/MarketingCoupon/UpdateStatus",
    coupon_list: "api/MarketingCoupon/QueryPages",
    coupon_get: "api/MarketingCoupon/Get",

    // 特价
    special_list: "api/MarketingSpecial/QueryPages",
    special_add: "api/MarketingSpecial/AddSpecial",
    special_update: "api/MarketingSpecial/UpdateSpecial",
    special_update_type: "api/MarketingSpecial/UpdateStatus",
    special_look: "api/MarketingSpecial/GetSpecial",
    special_delete: "api/MarketingSpecial/DeleteSpecial",
    // 满减
    fullgift_list: "api/MarketingFullMinus/QueryPage",
    fullgift_add: "api/MarketingFullMinus/AddMarketingFullMinus",
    fullgift_update: "api/MarketingFullMinus/UpdateMarketingFullMinus",
    fullgift_look: "api/MarketingFullMinus/GetMarketing",
    fullgift_delete: "api/MarketingFullMinus/MarketingStop",

    //精准营销
    precision_list: "api/MarketingPrecision/QueryPage",
    precision_add: "api/MarketingPrecision/AddMarketingPrecision",
    precision_update: "api/MarketingPrecision/UpdateMarketingPrecision",
    precision_delete: "api/MarketingPrecision/Delete",
    precision_update_status: "api/MarketingPrecision/UpdateState",
    precision_getone: "api/MarketingPrecision/GetModelFirst",
    precison_activity_list: "api/MarketingPrecision/QueryDetailsPage",

    //精准发券
    precision_send_add: "api/MarketingPrecisionCouponSend/AddCouponSend",
    precision_send_list: "api/MarketingPrecisionCouponSend/QueryPages",
    precision_send_getone: "api/MarketingPrecisionCouponSend/Get",
    //支付配置
    pay_type: "api/Payment/GetPaymentType",
    pay_open_close: "api/Payment/OpenOrCloseOnlinePay",
    pay_update: "api/Payment/AddOrUpdate",
    pay_look: "api/Payment/Query",
    pay_upload: "api/Payment/CertificateUplaod",
    pay_aliPay: "api/Payment/AliPay", //支付宝当面付(二维码/条码/声波支付/人脸)
    pay_wx: "api/Payment/WeChatMicroPay", //微信支付
    pay_result: "api/Payment/QueryPayResult", //支付结果
    AliPayAddOrUpdate: "api/Payment/AliPayAddOrUpdate",
    WeChatPayAddOrUpdate: "api/Payment/WeChatPayAddOrUpdate",

    jljh_pay: "api/Payment/GetJlPayConf",
    jljh_pay_update: "api/Payment/JlPayAddOrUpdate",

    gt_pay: "api/Payment/GetYYFPPayConf",
    gt_pay_update: "api/Payment/YYFPayAddOrUpdate",

    //小程序装修
    smallProgram: {
      UploadImages: "api/Fitment/UploadImages",
      UploadVideo: "api/Fitment/UploadVideo",
      AddFitmentSetting: "​api/Fitment/ADDFitmentSetting",
      UpdateFitmentSetting: "api/Fitment/UpdateFitmentSetting",
      GetSettingJson: "api/Fitment/GetSettingJson",
      QueryPage: "api/Fitment/QueryPage",
      Delete: "api/Fitment/Delete",
      SetIndex: "api/Fitment/SetIndex",
      GetSettingByShopID: "api/Fitment/GetSettingByShopID",
      // CheckGoodsStatus: "api/Fitment/GetGoodsStatus",
      CheckGoodsStatus: "api/Fitment/GetGoodsStatusNew",
      CheckSeckillStatus: "api/Fitment/GetSnappedStatus",
      CheckPresaleStatus: "api/Fitment/GetPresaleStatus",
    },

    //销售报表
    saleReport: {
      ShopSaleReport: "api/SaleReport/ShopSaleReport",
      ShopSaleReportDetails:'api/SaleReport/ShopSaleReportDetails', //点击商品编码，显示详情
      InvoicingDetailsReport: "api/SaleReport/InvoicingDetailsReport",
      ShopProfitReport: "api/SaleReport/ShopProfitReport",
      SalesFlowReport: "api/SaleReport/SalesFlowReport",
      GoodsSaleRankReport: "api/SaleReport/GoodsSaleRankReport",
      ClassesSalesRecord:'api/SaleReport/GetGoodsClassRecord',
      GetMemberExpend: 'api/SaleReport/GetMemberExpend',
      GetGoodsRefund: 'api/SaleReport/GetGoodsRefund',
      //图形报表
      GetClassSaleStatic:'api/SaleReport/GetClassSaleStatic',//商品分类销售额饼状图
      GetPayTypeSaleStaic:'api/SaleReport/GetPayTypeSaleStaic',//支付方式销售额饼状图
      GetOrderTypeStatic: 'api/SaleReport/GetOrderTypeStatic',//订单类型饼状图(门店、商城、自助)

      GetGoodsTOP: 'api/SaleReport/GetGoodsTOP',//商品销量排行漏斗图TOP10
      MemberStoredTOP: 'api/SaleReport/MemberStoredTOP',//会员储值排行漏斗图TOP10
      MemberExpendTOP: 'api/SaleReport/MemberExpendTOP',//会员消费排行漏斗图TOP10

      GetSaleStatic: 'api/SaleReport/GetSaleStatic',//销售额折线图
      GetRefundStatic: 'api/SaleReport/GetRefundStatic',//退款折线图
      GetMemberStatic: 'api/SaleReport/GetMemberStatic',//会员收支折线
      GetNewMember: 'api/SaleReport/GetNewMember',//新用户走势图
    },
    miniAdv:{
      queryByPage: 'api/AppAdvertising/AppAdvertisingQuery',
      getById: 'api/AppAdvertising/Get',
      add: 'api/AppAdvertising/AppAdvertisingCreate',
      update: 'api/AppAdvertising/AppAdvertisingUpdate',
      delete: 'api/AppAdvertising/AppAdvertisingDelete',
      onOrOff: 'api/AppAdvertising/DisableEnable',
      uploadImage: 'api/AppAdvertising/UploadImages',
    },
    //广告
    ad: {
      /** 添加广告 */
      Add: "api/Advertisements/Add",
      /** 删除广告 */
      Delete: "api/Advertisements/Delete",
      /** 编辑广告 */
      Update: "api/Advertisements/Update",
      /** 分页查询 */
      QueryPages: "api/Advertisements/QueryPages",
      /** 查询 */
      Query: "api/Advertisements/Query",
      /** 开关广告 */
      AdvertisementsOpenOrClose: "api/Advertisements/AdvertisementsOpenOrClose",
      /** 查询是否开启副屏 */
      QueryIsOpenExtendScreen: "api/Advertisements/QueryIsOpenExtendScreen",
      /** 开启或关闭副屏 */
      ExtendScreenOpenOrClose: "api/Advertisements/ExtendScreenOpenOrClose",
      /** 上传图片 */
      UploadImages: "api/Advertisements/UploadImages",
    },

    //小程序店铺操作
    WxShop: {
      GetUserWxSetting: "api/WxShop/GetUserWxSetting",
      GetShopCommitemplate: "api/WxShop/GetShopCommitemplate",
      Committemplate: "api/WxShop/Committemplate",
      Submit_audit: "api/WxShop/Submit_audit",
      Submit_Speedupaudit: "api/WxShop/Submit_Speedupaudit",
      Undocodeaudit: "api/WxShop/Undocodeaudit",
      GetexperienceCode: "api/WxShop/GetexperienceCode",
      ReleaseVersion: "api/WxShop/ReleaseVersion",
      Queryquota: "api/WxShop/Queryquota",
      Querymemberauth: "api/WxShop/Querymemberauth",
      bind_tester: "api/WxShop/bind_tester",
      unbind_tester: "api/WxShop/unbind_tester",
      QueryApplyPrivacyInterface: 'api/WxShop/QueryApplyPrivacyInterface',
      ApplyPrivacyInterface: 'api/WxShop/ApplyPrivacyInterface',
    },
    //微信 第三方平台
    WxSystem: {
      componentloginpage: "api/WxSystem/componentloginpage",
      GetNewtemplate: "api/WxSystem/GetNewtemplate",
    },
    //第三方
    Agent: {
      AddOrUpdateInfo: "api/Agent/AddOrUpdateInfo",
      GetInfo: "api/Agent/GetInfo/{ID}",
      UploadFile: "api/Agent/UploadFile",
      SendCode: "api/Agent/SendCode",
      AgentCheck: "api/Agent/AgentCheck/{id}",
    },
    //时段促销
    MarketingTimeSalesPromotion: {
      QueryPage: "api/MarketingTimeSalesPromotion/QueryPage",
      GetByID: "api/MarketingTimeSalesPromotion/GetByID",
      AddTimeSalesPromotion:
        "api/MarketingTimeSalesPromotion/AddTimeSalesPromotion",
      UpdateTimeSalesPromotion:
        "api/MarketingTimeSalesPromotion/UpdateTimeSalesPromotion",
      DeleteTemp: "api/MarketingTimeSalesPromotion/DeleteTemp",
      StopTimeSalesPromotion:
        "api/MarketingTimeSalesPromotion/StopTimeSalesPromotion",
    },
    //商城预售
    MarketingPresale: {
      AddPresale: "api/MarketingPresale/AddPresale",
      UpdatePresale: "api/MarketingPresale/UpdatePresale",
      QueryPage: "api/MarketingPresale/QueryPage",
      GetFirstMarkPresasle: "api/MarketingPresale/GetFirstMarkPresasle",
      StopMarkPresasle: "api/MarketingPresale/StopMarkPresasle",
    },
    //积分兑换优惠劵
    MarketingPointsExchangeOffer: {
      AddGoodsExchange: "api/MarketingPointsExchangeOffer/AddGoodsExchange",
      SetExchangeRules: "api/MarketingPointsExchangeOffer/SetExchangeRules",
      GetFirstModel: "api/MarketingPointsExchangeOffer/GetFirstModel",
      QueryPage: "api/MarketingPointsExchangeOffer/QueryPage",
      Shelves: "api/MarketingPointsExchangeOffer/Shelves",
      OfftheShelf: "api/MarketingPointsExchangeOffer/OfftheShelf",
      VoidtheShelf: "api/MarketingPointsExchangeOffer/VoidtheShelf",
    },
    //积分兑换商品
    MarketingPointsExchangeGoods: {
      AddGoodsExchange: "api/MarketingPointsExchangeGoods/AddGoodsExchange",
      SetExchangeRules: "api/MarketingPointsExchangeGoods/SetExchangeRules",
      QueryPage: "api/MarketingPointsExchangeGoods/QueryPage",
      GetFirstModel: "api/MarketingPointsExchangeGoods/GetFirstModel",
      Shelves: "api/MarketingPointsExchangeGoods/Shelves",
      OfftheShelf: "api/MarketingPointsExchangeGoods/OfftheShelf",
      VoidtheShelf: "api/MarketingPointsExchangeGoods/VoidtheShelf",
    },

    /**设备管理 */
    addBarCodeScales: "api/ShopBarCodeScales/AddBarCodeScales",
    updateBarCodeScales: "api/ShopBarCodeScales/UpdateBarCodeScales",
    queryBarCodeScalesByPage: "api/ShopBarCodeScales/QueryBarCodeScalesByPage",
    deleteBarCodeScales: "api/ShopBarCodeScales/DeleteBarCodeScales",
    queryAllBarCodeScales: "api/ShopBarCodeScales/QueryAllBarCodeScales",
    /**热键 */
    addBarCodeScalesHotKey: "api/ShopBarCodeScales/AddBarCodeScalesHotKey",
    updateBarCodeScalesHotKey:
      "api/ShopBarCodeScales/UpdateBarCodeScalesHotKey",
    deleteBarCodeScalesHotKey:
      "api/ShopBarCodeScales/DeleteBarCodeScalesHotKey",
    queryBarCodeScalesHotKeyByPage:
      "api/ShopBarCodeScales/QueryBarCodeScalesHotKeyByPage",
    // 'api/ShopBarCodeScales/DeleteBarCodeScalesHotKey',//删除热键
/**秤下发记录 */
  getScaleRecord:'api/BarCode_IssuedInfo/QueryPage',
  getScaleOne:'api/BarCode_IssuedInfo/QueryDetails',
    // 获取所有热键
    getAllHotKey: "api/ShopBarCodeScales/QueryAllHotKey",
    // 所有plu码
    getAllPlu: "api/ShopBarCodeScales/GetAllPlu",
    // 分销管理
    getDistribution: "api/Distribution_Settings/GetSetting",
    setDistribution: "api/Distribution_Settings/SetDistribution",
    getImage :'api/Distribution_Settings/UploadImages',
    // 查询商城待派送的订单
    queryOrderCount: "api/Order/QueryOrderCount",
    // 分页查询分润记录
    distributionList: "api/Distribution_LogInfo/QueryPage",

    //分页查询提现申请记录
    queryWithdrawList:'api/Distribution_WithdrawalsLog/QueryWithdrawalsLogPage',
    withdrawalsAudit: "api/Distribution_WithdrawalsLog/WithdrawalsAudit",
    confirmationOfReceipt: "api/Distribution_WithdrawalsLog/ConfirmationOfReceipt",
    // 裂变记录
    shareList:'api/Distribution_LogInfo/QueryShareRecords',
    // 裂变关系图
    shareRelationImage:'api/Distribution_LogInfo/GetMemberNexts',


    // 帮助中心

    queryMenuHelp:'api/HelpInfo/QueryMenu',
    queryContentHelp:'api/HelpInfo/GetMenuContent',
    querySearchHelp:'api/HelpInfo/SeacherContent',
    // 导出
    export: {
      ExportShopProfitReport: 'api/Export/ExportShopProfitReport',//经营对账 -- 门店利润
      ExportGoodsRefund: 'api/Export/ExportGoodsRefund',//商品退款信息表
      ExportMemberExpend: 'api/Export/ExportMemberExpend',//导出会员收支报表
      ExportGoodsClassRecord: 'api/Export/ExportGoodsClassRecord',//导出商品分类金额统计
      ExportInvoicingDetailsReport: 'api/Export/ExportInvoicingDetailsReport',//导出进销存明细
      GoodsSaleRankReport: 'api/Export/GoodsSaleRankReport',//导出商品销售排行
      ExportSalesFlowReport: 'api/Export/ExportSalesFlowReport',//导出销售流水
      ExportShopGoodsSale: 'api/Export/ExportShopGoodsSale',//导出门店销售信息
    }
  },
  imgPath: {
    public: "/asset",
  },
};

let host = window.location.host;
// 自动检测本地服务器
if (
  -1 != host.indexOf("127.0.0.1:3000") ||
  -1 != host.indexOf("localhost:3000") ||
  -1 != host.indexOf("192.168.101")
) {
  // ConfigApi.serviceDomain = 'http://webapi.duodianduo.cn';
  // ConfigApi.serviceDomain = 'http://192.168.101.110:19999'
  // ConfigApi.operationDomain = "http://runapi.duodianduo.cn"
}

// // 自动检测测试服务器
// else if (-1 != host.indexOf("***.**.**.***:****")) {
//     // Config.serviceDomain = 'http://***.**.**.***:****';
//     // Config.imgDomain = 'http://***.**.**.***:****';
// }
// // 自动检测正式服务器
// else {
// }
