import { Image } from "antd";
import { useEffect, useState } from "react";
import { fileAction } from './file_action'
export function Pdf({imgArr, hidePdf}){
  const [visible, setVisible] = useState(false);
  const action = fileAction
  useEffect(()=>{
    (async function(){
      if(imgArr && imgArr.length > 0){
            setVisible(true)
      }else{
        setVisible(false)
      }
    })()
    return()=>{
      setVisible(false)
    }
  },[imgArr])
       return <>
       <div style={{ display: 'none' }}>
        { imgArr.length > 0 && <Image.PreviewGroup preview={{ visible,  onVisibleChange: vis => {setVisible(vis); if(!vis){hidePdf()}} }}>
          {imgArr.map(item=>{
            return <Image src={item} />
          })}
        </Image.PreviewGroup>}
       </div>
       </>
 }
 