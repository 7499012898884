import { message } from "antd";
// import axios from "axios";
import Cookies from "js-cookie";
import { DataTableAction } from "../../table/action/data_table_action";
import { Tools, axios } from "../../../config";
import { ClassesLogic } from "../../../logic/classes_logic";
import { PurchaseLogic } from "../../../logic/warehouse/purchase_logic";
class ChooseAction extends DataTableAction {
    async delRow(id) {
       
    }
    delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {
       
    }
   
   
     async loadPage({ dispatch,  state, pageIndex, pageSize, search },_params?:any) {

        // 商品列表
        const { keywords, classesId, statusId, typeId, brandId, isStore } = search;
        const params = {
             pageIndex, pageSize, goodsBrandID: brandId, goodsClassesID: classesId, goodsType: typeId, status: statusId, goodsName: keywords,isStore: isStore,
            //合并额外的参数
            ..._params,
        }
        let type;
        if(_params&&_params.type){
            type = _params.type
        }
        delete params.type
        if (undefined == keywords) {
            delete params.goodsName;
        }
        if (undefined == brandId) {
            delete params.goodsBrandID;
        }
        if (undefined == classesId) {
            delete params.goodsClassesID;
        }
        if (undefined == typeId) {
            delete params.goodsType;
        }
        if (undefined == statusId) {
            delete params.status;
        }
        if (undefined == isStore) {
            delete params.isStore;
        }
        if (-1 == statusId) {
            delete params.status;
        }
        const r = await new PurchaseLogic().getGoodsList(params);
        if (r.data.statusCode == 200) {
            const dataList = r.data.data? r.data.data.dataSource || [] : [];
            let list = []
                 list = dataList.map((goods_v, i) => {
                       return {
                        ...goods_v,
                        // goodsClassify: clsItem.length && clsItem[0].classesName,
                        // unit: uniItem.length && uniItem[0].unitName,
                        key: i + 1 + ((pageIndex - 1) * pageSize),
    
                    }
                })            
            let totalCount = r.data.data && r.data.data.totalCount ? r.data.data.totalCount : 0
            this.setPaging({ dispatch, pageIndex, total: totalCount, dataList: list, pageSize });
        }

    }

    setCondition(method: "Keywords" | "Classes" | "Status", value, dispatch) {
        if ("Keywords" == method) {
            dispatch({ type: "SetSearch", payload: { keywords: value } });
        }
        else if ("Classes" == method) {
            dispatch({ type: "SetSearch", payload: { classesId: value } });
        }
        else if ("Status" == method) {
            dispatch({ type: "SetSearch", payload: { statusId: value } });
        }
    }
    async init({ dispatch }) {
        // 商品分类
        const list = await new ClassesLogic().selListByTree();
        
        dispatch({ type: "SetAreaList", payload: list });
    }
}
export const chooseGoodsAction = new ChooseAction();