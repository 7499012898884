import { createContext, useReducer } from "react";
import { ETopicType } from "../enum/ETopicType";

// interface IComList extends Array<any>{
//     type: 'advimg' | 'title' | 'imgguid' ;
//     content: Object;
// }
// interface IState {
//     comList?: IComList;

// }

export const initContent = {
  goods: [
    {
      img: "http://liqun.vip/Content/Public/images/diy/app/group",
      href: "#",
      name: "商品1",
      category: "选择商品",
    },
    {
      img: "http://liqun.vip/Content/Public/images/diy/app/group",
      href: "#",
      name: "商品2",
      category: "选择商品",
    },
    {
      img: "http://liqun.vip/Content/Public/images/diy/app/group",
      href: "#",
      name: "商品3",
      category: "选择商品",
    },
  ],
  foldValue: 1,
  space: "noblank",
};
export const initGood = {
  img: "http://liqun.vip/Content/Public/images/diy/app/group",
  href: "#",
  name: "华为快充电器SuperCharge（Max 40W）",
  category: "选择商品",
};
export const initEditor: IEditor = {
  isInitCom: true,
  description: "",
  color: "white",
};

export const initSearch = {
  holder: "搜索",
  bgColor: "#fffff",
  color: "#000000",
  boxColor: "#f8f8f8",
};

interface IEditor {
  description: string;
  color: string;
  isInitCom: boolean;
}
interface IAdv {
  foldValue: 1 | 2;
  space: "blank" | "noblank";
  goods: any[];
}
interface IContent {
  adv?: IAdv;
  title?: IEditor;
  search?;
  navigation?;
  commodity?;
}
export const initCommodity = {
  isInitCom: true,
  layout: 1,
  showIcon: true,
  showName: true,
  showPrice: true,
  datalist: [],
};
interface IAdv {
  foldValue: 1 | 2;
  space: "blank" | "noblank";
  goods: any[];
}

interface ICom {
  /** 类型 */
  type: ETopicType;

  /** 数据 */
  data: any;
  /** 数据提取方法 */
  extractDataF?: Function;
}

interface IInitData {
  /** 组件列表 */
  comList: ICom[];
  /** 当前激活的组件 */
  activeId: number;
  name: string;
  /** 记录首页用到的商品id */
  goodsList: any[];
}
/** 状态管理 */
const initState: IInitData = {
  comList: [],
  activeId: -1,
  name: "",
  goodsList: [],
};

type Dispatch = {
  type: any | "SET_DATA";
  payload:
    | any
    | {
        id: number;
        data: any;
      };
};

function reducer(state: IInitData, { type, payload }: Dispatch) {
  let newState = { ...state };
  let { comList, activeId } = state;
  switch (type) {
    case "ADD_COM": //向screen添加组件
      comList.push(payload);
      //设置当前激活的id
      state.activeId = comList.length - 1;
      return JSON.parse(JSON.stringify(state));

    case "RESET_COM": //重置组件列表
      state.comList = payload;
      return JSON.parse(JSON.stringify(state));

    case "ACTIVE": //选中某个组件
      state.activeId = payload;
      return JSON.parse(JSON.stringify(state));

    case "DELETE": //删除某个组件
      comList.splice(payload, 1);
      //重置当前激活id
      state.activeId = -1;
      return JSON.parse(JSON.stringify(state));

    case "LIST_MOVE": //广告列表移动
      state.comList[activeId].data.datalist = payload;
      return { ...state };

    // case "DELETE_GOOD":
    //   comList[activeId].content.goods.splice(payload, 1);
    //   return JSON.parse(JSON.stringify(state));

    // case "Select_good": //选择商品
    //   comList[state.activeId].content.goods.splice(
    //     payload.index,
    //     1,
    //     payload.good
    //   );
    // return JSON.parse(JSON.stringify(state));
    /**设置模板名称 */
    case "SAVE_NAME":
      state.name = payload;
      return { ...state };

    /** 设置数据 */
    case "SET_DATA":
      state.comList[payload.id].data = payload.data;
      if (payload.data.constructor === Object) {
        state.comList[payload.id].data = { ...payload.data };
      }
      if (payload.data.constructor === Array) {
        state.comList[payload.id].data = [...payload.data];
      }
      return { ...state };

    /**记录商城首页所用的商品 */
    case "SAVE_RECORD":
      let list = state.goodsList || [];

      state.goodsList = list;
      return { ...state };

    /**设置初始数据 */
    case "SET_COMLIST":
      state.comList = payload.comList;
      return { ...state };
    default:
      return state;
  }
}

/**
 * topic值类型
 */
export interface ITopicValue {
  state: IInitData;
  dispatch: React.Dispatch<Dispatch>;
}

/** topic */
export const TopicStore = createContext<ITopicValue>(null);

export function TopicStoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initState);
  const v = { state, dispatch };
  return <TopicStore.Provider value={v}>{props.children}</TopicStore.Provider>;
}
