import { message } from "antd";
import Cookies from "js-cookie";
import { Tools } from "../../../../config";
import { TaxCodeInfoLogic } from "../../../../logic/taxcode_logic";
import {
  FormData,
  FormAttribute,
} from "../../store/taxCodeInfo/form_store";
type Init = {
  state;
  dispatch;
  id: string;
  form;
  method: "Create" | "Edit" | "See" | string;
};
interface InitBasic {
  dispatch;
  state;
  form;
}
interface InitEdit {
  state;
  dispatch;
  id: string;
  form;
}
interface ISetValue {
  attribute: FormAttribute;
  value: string | number | string[] | boolean;
  dispatch;
}

export class FormAction {
  private ataxCodeInfoLgc = new TaxCodeInfoLogic();

  async init({ state, dispatch, id, form, method }: Init) {
    await this.initBasic({ dispatch, state, form });

    if ("Create" !== method) {
      await this.initEdit({ dispatch, state, id, form });
    }
  }
  private async initBasic({ dispatch, state, form }: InitBasic) {
    form.setFieldsValue({
      codeName:'',
      taxCode: '',
      rate: undefined,
      remark: ''
    });
  }
  private async initEdit({ dispatch, id, form, state }: InitEdit) {
    // 回填状态
    const res = await this.ataxCodeInfoLgc.getDetailById(id);
    if(res.statusCode === 200 && res.data!=null){
        let item = res.data
        dispatch({
            type: "SetForm",
            payload: Object.assign({},item),
          });
          // 填充节点
          form.setFieldsValue({
           codeName: item.codeName,
           taxCode: item.taxCode,
           rate: item.rate,
           remark: item.remark
          });
    }
  
  }
  reset({ dispatch, form }) {
    dispatch({
      type: "SetForm",
      payload: {
        codeName:'',
        taxCode: '',
        rate: undefined,
        remark: ''
      },
    });

    // 填充节点
    form.setFieldsValue({
      id: "",
      codeName:'',
      taxCode: '',
      rate: undefined,
      remark: ''
    });
  }
  setValue({ attribute, value, dispatch }: ISetValue) {
    dispatch({ type: "Change_FormData", payload: { attribute, value } });
  }
  private paddingData(formData) {
    let params = Object.assign({},formData)
    return params;
   
  }
  private setLoading(dispatch,isloading) {
      dispatch({type:'isLoading',payload: isloading})
  }
  async addSubmit({ state, form, dispatch }) {
    this.setLoading(dispatch,true)
    const res = await this.ataxCodeInfoLgc.addTaxCodeInfo(
      this.paddingData(state.formData)
    );
    this.setLoading(dispatch,false)
    if(res.data.statusCode === 200){
        message.success('添加成功')
        return true
    }else{
        message.error(res.data.returnMessage)
        return false
    }

  }

  async updSubmit({ state, dispatch,id, form }) {
    this.setLoading(dispatch,true)
    const res = await this.ataxCodeInfoLgc.updateTaxCodeInfo(
        {...this.paddingData(state.formData),id:id}
      );
      this.setLoading(dispatch,false)
    if(res.data.statusCode === 200){
        message.success('修改成功')
        return true
    }else{
        message.error(res.data.returnMessage)
        return false
    }
  }
}
export const formAction = new FormAction();
