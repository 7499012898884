import { message } from "antd";
// import axios from "axios";
import Cookies from "js-cookie";
import { DataTableAction } from "../../../../component/table/action/data_table_action";
import { Tools, axios } from "../../../../config";
import { StoreLogic } from "../../../../logic/store_logic";

export class FileAction extends DataTableAction {
    async delRow({ dispatch, rowItem }) {
        const r = await axios({
            method: "GET",
            url: Tools.getApiAddress("menber_rule_delete"),
            params: {
                id: rowItem.id.id
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })

        if (r.data.statusCode == 200) {
            message.success("删除成功")
        }else{
            message.error(r.data.returnMessage)
        }
    }
    delSelectList({ dispatch, selectList,  }: { dispatch: any; selectList: any;}) {
        selectList.map(async ({ id }) => {
            const r = await axios({
                method: "GET",
                url: Tools.getApiAddress("menber_rule_delete"),
                params: {
                    id: id
                },
                headers: {
                    SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
                },
            })

            if (r.data.statusCode == 200) {              
                message.success("删除成功")
            }else{
                message.error(r.data.returnMessage)
            }
        })

    }
    async loadPage({ dispatch, pageIndex, pageSize }) {
        this.setLoading({dispatch, loading:true})
        // 储值列表
        const r = await new StoreLogic().querysList();
        //console.log(pageIndex)
        if (r.data.statusCode == 200) {
            const dataList = r.data.data.dataSource;

            const list = dataList.map((v, i) => {
                v.grantType = v.grantType==1?'金额':v.grantType==2?'积分':'不赠送'
                v.key = i + 1;
                return v;
            })

            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: list, pageSize, loading: false });
        } else{
            this.setLoading({dispatch, loading: false})
        }

    }
}
export const fileAction = new FileAction();