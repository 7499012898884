import { createContext, useContext, useReducer } from "react";

export const FileStore = createContext<IContextProps>(null);

interface IContextProps {
    state: IState;
    dispatch: ({ type }: Dispatch) => void;
}

type Dispatch = { type, payload }

class Search {
    /** 活动名字 */
    seacherKey: string;
    shopID;
    shopName;
    goodsId;
    goodsName;
};

interface IState {
    search: Search;
}

const initialArgs: IState = {
    search: new Search()
}

function reducer(state: IState, { type, payload }: Dispatch) {
    switch (type) {
        case "SetSearch":
            return {
                ...state,
                search: {
                    ...state.search,
                    ...payload
                }
            }
        default:
            return state;
    }
}

export function FileStoreProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialArgs);

    const v = { state, dispatch };
    return <FileStore.Provider value={v}>{props.children}</FileStore.Provider>
}