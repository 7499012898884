import { DataTableAction } from "../../../../component/table/action/data_table_action";
import { AfterSalesLogic } from "../../../../logic/aftersales_logic";

class FileAction extends DataTableAction {
   
    async delRow(id) {       
    }
    delSelectList({ selectList }: { dispatch: any; selectList: any; }) {
    }
    
    async loadPage({ dispatch, pageIndex, pageSize, search }) {
        this.setLoading({dispatch, loading:true})
        const { keywords, goodsClass } = search;
        const params: any = {
            "pageIndex": pageIndex,
            "pageSize": pageSize
        }
        if (keywords) {
            params.keyWords = keywords;
        }       
        if (goodsClass) {
            params.goodsClass = goodsClass;
        }       
       
        // 档案列表
        const r = await new AfterSalesLogic().getList(params)

        if (r.data.statusCode == 200) {
            const dataList = r.data.data.dataSource || [];
            const list = (dataList || []).map((v, i) => {
                v.key = i + 1;
                return v;
            })

            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: list, pageSize, loading: false });
        }else{
            this.setLoading({dispatch, loading: false})
        }

    }
  

    setCondition({ dispatch, key, value }) {
        const params = {
        }
        params[key] = value;

        dispatch({ type: "SetSearch", payload: params })
    }
}
export const fileAction = new FileAction()