import { message } from "antd";
// import axios from "axios";
import Cookies from "js-cookie";
import { DataTableAction } from "../../../component/table/action/data_table_action";
import { Tools, axios } from "../../../config";
import { MimiAdLogic } from "../../../logic/miniAd_logic";

export class FileAction extends DataTableAction {
    async delRow(id) {
       const r = await new MimiAdLogic().delData(id)
        if (r.data.statusCode == 200) {
            message.success("删除成功")
        }
    }
    delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {
      
    }
    async loadPage({ dispatch, pageIndex, pageSize, search }) {
        this.setLoading({dispatch,loading: true})
        const { } = search;
        const params = {
            pageIndex,
            pageSize,
           
        };
   

        // 档案列表
        const r = await new MimiAdLogic().queryByPage(params);

        if (r.data.statusCode == 200) {
            const dataList = r.data.data.dataSource;

            const list = dataList.map((v, i) => {
                v.key = i + 1;
                return v;
            })

            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: list, pageSize, loading: false });
        }else{
            this.setLoading({dispatch,loading: false})
        }

    }
    setCondition(method: "Keywords" | "Area" | "Status" | "Type", value, dispatch) {
        if ("Keywords" == method) {
            dispatch({ type: "SetSearch", payload: { keywords: value } });
        }
        else if ("Area" == method) {
            dispatch({ type: "SetSearch", payload: { areaId: value } });
        }
        else if ("Status" == method) {
            dispatch({ type: "SetSearch", payload: { statusId: value } });
        }
        else if ("Type" == method) {
            dispatch({ type: "SetSearch", payload: { typeId: value } });
        }
    }
}
export const fileAction = new FileAction();