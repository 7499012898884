// import axios from "axios";
import Cookies from "js-cookie";
import { ConfigApi, Tools, axios } from "../../../config";
import { TopicLogic } from './topic_logic'
import { effeative } from "../../../logic/common_logic";
import { message } from "antd";
type fileUrl = {
    url: string;
    name: string;
};

function objToFormData(obj: any): FormData {
    let fromData = new FormData();
    for (let i in obj) {
        if (typeof obj[i] != "undefined") {
            fromData.set(i, obj[i]);
        }
    }
    return fromData;
}

/**
 * api处理
 */
export class ApiCon {
    /** 设置模板 */
    static AddFitmentSetting(TempID: string, TempJsonStr: string, Title: string): Promise<any> {
        return axios({
            method: "POST",
            // url: Tools.getApiAddress('smallProgram.SaveFitmentSetting'),
            // url:Tools.getApiAddress('smallProgram.ADDFitmentSetting'),
            url: ConfigApi.serviceDomain + '/api/Fitment/ADDFitmentSetting',
            data: objToFormData({
                TempID,
                TempJsonStr,
                Title
            }),
            headers: {
                'Content-Type': 'multipart/form-data;charset=UTF-8',
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
                // SYSTOKEN: '0E2A0D5C-702D-4054-B37D-C901BE93DA32'
            },
        }).then((r) => {
            if (r.data.statusCode == 200) {
                return r.data.data;
            } else {
                throw r.data.message;
            }
        });
    }
    /** 设置模板 */
    static UpdateFitmentSetting(TempID: string, TempJsonStr: string, Title: string): Promise<any> {
        return axios({
            method: "POST",
            // url: Tools.getApiAddress('smallProgram.SaveFitmentSetting'),
            // url:Tools.getApiAddress('smallProgram.ADDFitmentSetting'),
            url: ConfigApi.serviceDomain + '/api/Fitment/UpdateFitmentSetting',
            data: objToFormData({
                TempID,
                TempJsonStr,
                Title
            }),
            headers: {
                'Content-Type': 'multipart/form-data;charset=UTF-8',
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
                // SYSTOKEN: '0E2A0D5C-702D-4054-B37D-C901BE93DA32'
            },
        }).then((r) => {
            if (r.data.statusCode == 200) {
                return r.data.data;
            } else {
                throw r.data.returnMessage;
            }
        });
    }

    /**
     * 转blob视频的路径到远程的地址
     * @param _files 文件或者列表
     */
    static turnBlobVideoUrl(_files: fileUrl | fileUrl[]): Promise<(string | any)[]> {
        return this.turnBlobUrl(_files, 'video');
    }

    /**
     * 转blob图片的路径到远程的地址
     * @param _files 文件或者列表
     */
    static turnBlobImgUrl(_files: fileUrl | fileUrl[]): Promise<string[]> {
        return this.turnBlobUrl(_files, 'img');
    }

    /** 转blob路径到远程路径 */
    static turnBlobUrl(_files: fileUrl | fileUrl[], _typs: 'img' | 'video') {
        if (!Array.isArray(_files)) {
            _files = [_files];
        }
        //
        return Promise.all(_files.map((item) => {
            //如果是个blob地址的话就上传获取url,如果不是直接返回该url
            if (/^blob:/.test(item.url)) {
                return fetch(item.url).then((item) => {
                    return item.blob();
                }).then((blob) => {
                    let file = new File([blob], item.name || '');
                    switch (_typs) {
                        case 'img':
                            return this.uploadImgGetUrl(file);
                        case 'video':
                            return this.uploadVoidGetUrl(file);
                    }
                })
            } else {
                return item.url;
            }
        }));
    }

    /**
     * 上传图片获取链接
     * @param file 文件
     */
    static uploadImgGetUrl(file: File): Promise<string> {
        //发送请求
        const formData = new FormData();
        formData.append("file", file);

        return axios({
            method: "POST",
            // url: Tools.getApiAddress("goods_update_file"),
            // url: 'http://192.168.101.110:19999/api/Fitment/UploadImages',
            url: Tools.getApiAddress('smallProgram.UploadImages'),
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;charset=UTF-8',
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
                // SYSTOKEN: '0E2A0D5C-702D-4054-B37D-C901BE93DA32'
            },
        }).then((r) => {
            if (r.data.statusCode == 200) {
                return r.data.data;
            } else {
                throw r.data.message;
            }
        });
    }

    /**
     * 上传视频并获取数据
     * @param file 
     */
    static uploadVoidGetUrl(file: File): Promise<any> {
        //发送请求
        const formData = new FormData();
        formData.append("file", file);

        return axios({
            method: "POST",
            // url: Tools.getApiAddress("goods_update_file"),
            url: ConfigApi.serviceDomain + '/api/Fitment/UploadVideo',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;charset=UTF-8',
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }).then((r) => {
            if (r.data.statusCode == 200) {
                return r.data.data;
            } else {
                throw r.data.message;
            }
        });
    }

    /**
     * 获取图片路径
     * @param _url 原url
     */
    static getImageUrl(_url: string, _goodsSource?: number): string {
        if (!_url) { return _url }
        if (/^blob:/.test(_url)) { return _url; }
        //
        return Tools.getImageAddress(_url,_goodsSource);
    }

    /**
     * 获取视频路径
     * @param _url 原url
     */
    static getVideoUrl(_url: string): string {
        if (!_url) { return _url }
        if (/^blob:/.test(_url)) { return _url; }
        //
        return Tools.getImageAddress(_url);
    }


    /**
     * 获取模板列表
     */
    static async getFitmentList() {
        return axios({
            method: "GET",
            url: Tools.getApiAddress('smallProgram.QueryPage'),
            // url: 'http://192.168.101.110:19999/api/Fitment/SaveFitmentSetting', 
            params: {
                page: 1,
                size: 10
            },
            headers: {
                'Content-Type': 'multipart/form-data;charset=UTF-8',
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
                // SYSTOKEN: '0E2A0D5C-702D-4054-B37D-C901BE93DA32'
            },
        }).then((r) => {
            if (r.data.statusCode == 200) {
                // //console.log(r.data.data)
                return r.data.data;
            } else {
                throw r.data.message;
            }
        });
    }

    static async setIndex(TempID: string) {
        return await axios({
            method: 'GET',
            url: Tools.getApiAddress('smallProgram.SetIndex'),
            params: {
                TempID: TempID
            },
            headers: {
                SYSTOKEN: Cookies.get('token')
            }
        }).then((r) => {
            if (r.data.statusCode == 200) {
                message.info(r.data.returnMessage)
                // return r.data.returnMessage;
            } else {
                throw r.data.message;
            }
        });
    }
    static async GetSettingJson(TempID: string) {
        return await axios({
            method: 'GET',
            url: Tools.getApiAddress('smallProgram.GetSettingJson'),
            params: {
                TempID: TempID
            },
            headers: {
                SYSTOKEN: Cookies.get('token')
            }
        }).then((r) => {
            if (r.data.statusCode == 200) {
                return r.data.data;
            } else {
                throw r.data.message;
            }
        });
    }
}