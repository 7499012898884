import { message } from "antd";
// import axios from "axios";
import Cookies from "js-cookie";
import { saveAs } from 'file-saver';
import { DataTableAction } from "../../../component/table/action/data_table_action";
import { Tools, axios } from "../../../config";
import { OrderLogic } from "../../../logic/order_logic";
import { ShopLogic } from "../../../logic/shop_logic";
import { getGSCloudPlugin } from "../../../assets/js/GSCloudPluginFuncs";


let GSCloudPlugin = getGSCloudPlugin();
export class FileAction extends DataTableAction {
    async delRow({ dispatch, rowItem }) {
        const r = await axios({
            method: "GET",
            url: Tools.getApiAddress("shop_delete"),
            params: {
                shopId: rowItem.id
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })

        if (r.data.statusCode == 200) {
            message.success("删除成功")
        }
    }
    delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {
        selectList.map(async ({ id }) => {
            const r = await axios({
                method: "GET",
                url: Tools.getApiAddress("shop_delete"),
                params: {
                    shopId: id
                },
                headers: {
                    SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
                },
            })

            if (r.data.statusCode == 200) {
                message.success("删除成功")
            }
        })
    }
    // 导出
    async exportList({ search }) {
        const { dateStart, dateEnd, keywords, orderNo, people, statusId, typeId } = search;

        const selParams = {
            startDate: dateStart,
            endDate: dateEnd,
            keyWords: keywords,
            orderNumber: orderNo,
            operationID: people,
            orderState: statusId,
            orderType: typeId,
        }
        if (undefined == dateStart || "" == dateStart) {
            delete selParams.startDate;
        }
        if (undefined == dateEnd || "" == dateEnd) {
            delete selParams.endDate;
        }
        if (undefined == keywords || "" == keywords) {
            delete selParams.keyWords;
        }
        if (undefined == orderNo || "" == orderNo) {
            delete selParams.orderNumber;
        }
        if (undefined == people || "" == people || -1 === people) {
            delete selParams.operationID;
        }
        if (statusId == -1) {
            delete selParams.orderState
        }
        if (typeId == -1) {
            delete selParams.orderType
        }
        const r = await axios({
            method: "POST",
            url: Tools.getApiAddress("order_sel_export"),
            responseType: 'arraybuffer',
            data: selParams,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })
        console.log(r)

        if (r.status == 200) {
            let enc = new TextDecoder('utf-8')
            let data = enc.decode(new Uint8Array(r.data))
            if (data.indexOf('statusCode') != -1) {
                let datas = JSON.parse(data)
                if (datas.statusCode == 200) {
                    let blob = new Blob([r.data], { type: 'application/vnd.ms-excel' })
                    saveAs(blob, "订单.xlsx");
                } else {
                    message.error(datas.returnMessage)
                }
            } else {
                let blob = new Blob([r.data], { type: 'application/vnd.ms-excel' })
                saveAs(blob, "订单.xlsx");
            }
        }

    }
    // 打印
    async printFunction({id, type = 'default'},dispatch){
        var loginMsg = JSON.parse(Cookies.get('loginMsg'))
        GSCloudPlugin.SetAppKey(
          "BC85333E6E2345A9AC2C39635C722682",
          "四川智如客科技有限公司"
        );
        
        let that = this
        dispatch({type:'isloading',payload:{isLoading: true,msg:'打印中...'}})
        var url = document.location.toString().split("/")
    let printUrl = url[0] + '//' + url[1] + url[2]+'/print.html?shopName='+loginMsg.shopName+'&orderId='+id+'&token='+Cookies.get('token')
            if(type == 'refund'){
                printUrl =  url[0] + '//' + url[1] + url[2]+'/printRefund.html?shopName='+loginMsg.shopName+'&orderId='+id+'&token='+Cookies.get('token')
            }
    //    window.open(printUrl)

        GSCloudPlugin.PrintHtml({
            Title: 'HTML0001',
            Width: 210,
            Height: 0,
            Url: printUrl,
            PrinterName: '测试', // app.getPrinterName()方法仅用于在线演示
            OnSuccess: function(result) {
                dispatch({type:'isloading',payload:{isLoading: false,msg:'打印中...'}})
            },
            OnError: function(result) {
                dispatch({type:'isloading',payload:{isLoading: false,msg:'打印中...'}})
            },
          });
    }
    async loadPage(params, dispatch) {
        const { pageIndex, pageSize } = params;
        const { dateStart, dateEnd, keywords, orderNo, people, statusId, typeId } = params.search;
            this.setLoading({dispatch, loading:true})
        const selParams = {
            startDate: dateStart,
            endDate: dateEnd,
            keyWords: keywords,
            orderNumber: orderNo,
            operationID: people,
            orderState: statusId,
            orderType: typeId,
            pageIndex:pageIndex,
            pageSize: pageSize,
            // orderBy: string;
            // sort: string;
        }
        if (undefined == dateStart || "" == dateStart) {
            delete selParams.startDate;
        }
        if (undefined == dateEnd || "" == dateEnd) {
            delete selParams.endDate;
        }
        if (undefined == keywords || "" == keywords) {
            delete selParams.keyWords;
        }
        if (undefined == orderNo || "" == orderNo) {
            delete selParams.orderNumber;
        }
        if (undefined == people || "" == people || -1 === people) {
            delete selParams.operationID;
        }
        if (statusId == -1) {
            delete selParams.orderState
        }
        if (typeId == -1) {
            delete selParams.orderType
        }

        // 档案列表
        const r = await new OrderLogic().selList(selParams);

        if (r.statusCode == 200) {
            const dataList = r.data.dataSource;

            const list = dataList.map((v, i) => {
                v.key = i + 1;
                return v;
            })

            this.setPaging({ dispatch, pageIndex, total: r.data.totalCount, dataList: list, pageSize, loading: false });
        }else{
            this.setLoading({dispatch, loading: false})
        }

    }
    setCondition(method: "Keywords" | "OrderNo" | "Status" | "Type" | "People" | "DateStart" | "DateEnd", value, dispatch) {
        if ("Keywords" == method) {
            dispatch({ type: "SetSearch", payload: { keywords: value } });
        }
        else if ("OrderNo" == method) {
            dispatch({ type: "SetSearch", payload: { orderNo: value } });
        }
        else if ("Status" == method) {
            dispatch({ type: "SetSearch", payload: { statusId: value } });
        }
        else if ("Type" == method) {
            dispatch({ type: "SetSearch", payload: { typeId: value } });
        }
        else if ("People" == method) {
            dispatch({ type: "SetSearch", payload: { people: value } });
        }
        else if ("DateStart" == method) {
            dispatch({ type: "SetSearch", payload: { dateStart: value } });
        }
        else if ("DateEnd" == method) {
            dispatch({ type: "SetSearch", payload: { dateEnd: value } });
        }
    }
    /**
     * 改变订单状态
     * @param params 订单号，最终状态
     * @returns 
     */
    async changeStateSingle({ orderNumber, State }) {
        const r = await axios({
            method: "GET",
            url: Tools.getApiAddress("order_change_state"),
            params: {
                OrderNumber: orderNumber,
                State: State
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })

        if (r.data.statusCode == 200) {
            message.success("订单状态修改成功")
            return true;
        } else {
            message.error(r.data.returnMessage)
            return false;
        }
    }
    async changeState(params) {

        let arr1 = params.orderList.filter(order => {
            return order.state != 3//待发货
        })
        let arr2 = params.orderList.filter(order => {
            return (order.state != 2 && order.state != 4)//待收货
        })
        let arr3 = params.orderList.filter(order => {
            return order.state != 4//待提货
        })

        if (params.State == 2) {
            if (arr1.length > 0) {
                message.warn('存在不能修改为发货的订单')
                return false;
            }
        }
        if (params.State == 5) {
            if (arr2.length > 0) {
                message.warn('存在不能修改为完成的订单')
                return false;
            }
        }
        params.orderList.map(async (order) => {
            const r = await axios({
                method: "GET",
                url: Tools.getApiAddress("order_change_state"),
                params: {
                    OrderNumber: order.orderNumber,
                    State: params.State
                },
                headers: {
                    SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
                },
            })

            if (r.data.statusCode == 200) {
                message.success("订单状态修改成功")
            } else {
                message.error(r.data.returnMessage)
            }
        })

    }

    /**
     * 退款
     * @param OrderNumber
     */
    async returnSale({ OrderNumber,dispatch }) {
        dispatch({type:'isloading',payload:{isLoading: true,msg:'退款中...'}})
        const r = await axios({
            method: "GET",
            url: Tools.getApiAddress("order_refunds"),
            params: {
                OrderNumber: OrderNumber,
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })

        if (r.data.statusCode == 200) {
            dispatch({type:'isloading',payload:{isLoading: false,msg:'退款中...'}})
            message.success("退款成功")
        } else {
            dispatch({type:'isloading',payload:{isLoading: false,msg:'退款中...'}})
            message.error(r.data.returnMessage)
        }

    }
    /**
     * 审核退款
     * @param OrderNumber 订单号
     * @param State 0通过 1拒绝
     */
   async refundAudit({orderNumber,status,dispatch}){
    dispatch({type:'isloading',payload:{isLoading: true,msg:''}})
        const r = await axios({
            method: "GET",
            url: Tools.getApiAddress("order_refund_audit"),
            params: {
                OrderNumber: orderNumber,
                State: status
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })

        if (r.data.statusCode == 200) {
            dispatch({type:'isloading',payload:{isLoading: false,msg:''}})
            status==0? message.success("通过"):message.success("拒绝")
            return true
        } else {
            dispatch({type:'isloading',payload:{isLoading: false,msg:''}})
            message.error(r.data.returnMessage)
            return false
        }
    }
}
export const fileAction = new FileAction();