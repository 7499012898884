import { message } from "antd";
// import axios from "axios";
import Cookies from "js-cookie";
import { DataTableAction } from "../../../../component/table/action/data_table_action";
import { Tools, axios } from "../../../../config";
import { ShopLogic } from "../../../../logic/shop_logic";
import { UserLogic } from "../../../../logic/user_logic";

export class ListAction extends DataTableAction {
    async delRow({ dispatch, rowItem }) {
        let userIds = []
        userIds.push(rowItem.id)
        const r = await axios({
            method: "POST",
            url: Tools.getApiAddress("user_delete"),
            data: {
                userIds: userIds
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })

        if (r.data.statusCode == 200) {
            message.success("删除成功")
        }else{
            message.error(r.data.returnMessage)
        }
    }
    async delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {

        let userIds = []
        for (let i = 0; i < selectList.length; i++) {
            userIds.push(selectList[i].userID)
        }
        const r = await axios({
            method: "POST",
            url: Tools.getApiAddress("user_delete"),
            data: {
                userIds: userIds
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })

        if (r.data.statusCode == 200) {
            message.success("删除成功")
        }else{
            message.error(r.data.returnMessage)
        }
    }
    // 批量禁用启用
    async enableSelectList({ dispatch, selectList, status }: { dispatch: any; selectList: any; status:boolean}) {

        let userIds = []
        for (let i = 0; i < selectList.length; i++) {
            userIds.push(selectList[i].userID)
        }
        const r = await axios({
            method: "POST",
            url: Tools.getApiAddress("user_enable"),
            data: {
                userIds: userIds,
                status:status
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })

        if (r.data.statusCode == 200) {
            if(status){
                message.success("启用成功")
            }else{
                message.success("禁用成功")
            }
            
        }
    }
    async loadPage({ dispatch, pageIndex, pageSize, search }) {
        this.setLoading({dispatch,loading: true})
        const { keywords } = search;
        const selParams = {
            pageIndex,
            queryText:keywords,
            pageSize
        }

        // 会员列表
        const r = await new UserLogic().selList(selParams);

        if (r.dataSource) {
            const dataList = r.dataSource;

            const list = dataList.map((v, i) => {
                v.key = i + 1;
                return v;
            })
            this.setPaging({ dispatch, pageIndex, total: r.totalCount, dataList: list, pageSize });
        }  this.setLoading({dispatch,loading: false})

    }
    setCondition(method: "Keywords", value, dispatch) {

        if ("Keywords" == method) {
            dispatch({ type: "SetSearch", payload: { keywords: value } });
        }
    }
}
export const fileAction = new ListAction();