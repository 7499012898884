import { DataTable } from "../../component/table";
import styles from "./style/index.module.scss";
import { Button, message, Tag } from "antd";
import { Layout } from "../../component/layout";
import { TabsStyle, TabList, Tab, TabPanel } from "../../component/react-tabs";
import { TopicStore, TopicStoreProvider } from "./store/topic";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { MenuLogic } from "../../logic/menu_logic";
import { TopicBlank } from "./index";
import { ApiCon } from "./com/apiCon";
import { topicfileAction } from "./com/topic_logic";
import { useBoolean } from "ahooks";
import { defualtPageSize, Progress } from "../../logic/common_logic";
import {
  DataTableStore,
  DataTableStoreProvider,
} from "../../component/table/store/data_table_store";
import { ConfirmModal } from "../../component/modal";
import img_edit from "../../assets/images/icon/bianji.png";
import img_see from "../../assets/images/icon/chakan.png";
import img_del from "../../assets/images/icon/shanchu.png";
import { AuthLogic } from "../../logic/auth_logic";
import { ButtonPanel } from "../../component/button-panel/button_panel";
import { ColumnsType } from "antd/lib/table";

export function Topic() {
  const location = useLocation();
  const [menuList, setData] = useState([]);
  useEffect(() => {
    (async function () {
      let r = await new MenuLogic().menuData();
      var arr = [];
      if (r && r.length > 0) {
        r = r.filter(function (v) {
          return !v.hidden;
        });
        for (let i = 0; i < r.length; i++) {
          if (r[i].path == "/topic" && r[i].children) {
            arr = r[i].children.filter(function (v) {
              return !v.hidden;
            });
          }
        }
      }
      setData(arr);
    })();
    return () => {};
  }, []);

  return (
    <>
      {/* <Layout fullWidth={true} > */}
      <TabsStyle style="Full">
        <TabList>
          {menuList.map((v, index) => (
            <Tab key={index}>{v.name}</Tab>
          ))}
          {/* <Tab key={'1'}>开屏广告</Tab> */}
        </TabList>

        {location.pathname == "/topic/blank" ? (
          <TabPanel>
            <TopicStoreProvider>
              <TopicBlank />
            </TopicStoreProvider>
          </TabPanel>
        ) : (
          menuList.map((v) =>
            v.component == "/topic" ? (
              <TabPanel>
                <DataTableStoreProvider>
                  <FitMent viewPower={v.viewPower} />
                </DataTableStoreProvider>
              </TabPanel>
            ) : (
              <TabPanel>
                <TopicStoreProvider>
                  <TopicBlank />
                </TopicStoreProvider>
              </TabPanel>
            )
          )
        )}
      </TabsStyle>
      {/* </Layout> */}
    </>
  );
}

function FitMent(viewPower) {
  const [confirmStatedel, confirmdelFunc] = useBoolean(false);
  const [confirmStateset, confirmSetFunc] = useBoolean(false);
  const [tempId, setTempId] = useState("");
  const [isIndex, setIsIndex] = useState(false);
  const [delmany, delManyFunc] = useBoolean(false);
  const del = () => {
    if (isIndex) {
      message.error("主页不能删除");
      return false;
    } else if (tempId) {
      action.delRow(tempId);
      onSearch(1);
    }
  };
  const setIndex = () => {
    //console.log(tempId)

    if (tempId) {
      let r = action.setIndex(tempId);
      r.then((res) => {
        if (res && res == 200) {
          onSearch(1);
        }
      });
    }
  };
  const [menuButton, setData] = useState([]);
  useEffect(() => {
    (async function () {
      let r = await new AuthLogic().selButton(viewPower.viewPower);
      if (r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          r[i] = r[i].split("_")[r[i].split("_").length - 1];
        }
      }
      setData(r);
    })();

    return () => {};
  }, []);
  const history = useHistory();
  const columns: ColumnsType = [
    {
      key: "1",
      title: "操作",
      // dataIndex:'id',
      align: "center",
      width: 150,
      render: (v) => {
        return (
          <div className={styles.topicpage_actions}>
            {menuButton.indexOf("DELETE") != -1 && (
              <img
                src={img_del}
                onClick={() => {
                  setTempId(v.id);
                  setIsIndex(v.isSetIndex);
                  confirmdelFunc.setTrue();
                }}
                style={{ cursor: "pointer" }}
                alt=""
              />
            )}
            <ConfirmModal
              visible={confirmStatedel}
              title="温馨提示"
              subTitle="确认删除？"
              message="删除之后信息无法恢复，请慎重考虑"
              close={async (r) => {
                if (r) {
                  del();
                  onSearch(tableState.current);
                }
                confirmdelFunc.setFalse();
              }}
            />
            {menuButton.indexOf("UPDATE") != -1 && (
              <img
                src={img_edit}
                onClick={() => {
                  history.push({
                    pathname: "/topic/blank",
                    state: { id: v.id, title: v.title },
                  });
                }}
                style={{ cursor: "pointer" }}
              />
            )}
            {menuButton.indexOf("UPDATE") != -1 && (
              <span
                onClick={() => {
                  setTempId(v.id);
                  confirmSetFunc.setTrue();
                }}
              >
                设为主页{" "}
              </span>
            )}
            <ConfirmModal
              visible={confirmStateset}
              title="温馨提示"
              subTitle="确认设为主页？"
              message=""
              close={async (r) => {
                if (r) {
                  setIndex();
                  onSearch(tableState.current);
                }
                confirmSetFunc.setFalse();
              }}
            />
          </div>
        );
      },
    },
    {
      key: "2",
      title: "模板名称",
      dataIndex: "title",
      align: "center",
    },
    {
      key: "3",
      title: "创建人",
      dataIndex: "addUID",
      align: "center",
    },
    {
      key: "4",
      title: "创建时间",
      dataIndex: "addDateTime",
      align: "center",
    },
    {
      key: "5",
      title: "是否为主页",
      dataIndex: "isSetIndex",
      align: "center",
      render: (v) => (v ? <Tag color="#52c41a">主页</Tag> : <Tag></Tag>),
    },
  ];

  const action = topicfileAction;
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);
  const onSearch = async (pageIndex) => {
    await action.loadPage({
      dispatch: tableDispatch,
      pageIndex: pageIndex,
      pageSize: defualtPageSize(),
    });
  };
  return (
    <>
      {menuButton.indexOf("ADD") != -1 && (
        <div className={styles.create_button}>
          <button>
            <Link to="/topic/blank" style={{ color: "#ffc80b" }}>
              {" "}
              新建模板
            </Link>
          </button>
        </div>
      )}
      <ButtonPanel>
        {menuButton.indexOf("DELETE") != -1 && (
          <Button
            onClick={async () => {
              if (
                !tableState.selectedRowKeys.length ||
                !tableState.selectList.length
              ) {
                message.error("请选择删除项");
              } else {
                let arr = tableState.selectList.filter((item) => {
                  return item.isSetIndex == true;
                });
                if (arr.length > 0) {
                  message.error("选中项中有主页，不能删除");
                  action.clearSelectedRowKeys({ dispatch: tableDispatch });
                  return false;
                } else {
                  delManyFunc.setTrue();
                }
              }
            }}
          >
            批量删除
          </Button>
        )}
      </ButtonPanel>
      <DataTable
        columns={columns}
        action={action}
        rowSelection={{ type: "checkbox" }}
        onLoad={async () => {
          await onSearch(1);
          Progress.done();
        }}
        onChange={(pageIndex) => {
          onSearch(pageIndex);
        }}
        scroll={{ scrollToFirstRowOnChange: true, autoY: true }}
        selectColumn={true}
        // rowKey={(record) => record.id}
        loading={tableState.loading}
      />
      <ConfirmModal
        visible={delmany}
        title="温馨提示"
        subTitle="确认删除？"
        message="删除之后信息无法恢复，请慎重考虑"
        close={async (r) => {
          if (r) {
            action.delSelectList(tableState.selectList);
            action.clearSelectedRowKeys({ dispatch: tableDispatch });
            onSearch(tableState.current);
          }
          delManyFunc.setFalse();
        }}
      />
    </>
  );
}
