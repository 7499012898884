import {
  TabsStyle,
  TabList,
  Tab,
  TabPanel,
} from "../../../../../component/react-tabs";
import { autoRem } from "../../../../../global.style";
import { WXForm } from "./WXForm";
import { ZFBForm } from "./ZFBForm";
import { JLJHForm } from "./JLJHForm";
import { GTForm } from "./GTForm";
import { useEffect, useState } from "react";
import { AuthLogic } from "../../../../../logic/auth_logic";
import { useBoolean } from "ahooks";
/**
 * 支付配置
 */
export function Payment(viewPower) {
  const [power, powerFunc] = useBoolean(false);
  const [menuButton, setData] = useState([]);
  useEffect(() => {
    (async function () {
      let r = await new AuthLogic().selButton(viewPower.viewPower);
      if (r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          r[i] = r[i].split("_")[r[i].split("_").length - 1];
        }
      }
      setData(r);
      if (r.indexOf("UPDATE") == -1) {
        powerFunc.setFalse();
      } else {
        powerFunc.setTrue();
      }
    })();

    return () => {};
  }, []);
  return (
    <TabsStyle style="BottomLine">
      <TabList>
        <Tab>
          <span>支付宝配置</span>
          <hr />
        </Tab>
        <Tab>
          <span>微信配置</span>
          <hr />
        </Tab>
        <Tab>
          <span>嘉联聚合配置</span>
          <hr />
        </Tab>
        <Tab>
          <span>国通配置</span>
          <hr />
        </Tab>
        <hr />
      </TabList>
      <TabPanel>
        <div
          style={{
            margin: "" + autoRem(1.9375) + " 0 0 0",
            padding: "1rem",
            boxShadow:
              "0px " +
              autoRem(0.3125) +
              " " +
              autoRem(1.25) +
              " 0px rgba(209, 206, 206, 0.3)",
          }}
        >
          <ZFBForm disabled={!power} />
        </div>
      </TabPanel>
      <TabPanel>
        <div
          style={{
            margin: "" + autoRem(1.9375) + " 0 0 0",
            padding: "1rem",
            boxShadow:
              "0px " +
              autoRem(0.3125) +
              " " +
              autoRem(1.25) +
              " 0px rgba(209, 206, 206, 0.3)",
          }}
        >
          <WXForm disabled={!power} />
        </div>
      </TabPanel>
      <TabPanel>
        <div
          style={{
            // margin: "" + autoRem(1.9375) + " 0 0 0",
            padding: "1rem",
            boxShadow:
              "0px " +
              autoRem(0.3125) +
              " " +
              autoRem(1.25) +
              " 0px rgba(209, 206, 206, 0.3)",
          }}
        >
          <JLJHForm disabled={!power} />
        </div>
      </TabPanel>
      <TabPanel>
        <div
          style={{
            // margin: "" + autoRem(1.9375) + " 0 0 0",
            padding: "1rem",
            boxShadow:
              "0px " +
              autoRem(0.3125) +
              " " +
              autoRem(1.25) +
              " 0px rgba(209, 206, 206, 0.3)",
          }}
        >
          <GTForm disabled={!power} />
        </div>
      </TabPanel>
    </TabsStyle>
  );
}
