import React, { useContext, useState } from "react";
import {
  Button,
  Cascader,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  TreeSelect,
} from "antd";
import styles from "../../style/file_search.module.scss";
import { AlarmSetStore } from "../../store/alarm_set/alarmSet_store";
import { alarmSetAction } from "../../action/alarmSet/file_action";
import { useRequest } from "ahooks";

const { Option } = Select;
const { RangePicker } = DatePicker;

export function AlarmSetSearch({ onSearch }) {
  const { state, dispatch } = useContext(AlarmSetStore);
  const action = alarmSetAction;
  useRequest(() => action.initBasic({ state, dispatch }));
  return (
    <div>
      <Form layout="horizontal">
        <div className={styles.row}>
          <div className={styles.cell}>
            <Form.Item label="选择分类">
              <TreeSelect
                style={{ width: "15rem" }}
                showCheckedStrategy={TreeSelect.SHOW_PARENT}
                dropdownMatchSelectWidth={false}
                defaultValue={state.search.goodsClass}
                value={state.search.goodsClass}
                treeCheckable={true}
                treeData={state.classesList}
                onChange={(v) => {
                  action.setCondition({
                    dispatch,
                    key: "goodsClass",
                    value: v.length > 0 ? v : [],
                  });
                }}
                placeholder="选择商品分类"
              />
              {/* <Cascader style={{ width: "15rem" }} options={state.classesList} placeholder="请选择"
                                onChange={(v) => action.setCondition({ dispatch, key: "classesId", value: v.length > 0 ? [v[v.length - 1]] : [] })}
                            /> */}
            </Form.Item>
          </div>
        </div>
        <div className={styles.row}>
          <Button type="primary" danger onClick={() => onSearch(1)}>
            搜 索
          </Button>
        </div>
      </Form>
    </div>
  );
}
