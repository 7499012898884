import { createContext, useReducer } from "react";

class FormData {
    gradeName: string;
    remark: string;
    option: string;
    label: string;
    value: string;
    sortIndex: string;
}

type Dispatch = { type, payload }

interface IState {
    formData: FormData;
}

const initialArgs = {
    formData: new FormData()
}

export type FormAttribute = "GradeName" | "Remark" | "Option" | "Label" | "Value" | "SortIndex";

interface IContextProps {
    state: IState;
    dispatch: ({ type }: Dispatch) => void;
}

export const FormStore = createContext<IContextProps>(null);

function reducer(state: IState, { type, payload }: Dispatch) {
    switch (type) {
        // 填充表单
        case "SetForm":
            {
                const data = payload;
                const form = new FormData();
                {
                    const { gradeName, remark,option, label, value, sortIndex } = data;
                    form.gradeName = gradeName;
                    form.option = option;
                    form.label = label;
                    form.value = value;
                    form.sortIndex = sortIndex;
                    form.remark = remark;
                }

                return {
                    ...state,
                    formData: form
                }
            }
        // 设置表单
        case "Change_FormData":
            {
                const { attribute, value } = payload, props: any = {};

                const key: FormAttribute = attribute;
                let attri = "";

                switch (key) {
                    case "GradeName":
                        attri = "gradeName";
                        break
                    case "Option":
                        attri = "option";
                        break
                    case "Label":
                        attri = "label";
                        break
                    case "Value":
                        attri = "value";
                        break
                    case "SortIndex":
                        attri = "sortIndex";
                        break
                    case "Remark":
                        attri = "remark";
                        break
                    default:
                        break;
                }

                props[attri] = value;

                return {
                    ...state,
                    formData: {
                        ...state.formData,
                        ...props
                    }
                }
            }
        default:
            return state;
    }
}

export function FormStoreProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialArgs);

    const v = { state, dispatch };
    return <FormStore.Provider value={v}>{props.children}</FormStore.Provider>
}