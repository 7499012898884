import { createContext, useReducer } from "react";

export const FileStore = createContext<IContextProps>(null);
export const FormStore = createContext<IContextProps>(null);
interface IContextProps {
  state: IState;
  dispatch: ({ type }: Dispatch) => void;
}
class TableItem {
  // goodsID: string;
  // goodsSepID: string;
  goodsName: string;
  unitName: string;
  // oldShopPrice: number;
  // oldStorePrice: number;
  newShopPrice: number;
  newStorePrice: number;
  // unitContent: number;
  goodsUnitName: string;
  goodsSource: number
  isShop: boolean
  isStore: boolean
  barCode: string
}
class FormData {
  shopName: string;
  shopID: string[];
  isIssued: boolean = true;
  remark: string;
  goodsSep: TableItem[];
}
type Dispatch = { type; payload };
class Search {
  keywords: string;
  goodsName: string;
  goodsID: string[] = [];
  beginTime: string;
  endTime: string;
}

interface IState {
  formData: FormData;
  search: Search;
  tableList: any[];
  code?:string
  resultList: any[]
}

const initialArgs: IState = {
  formData: new FormData(),
  search: new Search(),
  tableList: [],
  code: '',
  resultList: []
};

function reducer(state: IState, { type, payload }: Dispatch) {
  switch (type) {
    case "SetSearch":
      return {
        ...state,
        search: {
          ...state.search,
          ...payload,
        },
      };
    case "SetSearchList":
      return {
        ...state,
        searchList: payload,
      };
    case "SetResultList":
      return {
        ...state,
        resultList: payload,
      };
    case "SetFormData": {
      return {
        ...state,
        formData: {
          ...state.formData,
          ...payload,
        },
      };
    }
    case "SetForm": {
      const { remark, isIssued, shopID, shopName, goodsSep } = payload;
      const item = new FormData();

      item.shopName = shopName;
      item.shopID = shopID;
      item.isIssued = isIssued;
      item.remark = remark;

      return {
        ...state,
        formData: item,
        tableList: goodsSep,
      };
    }
    case "AddTableItem": {
      const list = state.tableList || [];

      // 去重
      if (list.every((v, i) => v.barCode !== payload.barCode)) {
        const item = new TableItem();
        const {
          goodsName,
          unitName,
          newShopPrice,
          newStorePrice,
          goodsUnitName,
          unitContent,
          goodsSource,
          isShop,
          isStore,
          barCode
        } = payload;
        item.goodsUnitName = goodsUnitName;
        // item.unitContent = unitContent;
        item.goodsName = goodsName;
        item.unitName = unitName;
        item.newShopPrice = newShopPrice;
        item.newStorePrice = newStorePrice ;
        item.goodsSource = goodsSource
        item.isShop = isShop
        item.isStore = isStore
        item.barCode = barCode
        list.push(item);

        list.forEach((v, i) => (v.serial = i));
        return {
          ...state,
          tableList: [...list],
        };
      }
      return state;
    }
    case "DelTableItem": {
      const list = state.tableList || [];

      list.splice(payload, 1);

      return {
        ...state,
        tableList: [...list],
      };
    }
    case "ChangeTableItem": {
      const { index, key, value } = payload;

      const list = state.tableList || [];
      const item = list[index];
      item[key] = value;
      // if (key == "newShopPrice" && Number.isNaN(item.newShopPrice)) {
      //   item.newShopPrice = undefined;
      // }
      // if (key == "newStorePrice" && Number.isNaN(item.newStorePrice)) {
      //   item.newStorePrice = undefined;
      // }

      // if ("newShopPrice" == key) {
      //   item.newShopPrice = value;
      // }

      // if ("newStorePrice" == key) {
      //   item.newStorePrice = value;
      // }

      return {
        ...state,
        tableList: [...list],
      };
    }
    case "ClearTableList":
      return {
        ...state,
        tableList: [],
      };
      case "InputCode":
        return{
          ...state,
          code: payload
        }
    default:
      return {
        ...state,
      };
  }
}
export function UpdatePriceProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialArgs);

  const v: any = { state, dispatch };
  return <FileStore.Provider value={v}>{props.children}</FileStore.Provider>;
}
export function UpdatePriceFromProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialArgs);

  const v: any = { state, dispatch };
  return <FormStore.Provider value={v}>{props.children}</FormStore.Provider>;
}
