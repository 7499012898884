import { createContext, useReducer } from "react";

export class FormData {
  id: string;
  iconPath: string[] = [];
  classesName: string;
  parentID: string;
  addDateTime: null;
  shopShow: boolean = true;
  shopSort: number = 0;
  storeShow: boolean = true;
  storeSort: number = 0;
  subGoodsClassesVM: null;
  goodsSource?: number = 1
  classCode: string
  taxID?: string
}

export type Dispatch = { type; payload };

interface IState {
  taxCodeList: any[];
  parentList: any[];
  formData: FormData;
  loading: boolean;
}

const initialArgs = {
  taxCodeList:[],
  parentList: [],
  loading: false,
  formData: new FormData(),
};

export type FormAttribute =
  | "iconPath"
  | "classesName"
  | "shopShow"
  | "shopSort"
  | "storeShow"
  | "storeSort"
  | "parentID"
  | "classCode"
  | "taxID"

interface IContextProps {
  state: IState;
  dispatch: ({ type }: Dispatch) => void;
}

export const FormStore = createContext<IContextProps>(null);

function reducer(state: IState, { type, payload }: Dispatch) {
  switch (type) {
    case "isLoading":
      state.loading = payload;
      return {
        ...state,
      };
    // 加载分类列表
    case "LoadFirstLevelClasses":
      return {
        ...state,
        parentList: payload,
      };
      // 加载收税编码
    case "LoadTaxCode":
      return {
        ...state,
        taxCodeList: payload,
      };

    // 填充表单
    case "SetForm": {
      const {
        id,
        iconPath,
        classesName,
        parentID,
        shopShow,
        shopSort,
        storeShow,
        storeSort,
        goodsSource,
        classCode,
        taxID
      } = payload;
      const form = new FormData();
      form.id = id;
      form.iconPath = iconPath;
      form.classesName = classesName;
      form.parentID =
        parentID === "" || parentID === null ? "firstlevel" : parentID;
      form.shopShow = shopShow;
      form.shopSort = shopSort;
      form.storeShow = storeShow;
      form.storeSort = storeSort;
      form.goodsSource = goodsSource;
      form.classCode = classCode
      form.taxID = taxID

      return {
        ...state,
        formData: { ...form },
      };
    }
    // 设置表单
    case "Change_FormData": {
      const { attribute, value } = payload,
        props: any = {};

      const key: FormAttribute = attribute;
      let attri = "";

      switch (key) {
        case "iconPath":
          attri = "iconPath";
          break;
        case "classesName":
          attri = "classesName";
          break;
        case "shopShow":
          attri = "shopShow";
          break;
        case "storeShow":
          attri = "storeShow";
          break;
        case "shopSort":
          attri = "shopSort";
          break;
        case "storeSort":
          attri = "storeSort";
          break;
        case "parentID":
          attri = "parentID";
          break;
        case "classCode":
          attri = "classCode";
          break;
        case "taxID":
          attri = "taxID";
          break;
        default:
          break;
      }

      props[attri] = value;
      return {
        ...state,
        formData: {
          ...state.formData,
          ...props,
        },
      };
    }

    default:
      return state;
  }
}

export function FormStoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialArgs);

  const v = { state, dispatch };
  return <FormStore.Provider value={v}>{props.children}</FormStore.Provider>;
}
