import axios from "axios";
import { ConfigApi } from "../../config";

import qs from 'qs'

window['axios'] = axios;

/**
 * api控制器
 */
export class ApiCon {
    /** 获取api */
    static getApi(name: string, ...args): string {
        let reg = /\{.*?\}/g;
        let path: any = ConfigApi.apiPath;
        let url: string = path;
        for (let _o of name.split('.')) {
            url = url[_o];
        }
        if (url) {
            let arr: any;
            arr = url.match(reg)
            for (let i = 0; i < args.length; i++) {
                url = url.replace(arr[i], args[i]);
            }
        }
        return `${ConfigApi.operationDomain}/${url}`;
    }
    /** 获取图片路径 */
    static getImgURl(url: string): string {
        return `${ConfigApi.operationDomain}/${url}`;
    }
    /** 发送请求 */
    static query(url: string, method: "GET" | "POST", data?: any): Promise<any> {
        // //console.log(url, method, data);
        return axios({
            method: method,
            url: url,
            data: data,
        }).then(d => {
            if (d.data.code == 200) {
                return d.data.data;
            } else {
                throw d.data.message;
            }
        });
    }

    /** 添加或更新 */
    static AddOrUpdateInfo(op) {
        return this.query(this.getApi('Agent.AddOrUpdateInfo'), 'POST', qs.stringify(op));
    }
    /** 获取代理商审核信息 */
    static GetInfo(ID: string) {
        return this.query(this.getApi('Agent.GetInfo', ID), 'POST');
    }
    /** 上传执照 */
    static UploadFile(_fromData: FormData) {
        return this.query(this.getApi('Agent.UploadFile'), 'POST', _fromData);
    }
    /** 发送验证码 */
    static SendCode(phone: string, isFirst) {
        return this.query(this.getApi('Agent.SendCode') + `?phone=${phone}&isFirst=${isFirst}`, 'POST');
    }
    /** 检测代理商 */
    static AgentCheck(id: string) {
        return this.query(this.getApi('Agent.AgentCheck', id), 'GET');
    }
}