import { message } from "antd";
// import axios from "axios";
import Cookies from "js-cookie";
import { DataTableAction } from "../../../../component/table/action/data_table_action";
import { Tools, axios } from "../../../../config";
import { GradeChangeLogsLogic } from "../../../../logic/grade_change_logs_logic";

export class FileAction extends DataTableAction {
    async delRow({ dispatch, rowItem }) {
    }
    delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any}) {
    }
    async loadPage({ dispatch, pageIndex, pageSize, search }) {
        this.setLoading({dispatch, loading:true})

        const { seackerKey } = search;
        let param = {
            seackerKey: seackerKey,
            pageIndex: pageIndex,
            pageSize,
            orderBy: '',
            sort: ''
        }
        // 等级列表
        const r = await new GradeChangeLogsLogic().querysList(param);
        if (r.data.statusCode == 200) {
            const dataList = r.data.data.dataSource || [];
            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: dataList, pageSize, loading: false });
        } else{
            this.setLoading({dispatch, loading: false})
        }

    }
    setCondition({key, value, dispatch}) {
        let params = {}
        params[key] = value
            dispatch({ type: "SetSearch", payload: params });
    }
}
export const fileAction = new FileAction();