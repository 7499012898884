import { useBoolean, useRequest } from "ahooks";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Upload,
  Button as AntdButton,
  Modal,
  message,
} from "antd";
import { UploadOutlined, CameraOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect, useState, useRef } from "react";
import { formAction } from "../../action/distribution/file_action";
import { FormStore } from "../../store/distribution/file_store";
import { SteelyardLogic } from "../../../../logic/steelyard_logic";
import { Form as FormAntd } from "antd";
import { autoRem } from "../../../../global.style";
import { Progress } from "../../../../logic/common_logic";
import { AuthLogic } from "../../../../logic/auth_logic";
import { ConfigApi } from "../../../../config";
import Cookies from "js-cookie";
import { Tools, axios } from "../../../../config";
import styles from "../../style/distribution.module.scss";
import { zip } from "../../../../logic/common_logic";
// import axios from "axios";
import camera_png from "../../../../assets/images/goods/ico_dotted_line_camera.png";
const { Option } = Select;

export function Distribution(viewPower) {
  const [form] = FormAntd.useForm();
  const [] = useBoolean(false);
  const action = formAction;
  const { state, dispatch } = useContext(FormStore);
  const [modelList, setDataMode] = useState([]);
  let [fileList, setFileList] = useState([]);
  const [menuButton, setData] = useState([]);
  const [power, powerFunc] = useBoolean(false);
  const [previewVisible, previewFunc] = useBoolean(false);
  const [previewImage, setPreviewImage] = useState(null);
  // console.log(state.formData.backGroundPath);

  fileList = [
    {
      uid: "-1",
      name: "img.png",
      status: "done",
      url: ConfigApi.serviceDomain + state.formData.backGroundPath,
    },
  ];

  const props = {
    maxCount: 1,
    action: ConfigApi.serviceDomain + "/api/Distribution_Settings/UploadImages",
    headers: {
      SYSTOKEN: Cookies.get("token"),'ClientSource':'web',
    },
    data: {},
    // previewFile(file) {
    //   return fetch(
    //     ConfigApi.serviceDomain + "/api/Distribution_Settings/UploadImages",
    //     {
    //       method: "POST",
    //       body: file,
    //     }
    //   )
    //     .then((res) => res.json())
    //     .then(({ thumbnail }) => thumbnail);
    // },
  };
  useEffect(() => {
    (async function () {
      let r = await new AuthLogic().selButton(viewPower.viewPower);
      if (r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          r[i] = r[i].split("_")[r[i].split("_").length - 1];
        }
      }
      setData(r);
      if (r.indexOf("UPDATE") == -1) {
        powerFunc.setFalse();
      } else {
        powerFunc.setTrue();
      }
    })();

    return () => {};
  }, []);

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const labelInValueClick = async (v) => {
    let choose = modelList.filter((k) => {
      return (k.modelCode = v);
    });
    // action.setValue({ dispatch, value: v, attribute: "ModelCode" });
    // action.setValue({
    //   dispatch,
    //   value: choose[0].modelName,
    //   attribute: "ModelName",
    // });
  };
  const initialValue = { key: "" };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    previewFunc.setTrue();
    setPreviewImage(file.url || file.preview);
  };

  useEffect(() => {
    formAction.init({ state: state, dispatch, form });
    setFileList([
      {
        uid: "-1",
        name: "img.png",
        status: "done",
        url: Tools.getImageAddress(state.formData.backGroundPath),
      },
    ]);
    return () => {};
  }, []);
  const inputRef = useRef(null);

  /** 获取图片 */
  const onChange = async (e) => {
    let file = e.target.files[0];

    // 压缩
    file = await zip(file);

    const formData = new FormData();
    formData.append("file", file);

    const r = await axios({
      method: "POST",
      url: Tools.getApiAddress("getImage"),
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data;charset=UTF-8",
        SYSTOKEN: Cookies.get("token"),'ClientSource':'web',
      },
    });

    if (r.data.statusCode == 200) {
      const imgUrl = r.data.data;

      formAction.setValue({
        dispatch,
        attribute: "BackGroundPath",
        value: imgUrl,
      });

      message.success("上传成功");
    } else {
      message.error(r.data.returnMessage);
    }

    inputRef.current.value = "";
  };

  return (
    <>
      <div
        style={{
          margin: "" + autoRem(1.9375) + " 0 0 0",
          padding: "1rem",
          boxShadow:
            "0px " +
            autoRem(0.3125) +
            " " +
            autoRem(1.25) +
            " 0px rgba(209, 206, 206, 0.3)",
        }}
      >
        <FormAntd form={form}>
          <Row gutter={10}>
            <Col span={10}>
              <FormAntd.Item
                name="distribution-oneProfit"
                label="一级利润（%）"
                labelCol={{ span: 8 }}
                required
                rules={[
                  {
                    required: true,
                    message: "请输入一级分润",
                  },
                ]}
              >
                <Input
                  autoComplete="off"
                  placeholder="一级利润(1-99)"
                  type="number"
                  onChange={(v) =>
                    action.setValue({
                      dispatch,
                      value: v.target.value,
                      attribute: "OneProfit",
                    })
                  }
                />
              </FormAntd.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={10}>
              <FormAntd.Item
                name="distribution-twoProfit"
                label="二级利润（%）"
                labelCol={{ span: 8 }}
              >
                <Input
                  autoComplete="off"
                  type="number"
                  placeholder="二级利润(1-99)"
                  onChange={(v) => {
                    action.setValue({
                      dispatch,
                      value: v.target.value,
                      attribute: "TwoProfit",
                    });
                  }}
                />
              </FormAntd.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={10}>
              <FormAntd.Item
                name="distribution-premium"
                label="提现手续费占比（%）"
                labelCol={{ span: 8 }}
              >
                <Input
                  autoComplete="off"
                  type="number"
                  placeholder="手续费占比(0-99)"
                  onChange={(v) => {
                    action.setValue({
                      dispatch,
                      value: v.target.value,
                      attribute: "Premium",
                    });
                  }}
                />
              </FormAntd.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={10}>
              <FormAntd.Item
                name="distribution-minWithdrawalsMoney"
                label="单次最小提现金额"
                labelCol={{ span: 8 }}
              >
                <Input
                  autoComplete="off"
                  type="number"
                  placeholder="单次最小体现金额"
                  onChange={(v) =>
                    action.setValue({
                      dispatch,
                      value: v.target.value,
                      attribute: "MinWithdrawalsMoney",
                    })
                  }
                />
              </FormAntd.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={10}>
              <Form.Item
                name="distribution-backGroundPath"
                label="分享背景图"
                labelCol={{ span: 8 }}
                rules={[
                  {
                    required: true,
                    message: "请选择背景图",
                  },
                ]}
              >
                {state.formData.backGroundPath ? "" : ""}
                {state.formData.fileList ? "" : ""}
                {/* <Image
                  max={1}
                  role="main"
                  value={[]}
                  dispatch={dispatch}
                  imageUrl={state.formData.backGroundPath}
                  disabled={false}
                /> */}
                <div className={styles.img}>
                  <img
                    style={{
                      cursor: false ? "not-allowed" : "pointer",
                    }}
                    src={
                      state.formData.backGroundPath
                        ? Tools.getImageAddress(state.formData.backGroundPath)
                        : camera_png
                    }
                    alt=""
                    onClick={() => {
                      if (false) {
                        return;
                      }
                      inputRef.current.click();
                    }}
                  />
                  <span>点击选择图片</span>
                </div>
                <input
                  style={{ display: "none" }}
                  ref={inputRef}
                  onChange={onChange}
                  type="file"
                  accept="image/*"
                />
                <div style={{color:'#ff4d4f',marginLeft:'10px'}}>建议尺寸375x812</div>
              </Form.Item>
            </Col>
          </Row>
        </FormAntd>
        <Button
          type="primary"
          color="Red"
          style={{ marginLeft: "1.25rem" }}
          onClick={() => {
            action.addSubmit({
              state: state.formData,
              stateh: state,
              dispatch,
              form,
            });
          }}
        >
          保存设置
        </Button>
      </div>
    </>
  );
}
function Image({ max, role, value, imageUrl, dispatch, disabled }) {
  const inputRef = useRef(null);

  const onChange = async (e) => {
    let file = e.target.files[0];

    // 压缩
    file = await zip(file);

    const formData = new FormData();
    formData.append("file", file);

    const r = await axios({
      method: "POST",
      url: Tools.getApiAddress("getImage"),
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data;charset=UTF-8",
        SYSTOKEN: Cookies.get("token"),'ClientSource':'web',
      },
    });

    if (r.data.statusCode == 200) {
      const imgUrl = r.data.data;

      // 保存数据
      if (role == "main") {
        formAction.setValue({
          dispatch,
          attribute: "BackGroundPath",
          value: imgUrl,
        });
      }
      // else {
      //   formAction.setValue({ dispatch, attribute: "SubImages", value: list });
      // }

      message.success("上传成功");
    } else {
      message.error(r.data.returnMessage);
    }

    inputRef.current.value = "";
  };

  return (
    <div className={styles.img_group}>
      <div className={styles.img_list}>
        <div>
          <img
            width={86}
            height={86}
            src={Tools.getImageAddress(imageUrl)}
            alt=""
          />
          <img
            src={camera_png}
            className={styles.camare}
            onClick={(e) => {
              if (disabled) {
                e.preventDefault();
              } else inputRef.current.click();
            }}
            alt=""
          />
          <input
            ref={inputRef}
            type="file"
            onChange={onChange}
            style={{ display: "none" }}
          />
        </div>
      </div>
    </div>
  );
}
