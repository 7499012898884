import { message } from "antd";
// import axios from "axios";
import Cookies from "js-cookie";
import { DataTableAction } from "../../../../component/table/action/data_table_action";
import { Tools, axios } from "../../../../config";
import { RoleLogic } from "../../../../logic/role_logic";

export class RoleAction extends DataTableAction {
    async delRow({ dispatch, rowItem }) {
        const r = await axios({
            method: "GET",
            url: Tools.getApiAddress("role_delete"),
            params: {
                id: rowItem.id
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })

        if (r.data.statusCode == 200) {
            message.success("删除成功")
        }else{
            message.error(r.data.returnMessage)
        }
    }
    delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {
        selectList.map(async ({ id }) => {
            const r = await axios({
                method: "GET",
                url: Tools.getApiAddress("role_delete"),
                params: {
                    id: id
                },
                headers: {
                    SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
                },
            })

            if (r.data.statusCode == 200) {
                message.success("删除成功")
            }else{
                message.error(r.data.returnMessage)
            }
        })
    }
    async loadPage({ dispatch, pageIndex, pageSize,search }) {
        this.setLoading({dispatch,loading: true})
        // 角色定义列表
        const { name } = search;
        const r = await new RoleLogic().querysList({ pageIndex, pageSize,name });
       
        if (r.data.statusCode == 200) {
            const dataList = r.data.data.dataSource;

            const list = dataList.map((v, i) => {
                v.key = i + 1;
                return v;
            })

            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: list, pageSize });
        }  this.setLoading({dispatch,loading: false})

    }
    setCondition(method: "Name"| 'Remark',value, dispatch) {

        if ("Name" == method) {
            dispatch({ type: "SetSearch", payload: { name: value } });
        }
        if ("Remark" == method) {
            dispatch({ type: "SetSearch", payload: { remark: value } });
        }
    }
}
export const roleAction = new RoleAction();