import { message } from "antd";
import { FlowStoredLogic } from "../../../../logic/flowStored_logic";
import { FormAttribute } from "../../store/flowStored/form_store";

type Init = {
    state;
    dispatch;
    form;
    editId: string;
    seeId: string;
}
interface InitBasic {
    dispatch;
    state;
}
interface InitEdit {
    state;
    dispatch;
    form;
    id;
}
interface ISetValue {
    attribute: FormAttribute;
    value: string | number | any[];
    dispatch;
}
export class FormAction {

    // private FlowStoredLogic = new FlowStoredLogic();
    async  addSubmit({state,form,dispatch}){
        let param = {
            memberManagerID: state.formData.vipId,
            topUpMoney: state.formData.money,
            remark: state.formData.remark,
            pay: 4,
            storedType: 3
        }
        if(param.topUpMoney < 0.01){
            message.error('储值金额大于0.01')
            return false
        }
        dispatch({type:'Loading',payload: true})
        const res = await new FlowStoredLogic().addStore(param)
        if(res.data.statusCode == 200){
            if(res.data.data ){
                message.success('储值成功')
                return true
            }
        }else{
            res.data.data&& message.error(res.data.data.returnMessage || '充值失败')
            return false
        }
        dispatch({type:'Loading',payload: false})
    }
    reset({form,dispatch}){
        let item = {
            memberName:'',
            vipId:'',
            money:null,
            remark:''
        }
        dispatch({type:'SetForm',payload:item})
        form.setFieldsValue({
            'member-name':'',
            'member-money':null,
            'member-remark':''
        })
    }
    setValue({ attribute, value, dispatch }: ISetValue) {
        // //console.log(attribute, value, dispatch);
        dispatch({ type: "Change_FormData", payload: { attribute, value } });
    }
}
export const formAction = new FormAction();