import { useState, useEffect } from "react";
import {
  TabList,
  TabsStyle,
  Tab,
  TabPanel,
} from "../../../../component/react-tabs";
import { DataTableStoreProvider } from "../../../../component/table/store/data_table_store";
import { FlowStoreProvider } from "../../store/flowStored/file_store";
import { Flow } from "../../view/flowStored/flow";
import { FlowChargeoff } from '../flowChargeoff/file'
import { useBoolean } from "ahooks";
import {FlowChargeOffProvider} from '../../store/flowChargeoff/file_store'
const firstCom = {
  com:'',
  viewPower:''
}
export function WalletFlow({menu,component}){
    const [menuList,setMenu] = useState([])
    const [isShow,isShowFunc] = useBoolean(true)
    const [currentCom,setCurrent] = useState({
      com:'',
      viewPower:''
    })
    // useEffect(()=>{
    //     (async function () {
    //        let list = menu.children || []
    //        let menuArr = list.filter(m=>!m.hidden)
    //            setMenu(menuArr)
    //            console.log(menuArr)
    //       })();
    //       return () => {};
    // },[])
    useEffect(() => {
        const menu = localStorage.getItem("menu")
          ? JSON.parse(localStorage.getItem("menu"))
          : [];
        menu.map((item) => {
          let arr = [];
          if (item.path == "/member") {
            arr = item.children.filter((member) => member.component == component);
            if (arr.length > 0) {
              setMenu(arr[0].children || []);
              if(arr[0].children[0]){
                  firstCom.com = arr[0].children[0].component
                  firstCom.viewPower = arr[0].children[0].viewPower
                  setCurrent({
                    com:  arr[0].children[0].component,
                    viewPower: arr[0].children[0].viewPower
                  })
              }
              else{
                firstCom.com = 'ChangeoffFlow'
              }
            }
          }
        });
        return () => {};
      }, []);
    return(
        <>
         <TabsStyle style="BottomLine">
        <TabList>
          {menuList.length > 0 ? (
            menuList.map((item) => {
              return (
                <Tab >
                  <span style={{display: 'inline-block',height: '100%'}} onClick={()=>{
                      firstCom.com = item.component
                      firstCom.viewPower = item.viewPower
                    setCurrent({
                      com:  item.component,
                      viewPower: item.viewPower
                    })
                      console.log(firstCom)
                    //  if(firstCom.com == 'Flow' && item.component == 'Flow'){
                    //     isShowFunc.setTrue()
                    //  }else{
                    //   isShowFunc.setFalse()
                    //  }
                  }}>{item.name}</span>
                  <hr />
                </Tab>
              );
            })
          ) : (
            <div></div>
          )}
         
          <hr />
        </TabList>
        </TabsStyle>
        <TabPanel>
          {currentCom.com == 'Flow'?(
             <FlowStoreProvider>
             <DataTableStoreProvider>
                 <Flow viewPower={firstCom.viewPower} />
             </DataTableStoreProvider>
             </FlowStoreProvider>
          ):currentCom.com == 'ChangeoffFlow'?(<FlowChargeOffProvider>
            <DataTableStoreProvider>
                <FlowChargeoff viewPower={firstCom.viewPower} />
            </DataTableStoreProvider>
            </FlowChargeOffProvider> ):<div></div>}
        </TabPanel>
        {/* {menuList.length&&menuList.map(v=>{
               return v.component =='Flow' && isShow? (
                <TabPanel>
                 <FlowStoreProvider>
                    <DataTableStoreProvider>
                        <Flow viewPower={v.viewPower} />
                    </DataTableStoreProvider>
                    </FlowStoreProvider> 
                </TabPanel>
              ) : 
              v.component =='ChangeoffFlow' && !isShow?( <TabPanel>
                 <FlowChargeOffProvider>
                    <DataTableStoreProvider>
                        <FlowChargeoff viewPower={v.viewPower} />
                    </DataTableStoreProvider>
                    </FlowChargeOffProvider> 
                </TabPanel>):null
            
        })} */}
           
        </>
    )
}