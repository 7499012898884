import { Checkbox } from "antd";
import React from "react";
import { ComPopUpWin } from "../../../../component/pop-up-windows/comPopUpWin";
import { IHotsData, IHotsReducerPar, PrecinctCon } from "../../action/HotsAction";
import { SelectUrl } from "../../com/selectUrl";
import { ElementTransform, V2 } from "../../com/trasform";
import { ISelectUrl } from "../modal/modal";
import styles from "./../../style/hots.module.scss";
import { precinctMiniSize } from "./editHost";

/** input改变类型 */
type inputChangeType = "url" | "w" | "h" | "if_new_win_open";

/**
 * 编辑热图选项
 */
export class EditHostPrecinct extends React.Component<
  {
    id: number;
    close: () => void;
    data: PrecinctCon;
    setPrecinct: (id: number, data: PrecinctCon) => void;
    getRootDIvTransform: () => ElementTransform;
  },
  {
    url: ISelectUrl;
    size: V2;
    ifNewWinOpen: boolean;
  }
> {
  constructor(p) {
    super(p);
    this.state = {
      url: null,
      size: new V2(),
      ifNewWinOpen: false,
    };
  }

  componentDidMount() {
    //填充数据
    this.setState({
      url: this.props.data.url,
      size: this.props.data.size.clone().toInt(),
      ifNewWinOpen: this.props.data.ifNewWinOpen,
    });
    //
  }

  /** 输入改变 */
  private inputChange(e, type: inputChangeType) {
    let h: number;
    let w: number;
    switch (type) {
      case "url":
        this.setState({
          //如果手写了url就是个字符串了
          url: e.target.value,
        });
        break;
      case "w":
        w = e.target.value;
        h = this.state.size.y;
        this.setState({
          size: new V2(w, h),
        });
        break;
      case "h":
        h = e.target.value;
        w = this.state.size.x;
        this.setState({
          size: new V2(w, h),
        });
        break;
      case "if_new_win_open":
        this.setState({
          ifNewWinOpen: e.target.checked,
        });
        break;
    }
  }

  /** 选择url改变 */
  private selectUrlChange(select: ISelectUrl) {
    // //console.log("选择url", select);
    this.setState({
      url: select,
    });
  }

  /** 保存 */
  private save(): boolean {
    //console.log("保存修改选项", this.state);
    //重新设置选区数据
    this.props.data.url = this.state.url;
    this.props.data.ifNewWinOpen = this.state.ifNewWinOpen;
    //设置范围在可视区域内且大小瞒住要求
    let rootDivTranform = this.props.getRootDIvTransform();
    this.state.size.x = Math.min(
      this.state.size.x,
      rootDivTranform.size.x - this.props.data.pos.x
    );
    this.state.size.x = Math.max(this.state.size.x, precinctMiniSize.x);
    this.state.size.y = Math.min(
      this.state.size.y,
      rootDivTranform.size.y - this.props.data.pos.y
    );
    this.state.size.y = Math.max(this.state.size.y, precinctMiniSize.y);
    //
    this.props.data.size = this.state.size;
    this.props.setPrecinct(this.props.id, this.props.data);
    //返回true表示通过
    return true;
  }

  /** url字符串表示 */
  private get urlString(): string {
    if (!this.state.url) { return ''; }
    else if (typeof this.state.url == 'string') {
      return this.state.url;
    } else {
      return `goods?key=${this.state.url.key}&type=${this.state.url.type}`;
    }
  }


  render() {
    return (
      <ComPopUpWin pos="top" opacity={0.4}>
        <div
          className={
            styles.edit_host_precinct_pop_up_win + " " + styles.pop_up_win
          }
        >
          <div className={styles.top}>
            <span>编辑热区</span>
            <button
              onClick={() => {
                this.props.close();
              }}
            >
              X
            </button>
          </div>
          <div className={styles.content}>
            <div className={styles.top}>
              <div className={styles.item}>
                <span>链接：</span>
                <div className={styles.url}>
                  <input
                    type="text"
                    value={this.urlString}
                    onChange={(e) => {
                      this.inputChange(e, "url");
                    }}
                  />
                  <SelectUrl
                    ifShowTitle={false}
                    selectedBack={(select: ISelectUrl) => {
                      this.selectUrlChange(select);
                    }}
                  />
                </div>
              </div>
              <div className={styles.item}>
                <span>尺寸：</span>
                <div className={styles.size}>
                  <span>宽</span>
                  <input
                    type="number"
                    value={this.state.size.x}
                    onChange={(e) => {
                      this.inputChange(e, "w");
                    }}
                  />
                  <span>px，高</span>
                  <input
                    type="number"
                    value={this.state.size.y}
                    onChange={(e) => {
                      this.inputChange(e, "h");
                    }}
                  />
                  <span>px</span>
                </div>
              </div>
              <div className={styles.item}>
                <span></span>
                <div className={styles.if_new_win_open}>
                  <Checkbox
                    checked={this.state.ifNewWinOpen}
                    onChange={(e) => {
                      this.inputChange(e, "if_new_win_open");
                    }}
                  />
                  <span>新窗口打开</span>
                </div>
              </div>
            </div>
            <div className={styles.bottom}>
              <button
                onClick={() => {
                  this.save() && this.props.close();
                }}
              >
                确定
              </button>
              <button
                onClick={() => {
                  this.props.close();
                }}
              >
                取消
              </button>
            </div>
          </div>
        </div>
      </ComPopUpWin>
    );
  }
}
