export class ShopEntity {
    addDateTime: string;
    addUserID: string;
    city: string;
    detailedAddress: string;
    district: string;
    helpCode: string;
    id: string;
    linkman: string;
    parentID: string;
    personNumber: number;
    phone: string;
    provincialCapital: string;
    remark: string;
    shopClasses: number;
    shopCode: string;
    shopName: string;
    shopPhone: string;
    status: number;
    updateDateTime: string;
    updateUserID: string;
    usableArea: string;
}