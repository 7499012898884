import {GradeLogic} from '../../../../../logic/grade_logic'
import { MarketingPointsExchangeGoods_logic } from "../../../../../logic/market/MarketingPointsExchangeGoods_logic"
import { ShopLogic } from '../../../../../logic/shop_logic'
import {message } from 'antd'
type Init = {
    state;
    dispatch;
    form;
    id: string;
    method: string;
}
interface InitBasic {
    dispatch;
    search;
}
interface InitEdit {
    state;
    dispatch;
    form;
    id;
}

class FormAction {
    //
    async init({ state, dispatch, id, method, form }: Init) {
        //
        await this.initBasic({ dispatch, search: state.search });
        //
        if ("Create" !== method && id) {
            await this.initEdit({ dispatch, state, id, form })
        }
    }
    private async initBasic({ search, dispatch }: InitBasic) {
        //
        dispatch({ type: "LoadTag", payload: await new GradeLogic().gradeList() });
        
    }
    private async initEdit({ dispatch, id, form }: InitEdit) {
        //回填表单
        let item;
        
      const r =  await MarketingPointsExchangeGoods_logic.GetFirstModel({ID: id})
        if(r&&r.data&&r.data.statusCode == 200){
                item = r.data.data
            }else{
                message.error(r.data&&r.data.returnMessage)
            }

            // let shoplist = await (await new ShopLogic().selListpost()).data.data.dataSource;
            let shopIDarr = []
            let shopName = ''
            if(item){
                // item.shopID&&item.shopID.length&& item.shopID.map(shop=>{
                //     shoplist.map(s=>{if(s.id == shop.shopID){
                //         shopIDarr.push(shop.shopID)
                //         shopName = s.shopName
                //     }})
                // })
                if(item.markShopID && item.markShopID.length > 0){
                    shopIDarr = item.markShopID.map(m=>m.shopID)
                    shopName = item.markShopID.map(m=>m.shopName)
                }
    
            if(item.sep){       
                
                item['shopPrice'] = item.sep.shopPrice
                item['storePrice'] = item.sep.storePrice
                item['sumNumber'] = item.sep.sumNumber
            }
                let gradearr = []
                item.memberID&&item.memberID.length&&item.memberID.map(g=>{
                    gradearr.push(g.memberClassID)
                })
            item['memberClasss'] = gradearr
            item['isLimitCount'] = (item.exchangeCount==null || item.exchangeCount == 0)?1:2
            item['shopID'] =   shopIDarr
            item['shopName'] = shopName  
            item['isMemberClass'] = item.isMemberClass==true?2:1
    
            dispatch({ type: "SetForm", payload: item });
    
            form.setFieldsValue({
                'exchangeType': item.exchangeType,
                'exchangePoints1': item.exchangeType==1?item.exchangePoints:null,
                'exchangePoints2': item.exchangeType==2?item.exchangePoints:null,
                'exchangeMoney':item.exchangeMoney,
                'issueCount': item.issueCount,
                'exchangeCount': item.exchangeCount,
                'isMemberClass': item.isMemberClass,
                // 'memberClasss': item.memberClasss,
                'isLimitCount': item.isLimitCount,
                'shopName': item.shopName,
                'takeType': item.takeType,
                'takeBeginDate':item.takeBeginDate?item.takeBeginDate:'',
                'takeEndDate': item.takeEndDate?item.takeEndDate:'',
                'shippingFees': item.shippingFees
            })
            }
           
        
    }
    async addSubmit({ state, status },id) {
        const {formData} = state
        const params = {
            id:id,
            exchangeType: formData.exchangeType,
            exchangePoints: formData.exchangePoints,
            exchangeMoney:formData.exchangeMoney,
            issueCount: formData.issueCount,
            exchangeCount: formData.exchangeCount,
            isMemberClass: formData.isMemberClass == 1?false:true,
            memberClasss: formData.memberClasss,
            shopID: formData.shopID,
            takeType: formData.takeType,
            takeBeginDate:formData.takeBeginDate?formData.takeBeginDate:'',
            takeEndDate: formData.takeEndDate?formData.takeEndDate:'',
            shippingFees: formData.shippingFees
        }
        return await MarketingPointsExchangeGoods_logic.SetExchangeRules(params)
      
    }

    async updSubmit({ state, status }, id) {
        const {formData} = state
        const params = {
            id:id,
            exchangeType: formData.exchangeType,
            exchangePoints: formData.exchangeType==1?formData.exchangePoints1:formData.exchangePoints2,
            exchangeMoney:formData.exchangeMoney==null?0:formData.exchangeMoney,
            issueCount: formData.issueCount,
            exchangeCount: formData.exchangeCount,
            isMemberClass: formData.isMemberClass == 1?false:true,
            memberClasss: formData.isMemberClass == 1?[]:formData.memberClasss,
            shopID: formData.shopID,
            takeType: formData.takeType ? formData.takeType : 1,
            takeBeginDate:formData.takeBeginDate?formData.takeBeginDate:'',
            takeEndDate: formData.takeEndDate?formData.takeEndDate:'',
            shippingFees: formData.shippingFees
        }
        const r = await MarketingPointsExchangeGoods_logic.SetExchangeRules(params)
        if(r&&r.data&&r.data.statusCode == 200){
            message.success('设置成功')
            return true
        }else{
            message.error(r.data&&r.data.returnMessage || r.data.message || '设置失败')
        return false;

        }
    }

    clearTableList({ dispatch }) {
        dispatch({ type: "ClearTableList" });
    }

    setFormData({ dispatch, key, value }) {
        const params = {
        }
        params[key] = value;
        dispatch({ type: "SetFormData", payload: params })
    }

    reset({ dispatch }) {
        //
    }
}
export const formAction = new FormAction();