import React, { useContext, useState } from "react";
import { Button, Checkbox, Col, DatePicker, Form, Input, Row, Select } from "antd";
import styles from "../../style/file_search.module.scss";
import { fileAction } from "../../action/promotion/file_action";
import { FileStore } from "../../store/promotion/file_store";

const { Option } = Select;

export function FileSearch({ onSearch }) {
    const { state, dispatch } = useContext(FileStore);
    const action = fileAction;

    return (
        <div>
            <Form layout="horizontal">
                <div className={styles.row}>
                    <div className={styles.cell}>
                        <Form.Item label="使用途径">
                            <Select value={state.search.purposeType} style={{ width: '15rem' }} onChange={(value) => {
                                action.setCondition({
                                    dispatch,
                                    key: 'purposeType',
                                    value,
                                });
                            }}>
                                <Option value={3}>全部</Option>
                                <Option value={1}>门店收银</Option>
                                <Option value={2}>线上商城</Option>
                            </Select>
                        </Form.Item>
                    </div>
                </div>
                <div className={styles.row}>
                    <Button type="primary" danger onClick={() => onSearch()}>搜  索</Button>
                </div>
            </Form>
        </div>
    )
}