import { createContext, useContext, useReducer } from "react";

export const ChooseCouponStore = createContext<IContextProps>(null);
interface IChildItems {
  name: string;
  id: string;
  pid: string;
  children: any;
}
interface IState {
  treeData: any[];
  fresh: number;
  id: string;
}
interface IContextProps {
  state: IState;
  dispatch: ({ type }: Dispatch) => void;
}
const initialArgs: IState = {
  treeData: [
    {
      name: "手机",
      value: 6,
      symbolSize: [20, 20],
      cusField: "category",
      symbol:
        "image://http://www.iconpng.com/png/ecommerce-business/iphone.png",
      itemStyle: {
        normal: {
          label: {
            show: true,
            position: "right",
            formatter: "{b}",
          },
        },
      },
      children: [],
    },
  ],
  fresh: 0,
  id: "",
};
type Dispatch = { type; payload };

function reducer(state: IState, { type, payload }: Dispatch) {
  switch (type) {
    case "addChildren":
      let tree = state.treeData || [];
      let index = payload.index;

      if (payload.data instanceof Array) {
        state.treeData = [...payload.data] || [];
        state.fresh = payload.data.length;
      }
      if (index == 0) {
        tree[0].children = payload.data;
      } else if (index == 1 && tree[0].children) {
        let arr = tree[0].children.map((item) => {
          let obj = item;
          if (item.id == payload.id) {
            obj["children"] = payload.data;
          }
          return obj;
        });
        tree[0].children = arr;
      }
      state.treeData = tree;
      // console.log(payload);
      state.id = payload.id;
      return {
        ...state,
      };
    case "getData":
      state.treeData = payload;
      return {
        ...state,
      };
    default:
      return state;
  }
}
export function TreeStoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialArgs);

  const v = { state, dispatch };
  return (
    <ChooseCouponStore.Provider value={v}>
      {props.children}
    </ChooseCouponStore.Provider>
  );
}
