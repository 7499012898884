import { useBoolean } from "ahooks";
import { Control, Del, Edit, See, Stop, Enable } from "../../../../component/control-column";
import { ColumnsType } from "antd/lib/table";
import { useContext, useEffect, useState } from "react";
import { ConfirmModal } from "../../../../component/modal";
import { Button, message, Input, Tag } from "antd";
import { DataTable } from "../../../../component/table";
import { DataTableStore } from "../../../../component/table/store/data_table_store";
import { Progress, defualtPageSize } from "../../../../logic/common_logic";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { Search } from "../../../../component/search";
import { fileAction } from '../../action/commissionRule/file_action'
import { formAction } from '../../action/commissionRule/form_action'
import { FileStore } from "../../store/commissionRule/file_store";
import { FormStoreProvider } from '../../store/commissionRule/form_store';
import { Form } from './form'
import { AuthLogic } from "../../../../logic/auth_logic";

const formParams = {
    method: "Create",
    id: "",
    shopClasses: "",
    isdefault: false,
  };
  /**收银提成规则 */
export function CommissionRule(viewPower){
    const [createState, createFunc] = useBoolean(false);
    const [confirmState, confirmFunc] = useBoolean(false);
    const action = fileAction
    const {state, dispatch} = useContext(FileStore)
    const { state: tableState, dispatch: tableDispatch} = useContext(DataTableStore)
    const [menuButton, setData] = useState([]);
    useEffect(() => {
      (async function () {
        let r = await new AuthLogic().selButton(viewPower.viewPower);
        if (r && r.length > 0) {
          for (let i = 0; i < r.length; i++) {
            r[i] = r[i].split("_")[r[i].split("_").length - 1];
          }
        }
        setData(r);
      })();
  
      return () => {};
    }, []);
    const columns: ColumnsType = [
      {
        title: "操作",
        // dataIndex: 'id',
        key: "1",
        align: "center",
        width: Control.width(4),
        render: Control.render(
          (v) =>
            menuButton.indexOf("VIEW") != -1 && (
              <See
                click={() => {
                  formParams.id = v.id;
                  formParams.method = "Preview";
                  createFunc.setTrue();
                }}
              />
            ),
          (v) =>
            menuButton.indexOf("UPDATE") != -1 && (
              <Edit
                click={() => {
                  formParams.id = v.id;
                  formParams.method = "Edit";
                  createFunc.setTrue();
                }}
              />
            ),
          (v) => //禁用
            menuButton.indexOf("STOP") != -1 && v.status == 1 && (
              <Stop
                click={() => {
                  formParams.id = v.id;
                  formParams.method = "Stop";
                  confirmFunc.setTrue();
                  // createFunc.setTrue();
                }}
              />
            ),
          (v) => //启用
            menuButton.indexOf("ENABLE") != -1 && v.status == 2 && (
              <Enable
                click={() => {
                  formParams.id = v.id;
                  formParams.method = "Enable";
                  confirmFunc.setTrue();
                  // createFunc.setTrue();
                }}
              />
            ),
          (v) =>
            menuButton.indexOf("DELETE") != -1 && (
              <Del
                click={() => {
                  formParams.id = v.id;
                  formParams.method = "DELETE";
                  confirmFunc.setTrue();
                }}
              />
            )
        ),
      },
      {
        title: '规则编号',
        dataIndex: 'ruleNumber',
        key: '2',
        align: 'center',
      },
      {
        title: '规则名称',
        dataIndex: 'ruleName',
        key: '3',
        align: 'center',
      },
      {
        title: '提成方式',
        dataIndex: 'ctype',
        key: '4',
        align: 'center',
        width: 100,
        render: v=>{ return v == 1?<Tag color="cyan">固定金额</Tag>: v == 2 ? <Tag color="blue">百分点</Tag>: <span>-</span>}
      },
      {
        title: '状态',
        dataIndex: 'status',
        key: '5',
        align: 'center',
        width: 80,
        render: v=>{ return v == 1?<Tag color="success">启用</Tag>: v == 2 ? <Tag color="error">禁用</Tag>: <Tag>删除</Tag>}
      },
      {
        title: '创建人',
        dataIndex: 'addUserName',
        key: '6',
        align: 'center',
      },
      {
        title: '创建时间',
        dataIndex: 'addTime',
        key: '7',
        align: 'center',
      },
    ]
    const onSearch = async (pageIndex) => {
        await action.loadPage({
          pageIndex: pageIndex,
          pageSize: defualtPageSize(),
          dispatch: tableDispatch,
          search: state.search,
        });
      };
    return(
        <>
      <Search>
        <Input
          maxLength={20}
          allowClear
          placeholder="请输入名称、规则编号"
          onChange={(e) =>
            action.setCondition({dispatch, key: 'seacherKey',value: e.target.value})
          }
          onPressEnter={()=> onSearch(1)}
        />
        <input type="button" value="快速查找" onClick={() => onSearch(1)} />

        {/* <TipsGroup>
          <input
            type="button"
            value="高级查找"
            onClick={() => searchFunc.toggle()}
          />
          <Tips width="45rem" display={searchState}>
            <FileSearch
              onSearch={(...arg: [any]) => {
                onSearch(...arg);
                searchFunc.setFalse();
              }}
            />
          </Tips>
          <Mask visible={searchState} onClick={() => searchFunc.setFalse()} />
        </TipsGroup> */}
      </Search>

      <ButtonPanel>
        {menuButton.indexOf("ADD") != -1 && (
          <Button
            onClick={() => {
              formParams.id = "";
              formParams.method = "Create";
              createFunc.setTrue();
            }}
          >
            新建提成规则
          </Button>
        )}
        
        {menuButton.indexOf("EXPORT") != -1 && <Button>导出</Button>}
      </ButtonPanel>

      <DataTable
        columns={columns}
        action={fileAction}
        onLoad={async () => {
          await onSearch(1);
          Progress.done();
        }}
        onChange={(pageIndex) => {
          onSearch(pageIndex);
        }}
        scroll={{ scrollToFirstRowOnChange: true, autoY: true }}
        selectColumn={true}
        loading={tableState.loading}
      />

      {createState && (
        <FormStoreProvider>
          <Form
            close={async (hasUpdate) => {
              if (hasUpdate) {
                await onSearch(tableState.current);
              }

              createFunc.setFalse();
            }}
            action={formAction}
            id={formParams.id}
            method={formParams.method}
          />
        </FormStoreProvider>
      )}

      <ConfirmModal
        visible={confirmState}
        title="温馨提示"
        subTitle={formParams.method == 'DELETE'?"确认删除？":formParams.method == 'Stop'?'确认禁用？':'确认启用？'}
        message={formParams.method == 'DELETE'?"删除之后信息无法恢复，请慎重考虑": formParams.method == 'Stop'?'禁用之后不再生效，请慎重考虑':'启用后规则将生效，请慎重考虑'}
        close={async (r) => {
          if (r) {
            const hide = message.loading('修改中', 0)
              if(formParams.method == 'DELETE'){
                await action.changeStatus(formParams.id, 3)
              }else if(formParams.method == 'Stop'){
                await action.changeStatus(formParams.id, 2)
              } else if(formParams.method == 'Enable'){
                await action.changeStatus(formParams.id, 1)
              }
           hide()
           onSearch(tableState.current)
          }
          confirmFunc.setFalse();
        }}
      />
    </>
    )
}