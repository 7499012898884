import { message } from "antd";
// import axios from "axios";
import Cookies from "js-cookie";
import { DataTableAction } from "../../table/action/data_table_action";
import { Tools, axios } from "../../../config";
import { ShopLogic } from "../../../logic/shop_logic";
import { ClassesLogic } from "../../../logic/classes_logic";
import { GoodsLogic } from "../../../logic/goods_logic";
class ChooseAction extends DataTableAction {
    async delRow(id) {
       
    }
    delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {
      
    }
   
  
 
     async loadPage({ dispatch, pageIndex, pageSize, search }) {
        const { keywords } = search;
        const params: any = {
            "PageIndex": pageIndex,
            "PageSize": pageSize,
            "key":keywords || '',
        }
        // 档案列表
       const r = await new GoodsLogic().getImageList(params)
        if (r.data.code == 200) {
            const dataList = r.data.data.Items || [];        
            let arr = []
            let len = dataList.length
            let lineNum = len % 10 === 0 ? len / 10 : Math.floor( (len / 10) + 1 )
            let res = [];
            for (let i = 0; i < lineNum; i++) {
              let temp = dataList.slice(i*10, i*10+10);
              res.push(temp);
            }
            // dispatch({ type: "SetList", payload: { dataList: value } });
            this.setPaging({ dispatch, pageIndex, total: r.data.data.TotalItems, dataList: res, pageSize });
        }

    }

    setCondition(method: "Keywords" | "Classes" | "Status", value, dispatch) {
        if ("Keywords" == method) {
            dispatch({ type: "SetSearch", payload: { keywords: value } });
        }
        else if ("Classes" == method) {
            dispatch({ type: "SetSearch", payload: { classesId: value } });
        }
        else if ("Status" == method) {
            dispatch({ type: "SetSearch", payload: { statusId: value } });
        }else if("SetList" == method) {
            dispatch({ type: "SetList", payload: { dataList: value } });
        }
    }
    // setSelectListCard({dispatch,selectKeys}){
    //     this.setSelectList({dispatch,selectKeys})
    // }
  
}
export const chooseGoodsAction = new ChooseAction();