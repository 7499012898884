import { message } from "antd";
// import axios from "axios";
import Cookies from "js-cookie";
import { DataTableAction } from "../../../../component/table/action/data_table_action";
import { Tools, axios } from "../../../../config";
import { SwitchParamLogic } from "../../../../logic/switch_params_logic";

export class FileAction extends DataTableAction {
    async delRow({ dispatch, rowItem }) {
        const r = await axios({
            method: "GET",
            url: Tools.getApiAddress("dict_delete"),
            params: {
                id: rowItem.id
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })

        if (r.data.statusCode == 200) {
            message.success("删除成功")
        } else {
            message.error(r.data.returnMessage)
        }
    }
    delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {
        selectList.map(async ({ id }) => {
            const r = await axios({
                method: "GET",
                url: Tools.getApiAddress("dict_delete"),
                params: {
                    id: id
                },
                headers: {
                    SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
                },
            })

            if (r.data.statusCode == 200) {
                message.success("删除成功")
            } else {
                message.error(r.data.returnMessage)
            }
        })
    }
    async loadPage({ dispatch, pageIndex, pageSize, search }) {
        const { keywords } = search;
        let param = {
            queryText: keywords,
            pageIndex: pageIndex,
            pageSize: pageSize,
            orderBy: '',
            sort: ''

        }
        // 字典列表
        const r = await new SwitchParamLogic().queryPage();

        if (r.data.statusCode == 200) {
            const dataList = r.data.data.dataSource;

            const list = dataList.map((v, i) => {
                v.key = i + 1;
                return v;
            })

            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: list, pageSize });
        }

    }
    setCondition(method: "Keywords", value, dispatch) {

        if ("Keywords" == method) {
            dispatch({ type: "SetSearch", payload: { keywords: value } });
        }
    }

    async init({dispatch}){
        const r = await new SwitchParamLogic().queryPage();
        if(r&&r.data&&r.data.statusCode == 200){
            let arr = []
            r.data.data.map(item=>{
                item.list.length&&item.list.map(l=>{
                    
                })
            })
            dispatch({type:'Init',payload:r.data.data})
            // return r.data.data
        }
        // else{
        //     message.error('请求失败')
        // }
        }
    async Chagne(data,checked,dispatch,name?){
        let em = 1;
        let val = checked?'1':'0'
        // if(data){
            
        //     switch (data.key){
        //         case 'PaymentType':
        //             em = 1;
        //             val = checked?'Online':'Offline'
        //             break;
        //         case 'IsOpenExtendScreen':
        //                 em = 2;
        //                 val = checked?'1':'0'
        //                 break;
        //         case 'IsJLPay':
        //             em = 5;
        //               val = checked?'1':'0';
        //               break;
        //         case 'IsOpenOrderManualOffers':
        //             em =4;
        //             val = checked?'1':'0';
        //             break;
        //     }
        // }
        if(data.emKey == 1){
            val = checked
        }
        const param = {em:data.emKey,val:val}
     const r =   await new SwitchParamLogic().setParams(param)
        if(r.data.statusCode == 200){
            await this.init({dispatch})
            message.success('修改成功')
        }else{
            await this.init({dispatch})
            message.error(r.data.returnMessage)
        }
    }
}
export const fileAction = new FileAction();