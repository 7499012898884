import {
  Checkbox,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
  TimePicker,
  DatePicker,
  message,
  Tag,
  Spin,
} from "antd";
import { Button } from "../../../../component/button";
import { TextArea } from "../../../../component/text-area";
import { ColumnsType } from "antd/lib/table";
import { Table } from "../../../../component/table";
import React, { useContext, useRef } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { ChooseGoodsSpec } from "../../../../component/choose-goods-spec";
import { ChooseShop } from "../../../../component/choose-shop";
import { FormSubtitle } from "../../view/form_subtitle";
import styles from "../../style/form_basic.module.scss";
import { autoRem } from "../../../../global.style";
import { FormStore } from "../../store/update_price_store";
import { useBoolean } from "ahooks";
import { fileAction } from "../../action/update_price_action";
import img_del from "../../../../assets/images/shanchu.png";
import { useState } from "react";
import { ConfirmModal } from "../../../../component/modal";
import { Tools } from "../../../../config";
import axios from "axios";
import Cookies from "js-cookie";
export function DataTable({ dataList, disabled }) {
  const { dispatch } = useContext(FormStore);
  const action = fileAction;
  const [current, setCurrent] = useState(1);
  const [pageSize, setSize] = useState(10);
  const columns: ColumnsType = [
    {
      title: "操作",
      dataIndex: "serial",
      key: "1",
      align: "center",
      width: 100,

      render: (v, r, i) => (
        <div>
          <img
            src={img_del}
            style={{ width: 20, cursor: "pointer" }}
            onClick={() => {
              !disabled && action.delTableItem(dispatch, i);
            }}
            alt="删除"
          />
        </div>
      ),
    },
    {
      title: "规格条码",
      dataIndex: "barCode",
      key: "2",
      align: "center",
      width: 120,
    },
    {
      title: "商品名称",
      dataIndex: "goodsName",
      key: "3",
      align: "center",
      width: 150,
    },
    {
      title: "商品来源",
      dataIndex: "goodsSource",
      key: "4",
      align: "center",
      width: 100,
      render:(v,r)=>{
        return<>
            {v == 1 && <Tag color="blue">自定义</Tag>}
            {v == 2 && <Tag color="red">仓库采购</Tag>}
        </>
      }
    },
    {
      title: "单位",
      dataIndex: "unitName",
      key: "5",
      align: "center",
      width: 120,
    },
    // {
    //   title: "商品规格",
    //   key: "7",
    //   align: "center",
    //   width: 120,
    //   render: (v) => {
    //     return (
    //       <span>
    //         {v.unitContent}/{v.goodsUnitName}
    //       </span>
    //     );
    //   },
    // },
   

    {
      title: "新门店价格",
      dataIndex: "newShopPrice",
      key: "9",
      align: "center",
      width: 120,
      render: (e, r, i) => {
        return (
          <Input
            style={{ width: 100 }}
            value={e}
            disabled={disabled}
            type='number'
            min={0}
            onChange={(e) =>
              action.changeTableItem({
                dispatch,
                key: "newShopPrice",
                value: e.target.value,
                index: i + (current - 1) * pageSize,
              })
            }
          />
        );
        // return <div>***</div>;
      },
    },
    {
      title: "新商城价格",
      dataIndex: "newStorePrice",
      key: "8",
      align: "center",
      width: 120,
      render: (e, r, i) => {
        return (
          <Input
            style={{ width: 100 }}
            value={e}
            disabled={disabled}
            onChange={(e) =>
              action.changeTableItem({
                dispatch,
                key: "newStorePrice",
                value: e.target.value,
                index: i + (current - 1) * pageSize,
              })
            }
          />
        );
        // return <div>***</div>;
      },
    },
  ];

  return (
    <Table
      dataSource={dataList}
      columns={columns}
      scroll={{ y: 300 }}
      onChange={(pagination) => {
        setCurrent(pagination.current);
        setSize(pagination.pageSize);
      }}
    />
  );
}

export function FormBasic({ disabled, title, form, updateFormField }) {
  const action = fileAction;
  const [goodsState, goodsFunc] = useBoolean(false);
  const [shopState, shopFunc] = useBoolean(false);
  const { state, dispatch } = useContext(FormStore);

 //下载导入模板
 const [downloadState, downloadFunc] = useBoolean(false);
 const [importState, importFunc] = useBoolean(false);
const [loadState, loadFunc] = useBoolean(false)
  const { formData } = state;
  let height = document.body.clientHeight - 188;

  const inputRef = useRef(null);
  const onChange = async (e) => {
    let file = e.target.files[0];
    // console.log(file);

    // 压缩
    // file = await zip(file);

    const formData = new FormData();

    formData.append("file", file);
    // new Blob()
    loadFunc.setTrue()
    const r = await axios({
      method: "POST",
      url: Tools.getApiAddress("goods_updateprice_import"),
      data: formData,
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        SYSTOKEN: Cookies.get("token"),'ClientSource':'web',
      },
    });
    console.log(r)
    if (r.data.statusCode == 200) {
      let list =  r.data.data || []
      if(list.length > 0){
          message.success("导入模板成功");
          action.addTableItem({ dispatch, list: list });
      }else{
        message.error('查询商品失败')
      }
      loadFunc.setFalse()
    } else {
      message.error(r.data.returnMessage || "导入失败");
      loadFunc.setFalse()
    }
    inputRef.current.value = "";
    // onSearch(1);
  };
  return (
    <Spin  spinning={loadState} delay={500}>
    <div className={styles.root} style={{ height: height + "px" }}>
      <div className={styles.content}>
        <FormSubtitle>基础信息</FormSubtitle>

        <div
          style={{
            margin: "" + autoRem(1.9375) + " " + autoRem(1.25) + " 0 0",
          }}
        >
        
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                label={<span className={styles.alarm}>分配门店</span>}
                labelCol={{ span: 3 }}
                name="shopName"
                rules={
                  [
                    //   {
                    //     required: true,
                    //     message: "请选择店铺",
                    //   },
                    //   {
                    //     required: true,
                    //     validator: (_, __, callBack: (error?: string) => void) => {
                    //       if (
                    //         state.formData.shopList == undefined ||
                    //         state.formData.shopList.length == 0
                    //       ) {
                    //         callBack("请选择分配门店");
                    //         return;
                    //       }
                    //       callBack();
                    //     },
                    //   },
                  ]
                }
              >
                {formData.shopName ? "" : ""}
                <Input
                  style={{ width: "400px" }}
                  disabled={disabled}
                  value={formData.shopName}
                  autoComplete="off"
                  placeholder="请选择门店"
                  onClick={() => shopFunc.setTrue()}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                label={<span className={styles.alarm}>下发到秤</span>}
                labelCol={{ span: 3 }}
                name="isIssued"
                rules={
                  [
                    // {
                    //   required: true,
                    //   validator: (_, __, callBack: (error?: string) => void) => {
                    //     if (!formData.snappedName) {
                    //       callBack("请输入活动名称");
                    //       return;
                    //     }
                    //     callBack();
                    //   },
                    // },
                  ]
                }
              >
                <Checkbox
                  checked={formData.isIssued}
                  disabled={disabled}
                  onChange={(e) => {
                    console.log(e);
                    action.setFormData({
                      dispatch,
                      key: "isIssued",
                      value: e.target.checked,
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item labelCol={{ span: 3 }} label={<span>备注说明</span>}>
                <TextArea
                  disabled={disabled}
                  value={formData.remark}
                  maxLength={150}
                  rows={1}
                  style={{ resize: "none" }}
                  placeholder="请输入备注说明"
                  onChange={(e) => {
                    action.setFormData({
                      dispatch,
                      key: "remark",
                      value: e.target.value,
                    });
                  }}
                ></TextArea>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item labelCol={{ span: 3 }} label={<span>扫码获取商品</span>}>
              <Input style={{width: '50%'}}
                  autoFocus
                  allowClear
                  disabled={disabled}
                  placeholder="扫描商品条形码，快速添加"
                  value={state.code}
                  onPressEnter={async (e) => {
                    await action.getGoodsByBarcode({
                      barCode: e.currentTarget.value,
                      dispatch,
                      state,
                    });
                    dispatch({ type: "InputCode", payload: "" });
                  }}
                  onChange={(e) => {
                    dispatch({ type: "InputCode", payload: e.target.value });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 3 }}
                label={<span className={styles.alarm}>活动商品</span>}
              >
                <div
                  style={{
                    fontSize: "10px",
                    width: "60px",
                    height: "30px",
                    textAlign: "center",
                    lineHeight: "30px",
                    background: "#ff746d",
                    color: "#fff",
                    cursor: "pointer",
                  }}
                  onClick={() => !disabled && goodsFunc.setTrue()}
                >
                  添加商品
                </div>
                <ButtonPanel>
                  <Button onClick={() => downloadFunc.setTrue()}>下载导入模板</Button>
                  <Button onClick={() => inputRef.current.click()}>导入商品</Button>
                  <span style={{marginLeft:10}} className={styles.alarm}>根据商品规格为条码匹配商品</span>
                    <input
                      ref={inputRef}
                      type="file"
                      onChange={onChange}
                      style={{ display: "none" }}
                    />
                </ButtonPanel>
              </Form.Item>
            </Col>
          </Row>
         
          <ChooseShop
            visibleModal={shopState}
            type='checkbox'
            close={(status, r) => {
              if (r) {
                let arr = [];
                let shopName = []
                for (let i = 0; i < r.length; i++) {
                  arr.push(r[i].id);
                  shopName.push(r[i].shopName)
                }
                action.setFormData({ dispatch, key: "shopID", value: arr });
                action.setFormData({
                  dispatch,
                  key: "shopName",
                  value: shopName.toString(),
                });
              }
              updateFormField("shopName");
              shopFunc.setFalse();
            }}

          />
          {/* <ChooseShop
            visibleModal={shopState}
            close={(status, r) => {
              if (r) {
                action.setFormData({
                  dispatch,
                  key: "shopName",
                  value: r[0].shopName,
                });
                action.setFormData({ dispatch, key: "shopID", value: r[0].id });
              }
              updateFormField("shopName");
              shopFunc.setFalse();
            }}
          /> */}
          <ChooseGoodsSpec
            visibleModal={goodsState}
            filter={{
              source: "PC",
              type: true,
            }}
            rowKey='barCode'
            close={(status, r) => {
              if (r) {
                action.addTableItem({ dispatch, list: r });
              }
              goodsFunc.setFalse();
            }}
          />
        </div>
        <DataTable dataList={state.tableList} disabled={disabled} />
      </div>
      <ConfirmModal
          visible={downloadState}
          title="温馨提示"
          subTitle="确认下载导入模板？"
          message=""
          close={async (r) => {
            if (r) {
              // await action.downloadMode();
              // onSearch(1);
              window.open(Tools.getApiAddress("goods_updateprice_download"), "_self");
            }
            downloadFunc.setFalse();
          }}
        />
        <ConfirmModal
          visible={importState}
          title="温馨提示"
          subTitle="确认导入商品？"
          message="请使用导入模板填写商品信息，再导入"
          close={async (r) => {
            if (r) {            
              inputRef.current.click();
            }
            importFunc.setFalse();
          }}
        />

    </div>
    </Spin>
  );
}
