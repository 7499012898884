import { message } from "antd";
import { FapiaoReceiptLogic } from "../../logic/fapiao_logic";
class FaPiaoAction {
    /**提交申请 */
    async submit(params){
        const hide =   message.loading('数据提交中', 0)
        const r = await new FapiaoReceiptLogic().applyFapiao(params)
        hide()
        if(r.data.statusCode == 200){
            return r.data.data
          }else{
            message.error(r.data.returnMessage)
            return false
          }
    }
    /**检查是否已开票  */
    async checkhasApply(id){
        const r = await new FapiaoReceiptLogic().applyFapiaoResult(id)
        console.log(r)
        if(r.data.statusCode == 200){
            return r.data.data
        }else{
            message.error(r.data.returnMessage)
            return false
        }
    }
    /**开票记录 */
    async applyFapiaoLogs(SeacherKey){
        const r = await new FapiaoReceiptLogic().applyFapiaoLogs(SeacherKey)
        if(r.data.statusCode == 200){
            return r.data.data
        }else{
            message.error(r.data.returnMessage || '请求失败，请重新尝试')
            return false
        }
    }
    async reSendFapiaoByLogID(LogID){
            const r = await new FapiaoReceiptLogic().resendFaPiao(LogID)
            if(r.data.statusCode == 200){
                message.success('处理成功，请至邮箱查看')
            }else{
                message.error(r.data.returnMessage)
            }
    }
    async convertPdfToPng(path){
        const r = await new FapiaoReceiptLogic().convertPdfToPng(path)
        if(r.data.statusCode == 200){
            return r.data.data || []
        }else{
            // message.error('获取图片失败，请重新尝试')
            return false
        }
    }
}
export const fileAction =  new FaPiaoAction()