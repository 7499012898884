// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../../config";
import { effeative } from "../common_logic";
interface ISelList{

}
interface IGoodsParam{
pageIndex: number
pageSize: number
goodsName?: string     
orderBy?: string
sort?: string  
}
export class PurchaseLogic {
    /**
     * 库存管理 -- 采购列表
     * @param params 
     */
    async selList(params: ISelList) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("purchase_select_list"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }

    /**
     * 添加采购单
     */
    async addPurchase(params){
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("purchase_add_data"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    } 
      /**
     * 修改采购单
     */
      async updatePurchase(params){
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("purchase_update_data"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    } 
       /**
         * 获取采购单详情
        */
       async getPurchase(purchaseID: String){
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("purchase_select_data"),
            params: {
                purchaseID
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    } 

     /**
         * 删除采购单
        */
     async delPurchase(ID: String){
        const r = effeative(await axios({
            method: "get",
            url: Tools.getApiAddress("purchase_delete_data"),
            params: {
                ID: ID
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    } 
     /**
         * 审核采购单
        */
     async auditPurchase(purchaseID: String){
        const r = effeative(await axios({
            method: "get",
            url: Tools.getApiAddress("purchase_audit_data"),
            params: {
                purchaseID
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    } 
   

    /**
     * 获取商品列表
     */
    async getGoodsList(data: IGoodsParam){
        const r = effeative(await axios({
            method: "post",
            url: Tools.getApiAddress("wh_goods_list"),
           data,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    } 
    /**
     * 获取收货地址 
     */
    async getAddressList(){
        const r = effeative(await axios({
            method: "get",
            url: Tools.getApiAddress("custom_address_list"),
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    } 

    /**
     * @description 获取发货单列表
     * @param purchaseID
     */
    async getRecieptList(purchaseID: string){
        const r = effeative(await axios({
            method: "get",
            url: Tools.getApiAddress("purchase_get_shipped"),
            params:{
                purchaseID
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    } 
     /**
     * @description 获取发货单详情
     * @param invoiceID 发货单id
     */
     async getRecieptDetail(invoiceID: string){
        const r = effeative(await axios({
            method: "get",
            url: Tools.getApiAddress("purchase_get_shipped_detail"),
            params:{
                invoiceID
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    } 
     /**
     * @description 获取发货单详情
     * @param invoiceID 发货单id
     */
     async confirmReciept(invoiceID: string){
        const r = effeative(await axios({
            method: "get",
            url: Tools.getApiAddress("purchase_confirm_reciept"),
            params:{
                invoiceID
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    } 
}