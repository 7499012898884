import { message } from "antd";
import { DossierLogic } from "../../../../logic/dossier_logic";
import { FormAttribute } from "../../store/dossier/form_store";
import { GradeLogic } from "../../../../logic/grade_logic";
import { TagLogic } from "../../../../logic/tag_logic";
import { useContext } from "react";
import { FormStore } from "../../../commodity/store/form_store";

type Init = {
    state;
    dispatch;
    form;
    editId: string;
    seeId: string;
}
interface InitBasic {
    dispatch;
    state;
}
interface InitEdit {
    state;
    dispatch;
    form;
    id;
}
interface ISetValue {
    attribute: FormAttribute;
    value: string | number | any;
    dispatch;
}
var id = ''
var birthday = ''
export class FormAction {
    private GradeLgc = new GradeLogic();
    private TagLogic = new TagLogic();
    async initBasic({ dispatch, state ,form}) {
        dispatch({ type: "LoadGrade", payload: await this.GradeLgc.gradeList() });
        dispatch({ type: "LoadTag", payload: await this.TagLogic.querysList() });
        form.setFieldsValue({'member-status': '正常','member-memberPwd':'123456'})
        this.setValue({
            dispatch: dispatch,
            value: '3',
            attribute: "Status",
          })
          this.setValue({
            dispatch: dispatch,
            value: '123456',
            attribute: "MemberPwd",
          });
    }
    initBirthday() {
        return birthday
    }
    private gradeLgc = new DossierLogic();

    async init({ state, dispatch, seeId, editId, form }: Init) {
        await this.initBasic({ dispatch, state,form });
        if (editId || seeId) {
            await this.initEdit({ dispatch, state, id: editId || seeId, form })
        }
        id = editId || seeId
    }
    private async initEdit({ dispatch, state, id, form }: InitEdit) {
        // 回填状态
        const item = await this.gradeLgc.selData(id);

        const allTabs = await this.TagLogic.querysList();
delete item.label
        let lables: any[] = [];
        item.lableIDS?.forEach((item) => {
            lables.push(allTabs.find((_item) => {
                return _item.id == item.lableID;
            }));
        });
        lables = lables.filter(Boolean);
        // this.setValue({ dispatch, value: lables.map((item)=>{
        //     return {};
        // }), attribute: "Label" });

        //根据根据请求标签数据和标签列表的数据填充表单中的数据
        // //console.log(lables);
        if (lables.length > 0) {
            form.setFieldsValue({
                "member-label": lables.map((item) => {
                    return item.id;
                })
            });
        }
        let arr = []
        if(item.lableIDS&&item.lableIDS.length){
            arr = item.lableIDS.map(s=>{return s.lableID})
        }
       item['label'] = arr
       item['superiorMemberID'] = item.oneDistribution
       item['superiorMemberPhone'] = item.oneDistributionPhone
        // selectClasses
        dispatch({ type: "SetForm", payload: item });
        // 填充节点
        form.setFieldsValue({
            "member-card": item.memberCard,
            "member-name": item.memberName,
            "member-memberPwd": item.memberPwd,
            "member-phone": item.phone,
            "member-gender": item.gender,
            "member-birthday": item.birthday,
            "member-source": item.source,
            "member-balanceMoney": item.balanceMoney,
            "member-accountIntegral": item.accountIntegral,
            "member-status": item.status === 1 ? '挂失' : item.status === 2 ? '停用' : '正常',
            "member-membeClassID": item.membeClassID,
            "member-remark": item.remark,
            "member-superiorId": item.superiorMemberID,
            "member-superiorPhone": item.superiorMemberPhone
        });

        birthday = item.birthday
    }
    reset({ dispatch, form }) {
        dispatch({
            type: "SetForm", payload: {
                memberCard: "",
                memberName: "",
                membeClassID: "",
                remark: "",
                phone: "",
                birthday: "",
                source: "",
                label: [],
                balanceMoney: "",
                memberPwd: "123456",
                accountIntegral: "",
                status: 3,
                gender: "1",
                memberSuperiorID:'',
                memberSuperiorPhone:'',
            }
        });

        form.setFieldsValue({
            "member-card": "",
            "member-name": "",
            "member-memberPwd": "123456",
            "member-phone": "",
            "member-gender": "1",
            "member-birthday": "",
            "member-source": "",
            "member-label": [],
            "member-balanceMoney": "",
            "member-accountIntegral": "",
            "member-status": "正常",
            "member-membeClassID": "",
            "member-remark": "",
            "member-superiorId": "",
            "member-superiorPhone": "",
        });
    }
    setValue({ attribute, value, dispatch }: ISetValue) {
        // console.log(attribute, value, dispatch);
        dispatch({ type: "Change_FormData", payload: { attribute, value } });
    }
    private paddingData(state) {
        let params: any = {};
        {
            const { id, memberCard, memberName, membeClassID, phone, birthday, gender, source, label, remark, status, superiorMemberID,superiorMemberPhone } = state;
            params = {
                id, memberCard, memberName, membeClassID, phone, birthday, source, gender, label, remark, status, superiorMemberID, superiorMemberPhone
            }
        }
        return params;
    }
    async addSubmit({ state, form }) {
        let param = state
        // if (Array.isArray(param.label) && param.label.length) {
        //     param.label = param.label.join(',')
        // }
        //把label替换成labelID
        param['labelID'] = param['label'] || [];
        //
        param['memberCard'] = (param['card'] == '' || param['card'] == undefined || param['card'] == null)?param['phone']:param['card']        //
        param['memberPwd'] = state.memberPwd
        param['source'] =  state.source || 1
        // param['gender'] = 1
        console.log(state)
        const r = await this.gradeLgc.addData(param);
        if (r.data.statusCode == 200) {
            message.success("保存成功");
        } else {
            message.error(r.data.returnMessage);
        }
        return r.data.statusCode == 200;
    }
    // 更新会员
    async updSubmit({ state, form }) {
        state['id'] = id
        let param = this.paddingData(state)

        // if (Array.isArray(param.label)) {
        //     param.label = param.label.join(',')
        // }
        //把label替换成labelID
        param['labelID'] = param['label'] || [];
        param['memberCard'] = (param['card'] == '' || param['card'] == undefined || param['card'] == null)?param['phone']:param['card']
        //
        param['memberPwd'] = state.memberPwd
        param['source'] = state.source || 1
        if (undefined === param['gender']) param['gender'] = 1
        const r = await this.gradeLgc.updData(param);
        if (r.data.statusCode == 200) {
            message.success("更新成功");
        } else {
            message.error(r.data.returnMessage);
        }
        return r.data.statusCode == 200;
    }
}
export const formAction = new FormAction();