import styles from "../style/reportLayer.module.scss";
import { Pagination, Empty } from "antd";

/**
 * 报表结构
 * @param props 
 */
export function ReportLayer(props: {
    /** 查询项目 */
    queryItem?: any;
    /** 按钮 */
    button?: any;
    /** 表格内容 */
    children: any;
    /** 底部内容 */
    bottom?: any;
    /** 分页内容 */
    paging?: {
        current: number;
        total: number;
        pageSize: number;
        onChange: (page: number, pageSize: number) => void;
    };
}) {
    return (<div className={styles.report_content}>
        {props.queryItem}
        {
            props.button && <div className={styles.button}>
                {props.button}
            </div>
        }
        <div className={styles.table_content}>
            <table className={styles.report_content_table} id='export_table'>
                {props.children}
            </table>
            <span>{props.bottom}</span>
        </div>
        {props.paging && (
            <>
                {props.paging.total == 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                <div className={styles.paging}>
                    <Pagination current={props.paging.current} showTotal={total => `共${total}条`} onChange={props.paging.onChange} total={props.paging.total} showQuickJumper pageSize={props.paging.pageSize} />
                </div>
            </>
        )}
    </div>);
}