import { message } from "antd";
import moment from "moment";
import { ShopLogic } from "../../../logic/shop_logic";

type Init = {
    state;
    dispatch;
    form;
    id: string;
    method: string;
}
interface InitBasic {
    dispatch;
    state;
    form
}
interface InitEdit {
    state;
    dispatch;
    form;
    id;
}


export class ShopFormAction {

    private shopLgc = new ShopLogic();

    async init({ state, dispatch, id, method, form }: Init) {
        await this.initBasic({ dispatch, state ,form});

        if ("Create" !== method) {
            await this.initEdit({ dispatch, state, id, form })
        }

        //初始化
        dispatch({ type: "init", payload: method });
    }
    private async initBasic({dispatch,state, form  }: InitBasic) {
        // this.reset({dispatch, form })
    }
    private async initEdit({ dispatch, id, form }: InitEdit) {
        // 回填状态
        const r = await this.shopLgc.getShopSettings();
        if(r.data.statusCode== 200 && r.data.data){
            let item = r.data.data
            dispatch({ type: "SetForm", payload: item });

        // 填充节点
        // sendOffMoney:number //起送费
        // shippingFees: number //配送费
        // distributionPlatform: number // 1、商家自送2、达达配送
        // fullReductionNoShippingFees: number //满额免配送费
        form.setFieldsValue({
            "shop-isBusinessStatus": item.isBusinessStatus,
            "shop-businessBeginHours": moment(item.businessBeginHours),
            "shop-businessEndHours": moment(item.businessEndHours),
            "shop-storeLimitBusinessHours":item.storeLimitBusinessHours,
            "shop-shippingRange": item.shippingRange,
            "shop-sendOffMoney": item.sendOffMoney,
            "shop-shippingFees": item.shippingFees,
            "shop-distributionPlatform": item.distributionPlatform,
            'shop-fullReductionNoShippingFees': item.fullReductionNoShippingFees,
            'shop-packingFee':item.packingFee,
            'shop-isRefundAudit': item.isRefundAudit?0:1,
            'shop-packingFeeIsRefund': item.packingFeeIsRefund!==null?item.packingFeeIsRefund: true,
            'shop-shippingRangeIsRefund': item.shippingRangeIsRefund!==null?item.shippingRangeIsRefund: true,
            'shop-isPrint': item.isPrint
        });
        // console.log(form.getFieldsValue())
        }
        
    }
    reset({ dispatch, form }) {
        // selectClasses
        dispatch({
            type: "SetForm", payload: {
                isBusinessStatus: false,
                storeLimitBusinessHours: false,
                businessBeginHours:null,
                businessEndHours:null,
                shippingRange:null,
                sendOffMoney:null,
                shippingFees:null,
                distributionPlatform: 1,
                fullReductionNoShippingFees: null,
                packingFee: null,
                isRefundAudit: 0,
                shippingRangeIsRefund: true,
                packingFeeIsRefund: true,
                isPrint: false,            
            }
        });

        // 填充节点
        form.setFieldsValue({
            "shop-isBusinessStatus": false,
            "shop-businessBeginHours": '',
            "shop-businessEndHours": '',
            "shop-storeLimitBusinessHours":false,
            "shop-shippingRange": null,
            "shop-sendOffMoney": null,
            "shop-shippingFees": null,
            "shop-distributionPlatform": 1,
            'shop-fullReductionNoShippingFees': null,
            'shop-packingFee': null,
            'shop-isRefundAudit': 0,
            'shop-packingFeeIsRefund': true,
            'shop-shippingRangeIsRefund': true,
             'shop-isPrint': false
          
        });
    }
    setValue({ attribute, value, dispatch }) {
        dispatch({ type: "Change_FormData", payload: { attribute, value } });
    }
    private paddingData(state) {
        let params: any = {};
        {
          
            const {packingFeeIsRefund,shippingRangeIsRefund,packingFee,isRefundAudit,fullReductionNoShippingFees,distributionPlatform,shippingFees,sendOffMoney, isBusinessStatus,businessBeginHours,businessEndHours,storeLimitBusinessHours,shippingRange, isPrint} = state;
            
            params = {
                isRefundAudit:isRefundAudit==0?true:false,
                shippingRangeIsRefund,
                packingFeeIsRefund,
                packingFee,
                 fullReductionNoShippingFees,
                 distributionPlatform,
                 shippingFees,
                 sendOffMoney,
                isBusinessStatus,
                businessBeginHours,
                businessEndHours,
                storeLimitBusinessHours,
                shippingRange,
                isPrint
            }
        }
        return params;
    }
    async addSubmit({ state, dispatch,form }) {
        // console.log(form.getFieldsValue())
        dispatch({type:'Loading',payload: true})
        const r = await this.shopLgc.setShopSetting(this.paddingData(state.formData));
        dispatch({type:'Loading',payload: false})
        if (r.data.statusCode == 200) {
            message.success("保存成功");
        } else {
            message.error(r.data.message);
        }
      await  this.init({state,dispatch,id:'',method:'',form})

    }
    async updSubmit({ state, dispatch,form }) {
        dispatch({type:'Loading',payload: true})
        const r = await this.shopLgc.setShopSetting(this.paddingData(state));
        dispatch({type:'Loading',payload: false})
        if (r.data.statusCode == 200) {
            message.success("更新成功");
        } else {
            message.error(r.data.returnMessage);
        }
        await  this.init({state,dispatch,id:'',method:'',form})
    }
}
export const formAction = new ShopFormAction();