import { message } from "antd";
import { defualtPageSize } from "../../../../logic/common_logic";
import { GoodsLogic } from "../../../../logic/goods_logic";
import { ShopLogic } from "../../../../logic/shop_logic";
import { UnitLogic } from "../../../../logic/unit_logic";
import { UserLogic } from "../../../../logic/user_logic";
import { GradeLogic } from "../../../../logic/grade_logic";
import { SeckillLogic } from "../../../../logic/seckill_logic";
import Cookies from 'js-cookie'
type Init = {
    state;
    dispatch;
    form;
    id: string;
    method: string;
}
interface InitBasic {
    dispatch;
    search;
}
interface InitEdit {
    state;
    dispatch;
    form;
    id;
}
interface ISetValue {
    attribute: any;
    value: string | number | any[];
    dispatch;
}

class SeckillFormAction {
    setValue(arg0: { dispatch: ({ type }: { type: any; payload: any; }) => void; value: any; attribute: string; }) {
        throw new Error("Method not implemented.");
    }

    private shopLgc = new ShopLogic();
    private GradeLogic = new GradeLogic();
    async init({ state, dispatch, id, method, form }: Init) {
        await this.initBasic({ dispatch, search: state.search });

        if ("Create" !== method && id) {
            await this.initEdit({ dispatch, state, id, form })
        }
    }
    private async initBasic({ search, dispatch }: InitBasic) {
        this.clearTableList({ dispatch });
        dispatch({ type: "LoadTag", payload: await this.GradeLogic.gradeList() });

    //     const list = await (await new ShopLogic().selListpost()).data.data.dataSource || []
    //     dispatch({type: 'LoadShop',payload: list})
    //    let defaultShop = list.filter(item=> item.isdefault == true)
    //    if(defaultShop.length>0){
    //        let shopinfo = defaultShop[0]
    //     this.setFormData({ dispatch, key: "shopList", value: [shopinfo.id] });
    //     this.setFormData({
    //       dispatch,
    //       key: "shopName",
    //       value: shopinfo.shopName,
    //     });
    //    }
    let { shopID, shopName } = JSON.parse(Cookies.get('loginMsg'))
    this.setFormData({ dispatch, key: "shopList", value: [shopID] });
    this.setFormData({
      dispatch,
      key: "shopName",
      value: shopName,
    });
        // 用户信息
        {
            const r = await new UserLogic().selData();

            this.setFormData({ dispatch, key: "userName", value: r.userName })
        }
        // 商品搜索
        {
            const { keywords, classesId, statusId, typeId, brandId } = search;

            const params = {
                pageIndex: 1, pageSize: defualtPageSize(), goodsBrandID: brandId, goodsClassesID: classesId, goodsType: typeId, status: statusId, goodsNameOrNo: keywords
            }
            if (undefined == keywords) {
                delete params.goodsNameOrNo;
            }
            if (undefined == brandId) {
                delete params.goodsBrandID;
            }
            if (undefined == classesId) {
                delete params.goodsClassesID;
            }
            if (undefined == typeId) {
                delete params.goodsType;
            }
            if (undefined == statusId) {
                delete params.status;
            }

            const r = await new GoodsLogic().selList(params);

            if (r.data.statusCode == 200) {
                const dataList = r.data.data.dataSource;

                const list = dataList.map((goods_v, i) => {

                    return {
                        ...goods_v,
                        id: goods_v.id,
                        value: goods_v.goodsName,
                        key: i + 1
                    }
                })

                dispatch({ type: "SetSearchList", payload: list });

            }
        }


    }
    private async initEdit({ dispatch, id, form }: InitEdit) {
        // 回填状态
        const r = await (await new ShopLogic().selListpost()).data.data.dataSource || [];
        const item = await new SeckillLogic().selDataByWarehousing(id);
        if(!item){
            message.error('获取信息失败')
            return false
        }
        for (let i = 0; i < r.length; i++) {
            if (r[i].id == item.shopList[0].shopID) {
                item['shopName'] = r[i].shopName
            }
        }

        let arr = []
        for (let i = 0; i < item.memberClassList.length; i++) {
            arr.push(item.memberClassList[i].memberClassID)
        }
        item['goodsSpecifications'] = item.goods_SpecificationList
        item['classList'] = arr
        dispatch({ type: "SetForm", payload: item });
    }
    private paddingData(state, status: 1 | 2 | 3) {
        let params: any = {};
        {
            const { formData, tableList } = state;

            {
                const { shopId, remark, reasonId } = formData;
                params.shopID = shopId;
                params.source = 2;
                // 1. 出库 2入库 3报损 4报溢
                params.inventoryType = 2;
                // 1已审核 2未审核 3作废
                params.inventoryStatus = status;
                params.reason = reasonId;
                params.remark = remark;

                params.details = [

                ];

                tableList.forEach((v, i) => {
                    params.details.push({
                        "goodsID": v.goodsId,
                        "goodsName": v.goodsName,
                        "goodsUnitID": v.inStockUnitId,
                        "unitName": v.inStockUnit,
                        "inventoryType": 2,
                        "putPrice": v.inStockUnitPrice,
                        "putNumber": v.inStockNumber,
                        "remark": ""
                    })
                })

            }
        }
        return params;
    }
    // 新增秒杀
    async addSubmit({ state, status }) {
        if (state.formData.snappedName == undefined || state.formData.snappedName == '') {
            message.error("请输入活动名称");
            return false;
        }
        if (state.formData.shopList == undefined || state.formData.shopList.length == 0) {
            message.error("请选择分配门店");
            return false;
        }
        if (state.formData.isMemberClass == undefined || state.formData.isMemberClass == 1) {
            // state.formData.isMemberClass = 1
            if (state.formData.memberClassList == undefined || state.formData.memberClassList.length == 0) {
                message.error("请选择会员等级");

                return false;
            }

        }
        if (state.formData.beginTime == undefined || state.formData.endTime == false || state.formData.beginTime == '' || state.formData.endTime == '') {
            message.error("请选择活动时间");
            // state.formData.isMemberClass = 2
            return false;
        }else if(state.formData.beginTime == state.formData.endTime) {
            message.error("开始时间和结束时间不能相同");
            return false;
        }
        if (!state.tableList || !state.tableList.length) {
            message.error("请选择秒杀商品");
            return false;
        }
        
     
        let goodsList = []
        let goodsSort = 1;
        for(let i=0;i<state.tableList.length;i++){
            let v = state.tableList[i]
             for(let j=0;j< v.goodsSpecifications.length;j++){
                let s = v.goodsSpecifications[j]
                if(s.snappedPrice<=0){
                    message.error('活动价应该大于0')
                    return false;
                }else if(!(/^[1-9]\d*$/).test(s.snappedCount)){ 
                    message.error('秒杀库存应是大于零的整数')
                    return false;
                }else if(s.snappedCount>s.sumNumber){
                    message.error('秒杀库存不能大于该规格总库存')
                    return false;
                }else if(!(/^[1-9]\d*$/).test(s.singleCount)){
                    message.error('单人限购数量应是大于零的整数，请重新输入')
                    return false;
                }else if(s.singleCount >  s.snappedCount){
                    message.error('单人限购数量应小于秒杀库存，请重新输入')
                    return false;
                }
                else{
                    goodsList.push({
                        goodsID: s.goodsID,
                        shoppingPrice: s.storePrice,
                        goodsSepID: s.id,
                        //
                        snappedPrice: s.snappedPrice,
                        snappedCount: s.snappedCount,
                        singleCount: s.singleCount,
                        sort: goodsSort++,
                    });
                }
               
            };
        };

    
        // for (let i = 0; i < state.tableList.length; i++) {
        //     let obj = {}
        //     obj['goodsID'] = state.tableList[i].goodsId
        //     obj['shoppingPrice'] = state.tableList[i].inStockUnitPrice
        //     obj['snappedPrice'] = state.tableList[i].snappedPrice
        //     obj['snappedCount'] = state.tableList[i].snappedCount
        //     obj['singleCount'] = state.tableList[i].singleCount
        //     obj['sort'] = i + 1
        //     goodsList.push(obj)

        // }
        let param = {}
        let shopAarr = []
        state.formData.shopList.length>0&&state.formData.shopList.map(shop=>{
                shopAarr.push({
                    shopID: shop
                })
        })
        param['snappedName'] = state.formData.snappedName
        param['shopList'] = shopAarr
        param['isMemberClass'] = state.formData.isMemberClass
        param['memberClassList'] = state.formData.memberClassList
        param['beginTime'] = state.formData.beginTime
        param['endTime'] = state.formData.endTime
        param['goodsList'] = goodsList
        const r = await new SeckillLogic().addOperation(param);
        if (r.data.statusCode == 200) {
            message.success("保存成功");
            return true;
        } else {
            message.error('保存失败');
        }
        return r.data.statusCode == 200;

    }
    async updSubmit({ state, status }, id) {
        if (state.formData.snappedName == undefined || state.formData.snappedName == '') {
            message.error("请输入活动名称");
            return false;
        }
        if (state.formData.shopList == undefined || state.formData.shopList.length == 0) {
            message.error("请选择分配门店");
            return false;
        }
        if (state.formData.isMemberClass == undefined || state.formData.isMemberClass == 1) {
            state.formData.isMemberClass = 1
            if (state.formData.memberClassList == undefined || state.formData.memberClassList.length == 0) {
                message.error("请选择会员等级");

                return false;
            }

        }
        if (state.formData.beginTime == undefined || state.formData.endTime == false || state.formData.beginTime == '' || state.formData.endTime == '') {
            message.error("请选择活动时间");
            return false;
        }else if(state.formData.beginTime == state.formData.endTime) {
            message.error("开始时间和结束时间不能相同");
            return false;
        }
        if (!state.tableList || !state.tableList.length) {
            message.error("请选择秒杀商品");
            return false;
        }
        let goodsList = []
        let goodsSort = 1;
      

        for(let i=0;i<state.tableList.length;i++){
            let v = state.tableList[i]
             for(let j=0;j< v.goodsSpecifications.length;j++){
                let s = v.goodsSpecifications[j]
                //console.log(s)
                if(s.snappedPrice<=0){
                    message.error('活动价应该大于0')
                    return false;
                }else if(!(/^[1-9]\d*$/).test(s.snappedCount)){
                    message.error('秒杀库存应是大于零的整数')
                    return false;
                }else if(s.snappedCount>s.sumNumber){
                    message.error('秒杀库存不能大于该规格总库存')
                    return false;
                }else if(!(/^[1-9]\d*$/).test(s.singleCount)){
                    message.error('单人限购数量应是大于零的整数')
                    return false;
                }else if(s.singleCount >  s.snappedCount){
                    message.error('单人限购数量应小于秒杀库存，请重新输入')
                    return false;
                }
                else{
                    goodsList.push({
                        goodsID: s.goodsID,
                        shoppingPrice: s.storePrice,
                        goodsSepID: s.id,
                        //
                        snappedPrice: s.snappedPrice,
                        snappedCount: s.snappedCount,
                        singleCount: s.singleCount,
                        sort: goodsSort++,
                    });
                }
               
            };
        };
      
    
        let param = {}
        let shopAarr = []
        state.formData.shopList.length>0&&state.formData.shopList.map(shop=>{
                shopAarr.push({
                    shopID: shop
                })
        })
        param['snappedName'] = state.formData.snappedName
        param['shopList'] = shopAarr
        param['isMemberClass'] = state.formData.isMemberClass
        param['memberClassList'] = state.formData.isMemberClass == 1?[]:state.formData.memberClassList
        param['beginTime'] = state.formData.beginTime
        param['endTime'] = state.formData.endTime
        param['goodsList'] = goodsList
        param['id'] = id

        const r = await new SeckillLogic().updOperation(param);
        if (r.data.statusCode == 200) {
            message.success("更新成功");
        } else {
            message.error(r.data.returnMessage);
        }
        return r.data.statusCode == 200;
    }
    addTableItem({ dispatch, list }) {
        list.forEach(async v => {
            const r = await new UnitLogic().selData(v.goodsUnitID);
            console.log(v)
            //获取商品规格信息
            // v.goodsSpecifications = (await new GoodsLogic().selData(v.id)).goodsSpecifications;
            v['goodsSpecifications'] = v.goods_SpecificationList
            v.goodsUnitName = r.unitName;
            v.inStockUnitPrice = v.putNewPrice || 0;
            v.storePrice  = v.storePrice || 0
            v.inStockNumber = 0;
            v.snappedPrice = 0;
            v.snappedCount = 0;
            v.singleCount = 0;

            dispatch({ type: "AddTableItem", payload: v });
        })
    }
    delTableItem(dispatch, index) {
        dispatch({ type: "DelTableItem", payload: index });
    }
    setFormData({ dispatch, key, value }) {
        const params = {
        }
        params[key] = value;

        dispatch({ type: "SetFormData", payload: params })
    }
    setKeywords({ dispatch, value, state }) {
        this.setFormData({ dispatch, key: "keyword", value: value });

        state.searchList.forEach(e => {
            if (e.id == value) {
                this.setFormData({ dispatch, key: "keyword", value: value });

                dispatch({ type: "SetFormData", payload: { inStockUnitPrice: e.putNewPrice } })
            }

        })
    }

    changeTableItem({ dispatch, key, value, index }) {
        dispatch({ type: "ChangeTableItem", payload: { key, value, index } });
    }
    clearTableList({ dispatch }) {
        dispatch({ type: "ClearTableList" });
    }
}
export const seckillformAction = new SeckillFormAction();