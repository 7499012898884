import { message } from "antd";
import { defualtPageSize } from "../../../../logic/common_logic";
import { GoodsLogic } from "../../../../logic/goods_logic";
import { FullgiftLogic } from "../../../../logic/fullgift_logic";
import { ShopLogic } from "../../../../logic/shop_logic";
import { UnitLogic } from "../../../../logic/unit_logic";
import { UserLogic } from "../../../../logic/user_logic";
import { GradeLogic } from "../../../../logic/grade_logic";
import { ClassesLogic } from "../../../../logic/classes_logic";
import Cookies from "js-cookie";
type Init = {
    state;
    dispatch;
    form;
    id: string;
    method: string;
}
interface InitBasic {
    dispatch;
    search;
}
interface InitEdit {
    state;
    dispatch;
    form;
    id;
}
interface ISetValue {
    attribute: any;
    value: string | number | any[];
    dispatch;
}

class FullgiftFormAction {

    private GradeLogic = new GradeLogic();
    private shopLgc = new ShopLogic();
    setDay(state){
        let date1 = state.formData.beginDate;
        let date2 = state.formData.endDate
       let monthBegin = (new Date(date1)).getMonth();
       let monthEnd = (new Date(date2)).getMonth();
       let dayBegin = (new Date(date1)).getDate();
       let dayEnd = (new Date(date2)).getDate();
       let dayList=[]
   
           if(monthBegin!=null&&monthEnd!=null&&monthBegin == monthEnd){
               for(let i= dayBegin; i<=dayEnd ;i++){
                  dayList.push(i)
               }
           }else{
              for(let i = 0; i< 31;i++){
                  dayList.push(i+1)
               }
           }
           return dayList;
    }
    async init({ state, dispatch, id, method, form }: Init) {

        await this.initBasic({ dispatch, search: state.search });
        if ("Create" !== method && id) {
            await this.initEdit({ dispatch, state, id, form })
        }
    }
    private async initBasic({ search, dispatch }: InitBasic) {
        this.clearTableList({ dispatch });
        this.clearTableListAdd({ dispatch })
        dispatch({ type: "LoadTag", payload: await this.GradeLogic.gradeList() });
    //     const list = await (await new ShopLogic().selListpost()).data.data.dataSource || []
    //     dispatch({type: 'LoadShop',payload: list})
    //    let defaultShop = list.filter(item=> item.isdefault == true)
    //    if(defaultShop.length>0){
    //        let shopinfo = defaultShop[0]
    //     this.setFormData({ dispatch, key: "shopId", value: [shopinfo.id] });
    //     this.setFormData({
    //       dispatch,
    //       key: "shopName",
    //       value: shopinfo.shopName,
    //     });
    //    }
    let { shopID, shopName } = JSON.parse(Cookies.get('loginMsg'))
    this.setFormData({ dispatch, key: "shopIDs", value: [shopID] });
    this.setFormData({
      dispatch,
      key: "shopName",
      value: shopName,
    });
      
        // 用户信息
        {
            const r = await new UserLogic().selData();

            this.setFormData({ dispatch, key: "userName", value: r.userName })
            this.setFormData({ dispatch, key: "activityManner", value: 1 })
            this.addGradeItem(dispatch, 'addOne')
            this.setFormData({ dispatch, key: "useweekTypeValue", value: ['1', '2', '3', '4', '5', '6', '7'] })
        }
        // 商品搜索
        {
            const { keywords, classesId, statusId, typeId, brandId } = search;

            const params = {
                pageIndex: 1, pageSize: defualtPageSize(), goodsBrandID: brandId, goodsClassesID: classesId, goodsType: typeId, status: statusId, goodsNameOrNo: keywords
            }
            if (undefined == keywords) {
                delete params.goodsNameOrNo;
            }
            if (undefined == brandId) {
                delete params.goodsBrandID;
            }
            if (undefined == classesId) {
                delete params.goodsClassesID;
            }
            if (undefined == typeId) {
                delete params.goodsType;
            }
            if (undefined == statusId) {
                delete params.status;
            }

            const r = await new GoodsLogic().selList(params);

            if (r.data.statusCode == 200) {
                const dataList = r.data.data.dataSource;

                const list = dataList.map((goods_v, i) => {

                    return {
                        ...goods_v,
                        id: goods_v.id,
                        value: goods_v.goodsName,
                        key: i + 1
                    }
                })

                dispatch({ type: "SetSearchList", payload: list });

            }
        }


    }
    private async initEdit({ dispatch, id, form }: InitEdit) {
        // 回填状态
        const r = await (await new ShopLogic().selListpost()).data.data.dataSource;
        const list = await new ClassesLogic().selListBySelect();
        const item = await new FullgiftLogic().selDataByWarehousing(id);
        const params = {
            pageIndex: 1, pageSize: 999, goodsBrandID: '', goodsClassesID: '', goodsType: '', status: '', goodsNameOrNo: ''
        }
        let { shopID, shopName } = JSON.parse(Cookies.get('loginMsg'))
        // const shopList = await (await new GoodsLogic().selList(params)).data.data.dataSource;
        const shopList = []
        let dataList = []
        const goodr = await new GoodsLogic().selListForSpecification({pageIndex:1,pageSize:999,shopID:shopID,source:'PC',goodsClassesID:''})
        if(goodr.data && goodr.data.statusCode == 200){
            dataList = goodr.data.data.dataSource || [];

        }
        
       dataList.length&&dataList.map((goods_v, i) => {
        if(goods_v.goods_SpecificationList&&goods_v.goods_SpecificationList.length>0){
         goods_v.goods_SpecificationList.map((good,index)=>{
            delete good.goodsName         
            shopList.push({
                 key: i + 1,
                 ...goods_v,
                 ...good,
                goodsName:goods_v.goodsName,
                 goodsSepID:good.id,
                 goodsNo: goods_v.goodsNo,
                 goods_SpecificationList:[good]
             })
         })
        }
   
 })


//  console.log(dataList)
       if (item.targets.length != 0 && item.targetType == 2) {
            let arr = []
            for (let i = 0; i < list.length; i++) {
                for (let k = 0; k < item.targets.length; k++) {
                    if (list[i].value == item.targets[k].target) {
                        arr.push(list[i])
                    }
                }
                for (let j = 0; j < list[i].children.length; j++) {
                    for (let k = 0; k < item.targets.length; k++) {
                        if (list[i].children[j].value == item.targets[k].target) {
                            arr.push(list[i].children[j])
                        }

                    }
                }
            }
            let array = []
            for (let j = 0; j < arr.length; j++) {
                let obj = {}
                obj['title'] = arr[j].label
                obj['key'] = arr[j].value
                array.push(obj)
            }
            item['classifyList'] = array
        }

        if (item.targets.length != 0 && shopList && item.targetType == 3) {
            let arr = []
            for (let i = 0; i < shopList.length; i++) {
                for (let k = 0; k < item.targets.length; k++) {
                    if (shopList[i].goodsSepID == item.targets[k].target) {
                        arr.push(shopList[i])
                    }
                }
            }
            item['tableList'] = arr            

        }
        if (shopList && item.activityManner != 1) {
            let arr = []

            const list = []

            for (let k = 0; k < item.giveGoods.length; k++) {     
                for (let i = 0; i < shopList.length; i++) {
                            let s = shopList[i]
                                if(s.goodsSepID== item.giveGoods[k].goodsSpecificationID ){
                                    let ll = []
                                    s.goods_SpecificationList.map(lg=>{
                                        lg['giveCount'] = item.giveGoods[k].giveCount 
                                        ll.push(lg)
                                    })
                                    
                                    list.push({
                                       ...s,
                                        goods_SpecificationList: ll,
                                    })
                                }                     
                  
                }
               if(list.length>0){
                arr =list
               }
            }
          
                     item['tableListAdd'] = arr
        }
    let shopNameArr = []
  for (let i = 0; i < r.length; i++) {
            if (r[i].id == item.shopIDs[0].shopID) {
                shopNameArr.push(r[i].shopName)
            }
        }
        item['shopName'] = shopNameArr.toString()
        item['beginTime'] = item.beginTime.split(' ')[1]
        item['endTime'] = item.endTime.split(' ')[1]
        if(!item.isMemberClass){
            item['memberClassIDs'] = []
        }
        item['isMemberClass'] = !item.isMemberClass



    // marketingTypes
    if(item.marketingTypes.length>0){
        let arr = [];
        item.marketingTypes.map(ss=>{
            arr[ss.stage-1] = ss
        })
        item['marketingTypes'] = arr
    }
        dispatch({ type: "SetForm", payload: item });
    }
    // 提交活动
    async addSubmit({ state, status }) {
        if (state.formData.activityName == undefined) {
            message.error("请输入活动名称");
            return false;
        }
        if (state.formData.activityManner == 1) {
            let num = 0
            for (let i = 0; i < state.formData.marketingTypes.length; i++) {
                if (state.formData.marketingTypes[i].meetMoney == '' || state.formData.marketingTypes[i].discountMoney == '') {
                    num = num + 2
                }else
                if(state.formData.marketingTypes[i].meetMoney < 0 || state.formData.marketingTypes[i].discountMoney < 0){
                    message.error('请输入大于0的数')
                    return false;
                }else
                if(state.formData.marketingTypes[i].meetMoney < state.formData.marketingTypes[i].discountMoney){
             
                    message.error('减免金额不能大于条件金额')
                    return false;
                }
                if (num > 0) {
                    message.error("活动设置未填完整");
                    return false;
                }
            }
        }
        if (state.formData.activityManner == 2) {
            let num = 0
            for (let i = 0; i < state.formData.marketingTypes.length; i++) {
                if (state.formData.marketingTypes[i].meetMoney == '' || state.formData.marketingTypes[i].addMoney == '' || state.formData.marketingTypes[i].giveCount == '') {
                    num = num + 2
                }else
                if(state.formData.marketingTypes[i].meetMoney <= 0 || state.formData.marketingTypes[i].addMoney <= 0 || state.formData.marketingTypes[i].giveCount <= 0){
                    message.error('请输入大于0的数')
                    return false;
                }else
                 if( !(/^[1-9]\d*$/).test(state.formData.marketingTypes[i].giveCount)){
                    message.error('商品数量应是大于0的整数')
                    return false;
                }
                if (num > 0) {
                    message.error("活动设置未填完整");
                    return false;
                }
            }
        }
        if (state.formData.activityManner == 3) {
            let num = 0


            for (let i = 0; i < state.formData.marketingTypes.length; i++) {
                state.formData.marketingTypes[i].meetMoney = 0
                state.formData.marketingTypes[i].addMoney = 0
                state.formData.marketingTypes[i].discountMoney = 0 
                if (state.formData.marketingTypes[i].meetCount == '' || state.formData.marketingTypes[i].giveCount == '' || state.formData.marketingTypes[i].meetCount == undefined|| state.formData.marketingTypes[i].giveCount==undefined) {
                    num = num + 2
                }else
                if(!(/^[1-9]\d*$/).test(state.formData.marketingTypes[i].meetCount) ||   !(/^[1-9]\d*$/).test(state.formData.marketingTypes[i].giveCount)){
                    message.error('请输入大于0的整数')
                    return false;
                }
                // else if(state.formData.marketingTypes[i].giveCount > state.formData.marketingTypes[i].meetMoney){
                //     message.error('赠送数量不能大于')
                //     return false;
                // }
                if (num > 0) {
                    message.error("活动设置未填完整");
                    return false;
                }
            }
        }

        if (!state.formData.isMemberClass == undefined || !state.formData.isMemberClass) {
            if (state.formData.memberClassIDs == undefined || state.formData.memberClassIDs.length == 0) {
                message.error("请选择会员等级");
                return false;
            }
        }
        if (state.formData.beginDate == undefined || state.formData.beginDate == '' || state.formData.beginDate == null || state.formData.endDate == undefined || state.formData.endDate == '' || state.formData.endDate == null) {
            message.error("请选择活动日期");
            return false;
        }
        if (state.formData.beginTime == undefined || state.formData.endTime == undefined) {
            message.error("请选择活动时间段");
            return false;
        }
        if(state.formData.beginTime == state.formData.endTime){
            message.error("请选择不同的活动时间段");
            return false;
        }
        if (state.formData.useType == 2) {
            if (state.formData.useTypeValue == undefined || state.formData.useTypeValue.length == 0) {
            let dayList = this.setDay(state)
            state.formData.useTypeValue = dayList.toString()
            }
        } else {
            if (state.formData.useweekTypeValue == undefined || state.formData.useweekTypeValue.length == 0) {
                message.error("请选择日期集合");
                return false
            }
        }
        
        let targets = []
        if (state.formData.targetType == 2) {
            if (state.classifyList.length == 0) {
                message.error("请选择商品分类");
                return false;
            }
            for (let i = 0; i < state.classifyList.length; i++) {
                let obj = {}
                obj['target'] = state.classifyList[i].key
                obj['targetType'] = 2
                targets.push(obj)
            }
        }
        if (state.formData.targetType == 3) {
            if (state.tableList.length == 0) {
                message.error("请选择商品");
                return false;
            }
            for (let i = 0; i < state.tableList.length; i++) {
                let obj = {}
             obj['target'] = state.tableList[i].goodsSepID
                obj['targetType'] = 1
                targets.push(obj)
            }
        }
       
        let giveGoods = []
        if (state.formData.activityManner != 1) {
            if (state.tableListAdd.length == 0) {
                message.error("请选择赠送商品");
                return false;
            }
            let num = 0
            // for (let i = 0; i < state.tableListAdd.length; i++) {
            //     let obj = {}
            //     //console.log(state.tableListAdd[i])
            //     if(state.tableListAdd[i].giveCount==undefined||state.tableListAdd[i].giveCount==''){
            //         num = num+1
            //     } else{                   
            //         obj['giveCount'] = state.tableListAdd[i].giveCount
            //         obj['goodsID'] = state.tableListAdd[i].goodsId
            //         obj['goodsSpecificationID'] = state.tableListAdd[i].goods_SpecificationList
            //     }
            //     giveGoods.push(obj)
            // }
            // if (num > 0) {
            //     message.error("请输入赠送商品数量");
            //     return false;
            // }
        }
        let goodsList = []
        let totalCount = 0;
        for(let i=0;i<state.tableListAdd.length;i++) {
            let v = state.tableListAdd[i]
           for(let j=0;j<v.goods_SpecificationList.length;j++) {
                let s = v.goods_SpecificationList[j]
                totalCount += s.giveCount
                if(!(/^[1-9]\d*$/).test(s.giveCount)){
                    message.error('请输入大于0的整数')
                    return false;
                }else{
                    
                    goodsList.push({
                    goodsID: s.goodsID,
                    giveCount: s.giveCount,
                    goodsSpecificationID: s.id,
                });
            }  
            };
        };
       
        let parms = {
            activityName: state.formData.activityName,
            activityManner: state.formData.activityManner,
            isSuperimposing: state.formData.isSuperimposing == undefined ? false : state.formData.isSuperimposing,
            isMarketing: state.formData.isMarketing == undefined ? false : state.formData.isMarketing,
            isMemberClass: !state.formData.isMemberClass ,
            beginDate: state.formData.beginDate,
            endDate: state.formData.endDate,
            beginTime: state.formData.beginTime,
            endTime: state.formData.endTime,
            targets: targets,
            shopIDs: state.formData.shopIDs,
            memberClassIDs: state.formData.memberClassIDs,
            marketingTypes: state.formData.marketingTypes,
            giveGoods: goodsList,//赠送商品列表
            purposeType: state.formData.purposeType == undefined ? 1 : state.formData.purposeType,
            targetType: state.formData.targetType == undefined ? 1 : state.formData.targetType,
            useType: state.formData.useType == undefined || state.formData.useType == 1 ? 1 : 2,//Week按星期，Day按日
            useTypeValue: (state.formData.useType == 2 ? (Array.isArray(state.formData.useTypeValue) ? state.formData.useTypeValue.join(',') : state.formData.useTypeValue) : (Array.isArray(state.formData.useweekTypeValue) ? state.formData.useweekTypeValue.join(',') : state.formData.useweekTypeValue)),//日期集合
        }
        const r = await new FullgiftLogic().addOperation(parms);
        if (r.data.statusCode == 200) {
            message.success("保存成功");
            return true;
        } else {
            message.error(r.data.message);
            return false;
        }
    }
    async updSubmit({ state, status }, id) {
        if (state.formData.activityName == undefined) {
            message.error("请输入活动名称");
            return false;
        }
        if (state.formData.activityManner == 1) {
            let num = 0
            for (let i = 0; i < state.formData.marketingTypes.length; i++) {
                if (state.formData.marketingTypes[i].meetMoney == '' || state.formData.marketingTypes[i].discountMoney == '') {
                    num = num + 2
                }else
                if(state.formData.marketingTypes[i].meetMoney < 0 || state.formData.marketingTypes[i].discountMoney < 0){
                    message.error('请输入大于0的数')
                    return false;
                }else
                if(state.formData.marketingTypes[i].meetMoney < state.formData.marketingTypes[i].discountMoney){      
                    message.error('减免金额不能大于条件金额')
                    return false;
                }
                if (num > 0) {
                    message.error("活动设置未填完整");
                    return false;
                }
            }
        }
        if (state.formData.activityManner == 2) {
            let num = 0
            for (let i = 0; i < state.formData.marketingTypes.length; i++) {
                if (state.formData.marketingTypes[i].meetMoney == '' || state.formData.marketingTypes[i].addMoney == '' || state.formData.marketingTypes[i].giveCount == '') {
                    num = num + 2
                }else
                if(state.formData.marketingTypes[i].meetMoney < 0 || state.formData.marketingTypes[i].addMoney < 0 || state.formData.marketingTypes[i].giveCount<0){
                    message.error('请输入大于0的数')
                    return false;
                }else if(!(/^[1-9]\d*$/).test(state.formData.marketingTypes[i].giveCount)){
                    message.error('请输入大于0的整数')
                    return false;
                }else{
                    state.formData.marketingTypes[i].giveCount = parseInt(state.formData.marketingTypes[i].giveCount)
                }
                if (num > 0) {
                    message.error("活动设置未填完整");
                    return false;
                }
            }
        }
        if (state.formData.activityManner == 3) {
            let num = 0
            for (let i = 0; i < state.formData.marketingTypes.length; i++) {
                if (state.formData.marketingTypes[i].meetCount == '' || state.formData.marketingTypes[i].giveCount == ''|| state.formData.marketingTypes[i].meetCount==undefined || state.formData.marketingTypes[i].giveCount == undefined) {
                    num = num + 2
                }else  if(state.formData.marketingTypes[i].meetCount < 0 ||  state.formData.marketingTypes[i].giveCount < 0){
                    message.error('请输入大于0的数')
                    return false;
                }else if(!(/^[1-9]\d*$/).test(state.formData.marketingTypes[i].meetCount) || !(/^[1-9]\d*$/).test(state.formData.marketingTypes[i].giveCount)){
                    message.error('请输入大于0的整数')
                    return false;
                }else{
                    state.formData.marketingTypes[i].giveCount = parseInt(state.formData.marketingTypes[i].giveCount)
                    state.formData.marketingTypes[i].meetCount = parseInt(state.formData.marketingTypes[i].meetCount)
                }
                if (num > 0) {
                    message.error("活动设置未填完整");
                    return false;
                }
            }
        }

        if (!state.formData.isMemberClass == undefined || !state.formData.isMemberClass) {
            if (state.formData.memberClassIDs == undefined || state.formData.memberClassIDs.length == 0) {
                message.error("请选择会员等级");
                return false;
            }
        }
        if (state.formData.beginDate == undefined || state.formData.beginDate == '' || state.formData.beginDate == null || state.formData.endDate == undefined || state.formData.endDate == '' || state.formData.endDate == null) {
            message.error("请选择活动日期");
            return false;
        }
        if (state.formData.beginTime == undefined || state.formData.endTime == undefined) {
            message.error("请选择活动时间段");
            return false;
        }
        if(state.formData.beginTime == state.formData.endTime){
            message.error("请选择不同的活动时间段");
            return false;
        }
        if (state.formData.useType == 2) {
            if (state.formData.useTypeValue == undefined || state.formData.useTypeValue.length == 0) {
                let dayList = this.setDay(state)
                state.formData.useTypeValue = dayList.toString()
            }
        } else {
            if (state.formData.useweekTypeValue == undefined || state.formData.useweekTypeValue.length == 0) {
                message.error("请选择日期集合");
                return false
            }
        }
        let targets = []
        if (state.formData.targetType == 2) {
            if (state.classifyList.length == 0) {
                message.error("请选择商品分类");
                return false;
            }
            for (let i = 0; i < state.classifyList.length; i++) {
                let obj = {}
                obj['target'] = state.classifyList[i].key
                obj['targetType'] = 2
                targets.push(obj)
            }
        }
        if (state.formData.targetType == 3) {
            if (state.tableList.length == 0) {
                message.error("请选择商品");
                return false;
            }
            for (let i = 0; i < state.tableList.length; i++) {
                let obj = {}
                obj['target'] = state.tableList[i].goodsId?state.tableList[i].goodsId:state.tableList[i].id
                obj['targetType'] = 1
                targets.push(obj)
            }
        }
        let giveGoods = []
        if (state.formData.activityManner != 1) {
            if (state.tableListAdd == undefined) {
                message.error("请选择赠送商品");
                return false;
            }
            if (state.tableListAdd.length == 0) {
                message.error("请选择赠送商品");
                return false;
            }else{
                let num =0
                let totalCount = 0;
                for(let i=0;i<state.tableListAdd.length;i++) {
                    let v = state.tableListAdd[i]
                   for(let j=0;j<v.goods_SpecificationList.length;j++) {
                        let s = v.goods_SpecificationList[j]
                        totalCount += s.giveCount
                        if(!(/^[1-9]\d*$/).test(s.giveCount)){
                            message.error('请输入大于0的整数')
                            return false;
                        }else{
                            
                            giveGoods.push({
                            goodsID: s.goodsID,
                            giveCount: s.giveCount,
                            goodsSpecificationID: s.id,
                        });
                    }  
                    };
                };
            }
            // //console.log(state.tableListAdd)
            // return false
            
            
            let num = 0
           
            if (num > 0) {
                message.error("请输入赠送商品数量");
                return false;
            }
        }
        let parm = {
            id:id,
            activityName: state.formData.activityName,
            activityManner: state.formData.activityManner,
            isSuperimposing: state.formData.isSuperimposing == undefined ? false : state.formData.isSuperimposing,
            isMarketing: state.formData.isMarketing == undefined ? false : state.formData.isMarketing,
            isMemberClass: !state.formData.isMemberClass,
            beginDate: state.formData.beginDate,
            endDate: state.formData.endDate,
            beginTime: state.formData.beginTime,
            endTime: state.formData.endTime,
            targets: targets.length==0?[]:targets,
            shopIDs: state.formData.shopIDs,
            memberClassIDs: state.formData.memberClassIDs,
            marketingTypes: state.formData.marketingTypes,
            giveGoods: giveGoods,//赠送商品列表
            purposeType: state.formData.purposeType == undefined ? 1 : state.formData.purposeType,
            targetType: state.formData.targetType == undefined ? 1 : state.formData.targetType,
            useType: state.formData.useType == undefined || state.formData.useType == 1 ? 1 : 2,//Week按星期，Day按日
            useTypeValue: (state.formData.useType == 2 ? (Array.isArray(state.formData.useTypeValue) ? state.formData.useTypeValue.join(',') : state.formData.useTypeValue) : (Array.isArray(state.formData.useweekTypeValue) ? state.formData.useweekTypeValue.join(',') : state.formData.useweekTypeValue)),//日期集合
        }
        const r = await new FullgiftLogic().updOperation(parm);
        if (r.data.statusCode == 200) {
            message.success("更新成功");
            return true;
        } else {
            message.error(r.data.message);
            return false;
        }

    }
    setValue({ attribute, value, dispatch }: ISetValue) {
        dispatch({ type: "Change_FormData", payload: { attribute, value } });
    }
    addTableItem({ dispatch, list }) {
        list.forEach(async v => {
            const r = await new UnitLogic().selData(v.goodsUnitID);
            v.goodsUnitName = r.unitName;

            dispatch({ type: "AddTableItem", payload: v });
        })
    }
    addTableItemAdd({ dispatch, list }) {
        
        list.forEach(async v => {
          
            // const r = await new UnitLogic().selData(v.goodsUnitID);
            // v.goodsUnitName = r.unitName;
            dispatch({ type: "addTableItemAdd", payload: v });
        })
    }
    AddclassTableItem({ dispatch, list }) {
        console.log(list)
        dispatch({ type: "AddclassTableItem", payload: list });
    }
    delTableItem(dispatch, index) {
        dispatch({ type: "DelTableItem", payload: index });
    }
    delTableItemAdd(dispatch, index) {
        dispatch({ type: "DelTableItemAdd", payload: index });
    }
    delTableclassItem(dispatch, index) {
        dispatch({ type: "DelTableclassItem", payload: index });
    }
    addGradeItem(dispatch, text) {
        dispatch({ type: "AddGradeItem", payload: text });
    }
    setFormData({ dispatch, key, value }) {
        const params = {
        }
        params[key] = value;

        dispatch({ type: "SetFormData", payload: params })
    }
    setKeywords({ dispatch, value, state }) {
        this.setFormData({ dispatch, key: "keyword", value: value });

        state.searchList.forEach(e => {
            if (e.id == value) {
                this.setFormData({ dispatch, key: "keyword", value: value });

                dispatch({ type: "SetFormData", payload: { inStockUnitPrice: e.putNewPrice } })
            }

        })
    }
    changeTableItemAdd({ dispatch, key, value, index }) {
        dispatch({ type: "ChangeTableItemAdd", payload: { key, value, index } });
    }
    clearTableList({ dispatch }) {
        dispatch({ type: "ClearTableList" });
    }
    clearTableListAdd({ dispatch }) {
        dispatch({ type: "ClearTableListAdd" });
    }
}
export const fullgiftformAction = new FullgiftFormAction();