import { message } from "antd";
import moment from "moment";
import { MimiAdLogic } from "../../../logic/miniAd_logic";

type Init = {
    state;
    dispatch;
    form;
    id: string;
    method: string;
}
interface InitBasic {
    dispatch;
    state;
    form
}
interface InitEdit {
    state;
    dispatch;
    form;
    id;
}


export class FormAction {

    private miniLogic = new MimiAdLogic();

    async init({ state, dispatch, id, method, form }: Init) {
        await this.initBasic({ dispatch, state ,form});

        if ("Create" !== method) {
            await this.initEdit({ dispatch, state, id, form })
        }

        //初始化
        dispatch({ type: "init", payload: method });
    }
    private async initBasic({dispatch,state, form  }: InitBasic) {
        this.reset({dispatch, form })
    }
    private async initEdit({ dispatch, id, form }: InitEdit) {
        // 回填状态
        const r = await this.miniLogic.selData(id)
// console.log(r)
        if(r.data.statusCode== 200 && r.data.data){
            let item = r.data.data
            dispatch({ type: "SetForm", payload: item });

        // 填充节点
        form.setFieldsValue({
            'imgUrl':item.image,
            'isLink': (item.jumpType === null || item.jumpType === undefined)?false: true,
            'linkType': item.jumpType,
            'startTime': item.startTime,
            'endTime': item.endTime,
            'status': item.status==0?true:false,
            'isRepetition': item.isRepetition,

        });
        // console.log(form.getFieldsValue())
        }
        
    }
    reset({ dispatch, form }) {
        // selectClasses
        dispatch({type:'reset'});

        // 填充节点
        form.setFieldsValue({
            'imgUrl':'',
            'isLink': false,
            'linkType': 0,
            'startTime': null,
            'endTime':  null,
            'status': 0,
            "isRepetition": false,
            "advertisingSort": 0

          
        });
    }
    resetLink({dispatch,form}){
        dispatch({ type: "Reset_Link" });
    }
    setValue({ attribute, value, dispatch }) {
        dispatch({ type: "Change_FormData", payload: { attribute, value } });
    }
    private paddingData(state) {
        let params: any = {};
        {
         
            const {linkType,isLink,linkParam,imgUrl,startTime,endTime,status,isRepetition,advertisingSort} = state;
            params = {
                jumpType:isLink?linkType:null ,
                jumpValue:isLink?JSON.stringify(linkParam):null,
                status:status?0:1,
                image: imgUrl,
                startTime: startTime,
                endTime: endTime,
                isRepetition: isRepetition,
                advertisingSort:advertisingSort
            }
        }
      
        return params;
    }
    async addSubmit({ state, dispatch }) {
        dispatch({type:'Loading',payload: true})
        const r = await this.miniLogic.addData(this.paddingData(state.formData));
        dispatch({type:'Loading',payload: false})
        if (r.data.statusCode == 200) {
            message.success("保存成功");
            return true
        } else {
            message.error(r.data.returnMessage );
            return false
        }

    }
    async updSubmit({ state, dispatch,id }) {
        dispatch({type:'Loading',payload: true})
        let params =  this.paddingData(state.formData)
        params['id'] = id
        const r = await this.miniLogic.updData(params);
        dispatch({type:'Loading',payload: false})
        if (r.data.statusCode == 200) {
            message.success("更新成功");
            return true
        } else {
            message.error(r.data.returnMessage);
            return false
        }
    }
}
export const formAction = new FormAction();