import message from "antd/lib/message"
// import axios from "axios"
import Cookies from "js-cookie"
import { DataTableAction } from "../../../../component/table/action/data_table_action"
import { Tools, axios } from "../../../../config"
import { EquipmentLogic } from "../../../../logic/equip_logic"
import { saveAs } from 'file-saver';

class EquipAction extends DataTableAction {

    async delRow({dispatch, id}) {
        const r = await new EquipmentLogic().delData(id);

        if (r.data.statusCode == 200) {
            message.success("删除成功")
        }else{
            message.error("删除失败")
        }
    }

    delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {
       
    }
    async loadPage({ dispatch, pageIndex, pageSize, search }) {
        this.setLoading({dispatch,loading: true})
        const { SearchKey } = search;
        const params: any = {
            "pageIndex": pageIndex,
            "pageSize": pageSize,
            "seachKey":SearchKey,
            "orderBy":'',
            "sort":'',
        }
        // 档案列表
        const r = await new EquipmentLogic().selList(params);

        if (r.data.statusCode == 200) {
            const dataList = r.data.data.dataSource;         
            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: dataList, pageSize });
        }
        this.setLoading({dispatch,loading: false})

    }
    setCondition({ dispatch, key, value }) {
        const params = {
        }
            params[key] = value;
        dispatch({ type: "SetSearch", payload: params })
    }
  
}
export const fileAction = new EquipAction();