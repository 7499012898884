// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../config";
import { effeative } from "./common_logic";

interface IAddData {
    id: string;
    "cause": number;
    "status": number;
    "differencePrice": number;
    "remark": string;
    "inventoryCheckDetailDtos": {
        "goodsID": string;
        "shopName": string;
        "goodsUnit": string;
        "sysNumber": number;
        "checkNumber": number;
        "inventoryAvg": number;
        "differenceNumber": number;
        "differencePrice": number;
        "remark": string;
    }[]
}
interface ISelList {
    "keyWords": string;
    "addDateTimeStart": string;
    "addDateTimeEnd": string;
    "status": number;
    "pageIndex": number;
    "pageSize": number;
    "orderBy": string;
    "sort": string;
}

export class CheckLogic {
    async addData(params: IAddData) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("check_add_data"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    async updData(params: IAddData) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("check_update_data"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    async selList(params: ISelList) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("check_select_list"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    async delData(id: string) {
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("check_delete_data"),
            params: {
                ID: id
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    async selData(id: string) {
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("check_select_data"),
            params: {
                InvCheckID: id
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r.data.data;
    }
    
}