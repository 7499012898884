import { ConfigApi } from "./config.api";
import axios from "axios";
import Cookies from "js-cookie";

export const Tools = {
    //'smallProgram.UploadImages'
    getApiAddress(keyWorlds: string, ...args: string[]) {
        let reg = /\{.*?\}/g, path: any = ConfigApi.apiPath;

        let url: string = path;
        for (let _o of keyWorlds.split('.')) {
            url = url[_o];
        }

        if (url) {
            let arr: any;
            arr = url.match(reg)
            for (let i = 0; i < args.length; i++) {
                url = url.replace(arr[i], args[i]);
            }
            return `${ConfigApi.serviceDomain}/${url}`;
        }
    },
    getImageAddress(url: string, goodsSource?: number) {
        if(goodsSource && goodsSource == 2){
            return `${ConfigApi.whImgDomain}${url}`;
        }else{  
        return `${ConfigApi.imgDomain}${url}`;
        }
    }
}

// 封装请求
// 取消请求操作
const allPendingRequestsRecord = [];
const pending = {};
const removeAllPendingRequestsRecord = () => {
    allPendingRequestsRecord && allPendingRequestsRecord.forEach((func) => {
        // 取消请求（调用函数就是取消该请求）
        func('路由跳转了取消所有请求');
    });
    // 移除所有记录
    allPendingRequestsRecord.splice(0);
};

// 取消同一个重复的ajax请求
const removePending = (key, isRequest = false) => {
    if (pending[key] && isRequest) {
        pending[key]('取消重复请求');
    }
    delete pending[key];
};

// 取消所有请求的函数
export const removeAllRequest = (mes = '', callback = () => {}) => {
    removeAllPendingRequestsRecord();
    callback();
};
 const RequestAxios = axios.create({
    headers:{
        SYSTOKEN: Cookies.get("token"),
        'ClientSource':'web',
        shopID: Cookies.get('loginMsg')?JSON.parse(Cookies.get('loginMsg')).shopID:''
    }
 })
 RequestAxios.interceptors.request.use(
    config=>{
        // 在请求发送前执行一下取消操作，防止连续点击重复发送请求(例如连续点击2次按钮)
        let reqData = '';
        // 处理如url相同请求参数不同时上一个请求被屏蔽的情况
        if (config.method === 'get') {
            reqData = config.url + config.method + JSON.stringify(config.params);
        } else {
            reqData = config.url + config.method + JSON.stringify(config.data);
        }
        config.headers = {
            SYSTOKEN: Cookies.get("token"),
            'ClientSource':'web',
            shopID: Cookies.get('loginMsg')?JSON.parse(Cookies.get('loginMsg')).shopID:''
        }
        // 如果用户连续点击某个按钮会发起多个相同的请求，可以在这里进行拦截请求并取消上一个重复的请求
        // removePending(reqData, true);
        // 设置请求的 cancelToken（设置后就能中途控制取消了）
        config.cancelToken = new axios.CancelToken((c) => {
            pending[reqData] = c;
            allPendingRequestsRecord.push(c);
        });
        return config
    },
    (error) => Promise.reject(error),
 )
 RequestAxios.interceptors.response.use(
    response=>{
        //  // 在请求发送前执行一下取消操作，防止连续点击重复发送请求(例如连续点击2次按钮)
        //  let reqData = '';
        //  // 处理如url相同请求参数不同时上一个请求被屏蔽的情况
        //  if (response.config.method === 'get') {
        //      reqData = response.config.url + response.config.method + JSON.stringify(response.config.params);
        //  } else {
        //      reqData = response.config.url + response.config.method + JSON.stringify(response.config.data);
        //  }
        //  // 响应成功删除
        //  removePending(reqData, true);
        return response
    },
    error=>{
        // 终结由于取消重复请求而引发的报错提示
        if (axios.isCancel(error)) {
            return new Promise(() => {});
        }
        return Promise.reject(error)
    }
 )
export const requestAxios =  RequestAxios;