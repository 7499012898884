import React, { useEffect, useState } from "react";
import { Layout } from "../../component/layout";
import { TabsStyle, Tab, TabList, TabPanel } from "../../component/react-tabs";
import { MenuLogic } from "../../logic/menu_logic";
import { CouponStoreProvider } from "./store/coupon/file_store";
import { Coupon } from "./view/coupon/coupon";
import { SeckillStoreProvider } from "./store/seckill/file_store";
import { Seckill } from "./view/seckill/seckill";
import { SpecialStoreProvider } from "./store/special/file_store";
import { Special } from "./view/special/special";
import { FullgiftStoreProvider } from "./store/fullgift/file_store";
import { Fullgift } from "./view/fullgift/fullgift";
import { PrecisionStoreProvider } from "./store/precision/file_store";
import { Precision } from "./view/precision/precision";
import { DiscountStoreProvider } from "./store/discount/file_store";
import { Discount } from "./view/discount/discount";
import { DataTableStoreProvider } from "../../component/table/store/data_table_store";
import { BatchCoupon } from "./view/batchCoupon/batchCoupon";
import { BatchCouponStoreProvider } from "./store/batchCoupon/file_store";
import { FileStoreProvider as PromotionStoreProvider } from "./store/promotion/file_store";
import { FileStoreProvider as BookingStoreProvider } from "./store/booking/file_store";
import { FileStoreProvider as ExchangeCouponStoreProvider } from "./store/exchange/coupon/file_store";
import { FileStoreProvider as ExchangeGoodsStoreProvider } from "./store/exchange/goods/file_store";
import { Promotion } from "./view/promotion";
import { Booking } from "./view/booking";
import { ExchangeCoupon } from "./view/exchange/coupon";
import { ExchangeGoods } from "./view/exchange/goods";
export function MarketingPage() {
  const [menuLists, setData] = useState([]);
  const [numberTab, setDatanum] = useState(-1);
  var test = window.location.href.split("=")[1];

  useEffect(() => {
    (async function () {
      let r = await new MenuLogic().menuData();
      //console.log(r)
      var arr = [];
      if (r && r.length > 0) {
        r = r.filter(function (v) {
          return !v.hidden;
        });

        for (let i = 0; i < r.length; i++) {
          if (r[i].path == "/marketing" && r[i].children) {
            arr = r[i].children.filter(function (v) {
              return !v.hidden
              // return true;
            });
          }
        }
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].component == test) {
            setDatanum(i);
          }
        }
      }
      setData(arr);
    })();

    return () => {};
  }, []);
  return (
    // <Layout fullWidth={true}>
    <>
      {numberTab != -1 && (
        <TabsStyle style="Full" defaulIndex={numberTab}>
          <TabList>
            {menuLists.map((v, i) => (
              <Tab key={i}>{v.name}</Tab>
            ))}
          </TabList>
          {/* fullgift */}
          {menuLists.map((v) => {
            return v.component == "Coupon" ? (
              <TabPanel>
                <CouponStoreProvider>
                  <DataTableStoreProvider>
                    <Coupon viewPower={v.viewPower} />
                  </DataTableStoreProvider>
                </CouponStoreProvider>
              </TabPanel>
            ) : v.component == "Seckill" ? (
              <TabPanel>
                <SeckillStoreProvider>
                  <DataTableStoreProvider>
                    <Seckill viewPower={v.viewPower} />
                  </DataTableStoreProvider>
                </SeckillStoreProvider>
              </TabPanel>
            ) : v.component == "Discount" ? (
              <TabPanel>
                <DiscountStoreProvider>
                  <DataTableStoreProvider>
                    <Discount viewPower={v.viewPower} />
                  </DataTableStoreProvider>
                </DiscountStoreProvider>
              </TabPanel>
            ) : v.component == "Special" ? (
              <TabPanel>
                <SpecialStoreProvider>
                  <DataTableStoreProvider>
                    <Special viewPower={v.viewPower} />
                  </DataTableStoreProvider>
                </SpecialStoreProvider>
              </TabPanel>
            ) : v.component == "Fullgift" ? (
              <TabPanel>
                <FullgiftStoreProvider>
                  <DataTableStoreProvider>
                    <Fullgift viewPower={v.viewPower} />
                  </DataTableStoreProvider>
                </FullgiftStoreProvider>
              </TabPanel>
            ) : v.component == "Precision" ? (
              <TabPanel>
                <PrecisionStoreProvider>
                  <DataTableStoreProvider>
                    <Precision viewPower={v.viewPower} />
                  </DataTableStoreProvider>
                </PrecisionStoreProvider>
              </TabPanel>
            ) : v.component == "BatchCoupon" ? (
              <TabPanel>
                <BatchCouponStoreProvider>
                  <DataTableStoreProvider>
                    <BatchCoupon viewPower={v.viewPower} />
                  </DataTableStoreProvider>
                </BatchCouponStoreProvider>
              </TabPanel>
            ) : v.component == "Promotion" ? (
              <TabPanel>
                {/* 时段促销 */}
                <PromotionStoreProvider>
                  <DataTableStoreProvider>
                    <Promotion viewPower={v.viewPower} />
                  </DataTableStoreProvider>
                </PromotionStoreProvider>
              </TabPanel>
            ) : v.component == "Booking" ? (
              <TabPanel>
                {/* 商城预售 */}
                <BookingStoreProvider>
                  <DataTableStoreProvider>
                    <Booking viewPower={v.viewPower} />
                  </DataTableStoreProvider>
                </BookingStoreProvider>
              </TabPanel>
            ) : v.component == "Exchange" ? (
              <TabPanel>
                {/* 积分兑换 */}
                {
                  <TabsStyle style="BottomLine">
                    <TabList>
                      {v.children.map((item, i) => (
                        <Tab key={i}>
                          <span>{item.name}</span>
                          <hr />
                        </Tab>
                      ))}
                      <hr />
                    </TabList>
                    {v.children.map((item) => {
                      return item.component == "Coupon" ? (
                        <TabPanel>
                          {/* 优惠劵兑换 */}
                          <ExchangeCouponStoreProvider>
                            <DataTableStoreProvider>
                              <ExchangeCoupon viewPower={item.viewPower} />
                            </DataTableStoreProvider>
                          </ExchangeCouponStoreProvider>
                        </TabPanel>
                      ) : item.component == "Goods" ? (
                        <TabPanel>
                          {/* 商品兑换 */}
                          <ExchangeGoodsStoreProvider>
                            <DataTableStoreProvider>
                              <ExchangeGoods viewPower={item.viewPower} />
                            </DataTableStoreProvider>
                          </ExchangeGoodsStoreProvider>
                        </TabPanel>
                      ) : (
                        <span>不存在这个菜单</span>
                      );
                    })}
                  </TabsStyle>
                }
              </TabPanel>
            ) : (
              <span>不存在这个菜单</span>
            );
          })}
        </TabsStyle>
      )}
    </>
    // </Layout>
  );
}
