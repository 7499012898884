import { Cascader, Col, Form, Form as FormAntd, Input, Radio, Row, InputNumber } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useContext, useEffect, useState } from "react";
import { RadioStyle } from "../../../../component/radio-style";
import { autoRem } from "../../../../global.style";
import { formAction } from "../../action/grade/form_action";
import { FormStore } from "../../store/grade/form_store";

export function FormBasic(title) {
  const { state, dispatch: formDispatch } = useContext(FormStore);
  const action = formAction;
  var isLook = false;
  if (title.title == "查看降级规则") {
    isLook = true;
  } else {
    isLook = false;
  }
  return (
    <>
      <div style={{ margin: "1.9375rem 0 0 0" }}>
        <Row gutter={20}>
          <Col span={18}>
            <Form.Item
              name="grade-name"
              label="等级名称"
              labelCol={{ span: 6 }}
              rules={[
                {
                  required: true,
                  message: "请输入等级名称",
                },
              ]}
            >
              <Input
                disabled={isLook}
                placeholder="请输入等级名称"
                maxLength={10}
                onChange={(v) =>
                  action.setValue({
                    dispatch: formDispatch,
                    value: v.target.value,
                    attribute: "GradeName",
                  })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={18}>
            <Form.Item
              name="grade-useProportion"
              label="使用比列"
              labelCol={{ span: 6 }}
              rules={[
                {
                  required: false,
                  message: "请输入结算时可使用比例",
                },
              ]}
            >
              {/* {state.form.} */}
              <InputNumber  defaultValue={state.formData.useProportion} size='large' 
               formatter={(value) => `${value}%`}
              max={100} min={1} precision={0}  step={1}  onChange={(v) =>
                  {
                    action.setValue({
                    dispatch: formDispatch,
                    value: v,
                    attribute: "useProportion",
                  })
                }
                }/>
              {/* <Input
                disabled={isLook}
                placeholder="请输入结算时可使用比例"
                maxLength={10}
                onChange={(v) =>
                  action.setValue({
                    dispatch: formDispatch,
                    value: v.target.value,
                    attribute: "useProportion",
                  })
                }
              /> */}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={18}>
            <Form.Item name="remark" label="备注" labelCol={{ span: 6 }}>
              <TextArea
                disabled={isLook}
                rows={2}
                placeholder="请输入备注"
                onChange={(v) =>
                  action.setValue({
                    dispatch: formDispatch,
                    value: v.target.value,
                    attribute: "Remark",
                  })
                }
              ></TextArea>
            </Form.Item>
          </Col>
        </Row>
      </div>
    </>
  );
}
