import { useBoolean } from "ahooks";
import { Button, Input, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect, useState } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { Search } from "../../../../component/search";
import { DataTable, Table } from "../../../../component/table";
import {
  DataTableStore,
  DataTableStoreProvider,
} from "../../../../component/table/store/data_table_store";
import { Form } from "./precision_from";
import { precisionAction } from "../../action/precision/file_action";
import { PrecisionStore } from "../../store/precision/file_store";
import { PrecisionFormStoreProvider } from "../../store/precision/form_store";
import { TableExpand } from "../../../../component/table-expand";
import { ConfirmModal, Modal } from "../../../../component/modal";
import { Tips, TipsGroup } from "../../../../component/tips";
import { InventorySearch } from "./file_search";
import { Mask } from "../../../../component/mask";
import { defualtPageSize, Progress } from "../../../../logic/common_logic";
import { AuthLogic } from "../../../../logic/auth_logic";
import { Control, Del, Edit, See } from "../../../../component/control-column";
import { ChoosePrecision } from "../../../../component/precision-modal";
import img_stop from "../../../../assets/images/zz.png";
import img_detail from "../../../../assets/images/mx.png";
const formParams = {
  method: "Create",
  id: "",
  subId: "",
  item: null,
};
export function Precision(viewPower) {
  const [createState, createFunc] = useBoolean(false);
  const [searchState, searchFunc] = useBoolean(false);
  const [confirmState, confirmFunc] = useBoolean(false);
  const [detailState, detailFunc] = useBoolean(false);
  const [stopState, stopStateFunc] = useBoolean(false);
  const { state, dispatch } = useContext(PrecisionStore);
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);
  const [] = useBoolean(false);
  const [precisionId, setId] = useState(null);
  const [actionName, setName] = useState("");
  const action = precisionAction,
    formAction = precisionAction;
  const [menuButton, setData] = useState([]);
  useEffect(() => {
    (async function () {
      let r = await new AuthLogic().selButton(viewPower.viewPower);
      if (r && r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          r[i] = r[i].split("_")[r[i].split("_").length - 1];
        }
      }

      setData(r);
    })();
    return () => {};
  }, []);
  let width = menuButton.length ? menuButton.length : 3;
  const columns: ColumnsType = [
    {
      title: "操作",
      key: "1",
      dataIndex: "oddNumbers",
      align: "center",
      width: Control.width(width),
      render: Control.render(
        (v, r) =>
          menuButton.indexOf("VIEW") != -1 && (
            <See
              click={() => {
                formParams.id = r.id;
                formParams.method = "Preview";

                createFunc.setTrue();
              }}
            />
          ),
        (v, r) =>
          menuButton.indexOf("UPDATE") != -1 &&
          r.dataState == 0 && (
            <Edit
              click={() => {
                formParams.id = r.id;
                formParams.subId = r.id;
                formParams.method = "Edit";
                formParams.item = r;

                createFunc.setTrue();
              }}
            />
          ),
        (v, r) =>
          menuButton.indexOf("DELETE") != -1 && (
            <Del
              click={() => {
                formParams.id = r.id;
                formParams.subId = r.id;
                confirmFunc.setTrue();
              }}
            />
          ),
        (v, r) =>
          menuButton.indexOf("STOP") != -1 &&
          r.dataState != 2 && (
            <img
              src={img_stop}
              alt=""
              onClick={() => {
                formParams.id = r.id;
                formParams.subId = r.id;
                stopStateFunc.setTrue();
              }}
            />
          ),
        (v, r) =>
          menuButton.indexOf("VIEW") != -1 && (
            <img
              src={img_detail}
              alt=""
              onClick={() => {
                formParams.id = r.id;
                setId(r.id);
                setName(r.actionName);
                detailFunc.setTrue();
              }}
            />
          )
      ),
    },

    {
      title: "活动名称",
      dataIndex: "actionName",
      key: "3",
      align: "center",
    },

    {
      title: "活动起止日期",
      key: "6",
      width: 160,
      render: (v) => (
        <>
          {v.taskType == 1 ? (
            <span>立即生效</span>
          ) : (
            <span>{v.beginDate + "~" + v.endDate}</span>
          )}
        </>
      ),
    },
    // {
    //     title: '活动起止时间段',
    //     key: '7',
    //     align: "center",
    //     width: 160,
    //     render: (v) => <span>{v.beginTime+'~'+v.endTime}</span>
    // },

    {
      title: "活动状态",
      key: "8",
      align: "center",
      render: (v) => (
        <>
          <Tag
            color={
              v.dataState == 0
                ? "geekblue"
                : v.dataState == 1
                ? "success"
                : "volcano"
            }
          >
            {v.dataStateString}
          </Tag>
        </>
      ),
    },
    {
      title: "创建人",
      dataIndex: "addName",
      key: "8",
      align: "center",
      // width: 140
    },
    {
      title: "创建时间",
      dataIndex: "addTime",
      key: "8",
      align: "center",
      // width: 140
    },
    // {
    //     title:'明细',
    //     key:'2',
    //     align:'center',
    //     render: (v)=><><span style={{cursor:'pointer'}} >查看明细</span></>
    // },
  ];

  const onSearch = async (pageIndex) => {
    searchFunc.setFalse();
    await action.loadPage({
      pageIndex: pageIndex,
      pageSize: defualtPageSize(),
      dispatch: tableDispatch,
      search: state.search,
    });
  };

  useEffect(() => {}, [precisionId]);
  return (
    <>
      <Search>
        <Input
          maxLength={20}
          allowClear
          placeholder="请输入活动名称"
          onChange={(e) =>
            action.setCondition({
              dispatch,
              key: "seacherKey",
              value: e.target.value,
            })
          }
        />
        <input type="button" value="快速查找" onClick={() => onSearch(1)} />

        {/* <TipsGroup>
                    <input type="button" value="高级查找" onClick={() => searchFunc.toggle()} />
                    <Tips display={searchState} width="48rem">
                        <InventorySearch onSearch={()=> onSearch(1)} />
                    </Tips>
                    <Mask visible={searchState} onClick={() => searchFunc.setFalse()} />
                </TipsGroup> */}
      </Search>

      <ButtonPanel>
        {/* { menuButton.indexOf("ADD") != -1 && */}
        <Button
          onClick={() => {
            formParams.id = "";
            formParams.method = "Create";

            createFunc.setTrue();
          }}
        >
          新增活动
        </Button>
      </ButtonPanel>

      <DataTable
        columns={columns}
        action={action}
        onLoad={async () => {
          await onSearch(1);
          Progress.done();
        }}
        onChange={(pageIndex) => {
          onSearch(pageIndex);
        }}
        scroll={{ scrollToFirstRowOnChange: true, autoY: true, x: 1060 }}
        loading={tableState.loading}
      />

      {createState && (
        <PrecisionFormStoreProvider>
          <Form
            audit={true}
            close={(hasUpdate) => {
              if (hasUpdate) {
                onSearch(tableState.current);
              }

              createFunc.setFalse();
            }}
            action={formAction}
            id={formParams.id}
            subId={formParams.subId}
            item={formParams.item}
            method={formParams.method}
          />
        </PrecisionFormStoreProvider>
      )}

      <ChoosePrecision
        visibleModal={detailState}
        precisionId={formParams.id}
        close={() => detailFunc.setFalse()}
      />

      <ConfirmModal
        visible={confirmState}
        title="温馨提示"
        subTitle="确认删除？"
        message="删除之后信息无法恢复，请慎重考虑"
        close={async (r) => {
          if (r) {
            await action.delRow(formParams.subId);

            onSearch(tableState.current);
          }
          confirmFunc.setFalse();
        }}
      />
      <ConfirmModal
        visible={stopState}
        title="温馨提示"
        subTitle="确认终止？"
        message="终止之后状态无法更改，请慎重考虑"
        close={async (r) => {
          if (r) {
            await action.stopPrecision(formParams.subId);

            onSearch(tableState.current);
          }
          stopStateFunc.setFalse();
        }}
      />
    </>
  );
}
