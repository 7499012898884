import { createContext, useReducer } from "react";

type Dispatch = { type, payload }

class Detail{
    
}
class FormData {
    peopleList: any[] = [];
    userid: string;
    begin: string;
    end: string;
    beginTime: string;
    detail: any[] = []
}   
interface IState {
    ifInit: boolean;
    method: string;
    detail: any[];
    formData: FormData;
}

const initialArgs: IState = {
    ifInit: false,
    method: '',
    detail: [],
    formData: new FormData(),
}
interface IContextProps {
    state: IState;
    dispatch: ({ type }: Dispatch) => void;
}
export const FormStore = createContext<IContextProps>(null);
function reducer(state: IState, { type, payload}: Dispatch) {
    switch(type) {
        case 'setFormData':
            {
             return {
                    ...state,
                    formData: {
                        ...state.formData,
                        ...payload
                    }
                }
            }
        case 'setValue': {
            return{
                ...state,
                ...payload
            }
        }
        case 'IfInit':
            {
                state.ifInit = payload
                return { ...state }
            }
        case 'Reset': 
        {
            let peopleList = state.formData.peopleList;
           
            return { ...initialArgs,
                    formData: {
                        ...initialArgs.formData,
                        peopleList: peopleList
                    } }
        }
        default: return {...state }
    }

}
export function FormStoreProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialArgs);

    const v = { state, dispatch };
    return <FormStore.Provider value={v}>{props.children}</FormStore.Provider>
}