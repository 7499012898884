import { Tools, axios } from "../config";
import { effeative } from "./common_logic";
import Cookies from "js-cookie";

export class CommissionRuleLogic{
    /**
     * @description 获取收银提成规则 - 分页查询
     */
    async getList(data){
        const r = effeative(await axios({
            url: Tools.getApiAddress('commissionrule_list'),
            method:'POST',
            data
        }))
        return r
    }
    /**
     * 添加提成规则
     */
    async add(data){
        const r = effeative(await axios({
            url: Tools.getApiAddress('commissionrule_add'),
            method:'POST',
            data
        }))
        return r
    }
    /**编辑提成规则 */
    async update(data){
        const r = effeative(await axios({
            url: Tools.getApiAddress('commissionrule_update'),
            method:'POST',
            data
        }))
        return r
    }
     /**提成规则单条记录 */
    async getDetailById(ID: string){
        const r = effeative(await axios({
            url: Tools.getApiAddress('commissionrule_get'),
            method:'GET',
            params:{
                ID: ID
            }
        }))
        return r
    }
     /** 修改提成规则状态
      * @param ID
      * @param Status 1启用 2禁用 3删除
      */
    async changeStatus(ID: string, Status: number){
        const r = effeative(await axios({
            url: Tools.getApiAddress('commissionrule_change_status'),
            method:'GET',
            params:{
                ID: ID,
                Status: Status
            }
        }))
        return r
    }
     /** 获取所有提成规则  */
    async getListAll(){
        const r = effeative(await axios({
            url: Tools.getApiAddress('commissionrule_all_list'),
            method:'GET',
        }))
        return r
    }
     /** 获取门店提成记录
      * @param seackerKey 订单号、收银员查询
      * @param beginTime  默认当前月
      * @param endTime
       */
    async getCommissionLogs(data){
        const r = effeative(await axios({
            url: Tools.getApiAddress('commission_logs'),
            method:'POST',
            data
        }))
        return r
    }
    /**
     * @description 作废提成
     * @param id 
     * @param date
     */
    async cancelCommission(id,date){
        const r = effeative(await axios({
            url: Tools.getApiAddress('commission_cancel'),
            method: 'GET',
            params:{
                ID: id,
                date
            }
        }))
        return r
    }
}