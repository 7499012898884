import { useBoolean, useRequest } from "ahooks";
import { Input, Modal, Select, Tree, Button, TreeSelect, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect, useState } from "react";
import { Search } from "../search";
import { DataTable } from "../table";
import {
  DataTableStore,
  DataTableStoreProvider,
} from "../table/store/data_table_store";
import { ChooseGoodsPurchaseStore, ChooseStoreProvider } from "./store/choose_store";
import styles from "./style/index.module.scss";
import { Select as SelectAntd } from "antd";
import { chooseGoodsAction } from "./action/choose_action";
import { defualtPageSize } from "../../logic/common_logic";

let classId: string[] = [];

function Goods(params: IChooseGoodsSpec) {
  let {
    visibleModal,
    close,
    filter,
    type,
    clearSelectedList,
    rowKey
  } = params;
  const { Option } = Select,
    action = chooseGoodsAction;
  const { state, dispatch } = useContext(ChooseGoodsPurchaseStore);
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);

  const handleOk = () => {
    close && close(true, tableState.selectList);
  };

  const handleCancel = () => {
    close && close(false, null);
  };

  const columns: ColumnsType = [
    {
      title: "商品名称",
      dataIndex: "goodsName",
      key: "1",
      align: "center",
    },
    {
      title: "规格条码",
      dataIndex: "barCode",
      key: "2",
      align: "center",
    },
    {
      title: "商品分类",
      dataIndex: "classesName",
      key: "3",
      align: "center",
    },
    {
      title: "单位",
      // dataIndex: 'unitName',
      key: "4",
      align: "center",
      render: (v) => (
        <span>
          {/* {v.unitContent} */}
          1/
          {v.unitName}
        </span>
      ),
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "6",
      align: "center",
      render: (v) => <span>{v == 1 ? "上架" : "下架"}</span>,
    },
    {
      title: "商品来源",
      dataIndex: "goodsSource",
      key: "7",
      align: "center",
      render: (v) => (
        <>
          {v == 1 && <Tag color="blue">自定义</Tag>}
          {v == 2 && <Tag color="red">仓库采购</Tag>}
        </>
      ),
    },
  ];

  const onSearch = async (pageIndex, classId?) => {
      await action.loadPage({
        pageIndex: pageIndex,
        pageSize: defualtPageSize(),
        dispatch: tableDispatch,
        state,
        search: { ...state.search, classesId: classId,source: params?.filter?.source },
      });
  };

  useRequest(() => action.init({ dispatch }));

  const checkSelectable = (record) => {
    return false;
  };
  let tableRowSelection: any = {};
  if (type == "radio") {
    tableRowSelection = {
      rowSelection: {
        type: "radio",
        getCheckboxProps: (record) => ({
          disabled: checkSelectable(record), // Column configuration not to be checked
        }),
      },
    };
  }
 
  
  useEffect(() => {
    if (clearSelectedList) {
      action.clearSelectedRowKeys({ dispatch });
    }
    return () => {};
  });
  return (
    <Modal
      title="选择商品"
      visible={visibleModal}
      onOk={handleOk}
      onCancel={handleCancel}
      width={1000}
      cancelText="取消"
      okText="确定"
      footer={
        <>
          <Button
            type="primary"
            style={{
              backgroundColor: "#f7ba00",
              borderColor: "#f7ba00",
              color: "white",
            }}
            onClick={handleCancel}
          >
            取消
          </Button>
          <Button type="primary" onClick={handleOk}>
            确认
          </Button>
        </>
      }
    >
      <div className={styles.root}>
        <Search>
          <Select
            style={{ width: "12rem", marginLeft: 10 }}
            size="small"
            onChange={(v) => action.setCondition("Status", v, dispatch)}
            defaultValue={-1}
          >
            {state.statusList.map((v) => (
              <SelectAntd.Option key={v.id} value={v.id}>
                {v.value}
              </SelectAntd.Option>
            ))}
          </Select>
          <Input
            maxLength={20}
            allowClear
            placeholder="请输入商品名称、编码"
            onChange={(e) =>
              action.setCondition("Keywords", e.target.value, dispatch)
            }
          />
          <input type="button" value="快速查找" onClick={() => onSearch(1)} />
        </Search>

        <div style={{ marginTop: 10, height: 300 }}>
          <div className={styles.tree}>
          
            <TreeSelect
              style={{ width: "12rem", marginLeft: 10 }}
              showCheckedStrategy={TreeSelect.SHOW_PARENT}
              dropdownMatchSelectWidth={false}
              defaultValue={state.search.classesId}
              value={state.search.classesId}
              treeCheckable={true}
              treeData={state.areaList}
              onChange={(data) => {
                action.setCondition("Classes", data, dispatch);
                onSearch(1, data);
              }}
              placeholder="选择商品分类"
            />
          </div>
          <div className={styles.list}>
            <DataTable
              columns={columns}
              action={action}
              saveKeys={true}
              rowKey={rowKey?rowKey:'id'}
              rowSelection={{
                type: "checkbox",
                getCheckboxProps: (record) => ({
                  disabled: checkSelectable(record), // Column configuration not to be checked
                }),
              }}
              {...tableRowSelection}
              onLoad={() => onSearch(1)}
              onChange={(pageIndex) => {
                onSearch(pageIndex);
              }}
              selectColumn={true}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

type Filter = {
  type?: boolean;
  source?: string
  checkInvcount?: boolean
};
interface IChooseGoodsSpec {
  visibleModal: boolean;
  close?: (status, r) => void;
  filter?: Filter;
  type?;
  shopID?;
  rowKey?;
  clearSelectedList?: boolean;
}
export function ChooseGoodsSpec(params: IChooseGoodsSpec) {
  return (
    <ChooseStoreProvider>
      <DataTableStoreProvider>
        <Goods {...params} />
      </DataTableStoreProvider>
    </ChooseStoreProvider>
  );
}
