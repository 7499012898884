import { Button, Col, Form, Input, Row, Select, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useState } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { FormSubtitle } from "../../../commodity/view/form_subtitle";
import styles from "../../style/inventory_goods_formbasic.module.scss";
import img_del from "../../../../assets/images/shanchu.png";
import { useBoolean } from "ahooks";
import { ChooseGoods } from "../../../../component/choose-goods";
import { Table } from "../../../../component/table";
import { AlarmSetFormStore } from "../../store/alarm_set/alarmSet_form_store";
import { alarmSetFormAction } from "../../action/alarmSet/form_action";
import { autoRem } from "../../../../global.style";
import { NumberTool } from "../../../../tool/numberTool";

const { Option } = Select;

export function DataTable({ dataList, disabled }) {
  const { dispatch } = useContext(AlarmSetFormStore);
  const [current, setCurrent] = useState(1);
  const [pageSize, setSize] = useState(10);
  const action = alarmSetFormAction;

  const columns: ColumnsType = [
    {
      title: "操作",
      key: "1",
      align: "center",
      width: 100,
      render: (e, r: any, i) => (
        <div>
          <img
            src={img_del}
            style={{ width: 20 }}
            onClick={() => {
              if (disabled) return;
              action.delTableItem(dispatch, r.goodsID);
            }}
          />
        </div>
      ),
    },
    {
      title: "序号",
      dataIndex: "index",
      key: "1",
      align: "center",
      width: 50,
    },
    {
      title: "商品编码",
      dataIndex: "goodsNo",
      key: "2",
      align: "center",      
      width: 160
    },
    {
      title: "商品名称",
      dataIndex: "goodsName",
      key: "3",
      align: "center",
      width: 120
    },
    {
      title: "单位",
      dataIndex: "unitName",
      key: "4",
      align: "center",
      width: 80
    },
    {
      title: "商品分类",
      dataIndex: "goodsClassName",
      key: "5",
      align: "center",
      width: 120
    },
    {
      title: "商品来源",
      dataIndex: "goodsSource",
      key: "4",
      align: "center",
      render: (v) => (
        <>
          {v == 1 && <Tag color="blue">自定义</Tag>}
          {v == 2 && <Tag color="red">仓库采购</Tag>}
        </>
      ),
    },
    {
      title: "下限值",
      dataIndex: "minNumber",
      key: "9",
      align: "center",
      width: 120,
      render: (e, r: any, i) => {
        return (
          <Input style={{width: '90%'}}
            disabled={disabled}
            type="number"
            value={e}
            onChange={(e) => {
              dispatch({
                type: "ChangeTableItem",
                payload: {
                  id: r.goodsID,
                  key: "minNumber",
                  value: parseFloat(e.target.value),
                },
              });
            }}
          />
        );
      },
    },
    {
      title: "上限值",
      dataIndex: "maxNumber",
      key: "10",
      align: "center",
      width: 120,
      render: (e, r: any, i) => {
        return (
          <Input style={{width: '90%'}}
            disabled={disabled}
            type="number"
            value={e}
            onChange={(e) => {
              dispatch({
                type: "ChangeTableItem",
                payload: {
                  id: r.goodsID,
                  key: "maxNumber",
                  value: parseFloat(e.target.value),
                },
              });
            }}
          />
        );
      },
    },
   
  ];

  return (
    <Table
      scroll={{ y: 420 }}
      dataSource={dataList}
      columns={columns}
      onChange={(pagination) => {
        setCurrent(pagination.current);
        setSize(pagination.pageSize);
      }}
    />
  );
}



export function AlarmSetFormBasic({ disabled, updateFormField }) {
  const [goodsState, goodsFunc] = useBoolean(false);

  const { state, dispatch } = useContext(AlarmSetFormStore);
  const { formData } = state;
  const action = alarmSetFormAction;
  let height = document.body.clientHeight - 188 + 26;
  return (
    <div className={styles.root} style={{ height: height + "px" }}>
      <div className={styles.content}>
        <FormSubtitle>基础信息</FormSubtitle>

        <div
          style={{
            margin: "" + autoRem(1.9375) + " " + autoRem(1.25) + " 0 0",
          }}
        >
          
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item
                label="门店"
                labelCol={{ span: 8 }}
              >
               <span>{formData.shopName}</span>
              </Form.Item>
            </Col>
           
          </Row>
        </div>

        <div >
          <FormSubtitle>商品信息</FormSubtitle>
        </div>
        <div >
          <ButtonPanel>
            <Button disabled={disabled} onClick={() => goodsFunc.setTrue()}>
              选择商品
            </Button>
            {/* <Button onClick={() => goodsFunc.setTrue()}>批量删除</Button> */}
          </ButtonPanel>
        </div>

        <DataTable dataList={state.tableList} disabled={disabled} />
      </div>
      <ChooseGoods
        chooseType="AlarmSet"
        visibleModal={goodsState}
        rowKey="id"
        close={(status, r) => {
          if (r) {
            action.addTableItem({ dispatch, list: r });
          }
          goodsFunc.setFalse();
        }}
      />
    </div>
  );
}
