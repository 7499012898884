import { createContext, useContext, useReducer } from "react";

export const FileStore = createContext<IContextProps>(null);

interface IContextProps {
  state: IState;
  dispatch: ({ type }: Dispatch) => void;
}

type Dispatch = { type; payload };

class Search {
  keywords: string;
  orderNo: string;
  statusId: string;
  typeId: string;
  people: string;
  dateStart: string;
  dateEnd: string;
}

interface IState {
  typeList: any[];
  search: Search;
  isLoading: boolean;
  msg:string
}

const initialArgs = {
  typeList: [{ id: 1, value: "门店订单" }],
  search: new Search(),
  isLoading: false,
  msg:'退款中...'
};

function reducer(state: IState, { type, payload }: Dispatch) {
  switch (type) {
    case "SetSearch":
      return {
        ...state,
        search: {
          ...state.search,
          ...payload,
        },
      };
    case "isloading": {
      state.isLoading = payload.isLoading;
      state.msg = payload.msg
      return { ...state };
    }
    default:
      return state;
  }
}

export function FileStoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialArgs);

  const v = { state, dispatch };
  return <FileStore.Provider value={v}>{props.children}</FileStore.Provider>;
}
