import { useBoolean } from "ahooks";
import { Button, Input, message, Modal } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect, useState } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { DataTable } from "../../../../component/table";

import img_edit from "../../../../assets/images/bianji.png";
import img_see from "../../../../assets/images/chakan1.png";
import img_del from "../../../../assets/images/shanchu.png";
import { DataTableStore } from "../../../../component/table/store/data_table_store";
import { Search } from "../../../../component/search";
import { RoleForm } from "./role_form";
import { RoleFormStoreProvider } from "../../store/role/role_form_store";
import { roleAction } from "../../action/role/role_action";
import { roleFormAction } from "../../action/role/role_form_action";
import { RoleStore } from "../../store/role/role_store";
import { defualtPageSize, Progress } from "../../../../logic/common_logic";
import { AuthLogic } from "../../../../logic/auth_logic";
import { Control, See, Edit, Del } from "../../../../component/control-column";
import { ConfirmModal } from "../../../../component/modal";
let editId = "";
let seeId = "";
const formParams = {
  method: "Create",
  id: "",
};
export function Role(viewPower) {
  const [createState, createFunc] = useBoolean(false);
  const [deleteState] = useBoolean(false);
  const [confirmState, confirmFunc] = useBoolean(false);
  const [] = useBoolean(false);
  const action = roleAction;
  const [searchState, searchFunc] = useBoolean(false);
  const { state, dispatch } = useContext(RoleStore);
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);

  const [menuButton, setData] = useState([]);
  useEffect(() => {
    (async function () {
      let r = await new AuthLogic().selButton(viewPower.viewPower);
      if (r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          r[i] = r[i].split("_")[r[i].split("_").length - 1];
        }
      }
      setData(r);
    })();

    return () => {};
  }, []);
  const columns: ColumnsType = [
    {
      title: "操作",
      key: "1",
      align: "center",
      width: Control.width(2),
      render: Control.render(
        (v) =>
          menuButton.indexOf("UPDATE") != -1 && (
            <Edit
              click={() => {
                editId = v.id;
                seeId = "";
                createFunc.setTrue();
              }}
            />
          ),
        (v) =>
          menuButton.indexOf("DELETE") != -1 && (
            <Del
              click={() => {
                if (v.isdefault) {
                  message.error("该角色定义不能删除");
                } else {
                  formParams.id = v.id;
                  confirmFunc.setTrue();
                }
              }}
            />
          )
      ),
    },
    {
      title: "角色名称",
      dataIndex: "name",
      key: "2",
      align: "center",
    },
    {
      title: "角色备注",
      dataIndex: "remark",
      key: "3",
      align: "center",
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "4",
      align: "center",
    },
  ];
  const onSearch = async (pageIndex) => {
    await action.loadPage({
      pageIndex: pageIndex,
      pageSize: defualtPageSize(),
      dispatch: tableDispatch,
      search: state.search,
    });
  };
  return (
    <>
      <Search>
        <Input
          allowClear
          placeholder="请输入角色名称"
          maxLength={20}
          onChange={(e) =>
            action.setCondition("Name", e.target.value, dispatch)
          }
        />
        <input type="button" value="快速查找" onClick={() => onSearch(1)} />
      </Search>
      <ButtonPanel>
        {menuButton.indexOf("ADD") != -1 && (
          <Button
            onClick={() => {
              editId = "";
              seeId = "";
              createFunc.setTrue();
            }}
          >
            新增角色
          </Button>
        )}
        {menuButton.indexOf("DELETE") != -1 && (
          <Button
            onClick={async () => {
              if (!tableState.selectList.length) {
                message.warning("请选择删除项");
              } else {
                let arr = tableState.selectList.filter((item) => {
                  return item.isdefault == true;
                });
                if (arr.length > 0) {
                  message.error("存在不能的删除的角色定义");
                } else {
                  await action.delSelectList({
                    dispatch,
                    selectList: tableState.selectList,
                  });

                  action.clearSelectedRowKeys({ dispatch: tableDispatch });

                  await onSearch(tableState.current);
                }
              }
            }}
          >
            批量删除
          </Button>
        )}
        {menuButton.indexOf("EXPORT") != -1 && <Button>导出</Button>}
      </ButtonPanel>

      <DataTable
        columns={columns}
        action={roleAction}
        onLoad={async () => {
          await onSearch(1);
          Progress.done();
        }}
        onChange={(pageIndex) => {
          onSearch(pageIndex);
        }}
        scroll={{ scrollToFirstRowOnChange: true, autoY: true }}
        selectColumn={true}
        loading={tableState.loading}
      />

      {createState && (
        <RoleFormStoreProvider>
          <RoleForm
            close={(hasUpdate) => {
              if (hasUpdate) {
                onSearch(tableState.current);
              }

              createFunc.setFalse();
            }}
            action={roleFormAction}
            editId={editId}
            seeId={seeId}
          />
        </RoleFormStoreProvider>
      )}

      <ConfirmModal
        visible={confirmState}
        title="温馨提示"
        subTitle="确认删除？"
        message="删除之后信息无法恢复，请慎重考虑"
        close={async (r) => {
          if (r) {
            await action.delRow({ dispatch, rowItem: formParams });
            onSearch(tableState.current);
          }
          confirmFunc.setFalse();
        }}
      />
    </>
  );
}
