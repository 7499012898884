import { message } from "antd";
// import axios from "axios";
import Cookies from "js-cookie";
import { saveAs } from 'file-saver';
import { DataTableAction } from "../../../../component/table/action/data_table_action";
import { Tools, axios } from "../../../../config";
import { IntegralLogic } from "../../../../logic/integral_logic";

export class FileAction extends DataTableAction {
    delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {
        throw new Error("Method not implemented.");
    }
    async loadPage({ dispatch, pageIndex, pageSize, search }) {
        this.setLoading({dispatch, loading:true})
        // 积分列表
        const { keywords, oddNumbers, operationName, startDateTime, endDateTime } = search;
        let param: any = {
            keyWords: keywords,
            endDateTime: endDateTime,
            startDateTime: startDateTime,
            oddNumbers: oddNumbers,
            integralType: '',
            operationType: '',
            pageIndex: pageIndex,
            pageSize,
            orderBy: '',
            sort: ''
        }
        if (-1 != operationName) {
            param.operationId = operationName;
        }

        const r = await new IntegralLogic().querysList(param);

        if (r.data.statusCode == 200) {
            const dataList = r.data.data.dataSource;

            const list = dataList.map((v, i) => {
               
                // 收支类型
                // 1:IntegralTypeXfsy,消费收银新增;
                // 2:IntegralTypeCzzs,储值赠送;
                // 3:IntegralTypeJfdk,积分抵扣;
                // 4:IntegralTypeYxzz,营销赠送;
                // 5:IntegralTypeHt,回滚减少;
                v.integralType = v.integralType == 1 ? '消费收银' : v.integralType == 2 ? '储值赠送' : v.integralType == 3? '积分抵扣':  v.integralType == 4?'营销赠送':'回滚减少'
                // 操作类型
                // 1:OperationTypeZj,增加;
                // 2:OperationTypeDk,抵扣;
                // 3:OperationTypeZszj,赠送增加;
                // 4:OperationTypeHT,回退减少;
                v.operationType = v.operationType == 1 ? '增加' : v.operationType == 2 ? '扣减' : v.operationType == 3? '赠送增加' :'回退减少'
                v.key = i + 1;
                return v;
            })

            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: list, pageSize, loading: false });
        } else{
            this.setLoading({dispatch, loading: false})
        }
    }
    // 导出
    async exportList({ search }) {
        // 积分列表
        const { keywords, oddNumbers, operationName, startDateTime, endDateTime } = search;
        let param: any = {
            keyWords: keywords,
            endDateTime: endDateTime,
            startDateTime: startDateTime,
            oddNumbers: oddNumbers,
            integralType: '',
            operationType: '',
            orderBy: '',
            sort: ''
        }
        if (-1 != operationName) {
            param.operationId = operationName;
        }

        const r = await axios({
            method: "POST",
            url: Tools.getApiAddress("menber_point_export"),
            responseType: 'arraybuffer',
            data: param,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })
        if (r.status == 200) {
            let enc = new TextDecoder('utf-8')
            let data = enc.decode(new Uint8Array(r.data))
            if (data.indexOf('statusCode') != -1) {
                let datas = JSON.parse(data)
                if (datas.statusCode == 200) {
                    let blob = new Blob([r.data], { type: 'application/vnd.ms-excel' })
                    saveAs(blob, "会员积分.xlsx");
                } else {
                    message.error(datas.returnMessage)
                }
            } else {
                let blob = new Blob([r.data], { type: 'application/vnd.ms-excel' })
                saveAs(blob, "会员积分.xlsx");
            }
        }
    }
    setCondition(method: "Keywords" | "OddNumbers" | "OperationName" | "StartDateTime" | "EndDateTime", value, dispatch) {

        if ("Keywords" == method) {
            dispatch({ type: "SetSearch", payload: { keywords: value } });
        }
        else if ("OddNumbers" == method) {
            dispatch({ type: "SetSearch", payload: { oddNumbers: value } });
        }
        else if ("OperationName" == method) {
            dispatch({ type: "SetSearch", payload: { operationName: value } });
        }
        else if ("StartDateTime" == method) {
            dispatch({ type: "SetSearch", payload: { startDateTime: value } });
        }
        else if ("EndDateTime" == method) {
            dispatch({ type: "SetSearch", payload: { endDateTime: value } });
        }
    }
}
export const fileAction = new FileAction();