import { createContext, useContext, useReducer } from "react";

export const FileStore = createContext<IContextProps>(null);

interface IContextProps {
    state: IState;
    dispatch: ({ type }: Dispatch) => void;
}

type Dispatch = { type, payload }

interface IState {
    search: Search;
    gradeList: any[];
    TagList: any[];
}
class Search {
    keywords: string;
    membeClassID: string;
    label: any[];
    source: string;
    status: string;
    addDateTimeStart: string;
    addDateTimeEnd: string;
    birthdayStart: string;
    birthdayEnd: string;
};
const initialArgs = {
    dataList: [],
    search: new Search(),
    gradeList: [],
    TagList: []
}

function reducer(state: IState, { type, payload }: Dispatch) {
    switch (type) {
        // 等级列表
        case "LoadGrade":
            return {
                ...state,
                gradeList: payload
            }
        // 标签列表
        case "LoadTag":
            return {
                ...state,
                TagList: payload
            }
        case "SetSearch":
            return {
                ...state,
                search: {
                    ...state.search,
                    ...payload
                }
            }
        default:
            return state;
    }
}

export function FileStoreProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialArgs);

    const v = { state, dispatch };
    return <FileStore.Provider value={v}>{props.children}</FileStore.Provider>
}