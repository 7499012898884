import moment from "moment";
import { createContext, useContext, useReducer } from "react";
import { GoodsEntity } from "../../../../entity/goods_entity";
import { ShopEntity } from "../../../../entity/shop_entity";
import { UnitLogic } from "../../../../logic/unit_logic";

export const ReportFormStore = createContext<IContextProps>(null);

interface IContextProps {
  state: IState;
  dispatch: ({ type }: Dispatch) => void;
}

type Dispatch = { type; payload };

class Search {
  keyword: string;
  areaId: string;
  statusId: string;
  typeId: string;
}
export class FormData {
  keyword: string;
  shop = new ShopEntity();
  remark: string;
  userName: string;
  inStockUnitPrice: number;
  date: string = moment().format("YYYY-MM-DD HH:mm:ss");
  checkNumber: number;
  reasonId: number;
  typeId: number;
}

export class TableItem {
  serial: number;
  goodsId: string;
  goodsID: string;
  id: string;
  goodsCode: string;
  goodsName: string;
  unitName: string;
  systemNumber: number;
  averagePrice: number;
  unitNameId: string;
  goodsType: number;
  number: number;
  price: number;

  inventoryAvg: number;

  goodsSpecifications: any[];
  storageBatchNumber: string

  constructor(data) {
    {
      const {
        putNumber,
        goodsUnitID,
        id,
        goodsID,
        goodsNo,
        goodsName,
        goodsUnit,
        invckCount,
        averageRate,
        checkNumber,
        inventoryAvg,
        inventoryNumber,
        inventoryPrice,
        sumNumber,
        goodsType,
        storageBatchNumber
      } = data;
      // this.goodsType = goodsType || 1;
      this.goodsId = goodsID || id;
      this.goodsCode = goodsNo;
      this.goodsName = goodsName;
      this.goodsID = goodsID || id;
      this.unitNameId = goodsUnit || goodsUnitID;
      this.unitName = data["goodsUnitName"] || data["unitName"];
      this.systemNumber = sumNumber || 0;
      this.averagePrice = inventoryPrice?? inventoryAvg ?? 0;
      this.inventoryAvg = inventoryPrice ??inventoryAvg ?? 0;
      this.storageBatchNumber = storageBatchNumber
      // this.goodsSpecifications = data.goodsSpecifications;

      // init custom
      this.number = inventoryNumber || 0;
      this.countNumber(this.number);
    }
  }
  countNumber(value: number) {
    this.number = value;

    this.price = Number((this.number * this.averagePrice).toFixed(2));
  }
}

export class IState {
  searchList: any[];
  formData: FormData;
  search: Search;
  tableList: TableItem[];
  shoplist: any[];
  code: string;
}

const initialArgs: IState = {
  searchList: [],
  formData: new FormData(),
  search: new Search(),
  tableList: [],
  shoplist: [],
  code: "",
};

function reducer(state: IState, { type, payload }: Dispatch): IState {
  switch (type) {
    case "InputCode":
      state.code = payload;
      console.log(payload);
      return {
        ...state,
      };
    case "LoadShop":
      return {
        ...state,
        shoplist: payload,
      };
    /** 更新 */
    case "update":
      return {
        ...state,
      };

    case "SetSearchList":
      return {
        ...state,
        searchList: payload,
      };
    case "SetFormData": {
      return {
        ...state,
        formData: {
          ...state.formData,
          ...payload,
        },
      };
    }
    case "SetForm": {
      const { shopID, shopName, remark, goodsList, inventoryType, reason } =
        payload;
      let formData = new FormData();
      formData = state.formData;

      // shop
      const shopItem = new ShopEntity();
      shopItem.id = shopID;
      shopItem.shopName = shopName;

      formData.shop = shopItem;
      formData.typeId = inventoryType;
      formData.reasonId = reason;
      formData.remark = remark;
      let tableList = (goodsList || []).map((v) => new TableItem(v));
      tableList.length > 0 && tableList.forEach((v, i) => (v.serial = i));
      return {
        ...state,
        formData,
        tableList: [...tableList],
      };
    }
    case "AddTableItem": {
      const list = state.tableList || [];
      // 去重
      if (list.every((v, i) => v.storageBatchNumber !== payload.storageBatchNumber)) {
        // payload["inventoryAvg"] = payload.averageRate || 0;

        list.push(payload);

        list.length > 0 && list.forEach((v, i) => (v.serial = i));
        return {
          ...state,
          tableList: [...list],
        };
      }
      return state;
    }
    case "DelTableItem": {
      const list = state.tableList || [];

      list.splice(payload, 1);
      list.length > 0 && list.forEach((v, i) => (v.serial = i));
      return {
        ...state,
        tableList: [...list],
      };
    }
    case "ChangeTableItem": {
      const { index, key, value } = payload;

      const list = state.tableList || [];

      const item = list[index];

      item[key] = Number(value);
      if (Number(value) < 0) {
        item[key] = 0;
      }
      item.countNumber(item[key]);

      return {
        ...state,
        tableList: [...list],
      };
    }
    case "ClearTableList":
      return {
        ...state,
        tableList: [],
      };
    default:
      return state;
  }
}

export function ReportFormStoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialArgs);

  const v = { state, dispatch };
  return (
    <ReportFormStore.Provider value={v}>
      {props.children}
    </ReportFormStore.Provider>
  );
}
