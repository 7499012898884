import { message } from "antd";
import { SteelyardLogic } from "../../../../logic/steelyard_logic";
import { FormAttribute } from "../../store/steelyard/file_store";
type Init = {
    state;
    dispatch;
    form;
}
interface InitBasic {
    dispatch;
    state;
}
interface InitEdit {
    state;
    dispatch;
    form;
}
interface ISetValue {
    attribute: FormAttribute;
    value: string | number | any[] | any;
    dispatch;
}
var id = ''
export class FormAction {

    private steelyardLgc = new SteelyardLogic();

    async init({ state, dispatch, form }: Init) {
        await this.initEdit({ dispatch, state, form })
    }
    private async initEdit({ dispatch, form }: InitEdit) {
        // 回填状态
        const item = await this.steelyardLgc.steelyardgetParam();
        if (item != null) {
            dispatch({ type: "SetForm", payload: item });
            // 填充节点
            form.setFieldsValue({
                "steelyard-type": item.modelCode,
                "steelyard-serialPort": item.serialPort,
                "steelyard-portBaud": item.portBaud,
            });
        }
    }
    setValue({ attribute, value, dispatch }: ISetValue) {
        dispatch({ type: "Change_FormData", payload: { attribute, value } });
    }
    private paddingData(state) {
        let params: any = {};
        {
            const { id, modelName, modelCode, serialPort, portBaud } = state;

            params = {
                id,
                modelName: modelName,
                modelCode: modelCode,
                serialPort: serialPort,
                portBaud: portBaud,
            }
        }
        return params;
    }
    async addSubmit({ state, dispatch, stateh, form }) {
        let param = {
            id: this.paddingData(state).id,
            modelCode: this.paddingData(state).modelCode,
            modelName: this.paddingData(state).modelName,
            serialPort: this.paddingData(state).serialPort,
            portBaud: this.paddingData(state).portBaud,
        }
        const r = await this.steelyardLgc.steelyardgetAdd(param);
        if (r.statusCode == 200) {
            message.success("设置成功");
            await this.initEdit({ dispatch, state: stateh, form })
        } else {
            message.error(r.message);
        }
    }

}
export const formAction = new FormAction();