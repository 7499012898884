import React, { useContext, useEffect } from "react";
import { Button } from "../../../../component/button";
import { Layer } from "../../../../component/layer/layer";
import { Form as FormAntd, message } from "antd";
import { formAction } from "../../action/printSet/print_set_action";
import { FormStore } from "../../store/printSet/print_set_store";
import { FormBasic } from "./form_basic";
import "../../style/index.scss";

import { FileStore } from "../../store/equip/file_store";
import { fileAction } from "../../action/equip/file_action";
import { DataTableStore } from "../../../../component/table/store/data_table_store";
import { defualtPageSize } from "../../../../logic/common_logic";
import { useBoolean } from "ahooks";
interface IForm {
  children?: any;
  close: (hasUpdate?: boolean) => void;
  editId?: string;
  seeId?: string;
  method?: string;
  item?: Object;
}

export function Form({ close, seeId, editId, method, item }: IForm) {
  const [form] = FormAntd.useForm();
  const { state, dispatch } = useContext(FormStore);
  const [loading, loadFunc] = useBoolean(false)
  const action = formAction;
  useEffect(() => {
    formAction.initForm({
      state: state,
      dispatch,
      id: editId || seeId,
      form,
      method: method,
      item,
    });
    return () => {};
  }, []);

  function title() {
    if (editId) {
      return "编辑模板";
    } else if (seeId) {
      return "查看模板";
    } else {
      return "新建模板";
    }
  }

  return (
    <FormAntd form={form}>
      <Layer layerWith={3}>
        <Layer.Title
          onClick={() => {
            dispatch({ type: "Clear" });
            console.log(state);
            close();
          }}
        >
          {title()}
        </Layer.Title>
        <Layer.Body>
          <FormBasic disabled={title() == "查看模板"} />
        </Layer.Body>
        {title() != "查看模板" && (
          <>
            <Layer.Foot>
              <Button
                type="primary"
                color="Red"
                style={{ marginRight: "1.25rem" }}
                onClick={async () => {
                  const r = await form.validateFields();
                  if(loading){
                    message.loading('正在提交数据')
                    return false
                }
                  let status = false;
                  if (editId) {
                    loadFunc.setTrue()
                    status = await action.updSubmit({
                      dispatch,
                      state,
                      id: editId,
                    });
                    loadFunc.setFalse()
                  } else {
                    console.log(state);
                    loadFunc.setTrue()
                    status = await action.addSubmit({ dispatch, state });
                    loadFunc.setFalse()
                  }
                  dispatch({ type: "Clear" });
                  status && close && close(true);
                }}
              >
                保存
              </Button>
              <Button
                type="primary"
                color="Yellow"
                onClick={() => {
                  // action.reset({ dispatch, form })
                  dispatch({ type: "Clear" });
                  close(true);
                }}
              >
                返回
              </Button>
            </Layer.Foot>
          </>
        )}
        {title() == "查看模板" && (
          <>
            <Layer.Foot></Layer.Foot>
          </>
        )}
      </Layer>
    </FormAntd>
  );
}
