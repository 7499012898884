import { message } from "antd";
import { FormAttribute } from "../../store/setting/form_store";
import { ShopLogic } from "../../../../logic/shop_logic";

type Init = {
    dispatch;
    form;
    id: string;
}
interface ISetValue {
    key: FormAttribute;
    value: string | number | any[];
    dispatch;
}
export class FormAction {
    async init({form, id, dispatch}: Init){
        this.changeData({key:'shopID', value: id, dispatch})
        this.reset({form, dispatch, })
        const res = await new ShopLogic().getShopParam(id)
      if(res.data.statusCode == 200){
        let item = res.data.data
        if(item){
            form.setFieldsValue({
                "wnN_shop_nick": item.wnN_shop_nick,
                "wnN_storage_code": item.wnN_storage_code,
                "isMemberGrade": item.isMemberGrade,
                "memberClassTakeDay": item.memberClassTakeDay
            })
            dispatch({type:'SetData', payload: item})
        }
      }else{
        message.error(res.data.returnMessage)
      }
        
        
    }
    reset({form, dispatch}){
        form.resetFields();
        dispatch({type:'Reset'})
    }
    changeData({key, value,dispatch}: ISetValue){
        let params = {}
        params[key] = value
        dispatch({type:'Change_Data', payload: params})
    }
    async submit({state}){
        const {shopID, wnN_shop_nick, wnN_storage_code, isMemberGrade, memberClassTakeDay} = state
        let params = {
             shopID,
            wnN_shop_nick,
            wnN_storage_code,
            isMemberGrade,
            memberClassTakeDay: isMemberGrade?memberClassTakeDay: null
        }
        const res = await new ShopLogic().setShopParam(params)
        console.log(res)
        if(res.data.statusCode == 200){
            message.success('设置成功')
            return true
        }else{
            message.error(res.data.returnMessage)
            return false
        }
    }
}
export const formAction = new FormAction()