import { useContext, useEffect, useState } from "react";
import { Tabs, Radio, Space, Button, message, Empty, Tag } from "antd";
import { Table } from "../../../../component/table";
import { FormStore } from "../../store/update_price_store";
import styles from "../../style/update_price.module.scss";
import { ColumnsType } from "antd/lib/table";
import { autoRem } from "../../../../global.style";
export function DataTable({ dataList }) {
  const { dispatch } = useContext(FormStore);
  const [current, setCurrent] = useState(1);
  const [pageSize, setSize] = useState(10);
  const columns: ColumnsType = [
    {
      title: "规格条码",
      dataIndex: "barCode",
      key: "2",
      align: "center",
      width: 120,
    },
    {
      title: "商品名称",
      dataIndex: "goodsName",
      key: "3",
      align: "center",
      width: 150,
    },
    {
      title: "商品来源",
      dataIndex: "goodsSource",
      key: "4",
      align: "center",
      width: 90,
      render: (v, r) => {
        return (
          <>
            {v == 1 && <Tag color="blue">自定义</Tag>}
            {v == 2 && <Tag color="red">仓库采购</Tag>}
          </>
        );
      },
    },
    {
      title: "单位",
      dataIndex: "unitName",
      key: "5",
      align: "center",
      width: 90,
    },
    {
      title: "旧门店价格",
      dataIndex: "oldShopPrice",
      key: "6",
      align: "center",
      width: 100,
    },
    {
      title: "旧商城价格",
      dataIndex: "oldStorePrice",
      key: "7",
      align: "center",
      width: 100,
    },

    {
      title: "新门店价格",
      dataIndex: "newShopPrice",
      key: "8",
      align: "center",
      width: 100,
    },
    {
      title: "新商城价格",
      dataIndex: "newStorePrice",
      key: "9",
      align: "center",
      width: 100,
    },
    // {
    //   title: "改价结果",
    //   dataIndex: "resultCode",
    //   key: "10",
    //   align: "center",
    //   width: 100,
    //   render: (v, r) => {
    //     return (
    //       <>
    //         {v == 200 ? (
    //           <Tag color="success">成功</Tag>
    //         ) : (
    //           <Tag color="error">失败</Tag>
    //         )}
    //       </>
    //     );
    //   },
    // },
    {
      title: "结果说明",
      dataIndex: "result",
      key: "11",
      align: "center",
      width: 150
    },
  ];

  return (
    <Table
      dataSource={dataList}
      columns={columns}
      scroll={{ y: 450 }}
      onChange={(pagination) => {
        setCurrent(pagination.current);
        setSize(pagination.pageSize);
      }}
    />
  );
}
export function ResultList() {
  const { state, dispatch } = useContext(FormStore);
  const { TabPane } = Tabs;
  const [currentShop, setCurrent] = useState(0);
  const [currentTable, setCurrentTable] = useState("successDetails");
  const [tableList, setList] = useState([]);
  useEffect(() => {
    if (state.resultList.length > 0)
      setList(state.resultList[currentShop].successDetails);
  }, [currentShop, state.resultList]);
  return (
    <div className={styles.root}>
      <div className={styles.table_container}>
        <div className={styles.left_tabs}>
          <Tabs
            centered
            defaultActiveKey="0"
            tabPosition={"left"}
            style={{ height: "100%" }}
            onTabClick={(key, e) => {
              //   dispatch({ type: "SelectID", payload: state.equipLlist[key].id });
              if(Number(key) != currentShop){
                setCurrent(Number(key));
                setCurrentTable("successDetails");
              }
              
            }}
          >
            {state.resultList.map((item, index) => {
              return (
                <TabPane
                  tab={
                    <span
                      style={
                        item.id == currentShop
                          ? { fontSize: 14, color: "#ffc80b" }
                          : { fontSize: 14, color: "#02ca74" }
                      }
                    >
                      {item.shopName}
                    </span>
                  }
                  key={index}
                ></TabPane>
              );
            })}
          </Tabs>
        </div>
        <div className={styles.table}>
          <div className={styles.header}>
            <div
              onClick={() => {
                setCurrentTable("successDetails");
                setList(state.resultList[currentShop].successDetails);
              }}
              className={currentTable == "successDetails" ? styles.active : ""}
            >
              成功列表
            </div>
            <div
              onClick={() => {
                setCurrentTable("errorDetails");
                setList(state.resultList[currentShop].errorDetails);
              }}
              className={currentTable == "errorDetails" ? styles.active : ""}
            >
              失败列表
            </div>
          </div>
          <div>
            <DataTable dataList={tableList} />
          </div>
        </div>
      </div>
    </div>
  );
}
