import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
} from "antd";
import styles from "../../style/distribution/file_search.module.scss";
import { fileAction } from "../../action/share/list_action";
import { ListStore } from "../../store/share/list_store";
// import axios from "axios";
import { Tools, axios } from "../../../../config";
import Cookies from "js-cookie";
import { ChooseMember } from "../../../../component/choose-member";
import { useBoolean } from "ahooks";

const { Option } = Select;
const { RangePicker } = DatePicker;

let globalPeopleList = [];

export function FileSearch({ onSearch }) {
  const { state, dispatch } = useContext(ListStore);
  const [peopleList, setPeopleList] = useState([]);
  const action = fileAction;
  const [showState, showFunc] = useBoolean(false);
  const onClear = () => {
    action.setCondition("SetMember", [], dispatch);
  };
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div>
      <Form layout="horizontal">
        <div className={styles.row}>
          {/* <div className={styles.cell}>
            <Form.Item label="选择会员">
              <Input
                placeholder="请选择"
                onClick={() => showFunc.setTrue()}
                value={
                  state.member
                    ? state.member.map((item) => item.memberName).toString()
                    : ""
                }
              />
              <span
                className="ant-input-suffix"
                style={{ marginLeft: "-24px", zIndex: 99 }}
                onClick={onClear}
              >
                <span
                  role="button"
                  aria-label="close-circle"
                  className="anticon anticon-close-circle ant-input-clear-icon"
                >
                  <svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="close-circle"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 01-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"></path>
                  </svg>
                </span>
              </span>
            </Form.Item>
          </div> */}
          <div className={styles.cell}>
            <Form.Item label="加入时间">
              <RangePicker
                style={{ width: 432 }}
                onChange={(e) => {
                  action.setCondition(
                    "beginTime",
                    e ? e[0].format("yyyy-MM-DD") : "",
                    dispatch
                  );
                  action.setCondition(
                    "endTime",
                    e ? e[1].format("yyyy-MM-DD") : "",
                    dispatch
                  );
                }}
              />
            </Form.Item>
          </div>
        </div>
        <div className={styles.row}>
          <Button type="primary" danger onClick={() => onSearch(1)}>
            搜 索
          </Button>
        </div>
      </Form>
      <ChooseMember
        visibleModal={showState}
        close={(status, r) => {
          if (status) {
            if (r) {
              console.log(r);
              action.setCondition("SetMember", r, dispatch);
            }
          }
          showFunc.setFalse();
        }}
      />
    </div>
  );
}
