export abstract class DataListAction {
    abstract delSelectList({ dispatch, selectList });
    abstract delRow({ dispatch, rowItem });

    setSelectList({ dispatch, selectKeys }) {
        dispatch({ type: "Set_SelectList", payload: selectKeys })
    }
    clearSelectedRowKeys({ dispatch }) {
        dispatch({ type: "Clear_SelectedRowKeys" });
    }
    setSelectedRowKeys({ dispatch, selectKeys }) {
        dispatch({ type: "Set_SelectedRowKeys", payload: selectKeys })
    }
    setPaging({ dispatch, pageIndex, total, dataList, pageSize }) {
        dispatch({ type: "Set_Current", payload: pageIndex })
        // dispatch({ type: "Set_Total", payload: Number(total) })
        dispatch({ type: "Set_PageSize", payload: pageSize })
        dispatch({ type: "Set_DataList", payload: dataList })
    }
}