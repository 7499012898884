import styles from "./index.module.scss";

interface IMask {
    visible: boolean;
    onClick: () => void;
}
export function Mask({ visible, onClick }: IMask) {
    return (
        <>
            {visible ? (
                <div className={styles.root} onClick={onClick}></div>
            ) : null}
        </>
    )
}