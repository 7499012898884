import styles from './../style/flashsale.module.scss'
import { Tabs, Button, Modal,Table, Input, message, Tag } from 'antd';
import { useBoolean } from 'ahooks';
import {useState,useContext, useEffect } from 'react'
import { ColumnsType } from "antd/lib/table";
import { TopicDataAction } from "../action/TopicDataAction";
import { ITopicValue, TopicStore } from "../store/topic";
import { FileSearch } from "../../marketing/view/booking/file_search";
import { DataTableStoreProvider, DataTableStore } from '../../../component/table/store/data_table_store'
import { DataTable, } from "../../../component/table/view/data_table";
import { defualtPageSize, Progress } from "../../../logic/common_logic";
import { Search } from "./../../../component/search";
import { Tips, TipsGroup } from "../../../component/tips";
import { Mask } from "../../../component/mask";
import { Tools } from '../../../config';
import img_120_120 from '../../../assets/images/mini/group_120_120.png'
import { FileStoreProvider, FileStore } from '../../marketing/store/booking/file_store'
import { fileAction } from '../../marketing/action/booking/file_action'
import { MarketingPresale_logic } from '../../../logic/market/MarketingPresale_logic'
import { GoodsLogic } from '../../../logic/goods_logic'
interface IPreSaleData{
    name:string,
    selectUrl:any
}
export function PreSale({id,con}){
    const topicStoreState = useContext(TopicStore);
    const { TabPane } = Tabs;
    // 弹窗
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [activityId, setId] = useState(0)
    const showModal = (index) => {
        setId(index)
        setIsModalVisible(true);
    };
    const handleOk = () => {
        setIsModalVisible(false);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    let actionArg: [ITopicValue, number] = [topicStoreState, id];
const data: IPreSaleData[] = TopicDataAction.getData<IPreSaleData[]>(...actionArg);
const presaleGoodsList = []
for(let i=0;i<4;i++){
    presaleGoodsList.push({
        create_time: "",
        endTime: "2021/8/31",
        item_id: 526,
        original_price: "1699.00",
        pic: img_120_120,
        presalePrice: "1099.00",
        product_id: 1799,
        shopName: "店铺名",
        beginTime: "2021/7/29",
        goodsName: "商品名称",
        unitName: '个'
    })
}
const activitylist = [];
for(let i=0;i<2;i++){
    activitylist.push({
        name:null,
        selectUrl:{
            presaleName:`预售活动`,
            beginTime:'',
            endTime: '',
            goodsList:presaleGoodsList,
            isInit:true
        }
    })
}
const del = (index) =>{
    data.splice(index,1);
    TopicDataAction.setData<IPreSaleData[]>(...actionArg, data);
}
    const add = () =>{
        data.push({
            name: null,
            selectUrl:{
                presaleName:`预售活动`,
                beginTime:'',
                endTime: '',
                goodsList:presaleGoodsList,
                isInit:true
            }
        })
        TopicDataAction.setData<IPreSaleData[]>(...actionArg, data);
    }
    useEffect(() => {
        if (!data) {
          TopicDataAction.setData<IPreSaleData[]>(...actionArg, activitylist);
        }   
      });
      if(!data){
          return<div></div>
      }
      TopicDataAction.setEF(topicStoreState, id, () => {
        return new Promise((r, e) => {
          if (data.some((item) => {
            return (item.selectUrl.isInit)
          })) {
            message.error('限时购没有填写完整');
          } else {
            r(data);
          }
        });
      });
    if(con == 'show'){
        return(
            <div className={styles.container}>
                <Tabs defaultActiveKey="0" >
                    {data&&data.length>0?data.map((tab,index)=>(
                        <TabPane tab={tab.name&&tab.name!=null?tab.name:tab.selectUrl.presaleName} key={index}>
                            <List list={tab.selectUrl.goodsList} type={tab.name}/>
                        </TabPane> 
                    )):activitylist.map((tab,index)=>(
                        <TabPane tab={tab.selectUrl.presaleName} key={index}>
                            <List list={tab.selectUrl.goodsList} type='defualt'/>
                        </TabPane> ))}                   
                                 
                </Tabs>
                <div className={styles.ctrl}>
                            
                </div>  
            </div>
        )
    }else{
        return (
        <div className={styles.ctrl}>
            <h2>预售活动设置</h2>
            <div style={{padding:'20px 0',borderTop:'1px solid #eee'}}>
                <ul>
                   {data&&data.length>0&&data.map((activity,index)=>{
                       return(
                        <li className={styles.flashsale_list} key={index}>
                            <span>活动名称：<Input style={{ width: "20rem" }} maxLength={20} value={activity.name!=null?activity.name:activity.selectUrl.presaleName} onChange={(e)=>{ data[index].name = e.target.value; TopicDataAction.setData<IPreSaleData[]>(...actionArg, data);}}/></span>
                            <span style={{color:'#eee',fontSize:12}}>最多输入20个字</span>
                            <Button type="primary" style={{width:'20%'}} onClick={()=>showModal(index)}>选择预售活动</Button>   
                            <span className={styles.activity_del} onClick={()=>del(index)}>×</span>                            
                        </li>
                       )
                   })} 
                   <Modal title={<div>选择预售活动</div>} footer={null} visible={isModalVisible} width={1000} onOk={handleOk} onCancel={handleCancel} >
                       <FileStoreProvider><DataTableStoreProvider> 
                            <ShowActivity close={() => handleCancel()} list={data} activityId={activityId} id={id}/>
                            </DataTableStoreProvider>
                        </FileStoreProvider> 
                    </Modal>
                    <div className={styles.ctrl_list_add} onClick={add} >
                        +
                    </div>                    
                </ul>
            </div>
        </div>  )
    }
}
const List = ({list,type}) => {
    return(
        <ul className={styles.flashsale_good_list}>
           {list&&list.length>0&&list.map((item,index)=>(
                <li key={index}>
                <span>
                    <img src={item.goodsImage&&item.goodsImage!=''?Tools.getImageAddress(item.goodsImage):item.pic} alt={item.goodsName}/>
                </span>
                <div className={styles.price_and_title}>
                    <p style={{color:'#000',fontSize:13,}}>{item.goodsName}</p>
                    <span>￥{item.presalePrice} / {item.unitName}</span>
                </div>
            </li>         
           ))}   
        </ul>
    )
}
const ShowActivity = ({close, list, activityId, id}) => {
    const store = useContext(TopicStore)
    const actionArg: [ITopicValue, number]  = [store, id]
    const columns: ColumnsType = [
        {
            title: '活动名称',
            dataIndex: 'activityName',
            align: "center",
            width: 140
        },
        {
            title: '添加人',
            dataIndex: 'addName',
            align: "center",
        },
        {
            title: '参与店铺',
            dataIndex: 'shopName',
            align: "center",
        },      
        {
            title: '状态',
            dataIndex: 'state',
            align: "center",
            render: (v) => {
                switch (v) {
                    case 1:
                        return <Tag color='success'>正常</Tag>;
                    case 2:
                        return <Tag color='error'>终止</Tag>;
                }
            },
        },
        {
            title: '开始日期',
            dataIndex: 'beginDate',
            align: "center",
            render: (v) => <span>{v?v.substring(0,10):''}</span>
        },
        {
            title: '结束日期',
            dataIndex: 'endDate',
            align: "center",
            render: (v) => <span>{v?v.substring(0,10):''}</span>
        },
        {
            title: '到货日期',
            dataIndex: 'arrivalDate',
            align: "center",
            render: (v) => <span>{v?v.substring(0,10):''}</span>
        },
        {
            title: '添加时间',
            dataIndex: 'addTime',
            align: "center",
        },
    ]
    const { total, pageSize, current, dataList, selectedRowKeys } = store.state as any;
    const [searchState, searchFunc] = useBoolean(false);
    const action = fileAction
    const {state, dispatch} = useContext(FileStore)
    const { state: tableState, dispatch: tableDispatch } = useContext(DataTableStore);
    const onSearch = async (pageIndex) => {
        await action.loadPage({ pageIndex: pageIndex, pageSize: defualtPageSize(), dispatch: tableDispatch, search: state.search }) 
     }
     const selected = async() => {
        const r = await MarketingPresale_logic.GetFirstMarkPresasle({ID: tableState.selectList[0].id})
        let item;
        if(r&&r.data&&r.data.statusCode == 200){
            item = r.data.data
        }else{
            message.error('获取信息失败')
        }
        let list = []
        list = item?item.presalegoods: []
     let datalist =  []
     if( list.length>0){
         for(let l of list){
          const good =  await new GoodsLogic().selData(l.goodsID,'','WX')
            if(good.goodsSpecifications&&good.goodsSpecifications.length>0){
                const sep = good.goodsSpecifications.filter(gs=>gs.id == l.sepID)[0]
                datalist.push({
                    ...l,
                    ...sep,
                    goodsName: good.goodsName,
                    presalePrice: l.presaleMoney,
                    goodsImage:sep&&sep.specificationImage?sep.specificationImage: good.goodsImage
                })
            }
          
        }}
    const data: IPreSaleData[] = TopicDataAction.getData<IPreSaleData[]>(...actionArg);
    console.log(datalist)
    data[activityId].selectUrl = tableState.selectList[0]
    data[activityId].selectUrl['presaleName'] = tableState.selectList[0].activityName
    data[activityId].selectUrl.goodsList= datalist
    TopicDataAction.setData<IPreSaleData[]>(...actionArg,data)
}
    return  <div style={{marginTop: -20}}>
                <div className={styles.header_search}>
            <Search>
                <Input allowClear placeholder="请输入活动名称" onChange={e => action.setCondition({ dispatch, key: "name", value: e.target.value })} />
                <input type="button" value="快速查找" onClick={() => onSearch(1)} />

                <TipsGroup>
                    <input type="button" value="高级查找" onClick={() => searchFunc.toggle()} />
                    <Tips display={searchState} width="48rem">
                        <FileSearch onSearch={()=> onSearch(1)} />
                    </Tips>
                    <Mask visible={searchState} onClick={() => searchFunc.setFalse()} />
                </TipsGroup>
            </Search>
            </div>       

            <DataTable columns={columns} action={action}
                        // rowKey={record => record.id}
                        onLoad={async () => { await onSearch(1); Progress.done() }}
                        onChange={(pageIndex) => { onSearch(pageIndex) }}
                        scroll={{ scrollToFirstRowOnChange: true, autoY: true }}
                        rowSelection={{type:'radio',getCheckboxProps:(record)=>({disabled:record.status ==2})}}
                        selectColumn={true}
                    />
                      {tableState.dataList.length !=0?<div>
                <Button style={{background:'#f7ba00',borderColor:'#f7ba00',marginRight:10}} type="primary" onClick={()=>{close();}}>取消</Button>
                <Button type="primary" onClick={()=>{selected();close();}}>确定</Button>
            </div>:null}
    </div>
}