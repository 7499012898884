import { Responsive } from "react-grid-layout";
import { Control } from "./control";
import { autoRem } from "../../../../global.style";
import styles from "../../style/print_set.module.scss";
import GridLayout from "react-grid-layout";
import RGL, { WidthProvider } from "react-grid-layout";
import html2canvas from "html2canvas";
import { useContext, useRef } from "react";
import "react-resizable/css/styles.css";
import "react-grid-layout/css/styles.css";
import { FormStore } from "../../store/printSet/print_set_store";
import { getGSCloudPlugin } from "../../../../assets/js/GSCloudPluginFuncs";
import { formAction } from "../../action/printSet/print_set_action";
import { Button } from "antd";
let GSCloudPlugin = getGSCloudPlugin();
export function PrintSet() {
  const action = formAction;
  const { state, dispatch } = useContext(FormStore);
  const canvasRef = useRef();
  const { baseUnitHeight } = state;
  console.log(state);
  // 光速云打印 -- start
  // 打印
  const printFunction = ({ imgurl }, dispatch) => {
    GSCloudPlugin.SetAppKey(
      "BC85333E6E2345A9AC2C39635C722682",
      "四川智如客科技有限公司"
    );
    GSCloudPlugin.GetPrintQueue({
      OnSuccess: function (result) {
        console.log(result);
      },
      OnError: function (result) {
        console.log(result);
      },
    });
    dispatch({
      type: "isloading",
      payload: { isLoading: true, msg: "打印中..." },
    });
    var url = document.location.toString().split("/");

    // setTimeout(() => {
      GSCloudPlugin.PrintHtml({
        Title: "HTML0001",
        Width: 220,
        Height: 100,
        Url: url[0] + "//" + url[1] + url[2] + "/printGrid.html",
        PrinterName: "测试", // app.getPrinterName()方法仅用于在线演示
        OnSuccess: function (result) {
          dispatch({
            type: "isloading",
            payload: { isLoading: false, msg: "打印中..." },
          });
        },
        OnError: function (result) {
          dispatch({
            type: "isloading",
            payload: { isLoading: false, msg: "打印中..." },
          });
        },
      });
    // }, 2000);
  };
  // 光速云打印 -- end

  return (
    <>
      <Button
        type="primary"
        danger
        ghost
        style={{ marginBottom: "25px", display: "none" }}
        onClick={
          // exportImage

          () => {
            const { pageSize, baseUnit, baseUnitHeight, formData } = state;
            let ticketsJson = JSON.stringify({
              pageSize,
              baseUnit,
              baseUnitHeight,
              dataList: formData.dataList,
            });
            localStorage.setItem("printTemplate", ticketsJson);
            var url = document.location.toString().split("/");
            // let ids = JSON.stringify(["04a2088c-3f25-40a2-8bcf-77a9297588f1"]);

            window.open(url[0] + "//" + url[1] + url[2] + "/printGrid.html");
            window.a = "11";
            printFunction({ imgurl: "" }, dispatch);
          }
        }
      >
        打印测试
      </Button>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          ref={canvasRef}
          className={styles.content}
          style={{ width: state.pageSize * 3.45 }}
        >
          {state.formData.dataList && (
            <GridLayout
              className="layout"
              style={{ border: "solid 1px" }}
              cols={10}
              rowHeight={baseUnitHeight ?? 20}
              width={state.pageSize * 3.45}
              margin={[0, 0]}
              onDragStop={(layout, newItem) => {
                action.changeFormData({
                  dispatch,
                  data: layout,
                });
              }}
              onResizeStop={(
                layout,
                oldItem,
                newItem,
                placeholder,
                e,
                element
              ) => {
                // console.log(newItem);
                action.changeFormData({
                  dispatch,
                  data: layout,
                });
              }}
            >
              {state.formData.dataList.length > 0 &&
                state.formData.dataList.map((item) => {
                  return (
                    <div
                      data-grid={item}
                      key={item.i}
                      className={styles.com_container}
                      style={{
                        borderTop:
                          item.isBorder.indexOf("top") != -1
                            ? "solid 1px"
                            : "none",
                        borderRight:
                          item.isBorder.indexOf("right") != -1
                            ? "solid 1px"
                            : "none",
                        borderBottom:
                          item.isBorder.indexOf("bottom") != -1
                            ? "solid 1px"
                            : "none",
                        borderLeft:
                          item.isBorder.indexOf("left") != -1
                            ? "solid 1px"
                            : "none",
                        display: "flex",
                        whiteSpace: item.isEllipsis ? "nowrap" : "normal",
                        overflow: item.isEllipsis ? "hidden" : "auto",
                        textOverflow: item.isEllipsis ? "ellipsis" : "initial",
                      }}
                    >
                      {item.title != undefined && (
                        <div
                          style={{
                            fontSize: item.titleFontSize,
                            fontWeight: item.titleFontWeight,
                            height: "100%",
                            padding: "5px",
                            display: "flex",
                            justifyContent: item.textAlign,
                            alignItems: item.verticalAlign,
                          }}
                        >
                          {item.title}
                        </div>
                      )}
                      <div
                        style={{
                          fontSize: item.valueFontSize,
                          fontWeight: item.valueFontWeight,
                          flex: "1",
                          height: "100%",
                          padding: "5px",
                          display: "flex",
                          justifyContent: item.textAlign,
                          alignItems: item.verticalAlign,
                        }}
                      >
                        {item.value}
                      </div>
                    </div>
                  );
                })}
              {/* <div key="b" data-grid={{ i: "b", x: 0, y: 0, w: 2, h: 2 }}>
                b
              </div>
              <div key="c">c</div> */}
            </GridLayout>
          )}
        </div>
        <Control />
      </div>
    </>
  );
}
