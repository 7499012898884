import { createContext, useContext, useReducer } from "react";

export const ChooseSeckillStore = createContext<IContextProps>(null);

interface IContextProps {
    state: IState;
    dispatch: ({ type }: Dispatch) => void;
}

type Dispatch = { type, payload }

class Search {
    seacherKey: string;
};

interface IState {
    searchList: any[];
    search: Search;
    goodsItem: any;
    tableList: any[]
}

const initialArgs: IState = {
    searchList: [],
    search: new Search(),
    goodsItem: null,
    tableList:[]
}

function reducer(state: IState, { type, payload }: Dispatch) {
    switch (type) {
    case 'GoodsItem':
        return{
            ...state,
            goodsItems: payload
        }
    case 'GoodsList':
        return{
            ...state,
            tableList:[
                ...payload
            ]
        }
        case "SetSearch":
            return {
                ...state,
                search: {
                    ...state.search,
                    ...payload
                }
            }
        case "SetSearchList":
            return {
                ...state,
                searchList: payload
            }
        default:
            return state;
    }
}
export function ChooseStoreProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialArgs);

    const v = { state, dispatch };
    return <ChooseSeckillStore.Provider value={v}>{props.children}</ChooseSeckillStore.Provider>
}