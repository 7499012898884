import {
  Button,
  Col,
  Form,
  Radio,
  Row,
  message,
  Checkbox,
  DatePicker,
  Modal
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Input } from "../../../component/input";
import { RadioStyle } from "../../../component/radio-style";
import { Select } from "../../../component/select";
import { TextArea } from "../../../component/text-area";
import styles from "../style/form_basic.module.scss";
import { FormSubtitle } from "./form_subtitle";
import { Select as SelectAntd } from "antd";
import { FormStore } from "../store/form_store";
import { Cascader } from "../../../component/cascader";
import { formAction, FormAction } from "../action/form_action";
import { autoRem } from "../../../global.style";
import { FunctionTool } from "../../../tool/functionTool";
import { GoodsLogic } from "../../../logic/goods_logic";

import { FormDetails } from "./form_details";
import { FormFormat } from "./form_format";
import { FormSupplement } from "./form_supplement";
import moment from "moment";
import { ConfirmModal } from "../../../component/modal";
import { useBoolean } from "ahooks";
import * as _ from 'lodash'

function Type({ value, disabled, title }) {
  const { state, dispatch } = useContext(FormStore);

  const onChange = (e) => {
    // setValue(e.target.value);
    if (!disabled && title != "编辑商品") {
      formAction.setValue({
        dispatch,
        value: e.target.value,
        attribute: "Type",
      });
    }
  };
  useEffect(() => {
    formAction.setValue({ dispatch, value: value, attribute: "Type" });
  }, []);

  return (
    <RadioStyle>
      <Radio.Group
        className="custom-radio"
        onChange={onChange}
        value={value || 1}
      >
        <Radio value={1}>标准商品</Radio>
        <Radio value={2}>称重商品</Radio>
      </Radio.Group>
    </RadioStyle>
  );
}

/** 设置商品简码 */
const setGoodsSimpleCode = FunctionTool.throttle(
  (name: string, action, dispatch, setIfGetGSC, form) => {
    if (!name) {
      action.setValue({ dispatch, value: "", attribute: "SimpleCode" });
      return;
    }
    setIfGetGSC(true);
    //发送请求
    new GoodsLogic()
      .ConvertToSimp({
        source: name,
      })
      .then(({ data }) => {
        if (data.statusCode != 200) {
          message.error(data.returnMessage);
        } else {
          action.setValue({
            dispatch,
            value: data.data,
            attribute: "SimpleCode",
          });
          form.setFieldsValue({ "goods-shortcode": data.data });
        }
      })
      .finally(() => {
        setIfGetGSC(false);
      });
  },
  500
);

interface IFormBasic {
  action: FormAction;
  disabled: boolean;
  form;
  title;
}
export function FormBasic({ action, disabled, form, title }: IFormBasic) {
  const { state, dispatch } = useContext(FormStore);
  const { formData, classifyList } = state;
  const [ifGetGSC, setIfGetGSC] = useState(false);

  const [confirmState, confirmFunc] = useBoolean(false)
  const disabledDate = (current) => {
    return current > moment().startOf("second");
  };
  const byStringGetMoment = (string) => {
    if (!string) {
      return undefined;
    }
    return moment(string);
  };
  const handleChangeUnit = (v) => {
    let list = formData.formatList || []
    // list.map(item=>{
    //   console.log(item,item.unitId, v)
    // })
    if(list.filter(item=> item.unitId == v && !item.isDefault).length > 0 ){
      Modal.confirm({
        width: 420,
        title: '提示',
        content: '切换商品单位后会删除相同单位的规格，是否继续？',
        okText: '确认',
        cancelText: '取消',
        onCancel:(close)=>{
          let unitId = list.filter(item=>item.isDefault)[0].unitId
          unitId &&  action.setValue({ dispatch, value: unitId, attribute: "UnitId", })
          form.setFieldsValue({
            'goods-unit': unitId,
        });
            close()
        },
        onOk:(close)=>{          
           list.map((item, i)=>{
              if(item.unitId == v && !item.isDefault){
                  action.delFormatRow({ dispatch, rowIndex: i })
              }
          })
          action.setValue({ dispatch, value: v, attribute: "UnitId", formatList: list })
          close()
        }
      }); 
    }else{
      action.setValue({ dispatch, value: v, attribute: "UnitId", formatList: list })
    }
  }
  return (
    <div className={styles.root}>
      <FormSubtitle>基础信息</FormSubtitle>

      <div style={{ marginTop: "2.5625rem", marginLeft: "13px" }}>
        <Type disabled={disabled} value={formData.type} title={title} />
      </div>

      <div style={{ margin: "1.9375rem 0 0 0" }}>
        {title == "新增商品" && (
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                labelCol={{ span: 8 }}
                name="quickcode"
                label="快速录入"
              >
                <Input
                  autoFocus
                  allowClear
                  autoComplete="off"
                  disabled={disabled}
                  placeholder="扫描商品条形码，快速录入"
                  onPressEnter={(e) => {
                    if (!formData.goodsCode || title == "编辑商品") {
                      return false;
                    }
                    action.getGoodsInfo({ dispatch, form, state });
                  }}
                  onChange={(v) => {
                    action.setValue({
                      dispatch,
                      value: v.target.value,
                      attribute: "GoodsCode",
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="goods-classify"
              label="商品分类"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: true,
                  message: "请选择商品分类",
                },
              ]}
            >
              <Cascader
                allowClear
                // autoComplete="off"
                disabled={disabled}
                options={state.classifyList}
                placeholder="请选择商品分类"
                // changeOnSelect={true}
                onChange={(v) => {
                  let id;
                  if (v.length == 1) {
                    id = v[0];
                  } else {
                    id = v[1];
                  }
                  action.setValue({
                    dispatch,
                    value: id,
                    attribute: "ClassesId",
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="goods-name"
              label="商品名称"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: true,
                  message: "请输入商品名称",
                },
              ]}
            >
              <Input
                allowClear
                maxLength={20}
                autoComplete="off"
                disabled={disabled}
                placeholder="请输入商品名称"
                onChange={(v) => {
                  action.setValue({
                    dispatch,
                    value: v.target.value,
                    attribute: "GoodsName",
                  });
                  //设置商品简码
                  setGoodsSimpleCode(
                    v.target.value,
                    action,
                    dispatch,
                    setIfGetGSC,
                    form
                  );
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="goods-unit"
              label="商品单位"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: true,
                  message: "请选择商品单位",
                },
              ]}
            >
              <Select
                allowClear
                disabled={disabled}
                onChange={handleChangeUnit}
              >
                {state.unitList.map((v) => (
                  <SelectAntd.Option key={v.id} value={v.id}>
                    {v.unitName}
                  </SelectAntd.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="goods-code"
              label="商品编码"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: true,
                  message: "请输入商品编码",
                },
                {
                  pattern: /^[0-9]*$/,
                  message: "商品编码只能是数字",
                },
              ]}
            >
              <Input
                maxLength={50}
                allowClear
                autoComplete="off"
                disabled={disabled}
                placeholder="请输入商品编码"
                onChange={(v) =>
                 { action.setValue({
                    dispatch,
                    value: v.target.value,
                    attribute: "GoodsCode",
                  })
                  let index = _.findIndex(formData.formatList,function(o){return o.isDefault})
                  if(index !=-1)
                    action.setFormatValue({dispatch,value: v.target.value,attribute:'Barcode',rowIndex: index})}
                }
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="商品简码"
              name="goods-shortcode"
              labelCol={{ span: 8 }}
            >
              <Input
                maxLength={20}
                disabled={disabled}
                value={formData.simpleCode}
                placeholder="请输入商品简码"
                onChange={(v) => {
                  action.setValue({
                    dispatch,
                    value: v.target.value,
                    attribute: "SimpleCode",
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name={`goods-brand`}
              label="商品品牌"
              labelCol={{ span: 8 }}
            >
              <Select
                allowClear
                disabled={disabled}
                onChange={(v) =>
                  action.setValue({ dispatch, value: v, attribute: "BrandId" })
                }
              >
                {state.brandList.map((v) => (
                  <SelectAntd.Option key={v.id} value={v.id}>
                    {v.brandName}
                  </SelectAntd.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {title == "新增商品" && (
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="goods-inventoryNumber"
                label="库存数量"
                labelCol={{ span: 8 }}
                rules={[
                  {
                    validator: (_, __, callBack: (error?: string) => void) => {
                      if (state.formData.goodsPrice) {
                        if (
                          state.formData.goodsNumber == undefined ||
                          state.formData.goodsNumber == null ||
                          state.formData.goodsNumber == 0
                        ) {
                          callBack("请填写库存数量");
                          return;
                        } else if (isNaN(Number(state.formData.goodsNumber))) {
                          callBack("库存数量应该为数字");
                          return;
                        } else if (state.formData.goodsNumber < 0.001) {
                          callBack("库存数量应该大于0.001");
                          return;
                        }
                      }
                      callBack();
                    },
                  },
                ]}
              >
                <Input
                  // autoFocus
                  maxLength={20}
                  allowClear
                  autoComplete="off"
                  disabled={title == "编辑商品" || disabled}
                  placeholder="请输入商品库存数量"
                  type="number"
                  min={0.001}
                  onChange={(v) => {
                    action.setValue({
                      dispatch,
                      value: v.target.value,
                      attribute: "GoodsNumber",
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="库存价格"
                name="goods-inventoryPrice"
                labelCol={{ span: 8 }}
                rules={[
                  {
                    validator: (_, __, callBack: (error?: string) => void) => {
                      if (state.formData.goodsNumber) {
                        if (
                          state.formData.goodsPrice == undefined ||
                          state.formData.goodsPrice === null
                        ) {
                          callBack("请填写库存价格");
                          return;
                        } else if (isNaN(Number(state.formData.goodsPrice))) {
                          callBack("库存价格应该为数字");
                          return;
                        } else if (state.formData.goodsPrice < 0.01) {
                          callBack("库存价格应该大于0.01");
                          return;
                        }
                      }
                      callBack();
                    },
                  },
                ]}
              >
                <Input
                  maxLength={20}
                  disabled={title == "编辑商品" || disabled}
                  value={formData.goodsPrice}
                  placeholder="请输入商品库存价格"
                  type="number"
                  min={0.01}
                  onChange={(v) => {
                    let number = Number(v.target.value);

                    action.setValue({
                      dispatch,
                      value: v.target.value,
                      attribute: "GoodsPrice",
                    });

                    // action.setValue({
                    //   dispatch,
                    //   value: v.target.value,
                    //   attribute: "GoodsPrice",
                    // });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        {title == "新增商品" && (
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="goods-manufactureDate"
                label="生产日期"
                labelCol={{ span: 8 }}
                rules={[
                  {
                    validator: (_, __, callBack: (error?: string) => void) => {
                      if (state.formData.shelfLifeDay) {
                        if (
                          state.formData.manufactureDate == undefined ||
                          state.formData.manufactureDate == null ||
                          state.formData.manufactureDate == ""
                        ) {
                          callBack("请选择生产日期");
                          return;
                        }
                      }
                      callBack();
                    },
                  },
                ]}
              >
                <DatePicker
                  autoComplete="off"
                  style={{ width: "90%" }}
                  disabled={disabled}
                  disabledDate={disabledDate}
                  value={byStringGetMoment(formData.manufactureDate)}
                  placeholder="请选择生产日期"
                  onChange={(date, dateString) => {
                    action.setValue({
                      dispatch,
                      value: dateString,
                      attribute: "ManufactureDate",
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="保质期天数"
                name="goods-shelfLifeDay"
                labelCol={{ span: 8 }}
                rules={[
                  {
                    validator: (_, __, callBack: (error?: string) => void) => {
                      if (state.formData.manufactureDate) {
                        if (
                          state.formData.shelfLifeDay == undefined ||
                          state.formData.shelfLifeDay === null
                        ) {
                          callBack("请填写保质期天数");
                          return;
                        } else if (
                          !/^\+?[1-9][0-9]*$/.test(
                            state.formData.shelfLifeDay + ""
                          )
                        ) {
                          callBack("商品保质期应该是大于0的整数");
                          return;
                        }
                      }
                      callBack();
                    },
                  },
                ]}
              >
                <Input
                  maxLength={20}
                  disabled={title == "编辑商品" || disabled}
                  value={formData.goodsPrice}
                  placeholder="请输入商品保质期"
                  type="number"
                  min={1}
                  onChange={(v) => {
                    let number = Number(v.target.value);
                    if (isNaN(number)) {
                      // message.warning('入库数量应该为数字')
                    } else if (number < 1) {
                      // message.warning('入库数量应该大于0.001')
                    } else {
                      action.setValue({
                        dispatch,
                        value: v.target.value,
                        attribute: "ShelfLifeDay",
                      });
                    }

                    // action.setValue({
                    //   dispatch,
                    //   value: v.target.value,
                    //   attribute: "GoodsPrice",
                    // });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row gutter={16}>
          <Col span={16}>
            <Form.Item
              label="不参与优惠"
              labelCol={{ span: 4 }}
              name={`goods-isNoMarketing`}
            >
              <Checkbox
                value={formData.isNoMarketing}
                disabled={disabled}
                checked={formData.isNoMarketing}
                onChange={(v) =>
                  action.setValue({
                    dispatch,
                    value: v.target.checked,
                    attribute: "isNoMarketing",
                  })
                }
              >
                （勾选后商品将不享受任何促销价格，会员价及优惠券活动）
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={16}>
            <Form.Item
              label="手动折扣"
              labelCol={{ span: 4 }}
              name={`goods-isManualDiscounts`}
            >
              <Checkbox
                value={formData.isManualDiscounts}
                disabled={disabled}
                checked={formData.isManualDiscounts}
                onChange={(v) =>
                  action.setValue({
                    dispatch,
                    value: v.target.checked,
                    attribute: "isManualDiscounts",
                  })
                }
              >
                （勾选后商品在POS端销售时，允许使用"单品优惠" "赠送"功能）
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={16}>
            <Form.Item
              name="goods-remarks"
              label="备 注"
              labelCol={{ span: 4 }}
              className={styles.label_area}
            >
              <TextArea
                style={{ width: "65%" }}
                maxLength={200}
                allowClear
                autoComplete="off"
                disabled={disabled}
                cols={10}
                rows={6}
                autoSize={{ minRows: 6, maxRows: 9 }}
                onChange={(v) =>
                  action.setValue({
                    dispatch,
                    value: v.target.value,
                    attribute: "Remark",
                  })
                }
              ></TextArea>
            </Form.Item>
          </Col>
        </Row>
      </div>
      <div>
        <FormFormat disabled={title == "查看商品"} />
      </div>
      <div>
        <FormDetails disabled={title == "查看商品"} />
      </div>
      <div>
        <FormSupplement
          action={formAction}
          form={form}
          disabled={title == "查看商品"}
        />
      </div>
      <ConfirmModal
         visible={confirmState}
          title="温馨提示"
          subTitle="确认改变商品单位？"
          message="切换商品单位后会删除规格单位和商品单位相同的规格，是否继续？"
          close={async (r) => {
            if (r) {
              confirmFunc.setFalse();
            }else{
              confirmFunc.setFalse();
            }           
          }}/>
    </div>
  );
}
