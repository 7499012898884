import { CascaderProps } from "antd/lib/cascader/index";
import React, { Children, cloneElement } from "react";
import { Cascader as CascaderAntd } from "antd";
import styles from "./index.module.scss";

interface ITextAreaStyle {
    children?: any;
}

export function Cascader(params: ITextAreaStyle & CascaderProps ) {
    const { children } = params;

    const rootStyle = (params.className) ? params.className : "";

    return <CascaderAntd {...params} {...{ className: rootStyle + " " + styles.root }} />
}