import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
} from "antd";
import styles from "../style/file_search.module.scss";
import { fileAction } from "../action/file_action";
import { FileStore } from "../store/file_store";
// import axios from "axios";
import { Tools, axios } from "../../../config";
import Cookies from "js-cookie";
import { ChooseShop } from "../../../component/choose-shop";
import { useBoolean } from "ahooks";

const { Option } = Select;
const { RangePicker } = DatePicker;

export async function fetchPeopleList() {
  // 当前登录人店铺人员
  const r = await axios({
    method: "GET",
    url: Tools.getApiAddress("shop_user_list"),
    params: {},
    headers: {
      SYSTOKEN: Cookies.get("token"),
      ClientSource: "web",
    },
  });

  if (r.data.statusCode == 200) {
    return r.data.data;
  }
}

let globalPeopleList = [];

export function FileSearch({ onSearch }) {
  const { state, dispatch } = useContext(FileStore);
  const [shopState, shopFunc] = useBoolean(false);
  const [peopleList, setPeopleList] = useState([]);
  const action = fileAction;

  useEffect(() => {
    fetchPeopleList().then((l) => {
      globalPeopleList = l;
      setPeopleList(l);
    });

    return () => {};
  }, []);

  return (
    <div>
      <Form layout="horizontal">
        <div className={styles.row}>
          <div className={styles.cell}>
            <Form.Item label="订单单号">
              <Input
                style={{ width: 175 }}
                placeholder="请输入订单单号"
                onChange={(v) =>
                  action.setCondition("OrderNo", v.target.value, dispatch)
                }
              />
            </Form.Item>
          </div>
          <div className={styles.cell}>
            <Form.Item label="订单状态">
              <Select
                style={{ width: 175 }}
                onChange={(v) => action.setCondition("Status", v, dispatch)}
                defaultValue={-1}
                showSearch
                filterOption={(input: any, option: any) => {
                  return option.children.indexOf(input) >= 0;
                }}
              >
                <Option value={-1}>全部</Option>
                <Option value={1}>待付款</Option>
                <Option value={2}>待收货</Option>
                <Option value={3}>待发货</Option>
                <Option value={4}>待提货</Option>
                <Option value={5}>已完成</Option>
                <Option value={6}>退单</Option>
                <Option value={7}>已作废</Option>
              </Select>
            </Form.Item>
          </div>
          <div className={styles.cell}>
            <Form.Item label="订单来源">
              <Select
                style={{ width: 175 }}
                onChange={(v) => action.setCondition("Type", v, dispatch)}
                defaultValue={-1}
                showSearch
                filterOption={(input: any, option: any) => {
                  return option.children.indexOf(input) >= 0;
                }}
              >
                <Option value={-1}>全部</Option>
                <Option value={1}>门店订单</Option>
                <Option value={2}>商城订单</Option>
                <Option value={3}>自助收银订单</Option>
              </Select>
            </Form.Item>
          </div>
          <div className={styles.cell}>
            <Form.Item label="操作用户">
              <Select
                showSearch
                style={{ width: 175 }}
                onChange={(v) => action.setCondition("People", v, dispatch)}
                defaultValue={-1}
                filterOption={(input: any, option: any) => {
                  return option.children.indexOf(input) >= 0;
                }}
              >
                <Option value={-1}>全部</Option>
                {peopleList.map((v) => (
                  <Option value={v.userID}>{v.userName}</Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          {/* <div className={styles.cell}>
                        <Form.Item label="选择门店">
                            <Input style={{ width: "15rem" }} value={''} placeholder="请选择" onClick={() => shopFunc.setTrue()} />
                            <span className="ant-input-suffix" style={{ marginLeft: '-24px', zIndex: 99 }} onClick={()=>{}}>
                                <span role="button" aria-label="close-circle" className="anticon anticon-close-circle ant-input-clear-icon"><svg viewBox="64 64 896 896" focusable="false" data-icon="close-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 01-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"></path></svg></span>
                            </span>
                        </Form.Item>
                    </div> */}
          <div className={styles.cell}>
            <Form.Item label="制单时间">
              <RangePicker
                allowClear
                style={{ width: 432 }}
                onChange={(e) => {
                  console.log(e);
                  if (e == null) {
                    action.setCondition("DateStart", "", dispatch);
                    action.setCondition("DateEnd", "", dispatch);
                  } else {
                    action.setCondition(
                      "DateStart",
                      e[0].format("yyyy-MM-DD"),
                      dispatch
                    );
                    action.setCondition(
                      "DateEnd",
                      e[1].format("yyyy-MM-DD"),
                      dispatch
                    );
                  }
                }}
              />
            </Form.Item>
          </div>
        </div>
        <div className={styles.row}>
          <Button type="primary" danger onClick={() => onSearch(1)}>
            搜 索
          </Button>
        </div>
      </Form>
      <ChooseShop
        visibleModal={shopState}
        close={(status, r) => {
          if (r) {
            // action.setCondition({
            //   dispatch,
            //   key: "shopName",
            //   value: r[0].shopName,
            // });
            // action.setCondition({ dispatch, key: "shop", value: r });
          }
          shopFunc.setFalse();
        }}
      />
    </div>
  );
}
