import { message } from 'antd';
import { MarketingPointsExchangeOffer_logic} from '../../../../../logic/market/MarketingPointsExchangeOffer_logic'
import { GradeLogic } from '../../../../../logic/grade_logic'
import { ShopLogic} from '../../../../../logic/shop_logic'
import Item from 'antd/lib/list/Item';
type Init = {
    state;
    dispatch;
    form;
    id: string;
    method: string;
}
interface InitBasic {
    dispatch;
    search;
}
interface InitEdit {
    state;
    dispatch;
    form;
    id;
}

class FormAction {
    //
    async init({ state, dispatch, id, method, form }: Init) {
        //
        dispatch({ type: "LoadTag", payload: await new GradeLogic().gradeList() });
        await this.initBasic({ dispatch, search: state.search });
        //
        if ("Create" !== method && id) {
            await this.initEdit({ dispatch, state, id, form })
        }
    }
    private async initBasic({ search, dispatch }: InitBasic) {
        //
        this.setFormData({dispatch, key:'shopList',value: []})
        this.setFormData({dispatch, key:'shopName',value: ''})
    }
    private async initEdit({ dispatch, id, form }: InitEdit) {
        //回填表单
        let item;
        //获取信息
        let res = await MarketingPointsExchangeOffer_logic.GetFirstModel({},{ID: id})          
        if(res&&res.data&&res.data.statusCode == 200){
            item = res.data.data
        }   
        else{
            message.error('获取信息失败')
        }

        //获取店铺信息
        // let shoplist = await (await new ShopLogic().selListpost()).data.data.dataSource;
        let shopIDarr = []
        let shopName = ''
        // item.markShopID.length&& item.shopID.map(shop=>{
        //     shoplist.map(s=>{if(s.id == shop.shopID){
        //         shopIDarr.push(shop.shopID)
        //         shopName = s.shopName
        //     }})
        // })
        if(item.markShopID && item.markShopID.length > 0 ){
            shopIDarr = item.markShopID.map(m=>m.shopID)
            shopName = item.markShopID.map(m=>m.shopName)
        }
        let gradearr = []
        item.memberID&&item.memberID.length&&item.memberID.map(g=>{
            gradearr.push(g.memberClassID)
        })
        item['shopName'] = shopName
        item['shopID'] = shopIDarr
        item['memberClasss'] = gradearr      
        item['isSetCount'] = item.exchangeCount?2:1
        item['isLimit'] = item.isMemberClass
        item['isMemberClass'] = item.isMemberClass == true?2:1

        dispatch({ type: "SetForm", payload: item });

        form.setFieldsValue({
            "exchangeCount": item.exchangeCount==null?0:item.exchangeCount,
            "id": item.id,
            "isMemberClass": item.isMemberClass,
            // "memberClasss": item.memberClasss,
            "points": item.points,
            "shopID": item.shopID,
            "takeBeginDate": item.takeBeginDate,
            "takeEndDate": item.takeEndDate,
            "takeType": item.takeType,          

        });

        
    }
    async addSubmit({ state, status }) {
        return false;
    }

    async updSubmit({ state, status }, id) {
        const {formData} = state
        
      let params =  {
          id: id,
        isMemberClass: formData.isMemberClass == 1?false:true,
        exchangeCount: formData.exchangeCount?formData.exchangeCount:null,
        takeType: formData.takeType?formData.takeType:1,
        takeBeginDate:  formData.takeBeginDate?formData.takeBeginDate:'',
        takeEndDate:  formData.takeEndDate?formData.takeEndDate:'',
        points: formData.points,
        memberClasss: formData.isMemberClass == 1?[]:formData.memberClasss,
        shopID: formData.shopID
        }
        const r = await MarketingPointsExchangeOffer_logic.SetExchangeRules(params,{})
        if(r&&r.data&&r.data.statusCode == 200){
            message.success('设置成功')
            return true
        }else{
            message.error(r.data&&r.data.returnMessage || r.data.message || '设置失败')
            return false;
        }
    }

    clearTableList({ dispatch }) {
        dispatch({ type: "ClearTableList" });
    }

    setFormData({ dispatch, key, value }) {
        const params = {
        }
        params[key] = value;
        dispatch({ type: "SetFormData", payload: params })
    }

    reset({ dispatch }) {
        //
    }
}
export const formAction = new FormAction();