import { ITopicValue } from "../store/topic";
import { ETopicType } from '../enum/ETopicType'
import { SeckillLogic } from "../../../logic/seckill_logic";
import { TopicLogic } from '../com/topic_logic'
import Cookies from 'js-cookie'
import { message } from "antd";
type fType = () => Promise<any>;

/**
 * topic数据控制器
 */
export class TopicDataAction {
    /** 获取数据 */
    static getData<T = any>(store: ITopicValue, id: number): T {
        return store.state.comList[id].data;
    }

    /** 设置数据 */
    static setData<T = any>(store: ITopicValue, id: number, data: T) {
        store.dispatch({
            type: 'SET_DATA',
            payload: {
                id: id,
                data: data,
            }
        });
    }

    /**
     * 设置提取方法
     * @param _id id
     * @param _f 方法
     */
    static setEF(store: ITopicValue, _id: number, _f: fType) {
        store.state.comList[_id].extractDataF = _f;
    }

    /**
     * 获取提取方法
     * @param _id id
     */
    static getEF(store: ITopicValue, _id: number): fType {
        return store.state.comList[_id].extractDataF as fType;
    }

    /**
     * 获取配置信息
     */
     static setPage<T = any>(store: ITopicValue, comList: T) {
        store.dispatch({
            type: 'SET_COMLIST',
            payload: {
                comList: comList
            }
        });
      
    }
    /**判断商品是否存在 */
    static getGoods(list){
        // const comList = store.state.comList
        const comList = list
        let { shopID } = JSON.parse(Cookies.get('loginMsg'))
        let scales = []
        let presales  = []
        let goods = []
        let arr = []

        comList.map((com,i) => {

            switch(com.type){
                case ETopicType.adbannerone:
                    {
                        if( com.data.linkUrl == '预售活动' ) {
                            presales.push(com.data.ID)
                        }
                        break;
                    }
                case ETopicType.advimg :
                {
                   com.data.datalist.map( (item,index)=>{
                    let id ;
                    if(item.selectUrl.type == '商品'){
                        id = item.selectUrl.key
                    }
                    if(item.selectUrl.type == '限时购'){
                        id = item.selectUrl.key.goodsID
                        scales.push(item.selectUrl.key.seckillID)
                    }
                    goods.push(id)

                    })
                   
                    break;
                }
                case ETopicType.navimg :
                    {
                        com.data.length>0&&com.data.map( (item,index)=>{
                            let id ;
                            if(item.selectUrl.type == '商品'){
                                id = item.selectUrl.key
                            }
                            if(item.selectUrl.type == '限时购'){
                                id = item.selectUrl.key.goodsID
                                scales.push(item.selectUrl.key.seckillID)
                            }
                           goods.push(id)
                        })
                      

                        break;
                    }
                case ETopicType.goods : 
                    {
                        com.data.datalist.length>0&&com.data.datalist.map( (item,index)=>{
                           goods.push(item.id)
                        })
                       
                        break;
                    }
                case ETopicType.flashsale :
                    {
                        com.data.length>0&&com.data.map( (item,index)=>{
                            if(scales.indexOf(item.selectUrl.id) == -1){
                                scales.push(item.selectUrl.id)
                            }
                        })
                      
                        break;
                    }
                case ETopicType.shopwindow :
                    {
                        com.data.length>0&&com.data.map(  (item, index) => {
                            let id ;
                            if(item.selectUrl.type == '商品'){
                                id = item.selectUrl.key
                            }
                            if(item.selectUrl.type == '限时购'){
                                id = item.selectUrl.key.goodsID
                                scales.push(item.selectUrl.key.seckillID)
                            }
                          goods.push(id)
                        })
                        
                        break;
                    }
                case ETopicType.textlink :
                    {
                        const l = com.data
                        com.data.length>0&& com.data.map(  (item,index) => {
                            let id ;
                            if(item.selectUrl.type == '商品'){
                                id = item.selectUrl.key
                            }
                            if(item.selectUrl.type == '限时购'){
                                id = item.selectUrl.key.goodsID
                                scales.push(item.selectUrl.key.seckillID)
                            }
                          goods.push(id)
                        })
                      
                        break;
                    }
                case ETopicType.hots :
                    {
                        const l = com.data.precinctList
                        com.data.precinctList.length>9&&com.data.precinctList.map( async (item, index)=>{
                            let id ;
                            if(item.url.type == '商品'){
                                id = item.url.key
                            }
                            if(item.url.type == '限时购'){
                                id = item.url.key.goodsID
                                scales.push(item.url.key.seckillID)
                                console.log(item.url.key.seckillID)
                            }
                            goods.push(id)
                        })
                      
                        break;
                    }
                default: {
                    break;
                }
            }
        })
         goods.map(item=>{
            if(arr.indexOf(item) == -1){
                arr.push(item)
            }
        })
        let arrs = scales.filter(item=>item!=undefined)
        console.log(presales)
       let  ids={
            goods:goods,
            scales: arrs,
            presales:presales
        }
        return ids
    }

    /**验证商品是否存在 */
    static async checkGoods(goods){
      const r = await new TopicLogic().checkGoodsStatus(goods)
      if(r.data.statusCode == 200){
        return r.data.data
      }
    }    
    /**验证秒杀活动是否正常 */
    static async checkSeckill(snaps) {
        const r = await new TopicLogic().checkSeckillStatus(snaps)
        if(r.data.statusCode == 200){
            return r.data.data
          }
    }
    /**验证预售活动 */
    static async checkPresale(presales) {
        const r = await new TopicLogic().checkPresaleStauts(presales)
        if(r.data.statusCode == 200 ) {
            return r.data.data
        }
    }
}