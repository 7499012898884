import { useBoolean } from "ahooks";
import { Button, Input, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect, useState } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { Search } from "../../../../component/search";
import { DataTable } from "../../../../component/table";
import { DataTableStore } from "../../../../component/table/store/data_table_store";

import img_edit from "../../../../assets/images/bianji.png";
import img_see from "../../../../assets/images/chakan1.png";
import img_del from "../../../../assets/images/shanchu.png";
import { Form } from "./out_stock_goods_form";
import { warehousingAction } from "../../action/in-stock/warehousing_action";
import { TableExpand } from "../../../../component/table-expand";
import { ConfirmModal } from "../../../../component/modal";
import { Tips, TipsGroup } from "../../../../component/tips";
import { InventorySearch } from "./out_stock_search";
import { OutStockStore } from "../../store/out-stock/out_stock_store";
import { OutStockFormStoreProvider } from "../../store/out-stock/out_stock_form_store";
import { outStockAction } from "../../action/out-stock/out_stock_action";
import { outStockFormAction } from "../../action/out-stock/out_stock_form_action";
import { Mask } from "../../../../component/mask";
import { defualtPageSize, Progress } from "../../../../logic/common_logic";
import { AuthLogic } from "../../../../logic/auth_logic";
import { Control, Del, Edit, See } from "../../../../component/control-column";
const formParams = {
  method: "Create",
  id: "",
  subId: "",
  item: null,
};

export function OutStock(viewPower) {
  const [createState, createFunc] = useBoolean(false);
  const [searchState, searchFunc] = useBoolean(false);
  const [confirmState, confirmFunc] = useBoolean(false);

  const { state, dispatch } = useContext(OutStockStore);
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);
  const [] = useBoolean(false);

  const action = outStockAction,
    formAction = outStockFormAction;
  const [menuButton, setData] = useState([]);
  useEffect(() => {
    (async function () {
      let r = await new AuthLogic().selButton(viewPower.viewPower);
      if (r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          r[i] = r[i].split("_")[r[i].split("_").length - 1];
        }
      }
      setData(r);
    })();

    return () => {};
  }, []);
  const columns: ColumnsType = [
    {
      title: "操作",
      key: "1",
      dataIndex: "oddNumbers",
      align: "center",
      width: Control.width(3),
      render: Control.render(
        (v) =>
          menuButton.indexOf("VIEW") != -1 && (
            <See
              click={() => {
                formParams.id = v;
                formParams.method = "Preview";

                createFunc.setTrue();
              }}
            />
          ),
        (v, r) =>
          r.inventoryStatus !== 1 &&
          menuButton.indexOf("UPDATE") != -1 && (
            <Edit
              click={() => {
                formParams.id = v;
                formParams.subId = r.id;
                formParams.method = "Edit";
                formParams.item = r;

                createFunc.setTrue();
              }}
            />
          ),
        (v, r) =>
          r.inventoryStatus !== 1 &&
          menuButton.indexOf("DELETE") != -1 && (
            <Del
              click={() => {
                formParams.id = v;
                formParams.subId = r.id;

                confirmFunc.setTrue();
              }}
            />
          )
      ),
    },
    {
      title: "出库单号",
      dataIndex: "oddNumbers",
      key: "2",
      align: "center",
      width: 190,
    },
    {
      title: "门店",
      dataIndex: "shopName",
      key: "3",
      align: "center",
    },
    {
      title: "出库数量",
      dataIndex: "number",
      key: "4",
      align: "center",
    },
    {
      title: "出库金额",
      dataIndex: "money",
      key: "5",
      align: "center",
    },
    {
      title: "来源",
      dataIndex: "source",
      key: "6",
      align: "center",
      render: (v) => <span>{1 == v ? "收银端" : "管理端"}</span>,
    },
    {
      title: "单据状态",
      dataIndex: "inventoryStatus",
      key: "7",
      align: "center",
      render: (v) => (
        <>
          {v == "1" && <Tag color="success">已审核</Tag>}
          {v == "2" && <Tag color="error">未审核</Tag>}
          {v == "3" && <Tag>作废</Tag>}
        </>
      ),
    },
    {
      title: "理由",
      dataIndex: "reason",
      key: "8",
      align: "center",
      render: (v) => (
        <>
          {"1" == v && <span>手动入库</span>}
          {"2" == v && <span>退货入库</span>}
          {"3" == v && <span>采购入库</span>}
          {"4" == v && <span>手动出库</span>}
          {"5" == v && <span>销售出库</span>}
          {"6" == v && <span>库存调整</span>}
          {"7" == v && <span>商品试吃</span>}
          {"8" == v && <span>日常报损</span>}
          {"9" == v && <span>商品过期</span>}
          {"10" == v && <span>领用</span>}
          {"11" == v && <span>日常报溢</span>}
          {"12" == v && <span>订单赠送出库</span>}
          {"13" == v && <span>订单赠送退货</span>}
        </>
      ),
    },
    {
      title: "制单人",
      dataIndex: "addUserName",
      key: "8",
      align: "center",
      width: 90
    },
    {
      title: "审核时间",
      dataIndex: "checkDateTime",
      key: "8",
      align: "center",
      width: 140,
    },
    {
      title: "出库日期",
      dataIndex: "addDateTime",
      key: "8",
      align: "center",
      width: 140,
    },
    {
      title: "备注",
      dataIndex: "remark",
      key: "8",
      align: "center",
    },
  ];

  const onSearch = async (pageIndex) => {
    await action.loadPage({
      pageIndex: pageIndex,
      pageSize: defualtPageSize(),
      dispatch: tableDispatch,
      search: state.search,
    });
  };
  const totalNumber = () => {
    let total = 0;
    tableState.dataList.forEach((v) => {
      total += v.number;
    });
    return total;
  };
  const totalPrice = () => {
    let total = 0;
    tableState.dataList.forEach((v) => {
      total += v.money;
    });
    return total;
  };

  return (
    <>
      <Search>
        <Input
          maxLength={50}
          allowClear
          placeholder="请输入单号，门店名称"
          onChange={(e) =>
            action.setCondition({
              dispatch,
              key: "keywords",
              value: e.target.value,
            })
          }
        />
        <input type="button" value="快速查找" onClick={() => onSearch(1)} />

        <TipsGroup>
          <input
            type="button"
            value="高级查找"
            onClick={() => searchFunc.toggle()}
          />
          <Tips width="45rem" display={searchState}>
            <InventorySearch
              onSearch={() => {
                onSearch(1);
                searchFunc.setFalse();
              }}
            />
          </Tips>
          <Mask visible={searchState} onClick={() => searchFunc.setFalse()} />
        </TipsGroup>
      </Search>

      <ButtonPanel>
        {menuButton.indexOf("ADD") != -1 && (
          <Button
            onClick={() => {
              formParams.id = "";
              formParams.method = "Create";

              createFunc.setTrue();
            }}
          >
            新增出库单
          </Button>
        )}
        {menuButton.indexOf("EXPORT") != -1 && (
          <Button
            onClick={async () => {
              await action.exportList({ search: state.search });
            }}
          >
            导出
          </Button>
        )}
      </ButtonPanel>

      <DataTable
        columns={columns}
        action={action}
        onLoad={async () => {
          await onSearch(1);
          Progress.done();
        }}
        onChange={(pageIndex) => {
          onSearch(pageIndex);
        }}
        scroll={{ scrollToFirstRowOnChange: true, autoY: true, x: 1060 }}
        loading={tableState.loading}
      />
      <TableExpand>
        <span>出库数量合计: {totalNumber().toFixed(3)}</span>
        <span style={{ marginLeft: 20 }}>
          出库金额合计: ¥ {totalPrice().toFixed(2)}
        </span>
      </TableExpand>

      {createState && (
        <OutStockFormStoreProvider>
          <Form
            audit={menuButton.indexOf("AUDIT") != -1 ? true : false}
            close={(hasUpdate) => {
              if (hasUpdate) {
                onSearch(tableState.current);
              }

              createFunc.setFalse();
            }}
            action={formAction}
            id={formParams.id}
            subId={formParams.subId}
            item={formParams.item}
            method={formParams.method}
          />
        </OutStockFormStoreProvider>
      )}

      <ConfirmModal
        visible={confirmState}
        title="温馨提示"
        subTitle="确认删除？"
        message="删除之后信息无法恢复，请慎重考虑"
        close={async (r) => {
          if (r) {
            await action.delRow(formParams.subId);

            onSearch(tableState.current);
          }
          confirmFunc.setFalse();
        }}
      />
    </>
  );
}
