import { _tr, _td, TableTool } from "../../tool/TableTool";
import { TabKeys } from "../../tool/TabKeys";
import { ESSDetailTK, ESSDetailTreeTK } from "../tableKey/ESSDetailTK";

const ESSDetailEndTK = TabKeys.byTabKeysGetEndChilds(ESSDetailTK);

export function ESSDetailTable({ data }: { data }) {
    if (!data) { return <div></div> }

    const getTabTds1 = (data): _tr => {
        return ESSDetailEndTK.map((item, index) => {
            return <td key={index}>{data[item.key]}</td> as _td;
        });
    }
    const getTabTds = (data): _tr[] => {
        let _trs: _tr[] = data.invoicingDetails.map((item) => {
            return getTabTds1(item);
        });
        //第一列数据加上分类信息
        _trs[0] && _trs[0].unshift(<td className="dark" rowSpan={_trs.length}>{data.goodsClassName}</td> as _td);
        return _trs;
    };

    return <>
        <thead>
            {TableTool.getTreeTabThs([...ESSDetailTreeTK, ...ESSDetailTK]).map((item, index) => {
                return <tr key={index}>
                    {item}
                </tr>
            })}
        </thead>
        <tbody>
            {
                data.map((item) => {
                    return getTabTds(item).map((item, index) => {
                        return <tr key={index}>{item}</tr>
                    });
                })
            }
        </tbody>
    </>
}