import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
  TimePicker,
  DatePicker,
  Tag,
  message,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useState, useEffect } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { FormSubtitle } from "../../../commodity/view/form_subtitle";
import styles from "../../style/inventory_goods_formbasic.module.scss";
import { RadioStyle } from "../../../../component/radio-style";
import img_del from "../../../../assets/images/shanchu.png";
import { specialformAction } from "../../action/special/form_action";
import { useBoolean } from "ahooks";
import { ChooseGoods } from "../../../../component/choose-goods";
import { Table } from "../../../../component/table";
import { ChooseShop } from "../../../../component/choose-shop";
import { SpecialFormStore } from "../../store/special/form_store";
import { autoRem } from "../../../../global.style";
import moment from "moment";
const { Option } = Select;
const { RangePicker } = DatePicker;
let dayList = [];
export function DataTable({ dataList, disabled }) {
  const { dispatch } = useContext(SpecialFormStore);
  const action = specialformAction;

  const columns: ColumnsType = [
    {
      title: "操作",
      dataIndex: "serial",
      key: "1",
      align: "center",
      width: 60,
      render: (v, r, i) => (
        <div>
          <img
            src={img_del}
            style={{ width: 20 }}
            onClick={() => {
              !disabled && action.delTableItem(dispatch, v);
            }}
          />
        </div>
      ),
    },
    
    {
      title: "规格条码",
      dataIndex: "barCode",
      key: "2",
      align: "center",
      width: 120,
    },
    {
      title: "商品名称",
      dataIndex: "goodsName",
      key: "3",
      align: "center",
      width: 120,
    },
    {
      title: "商品分类",
      dataIndex: "goodsClassName",
      key: "4",
      align: "center",
      width: 100,
    },
    {
      title: "单位",
      dataIndex: 'goodsUnitName',
      key: "5",
      align: "center",
      width: 80,
    },

    {
      title: "门店价",
      dataIndex: 'shopPrice',
      key: "6",
      align: "center",
      width: 80,
    },
    {
      title: "商城价",
      dataIndex: 'storePrice',
      key: "7",
      align: "center",
      width: 80,
    },

    {
      title: "特价",
      key: "8",
      align: "center",
      width: 120,
      render: (e, r, i) => {
        return <Input style={{ width: 100 }} type='number' value={e.specialPrice} disabled={disabled}
          onChange={event => {
            let arr = event.target.value.split('.')
            if (arr[1] && arr[1].length > 2) {
            } else {
              action.changeTableItem({ dispatch, key: "specialPrice", value: event.target.value, index: e.serial })
            }
          }} />
      }
      //   render: (e,r,i)=><Input
      //   type="number"
      //   defaultValue={r.specialPrice}
      //   disabled={disabled}
      //   onChange={(e) => {
      //     e.specialPrice = parseFloat(e.currentTarget.value);
      //     dispatch({
      //       type: "update",
      //       payload: null,
      //     });

      //     if (parseFloat(e.currentTarget.value) <= 0) {
      //       message.error("请输入大于0的数");
      //       return false;
      //     } else {
      //     }
      //   }}
      // />
    },
  ];

  return (
    <Table
      dataSource={dataList}
      columns={columns}
    // expandable={{
    //   expandedRowRender: (record) => {
    //     return record.goodsSpecifications.map((item) => {
    //       return (
    //         <GetSpecifications specifications={item} disabled={disabled} />
    //       );
    //     });
    //   },
    //   rowExpandable: (record) => record.name !== "Not Expandable",
    // }}
    />
  );
}

function GetSpecifications({ specifications, disabled }) {
  const { state, dispatch } = useContext(SpecialFormStore);
  return (
    <div className={styles.specifications}>
      <div className={styles.input} style={{ width: autoRem(9.4) }}>
        <span>{specifications.unitContent}</span>
        <span>{specifications.unitName}</span>
      </div>
      <div
        className={styles.input}
        style={{ width: autoRem(9.4), border: "none" }}
      >
        <span>{specifications.shopPrice}</span>
      </div>
      <div className={styles.input} style={{ width: autoRem(9.4) }}>
        <span>{specifications.storePrice}</span>
      </div>
      {/* 特价 */}
      <div className={styles.input} style={{ width: autoRem(9.4) }}>
        <Input
          type="number"
          defaultValue={specifications.specialPrice}
          disabled={disabled}
          onChange={(e) => {
            specifications.specialPrice = parseFloat(e.currentTarget.value);
            dispatch({
              type: "update",
              payload: null,
            });

            if (parseFloat(e.currentTarget.value) <= 0) {
              message.error("请输入大于0的数");
              return false;
            } else {
            }
          }}
        />
      </div>
    </div>
  );
}
export function FormBasic({ title, disabled, updateFormField }) {
  const [shopState, shopFunc] = useBoolean(false);
  const [goodsState, goodsFunc] = useBoolean(false);

  const { state, dispatch } = useContext(SpecialFormStore);
  const { formData } = state;

  const action = specialformAction;
  var isLook = false;
  if (title == "查看活动") {
    isLook = true;
  } else {
    isLook = false;
  }
  const onChangeCheck = (e) => {
    if (!disabled) {
      action.setFormData({
        dispatch,
        key: "isMemberClass",
        value: e.target.checked ? 1 : 2,
      });
      if (e.target.checked) {
        action.setFormData({ dispatch, key: "classList", value: [] });
        action.setFormData({ dispatch, key: "useMemberClassID", value: [] });
      }
    }
  };
  const count = (key) =>
    state.tableList.map((v) => v[key]).reduce((p, c) => p + c, 0);

  const handleChange = (value, option) => {
    let arr = [];
    let arrayId = [];
    for (let i = 0; i < option.length; i++) {
      let obj = {};
      obj["memberClassID"] = option[i].key;
      arrayId.push(option[i].key);
      arr.push(obj);
    }
    action.setFormData({ dispatch, key: "memberClassList", value: arr });
    action.setFormData({ dispatch, key: "useMemberClassID", value: arrayId });
  };
  // 时间段选择
  const timePickeronChange = (time, timeString) => {
    action.setFormData({
      dispatch,
      key: "activityStartTime",
      value: timeString[0],
    });
    action.setFormData({
      dispatch,
      key: "activityEndTime",
      value: timeString[1],
    });
  };
  const range = (start, end) => {
    const result = [];

    for (let i = start; i < end; i++) {
      result.push(i);
    }

    return result;
  };
  const disabledDateTime = () => {
    let hours = moment().hours(); //0~23

    let minutes = moment().minutes(); //0~59

    let seconds = moment().seconds(); //0~59
    let time;
    //当日只能选择当前时间之后的时间点
    if (
      formData.activityEndDate &&
      moment(formData.activityEndDate) == moment()
    ) {
      time = {
        disabledHours: () => range(0, hours),

        disabledMinutes: () => range(0, minutes + 1),

        disabledSeconds: () => range(0, seconds + 1),
      };
      return time;
    }
  };

  // 日期段选择
  const onangeChange = (time, timeString) => {
    action.setFormData({
      dispatch,
      key: "activityStartDate",
      value: timeString[0],
    });
    action.setFormData({
      dispatch,
      key: "activityEndDate",
      value: timeString[1],
    });
    action.setFormData({ dispatch, key: "useTypeValue", value: [] });

    setDays();
  };
  const disabledDate = (current) => {
    return current < moment().startOf("day");
  };

  function setDays() {
    let date1 = formData.activityStartDate;
    let date2 = formData.activityEndDate;
    let monthBegin = new Date(date1).getMonth();
    let monthEnd = new Date(date2).getMonth();
    let dayBegin = new Date(date1).getDate();
    let dayEnd = new Date(date2).getDate();
    dayList = [];

    if (monthBegin != null && monthEnd != null && monthBegin == monthEnd) {
      //console.log(dayBegin,dayEnd)

      for (let i = dayBegin; i <= dayEnd; i++) {
        dayList.push({ value: `${i}`, name: `${i}日` });
      }
    } else {
      for (let i = 0; i < 31; i++) {
        dayList.push({ value: `${i + 1}`, name: `${i + 1}日` });
      }
    }
  }

  const selectDetaChange = (e) => {
    if (formData.useType == 2) {
      action.setFormData({ dispatch, key: "useTypeValue", value: e });
    }
  };
  const onChangeCheckbox = (e) => {
    if (formData.useType != 2) {
      //console.log(formData.useType)
      action.setFormData({ dispatch, key: "useweekTypeValue", value: e });
    }
  };
  function WeekType({ value, swtichValue }) {
    const [] = useState(1);
    const { dispatch } = useContext(SpecialFormStore);

    const onChange = (e) => {
      if (!disabled) {
        action.setFormData({ dispatch, key: "useType", value: e.target.value });
      }
    };
    return (
      <RadioStyle>
        <Radio.Group
          onChange={onChange}
          value={swtichValue}
          style={{ marginTop: "10px" }}
        >
          <Radio
            value={1}
            style={{ marginLeft: "15px", height: "30px", lineHeight: "30px" }}
          >
            按固定星期
          </Radio>
          <Radio
            value={2}
            style={{ marginLeft: "10px", height: "30px", lineHeight: "30px" }}
          >
            按固定日期
          </Radio>
        </Radio.Group>
        {formData.useType == 2 ? (
          <div style={{ marginLeft: "15px", marginTop: "10px" }}>
            固定日期 （默认不选表示不限制日期，可以多选）
          </div>
        ) : (
          <div style={{ marginLeft: "15px", marginTop: "10px" }}>固定星期</div>
        )}
      </RadioStyle>
    );
  }
  function ChannelType({ value, swtichValue }) {
    const [] = useState(1);
    const { dispatch } = useContext(SpecialFormStore);

    const onchannelChange = (e) => {
      if (!disabled) {
        action.setFormData({
          dispatch,
          key: "purposeType",
          value: e.target.value,
        });
        action.clearTableList({ dispatch });
      }
    };
    return (
      <RadioStyle>
        <Radio.Group onChange={onchannelChange} value={swtichValue}>
          <Radio
            value={1}
            style={{ marginLeft: "15px", height: "30px", lineHeight: "30px" }}
          >
            门店收银
          </Radio>
          <Radio
            value={2}
            style={{ marginLeft: "10px", height: "30px", lineHeight: "30px" }}
          >
            线上商城
          </Radio>
          <Radio
            value={3}
            style={{ marginLeft: "10px", height: "30px", lineHeight: "30px" }}
          >
            全部
          </Radio>
        </Radio.Group>
      </RadioStyle>
    );
  }
  setDays();

  let height = document.body.clientHeight - 188;
  useEffect(() => { }, []);
  return (
    <div className={styles.root} style={{ height: height + "px" }}>
      <div className={styles.content}>
        <FormSubtitle>基础信息</FormSubtitle>

        <div
          className="custom-radio"
          style={{
            margin: "" + autoRem(1.9375) + " " + autoRem(1.25) + " 0 0",
          }}
        >
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 3 }}
                label="活动名称"
                name="activityName"
                rules={[
                  {
                    required: true,
                    validator: (_, __, callBack: (error?: string) => void) => {
                      if (!formData.activityName) {
                        callBack("请输入活动名称");
                        return;
                      }
                      callBack();
                    },
                  },
                ]}
              >
                {formData.activityName ? "" : ""}
                <Input
                  maxLength={15}
                  style={{ width: "400px" }}
                  disabled={disabled}
                  value={formData.activityName}
                  autoComplete="off"
                  placeholder="请输入"
                  onChange={(e) => {
                    updateFormField("activityName");
                    action.setFormData({
                      dispatch,
                      key: "activityName",
                      value: e.target.value,
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 3 }}
                label={<span className={styles.alarm}>活动方式</span>}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Checkbox checked={true} onChange={onChangeCheck}>
                    直接特价
                  </Checkbox>
                  <span style={{ marginTop: "5px" }}>
                    注：针对设置的商品，进行特价
                  </span>
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 3 }}
                label="分配门店"
                name="shopName"
                rules={[
                  {
                    required: true,
                    validator(_, __, callback: (error?: string) => void) {
                      if (
                        state.formData.shopID == undefined ||
                        state.formData.shopID.length == 0
                      ) {
                        callback("请选择门店");
                        return;
                      }
                      callback();
                    },
                  },
                ]}
              >
                {formData.shopName ? "" : ""}
                <Input
                  style={{ width: "400px" }}
                  disabled={disabled}
                  value={formData.shopName}
                  autoComplete="off"
                  placeholder="请选择门店"
                  onClick={() => shopFunc.setTrue()}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 3 }}
                label={<span className={styles.alarm}>会员等级</span>}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Checkbox
                    checked={formData.isMemberClass == 1 ? true : false}
                    onChange={onChangeCheck}
                  >
                    不限制
                  </Checkbox>
                  <Select
                    disabled={
                      disabled || formData.isMemberClass == 1 ? true : false
                    }
                    mode="multiple"
                    style={{ width: "400px", marginTop: "10px" }}
                    value={formData.useMemberClassID}
                    placeholder="请选择"
                    onChange={handleChange}
                  >
                    {state.TagList.map((v) => (
                      <Option value={v.id} key={v.id}>
                        {v.gradeName}
                      </Option>
                    ))}
                  </Select>
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 3 }}
                label={<span className={styles.alarm}>活动时间</span>}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span>开始日期 - 结束日期</span>
                  {formData.activityStartDate == "" ||
                    formData.activityStartDate == null ? (
                    <RangePicker
                      disabled={disabled}
                      disabledDate={disabledDate}
                      style={{ width: "400px", marginTop: "10px" }}
                      onChange={onangeChange}
                    />
                  ) : (
                    <RangePicker
                      disabled={disabled}
                      disabledDate={disabledDate}
                      style={{ width: "400px", marginTop: "10px" }}
                      onChange={onangeChange}
                      value={[
                        moment(formData.activityStartDate, "YYYY-MM-DD"),
                        moment(formData.activityEndDate, "YYYY-MM-DD"),
                      ]}
                    />
                  )}
                  <span style={{ marginTop: "10px" }}>
                    开始时间段 - 结束时间段
                  </span>
                  {formData.activityStartTime == "" ||
                    formData.activityStartTime == null ? (
                    <TimePicker.RangePicker
                      disabled={disabled}
                      disabledMinutes={() => {
                        let h = disabledDateTime();
                        if (h) return h.disabledMinutes();
                      }}
                      disabledSeconds={() => {
                        let h = disabledDateTime();
                        if (h) return h.disabledSeconds();
                      }}
                      disabledHours={() => {
                        let h = disabledDateTime();
                        if (h) return h.disabledHours();
                      }}
                      hideDisabledOptions={true}
                      style={{ width: "400px", marginTop: "10px" }}
                      onChange={timePickeronChange}
                    />
                  ) : (
                    <TimePicker.RangePicker
                      disabled={disabled}
                      disabledMinutes={() => {
                        let h = disabledDateTime();
                        if (h) return h.disabledMinutes();
                      }}
                      disabledSeconds={() => {
                        let h = disabledDateTime();
                        if (h) return h.disabledSeconds();
                      }}
                      disabledHours={() => {
                        let h = disabledDateTime();
                        if (h) return h.disabledHours();
                      }}
                      hideDisabledOptions={true}
                      style={{ width: "400px", marginTop: "10px" }}
                      onChange={timePickeronChange}
                      value={[
                        moment(formData.activityStartTime, "hh-mm-ss"),
                        moment(formData.activityEndTime, "hh-mm-ss"),
                      ]}
                    />
                  )}

                  <WeekType
                    value={1}
                    swtichValue={formData.useType ? formData.useType : 1}
                  />
                  {formData.useType == 2 ? (
                    <Select
                      disabled={disabled}
                      mode="tags"
                      style={{ width: "400px", marginTop: "10px" }}
                      placeholder="请选择"
                      onChange={selectDetaChange}
                      value={
                        Array.isArray(formData.useTypeValue)
                          ? formData.useTypeValue
                          : []
                      }
                    >
                      {dayList.map((day, index) => {
                        return (
                          <Option value={day.value} key={index}>
                            {day.name}
                          </Option>
                        );
                      })}
                    </Select>
                  ) : (
                    <Checkbox.Group
                      disabled={disabled}
                      style={{ width: "100%", marginTop: "10px" }}
                      value={
                        Array.isArray(formData.useweekTypeValue)
                          ? formData.useweekTypeValue
                          : []
                      }
                      onChange={onChangeCheckbox}
                    >
                      <Row>
                        <Col
                          span={4}
                          style={{
                            marginRight: "6px",
                            border: "1px solid #eee",
                            textAlign: "center",
                            height: "35px",
                            lineHeight: "35px",
                          }}
                        >
                          <Checkbox value="1">星期一</Checkbox>
                        </Col>
                        <Col
                          span={4}
                          style={{
                            marginRight: "6px",
                            border: "1px solid #eee",
                            textAlign: "center",
                            height: "35px",
                            lineHeight: "35px",
                          }}
                        >
                          <Checkbox value="2">星期二</Checkbox>
                        </Col>
                        <Col
                          span={4}
                          style={{
                            marginRight: "6px",
                            border: "1px solid #eee",
                            textAlign: "center",
                            height: "35px",
                            lineHeight: "35px",
                          }}
                        >
                          <Checkbox value="3">星期三</Checkbox>
                        </Col>
                        <Col
                          span={4}
                          style={{
                            marginRight: "6px",
                            border: "1px solid #eee",
                            textAlign: "center",
                            height: "35px",
                            lineHeight: "35px",
                          }}
                        >
                          <Checkbox value="4">星期四</Checkbox>
                        </Col>
                        <Col
                          span={4}
                          style={{
                            marginRight: "6px",
                            border: "1px solid #eee",
                            textAlign: "center",
                            height: "35px",
                            lineHeight: "35px",
                          }}
                        >
                          <Checkbox value="5">星期五</Checkbox>
                        </Col>
                        <Col
                          span={4}
                          style={{
                            marginRight: "6px",
                            border: "1px solid #eee",
                            textAlign: "center",
                            height: "35px",
                            lineHeight: "35px",
                          }}
                        >
                          <Checkbox value="6">星期六</Checkbox>
                        </Col>
                        <Col
                          span={4}
                          style={{
                            marginRight: "6px",
                            border: "1px solid #eee",
                            textAlign: "center",
                            height: "35px",
                            lineHeight: "35px",
                          }}
                        >
                          <Checkbox value="7">星期日</Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  )}
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 3 }}
                label={<span className={styles.alarm}>使用途径</span>}
              >
                <ChannelType
                  value={1}
                  swtichValue={formData.purposeType ? formData.purposeType : 1}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 3 }}
                label={<span className={styles.alarm}>活动商品</span>}
              >
                <div
                  style={{
                    fontSize: "10px",
                    width: "60px",
                    height: "30px",
                    textAlign: "center",
                    lineHeight: "30px",
                    background: "#ff746d",
                    color: "#fff",
                    cursor: "pointer",
                  }}
                  onClick={() => !disabled && goodsFunc.setTrue()}
                >
                  添加商品
                </div>
              </Form.Item>
            </Col>
          </Row>
        </div>

        <DataTable dataList={state.tableList} disabled={disabled} />
      </div>

      <ChooseShop
        visibleModal={shopState}
        type='checkbox'
        close={(status, r) => {
          if (r) {
            let arr = [];
            let shopNameArr = []
            for (let i = 0; i < r.length; i++) {
              arr.push(r[i].id);
              shopNameArr.push(r[i].shopName)
            }
            action.setFormData({ dispatch, key: "shopID", value: arr });
            action.setFormData({
              dispatch,
              key: "shopName",
              value: shopNameArr.toString(),
            });
          }
          updateFormField("shopName");
          shopFunc.setFalse();
        }}
      />
      <ChooseGoods
        visibleModal={goodsState}
        key={formData.purposeType ? formData.purposeType : 1}
        chooseType="special"
        rowKey="barCode"
        filter={{
          isMarketing: true,
          specifications: true,
          source:
            formData.purposeType == 1
              ? "CS"
              : formData.purposeType == 2
                ? "WX"
                : formData.purposeType == 3
                  ? "PC"
                  : "CS",
        }}
        close={(status, r) => {
          if (r) {
            action.addTableItem({ dispatch, list: r });
          }
          goodsFunc.setFalse();
        }}
      />
    </div>
  );
}
