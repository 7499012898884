import { useBoolean } from "ahooks";
import { Col, Form, Input, Row, Select, Switch } from "antd";
import { useContext, useEffect } from "react";
import { Modal } from "../../../../component/modal";
import { formAction } from "../../action/printSet/print_set_action";
import { FormStore } from "../../store/printSet/print_set_store";
import styles from "../../style/print_set.module.scss";
export function PrintItemForm({ visibleModal, close: _close, form, title }) {
  const action = formAction;
  const { state, dispatch } = useContext(FormStore);

  const selectList = [
    {
      key: "goodsName",
      value: "商品名称",
      title: "商品名称",
    },
    {
      key: "shopPrice",
      value: "商品价格",
      title: "0.00",
    },
    {
      key: "unitContent",
      value: "规格",
      title: "规格",
    },
    {
      key: "remark",
      value: "规格说明",
      title: "规格说明",
    },
    {
      key: "unitName",
      value: "单位",
      title: "单位",
    },
    {
      key: "lineationPrice",
      value: "划线价（原价）",
      title: "1.00",
    },
    {
      key: "barCode",
      value: "货号（商品条码）",
      title: "货号（商品条码）",
    },

    {
      key: "free",
      value: "自定义",
      title: "自定义",
    },
  ];

  const close = () => {
    form.resetFields();
    _close();
  };

  const handleOk = async () => {
    await form.validateFields();
    action.saveComponent({ dispatch, title });
    close();
  };

  const handleCancel = () => {
    close();
  };
  useEffect(() => {
    action.init({ dispatch, form, item: state.selectFormItem });
  });
  function Radio({ onChange, defaultValue, disabled }) {
    const [state, stateFunc] = useBoolean(false);

    useEffect(() => {
      if (defaultValue) {
        stateFunc.setTrue();
      } else {
        stateFunc.setFalse();
      }

      return () => {};
    }, [defaultValue]);

    return (
      <div
        className={`${styles.radio} ${state ? styles.active : ""} ${
          disabled ? styles.disabled : ""
        }`}
        onClick={() => {
          if (disabled) return;
          onChange(!state);
          stateFunc.toggle();
        }}
      ></div>
    );
  }
  return (
    <Modal
      title="编辑表单组件"
      style={{ top: 20 }}
      visible={visibleModal}
      okText="确定"
      cancelText="取消"
      onOk={handleOk}
      onCancel={handleCancel}
      width={700}
    >
      <Form
        name="basic"
        initialValues={{ remember: true }}
        form={form}
        style={{ textAlign: "left" }}
      >
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item
              label="类型"
              name="componentType"
              labelCol={{ span: 4 }}
              rules={[
                {
                  required: true,
                  message: "请选择类型",
                },
              ]}
            >
              <Select
                onChange={(v, option) => {
                  action.setFormData({
                    dispatch,
                    key: "componentType",
                    value: v,
                  });
                  action.setFormData({
                    dispatch,
                    key: "value",
                    value: v == "free" ? "" : option["key"],
                  });
                }}
              >
                {selectList.map((item) => {
                  return (
                    <Select.Option value={item.key} key={item.title}>
                      {item.value}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={24}>
            {state.selectFormItem.componentType == "free" ? (
              <Form.Item
                label="自定义内容"
                labelCol={{ span: 4 }}
                name="value"
                rules={[
                  {
                    required: true,
                    message: "请填写内容",
                  },
                ]}
              >
                <Input
                  autoComplete="off"
                  onChange={(e) => {
                    action.setFormData({
                      dispatch,
                      key: "value",
                      value: e.target.value,
                    });
                  }}
                />
              </Form.Item>
            ) : (
              <Form.Item
                label="标题"
                labelCol={{ span: 4 }}
                name="title"
                // rules={[
                //   {
                //     required: true,
                //     message: "请填写字段名称",
                //   },
                // ]}
              >
                <Input
                  autoComplete="off"
                  onChange={(e) => {
                    action.setFormData({
                      dispatch,
                      key: "title",
                      value: e.target.value,
                    });
                  }}
                />
              </Form.Item>
            )}
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              label="标题字体大小"
              name="titleFontSize"
              labelCol={{ span: 8 }}
            >
              <Input
                autoComplete="off"
                onBlur={(e) => {
                  let size = Number(e.target.value);
                  if (isNaN(size)) {
                    action.setFormData({
                      dispatch,
                      key: "titleFontSize",
                      value: 12,
                    });
                  } else if (size < 12) {
                    action.setFormData({
                      dispatch,
                      key: "titleFontSize",
                      value: 12,
                    });
                  } else {
                    action.setFormData({
                      dispatch,
                      key: "titleFontSize",
                      value: size,
                    });
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="标题字体粗细"
              name="titleFontWeight"
              labelCol={{ span: 8 }}
            >
              <Select
                onChange={(v) => {
                  action.setFormData({
                    dispatch,
                    key: "titleFontWeight",
                    value: v,
                  });
                }}
              >
                <Select.Option value={400}>400</Select.Option>
                <Select.Option value={500}>500</Select.Option>
                <Select.Option value={600}>600</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              label="内容字体大小"
              name="valueFontSize"
              labelCol={{ span: 8 }}
            >
              <Input
                autoComplete="off"
                onBlur={(e) => {
                  let size = Number(e.target.value);
                  if (isNaN(size)) {
                    action.setFormData({
                      dispatch,
                      key: "valueFontSize",
                      value: 12,
                    });
                  } else if (size < 12) {
                    action.setFormData({
                      dispatch,
                      key: "valueFontSize",
                      value: 12,
                    });
                  } else {
                    action.setFormData({
                      dispatch,
                      key: "valueFontSize",
                      value: size,
                    });
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="内容字体粗细"
              name="valueFontWeight"
              labelCol={{ span: 8 }}
            >
              <Select
                onChange={(v) => {
                  action.setFormData({
                    dispatch,
                    key: "valueFontWeight",
                    value: v,
                  });
                }}
              >
                <Select.Option value={400}>400</Select.Option>
                <Select.Option value={500}>500</Select.Option>
                <Select.Option value={600}>600</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              label="文字位置(水平)"
              name="textAlign"
              labelCol={{ span: 8 }}
            >
              <Select
                onChange={(v) => {
                  action.setFormData({
                    dispatch,
                    key: "textAlign",
                    value: v,
                  });
                }}
              >
                <Select.Option value={"start"}>左</Select.Option>
                <Select.Option value={"center"}>中</Select.Option>
                <Select.Option value={"end"}>右</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="文字位置(垂直)"
              name="verticalAlign"
              labelCol={{ span: 8 }}
            >
              <Select
                onChange={(v) => {
                  action.setFormData({
                    dispatch,
                    key: "verticalAlign",
                    value: v,
                  });
                }}
              >
                <Select.Option value={"start"}>上</Select.Option>
                <Select.Option value={"center"}>中</Select.Option>
                <Select.Option value={"end"}>下</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item label="边框显示" name="isBorder" labelCol={{ span: 8 }}>
              <Select
                mode="multiple"
                onChange={(v) => {
                  action.setFormData({
                    dispatch,
                    key: "isBorder",
                    value: v,
                  });
                }}
              >
                <Select.Option value={"top"}>上</Select.Option>
                <Select.Option value={"right"}>右</Select.Option>
                <Select.Option value={"bottom"}>下</Select.Option>
                <Select.Option value={"left"}>左</Select.Option>
              </Select>
              {/* <Radio
                disabled={false}
                defaultValue={state.selectFormItem.isBorder}
                onChange={(v) => {
                  // updateFormField("shopShow");
                  action.setFormData({ dispatch, key: "isBorder", value: v });
                }}
              /> */}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="超出省略"
              name="isEllipsis"
              labelCol={{ span: 8 }}
            >
              <Radio
                disabled={false}
                defaultValue={state.selectFormItem.isEllipsis}
                onChange={(v) => {
                  // updateFormField("shopShow");
                  action.setFormData({ dispatch, key: "isEllipsis", value: v });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
