import { useBoolean, useRequest } from "ahooks";
import { Button, Input, message, Modal, Tag, Spin } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect, useState, useRef } from "react";
import { ButtonPanel } from "../../../component/button-panel/button_panel";
import { Search } from "../../../component/search";
import { DataTable } from "../../../component/table/view/data_table";
import { Tips, TipsGroup } from "../../../component/tips";
import { formAction } from "../action/form_action";
import { FormStoreProvider } from "../store/form_store";
import { FileSearch } from "./file_search";
import { Form } from "./form";
import { DataTableStore } from "../../../component/table/store/data_table_store";
import { FileStore } from "../store/file_store";
import { fileAction } from "../action/file_action";
import { Tools, axios } from "../../../config";
import { ConfirmModal } from "../../../component/modal";
import img_def from "../../../assets/images/goods/goods_def.jpg";
import { Mask } from "../../../component/mask";
import { defualtPageSize, Progress } from "../../../logic/common_logic";
import { Control, Del, Edit, See } from "../../../component/control-column";
import { AuthLogic } from "../../../logic/auth_logic";
import styles from "../style/file.module.scss";
import { zip } from "../../../logic/common_logic";
import Cookies from "js-cookie";
// import axios from "axios";
import { autoRem } from "../../../global.style";
const formParams = {
  method: "Create",
  id: "",
};
const imgParams = {
  url: "",
};

export function File(viewPower) {
  const inputRef = useRef(null);
  const [createState, createFunc] = useBoolean(false);
  const [searchState, searchFunc] = useBoolean(false);
  const [confirmState, confirmFunc] = useBoolean(false);
  const [confirmAllState, confirmAllFunc] = useBoolean(false); //批量删除
  //批量上架
  const [comfirmUpAll, upAllFunc] = useBoolean(false);
  //批量下架
  const [comfirmDownAll, downAllFunc] = useBoolean(false);
  //下载导入模板
  const [downloadState, downloadFunc] = useBoolean(false);
  const [importState, importFunc] = useBoolean(false);

  const onChange = async (e) => {
    let file = e.target.files[0];
    // console.log(file);

    // 压缩
    // file = await zip(file);

    const formData = new FormData();

    formData.append("file", file);
    console.log(formData);
    // new Blob()
    dispatch({ type: "isloading", payload: true });
    const r = await axios({
      method: "POST",
      url: Tools.getApiAddress("goods_import"),
      data: formData,
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        SYSTOKEN: Cookies.get("token"),'ClientSource':'web',
      },
    });
    if (r.data.statusCode == 200) {
      message.success("导入成功");
      dispatch({ type: "isloading", payload: false });
    } else {
      message.error(r.data.returnMessage || "导入失败");
      dispatch({ type: "isloading", payload: false });
    }
    inputRef.current.value = "";
    onSearch(1);
  };
  const [isModalVisible, isModalFunc] = useBoolean(false);
  const [isAsyncData, asyncDataFunc] = useBoolean(false);
  const { state, dispatch } = useContext(FileStore);
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);

  const action = fileAction;
  const [menuButton, setData] = useState([]);
  useEffect(() => {
    dispatch({ type: "isloading", payload: false });
    (async function () {
      let r = await new AuthLogic().selButton(viewPower.viewPower);
      if (r && r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          r[i] = r[i].split("_")[r[i].split("_").length - 1];
        }
      }
      setData(r);
    })();
    return () => {
      dispatch({ type: "isloading", payload: false });
    };
  }, []);
  let obj: any = {};
  if (menuButton.indexOf("VIEW") != -1) {
    obj["see"] = (v,goodsSource) => {
      formParams.id = v;
      formParams.method = "Preview";

      createFunc.setTrue();
    };
  }
  if (menuButton.indexOf("UPDATE") != -1) {
    obj["edit"] = (v,goodsSource) => {
      formParams.id = v;
      formParams.method = "Edit";
      if(goodsSource == 2){
        message.warning('采购商品不能修改')
      }else{
        createFunc.setTrue();
      }
    };
  }
  if (menuButton.indexOf("DELETE") != -1) {
    obj["del"] = (v, goodsSource) => {
      formParams.id = v;
      if(goodsSource == 2){
        message.warning('采购商品不能删除')
      }else{
        confirmFunc.setTrue();
      }
    };
  }
  // const ctv = control(obj)
  const columns: ColumnsType = [
    {
      title: "操作",
      // dataIndex: "id",
      key: "1",
      align: "center",
      width: Control.width(3),
      render: Control.render(
        (v) => <See click={() => obj.see(v.id, v.goodsSource)} />,
        (v) => <Edit click={() => obj.edit(v.id, v.goodsSource)} />,
        (v) => <Del click={() => obj.del(v.id, v.goodsSource)} />
      ),
    }, //第一列商品操作
    {
      title: "商品图片",
      // dataIndex: "goodsImage",
      key: "2",
      align: "center",
      render: (v) => (
        <div
          style={{
            width: 60,
            height: 60,
            // backgroundColor: "#fefaeb",
            margin: ".52rem auto",
            cursor: "pointer",
          }}
          onClick={() => {
            imgParams.url = v.goodsImage ? Tools.getImageAddress(v.goodsImage, v.goodsSource) : img_def;
            isModalFunc.setTrue();
          }}
        >
          <img
            src={v.goodsImage ? Tools.getImageAddress(v.goodsImage, v.goodsSource) : img_def}
            width={60}
            height={60}
            alt=""
          />
        </div>
      ),
    },
    {
      title: "商品名称",
      dataIndex: "goodsName",
      key: "3",
      align: "center",
    },
    {
      title: "商品编码",
      dataIndex: "goodsNo",
      key: "4",
      align: "center",
    },
    {
      title: "商品分类",
      dataIndex: "goodsClassify",
      key: "5",
      align: "center",
    },
    {
      title: "基础单位",
      dataIndex: "unit",
      key: "6",
      align: "center",
      render: (v) => <Tag>{v}</Tag>,
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "7",
      align: "center",
      render: (v) => (
        <Tag color={1 === v ? "success" : "error"}>
          {1 === v ? "正常" : "停售"}
        </Tag>
      ),
    },
    {
      title: "商品来源",
      dataIndex: "goodsSource",
      key: "8",
      align: "center",
      render: (v) => (
        <>
          {v == 1 && <Tag color="blue">自定义</Tag>}
          {v == 2 && <Tag color="red">仓库采购</Tag>}
        </>
      ),
    },
  ];

  useRequest(() => action.init({ dispatch }));

  const onSearch = async (pageIndex) => {
    await action.loadPage({
      pageIndex: pageIndex,
      pageSize: defualtPageSize(),
      dispatch: tableDispatch,
      search: state.search,
    });
  };

  return (
    <>
      <Spin
        tip="导入中..."
        spinning={state.isLoading}
        size="large"
        wrapperClassName="isloading"
      >
        {/* 搜索 */}
        <Search>
          <Input
            maxLength={20}
            allowClear
            placeholder="请输入商品名称、商品编码"
            onChange={(e) =>
              action.setCondition("Keywords", e.target.value, dispatch)
            }
          />
          <input type="button" value="快速查找" onClick={() => onSearch(1)} />

          <TipsGroup>
            <input
              type="button"
              value="高级查找"
              onClick={() => searchFunc.toggle()}
            />
            <Tips width="45rem" display={searchState}>
              <FileSearch
                onSearch={(...arg: [any]) => {
                  onSearch(...arg);
                  searchFunc.setFalse();
                }}
              />
            </Tips>
            <Mask visible={searchState} onClick={() => searchFunc.setFalse()} />
          </TipsGroup>
        </Search>
        {/* 新建和批量删除 */}
        <ButtonPanel>
          {menuButton.indexOf("ADD") != -1 && (
            <Button
              onClick={() => {
                formParams.id = "";
                formParams.method = "Create";

                createFunc.setTrue();
              }}
            >
              新建商品
            </Button>
          )}
          {menuButton.indexOf("DELETE") != -1 && (
            <Button
              onClick={async () => {
                if (!tableState.selectList.length) {
                  message.warning("请选择删除项");
                } else {
                  confirmAllFunc.setTrue();
                }
              }}
            >
              批量删除
            </Button>
          )}
          {menuButton.indexOf("UPALL") != -1 && (
            <Button
              onClick={async () => {
                if (!tableState.selectList.length) {
                  message.warning("请选择商品");
                }else if(tableState.selectList.filter(item=>item.goodsSource == 2).length > 0) {
                  message.warning('存在采购商品，继续操作')
                }  else {
                  upAllFunc.setTrue();
                }
              }}
            >
              批量上架
            </Button>
          )}
          {menuButton.indexOf("UPALL") != -1 && (
            <Button
              onClick={async () => {
                if (!tableState.selectList.length) {
                  message.warning("请选择商品");
                }else if(tableState.selectList.filter(item=>item.goodsSource == 2).length > 0) {
                  message.warning('存在采购商品，继续操作')
                } else {
                  downAllFunc.setTrue();
                }
              }}
            >
              批量下架
            </Button>
          )}
          {/* <Button onClick={() => asyncDataFunc.setTrue()}>同步默认商品</Button> */}
          <Button onClick={() => downloadFunc.setTrue()}>下载导入模板</Button>
          <Button onClick={() => inputRef.current.click()}>导入商品</Button>
          <input
            ref={inputRef}
            type="file"
            onChange={onChange}
            style={{ display: "none" }}
          />
        </ButtonPanel>

        {/* 数据展示 从DataTableStore获取datalist，给datasource 然后展示*/}
        <DataTable
          columns={columns}
          action={action}
          onLoad={async () => {
            await onSearch(1);
            Progress.done();
          }}
          onChange={(pageIndex) => {
            onSearch(pageIndex);
          }}
          scroll={{
            scrollToFirstRowOnChange: true,
            autoY: true,
            y: autoRem(45),
          }}
          loading={tableState.loading}
          selectColumn={true}
          rowSelection={{
            getCheckboxProps: (record) => ({
              disabled: record.goodsSource == 2, // Column configuration not to be checked
            }),
          }}
        />

        {createState && (
          <FormStoreProvider>
            <Form
              close={(hasUpdate) => {
                if (hasUpdate) {
                  onSearch(tableState.current);
                }

                createFunc.setFalse();
              }}
              action={formAction}
              id={formParams.id}
              method={formParams.method}
            />
          </FormStoreProvider>
        )}

        <ConfirmModal
          visible={confirmState}
          title="温馨提示"
          subTitle="确认删除？"
          message="删除之后信息无法恢复，请慎重考虑"
          close={async (r) => {
            if (r) {
              await action.delRow(formParams.id);

              onSearch(tableState.current);
            }
            confirmFunc.setFalse();
          }}
        />

        <ConfirmModal
          visible={confirmAllState}
          title="温馨提示"
          subTitle="确认删除？"
          message="删除之后信息无法恢复，请慎重考虑"
          close={async (r) => {
            if (r) {
              await action.delSelectList({
                dispatch,
                selectList: tableState.selectList,
              });

              action.clearSelectedRowKeys({ dispatch: tableDispatch });

              onSearch(tableState.current);
            }
            confirmAllFunc.setFalse();
          }}
        />
        <ConfirmModal
          visible={comfirmUpAll}
          title="温馨提示"
          subTitle="确认上架？"
          message="选中商品将更改为上架状态"
          close={async (r) => {
            if (r) {
              let goods = [];
              tableState.selectList.map((s) => {
                goods.push(s.id);
              });
              await action.updateState({ state: 1, goods });
              action.clearSelectedRowKeys({ dispatch: tableDispatch });
              onSearch(tableState.current);
            }
            upAllFunc.setFalse();
          }}
        />
        <ConfirmModal
          visible={comfirmDownAll}
          title="温馨提示"
          subTitle="确认下架？"
          message="选中商品将更改为下架状态"
          close={async (r) => {
            if (r) {
              let goods = [];
              tableState.selectList.map((s) => {
                goods.push(s.id);
              });
              await action.updateState({ state: 2, goods });
              action.clearSelectedRowKeys({ dispatch: tableDispatch });
              onSearch(tableState.current);
            }
            downAllFunc.setFalse();
          }}
        />
        <ConfirmModal
          visible={isAsyncData}
          title="温馨提示"
          subTitle="确认同步运营平台数据？"
          message="同步之后信息无法恢复，请慎重考虑"
          close={async (r) => {
            if (r) {
              await action.AsyncData();
              onSearch(1);
            }
            asyncDataFunc.setFalse();
          }}
        />
        <ConfirmModal
          visible={downloadState}
          title="温馨提示"
          subTitle="确认下载导入模板？"
          message=""
          close={async (r) => {
            if (r) {
              // await action.downloadMode();
              // onSearch(1);
              window.open(Tools.getApiAddress("goods_module"), "_self");
            }
            downloadFunc.setFalse();
          }}
        />
        <ConfirmModal
          visible={importState}
          title="温馨提示"
          subTitle="确认导入商品？"
          message="请使用导入模板填写商品信息，再导入"
          close={async (r) => {
            if (r) {
              // await action.AsyncData();
              // onSearch(1);
              inputRef.current.click();
            }
            importFunc.setFalse();
          }}
        />

        <Modal
          className={styles.root}
          visible={isModalVisible}
          onOk={() => isModalFunc.setFalse()}
          onCancel={() => isModalFunc.setFalse()}
          centered
          width={"auto"}
        >
          <img src={imgParams.url} />
        </Modal>
      </Spin>
    </>
  );
}
