import { createContext, useReducer } from "react";
import { Tools } from "../../../../config";
class FormData {
  oneProfit: any;
  twoProfit: any;
  premium: any;
  minWithdrawalsMoney: any;
  backGroundPath: any;
  id: string;
  fileList: any[];
}

type Dispatch = { type; payload };

interface IState {
  formData: FormData;
  menuList: any[];
  authxtList: any[];
}

const initialArgs = {
  formData: new FormData(),
  menuList: [],
  authxtList: [],
};

export type FormAttribute =
  | "OneProfit"
  | "Premium"
  | "TwoProfit"
  | "MinWithdrawalsMoney"
  | "BackGroundPath"
  | "Id";

interface IContextProps {
  state: IState;
  dispatch: ({ type }: Dispatch) => void;
}

export const FormStore = createContext<IContextProps>(null);

function reducer(state: IState, { type, payload }: Dispatch) {
  switch (type) {
    // 菜单列表
    case "MenuList":
      return {
        ...state,
        menuList: payload,
      };
    // 菜单列表
    case "AuthxtList":
      return {
        ...state,
        authxtList: payload,
      };
    // 填充表单
    case "SetForm": {
      const data = payload;
      const form = new FormData();

      {
        const {
          oneProfit,
          twoProfit,
          premium,
          minWithdrawalsMoney,
          backGroundPath,
          id,
        } = data;
        form.oneProfit = oneProfit;
        form.premium = premium;
        form.backGroundPath = backGroundPath;
        form.twoProfit = twoProfit;
        form.minWithdrawalsMoney = minWithdrawalsMoney;
        form.id = id;
        form.fileList = [
          {
            uid: "-1",
            name: "img.png",
            status: "done",
            url: Tools.getImageAddress(backGroundPath),
          },
        ];
      }

      return {
        ...state,
        formData: form,
      };
    }
    // 设置表单
    case "Change_FormData": {
      const { attribute, value } = payload,
        props: any = {};

      const key: FormAttribute = attribute;
      let attri = "";
      switch (key) {
        case "OneProfit":
          attri = "oneProfit";
          break;
        case "MinWithdrawalsMoney":
          attri = "minWithdrawalsMoney";
          break;
        case "TwoProfit":
          attri = "twoProfit";
          break;
        case "BackGroundPath":
          attri = "backGroundPath";
          state.formData.fileList = [
            {
              uid: "-1",
              name: "img.png",
              status: "done",
              url: Tools.getImageAddress(value),
            },
          ];
          break;
        case "Premium":
          attri = "premium";
          break;
        case "Id":
          attri = "id";
          break;
        default:
          break;
      }

      props[attri] = value;

      return {
        ...state,
        formData: {
          ...state.formData,
          ...props,
        },
      };
    }
    default:
      return state;
  }
}

export function FormStoreDistributionProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialArgs);

  const v = { state, dispatch };
  return <FormStore.Provider value={v}>{props.children}</FormStore.Provider>;
}
