import message from "antd/lib/message"
import { DataTableAction } from "../../../../component/table/action/data_table_action"
import { CommissionRuleLogic } from "../../../../logic/commissionrule_logic";

class FileAction extends DataTableAction {

    async delRow(id) {
        // const r = await CommissionRuleLogic.StopMarkPresasle(id);

        // if (r.data.statusCode == 200) {
        //     message.success("删除成功")
        // } else {
        //     message.error("删除失败")
        // }
    }

    delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {
        //
    }

    async loadPage({ dispatch, pageIndex, pageSize, search }) {
        this.setLoading({dispatch,loading: true})
        const {  seacherKey } = search;
        const params: any = {           
            "seacherKey": seacherKey,
            "pageIndex": pageIndex,
            "pageSize": pageSize,
            "orderBy": '',
            "sort": '',
        }

        // 档案列表
        const r = await new CommissionRuleLogic().getList(params);

        if (r&&r.data&&r.data.statusCode == 200) {
            const dataList = r.data.data.dataSource || [];
            //
            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount || 0, dataList: dataList, pageSize });
        }
            this.setLoading({dispatch,loading: false})
        

    }

    setCondition({ dispatch, key, value }) {
        const params = {
        }
        params[key] = value;
        //
        dispatch({ type: "SetSearch", payload: params })
    }

    async changeStatus(id, status){
        const r = await new CommissionRuleLogic().changeStatus(id, status)
        if(r&&r.data&&r.data.statusCode == 200){
            
        }
        else{
            message.error(r.data.returnMessage)
        }
    }
}
export const fileAction = new FileAction();