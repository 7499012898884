import moment from "moment";
import { createContext, useContext, useReducer } from "react";
import { GoodsEntity } from "../../../../entity/goods_entity";
import { ShopEntity } from "../../../../entity/shop_entity";
import { UnitLogic } from "../../../../logic/unit_logic";

export const CostFormStore = createContext<IContextProps>(null);

interface IContextProps {
  state: IState;
  dispatch: ({ type }: Dispatch) => void;
}

type Dispatch = { type; payload };

class Search {
  keyword: string;
  areaId: string;
  statusId: string;
  typeId: string;
}
export class FormData {
  keyword: string;
  shop = new ShopEntity();
  remark: string;
  userName: string;
  inStockUnitPrice: number;
  date: string = moment().format("YYYY-MM-DD HH:mm:ss");
  latestPrice: number;
  reasonId: number = 1;
  typeId: string;
}

export class TableItem {
  serial: number;
  goodsId: string;
  id: string;
  goodsNo: string;
  goodsCode: string;
  goodsName: string;
  unitName: string;
  goodsUnitID: string;
  // custom
  latestPrice: number;
  beforePrice: number;
  number: number;
  diffPrice: number;
  /** 规格列表 */
  goodsSpecifications: any[] = [];

  storageBatchNumber: string

  constructor(data) {
    {
      const {
        putNewPrice,
        goodsID,
        id,
        goodsNo,
        goodsName,
        sumNumber,
        unitName,
        goodsUnitID,
        inventoryPrice,
        afterPrice,
        beforePrice,
        difference,
        goodsUnit,
        invckCount,
        storageBatchNumber
      } = data;
      this.id = id;
      this.goodsId = goodsID || id;
      this.goodsCode = goodsNo;
      this.goodsName = goodsName;
      this.unitName = unitName;
      this.goodsUnitID = goodsUnitID || goodsUnit;
      this.beforePrice = beforePrice || inventoryPrice || putNewPrice || 0;
      this.number = sumNumber || invckCount;
      this.goodsNo = data.goodsNo;

      this.goodsSpecifications = data.goodsSpecifications;
      this.storageBatchNumber = storageBatchNumber
      // init custom
      this.latestPrice = afterPrice || 0;

      this.countLatestInventoryPrice(this.latestPrice);
    }
  }
  countLatestInventoryPrice(value: number) {
    // this.latestPrice = Number(value);
    this.diffPrice = Number(
      (this.latestPrice * this.number - this.beforePrice * this.number).toFixed(
        2
      )
    );
  }
}

export class IState {
  searchList: any[];
  formData: FormData;
  search: Search;
  tableList: TableItem[];
  shoplist: any[];
  code: string;
}

const initialArgs: IState = {
  searchList: [],
  formData: new FormData(),
  search: new Search(),
  tableList: [],
  shoplist: [],
  code: "",
};

function reducer(state: IState, { type, payload }: Dispatch): IState {
  switch (type) {
    case "InputCode":
      state.code = payload;
      console.log(payload);
      return {
        ...state,
      };
    case "LoadShop":
      return {
        ...state,
        shoplist: payload,
      };
    /** 更新 */
    case "update":
      return {
        ...state,
      };

    case "SetSearchList":
      return {
        ...state,
        searchList: payload,
      };
    case "SetFormData": {
      return {
        ...state,
        formData: {
          ...state.formData,
          ...payload,
        },
      };
    }
    case "SetForm": {
      const { shopID, shopName, remark, goodslist, reason } = payload;
      let formData = new FormData();
      formData = state.formData;

      // shop
      const shopItem = new ShopEntity();
      shopItem.id = shopID;
      shopItem.shopName = shopName;

      formData.shop = shopItem;
      formData.remark = remark;
      formData.reasonId = reason;

      const tableList =goodslist.length > 0 ? goodslist.map((v,i) => {
        const item = new TableItem(v);
        item.beforePrice = v.beforePrice;
        item.number = v.sumNumber;
        item.unitName = v.unitName;
        item.serial = i
        item.countLatestInventoryPrice(v.beforePrice);
        return item;
      }): [];

      return {
        ...state,
        formData,
        tableList: tableList.length> 0 ?[...tableList]: [],
      };
    }
    case "AddTableItem": {
      const list = state.tableList || [];

      // 去重
      if (list.every((v, i) => v.storageBatchNumber !== payload.storageBatchNumber)) {
        list.push(payload);

        list.length > 0 && list.forEach((v, i) => (v.serial = i));

        return {
          ...state,
          tableList: [...list],
        };
      }
      return state;
    }
    case "DelTableItem": {
      const list = state.tableList || [];

      list.splice(payload, 1);
      list.length > 0 && list.forEach((v, i) => (v.serial = i));
      return {
        ...state,
        tableList: [...list],
      };
    }
    case "ChangeTableItem": {
      const { index, key, value } = payload;

      const list = state.tableList || [];

      const item = list[index];

      if (isNaN(value)) {
        item.countLatestInventoryPrice(0);
        item[key] = "";
      } else {
        item[key] = Number(value);
        if (Number(value) < 0) {
          item[key] = 0;
        }

        item.countLatestInventoryPrice(value);
      }

      return {
        ...state,
        tableList: [...list],
      };
    }
    case "ClearTableList":
      return {
        ...state,
        tableList: [],
      };
    default:
      return state;
  }
}

export function CostFormStoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialArgs);

  const v = { state, dispatch };
  return (
    <CostFormStore.Provider value={v}>{props.children}</CostFormStore.Provider>
  );
}
