import { ShiftChangeLogic } from "../../../../logic/shift_change_logic"
import momnet from 'moment'
// import axios from "axios";
import { Tools, axios } from "../../../../config";
import Cookies from "js-cookie";
import { effeative } from "../../../../logic/common_logic";
import moment from "moment";
import { message } from "antd";

type Init = {
    state;
    dispatch;
    form;
    id: string;
    method: string;
    item?:Object
}
interface InitBasic {
    dispatch;
    state;
}
interface InitEdit {
    state;
    dispatch;
    form;
    id;
    item;
}
export class FormAction {
     
    async init({ state, dispatch, id, method, form, item }: Init) {
        await this.initBasic({ dispatch, state });

        if ("Create" !== method) {
            await this.initEdit({ dispatch, state, id, form, item })
        }

        //初始化
        dispatch({ type: "init", payload: method });
    }
    private async initBasic({ dispatch, state }: InitBasic) {
         // 当前登录人店铺人员
    const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("shop_user_list"),
            params: {},
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            }
        }))

    if (r.data.statusCode == 200) {
        this.setFormData({dispatch, key:'peopleList', value: r.data.data})
    }
    }
    private async initEdit({ dispatch, id, form, item }: InitEdit) {
        const {turnover,addUID, orderCount, reserves, sales, memberRecharge, beginTime, endTime,detail, bankCardMoney, wxMoney, cashMoney, aliMoney, type} = item
        dispatch({ type: "SetForm", payload: {} });
        this.setValue({dispatch, key:'detail', value: detail || []})
        dispatch({type:'IfInit', payload: true})
        let t1 = moment( beginTime, 'YYYY-MM-DD HH:mm:ss')
        let t2 = moment( endTime,'YYYY-MM-DD HH:mm:ss')
        form.setFieldsValue({
            'turnover': turnover,
            'orderCount': orderCount,
            'reserves': reserves,
            'sales': sales,
            'memberRecharge': memberRecharge,
            // 'begin': moment('YYYY-MM-DD HH:mm:ss', beginTime),
            // 'end': moment('YYYY-MM-DD HH:mm:ss', endTime),
            'begin':t1 ,
            'end': t2 ,
            'detail': detail || [],
            'bankCardMoney': '',
            'wxMoney': '',
            'cashMoney' : '',
            'aliMoney': '',
            // 'type': 0,
            'userid': addUID,
        })

    }
    private paddingData( data){
        const {turnover,userid, orderCount, reserves, sales, memberRecharge, begin, end,detail, bankCardMoney, wxMoney, cashMoney, aliMoney, type} = data
        return{
            turnover: turnover,
            orderCount: orderCount,
            reserves: reserves,
            sales: sales,
            memberRecharge: memberRecharge,
            beginTime: begin.format("yyyy-MM-DD HH:mm:ss"),
            endTime: end.format("yyyy-MM-DD HH:mm:ss"),
           detail: detail,
           userid: userid

            
        }

    }
    async addSubmit({state, form}){
        const detail = state.detail
       let data = form.getFieldsValue()
       let params = this.paddingData(data)
      const r = await  new ShiftChangeLogic().add(params)
      if(r.data.statusCode == 200) {
          message.success('补班成功')
      }else{
          message.error('保存失败')
      }
  
    }
    updSubmit({state}){

    }
    reset({dispatch, form}){
        dispatch({type:'Reset'})
        form.resetFields();
    }
    setValue({dispatch, key, value}){
        const params = {
        }
        params[key] = value;
        dispatch({type: 'setValue', payload: params})
    }
    setFormData({dispatch, key, value}){
        const params = {
        }
        params[key] = value;

        dispatch({type:'setFormData',payload: params})
    }
   async getDetail(state, form, dispatch){
       const {userid, begin,end}= form.getFieldsValue()
        let param = {
            userid: userid,
            begin: begin.format("yyyy-MM-DD HH:mm:ss"),
            end:  end.format("yyyy-MM-DD HH:mm:ss")
        }
      const r =   effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress('shift_change_getdetail'),
            params:param,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            }
        }))
        if(r.data.statusCode == 200){
            const res = r.data.data
            const {turnover, orderCount, reserves, sales, memberRecharge, beginTime, detail} = res
          
            if(beginTime == param.begin){
                this.setValue({dispatch, key:'detail', value: detail})
                this.setFormData({dispatch, key:'beginTime', value: beginTime})
                dispatch({type:'IfInit', payload: true})
                form.setFieldsValue({
                    'turnover': turnover || 0,
                    'orderCount': orderCount || 0,
                    'reserves': reserves || 0,
                    'sales': sales || 0,
                    'memberRecharge': memberRecharge || 0,    
                    'begin': moment(param.begin,'YYYY-MM-DD HH:mm:ss')||null,
                    'end':  moment(param.end, 'YYYY-MM-DD HH:mm:ss')||null,
                    'detail': detail
                })
            }else{    
                message.error('所选时间内已交班，请重新选择时间')
                form.setFieldsValue({
                    'begin': moment(beginTime, 'YYYY-MM-DD HH:mm:ss'),
                    'end': null
                })            
                
            }
           
        }
    }
}
export const formAction = new FormAction();
