export function getStoresProfitsStatsTK(data): {
    title: string;
    sameGrade?;
    sonItem: {
        title: string;
        number: number;
    }[];
}[] {
    //
    return [
        {
            title: '营业收入',
            sonItem: [
                {
                    title: '销售收入',
                    number: data.saleAmount,
                },
                {
                    title: '充值收入',
                    number: data.storedAmount,
                },
            ],
        },
        {
            title: '预付款抵扣',
            sonItem: [
                {
                    title: '会员钱包支付',
                    number: data.walletAmount,
                },
            ],
        },
        {
            title: '门店进货款',
            sonItem: [
                {
                    title: '入库成本',
                    number: data.putStorageAmount,
                },
            ],
        },
        {
            title: '损耗成本',
            sonItem: [
                {
                    title: '损耗成本',
                    number: data.lossAmount,
                },
            ],
        },
        {
            title: '订单收入',
           sameGrade:[ {
            title: '支付宝',
            number: data.aliPay,
            },
            {
                title: '微信',
                number: data.weChatPay,
            },
            {
                title: '银联',
                number: data.unionPay,
            },
            {
                title: '现金',
                number: data.cashPay,
            }, {
                title: '钱包',
                number: data.walletPay,
            },{
                title: '找零',
                number: 0 - data.smallChange ,
            },],
        sonItem:[]
            // sonItem: [ {
            //     title: '支付宝',
            //     number: data.aliPay,
            // },
            // {
            //     title: '微信',
            //     number: data.weChatPay,
            // },
            // {
            //     title: '支付宝',
            //     number: data.aliPay,
            // },
            // {
            //     title: '支付宝',
            //     number: data.aliPay,
            // },],
        }
        
    ];
}