import { TabPanel, TabsStyle, TabList, Tab } from "../../component/react-tabs";
import { FileStore, FileStoreProvider } from "./store";
import { Sale, FunnelPart, LinePart } from "./view/sale";
import { Pie } from "./view/pie";
import { Search } from "./view/search";
import { fileAction } from "./action";
import { useContext, useEffect } from "react";
import { useRequest } from "ahooks";
function Report() {
  const action = fileAction;
  const { state, dispatch } = useContext(FileStore);
  useRequest(async () => await action.init({ state, dispatch }));
  useEffect(() => {}, [state.condition.dateType, state.condition.startTime]);
  return (
    <div style={{ margin: `0 2rem` }}>
      <Search />
      <Sale
        orderTypePie={state.orderTypePie}
        classSalePie={state.classSalePie}
        payWayPie={state.payWayPie}
      ></Sale>
      <FunnelPart
        list={state.goodsTop}
        membersaleList={state.memberSaleTop}
        memberStoreList={state.memberStoreTop}
      />
      <LinePart
        newMemberLine={state.newMemberLine}
        memberIncomeExpend={state.memberIncomeExpendLine}
        refundList={state.refundListLine}
        saleList={state.saleListLine}
      />
    </div>
  );
}
/**图形报表 */
export function Analysis() {
  return (
    <>
      {/* <TabsStyle  style="Full">
    <TabList>
                      <Tab>
                      <span>图形报表</span>
                      <hr />
                    </Tab>
                   
                    <hr/>
                </TabList>
    <TabPanel> */}
      <FileStoreProvider>
        <Report />
      </FileStoreProvider>
      {/* </TabPanel>
    </TabsStyle> */}
    </>
  );
}
