import React, { useContext, useState } from "react";
import {
  Button,
  Cascader,
  TreeSelect,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
} from "antd";
import styles from "../../../style/file_search.module.scss";
import { FileRunwaterStore } from "../../../store/file/file_runwater_store";
import {
  fileRunwaterAction,
  FileRunwaterAction,
} from "../../../action/file/file_runwater_action";
import { useBoolean } from "ahooks";
import { ChooseShop } from "../../../../../component/choose-shop";
import { ChooseGoods } from "../../../../../component/choose-goods";
import moment from "moment";

const { Option } = Select;
const { RangePicker } = DatePicker;

export function FileRunwaterSearch({ onSearch }) {
  const [shopState, shopFunc] = useBoolean(false);
  const [goodsState, goodsFunc] = useBoolean(false);
  const { state, dispatch } = useContext(FileRunwaterStore);
  const action = fileRunwaterAction;
  const onangeChange = () => {
    action.setCondition({ dispatch, key: "shopName", value: "" });
    action.setCondition({ dispatch, key: "shop", value: "" });
  };
  return (
    <div>
      <Form layout="horizontal">
        <div className={styles.row}>
          <div className={styles.cell}>
            <Form.Item label="选择商品">
              <Input
                style={{ width: 175 }}
                value={
                  state.search.goods
                    ? state.search.goods.map((v) => v.goodsName).toString()
                    : ""
                }
                placeholder="请选择"
                onClick={() => goodsFunc.setTrue()}
              />
            </Form.Item>
          </div>
          <div className={styles.cell}>
            <Form.Item label="选择门店">
              <Input
                style={{ width: 175 }}
                value={state.search.shop ? state.search.shop[0].shopName : ""}
                placeholder="请选择"
                onClick={() => shopFunc.setTrue()}
              />
              <span
                className="ant-input-suffix"
                style={{ marginLeft: "-24px", zIndex: 99 }}
                onClick={onangeChange}
              >
                <span
                  role="button"
                  aria-label="close-circle"
                  className="anticon anticon-close-circle ant-input-clear-icon"
                >
                  <svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="close-circle"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 01-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"></path>
                  </svg>
                </span>
              </span>
            </Form.Item>
          </div>

          <div className={styles.cell}>
            <Form.Item label="商品分类">
              <TreeSelect
                style={{ width: 175 }}
                showCheckedStrategy={TreeSelect.SHOW_PARENT}
                dropdownMatchSelectWidth={false}
                defaultValue={state.search.classesId}
                value={state.search.classesId}
                treeCheckable={true}
                treeData={state.classesList}
                onChange={(v) => {
                  action.setCondition({
                    dispatch,
                    key: "classesId",
                    value: v.length > 0 ? [v[v.length - 1]] : [],
                  });
                }}
                placeholder="选择商品分类"
              />
              {/* <Cascader style={{ width: 175 }} options={state.classesList} placeholder="请选择"
                                onChange={(v) => action.setCondition({ dispatch, key: "classesId", value: v[v.length - 1] })}
                            /> */}
            </Form.Item>
          </div>
          <div className={styles.cell}>
            <Form.Item label="库存类型">
              <Select
                style={{ width: 175 }}
                onChange={(v) =>
                  action.setCondition({ dispatch, key: "typeId", value: v })
                }
                defaultValue={-1}
                showSearch
                filterOption={(input:any, option:any) => {
                  return option.children.indexOf(input) >= 0;
                }}
              >
                <Option value={-1}>全部</Option>
                <Option value="1">出库</Option>
                <Option value="2">入库</Option>
                <Option value="3">报损</Option>
                <Option value="4">报溢</Option>
              </Select>
            </Form.Item>
          </div>
          <div className={styles.cell}>
            <Form.Item label="选择日期">
              <RangePicker
                style={{ width: 432 }}
                onChange={(e) => {
                  action.setCondition({
                    dispatch,
                    key: "startDate",
                    value: e && e.length > 0 && e[0].format("yyyy-MM-DD"),
                  });
                  action.setCondition({
                    dispatch,
                    key: "endDate",
                    value:
                      (e && e.length > 0 && e[1].format("yyyy-MM-DD")) || "",
                  });
                }}
              />
            </Form.Item>
          </div>
        </div>
        <div className={styles.row}>
          <Button type="primary" danger onClick={() => onSearch(1)}>
            搜 索
          </Button>
        </div>
      </Form>

      <ChooseShop
        visibleModal={shopState}
        close={(status, r) => {
          if (r) {
            action.setCondition({ dispatch, key: "shop", value: r });
          }
          shopFunc.setFalse();
        }}
      />
      <ChooseGoods
        visibleModal={goodsState}
        close={(status, r) => {
          if (r) {
            action.setCondition({ dispatch, key: "goods", value: r });
          }
          goodsFunc.setFalse();
        }}
      />
    </div>
  );
}
