import message from "antd/lib/message"
// import axios from "axios"
import Cookies from "js-cookie"
import { DataTableAction } from "../../../../component/table/action/data_table_action"
import { Tools, axios } from "../../../../config"
import { CheckLogic } from "../../../../logic/check_logic"

class InventoryAction extends DataTableAction {
    async delRow(id) {
        const r = await new CheckLogic().delData(id);

        if (r.data.statusCode == 200) {
            message.success("删除成功")
        }
    }
    delSelectList({ selectList }: { dispatch: any; selectList: any; }) {
        selectList.map(async ({ id }) => {
            const r = await axios({
                method: "GET",
                url: Tools.getApiAddress("shop_delete"),
                params: {
                    shopId: id
                },
                headers: {
                    SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
                },
            })

            if (r.data.statusCode == 200) {
                message.success("删除成功")
            }
        })
    }
    async loadPage({ dispatch, pageIndex, pageSize, search }) {
        this.setLoading({dispatch, loading:true})
        const { keywords, statusId, shop, goods, startDate, endDate } = search;

        const params: any = {
            "pageIndex": pageIndex,
            "pageSize": pageSize
        }
        if (keywords) {
            params.keyWords = keywords;
        }
        if (startDate && endDate) {
            params.addDateTimeStart = startDate;
            params.addDateTimeEnd = endDate;
        }
        if (undefined != statusId) {
            params.status = statusId;
        }
        if (statusId == -1) {
            delete params.status;
        }
        if (shop && shop.length) {
            params.shopID = shop[0].id;
        }
        if (goods && goods.length) {
            params.goodsID = goods.map(v => v.id);
        }

        // 档案列表
        const r = await new CheckLogic().selList(params);

        if (r.data.statusCode == 200) {
            const dataList = r.data.data.dataSource;

            const list = dataList.map((v, i) => {
                v.key = i + 1;
                return v;
            })

            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: list, pageSize, loading: false });
        }
        else{
            this.setLoading({dispatch, loading: false})
        }
    }
    setCondition({ dispatch, key, value }) {
        const params = {
        }
        params[key] = value;

        dispatch({ type: "SetSearch", payload: params })
    }
}
export const inventoryAction = new InventoryAction();