import moment from "moment";
import { createContext, useContext, useReducer } from "react";

export const SeckillFormStore = createContext<IContextProps>(null);

interface IContextProps {
  state: IState;
  dispatch: ({ type }: Dispatch) => void;
}

type Dispatch = { type; payload };

class Search {
  keyword: string;
  areaId: string;
  statusId: string;
  typeId: string;
}
export class FormData {
  snappedName: string;
  isMemberClass: number = 2;
  beginTime: "";
  endTime: "";
  shopName: "";
  memberClassList: [];
  shopList: [];
  classList: [];
  goodsList: [];
}
export class TableItem {
  serial: number;
  goodsId: string;
  goodsCode: string;
  goodsName: string;
  inStockUnit: string;
  inStockUnitId: string;
  /**
   * 入库单价
   */
  inStockUnitPrice: number;
  inStockNumber: number;
  snappedCount: number;
  snappedPrice: number;
  goodsUnitName: number;
  goodsClassName: string;
  singleCount: number;
  /** 规格列表 */
  goodsSpecifications: any[];
  storePrice: number;
}
interface IState {
  searchList: any[];
  formData: FormData;
  search: Search;
  tableList: TableItem[];
  TagList: any[];
  shoplist: any[];
}

const initialArgs: IState = {
  searchList: [],
  formData: new FormData(),
  search: new Search(),
  tableList: [],
  TagList: [],
  shoplist: [],
};

function reducer(state: IState, { type, payload }: Dispatch) {
  switch (type) {
    case "LoadShop":
      return {
        ...state,
        shoplist: payload,
      };
    /** 更新 */
    case "update":
      return {
        ...state,
      };

    // 等级列表
    case "LoadTag":
      return {
        ...state,
        TagList: payload,
      };
    case "SetSearchList":
      return {
        ...state,
        searchList: payload,
      };
    case "SetFormData": {
      return {
        ...state,
        formData: {
          ...state.formData,
          ...payload,
        },
      };
    }
    case "SetForm": {
      const {
        snappedName,
        isMemberClass,
        beginTime,
        endTime,
        memberClassList,
        classList,
        goodsList,
        shopList,
        shopName,
      } = payload;
      const item = new FormData();

      item.snappedName = snappedName;
      item.isMemberClass = isMemberClass;
      item.beginTime = beginTime != null ? beginTime : "";
      item.endTime = endTime != null ? endTime : "";
      item.memberClassList = memberClassList;
      item.classList = classList;
      item.shopList = shopList;
      item.shopName = shopName;
      const list = [];
      goodsList.forEach(
        ({
          goodsID,
          goodsNo,
          goodsName,
          putNumber,
          classesName,
          singleCount,
          snappedPrice,
          unitName,
          snappedCount,
          goodsSep,
          storePrice,
        }) => {
          const item = new TableItem();
          item.storePrice = storePrice;
          item.goodsId = goodsID;
          item.goodsCode = goodsNo;
          item.goodsName = goodsName;
          item.inStockUnitPrice = snappedPrice;
          item.goodsUnitName = unitName;
          item.inStockNumber = putNumber;
          item.snappedCount = snappedCount;
          item.snappedPrice = snappedPrice;
          item.goodsClassName = classesName;
          item.singleCount = singleCount;
          item.goodsSpecifications = goodsSep;
          list.push(item);
        }
      );
      console.log(list);
      return {
        ...state,
        formData: item,
        tableList: list,
      };
    }
    case "AddTableItem": {
      const list = state.tableList || [];

      // 去重
      if (list.every((v, i) => v.goodsId !== payload.id)) {
        const item = new TableItem();
        const {
          goodsNo,
          goodsName,
          id,
          goodsUnitID,
          storePrice,
          goodsUnitName,
          inStockUnitPrice,
          inStockNumber,
          snappedCount,
          snappedPrice,
          goodsClassName,
          singleCount,
        } = payload;
        item.storePrice = storePrice;
        item.goodsId = id;
        item.goodsCode = goodsNo;
        item.goodsName = goodsName;
        item.inStockUnitId = goodsUnitID;
        item.goodsUnitName = goodsUnitName;
        item.inStockNumber = inStockNumber;
        item.snappedCount = snappedCount;
        item.inStockUnitPrice = inStockUnitPrice;
        item.snappedPrice = snappedPrice;
        item.goodsClassName = goodsClassName;
        item.singleCount = singleCount;
        item.goodsSpecifications = payload.goodsSpecifications;

        list.push(item);

        list.forEach((v, i) => (v.serial = i));

        return {
          ...state,
          tableList: [...list],
        };
      }
      return state;
    }
    case "DelTableItem": {
      const list = state.tableList || [];

      list.splice(payload, 1);

      return {
        ...state,
        tableList: [...list],
      };
    }
    case "ChangeTableItem": {
      const { index, key, value } = payload;

      const list = state.tableList || [];

      const item = list[index];

      item[key] = Number(value);

      if (undefined == item.snappedPrice) {
        item.snappedPrice = 0;
      }
      if (undefined == item.snappedCount) {
        item.snappedCount = 0;
      }
      if (undefined == item.singleCount) {
        item.singleCount = 0;
      }

      // 秒杀库存
      if ("singleCount" == key) {
        item.singleCount = value;
      }

      // 秒杀价
      if ("snappedPrice" == key) {
        item.snappedPrice = value;
      }

      // 单人限购
      if ("singleCount" == key) {
        item.singleCount = value;
      }

      return {
        ...state,
        tableList: [...list],
      };
    }
    case "ClearTableList":
      return {
        ...state,
        tableList: [],
      };
    default:
      return state;
  }
}

export function SeckillFormStoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialArgs);

  const v = { state, dispatch };
  return (
    <SeckillFormStore.Provider value={v}>
      {props.children}
    </SeckillFormStore.Provider>
  );
}
