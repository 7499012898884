import styles from "./index.module.scss";
import { Button as AntdButton, ButtonProps } from "antd"
import { cloneElement } from "react";

interface IButton {
    children?: any;
    color?: "Red" | "Yellow" | "Green";
}
export function Button(params: IButton & ButtonProps) {
    const { children } = params;

    const rootStyle = (params.className) ? params.className : "";

    return <AntdButton {...params} {...{ className: rootStyle + " " + styles.root }} >{children}</AntdButton>
}