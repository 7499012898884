import { useBoolean, useFavicon } from "ahooks";
import Modal from "antd/lib/modal/Modal";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { ConfigApi, Tools } from "../../../config";
import styles from "../style/info.module.scss";
import { Empty} from 'antd'
// const messageList = [
//   { text: "正在进行的促销活动", num: 0 },
//   { text: "商户今日新增会员总数", num: "+5" },
//   { text: "本店今日新增会员总数", num: "+15" },
//   { text: "商品库存预警", num: "+3" },
//   { text: "商品即将过期/已过期的门店", num: "+3" },
// ]

// const concatList = [
//   { text: "电话：173233043386" },
//   { text: "座机：028-6248-6402" },
//   { text: "邮箱：306196081@qq.com" },
//   { text: "官网：http://www.7jupu.com" },
//   { text: "地址：华府大道益州国际广场1212" },
// ]

async function* fetchData() {
  {
    const r = await axios({
      method: "GET",
      url: Tools.getApiAddress("info_message").replace(
        ConfigApi.serviceDomain,
        ConfigApi.operationDomain
      ),
      params: {
        PageIndex: 1,
        PageSize: 5,
        keyword: "",
        NoticePort: 1, // web 端
      },
    });

    yield r.data.data.Items;
  }

  {
    const agentID =
      localStorage.getItem("agentID") != ""
        ? localStorage.getItem("agentID")
        : "";
    const r = await axios({
      method: "GET",
      url: Tools.getApiAddress("info_concat").replace(
        ConfigApi.serviceDomain,
        ConfigApi.operationDomain
      ),
      params: {
        agentID: agentID,
      },
    });

    yield r.data.data;
  }
}

let mesParams: any = {};

export const Info = () => {
  const [concatList, setConcatList] = useState([
    { text: `电话：` },
    { text: `座机：` },
    { text: `邮箱：` },
    { text: `官网：` },
    { text: `地址：` },
  ]);
  const [msgList, setMsgList] = useState([]);
  const [isModalVisible, isModalFunc] = useBoolean(false);
  const [logo, setLogo] = useState("");
  let height = document.body.clientHeight;

  useEffect(() => {
    (async function () {
      const fetch = fetchData();

      const r = (await fetch.next()).value;
      setMsgList(r);

      {
        const { Phone, landline, QQEmail, SiteUrl, Address, Logo, SysLogo } = (
          await fetch.next()
        ).value;
        localStorage.setItem("logo", Logo);
        localStorage.setItem("SysLogo", SysLogo);
        localStorage.setItem("landline", landline);
        //设置网页logo
        setLogo(Logo);
        let link =
          document.querySelector("link[rel*='icon']") ||
          document.createElement("link");
        link["type"] = "image/x-icon";
        link["rel"] = "shortcut icon";
        link["href"] = logo;
        document.getElementsByTagName("head")[0].appendChild(link);
        setConcatList([
          { text: `电话：${Phone}` },
          { text: `座机：${landline}` },
          { text: `邮箱：${QQEmail}` },
          { text: `官网：${SiteUrl}` },
          { text: `地址：${Address}` },
        ]);
      }
    })();
    (async function () {
      // banner
      const r = await axios({
        method: "GET",
        url: Tools.getApiAddress("advertising").replace(
          ConfigApi.serviceDomain,
          ConfigApi.operationDomain
        ),
        params: {
          type: 1,
        },
      });

      if (r && r.data && r.data.data && r.data.data.length > 0) {
        localStorage.setItem("posterUrl", JSON.stringify(r.data.data));
      } else {
      }
    })();
    return () => {};
  }, []);

  return (
    <div className={styles.right_c}>
      <div className={styles.top_c}>
        <div className={styles.title_c}>消息通知</div>
       {msgList.length>0?<> {msgList.map((v, i) => (
          <div
            key={i}
            className={styles.item_c}
            onClick={() => {
              mesParams = v;
              isModalFunc.setTrue();
            }}
          >
            <span className={styles.badges}>
              <i>{i + 1}</i>
            </span>
            <span className={styles.text} title={v.NoticeTitle}>
              {v.NoticeTitle}
            </span>
            <span className={styles.badge_c}>
              {/* <span className={styles.badge}>{v.num}</span> */}
            </span>
            <hr />
          </div>
        ))}</>:
         <Empty  image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      </div>

      <div className={styles.bottom_c}>
        <div className={styles.title_c}>联系我们</div>
     {concatList.length>0?  <> {concatList.map((v, i) => (
          <div key={i} className={styles.item_c}>
            <i></i>
            <span className={styles.text} title={v.text}>
              {v.text}
            </span>
          </div>
        ))}</>:<Empty  image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      </div>
      {isModalVisible ? (
        <Modal
          className={styles.user_protocol}
          title="消息通知"
          visible={isModalVisible}
          onOk={() => isModalFunc.setFalse()}
          onCancel={() => isModalFunc.setFalse()}
          centered
          width={880}
        >
          <p>
            <h3 style={{ marginBottom: 0, fontSize: 16, textAlign: "center" }}>
              {mesParams.NoticeTitle}
            </h3>
            <div style={{ textAlign: "center" }}>
              <small>
                发布时间:{(mesParams.CreateTime || "").substr(0, 10)}
              </small>
            </div>
            <p
              style={{
                marginTop: "1rem",
                fontSize: 14,
                color: "gray",
                textAlign: "initial",
              }}
              dangerouslySetInnerHTML={{ __html: mesParams.NoticeContent }}
            ></p>
          </p>
        </Modal>
      ) : null}
    </div>
  );
};
