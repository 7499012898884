import { Form, Button, Row, Col, Input, Select, Switch } from "antd";
import { autoRem } from "../../../../global.style";
import styles from "../../style/print_set.module.scss";
import { PlusOutlined, EditFilled, DeleteFilled } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import { ConfirmModal, Modal } from "../../../../component/modal";
import { useBoolean } from "ahooks";
import { PrintItemForm } from "./componentForm";
import { formAction } from "../../action/printSet/print_set_action";
import { FormStore } from "../../store/printSet/print_set_store";
import { useContext, useState } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
const formParams = {
  title: "",
  index: null,
};
const { TextArea } = Input;
export function Control() {
  const [visibleState, visibleFunc] = useBoolean(false);
  const [form] = Form.useForm();
  const [itemForm] = Form.useForm();
  const action = formAction;
  const { state, dispatch } = useContext(FormStore);
  const [delConfirm, delConfirmFunc] = useBoolean(false);
  const [ticketJson, setJson] = useState('')
  return (
    <div className={styles.control}>
      <Row gutter={16}>
        <Col span={24}>
        <Form.Item label="">
        <TextArea
            allowClear
            autoSize={{ minRows: 2, maxRows: 3 }}
            value={ticketJson} 
            
            onChange={e=>setJson( e.target.value)}           
          />
            <ButtonPanel>
              <Button onClick={()=>action.getTemplate({ticketsJson: ticketJson, dispatch})}>解析模板</Button>
            </ButtonPanel>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="小票类型">
            <Select
              value={state.ticketsType}
              onChange={(v, option) => {
                action.setPageSize({
                  dispatch,
                  data: { type: v, pageSize: v === 1 || v === 4 ? 58 : 80 },
                });
              }}
            >
              {/* 1:58收银2:80收银;3:80配送;4:58价签 */}
              <Select.Option value={1} key={1} disabled>
                58收银
              </Select.Option>
              <Select.Option value={2} key={2} disabled>
                80收银
              </Select.Option>
              <Select.Option value={3} key={3}>
                80价签
              </Select.Option>
              <Select.Option value={4} key={4}>
                58价签
              </Select.Option>
            </Select>
          </Form.Item>
          
          {/* */}
        </Col>
      </Row>
      <div className={styles.com_list}>
        <h2>组件列表</h2>
        {state.formData.dataList &&
          state.formData.dataList.length > 0 &&
          state.formData.dataList.map((item, i) => {
            return (
              <div className={styles.com_item}>
                <div>
                  {item.title}
                  {item.title != undefined ? ":" : ""}
                </div>
                <div>{item.value}</div>
                <div>
                  <EditFilled
                    onClick={() => {
                      action.setActive({
                        dispatch,
                        items: { index: i, selectFormItem: item },
                      });
                      visibleFunc.setTrue();
                      formParams.title = "edit";
                      formParams.index = i;
                    }}
                  />
                  <DeleteFilled
                    style={{ marginLeft: "10px" }}
                    onClick={() => {
                      formParams.index = i;
                      delConfirmFunc.setTrue();
                    }}
                  />
                </div>
              </div>
            );
          })}
      </div>
      <div className={styles.add_button}>
        <Button
          type="dashed"
          size="large"
          icon={<PlusOutlined />}
          onClick={() => {
            formParams.title = "add";
            dispatch({ type: "Add", payload: "" });
            visibleFunc.setTrue();
          }}
        >
          点击添加
        </Button>
      </div>
      {visibleState && (
        <PrintItemForm
          visibleModal={visibleState}
          close={() => {
            visibleFunc.setFalse();
          }}
          form={itemForm}
          title={formParams.title}
        ></PrintItemForm>
      )}
      {/* <ConfirmModal visible={delConfirm} title=""></ConfirmModal> */}
      <ConfirmModal
        visible={delConfirm}
        title="温馨提示"
        subTitle="确认删除？"
        message="删除之后会影响布局，请确认"
        close={async (r) => {
          if (r) {
            await action.delItems({ dispatch, index: formParams.index });
          }
          delConfirmFunc.setFalse();
        }}
      />
    </div>
  );
}
