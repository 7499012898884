import { useEffect, useState } from "react";

export function HelpVideo() {
  const [srcUrl, setSrcUrl] = useState("");
  useEffect(() => {
    let src = window.location.href.split("=")[1];
    setSrcUrl(src);
  });
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        background: "#000000",
        opacity: "0.9",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          textAlign: "center",
          width: "100%",
        }}
      >
        <video
          src={srcUrl || "https://www.w3school.com.cn/i/movie.ogg"}
          controls
          width="50%"
          style={{ cursor: "pointer", borderRadius: "10px" }}
        >
          该浏览器暂不支持播放该视频
        </video>
      </div>
    </div>
  );
}
