import { message } from "antd";
import { RoleLogic } from "../../../../logic/role_logic";
import { FormAttribute } from "../../store/role/role_form_store";

type Init = {
    state;
    dispatch;
    form;
    editId: string;
    seeId: string;
}
interface InitBasic {
    dispatch;
    state;
}
interface InitEdit {
    state;
    dispatch;
    form;
    id;
}
interface ISetValue {
    attribute: FormAttribute;
    value: string | number | any[] | boolean;
    dispatch;
}

export class RoleFormAction {

    private roleLgc = new RoleLogic();

    async init({ state, dispatch, seeId, editId, form }: Init) {
        await this.initBasic({ dispatch, state });

        if (editId || seeId) {
            await this.initEdit({ dispatch, state, id: editId || seeId, form })
        }
    }
    private async initBasic({ }: InitBasic) {

    }
    private async initEdit({ dispatch, id, form }: InitEdit) {
        // 回填状态
        const item = await this.roleLgc.selData(id);

        // selectClasses
        dispatch({ type: "SetForm", payload: item });
        // 填充节点
        form.setFieldsValue({
            "role-name": item.name,
            "role-remarks": item.remark,
        });
    }
    reset({ dispatch, form }) {
        // selectClasses
        dispatch({
            type: "SetForm", payload: {
                id: "",
                name: "",
                remark: ""
            }
        });
        // 填充节点
        form.setFieldsValue({
            "role-name": "",
            "role-remarks": ""
        });
    }
    setValue({ attribute, value, dispatch }: ISetValue) {
        dispatch({ type: "Change_FormData", payload: { attribute, value } });
    }
    private paddingData(state) {
        let params: any = {};
        {
            const { id, name, remark } = state;

            params = {
                id: id,
                name: name,
                remark: remark
            }
        }
        return params;
    }
    async addSubmit({ state }) {
        const r = await this.roleLgc.addData(this.paddingData(state));

        if (r.statusCode == 200) {
            message.success("保存成功");
        } else {
            message.error(r.message);
        }
        return r.statusCode == 200;
    }
    async updSubmit({ state }) {
        const r = await this.roleLgc.updData(this.paddingData(state));
        if (r.statusCode == 200) {
            message.success("更新成功");
        } else {
            message.error(r.message);
        }
        return r.statusCode == 200;
    }
    setCondition(method: "Name" | 'Remark', value, dispatch) {

        if ("Name" == method) {
            dispatch({ type: "SetSearch", payload: { name: value } });
        }
        if ("Remark" == method) {
            dispatch({ type: "SetSearch", payload: { remark: value } });
        }
    }
}
export const roleFormAction = new RoleFormAction();