import { useBoolean, useRequest } from "ahooks";
import {
  Input,
  Modal,
  Select,
  Tree,
  Button,
  Card,
  Checkbox,
  message,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect, useState } from "react";
import { Search } from "../search";
import { DataTable } from "../table";
import {
  DataTableStore,
  DataTableStoreProvider,
} from "../table/store/data_table_store";
import { ChooseGoodsStore, ChooseStoreProvider } from "./store/choose_store";
import styles from "./style/index.module.scss";
import { Select as SelectAntd } from "antd";
import { chooseGoodsAction } from "./action/choose_action";
import { defualtPageSize } from "../../logic/common_logic";
import { ConfigApi, Tools } from "../../config";
import img_def from "../../assets/images/goods/goods_def.jpg";
let classId: string[] = [];

function Images(params: IChooseGoods) {
  let {
    visibleModal,
    close,
    filter,
    type,
    shopID,
    savekeysNo,
    clearSelectedList,
  } = params;
  const { Option } = Select,
    action = chooseGoodsAction;
  const { state, dispatch } = useContext(ChooseGoodsStore);
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);

  const handleOk = () => {
    close && close(true, tableState.selectList);
  };

  const handleCancel = () => {
    close && close(false, null);
  };
  const [list, setList] = useState([]);
  useEffect(() => {
    return () => {};
  });
  const { Meta } = Card;
  const Images = (v) => {
    // console.log(v);
    let flag = false;
    useEffect(() => {
      tableState.selectList.map((item, i) => {
        if (item.ID == v.ID) {
          flag = true;
        }
      });
    });
    return (
      <div style={{ position: "relative" }}>
        <Checkbox
          type="checkbox"
          name="fold"
          id={v.ID}
          style={{ position: "absolute", right: 0, top: 0, zIndex: 100000 }}
          checked={
            tableState.selectList.filter((item) => item.ID == v.ID).length > 0
              ? true
              : false
          }
          onChange={(e) => {
            let arr = list;
            if (e.target.checked) {
              if (arr.length < 5) {
                filter && filter.type == "radio" ? (arr = [v]) : arr.push(v);
                setList(arr);
              } else {
                message.error("最多五张图片");
              }
            } else {
              for (let i = 0; i < tableState.selectList.length; i++) {
                if (tableState.selectList[i].ID == v.ID) {
                  arr.splice(i, 1);
                  setList(arr);
                }
              }
            }

            // console.log(arr);
            action.setSelectList({
              dispatch: tableDispatch,
              selectKeys: arr,
            });
          }}
          // onClick={(e) => {
          //   let arr = tableState.selectList || [v];

          //   tableState.selectList.map((item, i) => {
          //     if (item.ID == v.ID) {
          //       console.log("1");
          //       arr.splice(i, 1);
          //     } else if (arr.length < 5) {
          //       console.log("2");
          //       arr.push(v);
          //     } else if (arr.length == 5) {
          //       console.log("3");
          //       arr.splice(0, 1);
          //       arr.push(v);
          //     }
          //   });
          //   console.log(arr);
          //   action.setSelectList({
          //     dispatch: tableDispatch,
          //     selectKeys: arr,
          //   });
          // }}
        />
        <label htmlFor={v.ID}>
          {" "}
          <Card hoverable style={{ width: 80, margin: "0 auto" }}>
            <img
              alt=""
              src={
                v
                  ? Tools.getImageAddress(v.ThumbnailPath).replace(
                      ConfigApi.serviceDomain,
                      ConfigApi.operationDomain
                    )
                  : img_def
              }
              width={60}
              height={60}
            />
            <Meta description={v.GoodsName}></Meta>
          </Card>
        </label>
      </div>
    );
  };
  const columns: ColumnsType = [
    {
      // title: "",
      // dataIndex: "GoodsName",
      key: "1",
      align: "center",
      render: (v) => v[0] && Images(v[0]),
    },
    {
      key: "2",
      align: "center",
      render: (v) => v[1] && Images(v[1]),
    },
    {
      key: "3",
      align: "center",
      render: (v) => v[2] && Images(v[2]),
    },
    {
      key: "4",
      align: "center",
      render: (v) => v[3] && Images(v[3]),
    },
    {
      key: "5",
      align: "center",
      render: (v) => v[4] && Images(v[4]),
    },
    {
      key: "6",
      align: "center",
      render: (v) => v[5] && Images(v[5]),
    },
    {
      key: "7",
      align: "center",
      render: (v) => v[6] && Images(v[6]),
    },
    {
      key: "8",
      align: "center",
      render: (v) => v[7] && Images(v[7]),
    },
    {
      key: "9",
      align: "center",
      render: (v) => v[8] && Images(v[8]),
    },
    {
      key: "10",
      align: "center",
      render: (v) => v[9] && Images(v[9]),
    },
  ];
  let tableRowSelection = {
    rowSelection: {
      type: "radio",
    },
  };
  const onSearch = async (pageIndex) => {
    //console.log(74)
    await action.loadPage({
      pageIndex: pageIndex,
      pageSize: 40,
      dispatch: tableDispatch,
      search: { ...state.search },
    });
  };

  useEffect(() => {
    if (clearSelectedList) {
      action.clearSelectedRowKeys({ dispatch });
    }
    return () => {
      if (clearSelectedList) {
        action.clearSelectedRowKeys({ dispatch });
      }
    };
  });
  return (
    <Modal
      title="选择商品图片"
      visible={visibleModal}
      onOk={handleOk}
      onCancel={handleCancel}
      width={1000}
      bodyStyle={{ height: 600 }}
      cancelText="取消"
      okText="确定"
      footer={
        <>
          <Button
            type="primary"
            style={{
              backgroundColor: "#f7ba00",
              borderColor: "#f7ba00",
              color: "white",
            }}
            onClick={handleCancel}
          >
            取消
          </Button>
          <Button type="primary" onClick={handleOk}>
            确认
          </Button>
        </>
      }
    >
      <div className={styles.root}>
        <Search>
          <Input
            maxLength={20}
            allowClear
            placeholder="请输入图片名称"
            onChange={(e) =>
              action.setCondition("Keywords", e.target.value, dispatch)
            }
          />
          <input type="button" value="快速查找" onClick={() => onSearch(1)} />
        </Search>

        <div style={{ marginTop: 10, height: 200 }}>
          <div className={styles.list}>
            <DataTable
              showHeader={false}
              scroll={{ y: 800 }}
              columns={columns}
              action={action}
              rowKey={(record) => {
                // console.log(record.length>0);
                return (record.length > 0 ? 'ID':'id') || "";
              }}
              rowSelection={{
                type: type || "radio",
              }}
              onLoad={() => onSearch(1)}
              onChange={(pageIndex) => {
                onSearch(pageIndex);
              }}
              // selectColumn={true}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

type Filter = {
  type?: string;
};
interface IChooseGoods {
  visibleModal: boolean;
  close?: (status, r) => void;
  filter?: Filter;
  type?;
  shopID?;
  clearSelectedList?: boolean;
  savekeysNo?;
}
export function ChooseImage(params: IChooseGoods) {
  return (
    <ChooseStoreProvider>
      <DataTableStoreProvider>
        <Images {...params} />
      </DataTableStoreProvider>
    </ChooseStoreProvider>
  );
}
