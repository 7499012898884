import React, { useContext } from "react";
import { Button, Form, Select,Input } from "antd";
import styles from "../../style/file_search.module.scss";
import { useBoolean, useRequest } from "ahooks";
import { DatePicker, Space } from "antd";
import { fileAction } from "../../action/dossier/file_action";
import { FileStore } from "../../store/dossier/file_store";
import { ChooseShop } from "../../../../component/choose-shop";
const { RangePicker } = DatePicker;
const { Option } = Select;

export function FileSearch({ onSearch }) {
    const { state, dispatch } = useContext(FileStore);
    const [shopState,shopFunc] = useBoolean(false)
    useRequest(() => fileAction.initBasic({ state, dispatch }));
    const action = fileAction;
    return (
        <div>
            <Form layout="horizontal">
                <div className={styles.row}>
               
                    <div className={styles.cell}>
                        <Form.Item label="会员等级">
                            <Select style={{ width: 175 }} placeholder="请选择" onChange={(v) => action.setCondition("MembeClassID", v, dispatch)}
                                showSearch
                                filterOption={(input:any, option:any) => {
                                    return option.children.indexOf(input) >= 0;
                                }}
                            >
                                {
                                    state.gradeList.length > 0 ? state.gradeList.map(v => (
                                        <Option value={v.id} key={v.id}>{v.gradeName}</Option>
                                    )) : ''
                                }
                            </Select>
                        </Form.Item>
                    </div>
                    <div className={styles.cell}>
                        <Form.Item label="会员标签">
                            <Select
                                mode="multiple"
                                allowClear
                                style={{ width: 175 }}
                                placeholder="请选择"
                                onChange={(v) => action.setCondition("Label", v, dispatch)}
                            >
                                {
                                    state.TagList.map(v => (
                                        <Option value={v.id} key={v.id}>{v.labelName}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </div>
                    <div className={styles.cell}>
                        <Form.Item label="会员来源">
                            <Select style={{ width: 175 }} placeholder="请选择" onChange={(v) => action.setCondition("Source", v, dispatch)}
                                showSearch
                                filterOption={(input:any, option:any) => {
                                    return option.children.indexOf(input) >= 0;
                                }}
                            >
                                <Option value={0}>全部</Option>
                                <Option value={1}>管理端</Option>
                                <Option value={2}>客户端</Option>
                                <Option value={3}>小程序</Option>
                            </Select>
                        </Form.Item>
                    </div>
                    <div className={styles.cell}>
                        <Form.Item label="会员状态">
                            <Select style={{ width: 175 }} placeholder="请选择" onChange={(v) => action.setCondition("Status", v, dispatch)}
                                showSearch
                                filterOption={(input:any, option:any) => {
                                    return option.children.indexOf(input) >= 0;
                                }}
                            >
                                <Option value={0}>全部</Option>
                                <Option value={1}>挂失</Option>
                                <Option value={2}>停用</Option>
                                <Option value={3}>正常</Option>
                            </Select>
                        </Form.Item>
                    </div>
                    {/* <div className={styles.cell}>
                        <Form.Item label="选择门店">
                            <Input style={{ width: 175 }} value={''} placeholder="请选择" onClick={() => shopFunc.setTrue()} />
                            <span className="ant-input-suffix" style={{ marginLeft: '-24px', zIndex: 99 }} onClick={()=>{}}>
                                <span role="button" aria-label="close-circle" className="anticon anticon-close-circle ant-input-clear-icon"><svg viewBox="64 64 896 896" focusable="false" data-icon="close-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 01-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"></path></svg></span>
                            </span>
                        </Form.Item>
                    </div> */}
                    <div className={styles.cell}>
                        <Form.Item label="注册时间">
                            <Space style={{ width: 432 }} direction="vertical" size={12} >
                                <RangePicker onChange={function (date, dateString) {
                                    action.setCondition("AddDateTimeStart", dateString[0], dispatch)
                                    action.setCondition("AddDateTimeEnd", dateString[1], dispatch)
                                }} />
                            </Space>
                        </Form.Item>
                    </div>
                    <div className={styles.cell}>
                        <Form.Item label="出生日期">
                            <Space style={{ width: 432 }} direction="vertical" size={12}>
                                <RangePicker
                                    onChange={function (date, dateString) {
                                        action.setCondition("BirthdayStart", dateString[0], dispatch)
                                        action.setCondition("BirthdayEnd", dateString[1], dispatch)
                                    }}
                                />
                            </Space>
                        </Form.Item>
                    </div>
                </div>
                <div className={styles.row}>
                    <Button type="primary" danger onClick={() => onSearch(1)}>搜  索</Button>
                </div>
            </Form>
            <ChooseShop
                 visibleModal={shopState}
                 close={(status, r) => {
                    if (r) {
                    
                    }
                    shopFunc.setFalse();
                  }}
            />
        </div>
    )
}