import { message } from "antd";
import { useRef, useEffect } from "react";
import { useContext } from "react";
import { comConType } from "..";
import { Tools } from "../../../config";
import { TopicDataAction } from "../action/TopicDataAction";
import { ApiCon } from "../com/apiCon";
import { ITopicValue, TopicStore } from "../store/topic";
import styles from "../style/video.module.scss";

interface IData {
  /** 视频地址 */
  videoUrl: string;
  /** 视频名称 */
  videoName: string;
}

/**
 * 辅助空白
 * @param props
 */
export function Video(props: { id: number, con: comConType }) {
  const topicStoreState = useContext(TopicStore);
  const inputRef = useRef(null);

  let actionArg: [ITopicValue, number] = [topicStoreState, props.id];
  const data: IData = TopicDataAction.getData<IData>(...actionArg);
  useEffect(() => {
    if (!data) {
      TopicDataAction.setData<IData>(...actionArg, {
        videoUrl: '',
        videoName: '',
      });
    }
  });
  if (!data) {
    return <div></div>;
  }

  //注册数据提取方法
  TopicDataAction.setEF(topicStoreState, props.id, () => {
    return new Promise((r, e) => {
      if (data.videoUrl) {
        ApiCon.turnBlobVideoUrl({
          url: data.videoUrl,
          name: data.videoName,
        }).then((urls) => {
          let _data;
          if (typeof urls[0] == 'string') {
            _data = {
              videoUrl: urls[0],
            };
          } else {
            _data = {
              width: urls[0].width,
              height: urls[0].height,
              videoUrl: urls[0].videoPath,
            };
          }
          r({
            ...data,
            ..._data,
          });
        });
      } else {
        message.error('有视频没有上传');
      }
    });
  });

  /** input改变 */
  const inputChange = (e) => {
    let _file: File = inputRef.current.files[0];
    data.videoUrl = URL.createObjectURL(_file);
    data.videoName = _file.name;
    TopicDataAction.setData(...actionArg, data);
  };

  let videoUrl: string = ApiCon.getVideoUrl(data.videoUrl);

  if (props.con == 'show') {
    return (<div className={styles.video}>
      <video src={videoUrl} autoPlay loop></video>
      {!videoUrl && <div></div>}
    </div>);
  } else {
    return (
      <>
        <div className={styles.video_ctrl}>
          <span>视频展示：</span>
          <div className={styles.right}>
            <input
              ref={inputRef}
              onChange={inputChange}
              type="file"
              accept="video/*"
            />
            <div className={styles.video_}>
              <video src={videoUrl} controls></video>
              {!videoUrl && <div></div>}
            </div>
            <button
              onClick={() => {
                inputRef.current.click();
              }}
            >
              选择文件
            </button>
            <span>
              视频时长建议9-30秒，视频大小不超过 100
              MB，建议宽高比16:9，支持的视频格式MP4
            </span>
          </div>
        </div>
      </>
    );
  }
}
