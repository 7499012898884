import styles from "../style/form_subtitle.module.scss";

interface IFormSubtitle {
    children?: any;
    style?:any
}
export function FormSubtitle({ children ,style}: IFormSubtitle) {
    return (
        <div className={styles.root}>
            <i style={style}></i>
            <span  style={style}>{children}</span>
        </div>
    )
}