import clsx from "clsx";
import React, { useContext, useRef } from "react";
import { Progress } from "../../../logic/common_logic";
import { TabStore } from "../store/tab_store";

interface ITab {
    children?: JSX.Element | JSX.Element[] | string;
    serial?: number;
}

Tab['nodetype'] = 'Tab';

export function Tab({ children, serial }: ITab) {
    const divRef = useRef();
    const { selectIndex, setSelectIndex } = useContext(TabStore);

    return (
        <li onClick={() => {
            setSelectIndex(serial);
            
            selectIndex !== serial && Progress.start();
        }}
            onDoubleClick={() => {
                setSelectIndex(-1)
                setTimeout(() => {
                    setSelectIndex(serial)
                }, 50);
                Progress.start();
            }}
            className={clsx({ active: selectIndex == serial })}
            style={{userSelect:"none"}}
            >
            {children}
        </li>
    )
}