import React, { useEffect, useState } from "react";
import { Layout } from "../../component/layout";
import { TabsStyle, TabList, Tab, TabPanel } from "../../component/react-tabs";
import { DataTableStoreProvider } from "../../component/table/store/data_table_store";
import { File } from "../commodity/view/file";
import { ListStoreProvider } from "./store/list/list_store";
import { AuthStoreProvider } from "./store/auth/file_store";
import { RoleStoreProvider } from "./store/role/role_store";
import { UserRoleStoreProvider } from "./store/userRole/file_store";
import { DataListStoreProvider } from "../../component/data-list/store/data_table_store";
import { List } from "./view/list/list";
import { Role } from "./view/role/role";
import { UserRole } from "./view/userRole/userRole";
// import { UserRole } from "./view/user";
import { Auth } from "./view/auth/auth";
import { MenuLogic } from "../../logic/menu_logic";

export function User() {
  const [menuLists, setData] = useState([]);
  useEffect(() => {
    (async function () {
      let r = await new MenuLogic().menuData();
      var arr = [];
      if (r && r.length > 0) {
        r = r.filter(function (v) {
          return !v.hidden;
        });
        for (let i = 0; i < r.length; i++) {
          if (r[i].path == "/user" && r[i].children) {
            arr = r[i].children.filter(function (v) {
              return !v.hidden;
            });
          }
        }
      }
      setData(arr);
    })();

    return () => {};
  }, []);
  return (
    // <Layout fullWidth={true} >
    <TabsStyle style="Full">
      <TabList>
        {menuLists.map((v, i) => (
          <Tab key={i}>{v.name}</Tab>
        ))}
      </TabList>
      {menuLists.map((v) =>
        v.component == "List" ? (
          //用户列表
          <TabPanel>
            <ListStoreProvider>
              <DataTableStoreProvider>
                <List viewPower={v.viewPower} />
              </DataTableStoreProvider>
            </ListStoreProvider>
          </TabPanel>
        ) : v.component == "Role" ? (
          //角色定义
          <TabPanel>
            <RoleStoreProvider>
              <DataTableStoreProvider>
                <Role viewPower={v.viewPower} />
              </DataTableStoreProvider>
            </RoleStoreProvider>
          </TabPanel>
        ) : v.component == "Auth" ? (
          //角色权限
          <TabPanel>
            <AuthStoreProvider>
              <DataTableStoreProvider>
                <DataListStoreProvider>
                  <Auth />
                </DataListStoreProvider>
              </DataTableStoreProvider>
            </AuthStoreProvider>
          </TabPanel>
        ) : v.component == "UserRole" ? (
          //用户角色
          <TabPanel>
            <UserRoleStoreProvider>
              <DataTableStoreProvider>
                <DataListStoreProvider>
                  <UserRole viewPower={v.viewPower} />
                </DataListStoreProvider>
              </DataTableStoreProvider>
            </UserRoleStoreProvider>
          </TabPanel>
        ) : (
          <TabPanel>
            <div></div>
          </TabPanel>
        )
      )}
    </TabsStyle>
    // </Layout>
  );
}
