import { useBoolean } from "ahooks";
import { Button, Input, message, Modal, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect, useState } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { DataTable } from "../../../../component/table";

import img_check from "../../../../assets/images/icon/status/zhengque.png";
import no_check from "../../../../assets/images/icon/status/jinyong.png";

import { fileAction } from "../../action/list/list_action";
import { ListFormStoreProvider } from "../../store/list/list_form_store";
import { Form } from "./list_form";
import { formAction } from "../../action/list/list_form_action";
import { DataTableStore } from "../../../../component/table/store/data_table_store";
import { Search } from "../../../../component/search";
import { ListStore } from "../../store/list/list_store";
import { defualtPageSize, Progress } from "../../../../logic/common_logic";
import { AuthLogic } from "../../../../logic/auth_logic";
import {
  Control,
  Del,
  Edit,
  Lock,
  See,
} from "../../../../component/control-column";
import { ConfirmModal } from "../../../../component/modal";

let editId = "";
let seeId = "";
let passwordId = "";
let isdefault = false;
const formParams = {
  method: "Create",
  id: "",
};
export function List(viewPower) {
  const [createState, createFunc] = useBoolean(false);
  const [deleteState] = useBoolean(false);
  const [] = useBoolean(false);
  const action = fileAction;
  const [confirmState, confirmFunc] = useBoolean(false);
  const [qiyongState, qiyongFunc] = useBoolean(false);
  const [stopState, stopFunc] = useBoolean(false);
  const [delState, delFunc] = useBoolean(false);
  const { state, dispatch } = useContext(ListStore);
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);
  const [] = useBoolean(false);

  const [menuButton, setData] = useState([]);
  useEffect(() => {
    (async function () {
      let r = await new AuthLogic().selButton(viewPower.viewPower);
      if (r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          r[i] = r[i].split("_")[r[i].split("_").length - 1];
        }
      }
      setData(r);
    })();

    return () => {};
  }, []);

  const columns: ColumnsType = [
    {
      title: "操作",
      key: "1",
      align: "center",
      width: Control.width(4),
      render: Control.render(
        (v) =>
          menuButton.indexOf("VIEW") != -1 && (
            <See
              click={() => {
                editId = "";
                seeId = v.userID;
                passwordId = "";
                isdefault = v.isdefault;
                createFunc.setTrue();
              }}
            />
          ),
        (v) =>
          menuButton.indexOf("UPDATE") != -1 && (
            <Edit
              click={() => {
                editId = v.userID;
                seeId = "";
                passwordId = "";
                isdefault = v.isdefault;
                createFunc.setTrue();
              }}
            />
          ),
        (v) =>
          menuButton.indexOf("DELETE") != -1 && (
            <Del
              click={() => {
                if (v.isdefault) {
                  message.error("该用户不能删除");
                } else {
                  formParams.id = v.userID;
                  confirmFunc.setTrue();
                }
              }}
            />
          ),
        (v) =>
          menuButton.indexOf("RESETPASSWD") != -1 && (
            <Lock
              click={() => {
                editId = "";
                seeId = "";
                passwordId = v.userID;
                createFunc.setTrue();
              }}
            />
          )
      ),
    },
    {
      title: "用户名称",
      dataIndex: "userName",
      key: "2",
      align: "center",
    },
    {
      title: "手机号码",
      dataIndex: "phone",
      key: "3",
      align: "center",
      width: 100,
    },
    {
      title: "性别",
      dataIndex: "sex",
      key: "4",
      align: "center",
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "5",
      align: "center",
      width: 140,
    },
    {
      title: "是否启用",
      key: "5",
      align: "center",
      dataIndex: "enabled",
      render: (v) =>
        v ? <Tag color="success">启用</Tag> : <Tag color="default">禁用</Tag>,
    },
    {
      title: "备注",
      dataIndex: "remark",
      key: "8",
      align: "center",
    },
  ];

  const onSearch = async (pageIndex) => {
    await action.loadPage({
      pageIndex: pageIndex,
      pageSize: defualtPageSize(),
      dispatch: tableDispatch,
      search: state.search,
    });
  };

  return (
    <>
      <Search>
        <Input
          maxLength={20}
          allowClear
          placeholder="请输入用户名称、手机号"
          onChange={(e) =>
            action.setCondition("Keywords", e.target.value, dispatch)
          }
        />
        <input type="button" value="快速查找" onClick={() => onSearch(1)} />
      </Search>
      <ButtonPanel>
        {menuButton.indexOf("ADD") != -1 && (
          <Button
            onClick={() => {
              editId = "";
              seeId = "";
              passwordId = "";
              isdefault = false;
              createFunc.setTrue();
            }}
          >
            新增用户
          </Button>
        )}
        {menuButton.indexOf("DELETE") != -1 && (
          <Button
            onClick={async () => {
              if (!tableState.selectList.length) {
                message.warning("请选择删除项");
              } else {
                let arr = tableState.selectList.filter((item) => {
                  return item.isdefault == true;
                });
                if (arr.length > 0) {
                  message.error("存在用户不能删除");
                  action.clearSelectedRowKeys({ dispatch: tableDispatch });
                } else {
                  delFunc.setTrue();
                }
              }
            }}
          >
            批量删除
          </Button>
        )}
        <Button
          onClick={async () => {
            if (!tableState.selectList.length) {
              message.warning("请选择启用项");
            } else {
              qiyongFunc.setTrue();
            }
          }}
        >
          批量启用
        </Button>
        <Button
          onClick={async () => {
            if (!tableState.selectList.length) {
              message.warning("请选择禁用项");
            } else {
              let arr = tableState.selectList.filter((item) => {
                return item.isdefault == true;
              });
              if (arr.length > 0) {
                message.error("存在不能禁用的用户");
                action.clearSelectedRowKeys({ dispatch: tableDispatch });
              } else {
                stopFunc.setTrue();
              }
            }
          }}
        >
          批量禁用
        </Button>
        {menuButton.indexOf("EXPORT") != -1 && <Button>导出</Button>}
      </ButtonPanel>

      <DataTable
        columns={columns}
        action={fileAction}
        onLoad={async () => {
          await onSearch(1);
          Progress.done();
        }}
        onChange={(pageIndex) => {
          onSearch(pageIndex);
        }}
        scroll={{ scrollToFirstRowOnChange: true, autoY: true }}
        selectColumn={true}
        loading={tableState.loading}
      />

      {createState && (
        <ListFormStoreProvider>
          <Form
            close={(hasUpdate) => {
              if (hasUpdate) {
                onSearch(tableState.current);
              }

              createFunc.setFalse();
            }}
            action={formAction}
            editId={editId}
            passwordId={passwordId}
            seeId={seeId}
            isdefault={isdefault}
          />
        </ListFormStoreProvider>
      )}

      <ConfirmModal
        visible={confirmState}
        title="温馨提示"
        subTitle="确认删除？"
        message="删除之后信息无法恢复，请慎重考虑"
        close={async (r) => {
          if (r) {
            await action.delRow({ dispatch, rowItem: formParams });
            onSearch(tableState.current);
          }
          confirmFunc.setFalse();
        }}
      />
      <ConfirmModal
        visible={qiyongState}
        title="温馨提示"
        subTitle="确认启用？"
        message="启用之后用户正常使用"
        close={async (r) => {
          if (r) {
            let status = true;
            await action.enableSelectList({
              dispatch,
              selectList: tableState.selectList,
              status,
            });

            action.clearSelectedRowKeys({ dispatch: tableDispatch });

            await onSearch(tableState.current);
          }
          qiyongFunc.setFalse();
        }}
      />
      <ConfirmModal
        visible={stopState}
        title="温馨提示"
        subTitle="确认禁用？"
        message="禁用之后用户无法正常使用，请慎重考虑"
        close={async (r) => {
          if (r) {
            let status = false;

            await action.enableSelectList({
              dispatch,
              selectList: tableState.selectList,
              status,
            });

            action.clearSelectedRowKeys({ dispatch: tableDispatch });

            await onSearch(tableState.current);
          }
          stopFunc.setFalse();
        }}
      />
      <ConfirmModal
        visible={delState}
        title="温馨提示"
        subTitle="确认删除？"
        message="删除之后信息无法恢复，请慎重考虑"
        close={async (r) => {
          if (r) {
            await action.delSelectList({
              dispatch,
              selectList: tableState.selectList,
            });

            action.clearSelectedRowKeys({ dispatch: tableDispatch });

            await onSearch(tableState.current);
          }
          delFunc.setFalse();
        }}
      />
    </>
  );
}
