import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
  TimePicker,
  DatePicker,
  Tag,
  message,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { FormSubtitle } from "../../../commodity/view/form_subtitle";
import styles from "../../style/inventory_goods_formbasic.module.scss";

import img_del from "../../../../assets/images/shanchu.png";
import { fullgiftformAction } from "../../action/fullgift/form_action";
import { useBoolean } from "ahooks";
import { ChooseGoods } from "../../../../component/choose-goods";
import { ChooseClassify } from "../../../../component/choose-classify";
import { Table } from "../../../../component/table";
import { ChooseShop } from "../../../../component/choose-shop";
import { FullgiftFormStore } from "../../store/fullgift/form_store";
import { autoRem } from "../../../../global.style";
import { useState } from "react";
import { RadioStyle } from "../../../../component/radio-style";
import { start } from "repl";
import moment from "moment";
let dayList = [];

const { Option } = Select;
const { RangePicker } = DatePicker;
export function DataTable({ dataList, disabled }) {
  const { dispatch } = useContext(FullgiftFormStore);
  const action = fullgiftformAction;
  const columns: ColumnsType = [
    {
      title: "操作",
      dataIndex: "serial",
      key: "1",
      align: "center",
      width: 100,
      render: (v, r, i) => (
        <div>
          <img
            src={img_del}
            style={{ width: 20 }}
            onClick={() => {
              !disabled && action.delTableItem(dispatch, i);
            }}
          />
        </div>
      ),
    },
    {
      title: "商品编码",
      dataIndex: "goodsNo",
      key: "2",
      align: "center",
    },
    {
      title: "商品名称",
      dataIndex: "goodsName",
      key: "3",
      align: "center",
    },
    {
      title: "商品分类",
      dataIndex: "goodsClassName",
      key: "4",
      align: "center",
    },
    {
      title: "单位",
      //   dataIndex: "unitName",
      key: "5",
      align: "center",
      render: (v) => (
        <span>
          {v.unitContent}
          {v.unitName}
        </span>
      ),
    },
    {
      title: "门店价",
      dataIndex: "storePrice",
      key: "5",
      align: "center",
    },
    {
      title: "商城价",
      dataIndex: "storePrice",
      key: "5",
      align: "center",
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "6",
      align: "center",
      render: (v) => (
        <>
          {v == 1 && <Tag color="success">正常</Tag>}
          {v == 2 && <Tag color="error">停售</Tag>}
        </>
      ),
    },
  ];
  return <Table dataSource={dataList} columns={columns} />;
}

export function DataTableclassity({ dataList, disabled }) {
  const { dispatch } = useContext(FullgiftFormStore);
  const action = fullgiftformAction;

  const columns: ColumnsType = [
    {
      title: "操作",
      dataIndex: "serial",
      key: "1",
      align: "center",
      width: 100,
      render: (v, r, i) => (
        <div>
          <img
            src={img_del}
            style={{ width: 20 }}
            onClick={() => {
              !disabled && action.delTableclassItem(dispatch, i);
            }}
          />
        </div>
      ),
    },
    {
      title: "商品分类",
      dataIndex: "title",
      key: "2",
      align: "center",
    },
  ];
  return <Table dataSource={dataList} columns={columns} />;
}
export function DataTableAdd({ dataList, disabled }) {
  const { dispatch } = useContext(FullgiftFormStore);
  const action = fullgiftformAction;

  const columns: ColumnsType = [
    {
      title: "操作",
      dataIndex: "serial",
      key: "1",
      align: "center",
      width: 100,
      render: (v, r, i) => (
        <div>
          <img
            src={img_del}
            style={{ width: 20 }}
            onClick={() => {
              !disabled && action.delTableItemAdd(dispatch, i);
            }}
          />
        </div>
      ),
    },
    {
      title: "商品编码",
      dataIndex: "goodsNo",
      key: "2",
      align: "center",
    },
    {
      title: "商品名称",
      dataIndex: "goodsName",
      key: "3",
      align: "center",
    },
    {
      title: "商品分类",
      dataIndex: "goodsClassName",
      key: "4",
      align: "center",
    },
    {
      title: "单位",
      dataIndex: "unitName",
      key: "5",
      align: "center",
      width: autoRem(9.4),
      render: (v) => <span>-</span>,
    },
    {
      title: "门店价",
      key: "6",
      align: "center",
      width: autoRem(9.4),
      render: (v) => <span>-</span>,
    },
    {
      title: "商城价",
      key: "7",
      align: "center",
      width: autoRem(9.4),
      render: (v) => <span>-</span>,
    },
    {
      title: "赠送总库存",
      dataIndex: "giveCount",
      key: "8",
      align: "center",
      width: autoRem(9.4),
      render: (e, r, i) => <span>****</span>,
    },
  ];
  return (
    <Table
      dataSource={dataList}
      columns={columns}
      expandable={{
        expandedRowRender: (record) => {
          if (
            record.goods_SpecificationList &&
            record.goods_SpecificationList.length > 0
          )
            return record.goods_SpecificationList.map((item) => {
              return (
                <GetSpecifications specifications={item} disabled={disabled} />
              );
            });
        },
        rowExpandable: (record) => record.name !== "Not Expandable",
      }}
    />
  );
}

function GetSpecifications({ specifications, disabled }) {
  const { state, dispatch } = useContext(FullgiftFormStore);
  if (specifications) {
    return (
      <div className={styles.specifications}>
        <div className={styles.input} style={{ width: autoRem(9.4) }}>
          <span>{specifications.unitContent}</span>
          <span>{specifications.unitName}</span>
        </div>
        <div
          className={styles.input}
          style={{ width: autoRem(9.4), border: "none" }}
        >
          <span>{specifications.shopPrice}</span>
        </div>
        <div className={styles.input} style={{ width: autoRem(9.4) }}>
          <span>{specifications.storePrice}</span>
        </div>
        {/* 赠送商品 */}
        <div className={styles.input} style={{ width: autoRem(9.4) }}>
          <Input
            type="number"
            defaultValue={specifications.giveCount}
            disabled={disabled}
            onChange={(e) => {
              specifications.giveCount = parseFloat(e.currentTarget.value);
              dispatch({
                type: "update",
                payload: null,
              });
              if (!/^[1-9]\d*$/.test(e.currentTarget.value)) {
                message.error("请输入大于0的数");
                return false;
              } else {
                specifications.giveCount = Math.abs(
                  parseFloat(e.currentTarget.value)
                );
                dispatch({
                  type: "update",
                  payload: null,
                });
              }
            }}
          />
        </div>
      </div>
    );
  }
}
export function InventoryGoodsFormBasic({ title, disabled, updateFormField }) {
  const [shopState, shopFunc] = useBoolean(false);
  const [goodsState, goodsFunc] = useBoolean(false);
  const [goodsStateAdd, goodsFuncAdd] = useBoolean(false);
  const [classifyState, classifyFunc] = useBoolean(false);

  const { state, dispatch } = useContext(FullgiftFormStore);
  const { formData } = state;
  const action = fullgiftformAction;
  var isLook = false;
  if (title == "查看满减") {
    isLook = true;
  } else {
    isLook = false;
  }
  let isEdit = false;
  if (title == "编辑满减") {
    isEdit = true;
  }
  const onChangeCheck = (e) => {
    if (!disabled) {
      action.setFormData({
        dispatch,
        key: "isMemberClass",
        value: e.target.checked,
      });

      if (e.target.checked) {
        action.setFormData({ dispatch, key: "memberClassIDs", value: [] });
      }
    }
  };
  const handleChange = (e) => {
    action.setFormData({ dispatch, key: "memberClassIDs", value: e });
  };
  const selectDetaChange = (e) => {
    if (formData.useType == 2) {
      action.setFormData({ dispatch, key: "useTypeValue", value: e });
    }
  };
  // 时间段选择
  const timePickeronChange = (time, timeString) => {
    action.setFormData({ dispatch, key: "beginTime", value: timeString[0] });
    action.setFormData({ dispatch, key: "endTime", value: timeString[1] });
  };

  const range = (start, end) => {
    const result = [];

    for (let i = start; i < end; i++) {
      result.push(i);
    }

    return result;
  };

  const disabledDateTime = () => {
    let hours = moment().hours(); //0~23

    let minutes = moment().minutes(); //0~59

    let seconds = moment().seconds(); //0~59
    let time;

    //当日只能选择当前时间之后的时间点
    if (formData.endDate && moment(formData.endDate) == moment()) {
      time = {
        disabledHours: () => range(0, hours),

        disabledMinutes: () => range(0, minutes),

        disabledSeconds: () => range(0, seconds + 1),
      };
      return time;
    }
  };

  // 日期段选择
  const onangeChange = (time, timeString) => {
    action.setFormData({ dispatch, key: "beginDate", value: timeString[0] });
    action.setFormData({ dispatch, key: "endDate", value: timeString[1] });
    if (formData.useType == 2) {
      action.setFormData({ dispatch, key: "useTypeValue", value: [] });
    }
    setDays();
  };
  const disabledDate = (current) => {
    return current < moment().startOf("day");
  };
  const onChangeCheckbox = (e) => {
    if (formData.useType != 2) {
      action.setFormData({ dispatch, key: "useweekTypeValue", value: e });
      action.setFormData({ dispatch, key: "useTypeValue", value: e });
    }
  };
  const count = (key) =>
    state.tableList.map((v) => v[key]).reduce((p, c) => p + c, 0);
  function ShopType({ value, swtichValue, disabled }) {
    const [] = useState(1);
    const { dispatch } = useContext(FullgiftFormStore);

    const onChange = (e) => {
      if (!disabled) {
        action.addGradeItem(dispatch, "addOne");
        action.setFormData({
          dispatch,
          key: "activityManner",
          value: e.target.value,
        });
      }
    };
    return (
      <RadioStyle>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Radio.Group
            onChange={onChange}
            value={swtichValue}
            style={{ marginTop: "10px" }}
          >
            <Radio
              value={1}
              style={{ marginLeft: "15px", height: "30px", lineHeight: "30px" }}
            >
              满额减
            </Radio>
            <Radio
              value={2}
              style={{ marginLeft: "10px", height: "30px", lineHeight: "30px" }}
            >
              满额加价送
            </Radio>
            <Radio
              value={3}
              style={{ marginLeft: "10px", height: "30px", lineHeight: "30px" }}
            >
              满量送赠品
            </Radio>
          </Radio.Group>
          {formData.activityManner == 1 ? (
            <div style={{ marginLeft: "3px", marginTop: "10px" }}>
              注：针对设置的商品买满一定金额，减一定金额，例如：满100减10，满200减30
            </div>
          ) : formData.activityManner == 2 ? (
            <div style={{ marginLeft: "3px", marginTop: "10px" }}>
              注：针对设置的商品买满一定金额，加一定金额，赠送商品，例如：满100加10送商品A或B
            </div>
          ) : (
            <div style={{ marginLeft: "15px", marginTop: "10px" }}>
              注：针对设置的商品买满一定数量，赠送商品，例如：买1斤肉送0.5斤姜
            </div>
          )}
        </div>
      </RadioStyle>
    );
  }
  function WeekType({ value, swtichValue }) {
    const [] = useState(1);
    const { dispatch } = useContext(FullgiftFormStore);

    const onChange = (e) => {
      if (!disabled) {
        action.setFormData({ dispatch, key: "useTypeValue", value: [] });
        action.setFormData({ dispatch, key: "useType", value: e.target.value });
      }
    };
    return (
      <RadioStyle>
        <Radio.Group
          onChange={onChange}
          value={swtichValue}
          style={{ marginTop: "10px" }}
        >
          <Radio
            value={1}
            style={{ marginLeft: "15px", height: "30px", lineHeight: "30px" }}
          >
            按固定星期
          </Radio>
          <Radio
            value={2}
            style={{ marginLeft: "10px", height: "30px", lineHeight: "30px" }}
          >
            按固定日期
          </Radio>
        </Radio.Group>
        {formData.useType == 2 ? (
          <div style={{ marginLeft: "15px", marginTop: "10px" }}>
            固定日期 （默认不选表示不限制日期，可以多选）
          </div>
        ) : (
          <div style={{ marginLeft: "15px", marginTop: "10px" }}>固定星期</div>
        )}
      </RadioStyle>
    );
  }

  function setDays() {
    let date1 = formData.beginDate;
    let date2 = formData.endDate;
    let monthBegin = new Date(date1).getMonth();
    let monthEnd = new Date(date2).getMonth();
    let dayBegin = new Date(date1).getDate();
    let dayEnd = new Date(date2).getDate();
    dayList = [];

    if (monthBegin != null && monthEnd != null && monthBegin == monthEnd) {
      for (let i = dayBegin; i <= dayEnd; i++) {
        dayList.push({ value: `${i}`, name: `${i}日` });
      }
    } else {
      for (let i = 0; i < 31; i++) {
        dayList.push({ value: `${i + 1}`, name: `${i + 1}日` });
      }
    }
  }
  function ChannelType({ value, swtichValue, disabled }) {
    const [] = useState(1);
    const { dispatch } = useContext(FullgiftFormStore);

    const onchannelChange = (e) => {
      if (!disabled) {
        action.setFormData({
          dispatch,
          key: "purposeType",
          value: e.target.value,
        });
        action.clearTableList({ dispatch });
      }
    };
    return (
      <RadioStyle>
        <Radio.Group onChange={onchannelChange} value={swtichValue}>
          <Radio
            value={1}
            style={{ marginLeft: "15px", height: "30px", lineHeight: "30px" }}
          >
            门店收银
          </Radio>
          <Radio
            value={2}
            style={{ marginLeft: "10px", height: "30px", lineHeight: "30px" }}
          >
            线上商城
          </Radio>
          <Radio
            value={3}
            style={{ marginLeft: "10px", height: "30px", lineHeight: "30px" }}
          >
            全部
          </Radio>
        </Radio.Group>
      </RadioStyle>
    );
  }
  function DetailedChannelType({ value, swtichValue, disabled }) {
    const [] = useState(1);
    const { dispatch } = useContext(FullgiftFormStore);

    const ondetailedChange = (e) => {
      if (!disabled) {
        action.setFormData({
          dispatch,
          key: "targetType",
          value: e.target.value,
        });
      }
    };
    return (
      <RadioStyle>
        <Radio.Group
          onChange={ondetailedChange}
          value={swtichValue}
          style={{ display: "flex", justifyContent: "start" }}
        >
          <Radio
            value={1}
            style={{ marginLeft: "15px", height: "30px", lineHeight: "30px" }}
          >
            全场商品
          </Radio>
          <Radio
            value={2}
            style={{ marginLeft: "10px", height: "30px", lineHeight: "30px" }}
          >
            指定分类
          </Radio>
          <Radio
            value={3}
            style={{ marginLeft: "10px", height: "30px", lineHeight: "30px" }}
          >
            指定商品
          </Radio>
          {formData.targetType == 3 && !disabled && (
            <div
              style={{
                fontSize: "10px",
                width: "60px",
                height: "30px",
                textAlign: "center",
                lineHeight: "30px",
                background: "#ff746d",
                color: "#fff",
                cursor: "pointer",
              }}
              onClick={() => goodsFunc.setTrue()}
            >
              添加商品
            </div>
          )}
          {formData.targetType == 2 && !disabled && (
            <div
              style={{
                fontSize: "10px",
                width: "60px",
                height: "30px",
                textAlign: "center",
                lineHeight: "30px",
                background: "#ff746d",
                color: "#fff",
                cursor: "pointer",
              }}
              onClick={() => classifyFunc.setTrue()}
            >
              添加分类
            </div>
          )}
        </Radio.Group>
      </RadioStyle>
    );
  }
  let height = document.body.clientHeight - 188;
  useEffect(() => {
    setDays();
  }, [formData.beginDate, formData.endDate]);
  return (
    <div className={styles.root} style={{ height: height + "px" }}>
      <div className={styles.content}>
        <FormSubtitle>基础信息</FormSubtitle>

        <div
          className="custom-radio"
          style={{
            margin: "" + autoRem(1.9375) + " " + autoRem(1.25) + " 0 0",
          }}
        >
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                label="活动名称"
                labelCol={{ span: 3 }}
                name="activityName"
                rules={[
                  {
                    required: true,
                    validator: (_, __, callBack: (error?: string) => void) => {
                      if (!formData.activityName) {
                        callBack("请输入活动名称");
                        return;
                      }
                      callBack();
                    },
                  },
                ]}
              >
                {formData.activityName ? "" : ""}
                <Input
                  maxLength={15}
                  style={{ width: "400px" }}
                  disabled={disabled}
                  value={formData.activityName}
                  autoComplete="off"
                  placeholder="请输入"
                  onChange={(v) => {
                    updateFormField("activityName");
                    action.setFormData({
                      dispatch,
                      key: "activityName",
                      value: v.target.value,
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 3 }}
                label={<span className={styles.alarm}>活动方式</span>}
              >
                <ShopType
                  value={1}
                  swtichValue={
                    formData.activityManner ? formData.activityManner : 1
                  }
                  disabled={disabled || isEdit}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 3 }}
                label={<span className={styles.alarm}>活动设置</span>}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {formData.activityManner == 1 && (
                    <div style={{ display: "flex", justifyContent: "start" }}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {formData.marketingTypes != undefined &&
                          formData.marketingTypes.map((v, index) => (
                            <div style={{ marginBottom: "5px" }}>
                              <span>第{v.stage}级买满</span>
                              <Input
                                key={index}
                                type="number"
                                style={{ width: "70px", margin: "0 10px" }}
                                disabled={disabled}
                                value={v.meetMoney}
                                autoComplete="off"
                                onChange={(e) => {
                                  v.meetMoney = parseFloat(e.target.value);
                                  action.setFormData({
                                    dispatch,
                                    key: "marketingTypes",
                                    value: formData.marketingTypes,
                                  });
                                }}
                              />
                              <span>元, 减免</span>
                              <Input
                                style={{ width: "70px", margin: "0 10px" }}
                                type="number"
                                disabled={disabled}
                                value={v.discountMoney}
                                autoComplete="off"
                                onChange={(e) => {
                                  v.discountMoney = parseFloat(e.target.value);
                                  action.setFormData({
                                    dispatch,
                                    key: "marketingTypes",
                                    value: formData.marketingTypes,
                                  });
                                }}
                              />
                              <span>元</span>
                            </div>
                          ))}
                      </div>
                      <div
                        style={{
                          width: "70px",
                          textAlign: "center",
                          background: "#ff746d",
                          color: "#fff",
                          height: "30px",
                          lineHeight: "30px",
                          margin: "0 8px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (disabled) return false;
                          action.addGradeItem(dispatch, "add");
                        }}
                      >
                        添加等级
                      </div>
                      <div
                        style={{
                          width: "70px",
                          textAlign: "center",
                          background: "#ff746d",
                          color: "#fff",
                          height: "30px",
                          lineHeight: "30px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (disabled) return false;
                          action.addGradeItem(dispatch, "reduce");
                        }}
                      >
                        减少等级
                      </div>
                    </div>
                  )}
                  {formData.activityManner == 2 && (
                    <div style={{ display: "flex", justifyContent: "start" }}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {formData.marketingTypes != undefined &&
                          formData.marketingTypes.map((v, index) => (
                            <div style={{ marginBottom: "5px" }}>
                              <span>买满</span>
                              <Input
                                key={index}
                                type="number"
                                style={{ width: "70px", margin: "0 10px" }}
                                disabled={disabled}
                                defaultValue={v.meetMoney}
                                autoComplete="off"
                                onChange={(e) => {
                                  v.meetMoney = parseFloat(e.target.value);
                                }}
                              />
                              <span>元, 加价</span>
                              <Input
                                style={{ width: "70px", margin: "0 10px" }}
                                type="number"
                                disabled={disabled}
                                defaultValue={v.addMoney}
                                autoComplete="off"
                                onChange={(e) => {
                                  v.addMoney = parseFloat(e.target.value);
                                }}
                              />
                              <span>元，赠送商品</span>
                              <Input
                                style={{ width: "70px", margin: "0 10px" }}
                                type="number"
                                disabled={disabled}
                                defaultValue={v.giveCount}
                                autoComplete="off"
                                onChange={(e) => {
                                  v.giveCount = e.target.value;
                                }}
                              />
                              <span>数量</span>
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                  {formData.activityManner == 3 && (
                    <div style={{ display: "flex", justifyContent: "start" }}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {formData.marketingTypes != undefined &&
                          formData.marketingTypes.map((v, index) => (
                            <div style={{ marginBottom: "5px" }}>
                              <span>第{v.stage}级买满</span>
                              <Input
                                key={index}
                                style={{ width: "70px", margin: "0 10px" }}
                                type="number"
                                disabled={disabled}
                                defaultValue={v.meetCount}
                                autoComplete="off"
                                onChange={(e) => {
                                  v.meetCount = e.target.value;
                                }}
                              />
                              <span>数量, 赠送商品</span>
                              <Input
                                style={{ width: "70px", margin: "0 10px" }}
                                type="number"
                                disabled={disabled}
                                defaultValue={v.giveCount}
                                autoComplete="off"
                                onChange={(e) => {
                                  v.giveCount = e.target.value;
                                }}
                              />
                              <span>数量</span>
                            </div>
                          ))}
                      </div>
                      <div
                        style={{
                          width: "70px",
                          textAlign: "center",
                          background: "#ff746d",
                          color: "#fff",
                          height: "30px",
                          lineHeight: "30px",
                          margin: "0 8px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (disabled) return false;
                          action.addGradeItem(dispatch, "add");
                        }}
                      >
                        添加等级
                      </div>
                      <div
                        style={{
                          width: "70px",
                          textAlign: "center",
                          background: "#ff746d",
                          color: "#fff",
                          height: "30px",
                          lineHeight: "30px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (disabled) {
                            return false;
                          }
                          action.addGradeItem(dispatch, "reduce");
                        }}
                      >
                        减少等级
                      </div>
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Row>
                      <Col span={8} style={{ marginRight: "6px" }}>
                        <Checkbox
                          checked={formData.isSuperimposing}
                          disabled={disabled || isEdit}
                          onChange={(e) => {
                            action.setFormData({
                              dispatch,
                              key: "isSuperimposing",
                              value: e.target.checked,
                            });
                          }}
                        >
                          允许叠加
                        </Checkbox>
                      </Col>
                      <Col span={16} style={{ marginRight: "6px" }}>
                        <Checkbox
                          checked={formData.isMarketing}
                          disabled={disabled || isEdit}
                          onChange={(e) => {
                            action.setFormData({
                              dispatch,
                              key: "isMarketing",
                              value: e.target.checked,
                            });
                          }}
                        >
                          允许与其他优惠同时使用
                        </Checkbox>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                label="分配门店"
                labelCol={{ span: 3 }}
                name="shopName"
                rules={[
                  {
                    required: true,
                    validator(_, __, callback: (error?: string) => void) {
                      if (
                        state.formData.shopIDs == undefined ||
                        state.formData.shopIDs.length == 0
                      ) {
                        callback("请选择分配门店");
                        return;
                      }
                      callback();
                    },
                  },
                ]}
              >
                {formData.shopName ? "" : ""}
                <Input
                  style={{ width: "400px" }}
                  disabled={disabled}
                  value={formData.shopName}
                  autoComplete="off"
                  placeholder="请选择门店"
                  onClick={() => shopFunc.setTrue()}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 3 }}
                label={<span className={styles.alarm}>会员等级</span>}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Checkbox
                    checked={formData.isMemberClass}
                    onChange={onChangeCheck}
                  >
                    不限制
                  </Checkbox>
                  <Select
                    mode="multiple"
                    style={{ width: "400px", marginTop: "10px" }}
                    placeholder="请选择"
                    disabled={disabled || formData.isMemberClass}
                    value={formData.memberClassIDs}
                    onChange={handleChange}
                  >
                    {state.TagList.map((v) => (
                      <Option value={v.id} key={v.id}>
                        {v.gradeName}
                      </Option>
                    ))}
                  </Select>
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 3 }}
                label={<span className={styles.alarm}>活动时间</span>}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span>开始日期 - 结束日期</span>
                  {formData.beginDate == "" || formData.beginDate == null ? (
                    <RangePicker
                      disabled={disabled}
                      disabledDate={disabledDate}
                      style={{ width: "400px", marginTop: "10px" }}
                      onChange={onangeChange}
                    />
                  ) : (
                    <RangePicker
                      disabled={disabled}
                      disabledDate={disabledDate}
                      style={{ width: "400px", marginTop: "10px" }}
                      onChange={onangeChange}
                      value={[
                        moment(formData.beginDate, "YYYY-MM-DD"),
                        moment(formData.endDate, "YYYY-MM-DD"),
                      ]}
                    />
                  )}
                  <span style={{ marginTop: "10px" }}>
                    开始时间段 - 结束时间段
                  </span>
                  {formData.beginTime == "" || formData.beginTime == null ? (
                    <TimePicker.RangePicker
                      disabled={disabled}
                      disabledMinutes={() => {
                        let h = disabledDateTime();
                        if (h) return h.disabledMinutes();
                      }}
                      disabledSeconds={() => {
                        let h = disabledDateTime();
                        if (h) return h.disabledSeconds();
                      }}
                      disabledHours={() => {
                        let h = disabledDateTime();
                        if (h) return h.disabledHours();
                      }}
                      hideDisabledOptions={true}
                      style={{ width: "400px", marginTop: "10px" }}
                      onChange={timePickeronChange}
                    />
                  ) : (
                    <TimePicker.RangePicker
                      style={{ width: "400px", marginTop: "10px" }}
                      disabled={disabled}
                      disabledMinutes={() => {
                        let h = disabledDateTime();
                        if (h) return h.disabledMinutes();
                      }}
                      disabledSeconds={() => {
                        let h = disabledDateTime();
                        if (h) return h.disabledSeconds();
                      }}
                      disabledHours={() => {
                        let h = disabledDateTime();
                        if (h) return h.disabledHours();
                      }}
                      hideDisabledOptions={true}
                      onChange={timePickeronChange}
                      value={[
                        moment(formData.beginTime, "hh-mm-ss"),
                        moment(formData.endTime, "hh-mm-ss"),
                      ]}
                    />
                  )}

                  <WeekType
                    value={1}
                    swtichValue={formData.useType ? formData.useType : 1}
                  />
                  {formData.useType == 2 ? (
                    <Select
                      mode="tags"
                      disabled={disabled}
                      style={{ width: "400px", marginTop: "10px" }}
                      placeholder="请选择"
                      onChange={selectDetaChange}
                      value={formData.useTypeValue ? formData.useTypeValue : []}
                    >
                      {dayList &&
                        dayList.length > 0 &&
                        dayList.map((day, index) => {
                          return (
                            <Option value={day.value} key={index}>
                              {day.name}
                            </Option>
                          );
                        })}
                    </Select>
                  ) : (
                    <Checkbox.Group
                      style={{ width: "100%", marginTop: "10px" }}
                      disabled={disabled}
                      value={
                        Array.isArray(formData.useweekTypeValue)
                          ? formData.useweekTypeValue
                          : []
                      }
                      onChange={onChangeCheckbox}
                    >
                      <Row>
                        <Col
                          span={4}
                          style={{
                            marginRight: "6px",
                            border: "1px solid #eee",
                            textAlign: "center",
                            height: "35px",
                            lineHeight: "35px",
                          }}
                        >
                          <Checkbox value="1">星期一</Checkbox>
                        </Col>
                        <Col
                          span={4}
                          style={{
                            marginRight: "6px",
                            border: "1px solid #eee",
                            textAlign: "center",
                            height: "35px",
                            lineHeight: "35px",
                          }}
                        >
                          <Checkbox value="2">星期二</Checkbox>
                        </Col>
                        <Col
                          span={4}
                          style={{
                            marginRight: "6px",
                            border: "1px solid #eee",
                            textAlign: "center",
                            height: "35px",
                            lineHeight: "35px",
                          }}
                        >
                          <Checkbox value="3">星期三</Checkbox>
                        </Col>
                        <Col
                          span={4}
                          style={{
                            marginRight: "6px",
                            border: "1px solid #eee",
                            textAlign: "center",
                            height: "35px",
                            lineHeight: "35px",
                          }}
                        >
                          <Checkbox value="4">星期四</Checkbox>
                        </Col>
                        <Col
                          span={4}
                          style={{
                            marginRight: "6px",
                            border: "1px solid #eee",
                            textAlign: "center",
                            height: "35px",
                            lineHeight: "35px",
                          }}
                        >
                          <Checkbox value="5">星期五</Checkbox>
                        </Col>
                        <Col
                          span={4}
                          style={{
                            marginRight: "6px",
                            border: "1px solid #eee",
                            textAlign: "center",
                            height: "35px",
                            lineHeight: "35px",
                          }}
                        >
                          <Checkbox value="6">星期六</Checkbox>
                        </Col>
                        <Col
                          span={4}
                          style={{
                            marginRight: "6px",
                            border: "1px solid #eee",
                            textAlign: "center",
                            height: "35px",
                            lineHeight: "35px",
                          }}
                        >
                          <Checkbox value="7">星期日</Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  )}
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                label={<span className={styles.alarm}>使用途径</span>}
                labelCol={{ span: 3 }}
              >
                <ChannelType
                  value={1}
                  swtichValue={formData.purposeType ? formData.purposeType : 1}
                  disabled={disabled || isEdit}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                label={<span className={styles.alarm}>活动明细</span>}
                labelCol={{ span: 3 }}
              >
                <DetailedChannelType
                  value={1}
                  swtichValue={formData.targetType ? formData.targetType : 1}
                  disabled={disabled}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        {formData.targetType == 3 && (
          <DataTable dataList={state.tableList} disabled={disabled} />
        )}
        {formData.targetType == 2 && (
          <DataTableclassity
            dataList={state.classifyList}
            disabled={disabled}
          />
        )}
        {formData.activityManner != 1 && (
          <div>
            <Row gutter={16} style={{ marginTop: "20px" }}>
              <Col span={24}>
                <Form.Item label="赠送商品" labelCol={{ span: 3 }}>
                  <span
                    style={{
                      fontSize: "14px",
                      textAlign: "center",
                      background: "#ff746d",
                      color: "#fff",
                      cursor: "pointer",
                      padding: "5px 7px",
                    }}
                    onClick={() => {
                      if (disabled) return false;
                      goodsFuncAdd.setTrue();
                    }}
                  >
                    添加商品
                  </span>
                </Form.Item>
              </Col>
            </Row>
            <DataTableAdd
              dataList={state.tableListAdd}
              disabled={disabled}
            ></DataTableAdd>
          </div>
        )}
      </div>

      <ChooseShop
        visibleModal={shopState}
        type='checkbox'
        close={(status, r) => {
          if (r) {
            let arr = [];
            let shopNameArr = []
            arr.push(r[0].id);
           r.length>0 && r.map(item=>{
              arr.push(item.id)
shopNameArr.push(item.shopName)
            })
            action.setFormData({ dispatch, key: "shopIDs", value: arr });
            action.setFormData({
              dispatch,
              key: "shopName",
              value: shopNameArr.toString(),
            });
          }
          // updateFormField('shopName');
          shopFunc.setFalse();
        }}
      />
      <ChooseGoods
        visibleModal={goodsState}
        key={formData.purposeType ? formData.purposeType : 1}
        shopID={formData.shopId}
        filter={{
          isMarketing: true,
          specifications: true,
          source:
            formData.purposeType == 1
              ? "CS"
              : formData.purposeType == 2
              ? "WX"
              : formData.purposeType == 3
              ? "PC"
              : "CS",
        }}
        close={(status, r) => {
          if (r) {
            action.addTableItem({ dispatch, list: r });
          }
          goodsFunc.setFalse();
        }}
      />
      <ChooseGoods
        visibleModal={goodsStateAdd}
        filter={{ specifications: true, isMarketing: true }}
        close={(status, r) => {
          if (r) {
            action.addTableItemAdd({ dispatch, list: r });
          }
          goodsFuncAdd.setFalse();
        }}
      />
      <ChooseClassify
        visibleModal={classifyState}
        close={(status, r) => {
          if (r) {
            action.AddclassTableItem({ dispatch, list: r });
          }
          classifyFunc.setFalse();
        }}
      />
    </div>
  );
}
