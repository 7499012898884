import { createContext, useReducer } from "react"


type Dispatch = { type, payload }
export const AfterSalesStore = createContext<IContextProps>(null)
interface IContextProps {
    state: IState;
    dispatch: ({ type }: Dispatch) => void;
}

class Search{
    afterSalesNumber: string
    invoiceNumber: string
    salesStatus: Number | string
    afterSalesType: Number | string
    auditStatus: Number | string
}

interface IState{
    search: Search
}

const initialArgs: IState = {
    search: new Search()
}

function reducer(state: IState, { type, payload }: Dispatch) {
    switch (type) {
        case "SetSearch":
            return {
                ...state,
                search: {
                    ...state.search,
                    ...payload
                }
            }
       
        default:
            return state;
    }
}
export function AfterSalesStoreProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialArgs);

    const v = { state, dispatch };
    return <AfterSalesStore.Provider value={v}>{props.children}</AfterSalesStore.Provider>
}