import { useBoolean } from "ahooks"
import { Row, Col, Form, Input } from "antd"
import { useContext } from "react"
import { ChooseMember } from "../../../../component/choose-member"
import { TextArea } from "../../../../component/text-area"
import { formAction } from "../../action/flowChargeoff/form_action"
import { FormStore } from "../../store/flowChargeoff/form_store"
export function FormBasic({updateFormField}){
    const action = formAction
    const [visibleState,visibleFunc] = useBoolean(false)
    const {state,dispatch} = useContext(FormStore)
    return(
        <>
          <div style={{ margin: "1.9375rem 24px 0 0" }}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="member-name"
              label="选择会员"
              labelCol={{ span: 8 }}
              rules={[
                {
                    required: true,
                    message: "请选择会员",
                },                
              ]}
            >
              <Input
                // allowClear
                maxLength={20}
                autoComplete="off"
                placeholder="请输入会员卡号"
                value={state.formData.memberName}
                onClick={(v) =>  visibleFunc.setTrue() }
                onChange={(v) =>  visibleFunc.setTrue() }
              />
            </Form.Item>
          </Col>
          </Row>
          <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="member-money"
              label="扣款金额"
              labelCol={{ span: 8 }}
              rules={[
                {
                    required: true,
                    message: "请输入扣款金额",
                },                
              ]}
            >
              <Input
                maxLength={20}
                autoComplete="off"
                placeholder="请输入扣款金额"
                value={state.formData.money}
                type='number'
                min={0}
                onChange={(v) =>  {
                    action.setValue({dispatch: dispatch, value: v.target.value, attribute: "Money",})
                } }
              />
            </Form.Item>
          </Col>
          </Row>
          <Row gutter={24}>
          <Col span={16}>
            <Form.Item
              name="member-remark"
              label="备注"
              labelCol={{ span: 6 }}
              rules={[
                {
                    required: true,
                    message: "请输入扣款备注",
                },                
              ]}
            >
                <TextArea
                    rows={3}
                    autoSize={{ minRows: 3, maxRows: 4 }}
                     maxLength={50}
                     autoComplete="off"
                     placeholder="请输入扣款备注"
                     value={state.formData.remark}
                     onChange={(v) =>  {
                         action.setValue({dispatch: dispatch, value: v.target.value, attribute: "Remark",})
                     } }
                />
             
            </Form.Item>
          </Col>
          </Row>
          <ChooseMember
          filter={{balance: true}}
          type='radio'
            visibleModal={visibleState}
            close={(status,r)=>{
                if(r && r.length>0){
                    console.log(r)
                     action.setValue({dispatch: dispatch, value: r[0].id, attribute: "VipId",})
                     action.setValue({dispatch: dispatch, value: r[0].memberName, attribute: "MemberName",})
                     updateFormField("member-name",r[0].memberName);
                     
                }
                visibleFunc.setFalse()
            }}
          />
          </div>
        </>
    )
}