import { Tools } from "../../config";
import { DistributionRecordsLogic } from '../../logic/distributionRecords_login'
interface IChild{
  name: string,
  id:string,
  value:number,
  symbol:string,
  symbolSize:any[],
  itemStyle: object,
  children: any[]
}
class treeDataAction{

  /**设置根节点 */
  getRootNode(item,dispatch){
    let rootData = [
      {
        collapsed: false,
        
        id: item.id,
        name: item.memberName,
        index: 0,
        value:item.addDateTime,
        symbolSize: [60, 60],
        cusField: "category",
        symbol: 'image://'+Tools.getImageAddress(item.headPath),
          // "image://http://www.iconpng.com/png/ecommerce-business/iphone.png",
        itemStyle: {
          normal: {
            label: {
              offset:[0, 50],
              show: true,
              position: "right",
              // formatter: "{b}",
            },
          },
        },
        children: [],
      },
    ];
    dispatch({ type: "getData", payload: rootData });
  }
  paddingData(data,index){
    // console.log(data.id)
    let obj = data;
    obj['id'] = data.id
    obj['name'] = data.memberName
    obj['index'] = index+1
    obj['value'] = data.addDateTime    
    obj['itemStyle'] = {
      normal: {
        label: {
          show: true,
          position: "right",
          formatter: "{b}",
        },
      },
    }
    obj['children'] = []
    obj['symbol'] = 'image://'+ Tools.getImageAddress(data.headPath)
    obj['symbolSize'] = [60,60]
    // console.log(obj)
    return obj
  }
    /**获取关系 */
  async getchild(id,index,dispatch){
      let data =[]
let param  = {
  MemberId: id,
  leven: 1
}
    const r = await  new DistributionRecordsLogic().queryRelation(param)
    if(r.data.statusCode == 200){
     let treedata = r.data.data || []
      if(treedata.length>0){
        treedata.map(item=>{
         let obj = this.paddingData(item,index)
         data.push(obj)
        })
        console.log(data)
      }
    }

      // if(index == 0){
      //     data = [
      //        {
      //          id:'1',
      //     name: "小\n米", //由于label的formatter存在bug，所以无法通过html进行格式化，如果要换行要用\n
      //     value: 1,
      //     symbol: "image://http://pic.58pic.com/58pic/12/36/51/66d58PICMUV.jpg",
      //     // symbolSize: [20, 20],
      //     cusField: "product",         
      //     itemStyle: {
      //       normal: {
      //         label: {
      //           show: true,
      //           position: "right",
      //           formatter: "{b}--->>>", //有bug，formatter不起作用，这个bug看网友求助已经很久了，但是后面更新的版本一直没有解决
      //         },
      //       },
      //     },
      //   },
      //   {
      //     id:'2',
      //     name: "苹果",
      //     symbol: "image://http://www.viastreaming.com/images/apple_logo2.png",
      //     // symbolSize: [20, 20],
      //     cusField: "product",
      //     itemStyle: {
      //       normal: {
      //         label: {
      //           show: false,
      //         },
      //       },
      //     },
      //     value: 1,
      //   },
      //     ]
      // }else if(index == 1){
      //   data = [ {
      //     value: 2,
      //     id:'3',
      //     name: "小米11",
      //     symbol: "circle",
      //     cusField: "product", //自定义属性，演示用，实际开发中可以在后台建模产生series整个data时增加而外属性以供使用
      //     itemStyle: {
      //       normal: {
      //         label: {
      //           show: true,
      //           position: "bottom",
      //           formatter: "{b}--->>>",
      //         },
      //       },
      //     },
      //   },]
      // }
        //  data =  [
        //     {
        //       name: "手机",
        //       value: 0,
        //     //   symbolSize: [60, 70],
        //       cusField: "category",
        //       symbol:
        //         "image://http://www.iconpng.com/png/ecommerce-business/iphone.png",
        //       itemStyle: {
        //         normal: {
        //           label: {
        //             show: true,
        //             position: "right",
        //             formatter: "{b}",
        //           },
        //         },
        //       },
        //       children: [
        //         {
        //           name: "小米", //由于label的formatter存在bug，所以无法通过html进行格式化，如果要换行要用\n
        //           value: 1,
        //           symbol: "image://http://pic.58pic.com/58pic/12/36/51/66d58PICMUV.jpg",
        //           symbolSize: [60, 60],
        //           cusField: "product",
        //           children: [
        //             {
        //               name: "小米11",
        //               symbol: "circle",
        //               cusField: "product", //自定义属性，演示用，实际开发中可以在后台建模产生series整个data时增加而外属性以供使用
        //               itemStyle: {
        //                 normal: {
        //                   label: {
        //                     show: true,
        //                     position: "bottom",
        //                     formatter: "{b}--->>>",
        //                   },
        //                 },
        //               },
        //             },
        //           ],
        //           itemStyle: {
        //             normal: {
        //               label: {
        //                 show: true,
        //                 position: "right",
        //                 formatter: "{b}--->>>", //有bug，formatter不起作用，这个bug看网友求助已经很久了，但是后面更新的版本一直没有解决
        //               },
        //             },
        //           },
        //         },
        //         {
        //           name: "苹果",
        //           symbol: "image://http://www.viastreaming.com/images/apple_logo2.png",
        //           symbolSize: [60, 60],
        //           cusField: "product",
        //           itemStyle: {
        //             normal: {
        //               label: {
        //                 show: false,
        //               },
        //             },
        //           },
        //           value: 1,
        //         },
        //         {
        //             name: "苹果",
        //             symbol: "image://http://www.viastreaming.com/images/apple_logo2.png",
        //             symbolSize: [60, 60],
        //             cusField: "product",
        //             itemStyle: {
        //               normal: {
        //                 label: {
        //                   show: false,
        //                 },
        //               },
        //             },
        //             value: 1,
        //           },
        //       ],
        //     },
        //   ]
        
       dispatch({type:'addChildren',payload:{data:data,id:id,index:index}})
    }
}
export const treeAction = new treeDataAction()