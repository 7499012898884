import { message } from 'antd';
import { EquipmentLogic } from '../../../../logic/equip_logic'
type Init = {
    state;
    dispatch;
    form;
    id: string;
    method: string;
    item;
}
interface InitBasic {
    dispatch;
    search;
}
interface InitEdit {
    state;
    dispatch;
    form;
    id;
    item;
}

class FormAction {
    //
    async init({ state, dispatch, id, method, form ,item}: Init) {
        //
        // dispatch({ type: "LoadTag", payload: await new EquipmentLogic()() });
        await this.initBasic({ dispatch, search: state.search });
        //
        if ("Create" !== method && id) {
            await this.initEdit({ dispatch, state, id, form, item })
        }
    }
    private async initBasic({ search, dispatch }: InitBasic) {
     
    }
    private async initEdit({ dispatch, id, form, item }: InitEdit) {
        //回填表单
        //获取信息
        // let res = await EquipmentLogic.GetFirstModel({},{ID: id})          
        // if(res&&res.data&&res.data.statusCode == 200){
        //     item = res.data.data
        // }   
        // else{
        //     message.error('获取信息失败')
        // }

       

        dispatch({ type: "SetForm", payload: item });

        form.setFieldsValue({
            "ipAddress": item.ipAddress,
            "equipmentName": item.equipmentName,
       
        });

        
    }
    async addSubmit({ state }) {
        const {formData}  = state
        const params ={
            ipAddress: formData.ipAddress,
            equipmentName: formData.equipmentName
        }
         const r =  await new EquipmentLogic().addOperation(params)
         if(r.data.statusCode == 200){
                message.success('添加成功')
                return true
         }else{
             message.error(r.data.returnMessage)
             return false
         }
    }

    async updSubmit({ state, id }) {
        const {formData}  = state
        const params ={
            id:id,
            ipAddress: formData.ipAddress,
            equipmentName: formData.equipmentName
        }
         const r =  await new EquipmentLogic().updateOperation(params)
         if(r.data.statusCode == 200){
                message.success('修改成功')
                return true
         }else{
             message.error(r.data.returnMessage)
             return false
         }
    }

    clearTableList({ dispatch }) {
        dispatch({ type: "ClearTableList" });
    }

    setFormData({ dispatch, key, value }) {
        const params = {
        }
        params[key] = value;
        dispatch({ type: "SetFormData", payload: params })
    }

    reset({ dispatch,form }) {
        form.setFieldsValue({
            "ipAddress": '',
            "equipmentName": '',       
        });
        this.setFormData({dispatch,key:'ipAddress',value:''})
        this.setFormData({dispatch,key:'equipmentName',value:''})
    }
}
export const formAction = new FormAction();