import {
  Cascader,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Form as FormAntd,
  Input,
  Radio,
  Row,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useContext, useEffect, useState } from "react";
import { autoRem } from "../../../../global.style";
import { roleFormAction } from "../../action/role/role_form_action";
import { RoleFormStore } from "../../store/role/role_form_store";

export function RoleFormBasic(title) {
  const { state, dispatch: formDispatch } = useContext(RoleFormStore);
  const action = roleFormAction;
  const { formData } = state;
  var isLook = false;
  if (title.title == "查看用户") {
    isLook = true;
  } else {
    isLook = false;
  }
  return (
    <div style={{ marginTop: "1.9375rem",marginRight: '1.5rem' }}>
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item
            name="role-name"
            label="角色名称"
            labelCol={{ span: 5 }}
            rules={[
              {
                required: true,
                message: "请输入角色名称",
              },
            ]}
          >
            <Input
              disabled={isLook}
              maxLength={20}
              placeholder="请输入角色名称"
              onChange={(v) =>
                action.setValue({
                  dispatch: formDispatch,
                  value: v.target.value,
                  attribute: "Name",
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item
            name="role-remarks"
            label="角色备注"
            labelCol={{ span: 5 }}
            rules={[
              {
                required: true,
                message: "请输入角色备注",
              },
            ]}
          >
            <TextArea
              disabled={isLook}
              placeholder="请输入角色备注"
              rows={2}
              onChange={(v) =>
                action.setValue({
                  dispatch: formDispatch,
                  value: v.target.value,
                  attribute: "Remark",
                })
              }
            ></TextArea>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}
