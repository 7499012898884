import { useEffect, useState } from "react";
import { Button } from "../../../../component/button";
import { Layer } from "../../../../component/layer/layer";
import { ApiCon } from "../../com/apiCon";
import { Table } from "../../../../component/table";
import { ColumnsType } from "antd/lib/table";
import { Tag } from "antd";
interface IForm {
  children?: any;
  // action: FormAction;
  close: (hasUpdate?: boolean) => void;
  searchState?: any;
  goodsNo: string | Number;
}
export function StoreSalesStatsForm({ close, searchState, goodsNo }: IForm) {
  const title = () => {
    return "查看详情";
  };

  const [tableData, setData] = useState([]);
  const [queryState, setQueryState] = useState<{
    pageIndex?: number;
    total?: number;
    pageSize?: number;
  }>({
    pageIndex: 1,
    total: 0,
    pageSize: 10
  });
  const getList = () => {
    let op: any = Object.assign({}, searchState, queryState, {
      seacherKey: goodsNo,
    });
    ApiCon.ShopSaleReportDetails(op).then((res) => {
    //   console.log(res);
      let list = res.dataSource || [];
      setData(list);
      setQueryState({
        total: res.totalCount || 0
      });
    });
  };
  useEffect(() => {
    getList();
    return () => {};
  }, [goodsNo]);
  const columns: ColumnsType = [
    {
      title: "订单号",
      dataIndex: "orderNumber",
      key: "1",
      align: "center",
      width: 200,
    },
    {
        title: "商品名称",
        dataIndex: "goodsName",
        key: "2",
        align: "center",
        width: 150,
      },
    {
      title: "规格条码",
      dataIndex: "barCode",
      key: "3",
      align: "center",
      width: 120,
    },
    {
      title: "商品分类",
      dataIndex: "goodsClassName",
      key: "4",
      align: "center",
      width: 120,
    },   
    {
      title: "规格单位",
      dataIndex: "unitName",
      key: "5",
      align: "center",
      width: 90,
    },   
    {
      title: "商品数量",
      dataIndex: "goodsNumber",
      key: "6",
      align: "center",
      width: 90,
    },   
    {
      title: "实付金额(元)",
      dataIndex: "expenditurePrice",
      key: "7",
      align: "center",
      width: 90,
    },   
    {
      title: "原价(元)",
      dataIndex: "originalPrice",
      key: "8",
      align: "center",
      width: 90,
    },   
    {
      title: "优惠金额(元)",
      dataIndex: "detailDiscountsPrice",
      key: "9",
      align: "center",
      width: 90,
    },   
    
    {
        title: "订单类型",
        dataIndex: "orderTypeString",
        key: "10",
        align: "center",
        width: 80,
      },
    {
      title: "订单状态",
      // dataIndex: 'orderStatusString',
      key: "11",
      align: "center",
      width: 90,
      render: (v, r: any) => (
        <>
          {r.state == 1 && <Tag color="magenta">{r.orderStatusString}</Tag>}
          {r.state == 2 && <Tag color="volcano">{r.orderStatusString}</Tag>}
          {r.state == 3 && <Tag color="geekblue">{r.orderStatusString}</Tag>}
          {r.state == 4 && <Tag color="gold">{r.orderStatusString}</Tag>}
          {r.state == 5 && <Tag color="success">{r.orderStatusString}</Tag>}
          {r.state == 6 && <Tag color="blue">{r.orderStatusString}</Tag>}
          {r.state == 7 && <Tag color="error">{r.orderStatusString}</Tag>}
        </>
      ),
    },
    {
      title: "制单时间",
      dataIndex: "addDateTime",
      key: "12",
      align: "center",
      width: 130,
    },
  ];
  return (
    <Layer width='85%'>
      <Layer.Title onClick={() => close()}>{title()}</Layer.Title>
      <Layer.Body>
        {/* <FormBasic disabled={title() == "查看订单"} /> */}
        <div style={{margin: '15px'}}>
        <Table
          columns={columns}
          dataSource={tableData}
          scroll={{ y: 600 }}
          pagination={{
            pageSize: queryState.pageSize,
            defaultPageSize: 10,
            total: queryState.total,
            onChange: (page, pageSize) => {
              setQueryState({
                pageIndex: page,
              });
              getList();
            },
          }}
        />
        </div>
      </Layer.Body>
      <Layer.Foot borderStyle={{}}>
        <Button type="primary" color="Yellow" onClick={() => close()}>
          返回
        </Button>
      </Layer.Foot>
    </Layer>
  );
}
