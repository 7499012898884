import { TabKeys } from "../../tool/TabKeys";

export const ESSDetailTreeTK: TabKeys[] = [
    {
        key: 'classesName',
        title: '商品类别',
    }
];
export const ESSDetailTK: TabKeys[] = [
    {
        key: 'goodsNo',
        title: '商品编码',
    },
    {
        key: 'goodsName',
        title: '商品名称',
    },
    {
        key: 'unitName',
        title: '商品单位',
    },
    {
        key: '',
        title: '库存明细',
        child: [
            {
                key: 'putNumber',
                title: '库存数量',
            },
            {
                key: 'inventoryPrice',
                title: '库存价',
            },
            {
                key: 'putSumPrice',
                title: '库存总价',
            },
        ],
    },
    {
        key: '',
        title: '报损明细',
        child: [
            {
                key: 'bscount',
                title: '报损总数',
            },
            {
                key: 'bspriceAvg',
                title: '报损平均金额',
            },
            {
                key: 'sumPrice',
                title: '报损总金额',
            },
        ],
    },
    {
        key: '',
        title: '销售明细',
        child: [
            {
                key: 'saleCount',
                title: '销售总数',
            },
            {
                key: 'salepriceAvg',
                title: '销售平均价',
            },
            {
                key: 'saleSumprice',
                title: '销售总金额',
            },
        ],
    },
    {
        key: '',
        title: '退货明细',
        child: [
           
            {
                key: 'returnCount',
                title: '退货总数',
            },
            {
                key: 'returnPricAvg',
                title: '退货平均金额',
            },
            {
                key: 'returnsumPric',
                title: '退货总金额',
            },
        ],
    },
    // {
    //     key: 'putNewPrice',
    //     title: '成本变更金额',
    // },
];