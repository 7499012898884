import { Checkbox, Form as AntdForm, Input } from "antd";
import React, { useState } from "react";
import { useRequest } from "ahooks";
import { useHistory } from "react-router-dom";
import { Button } from "../../../component/button";
import { fetchCodeAction, fetchLoginAction } from "../action/form_action";
import styles from "../../login/style/form.module.scss";

import { message } from "antd";
// import axios from "axios";
import { Tools, axios } from "../../../config";
// 发送短信验证码
export async function getSendCodeStatus(stateTime, state, fun) {
    const { account, passwd, inputCode, rawCode } = state;
    if (state.account == '') {
        message.error('请输入账号')
        return false
    }
    if (state.passwd == '') {
        message.error('请输入新密码')
        return false
    }
    if (state.inputCode == '') {
        message.error('请输入图形码')
        return false
    }
    let time = 60
    if (stateTime != '重新获取' && stateTime != '获取验证码') {
        return false
    }
    const { data, status } = await axios({
        method: "GET",
        url: Tools.getApiAddress("phone_code"),
        params: {
            "PhoneNumber": account,
            "Uuid": rawCode.captchaGUID,
            "Code": inputCode,
            "CheckID": false
        }
    })
    if (200 == status) {
        if (200 != data.statusCode) {
            message.error(data.data ? data.data : data.returnMessage)
            return false
        } else {
            message.success('发送成功');
        }
    }
    // 倒计时
    let countDown = setInterval(() => {
        time = time - 1
        if (time == 0) {
            clearInterval(countDown)
            fun('重新获取')
        } else {
            fun(time + '秒')
        }
    }, 1000);

}
export function Form() {
    const [state, setData] = useState({ account: "", passwd: "", code: "", inputCode: "", phoneCode: '', rawCode: "" });
    const [stateTime, setTimeData] = useState('获取验证码');
    useRequest(() => fetchCodeAction(state, setData))
    const his = useHistory();
    
    return (
        <div className={styles.root}>
            <AntdForm onFinish={() => fetchLoginAction(state, setData, his)}>

                <AntdForm.Item name="username" rules={[{ required: true, message: "请输入账号" }]}>
                    <Input autoComplete="off" prefix={<i className={styles.phone} style={{ height: 27 }}></i>} placeholder="请输入账号" onInput={(e) => setData({ ...state, account: e.currentTarget.value })} />
                </AntdForm.Item>

                <AntdForm.Item name="passworld" rules={[{ required: true, message: "请输入新密码" }]}>
                    <Input type="password" autoComplete="off" prefix={<i className={styles.passwd} style={{ height: 27 }}></i>} placeholder="请输入新密码" onInput={(e) => setData({ ...state, passwd: e.currentTarget.value })} />
                </AntdForm.Item>
                <div className={styles.item_group}>

                    <AntdForm.Item name="graphCode" rules={[{ required: true, message: "请输入图形码" }]}>
                        <Input autoComplete="off" prefix={<i className={styles.safe} style={{ height: 27 }}></i>} placeholder="请输入图形码" onInput={(e) => setData({ ...state, inputCode: e.currentTarget.value })} maxLength={4} />
                    </AntdForm.Item>

                    <AntdForm.Item className={styles.code}>
                        <img src={state.code} onClick={() => fetchCodeAction(state, setData)} />
                    </AntdForm.Item>

                    <div style={{ clear: "both" }}></div>
                </div>

                <div className={styles.item_group} style={{ marginBottom: 0 }}>

                    <AntdForm.Item style={{ marginBottom: 0 }} name="code">
                        <Input autoComplete="off" prefix={<i className={styles.phone} style={{ height: 27 }}></i>} placeholder="请输入验证码" onInput={(e) => setData({ ...state, phoneCode: e.currentTarget.value })} maxLength={6} />
                    </AntdForm.Item>

                    <div style={{ marginBottom: 0, lineHeight: '36px' }} className={styles.btn_code} onClick={() => {
                        getSendCodeStatus(stateTime, state, (time) => {
                            setTimeData(time)
                        })
                    }}>
                        <span>{stateTime}</span>
                    </div>

                    <div style={{ clear: "both" }}></div>
                </div>

                <AntdForm.Item className="sure" style={{ marginBottom: 0, marginTop: "1.2rem" }}>
                    <Button color="Red" htmlType="submit" >确定</Button>
                </AntdForm.Item>

                <AntdForm.Item style={{ marginBottom: 5 }} name="protocol">
                    <a href="#" style={{ color: "#ff746d", float: "right" }} onClick={() => his.replace("login")}><span style={{ fontSize: 14, color: "#666666" }}>前往登录？</span> </a>
                </AntdForm.Item>

            </AntdForm>
        </div>
    )
}