import { TabKeys } from "../../tool/TabKeys";

export const goodsTreeDataTablKey: (TabKeys & { subtotal?: string })[] = [
    {
        key: 'dianpu',
        title: '门店名称',
        // subtotal: '门店小计',
    },
    // {
    //     key: 'leibie',
    //     title: '商品类别',
    //     subtotal: '',
    // },
];

export const googsDataTablKey: TabKeys[] = [
    {
        key: 'className',
        title: "商品类别"
    },
    {
        key: 'totalMoney',
        title: "销售额"
    },
    {
        key: 'preferentialMoney',
        title: "优惠金额"
    },
    {
        key:'orderNum',
        title:'订单数量'
    },
    {
        key:'goodsNum',
        title:'销售数量'
    }
   
];