import message from "antd/lib/message"
import { DataTableAction } from "../../../../component/table/action/data_table_action"
import { PrintSetLogic } from "../../../../logic/print_set_logic"

class EquipAction extends DataTableAction {

    async delRow({dispatch, id}) {
        const r = await new PrintSetLogic().delete(id);

        if (r.data.statusCode == 200) {
            message.success("删除成功")
        }else{
            message.error("删除失败")
        }
    }

    delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {
       
    }
    async loadPage({ dispatch, pageIndex, pageSize, search }) {
        this.setLoading({dispatch,loading: true})
        const { searchKey,ticketsType } = search;
        const params: any = {
            "pageIndex": pageIndex,
            "pageSize": pageSize,
            // "seachKey":SearchKey,
            "ticketsType":ticketsType,
            "orderBy":'',
            "sort":'',
        }
        // 档案列表
        const r = await new PrintSetLogic().queryList(params);

        if (r.data.statusCode == 200) {
            const dataList = r.data.data.dataSource;         
            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: dataList, pageSize });
        }
        this.setLoading({dispatch,loading: false})

    }
    setCondition({ dispatch, key, value }) {
        const params = {
        }
            params[key] = value;
        dispatch({ type: "SetSearch", payload: params })
    }
   async setInuse({dispatch,ID}){
    this.setLoading({dispatch,loading: true})
    const r = await new PrintSetLogic().setInuse({ID:ID})
    this.setLoading({dispatch,loading: false})
    if(r.data.statusCode === 200){
        message.success('设置成功')
    }else{
        message.error(r.data.returnMessage)
    }
    }
  
}
export const fileAction = new EquipAction();