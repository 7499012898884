import { message } from "antd";
// import axios from "axios";
import Cookies from "js-cookie";
import { DataTableAction } from "../../table/action/data_table_action";
import { Tools, axios } from "../../../config";
import { PrecisionLogic } from "../../../logic/precision_logic";
class ChooseAction extends DataTableAction {
    async delRow(id) {
      
    }
    delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {
        
    }
    async loadPage({ dispatch, pageIndex, pageSize, search ,precisionId}) {

        const { seacherKey} = search;
        const params: any = {
            "pageIndex": pageIndex,
            "pageSize": pageSize,
            "seacherKey":seacherKey,
            "id":precisionId,
            "orderBy":'',
            "sort":'',
        }
        // 档案列表
        const r = await new PrecisionLogic().selList({...params})

        if (r.data.statusCode == 200) {
            const dataList = r.data.data.dataSource;        
            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: dataList, pageSize });
        }

    }
 
    setCondition({ dispatch, key, value ,precisionId}) {
        const params = {
        }
        params[key] = value;
        params['id'] = precisionId;
        dispatch({ type: "SetSearch", payload: {...params} })
    }
 

}
export const chooseCouponAction = new ChooseAction();