import React, { useEffect, useState } from "react";
import { DataListStoreProvider } from "../../component/data-list/store/data_table_store";
import { Layout } from "../../component/layout";
import { TabsStyle, TabList, Tab, TabPanel } from "../../component/react-tabs";
import { DataTableStoreProvider } from "../../component/table/store/data_table_store";
import { AlarmStoreProvider } from "./store/alarm_store";
import { Alarm } from "./view/alarm/alarm";
import { AlarmValue } from "./view/alarm_value/alarmvalue";
import { Cost } from "./view/cost/cost";
import { Inventory } from "./view/inventory/inventory";
import { Report } from "./view/report/report";
import { Warehousing } from "./view/in-stock/warehousing";
import { WarehousingStoreProvider } from "./store/in-stock/warehousing_store";
import { FileGoods } from "./view/file/goods/goods";
import { FileShop } from "./view/file/shop/shop";
import { FileRunwater } from "./view/file/runwater/runwater";
import { FileStoreProvider } from "./store/file/file_goods_store";
import { FileShopStoreProvider } from "./store/file/file_shop_store";
import { FileRunwaterStoreProvider } from "./store/file/file_runwater_store";
import { AlarmValueStoreProvider } from "./store/alarm_value_store";
import { OutStock } from "./view/out-stock/out_stock";
import { OutStockStoreProvider } from "./store/out-stock/out_stock_store";
import { InventoryStoreProvider } from "./store/inventory/inventory_store";
import { ReportStoreProvider } from "./store/report/report_store";
import { CostStoreProvider } from "./store/cost/cost_store";
import { MenuLogic } from "../../logic/menu_logic";

import { ExpiredAlarm } from "./view/expireAlarm/index";
import { ExpiredAlarmStoreProvider } from "./store/expired-alarm/file_store";

import { Purchase } from './view/purchase/file'
import { PurchaseStoreProdiver } from "./store/purchase/purchase_store";

// 预警值设置
import { AlarmSet } from "./view/alarm_set/file";
import {  AlarmSetStoreProvider } from "./store/alarm_set/alarmSet_store";
// 售后单
import { InvioceAfterSales } from './view/afterSales/file'
import { AfterSalesStoreProvider } from './store/afterSale/file_store'
export function InStock() {
  const [menuLists, setData] = useState([]);
  useEffect(() => {
    (async function () {
      let r = await new MenuLogic().menuData();
      var arr = [];

      if (!r) return;

      if (r.length > 0) {
        r = r.filter(function (v) {
          return !v.hidden;
        });
        for (let i = 0; i < r.length; i++) {
          if (r[i].path == "/in-stock" && r[i].children) {
            arr = r[i].children.filter(function (v) {
              return !v.hidden;
            });
          }
        }
      }
      // arr.push({
      //   name:'售后单',
      //   component: "AfterSale",
      //   viewPower:''
      // })
      setData(arr);
    })();

    return () => {};
  }, []);
  return (
    // <Layout fullWidth={true} >
    <TabsStyle style="Full">
      <TabList>
        {menuLists.map((v) => (
          <Tab key={v.name}>{v.name}</Tab>
        ))}
      </TabList>
      {menuLists.map((v) =>
        v.component == "Dossier" ? (
          //库存档案
          <TabPanel key={v.viewPower}>
            <TabsStyle style="BottomLine">
              <TabList>
                <Tab>
                  <span>实时库存(按商品)</span>
                  <hr />
                </Tab>
                <Tab>
                  <span>实时库存(按门店)</span>
                  <hr />
                </Tab>
                <Tab>
                  <span>库存流水</span>
                  <hr />
                </Tab>
                <hr />
              </TabList>
              <TabPanel>
                {/* 实时库存(按商品) */}
                <FileStoreProvider>
                  <DataTableStoreProvider>
                    <FileGoods viewPower={v.viewPower} />
                  </DataTableStoreProvider>
                </FileStoreProvider>
              </TabPanel>
              <TabPanel>
                {/* 实时库存(按门店) */}
                <FileShopStoreProvider>
                  <DataTableStoreProvider>
                    <FileShop viewPower={v.viewPower} />
                  </DataTableStoreProvider>
                </FileShopStoreProvider>
              </TabPanel>
              <TabPanel>
                {/* 库存流水 */}
                <FileRunwaterStoreProvider>
                  <DataTableStoreProvider>
                    <FileRunwater viewPower={v.viewPower} />
                  </DataTableStoreProvider>
                </FileRunwaterStoreProvider>
              </TabPanel>
            </TabsStyle>
          </TabPanel>
        ) : v.component == "AlarmValue" ? (
          //预警值设置
          <TabPanel key={v.viewPower}>
            {/* <AlarmValueStoreProvider>
              <DataTableStoreProvider>
                <DataListStoreProvider>
                  <AlarmValue />
                </DataListStoreProvider>
              </DataTableStoreProvider>
            </AlarmValueStoreProvider> */}
            <AlarmSetStoreProvider>
              <DataTableStoreProvider>
                <DataListStoreProvider>
                  <AlarmSet viewPower={v.viewPower}/>
                </DataListStoreProvider>
              </DataTableStoreProvider>
            </AlarmSetStoreProvider>
          </TabPanel>
        ) : v.component == "Alarm" ? (
          //库存预警
          <TabPanel key={v.viewPower}>
            <AlarmStoreProvider>
              <DataTableStoreProvider>
                <DataListStoreProvider>
                  <Alarm />
                </DataListStoreProvider>
              </DataTableStoreProvider>
            </AlarmStoreProvider>
          </TabPanel>
        ) : v.component == "Warehousing" ? (
          //入库管理
          <TabPanel key={v.viewPower}>
            <WarehousingStoreProvider>
              <DataTableStoreProvider>
                <Warehousing viewPower={v.viewPower} />
              </DataTableStoreProvider>
            </WarehousingStoreProvider>
          </TabPanel>
        ) : v.component == "OutStock" ? (
          //出库管理
          <TabPanel key={v.viewPower}>
            <OutStockStoreProvider>
              <DataTableStoreProvider>
                <OutStock viewPower={v.viewPower} />
              </DataTableStoreProvider>
            </OutStockStoreProvider>
          </TabPanel>
        ) : v.component == "Inventory" ? (
          //库存盘点
          <TabPanel key={v.viewPower}>
            <InventoryStoreProvider>
              <DataTableStoreProvider>
                <Inventory viewPower={v.viewPower} />
              </DataTableStoreProvider>
            </InventoryStoreProvider>
          </TabPanel>
        ) : v.component == "Report" ? (
          //报损报益
          <TabPanel key={v.viewPower}>
            <ReportStoreProvider>
              <DataTableStoreProvider>
                <Report viewPower={v.viewPower} />
              </DataTableStoreProvider>
            </ReportStoreProvider>
          </TabPanel>
        ) : v.component == "Cost" ? (
          //成本变更
          <TabPanel key={v.viewPower}>
            <CostStoreProvider>
              <DataTableStoreProvider>
                <Cost viewPower={v.viewPower} />
              </DataTableStoreProvider>
            </CostStoreProvider>
          </TabPanel>
        ) : v.component == "InventoryExpired" ? (
          //成本变更
          <TabPanel key={v.viewPower}>
            <ExpiredAlarmStoreProvider>
              <DataTableStoreProvider>
                <ExpiredAlarm viewPower={v.viewPower} />
              </DataTableStoreProvider>
            </ExpiredAlarmStoreProvider>
          </TabPanel>
        ) : v.component == "Purchase" ? (
          //采购
          <TabPanel key={v.viewPower}>
            <PurchaseStoreProdiver>
              <DataTableStoreProvider>
                <Purchase  viewPower={v.viewPower} />
              </DataTableStoreProvider>
            </PurchaseStoreProdiver>
          </TabPanel>
        ): v.component == "AfterSale" ? (
          //售后
          <TabPanel key={v.viewPower}>
            <AfterSalesStoreProvider>
              <DataTableStoreProvider>
                <InvioceAfterSales  viewPower={v.viewPower} />
              </DataTableStoreProvider>
            </AfterSalesStoreProvider>
          </TabPanel>
        ): (
          <TabPanel key={'none'}>
           <div></div>
          </TabPanel> //
        )
      )}
    </TabsStyle>
    // </Layout>
  );
}
