
import { message } from "antd";
import { GradeLogic } from "../../../../logic/grade_logic";
import { FormAttribute } from "../../store/grade/form_store";

type Init = {
    state;
    dispatch;
    form;
    editId: string;
    seeId: string;
}
interface InitBasic {
    dispatch;
    state;
    form
}
interface InitEdit {
    state;
    dispatch;
    form;
    id;
}
interface ISetValue {
    attribute: FormAttribute;
    value: string | number | any[] | boolean;
    dispatch;
}
var id = ''
export class FormAction {

    private gradeLgc = new GradeLogic();

    async init({ state, dispatch, seeId, editId, form }: Init) {
        await this.initBasic({ dispatch, form, state });
        if (editId || seeId) {
            await this.initEdit({ dispatch, state, id: editId || seeId, form })
        }
        id = editId || seeId
    }
    private async initBasic({dispatch, form }: InitBasic) {
        this.reset({dispatch, form})
    }
    private async initEdit({ dispatch, id, form }: InitEdit) {
        // 回填状态
        const item = await this.gradeLgc.selData(id);

        // selectClasses
        dispatch({ type: "SetForm", payload: item });
      
        // 填充节点
        form.setFieldsValue({
            "grade-name": item.gradeName,
            "remark": item.remark,
            "grade-useProportion": item.useProportion,
            "isLock": item.isLock,
            "classLevel": item.classLevel,
            "exConsumptionMoney": item.exConsumptionMoney
        });
    }
    reset({ dispatch, form }) {
        // selectClasses
        dispatch({
            type: "SetForm", payload: {
                gradeName: "",
                remark: "",
                useProportion:'',
                isLock: false,
                classLevel: null,
                exConsumptionMoney: undefined
            }
        });

        // 填充节点
        form.setFieldsValue({
            "grade-name": "",
            "remark": "",
            "useProportion":"",
            "isLock": false,
            "classLevel": null,
            "exConsumptionMoney": undefined
        });
    }
    setValue({ attribute, value, dispatch }: ISetValue) {
        dispatch({ type: "Change_FormData", payload: { attribute, value } });
    }
    private paddingData(state) {
        let params: any = {};
        {
            const { id, gradeName, remark, useProportion, isLock, classLevel, exConsumptionMoney } = state;
            params = {
                id,
                gradeName,
                remark,
                useProportion: (useProportion!== null && useProportion!==undefined && useProportion !=='') ?useProportion:100,
                isLock,
                classLevel: classLevel? classLevel: null,
                exConsumptionMoney: exConsumptionMoney?exConsumptionMoney: 0
            }
        }
        return params;
    }
    async addSubmit({ state }) {
        const r = await this.gradeLgc.addData(this.paddingData(state));
        if (r.data.statusCode == 200) {
            message.success("保存成功");
        } else {
            message.error(r.data.message);
        }
        return r.data.statusCode == 200;
    }
    // 更新等级
    async updSubmit({ state }) {
        state['id'] = id
        const r = await this.gradeLgc.updData(this.paddingData(state));
        if (r.data.statusCode == 200) {
            message.success("更新成功");
        } else {
            message.error(r.data.message);
        }
        return r.data.statusCode == 200;
    }
}
export const formAction = new FormAction();