import { PaymentCon } from "./paymentCon";
import {
  Upload,
  Col,
  Form,
  Form as FormAntd,
  Input,
  Radio,
  Row,
  Select,
  message,
  Button as AntdButton,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import { ApiCon } from "./apiCon";
import moment from "moment";

const { Option } = Select;

/**
 * 微信表单
 */
export function JLJHForm({ disabled }) {
  const [_, update] = useState(0);
  const [form] = Form.useForm();

  //填充数据
  useEffect(() => {
    ApiCon.getJLJH().then((d) => {
      console.log(d);
      if (!d) {
        return;
      }
      //填充form表单
      form.setFieldsValue({
        ...d,
      });
    });
  }, []);

  const props = {
    maxCount: 1,
    beforeUpload(file) {
      return ApiCon.uplodaFile(file)
        .then((d) => {
          form.setFieldsValue({
            pemStorage: d,
          });
          return true;
        })
        .catch((e) => {
          message.error(e);
          return false;
        });
    },
  };

  return (
    <>
      <Form form={form}>
        <div style={{ margin: "1.9375rem 0 0 0" }}>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item name="mchID" label="商户号" labelCol={{ span: 5 }}>
                <Input placeholder="请输入商户号" disabled={disabled} />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
      <PaymentCon
        save={() => {
          if (disabled) {
            return false;
          }
          form.validateFields().then(() => {
            ApiCon.updateJLJH({
              ...form.getFieldsValue(),
            })
              .then(() => {
                message.success("保存成功");
              })
              .catch((e) => {
                message.error(e);
              });
          });
        }}
        reset={() => {
          form.resetFields();
        }}
      />
    </>
  );
}
