import { useEffect } from "react";
import { useContext } from "react";
import { ITopicValue, TopicStore } from "../store/topic";
import styles from "../style/blank.module.scss";
import { Slider } from "antd";
import { TopicDataAction } from "../action/TopicDataAction";
import { comConType } from "..";

interface IData {
  /** 高度 */
  height: number;
}

/**
 * 辅助空白
 * @param props
 */
export function Blank(props: { id: number, con: comConType }) {
  const topicStoreState = useContext(TopicStore);

  let actionArg: [ITopicValue, number] = [topicStoreState, props.id];
  const data: IData = TopicDataAction.getData<IData>(...actionArg);
  useEffect(() => {
    if (!data) {
      TopicDataAction.setData<IData>(...actionArg, {
        height: 10,
      });
    }
  });
  if (!data) {
    return <div></div>;
  }

  if (props.con == 'show') {
    return (<div className={styles.blank}>
      <div className={styles.content} style={{ height: data.height + "px" }}></div>
    </div>);
  } else {
    return (
      <>
        <div className={styles.blank_ctrl}>
          <span>高度</span>
          <Slider
            value={data.height}
            onChange={(value) => {
              data.height = value;
              TopicDataAction.setData(...actionArg, data);
            }}
            max={100}
            min={10}
            style={{ width: "200px", margin: "0px 20px" }}
          />
          <span>{data.height}px</span>
        </div>
      </>
    );
  }
}
