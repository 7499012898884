import {
  Button,
  Checkbox,
  Col,
  Form,
  Form as FormAntd,
  Input,
  Radio,
  Row,
  Select,
  TimePicker,
  DatePicker,
  Tag,
  message,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { FormSubtitle } from "../../../commodity/view/form_subtitle";
import styles from "../../style/inventory_goods_formbasic.module.scss";

import img_del from "../../../../assets/images/shanchu.png";
import { warehousingformAction } from "../../action/discount/form_action";
import { useBoolean } from "ahooks";
import { ChooseGoods } from "../../../../component/choose-goods";
import { ChooseClassify } from "../../../../component/choose-classify";
import { Table } from "../../../../component/table";
import { ChooseShop } from "../../../../component/choose-shop";
import { DiscountFormStore } from "../../store/discount/form_store";
import { autoRem } from "../../../../global.style";
import { useState } from "react";
import { RadioStyle } from "../../../../component/radio-style";
import moment from "moment";
import { NumberTool } from "../../../../tool/numberTool";
let dayList = [];
const { Option } = Select;
const { RangePicker } = DatePicker;
export function DataTable({ dataList, disabled }) {
  const { dispatch } = useContext(DiscountFormStore);
  const action = warehousingformAction;

  const columns: ColumnsType = [
    {
      title: "操作",
      dataIndex: "serial",
      key: "1",
      align: "center",
      width: 100,
      render: (v, r, i) => (
        <div>
          <img
            src={img_del}
            style={{ width: 20 }}
            onClick={() => {
              console.log(v)
              !disabled && action.delTableItem(dispatch, v);
            }}
          />
        </div>
      ),
    },
    {
      title: "规格条码",
      dataIndex: "barCode",
      key: "2",
      align: "center",
    },
    {
      title: "商品名称",
      dataIndex: "goodsName",
      key: "3",
      align: "center",
    },
    {
      title: "商品分类",
      dataIndex: "goodsClassName",
      key: "4",
      align: "center",
    },
    {
      title: "单位",
      // dataIndex: 'unitName',
      key: "5",
      align: "center",
      render: (v) => {
        return (
          <>
            <span>
              {v.goodsUnitName}
            </span>
          </>
        );
      },
    },
    // {
    //   title: "状态",
    //   dataIndex: "status",
    //   key: "6",
    //   align: "center",
    //   render: (v) => (
    //     <>
    //       {v == 1 && <Tag color="success">正常</Tag>}
    //       {v == 2 && <Tag color="error">停售</Tag>}
    //     </>
    //   ),
    // },
  ];
  return <Table dataSource={dataList} columns={columns} />;
}

export function DataTableclassity({ dataList, disabled }) {
  const { dispatch } = useContext(DiscountFormStore);
  const action = warehousingformAction;

  const columns: ColumnsType = [
    {
      title: "操作",
      dataIndex: "serial",
      key: "1",
      align: "center",
      width: 100,
      render: (v, r, i) => (
        <div>
          <img
            src={img_del}
            style={{ width: 20 }}
            onClick={() => {
              !disabled && action.delTableclassItem(dispatch, v);
            }}
          />
        </div>
      ),
    },
    {
      title: "分类编码",
      dataIndex: "classCode",
      key: "2",
      align: "center",
    },
    {
      title: "商品分类",
      dataIndex: "goodsClassName",
      key: "3",
      align: "center",
    },
  ];
  return <Table dataSource={dataList} columns={columns} />;
}

export function InventoryGoodsFormBasic({ title, disabled, updateFormField }) {
  const [shopState, shopFunc] = useBoolean(false);
  const [goodsState, goodsFunc] = useBoolean(false);
  const [classifyState, classifyFunc] = useBoolean(false);
  const [clearGoods, clearFunc] = useBoolean(false);
  const { state, dispatch } = useContext(DiscountFormStore);
  const { formData } = state;
  const action = warehousingformAction;
  var isLook = false;
  if (title == "预览折扣") {
    isLook = true;
  } else {
    isLook = false;
  }
  let isEdit = false;
  if (title == "编辑折扣") {
    isEdit = true;
  } else isEdit = false;
  const onChangeCheck = (e) => {
    action.setFormData({ dispatch, key: "isLimit", value: e.target.checked });
    if (e.target.checked) {
      action.setFormData({ dispatch, key: "useMemberClassID", value: [] });
    }
  };
  const handleChange = (e) => {
    action.setFormData({ dispatch, key: "useMemberClassID", value: e });
  };
  const selectDetaChange = (e) => {
    if (formData.useType == 2) {
      action.setFormData({ dispatch, key: "useTypeValue", value: e });
    }
  };
  // 时间段选择
  const timePickeronChange = (time, timeString) => {
    action.setFormData({
      dispatch,
      key: "activityStartTime",
      value: timeString[0],
    });
    action.setFormData({
      dispatch,
      key: "activityEndTime",
      value: timeString[1],
    });
  };
  const range = (start, end) => {
    const result = [];

    for (let i = start; i < end; i++) {
      result.push(i);
    }

    return result;
  };

  const disabledDateTime = () => {
    // let year = new Date(date1).getFullYear;
    // let yearNow = new Date().getFullYear;
    let hours = moment().hours(); //0~23

    let minutes = moment().minutes(); //0~59

    let seconds = moment().seconds(); //0~59
    let time;
    //当日只能选择当前时间之后的时间点
    if (
      formData.activityEndDate &&
      moment(formData.activityEndDate) == moment()
    ) {
      time = {
        disabledHours: () => range(0, hours),

        disabledMinutes: () => range(0, minutes + 1),

        disabledSeconds: () => range(0, seconds + 1),
      };
      return time;
    }
  };

  // 日期段选择
  const onangeChange = (time, timeString) => {
    console.log(timeString);
    action.setFormData({
      dispatch,
      key: "activityStartDate",
      value: timeString[0],
    });
    action.setFormData({
      dispatch,
      key: "activityEndDate",
      value: timeString[1],
    });
    if (formData.useType == 2) {
      action.setFormData({ dispatch, key: "useTypeValue", value: "" });
    }
    setDays();
  };
  const disabledDate = (current) => {
    return current < moment().startOf("day");
  };
  const onChangeCheckbox = (e) => {
    if (formData.useType != 2) {
      action.setFormData({ dispatch, key: "useweekTypeValue", value: e });
      action.setFormData({ dispatch, key: "useTypeValue", value: e });
    }
  };

  function setDays() {
    let date1 = formData.activityStartDate;
    let date2 = formData.activityEndDate;

    let monthBegin = new Date(date1).getMonth();
    let monthEnd = new Date(date2).getMonth();
    let dayBegin = new Date(date1).getDate();
    let dayEnd = new Date(date2).getDate();
    dayList = [];
    if (monthBegin != null && monthEnd != null && monthBegin == monthEnd) {
      for (let i = dayBegin; i <= dayEnd; i++) {
        dayList.push({ value: `${i}`, name: `${i}日` });
      }
    } else {
      for (let i = 0; i < 31; i++) {
        dayList.push({ value: `${i + 1}`, name: `${i + 1}日` });
      }
    }
  }

  const count = (key) =>
    state.tableList.map((v) => v[key]).reduce((p, c) => p + c, 0);
  function ShopType({ value, swtichValue, disabled }) {
    const [] = useState(1);
    const { dispatch } = useContext(DiscountFormStore);

    const onChange = (e) => {
      if (!disabled) {
        action.addGradeItem(dispatch, "addOne");
        action.setFormData({
          dispatch,
          key: "discountType",
          value: e.target.value,
        });
      }
    };
    return (
      <RadioStyle>
        <Radio.Group
          onChange={onChange}
          value={swtichValue}
          style={{ marginTop: "10px" }}
        >
          <Radio
            value={1}
            style={{ marginLeft: "15px", height: "30px", lineHeight: "30px" }}
          >
            直接折扣
          </Radio>
          {formData.purposeType != 2 && formData.purposeType != 3 && (
            <Radio
              value={2}
              style={{ marginLeft: "10px", height: "30px", lineHeight: "30px" }}
            >
              满额折扣
            </Radio>
          )}
        </Radio.Group>
        {formData.discountType == 2 ? (
          <div style={{ marginLeft: "3px", marginTop: "10px" }}>
            注：针对设置的商品，买满多少金额进行折扣，例如：满100元打9折
          </div>
        ) : (
          <div style={{ marginLeft: "15px", marginTop: "10px" }}>
            注：针对设置的商品，进行折扣，例如：全场95折
          </div>
        )}
      </RadioStyle>
    );
  }
  function WeekType({ value, swtichValue }) {
    const [] = useState(1);
    const { dispatch } = useContext(DiscountFormStore);

    const onChange = (e) => {
      if (!disabled) {
        action.setFormData({ dispatch, key: "useTypeValue", value: [] });
        action.setFormData({ dispatch, key: "useType", value: e.target.value });
      }
    };
    return (
      <RadioStyle>
        <Radio.Group
          onChange={onChange}
          value={swtichValue}
          style={{ marginTop: "10px" }}
        >
          <Radio
            value={1}
            style={{ marginLeft: "15px", height: "30px", lineHeight: "30px" }}
          >
            按固定星期
          </Radio>
          <Radio
            value={2}
            style={{ marginLeft: "10px", height: "30px", lineHeight: "30px" }}
          >
            按固定日期
          </Radio>
        </Radio.Group>
        {formData.useType == 2 ? (
          <div style={{ marginLeft: "15px", marginTop: "10px" }}>
            固定日期 （默认不选表示不限制日期，可以多选）
          </div>
        ) : (
          <div style={{ marginLeft: "15px", marginTop: "10px" }}>固定星期</div>
        )}
      </RadioStyle>
    );
  }
  function ChannelType({ value, swtichValue, disabled }) {
    const [] = useState(1);
    const { dispatch } = useContext(DiscountFormStore);

    const onchannelChange = (e) => {
      if (!disabled) {
        action.setFormData({
          dispatch,
          key: "purposeType",
          value: e.target.value,
        });
        action.clearTableList({ dispatch });
      }
    };
    return (
      <RadioStyle>
        <Radio.Group onChange={onchannelChange} value={swtichValue}>
          <Radio
            value={1}
            style={{ marginLeft: "15px", height: "30px", lineHeight: "30px" }}
          >
            门店收银
          </Radio>
          <Radio
            value={2}
            style={{ marginLeft: "10px", height: "30px", lineHeight: "30px" }}
          >
            线上商城
          </Radio>
          <Radio
            value={3}
            style={{ marginLeft: "10px", height: "30px", lineHeight: "30px" }}
          >
            全部
          </Radio>
        </Radio.Group>
      </RadioStyle>
    );
  }
  function DetailedChannelType({ value, swtichValue }) {
    const [] = useState(1);
    const { dispatch } = useContext(DiscountFormStore);

    const ondetailedChange = (e) => {
      if (!disabled) {
        action.setFormData({ dispatch, key: "range", value: e.target.value });
      }
    };
    return (
      <RadioStyle>
        <Radio.Group
          onChange={ondetailedChange}
          value={swtichValue}
          style={{ display: "flex", justifyContent: "start" }}
        >
          <Radio
            value={1}
            style={{ marginLeft: "15px", height: "30px", lineHeight: "30px" }}
          >
            全场商品
          </Radio>
          <Radio
            value={2}
            style={{ marginLeft: "10px", height: "30px", lineHeight: "30px" }}
          >
            指定分类
          </Radio>
          <Radio
            value={3}
            style={{ marginLeft: "10px", height: "30px", lineHeight: "30px" }}
          >
            指定商品
          </Radio>
          {formData.range == 3 && !disabled && (
            <div
              style={{
                fontSize: "10px",
                width: "60px",
                height: "30px",
                textAlign: "center",
                lineHeight: "30px",
                background: "#ff746d",
                color: "#fff",
                cursor: "pointer",
              }}
              onClick={() => goodsFunc.setTrue()}
            >
              添加商品
            </div>
          )}
          {formData.range == 2 && !disabled && (
            <div
              style={{
                fontSize: "10px",
                width: "60px",
                height: "30px",
                textAlign: "center",
                lineHeight: "30px",
                background: "#ff746d",
                color: "#fff",
                cursor: "pointer",
              }}
              onClick={() => classifyFunc.setTrue()}
            >
              添加分类
            </div>
          )}
        </Radio.Group>
      </RadioStyle>
    );
  }
  useEffect(() => {
    setDays();
  }, [formData.activityStartDate, formData.activityEndDate]);
  let height = document.body.clientHeight - 188;
  return (
    <div className={styles.root} style={{ height: height + "px" }}>
      <div className={styles.content}>
        <FormSubtitle>基础信息</FormSubtitle>

        <div
          className="custom-radio"
          style={{
            margin: "" + autoRem(1.9375) + " " + autoRem(1.25) + " 0 0",
          }}
        >
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                label="活动名称"
                labelCol={{ span: 3 }}
                name="activityName"
                rules={[
                  {
                    required: true,
                    validator: (_, __, callBack: (error?: string) => void) => {
                      if (!formData.activityName) {
                        callBack("请输入活动名称");
                        return;
                      }
                      callBack();
                    },
                  },
                ]}
              >
                {formData.activityName ? "" : ""}
                <Input
                  maxLength={15}
                  style={{ width: "400px" }}
                  disabled={disabled}
                  value={formData.activityName}
                  autoComplete="off"
                  placeholder="请输入"
                  onChange={(v) => {
                    action.setFormData({
                      dispatch,
                      key: "activityName",
                      value: v.target.value,
                    });
                    updateFormField("activityName");
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                label={<span className={styles.alarm}>活动方式</span>}
                labelCol={{ span: 3 }}
                rules={[{ required: true }]}
              >
                <ShopType
                  value={1}
                  swtichValue={
                    formData.discountType ? formData.discountType : 1
                  }
                  disabled={disabled || isEdit}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                label={<span className={styles.alarm}>活动设置</span>}
                labelCol={{ span: 3 }}
                rules={[{ required: true }]}
              >
                {formData.discountType == 2 && (
                  <div style={{ display: "flex", justifyContent: "start" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {formData.activityTypes != undefined &&
                        formData.activityTypes.map((v, index) => (
                          <div style={{ marginBottom: "5px" }}>
                            <span>第{v.discountClass}级买满</span>
                            <Input
                              type="number"
                              key={index}
                              style={{ width: "70px", margin: "0 10px" }}
                              disabled={disabled}
                              defaultValue={v.meetMoney}
                              autoComplete="off"
                              onChange={(e) => {
                                if (
                                  e.target.value == "" ||
                                  e.target.value == undefined ||
                                  e.target.value == null
                                ) {
                                  message.error("请输入金额");
                                  return false;
                                } else if (parseFloat(e.target.value) <= 0) {
                                  message.error("金额应该大于0，请重新输入");
                                  return false;
                                } else {
                                  v.meetMoney = e.target.value;
                                }
                              }}
                            />
                            <span>元, 折扣</span>
                            <Input
                              // type="number"
                              value={v.discount}
                              style={{ width: "70px", margin: "0 10px" }}
                              disabled={disabled}
                              defaultValue={v.discount}
                              autoComplete="off"
                              onChange={(e) => {
                                v.discount =
                                  e.target.value &&
                                  NumberTool.setRange(e.target.value, [1, 99]);
                                dispatch({
                                  type: "update",
                                  payload: {},
                                });
                              }}
                            />
                            <span>% （折扣请输入1~99）</span>
                          </div>
                        ))}
                    </div>
                    <div
                      style={{
                        width: "70px",
                        textAlign: "center",
                        background: "#ff746d",
                        color: "#fff",
                        height: "30px",
                        lineHeight: "30px",
                        margin: "0 8px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (disabled) {
                          return false;
                        }
                        action.addGradeItem(dispatch, "add");
                      }}
                    >
                      添加等级
                    </div>
                    <div
                      style={{
                        width: "70px",
                        textAlign: "center",
                        background: "#ff746d",
                        color: "#fff",
                        height: "30px",
                        lineHeight: "30px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (disabled) {
                          return false;
                        }
                        action.addGradeItem(dispatch, "reduce");
                      }}
                    >
                      减少等级
                    </div>
                  </div>
                )}
                {formData.discountType != 2 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <span>折扣</span>
                    <Input
                      // type="number"
                      style={{ width: "70px", margin: "0 10px" }}
                      disabled={disabled}
                      value={formData.discountZj}
                      autoComplete="off"
                      onChange={(e) =>
                        action.setFormData({
                          dispatch,
                          key: "discountZj",
                          value:
                            e.target.value &&
                            NumberTool.setRange(e.target.value, [1, 99]),
                        })
                      }
                    />
                    <span>% （折扣请输入1~99）</span>
                  </div>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                label="分配门店"
                labelCol={{ span: 3 }}
                name="shopName"
                rules={[
                  {
                    required: true,
                    validator(_, __, callback: (error?: string) => void) {
                      if (
                        state.formData.shopIDs == undefined ||
                        state.formData.shopIDs.length == 0
                      ) {
                        callback("请选择可用门店");
                        return;
                      }
                      callback();
                    },
                  },
                ]}
              >
                {formData.shopName ? "" : ""}
                <Input
                  style={{ width: "400px" }}
                  disabled={disabled}
                  value={formData.shopName}
                  autoComplete="off"
                  placeholder="请选择门店"
                  onClick={() => shopFunc.setTrue()}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                label={<span className={styles.alarm}>会员等级</span>}
                labelCol={{ span: 3 }}
                rules={[{ required: true }]}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Checkbox
                    disabled={disabled}
                    checked={formData.isLimit}
                    onChange={onChangeCheck}
                  >
                    不限制
                  </Checkbox>
                  <Select
                    mode="multiple"
                    style={{ width: "400px", marginTop: "10px" }}
                    placeholder="请选择"
                    disabled={disabled || formData.isLimit}
                    value={formData.useMemberClassID}
                    onChange={handleChange}
                  >
                    {state.TagList.map((v) => (
                      <Option value={v.id} key={v.id}>
                        {v.gradeName}
                      </Option>
                    ))}
                  </Select>
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                label={<span className={styles.alarm}>活动时间</span>}
                labelCol={{ span: 3 }}
                rules={[{ required: true }]}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span>开始日期 - 结束日期</span>
                  {formData.activityStartDate == "" ||
                  formData.activityStartDate == null ? (
                    <RangePicker
                      disabled={disabled}
                      disabledDate={disabledDate}
                      style={{ width: "400px", marginTop: "10px" }}
                      onChange={onangeChange}
                    />
                  ) : (
                    <RangePicker
                      disabled={disabled}
                      disabledDate={disabledDate}
                      style={{ width: "400px", marginTop: "10px" }}
                      onChange={onangeChange}
                      value={[
                        moment(formData.activityStartDate, "YYYY-MM-DD"),
                        moment(formData.activityEndDate, "YYYY-MM-DD"),
                      ]}
                    />
                  )}
                  <span style={{ marginTop: "10px" }}>
                    开始时间段 - 结束时间段
                  </span>
                  {formData.activityStartTime == "" ||
                  formData.activityStartTime == null ? (
                    <TimePicker.RangePicker
                      disabled={disabled}
                      disabledMinutes={() => {
                        let h = disabledDateTime();
                        if (h) return h.disabledMinutes();
                      }}
                      disabledSeconds={() => {
                        let h = disabledDateTime();
                        if (h) return h.disabledSeconds();
                      }}
                      disabledHours={() => {
                        let h = disabledDateTime();
                        if (h) return h.disabledHours();
                      }}
                      hideDisabledOptions={true}
                      style={{ width: "400px", marginTop: "10px" }}
                      onChange={timePickeronChange}
                    />
                  ) : (
                    <TimePicker.RangePicker
                      disabled={disabled}
                      disabledMinutes={() => {
                        let h = disabledDateTime();
                        if (h) return h.disabledMinutes();
                      }}
                      disabledSeconds={() => {
                        let h = disabledDateTime();
                        if (h) return h.disabledSeconds();
                      }}
                      disabledHours={() => {
                        let h = disabledDateTime();
                        if (h) return h.disabledHours();
                      }}
                      hideDisabledOptions={true}
                      style={{ width: "400px", marginTop: "10px" }}
                      onChange={timePickeronChange}
                      value={[
                        moment(formData.activityStartTime, "hh-mm-ss"),
                        moment(formData.activityEndTime, "hh-mm-ss"),
                      ]}
                    />
                  )}

                  <WeekType
                    value={1}
                    swtichValue={formData.useType ? formData.useType : 1}
                  />
                  {formData.useType == 2 ? (
                    <Select
                      disabled={disabled}
                      mode="tags"
                      style={{ width: "400px", marginTop: "10px" }}
                      placeholder="请选择"
                      onChange={selectDetaChange}
                      value={
                        Array.isArray(formData.useTypeValue)
                          ? formData.useTypeValue
                          : []
                      }
                    >
                      {dayList.map((item, index) => {
                        return (
                          <Option value={item.value} key={index}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select>
                  ) : (
                    <Checkbox.Group
                      disabled={disabled}
                      style={{ width: "100%", marginTop: "10px" }}
                      value={
                        Array.isArray(formData.useweekTypeValue)
                          ? formData.useweekTypeValue
                          : []
                      }
                      onChange={onChangeCheckbox}
                    >
                      <Row>
                        <Col
                          span={4}
                          style={{
                            marginRight: "6px",
                            border: "1px solid #eee",
                            textAlign: "center",
                            height: "35px",
                            lineHeight: "35px",
                          }}
                        >
                          <Checkbox value="1">星期一</Checkbox>
                        </Col>
                        <Col
                          span={4}
                          style={{
                            marginRight: "6px",
                            border: "1px solid #eee",
                            textAlign: "center",
                            height: "35px",
                            lineHeight: "35px",
                          }}
                        >
                          <Checkbox value="2">星期二</Checkbox>
                        </Col>
                        <Col
                          span={4}
                          style={{
                            marginRight: "6px",
                            border: "1px solid #eee",
                            textAlign: "center",
                            height: "35px",
                            lineHeight: "35px",
                          }}
                        >
                          <Checkbox value="3">星期三</Checkbox>
                        </Col>
                        <Col
                          span={4}
                          style={{
                            marginRight: "6px",
                            border: "1px solid #eee",
                            textAlign: "center",
                            height: "35px",
                            lineHeight: "35px",
                          }}
                        >
                          <Checkbox value="4">星期四</Checkbox>
                        </Col>
                        <Col
                          span={4}
                          style={{
                            marginRight: "6px",
                            border: "1px solid #eee",
                            textAlign: "center",
                            height: "35px",
                            lineHeight: "35px",
                          }}
                        >
                          <Checkbox value="5">星期五</Checkbox>
                        </Col>
                        <Col
                          span={4}
                          style={{
                            marginRight: "6px",
                            border: "1px solid #eee",
                            textAlign: "center",
                            height: "35px",
                            lineHeight: "35px",
                          }}
                        >
                          <Checkbox value="6">星期六</Checkbox>
                        </Col>
                        <Col
                          span={4}
                          style={{
                            marginRight: "6px",
                            border: "1px solid #eee",
                            textAlign: "center",
                            height: "35px",
                            lineHeight: "35px",
                          }}
                        >
                          <Checkbox value="7">星期日</Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  )}
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 3 }}
                label={<span className={styles.alarm}>使用途径</span>}
              >
                <ChannelType
                  value={1}
                  swtichValue={formData.purposeType ? formData.purposeType : 1}
                  disabled={disabled || isEdit}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 3 }}
                label={<span className={styles.alarm}>活动明细</span>}
              >
                <DetailedChannelType
                  value={1}
                  swtichValue={formData.range ? formData.range : 1}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        {formData.range == 3 && (
          <DataTable dataList={state.tableList} disabled={disabled} />
        )}
        {formData.range == 2 && (
          <DataTableclassity
            dataList={state.classifyList}
            disabled={disabled}
          />
        )}
      </div>

      <ChooseShop
        visibleModal={shopState}
        type='checkbox'
        close={(status, r) => {
          if (r) {
            let ids = r.length>0?r.map(shop=>shop.id):[]
            let names = r.length>0?r.map(shop=>shop.shopName).toString():''
            action.setFormData({ dispatch, key: "shopIDs", value: ids });
            action.setFormData({
              dispatch,
              key: "shopName",
              value:names,
            });
          }
          updateFormField("shopName");
          shopFunc.setFalse();
        }}
      />
      <ChooseGoods
        visibleModal={goodsState}
        key={formData.purposeType ? formData.purposeType : 1}
        shopID={formData.shopIDs}
        rowKey="barCode"
        chooseType='discount'
        filter={{
          isMarketing: true,
          specifications: true,
          checkInvcount: false,
          source:
            formData.purposeType == 1
              ? "CS"
              : formData.purposeType == 2
              ? "WX"
              : formData.purposeType == 3
              ? "PC"
              : "CS",
        }}
        close={(status, r) => {
          if (r) {
            action.addTableItem({ dispatch, list: r });
          }
          goodsFunc.setFalse();
        }}
      />
      <ChooseClassify
        visibleModal={classifyState}
        close={(status, r) => {
          if (r) {
            action.AddclassTableItem({ dispatch, list: r });
            // console.log(r)
          }
          classifyFunc.setFalse();
        }}
      />
    </div>
  );
}
