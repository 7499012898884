import { message } from "antd";
import { AxiosResponse } from "axios";
import Cookies from "js-cookie";
import { AppGlobal } from "../App";
import {removeAllRequest} from '../config/config.tool'

import * as NProgress from "nprogress";
import "nprogress/nprogress.css";

/**
 * 登录状态是否有效
 */
export function effeative(r: AxiosResponse<any>) {
    if (r.data.statusCode == 401) {
        removeAllRequest()
        Cookies.remove("token");
        Cookies.remove("token");
        AppGlobal.his && AppGlobal.his.replace("/login");
        message.error(r.data.message);
    } else {
        return r;
    }
}
export function fillHeight(current: any, appendValue: number) {
    if (!current) return 0;
    const top = current.offsetTop;
    const value = document.documentElement.scrollHeight - top;
    return value > appendValue && value - appendValue;
}

function initPageSize(size: number) {
    return function () {
        return size;
    }
}

/**
 * 默认列表 20 条数据
 */
export const defualtPageSize = initPageSize(10);
export const maxPageSize = initPageSize(9999);

function initFormWidth(width: number) {

    return function () {
        // 1920
        if (document.documentElement.clientWidth >= 1920) {
            if (500 == width) {
                return 600;
            }
            else if (700 == width) {
                return 800;
            }
            else if (850 == width) {
                return 1110;
            }
        }

        return width;
    };
}

export const minWidth = initFormWidth(500);
export const midWidth = initFormWidth(700);
export const maxWidth = initFormWidth(850);
export const moreMaxWidth = initFormWidth(1000);
// NProgress
export const Progress = {
    start: () => { NProgress.start(); NProgress.inc(.2) },
    done: NProgress.done,
    percent50: () => NProgress.inc(.5)
}

export async function zip(file) {
    function canvasDataURL(file, callback) { //压缩转化为base64
        var reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function (e) {
            const img: any = new Image()
            const quality = 0.5 // 图像质量
            const canvas = document.createElement('canvas')
            const drawer = canvas.getContext('2d')
            img.src = this.result
            img.onload = function () {
                canvas.width = img.width
                canvas.height = img.height
                drawer.drawImage(img, 0, 0, canvas.width, canvas.height)
                convertBase64UrlToBlob(canvas.toDataURL(file.type, quality), callback);
            }
        }
    }
    function convertBase64UrlToBlob(urlData, callback) { //将base64转化为文件格式
        const arr = urlData.split(',')
        const mime = arr[0].match(/:(.*?);/)[1]
        const bstr = atob(arr[1])
        let n = bstr.length
        const u8arr = new Uint8Array(n)
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
        }
        callback(new Blob([u8arr], {
            type: mime
        }));
    }
    function main(file) {
        return new Promise((resolve, rejects) => {
            canvasDataURL(file, function (blob) {
                var aafile = new File([blob], file.name, {
                    type: file.type
                })
                var isLt1M;
                if (file.size < aafile.size) {
                    isLt1M = file.size
                } else {
                    isLt1M = aafile.size
                }
                resolve(aafile);
            })
        })
    }
    return await main(file);
}