import defaultImgUrl from "../../../assets/images/mini/group_350_90.png";
import { message, Input, Select, Modal, Tag ,Form as FormAntd, Row,Col, DatePicker} from "antd";
import { Button } from "../../../component/button";
import { autoRem } from "../../../global.style";
import { useBoolean } from "ahooks";
import styles from '../style/index.module.scss'
import camera_png from "../../../assets/images/goods/ico_dotted_line_camera.png";
import { useContext, useEffect, useRef, useState } from "react";
import { AuthLogic } from "../../../logic/auth_logic";
import { ConfigApi } from "../../../config";
import Cookies from "js-cookie";
import { Tools, axios } from "../../../config";
import { zip } from "../../../logic/common_logic";
// import axios from "axios";
import { AdvStoreForm } from "../store/form_store";
import {formAction } from '../action/form_action'
import { ChooseGoods } from "../../../component/choose-goods";
import { ChoosePresale } from "../../../component/choose-presale";
import { ChooseSeckill } from "../../../component/choose-seckill";
import moment from 'moment'
export function MiniAdvertise({form,disabled}){
  const {Option} = Select
  const {RangePicker} = DatePicker

  const [goodsState,goodsFunc] = useBoolean(false)
  const [presaleState,presaleFunc] = useBoolean(false)
  const [seckillState,seckillFunc] = useBoolean(false)
  const {state,dispatch} = useContext(AdvStoreForm)

  const inputRef = useRef(null)
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current < moment().endOf('day');
  }
  useEffect(() => {
    // (async function () {
    //   let r = await new AuthLogic().selButton(viewPower.viewPower);
    //   if (r.length > 0) {
    //     for (let i = 0; i < r.length; i++) {
    //       r[i] = r[i].split("_")[r[i].split("_").length - 1];
    //     }
    //   }
    //   setData(r);
    //   if (r.indexOf("ADD") == -1) {
    //     powerFunc.setFalse();
    //   } else {
    //     powerFunc.setTrue();
    //   }
    // })();

    return () => {};
  }, []);


   /** 获取图片 */
   const onChange = async (e) => {
   
    let file = e.target.files[0];

    // 压缩
    file = await zip(file);

    const formData = new FormData();
    formData.append("file", file);

    const r = await axios({
      method: "POST",
      url: Tools.getApiAddress("miniAdv.uploadImage"),
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data;charset=UTF-8",
        SYSTOKEN: Cookies.get("token"),'ClientSource':'web',
      },
    });

    if (r.data.statusCode == 200) {
      const imgUrl = r.data.data;
      formAction.setValue({
        dispatch,
        attribute: "imgUrl",
        value: imgUrl,
      });

      message.success("上传成功");
    } else {
      message.error(r.data.returnMessage);
    }

    inputRef.current.value = null;
  };
  function Radio({ onChange, defaultValue,msg ,type }) {
    const [state, stateFunc] = useBoolean(false);
  
    useEffect(() => {
      if (defaultValue) {
        stateFunc.setTrue();
      } else {
        stateFunc.setFalse();
      }
  
      return () => {};
    }, [defaultValue]);
  
    return (
      <div className={styles.setting}>
        <div
        className={`${styles.radio} ${state ? styles.active : ""} `}
        onClick={() => {
          if(disabled ){
            return
          }
       if(type == 'islink'){
        return
       }
            onChange(!state);
            stateFunc.toggle();
      
         
        }}
      ></div>
      <span className={styles.note}>{msg}</span>
      </div>
    );
  }
  return <>
     <div
        style={{
          margin: "" + autoRem(1.9375) + " 0 0 0",
          padding: "1rem",
          boxShadow:
            "0px " +
            autoRem(0.3125) +
            " " +
            autoRem(1.25) +
            " 0px rgba(209, 206, 206, 0.3)",
        }}
      >
        {/* <FormAntd form={form}> */}
          <Row gutter={24}>
            <Col span={20}>
              <FormAntd.Item              
              name="imgUrl"
              label="开屏广告图片"
              labelCol={{ span: 6 }}
              rules={[
                {
                  required: true,
                  message: "请选择广告图片",
                  validator: (_, __, callBack: (error?: string) => void) => {
                
                    if (
                      state.formData.imgUrl == ''
                    ) {
                     callBack('请选择广告图片')
                     return;
                    }
                    callBack();
                  },
                },
              ]}
              >
            <div className={styles.img}>
                  <img
                    style={{
                      cursor: false ? "not-allowed" : "pointer",
                    }}
                    src={state.formData.imgUrl?Tools.getImageAddress(state.formData.imgUrl) :
                      camera_png
                    }
                    alt=""
                    onClick={() => {
                      if (disabled) {
                        return;
                      }
                      inputRef.current.click();
                    }}
                  />
                  <span>点击选择图片</span>
                </div>
                <input
                  style={{ display: "none" }}
                  ref={inputRef}
                  onChange={onChange}
                  type="file"
                  accept="image/*"
                />
                <div style={{color:'#ff4d4f',marginLeft:'10px'}}>建议尺寸260x280</div>
                </FormAntd.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={20}>
              <FormAntd.Item
                name="status"
                label="是否启用"
                labelCol={{ span: 6 }}
              >
               <Radio
                type={''}
                msg=''
                defaultValue={state.formData.status}
                onChange={(v) =>
                  {
                  formAction.resetLink({dispatch,form})
                    formAction.setValue({
                              dispatch,
                              value: v,
                              attribute: 'status',
                            });
                    
                  }
              }
            />                
              </FormAntd.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={20}>
              <FormAntd.Item
                name="isRepetition"
                label="是否重复"
                labelCol={{ span: 6 }}
              >
               <Radio
                type={''}
                msg=''
                defaultValue={state.formData.isRepetition}
                onChange={(v) =>
                  {
                  // formAction.resetLink({dispatch,form})
                    formAction.setValue({
                              dispatch,
                              value: v,
                              attribute: 'isRepetition',
                            });
                    
                  }
              }
            />                
              </FormAntd.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={20}>
              <FormAntd.Item
                name="isLink"
                label="是否跳转链接"
                labelCol={{ span: 6 }}
              >
               <Radio
                type={''}
                msg=''
                defaultValue={state.formData.isLink}
                onChange={(v) =>
                  {
                  formAction.resetLink({dispatch,form})
                    formAction.setValue({
                              dispatch,
                              value: v,
                              attribute: 'isLink',
                            });
                    
                  }
              }
            />                
              </FormAntd.Item>
            </Col>
          </Row>
          {state.formData.isLink && <Row gutter={24}>
            <Col span={20}>
              <FormAntd.Item
                name="linkType"
                label="跳转链接"
                labelCol={{ span: 6 }}
                rules={[{
                  required: true,
                  validator: (_, __, callBack: (error?: string) => void) => {
                    let type = state.formData.linkType
                    console.log(state.formData.linkParam.id)
                    if (
                      state.formData.isLink && type!=0&&type!=3
                    ) {
                      switch(type){
                        case 1:
                        case 2:
                         {
                           if(state.formData.linkParam.id=='' || state.formData.linkParam.id == undefined){
                            callBack("请选择活动");
                            return;
                           }
                           break
                         }
                         case 4:{
                          if(state.formData.linkParam.id=='' || state.formData.linkParam.id == undefined){
                            callBack("请选择商品");
                            return;
                           }
                           break
                         }

                      }
                     
                     
                    }
                    callBack();
                  },
                }]}
              >
               <Select value={state.formData.linkType} disabled={disabled}  style={{ width: 120 }} onChange={(v)=>{
                 formAction.setValue({attribute: 'linkType',value:v,dispatch})
               }}>
                  <Option value={0}>积分商城</Option>
                  <Option value={3}>优惠券</Option>
                  <Option value={4}>商品</Option>
                  <Option value={2}>秒杀活动</Option>
                  <Option value={1}>预售活动</Option>
               </Select>
               {state.formData.linkType!=3&&state.formData.linkType!=0&&<><div style={{display:'flex',alignItems:'center'}}>
                  <div   style={{
                    fontSize: "10px",
                    height: "30px",
                    textAlign: "center",
                    lineHeight: "30px",
                    background: "#ff746d",
                    color: "#fff",
                    cursor: "pointer",
                    padding: "0 5px",
                    margin:'0 20px'
                  }} onClick={()=>{
                    if(disabled){
                      return;
                    }
                    switch(state.formData.linkType){
                      case 4:
                       goodsFunc.setTrue();break
                     case 2:
                       seckillFunc.setTrue();break
                     case 1:
                         presaleFunc.setTrue();break
                    }
                  }}>点击选择</div>
                   <span >{state.formData.linkParam?state.formData.linkParam.name:''}</span>
                 </div>  </>}          
              </FormAntd.Item>
            </Col>
          </Row>}
         
          <Row gutter={24}>
            <Col span={20}>
              <FormAntd.Item
                name=""
                label="有效期"
                labelCol={{ span: 6 }}
              >
               {(state.formData.startTime !='' && state.formData.startTime!=undefined && state.formData.startTime!=null) ?<RangePicker allowClear disabled={disabled} disabledDate={disabledDate} defaultValue={[moment(state.formData.startTime),moment(state.formData.end)]} onChange={(date,dateString)=>{
                 console.log(dateString)
                 formAction.setValue({
                   attribute:'startTime',
                   value:dateString[0],
                   dispatch
                 })
                 formAction.setValue({
                  attribute:'endTime',
                  value:dateString[1],
                  dispatch
                })
               }}></RangePicker> :<RangePicker disabled={disabled} allowClear disabledDate={disabledDate} defaultValue={[null,null]} onChange={(date,dateString)=>{
                formAction.setValue({
                  attribute:'startTime',
                  value:dateString[0],
                  dispatch
                })
                formAction.setValue({
                 attribute:'endTime',
                 value:dateString[1],
                 dispatch
               })
              }}></RangePicker>}            
              </FormAntd.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={20}>
              <FormAntd.Item
                name="advertisingSort"
                label="广告顺序"
                labelCol={{ span: 6 }}
                rules={[{
                  required: true,
                  pattern:  /^-?\d+$/,
                  message:'请输入整数'
                }]}
              >
              <Input value={state.formData.advertisingSort} type='number' disabled={disabled} min={0} onChange={(e)=>{
                  formAction.setValue({
                    value: e.target.value,
                    attribute:'advertisingSort',
                    dispatch
                  })
              }}/>
              </FormAntd.Item>
            </Col>
          </Row>
        {/* </FormAntd> */}
       
      </div>
      <ChooseGoods visibleModal={goodsState}  savekeysNo={true}
        filter={{ specifications: false,  type: false,source:'WX' }}
        type="radio"  close={(status,r)=>{
          if(r && r.length>0){
            let param={id: r[0].id,name:r[0].goodsName,goodsSource: r[0].goodsSource}
              formAction.setValue({attribute:'linkParam',value:param,dispatch})
          }
          goodsFunc.setFalse()
      }}/>
      <ChoosePresale visibleModal={presaleState} type='radio' close={(status,r)=>{
           if(r && r.length>0){
            let param={id: r[0].id,name:r[0].activityName}
              formAction.setValue({attribute:'linkParam',value:param,dispatch})
          }
          presaleFunc.setFalse()
      }}/>
       <ChooseSeckill visibleModal={seckillState} type='radio' close={(status,r)=>{
         console.log(r)
           if(r && r.length>0){
            let param={id: r[0].id,name:r[0].snappedName}
              formAction.setValue({attribute:'linkParam',value:param,dispatch})
          }
          seckillFunc.setFalse()
      }}/>
      
  </>
}