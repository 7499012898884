import { Cascader, Col, Form, Form as FormAntd, Input, Radio, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useContext, useEffect, useState } from "react";
import { RadioStyle } from "../../../../component/radio-style";
import { formAction } from "../../action/stored/form_action";
import { FormStore } from "../../store/stored/form_store";
import { Select } from "antd";
import { autoRem } from "../../../../global.style";
const { Option } = Select;

export function FormBasic({ title, updateFormField }) {
  const { state, dispatch: formDispatch } = useContext(FormStore);
  const action = formAction;
  var isLook = false;
  if (title.title == "查看规则") {
    isLook = true;
  } else {
    isLook = false;
  }

  return (
    <>
      <div style={{ margin: "1.9375rem 24px 0 0" }}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="rule-name"
              label="规则名称"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: true,
                  message: "请输入规则名称",
                },
              ]}
            >
              <Input
                placeholder="请输入规则名称"
                maxLength={20}
                autoComplete="off"
                disabled={isLook}
                onChange={(v) =>
                  action.setValue({
                    dispatch: formDispatch,
                    value: v.target.value,
                    attribute: "RuleName",
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="store-money"
              label="储值金额"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: true,
                  message: "请输入储值金额",
                },
                {
                  pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
                  message: "请输入大于0的数字",
                },
              ]}
            >
              <Input
                disabled={isLook}
                placeholder="请输入储值金额"
                maxLength={20}
                type="number"
                autoComplete="off"
                onChange={(v) =>
                  action.setValue({
                    dispatch: formDispatch,
                    value: v.target.value,
                    attribute: "StoredValue",
                  })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="store-type"
              label="赠送类型"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: true,
                  message: "请选择赠送类型",
                },
              ]}
            >
              <Select
                showSearch
                disabled={isLook}
                placeholder="请选择赠送类型"
                optionFilterProp="children"
                filterOption={(input:any, option:any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={(v) => {
                  action.setValue({
                    dispatch: formDispatch,
                    value: v,
                    attribute: "GrantType",
                  });
                  if (v == 3) {
                    updateFormField("store-give", 0);
                    action.setValue({
                      dispatch: formDispatch,
                      value: 0,
                      attribute: "GrantValue",
                    });
                  } else {
                    updateFormField("store-give", null);
                    action.setValue({
                      dispatch: formDispatch,
                      value: null,
                      attribute: "GrantValue",
                    });
                  }
                }}
              >
                <Option value={1}>金额</Option>
                <Option value={2}>积分</Option>
                <Option value={3}>不赠送</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="store-give"
              label="赠送值"
              labelCol={{ span: 8 }}
              rules={[
                {
                  validator: (_, __, callBack: (error?: string) => void) => {
                    if (state.formData.grantType == "1") {
                      if (Number(state.formData.grantValue) <= 0) {
                        callBack("赠送金额应该大于0");
                        return;
                      } else if (
                        state.formData.grantValue == "" ||
                        state.formData.grantValue == undefined ||
                        state.formData.grantValue == null
                      ) {
                        callBack("请输入赠送金额");
                        return;
                      }
                    } else if (state.formData.grantType == "2") {
                      if (
                        Number(state.formData.grantValue) <= 0 ||
                        !/^\+?[1-9][0-9]*$/.test(state.formData.grantValue)
                      ) {
                        callBack("赠送积分值应该大于0的整数");
                        return;
                      } else if (
                        state.formData.grantValue == "" ||
                        state.formData.grantValue == undefined ||
                        state.formData.grantValue == null
                      ) {
                        callBack("请输入赠送积分值");
                        return;
                      }
                    }
                    callBack();
                  },
                },
              ]}
            >
              {state.formData.grantValue ? "" : ""}
              <Input
                disabled={isLook || state.formData.grantType == "3"}
                type="number"
                value={state.formData.grantValue}
                placeholder="请输入赠送值"
                autoComplete="off"
                onChange={(v) => {
                  action.setValue({
                    dispatch: formDispatch,
                    value: v.target.value,
                    attribute: "GrantValue",
                  });
                  updateFormField("store-give", v.target.value);
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        {isLook && (
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="store-time"
                label="创建时间"
                labelCol={{ span: 8 }}
              >
                <Input
                  placeholder="请输入创建时间"
                  autoComplete="off"
                  disabled={isLook}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="store-user"
                label="创建用户"
                labelCol={{ span: 8 }}
              >
                <Input
                  disabled={isLook}
                  placeholder="请输入创建用户"
                  autoComplete="off"
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name="remark" label="备注" labelCol={{ span: 4 }}>
              <TextArea
                disabled={isLook}
                rows={2}
                autoComplete="off"
                onChange={(v) =>
                  action.setValue({
                    dispatch: formDispatch,
                    value: v.target.value,
                    attribute: "Remark",
                  })
                }
              ></TextArea>
            </Form.Item>
          </Col>
        </Row>
      </div>
    </>
  );
}
