import { useContext, useEffect, useRef } from "react";
import { FileStore } from "../store";
import {ITitle} from './pie'
class IParam{
    divStyles?:{}
    title?: ITitle
    data: any[]
    // legend?: ILegend
    name?:string 
    // center?:string[] = ['50%','50%']
    // radius?: Array<Number|String> = [0,'50%']//number：直接指定外半径值。string：例如，'20%'，表示外半径为可视区尺寸（容器高宽中较小一项）的 20% 长度。    Array.<number|string>：数组的第一项是内半径，第二项是外半径。每一项遵从上述 number string 的描述。
    max?: number = 100
}
declare var echarts: any;
export function Funnel({title,name,data,divStyles,max}:IParam){
    const pieRef = useRef(null)
    const {state,dispatch} = useContext(FileStore)
    useEffect(()=>{
     var dom = pieRef.current;
     var myChart = echarts.init(dom);
     var option;
      
     option = {
        title: {...title,textStyle:{
            color:title.color?title.color:'#333',
            fontSize:title.fontSize?title.fontSize:'18'
        }},
        tooltip: {
          formatter: function (params) {
            // console.log(params)
            // do some thing
            return  '名称：' +params.name+'<br/>'+'数量：'+params.data.num
         }
        },
      
        legend: {
            show:false
        },
        series: [
          {
            name: name,
            type: 'funnel',
            data: data,
            left: '10%',
            top: 60,
            bottom: 60,
            width: '80%',
            min: 0,
            max: 100,
            minSize: '0%',
            maxSize: '100%',
            label: {
                show: true,
                position: 'inside'
              },
              labelLine: {
                length: 10,
                lineStyle: {
                  width: 1,
                  type: 'solid'
                }
              },
              itemStyle: {
                borderColor: '#fff',
                borderWidth: 1
              },
              emphasis: {
                label: {
                  fontSize: 18
                }
              },
          }
        ]
      };
      option && myChart.setOption(option);
      return(()=>{

    })
   },[state.condition.startTime,state.condition.endTime,data,title])
    return<div ref={pieRef}  style={{...divStyles,background:'#fff'}} ></div>
}