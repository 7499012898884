import { useBoolean, useDebounceFn, useRequest } from "ahooks";
import { useContext, useEffect, useState } from "react";
import { Form as FormAntd,Row,Col, Input,TimePicker, Button, Select } from "antd";
import { ShopFormAction } from "../action/shop_setting_action";
import { ShopSettingStore } from "../store/shop_setting_store";
import { AuthLogic } from "../../../logic/auth_logic";
import styles from "../style/form_basic.module.scss";
import { autoRem } from "../../../global.style";
import moment from "moment";
import { ConfirmModal } from "../../../component/modal";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
export function ShopSetting(viewPower){
  const {Option} = Select
    const {RangePicker} = TimePicker 
    const {state,dispatch} = useContext(ShopSettingStore)
      const [form] = FormAntd.useForm();
      const formAction = new ShopFormAction();
      const [menuButton, setData] = useState([]);
      const [power, powerFunc] = useBoolean(false);
      const [confirmState, confirmFunc] = useBoolean(false);
      useEffect(() => {
        (async function () {
          let r = await new AuthLogic().selButton(viewPower.viewPower);
          if (r.length > 0) {
            for (let i = 0; i < r.length; i++) {
              r[i] = r[i].split("_")[r[i].split("_").length - 1];
            }
          }
          setData(r);
          if (r.indexOf("ADD") == -1) {
            powerFunc.setFalse();
          } else {
            powerFunc.setTrue();
          }
        })();
    
        return () => {};
      }, []);
      useRequest(async()=> await formAction.init({state,dispatch,id:'',method:'',form}))
// formAction.init({state,dispatch,id:'',method:'',form})
const his = useHistory()
const {run } = useDebounceFn(fn=>{
  fn && fn()
},{
  wait:1500
})
function Radio({ onChange, defaultValue,msg ,type }) {
    const [state, stateFunc] = useBoolean(false);
  
    useEffect(() => {
      if (defaultValue) {
        stateFunc.setTrue();
      } else {
        stateFunc.setFalse();
      }
  
      return () => {};
    }, [defaultValue]);
  
    return (
      <div className={styles.setting}>
        <div
        className={`${styles.radio} ${state ? styles.active : ""} `}
        onClick={() => {
          if(!power){
            return
          }
        //   if(type == 'card'){
        //     if(defaultValue === null ){
        //       onChange(!state);
        //       stateFunc.toggle();
        //     }else{
              
        //     }
        //   }else{
            onChange(!state);
            stateFunc.toggle();
        //   }
         
        }}
      ></div>
      <span className={styles.note}>{msg}</span>
      </div>
    );
  }
    return (
        <>
             <div
    style={{
      margin: "" + autoRem(1.9375) + " 0 0 0",
      padding: "1rem",
      boxShadow:
        "0px " +
        autoRem(0.3125) +
        " " +
        autoRem(1.25) +
        " 0px rgba(209, 206, 206, 0.3)",
    }}
  >
    <FormAntd form={form}>
      <Row gutter={10}>
        <Col span={10}>
          <FormAntd.Item
            name="shop-isBusinessStatus"
            label="营业状态"
            labelCol={{ span: 8 }}
            className={"custom-radio"}            
          >
            <Radio
            type={''}
            msg='是否正常营业'
            defaultValue={state.formData.isBusinessStatus}
            onChange={(v) =>
              {
                formAction.setValue({
                          dispatch,
                          value: v,
                          attribute: 'isBusinessStatus',
                        });
              }
          }
        />            
          </FormAntd.Item>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={10}>
          <FormAntd.Item
            name="shop-storeLimitBusinessHours"
            label="商城下单限制"
            labelCol={{ span: 8 }}
            className={"custom-radio"}            
          >
            <Radio
            type={'card'}
            msg='非营业时间商城是否可以下单'
            defaultValue={state.formData.storeLimitBusinessHours}
            onChange={(v) =>
              {
                formAction.setValue({
                          dispatch,
                          value: v,
                          attribute: 'storeLimitBusinessHours',
                        });
              }
          }
        />            
          </FormAntd.Item>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={10}>
          <FormAntd.Item
            name="shop-shippingRangeIsRefund"
            label="退配送费"
            labelCol={{ span: 8 }}
            className={"custom-radio"}            
          >
            <Radio
            type={''}
            msg='退款时配送费是否退还'
            defaultValue={state.formData.shippingRangeIsRefund}
            onChange={(v) =>
              {
                formAction.setValue({
                          dispatch,
                          value: v,
                          attribute: 'shippingRangeIsRefund',
                        });
              }
          }
        />            
          </FormAntd.Item>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={10}>
          <FormAntd.Item
            name="shop-packingFeeIsRefund"
            label="退打包费"
            labelCol={{ span: 8 }}
            className={"custom-radio"}            
          >
            <Radio
            type={''}
            msg='退款时打包费是否退还'
            defaultValue={state.formData.packingFeeIsRefund}
            onChange={(v) =>
              {
                formAction.setValue({
                          dispatch,
                          value: v,
                          attribute: 'packingFeeIsRefund',
                        });
              }
          }
        />            
          </FormAntd.Item>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={10}>
          <FormAntd.Item
            name="shop-isPrint"
            label="是否自动打印"
            labelCol={{ span: 8 }}
            className={"custom-radio"}            
          >
            <Radio
            type={''}
            msg='有新商城订单，是否自动打印单据'
            defaultValue={state.formData.isPrint}
            onChange={(v) =>
              {
                formAction.setValue({
                          dispatch,
                          value: v,
                          attribute: 'isPrint',
                        });
              }
          }
        />            
          </FormAntd.Item>
        </Col>
      </Row>
      <Row gutter={10}>
      <Col span={10}>
              <FormAntd.Item
                label="退款流程"
                name="shop-isRefundAudit"
                className={"custom-radio"}    
                labelCol={{ span: 8 }}
                rules={[
                  {
                    required: true,
                    message: "请选择",
                    validator: (
                      rule,
                      value,
                      callback: (error?: string) => void
                    ) => {
                      if (!state.formData.isRefundAudit) {
                        callback("请选择退款方式");
                      } else {
                        callback();
                      }
                    },
                  },
                ]}
              >
                {state.formData.isRefundAudit ? "" : ""}
                <Select //待发货订单退款流程
                  // disabled
                  value={state.formData.isRefundAudit}
                  allowClear
                  onChange={(v) => {
                    formAction.setValue({
                      dispatch,
                      value: v,
                      attribute: 'isRefundAudit',
                    });
                   
                    // updateFormField("distributionPlatform");
                  }}
                >
                  <Option value={0}>需要审核</Option>
                  <Option value={1}>直接退款</Option>
                  {/* <Option value={3}>采购入库</Option> */}
                
                </Select>
              </FormAntd.Item>
            </Col>
      </Row>
      <Row gutter={10}>
        <Col span={10}>
          <FormAntd.Item
            name="shop-sendOffMoney"
            label="起送价"
            labelCol={{ span: 8 }}
            className={"custom-radio"}    
            rules={[
                {
                    required: true,
                    message:'请输入起送价'
                },
                {
                    pattern:/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
                    message:'起送价大于0'
                }
            ]}                
          >
              <Input value={state.formData.sendOffMoney} type='number' min={0.01} onChange={(e)=>{
                formAction.setValue({
                    dispatch,
                    value: e.target.value,
                    attribute: 'sendOffMoney',
                  });
              }}/>
            
          </FormAntd.Item>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={10}>
          <FormAntd.Item
            name="shop-shippingFees"
            label="配送费"
            labelCol={{ span: 8 }}
            className={"custom-radio"}    
            rules={[
                {
                    required: true,
                    message:'请输入配送费'
                },
                {
                    pattern:/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
                    message:'配送费大于0'
                }
            ]}                
          >
              <Input value={state.formData.shippingFees} type='number' min={0} onChange={(e)=>{
                formAction.setValue({
                    dispatch,
                    value: e.target.value,
                    attribute: 'shippingFees',
                  });
              }}/>
            
          </FormAntd.Item>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={10}>
          <FormAntd.Item
            name="shop-packingFee"
            label="打包费"
            labelCol={{ span: 8 }}
            className={"custom-radio"}    
            rules={[
                {
                    required: true,
                    message:'请输入金额'
                },
                {
                    pattern:/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
                    message:'金额大于0'
                }
            ]}                
          >
             <Input value={state.formData.packingFee} type='number' min={0.01} onChange={(e)=>{
                formAction.setValue({
                    dispatch,
                    value: isNaN(Number(e.target.value))?e.target.value:Number(e.target.value),
                    attribute: 'packingFee',
                  });
              }}/>
            
          </FormAntd.Item>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={10}>
          <FormAntd.Item
            name="shop-fullReductionNoShippingFees"
            label="满额免配送费"
            labelCol={{ span: 8 }}
            className={"custom-radio"}    
            rules={[
                {
                    required: true,
                    message:'请输入金额'
                },
                {
                    pattern:/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
                    message:'金额大于0'
                }
            ]}                
          >
              <Input value={state.formData.fullReductionNoShippingFees} type='number' min={0.01} onChange={(e)=>{
                formAction.setValue({
                    dispatch,
                    value: e.target.value,
                    attribute: 'fullReductionNoShippingFees',
                  });
              }}/>
            
          </FormAntd.Item>
        </Col>
      </Row>

      <Row gutter={10}>
      <Col span={10}>
              <FormAntd.Item
                label="配送平台"
                name="distributionPlatform"
                className={"custom-radio"}    
                labelCol={{ span: 8 }}
                rules={[
                  {
                    required: true,
                    message: "请选择配送平台",
                    validator: (
                      rule,
                      value,
                      callback: (error?: string) => void
                    ) => {
                      if (!state.formData.distributionPlatform) {
                        callback("请选择配送平台");
                      } else {
                        callback();
                      }
                    },
                  },
                ]}
              >
                {state.formData.distributionPlatform ? "" : ""}
                <Select
                  disabled
                  value={state.formData.distributionPlatform}
                  allowClear
                  onChange={(v) => {
                    formAction.setValue({
                      dispatch,
                      value: v,
                      attribute: 'distributionPlatform',
                    });
                   
                    // updateFormField("distributionPlatform");
                  }}
                >
                  <Option value={1}>商家自送</Option>
                  <Option value={2}>达达配送</Option>
                  {/* <Option value={3}>采购入库</Option> */}
                
                </Select>
              </FormAntd.Item>
            </Col>
      </Row>
      <Row gutter={10}>
        <Col span={10}>
          <FormAntd.Item
            name="shop-shippingRange"
            label="配送范围(千米)"
            labelCol={{ span: 8 }}
            className={"custom-radio"}    
            rules={[
                {
                    required: true,
                    message:'请输入配送范围'
                },
                {
                    pattern:/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
                    message:'配送范围大于0'
                }
            ]}                
          >
              <Input value={state.formData.shippingRange} type='number' min={0} onChange={(e)=>{
                formAction.setValue({
                    dispatch,
                    value: e.target.value,
                    attribute: 'shippingRange',
                  });
              }}/>
            
          </FormAntd.Item>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={10}>
          <FormAntd.Item
          // name={'businessBeginHours'}
          help={state.formData.businessBeginHours && state.formData.businessEndHours?'':'请选择门店营业时间'}
          validateStatus={state.formData.businessBeginHours && state.formData.businessEndHours?'success':'error'}
            label="门店营业时间"
            labelCol={{ span: 8 }}
            className={"custom-radio"} 
            rules={[
                {
                    required: true,
                    message:'请选择门店营业时间'
                }
            ]}           
          >
            {}
            <RangePicker  
            defaultValue={state.formData.businessBeginHours?[moment(state.formData.businessBeginHours,'HH:mm:ss'),moment(state.formData.businessEndHours,'HH:mm:ss')]:[null,null]}
                value={state.formData.businessBeginHours?[moment(state.formData.businessBeginHours,'HH:mm:ss'),moment(state.formData.businessEndHours,'HH:mm:ss')
                ]:[null,null]}
                disabled={!power}
                style={{ width: "400px" }}
                onChange={(time, timsString) => {
                    formAction.setValue({
                        dispatch,
                        value: timsString ? timsString[0] : "",
                        attribute: 'businessBeginHours',
                      });
                      formAction.setValue({
                        dispatch,
                        value: timsString ? timsString[1] : "",
                        attribute: 'businessEndHours',
                      });               
                form.setFieldsValue(['shop-businessBeginHours',timsString ? timsString[0] : ""])
                }}/>
          </FormAntd.Item>
        </Col>
      </Row>
      </FormAntd>
      <Button loading={state.loading}
      type="primary"
      color="Red"
      style={{ marginLeft: "1.25rem" }}
      onClick={() => {
        if (!power) {
          return false;
        }
        confirmFunc.setTrue()
        // formAction.addSubmit({state,dispatch,form})
      }}
    >
      保存设置
    </Button>
      </div>
      <ConfirmModal
        visible={confirmState}
        title="温馨提示"
        subTitle="保存后是否再次登录"
        message="修改自动打印订单后，重新登录生效"
        cancelText="保存"
        okText="保存并重登录"
        close={async (r) => {
          if (r) {
           await formAction.addSubmit({state,dispatch,form})
           Cookies.remove("token");
            Cookies.remove("loginMsg");
            localStorage.removeItem("logo");
            localStorage.removeItem("SysLogo");
            his.replace("/login");
          }else{
            formAction.addSubmit({state,dispatch,form})
          }
          confirmFunc.setFalse();
        }}
      />
        </>
    )
}