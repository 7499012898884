import { Route, useHistory, Switch } from "react-router-dom";
import Commodity from "./page/commodity";
import Member from "./page/member";
import Marketing from "./page/marketing";
import Login from "./page/login";
import Survey from "./page/survey";
import { Shop } from "./page/shop";
import { Order } from "./page/order";
import { InStock } from "./page/warehousing";
import { User } from "./page/user";
import { System } from "./page/system";
import Register from "./page/register";
import { Forgot } from "./page/forgot";
import { MarketingPage } from "./page/marketing/pageindex";
import { Topic } from "./page/topic/pageIndex";
import { TopicBlank } from "./page/topic";
import Report from "./page/report";
import { AgentApply } from "./agent/agentApply";
import { AgentLogin } from "./agent/agentLogin";
import { Error } from "./error";
import { Distribute } from "./page/distribution";
import { Layout } from "./component/layout/index";
import { Help } from "./page/help";
import { HelpVideo } from "./page/help/Video";
import {Analysis} from './page/analysis/index'
import {MiniAdv} from './page/miniAdvertise/index'
import {Fapiao} from './page/fapiao/index'
import {ApplyFapiaoSuccess} from './page/fapiao/success'
export const AppGlobal = {
  his: null,
};

function App() {
  const his = useHistory();

  AppGlobal.his = his;
  return (
    <>
      <Switch>
        <Route path="/" component={Login} exact />
        <Route path="/login" component={Login} />
        <Route path="/forgot" component={Forgot} />
        <Route path="/register" component={Register} />
        <Route path="/help" component={Help} exact />
        <Route path="/video" component={HelpVideo} exact />
        <Route path="/fapiao" component={Fapiao} exact />
        <Route path="/success" component={ApplyFapiaoSuccess} exact />
        <Layout fullWidth={true}>
          <Route path="/survey" component={Survey} />
          <Route path="/commodity" component={Commodity} />
          <Route path="/shop" component={Shop} />
          <Route path="/order" component={Order} />
          <Route path="/in-stock" component={InStock} />
          <Route path="/marketing" component={Marketing} />
          <Route path="/market/page" component={MarketingPage} />
          <Route path="/member" component={Member} />
          <Route path="/user" component={User} />
          <Route path="/system" component={System} />
          <Route path="/topic" component={Topic} />
          <Route path="/agentApply" component={AgentApply} />
          <Route path="/error" component={Error} />
          <Route path="/distribution" component={Distribute} />
          <Route path="/report" component={Report} />
          <Route path='/analysis' component={Analysis} />
          {/* <Route path="/changeShop" component={ChangeShop} /> */}
          {/* <Route path='/mini-adv' component={MiniAdv}/> */}
        </Layout>
      </Switch>
    </>
  );
}
export default App;
// export default class ErrorBoundary extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { hasError: false };
//   }

//   componentDidCatch(error, info) {
//     // Display fallback UI
//     this.setState({ hasError: true });
//     // You can also log the error to an error reporting service
//     // logErrorToMyService(error, info);
//   }

//   render() {
//     if (this.state) {
//       // You can render any custom fallback UI
//       return <Error/>;
//     }
//     return <App/>
//   }
// }
