import React, { useContext, useEffect } from "react";
import { Button } from "../../../../component/button";
import { Layer } from "../../../../component/layer/layer";
import { Form as FormAntd, message } from "antd";
import { formAction, FormAction } from "../../action/shiftchange/form_action";
import { FormStore } from "../../store/shiftChange/form_store";
import { FormBasic } from "./form_basic";
import { useBoolean } from "ahooks";

interface IForm {
    children?: any;
    action: FormAction;
    close: (hasUpdate?: boolean) => void;
    id?: string;
    method?: string;
    item?:Object
}
export function Form({ close, action, id, method, item }: IForm) {
    const [form] = FormAntd.useForm();

    const { state, dispatch: formDispatch } = useContext(FormStore);
    const [loading, loadFunc] = useBoolean(false)
    useEffect(() => {

        formAction.init({ state: state, dispatch: formDispatch, id, method, form, item });

        return () => {

        }
    }, []);

    const title = () => {
        if ("Create" === method) {
            return "新建补班记录";
        }
        else if ("Edit" === method) {
            return "编辑档案";
        }
        else {
            return "  查看交班记录";
        }
    }

    return (
        <FormAntd form={form}>
            <Layer layerWith={2} >
                <Layer.Title onClick={() => close()}>{title()}</Layer.Title>
                <Layer.Body>
                    <FormBasic disabled={title() == "  查看交班记录"} form={form}/>
                </Layer.Body>
                {
                    title() != "  查看交班记录" && (
                        <>
                            <Layer.Foot>
                                <Button type="primary" color="Red" style={{ marginRight: "1.25rem" }} onClick={async () => {
                                    await form.validateFields();
                                    if(loading){
                                        message.loading('正在提交数据')
                                        return false
                                    }
                                    if(form.getFieldValue(['detail']) == undefined || form.getFieldValue(['detail']) instanceof Array? form.getFieldValue(['detail']).length == 0: false){
                                        message.error('请先获取补班数据')
                                        return
                                    }

                                    if ("Edit" === method) {
                                        loadFunc.setTrue()
                                        await action.updSubmit({ state: state.formData });
                                        loadFunc.setFalse()
                                    } else {
                                        loadFunc.setTrue()
                                        await action.addSubmit({ state: state.formData, form });
                                        loadFunc.setFalse()
                                    }

                                    close && close(true);
                                }}>
                                    保存
                                </Button>
                                <Button type="primary" color="Yellow" onClick={() => action.reset({ dispatch: formDispatch, form })}>
                                    重置
                                </Button>
                            </Layer.Foot>
                        </>
                    )
                }
                {
                    title() == "  查看交班记录" && (
                        <>
                            <Layer.Foot>
                            </Layer.Foot>
                        </>
                    )
                }
            </Layer>
        </FormAntd>
    )
}