// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../config";
import { effeative, maxPageSize } from "./common_logic";

export class AuthxtLogic {
    async selData(id: string) {
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("authxt_look"),
            params: {
                id
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r.data.data;
    }
    async querysList(param) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("authxt_list"),
            data: param,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    async gradeList() {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("authxt_list"),
            data: {
                gradeName: '',
                pageIndex: 1,
                pageSize: maxPageSize(),
                orderBy: '',
                sort: ''
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r.data.data.dataSource;
    }
    async addData(params) {

        return effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("authxt_add"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
    }
    async updData(params) {
        return effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("authxt_update"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
    }

}