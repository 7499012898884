import { createContext, useReducer } from "react";

export const FileStore = createContext(null);

type Dispatch = { type, payload }

class LinkParam{
   snappID?:string
   id?:string
}
class FormData{
   isLink: boolean = false
   linkType?: number = 0
   linkParam?: LinkParam
   imgUrl:''

  }
  class Search{

  }
interface IState{
    formData: FormData
    loading: boolean
    search: Search
    
}
const initState:IState = {
    formData: new FormData(),
    loading: false,
    search: new Search()
}



function reducer(state:IState, {type, payload}: Dispatch) {
    switch(type) {
        case 'Loading':
            state.loading = payload
            return{
                ...state
            }
        case 'Reset_Link':
            state.formData.linkType=0
            state.formData.linkParam = {}
            return{
                ...state,
                formData:{
                    ...state.formData
                }
            }
        case 'SetForm':
           const item = new FormData()
            return{
                ...state,
                formData:{
                    ...item
                }
            }
        case "SetSearch":
            return {
                ...state,
                search: {
                    ...state.search,
                    ...payload
                }
            }
         // 设置表单
         case "Change_FormData":
            {
                const { attribute, value } = payload, props: any = {};


                props[attribute] = value;

                return {
                    ...state,
                    formData: {
                        ...state.formData,
                        ...props
                    }
                }
            }
        default: return {...state }
    }
}
export function FileStoreProvider (props) {
 const [state, dispatch] = useReducer(reducer, initState);
    const v: any = { state, dispatch };
    return <FileStore.Provider value={v}>{props.children}</FileStore.Provider>
}


