import moment from "moment";
import { createContext, useContext, useReducer } from "react";
import { GoodsEntity } from "../../../../entity/goods_entity";
import { ShopEntity } from "../../../../entity/shop_entity";
import { UnitLogic } from "../../../../logic/unit_logic";

export const InventoryGoodsFormStore = createContext<IContextProps>(null);

interface IContextProps {
  state: IState;
  dispatch: ({ type }: Dispatch) => void;
}

type Dispatch = { type; payload };

class Search {
  keyword: string;
  areaId: string;
  statusId: string;
  typeId: string;
}
export class FormData {
  keyword: string;
}

export class IState {
  searchList: any[];
  remark: string;
}

const initialArgs: IState = {
  searchList: [],
  remark: "",
};

function reducer(state: IState, { type, payload }: Dispatch): IState {
  switch (type) {
    case "LoadShop":
      return {
        ...state,
      };
    /** 更新 */
    case "update":
      return {
        ...state,
      };

    case "SetSearchList":
      return {
        ...state,
        searchList: payload,
      };

    case "SetReamrk": {
      state.remark = payload;
      return {
        ...state,
      };
    }
    default:
      return state;
  }
}

export function InventoryGoodsFormStoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialArgs);

  const v = { state, dispatch };
  return (
    <InventoryGoodsFormStore.Provider value={v}>
      {props.children}
    </InventoryGoodsFormStore.Provider>
  );
}
