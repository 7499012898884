import { createContext, useContext, useReducer } from "react";

export const FileStore = createContext<IContextProps>(null);

interface IContextProps {
  state: IState;
  dispatch: ({ type }: Dispatch) => void;
}

type Dispatch = { type; payload };

class Search {
  seachKey: string;
  ticketsType: number = 4;
}

interface IState {
  search: Search;
  dataList: any[];
  isLoading: boolean;
  msg: string;
}

const initialArgs: IState = {
  search: new Search(),
  dataList: [],
  isLoading: false,
  msg: "打印中...",
};

function reducer(state: IState, { type, payload }: Dispatch) {
  switch (type) {
    case "isloading": {
      state.isLoading = payload.isLoading;
      state.msg = payload.msg;
      return { ...state };
    }
    case "SetSearch":
      return {
        ...state,
        search: {
          ...state.search,
          ...payload,
        },
      };
    default:
      return state;
  }
}

export function PrintSetFileStoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialArgs);

  const v = { state, dispatch };
  return <FileStore.Provider value={v}>{props.children}</FileStore.Provider>;
}
