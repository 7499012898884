import { Modal } from "antd";
import React, { Children, cloneElement } from "react";
import styles from "./index.module.scss";

interface IRadioStyle {
    children?: any;
}
export function RadioStyle({ children }: IRadioStyle) {
    return (
        Children.map(children, v => {
            const rootStyle = (v.props && v.props.className) ? v.props.className : "";
            return cloneElement(v, { className: rootStyle + " " + styles.root });
        })
    )
}