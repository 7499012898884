import styles from "../index.module.scss";
import { useEffect, useRef, useContext, useState } from "react";
import { autoRem } from "../../../global.style";
// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../../../config";
import { TransactionStore } from "../store/transaction_store";
export function MemberSale() {
  const { state, dispatch } = useContext(TransactionStore);

  const [dataList, setDataList] = useState([]);
  let type = state.condition.dateType;
  const [memerSaleRate, setSaleRate] = useState(null);
  const [memberOrderRate, setOrderRate] = useState(null);
  const [notMemerSaleRate, setNotSaleRate] = useState(null);
  const [notMemberOrderRate, setNotOrderRate] = useState(null);
  const [member, setMember] = useState(null);
  const [notMember, setNotMember] = useState(null);
  async function load() {
     const {dateType,startTime,endTime} = state.condition
    const r = await axios({
      method: "POST",
      url: Tools.getApiAddress("statistics_sale"),
      data: { 
        "dateType": dateType,
        "startTime": startTime,
        "endTime": endTime
       },
      headers: {
        SYSTOKEN: Cookies.get("token"),'ClientSource':'web',
      },
    });
    if (r.data.statusCode == 200) {
      const list = r.data.data;
      // console.log(list)
      // if(list.length >0 ){

      //     let ordertotal = list[0].orderCount+list[1].orderCount
      //     let saleTotal = list[0].salesPrice+list[1].salesPrice
      //     if(list[0].isMember == 1){
      //         setMember(list[0])
      //         setNotMember(list[1])
      //         setOrderRate(list[0].orderCount/ordertotal)
      //         setSaleRate(list[0].salesPrice/saleTotal)
      //         console.log(list[0].salesPrice/saleTotal)
      //     }else{
      //         setMember(list[1])
      //         setNotMember(list[0])
      //         setOrderRate(list[1].orderCount/ordertotal)
      //         setSaleRate(list[1].salesPrice/saleTotal)
      //     }

      // }
      let saleTotal = 0;
      let orderTotal = 0;
      if (list.length == 0) {
        dispatch({
          type: "Member",
          payload: {
            isMember: 1,
            orderCount: 0,
            salesPrice: 0,
          },
        });
      }
      if (list.length == 1) {
        if (list[0]) {
          if (list[0].isMember == 1) {
            setOrderRate(1);
            setSaleRate(1);
            setMember({ ...list[0] });
            setNotMember({
              isMember: 2,
              orderCount: 0,
              salesPrice: 0,
            });
            dispatch({ type: "Member", payload: list[0] });
          } else {
            setNotSaleRate(1);
            setNotOrderRate(1);
            setMember({
              isMember: 1,
              orderCount: 0,
              salesPrice: 0,
            });
            dispatch({
              type: "Member",
              payload: {
                isMember: 1,
                orderCount: 0,
                salesPrice: 0,
              },
            });
            setNotMember(list[0]);
          }
        }
      } else {
        for (let i = 0; i < list.length; i++) {
          saleTotal += list[i].salesPrice;
          orderTotal += list[i].orderCount;
          if (list[i].isMember == 1) {
            setMember({ ...list[i] });
            dispatch({ type: "Member", payload: list[i] });
            setOrderRate(list[i].orderCount / orderTotal);
            setSaleRate(list[i].salesPrice / saleTotal);
          }
          if (list[i].isMember == 2) {
            setNotMember(list[i]);
          }
          // else if(list[i].isMember == 2){
          //     setOrderRate(list[i].orderCount/orderTotal)
          //     setSaleRate(list[i].salesPrice/saleTotal)
          // }
        }
      }

      setDataList([...list]);
    }
  }

  const ll = [
    [
      {
        value: 0,
        name: "会员",
        itemStyle: { color: "#ffd24e" },
        textStyle: { color: "#005eff" },
        label: {
          normal: {
            textStyle: {
              color: "#ffd24e", // 改变标示文字的颜色
            },
          },
        },
      },
      {
        value: 0,
        name: "非会员",
        itemStyle: { color: "#0fe8c3" },
        label: {
          normal: {
            textStyle: {
              color: "#0fe8c3", // 改变标示文字的颜色
            },
          },
        },
      },
    ],
    [
      {
        value: 0,
        name: "会员",
        itemStyle: { color: "#ffd24e" },
        label: {
          normal: {
            textStyle: {
              color: "#ffd24e", // 改变标示文字的颜色
            },
          },
        },
      },
      {
        value: 0,
        name: "非会员",
        itemStyle: { color: "#0fe8c3" },
        label: {
          normal: {
            textStyle: {
              color: "#0fe8c3", // 改变标示文字的颜色
            },
          },
        },
      },
    ],
  ];
  const ss = [
    [
      {
        value:
          member && notMember
            ? (member.orderCount / (member.orderCount + notMember.orderCount)) *
              100
            : 0,
        name: "会员",
        itemStyle: { color: "#ffc80b" },
      },
      {
        value:
          member && notMember
            ? (notMember.orderCount /
                (member.orderCount + notMember.orderCount)) *
              100
            : 0,
        name: "非会员",
        itemStyle: { color: "#42dfb2" },
      },
    ],
    [
      {
        value:
          member && notMember
            ? (member.salesPrice / (member.salesPrice + notMember.salesPrice)) *
              100
            : 0,
        name: "会员",
        itemStyle: { color: "#ffc80b" },
      },
      {
        value:
          member && notMember
            ? (notMember.salesPrice /
                (member.salesPrice + notMember.salesPrice)) *
              100
            : 0,
        name: "非会员",
        itemStyle: { color: "#42dfb2" },
      },
    ],
  ];
  useEffect(() => {
    load();

    return () => {
      // setSaleRate(null)
      // setOrderRate(null)
    };
  }, [state.condition, type]);

  return (
    <div className={styles.left}>
      <div>
        <div className={styles.head_c}>
          <span>会员销售情况</span>
        </div>
        <div className={styles.sale_pie}>
          <div className={styles.saleMoney}>
            <span>会员销售额(元)：{member ? state.member.salesPrice : 0}</span>
          </div>
          <div className={styles.round}>
            {dataList.length > 0 &&
              ss.map((item, index) => {
                return (
                  <div className={styles.item}>
                    {" "}
                    <Pie data={item} rate={0} key={index} index={index} />
                  </div>
                );
              })}
            {dataList.length == 0 &&
              ll.map((item, index) => {
                return (
                  <div className={styles.item}>
                    {" "}
                    <Pie data={item} rate={0} key={index} index={index} />
                  </div>
                );
              })}
          </div>
          <div className={styles.explain}>
            <span
              className={styles.point}
              style={{ background: "#ffc80b" }}
            ></span>
            <span>会员</span>
            <span
              className={styles.point}
              style={{ background: "#42dfb2" }}
            ></span>
            <span>非会员</span>
          </div>
        </div>
      </div>
      <div className={styles.sale_table}>
        <div>
          <span>类型</span>
          <span>订单量</span>
          <span>订单占比</span>
          <span>消费金额</span>
          <span>金额占比</span>
        </div>
        {dataList.length > 0 && member && notMember && (
          <>
            <div>
              <span>{member.isMember == 1 && "会员"}</span>
              <span>{member.orderCount}</span>
              <span>
                {(
                  (member.orderCount /
                    (member.orderCount + notMember.orderCount)) *
                  100
                ).toFixed(2)}
                %
              </span>
              <span>{member.salesPrice}</span>
              <span>
                {(
                  (member.salesPrice /
                    (member.salesPrice + notMember.salesPrice)) *
                  100
                ).toFixed(2)}
                %
              </span>
            </div>
            <div>
              <span>{notMember.isMember == 2 && "非会员"}</span>
              <span>{notMember.orderCount}</span>
              <span>
                {(
                  (notMember.orderCount /
                    (member.orderCount + notMember.orderCount)) *
                  100
                ).toFixed(2)}
                %
              </span>
              <span>{notMember.salesPrice}</span>
              <span>
                {(
                  (notMember.salesPrice /
                    (member.salesPrice + notMember.salesPrice)) *
                  100
                ).toFixed(2)}
                %
              </span>
            </div>
          </>
        )}
        {/* {dataList.length>0&&dataList.map((item,index)=>{
                return  <div>
                            <span>{item.isMember==1?'会员':'非会员'}</span>
                            <span>{item.orderCount}</span>
                            <span>{(item.isMember==1?memberOrderRate*100:notMemberOrderRate*100).toFixed(2)}%</span>
                            <span>{item.salesPrice}</span>
                            <span>{item.isMember == 1?0:0}%</span>
                        </div>
            })} */}

        {!dataList.length ? <div className={styles.empty}></div> : null}
      </div>
    </div>
  );
}

declare let echarts: any;

function Pie({ data, rate, index }) {
  const divRef = useRef();
  useEffect(() => {
    var dom = divRef.current;

    var myChart = echarts.init(dom);
    var app = {};
    var option = null;

    option = {
      tooltip: {
        trigger: "item",
        formatter: "{b}:{d}%",
      },

      title: {
        text: `${index == 0 ? "订单量占比" : "销售额占比"}`,
        left: "center",
        top: "center",
        textStyle: {
          fontSize: autoRem(1),
          fontWeight: "normal",
          // fontFamily:'PingFangSC-Regular',
          color: "#333333",
        },
        // z:333
      },
      series: [
        {
          name: "访问来源",
          type: "pie",
          radius: ["40%", "60%"],
          // radius:'50%',
          avoidLabelOverlap: false,
          data: data,

          labelLine: {
            show: true,
            length: autoRem(5),
            length2: autoRem(10),
            textStyle: {
              fontSize: autoRem(1),
            },
          },
          label: {
            normal: {
              // 各分区的提示内容
              // params: 即下面传入的data数组,通过自定义函数，展示你想要的内容和格式
              formatter: function (params) {
                return params.name == "会员"
                  ? `{a|${params.name}}`
                  : `{b|${params.name}}`;
              },
              textStyle: {
                // 提示文字的样式
                color: "#ffc80b",
                fontSize: autoRem(1),
              },
              rich: {
                a: {
                  color: "#ffc80b",
                },
                b: {
                  color: "#42dfb2",
                },
              },
            },
          },
        },
      ],
    };
    if (option && typeof option === "object") {
      myChart.setOption(option, true);
    }
  }, [index, data]);

  return <div ref={divRef} style={{ height: "100%" }}></div>;
}
