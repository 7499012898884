import { useBoolean } from "ahooks";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { ConfigApi, Tools } from "../../../config";
import { Mask } from "../../mask";
import styles from "../style/renew.module.scss";
import img_renew from "../../../assets/images/renew.png";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
interface IRenew {
  visible: boolean;
  close: () => void;
}
//添加一个用来提示的元素
let popHtml = document.body.appendChild(document.createElement("div"));

/** 获取时间差天数 */
function getTimeDiffDay(_start: string | Date, _end: string | Date): number {
  let dateDiff =
    (typeof _end == "string" ? new Date(_end) : _end).getTime() -
    (typeof _start == "string" ? new Date(_start) : _start).getTime(); //时间差的毫秒数
  return Math.floor(dateDiff / (24 * 3600 * 1000));
}

export function Renew({ visible, close }: IRenew) {
  let [ifShow, setShow] = useState(false);
  const [time, setTime] = useState(6);
  const [_endTime, setEndTime] = useState("");
  const [info, setInfo] = useState([]);
  const his = useHistory()

  useEffect(() => {
    if(Cookies.get("loginMsg")==undefined){
      // message.error('登录失效，请登录')
      his.replace('/login')
      return
    }
    let msg = JSON.parse(Cookies.get("loginMsg"));
    if (typeof msg.versionType == "number" && msg.versionType != 3) {
      if (getTimeDiffDay(new Date(), msg.expireTime) <= 30) {
        setShow(true);
        setEndTime(msg.expireTime);
      }
    }
  }, []);
  useEffect(() => {
    (async function () {
      const r = await axios({
        method: "GET",
        url: Tools.getApiAddress("setmeal").replace(
          ConfigApi.serviceDomain,
          ConfigApi.operationDomain
        ),
      });

      setInfo(r.data.data.Items);
    })();

    return () => {};
  }, []);
  const agentID = localStorage.getItem("agentID");
  const landline = localStorage.getItem("landline");
  return visible ? (
    <div className={styles.root}>
      <div className={styles.content} onClick={(e) => e.stopPropagation()}>
        <div className={styles.content_head}>
          <img src={img_renew} />
          <span>续费提醒</span>
        </div>
        <div className={styles.content_mid}>
          {/* <span>您的店铺版本即将到期，请尽快续费</span>
          <span>
            {_endTime}到期，还剩{getTimeDiffDay(new Date(), _endTime)}天
          </span> */}
          <p>
            您当前版本
            {getTimeDiffDay(new Date(), _endTime) <= 0 ? "已到期" : "即将到期"}
            ，商品、会员、营销等功能将受限
          </p>
          {getTimeDiffDay(new Date(), _endTime) > 0 && (
            <p>
              {_endTime}到期，还剩{getTimeDiffDay(new Date(), _endTime)}天
            </p>
          )}
          <p>
            请联系客服：<span>{landline}</span>充值服务
          </p>
        </div>
        <div className={styles.content_bottom}>
          <button onClick={() => close()}>关闭</button>
        </div>
        {/* <h1>续费提醒<span>您当前版本已到期 所有功能将受限使用</span></h1>
                    {/* {agentID == '0'?<div className={styles.item_group}>
                        请联系平台管理员进行续费
                    </div>:<div className={styles.item_group}>
                        请联系管理员进行续费
                    </div>} 
                    <h2>请选择续费方式</h2>
                    <div className={styles.item_group}>

                        {
                            info.map(v => (
                                <div className={`${styles.item} ${styles.focus}`} style={{ width: "17.5rem" }}>
                                    <h1>{v.SetMealName}</h1>
                                    {v.SetMealType == 2 ? (
                                        <h2>{v.SMAmount}<span>元/年</span> <span>(<span className={styles.through}>2888元</span>)</span></h2>
                                    ) : (
                                            <h2>{v.SMAmount}<span>元/终生</span> <span>(<span className={styles.through}>6800元</span>)</span></h2>
                                        )}
                                    <div className={styles.line}></div>
                                    <span className={styles.btn}>立即续费</span>
                                    <i className={styles.top_badge1}></i>
                                    <i className={styles.bottom_badge}></i>
                                </div>
                            ))
                        }

                         <div className={`${styles.item} ${styles.focus}`} style={{ width: "17.5rem" }}>
                            <h1>永久</h1>
                            <h2>299<span>元/终生</span> <span>(<span className={styles.through}>1899元</span>)</span></h2>
                            <div className={styles.line}></div>
                            <span className={styles.btn}>立即续费</span>
                            <i className={styles.top_badge1}></i>
                            <i className={styles.bottom_badge}></i>
                        </div>

                        <div className={styles.item} style={{ width: "17.5rem" }}>
                            <h1>包年</h1>
                            <h2>599<span>元/终生</span> <span>(<span className={styles.through}>1899元</span>)</span></h2>
                            <div className={styles.line}></div>
                            <span className={styles.btn}>立即续费</span>
                            <i className={styles.top_badge2}></i>
                            <i className={styles.bottom_badge}></i>
                        </div>


                        
                        <div className={styles.item}>
                            <h1>普通版</h1>
                            <h2>299<span>元/终生</span> <span>(<span className={styles.through}>1899元</span>)</span></h2>
                            <div className={styles.line}></div>
                            <span className={styles.btn}>立即续费</span>
                            <i className={styles.bottom_badge}></i>
                        </div> 

                    </div>
                    <div className={styles.clear}></div>
                    <div className={styles.payment}>
                        应付金额: <span>899元</span>
                    </div>
                    <div className={styles.renew_method}>请选择续费方式</div>
                    <div className={styles.qrcode_group}>
                        <div className={styles.item}>
                            <div className={styles.qrcode}>

                            </div>
                            <h1><i className={styles.wx}></i>使用微信扫码付款</h1>
                        </div>
                        <div className={styles.item}>
                            <div className={styles.qrcode}>

                            </div>
                            <h1><i className={styles.zf}></i>使用支付宝扫码付款</h1>
                        </div>
                    </div> */}
      </div>
    </div>
  ) : null;
}
