import { createContext, useReducer } from "react";

class FormData {
    remark: string;
    vipId: string
    money: number
    memberName: string
}

type Dispatch = { type, payload }

interface IState {
    formData: FormData;
    loading:boolean
}

const initialArgs = {
    formData: new FormData(),
    loading: false
}

export type FormAttribute = "Remark" | 'VipId' | 'Money' | 'MemberName';

interface IContextProps {
    state: IState;
    dispatch: ({ type }: Dispatch) => void;
}

export const FormStore = createContext<IContextProps>(null);

function reducer(state: IState, { type, payload }: Dispatch) {
    switch (type) {
        case 'Loading':
            state.loading = payload
            return{
                ...state
            }
        // 填充表单
        case "SetForm":
            {
                const data = payload;
                const form = new FormData();
                {
                    const {remark,vipId,money } = data;
                    form.remark = remark;
                    form.money = money
                    form.vipId = vipId
                }

                return {
                    ...state,
                    formData: form
                }
            }
        // 设置表单
        case "Change_FormData":
            {
                const { attribute, value } = payload, props: any = {};

                const key: FormAttribute = attribute;
                let attri = "";

                switch (key) {
                    case "Remark":
                        attri = "remark";
                        break
                    case "VipId":
                        attri = 'vipId'; break
                    case 'Money':
                        attri = 'money';break
                    case 'MemberName':
                        attri = 'memberName';break
                    default:
                        break;
                }

                props[attri] = value;

                return {
                    ...state,
                    formData: {
                        ...state.formData,
                        ...props
                    }
                }
            }
        default:
            return state;
    }
}

export function FormStoreProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialArgs);

    const v = { state, dispatch };
    return <FormStore.Provider value={v}>{props.children}</FormStore.Provider>
}