import message from "antd/lib/message"
// import axios from "axios"
import Cookies from "js-cookie"
import { DataTableAction } from "../../../../component/table/action/data_table_action"
import { Tools, axios } from "../../../../config"
import { AlarmSetLogic } from "../../../../logic/alarm_set_logic"
import { ClassesLogic } from "../../../../logic/classes_logic";
class AlarmSetAction extends DataTableAction {
    async initBasic({ dispatch, state }) {
       // 商品分类
       dispatch({ type: "LoadClasses", payload: await new ClassesLogic().selListBySelect() });
    }
    async delRow(id) {
        const r = await new AlarmSetLogic().deleteAlarmSet(id);
        if (r.data.statusCode == 200) {
            message.success("删除成功")
        }
    }
    delSelectList({ selectList }: { dispatch: any; selectList: any; }) {
    }
    
    async loadPage({ dispatch, pageIndex, pageSize, search }) {
        this.setLoading({dispatch, loading:true})
        const { keywords, goodsClass } = search;
        const params: any = {
            "pageIndex": pageIndex,
            "pageSize": pageSize
        }
        if (keywords) {
            params.keyWords = keywords;
        }       
        if (goodsClass) {
            params.goodsClass = goodsClass;
        }       
       
        // 档案列表
        const r = await new AlarmSetLogic().getList(params);

        if (r.data.statusCode == 200) {
            const dataList = r.data.data.dataSource || [];

            const list = (dataList || []).map((v, i) => {
                v.key = i + 1;
                return v;
            })

            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: list, pageSize, loading: false });
        }else{
            this.setLoading({dispatch, loading: false})
        }

    }
  

    setCondition({ dispatch, key, value }) {
        const params = {
        }
        params[key] = value;

        dispatch({ type: "SetSearch", payload: params })
    }
}
export const alarmSetAction = new AlarmSetAction();