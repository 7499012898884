import styles from "../style/adv.module.scss";
import { useContext, useEffect, useState, useRef } from "react";
import { Carousel, message } from "antd";
import { TopicDataAction } from "../action/TopicDataAction";
import { ITopicValue, TopicStore } from "../store/topic";
import { Input, Space, Radio } from "antd";
import img_750_350 from "../../../assets/images/mini/group_750_350.png";
import { Tools } from "../../../config";
import { IAdvData } from "../enum/dataType";
import { DrageList } from "../com/dragList";
import { ISelectUrl } from "../view/modal/modal";
import { SelectUrl } from "../com/selectUrl";
import { ApiCon } from "./../com/apiCon";
import waitupload from "../../../assets/images/mini/waitupload.png";
function CarouselBox(props) {
  const list = props.data;
  return (
    <Carousel autoplay {...props.settings} dots={"advdots"}>
      {list && list.length > 0 ? (
        list.map((item, index) => (
          <div key={index}>
            <img
              src={
                item && item.fileUrl != ""
                  ? Tools.getImageAddress(item.fileUrl)
                  : item.selectUrl.imgUrl
                  ? Tools.getImageAddress(item.selectUrl.imgUrl, item.selectUrl.goodsSource)
                  : img_750_350
              }
            />
          </div>
        ))
      ) : (
        <div>
          <img src={img_750_350} alt="" />
        </div>
      )}
    </Carousel>
  );
}

function AdvImageList(props) {
  const list = props.data;
  return (
    <>
      {list && list.length > 0 ? (
        list.map((item, index) => (
          <div key={index}>
            <img
              src={
                item && item.fileUrl != ""
                  ? Tools.getImageAddress(item.fileUrl)
                  : item.selectUrl.imgUrl
                  ? Tools.getImageAddress(item.selectUrl.imgUrl, item.selectUrl.goodsSource)
                  : img_750_350
              }
            />
          </div>
        ))
      ) : (
        <div>
          <img src={img_750_350} alt="" />
        </div>
      )}
    </>
  );
}
export function Adv({ id, con }) {
  const topicStoreState = useContext(TopicStore);
  const defaultSettings = {
    dotPosition: "bottom",
  };
  const spaceStyle = {
    margin: "10px auto",
  };

  const initList = [];
  for (let i = 0; i < 3; i++) {
    initList.push({
      fileUrl: "",
      selectUrl: {
        key: "",
        title: "标题",
        imgUrl: "",
        url: "",
        type: "",
        isInit: true,
      },
    });
  }
  const initdata: IAdvData = {
    foldValue: 1,
    space: "noblank",
    datalist: initList,
  };
  let actionArg: [ITopicValue, number] = [topicStoreState, id];
  const data: IAdvData = TopicDataAction.getData<IAdvData>(...actionArg);
  const [value, setValue] = useState(1);
  const [isblank, setBlank] = useState("noblank");
  const [selectId, setSelectId] = useState(null);

  const changeFold = (e) => {
    const v = e.target.value;
    data.foldValue = v;

    TopicDataAction.setData<IAdvData>(...actionArg, data);
    setValue(v);
  };
  const changeBlank = (e) => {
    const v = e.target.value;
    data.space = v;
    TopicDataAction.setData<IAdvData>(...actionArg, data);
    setBlank(v);
  };
  const inputRef = useRef(null);

  /** 选择图片 */
  //   const selectImage = () => {
  //     inputRef.current.click();
  //   };
  /** 获取图片 */
  const inputChange = () => {
    const _file = inputRef.current.files[0];
    ApiCon.uploadImgGetUrl(_file).then((res) => {
      data.datalist[selectId].fileUrl = res;
      data.datalist[selectId].fileName = _file.name;
      TopicDataAction.setData<any>(...actionArg, data);
    });
  };

  const del = (index) => {
    data.datalist.splice(index, 1);
    TopicDataAction.setData<IAdvData>(...actionArg, data);
  };
  const add = () => {
    data.datalist.push({
      fileUrl: "",
      selectUrl: {
        key: "",
        imgurl: "",
        url: "",
        title: "标题",
        type: "",
        isInit: true,
        goodsSource: 1,
      },
    });
    TopicDataAction.setData<IAdvData>(...actionArg, data);
  };

  useEffect(() => {
    if (!data) {
      TopicDataAction.setData<IAdvData>(...actionArg, initdata);
    }
  });
  if (!data) {
    return <div></div>;
  }
  /** 编辑列表url */
  const editLinkItemUrl = (i: number, selectUrl: ISelectUrl) => {
    console.log(selectUrl)
    data.datalist[i].selectUrl = selectUrl;
    data.datalist[i].fileUrl = "";
    data.datalist[i].fileName = "";
    TopicDataAction.setData(...actionArg, data);
  };

  //注册数据提取方法
  TopicDataAction.setEF(topicStoreState, id, () => {
    return new Promise((r, e) => {
      const advdata = data.datalist.filter((item) => {
        return !item.selectUrl.isInit;
      });
      let youhui = advdata.some((adv) => {
        if (adv.selectUrl.type == "优惠券") return adv.fileUrl == "";
      });
      let classify = advdata.some((adv) => {
        if (adv.selectUrl.type == "分类") return adv.fileUrl == "";
      });
      if (advdata.length == 0) {
        message.error("有广告没有填充内容");
      } else if (youhui) {
        message.error("存在优惠券没有图片");
      } else if (classify) {
        message.error("存在分类没有图片");
      } else {
        ApiCon.turnBlobImgUrl(
          advdata.map((item) => {
            return {
              url: item.fileUrl,
              name: item.fileName,
            };
          })
        ).then((urls) => {
          let arr = advdata.map((item, index) => {
            return {
              ...item,
              fileUrl: urls[index],
            };
          });
          let d = {
            space: data.space,
            foldValue: data.foldValue,
            datalist: arr,
          };
          r(d);
        });
      }
    });
  });
  if (con == "show") {
    return (
      <>
        <div
          className={styles.adv}
          style={data.space === "blank" ? spaceStyle : null}
          onClick={() => {
            topicStoreState.dispatch({ type: "ACTIVE", payload: id });
          }}
        >
          <div className={styles.adv_images}>
            {data && data.datalist && data.datalist.length == 0 ? (
              <img src={waitupload} />
            ) : data.foldValue == 1 ? (
              <CarouselBox data={data.datalist} settings={defaultSettings} />
            ) : (
              <AdvImageList data={data.datalist} />
            )}
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className={styles.adv_ctrl} id="adv_ctrl_selected">
        <div className={styles.triangle_left}></div>
        <div className={styles.adv_ctrl_title}>广告图片设置</div>
        <Space direction="vertical" size={"middle"}>
          <div className={styles.formitems}>
            <span className={styles.formitems_name}>显示方式：</span>
            <div className={styles.formitems_cols}>
              {/* <Radio.Group size={'small'} onChange={changeFold} defaultValue={data.foldValue}  value={value}>
                                <Space  direction="vertical">
                                  <Radio value={1} >折叠显示</Radio>
                                    <Radio value={2}>分开显示</Radio>
                                </Space>
                            </Radio.Group> */}
              <div className={styles.input_radio}>
                <input
                  type="radio"
                  name="fold"
                  value={1}
                  id="fold1"
                  checked={data.foldValue == 1 ? true : false}
                  onChange={changeFold}
                />
                <label htmlFor="fold1">折叠显示</label>
              </div>
              <div className={styles.input_radio}>
                <input
                  type="radio"
                  name="fold"
                  value={2}
                  id="fold2"
                  checked={data.foldValue == 2 ? true : false}
                  onChange={changeFold}
                />
                <label htmlFor="fold2">分开显示</label>
              </div>
            </div>
          </div>
          <div className={styles.formitems}>
            <span className={styles.formitems_name}>整体上下留白：</span>
            <div className={styles.formitems_cols}>
              {/* <Radio.Group size={'small'} onChange={ changeBlank } defaultValue={data.space} value={isblank}>
                                <Space  direction="vertical">
                                    <Radio value='blank'>是</Radio>
                                    <Radio value='noblank'>否</Radio>
                                </Space>
                            </Radio.Group> */}
              <div className={styles.input_radio}>
                <input
                  type="radio"
                  name="blank"
                  value="blank"
                  id="blank1"
                  checked={data.space == "blank" ? true : false}
                  onChange={changeBlank}
                />
                <label htmlFor="blank1">是</label>
              </div>
              <div className={styles.input_radio}>
                <input
                  type="radio"
                  name="blank"
                  value="noblank"
                  id="blank2"
                  checked={data.space == "noblank" ? true : false}
                  onChange={changeBlank}
                />
                <label htmlFor="blank2">否</label>
              </div>
            </div>
          </div>

          {/* 广告商品列表 */}
          <input
            ref={inputRef}
            onChange={inputChange}
            type="file"
            accept="image/*"
            style={{ display: "none" }}
          />

          {data && data.datalist && (
            <DrageList
              list={data.datalist}
              onDragEnd={(list, oldi, newi) => {
                topicStoreState.dispatch({ type: "LIST_MOVE", payload: list });
              }}
            >
              {(good, i) => {
                return (
                  <div className={styles.ctrl_item_list} key={i + good.fileUrl}>
                    <div className={styles.advlist_operation}>
                      <span onClick={() => del(i)}>x</span>
                    </div>
                    {/* {good.name} */}
                    <div className={styles.ctrl_item_list_content}>
                      <div
                        className={styles.img_reselect}
                        onClick={() => {
                          setSelectId(i);
                          inputRef.current.click();
                          // selectImage()
                        }}
                      >
                        <img
                          src={
                            good.fileUrl != ""
                              ? Tools.getImageAddress(good.fileUrl)
                              : good.selectUrl.imgUrl
                              ? Tools.getImageAddress(good.selectUrl.imgUrl, good.selectUrl.goodsSource)
                              : img_750_350
                          }
                          alt=""
                        />
                        <span className={styles.img_reselect_op}>重新选择</span>
                      </div>
                      <div className={styles.img_info}>
                        <div className={styles.formitems}>
                          <label className={styles.formitems_name}>
                            链接到：{" "}
                          </label>
                          <div className={styles.formitems_cols}>
                            {/* {good.selectUrl.url!=''?<Link to='' className={styles.selecteditems}>{good.selectUrl.type}<span>{good.selectUrl.title}</span></Link>:null} */}
                            {/* <span onClick={()=>showModal()} className={styles.select_modal}>选择连接</span> */}

                            <SelectUrl
                              select={good.selectUrl}
                              selectedBack={(select: ISelectUrl) => {
                                editLinkItemUrl(i, select);
                              }}
                            />
                          </div>
                        </div>
                        <div className={styles.formitems}>
                          <label className={styles.formitems_name}>
                            标题：{" "}
                          </label>
                          <div className={styles.formitems_cols}>
                            <Input
                              size="small"
                              disabled
                              value={good.selectUrl.title}
                              style={{
                                padding: "2px 10px",
                                fontSize: 12,
                                width: 240,
                              }}
                            ></Input>{" "}
                          </div>
                        </div>
                        <div className={styles.formitems}>
                          <label className={styles.formitems_name}>
                            建议尺寸：{" "}
                          </label>
                          <span className={styles.formitems_cols}>750*350</span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }}
            </DrageList>
          )}
        </Space>
        <AddList addGood={() => add()} />
      </div>
    );
  }
}

function AddList({ addGood }) {
  return (
    <div className={styles.ctrl_list_add} onClick={() => addGood()}>
      +
    </div>
  );
}
