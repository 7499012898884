import React, { useEffect, useState } from 'react';
import styles from "../style/menu.module.scss";
import { survey, survey_w, member, member_w, commodity, commodity_w, order, order_w, shop, shop_w, stock, stock_w, system, user, user_w, system_w, editor, editor_w ,special, special_w ,report,report_w} from "../../variable";
import { useHistory, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { MenuLogic } from '../../../logic/menu_logic';
import { Progress } from '../../../logic/common_logic';
import help_btn from '../../../assets/images/icon/help.png'
export const Menu = ({helpClose}) => {
  let his = useHistory();
  const [menuLists, setData] = useState([])
  function handlerClick(path) {

    !his.location.pathname.includes(path) && Progress.start()

    his.replace(path)
  }

  const localtion = useLocation();
  const menuLogic = async function () {
    let r = await new MenuLogic().menuData();
    if (r && r.length > 0) {
      r = r.filter(function (v) {
        return !v.hidden
      })
      for (var i = 0; i <= r.length; i++) {

        if (r[i]) {
          if (r[i].meta.icon == 'survey') {
            r[i].meta.icon = survey
            r[i].meta['selectImg'] = survey_w
          }
          if (r[i].meta.icon == 'member') {
            r[i].meta.icon = member
            r[i].meta['selectImg'] = member_w
          }
          if (r[i].meta.icon == 'commodity') {
            r[i].meta.icon = commodity
            r[i].meta['selectImg'] = commodity_w
          }
          if (r[i].meta.icon == 'order') {
            r[i].meta.icon = order
            r[i].meta['selectImg'] = order_w
          }
          if (r[i].meta.icon == 'shop') {
            r[i].meta.icon = shop
            r[i].meta['selectImg'] = shop_w
          }
          if (r[i].meta.icon == 'stock') {
            r[i].meta.icon = stock
            r[i].meta['selectImg'] = stock_w
          }
          if (r[i].meta.icon == 'system') {
            r[i].meta.icon = system
            r[i].meta['selectImg'] = system_w
          }
          if (r[i].meta.icon == 'user') {
            r[i].meta.icon = user
            r[i].meta['selectImg'] = user_w
          }
          if (r[i].meta.icon == 'editor') {
            r[i].meta.icon = editor
            r[i].meta['selectImg'] = editor_w
          }
          if (r[i].meta.icon == 'special') {
            r[i].meta.icon = special
            r[i].meta['selectImg'] = special_w
          }
          if (r[i].meta.icon == 'report') {
            r[i].meta.icon = report
            r[i].meta['selectImg'] = report_w
          }
        }

      }
    }
    let memu = JSON.stringify(r)
    if (r != undefined && r != '') {
      if (!localStorage.getItem('menu') || memu != localStorage.getItem('menu')) {
        localStorage.setItem('menu', memu);
        setData(JSON.parse(localStorage.getItem('menu')));
      }
    }
  }
  useEffect(() => {
    (async function () {
      const r = localStorage.getItem('menu');

      if (localStorage.getItem('menu')) {
        setData(JSON.parse(localStorage.getItem('menu')));
        menuLogic()
      } else {
        menuLogic()
      }
    }())

    return () => {
    }
  }, []);

  return (
    <div className={styles.menu}>
      {
        menuLists.map((v, i) => (
          <div key={i} className={`${styles.item} ${v.path == localtion.pathname ? styles.active : ''}`} onClick={() => handlerClick(v.path)}>
            <i>
              <img className={styles.def} src={v.meta.icon} />
              <img className={styles.act} src={v.meta.selectImg} />
            </i>
            <span dangerouslySetInnerHTML={{ __html: v.name }}></span>
            <hr className={styles.line} />
            <hr className={styles.line_top} />
          </div>
        ))
      }
      {helpClose=='close' &&  <div key={'help'} className={styles.item} onClick={() => {
        
      }}>
            <i>
             
              <img className={styles.act} src={help_btn} />
            </i>
            <span ></span>
            <hr className={styles.line} />
            <hr className={styles.line_top} />
          </div>}
    </div>
  )
}