import message from "antd/lib/message"
// import axios from "axios"
import Cookies from "js-cookie"
import { DataTableAction } from "../../../../component/table/action/data_table_action"
import { Tools, axios } from "../../../../config"
import { PurchaseLogic } from "../../../../logic/warehouse/purchase_logic"
import { ShopLogic } from "../../../../logic/shop_logic"
import { UserLogic } from "../../../../logic/user_logic"
import { saveAs } from 'file-saver';

class PurchaseAction extends DataTableAction {

    async delRow(id) {
        const r = await new PurchaseLogic().delPurchase(id);
        console.log(r)
        // if (r.data.statusCode == 200) {
        //     message.success("删除成功")
        // }else{
        //     message.error(r.data.returnMessage)
        // }
    }
   async delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {
       
   }
    async loadPage({ dispatch, pageIndex, pageSize, search }) {
        dispatch({type:'SetLoading',payload:{loading: false, msg:''}})
        this.setLoading({dispatch, loading: true })
        const { auditStatus, purchaseNumber, purchaseTitle, sendStatus} = search;
        let { shopID, shopName } = JSON.parse(Cookies.get('loginMsg'))
        const params: any = {
            "shippable": sendStatus??undefined,
            "posShopID": shopID,
            "auditStatus": auditStatus,
            "purchaseNumber": purchaseNumber,
            "purchaseTitle": purchaseTitle,
            "pageIndex": pageIndex,
            "pageSize": pageSize
        }
        // if (keywords) {
        //     params.keyWords = keywords;
        // }
        // if (statusId) {
        //     params.inventoryStatus = statusId;
        // }
        // if (statusId == -1) {
        //     delete params.inventoryStatus;
        // }
        // if (shop && shop.length) {
        //     params.shopID = shop[0].id;
        // }
        // if (goods && goods.length) {
        //     params.goodsID = goods.map(v => v.id);
        // }

        // 档案列表
        const r = await new PurchaseLogic().selList(params);
        this.setLoading({dispatch, loading: false })
        console.log(r)

        if (r.data.statusCode == 200) {
            const dataList = r.data.data? r.data.data.dataSource || [] : [];

            const list = dataList.map((v, i) => {
                v.key = i + 1;
                return v;
            })
            const totalCount =  r.data.data ? r.data.data.totalCount : 0;
            this.setPaging({ dispatch, pageIndex, total: totalCount, dataList: list, pageSize, loading: false });
        } else{
            message.error(r.data.returnMessage)
            this.setLoading({dispatch, loading: false})
        }

    }
// 
//   设置查询条件
    setCondition({ dispatch, key, value }) {
        const params = {
        }
        params[key] = value;

        dispatch({ type: "SetSearch", payload: params })
    }

    // 提交审核
    async submitAudit({dispatch},msg, purchaseID){
        dispatch({type:'SetLoading',payload:{loading: true, msg: msg}})
        const r = await new PurchaseLogic().auditPurchase(purchaseID)
        dispatch({type:'SetLoading',payload:{loading: false, msg:''}})

        if(r.data.statusCode == 200) {
            message.success('提交成功')
        }else{
            message.error(r.data.returnMessage || r.data.message)
        }
    }

    
    // 发货单 
      /**获取发货单列表 */
      async getRecieptList({dispatch, purchaseID}){
            const r = await new PurchaseLogic().getRecieptList(purchaseID)
            if(r.data.statusCode == 200) {
                let list = r.data.data || []
                dispatch({type:'SetRecieptList',payload: list})
            }else{
                message.error(r.data.returnMessage || r.data.message)
            }
      } 
      /**
       * @description 获取发货单详情
       */
      async getRecieptGoodsList(id: string){
        const r = await new PurchaseLogic().getRecieptDetail(id)
        if(r.data.statusCode == 200) {
            let data = r.data.data
            return data
        }else{
            message.error(r.data.returnMessage || r.data.data)
        }
      }
      /**
       * @description 确认收货
       * @param dispatch
       * @param invoiceID 发货单id 
       */
      async confirmReciept(invoiceID: string){
        const r = await new PurchaseLogic().confirmReciept(invoiceID)
        if(r.data.statusCode == 200) {
           return true
        }else{
            message.error(r.data.returnMessage || r.data.data)
            return false
        }
      }
  
}
export const purchaseAction = new PurchaseAction();