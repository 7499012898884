import { PaymentCon } from "./paymentCon";
import {
  Upload,
  Col,
  Form,
  Form as FormAntd,
  Input,
  Radio,
  Row,
  Select,
  DatePicker,
  message,
  Button as AntdButton,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import { ApiCon } from "./apiCon";
import moment from "moment";

const { Option } = Select;

/**
 * 微信表单
 */
export function WXForm({ disabled }) {
  const [_, update] = useState(0);
  const [form] = Form.useForm();

  //填充数据
  useEffect(() => {
    ApiCon.getWX().then((d) => {
      if (!d) {
        return;
      }
      d["linkType"] = 1;
      //填充form表单
      form.setFieldsValue({
        ...d,
        certificateExpireDate: moment(d.certificateExpireDate),
      });
    });
  }, []);

  const props = {
    maxCount: 1,
    beforeUpload(file) {
      return ApiCon.uplodaFile(file)
        .then((d) => {
          form.setFieldsValue({
            pemStorage: d,
          });
          return true;
        })
        .catch((e) => {
          message.error(e);
          return false;
        });
    },
  };

  return (
    <>
      <Form form={form}>
        <div style={{ margin: "1.9375rem 0 0 0" }}>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item name="linkType" label="模式" labelCol={{ span: 5 }}>
                <Select
                  // disabled
                  onChange={() => {
                    update(_ + 1);
                  }}
                >
                  <Option value={1}>直连模式</Option>
                  <Option value={2}>商户模式</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item name="mchID" label="商户号" labelCol={{ span: 5 }}>
                <Input placeholder="请输入商户号" disabled={disabled} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item name="appID" label="APPID" labelCol={{ span: 5 }}>
                <Input placeholder="请输入APPID" disabled={disabled} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item name="apiKey" label="ApiKey" labelCol={{ span: 5 }}>
                <Input placeholder="请输入ApiKey" disabled={disabled} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                name="pemSecretKey"
                label="pem密钥"
                labelCol={{ span: 5 }}
              >
                <Input placeholder="请输入pem密钥" disabled={disabled} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12} style={{ display: "none" }}>
            <Col span={12}>
              <Form.Item
                name="pemStorage"
                label="pem密钥"
                labelCol={{ span: 5 }}
              ></Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item name="" label="pem存储" labelCol={{ span: 5 }}>
                <Upload {...props}>
                  <AntdButton icon={<UploadOutlined />}>点击上传</AntdButton>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                name="certificateExpireDate"
                label="证书到期时间"
                labelCol={{ span: 5 }}
              >
                <DatePicker placeholder="请选择到期时间" disabled={disabled} />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
      <PaymentCon
        save={() => {
          if (disabled) {
            return false;
          }
          form.validateFields().then(() => {
            ApiCon.updateWX({
              ...form.getFieldsValue(),
              certificateExpireDate: (
                form.getFieldValue("certificateExpireDate") as moment.Moment
              )?.format("YYYY-MM-DD"),
            })
              .then(() => {
                message.success("保存成功");
              })
              .catch((e) => {
                message.error(e);
              });
          });
        }}
        reset={() => {
          form.resetFields();
        }}
      />
    </>
  );
}
