import { createContext, useReducer } from "react";

class FormData {
    id: string;
    shopCode: string;
    shopName: string;
    memoryCode: string;
    shopPhone: string;
    type: number = 1;
    businessArea: string;
    peopleCount: string;
    contactPeople: string;
    contactPhone: string;
    status: number = 1;
    city: string[];
    address: string;
    remark: string;
    longitude: number;
    latitude: number;
    shopLogo: string;
}

type Dispatch = { type, payload }

interface IState {
    ifInit: boolean;
    method: string;
    formData: FormData;
}

const initialArgs: IState = {
    ifInit: false,
    method: '',
    formData: new FormData(),
}

export type FormAttribute = 'Logo' | "ShopCode" | "ShopName" | "MemoryCode" | "ShopPhone" | "Type" | "BusinessArea" | "PeopleCount" | "ContactPeople" | "ContactPhone" | "Status" | "City" | "Address" | "Remark" | "Longitude" | "Latitude";

interface IContextProps {
    state: IState;
    dispatch: ({ type }: Dispatch) => void;
}

export const FormStore = createContext<IContextProps>(null);

function reducer(state: IState, { type, payload }: Dispatch) {
    switch (type) {
        // 初始化
        case "init":
            return {
                ...state,
                ifInit: true,
                method: payload,
            }
        // 填充表单
        case "SetForm":
            {
                const data = payload;
                const form = new FormData();

                {
                    const { shopLogo,id, shopCode, shopName, helpCode, shopPhone, shopClasses, personNumber, linkman, phone, status, provincialCapital, city, district, detailedAddress, usableArea } = data;
                    form.id = id;
                    form.shopLogo = shopLogo
                    form.shopCode = shopCode;
                    form.shopName = shopName;
                    form.memoryCode = helpCode;
                    form.shopPhone = shopPhone;
                    form.type = shopClasses;
                    form.businessArea = usableArea;
                    form.peopleCount = personNumber;
                    form.contactPeople = linkman;
                    form.contactPhone = phone;
                    form.status = status;
                    //设置地理位置
                    form.city = [provincialCapital, city, district];
                    form.address = detailedAddress;
                    form.longitude = data.longitude;
                    form.latitude = data.latitude;
                }

                return {
                    ...state,
                    formData: form
                }
            }
        // 设置表单
        case "Change_FormData":
            {
                const { attribute, value } = payload, props: any = {};

                const key: FormAttribute = attribute;
                let attri = "";

                switch (key) {
                    case 'Logo':
                        attri = 'shopLogo';
                        break;
                    case "ShopCode":
                        attri = "shopCode";
                        break
                    case "ShopName":
                        attri = "shopName";
                        break
                    case "MemoryCode":
                        attri = "memoryCode";
                        break
                    case "ShopPhone":
                        attri = "shopPhone";
                        break
                    case "Type":
                        attri = "type";
                        break
                    case "BusinessArea":
                        attri = "businessArea";
                        break
                    case "PeopleCount":
                        attri = "peopleCount";
                        break
                    case "ContactPeople":
                        attri = "contactPeople";
                        break
                    case "ContactPhone":
                        attri = "contactPhone";
                        break
                    case "Status":
                        attri = "status";
                        break
                    case "City":
                        attri = "city";
                        break
                    case "Address":
                        attri = "address";
                        break
                    case "Remark":
                        attri = "remark";
                        break
                    case "Longitude":
                        attri = "longitude";
                        break
                    case "Latitude":
                        attri = "latitude";
                        break
                    default:
                        break;
                }

                props[attri] = value;

                return {
                    ...state,
                    formData: {
                        ...state.formData,
                        ...props
                    }
                }
            }
        default:
            return state;
    }
}

export function FormStoreProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialArgs);

    const v = { state, dispatch };
    return <FormStore.Provider value={v}>{props.children}</FormStore.Provider>
}