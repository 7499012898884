import { createContext, useContext, useReducer } from "react";

export const PaymentStore = createContext<IContextProps>(null);

interface IContextProps {
    state: IState;
    dispatch: ({ type }: Dispatch) => void;
}

type Dispatch = { type, payload }

interface IState {
    search: Search;
}
class Search {
    keywords: string;
    membeClassID: string;
};
const initialArgs = {
    dataList: [],
    search: new Search(),
}

function reducer(state: IState, { type, payload }: Dispatch) {
    switch (type) {
        case "SetSearch":
            return {
                ...state,
                search: {
                    ...state.search,
                    ...payload
                }
            }
        default:
            return state;
    }
}

export function PaymentStoreProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialArgs);

    const v = { state, dispatch };
    return <PaymentStore.Provider value={v}>{props.children}</PaymentStore.Provider>
}