import { message } from "antd";
// import axios from "axios";
import Cookies from "js-cookie";
import { DataTableAction } from "../../../component/table/action/data_table_action";
import { Tools, axios} from "../../../config";
import { ShopLogic } from "../../../logic/shop_logic";

export class FileAction extends DataTableAction {
    async delRow(id) {
        const r = await axios({
            method: "GET",
            url: Tools.getApiAddress("shop_delete"),
            params: {
                shopId: id
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })

        if (r.data.statusCode == 200) {
            message.success("删除成功")
        }
    }
    delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {
        selectList.map(async ({ id }) => {
            const r = await axios({
                method: "GET",
                url: Tools.getApiAddress("shop_delete"),
                params: {
                    shopId: id
                },
                headers: {
                    SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
                },
            })

            if (r.data.statusCode == 200) {
                message.success("删除成功")
            }
        })
    }
    async loadPage({ dispatch, pageIndex, pageSize, search }) {
        this.setLoading({dispatch,loading: true})
        const { keywords, typeId, statusId, areaId } = search;
        const params = {
            pageIndex,
            pageSize,
            seacherName: keywords,
            shopType: typeId,
            status: statusId,
            provincialCapital: (areaId && areaId.length >= 1) && areaId[0],
            city: (areaId && areaId.length >= 2) && areaId[1],
            district: (areaId && areaId.length >= 3) && areaId[2],
        };
        if ((areaId && areaId.length < 1)) {
            delete params.provincialCapital;
        }
        if ((areaId && areaId.length < 2)) {
            delete params.city;
        }
        if ((areaId && areaId.length < 3)) {
            delete params.district;
        }
        if(-1 === statusId){
            delete params.status;
        }

        // 档案列表
        const r = await new ShopLogic().selList(params);

        if (r.data.statusCode == 200) {
            const dataList = r.data.data.dataSource;

            const list = dataList.map((v, i) => {
                v.key = i + 1;
                return v;
            })

            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: list, pageSize, loading: false });
        }else{
            this.setLoading({dispatch,loading: false})
        }

    }
    setCondition(method: "Keywords" | "Area" | "Status" | "Type", value, dispatch) {
        if ("Keywords" == method) {
            dispatch({ type: "SetSearch", payload: { keywords: value } });
        }
        else if ("Area" == method) {
            dispatch({ type: "SetSearch", payload: { areaId: value } });
        }
        else if ("Status" == method) {
            dispatch({ type: "SetSearch", payload: { statusId: value } });
        }
        else if ("Type" == method) {
            dispatch({ type: "SetSearch", payload: { typeId: value } });
        }
    }
}
export const fileAction = new FileAction();