import moment from "moment";
import { createContext, useContext, useReducer } from "react";
import { List } from "../../../user/view/list/list";

export const FullgiftFormStore = createContext<IContextProps>(null);

interface IContextProps {
  state: IState;
  dispatch: ({ type }: Dispatch) => void;
}

type Dispatch = { type; payload };

class Search {
  keyword: string;
  areaId: string;
  statusId: string;
  typeId: string;
}
export class FormData {
  activityName: string;
  activityManner: number;
  isSuperimposing: boolean;
  isMarketing: boolean;
  beginDate: string;
  endDate: string;
  beginTime: string;
  targetType: number;
  endTime: string;
  marketingTypes: any;
  isMemberClass: boolean = true;
  memberClassIDs: any;
  shopIDs: any;
  shopName: string;

  activityStartDate: string;
  activityEndDate: string;
  activityStartTime: string;
  activityEndTime: string;
  isLimit: boolean;
  useType: any;
  useTypeValue: [];
  purposeType: number;
  range: number;
  activityTypes: any;
  discountZj: string;
  useweekTypeValue: [];
  activityRanges: [];
  shopId: string;
}
export class TableItem {
  serial: number;
  goodsId: string;
  goodsNo: string;
  goodsName: string;
  unitName: string;
  inStockUnitId: string;
  goodsClassName: string;
  putNewPrice: string;
  storePrice: string;
  giveCount: number;
  status: number;
  goodsSepID: string;
  goods_SpecificationList?: [];
}
interface IState {
  searchList: any[];
  formData: FormData;
  search: Search;
  tableList: TableItem[];
  tableListAdd: TableItem[];
  classifyList: TableItem[];
  TagList: any[];
  shoplist: any[];
}

const initialArgs: IState = {
  searchList: [],
  formData: new FormData(),
  search: new Search(),
  tableList: [],
  tableListAdd: [],
  classifyList: [],
  TagList: [],
  shoplist: [],
};

function reducer(state: IState, { type, payload }: Dispatch) {
  switch (type) {
    case "LoadShop":
      return {
        ...state,
        shoplist: payload,
      };
    /** 更新 */
    case "update":
      return {
        ...state,
      };
    case "SetSearchList":
      return {
        ...state,
        searchList: payload,
      };
    case "SetFormData": {
      return {
        ...state,
        formData: {
          ...state.formData,
          ...payload,
        },
      };
    }
    // 列表
    case "LoadTag":
      return {
        ...state,
        TagList: payload,
      };
    case "SetForm": {
      const {
        activityName,
        activityManner,
        isMarketing,
        isSuperimposing,
        marketingTypes,
        endDate,
        beginDate,
        beginTime,
        endTime,
        isMemberClass,
        tableList,
        tableListAdd,
        classifyList,
        targetType,
        shopIDs,
        memberClassIDs,
        activityStartDate,
        activityEndDate,
        activityStartTime,
        activityEndTime,
        useType,
        useTypeValue,
        purposeType,
        range,
        discountZj,
        activityTypes,
        shopName,
        shopID,
        activityRanges,
        goodsList,
      } = payload;
      const item = new FormData();
      item.activityManner = activityManner;
      item.targetType = targetType;
      item.isSuperimposing = isSuperimposing;
      item.isMarketing = isMarketing;
      item.beginDate = beginDate != null ? beginDate.slice(0, 10) : "";
      item.endDate = endDate != null ? endDate.slice(0, 10) : "";
      item.beginTime = beginTime != null ? beginTime.slice(0, 10) : "";
      item.endTime = endTime != null ? endTime.slice(0, 10) : "";
      item.useType = useType;
      item.useTypeValue = useType == 2 ? useTypeValue.split(",") : [];
      item.purposeType = purposeType;
      item.isMemberClass = isMemberClass;
      item.marketingTypes = marketingTypes;
      item.shopName = shopName;
      item.useweekTypeValue = useType == 1 ? useTypeValue.split(",") : [];
      let arr = [];
      if (memberClassIDs != "") {
        for (let i = 0; i < memberClassIDs.length; i++) {
          arr.push(memberClassIDs[i].memberClassID);
        }
      } else {
        arr = [];
      }
      item.memberClassIDs = arr;
      let shopArr = [];
      if (shopIDs != "") {
        for (let i = 0; i < shopIDs.length; i++) {
          shopArr.push(shopIDs[i].shopID);
        }
      } else {
        arr = [];
      }
      item.shopIDs = shopArr;
      item.activityName = activityName;
      return {
        ...state,
        formData: item,
        tableList: tableList,
        tableListAdd: tableListAdd,
        classifyList: classifyList,
      };
    }
    case "AddTableItem": {
      const list = state.tableList || [];
      //console.log(payload)
      // 去重
      if (
        list.every((v, i) =>
          payload.goodsId
            ? v.goodsId !== payload.goodsId
            : v.goodsId !== payload.id
        )
      ) {
        const item = new TableItem();
        const {
          goodsNo,
          goodsName,
          id,
          goodsUnitID,
          goodsUnitName,
          storePrice,
          putNewPrice,
          goodsClassName,
          status,
        } = payload;
        item.status = status;
        item.goodsId = id;
        item.goodsNo = goodsNo;
        item.goodsName = goodsName;
        item.inStockUnitId = goodsUnitID;
        item.unitName = goodsUnitName;
        item.putNewPrice = putNewPrice;
        item.storePrice = storePrice;
        item.goodsClassName = goodsClassName;

        list.push(payload);

        list.forEach((v, i) => (v.serial = i));
        return {
          ...state,
          tableList: [...list],
        };
      }
      return state;
    }
    case "addTableItemAdd": {
      //console.log(payload)
      const list = state.tableListAdd || [];
      // 去重
      if (
        list.every((v, i) =>
          payload.goodsSepID
            ? v.goodsSepID !== payload.goodsSepID
            : v.goodsSepID !== payload.goodsSepID
        )
      ) {
        const item = new TableItem();
        const {
          goodsNo,
          goodsSepID,
          goodsName,
          goodsID,
          giveCount,
          goodsUnitID,
          goodsUnitName,
          storePrice,
          putNewPrice,
          goodsClassName,
          status,
          goods_SpecificationList,
        } = payload;
        item.status = status;
        item.goodsId = goodsID;
        item.goodsSepID = goodsSepID;
        item.goodsNo = goodsNo;
        item.goodsName = goodsName;
        item.giveCount = giveCount;
        item.inStockUnitId = goodsUnitID;
        item.unitName = goodsUnitName;
        item.putNewPrice = putNewPrice;
        item.storePrice = storePrice;
        item.goodsClassName = goodsClassName;
        item.goods_SpecificationList = goods_SpecificationList;
        list.push(item);

        list.forEach((v, i) => (v.serial = i));

        return {
          ...state,
          tableListAdd: [...list],
        };
      }
      return state;
    }
    case "AddclassTableItem": {
      if (payload.length > 0) {
        return {
          ...state,
          classifyList: [...payload],
        };
      }

      return state;
    }
    case "AddGradeItem": {
      var list = state.formData.marketingTypes || [];
      if (payload == "add") {
        if (list.length < 5) {
          let obj = {
            meetMoney: "",
            discountMoney: "",
            addMoney: "",
            giveCount: "",
            stage: list.length + 1,
          };
          list.push(obj as never);
        }
      } else {
        if (payload == "addOne") {
          list = [];
          let obj = {
            meetMoney: "",
            discountMoney: "",
            addMoney: "",
            giveCount: "",
            stage: list.length + 1,
          };
          list.push(obj as never);
        } else {
          if (list.length > 1) {
            list.pop();
          }
        }
      }
      state.formData.marketingTypes = list;
      return {
        ...state,
        formData: state.formData,
      };
    }
    case "DelTableclassItem": {
      const list = state.classifyList || [];

      list.splice(payload, 1);
      return {
        ...state,
        classifyList: [...list],
      };
    }
    case "DelTableItem": {
      const list = state.tableList || [];

      list.splice(payload, 1);

      return {
        ...state,
        tableList: [...list],
      };
    }
    case "DelTableItemAdd": {
      const list = state.tableListAdd || [];

      list.splice(payload, 1);

      return {
        ...state,
        tableListAdd: [...list],
      };
    }
    case "ChangeTableItemAdd": {
      const { index, key, value } = payload;

      const list = state.tableListAdd || [];

      const item = list[index];

      item[key] = Number(value);

      if (undefined == item.giveCount) {
        item.giveCount = 0;
      }
      // 赠送库存
      if ("giveCount" == key) {
        item.giveCount = item.giveCount;
      }

      return {
        ...state,
        tableListAdd: [...list],
      };
    }
    // case 'ClearClass'
    case "ClearTableList":
      return {
        ...state,
        tableList: [],
      };
    case "ClearTableListAdd":
      return {
        ...state,
        tableListAdd: [],
      };
    default:
      return state;
  }
}

export function FullgiftFormStoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialArgs);

  const v = { state, dispatch };
  return (
    <FullgiftFormStore.Provider value={v}>
      {props.children}
    </FullgiftFormStore.Provider>
  );
}
