import {MiniAdvertise} from './view/advertise'
// import { AdvStoreProvider } from './store/file_store'
import {FileStoreProvider} from './store/file_store'
import {File} from './view/index'
import { DataTableStoreProvider } from '../../component/table/store/data_table_store'
    export function MiniAdv({viewPower}){
    return <div>
        {/* <DataTableStoreProvider> */}
        <FileStoreProvider><File viewPower={viewPower}/></FileStoreProvider>
        {/* </DataTableStoreProvider> */}
    </div>
}