// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../config";
import { effeative } from "./common_logic";
import { ConfigApi }from '../config'
export interface ISelList {
    pageIndex,
    pageSize,
    goodsClassesID?,
    status?
    goodsType?
    goodsBrandID
    goodsNameOrNo
}

export class GoodsLogic {

    classifyName(dataList, id) {
        const item = dataList.filter(v => (
            v == id
        ))
        return item;
    }
    async selData(goodsId: string, ShopID?:string, Source?:string) {
        let param = {
            goodsID: goodsId,
            ShopID: ShopID,
            Source: Source
        }
        if(ShopID == undefined){
           delete param.ShopID
        }
        if(Source == undefined){
            delete param.Source
        }
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("goods_query"),
            params: param,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r.data.data;
    }
    async selList({ pageIndex, pageSize, goodsClassesID, status, goodsType, goodsBrandID, goodsNameOrNo }: ISelList) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("goods_sel_page"),
            data: {
                goodsClassesID,
                goodsName: "",
                simpCode: "",
                goodsNo: goodsNameOrNo,
                goodsType,
                goodsBrandID,
                status,
                englishName: "",
                pageIndex: pageIndex,
                pageSize: pageSize,
                orderBy: "",
                sort: "",
                goodsNameOrNo
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    async selListForSpecification(params) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("inverntory_guige_list"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    async selListByZeroInventory({ pageIndex, pageSize, goodsClassesID, status, goodsType, goodsBrandID, goodsNameOrNo }: ISelList) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("goods_select_page_by_zero_inventory"),
            data: {
                goodsClassesID,
                goodsName: "",
                simpCode: "",
                goodsNo: "",
                goodsType,
                goodsBrandID,
                status,
                englishName: "",
                pageIndex: pageIndex,
                pageSize: pageSize,
                orderBy: "",
                sort: "",
                goodsNameOrNo
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    async addData(state) {
        const params = this.paddingData(state);

        return effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("goods_add"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))

    }
    async updData(state) {
        const params = this.paddingData(state);

        params.specificationUpdateDtos = params.specificationAddDtos;
        delete params.specificationAddDtos;

        return effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("goods_update"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))

    }
    private paddingData(state) {
        let params: any = {};

        {
           const {isNoMarketing, isManualDiscounts, id, classesId, unitId, goodsName, goodsImage, subImages, description, simpleCode, type, brandId, statusId, sort, english, remark, goodsCode } = state;

            params = {
                id,
                goodsClassesID: classesId,
                goodsUnitID: unitId,
                goodsName: goodsName,
                goodsImage: goodsImage && goodsImage.toString(),
                subImages: subImages && subImages.toString(),
                detailed: description,
                simpCode: simpleCode,
                goodsNo: goodsCode,
                goodsType: type,
                goodsBrandID: brandId,
                status: statusId,
                isNoMarketing: isNoMarketing,
                isManualDiscounts: isManualDiscounts,
                specificationAddDtos: [
                ],
                sort: sort,
                englishName: english,
                remark: remark
            }
        }
        {
            const { formatList } = state;

            formatList.forEach((v) => {
                const {isNegativeInventory,wnnCode, isDefault, remark, pluCode,  id, image, unitId, unitContentValue, unitContentId, barCode, shopPrice, storePrice, lineationPrice, lowestPrice, highestPrice, isShop, isStore } = v;

                params.specificationAddDtos.push(
                    {
                        id,
                        goodsID: state.id,
                        specificationImage: image,
                        goodsUnitID: unitId,
                        unitContent: unitContentValue,
                        barCode: barCode,
                        shopPrice: shopPrice,
                        storePrice: storePrice,
                        lineationPrice: lineationPrice,
                        minPrice: lowestPrice,
                        maxPrice: highestPrice,
                        isShop: isShop,
                        isStore: isStore,
                        pluCode,
                        remark,
                        isNegativeInventory,
                        isDefault,
                        wnnCode
                    }
                )
            })
        }

        return params;
    }

    async ConvertToSimp(op) {
        let _opString: string = '';
        for (let i in op) {
            _opString += `${i}=${op[i]}&`;
        }
        _opString = _opString.replace(/&$/, '');
        //
        return effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("goods_convertToSimp") + `?${_opString}`,
            data: op,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
    }

    async updateStatus(op){
        return effeative(await axios({
            method:'POST',
            url: Tools.getApiAddress('goods_update_status'),
            params: {State:op.state},
            data: op.goods,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
    }
    async getGoodsInfo(BarCode){
        // url: Tools.getApiAddress("advertising").replace(ConfigApi.serviceDomain, ConfigApi.operationDomain),
        return effeative(await axios({
            method:'GET',
            url: Tools.getApiAddress("getcode_for_info").replace(ConfigApi.serviceDomain, ConfigApi.operationDomain),
            params:{BarCode:BarCode},
            // headers:{
            //     SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            // }
        }))
    }
    async getImageList(param){
        return effeative( await axios({
            method:'GET',
            url: Tools.getApiAddress("img_list").replace(ConfigApi.serviceDomain, ConfigApi.operationDomain),
            params: param,
             
        }))
    }
    async getImageOne(id){
        return effeative( await axios({
            method:'GET',
            url: Tools.getApiAddress("get_img_One"),
            params: {ID: id},
            headers:{
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            }
        }))
    }
    async queryGoodsPrint({ pageIndex, pageSize, goodsClassesID, status, goodsType, goodsBrandID, goodsNameOrNo }: ISelList){
        // return effeative( await axios({
        //     method:'POST',
        //     url: Tools.getApiAddress('goods_print'),

        // }))
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("goods_print"),
            data: {
                goodsClassesID,
                goodsName: "",
                simpCode: "",
                goodsNo: goodsNameOrNo,
                goodsType,
                goodsBrandID,
                status,
                englishName: "",
                pageIndex: pageIndex,
                pageSize: pageSize,
                orderBy: "",
                sort: "",
                goodsNameOrNo
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    // goods_info_bySpeId
    async getGoodsInfoBySpeId(param){
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("goods_info_bySpeId"),
            params: param,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r
    }
    async getGoodsInfoByBarCode(param){
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("goods_info_byBarCode"),
            params: param,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r
    }
    // 获取商品规格信息 一条一条
    async getGoodsSpecList(param){
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("goods_speclist_query"),
            data: param,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r
    }
}