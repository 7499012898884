// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../config";
import { effeative } from "./common_logic";
export class PrintSetLogic{
     /**
     * 分页查询信息
     */
      async queryList(params) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("print_set_list"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    /**新增模板 
     * @param  ticketsType int 小票类型(1:58收银2:80收银;3:80配送;4:58价签)
     * @param ticketsJson string 配置json字符串
     * @param isStencil boolean 该类型是否是模版
    */
    async add(params) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("print_set_add"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    /**编辑模板
     *  @param ticketsJson*	string 配置json字符串
        @param isStencil*	boolean 该类型是否是模版
        @param id*	string主键ID
     */
    async update(params) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("print_set_update"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    /**删除模板 
     * @param ID paremeter
    */
    async delete(id) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("print_set_del"),
            params: {ID:id},
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
     /**应用模板 
     * @param ID paremeter
    */
      async setInuse(params) {
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("print_set_inuse"),
            params: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
}