import { useBoolean } from "ahooks";
import { Button, Input, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect, useState } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { Search } from "../../../../component/search";
import { DataTable } from "../../../../component/table";
import { DataTableStore } from "../../../../component/table/store/data_table_store";
import { Form } from "./form";
import { ConfirmModal } from "../../../../component/modal";
import { Tips, TipsGroup } from "../../../../component/tips";
import { AlarmSetSearch } from "./search";
import { AlarmSetFormStoreProvider } from "../../store/alarm_set/alarmSet_form_store";
import { AlarmSetStore } from "../../store/alarm_set/alarmSet_store";
import { costFormAction } from "../../action/cost/cost_form_action";
import { alarmSetAction } from "../../action/alarmSet/file_action";
import { Mask } from "../../../../component/mask";
import { defualtPageSize, Progress } from "../../../../logic/common_logic";
import { AuthLogic } from "../../../../logic/auth_logic";
import { Control, See, Edit, Del } from "../../../../component/control-column";

const formParams = {
  method: "Create",
  id: "",
  subId: "",
  item: null,
};

export function AlarmSet(viewPower) {
  const [createState, createFunc] = useBoolean(false);
  const [searchState, searchFunc] = useBoolean(false);
  const [confirmState, confirmFunc] = useBoolean(false);

  const { state, dispatch } = useContext(AlarmSetStore);
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);

  const action = alarmSetAction,
    formGoodsAction = costFormAction;
  const [menuButton, setData] = useState([]);
  useEffect(() => {
    (async function () {
      let r = await new AuthLogic().selButton(viewPower.viewPower);
      if (r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          r[i] = r[i].split("_")[r[i].split("_").length - 1];
        }
      }
      setData(r);
    })();

    return () => {};
  }, []);
  const columns: ColumnsType = [
    {
      title: "操作",
      key: "1",
      align: "center",
      width: Control.width(2),
      render: Control.render(
        (v, r) =>
          r.status !== 1 &&
          menuButton.indexOf("DELETE") != -1 &&
          v.status !== 1 && (
            <Del
              click={() => {
                formParams.id = v.numberNo;
                formParams.subId = r.numberNo;

                confirmFunc.setTrue();
              }}
            />
          )
      ),
    },
    {
      title: "商品编码",
      dataIndex: "goodsNo",
      key: "2",
      align: "center",
      width: 180,
    },
    {
      title: "商品名称",
      dataIndex: "goodsName",
      key: "3",
      align: "center",
    },
    {
      title: "商品分类",
      dataIndex: "goodsClassName",
      key: "4",
      align: "center",
    },
    {
      title: "商品单位",
      dataIndex: "goodsUnitName",
      key: "5",
      align: "center",
    },
    {
      title: "预警值下限",
      dataIndex: "minNumber",
      key: "6",
      align: "center",
    },
    {
      title: "预警值上限",
      dataIndex: "maxNumber",
      key: "7",
      align: "center",
    },
    // {
    //   title: "门店",
    //   dataIndex: "shopName",
    //   key: "3",
    //   align: "center",
    // },
  ];

  const onSearch = async (pageIndex) => {
    await action.loadPage({
      pageIndex: pageIndex,
      pageSize: defualtPageSize(),
      dispatch: tableDispatch,
      search: state.search,
    });
  };

  return (
    <>
      <Search>
        <Input
          maxLength={50}
          placeholder="请输入商品编码、名称"
          onChange={(e) =>
            action.setCondition({
              dispatch,
              key: "keywords",
              value: e.target.value,
            })
          }
        />
        <input type="button" value="快速查找" onClick={() => onSearch(1)} />

        <TipsGroup>
          <input
            type="button"
            value="高级查找"
            onClick={() => searchFunc.toggle()}
          />
          <Tips width="536px" display={searchState}>
            <AlarmSetSearch
              onSearch={(...arg: [any]) => {
                onSearch(...arg);
                searchFunc.setFalse();
              }}
            />
          </Tips>
          <Mask visible={searchState} onClick={() => searchFunc.setFalse()} />
        </TipsGroup>
      </Search>

      <ButtonPanel>
        {menuButton.indexOf("ADD") != -1 && (
          <Button
            onClick={() => {
              formParams.id = "";
              formParams.method = "Create";

              createFunc.setTrue();
            }}
          >
            新增预警设置
          </Button>
        )}
       
      </ButtonPanel>

      <DataTable
        columns={columns}
        action={action}
        onLoad={async () => {
          await onSearch(1);
          Progress.done();
        }}
        onChange={(pageIndex) => {
          onSearch(pageIndex);
        }}
        scroll={{ scrollToFirstRowOnChange: true, autoY: true }}
        loading={tableState.loading}
      />

      {createState && (
        <AlarmSetFormStoreProvider>
          <Form
            audit={menuButton.indexOf("AUDIT") != -1 ? true : false}
            close={(hasUpdate) => {
              if (hasUpdate) {
                onSearch(tableState.current);
              }
              createFunc.setFalse();
            }}
            action={formGoodsAction}
            id={formParams.id}
            subId={formParams.subId}
            item={formParams.item}
            method={formParams.method}
          />
        </AlarmSetFormStoreProvider>
      )}

      <ConfirmModal
        visible={confirmState}
        title="温馨提示"
        subTitle="确认删除？"
        message="删除之后信息无法恢复，请慎重考虑"
        close={async (r) => {
          if (r) {
            await action.delRow(formParams.subId);

            onSearch(tableState.current);
          }
          confirmFunc.setFalse();
        }}
      />
    </>
  );
}
