import React, { useEffect } from "react";
import { useState } from "react";
import styles from "../style/head.module.scss";

import img_fankui from "../../../assets/images/fankui.png";
import img_shangcheng from "../../../assets/images/shangcheng.png";
import img_xufei from "../../../assets/images/xufei.png";
import img_iconfontxiazai from "../../../assets/images/iconfontxiazai.png";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import Modal from "antd/lib/modal/Modal";
import { Form, Input, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useBoolean } from "ahooks";
import axios from "axios";
import { Feedback } from "./feedback";
import { Renew } from "./renew";
import { ConfigApi, Tools } from "../../../config";
import { UserLogic } from "../../../logic/user_logic";
import { RightOutlined } from "@ant-design/icons";
// import back_url from "../../../assets/images/banner2.png";
 import back_url from "../../../assets/images/banner3.png";
import logo_url from "../../../assets/images/logo3.png";
import { VoiceLogic } from "../../../logic/voice_login";
import { OrderLogic } from "../../../logic/order_logic";
import { ShopLogic } from "../../../logic/shop_logic";
import { getGSCloudPlugin } from "../../../assets/js/GSCloudPluginFuncs";

let GSCloudPlugin = getGSCloudPlugin();
let logo = "";
let timer = null;
let ws = null
function ChangePasswd({ visibleModal, close: _close, exit }) {
  const [form] = useForm();

  const close = () => {
    form.resetFields();
    _close();
  };

  const handleOk = async () => {
    await form.validateFields();

    const oldPasswd = form.getFieldValue("old-passwd");
    const newPasswd = form.getFieldValue("new-passwd");

    new UserLogic()
      .undatePasswor({
        currentPassword: oldPasswd,
        confirmPassword: newPasswd,
      })
      .then((data) => {
        if (data.statusCode == 200) {
          message.success("修改成功");
          exit();
          close();
        } else {
          message.error(data.returnMessage);
        }
      });
  };

  const handleCancel = () => {
    close();
  };

  return (
    <Modal
      title="修改密码"
      style={{ top: 20 }}
      visible={visibleModal}
      okText="提交"
      cancelText="取消"
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Form
        name="basic"
        initialValues={{ remember: true }}
        form={form}
        style={{ textAlign: "left" }}
      >
        <Form.Item
          label="旧密码"
          name="old-passwd"
          style={{ marginLeft: 30 }}
          rules={[{ required: true, message: "请输入旧密码!" }]}
        >
          <Input.Password placeholder="请输入旧密码!" />
        </Form.Item>
        <Form.Item
          label="新密码"
          name="new-passwd"
          style={{ marginLeft: 30 }}
          rules={[{ required: true, message: "请输入新密码!" }]}
        >
          <Input.Password placeholder="请输入新密码!" />
        </Form.Item>
        <Form.Item
          label="确认新密码"
          name="re-passwd"
          rules={[
            {
              required: true,
              validator: (rule, value, callback: (error?: string) => void) => {
                if (value == "" || value == undefined) {
                  callback("请再次输入新密码");
                } else if (value != form.getFieldValue("new-passwd")) {
                  callback("两次密码输入不一致");
                } else {
                  callback();
                }
              },
            },
          ]}
        >
          <Input.Password placeholder="请再次输入新密码!" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export const Head = () => {
  const [info, setInfo] = useState(false);
  const [messageCon, setMessageCon] = useState<{
    show: boolean;
    content?: any;
  }>({
    show: false,
  });
  const [mesegeNum, setMesegeNum] = useState(0);
  const [banner, setBanner] = useState(null);
  const [logo, setLogo] = useState("");

  const [visibleModal, visibleModalFunc] = useBoolean(false);
  const [feedback, feedbackFunc] = useBoolean(false);
  const [renew, renewFunc] = useBoolean(false);
  // 是否自动打印  门店参数设置获取
  const [isAutoPrint, autoPrintFunc] = useBoolean(false)
  const [isExpired, setIsExpired] = useState(false);

  const exit = () => {
    Cookies.remove("token");
    Cookies.remove("loginMsg");
    localStorage.removeItem("logo");
    localStorage.removeItem("SysLogo");
    his.replace("/login");
  };
  const [loginMsg, setData] = useState(Object);
  const his = useHistory();
  useEffect(() => {
    (async function () {
      if (window.localStorage.getItem("mesegeNum")) {
      }    
      clearInterval(timer);
      // timer = setInterval(async function () {
      // let r = await new VoiceLogic().queryOrderCount();
      if (Cookies.get("loginMsg") == undefined) {
        his.replace("/login");
        return false;
      }
      var url = JSON.parse(Cookies.get("loginMsg")).socketUrl;
      // 是否自动打印
      const shopRes = await new ShopLogic().getShopSettings()
      if(shopRes.data.statusCode == 200){
        if(shopRes.data.data.isPrint){
          window.localStorage.setItem('isAutoPrint', 'true') 
        }else{
          window.localStorage.setItem('isAutoPrint', 'false') 
        }
      }
      socket(url);
      function socket(url) {
        if (url == undefined) {
          return;
        }
        // console.log(url);
         ws = new WebSocket(url);
        ws.onopen = function () {
          message.success("通讯组件连接成功。");
          // setInterval(() => {
          //   ws.close();
          // }, 7000);
          setInterval(function () {
            ws.send("1");
          }, 5000);
        };
        ws.onclose = async function () {
          // 关闭 websocket
          if(his.location.pathname == '/login'){
              ws.close()
          }else{
            message.warning("通讯组件重连中....");
            setTimeout(async () => {
              const item = await new OrderLogic().prevConnectServer();
              // console.log(item);
              socket(item);
            }, 5000);
          }
         
        };
        ws.onmessage = async  function (evt) {
          console.log(evt)
          var received_msg = JSON.parse(evt.data);
          if (received_msg.stype == 1) {
            
            // setTimeout(async function () {
              var msg = new SpeechSynthesisUtterance(
                "您有一笔新的订单,请及时处理"
              );
              msg.volume = 100;
              msg.rate = 1;
              msg.pitch = 1.5;
              window.speechSynthesis.speak(msg);
              // 打印             
                 if(window.localStorage.getItem("isAutoPrint") == 'true'){
                  console.log('autoprint')
                  var loginMsg = JSON.parse(Cookies.get('loginMsg'))
                  GSCloudPlugin.SetAppKey(
                    "BC85333E6E2345A9AC2C39635C722682",
                    "四川智如客科技有限公司"
                  );
    
                  let that = this
                  var url = document.location.toString().split("/")
                  GSCloudPlugin.PrintHtml({
                      Title: 'HTML0001',
                      Width: 210,
                      Height: 0,
                      Url: url[0] + '//' + url[1] + url[2]+'/print.html?shopName='+loginMsg.shopName+'&orderId='+received_msg.args+'&token='+Cookies.get('token'),
                      PrinterName: '', // app.getPrinterName()方法仅用于在线演示
                      OnSuccess: function(result) {
                          console.log(result)
                      },
                      OnError: function(result) {
                        console.log(result)
                      },
                    });
                 }else{
                  console.log('not autoprint')
                 }
              
            // }, 1000);
          } else {
            // setTimeout(() => {
              var msg = new SpeechSynthesisUtterance(
                "您有一笔新的订单退款申请,请及时处理"
              );
              msg.volume = 100;
              msg.rate = 1;
              msg.pitch = 1.5;
              window.speechSynthesis.speak(msg);
            // }, 1000);
          }
        };
        ws.onerror = function (evt) {
          message.error("通讯组件异常。");
        };
      }
    })();
    return () => {
      clearInterval(timer);
      ws.close()
    };
  }, []);
  useEffect(() => {
    (async function () {
      if (Cookies.get("loginMsg") == undefined) {
        his.push("/login");
        return false;
      }
      let msg = Cookies.get("loginMsg")
        ? JSON.parse(Cookies.get("loginMsg"))
        : his.push("/login");
      msg.beginTime =
        msg.beginTime != null ? msg.beginTime.substr(0, 10) : msg.beginTime;
      msg.expireTime =
        msg.expireTime != null ? msg.expireTime.substr(0, 10) : "无";
      // 永久
      if (msg.expireTime !== "无") {
        if (new Date(msg.expireTime).getTime() < new Date().getTime()) {
          setIsExpired(true);
        }
      }
      setData(msg);
    })();

    return () => {};
  }, []);

  useEffect(() => {
    (async function () {
      const r = await axios({
        method: "GET",
        url: Tools.getApiAddress("advertising").replace(
          ConfigApi.serviceDomain,
          ConfigApi.operationDomain
        ),
        params: {
          type: 1,
        },
      });

      if (r && r.data && r.data.data && r.data.data.length > 0) {
        let banners = r.data.data;
        let num = 0;
        if (banners.length > 0) {
          setBanner(
            Tools.getImageAddress(banners[0].PosterUrl).replace(
              ConfigApi.imgDomain,
              ConfigApi.operationDomain
            )
          );
        } else if (banners.length > 1) {
          setInterval(function () {
            setBanner(
              Tools.getImageAddress(banners[num].PosterUrl).replace(
                ConfigApi.imgDomain,
                ConfigApi.operationDomain
              )
            );

            num++;
            if (num >= banners.length) {
              num = 0;
            }
          }, 1000 * 30);
        }
      } else {
      }
    })();
    setTimeout(() => {
      if (localStorage.getItem("SysLogo")) {
        setLogo(
          Tools.getImageAddress(localStorage.getItem("SysLogo")).replace(
            ConfigApi.imgDomain,
            ConfigApi.operationDomain
          )
        );
      } else {
        setLogo(logo_url);
      }
      //     let banners = JSON.parse(localStorage.getItem('posterUrl'))
      //     if(banners){
      //         setBanner(Tools.getImageAddress(banners[0].PosterUrl).replace(ConfigApi.imgDomain, ConfigApi.operationDomain));
      //         let num = 0;
      //         if(banners.length>0){
      //             setBanner(Tools.getImageAddress(banners[0].PosterUrl).replace(ConfigApi.imgDomain, ConfigApi.operationDomain));
      //           }else

      //             if (banners.length > 1) {
      //                 setInterval(function () {

      //                     setBanner(Tools.getImageAddress(banners[num].PosterUrl).replace(ConfigApi.imgDomain, ConfigApi.operationDomain));

      //                     num++;
      //                     if (num >= banners.length) {
      //                         num = 0;
      //                     }
      //                 }, 1000 * 30);
      //             }
      //         }
    }, 500);

    return () => {};
  }, [logo]);

  const download = async () => {
    // const r = await axios({
    //     method: "GET",
    //     url: Tools.getApiAddress("version").replace(ConfigApi.serviceDomain, ConfigApi.agentDomain)
    // })
    window.open(
      Tools.getApiAddress("version").replace(
        ConfigApi.serviceDomain,
        ConfigApi.operationDomain
      ) + "?Encode=LCQ",
      "_self"
    );
    // let blob = new Blob([r.data], { type: 'application/vnd.ms-excel' })
    // saveAs(blob, "会员档案.xlsx");
    // if(r&&r.data&&r.data.data){
    //     const elt = document.createElement('a');
    //     elt.setAttribute('href', `${ConfigApi.operationDomain}${r.data.data.VerPack}`);
    //     // elt.setAttribute('href', 'http://localhost:3000/survey');

    //     elt.setAttribute('download', r.data.data.VerName);
    //     elt.style.display = 'none';
    //     document.body.appendChild(elt);
    //     elt.click();
    //     document.body.removeChild(elt);
    // }
  };
  return (
    <div className={styles.nav_c}>
      <div className={styles.logo_c}>
        <div className={styles.logo} onClick={() => his.replace("survey")}>
          <img src={logo} />
        </div>
      </div>
      <div
        className={styles.banner}
        style={{ backgroundImage: `url(${banner? banner : back_url})` }}
      ></div>
      <div className={styles.right}>
        <div className={styles.btn_c}>
          <div
            className={styles.item}
            key={0}
            onClick={() => feedbackFunc.setTrue()}
          >
            <i></i>
            <span>反馈</span>
          </div>
          <div
            className={styles.item}
            key={1}
            onClick={() => {
              message.info("功能开发中，敬请期待");
            }}
          >
            <i></i>
            <span>商城</span>
          </div>
          {(loginMsg.versionType == 1 || loginMsg.versionType == 2) && (
            <div
              className={styles.item}
              key={2}
              onClick={() => renewFunc.setTrue()}
            >
              <i></i>
              <span>续费</span>
            </div>
          )}
          {/* 收银端下载 */}
          {/* <div className={styles.item} key={3} onClick={() => download()}>
            <i></i>
            <span>下载</span>
          </div> */}
          <div
            className={`${styles.item} ${styles.message}`}
            onMouseOver={() => setMessageCon({ show: true })}
            onMouseOut={() => setMessageCon({ show: false })}
          >
            <i></i>
            <span>消息</span>
            {mesegeNum > 0 && (
              <span className={styles.number}>{mesegeNum}</span>
            )}
            <div
              className={styles.drop_down}
              style={{ display: messageCon.show ? "block" : "none" }}
            >
              <div>
                <div style={{ color: "#404040" }}> 订单消息</div>
                <div>
                  <span className={styles.message_content}>{mesegeNum}</span>
                </div>
              </div>
              {/* <div>
                <div style={{ color: "#404040" }}> 消息类型</div>
                <div>
                  <span className={styles.message_content}>1</span>
                </div>
              </div>
              <div>
                <div style={{ color: "#404040" }}> 消息类型</div>
                <div>
                  <span className={styles.message_content}>1</span>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div
          className={styles.head_c}
          onMouseOver={() => setInfo(true)}
          onMouseOut={() => setInfo(false)}
        >
          <i></i>
          <span>{loginMsg.phone}</span>
          <div
            className={styles.msg_c}
            style={{ display: info ? "block" : "none" }}
          >
            <div className={styles.item}>
              <span>店铺：{loginMsg.shopName}</span>
            </div>
            <div className={styles.item}>
              <span>
                版本：
                {loginMsg.versionType == 1
                  ? "试用版"
                  : loginMsg.versionType == 2
                  ? "年版"
                  : "终身版本"}
              </span>
            </div>
            <div className={styles.item}>
              <span>开通时间：{loginMsg.beginTime}</span>
            </div>
            <div className={styles.item}>
              <span>到期时间：{loginMsg.expireTime}</span>
            </div>
            <div className={styles.item}>
              <input
                className={styles.btn_change}
                type="button"
                value="修改密码"
                onClick={() => visibleModalFunc.setTrue()}
              />
              <input
                className={styles.btn_exit}
                type="button"
                value="退出"
                onClick={exit}
              />
            </div>
          </div>
        </div>
      </div>

      {/* 修改密码 */}
      <ChangePasswd
        visibleModal={visibleModal}
        close={() => visibleModalFunc.setFalse()}
        exit={exit}
      />

      {/* 问题反馈 */}
      <Feedback
        title="问题反馈"
        visible={feedback}
        close={() => {
          feedbackFunc.setFalse();
        }}
      />

      {/* 续费 */}
      {(loginMsg.versionType == 1 || loginMsg.versionType == 2) && (
        <Renew
          visible={isExpired ? true : renew}
          close={() => {
            if (!isExpired) {
              renewFunc.setFalse();
            }
          }}
        />
      )}
    </div>
  );
};
