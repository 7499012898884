import React, { useContext, useEffect } from "react";
import { Button } from "../../../component/button";
import { Layer } from "../../../component/layer/layer";
import { Form as FormAntd } from "antd";
import { formAction, FormAction } from "../action/form_action";
import { FormStore } from "../store/form_store";
import { FormBasic } from "./form_basic";
import {fileAction} from '../action/file_action'

interface IForm {
    children?: any;
    action: FormAction;
    close: (hasUpdate?: boolean) => void;
    id?: string;
    method?: string;
    orderNumber?:string;
    status?:number;
    orderType?:number
    returnState?:number
}
export function Form({ close, action, id, method, orderNumber, status, orderType, returnState }: IForm) {
    const [form] = FormAntd.useForm();

    const { state, dispatch: formDispatch } = useContext(FormStore);

    useEffect(() => {
        formAction.init({ state: state, dispatch: formDispatch, id, form ,orderNumber });
        return () => {

        }
    }, [orderNumber]);

    const title = () => {
        switch (method){
            case 'Update':
                return '修改订单状态'
            case 'Audit':
                return '订单退款审核'
            case 'Refund':
                return '订单退款'
            default :
                return "查看订单";
        }
    }

    return (
        <FormAntd form={form} size="small">
            <Layer width="100%" >
                <Layer.Title onClick={() => close()}>{title()}</Layer.Title>
                <Layer.Body>
                    <FormBasic disabled={title() == "查看订单"} />
                </Layer.Body>

                <Layer.Foot borderStyle={title()=='查看订单'?{border:'none'}:{}}>
                {   
                        orderType == 2 ? title() == '修改订单状态'? (
                            <>
                                {status == 3&&<Button type="primary" color="Red" loading={state.isLoading}  style={{ marginRight: "1.25rem" }} onClick={async () => {
                                    const r = await form.validateFields();
                                    let flag = false;
                                    if (id) {
                                        flag = await fileAction.changeStateSingle({orderNumber:orderNumber,State:2});
                                    } 
                                   
                                    flag && (close && close(true))
                                }}>
                                    发货
                                </Button>}
                             {(status == 2 || status == 4)&&   <Button type="primary" color="Red" loading={state.isLoading}  onClick={async () => {
                                    const r = await form.validateFields();
                                    let flag = false;
                                    if (id) {
                                       flag = await fileAction.changeStateSingle({orderNumber:orderNumber,State:5});                                    
                                        flag && (close && close(true))
                                    }
                                }}>
                                完成
                                </Button>}
                            </>
                        ):title() == '订单退款审核' && returnState == 5  ?<>
                             <Button type="primary" color="Red" style={{ marginRight: "1.25rem" }} loading={state.isLoading}  onClick={async () => {
                                    const r = await form.validateFields();
                                    let flag = false;
                                    if (id) {
                                        formDispatch({type:'isloading',payload: true})
                                        flag = await fileAction.refundAudit({orderNumber:orderNumber,status:0,dispatch:formDispatch});
                                        formDispatch({type:'isloading',payload: false})
                                    } 
                                   
                                    flag && (close && close(true))
                                }}>
                                    通过
                                </Button>
                                <Button type="primary" color="Yellow" loading={state.isLoading} style={{ marginRight: "1.25rem" }} onClick={async () => {
                                    const r = await form.validateFields();
                                    let flag = false;
                                    if (id) {
                                        formDispatch({type:'isloading',payload: true})
                                        flag = await fileAction.refundAudit({orderNumber:orderNumber,status:1,dispatch:formDispatch});
                                        formDispatch({type:'isloading',payload: false})
                                    } 
                                   
                                    flag && (close && close(true))
                                }}>
                                    拒绝
                                </Button>
                        </>: title() == '订单退款' && <>
                                <Button type="primary" color="Red" style={{ marginRight: "1.25rem" }} loading={state.isLoading}  onClick={async () => {
                                    const r = await form.validateFields();
                                    let flag = false;
                                    if (id) {
                                        formDispatch({type:'isloading',payload: true})
                                        flag = await fileAction.refundAudit({orderNumber:orderNumber,status:0,dispatch:formDispatch});
                                        formDispatch({type:'isloading',payload: false})
                                    } 
                                   
                                    flag && (close && close(true))
                                }}>退款</Button>
                        </>: null
                    }
                </Layer.Foot>
            </Layer>
        </FormAntd>
    )
}