import { useBoolean } from "ahooks";
import { Button, message, Input, Modal, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect, useState } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { DataTable } from "../../../../component/table";

import img_edit from "../../../../assets/images/bianji.png";
import img_see from "../../../../assets/images/chakan1.png";
import img_del from "../../../../assets/images/shanchu.png";

import { fileAction } from "../../action/dossier/file_action";
import { FormStoreProvider } from "../../store/dossier/form_store";
import { Form } from "./form";
import { formAction } from "../../action/dossier/form_action";
import { DataTableStore } from "../../../../component/table/store/data_table_store";

import { Search } from "../../../../component/search";
import { Tips, TipsGroup } from "../../../../component/tips";
import { FileSearch } from "./file_search";
import { FileStore } from "../../store/dossier/file_store";
import { Mask } from "../../../../component/mask";
import { ConfirmModal } from "../../../../component/modal";
import normal from "../../../../assets/images/icon/status/zhengque.png";
import stop from "../../../../assets/images/icon/status/jinyong.png";
import loss from "../../../../assets/images/icon/status/qidong.png";
import {
  defualtPageSize,
  fillHeight,
  Progress,
} from "../../../../logic/common_logic";
import { AuthLogic } from "../../../../logic/auth_logic";
import {
  Control,
  Del,
  Edit,
  ResetPwd,
  See,
} from "../../../../component/control-column";

let editId = "";
let seeId = "";
const formParams = {
  method: "Create",
  id: "",
};
export function Dossier(viewPower) {
  const [createState, createFunc] = useBoolean(false);
  const [deleteState] = useBoolean(false);
  const [resetConfirmState, resetConfirmFunc] = useBoolean(false); //是否重置密码询问框
  const [confirmState, confirmFunc] = useBoolean(false);
  const [searchState, searchFunc] = useBoolean(false);
  const [confirmAllState, confirmAllFunc] = useBoolean(false);

  const action = fileAction;
  const { state, dispatch } = useContext(FileStore);
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);

  const [menuButton, setData] = useState([]);
  useEffect(() => {
    (async function () {
      let r = await new AuthLogic().selButton(viewPower.viewPower);
      if (r && r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          r[i] = r[i].split("_")[r[i].split("_").length - 1];
        }
      }
      setData(r);
    })();

    return () => {};
  }, []);
  const columns: ColumnsType = [
    {
      title: "操作",
      key: "1",
      align: "center",
      fixed: "left",
      width: Control.width(4),
      render: Control.render(
        (v) =>
          menuButton.indexOf("VIEW") != -1 && (
            <See
              click={() => {
                editId = "";
                seeId = v.id;
                createFunc.setTrue();
              }}
            />
          ),
        (v) =>
          menuButton.indexOf("UPDATE") != -1 && (
            <Edit
              click={() => {
                editId = v.id;
                seeId = "";
                createFunc.setTrue();
              }}
            />
          ),
        (v) =>
          menuButton.indexOf("UPDATE") != -1 && (
            <ResetPwd
              click={() => {
                formParams.id = v.id;
                resetConfirmFunc.setTrue();
              }}
            />
          ),
        (v) =>
          menuButton.indexOf("DELETE") != -1 && (
            <Del
              click={() => {
                formParams.id = v;
                confirmFunc.setTrue();
              }}
            />
          )
      ),
    },
    {
      title: "会员姓名",
      dataIndex: "memberName",
      key: "2",
      align: "center",
    },
    {
      title: "手机号码",
      dataIndex: "phone",
      key: "3",
      align: "center",
    },
    {
      title: "等级",
      dataIndex: "gradeName",
      key: "4",
      align: "center",
    },
    {
      title: "总消费金额(￥)",
      dataIndex: "totalConsumption",
      key: "5",
      align: "center",
    },

    {
      title: "账户余额",
      dataIndex: "balanceMoney",
      key: "6",
      align: "center",
    },
    {
      title: "账户积分",
      dataIndex: "accountIntegral",
      key: "7",
      align: "center",
    },
    {
      title: "会员来源",
      key: "8",
      align: "center",
      render: (v) => <Tag color="default">{v.source}</Tag>,
    },

    {
      title: "会员标签",
      dataIndex: "label",
      key: "9",
      width: 150,
      align: "center",
    },
    {
      title: "会员状态",
      key: "10",
      align: "center",
      dataIndex: "status",
      render: (v) =>
        v == "挂失" ? (
          <Tag color="warning">挂失</Tag>
        ) : v == "停用" ? (
          <Tag color="error">停用</Tag>
        ) : (
          <Tag color="success">正常</Tag>
        ),
    },
    {
      title: "注册门店",
      dataIndex: "enrollShopName",
      key: "11",
      align: "center",
    },
    {
      title: "注册时间",
      dataIndex: "addDateTime",
      key: "127",
      align: "center",
      width: 150,
    },
  ];
  const onSearch = async (pageIndex) => {
    await action.loadPage({
      pageIndex: pageIndex,
      pageSize: defualtPageSize(),
      dispatch: tableDispatch,
      search: state.search,
    });
  };
  return (
    <>
      <Search>
        <Input
          allowClear
          placeholder="请输入会员卡号/姓名/手机号码"
          onChange={(e) =>
            action.setCondition("Keywords", e.target.value, dispatch)
          }
        />
        <input type="button" value="快速查找" onClick={() => onSearch(1)} />

        <TipsGroup>
          <input
            type="button"
            value="高级查找"
            onClick={() => searchFunc.toggle()}
          />
          <Tips width="536px" display={searchState}>
            <FileSearch
              onSearch={(...arg: [any]) => {
                onSearch(...arg);
                searchFunc.setFalse();
              }}
            />
          </Tips>
          <Mask visible={searchState} onClick={() => searchFunc.setFalse()} />
        </TipsGroup>
      </Search>

      <ButtonPanel>
        {menuButton.indexOf("ADD") != -1 && (
          <Button
            onClick={() => {
              formParams.id = "";
              formParams.method = "Create";

              editId = "";
              seeId = "";
              createFunc.setTrue();
            }}
          >
            新建档案
          </Button>
        )}
        {menuButton.indexOf("DELETE") != -1 && (
          <Button
            onClick={async () => {
              if (!tableState.selectList.length) {
                message.warning("请选择删除项");
              } else {
                confirmAllFunc.setTrue();
              }
            }}
          >
            批量删除
          </Button>
        )}

        {menuButton.indexOf("EXPORT") != -1 && (
          <Button
            onClick={async () => {
              await action.exportList({ search: state.search });
            }}
          >
            导出
          </Button>
        )}
      </ButtonPanel>

      <DataTable
        columns={columns}
        action={fileAction}
        onLoad={async () => {
          await onSearch(1);
          Progress.done();
        }}
        onChange={(pageIndex) => {
          onSearch(pageIndex);
        }}
        scroll={{ scrollToFirstRowOnChange: true, autoY: true, x: 1100 }}
        selectColumn={true}
        loading={tableState.loading}
      />

      {createState && (
        <FormStoreProvider>
          <Form
            close={(hasUpdate) => {
              if (hasUpdate) {
                onSearch(tableState.current);
              }

              createFunc.setFalse();
            }}
            action={formAction}
            editId={editId}
            seeId={seeId}
          />
        </FormStoreProvider>
      )}

      <ConfirmModal
        visible={confirmState}
        title="温馨提示"
        subTitle="确认删除？"
        message="删除之后信息无法恢复，请慎重考虑"
        close={async (r) => {
          if (r) {
            await action.delRow({ dispatch, rowItem: formParams });
            onSearch(tableState.current);
          }
          confirmFunc.setFalse();
        }}
      />

      <ConfirmModal
        visible={confirmAllState}
        title="温馨提示"
        subTitle="确认删除？"
        message="删除之后信息无法恢复，请慎重考虑"
        close={async (r) => {
          if (r) {
            await action.delSelectList({
              dispatch,
              selectList: tableState.selectList,
            });

            action.clearSelectedRowKeys({ dispatch: tableDispatch });

            await onSearch(tableState.current);
          }
          confirmAllFunc.setFalse();
        }}
      />
      <ConfirmModal
        visible={resetConfirmState}
        title="温馨提示"
        subTitle="确认重置支付密码？"
        message="重置会员支付密码为123456，无法恢复，请慎重考虑"
        close={async (r) => {
          if (r) {
            await action.resetPwd({ dispatch, id: formParams.id });
            onSearch(tableState.current);
          }
          resetConfirmFunc.setFalse();
        }}
      />
    </>
  );
}
