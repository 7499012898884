import { useBoolean, useDebounceFn } from "ahooks";
import { Button, Input, message, Tag, Spin, Select } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
} from "react";
import Cookies from "js-cookie";
import { ButtonPanel } from "../../../component/button-panel/button_panel";
import { DataTable } from "../../../component/table";

// import img_edit from "../../../assets/images/bianji.png";
// import img_see from "../../../assets/images/chakan1.png";
// import img_del from "../../../assets/images/shanchu.png";

import { fileAction } from "../action/file_action";
import { FormStoreProvider } from "../store/form_store";
import { Form } from "./form";
import { formAction } from "../action/form_action";
import { DataTableStore } from "../../../component/table/store/data_table_store";
import { Search } from "../../../component/search";
import { Tips, TipsGroup } from "../../../component/tips";
import { FileSearch } from "./file_search";
import { FileStore } from "../store/file_store";
import { Mask } from "../../../component/mask";
import { defualtPageSize, Progress } from "../../../logic/common_logic";
import { AuthLogic } from "../../../logic/auth_logic";
import { Control, Edit, Refund, See,Print } from "../../../component/control-column";
import { ConfirmModal } from "../../../component/modal";
import img_refund from "../../../assets/images/icon/refund.png";
const formParams = {
  method: "Create",
  id: "",
  orderNumber: "",
  state: 0,
  orderType: 2,
  toState: 2,
  returnState:null
};

export function File(viewPower) {
  const { Option } = Select;
  const [createState, createFunc] = useBoolean(false);
  const [orderState, orderStateFunc] = useBoolean(false);
  const [refund, refundFunc] = useBoolean(false);
  const [] = useBoolean(false);
  const action = fileAction;
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);
  const [searchState, searchFunc] = useBoolean(false);
  const { state, dispatch } = useContext(FileStore);
  const [menuButton, setData] = useState([]);
  //socket-- start
  const ws = useRef<WebSocket | null>(null);
  const [info, setInfo] = useState("");
  //启动
  // useLayoutEffect(() => {
  //   const token = Cookies.get("token");
  //   ws.current = new WebSocket(
  //     "ws://192.168.101.110:19999/ws?SYSTOKEN=" + token
  //   );

  //   ws.current.onmessage = (e) => {
  //     setInfo(e.data);
  //     if (e.data) {
  //       var synth = window.speechSynthesis;
  //       const audioInfo = new SpeechSynthesisUtterance(e.data);
  //       synth.speak(audioInfo);
  //       // speechSynthesis.speaking
  //     }
  //   };
  //   return () => {
  //     ws.current?.close();
  //   };
  // }, [ws]);
  //socket-- end
  useEffect(() => {
    (async function () {
  dispatch({type:'isloading',payload:{isLoading: false,msg:''}});

      let r = await new AuthLogic().selButton(viewPower.viewPower);
      if (r && r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          r[i] = r[i].split("_")[r[i].split("_").length - 1];
        }
      }
      setData(r);
    })();
    return () => {
      dispatch({type:'isloading',payload:{isLoading: false,msg:''}})
    };
  }, []);
  const calcTime = (time) => {
    //订单完成后可退款的时间范围 30天
    let t = new Date(time).getTime();
    let now = new Date().getTime();
    if (now - t < 2592000000) {
      return true;
    } else {
      return false;
    }
  };
  const columns: ColumnsType = [
    {
      title: "操作",
      key: "1",
      align: "center",
      // dataIndex: 'id',
      width: Control.width(4),
      render: Control.render(
        (v) =>
          menuButton.indexOf("VIEW") != -1 && (
            <See
              click={() => {
                formParams.id = v.id;
                formParams.method = "Preview";
                formParams.orderNumber = v.orderNumber;
                formParams.state = v.state;
                formParams.orderType = v.orderType;
                createFunc.setTrue();
              }}
            />
          ),
          (v) =>(
            <Print
              click={async () => {
                await action.printFunction({ id:v.id },dispatch);
              }}
            />
          ),
          (v) => v.state == 6 && (
            <Print tips="打印退款小票" color="#f50"
              click={async () => {
                await action.printFunction({ id:v.id, type:'refund' },dispatch);
              }}
            />
          ),
          (v) =>
          menuButton.indexOf("UPDATE") != -1 && v.orderType == 2 && (v.state ==2 || v.state == 3 || v.state == 4 || v.salesReturnStatus == 5) &&(
            <Edit tips={v.salesReturnStatus == 5?'审核退款':'修改订单状态'}
              click={() => {
                formParams.id = v.id;
                formParams.method = v.salesReturnStatus == 5?'Audit':"Update";
                formParams.orderNumber = v.orderNumber;
                formParams.state = v.state;
                formParams.returnState = v.salesReturnStatus
                formParams.orderType = v.orderType;
                createFunc.setTrue();
              }}
            />
          ),
        (v) =>
          menuButton.indexOf("UPDATE") != -1 && !v.isExchangeGoods && 
          (v.state == 2 ||
            v.state == 3 ||
            v.state == 4 ||
            (v.state == 5 && calcTime(v.addDateTime))) &&  
          (v.salesReturnStatus == 2 || v.salesReturnStatus == 3 || v.salesReturnStatus == 6 || v.salesReturnStatus == null ) && (
            <Refund   click={() => {
              calcTime(v.addDateTime);
              refundFunc.setTrue();
              formParams.orderNumber = v.orderNumber;
            }}/>
            // <span
            //   style={{ color: "#ffc80b", margin: "0 5px", cursor: "pointer" }}
            //   onClick={() => {
            //     calcTime(v.addDateTime);
            //     refundFunc.setTrue();
            //     formParams.orderNumber = v.orderNumber;
            //   }}
            // >
            //   <img src={img_refund} />
            // </span>
          )
      ),
    },
    {
      title: "订单单号",
      dataIndex: "orderNumber",
      key: "2",
      align: "center",
      width: 180,
    },
    {
      title: "配送单号",
      dataIndex: "shippingNumber",
      key: "13",
      align: "center",
      width: 100,
    },
    {
      title: "是否打印",
      dataIndex: "isPrint",
      key: "14",
      align: "center",
      width: 100,
      render: v=><Tag color={v?'default':'error'}>{v?'已打印':'未打印'}</Tag>
    },
    {
      title: "顾客类型",
      dataIndex: "expenditureType",
      key: "3",
      align: "center",
      width: 60,
      render: (v) => <span>{1 === v ? "会员" : "散客"}</span>,
    },
    {
      title: "订单来源",
      dataIndex: "orderType",
      key: "4",
      align: "center",
      width: 80,
      render: (v) => <span>{v == 1 ? "门店订单" : v==2?"商城订单":'自助收银'}</span>,
    },
    {
      title: "订单类型",
      dataIndex: "isExchangeGoods",
      key: "4",
      align: "center",
      width: 80,
      render: (v) => <Tag color={v?'#f50':'cyan'}>{v?'换货订单':'普通订单'}</Tag>,
    },
    {
      title: "会员姓名",
      dataIndex: "memberName",
      key: "5",
      align: "center",
      width: 100,
    },
    {
      title: "手机号码",
      dataIndex: "phone",
      key: "5",
      align: "center",
      width: 100,
    },
    {
      title: "商品总数量",
      dataIndex: "sumNumber",
      key: "6",
      align: "center",
      width: 100,
    },
    {
      title: "应收总金额",
      dataIndex: "priceActuallyPayable",
      key: "7",
      align: "center",
      width: 100,
      render: (v) => <span>{v}</span>,
    },
    {
      title: "订单优惠金额",
      dataIndex: "totoMarketingPrice",
      key: "8",
      align: "center",
      width: 100,
      // render: ({ priceActuallyPayable, sellingPrice }) => <span>{sellingPrice - priceActuallyPayable}</span>
      render: (v) => <span>{v}</span>,
    },
    {
      title: "实收总金额",
      dataIndex: "sellingPrice",
      key: "9",
      align: "center",
      width: 100,
    },
    {
      title: "订单状态",
      // dataIndex: 'orderStatusString',
      key: "10",
      align: "center",
      width: 80,
      render: (v, r: any) => (
        <>
          {r.state == 1 && <Tag color="magenta">{r.orderStatusString}</Tag>}
          {r.state == 2 && <Tag color="volcano">{r.orderStatusString}</Tag>}
          {r.state == 3 && <Tag color="geekblue">{r.orderStatusString}</Tag>}
          {r.state == 4 && <Tag color="gold">{r.orderStatusString}</Tag>}
          {r.state == 5 && <Tag color="success">{r.orderStatusString}</Tag>}
          {r.state == 6 && <Tag color="blue">{r.orderStatusString}</Tag>}
          {r.state == 7 && <Tag color="error">{r.orderStatusString}</Tag>}
        </>
      ),
    },
    {
      title: "退单状态",
      dataIndex: "salesReturnStatusString",
      key: "11",
      align: "center",
      width: 80,
      // render: (v) => v && <Tag color="cyan">{v}</Tag>,
    },
    {
      title: "退款金额",
      dataIndex: "refundMoney",
      key: "12",
      align: "center",
      width: 100,
    },
    {
      title: "制单时间",
      dataIndex: "addDateTime",
      key: "13",
      align: "center",
      width: 130,
    },
  ];

  const onSearch = async (pageIndex) => {
    await action.loadPage(
      {
        pageIndex: pageIndex,
        pageSize: defualtPageSize(),
        dispatch: tableDispatch,
        search: state.search,
      },
      tableDispatch
    );
  };
  const changeStatus = (status) => {
    if (!tableState.selectList.length) {
      message.warning("请选择修改项");
    } else {
      let arr = tableState.selectList.filter((order) => {
        return order.orderType != 2;
      });
      if (arr.length > 0) {
        message.warn("只能修改商城订单状态");
        action.clearSelectedRowKeys({ dispatch: tableDispatch });
        return false;
      } else {
        orderStateFunc.setTrue();
      }
    }
  };
  const {run } = useDebounceFn(fn=>{
    fn && fn()
  },{
    wait:1500
  })
  return (
    <>
      <Spin
        tip={state.msg}
        spinning={state.isLoading}
        size="large"
        wrapperClassName="isloading"
      >
        <Search>
          <Input
            allowClear
            placeholder="请输入会员卡号、姓名、手机号码"
            onChange={(e) =>
              action.setCondition("Keywords", e.target.value, dispatch)
            }
          />
          <input type="button" value="快速查找" onClick={() => onSearch(1)} />

          <TipsGroup>
            <input
              type="button"
              value="高级查找"
              onClick={() => searchFunc.toggle()}
            />
            <Tips width="536px" display={searchState}>
              <FileSearch
                onSearch={(...arg: [any]) => {
                  onSearch(...arg);
                  searchFunc.setFalse();
                }}
              />
            </Tips>
            <Mask visible={searchState} onClick={() => searchFunc.setFalse()} />
          </TipsGroup>
        </Search>

        <ButtonPanel>
          {menuButton.indexOf("EXPORT") != -1 && (
            <Button
              onClick={async () => {
               run( await action.exportList({ search: state.search }))
              }}
            >
              导出
            </Button>
          )}
          {menuButton.indexOf("UPDATE") != -1 ? (
            <>
              <Button
                onClick={() => {
                  changeStatus(2);
                  formParams.toState = 2;
                }}
              >
                发货
              </Button>
              <Button
                onClick={() => {
                  changeStatus(5);
                  formParams.toState = 5;
                }}
              >
                完成
              </Button>
            </>
          ) : null}

          {/* {orderState&& <ChannelType load={()=>onSearch(tableState.current)}  close={()=>{orderStateFunc.setFalse()}}/>}           */}
        </ButtonPanel>

        <DataTable
          columns={columns}
          action={fileAction}
          rowSelection={{ type: "checkbox" }}
          onLoad={async () => {
            await onSearch(1);
            Progress.done();
          }}
          onChange={(pageIndex) => {
            onSearch(pageIndex);
          }}
          loading={tableState.loading}
          scroll={{ scrollToFirstRowOnChange: true, autoY: true, x: 1060 }}
          selectColumn={true}
        />
        {createState && (
          <FormStoreProvider>
            <Form
              close={(hasUpdate) => {
                if (hasUpdate) {
                  onSearch(tableState.current);
                }

                createFunc.setFalse();
              }}
              action={formAction}
              id={formParams.id}
              method={formParams.method}
              orderNumber={formParams.orderNumber}
              status={formParams.state}
              orderType={formParams.orderType}
              returnState={formParams.returnState}
            />
          </FormStoreProvider>
        )}
        <ConfirmModal
          visible={orderState}
          title="温馨提示"
          subTitle="确认修改订单状态？"
          message="修改后不可更改，确认修改？"
          close={async (r) => {
            if (r) {
              action.changeState({
                orderList: tableState.selectList,
                State: formParams.toState,
              });
              action.clearSelectedRowKeys({ dispatch: tableDispatch });
              onSearch(tableState.current);
            }
            orderStateFunc.setFalse();
          }}
        />
        <ConfirmModal
          visible={refund}
          title="温馨提示"
          subTitle="确认退款？"
          message="退款后不可更改，确认退款？"
          close={async (r) => {
            refundFunc.setFalse();

            if (r) {
              await action.returnSale({
                OrderNumber: formParams.orderNumber,
                dispatch,
              });
              onSearch(tableState.current);
            }
          }}
        />
      </Spin>
    </>
  );
}
