import { useBoolean, useRequest } from "ahooks";
import { Input, Modal, Select, Tree, Button, TreeSelect, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect, useState } from "react";
import { Search } from "../search";
import { DataTable } from "../table";
import {
  DataTableStore,
  DataTableStoreProvider,
} from "../table/store/data_table_store";
import { ChooseGoodsStore, ChooseStoreProvider } from "./store/choose_store";
import styles from "./style/index.module.scss";
import { Select as SelectAntd } from "antd";
import { chooseGoodsAction } from "./action/choose_action";
import { defualtPageSize } from "../../logic/common_logic";


function Goods(params: IChooseGoods) {
  let {
    visibleModal,
    close,
    filter,
    type,
    shopID,
    savekeysNo,
    clearSelectedList,
  } = params;
  const { Option } = Select,
    action = chooseGoodsAction;
  const { state, dispatch } = useContext(ChooseGoodsStore);
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);

  const handleOk = () => {
    
    close && close(true, tableState.selectList);
  };

  const handleCancel = () => {
    close && close(false, null);
  };

  const columns: ColumnsType = [
    {
      title: "商品名称",
      dataIndex: "goodsName",
      key: "1",
      align: "center",
    },
    {
      title: "商品编码",
      dataIndex: "goodsNo",
      key: "2",
      align: "center",
    },
    {
      title: "商品PLU码",
      dataIndex: "pluCode",
      key: "3",
      align: "center",
    },
    {
      title: "单位",
      dataIndex: "unitName",
      key: "4",
      align: "center",
      render: (v) => <Tag>{v}</Tag>,
    },
    // {
    //   title: "状态",
    //   dataIndex: "status",
    //   key: "6",
    //   align: "center",
    //   render: (v) => <span>{v == 1 ? "上架" : "下架"}</span>,
    // },
  ];

  const onSearch = async (pageIndex, classId?) => {
    await action.loadPage({
      pageIndex: pageIndex,
      pageSize: defualtPageSize(),
      dispatch: tableDispatch,
      state,
      search: { ...state.search, classesId: classId },
    });
  };

  useRequest(() => action.init({ dispatch }));

  useEffect(() => {
    if (clearSelectedList) {
      action.clearSelectedRowKeys({ dispatch });
    }

    return () => {};
  },[]);
  return (
    <Modal
      title="选择商品"
      visible={visibleModal}
      onOk={handleOk}
      onCancel={handleCancel}
      width={1000}
      cancelText="取消"
      okText="确定"
      footer={
        <>
          <Button
            type="primary"
            style={{
              backgroundColor: "#f7ba00",
              borderColor: "#f7ba00",
              color: "white",
            }}
            onClick={handleCancel}
          >
            取消
          </Button>
          <Button type="primary" onClick={handleOk}>
            确认
          </Button>
        </>
      }
    >
       
      <div className={styles.root}>
      <Search>
        
        <Input
          maxLength={20}
          allowClear
          placeholder="请输入商品名称、编码"
          onChange={(e) =>
            action.setCondition("Keywords", e.target.value, dispatch)
          }
        />
        <input type="button" value="快速查找" onClick={() => onSearch(1)} />
      </Search>
        <div style={{ marginTop: 10, height: 200 }}>
          <div className={styles.tree}>
            <TreeSelect
              style={{ width: "12rem", marginLeft: 10 }}
              showCheckedStrategy={TreeSelect.SHOW_PARENT}
              dropdownMatchSelectWidth={false}
              defaultValue={state.search.classesId}
              value={state.search.classesId}
              treeCheckable={true}
              treeData={state.areaList}
              onChange={(data) => {
                action.setCondition("Classes", data, dispatch);
                onSearch(1, data);
              }}
              placeholder="选择商品分类"
            />
          </div>
          <div className={styles.list}>
            <DataTable
              columns={columns}
              action={action}
              // rowKey={(record) => record.id}
              rowSelection={{
                type: "radio",
              }}
              onLoad={() => onSearch(1)}
              onChange={(pageIndex) => {
                onSearch(pageIndex);
              }}
              selectColumn={true}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

type Filter = {
  zeroInventory?: boolean;
  specifications?: boolean;
  source?: string;
  isMarketing?: boolean;
  isStore?: boolean;
  checkInvcount?: boolean;
  type?: boolean;
};
interface IChooseGoods {
  visibleModal: boolean;
  close?: (status, r) => void;
  filter?: Filter;
  type?;
  shopID?;
  clearSelectedList?: boolean;
  savekeysNo?;
}
export function ChooseGoodsPlu(params: IChooseGoods) {
  return (
    <ChooseStoreProvider>
      <DataTableStoreProvider>
        <Goods {...params} />
      </DataTableStoreProvider>
    </ChooseStoreProvider>
  );
}
