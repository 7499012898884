// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../config";
import { effeative } from "./common_logic";
/**图形报表 */
export class GraphicReportLogic{
    /**会员消费排行漏斗
     * @param required startTime 开始时间
     * @param required endTime 结束时间
     */
    async MemberExpendTOP(params) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("saleReport.MemberExpendTOP"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
   
    /**
     * 会员储值排行漏斗图TOP10
     * @param required  startTime 开始时间
     * @param required endTime 结束时间
     */ 
    async MemberStoredTOP(params){
        const r = effeative(await axios({
            method: 'POST',
            url: Tools.getApiAddress('saleReport.MemberStoredTOP'),
            data: params,
            headers:{
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            }
        }))
        return r
    }

    /**
     * 商品销量排行漏斗图TOP10
     * @param required startTime 开始时间
     * @param required endTime 结束时间
     */
    async GetGoodsTOP(params){
        const r = effeative(await axios({
            method: 'POST',
            url: Tools.getApiAddress('saleReport.GetGoodsTOP'),
            data: params,
            headers:{
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            }
        }))
        return r
    }
       /**
         * 新用户走势图
         * @param type 查询类型 0 月 1 日 （2 周 未使用
         * @param required  startTime 开始时间
         * @param required  endTime 结束时间
         */
        async GetNewMember(params){
            const r = effeative(await axios({
                method: 'POST',
                url: Tools.getApiAddress('saleReport.GetNewMember'),
                data: params,
                headers:{
                    SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
                }
            }))
            return r
        }

          /**
         * 会员收支折线
         * @param type 查询类型 0 月 1 日 （2 周 未使用
         * @param required  startTime 开始时间
         * @param required  endTime 结束时间
         */
           async GetMemberStatic(params){
            const r = effeative(await axios({
                method: 'POST',
                url: Tools.getApiAddress('saleReport.GetMemberStatic'),
                data: params,
                headers:{
                    SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
                }
            }))
            return r
        }
          /**
             * 退款折线图
             * @param type 查询类型 0 月 1 日 （2 周 未使用
             * @param required  startTime 开始时间
             * @param required  endTime 结束时间
             */
        async GetRefundStatic(params){
            const r = effeative(await axios({
                method: 'POST',
                url: Tools.getApiAddress('saleReport.GetRefundStatic'),
                data: params,
                headers:{
                    SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
                }
            }))
            return r
        }
          /**
             * 销售额折线图
             * @param type 查询类型 0 月 1 日 （2 周 未使用
             * @param required  startTime 开始时间
             * @param required  endTime 结束时间
             */
           async GetSaleStatic(params){
            const r = effeative(await axios({
                method: 'POST',
                url: Tools.getApiAddress('saleReport.GetSaleStatic'),
                data: params,
                headers:{
                    SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
                }
            }))
            return r
        }
           /**
             * 商品分类销售额饼状图
             * @param type 查询类型 0 月 1 日 （2 周 未使用
             * @param required  startTime 开始时间
             * @param required  endTime 结束时间
             */
            async GetClassSaleStatic(params){
                const r = effeative(await axios({
                    method: 'POST',
                    url: Tools.getApiAddress('saleReport.GetClassSaleStatic'),
                    data: params,
                    headers:{
                        SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
                    }
                }))
                return r
            }
             /**
             * 订单类型饼状图(门店、商城、自助)
             * @param type 查询类型 0 月 1 日 （2 周 未使用
             * @param required  startTime 开始时间
             * @param required  endTime 结束时间
             */
              async GetOrderTypeStatic(params){
                const r = effeative(await axios({
                    method: 'POST',
                    url: Tools.getApiAddress('saleReport.GetOrderTypeStatic'),
                    data: params,
                    headers:{
                        SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
                    }
                }))
                return r
            }
            /**
             * 支付方式销售额饼状图
             * @param type 查询类型 0 月 1 日 （2 周 未使用
             * @param required  startTime 开始时间
             * @param required  endTime 结束时间
             */
             async GetPayTypeSaleStaic(params){
                const r = effeative(await axios({
                    method: 'POST',
                    url: Tools.getApiAddress('saleReport.GetPayTypeSaleStaic'),
                    data: params,
                    headers:{
                        SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
                    }
                }))
                return r
            }
}