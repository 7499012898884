import React, { useContext, useEffect } from "react";
import { Button } from "../../../../component/button";
import { Layer } from "../../../../component/layer/layer";
import { Form as FormAntd } from "antd";
import { formAction, FormAction } from "../../action/userRole/form_action";
import { FormStore } from "../../store/userRole/form_store";
import Table, { ColumnsType } from "antd/lib/table";
import no_check from "../../../../assets/images/nocheck.png"
import img_check from "../../../../assets/images/check.png"
import { message } from "antd";
import { useRequest } from "ahooks";


import { FileStore } from "../../store/userRole/file_store";
import { fileAction } from "../../action/userRole/file_action";
import { DataTableStore } from "../../../../component/table/store/data_table_store";
import { defualtPageSize } from "../../../../logic/common_logic";
import { autoRem } from "../../../../global.style";
interface IForm {
    children?: any;
    action: FormAction;
    close: (hasUpdate?: boolean) => void;
    editId?: string;
    seeId?: string;
    roleId?: string;
    isdefault?:boolean;
}
export function DataTable({ dataList, action, tableState }) {
    const columns: ColumnsType = [
        {
            title: '用户账号',
            dataIndex: 'userID',
            key: '2',
            align: "center"
        },
        {
            title: '用户名称',
            dataIndex: 'userName',
            key: '2',
            align: "center"
        },
        {
            title: '性别',
            dataIndex: 'sex',
            key: '2',
            align: "center"
        },
        {
            title: '邮箱',
            dataIndex: 'email',
            key: '2',
            align: "center"
        },
        {
            title: '是否启用',
            key: '5',
            align: "center",
            render: (v) => <img src={v.enabled ? img_check : no_check} width={20} height={20}></img>
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: '2',
            align: "center"
        },
    ];

    return (
        <Table dataSource={dataList} columns={columns} rowSelection={{
            type: "checkbox", onChange: (selectedRowKeys, selectedRows) => {
                tableState.selectList = selectedRows
            },
        }} />
    )
}
export function Form({ close, action, editId, seeId, roleId, isdefault }: IForm) {

    const { state: tableState, dispatch: tableDispatch } = useContext(DataTableStore);

    const { state: formState, dispatch } = useContext(FormStore);

    const { state: fileState, dispatch: fileDispatch } = useContext(FileStore);
    useRequest(() => formAction.initBasic({ state, dispatch, roleId }));
    const [form] = FormAntd.useForm();

    const { state, dispatch: formDispatch } = useContext(FormStore);
    useEffect(() => {
        formAction.init({ state: state, dispatch: formDispatch, editId: editId, seeId, form });

        return () => {

        }
    }, []);

    function title() {
        return "添加用户";
    }
    const onSearch = async (pageIndex) => {
        await fileAction.loadPage({ pageIndex: pageIndex, pageSize: defualtPageSize(), dispatch: tableDispatch, search: fileState.search })
    }
    return (
        <FormAntd form={form}>
            <Layer layerWith={3} >
                <Layer.Title onClick={() => close()}>{title()}</Layer.Title>
                <Layer.Body>
                    <div style={{ margin: "" + autoRem(1.9375) + " " + autoRem(1.25) + " 0 0" }}>
                        <DataTable dataList={state.userList} tableState={tableState} action={action} />
                    </div>
                </Layer.Body>
                {
                    title() != "查看等级" && (
                        <>
                            <Layer.Foot>
                                <Button type="primary" color="Red" style={{ marginRight: "1.25rem" }} onClick={async () => {
                                    const r = await form.validateFields();
                                    let status = false;
                                    if (!tableState.selectList.length) {
                                        message.warning("请选择需要添加的用户")
                                    } else {
                                        let arr = tableState.selectList.filter(item=>item.isdefault ==true)
                                        if(!isdefault&&arr.length>0){
                                            message.error('默认用户不能添加')
                                        }else{
                                            status = await fileAction.addsSubmit({ dispatch, roleId: roleId, selectLists: tableState.selectList })
                                            status && (close && close(true))
                                        }
                                      
                                    }

                                }}>
                                    保存
                                </Button>
                                {/* <Button type="primary" color="Yellow">
                                    重置
                                </Button> */}
                            </Layer.Foot>
                        </>
                    )
                }

                {
                    title() == "查看等级" && (
                        <>
                            <Layer.Foot>
                            </Layer.Foot>
                        </>
                    )
                }
            </Layer>
        </FormAntd>
    )
}