import { Layout } from "../../component/layout";
import { TabsStyle, TabList, Tab, TabPanel } from "../../component/react-tabs";
import { MenuLogic } from "../../logic/menu_logic";
import { ESSDetail } from "./view/ESSDetail";
import { GoodsSalesRanking } from "./view/goodsSalesRanking";
import { SalesRW } from "./view/salesRW";
import { StoresProfitsStats } from "./view/storesProfitsStats";
import { StoresSalesStats } from "./view/storesSalesStats";
import { useState, useEffect } from "react";
import {ClassesSalesStats} from './view/classesSalesStats'
import {MemberFlow} from './view/memberFlow'
import {GoodsRefundStats} from './view/goodsRefundStats'
import {Analysis} from '../analysis/index'
function getTabPanel(type,viewPower) {
  let content: JSX.Element;
  switch (type) {
    case "sp":
      content = <StoresProfitsStats viewPower={viewPower}/>;
      break;
    case "sss":
      content = <StoresSalesStats viewPower={viewPower}/>;
      break;
    case "sw":
      content = <SalesRW viewPower={viewPower}/>;
      break;
    case "gsr":
      content = <GoodsSalesRanking viewPower={viewPower}/>;
      break;
    case "essd":
      content = <ESSDetail viewPower={viewPower}/>;
      break;
    case 'ClassesSalesReport':
      content = <ClassesSalesStats viewPower={viewPower}/>
      break;
    case 'MemberFlow':
      content = <MemberFlow viewPower={viewPower}/>
      break;
    case 'GoodsRefund':
      content = <GoodsRefundStats viewPower={viewPower}/>
      break;
    case 'Analysis':
      content = < Analysis/>
      break
    
  }
  return <TabPanel>{content}</TabPanel>;
}

/**
 * 报表
 */
function Report() {
  const [menuLists, setData] = useState([]);
  const getMenuList = async (url: string) => {
    let r = await new MenuLogic().menuData();
    var arr = [];
    if (r && r.length > 0) {
      r = r.filter(function (v) {
        return !v.hidden;
      });
      for (let i = 0; i < r.length; i++) {
        if (r[i].path == url && r[i].children) {
          arr = r[i].children.filter(function (v) {
            return !v.hidden;
          });
        }
      }
    }
    //console.log(arr);
    setData(arr);
  };
  useEffect(() => {
    getMenuList("/report");
    return () => {};
  }, []);

  const getTab = (list: any[], style) => {
    if (!list || list.length == 0) {
      return;
    }
    return (
      <TabsStyle style={style}>
        <TabList>
          {list.map((item, index) => {
            return (
              <Tab key={index}>
                <span>{item.meta.title}</span>
                <hr />
              </Tab>
            );
          })}
          <hr />
        </TabList>
        {list.map((item) => {
          let tab = getTab(item.children, "BottomLine");
          if (tab) {
            return <TabPanel>{tab}</TabPanel>;
          } else {
            return getTabPanel(item.component,item.viewPower);
          }
        })}
      </TabsStyle>
    );
  };

  return (
    <>
      {/* <Layout fullWidth={true}> */}
      {getTab(menuLists, "Full")}
      {/* </Layout> */}
    </>
  );
}

export default Report;
