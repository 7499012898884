/** 标签键 */
export class TabKeys {
    key: string;
    title: string;
    render?: (index: number, value: any) => string;
    child?: TabKeys[];
    [index: string]: any;
    styles?: any

    constructor(key: string, title: string, child?: TabKeys[]) {
        this.key = key;
        this.title = title;
        this.child = child;
    }

    /** 获取深度 */
    static getTabKeysDeep(tabKeys: TabKeys[]): number {
        let deep: number = 1;
        for (let tabKey of tabKeys) {
            deep = Math.max(deep, this.getTabKeyDeep(tabKey));
        }
        return deep;
    }
    /** 获取深度 */
    static getTabKeyDeep(tabKey: TabKeys, _deep: number = 1): number {
        let deep: number = 0;
        if (tabKey.child && tabKey.child.length > 0) {
            tabKey.child.forEach((item) => {
                deep = Math.max(deep, this.getTabKeyDeep(item, _deep + 1));
            });
        } else {
            deep = _deep;
        }
        return deep;
    }

    /** 获取某一深度的全部tabKey */
    static getDeepTabKey(tabKeys: TabKeys[], _deep: number) {
        let _tabKeys: TabKeys[] = [];
        if (_deep > 0) {
            tabKeys.forEach((item) => {
                if (item.child && item.child.length > 0) {
                    _tabKeys.push(...item.child);
                }
            });
            _deep--;
            _tabKeys = this.getDeepTabKey(_tabKeys, _deep);
        } else {
            _tabKeys = tabKeys
        }
        //
        return _tabKeys;
    }

    /** 获取子键数量 */
    static getChildNumber(tabKey: TabKeys): number {
        let _number: number = 0;
        if (tabKey.child && tabKey.child.length > 0) {
            _number = tabKey.child.length;
            //递归遍历后续子节点
            tabKey.child.forEach((item) => {
                _number += this.getChildNumber(item);
            });
        }
        return _number;
    }

    /** 通过tabkey列表获取末尾节点列表 */
    static byTabKeysGetEndChilds(tabKeys: TabKeys[]): TabKeys[] {
        return this.getEndChilds(new TabKeys('', '', tabKeys));
    }

    /** 获取树末尾子 */
    static getEndChilds(tabKey: TabKeys): TabKeys[] {
        let _tabKey: TabKeys[] = [];
        if (tabKey.child && tabKey.child.length > 0) {
            tabKey.child.forEach((item) => {
                _tabKey.push(...this.getEndChilds(item));
            });
        }
        else {
            _tabKey.push(tabKey);
        }
        return _tabKey;
    }
}