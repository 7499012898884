import React, { useEffect, useState } from "react";
import { Layout } from "../../component/layout";
import { TabsStyle, TabList, Tab, TabPanel } from "../../component/react-tabs";
import { DataTableStoreProvider } from "../../component/table/store/data_table_store";
import { MenuLogic } from "../../logic/menu_logic";
import { WithDraw } from "./view/withdraw/file";
import { InventoryStoreProvider } from "./store/withdraw/inventory_store";
import { DistributionRecords } from "./view/distributionRecords/list";
import { ListStoreProvider } from "./store/distributionRecords/list_store";
import { FormStoreDistributionProvider } from "./store/distribution/file_store";
import { Distribution } from "./view/distribution/distribution";
import { ShareStoreProvider } from "./store/share/list_store";
import { ShareList } from "./view/shareList/list";
export function Distribute() {
  const [menuLists, setData] = useState([]);
  useEffect(() => {
    (async function () {
      let r = await new MenuLogic().menuData();
      var arr = [];
      if (r && r.length > 0) {
        r = r.filter(function (v) {
          return !v.hidden;
        });
        for (let i = 0; i < r.length; i++) {
          if (r[i].path == "/distribution" && r[i].children) {
            arr = r[i].children.filter(function (v) {
              return !v.hidden;
            });
          }
        }
      }
      setData(arr);
    })();

    return () => {};
  }, []);
  return (
    // <Layout fullWidth={true}>
    <TabsStyle style="Full">
      <TabList>
        {menuLists.map((v, i) => (
          <Tab key={i}>{v.name}</Tab>
        ))}
      </TabList>
      {menuLists.map((v) =>
        v.component == "withdraw" ? (
          <TabPanel key={v.component}>
            <InventoryStoreProvider>
              <DataTableStoreProvider>
                <WithDraw viewPower={v.viewPower} />
              </DataTableStoreProvider>
            </InventoryStoreProvider>
          </TabPanel>
        ) : v.component == "distribution" ? (
          //分销管理
          <TabPanel key={v.component}>
            <FormStoreDistributionProvider>
              <DataTableStoreProvider>
                <Distribution viewPower={v.viewPower} />
              </DataTableStoreProvider>
            </FormStoreDistributionProvider>
          </TabPanel>
        ) : v.component == "DistributionRecords" ? (
          <TabPanel key={v.component}>
            <ListStoreProvider>
              <DataTableStoreProvider>
                <DistributionRecords viewPower={v.viewPower} />
              </DataTableStoreProvider>
            </ListStoreProvider>
          </TabPanel>
        ) : v.component == "shareList" ? (
          <TabPanel key={v.component}>
            <ShareStoreProvider>
              <DataTableStoreProvider>
                <ShareList viewPower={v.viewPower} />
              </DataTableStoreProvider>
            </ShareStoreProvider>
          </TabPanel>
        ) : (
          <TabPanel>
            <div></div>
          </TabPanel>
        )
      )}
    </TabsStyle>
    // </Layout>
  );
}
