import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
/**
 * 可拖拽拖拽列表
 * @param param
 */
export function DrageList<List = any>(
    { children, list, onDragEnd }: {
        /** 列表数组 */
        list: List[],
        /** 从列表中获取内容的方法 */
        children: (item: List, index: number) => any,
        /** 拖动结束，返回一个排好序的新列表，和拖拽开始位置结束后的位置 */
        onDragEnd: (_list: List[], startIndex: number, endIndex: number) => void
    }) {
    return (
        <DragDropContext
            onDragEnd={({ source, destination }) => {
                // //console.log('拖拽完成', list, source, destination);
                //拖拽起点和终点，只要其中一个不存在就表示没有拖拽
                if (!source || !destination) { return; }
                let _list = [...list];
                let startIndex: number = source.index;
                let endIndex: number = destination.index;
                let _ = _list.splice(startIndex, 1)[0];
                _list.splice(endIndex, 0, _);
                //交换前后数据
                onDragEnd(_list, startIndex, endIndex);
            }}
        >
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        {
                            list&&list.length>0&&list.map((item, index) => {
                                return (<Draggable key={index} draggableId={`draggable-${index}`} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            {children(item, index)}
                                        </div>
                                    )}
                                </Draggable>);
                            })
                        }
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}