import React from "react";
import styles from "./index.module.scss";

interface ISearchPanel {
    children?: any;
}
export function Search({ children }: ISearchPanel) {
    return (
        <div className={styles.root}>
            {children}
        </div>
    )
}