import React, { useContext, useEffect } from "react";
import { Button } from "../../../../component/button";
import { Layer } from "../../../../component/layer/layer";
import { Form as FormAntd } from "antd";
import { formAction, FormAction } from "../../../order/action/form_action";
import { FormStore } from "../../../order/store/form_store";
import { FormBasic } from "./list_form_basic";
import { fileAction } from "../../../order/action/file_action";

interface IForm {
  children?: any;
  // action: FormAction;
  close: (hasUpdate?: boolean) => void;
  id?: string;
  method?: string;
  orderNumber?: string;
  status?: number;
  orderType?: number;
}
export function Form({
  close,
  // action,
  id,
  method,
  orderNumber,
  status,
  orderType,
}: IForm) {
  const [form] = FormAntd.useForm();

  const { state, dispatch: formDispatch } = useContext(FormStore);

  useEffect(() => {
    formAction.init({
      state: state,
      dispatch: formDispatch,
      id,
      form,
      orderNumber,
    });
    return () => {};
  }, [orderNumber]);

  const title = () => {
    return "查看订单";
  };

  return (
    <FormAntd form={form}>
      <Layer width={'100%'} onClick={() =>{}}>
        <Layer.Title onClick={() => close()}>{title()}</Layer.Title>
        <Layer.Body>
          <FormBasic disabled={title() == "查看订单"} />
        </Layer.Body>

        <Layer.Foot
          borderStyle={title() == "查看订单" ? { border: "none" } : {}}
        >
          {orderType == 2 ? (
            <>
              {status == 3 && (
                <Button
                  type="primary"
                  color="Red"
                  style={{ marginRight: "1.25rem" }}
                  onClick={async () => {
                    const r = await form.validateFields();
                    let flag = false;
                    if (id) {
                      flag = await fileAction.changeStateSingle({
                        orderNumber: orderNumber,
                        State: 2,
                      });
                    }

                    flag && close && close(true);
                  }}
                >
                  发货
                </Button>
              )}
              {(status == 2 || status == 4) && (
                <Button
                  type="primary"
                  color="Red"
                  onClick={async () => {
                    const r = await form.validateFields();
                    let flag = false;
                    if (id) {
                      flag = await fileAction.changeStateSingle({
                        orderNumber: orderNumber,
                        State: 5,
                      });
                      flag && close && close(true);
                    }
                  }}
                >
                  完成
                </Button>
              )}
            </>
          ) : null}
        </Layer.Foot>
      </Layer>
    </FormAntd>
  );
}
