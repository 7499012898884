import moment from "moment";
import { ReportLayer } from "../com/reportLayer";
import { useState, useEffect } from "react";
import { SalesRWTable } from "./tableShow/salesRWTable";
import { Buttons } from "./com/buttons";
import { DateSelect } from "./select/dateSelect";
import { ApiCon } from "../com/apiCon";
import { SearchLayer } from "./search/searchLayer";
import { Form, Input } from "antd";
import { Select, message } from "antd";
import { SelectStore } from "./store/SelectStore";
import { ISelectTime, ISpeedQuery } from "../com/ISelectType";
import { QueryType } from "../com/QueryType";
import { useBoolean, useDebounceFn } from "ahooks";
// 引入订单表单
import { FormStoreProvider } from "../../order/store/form_store";
import { Form as OrderForm} from "../../order/view/form";
import { formAction } from "../../order/action/form_action";
const formParams = {
  method: "Create",
  id: "",
  orderNumber: "",
  state: 0,
  orderType: 2,
  toState: 2,
  returnState:null
};
const { Option } = Select;

/**
 * 销售流水
 */
export function SalesRW(viewPower) {
  // 是否显示订单详情
const [createState, createFunc] = useBoolean(false)

  const [data, setData] = useState(null);
  const [ifInit, setIfInit] = useState(false);
  const [ifLastPage, setIfLastPage] = useState(false);
  const [queryType, setQueryType] = useState<QueryType>("n");
  const [queryState, setQueryState] = useState<
    {
      orderType?: number;
      state?: number;
      payType?: number;
      phone: string;
    } & ISelectTime &
      ISpeedQuery
  >({
    seacherKey: "",
    beginTime: moment(Date.now()).startOf("month").format("Y-M-D"),
    endTime: moment(Date.now()).format("Y-M-D"),
    phone: "",
  });
  const [paging, setPaging] = useState<{
    pageSize: number;
    current: number;
    total: number;
  }>({
    pageSize: 20,
    current: 1,
    total: 0,
  });

  useEffect(() => {
    if (!ifInit) {
      setIfInit(true);
      queryClick();
    }
  });

  const queryClick = (_queryType: QueryType = queryType) => {
    setQueryType(_queryType);
    let op: any = {
      ...queryState,
      ...{
        pageIndex: paging.current,
        pageSize: paging.pageSize,
      },
    };
    //根据不同的查询模式修剪参数
    switch (_queryType) {
      case "c":
        op.seacherKey = "";
        break;
      case "s":
        break;
      case "n":
        op.seacherKey = "";
        break;
    }
    //请求数据
    ApiCon.SalesFlowReport(op)
      .then((data) => {
        //console.log('销售流水数据', data);
        setPaging({
          ...paging,
          total: data.totalCount,
        });
        setIfLastPage(!data.hasNextPage);
        if (data.dataSource) {
          console.log(data.dataSource)
          setData(data.dataSource);
        } else {
          setData({
            list: [],
          });
        }
      })
      .catch((data) => {
        setData({
          list: [],
        });
        //提示
        message.error(data.message);
      });
  };

  const setQuery = (query) => {
    setQueryState({
      ...queryState,
      ...query,
    });
  };
  const { run } = useDebounceFn(
    (fn) => {
      fn && fn();
    },
    {
      wait: 1500,
    }
  );
const handleClickTd = (data) => {
  formParams.id = data.expenditureID;
  formParams.method = "Preview";
  formParams.orderNumber = data.orderNumber;
  formParams.state = data.state;
  formParams.orderType = data.orderType;
  createFunc.setTrue();
}
  return (
    <SelectStore.Provider
      value={{
        state: queryState,
        dispatch: setQuery,
      }}
    >
      <ReportLayer
        queryItem={
          <SearchLayer
            queryClick={queryClick}
            speedQureyPlaceholder="请输入商品分类，商品名称，商品编码"
          >
            <div className="item_">
              <Form.Item label="订单类型">
                <Select
                  style={{ width: 175 }}
                  allowClear
                  value={queryState.orderType}
                  onChange={(value) => {
                    setQuery({ orderType: value });
                  }}
                >
                  <Option value={1}>门店订单</Option>
                  <Option value={2}>商城订单</Option>
                  <Option value={2}>自助收银订单</Option>
                </Select>
              </Form.Item>
            </div>
            <div
              className="item_"
              style={{
                paddingLeft: 14,
              }}
            >
              <Form.Item label="订单状态">
                <Select
                  style={{ width: 175 }}
                  allowClear
                  value={queryState.state}
                  onChange={(value) => {
                    setQuery({ state: value });
                  }}
                >
                  <Option value={1}>待付款</Option>
                  <Option value={2}>待收货</Option>
                  <Option value={3}>待发货</Option>
                  <Option value={4}>待提货</Option>
                  <Option value={5}>已完成</Option>
                  <Option value={6}>退单</Option>
                  <Option value={7}>作废</Option>
                </Select>
              </Form.Item>
            </div>
            <div className="item_">
              <Form.Item label="支付方式">
                <Select
                  style={{ width: 175 }}
                  allowClear
                  value={queryState.payType}
                  onChange={(value) => {
                    setQuery({ payType: value });
                  }}
                >
                  <Option value={1}>支付宝</Option>
                  <Option value={2}>微信</Option>
                  <Option value={3}>银联</Option>
                  <Option value={4}>现金</Option>
                  <Option value={5}>钱包余额</Option>
                </Select>
              </Form.Item>
            </div>
            <div className="item_" style={{
                paddingLeft: 14,
              }}>
              <Form.Item label="会员手机">
                <Input
                  style={{ width: 175 }}
                  allowClear
                  value={queryState.phone}
                  onChange={(e) => {
                    setQuery({ phone: e.target.value });
                  }}
                />
              </Form.Item>
            </div>
            <DateSelect />
          </SearchLayer>
        }
        button={
           (
            <Buttons
              exportExcel={() => {
                run(async () => {
                  let today = moment(Date.now()).format("yyyy-MM-DD")
                  const r = await ApiCon.exportExcel('export_table',`销售流水数据${today}.xlsx`);
                });
              }}
            />
          )
        }
        paging={{
          ...paging,
          onChange: (page, pageSize) => {
            paging.current = page;
            paging.pageSize = pageSize;
            setPaging({
              ...paging,
            });
            queryClick();
          },
        }}
      >
        <SalesRWTable data={data} ifLastPage={ifLastPage} handleClickTd={(val)=>handleClickTd(val)}/>
      </ReportLayer>     
      {createState && (<div>
          <FormStoreProvider>
            <OrderForm
              close={(hasUpdate) => {
                // if (hasUpdate) {
                //   onSearch(tableState.current);
                // }

                createFunc.setFalse();
              }}
              action={formAction}
              id={formParams.id}
              method={formParams.method}
              orderNumber={formParams.orderNumber}
              status={formParams.state}
              orderType={formParams.orderType}
              returnState={formParams.returnState}
            />
          </FormStoreProvider>
          </div>
        )}
    </SelectStore.Provider>
  );
}
