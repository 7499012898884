import moment from "moment";
import { Form, DatePicker, Space } from 'antd';
import { useContext, useState } from 'react';
import { SelectStore } from "../store/SelectStore";
import { ISelectTime } from "../../com/ISelectType";
const { RangePicker } = DatePicker;
/**
 * 日期选择器
 */
export function DateSelect({ ifShowTab = true }: {
    ifShowTab?: boolean
}) {
    const { state, dispatch } = useContext<{
        state: ISelectTime,
        dispatch: (state: ISelectTime) => void;
    }>(SelectStore);
    const [dates, setDates] = useState([]);

    const disabledDate = current => {
        if (!dates || dates.length === 0) {
            return false;
        }
        const tooLate = dates[0] && current.diff(dates[0], 'days') > 180;
        const tooEarly = dates[1] && dates[1].diff(current, 'days') > 180;
        return tooEarly || tooLate;
    };

    return <div className="item">
        <Form.Item label={ifShowTab ? '时间区段' : ''}>
            <Space direction="vertical" size={12}>
                <RangePicker style={{ width: 425 }} allowClear={false} value={[moment(state.beginTime), moment(state.endTime)]}
                    onChange={(dates) => {
                        if (!dates) { return; }
                        dispatch({
                            beginTime: dates[0].format('Y-M-D'),
                            endTime: dates[1].format('Y-M-D'),
                        });
                    }}
                    disabledDate={disabledDate}
                    onCalendarChange={val => setDates(val)}
                />
            </Space>
        </Form.Item>
    </div>
}