// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../config";
import { effeative } from "./common_logic";

interface IOperation {
    "shopID": string;
    "source": number;
    "inventoryType": number;
    "inventoryStatus": number;
    "reason": number;
    "remark": string;
    "details": [
        {
            "goodsID": string;
            "goodsName": string;
            "goodsUnitID": string;
            "unitName": string;
            "inventoryType": number;
            "putPrice": number;
            "putNumber": number;
            "remark": string;
        }
    ]
}
interface ISelListByRecord {
    "source"?: number;
    "inventoryType"?: number;
    "inventoryStatus"?: number;
    "reason"?: number;
    "keyWords"?: string;
    "pageIndex"?: number;
    "pageSize"?: number;
    "orderBy"?: string;
    "sort"?: string;
}
interface ISelList {
    "keyWords"?: string;
    "goodsClass"?: string;
    "shopID"?: string;
    "pageIndex"?: number;
    "pageSize"?: number;
    "orderBy"?: string;
    "sort"?: string;
}
interface ISelListByRunwater {
    "keyWords"?: string;
    "goodsClass"?: string;
    "shopID"?: string;
    "startTime"?: string;
    "endTime"?: string;
    "pageIndex"?: number;
    "pageSize"?: number;
    "orderBy"?: string;
    "sort"?: string;
}

export class DiscountLogic {
    async addOperation(params) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("marketing_add"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    /**
     * 分页查询库存记录信息
     */
    async selListByRecord(params: ISelListByRecord) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("marketing_list"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    async selListByExportRecord(params: ISelListByRecord) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("inventory_instock_export"),
            responseType: 'arraybuffer',
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    async selListByOutRecord(params: ISelListByRecord) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("inventory_out_select_list"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    async selListByExportOutRecord(params: ISelListByRecord) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("inventory_outstock_export"),
            responseType: 'arraybuffer',
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    async selListByRunwater(params: ISelListByRunwater) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("inventory_select_runwater_list"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    async selListByExportRunwater(params: ISelListByRunwater) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("inventory_flow_export"),
            responseType: 'arraybuffer',
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    async selList(params: ISelList) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("inventory_select_list"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    async selExportList(params: ISelList) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("inventory_export"),
            responseType: 'arraybuffer',
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    
    async selData(goodsId: string) {
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("inventory_sel_data"),
            params: {
                goodsId
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            }
        }))
        if (r.data && r.data.data) {
            return r.data.data;
        }
    }
    async selDataByWarehousing(oddNumbers: string) {
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("marketing_look"),
            params: {
                id:oddNumbers
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            }
        }))
        if (r.data && r.data.data) {
            return r.data.data;
        }
    }
    async updOperation(params) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("marketing_update"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            }
        }))
        return r;
    }
    async delData(InvID: string) {
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("marketing_type_update"),
            params: {
                id:InvID
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            }
        }))
        return r;
    }
}