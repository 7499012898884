import { createContext, useReducer } from "react";

export const UnitStore = createContext<IContextProps>(null);

type State = "Text" | "Input" | "Tips" | "Add";
type Dispatch = { type, payload }

class Cell {
    state: State;
    value: string;
    entranceValue: string;
    rawData: any;
    /** 图标地址 */
    iconPath: string;
    entranceIconPath: string;
    isHidden: boolean;
    constructor(state: State, value: string, rawData?) {
        this.state = state;
        this.value = value;
        this.entranceValue = "";
        this.rawData = rawData;
        //
        this.iconPath = '';
        this.entranceIconPath = '';
        this.isHidden = false;
    }

    /** 获取最新的icon地址 */
    get getOnIconPath(): string {
        return this.entranceIconPath || this.iconPath;
    }
}
class Row {
    rows: Cell[] = [];
    constructor(rows: Cell[]) {
        this.rows = rows;
    }
}
interface IContextProps {
    state: IState;
    dispatch: ({ type }: Dispatch) => void;
}
interface IState {
    isHidden?: boolean;
    dataList: Row[];
}
const initialArgs = {
    dataList: [],
    isHidden: false
}
function reducer(state: IState, { type, payload }: Dispatch) {
    switch (type) {
       
        // 新增行
        case "ADD_ROW":
            return {
                ...state,
                dataList: [...state.dataList, new Row([new Cell("Add", "")])]
            };
        // 新增单元格
        case "ADD_CELL":
            {
                const row = state.dataList[payload.rowindex];
                row.rows.splice(row.rows.length - 1, 1)
                row.rows.push(new Cell("Input", ""))
                row.rows.push(new Cell("Add", ""))
                return {
                    ...state
                }
            }
        // 单元格输入文本
        case "INPUT_TEXT":
            {
                const call = state.dataList[payload.rowindex].rows[payload.cellindex];
                call.entranceValue = payload.value;

                return {
                    ...state
                }
            }
        //改变图标
        case "INPUT_ICON":
            const call = state.dataList[payload.rowindex].rows[payload.cellindex];
            call.entranceIconPath = payload.iconPath;
            return {
                ...state,
            };
        // 保存 input 模式
        case "SAVE_INPUT":
            {
                const call = state.dataList[payload.rowindex].rows[payload.cellindex];
                const entranceValue = call.entranceValue;
                const entranceIconPath = call.entranceIconPath;

                // 输入模式下，为空保存
                if ("Input" == call.state && call.entranceValue.trim() == "") {
                    // 删除当前单元格
                    state.dataList[payload.rowindex].rows.splice(payload.cellindex, 1);
                }
                // 普通保存
                else {
                    call.state = "Text";
                    call.value = entranceValue;
                    call.iconPath = entranceIconPath || call.iconPath;
                    call.rawData = payload.rawData;
                }

                return {
                    ...state
                }
            }
        // 取消 input 模式
        case "CANCEL_INPUT":
            {
                const call = state.dataList[payload.rowindex].rows[payload.cellindex];

                // 输入模式下，为空取消
                if ("Input" == call.state && call.value.trim() == "") {
                    // 删除当前单元格
                    state.dataList[payload.rowindex].rows.splice(payload.cellindex, 1);
                }
                // 普通取消
                else {
                    call.state = "Text";
                    call.entranceValue = "";
                    call.entranceIconPath = '';
                }

                return {
                    ...state
                }
            }
        // 切换 tips 模式
        case "TOGGOLE_TIPS":
            {
                const call = state.dataList[payload.rowindex].rows[payload.cellindex];

                call.state = "Tips";

                return {
                    ...state
                }
            }
        // 切换 text 模式
        case "TOGGOLE_TEXT":
            {
                const call = state.dataList[payload.rowindex].rows[payload.cellindex];

                call.state = "Text";

                return {
                    ...state
                }
            }
        // 编辑
        case "TOGGOLE_EDIT":
            {
                const call = state.dataList[payload.rowindex].rows[payload.cellindex];
                call.state = "Input";
                call.entranceValue = call.value;

                return {
                    ...state
                }
            }
        // 删除
        case "TOGGOLE_DELETE":
            {
                // 删除当前单元格
                state.dataList[payload.rowindex].rows.splice(payload.cellindex, 1);

                return {
                    ...state
                }
            }
        case "DELETE_ROW":
            {
                // 删除当前单元行
                state.dataList[payload.rowindex].rows.length = 0;

                return {
                    ...state
                }
            }
        // 初始列表
        case "INIT_LIST":
            {
                const dataList = payload.dataList.map(row => {

                    return new Row(
                        row.map(cell => {
                            let call = new Cell("Text", payload.callbackText(cell), cell)
                            payload.callbackIcon && (call.iconPath = payload.callbackIcon(cell));
                            payload.callbackIsHidden && (call.isHidden = payload.callbackIsHidden(cell))
                            return call;
                        })
                    )
                })

                dataList.forEach(v => v.rows.push(new Cell("Add", "")));
                return {
                    dataList
                }
            }
        // 删除行
        case "DELETE_ROW_ACTION":
            {
                // 删除当前单元格
                state.dataList[payload.rowindex].rows.length = 0;

                return {
                    ...state
                }
            }
        default:
            return state;
    }
}

export function UnitStoreProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialArgs);

    const v = { state, dispatch };
    return <UnitStore.Provider value={v}>{props.children}</UnitStore.Provider>
}