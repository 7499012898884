import { Button, Col, Form, Input, Row, Select } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useState } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { FormSubtitle } from "../../../commodity/view/form_subtitle";
import styles from "../../style/inventory_goods_formbasic.module.scss";

import img_del from "../../../../assets/images/shanchu.png";
import { warehousingformAction } from "../../action/in-stock/warehousing_form_action";
import { useBoolean } from "ahooks";
import { ChooseGoods } from "../../../../component/choose-goods";
import { Table } from "../../../../component/table";
import { ChooseShop } from "../../../../component/choose-shop";
import { inventoryGoodsFormAction } from "../../action/inventory/inventory_goods_form_action";
import { InventoryGoodsFormStore } from "../../store/inventory/inventory_goods_form_store";
import { TableExpand } from "../../../../component/table-expand";
import { UnitLogic } from "../../../../logic/unit_logic";
import { autoRem } from "../../../../global.style";
import { NumberTool } from "../../../../tool/numberTool";

const { Option } = Select;

export function DataTable({ dataList, disabled }) {
  const { dispatch } = useContext(InventoryGoodsFormStore);
  const [current, setCurrent] = useState(1);
  const [pageSize, setSize] = useState(10);
  const action = inventoryGoodsFormAction;

  const columns: ColumnsType = [
    {
      title: "操作",
      dataIndex: "serial",
      key: "1",
      align: "center",
      width: 100,
      render: (v, r, i) => (
        <div>
          <img
            src={img_del}
            style={{ width: 20 }}
            onClick={() => {
              if (disabled) return;
              action.delTableItem(dispatch, v);
            }}
          />
        </div>
      ),
    },
    {
      title: "批次号",
      dataIndex: "storageBatchNumber",
      key: "0",
      align: "center",
      width: 185
    },
    {
      title: "商品编码",
      dataIndex: "goodsCode",
      key: "2",
      align: "center",
      width: 120
    },
    {
      title: "商品名称",
      dataIndex: "goodsName",
      key: "3",
      align: "center",
      width: 120
    },
    {
      title: "库存单位",
      dataIndex: "unitName",
      key: "4",
      align: "center",
      width: 80
    },
    // {
    //     title: '商品规格',
    //     dataIndex: '',
    //     key: '7',
    //     align: "center",
    //     width: 120,
    //     render: (e, r, i) => {
    //         return <div>****</div>;
    //     }
    // },
    {
      title: "系统数量",
      dataIndex: "systemNumber",
      key: "7",
      align: "center",
      width: 100
      // render: (e, r: any, i) => {
      //     return <div>{r.goodsSpecifications && r.goodsSpecifications.reduce((a, b) => {
      //         return a + (b.sysNumber || 0);
      //     }, 0)}</div>
      // }
    },
    {
      title: "实盘数量",
      dataIndex: "checkNumber",
      key: "8",
      align: "center",
      width: 120,
      render: (v, r: any, i) => {
        return (
          <Input
            type="number"
            style={{ width: 100 }}
            value={v}
            disabled={disabled}
            onChange={(e) => {
              action.changeTableItem({
                dispatch,
                key: "checkNumber",
                value: e.target.value,
                index: i + (current - 1) * pageSize,
              });
            }}
          />
        );
      },
      // render: (e, r: any, i) => {
      //     return <div>{r.goodsSpecifications && r.goodsSpecifications.reduce((a, b) => {
      //         return a + (b.checkNumber || 0);
      //     }, 0)}</div>
      // }
    },
    {
      title: "库存价",
      dataIndex: "averagePrice",
      key: "9",
      align: "center",
      width: 100
      // render: (e, r: any, i) => {
      //     return <div>{NumberTool.toAmount(r.goodsSpecifications && r.goodsSpecifications.reduce((a, b) => {
      //         return a + (b.inventoryAvg || 0);
      //     }, 0))}</div>
      // }
    },
    {
      title: "差异数量",
      dataIndex: "differenceNumber",
      key: "10",
      align: "center",
      width: 100
      //   render: (e, r: any, i) => {
      //     return (
      //       <div>
      //         {r.goodsSpecifications &&
      //           r.goodsSpecifications.reduce((a, b) => {
      //             return a + (b.differenceNumber || 0);
      //           }, 0)}
      //       </div>
      //     );
      //   },
    },
    {
      title: "差异金额",
      dataIndex: "differencePrice",
      key: "11",
      align: "center",
      width: 100,
      render: (e, r: any, i) => {
        return (
          <div>
            ￥{NumberTool.toAmount(r.differenceNumber * r.averagePrice)}
          </div>
        );
      },
    },
  ];

  return (
    <Table
      scroll={{ y: 275 }}
      dataSource={dataList}
      columns={columns}
      onChange={(pagination) => {
        setCurrent(pagination.current);
        setSize(pagination.pageSize);
      }}
      //   expandable={{
      //     expandedRowRender: (record) => {
      //       if (record.goodsSpecifications) {
      //         return record.goodsSpecifications.map((item) => {
      //           return (
      //             <GetSpecifications
      //               disabled={disabled}
      //               specifications={item}
      //               unitName={record.unitName}
      //             />
      //           );
      //         });
      //       }
      //     },
      //     rowExpandable: (record) => record.name !== "Not Expandable",
      //   }}
    />
  );
}

function GetSpecifications({ specifications, unitName, disabled }) {
  const { state, dispatch } = useContext(InventoryGoodsFormStore);
  return (
    <div className={styles.specifications}>
      <div className={styles.info}>
        <span>{specifications.unitContent}</span>
        <span>{specifications.unitName}</span>
      </div>
      <div className={styles.input} style={{ width: 105 }}>
        <Input
          disabled={true}
          type="number"
          value={specifications.sysNumber}
          onChange={(e) => {
            specifications.sysNumber = Math.abs(
              parseFloat(e.currentTarget.value)
            );
            dispatch({
              type: "update",
              payload: null,
            });
          }}
        />
      </div>
      <div className={styles.input} style={{ width: 105 }}>
        <Input
          disabled={disabled}
          type="number"
          value={specifications.checkNumber}
          onChange={(e) => {
            specifications.checkNumber = Math.abs(
              parseFloat(e.currentTarget.value)
            );
            specifications.differenceNumber =
              specifications.sysNumber - specifications.checkNumber;
            // let price =  specifications.inventoryAvg *  NumberTool.toInteger(specifications.differenceNumber).num / NumberTool.toInteger(specifications.differenceNumber).times
            let price = NumberTool.multiply(
              specifications.inventoryAvg,
              specifications.differenceNumber,
              2
            );
            specifications.differencePrice = price.toFixed(2);
            dispatch({
              type: "update",
              payload: null,
            });
          }}
        />
      </div>
      <div className={styles.input} style={{ width: 105 }}>
        <Input
          disabled={true}
          type="number"
          value={specifications.inventoryAvg}
          onChange={(e) => {
            // specifications.inventoryAvg = Math.abs(parseFloat(e.currentTarget.value));

            dispatch({
              type: "update",
              payload: null,
            });
          }}
        />
      </div>
      <div className={styles.input} style={{ width: 105 }}>
        <Input
          disabled={true}
          type="number"
          value={specifications.differenceNumber}
          onChange={(e) => {
            dispatch({
              type: "update",
              payload: null,
            });
          }}
        />
      </div>
      <div className={styles.input} style={{ width: 105 }}>
        <Input
          disabled={true}
          type="number"
          value={specifications.differencePrice}
          onChange={(e) => {
            dispatch({
              type: "update",
              payload: null,
            });
          }}
        />
      </div>
    </div>
  );
}

export function InventoryGoodsFormBasic({ disabled, updateFormField }) {
  const [shopState, shopFunc] = useBoolean(false);
  const [goodsState, goodsFunc] = useBoolean(false);

  const { state, dispatch } = useContext(InventoryGoodsFormStore);
  const { formData } = state;

  const action = inventoryGoodsFormAction;

  const combined = (key) => {
    return state.tableList.reduce((a, b) => {
      //   if (b.goodsSpecifications) {
      // return (
      //   a +
      //   b.goodsSpecifications.reduce((a, b) => {
      return a + (b[key] || 0);
      //   }, 0)
      // );
      //   }
    }, 0);
  };

  let height = document.body.clientHeight - 188 + 26;
  return (
    <div className={styles.root} style={{ height: height + "px" }}>
      <div className={styles.content}>
        <FormSubtitle>基础信息</FormSubtitle>

        <div
          style={{
            margin: "" + autoRem(1.9375) + " " + autoRem(1.25) + " 0 0",
          }}
        >
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item name="" label="制单人" labelCol={{ span: 8 }}>
                <span>{formData.userName}</span>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="制单时间" labelCol={{ span: 8 }}>
                <span style={{ display: "block", minWidth: 200 }}>
                  {formData.date}
                </span>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item
                label="门店"
                name="shop"
                labelCol={{ span: 8 }}
                rules={[
                  {
                    required: true,
                    message: "请选择门店",
                    validator: (
                      rule,
                      value,
                      callback: (error?: string) => void
                    ) => {
                      if (!formData.shop?.shopName) {
                        callback("请选择门店");
                      } else {
                        callback();
                      }
                    },
                  },
                ]}
              >
                {formData.shop.shopName ? "" : ""}
                <Input
                  disabled={disabled}
                  value={formData.shop && formData.shop.shopName}
                  autoComplete="off"
                  placeholder="请选择"
                  onClick={() => shopFunc.setTrue()}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="盘点理由"
                name="reasonId"
                labelCol={{ span: 10 }}
                rules={[
                  {
                    required: true,
                    message: "请选择盘点理由",
                    validator: (
                      rule,
                      value,
                      callback: (error?: string) => void
                    ) => {
                      if (!formData.reasonId) {
                        callback("请选择盘点理由");
                      } else {
                        callback();
                      }
                    },
                  },
                ]}
              >
                {formData.reasonId ? "" : ""}
                <Select
                  disabled={disabled}
                  value={formData.reasonId}
                  onChange={(v) => {
                    action.setFormData({ dispatch, key: "reasonId", value: v });
                    updateFormField("reasonId");
                  }}
                >
                  <Option value={1}>日常盘点</Option>
                  <Option value={2}>月度盘点</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="备注" labelCol={{ span: 8 }}>
                <Input
                  maxLength={200}
                  disabled={disabled}
                  value={formData.remark}
                  autoComplete="off"
                  onChange={(v) =>
                    action.setFormData({
                      dispatch,
                      key: "remark",
                      value: v.target.value,
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <div style={{ marginTop: -20 }}>
          <FormSubtitle>商品信息</FormSubtitle>
        </div>

        <div
          style={{
            margin: "" + autoRem(1.9375) + " " + autoRem(1.25) + " 0 0",
          }}
        >
          {/* <Row gutter={16} style={{ marginTop: 10 }}>
            <Col span={10}>
              <Form.Item label="快速添加" labelCol={{ span: 6 }}>
                <Input
                  autoFocus
                  allowClear
                  disabled={disabled}
                  placeholder="扫描商品条形码，快速添加"
                  value={state.code}
                  onPressEnter={async (e) => {
                    await action.getGoods({
                      id: e.currentTarget.value,
                      dispatch,
                      state,
                    });
                    dispatch({ type: "InputCode", payload: "" });
                  }}
                  onChange={(e) => {
                    dispatch({ type: "InputCode", payload: e.target.value });
                  }}
                />
              </Form.Item>
            </Col>            
          </Row> */}
        </div>

        <div style={{ marginTop: -20 }}>
          <ButtonPanel>
            <Button disabled={disabled} onClick={() => goodsFunc.setTrue()}>
              选择商品
            </Button>
          </ButtonPanel>
        </div>

        <DataTable dataList={state.tableList} disabled={disabled} />
        <TableExpand>
          系统数量合计: {combined("systemNumber").toFixed(3)}
          <span style={{ marginLeft: 10 }}>
            实盘数量合计: {combined("checkNumber").toFixed(3)}
          </span>
          <span style={{ marginLeft: 10 }}>
            差异数量合计: {combined("differenceNumber").toFixed(3)}
          </span>
          <span style={{ marginLeft: 10 }}>
            差异金额合计: ¥ {NumberTool.toAmount(combined("differencePrice"))}
          </span>
        </TableExpand>
      </div>

      <ChooseShop
        visibleModal={shopState}
        close={(status, r) => {
          if (r) {
            action.setFormData({ dispatch, key: "shop", value: r[0] });
          }
          updateFormField("shop");
          shopFunc.setFalse();
        }}
      />
      <ChooseGoods
        rowKey="storageBatchNumber"
        visibleModal={goodsState}
        close={(status, r) => {
          if (r) {
            action.addTableItem({ dispatch, list: r });
          }
          goodsFunc.setFalse();
        }}
        filter={{ inventoryType: 'check',zeroInventory: true }}
      />
    </div>
  );
}
