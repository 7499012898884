import moment from "moment";
import { GraphicReportLogic } from "../../logic/graphicReport_logic";
class FileAction{
    
   async init({ state, dispatch,}){
    //  let day = new Date().getDate();
    // let start = new Date().getTime() - (day-1) *24 *60 *60 * 1000
    // let startTime = moment(start).format('yyyy-MM-DD')
    // console.log(startTime)
    // let endTime = moment().format('yyyy-MM-DD')
    // dispatch({ type: "SetCondition", payload: { ...state.condition, startTime, endTime } });
      this.loadData({ dateType: state.condition.dateType,startTime:state.condition.startTime,endTime:state.condition.endTime},dispatch)
    }
    setType(type, { condition, dispatch,state }) {
        if(type == condition.dateType){
            return false
        }
        
            let day = new Date().getDate();
            let start = new Date().getTime() - (day-1) *24 *60 *60 * 1000
            let startTime =  condition.startTime
            let endTime = condition.endTime
            if(new Date(condition.endTime).getTime() - new Date(condition.startTime).getTime() > 24*60*60*1000*30){
             startTime = moment(start).format('yyyy-MM-DD')
             endTime = moment().format('yyyy-MM-DD')
            }
            
            dispatch({ type: "SetCondition", payload: { startTime,endTime, dateType:type } });
            this.loadData({ startTime,endTime, dateType:type},dispatch)
    }
    setDate({ startTime, endTime }, { condition, dispatch,state }) {
        dispatch({ type: "SetCondition", payload: { ...condition, startTime, endTime } });
        if(startTime!='' && endTime!='' && startTime!=undefined && endTime!=undefined){
            this.loadData({ dateType: condition.dateType,startTime,endTime},dispatch)
        }
    }
        loadData({startTime,endTime,dateType},dispatch){
          let params = {
                type: dateType,
                starTime:startTime,
                endTime: endTime
            }
            // console.log(params)
            this.loadGoodsTop(dispatch,params)
            this.loadMemberExpendTOP(dispatch,params)
            this.loadMemberStoredTOP(dispatch,params)
            this.loadGetNewMember(dispatch,params)
            this.loadGetMemberStatic(dispatch,params)
            this.loadGetRefundStatic(dispatch,params)
            this.loadGetSaleStatic(dispatch,params)
            this.loadGetOrderTypeStatic(dispatch,params)
            this.loadGetPayTypeSaleStaic(dispatch,params)
            this.loadGetClassSaleStatic(dispatch,params)
        }
            /**获取商品销售top10 */
        async  loadGoodsTop( dispatch, params){
                let param ={
                    ...params
                }
                delete param.type
                const logic = new GraphicReportLogic()       
                const r = await  logic.GetGoodsTOP(param)
                if(r && r.data && r.data.statusCode == 200){
                let data = r.data.data || []
                let dataList = []
                let max = 0
                if(data.length>0){
                        data.map((item,index)=>{                    
                            max = max>item.goodsNum?max:item.goodsNum
                            dataList.push({
                                value: (10-index)*10,
                                num: item.goodsNum,
                                name: item.name
                            })
                        })
                }
                    let obj = {}
                    obj['goodsTop'] = dataList
                    // dispatch({type:'InitList',payload:obj})
                    dispatch({type:'InitList',payload:{attribute:'goodsTop',value: dataList}})
                }
                }
          /**获取会员消费排行top10 */
        async  loadMemberExpendTOP( dispatch, params){
            let objParam = {...params}
            delete objParam.type
            const logic = new GraphicReportLogic()       
            const r = await  logic.MemberExpendTOP(objParam)
            if(r && r.data && r.data.statusCode == 200){
            let data = r.data.data || []
            let dataList = []
            if(data.length>0){
                    data.map((item,index)=>{                    
                        dataList.push({
                            value: (10-index)*10,
                            num: item.money,
                            name: item.name
                        })
                    })
            }
                let param = {}
                param['memberSaleTop'] = dataList
                // dispatch({type:'InitList',payload:param})
                dispatch({type:'InitList',payload:{attribute:'memberSaleTop',value: dataList}})
            }
            }
                      /**获取会员储值排行top10 */
        async  loadMemberStoredTOP( dispatch, params){
            let objParam = {...params}
            delete objParam.type
            const logic = new GraphicReportLogic()       
            const r = await  logic.MemberStoredTOP(objParam)
            if(r && r.data && r.data.statusCode == 200){
            let data = r.data.data || []
            let dataList = []
            if(data.length>0){
                    data.map((item,index)=>{                    
                        dataList.push({
                            value: (10-index)*10,
                            num: item.money,
                            name: item.name
                        })
                    })
            }
                let param = {}
                param['memberStoreTop'] = dataList
                dispatch({type:'InitList',payload:{attribute:'memberStoreTop',value: dataList}})
            }
            }
        /**获取 新用户走势图*/
        async loadGetNewMember(dispatch,params){
            const r = await new GraphicReportLogic().GetNewMember(params)
            if(r && r.data && r.data.statusCode == 200){
                let arr = r.data.data || []
                let dataList = []
                if(arr.length>0){
                    arr.map((item,index)=>{
                        dataList.push({
                            text:'新增会员',
                            index: index,
                            name:item.orderDate,
                            value: item.memberNum
                        })
                    })
                }
                let param = {}
               
                param['newMemberLine'] = [dataList]
                dispatch({type:"InitList",payload: {attribute:'newMemberLine',value:[dataList]}})
            }
        }
         /**获取 会员收支折线*/
         async loadGetMemberStatic(dispatch,params){
          
            const r = await new GraphicReportLogic().GetMemberStatic(params)
            if(r && r.data &&r.data.data &&  r.data.statusCode == 200){
                let expendList = r.data.data.expendList || []
                let storedList = r.data.data.storedList || []
                let dataListEx = []
                let dataListStore = []
                if(expendList.length>0){
                    expendList.map(item=>{
                        dataListEx.push({
                            text:'会员消费',
                            name: item.orderDate,
                            value: item.money
                        })
                    })
                }
                if(storedList.length>0){
                    storedList.map(item=>{
                        dataListStore.push({
                            text:'会员收入',
                            name: item.orderDate,
                            value: item.money
                        })
                    })
                }
               
                let param = {}
              
                param['memberIncomeExpendLine'] = [dataListEx,dataListStore]
                param['memberExpendLine'] = dataListEx
                param['memberIncomeLine'] = dataListStore
                dispatch({type:"InitList",payload: {attribute:'memberIncomeExpendLine',value:[dataListEx,dataListStore]}})
            }
        }

         /**获取 退款折线图*/
         async loadGetRefundStatic(dispatch,params){
            const r = await new GraphicReportLogic().GetRefundStatic(params)
            if(r && r.data && r.data.statusCode == 200){
                let arr = r.data.data || []
                let dataList = []
                if(arr.length>0){
                    arr.map((item,index)=>{
                        dataList.push({
                            text:'会员退款',
                            index: index,
                            name:item.orderDate,
                            value: item.money
                        })
                    })
                }
                let param = {}
               
                param['refundListLine'] = [dataList]
                dispatch({type:"InitList",payload: {attribute:'refundListLine',value:[dataList]}})
            }
        }
         /**获取 销售额折线图*/
         async loadGetSaleStatic(dispatch,params){
          
            const r = await new GraphicReportLogic().GetSaleStatic(params)
            if(r && r.data && r.data.statusCode == 200){
                let arr = r.data.data || []
                let dataList = []
                if(arr.length>0){
                    arr.map((item,index)=>{
                        dataList.push({
                            text:'销售额',
                            index: index,
                            name:item.orderDate,
                            value: item.saleMoney
                        })
                    })
                }
                let param = {}
               
                param['saleListLine'] = [dataList]
                dispatch({type:"InitList",payload: {attribute:'saleListLine',value:[dataList]}})
            }
        }

        /**获取订单类型饼状图 */
        async  loadGetOrderTypeStatic( dispatch, params){
            let param ={
                ...params
            }
            delete param.type
            const logic = new GraphicReportLogic()       
            const r = await  logic.GetOrderTypeStatic(param)
            if(r && r.data && r.data.statusCode == 200){
            let data = r.data.data || []
            let dataList = []
            if(data.length>0){
                    data.map((item,index)=>{                    
                        dataList.push({
                            text:'订单类型',
                            value: item.money,
                            num: item.money,
                            name: item.orderType ==1?'门店订单':item.orderType == 2?'商城订单':'自助收银订单'
                        })
                    })
            }
                dispatch({type:'InitList',payload:{attribute:'orderTypePie',value: dataList}})
            }
            }
        
        /**获取支付方式饼状图 */
        async  loadGetPayTypeSaleStaic( dispatch, params){
            let param ={
                ...params
            }
            delete param.type
            const logic = new GraphicReportLogic()       
            const r = await  logic.GetPayTypeSaleStaic(param)
            if(r && r.data && r.data.statusCode == 200){
            let data = r.data.data || []
            let dataList = []
            if(data.length>0){
                    data.map((item,index)=>{                    
                        dataList.push({
                            text:'支付方式',
                            value: item.saleMoney,
                            num: item.saleMoney,
                            name: item.name
                        })
                    })
            }
                dispatch({type:'InitList',payload:{attribute:'payWayPie',value: dataList}})
            }
            }
                
        /**获取商品分类饼状图 */
        async  loadGetClassSaleStatic( dispatch, params){
            let param ={
                ...params
            }
            delete param.type
            const logic = new GraphicReportLogic()       
            const r = await  logic.GetClassSaleStatic(param)
            if(r && r.data && r.data.statusCode == 200){
            let data = r.data.data || []
            let dataList = []
            if(data.length>0){
                    data.map((item,index)=>{                    
                        dataList.push({
                            text:'商品分类',
                            value: item.saleMoney,
                            num: item.saleMoney,
                            name: item.name
                        })
                    })
            }
                dispatch({type:'InitList',payload:{attribute:'classSalePie',value: dataList}})
            }
            }
            
}
export const fileAction = new FileAction()