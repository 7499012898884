import { useEffect } from "react";
import { useRef } from "react";
import ReactDOM from "react-dom";
import styles from "./prop.module.scss";
//添加一个用来提示的元素
let popHtml = document.body.appendChild(document.createElement("div"));
/**
 * 基础弹窗
 * @param props
 */
export function ComPopUpWin(props: {
  children?;
  pos?: "top" | "centre";
  backgroundColor?: string;
  opacity?: number;
  onScroll?: (scrollTop: number) => void;
}) {
  let ref = useRef(null);

  //
  return ReactDOM.createPortal(
    <div
      className={styles.pop_up_window + (props.pos ? " flex_" + props.pos : "")}
    >
      <div
        className={styles.shade}
        style={{
          backgroundColor: props.backgroundColor,
          opacity: props.opacity,
        }}
      ></div>
      <div
        className={styles.content}
        ref={ref}
        onScroll={(e) => {
          props.onScroll?.(ref.current.scrollTop);
        }}
      >
        {props.children}
      </div>
    </div>,
    popHtml
  );
}
