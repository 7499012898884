import { createContext, useReducer } from "react";

class FormData {
  memberCard: string;
  memberName: string;
  membeClassID: string;
  phone: string;
  birthday: any;
  source: number;
  label: string;
  balanceMoney: number;
  memberPwd: string;
  remark: string;
  accountIntegral: number;
  status: number;
  gender: number;
  card: string = "";
  superiorMemberID: string;
  superiorMemberPhone: string;
}

type Dispatch = { type; payload };

interface IState {
  formData: FormData;
  gradeList: any[];
  TagList: any[];
}

const initialArgs = {
  formData: new FormData(),
  gradeList: [],
  TagList: [],
};

export type FormAttribute =
  | "Card"
  | "MemberCard"
  | "Remark"
  | "MemberName"
  | "Gender"
  | "MembeClassID"
  | "Phone"
  | "Birthday"
  | "Source"
  | "Label"
  | "BalanceMoney"
  | "AccountIntegral"
  | "Status"
  | "MemberPwd"
  | "SuperiorMemberId"
  | "SuperiorMemberPhone";

interface IContextProps {
  state: IState;
  dispatch: ({ type }: Dispatch) => void;
}

export const FormStore = createContext<IContextProps>(null);

function reducer(state: IState, { type, payload }: Dispatch) {
  switch (type) {
    // 填充表单
    case "SetForm": {
      const data = payload;
      const form = new FormData();
      {
        const {
          memberCard,
          memberName,
          membeClassID,
          remark,
          phone,
          birthday,
          source,
          label,
          balanceMoney,
          memberPwd,
          accountIntegral,
          status,
          gender,
          superiorMemberID,
          superiorMemberPhone
        } = data;
        form.memberCard = memberCard;
        form.memberName = memberName;
        form.membeClassID = membeClassID;
        form.phone = phone;
        form.birthday = birthday;
        form.source = source;
        form.label = label;
        form.balanceMoney = balanceMoney;
        form.memberPwd = memberPwd;
        form.remark = remark;
        form.accountIntegral = accountIntegral;
        form.status = status;
        form.gender = gender;
        form.card = memberCard;
        form.superiorMemberID = superiorMemberID;
        form.superiorMemberPhone = superiorMemberPhone;
      }

      return {
        ...state,
        formData: form,
      };
    }
    // 等级列表
    case "LoadGrade":
      return {
        ...state,
        gradeList: payload,
      };
    // 标签列表
    case "LoadTag":
      return {
        ...state,
        TagList: payload,
      };
    // 设置表单
    case "Change_FormData": {
      const { attribute, value } = payload,
        props: any = {};

      const key: FormAttribute = attribute;
      let attri = "";

      switch (key) {
        case "Card":
          attri = "card";
          break;
        case "MemberCard":
          attri = "memberCard";
          break;
        case "MemberName":
          attri = "memberName";
          break;
        case "MembeClassID":
          attri = "membeClassID";
          break;
        case "Gender":
          attri = "gender";
          break;
        case "Phone":
          attri = "phone";
          break;
        case "Birthday":
          attri = "birthday";
          break;
        case "Source":
          attri = "source";
          break;
        case "Label":
          attri = "label";
          break;
        case "BalanceMoney":
          attri = "balanceMoney";
          break;
        case "MemberPwd":
          attri = "memberPwd";
          break;
        case "AccountIntegral":
          attri = "accountIntegral";
          break;
        case "Status":
          attri = "status";
          break;
        case "SuperiorMemberId":
          attri = "superiorMemberID";
          break;
          case "SuperiorMemberPhone":
            attri = "superiorMemberPhone";
            break;
        case "Remark":
          attri = "remark";
          break;
        default:
          break;
      }
      props[attri] = value;

      return {
        ...state,
        formData: {
          ...state.formData,
          ...props,
        },
      };
    }
    default:
      return state;
  }
}

export function FormStoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialArgs);

  const v = { state, dispatch };
  return <FormStore.Provider value={v}>{props.children}</FormStore.Provider>;
}
