import { createContext, useReducer } from "react";

class FormData {
    parentUID: string;
    name: string;
    icon: string;
    sortIndex: string;
    path: string;
    component: string;
    viewPower: string;
    keepAlive: boolean;
    isFrame: boolean;
    hidden: boolean;
    contactPeople: string;
    contactPhone: string;
    status: number = 1;
    city: string[];
    address: string;
    remark: string;
}

type Dispatch = { type, payload }

interface IState {
    formData: FormData;
    menuList: any[]
    authxtList: any[]
}

const initialArgs = {
    formData: new FormData(),
    menuList: [],
    authxtList: [],
}

export type FormAttribute = "ParentUID" | "Name" | "Icon" | "SortIndex" | "Hidden" | "Path" | "Component" | "ViewPower" | "KeepAlive" | "IsFrame" | "ContactPeople" | "ContactPhone" | "Status" | "City" | "Address" | "Remark";

interface IContextProps {
    state: IState;
    dispatch: ({ type }: Dispatch) => void;
}

export const FormStore = createContext<IContextProps>(null);

function reducer(state: IState, { type, payload }: Dispatch) {
    switch (type) {
        // 菜单列表
        case "MenuList":
            return {
                ...state,
                menuList: payload
            }
        // 菜单列表
        case "AuthxtList":
            return {
                ...state,
                authxtList: payload
            }
        // 填充表单
        case "SetForm":
            {
                const data = payload;
                const form = new FormData();

                {
                    const { parentUID, name, icon, sortIndex, path, component, viewPower, keepAlive, hidden, isFrame, phone, status, provincialCapital, city, district, detailedAddress, usableArea } = data;
                    form.parentUID = parentUID;
                    form.name = name;
                    form.icon = icon;
                    form.sortIndex = sortIndex;
                    form.path = path;
                    form.component = component;
                    form.viewPower = viewPower;
                    form.keepAlive = keepAlive;
                    form.hidden = hidden;
                    form.isFrame = isFrame;
                    form.contactPhone = phone;
                    form.status = status;
                    form.city = [provincialCapital, city, district];
                    form.address = detailedAddress;
                }

                return {
                    ...state,
                    formData: form
                }
            }
        // 设置表单
        case "Change_FormData":
            {
                const { attribute, value } = payload, props: any = {};

                const key: FormAttribute = attribute;
                let attri = "";

                switch (key) {
                    case "ParentUID":
                        attri = "parentUID";
                        break
                    case "Name":
                        attri = "name";
                        break
                    case "Icon":
                        attri = "icon";
                        break
                    case "SortIndex":
                        attri = "sortIndex";
                        break
                    case "Path":
                        attri = "path";
                        break
                    case "Component":
                        attri = "component";
                        break
                    case "ViewPower":
                        attri = "viewPower";
                        break
                    case "KeepAlive":
                        attri = "keepAlive";
                        break
                    case "Hidden":
                        attri = "hidden";
                        break
                    case "IsFrame":
                        attri = "isFrame";
                        break
                    case "ContactPeople":
                        attri = "contactPeople";
                        break
                    case "ContactPhone":
                        attri = "contactPhone";
                        break
                    case "Status":
                        attri = "status";
                        break
                    case "City":
                        attri = "city";
                        break
                    case "Address":
                        attri = "address";
                        break
                    case "Remark":
                        attri = "remark";
                        break
                    default:
                        break;
                }

                props[attri] = value;

                return {
                    ...state,
                    formData: {
                        ...state.formData,
                        ...props
                    }
                }
            }
        default:
            return state;
    }
}

export function FormStoreProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialArgs);

    const v = { state, dispatch };
    return <FormStore.Provider value={v}>{props.children}</FormStore.Provider>
}