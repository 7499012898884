import { useState, useContext } from "react";
import { Mask } from "../../../../component/mask";
import { Search } from "../../../../component/search";
import { TipsGroup, Tips } from "../../../../component/tips";
import { Form, Button, Input } from "antd";
import styles from "../../style/searchLayer.module.scss";
import { ISpeedQuery } from "../../com/ISelectType";
import { SelectStore } from "../store/SelectStore";
import { QueryType } from "../../com/QueryType";
/**
 * search通用布局
 */
export function SearchLayer(props: {
    /** 表单 */
    children: JSX.Element | JSX.Element[];
    speedQureyPlaceholder: string;
    /** 查找按钮点击 */
    queryClick: (type: QueryType) => void;
    /**是否显示输入框 */
    notShowInput?: boolean
    /**左边距 */
    left?:Object
}) {
    const left = props.left?props.left:{}
    const { state, dispatch } = useContext<{
        state: ISpeedQuery,
        dispatch: (state: ISpeedQuery) => void;
    }>(SelectStore);
    const [searchState, searchFunc] = useState(false);
    return <Search>
        {!props.notShowInput&&<><Input allowClear placeholder={props.speedQureyPlaceholder} onChange={e => {
            dispatch({
                seacherKey: e.target.value,
            });
        }} />
        <input type="button" value="快速查找" onClick={() => props.queryClick('s')} /></>}
        <TipsGroup>
            <input type="button" value="高级查找" onClick={() => searchFunc(true)} />
            <Tips display={searchState} width="48rem" {...left}>
                <Form layout="horizontal">
                    <div className={styles.form_content}>
                        {props.children}
                    </div>
                </Form>
                <div className={styles.button}>
                    <Button type="primary" danger onClick={() => {
                        props.queryClick('c');
                        searchFunc(false);
                    }}>搜索</Button>
                </div>
            </Tips>
            <Mask visible={searchState} onClick={() => searchFunc(false)} />
        </TipsGroup>
    </Search>
}