import { Form, Row, Col, Input, Select, DatePicker } from "antd";
import { useContext, useEffect, useState } from "react";
import { Select as SelectAntd } from "../../../../component/select";
import { FormSubtitle } from "../../../commodity/view/form_subtitle";
import { formAction } from "../../action/shiftchange/form_action";
import styles from "../../style/form_basic.module.scss";
import { FormStore } from "../../store/shiftChange/form_store";
import moment from "moment";
import { autoRem } from "../../../../global.style";
import { Button } from "../../../../component/button";
export function FormBasic({ disabled, form }) {
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  let height = document.body.clientHeight - 188;
  const { state, dispatch } = useContext(FormStore);
  const { formData } = state;
  const action = formAction;
  const disabledDate = (current) => {
    return current && current > moment().startOf("day");
  };

  return (
    <div style={{ height: height + "px" }} className={styles.root}>
      <div className={styles.content}>
        <FormSubtitle>人员信息</FormSubtitle>
        <div
          style={{
            margin: "" + autoRem(1.9375) + " " + autoRem(1.25) + " 0 0",
          }}
          className="custom-radio"
        >
          <Row gutter={24} style={{ marginTop: 0 }}>
            <Col span={12}>
              <Form.Item
                name="userid"
                label="补班人员"
                labelCol={{ span: 8 }}
                rules={[
                  {
                    required: true,
                    message: "请选择交班人",
                  },
                ]}
              >
                <SelectAntd
                  placeholder="请选择补班人员"
                  disabled={disabled}
                  onChange={(e) => {
                    action.setFormData({ dispatch, key: "userid", value: e });
                  }}
                >
                  {formData.peopleList.map((v) => (
                    <Option value={v.userID}>{v.userName}</Option>
                  ))}
                </SelectAntd>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="begin"
                label="补班开始时间"
                labelCol={{ span: 8 }}
                rules={[
                  {
                    required: true,
                    message: "请选择补班开始时间",
                  },
                  {
                    validator: (_, __, callBack: (error?: string) => void) => {
                      if (
                        moment(form.getFieldValue("begin")).valueOf() >=
                        moment(form.getFieldValue("end")).valueOf()
                      ) {
                        callBack("结束时间不能早于开始时间");
                        return;
                      } else if (
                        new Date(form.getFieldValue("begin")).getDay() !=
                        new Date(form.getFieldValue("end")).getDay()
                      ) {
                        callBack("开始时间和结束时间应在同一天内");
                        return;
                      }
                      callBack();
                    },
                  },
                ]}
              >
                <DatePicker
                  showTime
                  disabledDate={disabledDate}
                  disabled={disabled}
                  showNow={false}
                  // format='YYYY-MM-DD HH:mm:ss'
                  onChange={(date, dateString) => {
                    action.setFormData({
                      dispatch,
                      key: "begin",
                      value: dateString,
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="end"
                label="补班结束时间"
                labelCol={{ span: 8 }}
                rules={[
                  {
                    required: true,
                    message: "请选择补班结束时间",
                  },
                  {
                    validator: (_, __, callBack: (error?: string) => void) => {
                      if (
                        moment(form.getFieldValue("begin")).valueOf() >=
                        moment(form.getFieldValue("end")).valueOf()
                      ) {
                        callBack("结束时间不能早于开始时间");
                        return;
                      } else if (
                        new Date(form.getFieldValue("begin")).getDay() !=
                        new Date(form.getFieldValue("end")).getDay()
                      ) {
                        callBack("开始时间和结束时间应在同一天内");
                        return;
                      }
                      callBack();
                    },
                  },
                  // {

                  // }
                ]}
              >
                <DatePicker
                  showTime
                  disabledDate={disabledDate}
                  showNow={false}
                  // format='YYYY-MM-DD HH:mm:ss'
                  disabled={disabled}
                  onChange={(date, dateString) => {
                    action.setFormData({
                      dispatch,
                      key: "end",
                      value: dateString,
                    });
                    // action.setFormData({ dispatch, key: 'end', value: dateString[1]})
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          {!disabled && (
            <Row>
              <Col>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Button
                    type="primary"
                    color="Red"
                    onClick={async () => {
                      if (disabled) {
                        return;
                      } else {
                        form
                          .validateFields(["userid", "begin", "end"], (err) => {
                            if (err) {
                              return;
                            } else {
                            }
                          })
                          .then((v) => {
                            action.getDetail(state, form, dispatch);
                          });
                      }
                    }}
                  >
                    获取交班数据
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          )}
        </div>
      </div>

      {state.ifInit && (
        <>
          <div>
            <FormSubtitle>交班详细</FormSubtitle>
            <div
              style={{
                margin: "" + autoRem(1.9375) + " " + autoRem(1.25) + " 0 0",
              }}
              className="custom-radio"
            >
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    name="turnover"
                    label="总营业额"
                    labelCol={{ span: 8 }}
                  >
                    <Input allowClear autoComplete="off" disabled />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="orderCount"
                    label="总营业单数"
                    labelCol={{ span: 8 }}
                  >
                    <Input allowClear autoComplete="off" disabled />
                  </Form.Item>
                </Col>
                <Col span={12} style={{ display: "none" }}>
                  <Form.Item
                    name="detail"
                    label="总营业额"
                    labelCol={{ span: 8 }}
                  >
                    <Input allowClear autoComplete="off" disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    name="memberRecharge"
                    label="总储值金额"
                    labelCol={{ span: 8 }}
                  >
                    <Input allowClear autoComplete="off" disabled />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="sales"
                    label="总销售额"
                    labelCol={{ span: 8 }}
                  >
                    <Input allowClear autoComplete="off" disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    name="reserves"
                    label="备用金"
                    labelCol={{ span: 8 }}
                    rules={[
                      {
                        required: true,
                        message: "请输入备用金",
                      },
                      {
                        pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
                        message: "请输入大于等于0的数",
                      },
                    ]}
                  >
                    <Input
                      allowClear
                      autoComplete="off"
                      maxLength={20}
                      disabled={disabled}
                      placeholder="请输入备用金"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </div>
        </>
      )}
      <div>
        {state.detail.map((item) => {
          return (
            <>
              <FormSubtitle>
                收入类型：{item.stype == 1 ? "销售" : "会员储值"}
              </FormSubtitle>
              <div
                style={{
                  margin: "" + autoRem(1.9375) + " " + autoRem(1.25) + " 0 0",
                }}
                className="custom-radio"
              >
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item
                      // name='bankCardMoney'
                      label="银行卡收入"
                      labelCol={{ span: 8 }}
                    >
                      <Input
                        allowClear
                        autoComplete="off"
                        disabled
                        defaultValue={item.bankCardMoney}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      // name='cashMoney'
                      label="现金收入"
                      labelCol={{ span: 8 }}
                    >
                      <Input
                        allowClear
                        autoComplete="off"
                        disabled
                        defaultValue={item.cashMoney}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item
                      // name='wxMoney'
                      label="微信收入"
                      labelCol={{ span: 8 }}
                    >
                      <Input
                        allowClear
                        autoComplete="off"
                        disabled
                        defaultValue={item.wxMoney}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      // name='aliMoney'
                      label="支付宝收入"
                      labelCol={{ span: 8 }}
                    >
                      <Input
                        allowClear
                        autoComplete="off"
                        disabled
                        defaultValue={item.aliMoney}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </>
          );
        })}
      </div>
      {/* </div> */}
    </div>
  );
}
