import { useBoolean, useRequest } from "ahooks";
import { Button, Input, message, Modal, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect, useState } from "react";
import { ButtonPanel } from "../../../component/button-panel/button_panel";
import { DataTable } from "../../../component/table";

import img_edit from "../../../assets/images/bianji.png";
import img_see from "../../../assets/images/chakan1.png";
import img_del from "../../../assets/images/shanchu.png";
import { FileAction, fileAction } from "../action/file_action";
import { FormStoreProvider } from "../store/form_store";
import { Form } from "./form";
import { formAction } from "../action/form_action";
import { DataTableStore } from "../../../component/table/store/data_table_store";
import { FileStore } from "../store/file_store";
import { Search } from "../../../component/search";
import { Tips, TipsGroup } from "../../../component/tips";
import { FileSearch } from "./file_search";
import { ConfirmModal } from "../../../component/modal";
import { Mask } from "../../../component/mask";
import { defualtPageSize, Progress } from "../../../logic/common_logic";
import { AuthLogic } from "../../../logic/auth_logic";
import { Control, See, Edit, Del } from "../../../component/control-column";
const formParams = {
  method: "Create",
  id: "",
  shopClasses: "",
  isdefault: false,
};

export function File(viewPower) {
  const [createState, createFunc] = useBoolean(false);
  const [confirmState, confirmFunc] = useBoolean(false);
  const [searchState, searchFunc] = useBoolean(false);
  const action = fileAction;

  const { state, dispatch } = useContext(FileStore);
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);

  const [menuButton, setData] = useState([]);
  useEffect(() => {
    (async function () {
      let r = await new AuthLogic().selButton(viewPower.viewPower);
      if (r && r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          r[i] = r[i].split("_")[r[i].split("_").length - 1];
        }
      }
      setData(r);
    })();

    return () => {};
  }, []);
  const columns: ColumnsType = [
    {
      title: "操作",
      // dataIndex: 'id',
      key: "1",
      align: "center",
      width: Control.width(3),
      render: Control.render(
        (v) =>
          menuButton.indexOf("VIEW") != -1 && (
            <See
              click={() => {
                formParams.id = v.id;
                formParams.method = "Preview";

                createFunc.setTrue();
              }}
            />
          ),
        (v) =>
          menuButton.indexOf("UPDATE") != -1 && (
            <Edit
              click={() => {
                formParams.id = v.id;
                formParams.method = "Edit";
                formParams.isdefault = v.isdefault;
                createFunc.setTrue();
              }}
            />
          ),
        (v) =>
          menuButton.indexOf("DELETE") != -1 && (
            <Del
              click={() => {
                formParams.id = v.id;
                formParams.shopClasses = v.shopClasses;
                formParams.isdefault = v.isdefault;
                confirmFunc.setTrue();
              }}
            />
          )
      ),
    },
    {
      title: "门店编码",
      dataIndex: "shopCode",
      key: "2",
      align: "center",
      width: 140,
    },
    {
      title: "门店名称",
      dataIndex: "shopName",
      key: "3",
      align: "center",
    },
    {
      title: "门店电话",
      dataIndex: "shopPhone",
      key: "4",
      align: "center",
      width: 100,
    },
    {
      title: "门店类型",
      dataIndex: "shopClasses",
      key: "5",
      align: "center",
      render: (v) => <Tag>{1 === v ? "加盟" : "直营"}</Tag>,
    },
    {
      title: "联系人",
      dataIndex: "linkman",
      key: "6",
      align: "center",
    },
    {
      title: "联系电话",
      dataIndex: "phone",
      key: "7",
      align: "center",
      width: 100,
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "7",
      align: "center",
      render: (v) => (
        <Tag color={1 === v ? "success" : "error"}>
          {1 === v ? "启用" : "停用"}
        </Tag>
      ),
    },
  ];

  const onSearch = async (pageIndex) => {
    await action.loadPage({
      pageIndex: pageIndex,
      pageSize: defualtPageSize(),
      dispatch: tableDispatch,
      search: state.search,
    });
  };

  return (
    <>
      <Search>
        <Input
          maxLength={20}
          allowClear
          placeholder="请输入门店编码、名称、手机号码"
          onChange={(e) =>
            action.setCondition("Keywords", e.target.value, dispatch)
          }
        />
        <input type="button" value="快速查找" onClick={() => onSearch(1)} />

        <TipsGroup>
          <input
            type="button"
            value="高级查找"
            onClick={() => searchFunc.toggle()}
          />
          <Tips width="45rem" display={searchState}>
            <FileSearch
              onSearch={(...arg: [any]) => {
                onSearch(...arg);
                searchFunc.setFalse();
              }}
            />
          </Tips>
          <Mask visible={searchState} onClick={() => searchFunc.setFalse()} />
        </TipsGroup>
      </Search>

      <ButtonPanel>
        {menuButton.indexOf("ADD") != -1 && (
          <Button
            onClick={() => {
              formParams.id = "";
              formParams.method = "Create";
              formParams.isdefault = false;
              createFunc.setTrue();
            }}
          >
            新建档案
          </Button>
        )}
        {menuButton.indexOf("DELETE") != -1 && (
          <Button
            onClick={async () => {
              if (!tableState.selectList.length) {
                message.warning("请选择删除项");
              } else {
                let arr = tableState.selectList.filter(
                  (item) => item.isdefault == true
                );
                if (arr.length > 0) {
                  message.error("选中项中存在主店，不能删除");
                  action.clearSelectedRowKeys({ dispatch: tableDispatch });

                  return false;
                } else {
                  await action.delSelectList({
                    dispatch,
                    selectList: tableState.selectList,
                  });
                  action.clearSelectedRowKeys({ dispatch: tableDispatch });

                  await onSearch(tableState.current);
                }
              }
            }}
          >
            批量删除
          </Button>
        )}
        {menuButton.indexOf("EXPORT") != -1 && <Button>导出</Button>}
      </ButtonPanel>

      <DataTable
        columns={columns}
        action={fileAction}
        onLoad={async () => {
          await onSearch(1);
          Progress.done();
        }}
        onChange={(pageIndex) => {
          onSearch(pageIndex);
        }}
        scroll={{ scrollToFirstRowOnChange: true, autoY: true }}
        selectColumn={true}
        loading={tableState.loading}
      />

      {createState && (
        <FormStoreProvider>
          <Form
            close={async (hasUpdate) => {
              if (hasUpdate) {
                await onSearch(tableState.current);
              }

              createFunc.setFalse();
            }}
            action={formAction}
            id={formParams.id}
            method={formParams.method}
            isdefault={formParams.isdefault}
          />
        </FormStoreProvider>
      )}

      <ConfirmModal
        visible={confirmState}
        title="温馨提示"
        subTitle="确认删除？"
        message="删除之后信息无法恢复，请慎重考虑"
        close={async (r) => {
          if (r) {
            if (formParams.shopClasses == "2") {
              message.error("主门店不能删除");
              confirmFunc.setFalse();
              return false;
            } else {
              await action.delRow(formParams.id);
              onSearch(tableState.current);
            }
          }
          confirmFunc.setFalse();
        }}
      />
    </>
  );
}
