/**
 * tipic类型
 */
export enum ETopicType {
  advimg = "0",
  editor = '1',
  navimg ='2',
  adbannerone = '3',
  search = '4',
  goods = '5',
  flashsale = '6',
  shopwindow = '7',
  textlink = '8',
  divider = '9',
  blank = '10',
  video = '11',
  /** 热图 */
  hots = '12',
  /**预售 */
  presale = '13'
}