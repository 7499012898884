import { useBoolean, useRequest } from "ahooks";
import { Input, Modal, Select, Tag,Button } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect } from "react";
import { Search } from "../search";
import { DataTable } from "../table";
import { DataTableStore, DataTableStoreProvider } from "../table/store/data_table_store";
import { ChooseCouponStore, ChooseStoreProvider } from "./store/choose_store";
import styles from "./style/index.module.scss";
import { Select as SelectAntd } from "antd";
import { chooseCouponAction } from "./action/choose_action";
import { defualtPageSize } from "../../logic/common_logic";
import { user } from "../variable";
let classId = "";

function CouponExchange(params: IChooseCouponExchange) {
    const { visibleModal, close, type ,id} = params;
    const { Option } = Select, 
    action = chooseCouponAction;

    const { state, dispatch } = useContext(ChooseCouponStore);
    const { state: tableState, dispatch: tableDispatch } = useContext(DataTableStore);

    const handleOk = () => {
        close && close(true, tableState.selectList);
    };

    const handleCancel = () => {

        close && close(false, null);
    };
    const cloumnsDetail: ColumnsType = [
        {
            title:'会员姓名',
            key:'1',
            align:'center',
            dataIndex:'memberName'
        },
        {
            title:'手机号码',
            key:'2',
            align:'center',
            dataIndex:'phone'
           },
        {
            title:'优惠券名称',
            key:'3',
            align:'center',
            dataIndex:'rightsType',        

           },
           {
            title:'领取途径',
            key:'4',
            align:'center',
            dataIndex:'phone'
           },
           {
            title:'领取时间',
            key:'5',
            align:'center',
            dataIndex:'phone'
           },          
           {
            title:'使用时间',
            key:'6',
            align:'center',
            dataIndex:'giveDateTime',
           },
           {
            title:'使用门店',
            key:'7',
            align:'center',
            dataIndex:'giveDateTime',
           },
           {
            title:'使用单号',
            key:'8',
            align:'center',
            dataIndex:'giveDateTime',
           }
     ]
    let tableRowSelection: any = {};
   if(type =='radio'){
    tableRowSelection = {
        rowSelection: {
            type: "radio",
        }}
   }

    // useRequest(() => action.init({ dispatch }));
    const onSearch = async (pageIndex) => {
        await action.loadPage({ pageIndex: pageIndex, pageSize: defualtPageSize(), dispatch: tableDispatch, search: state.search ,id})
    }

    useEffect(()=>{
    })
    return (      
      

<Modal title="活动明细" visible={visibleModal}  onCancel={handleCancel} width={1000}  footer={  <Button type="primary" color="Red" onClick={handleCancel}>返回</Button>}>

<div className={styles.root}>
    {/* <Search>
      
        <Input placeholder="请输入活动名称" 
         onChange={e => action.setCondition({ dispatch, key: "seacherKey", value: e.target.value ,CouponExchangeId})}
        />
        <input type="button" value="快速查找" 
        onClick={() => onSearch(1)} 
        />
    </Search> */}

    <div style={{ marginTop: 10}}>
        
            <DataTable columns={cloumnsDetail} action={action} {...tableRowSelection}
                onLoad={() => onSearch(1)}
                onChange={(pageIndex) => { onSearch(pageIndex) }}
                // selectColumn={true}
            />
    </div>
</div>
</Modal>
    )
}


interface IChooseCouponExchange {
    visibleModal: boolean;
    type?:string;
    close?: (status, r) => void;
    id:string
}
export function ChooseCouponExchange(params: IChooseCouponExchange) {
    return (
        <ChooseStoreProvider>
            <DataTableStoreProvider>
                <CouponExchange {...params} />
            </DataTableStoreProvider>
        </ChooseStoreProvider>
    )
}