import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
  TimePicker,
  DatePicker,
  Tag,
  message,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { FormSubtitle } from "../../../commodity/view/form_subtitle";
import styles from "../../style/inventory_goods_formbasic.module.scss";

import { useBoolean } from "ahooks";
import { ChooseGoods } from "../../../../component/choose-goods";
import { ChooseShop } from "../../../../component/choose-shop";
import { formAction } from "../../action/promotion/form_action";
import { FormStore } from "../../store/promotion/form_store";
import { autoRem } from "../../../../global.style";
import { useState } from "react";
import { ChooseClassify } from "../../../../component/choose-classify";
import { RadioStyle } from "../../../../component/radio-style";
import { start } from "repl";
import moment from "moment";
import { Table } from "../../../../component/table";
import img_del from "../../../../assets/images/shanchu.png";
import { NumberTool } from "../../../../tool/numberTool";
let dayList = [];

const { Option } = Select;
const { RangePicker } = DatePicker;

export function FormBasic({ title, disabled, updateFormField, isEdit }) {
  /** 是否选择店铺 */
  const [shopState, shopFunc] = useBoolean(false);
  /** 是否选择商品 */
  const [goodsState, goodsFunc] = useBoolean(false);

  /** 表单状态 */
  const { state, dispatch } = useContext(FormStore);
  /** 表单 */
  const { formData } = state;
  /** 表单action */
  const action = formAction;

  const onChangeCheck = (e) => {
    if (!disabled) {
      action.setFormData({
        dispatch,
        key: "isMemberClass",
        value: e.target.checked ? 1 : 2,
      });
      if (e.target.checked) {
        action.setFormData({ dispatch, key: "classList", value: [] });
      }
    }
  };
  const handleChange = (value, option) => {
    let arr = [];
    let arrayId = [];
    for (let i = 0; i < option.length; i++) {
      let obj = {};
      obj["memberClassID"] = option[i].key;
      arrayId.push(option[i].key);
      arr.push(obj);
    }
    action.setFormData({ dispatch, key: "memberClassList", value: arr });
    action.setFormData({ dispatch, key: "classList", value: arrayId });
  };
  useEffect(() => {
    return () => {};
  });
  /**等级验证 */
  let checkGrade =
    formData.isMemberClass == 1
      ? {}
      : {
          require: true,
          validator: (_, __, callback: (error?: string) => void) => {
            if (
              formData.classList == undefined ||
              formData.classList.length == 0
            ) {
              callback("请选择会员等级");
              return;
            }
            callback();
          },
        };

  let height = document.body.clientHeight - 188;
  return (
    <div className={styles.root} style={{ height: height + "px" }}>
      <div className={styles.content}>
        <FormSubtitle>基础信息</FormSubtitle>
        <Row gutter={16} style={{ marginTop: 0 }}>
          <Col span={24}>
            <Form.Item
              label="活动名称"
              labelCol={{ span: 3 }}
              name="activityName"
              rules={[
                {
                  required: true,
                  validator: (_, __, callBack: (error?: string) => void) => {
                    if (!formData.activityName) {
                      callBack("请输入活动名称");
                      return;
                    }
                    callBack();
                  },
                },
              ]}
            >
              {formData.activityName ? "" : ""}
              <Input
                style={{ width: "400px" }}
                maxLength={15}
                disabled={disabled}
                value={formData.activityName}
                autoComplete="off"
                placeholder="请输入"
                onChange={(e) => {
                  updateFormField("activityName");
                  action.setFormData({
                    dispatch,
                    key: "activityName",
                    value: e.target.value,
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 0 }}>
          <Col span={24}>
            <Form.Item
              label="分配门店"
              labelCol={{ span: 3 }}
              name="shopName"
              rules={[
                {
                  required: true,
                  validator: (_, __, callBack: (error?: string) => void) => {
                    if (
                      state.formData.shopList == undefined ||
                      state.formData.shopList.length == 0
                    ) {
                      callBack("请选择分配门店");
                      return;
                    }
                    callBack();
                  },
                },
              ]}
            >
              {formData.shopName ? "" : ""}
              <Input
                style={{ width: "400px" }}
                disabled={disabled}
                value={formData.shopName}
                autoComplete="off"
                placeholder="请选择门店"
                onClick={() => shopFunc.setTrue()}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 0 }}>
          <Col span={24}>
            <Form.Item
              labelCol={{ span: 3 }}
              label={<span className={styles.alarm}>会员等级</span>}
              rules={[checkGrade]}
            >
              {" "}
              {formData.isMemberClass ? "" : ""}
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Checkbox
                  checked={formData.isMemberClass == 1 ? true : false}
                  onChange={onChangeCheck}
                >
                  不限制
                </Checkbox>
                <Form.Item name="classList">
                  {formData.classList ? "" : ""}
                  <Select
                    disabled={
                      disabled || formData.isMemberClass == 1 ? true : false
                    }
                    mode="multiple"
                    style={{ width: "400px", marginTop: "10px" }}
                    value={formData.classList}
                    placeholder="请选择"
                    onChange={handleChange}
                  >
                    {state.TagList.map((v) => (
                      <Option value={v.id} key={v.id}>
                        {v.gradeName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 0 }}>
          <Col span={24}>
            <Form.Item
              label={<span className={styles.alarm}>活动时间</span>}
              labelCol={{ span: 3 }}
              rules={[{ required: true }]}
              className={"custom-radio"}
            >
              <Time disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 0 }}>
          <Col span={24}>
            <Form.Item
              label={<span className={styles.alarm}>活动时段</span>}
              labelCol={{ span: 3 }}
              // rules={[{ required: true }]}
              className={"custom-radio"}
            >
              {formData.PeriodTimeList ? [] : []}
              <PeriodTime disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 0 }}>
          <Col span={24}>
            <Form.Item
              labelCol={{ span: 3 }}
              label={<span className={styles.alarm}>使用途径</span>}
              className={"custom-radio"}
            >
              <ApplicableWay disabled={disabled} isEdit={isEdit} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 0 }}>
          <Col span={24}>
            <Form.Item
              labelCol={{ span: 3 }}
              label={<span className={styles.alarm}>活动明细</span>}
              className={"custom-radio"}
            >
              <ActivitySubsidiary disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>
        <div
          style={{
            marginTop: 10,
          }}
        >
          {formData.activitySubsidiary == 3 && (
            <DataTable dataList={state.tableList} disabled={disabled} />
          )}
          {formData.activitySubsidiary == 2 && (
            <DataTableclassity
              dataList={state.classifyList}
              disabled={disabled}
            />
          )}
        </div>
      </div>
      <ChooseShop
        visibleModal={shopState}
        type='checkbox'
        close={(status, r) => {
          if (r) {
            let arr = [];
            let shopNameArr = []
            if (r.length > 0) {
              r.map((item) => {
                arr.push(item.id);
                shopNameArr.push(item.shopName)
              });
            }
            action.setFormData({ dispatch, key: "shopList", value: arr });
            action.setFormData({
              dispatch,
              key: "shopName",
              value: shopNameArr.toString(),
            });
          }
          updateFormField("shopName");
          shopFunc.setFalse();
        }}
      />
    </div>
  );
}

function WeekType({ disabled, value, swtichValue }) {
  /** 表单状态 */
  const { state, dispatch } = useContext(FormStore);
  /** 表单 */
  const { formData } = state;

  /** 表单action */
  const action = formAction;

  const onChange = (e) => {
    if (!disabled) {
      action.setFormData({ dispatch, key: "useType", value: e.target.value });
    }
    if (e.target.value == 2) {
      action.setFormData({ dispatch, key: "useTypeValue", value: [] });
    }
    if (e.target.value == 1) {
      // action.setFormData({ dispatch, key: "useweekTypeValue", value: [] })
    }
  };
  return (
    <RadioStyle>
      <Radio.Group
        onChange={onChange}
        value={swtichValue}
        style={{ marginTop: "10px" }}
      >
        <Radio
          value={1}
          style={{ marginLeft: "15px", height: "30px", lineHeight: "30px" }}
        >
          按固定星期
        </Radio>
        <Radio
          value={2}
          style={{ marginLeft: "10px", height: "30px", lineHeight: "30px" }}
        >
          按固定日期
        </Radio>
      </Radio.Group>
      {formData.useType == 2 ? (
        <div style={{ marginLeft: "15px", marginTop: "10px" }}>
          固定日期 （默认不选表示不限制日期，可以多选）
        </div>
      ) : (
        <div style={{ marginLeft: "15px", marginTop: "10px" }}>固定星期</div>
      )}
    </RadioStyle>
  );
}

/** 活动时间 */
function Time({ disabled }) {
  /** 表单状态 */
  const { state, dispatch } = useContext(FormStore);
  /** 表单 */
  const { formData } = state;

  /** 表单action */
  const action = formAction;

  // 日期段选择
  const onangeChange = (time, timeString) => {
    action.setFormData({ dispatch, key: "beginDate", value: timeString[0] });
    action.setFormData({ dispatch, key: "endDate", value: timeString[1] });
    action.setFormData({ dispatch, key: "useTypeValue", value: "" });
    setDays();
  };
  const disabledDate = (current) => {
    return current < moment().startOf("day");
  };
  const onChangeCheckbox = (e) => {
    if (formData.useType != 2) {
      action.setFormData({ dispatch, key: "useweekTypeValue", value: e });
      action.setFormData({ dispatch, key: "useTypeValue", value: e });
    }
  };
  const selectDetaChange = (e) => {
    if (formData.useType == 2) {
      action.setFormData({ dispatch, key: "useTypeValue", value: e });
    }
  };

  function setDays() {
    let arr = [];
    let date1 = formData.beginDate;
    let date2 = formData.endDate;
    let monthBegin = new Date(date1).getMonth();
    let monthEnd = new Date(date2).getMonth();
    let dayBegin = new Date(date1).getDate();
    let dayEnd = new Date(date2).getDate();
    dayList = [];
    if (monthBegin != null && monthEnd != null && monthBegin == monthEnd) {
      for (let i = dayBegin; i <= dayEnd; i++) {
        dayList.push({ value: `${i}`, name: `${i}日` });
        arr.push({ value: `${i}`, name: `${i}日` });
      }
    } else {
      for (let i = 0; i < 31; i++) {
        dayList.push({ value: `${i + 1}`, name: `${i + 1}日` });
        arr.push({ value: `${i + 1}`, name: `${i + 1}日` });
      }
    }
    return arr;
  }
  useEffect(() => {
    setDays();
  }, [formData.beginDate, formData.endDate]);
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span>开始日期 - 结束日期</span>
      {formData.beginDate == "" || formData.beginDate == null ? (
        <Form.Item
          name="beginDate,endDate"
          rules={[
            {
              required: true,
              message: "请选择日期",
            },
          ]}
        >
          <RangePicker
            disabled={disabled}
            disabledDate={disabledDate}
            style={{ width: "400px", marginTop: "10px" }}
            onChange={onangeChange}
          />{" "}
        </Form.Item>
      ) : (
        <RangePicker
          disabled={disabled}
          disabledDate={disabledDate}
          style={{ width: "400px", marginTop: "10px" }}
          onChange={onangeChange}
          defaultValue={[
            moment(formData.beginDate, "YYYY-MM-DD"),
            moment(formData.endDate, "YYYY-MM-DD"),
          ]}
        />
      )}
      <WeekType
        disabled={disabled}
        value={1}
        swtichValue={formData.useType ? formData.useType : 1}
      />
      {formData.useType == 2 ? (
        <Select
          disabled={disabled}
          mode="tags"
          style={{ width: "400px", marginTop: "10px" }}
          placeholder="请选择"
          onChange={selectDetaChange}
          value={
            Array.isArray(formData.useTypeValue) ? formData.useTypeValue : []
          }
        >
          {setDays().map((item, index) => {
            return (
              <Option value={item.value} key={index}>
                {item.name}
              </Option>
            );
          })}
        </Select>
      ) : (
        <Checkbox.Group
          disabled={disabled}
          style={{ width: "100%", marginTop: "10px" }}
          value={
            Array.isArray(formData.useweekTypeValue)
              ? formData.useweekTypeValue
              : []
          }
          onChange={onChangeCheckbox}
        >
          <Row>
            <Col
              span={4}
              style={{
                marginRight: "6px",
                border: "1px solid #eee",
                textAlign: "center",
                height: "35px",
                lineHeight: "35px",
              }}
            >
              <Checkbox value="1">星期一</Checkbox>
            </Col>
            <Col
              span={4}
              style={{
                marginRight: "6px",
                border: "1px solid #eee",
                textAlign: "center",
                height: "35px",
                lineHeight: "35px",
              }}
            >
              <Checkbox value="2">星期二</Checkbox>
            </Col>
            <Col
              span={4}
              style={{
                marginRight: "6px",
                border: "1px solid #eee",
                textAlign: "center",
                height: "35px",
                lineHeight: "35px",
              }}
            >
              <Checkbox value="3">星期三</Checkbox>
            </Col>
            <Col
              span={4}
              style={{
                marginRight: "6px",
                border: "1px solid #eee",
                textAlign: "center",
                height: "35px",
                lineHeight: "35px",
              }}
            >
              <Checkbox value="4">星期四</Checkbox>
            </Col>
            <Col
              span={4}
              style={{
                marginRight: "6px",
                border: "1px solid #eee",
                textAlign: "center",
                height: "35px",
                lineHeight: "35px",
              }}
            >
              <Checkbox value="5">星期五</Checkbox>
            </Col>
            <Col
              span={4}
              style={{
                marginRight: "6px",
                border: "1px solid #eee",
                textAlign: "center",
                height: "35px",
                lineHeight: "35px",
              }}
            >
              <Checkbox value="6">星期六</Checkbox>
            </Col>
            <Col
              span={4}
              style={{
                marginRight: "6px",
                border: "1px solid #eee",
                textAlign: "center",
                height: "35px",
                lineHeight: "35px",
              }}
            >
              <Checkbox value="7">星期日</Checkbox>
            </Col>
          </Row>
        </Checkbox.Group>
      )}
    </div>
  );
}

/** 活动时段 */
function PeriodTime({ disabled }) {
  /** 表单状态 */
  const { state, dispatch } = useContext(FormStore);
  /** 表单 */
  const { formData } = state;

  /** 表单action */
  const action = formAction;

  useEffect(() => {
    if (formData.PeriodTimeList.length == 0) {
      //默认添加两个时间段
      action.AddPeriodTime({ dispatch, length: 2 });
    }
    //    return()=>{
    //         action.AddPeriodTime({ dispatch, length: 0});
    //    }
  }, []);
  /**设置不可选择时间段 */
  const range = (start, end) => {
    const result = [];

    for (let i = start; i < end; i++) {
      result.push(i);
    }

    return result;
  };
  const disabledDateTime = () => {
    let hours = moment().hours(); //0~23

    let minutes = moment().minutes(); //0~59

    let seconds = moment().seconds(); //0~59
    let time;

    //当日只能选择当前时间之后的时间点
    if (formData.beginDate && moment(formData.endDate) == moment()) {
      time = {
        disabledHours: () => range(0, hours),

        disabledMinutes: () => range(0, minutes),

        disabledSeconds: () => range(0, seconds + 1),
      };
      return time;
    }
  };

  const selectTime = (timeString, item, key, index) => {
    let _time = timeString;
    //console.log('选择时间', _time);
    //
    item[key] = _time;
    action.UpdatePeriodTime({ dispatch, index });
  };
  const byStringGetMoment = (string) => {
    if (!string) {
      return undefined;
    }
    return moment(string, "hh:mm:ss");
  };

  return (
    <div>
      {formData.PeriodTimeList.map((item, index) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: "10px",
              verticalAlign: "top",
            }}
            key={index}
          >
            <span>第{index + 1}时间段</span>

            <TimePicker
              disabled={disabled}
              disabledMinutes={() => {
                let h = disabledDateTime();
                if (h) return h.disabledMinutes();
              }}
              disabledSeconds={() => {
                let h = disabledDateTime();
                if (h) return h.disabledSeconds();
              }}
              disabledHours={() => {
                let h = disabledDateTime();
                if (h) return h.disabledHours();
              }}
              hideDisabledOptions={true}
              value={byStringGetMoment(item.beginTime)}
              style={{ width: "120px", margin: "2px 10px" }}
              onChange={(time, timeString) => {
                selectTime(timeString, item, "beginTime", index);
              }}
            />
            <span>至</span>

            <TimePicker
              disabled={disabled}
              hideDisabledOptions={true}
              disabledMinutes={() => {
                let h = disabledDateTime();
                if (h) return h.disabledMinutes();
              }}
              disabledSeconds={() => {
                let h = disabledDateTime();
                if (h) return h.disabledSeconds();
              }}
              disabledHours={() => {
                let h = disabledDateTime();
                if (h) return h.disabledHours();
              }}
              value={byStringGetMoment(item.endTime)}
              style={{ width: "120px", margin: "2px 10px" }}
              onChange={(time, timeString) => {
                selectTime(timeString, item, "endTime", index);
              }}
            />
            <span>折扣</span>

            <Input
              style={{ width: "100px", margin: "2px 10px" }}
              value={item.markProportion}
              disabled={disabled}
              onChange={(e) => {
                item.markProportion =
                  e.target.value &&
                  NumberTool.setRange(e.target.value, [1, 99]);
                action.UpdatePeriodTime({ dispatch, index });
              }}
            />
            <span>%（折扣请输入1~99）</span>
            {index >= 1 ? (
              <div
                style={{
                  fontSize: "10px",
                  height: "30px",
                  textAlign: "center",
                  lineHeight: "30px",
                  background: "#ff746d",
                  color: "#fff",
                  cursor: "pointer",
                  padding: "0 5px",
                }}
                onClick={() => {
                  if (disabled) {
                    return;
                  }
                  if (formData.PeriodTimeList.length >= 10) {
                    message.error("最多添加十个");
                    return;
                  }
                  action.AddPeriodTime({ dispatch, length: 1 });
                }}
              >
                添加时间段
              </div>
            ) : (
              ""
            )}
            {index >= 2 ? (
              <div
                style={{
                  fontSize: "10px",
                  height: "30px",
                  textAlign: "center",
                  lineHeight: "30px",
                  background: "#ff746d",
                  color: "#fff",
                  cursor: "pointer",
                  padding: "0 5px",
                  marginLeft: 10,
                }}
                onClick={() => {
                  if (disabled) {
                    return;
                  }
                  action.DelPeriodTime({
                    dispatch,
                    id: index,
                  });
                }}
              >
                删除时间段
              </div>
            ) : (
              ""
            )}
          </div>
        );
      })}
    </div>
  );
}

/** 使用途径 */
function ApplicableWay({ disabled, isEdit }) {
  /** 表单状态 */
  const { state, dispatch } = useContext(FormStore);
  /** 表单 */
  const { formData } = state;

  /** 表单action */
  const action = formAction;

  const onchannelChange = (e) => {
    if (!disabled && !isEdit) {
      action.setFormData({
        dispatch,
        key: "purposeType",
        value: e.target.value,
      });
    }
  };

  return (
    <RadioStyle>
      <Radio.Group onChange={onchannelChange} value={formData.purposeType}>
        <Radio
          value={1}
          style={{ marginLeft: "15px", height: "30px", lineHeight: "30px" }}
        >
          门店收银
        </Radio>
        <Radio
          value={2}
          style={{ marginLeft: "10px", height: "30px", lineHeight: "30px" }}
        >
          线上商城
        </Radio>
        <Radio
          value={3}
          style={{ marginLeft: "10px", height: "30px", lineHeight: "30px" }}
        >
          全部
        </Radio>
      </Radio.Group>
    </RadioStyle>
  );
}

/** 活动明细 */
function ActivitySubsidiary({ disabled }) {
  const [goodsState, goodsFunc] = useBoolean(false);
  const [classifyState, classifyFunc] = useBoolean(false);
  /** 表单状态 */
  const { state, dispatch } = useContext(FormStore);
  /** 表单 */
  const { formData } = state;

  /** 表单action */
  const action = formAction;

  const onchannelChange = (e) => {
    if (!disabled) {
      action.setFormData({
        dispatch,
        key: "activitySubsidiary",
        value: e.target.value,
      });
      action.clearTableList({ dispatch });
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <RadioStyle>
        <Radio.Group
          onChange={onchannelChange}
          value={formData.activitySubsidiary}
          style={{ display: "flex", justifyContent: "start" }}
        >
          <Radio
            value={1}
            style={{ marginLeft: "15px", height: "30px", lineHeight: "30px" }}
          >
            全场商品
          </Radio>
          <Radio
            value={2}
            style={{ marginLeft: "10px", height: "30px", lineHeight: "30px" }}
          >
            指定分类
          </Radio>
          <Radio
            value={3}
            style={{ marginLeft: "10px", height: "30px", lineHeight: "30px" }}
          >
            指定商品
          </Radio>
          {formData.activitySubsidiary == 3 && (
            <div
              style={{
                fontSize: "10px",
                width: "60px",
                height: "30px",
                textAlign: "center",
                lineHeight: "30px",
                background: "#ff746d",
                color: "#fff",
                cursor: "pointer",
              }}
              onClick={() => !disabled && goodsFunc.setTrue()}
            >
              添加商品
            </div>
          )}
          {formData.activitySubsidiary == 2 && (
            <div
              style={{
                fontSize: "10px",
                width: "60px",
                height: "30px",
                textAlign: "center",
                lineHeight: "30px",
                background: "#ff746d",
                color: "#fff",
                cursor: "pointer",
              }}
              onClick={() => !disabled && classifyFunc.setTrue()}
            >
              添加分类
            </div>
          )}
        </Radio.Group>
      </RadioStyle>

      <ChooseGoods
        visibleModal={goodsState}
        key={formData.purposeType ? formData.purposeType : 1}
        shopID={
          formData.shopList &&
          formData.shopList.length > 0 &&
          formData.shopList[0]
        }
        filter={{
          isMarketing: true,
          specifications: true,
          source:
            formData.purposeType == 1
              ? "CS"
              : formData.purposeType == 2
              ? "WX"
              : formData.purposeType == 3
              ? "PC"
              : "CS",
        }}
        close={(status, r) => {
          if (r) {
            action.addTableItem({ dispatch, list: r });
          }
          goodsFunc.setFalse();
        }}
      />
      <ChooseClassify
        visibleModal={classifyState}
        close={(status, r) => {
          if (r) {
            // console.log(r)
            action.AddclassTableItem({ dispatch, list: r });
          }
          classifyFunc.setFalse();
        }}
      />
    </div>
  );
}

/** 商品列表 */
export function DataTable({ dataList, disabled }) {
  /** 表单状态 */
  const { state, dispatch } = useContext(FormStore);
  /** 表单 */
  const { formData } = state;

  /** 表单action */
  const action = formAction;
  const columns: ColumnsType = [
    {
      title: "操作",
      dataIndex: "serial",
      key: "1",
      align: "center",
      width: 100,
      render: (v, r, i) => (
        <div>
          <img
            src={img_del}
            style={{ width: 20 }}
            alt=""
            onClick={() => {
              !disabled && action.delTableItem({ dispatch, index: i });
            }}
          />
        </div>
      ),
    },
    {
      title: "商品编码",
      dataIndex: "goodsNo",
      key: "2",
      align: "center",
      width: 100,
    },
    {
      title: "商品名称",
      dataIndex: "goodsName",
      key: "3",
      align: "center",
      width: 100,
    },
    {
      title: "商品分类",
      dataIndex: "goodsClassName",
      key: "4",
      align: "center",
      width: 100,
    },
    {
      title: "单位",
      dataIndex: "unitName",
      key: "8",
      align: "center",
      width: 120,
    },
    {
      title: "门店价",
      dataIndex: "shopPrice",
      key: "8",
      align: "center",
      width: 120,
    },
    {
      title: "商城价",
      dataIndex: "storePrice",
      key: "8",
      align: "center",
      width: 120,
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "8",
      align: "center",
      width: 120,
      render: (v) => (
        <>
          {v == 1 && <Tag color="success">正常</Tag>}
          {v == 2 && <Tag color="error">停售</Tag>}
        </>
      ),
    },
  ];

  return <Table dataSource={dataList} columns={columns} />;
}

/** 分类列表 */
export function DataTableclassity({ dataList, disabled }) {
  /** 表单状态 */
  const { state, dispatch } = useContext(FormStore);
  /** 表单 */
  const { formData } = state;

  /** 表单action */
  const action = formAction;

  const columns: ColumnsType = [
    {
      title: "操作",
      dataIndex: "serial",
      key: "1",
      align: "center",
      width: 100,
      render: (v, r, i) => (
        <div>
          <img
            src={img_del}
            style={{ width: 20 }}
            alt=""
            onClick={() => {
              !disabled && action.delTableclassItem({ dispatch, index: i });
            }}
          />
        </div>
      ),
    },
    {
      title: "商品分类",
      dataIndex: "title",
      key: "2",
      align: "center",
    },
  ];
  return <Table dataSource={dataList} columns={columns} />;
}
