import { createContext, useReducer } from "react";
import { defualtPageSize } from "../../../logic/common_logic";

export const DataTableStore = createContext<IContextProps>(null);

interface IContextProps {
    state: IState;
    dispatch: ({ type }: Dispatch) => void;
}

type Dispatch = { type, payload }

interface IState {
    dataList: any[],
    selectList: any[];
    pageSize: number;
    current: number;
    total: number;
    selectedRowKeys: string[];
    loading: boolean
}

const initialArgs: IState = {
    dataList: [],
    selectList: [],
    pageSize: defualtPageSize(),
    current: 1,
    total: 0,
    selectedRowKeys: [],
    loading: false
}

function reducer(state: IState, { type, payload }: Dispatch) {
    switch (type) {
        case "Set_SelectList":
            // //console.log(payload)
            return {
                ...state,
                selectList: [...payload]
            }
        case "Clear_SelectedRowKeys":
            return {
                ...state,
                selectedRowKeys: [],
                selectList:[]
            }
        case "Set_SelectedRowKeys":
            // //console.log(payload)
            return {
                ...state,
                selectedRowKeys: [...payload]
            }
        case "Set_Total":
            return {
                ...state,
                total: payload
            }
        case "Set_DataList":
            return {
                ...state,
                dataList: payload
            }
        case "Set_Current":
            return {
                ...state,
                current: payload
            }
        case "Set_PageSize":
            return {
                ...state,
                pageSize: payload
            }
        case 'Loading' :
            return {
                ...state,
                loading: payload
            }
        default:
            return state;
    }
}

export function DataTableStoreProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialArgs);

    const v = { state, dispatch };
    return <DataTableStore.Provider value={v}>{props.children}</DataTableStore.Provider>
}