import React, { useContext, useEffect } from "react";
import { Button } from "../../../../component/button";
import { Layer } from "../../../../component/layer/layer";
import { Form as FormAntd, message } from "antd";
import { formAction, FormAction } from "../../action/dossier/form_action";
import { fileAction } from "../../action/dossier/file_action";
import { FormStore } from "../../store/dossier/form_store";
import { FormBasic } from "./form_basic";
import { FileStore } from "../../store/dossier/file_store";
import { DataTableStore } from "../../../../component/table/store/data_table_store";
import { defualtPageSize } from "../../../../logic/common_logic";
import { useBoolean } from "ahooks";
interface IForm {
  children?: any;
  action: FormAction;
  close: (status: boolean) => void;
  editId?: string;
  seeId?: string;
}
export function Form({ close, action, editId, seeId }: IForm) {
  const [form] = FormAntd.useForm();

  const { state, dispatch: formDispatch } = useContext(FormStore);

  const { state: fileState, dispatch: fileDispatch } = useContext(FileStore);
  const [loading, loadFunc] = useBoolean(false)
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);

  useEffect(() => {
    formAction.init({
      state: state,
      dispatch: formDispatch,
      editId: editId,
      seeId,
      form,
    });

    return () => {};
  }, []);

  function title() {
    if (editId) {
      return "编辑档案";
    } else if (seeId) {
      return "查看档案";
    } else {
      return "新建档案";
    }
  }
  const onSearch = async (pageIndex) => {
    await fileAction.loadPage({
      pageIndex: pageIndex,
      pageSize: defualtPageSize(),
      dispatch: tableDispatch,
      search: fileState.search,
    });
  };
  return (
    <FormAntd form={form}>
      <Layer layerWith={2} >
        <Layer.Title onClick={() => close(false)}>{title()}</Layer.Title>
        <Layer.Body>
          <FormBasic
            title={title()}
            disabled={title() == "查看档案"}
            form={form} 
            updateFormField={(field, value = '') => {
              //为表单某个字段设置数据
              form.setFieldsValue({
                  [field]: value,
              });
              //验证表单的某个字段
              form.validateFields([field]);
          }}
          />
        </Layer.Body>
        <Layer.Foot
          borderStyle={title() == "查看档案" ? { border: "none" } : {}}
        >
          {title() != "查看档案" ? (
            <>
              <Button
                type="primary"
                color="Red"
                style={{ marginRight: "1.25rem" }}
                onClick={async () => {
                  const r = await form.validateFields();
                  if(loading){
                    message.loading('正在提交数据')
                    return false
                }
                  let status = false;
                  if (editId) {
                    loadFunc.setTrue()
                    status = await action.updSubmit({
                      state: state.formData,
                      form,
                    });
                    loadFunc.setFalse()
                  } else {
                    loadFunc.setTrue()
                    status = await action.addSubmit({
                      state: state.formData,
                      form,
                    });
                    loadFunc.setFalse()
                  }
                  status && close && close(true);
                }}
              >
                保存
              </Button>
              <Button
                type="primary"
                color="Yellow"
                onClick={() => action.reset({ form, dispatch: formDispatch })}
              >
                重置
              </Button>
            </>
          ) : null}
        </Layer.Foot>
      </Layer>
    </FormAntd>
  );
}
