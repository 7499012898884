type Init = {
    state;
    dispatch;
    form;
    id: string;
    method: string;
}
interface InitBasic {
    dispatch;
    // search;
}
interface InitEdit {
    state;
    dispatch;
    form;
    id;
}
class FormAction {
    async init({ state, dispatch, id, method, form }: Init) {
        await this.initBasic({dispatch})
        if(method == 'Create' && id){
            await this.initEdit({dispatch, id, form, state})
        }
    }
    async initBasic({dispatch}: InitBasic){
        this.clearTableList({dispatch})
    }
    async initEdit({dispatch, id}: InitEdit){

    }
    private async paddingData(formData){}
    async addSubmit({dispatch, state}){
        return true
    }
    async updSubmit({dispatch, state, id}){
        return true
    }
    // 表单
    addTableItem({ dispatch, list }) {
        list.length > 0 &&  list.forEach(async goods => {
            dispatch({ type: "AddTableItem", payload: goods });
        })
    }
    delTableItem(dispatch, id) {
        dispatch({ type: "DelTableItem", payload: id });
    }
    setFormData({ dispatch, key, value }) {
        const params = {
        }
        params[key] = value;

        dispatch({ type: "SetFormData", payload: params })
    }
   
    changeTableItem({ dispatch, key, value, index }) {
        dispatch({ type: "ChangeTableItem", payload: { key, value, index } });
    }
    clearTableList({ dispatch }) {
        dispatch({ type: "ClearTableList" });
    }

}
export const formAction = new FormAction()
