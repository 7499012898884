import {
  Cascader,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Form as FormAntd,
  Input,
  Radio,
  Row,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { RadioStyle } from "../../../../component/radio-style";
import {
  Tab,
  TabList,
  TabPanel,
  TabsStyle,
} from "../../../../component/react-tabs";
import { autoRem } from "../../../../global.style";
import { formAction } from "../../action/list/list_form_action";
import { ListFormStore } from "../../store/list/list_form_store";

function Type({ defaultValue }) {
  const { state, dispatch } = useContext(ListFormStore);

  const onChange = (e) => {
    formAction.setValue({ dispatch, value: e.target.value, attribute: "Sex" });
  };
  useEffect(() => {
    formAction.setValue({ dispatch, value: defaultValue, attribute: "Sex" });
  }, []);

  return (
    <RadioStyle>
      <Radio.Group onChange={onChange} value={defaultValue}>
        <Radio value={1}>男</Radio>
        <Radio value={2}>女</Radio>
      </Radio.Group>
    </RadioStyle>
  );
}
interface IForm {
  passwordId?: string;
}
export function PswFormBasic({ passwordId }: IForm) {
  const { state, dispatch: formDispatch } = useContext(ListFormStore);
  const action = formAction;
  const { formData } = state;
  return (
    passwordId && (
      <>
        <TabsStyle style="Capsule">
          <TabPanel>
            <div style={{ margin: "1.9375rem 0 0 0" }}>
              <Row gutter={16}>
                <Col span={16}>
                  <FormAntd.Item
                    label="新密码"
                    labelCol={{ span: 6 }}
                    rules={[
                      {
                        required: true,
                        message: "请输入新密码",
                      },
                    ]}
                  >
                    <Input
                      autoComplete="off"
                      maxLength={20}
                      placeholder="请输入新密码"
                      onChange={(v) =>
                        action.setValue({
                          dispatch: formDispatch,
                          value: v.target.value,
                          attribute: "NewPassword",
                        })
                      }
                    />
                  </FormAntd.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={16}>
                  <FormAntd.Item
                    label="确认密码"
                    labelCol={{ span: 6 }}
                    rules={[
                      {
                        required: true,
                        message: "请输入确认密码",
                      },
                    ]}
                  >
                    <Input
                      autoComplete="off"
                      maxLength={20}
                      placeholder="请输入确认密码"
                      onChange={(v) =>
                        action.setValue({
                          dispatch: formDispatch,
                          value: v.target.value,
                          attribute: "ConfirmPassword",
                        })
                      }
                    />
                  </FormAntd.Item>
                </Col>
              </Row>
            </div>
          </TabPanel>
        </TabsStyle>
      </>
    )
  );
}

function dateFormat(arg0: string, dateFormat: any): moment.Moment {
  throw new Error("Function not implemented.");
}
