// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../config";
import { effeative } from "./common_logic";


export class HotKeyLogic {
    async addOperation(params) {
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("addBarCodeScalesHotKey"),
            params:params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    async updateOperation(params) {
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("updateBarCodeScalesHotKey"),
            params: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    /**
     * 分页查询
     */

    async selList(params) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("queryBarCodeScalesHotKeyByPage"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    
    async selData(id) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("updateBarCodeScales"),
            params: {
                id
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            }
        }))
        if (r.data && r.data.data) {
            return r.data.data;
        }
    }   

    async delData(id: string) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("deleteBarCodeScalesHotKey"),
            params: {
                ID:id
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            }
        }))
        return r;
    }
    async getAllPlu(param){
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("getAllPlu"),
           data:param,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            }
        }))
        return r;
    }
    async getAllHotKey(id){
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("getAllHotKey"),
           data:[id],
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            }
        }))
        return r;
    }
}