// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../config";
import { effeative } from "./common_logic";

interface IAddData {
    "remark": string;
    "status": number;
    "reason": number;
    "numberNo": string;
    "goodslist": {
        "id": string;
        "goodsID": string;
        "goodsName": string;
        "goodsUnit": string;
        "afterPrice": number;
    }[]
}
interface ISelListByRecord {
    "keyWords": string;
    "addDateTimeStart": string;
    "addDateTimeEnd": string;
    "shopID": any;
    "status": number;
    "pageIndex": number;
    "pageSize": number;
    "orderBy": string;
    "sort": string;
}

export class CostLogic {
    async addData(params: IAddData) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("cost_add_data"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    async updData(params: IAddData) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("cost_upd_data"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    async selList(params: ISelListByRecord) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("cost_select_list"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    async exportList(params: ISelListByRecord) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("cost_export"),
            responseType: 'arraybuffer',
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    async delData(numberNo: string) {
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("cost_delete_data"),
            params: {
                NumberNo: numberNo
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    async selData(id: string) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("cost_select_data"),
            params: {
                NumberNo: id
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r.data.data;
    }

}