import {
  AutoComplete,
  Cascader,
  Col,
  Form,
  Form as FormAntd,
  Input,
  Radio,
  Row,
  Select,
  TreeSelect,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useContext, useEffect, useState } from "react";
import { formAction } from "../../action/auth/form_action";
import { FormStore } from "../../store/auth/form_store";
import { Select as SelectAntd } from "antd";
import { autoRem } from "../../../../global.style";
const { TreeNode } = TreeSelect;

export function FormBasic(title) {
  const { state, dispatch: formDispatch } = useContext(FormStore);
  const action = formAction;
  const { formData, menuList } = state;
  var isLook = false;
  if (title.title == "查看定义") {
    isLook = true;
  } else {
    isLook = false;
  }
  return (
    <>
      <div style={{ margin: "1.9375rem 0 0 0" }}>
        <Row gutter={16}>
          <Col span={11}>
            <FormAntd.Item
              name="auth-page"
              label="系统页面"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: true,
                  message: "请输入系统页面",
                },
              ]}
            >
              <Input
                maxLength={20}
                disabled={isLook}
                placeholder="请输入系统页面"
                onChange={(v) =>
                  action.setValue({
                    dispatch: formDispatch,
                    value: v.target.value,
                    attribute: "Page",
                  })
                }
              />
            </FormAntd.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              name="auth-name"
              label="权限标识"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: true,
                  message: "请输入权限标识",
                },
              ]}
            >
              <Input
                disabled={isLook}
                maxLength={50}
                placeholder="请输入权限标识"
                onChange={(v) =>
                  action.setValue({
                    dispatch: formDispatch,
                    value: v.target.value,
                    attribute: "Name",
                  })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={11}>
            <Form.Item
              name="auth-description"
              label="权限描述"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: true,
                  message: "请选择权限描述",
                },
              ]}
            >
              <AutoComplete
                placeholder="请输入权限描述"
                disabled={isLook}
                onSelect={(v) =>
                  action.setValue({
                    dispatch: formDispatch,
                    value: v,
                    attribute: "Description",
                  })
                }
                onChange={(v) =>
                  action.setValue({
                    dispatch: formDispatch,
                    value: v,
                    attribute: "Description",
                  })
                }
                dataSource={["浏览", "新增", "编辑", "删除","导出","提交审核",'收货','禁用','启用']}
                children={<Input type="number" />}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={22}>
            <Form.Item name="remark" label="备注" labelCol={{ span: 4 }}>
              <TextArea
                placeholder="请输入备注"
                disabled={isLook}
                rows={2}
                onChange={(v) =>
                  action.setValue({
                    dispatch: formDispatch,
                    value: v.target.value,
                    attribute: "Remark",
                  })
                }
              ></TextArea>
            </Form.Item>
          </Col>
        </Row>
      </div>
    </>
  );
}
