import { useBoolean } from "ahooks";
import { Col, Form, Form as FormAntd, Input, Row, Collapse, Modal, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useState } from "react";
import { Table } from "../../../component/table";
import { TableExpand } from "../../../component/table-expand";
import { TextArea } from "../../../component/text-area";
import { autoRem } from "../../../global.style";
import { NumberTool } from "../../../tool/numberTool";
import { FormSubtitle } from "../../commodity/view/form_subtitle";
import { formAction } from "../action/form_action";
import { FormStore } from "../store/form_store";
import styles from "../style/form_basic.module.scss";
import { Control, See } from "../../../component/control-column";
import { Button } from "../../../component/button";
import {WlnInfo} from './wlnInfo'
import { FaPiaoLogs } from "./fapiaoLogs";
export function DataTable({ dataList }) {
  const { state, dispatch } = useContext(FormStore);
  const [showDetail, showDetailFunc] = useBoolean(false)
  const action = formAction;
  const { formData } = state;
  let allPrice = 0;
  let allDiscountPrice = 0;
  const [detailList, setData] = useState([])
  const OrderGoodsStatus = {
    0:{
      text: '正常',
      color:'#87d068'
    },
    2:{
      text: '部分退货',
      color:'cyan'
    },
    3:{
        text: '全退货',
        color:'#f50'
    },
    4:{
        text: '已换货',
        color:'geekblue'
    },
  }
  const columns: ColumnsType = [
    {
      title: "详情",
      // dataIndex: "goodsName",
      key: "0",
      align: "center",
      width: 50,
      render:Control.render(v=><See  click={() => {setData(v.storageBatchNumber || []);showDetailFunc.setTrue()}}/>)
    },
    {
      title: "商品名称",
      dataIndex: "goodsName",
      key: "3",
      align: "center",
    },

    {
      title: "销售数量",
      dataIndex: "goodsNumber",
      key: "4",
      align: "center",
    },
    {
      title: "销售单位",
      dataIndex: "unitName",
      key: "5",
      align: "center",
    },

    {
      title: "出货数量",
      dataIndex: "outInventoryCount",
      key: "6",
      align: "center",
    },
    {
      title: "出货单位",
      dataIndex: "outInventoryUnitName",
      key: "7",
      align: "center",
    },
    {
      title: "商品原价",
      dataIndex: "originalPrice",
      key: "8",
      align: "center",
    },

    {
      title: "应付总金额",
      key: "9",
      align: "center",
      render: (v) => {
        return <span>{(v.originalPrice * v.goodsNumber).toFixed(2)}</span>;
      },
    },
    {
      title: "优惠总金额",
      dataIndex: "discountsPrice",
      key: "10",
      align: "center",
      render: (v) => {
        return <span>{v}</span>;
      },
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "11",
      align: "center",
      width: 80,
      render: (v) => {
        if(formData.isExchangeGoods){
          return <Tag color="geekblue">换货</Tag>
        }else{
          return <Tag color={OrderGoodsStatus[v]?OrderGoodsStatus[v].color:'#67c23a'}>{OrderGoodsStatus[v]?OrderGoodsStatus[v].text:'正常'}</Tag>;
        }
      },
    },
    {
      title: (
        <div>
          <div style={{ paddingBottom: autoRem(0.6) }}>优惠详情</div>
          <div className={styles.head}>
            <span>优惠活动</span>
            <span>活动名称</span>
            <span style={{ border: "none" }}>优惠金额</span>
          </div>
        </div>
      ),
      // dataIndex: 'expenditurePrice',
      key: "12",
      align: "center",
      render: (v) =>
        formData.orderMarketing.orderSingle.length > 0 && (
          <>
            <table className={styles.youhui}>
              <tbody>
                {formData.orderMarketing.orderSingle.map((item,i) => {
                  if (v.id == item.orderDetailID) {
                    return (
                      <tr key={item.marketingTypeString+i}>
                        <td align="center" width="33.3%">
                          {item.marketingTypeString}
                        </td>
                        <td align="center" width="33.3%">
                          {item.marketingName}
                        </td>
                        <td align="center" width="33.3%">
                          {" "}
                          -{item.marketingMoney}
                        </td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
          </>
        ),
    },
  ];
  
  const Detail = () => {
    const detailColumns: ColumnsType = [
      {
        title: "批次号",
        dataIndex: "storageBatchNumber",
        key: "1",
        align: "center",
        width: 200
      },  
      {
        title: "单价(元)",
        dataIndex: "putPrice",
        key: "2",
        align: "center",
        render: v=><span>{v.toFixed(2)}</span>
      },
      {
        title: "数量",
        dataIndex: "putNumber",
        key: "3",
        align: "center",
      },
      {
        title: "单位",
        dataIndex: "unitName",
        key: "4",
        align: "center",
      },
    ]
    return <Modal 
       title='出库批次详情'
       visible={showDetail}
       width={500}
       footer={
         <>
           <Button
             type="primary"
             style={{
               backgroundColor: "#f7ba00",
               borderColor: "#f7ba00",
               color: "white",
             }}
             onClick={()=>{
                showDetailFunc.setFalse()
             }}
           >
             取消
           </Button>
        
         </>
       }
       >
        <Table         
            columns={detailColumns}
            dataSource={detailList}
            scroll={{y: 500}}
            pagination={false}
            />
       </Modal>
  }
  return (
    <>
      <Table
        dataSource={dataList}
        columns={columns}
        pagination={false}
        scroll={{}}
      />
      {showDetail && <Detail/>}
    </>
  );
}
function GiveGoodsDataTable({ dataList }) {
  const columns: ColumnsType = [
    {
      title: "商品名称",
      dataIndex: "goodsName",
      key: "1",
      align: "center",
    },

    {
      title: "赠送数量",
      dataIndex: "meetCount",
      key: "2",
      align: "center",
    },
    {
      title: "单位",
      // dataIndex: "originalPrice",
      key: "3",
      align: "center",
      render: (v) => (
        <span>
          {v.unitContent}/{v.unitName}
        </span>
      ),
    },

    {
      title: "商城价",
      dataIndex: "storePrice",
      key: "4",
      align: "center",
    },
    {
      title: "门店价",
      dataIndex: "shopPrice",
      key: "5",
      align: "center",
    },
  ];

  return (
    <>
      <Table
        dataSource={dataList}
        columns={columns}
        pagination={false}
        scroll={{}}
      />
    </>
  );
}
export function FormBasic({ disabled }) {
  const { state } = useContext(FormStore);
  const { formData } = state;
  const [list, setList] = useState([]);
  const { Panel } = Collapse;

  const [showWlnInfo, showWlnInfoFunc] = useBoolean(false)

  // 开票记录
  const [showFapiaoLogs, showLogsFunc] = useBoolean(false)
  const getSum = (total, v) => {
    if (v) {
      // let multi = NumberTool.multiply(v.originalPrice, v.goodsNumber, 3)
      // let s = NumberTool.subtract(  , 3)
      // return NumberTool.add(total, s, 3)
    } else {
      return total;
    }
  };
  return (
    <div className={styles.root}>
      <div style={{ margin: "1.9375rem 0 0 20px" }} className={styles.content}>
        <FormSubtitle>基本信息</FormSubtitle>
          <Row gutter={24} style={{ marginTop: 10 }}>
            <Col span={6}>
              <FormAntd.Item label="订单类型" labelCol={{ span: 8 }}>
                  <Tag color={formData.isExchangeGoods?'#f50':'cyan'}>{formData.isExchangeGoods?'换货订单':'普通订单'}</Tag>
              </FormAntd.Item>
            </Col>
            {/* <Col span={12}>
              <FormAntd.Item label="原始订单" labelCol={{ span: 4 }}>
                  <span>{formData.parentExpenditureID}</span>
              </FormAntd.Item>
            </Col> */}
          </Row>
        <Row gutter={24} >
          <Col span={6}>
            <FormAntd.Item label="订单编号" labelCol={{ span: 8 }}>
              <span>{formData.orderNumber}</span>
            </FormAntd.Item>
          </Col>
          <Col span={6}>
            <FormAntd.Item label="顾客类型" labelCol={{ span: 8 }}>
              <span>{1 === formData.expenditureType ? "会员" : "散客"}</span>
            </FormAntd.Item>
          </Col>
          <Col span={6}>
            <FormAntd.Item label="订单来源" labelCol={{ span: 8 }}>
              <span>
                {1 === formData.orderType
                  ? "门店订单"
                  : formData.orderType == 2
                  ? "商城订单"
                  : "自助收银订单"}
              </span>
            </FormAntd.Item>
          </Col>        
          <Col span={6}>
            <FormAntd.Item label="会员姓名" labelCol={{ span: 8 }}>
              <span>{formData.memberName}</span>
            </FormAntd.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <FormAntd.Item label="会员卡号" labelCol={{ span: 8 }}>
              <span>{formData.memberCard}</span>
            </FormAntd.Item>
          </Col>
          <Col span={6}>
            <FormAntd.Item label="手机号码" labelCol={{ span: 8 }}>
              <span>{formData.phone}</span>
            </FormAntd.Item>
          </Col>
          <Col span={6}>
            <FormAntd.Item label="订单状态" labelCol={{ span: 8 }}>
              {/* <span>{1 === formData.orderStatusString ? "完成" : "退单"}</span> */}
              <span>{formData.orderStatusString}</span>
            </FormAntd.Item>
          </Col>
          <Col span={6}>
            <FormAntd.Item label="退单状态" labelCol={{ span: 8 }}>
              <span>{formData.salesReturnStatusString}</span>
              {/* {"1" == formData.salesReturnStatus && <span>已退</span>}
              {"2" == formData.salesReturnStatus && <span>未退</span>}
              {"3" == formData.salesReturnStatus && <span>部分退</span>} */}
            </FormAntd.Item>
          </Col>
        </Row>

        <Row gutter={24}>
        <Col span={6}>
            <FormAntd.Item label="获得积分" labelCol={{ span: 8 }}>
              <span style={formData.earnPoints&& formData.earnPoints> 0?{color: '#ffc80b'}:{}}>{formData.earnPoints || 0}</span>
            </FormAntd.Item>
          </Col>
          <Col span={6}>
            <FormAntd.Item label="操作用户" labelCol={{ span: 8 }}>
              {/* <span>{formData.addUserID}</span> */}
              <span>
                {formData.orderType == 1
                  ? formData.addUserID
                  : formData.orderType == 2
                  ? formData.memberName
                  : ""}
              </span>
            </FormAntd.Item>
          </Col>
          <Col span={6}>
            <FormAntd.Item label="添加时间" labelCol={{ span: 8 }}>
              <span>{formData.addDateTime}</span>
            </FormAntd.Item>
          </Col>
         {formData.invoicingStatus ?  <Col span={6}>
            <FormAntd.Item label="开票记录" labelCol={{ span: 8 }}>
              <span style={{color: '#ffc80b',cursor:'pointer'}} onClick={()=>{
                  showLogsFunc.setTrue()
              }}>点击查看</span>
            </FormAntd.Item>
          </Col>:null}
        </Row>
        {formData.orderType == 2 && (
          <Row gutter={24}>
            <Col span={6}>
              <FormAntd.Item label="配送单号" labelCol={{ span: 8 }}>
                <span>{formData.shippingNumber}</span>
              </FormAntd.Item>
            </Col>
            <Col span={6}>
              <FormAntd.Item label="收货人" labelCol={{ span: 8 }}>
                <span>{formData.receiptName}</span>
              </FormAntd.Item>
            </Col>
            <Col span={6}>
              <FormAntd.Item label="联系方式" labelCol={{ span: 8 }}>
                <span>{formData.receiptPhone}</span>
              </FormAntd.Item>
            </Col>
          </Row>
        )}
        {formData.orderType == 2 && (
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item name="address" label="收货地址" labelCol={{ span: 2 }}>
                <span>{formData.address}</span>
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name="remarks" label="备 注" labelCol={{ span: 2 }}>
              <span>{formData.remark}</span>
            </Form.Item>
          </Col>
        </Row>
        {formData.wnnResult && <FormSubtitle>万里牛推送信息</FormSubtitle>}
        {formData.wnnResult && <Row gutter={24}>
            <Col span={6}>
              <FormAntd.Item label="推送状态" labelCol={{ span: 8 }}>
                <Tag color={formData.wnnResult.code == 0 ? "success" : "error"}>
                  {formData.wnnResult.code == 0 ? "成功" : "失败"}
                </Tag>
              </FormAntd.Item>
            </Col>
            <Col span={6}>
              <FormAntd.Item label="万里牛出库单号" labelCol={{ span: 8 }}>
                <span>{formData.wnnResult.data}</span>
              </FormAntd.Item>
            </Col>
            <Col span={12}>
              <FormAntd.Item label="描述信息" labelCol={{ span: 4 }}>
                <span>{formData.wnnResult.message}</span>
              </FormAntd.Item>
            </Col>
          </Row>}

        <FormSubtitle>商品明细</FormSubtitle>

        <div style={{ marginTop: 20 }}>
          <DataTable dataList={formData.detailVms || []} />
        </div>
        {formData.detailVms && (
          <TableExpand>
            <div style={{ fontSize: autoRem(1.2) }}>
              <div>
                {formData.orderMarketing &&
                  formData.orderMarketing.wholeMarketing &&
                  formData.orderMarketing.wholeMarketing.length > 0 && (
                    <span>
                      {/* 整单优惠：{formData.orderMarketing.wholeMarketing.map(item=>{
                                           
                                            return(
                                                <div className={styles.wholeDiscount}>
                                                <span  className={styles.wholediscount_items}>活动类型：{item.marketingTypeString} </span>
                                                <span className={styles.wholediscount_items}>活动名称：{item.marketingName}</span>
                                                <span className={styles.wholediscount_items}>优惠金额：<span style={{color:'#ffc80b'}}>￥{item.marketingMoney}</span></span>
                                                </div>
                                            )                                            
                                        })} */}
                      <Collapse ghost expandIconPosition="right">
                        <Panel header="整单优惠" key="1">
                          {formData.orderMarketing.wholeMarketing.map(
                            (item) => {
                              // if(item.giveGoods.length>0){
                              //     // setList(item.giveGoods)
                              //     list1= item.giveGoods
                              // }
                              return (
                                <div className={styles.wholeDiscount}>
                                  <span className={styles.wholediscount_items}>
                                    活动类型：{item.marketingTypeString}{" "}
                                  </span>
                                  <span className={styles.wholediscount_items}>
                                    活动名称：{item.marketingName}
                                  </span>
                                  {item.activityManner != 2 && (
                                    <span
                                      className={styles.wholediscount_items}
                                    >
                                      优惠金额：
                                      <span style={{ color: "#ffc80b" }}>
                                        -￥{item.marketingMoney}
                                      </span>
                                    </span>
                                  )}
                                  {item.activityManner == 2 && (
                                    <span
                                      className={styles.wholediscount_items}
                                    >
                                      加价金额：
                                      <span style={{ color: "#ffc80b" }}>
                                        +￥{item.fareIncrease}
                                      </span>
                                    </span>
                                  )}
                                </div>
                              );
                            }
                          )}
                        </Panel>
                      </Collapse>
                    </span>
                  )}
              </div>
              <div style={{ marginTop: 10 }}>
                商品总数：
                <span className={styles.order_num} style={{ color: "#ffc80b" }}>
                  {(
                    formData.detailVms.map((v) => (v ? v.goodsNumber : 0)) || []
                  )
                    .reduce((p, c) => p + c, 0)
                    .toFixed(2)}
                </span>{" "}
              </div>

              <div style={{ marginTop: 10 }}>
                金额合计：
                <span className={styles.order_num} style={{ color: "#ffc80b" }}>
                  ￥{formData.goodsOldPrice}
                </span>
              </div>

              {formData.payIntegral && formData.payIntegral > 0 && (
                <div style={{ marginTop: 10 }}>
                  消耗积分：
                  <span className={styles.order_num}>
                    {formData.payIntegral}
                  </span>
                </div>
              )}
              {formData.totoMarketingPrice &&
              formData.totoMarketingPrice > 0 ? (
                <div style={{ marginTop: 10 }}>
                  总优惠：
                  <span
                    className={styles.order_num}
                    style={{ color: "#ffc80b" }}
                  >
                    -￥{formData.totoMarketingPrice}
                  </span>
                </div>
              ) : null}

              {formData.payLogs && (
                <>
                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    {/* 商品原价：<span className={styles.order_num} style={{color:'#ffc80b'}}>￥{formData.goodsOldPrice}</span> */}

                    {formData.payLogs.length > 0 && (
                      <>
                        {" "}
                        <span>支付方式：</span>
                        <div style={{ textAlign: "left" }}>
                          {formData.payLogs.map((item) => {
                            switch (item.paymentMethod) {
                              case 1:
                                return (
                                  <div>
                                    <span
                                      style={{
                                        width: 60,
                                        display: "inline-block",
                                      }}
                                    >
                                      支付宝
                                    </span>
                                    <span className={styles.order_num}>
                                      ￥{item.paymentAmount}
                                    </span>
                                  </div>
                                );
                              case 2:
                                return (
                                  <div>
                                    <span
                                      style={{
                                        width: 60,
                                        display: "inline-block",
                                      }}
                                    >
                                      微信
                                    </span>
                                    <span className={styles.order_num}>
                                      ￥{item.paymentAmount}
                                    </span>
                                  </div>
                                );
                              case 3:
                                return (
                                  <div>
                                    <span
                                      style={{
                                        width: 60,
                                        display: "inline-block",
                                      }}
                                    >
                                      银联
                                    </span>
                                    <span className={styles.order_num}>
                                      ￥{item.paymentAmount}
                                    </span>
                                  </div>
                                );
                              case 4:
                                return (
                                  <div>
                                    <span
                                      style={{
                                        width: 60,
                                        display: "inline-block",
                                      }}
                                    >
                                      现金
                                    </span>
                                    <span className={styles.order_num}>
                                      ￥{item.paymentAmount}
                                    </span>
                                  </div>
                                );
                              case 5:
                                return (
                                  <div>
                                    <span
                                      style={{
                                        width: 60,
                                        display: "inline-block",
                                      }}
                                    >
                                      钱包余额
                                    </span>
                                    <span className={styles.order_num}>
                                      ￥{item.paymentAmount}
                                    </span>
                                  </div>
                                );
                            }
                          })}
                        </div>
                      </>
                    )}
                  </div>
                  {formData.orderType == 2 &&
                  formData.shippingFees &&
                  formData.shippingFees > 0 ? (
                    <div style={{ marginTop: 10 }}>
                      <>
                        配送费：
                        <span
                          className={styles.order_num}
                          style={{ color: "#ffc80b" }}
                        >
                          ￥{formData.shippingFees}
                        </span>
                      </>
                    </div>
                  ) : null}
                  {formData.orderType == 2 &&
                  formData.packingFee &&
                  formData.packingFee > 0 ? (
                    <div style={{ marginTop: 10 }}>
                      <>
                        打包费：
                        <span
                          className={styles.order_num}
                          style={{ color: "#ffc80b" }}
                        >
                          ￥{formData.packingFee}
                        </span>
                      </>
                    </div>
                  ) : null}
                  {
                    formData.smallChange && formData.smallChange > 0 ? (
                      <div style={{ marginTop: 10 }}>
                        <>
                          找零：
                          <span
                            className={styles.order_num}
                            style={{ color: "#ffc80b" }}
                          >
                            ￥{formData.smallChange}
                          </span>
                        </>
                      </div>
                    ) : null
                  }
                  <div style={{ marginTop: 10 }}>
                    {formData.payLogs.length == 0 && (
                      <>
                        应付：
                        <span
                          className={styles.order_num}
                          style={{ color: "#ffc80b" }}
                        >
                          ￥{formData.sellingPrice}
                        </span>
                      </>
                    )}
                    {formData.payLogs.length > 0 && (
                      <>
                        实收：
                        <span
                          className={styles.order_num}
                          style={{ color: "#ff746d" }}
                        >
                          ￥{formData.sellingPrice}
                        </span>
                      </>
                    )}
                  </div>
                  {formData.payLogs.length > 0 && formData.refundMoney > 0 && ( <div  style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "flex-end",
                    }}>
                 
                      <>
                        <span>退款金额：</span>
                        <div style={{ textAlign: "left" }}>
                          {formData.payLogs.map((item) => {
                            switch (item.paymentMethod) {
                              case 1:
                                return (
                                  <div>
                                    <span
                                      style={{
                                        width: 60,
                                        display: "inline-block",
                                      }}
                                    >
                                      支付宝
                                    </span>
                                    <span className={styles.order_num}>
                                      ￥{item.refundMoney}
                                    </span>
                                  </div>
                                );
                              case 2:
                                return (
                                  <div>
                                    <span
                                      style={{
                                        width: 60,
                                        display: "inline-block",
                                      }}
                                    >
                                      微信
                                    </span>
                                    <span className={styles.order_num}>
                                      ￥{item.refundMoney}
                                    </span>
                                  </div>
                                );
                              case 3:
                                return (
                                  <div>
                                    <span
                                      style={{
                                        width: 60,
                                        display: "inline-block",
                                      }}
                                    >
                                      银联
                                    </span>
                                    <span className={styles.order_num}>
                                      ￥{item.refundMoney}
                                    </span>
                                  </div>
                                );
                              case 4:
                                return (
                                  <div>
                                    <span
                                      style={{
                                        width: 60,
                                        display: "inline-block",
                                      }}
                                    >
                                      现金
                                    </span>
                                    <span className={styles.order_num}>
                                      ￥{item.refundMoney}
                                    </span>
                                  </div>
                                );
                              case 5:
                                return (
                                  <div>
                                    <span
                                      style={{
                                        width: 60,
                                        display: "inline-block",
                                      }}
                                    >
                                      钱包余额
                                    </span>
                                    <span className={styles.order_num}>
                                      ￥{item.refundMoney}
                                    </span>
                                  </div>
                                );
                            }
                          })}
                        </div>
                      </>
                   
                  </div>
                   )}
                </>
              )}
            </div>
          </TableExpand>
        )}
        {formData.giveGoods.length > 0 && (
          <>
            <FormSubtitle>赠品明细</FormSubtitle>

            <div style={{ marginTop: 20 }}>
              <GiveGoodsDataTable dataList={formData.giveGoods} />
            </div>
          </>
        )}
        {/* 万里牛订单信息 */}
        {/* {showWlnInfo && <WlnInfo visibleModal={showWlnInfo} data={formData.wnnResult} close={()=>showWlnInfoFunc.setFalse()}/>} */}
        {showFapiaoLogs && <FaPiaoLogs id={formData.id} close={()=>showLogsFunc.setFalse()} visibleModal={showFapiaoLogs}/>}
      </div>
    </div>
  );
}
