import { message } from "antd";
import Cookies from "js-cookie";
import { Tools } from "../../../config";
import { BrandLogic } from "../../../logic/brand_logic";
import { ClassesLogic } from "../../../logic/classes_logic";
import { GoodsLogic } from "../../../logic/goods_logic";
import { UnitLogic } from "../../../logic/unit_logic";
import { FormatAttribute, FormAttribute } from "../store/form_store";
import {FormatData, FormData} from '../store/form_store'
import { InventoryLogic, IOperation } from '../../../logic/inventory_logic'
import {Dispatch} from '../store/form_store'
type Init = {
    state;
    dispatch;
    goodsId: string;
    form;
    method: "Create" | "Edit" | "See" | string;
}
interface InitBasic {
    dispatch;
    state;
}
interface InitEdit {
    state;
    dispatch;
    goodsId: string;
    form;
}
interface ISetValue {
    attribute: FormAttribute;
    value: string | number | string[] | boolean;
    dispatch;
    formatList?: FormatData[]
}
interface ISetFormatValue {
    attribute: FormatAttribute;
    value: string | number | boolean;
    dispatch;
    rowIndex;
}

export class FormAction {

    private goodsLgc = new GoodsLogic();
    private unitLgc = new UnitLogic();
    private classesLgc = new ClassesLogic();
    private brandLgc = new BrandLogic();

    async init({ state, dispatch, goodsId, form, method }: Init) {
        await this.initBasic({ dispatch, state });

        if ("Create" !== method) {
            await this.initEdit({ dispatch, state, goodsId, form })
        }
    }
    private async initBasic({ dispatch, state }: InitBasic) {
        dispatch({type:'InitForm'})

        // 初始化表格
        state.formData.formatList.length = 0;
        if (!state.formData.formatList || state.formData.formatList.length == 0) {
            this.addFormatRow({ dispatch });
        }
        // 商品分类
        dispatch({ type: "LoadClasses", payload: await this.classesLgc.selListBySelect() });
        // 商品单位
        dispatch({ type: "LoadUnit", payload: await this.unitLgc.querysList() });
        // 商品品牌
        const r = await this.brandLgc.querysList();
        dispatch({ type: "LoadBrand", payload: r.data.data.dataSource });
       
      
    }
    private async initEdit({ dispatch, goodsId, form, state }: InitEdit) {
        // 回填状态
        const item = await this.goodsLgc.selData(goodsId);
       if(item){
        let selectClasses = [];    
        let parentId = ''
      const classList = await this.classesLgc.selListBySelect()
     if(classList&&classList.length>0){
            classList.map(v=>{

                if(v.value == item.goodsClassesID){
                    selectClasses = [v.value,'']
                }else{
                    if(v.children.length>0){
                        v.children.map(c=>{
                            if(c.value == item.goodsClassesID){
                                parentId =v.value
                                selectClasses = [parentId,item.goodsClassesID]
        
                            }
                        })
                     }
                }            
            })
        }     
        dispatch({ type: "SetForm", payload: { data: item, selectClasses: selectClasses } });
        if(item.goodsSpecifications.length == 0){
            this.addFormatRow({ dispatch });
        }
        // 填充节点
        form.setFieldsValue({
            "quickcode":item.goodsNo,
            "goods-name": item.goodsName,
            "goods-unit": item.goodsUnitID,
            "goods-code": item.goodsNo,
            "goods-shortcode": item.simpCode,
            "goods-brand": item.goodsBrandID,
            "goods-remarks": item.remark,
            "goods-status": item.status,
            "goods-sort": item.sort,
            "goods-english": item.englishName,
            "goods-classify": selectClasses,
            'goods-isManualDiscounts': item.isManualDiscounts,
            'goods-isNoMarketing': item.isNoMarketing,
            // "goods-inventoryPrice": 
            // "goods-inventoryNumber":

        });
       }
    }
    reset({ dispatch, form }) {

        // selectClasses
        dispatch({
            type: "SetForm", payload: {
                data: {
                    goodsName: "",
                    goodsType: "",
                    goodsClassesID: "",
                    classesName: "",
                    goodsUnitID: "",
                    goodsNo: "",
                    simpCode: "",
                    goodsBrandID: "",
                    remark: "",
                    goodsImage: "",
                    subImages: "",
                    detailed: "",
                    status: "",
                    sort: "",
                    isNoMarketing:false,
                    isManualDiscounts: false,
                    englishName: "",
                    goodsSpecifications: [new FormatData()],
                    id: "",
                    goodsNumber:null,
                    goodsPrice:null,
                    

                }, selectClasses: {}
            }
        });

        // 填充节点
        form.setFieldsValue({
            "goods-name": "",
            "goods-unit": "",
            "goods-code": "",
            "goods-shortcode": "",
            "goods-brand": "",
            "goods-remarks": "",
            "goods-status": "",
            "goods-sort": "",
            "goods-english": "",
            "goods-classify": "",
            'goods-isManualDiscounts': false,
            'goods-isNoMarketing': false,
            "goods-inventoryPrice": null,
            "goods-inventoryNumber":null,
            'goods-manufactureDate':null,
            'goods-shelfLifeDay': null
        });
    }
    setValue({ attribute, value, dispatch, formatList }: ISetValue) {
        dispatch({ type: "Change_FormData", payload: { attribute, value } });   
       if( attribute == 'UnitId'){
        let index = 0
        // if(formatList && formatList.length > 0){
        //         formatList.map((d,i)=>{
        //             if(d.isDefault){
        //                 index = i
        //             }
        //         })
        // }
        this.setFormatValue({ dispatch, attribute: "UnitId", value: value?value.toString():'' , rowIndex: index })
        this.setFormatValue({ dispatch, attribute: "UnitContentId", value: value?value.toString():'' , rowIndex: index })
       }
    }
    setFormatValue({ attribute, value, dispatch, rowIndex }: ISetFormatValue) {
        dispatch({ type: "Change_FormatData", payload: { attribute, value, rowIndex } });
    }
    addFormatRow({ dispatch }) {
        dispatch({ type: "Add_FormatRow" });
    }
    delFormatRow({ dispatch, rowIndex }) {
        dispatch({ type: "Delete_FormatRow", payload: rowIndex });
    }
    async addSubmit({ state, form, unitList }) {
        // 输入验证
        // item=>{
        //     if(item.highestPrice < 0 || item.lowestPrice < 0 || item.storePrice < 0 || item.lineationPrice < 0 || item.shopPrice < 0)){
        //         message.error('价格大于0')
        //         return false
        //     }
          
        // }
     
       if(state.formatList.length> 0){
        for(let i = 0; i< state.formatList.length; i++){
            let item = state.formatList[i]
            if(item.unitContentValue&&parseFloat(item.unitContentValue) <= 0){
                message.error('规格单位内容数量大于0')
                return false
            }
            // if(item.unitId == item.unitContentId)    {
            //     if(item.unitContentValue > 1){
            //         message.error('规格单位和商品单位相同，数量不能大于1') ///提示？？？？？
            //         return false
            //     }
            // }
            if(item.isShop){
                if(item.shopPrice == '' || item.shopPrice == undefined || item.shopPrice === null){
                    message.error('门店价格不能为空')
                    return false
                }else if(item.shopPrice<0.01){
                    message.error('门店价格不能低于0.01')
                    return false
                }
            }else{
                // 不在门店售卖，为空时默认为0
                if(item.shopPrice){}else{
                    item.shopPrice = 0
                }
            }
            if(item.isStore){
                if(item.storePrice == '' || item.storePrice == undefined || item.storePrice === null){
                    message.error('商城价格不能为空')
                    return false
                }else if(item.storePrice<0.01){
                    message.error('商城价格不能低于0.01')
                    return false
                }
            }else{
                // 不在商城售卖，为空时默认为0
                if(item.storePrice){}else{
                    item.storePrice = 0
                }
            }
         if(item.lineationPrice == '' || item.lineationPrice == undefined || item.lineationPrice === null){
            item.lineationPrice = 0
            }else if(item.lineationPrice < 0.01){
                        message.error('划线价大于0.01')
                        return false
            }      
          
            if(item.barCode == '' || item.barCode == undefined || item.barCode == null){
                message.error('规格条码必填')
                return false
            }else if(!(/^[0-9]*$/.test(item.barCode))){
                message.error('规格条码只能输入数字')
                return false
            }
            if(item.pluCode == '' || item.pluCode == undefined || item.pluCode == null){

            } else if(parseInt(item.pluCode) < 0 || parseInt(item.pluCode) >999999){
                message.error('PLU码只能输入1-6位数字')
                return false
            }  
        };
       }
        // if(state.goodsImage == undefined || state.goodsImage == ''){ //后端添加默认值
        //     message.error('请上传商品主图')
        //     return false
        // }   
        console.log(state)
        // return false
        let flag = false
        const r = await this.goodsLgc.addData(state);
        if (r.data.statusCode == 200) {
            message.success("保存成功");           
            // shelfLifeDay 保质期天数  manufactureDate 生产日期
                const {goodsNumber, goodsPrice, manufactureDate, shelfLifeDay} = state
                let { shopID} = JSON.parse(Cookies.get('loginMsg'))
                const unitName = unitList.filter(item=>item.id == state.unitId)[0].unitName
                
                if(goodsNumber && goodsPrice){
                    
                    const inventoryParams:IOperation = {
                        shopID: shopID,
                        source: 2,
                        inventoryType: 2,
                        inventoryStatus: 1,
                        reason: 1,
                        remark:'',
                        details:[{
                            goodsID: r.data.data.id,
                            goodsName: state.goodsName,
                            goodsUnitID: state.unitId,
                            unitName: unitName,
                            inventoryType: 2,
                            putPrice: goodsPrice,
                            putNumber: goodsNumber,
                        }]
                }
                if(manufactureDate && shelfLifeDay){
                    inventoryParams.details[0]['manufactureDate'] = manufactureDate
                    inventoryParams.details[0]['shelfLifeDay'] = shelfLifeDay
                }
                const res = await new InventoryLogic().addOperation(inventoryParams)
                if(res.data.statusCode == 200){
                        message.success("入库成功");
                       return true
                }else{
                    message.error(r.data.returnMessage || r.data.message);
                    return false
                }
                }else{
                    // message.success("保存成功");
                       return true
                }
               
        } else {
            message.error(r.data.returnMessage || r.data.message);
           return false
        }

       
     
        // const inventory = await 
      
    }
    
    async updSubmit({ state, form }) {
        // 输入验证

        // debugger;
       if(state.formatList.length> 0){
            for(let i=0; i< state.formatList.length;i++){
            let item = state.formatList[i]
            if(item.unitContentValue&&parseFloat(item.unitContentValue) <= 0){
                message.error('规格单位内容数量大于0')
                return false
            }
            if(item.unitId == item.unitContentId)    {
                if(item.unitContentValue > 1){
                    message.error('规格单位和商品单位相同，数量不能大于1') ///提示？？？？？
                    return false
                }
            }
            if(item.isShop){
                if(item.shopPrice == '' || item.shopPrice == undefined || item.shopPrice === null){
                    message.error('门店价格不能为空')
                    return false
                }else if(item.shopPrice < 0.01){
                    message.error('门店价格不能低于0.01')
                    return false
                } 
            }else{
                // 不在门店售卖，为空时默认为0
                if(item.shopPrice){}else{
                    item.shopPrice = 0
                }
            }
            if(item.isStore){
                if(item.storePrice == '' || item.storePrice == undefined || item.storePrice === null){
                    message.error('商城价格不能为空')
                    return false
                }else if(item.storePrice < 0.01){
                    message.error('商城价格不能低于0.01')
                    return false
                }
            }else{
                // 不在商城售卖，为空时默认为0
                if(item.storePrice){}else{
                    item.storePrice = 0
                }
            }
            if(item.lineationPrice == '' || item.lineationPrice == undefined || item.lineationPrice === null){
                item.lineationPrice = 0
            }else if(item.lineationPrice < 0.01){
                        message.error('划线价大于0.01')
                        return false
            }  
            // if( item.storePrice == ''  || item.shopPrice == ''  || item.storePrice == undefined  || item.shopPrice == undefined){
            //     message.error('价格不能为空')
            //     return false
            // }else if( item.storePrice < 0 ||  item.shopPrice < 0){
            //             message.error('价格大于0')
            //             return false
            // }     
          
            if(item.barCode == '' || item.barCode == undefined || item.barCode == null){
                message.error('规格条码必填')
                return false
            }else if(!(/^[0-9]*$/.test(item.barCode))){
                message.error('规格条码只能输入数字')
                return false
            }
            if(item.pluCode == '' || item.pluCode == undefined || item.pluCode == null){

            } else if(parseInt(item.pluCode) < 0 || parseInt(item.pluCode) >999999){
                message.error('PLU码只能输入1-6位数字')
                return false
            }  
        };
       }
        // if(state.goodsImage == undefined || state.goodsImage == ''){ //后端添加默认值
        //     message.error('请上传商品主图')
        //     return false
        // } 
       
        const r = await this.goodsLgc.updData(state);
        if (r.data.statusCode == 200) {
            message.success("更新成功");
        } else {
            message.error(r.data.returnMessage || r.data.message);
        }
        return r.data.statusCode == 200;
    }
    /**扫码枪获取商品信息 */
    async getGoodsInfo({dispatch, form, state}){
        let res
        if((state.formData.goodsCode.length == 13 || state.formData.goodsCode.length == 14) && ((/\d/).test(state.formData.goodsCode))){
           res = await new GoodsLogic().getGoodsInfo(state.formData.goodsCode)

        }
        let item;
        // const res = {
        //     "code": 200,
        //     "message": "",
        //     "data": {
        //       "ID": "4679a289-e884-4d59-88d2-a256ebc4d836",
        //       "code": "6933684022539",
        //       "goodsType": "造纸原料和纸制品>>纸制品>>个人纸制品>>纸巾",
        //       "trademark": "植护",
        //       "goodsName": "植护功夫本色纸巾纸",
        //       "spec": "120mm*175mm*100抽（3层/抽）",
        //       "price": 1,
        //       "manuName": "福建朵朵云日用品有限公司",
        //       "manuAddress": "福建省福州市仓山区建新镇半道村168号1#楼101室",
        //       "AddTime": "2021-12-08T14:29:17.147"
        //     }
        //   }
          if(res && res.data && res.data.code == 200){
              item = res.data.data
          }
        // let good = new FormData();
        class goods{
            goodsName:string;
            goodsType:number;
            goodsClassesID:string;
            goodsUnitID:string;
            goodsNo:string;
            simpCode:string;
            goodsBrandID:string;
            remark:string
            goodsImage: string = '';
            subImages: string = ''
            // detailed:,
            status:number;
            sort:number;
            englishName:string;
            goodsSpecifications: any[];
            id:string;
            isManualDiscounts: boolean = false ;
            isNoMarketing: boolean = false;
            
        }
        let good = new goods()
        const classList = state.classifyList
        let selectClasses = []
        let parentId = ''     
          let classId = ''
          if(item){
              /**分类 */
            let classArr = item.goodsType.split('>>')
            let classStr = classArr[classArr.length-1]
            let defaultClass = ''
            if(classList&&classList.length>0){
                classList.map(v=>{    
                    if(v.label == '默认分类' || v.label == '未分类'){
                        defaultClass = v.value
                    }
                    if(v.label == classStr){
                        selectClasses = [v.value,'']
                        classId = v.value
                    }else{
                        if(v.children.length>0){
                            v.children.map(c=>{
                                if(c.label == classStr){
                                    parentId =v.value
                                    classId = c.value
                                    selectClasses = [parentId,c.value]           
                                }
                            })
                         }
                    }            
                })
            }
            if(selectClasses.length == 0){
                selectClasses = [defaultClass,'']
                classId = defaultClass
            }
            const unitList = state.unitList 
            const unitId = unitList.length>0?unitList[0].id:''
            const unitName = unitList.length>0?unitList[0].unitNmae :''
            // good['id'] = '';
            good.goodsName = item.goodsName
            good['selectClasses'] = selectClasses
            good['goodsClassesID'] = classId
            good['goodsNo'] = item.code
            good['goodsCode'] = item.code
            good['goodsUnitID'] =  unitId
            good['unitName'] = unitName
            good['status'] = 1
            good['goodsType'] = 1
            let obj = new FormatData();
           obj["goodsUnitID"] = unitId;
           obj["unitContent"] = '1';
           obj["unitContentId"] = unitId;
           obj["barCode"] = item.code;
           obj['pluCode'] = undefined
           obj["shopPrice"] = item.price;
           obj["storePrice"] = item.price;     
           obj["isStore"] = false;
           obj["isShop"] = true;
           obj['remark'] = item.spec
           obj['isNegativeInventory'] = false
           obj['isDefault'] = true
           good['goodsSpecifications'] = [{...obj}]
        //    console.log(good)
            dispatch({ type: "SetForm", payload: { data: good, selectClasses: selectClasses } });
            // if(item.goodsSpecifications.length == 0){
            //     this.addFormatRow({ dispatch });
            // }
            // 填充节点
            form.setFieldsValue({
                "goods-name": good.goodsName,
                "goods-unit": good.goodsUnitID,
                "goods-code": good.goodsNo,
                "goods-shortcode": good.simpCode,
                "goods-brand": good.goodsBrandID,
                "goods-remarks": good.remark,
                "goods-status": good.status,
                "goods-sort": good.sort,
                "goods-english": good.englishName,
                "goods-classify": selectClasses,
                'goods-isManualDiscounts': good.isManualDiscounts,
                'goods-isNoMarketing': good.isNoMarketing
    
            });
                if(item.goodsName){
                    new GoodsLogic().ConvertToSimp({
                        source: item.goodsName,
                    }).then(({ data }) => {
                        if (data.statusCode != 200) {
                            message.error(data.returnMessage);
                        } else {
                            good.simpCode = data.data
                            this.setValue({ dispatch, value: data.data, attribute: "SimpleCode" })
                            form.setFieldsValue({'goods-shortcode':data.data})
                        }
                    });
                }
          }else{
              message.error('该商品暂无信息,请手动输入')
              
          }

    }

    async getImageOne({id,role,dispatch,state,rowIndex}:{id:string,role?:string,dispatch:any,state:any,rowIndex?:any}){
        const r = await new GoodsLogic().getImageOne(id)
        if(r.data.statusCode == 200){
            if (role == "main") {
                formAction.setValue({ dispatch, attribute: "GoodsImage", value: [r.data.data] });
              } else if(role == 'sub'){
                  let arr = state.formData.subImages || []
                    arr.push(r.data.data)
                formAction.setValue({ dispatch, attribute: "SubImages", value: arr});
              }else{
                this.setFormatValue({
                    dispatch,
                    value: r.data.data,
                    attribute: "Image",
                    rowIndex,
                  });
              }
        }else{
            message.error(r.data.returnMessage)
        }
    }

}
export const formAction = new FormAction();