import { createContext, useReducer } from "react";

class FormData {
    gradeName: string;
    page: string;
    name: string;
    description: string;
    remark: string;
}

type Dispatch = { type, payload }

interface IState {
    formData: FormData;
    menuList: any[]
}

const initialArgs = {
    formData: new FormData(),
    menuList: []
}

export type FormAttribute = "GradeName" | "Remark" | "Page" | "Name" | "Description";

interface IContextProps {
    state: IState;
    dispatch: ({ type }: Dispatch) => void;
}

export const FormStore = createContext<IContextProps>(null);

function reducer(state: IState, { type, payload }: Dispatch) {
    switch (type) {
         // 菜单列表
         case "MenuList":
            return {
                ...state,
                menuList: payload
            }
        // 填充表单
        case "SetForm":
            {
                const data = payload;
                const form = new FormData();
                if(data){
                    {
                        const { gradeName, remark ,page, name,description} = data;
                        form.gradeName = gradeName;
                        form.page = page;
                        form.name = name;
                        form.description = description;
                        form.remark = remark;
                    }
                }

                return {
                    ...state,
                    formData: form
                }
            }
        // 设置表单
        case "Change_FormData":
            {
                const { attribute, value } = payload, props: any = {};

                const key: FormAttribute = attribute;
                let attri = "";

                switch (key) {
                    case "GradeName":
                        attri = "gradeName";
                        break
                    case "Page":
                        attri = "page";
                        break
                    case "Name":
                        attri = "name";
                        break
                    case "Description":
                        attri = "description";
                        break
                    case "Remark":
                        attri = "remark";
                        break
                    default:
                        break;
                }

                props[attri] = value;

                return {
                    ...state,
                    formData: {
                        ...state.formData,
                        ...props
                    }
                }
            }
        default:
            return state;
    }
}

export function FormStoreProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialArgs);

    const v = { state, dispatch };
    return <FormStore.Provider value={v}>{props.children}</FormStore.Provider>
}