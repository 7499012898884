import { createContext, useReducer } from "react";

export const FormStore = createContext<IContextProps>(null);

interface IContextProps {
  state: IState;
  dispatch: ({ type }: Dispatch) => void;
}

type Dispatch = { type; payload };

export class FormData {
    ruleName: string
    id: string
    ctype: number
}
class TableItem {
    serial: number
    ruleGrade: number = undefined
    minSellingPrice: number = undefined
    maxSellingPrice: number = undefined
    commissionValue: number = undefined
}


interface IState {
  formData: FormData;
  tableList: TableItem[]
}

export const initialArgs: IState = {
  formData: new FormData(),
  tableList: []
};

function reducer(state: IState, { type, payload }: Dispatch) {
  switch (type) {
    case "SetFormData": {
      return {
        ...state,
        formData: {
          ...state.formData,
          ...payload,
        },
      };
    }
    case "ResetCommissionValue": {
      let list = state.tableList || []
      list.length > 0 && list.forEach(item=>{
        item.commissionValue = undefined
      })
      return{
        ...state,
        tableList: list.length > 0? [...list]: []
      }
    }
    case "SetForm": {
      const {
        details,
        id,
        ruleName,
        ctype,
      } = payload;
      const item = new FormData();
      item.ctype = ctype
      item.ruleName = ruleName
      item.id = id
      state.formData = item;
      let list = details || []
      list.length > 0 && list.forEach((v, i) =>{
        v.serial = i
      });
      return {
        ...state,
        tableList: list.length > 0? [...list] : []
      };
    }
    case "AddTableItem": {
        const list = state.tableList || [];
        let item = new TableItem()
        list.push(item)
        list.length > 0 && list.forEach((v, i) =>{
          v.serial = i
          v.ruleGrade = i + 1
        });
        return {
            ...state,
            tableList: [...list]
        };
      }
      case "DelTableItem": {
        const list = state.tableList || [];
  
        list.splice(payload, 1);

        list.length > 0 && list.forEach((v, i) =>{
          v.serial = i
          v.ruleGrade = i + 1
        });
        return {
          ...state,
          tableList: [...list],
        };
      }
      case "ChangeTableItem": {
        const { index, key, value } = payload;
        const list = state.tableList || [];
        list[index][key] = value;
        return {
          ...state,
          tableList: [...list],
        };
      }
      case "ClearTableList":
        return {
          ...state,
          tableList: [],
        };
    default:
      return state;
  }
}

export function FormStoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialArgs);

  const v = { state, dispatch };
  return <FormStore.Provider value={v}>{props.children}</FormStore.Provider>;
}
