import { Form } from "antd";
import moment from "moment";
import { createContext, useContext, useReducer } from "react";

export const BatchCouponFormStore = createContext<IContextProps>(null);

interface IContextProps {
    state: IState;
    dispatch: ({ type }: Dispatch) => void;
}

type Dispatch = { type, payload }

class Search {
    keyword: string;
    areaId: string;
    statusId: string;
    typeId: string;
};



export class FormData {
    id: string;
    userName:string;
    targets	:[];
    members: MemberTableItem[];
    labels:[];
    activityName:string;
    shopID:string;
    couponID: string;
    coupon: any[]
    couponAmount: number;
    couponQuantity? :number;
    sendMemberType:number;
    sendType: number;
    sendStatus: number;
    sendTime: string ;
    addDateTime: string ;
    addUserID: string;    
}
export class MemberTableItem {
    memberId:string
    memberCard: string;
    memberName: string;
    membeClassID: string;
    phone: string;
    birthday: any;
    source: number;
    label: string;
    balanceMoney: number;
    memberPwd: string;
    remark: string;
    accountIntegral: number;
    status: number;
    gender: number;
}

export class Coupon {
    id: string;
    couponName: string;
    couponDetail: string;
    couponNumber: string;
    couponType: number;
    subMoney: string;
    discount: string;
    validType: number;
    validStartDate: string;
    validEndDate: string;
    validDay: string;
    meetMoney: string;
    useTargetType: number;
    isOverlay: number;
    getCouponType: number;
    range: number;
    validDate: [];
    couponAmount?: number;
}


interface IState {
    searchList: any[];
    formData: FormData;
    search: Search;
    coupon: any[];
    /**
     * 发券会员列表
     */
     memberList: any[];
     labels:any[]

}
const formData = new FormData();
formData.sendType = 1;
formData.sendMemberType = 1;
formData.couponQuantity = 1;
export const initialArgs: IState = {
    searchList: [],
    formData: formData,
    search: new Search(),
    memberList: [],
    coupon: [],
    labels:[]
}

function reducer(state: IState, { type, payload }: Dispatch) {
    switch (type) {
        case "SetSearchList":
            return {
                ...state,
                searchList: payload
            }
            case "LoadTag":
                return {
                    ...state,
                    labels: payload
                }

        case "SetFormData":
            {        

                return {
                    ...state,
                    formData: {
                        ...state.formData,
                        ...payload
                    }
                }
            }
        case "SetForm":
            {
                const { id,labels,userName, activityName, shopID, couponID ,couponAmount, sendMemberType, sendType, sendStatus, sendTime, addDateTime, addUserID,members,coupon} = payload;
                const item = new FormData();
                item.id = id;
                item.userName = userName;
                // targets	:[];
                item.members = members;
                item.labels = labels
                item.activityName = activityName;
                item.shopID = shopID;
                item.couponID = couponID;
                item.couponAmount = couponAmount;
                item.couponQuantity = couponAmount;
                item.sendMemberType = sendMemberType;
                item.sendType = sendType;
                item.sendStatus = sendStatus;
                item.sendTime = sendTime;
                item.addDateTime = addDateTime;
                item.addUserID  = addUserID;    
                item.coupon = coupon;         
                let list = members
                list.length > 0 &&  list.forEach((v, i) => (v['serial'] = i));
                return {
                    ...state,
                    formData: item,
                    memberList: members,
                    coupon:coupon,
                    // labels:labels
                }
            }
        case "AddCouponTableItem":
            {
                if (payload.length > 0) {

                    const list = state.coupon
                    state.coupon = [...payload]
                    return {
                        ...state
                    }
                }

                return state;
            }
            case "DelCouponTableItem":
                {
                    const list = state.coupon || [];
                    list.splice(payload, 1)    
                    return {
                        ...state,
                        coupon: [...list]                    
                    }
                }
        case "AddMemberTableItem":
            {
              
                // const obj= {}
                // const li = list.concat(payload).reduce((item, next)=>{
                //     if(!obj[next.memberId]) {
                //         item.push(next);
                //         obj[next.memberId] = true;
                //     }
                // })
            //   state.memberList = payload
              const list = payload || [];
              list.length > 0 &&  list.forEach((v, i) => (v['serial'] = i));
                return {
                    ...state,
                    memberList: list.length > 0?[...list]: []
                }
            }
            case "DelMemberTableItem":
                {
                    const list = state.memberList || [];    
                    list.splice(payload, 1);
                   list.length > 0 &&  list.forEach((v, i) => (v['serial'] = i));
                    return {
                        ...state,
                        memberList: list.length > 0?[...list]: []
                    }
                }
      
        case "ChangeTableItem":
            {
                const { value, index} = payload;
                 state.coupon[0].couponAmount = value;
                return {
                    ...state
                }
            }
        case "ClearTableList":
            return {
                ...state,
                memberList: [],
                coupon:[]
            }
        default:
            return state;
    }
}

export function BatchCouponFormStoreProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialArgs);

    const v = { state, dispatch };
    return <BatchCouponFormStore.Provider value={v}>{props.children}</BatchCouponFormStore.Provider>
}