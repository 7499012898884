import { GradeLogic } from "../../../../logic/grade_logic";
import { UnitLogic } from "../../../../logic/unit_logic";
import { MarketingPresale_logic } from '../../../../logic/market/MarketingPresale_logic'
import { ShopLogic } from '../../../../logic/shop_logic'
import { GoodsLogic } from '../../../../logic/goods_logic'
import { message } from "antd";
import Cookies from 'js-cookie'
type Init = {
    state;
    dispatch;
    form;
    id: string;
    method: string;
}
interface InitBasic {
    dispatch;
    search;
}
interface InitEdit {
    state;
    dispatch;
    form;
    id;
}

class FormAction {
    private GradeLogic = new GradeLogic();
    //
    async init({ state, dispatch, id, method, form }: Init) {
        //
        await this.initBasic({ dispatch, search: state.search });
        //
        if ("Create" !== method && id) {
            await this.initEdit({ dispatch, state, id, form })
        }
    }
    private async initBasic({ search, dispatch }: InitBasic) {
        //设置等级列表
        dispatch({ type: "LoadTag", payload: await this.GradeLogic.gradeList() });
        let { shopID, shopName } = JSON.parse(Cookies.get('loginMsg'))
      
        this.clearTableList({ dispatch });
    //    let defaultShop = list.filter(item=> item.isdefault == true)
    //    if(defaultShop.length>0){
    //        let shopinfo = defaultShop[0]
        this.setFormData({ dispatch, key: "shopList", value: [shopID] });
        this.setFormData({
          dispatch,
          key: "shopName",
          value: shopName,
        });
    //    }
    }
    private async initEdit({ dispatch, id, form, state }: InitEdit) {
        //回填表单
        let item;
        const r = await MarketingPresale_logic.GetFirstMarkPresasle({ID: id})
        if(r&&r.data&&r.data.statusCode == 200){
            item = r.data.data
        }else{
            message.error('获取信息失败')
        }
if(item){
    
        //门店回填
        let shoplist = await (await new ShopLogic().selListpost()).data.data.dataSource || []
        dispatch({type: 'LoadShop',payload: shoplist})
        let shopArr = []
        let shopName = []
        item.shopIDs.length&& item.shopIDs.map(shop=>{
            shoplist.map(s=>{if(s.id == shop.shopID){
                shopArr.push(shop.shopID)
                shopName.push(s.shopName)
            }})
        })
        item['shopList'] = shopArr
        item['shopName'] = shopName.toString()

        //等级回填
        let gradeArr = []
       if(item.isMemberClass){
        item.memberClass.length&&item.memberClass.map(g=>{
            gradeArr.push(g.memberClassID)
        })
        item['classList'] = gradeArr
       }else{
           item['classList'] = []
       }
        item['isMemberClass'] = item.isMemberClass==true?2:1

        //商品回填
        const r = await new GoodsLogic().selListForSpecification({pageIndex:1,pageSize:999,shopID:shopArr[0],source:'PC',goodsClassesID:''})
        const dataList =  (r.data.statusCode == 200 && r.data.data && r.data.data.dataSource) || [];
       let list = []
        dataList.map((goods_v, i) => {
            if(goods_v.goods_SpecificationList&&goods_v.goods_SpecificationList.length>0){
             for(let i =0;i<goods_v.goods_SpecificationList.length;i++){
                 let good = goods_v.goods_SpecificationList[i]
                     if(list.filter(ss=>ss.goodsSepID == good.id).length>0){
                         continue;
                     }
                     delete goods_v.id;
                     list.push({
                         key: i + 1,
                         ...goods_v,
                         ...good,
                         goodsNo:goods_v.goodsNo,
                         goods_SpecificationList:[good],
                         goodsName:goods_v.goodsName,
                         goodsSepID: good.id,
                         id: good.id,
                         storePrice:good.storePrice
                     })
                
             }
            }
       
     })
        let goodArr = []
        item.presalegoods&&item.presalegoods.length>0&&item.presalegoods.map(pregood=>{
            list.length&&list.map(good=>{
                if(good.goodsSepID == pregood.sepID){
                    goodArr.push({
                        ...good,
                        sepID: pregood.sepID,
                        presaleMoney:pregood.presaleMoney,
                        presaleCount: pregood.presaleCount,
                        presaleUseCount: pregood.presaleUseCount
                    })
                }
            })
        })
        item['tableList'] = goodArr;
}
      
        dispatch({ type: "SetForm", payload: item });
     
    }
    async addSubmit({ state, status }) {
        const { formData } = state

        //


        let goodsList = [];
        if(state.tableList.length == 0){
            message.error('请选择商品')
            return false;
        }else if(state.tableList.length>0){
            for(let i=0; i < state.tableList.length;i++){
                let t = state.tableList[i]
                if(t.presaleMoney==undefined || t.presaleMoney == null || t.presaleMoney == ''){
                    message.error(`第${i+1}个商品请输入预售价`)
                    return false;
                }else if(t.presaleMoney == 0 || !(/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/).test(t.presaleMoney)){
                    message.error(`第${i+1}个商品预售价应该大于0`)
                    return false;
                }
                if(t.presaleCount==undefined || t.presaleCount == null || t.presaleCount == ''){
                    message.error(`第${i+1}个商品请输入预售库存`)
                    return false;
                }else if(!(/^\+?[1-9][0-9]*$/).test(t.presaleCount)){
                    message.error(`第${i+1}个商品预售库存应为大于0整数`)
                    return false;
                }else if(t.presaleCount > t.sumNumber){
                    message.error(`第${i+1}个商品预售库存不能大于该规格库存`)
                    return false;
                }else
                if(t.presaleUseCount==undefined || t.presaleUseCount == null || t.presaleUseCount == ''){
                    message.error(`第${i+1}个商品请输入单人现购`)
                    return false;
                }else if(!(/^\+?[1-9][0-9]*$/).test(t.presaleUseCount)){
                    message.error(`第${i+1}个商品单人限购应为大于0整数`)
                    return false;
                } else if(parseFloat(t.presaleUseCount) > parseFloat(t.presaleCount)){
                    message.error(`第${i+1}个商品单人限购不能大于预售库存`)
                    return false;
                }

                goodsList.push({
                    goodsID: t.goodsID,
                    sepID: t.sepID,
                    presaleMoney:t.presaleMoney,
                    presaleCount:t.presaleCount,
                    presaleUseCount:t.presaleUseCount
                })
            }
            
        }
        const params = {
            activityName: formData.activityName,
            isMemberClass: formData.isMemberClass == 1?false: true,
            beginDate: formData.beginDate,
            endDate: formData.endDate,
            arrivalDate: formData.arrivalDate,
            memberClass: formData.isMemberClass == 1?[]:formData.classList,
            presalegoods: goodsList,
            shopIDs: formData.shopList
        }
        const r =await MarketingPresale_logic.AddPresale(params)
        if(r&&r.data&&r.data.statusCode == 200){
                message.success('添加成功')
                return true
        }
        else{
            message.error(r.data.returnMessage ||r.data.message)
            return false
        }
    }

    async updSubmit({ state, status }, id) {
        const { formData } = state
        
        let goodsList = [];
        if(state.tableList.length == 0){
            message.error('请选择商品')
            return false;
        }else if(state.tableList.length>0){
            for(let i=0; i < state.tableList.length;i++){
                let t = state.tableList[i]
                if(t.presaleMoney==undefined || t.presaleMoney == null || t.presaleMoney == ''){
                    message.error(`第${i+1}个商品请输入预售价`)
                    return false;
                }else if(t.presaleMoney == 0 || !(/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/).test(t.presaleMoney)){
                    message.error(`第${i+1}个商品预售价应该大于0`)
                    return false;
                }
                if(t.presaleCount==undefined || t.presaleCount == null || t.presaleCount == ''){
                    message.error(`第${i+1}个商品请输入预售库存`)
                    return false;
                }else if(!(/^\+?[1-9][0-9]*$/).test(t.presaleCount)){
                    message.error(`第${i+1}个商品预售库存应为大于0整数`)
                    return false;
                }else if(t.presaleCount>t.sumNumber){
                    message.error(`第${i+1}个商品预售库存不能大于该规格库存`)
                    return false;
                }
                if(t.presaleUseCount==undefined || t.presaleUseCount == null || t.presaleUseCount == ''){
                    message.error(`第${i+1}个商品请输入单人现购`)
                    return false;
                }else if(!(/^\+?[1-9][0-9]*$/).test(t.presaleUseCount)){
                    message.error(`第${i+1}个商品单人限购应为大于0整数`)
                    return false;
                } else if(parseFloat(t.presaleUseCount) > parseFloat(t.presaleCount)){
                    message.error(`第${i+1}个商品单人限购不能大于预售库存`)
                    return false;
                }

                goodsList.push({
                    goodsID: t.goodsID,
                    sepID: t.sepID,
                    presaleMoney:t.presaleMoney,
                    presaleCount:t.presaleCount,
                    presaleUseCount:t.presaleUseCount
                })
            }
            
        }
        const params = {
            id:id,
            activityName: formData.activityName,
            isMemberClass: formData.isMemberClass == 1?false: true,
            beginDate: formData.beginDate,
            endDate: formData.endDate,
            arrivalDate: formData.arrivalDate,
            memberClass: formData.isMemberClass == 1?[]:formData.classList,
            presalegoods: goodsList,
            shopIDs: formData.shopList
        }
        const r = await MarketingPresale_logic.UpdatePresale(params)
        if(r&&r.data&&r.data.statusCode == 200){
            message.success('修改成功')
            return true
        }
        else{
            message.error(r.data.returnMessage ||r.data.message)
            return false
        }
    }

    addTableItem({ dispatch, list }) {
        list.forEach(async v => {
            dispatch({ type: "AddTableItem", payload: v });
        })
    }
    changeTableItem({ dispatch, payload}){
        dispatch({ type: 'ChangeTableItem', payload})
    }
    delTableItem(dispatch, index) {
        dispatch({ type: "DelTableItem", payload: index });
    }

    clearTableList({ dispatch }) {
        dispatch({ type: "ClearTableList" });
    }

    setFormData({ dispatch, key, value }) {
        const params = {
        }
        params[key] = value;

        dispatch({ type: "SetFormData", payload: params })
    }

    reset({ dispatch }) {
        //
    }
}
export const formAction = new FormAction();