// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../config";
import { effeative } from "./common_logic";

export class DictLogic {
    async selData(id: string) {
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("dict_look"),
            params: {
                id
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r.data.data;
    }
    async querysList(param) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("dict_list"),
            data: param,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    async addData(params) {
        return effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("dict_add"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))

    }
    async updData(params) {
        return effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("dict_update"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
    }

}