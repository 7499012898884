import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { useBoolean, useRequest } from "ahooks";
import { Button, Input, message, Modal, Tag, Spin } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect, useState, useRef } from "react";
import { Search } from "../../../../component/search";
import { DataTable } from "../../../../component/table/view/data_table";
import { Tips, TipsGroup } from "../../../../component/tips";
import { FileSearch } from "./file_search";
import { Mask } from "../../../../component/mask";
import { defualtPageSize, Progress } from "../../../../logic/common_logic";
import { Control, Del, Edit, See } from "../../../../component/control-column";
import { DataTableStore } from "../../../../component/table/store/data_table_store";
import { autoRem } from "../../../../global.style";
import { ConfirmModal } from "../../../../component/modal";
import { Form } from "./form";
import { AuthLogic } from "../../../../logic/auth_logic";
import { fileAction } from "../../action/update_price_action";
import { FileStore } from "../../store/update_price_store";
import { UpdatePriceFromProvider } from "../../store/update_price_store";
const formParams = {
  id: "",
  method: "",
};
export function UpdatePrice(viewPower) {
  const [createState, createFunc] = useBoolean(false);
  const [searchState, searchFunc] = useBoolean(false);
  const [confirmState, confirmFunc] = useBoolean(false);
  const { state, dispatch } = useContext(FileStore);
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);
  const action = fileAction;
  //权限
  const [menuButton, setData] = useState([]);
  useEffect(() => {
    (async function () {
      let r = await new AuthLogic().selButton(viewPower.viewPower);
      if (r && r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          r[i] = r[i].split("_")[r[i].split("_").length - 1];
        }
      }
      setData(r);
    })();

    return () => {};
  }, []);

  const onSearch = async (pageIndex) => {
    await action.loadPage({
      pageIndex: pageIndex,
      pageSize: defualtPageSize(),
      dispatch: tableDispatch,
      search: state.search,
    });
  };
  const columns: ColumnsType = [
    {
      title: "操作",
      dataIndex: "id",
      key: "1",
      align: "center",
      width: Control.width(3),
      render: Control.render(
        (v) =>
          menuButton.indexOf("VIEW") != -1 && (
            <See
              click={() => {
                formParams.id = v;
                formParams.method = "VIEW";
                createFunc.setTrue();
              }}
            />
          ),
        // (v) => <Edit click={() => obj.edit(v)} />,
        (v) =>
          menuButton.indexOf("DELETE") != -1 && (
            <Del
              click={() => {
                formParams.id = v;
                confirmFunc.setTrue();
              }}
            />
          )
      ),
    }, //第一列商品操作

    {
      title: "改价单号",
      dataIndex: "upPriceNumber",
      key: "3",
      align: "center",
    },
    {
      title: "商品规格总量",
      dataIndex: "number",
      key: "4",
      align: "center",
    },
    {
      title: "是否下发到秤",
      dataIndex: "isIssued",
      key: "5",
      align: "center",
      render: (v) => {
        return <span>{v ? "是" : "否"}</span>;
      },
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "7",
      align: "center",
      render: (v) => (
        <Tag color={1 === v ? "success" : "error"}>
          {1 === v ? "正常" : "停售"}
        </Tag>
      ),
    },
    {
      title: "创建人",
      dataIndex: "addName",
      key: "8",
      align: "center",
    },
    {
      title: "创建时间",
      dataIndex: "createDate",
      key: "6",
      align: "center",
    },
  ];

  return (
    <div>
      <Search>
        <Input
          maxLength={50}
          allowClear
          placeholder="请输入商品名称"
          onChange={(e) => {
            action.setCondition({
              dispatch,
              key: "keywords",
              value: e.target.value,
            });
          }}
        />
        <input
          type="button"
          value="快速查找"
          onClick={() => {
            onSearch(1);
          }}
        />

        <TipsGroup>
          <input
            type="button"
            value="高级查找"
            onClick={() => searchFunc.toggle()}
          />
          <Tips width="45rem" display={searchState}>
            <FileSearch
              onSearch={(...arg: [any]) => {
                onSearch(...arg);
                searchFunc.setFalse();
              }}
            />
          </Tips>
          <Mask visible={searchState} onClick={() => searchFunc.setFalse()} />
        </TipsGroup>
      </Search>
      <ButtonPanel>
        {menuButton.indexOf("ADD") != -1 && (
          <Button
            onClick={() => {
              formParams.id = "";
              formParams.method = "Create";

              createFunc.setTrue();
            }}
          >
            新增改价
          </Button>
        )}
      </ButtonPanel>
      <DataTable
        columns={columns}
        action={action}
        onLoad={async () => {
          await onSearch(1);
          Progress.done();
        }}
        onChange={(pageIndex) => {
          onSearch(pageIndex);
        }}
        scroll={{
          scrollToFirstRowOnChange: true,
          autoY: true,
          y: autoRem(55),
        }}
        loading={tableState.loading}
        // selectColumn={true}
      />
      {createState && (
        <UpdatePriceFromProvider>
          <Form
            close={(hasUpdate) => {
              if (hasUpdate) {
                onSearch(tableState.current);
              }

              createFunc.setFalse();
            }}
            action={action}
            id={formParams.id}
            method={formParams.method}
          />
        </UpdatePriceFromProvider>
      )}

      <ConfirmModal
        visible={confirmState}
        title="温馨提示"
        subTitle="确认删除？"
        message="删除之后信息无法恢复，请慎重考虑"
        close={async (r) => {
          if (r) {
            await action.delRow(formParams.id);

            onSearch(tableState.current);
          }
          confirmFunc.setFalse();
        }}
      />
    </div>
  );
}
