import { useState,useEffect, useContext } from "react";
import { Search } from "../../../../component/search";
import { Tips, TipsGroup } from "../../../../component/tips";
import { Input, Button, Tag } from "antd";
import { Mask } from "../../../../component/mask";
import { FileSearch } from "./file_search";
import { useBoolean } from "ahooks";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { AuthLogic } from "../../../../logic/auth_logic";
import {ColumnsType} from 'antd/lib/table'
import { DataTable } from "../../../../component/table";
import { defualtPageSize, Progress } from "../../../../logic/common_logic";
import { DataTableStore } from "../../../../component/table/store/data_table_store";
import { fileAction } from "../../action/flowChargeoff/file_action";
import { FileStore } from "../../store/flowChargeoff/file_store";
// 扣款表单
import { FormStoreProvider } from "../../store/flowChargeoff/form_store";
import { Form } from "./form";
import { formAction } from "../../action/flowChargeoff/form_action";
export function FlowChargeoff (viewPower) {
    const action = fileAction
    const [searchState,searchFunc] = useBoolean(false)
    const [createState,createFunc] = useBoolean(false)
    const {state: tableState, dispatch: tableDispatch} = useContext(DataTableStore)
    const {state,dispatch} = useContext(FileStore)
    const [menuButton, setData] = useState([])
    useEffect(() => {
        (async function () {

            let r = await new AuthLogic().selButton(viewPower.viewPower);
            if (r&&r.length > 0) {
                for (let i = 0; i < r.length; i++) {

                    r[i] = r[i].split('_')[r[i].split('_').length - 1]
                }
            }
            setData(r);
        }())

        return () => {

        }
    }, []);

    const columns: ColumnsType = [

        {
            title: '出账单号',
            dataIndex: 'serialCode',
            key: '1',
            align: "center",
            width: 150
        },
        {
            title: '会员姓名',
            dataIndex: 'vipName',
            key: '2',
            align: "center"
        },
        {
            title: '手机号码',
            dataIndex: 'vipPhone',
            key: '3',
            align: "center"
        },
        {
            title: '支出金额',
            dataIndex: 'money',
            key: '4',
            align: "center"
        },
        {
            title: '支出类型',
            dataIndex: 'orderType',
            key: '5',
            align: "center",
            render:v=>{
                return <Tag>{v==1?'消费':v==2?'会员扣款':'储值退款'}</Tag>
            }
        },
        {
            title: '支出时间',
            dataIndex: 'payTime',
            key: '6',
            align: "center"
        },      
        {
            title: '消费门店',
            dataIndex: 'shopName',
            key: '7',
            align: "center",
            width: 110
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: '8',
            align: "center"
        },
    ];
    const onSearch = async (pageIndex) => {
        await fileAction.loadPage({ pageIndex: pageIndex, pageSize: defualtPageSize(), dispatch: tableDispatch, search: state.search })
    }
    return(
        <>
          <Search>
                <Input allowClear placeholder="请输入会员卡号、姓名" maxLength={20} onChange={e => {
                    action.setCondition("Keywords", e.target.value, dispatch)
                }} />
                <input type="button" value="快速查找" onClick={() => onSearch(1)} />

                <TipsGroup>
                    <input type="button" value="高级查找" onClick={() => searchFunc.toggle()} />
                    <Tips width="45rem" display={searchState}>
                        <FileSearch onSearch={(...arg: [any]) => {
                            onSearch(...arg);
                            searchFunc.setFalse();
                        }} />
                    </Tips>
                    <Mask visible={searchState} onClick={() => searchFunc.setFalse()} />
                </TipsGroup>
            </Search>
            <ButtonPanel>
                {
                    menuButton.indexOf("ADD") != -1 && <Button onClick={async () => {
                        createFunc.setTrue()
                    }}>扣款</Button>}
            </ButtonPanel>
            <DataTable columns={columns} action={fileAction}
                onLoad={async () => { await onSearch(1); Progress.done() }}
                onChange={(pageIndex) => { onSearch(pageIndex) }}
                scroll={{ scrollToFirstRowOnChange: true, autoY: true }}
                selectColumn={false}
                loading={ tableState.loading }
            />
            {
                createState && (
                    <FormStoreProvider>
                        <Form close={(hasUpdate) => {
                            if (hasUpdate) {
                                onSearch(tableState.current);
                            }

                            createFunc.setFalse();

                        }} action={formAction} editId={''} seeId={''}  />
                    </FormStoreProvider>
                )
            }
        </>
    )
}