import search from '../../../assets/images/topic/search.png'
import { useContext, useState, useEffect } from 'react';
import { Space, Input, message } from 'antd';
import { TopicDataAction } from "../action/TopicDataAction";
import { ITopicValue, TopicStore } from "../store/topic";
import { ColorPicker } from '../../../component/colorPicker';
import styles from './../style/search.module.scss'
interface IData{
    holder:string,
    color:string,
    bgColor:string,
    boxColor:string
}
export function Search({id, con}){
    const topicStoreState = useContext(TopicStore)
    const setColor =(type,color) =>{
        switch(type){
            case 'color': data.color = color; break;
            case 'bgColor': data.bgColor = color;break;
            case 'boxColor': data.boxColor = color;break;
        }
        TopicDataAction.setData<IData>(...actionArg, data);
    }

     const initSearch = {
        holder: "搜索",
        bgColor: "#fffff",
        color: "#000000",
        boxColor: "#f8f8f8",
      };
      let actionArg: [ITopicValue, number] = [topicStoreState, id];
      const data: IData = TopicDataAction.getData<IData>(...actionArg);

      //注册数据提取方法
  TopicDataAction.setEF(topicStoreState, id, () => {
   
  return new Promise((r, e) => {
    if (data.holder == '') {
      message.error('没有设置搜索关键字');
    } else {
      r(data);
    }
  });
});
      useEffect(() => {
        if (!data) {
          TopicDataAction.setData<IData>(...actionArg, initSearch);
        }       
      },[]);
    if(!data){
        return<div></div>
    }
     
    if(con == 'show')
    {return(
        <div className={styles.container}>
            <div style={{padding:'2px',color:`${data.color}`,backgroundColor:`${data.bgColor}`}}>
                <div style={{margin:'5px auto',padding:'5px',width:'90%',borderRadius:'20px',backgroundColor:`${data.boxColor}`,display:'flex',alignItems:'center'}}> <img style={{margin:'0 10px'}} src= {search} alt='搜索'/>{data.holder} </div>
            </div> 
        </div>
    )}
    else{
        return(
            <div className={styles.ctrl} id='search_ctrl_selected'>
                <h2>搜索设置</h2>
                <div style={{padding:'20px 0',borderTop:'1px solid #eee'}} >
                    <Space direction="vertical" size={'middle'}>
                        <div className={styles.ctrl_items}><label>预设关键字：</label><Input placeholder='请输入搜索关键词' maxLength={20} allowClear={true} onChange={(e)=>{ data.holder = e.target.value;TopicDataAction.setData<IData>(...actionArg, data);}}/></div>
                        <div className={styles.ctrl_items}><label>背景颜色：</label>
                        <ColorPicker type='bgcolor' id={id} defaultColor={data.bgColor} /></div>
                        <div className={styles.ctrl_items}><label>框体颜色：</label><ColorPicker  id={id} type='boxcolor'  defaultColor={data.boxColor} /></div>
                        <div className={styles.ctrl_items}><label>字体颜色：</label><ColorPicker id={id} type='color' defaultColor={data.color} /></div>
                    </Space>
                </div>
            </div>
        )
    }
}
