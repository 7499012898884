import React, { Children, useState } from "react";
import { TabStore } from "../store/tab_store";
import fullStyles from "../style/tabs_full.module.scss";
import bottomlineStyles from "../style/tabs_bottomline.module.scss";
import capsuleStyles from "../style/tabs_capsule.module.scss";

interface ITabs {
  children?: any;
  selectIndex?: number;
  className?: string;
  defaulIndex?;
}

function Tabs({ children, className, defaulIndex }: ITabs) {
  const [selectIndex, setSelectIndex] = useState(defaulIndex || 0);

  let count = -1;
  return (
    <div className={className}>
      <TabStore.Provider value={{ selectIndex, setSelectIndex }}>
        {Children.map(children, (v) => {
          if ("TabPanel" == v.type.nodetype) {
            count++;
            if (count != selectIndex) {
              return null;
            }
          }
          return v;
        })}
      </TabStore.Provider>
    </div>
  );
}

interface ITabsStyle {
  children?: any;
  defaulIndex?;
  style: "Full" | "BottomLine" | "Capsule";
}
export function TabsStyle({ children, style, defaulIndex }: ITabsStyle) {
  let clsName = "";
  if ("BottomLine" == style) {
    clsName = bottomlineStyles.root;
  } else if ("Capsule" == style) {
    clsName = capsuleStyles.root;
  } else if ("Full" == style) {
    clsName = fullStyles.root;
  }
  return (
    <Tabs className={clsName} defaulIndex={defaulIndex}>
      {children}
    </Tabs>
  );
}
