import message from "antd/lib/message"
// import axios from "axios"
import Cookies from "js-cookie"
import { DataTableAction } from "../../../../component/table/action/data_table_action"
import { Tools, axios } from "../../../../config"
import { CostLogic } from "../../../../logic/cost_logic"
import { saveAs } from 'file-saver';

class CostAction extends DataTableAction {
    async delRow(id) {
        const r = await new CostLogic().delData(id);

        if (r.data.statusCode == 200) {
            message.success("删除成功")
        }
    }
    delSelectList({ selectList }: { dispatch: any; selectList: any; }) {
        selectList.map(async ({ id }) => {
            const r = await axios({
                method: "GET",
                url: Tools.getApiAddress("shop_delete"),
                params: {
                    shopId: id
                },
                headers: {
                    SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
                },
            })

            if (r.data.statusCode == 200) {
                message.success("删除成功")
            }
        })
    }
    async loadPage({ dispatch, pageIndex, pageSize, search }) {
        this.setLoading({dispatch, loading:true})
        const { keywords, statusId, shop, goods, startDate, endDate } = search;
        const params: any = {
            "pageIndex": pageIndex,
            "pageSize": pageSize
        }
        if (keywords) {
            params.keyWords = keywords;
        }
        if (startDate && endDate) {
            params.addDateTimeStart = startDate;
            params.addDateTimeEnd = endDate;
        }
        if (undefined != statusId && -1) {
            params.status = parseInt(statusId);
        }
        if (statusId == -1) {
            delete params.inventoryStatus;
            delete params.status;
        }
        if (shop && shop.length) {
            params.shopID = shop[0].id;
        }
        if (goods && goods.length) {
            params.goodsID = goods.map(v => v.id);
        }
        // 档案列表
        const r = await new CostLogic().selList(params);

        if (r.data.statusCode == 200) {
            const dataList = r.data.data.dataSource;

            const list = (dataList || []).map((v, i) => {
                v.key = i + 1;
                return v;
            })

            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: list, pageSize, loading: false });
        }else{
            this.setLoading({dispatch, loading: false})
        }

    }
    // 导出
    async exportList({ search }) {
        const { keywords, statusId, shop, goods, startDate, endDate } = search;

        const params: any = {
        }
        if (keywords) {
            params.keyWords = keywords;
        }
        if (startDate && endDate) {
            params.addDateTimeStart = startDate;
            params.addDateTimeEnd = endDate;
        }
        if (undefined != statusId) {
            params.status = statusId;
        }
        if (statusId == -1) {
            delete params.inventoryStatus;
            delete params.status;
        }
        if (shop && shop.length) {
            params.shopID = shop[0].id;
        }
        if (goods && goods.length) {
            params.goodsID = goods.map(v => v.id);
        }

        // 档案列表
        const r = await new CostLogic().exportList(params);

        if (r.status == 200) {
            let enc = new TextDecoder('utf-8')
            let data = enc.decode(new Uint8Array(r.data))
            if (data.indexOf('statusCode') != -1) {
                let datas = JSON.parse(data)
                if (datas.statusCode == 200) {
                    let blob = new Blob([r.data], { type: 'application/vnd.ms-excel' })
                    saveAs(blob, "成本变更.xlsx");
                } else {
                    message.error(datas.returnMessage)
                }
            } else {
                let blob = new Blob([r.data], { type: 'application/vnd.ms-excel' })
                saveAs(blob, "成本变更.xlsx");
            }
        }

    }

    setCondition({ dispatch, key, value }) {
        const params = {
        }
        params[key] = value;

        dispatch({ type: "SetSearch", payload: params })
    }
}
export const costAction = new CostAction();