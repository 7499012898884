import React, { useEffect, useState, useContext} from "react";
import styles from "../index.module.scss";
// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../../../config";
import { TransactionStore } from "../store/transaction_store";

const menu = [
    { id: 1, name: "今天" },
    { id: 2, name: "昨天" },
    { id: 3, name: "最近7天" },
    { id: 4, name: "最近30天" },
]

export function Ranking() {
    const { state, dispatch } = useContext(TransactionStore);
    const [dataList, setDataList] = useState([]);

    async function load() {
        const { dateType, startTime, endTime } = state.condition;

        const r = await axios({
            method: "POST",
            url: Tools.getApiAddress("statistics_ranking"),
            data: {
                "dateType": dateType,
                "startTime": startTime,
                "endTime": endTime
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        });
        if (r.data.statusCode == 200) {
            const list = r.data.data;

            setDataList(list);
        }
    }

    // function handleMenuClick(e) {
    //     const key = e;

    //     condition = { ...condition, dateType: key };

    //     load();
    // }

    useEffect(() => {

        load();

        return () => {

        }
    }, [state.condition]);

    return (
        <div className={styles.left}>
            <div className={styles.head_c}>
                <span>商品销售排行</span>

                {/* <Select className={styles.menu}
                    onChange={handleMenuClick}
                    value={condition.dateType}
                >
                    {
                        menu.map((v, i) => (
                            <SelectAntd.Option key={i} value={v.id}>{v.name}</SelectAntd.Option>
                        ))
                    }
                </Select> */}
            </div>

            <div className={styles.body_c}>
                <div className={styles.title_c}>
                    <span>排名</span>
                    <span>商品</span>
                    <span>销售额</span>
                    <span>销量
                  {/* <i>
                            <span className={styles.up}></span>
                            <span className={styles.down}></span>
                        </i> */}
                    </span>
                </div>
                <div className={styles.content_c}>
                    {
                        dataList.map((v, i) => (
                            <div key={i} className={styles.item}>
                                <span className={styles.span}>
                                    {i + 1}
                                </span>
                                <span className={styles.span}>
                                    {v.goodsName}
                                </span>
                                <span className={styles.span}>
                                    {v.salesPrice}
                                </span>
                                <span className={styles.span}>
                                    {v.salesNumber}
                                </span>
                            </div>
                        ))
                    }
                </div>

                {!dataList.length ? <div className={styles.empty}></div> : null}
            </div>
        </div>
    )
}
