import { useRequest } from "ahooks";
import { ColumnsType } from "antd/lib/table";
import { useContext, useState } from "react";
import { DataListAction } from "../action/data_table_action";
import { DataListStore } from "../store/data_table_store";
import { List } from "./table";
import styles from "../style/table.module.scss";

interface IDataTable {
    columns: ColumnsType;
    action: DataListAction;
    onLoad?: () => void;
    onChange?: (pageIndex: number) => void;
    onSelect?: (data) => void;
}
export function DataList({ columns, action, onLoad, onChange, onSelect }: IDataTable) {
    const { state, dispatch } = useContext(DataListStore);
    const { total, pageSize, current, dataList, selectedRowKeys } = state;
    const { clearSelectedRowKeys, setSelectList, setSelectedRowKeys } = action;

    const [key, setKey] = useState(1);

    const paginationProps = {
        pageSize: pageSize,
        current: current,
        total: total,
        simple: true,
        onChange: (current) => {
            clearSelectedRowKeys({ dispatch });
            onChange(current);
        }
    }

    useRequest(() => onLoad());

    return (
        <List dataSource={dataList} columns={columns} pagination={paginationProps}
            rowClassName={v => v.key === key ? styles.item : ''}
            onRow={(record) => {
                return {
                    onClick: () => {
                        setKey(record.key);

                        onSelect && onSelect(record);
                    },
                };
            }}
        />
    )
}