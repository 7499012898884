import { useBoolean, useDebounceFn } from "ahooks";
import { Button, Input, Tag ,Spin} from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect, useState } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { DataTable } from "../../../../component/table";
import { fileAction } from "../../action/flowStored/file_action";
import { DataTableStore } from "../../../../component/table/store/data_table_store";

import { Search } from "../../../../component/search";
import { Tips, TipsGroup } from "../../../../component/tips";
import { FileSearch } from "./file_search";
import { FileStore } from "../../store/flowStored/file_store";
import { Mask } from "../../../../component/mask";
import { defualtPageSize, Progress } from "../../../../logic/common_logic";
import { AuthLogic } from "../../../../logic/auth_logic";
import { Control, Del, Edit, Refund, See } from "../../../../component/control-column";
import { ConfirmModal } from "../../../../component/modal";
import { FormStoreProvider } from "../../store/flowStored/form_store";
import { formAction } from "../../action/flowStored/form_action";
import { Form } from "./form";
const formParams = {
    method: "refound",
    id: "",
    status:'',
}
export function Flow(viewPower) {
    const [] = useBoolean(false);
    const action = fileAction;
    const [createState,createFunc] = useBoolean(false)
    const [searchState, searchFunc] = useBoolean(false);
    const { state, dispatch } = useContext(FileStore);
    const { state: tableState, dispatch: tableDispatch } = useContext(DataTableStore);
    const [confirmState,confirmFunc] = useBoolean(false)
    const [menuButton, setData] = useState([])
    useEffect(() => {
        (async function () {
            dispatch({type:'isloading', payload: false})
            let r = await new AuthLogic().selButton(viewPower.viewPower);
            if (r&&r.length > 0) {
                for (let i = 0; i < r.length; i++) {

                    r[i] = r[i].split('_')[r[i].split('_').length - 1]
                }
            }
            setData(r);
        }())

        return () => {
            dispatch({type:'isloading', payload: false})
        }
    }, []);
    const calcTime = (time) => {
        //订单完成后可退款的时间范围 30天
        let t = new Date(time).getTime();
        let now = new Date().getTime();
        if (now - t < 2592000000) {
          return true;
        } else {
          return false;
        }
      };
    const columns: ColumnsType = [
        {
            title: '操作',
            key: '0',
            align: "center",
            fixed: "left",
            width: Control.width(2),
            render: Control.render(
                v => menuButton.indexOf("ADD") != -1  && v.paymentStatus == 1 && calcTime(v.addDateTime) && v.incomeType != 2 &&<Refund click={() => {
                    formParams.id = v.storedNumber;
                    confirmFunc.setTrue()
                }} />,
               
            )
        },
        {
            title: '储值单号',
            dataIndex: 'storedNumber',
            key: '1',
            align: "center",
            width: 150
        },
        {
            title: '会员姓名',
            dataIndex: 'memberName',
            key: '2',
            align: "center"
        },
        {
            title: '手机号码',
            dataIndex: 'memberPhone',
            key: '3',
            align: "center"
        },
        {
            title: '储值金额',
            dataIndex: 'topUpMoney',
            key: '4',
            align: "center"
        },
        {
            title: '支付方式',
            dataIndex: 'pay',
            key: '5',
            align: "center"
        },
        {
            title: '收入类型',
            dataIndex: 'incomeType',
            key: '5',
            align: "center",
            render: v=>{
                 switch(v){
                    case 1:
                        return <Tag color="cyan">储值</Tag>
                    case 2:
                        return <Tag color="purple">退单收入</Tag>
                    default:
                        return<span>-</span>
                 }
            }
        },
        {
            title: '充值前金额',
            dataIndex: 'topUpBeforeMoney',
            key: '6',
            align: "center"
        },
        {
            title: '充值后金额',
            dataIndex: 'topUpAfterMoney',
            key: '7',
            align: "center"
        },
        {

            title: '支付状态',
            key: '8',
            align: "center",
            dataIndex: "paymentStatusMsg",
            // render: v => v == 1 ?
            //     <Tag color="success">支付成功</Tag> : v == 2?
            //         <Tag color="error">支付失败</Tag> : v == 0 ?
            //             <Tag color="warning">待支付</Tag> : <Tag color="geekblue">退款</Tag>
        },
        {
            title: '赠送金额',
            dataIndex: 'giftMoney',
            key: '9',
            align: "center",
            render:v=><span>{v>0?v:null}</span>        
        },
        {
            title: '储值来源',
            dataIndex: 'storedType',
            key: '10',
            align: "center",
            width: 100,
            render: v=>{
                return <span>{v==1?'收银端':v==2?'商城':'管理端'}</span>
            }
        },
        {
            title: '储值门店',
            dataIndex: 'shopName',
            key: '11',
            align: "center",
            width: 110
        },
        {
            title: '储值时间',
            dataIndex: 'addDateTime',
            key: '12',
            align: "center",
            width: 140
        },
       
        {
            title: '备注',
            dataIndex: 'remark',
            key: '13',
            align: "center"
        },
    ];
    const onSearch = async (pageIndex) => {
        await action.loadPage({ pageIndex: pageIndex, pageSize: defualtPageSize(), dispatch: tableDispatch, search: state.search })
    }
    const { run } = useDebounceFn(fn=>{
        fn && fn()
    },{
        wait:1500
    })
  
    return (
        <>
            <Search>
                <Input allowClear placeholder="请输入会员卡号/姓名/手机号码" onChange={e => action.setCondition("Keywords", e.target.value, dispatch)} />
                <input type="button" value="快速查找" onClick={() => onSearch(1)} />

                <TipsGroup>
                    <input type="button" value="高级查找" onClick={() => searchFunc.toggle()} />
                    <Tips width="45rem" display={searchState}>
                        <FileSearch onSearch={(...arg: [any]) => {
                            onSearch(...arg);
                            searchFunc.setFalse();
                        }} />
                    </Tips>
                    <Mask visible={searchState} onClick={() => searchFunc.setFalse()} />
                </TipsGroup>
            </Search>
            <ButtonPanel>
                {
                    menuButton.indexOf("EXPORT") != -1 && <Button onClick={async () => {
                       run(async()=>{
                        await action.exportList({ search: state.search })
                       })
                    }}>导出</Button>                   
                    }
                    { menuButton.indexOf("ADD") != -1 && <Button onClick={async () => {
                        createFunc.setTrue()
                    }}>储值</Button>}
            </ButtonPanel>
           {/* <Spin  tip="退款中..."
                spinning={state.loading}
                size="large"
                wrapperClassName="isloading"> */}
           <DataTable columns={columns} action={fileAction}
                onLoad={async () => { await onSearch(1); Progress.done() }}
                onChange={(pageIndex) => { onSearch(pageIndex) }}
                scroll={{ scrollToFirstRowOnChange: true, autoY: true }}
                selectColumn={false}
                loading={ state.loading ||  tableState.loading }
            />
               {
                createState && (
                    <FormStoreProvider>
                        <Form close={(hasUpdate) => {
                            if (hasUpdate) {
                                onSearch(tableState.current);
                            }

                            createFunc.setFalse();

                        }} action={formAction} editId={''} seeId={''}  />
                    </FormStoreProvider>
                )
            }
           {/* </Spin> */}
             <ConfirmModal visible={confirmState} title="温馨提示" subTitle="确认退款？" message="退款之后无法更改，请慎重考虑" close={async (r) => {
                  confirmFunc.setFalse();
                if (r) {
                    await action.returnSale({OrderNumber:formParams.id,dispatch})
                    await onSearch(tableState.current);
                }
               
            }} />
        </>
    )
}