import React, { useContext, useEffect } from "react";
import { Button } from "../../../../component/button";
import { Layer } from "../../../../component/layer/layer";
import { Form as FormAntd } from "antd";
import { RoleFormBasic } from "./role_form_basic";
import { RoleFormStore } from "../../store/role/role_form_store";
import { roleFormAction, RoleFormAction } from "../../action/role/role_form_action";

import { RoleStore } from "../../store/role/role_store";
import { roleAction } from "../../action/role/role_action";
import { DataTableStore } from "../../../../component/table/store/data_table_store";
import { defualtPageSize } from "../../../../logic/common_logic";
interface IForm {
    children?: any;
    action: RoleFormAction;
    close: (hasUpdate?: boolean) => void;
    editId?: string;
    seeId?: string;
}
export function RoleForm({ close, editId, seeId }: IForm) {
    const [form] = FormAntd.useForm();

    const { state, dispatch: formDispatch } = useContext(RoleFormStore);

    const action = roleFormAction;

    const { state: fileState, dispatch: fileDispatch } = useContext(RoleStore);
    const { state: tableState, dispatch: tableDispatch } = useContext(DataTableStore);
    useEffect(() => {

        roleFormAction.init({ state: state, dispatch: formDispatch, editId: editId, seeId, form });

        return () => {

        }
    }, []);

    function title() {
        if (editId) {
            return "编辑用户";
        }
        else if (seeId) {
            return "查看用户";
        } else {
            return "新建用户";
        }
    }
    const onSearch = async (pageIndex) => {
        await roleAction.loadPage({ pageIndex: pageIndex, pageSize: defualtPageSize(), dispatch: tableDispatch, search: fileState.search })
    }
    return (
        <FormAntd form={form}>
            <Layer layerWith={1} >
                <Layer.Title onClick={() => close()}>{title()}</Layer.Title>
                <Layer.Body>
                    {
                        <RoleFormBasic title={title()} />
                    }
                </Layer.Body>
                {
                    title() != "查看用户" && (
                        <>
                            <Layer.Foot>
                                <Button type="primary" color="Red" style={{ marginRight: "1.25rem" }} onClick={async () => {
                                    const r = await form.validateFields();
                                    let status = false;
                                    if (editId) {
                                        status = await action.updSubmit({ state: state.formData });
                                    } else {
                                        status = await action.addSubmit({ state: state.formData });
                                    }
                                    status && (close && close(true))
                                }}>
                                    保存
                                </Button>
                                <Button type="primary" color="Yellow" onClick={() => action.reset({ dispatch: formDispatch, form })}>
                                    重置
                                </Button>
                            </Layer.Foot>
                        </>
                    )
                }
                {
                    title() == "查看用户" && (
                        <>
                            <Layer.Foot>
                            </Layer.Foot>
                        </>
                    )
                }
            </Layer>
        </FormAntd>
    )
}