import { useBoolean, useRequest } from "ahooks";
import { Input, Modal, Select, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext } from "react";
import { Search } from "../search";
import { DataTable } from "../table";
import {
  DataTableStore,
  DataTableStoreProvider,
} from "../table/store/data_table_store";
import { ChoosePresaleStore, ChooseStoreProvider } from "./store/choose_store";
import styles from "./style/index.module.scss";
import { Select as SelectAntd } from "antd";
import { choosePresaleAction } from "./action/choose_action";
import { defualtPageSize } from "../../logic/common_logic";
let classId = "";

function Presale(params: IChoosePresale) {
  const { visibleModal, close, type } = params;
  const { Option } = Select,
    action = choosePresaleAction;

  const { state, dispatch } = useContext(ChoosePresaleStore);
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);

  const handleOk = () => {
    close && close(true, tableState.selectList);
  };

  const handleCancel = () => {
    close && close(false, null);
  };

  const columns: ColumnsType = [
   
    {
      title: "活动名称",
      dataIndex: "activityName",
      align: "center",
      width: 140,
    },
    {
      title: "添加人",
      dataIndex: "addName",
      align: "center",
    },
    {
      title: "参与店铺",
      dataIndex: "shopName",
      align: "center",
    },
    // {
    //     title: '是否限制会员等级',
    //     dataIndex: 'isMemberClass',
    //     align: "center",
    // },
    {
      title: "活动状态",
      align: "center",
      render: (v) => {
        return (
          <>
            <Tag
              color={
                v.dataState == 0
                  ? "geekblue"
                  : v.dataState == 1
                  ? "success"
                  : "volcano"
              }
            >
              {v.dataStateString}
            </Tag>
          </>
        );
      },
    },
    {
      title: "开始日期",
      dataIndex: "beginDate",
      align: "center",
      // render: (v) => <span>{v ? v.substring(0, 10) : ""}</span>,
    },
    {
      title: "结束日期",
      dataIndex: "endDate",
      align: "center",
      // render: (v) => <span>{v ? v.substring(0, 10) : ""}</span>,
    },
    {
      title: "到货日期",
      dataIndex: "arrivalDate",
      align: "center",
      render: (v) => <span>{v ? v.substring(0, 10) : ""}</span>,
    },
    {
      title: "添加时间",
      dataIndex: "addTime",
      align: "center",
    },
  ];

  const onSearch = async (pageIndex) => {
    // searchFunc.setFalse();
    await action.loadPage({
      pageIndex: pageIndex,
      pageSize: defualtPageSize(),
      dispatch: tableDispatch,
      search: state.search,
    });
  };

  let tableRowSelection: any = {};
  if (type == "radio") {
    tableRowSelection = {
      rowSelection: {
        type: "radio",
        getCheckboxProps: (record) => ({
          disabled: record.dataState == 2, // Column configuration not to be checked
        }),
      },
    };
  }
  const checkSelectable = (record) => {
    let flag = false;
    if (record.status == 2) {
      flag = true;
    }
    return flag;
  };
 
  return (
    <Modal
      title="选择预售活动"
      visible={visibleModal}
      onOk={handleOk}
      onCancel={handleCancel}
      width={1000}
      cancelText="取消"
      okText="确定"
    >
      <div className={styles.root}>
        <Search>
          <Input
            allowClear
            placeholder="请输入活动名称"
            maxLength={20}
            onChange={(e) =>
              action.setCondition({
                dispatch,
                key: "seacherKey",
                value: e.target.value,
              })
            }
          />
          <input type="button" value="快速查找" onClick={() => onSearch(1)} />
        </Search>

        <div style={{ marginTop: 10 }}>
          <DataTable
            columns={columns}
            action={action}
            // rowKey={(record) => record.id}
            rowSelection={{
              getCheckboxProps: (record) => ({
                disabled: record.dataState == 2, // Column configuration not to be checked
              }),
            }}
            {...tableRowSelection}
            onLoad={() => onSearch(1)}
            onChange={(pageIndex) => {
              onSearch(pageIndex);
            }}
            selectColumn={true}
          />
        </div>
      </div>
    </Modal>
  );
}

interface IChoosePresale {
  visibleModal: boolean;
  type?: string;
  close?: (status, r) => void;
  getPresaleType?: number;
}
export function ChoosePresale(params: IChoosePresale) {
  return (
    <ChooseStoreProvider>
      <DataTableStoreProvider>
        <Presale {...params} />
      </DataTableStoreProvider>
    </ChooseStoreProvider>
  );
}
