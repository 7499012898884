import moment from "moment";
import { createContext, useContext, useReducer } from "react";

export const FormStore = createContext<IContextProps>(null);

interface IContextProps {
  state: IState;
  dispatch: ({ type }: Dispatch) => void;
}

type Dispatch = { type; payload };

/** 时段 */
class PeriodTime {
  beginTime: string;
  endTime: string;
  markProportion: number;
  fullMinusID?;
  id?;
  stage?;
}

export class FormData {
  /** 活动名称 */
  activityName: string;
  shopList: any[];
  shopName: string;
  isMemberClass: number = 1;
  classList: any[] = [];
  tableList: any[] = [];
  /** 适用途径 */
  purposeType: number = 1;
  /** 活动明细 */
  activitySubsidiary: number = 1;
  /** 活动时间相关 */
  useType: number = 1;
  beginDate;
  endDate;
  useweekTypeValue;
  useTypeValue;
  /** 时段列表 */
  PeriodTimeList: PeriodTime[] = [];
}

interface IState {
  /** 会员等级列表 */
  TagList;
  /** 指定商品 */
  tableList;
  /** 指定分类 */
  classifyList;
  //
  formData: FormData;
  shoplist: any[];
}

export class TableItem {
  serial: number;
  goodsId: string;
  goodsName: string;
  goodsClassName: string;
  unitName: string;
  inStockUnit: string;
  goodsNo: string;
  storePrice: string;
  shopPrice: string;
  status: number;
  goodsSepID: string;
}

export const initialArgs: IState = {
  TagList: [],
  tableList: [],
  classifyList: [],
  formData: new FormData(),
  shoplist: [],
};

function reducer(state: IState, { type, payload }: Dispatch) {
  switch (type) {
    case "LoadShop":
      return {
        ...state,
        shoplist: payload,
      };
    // 等级列表
    case "LoadTag":
      return {
        ...state,
        TagList: payload,
      };
    case "SetFormData": {
      return {
        ...state,
        formData: {
          ...state.formData,
          ...payload,
        },
      };
    }
    case "SetForm": {
      const {
        activityName,
        shopList,
        shopName,
        isMemberClass,
        classList,
        classifyList,
        tableList,
        purposeType,
        activitySubsidiary,
        useType,
        beginDate,
        endDate,
        useweekTypeValue,
        useTypeValue,
        PeriodTimeList,
      } = payload;
      const item = new FormData();
      item.activityName = activityName;
      item.shopName = shopName;
      item.shopList = shopList;
      item.isMemberClass = isMemberClass;
      item.PeriodTimeList = PeriodTimeList;
      item.classList = classList;
      item.purposeType = purposeType;
      item.activitySubsidiary = activitySubsidiary;
      item.useType = useType;
      item.beginDate = beginDate;
      item.endDate = endDate;
      item.useweekTypeValue = useweekTypeValue;
      item.useTypeValue = useTypeValue;
      state.formData = item;
      return {
        ...state,
        tableList: tableList,
        classifyList: classifyList,
      };
    }
    case "AddclassTableItem": {
      if (payload.length > 0) {
        return {
          ...state,
          classifyList: [...payload],
        };
      }

      return state;
    }
    case "DelTableclassItem": {
      const list = state.classifyList || [];

      list.splice(payload, 1);
      return {
        ...state,
        classifyList: [...list],
      };
    }
    case "ClearTableclassItem": {
      return {
        ...state,
        classifyList: [],
      };
    }
    case "AddTableItem": {
      const list = state.tableList || [];

      // 去重
      if (list.every((v, i) => v.goodsId !== payload.id)) {
        const item = new TableItem();
        const {
          status,
          id,
          goodsNo,
          goodsName,
          goodsClassName,
          unitName,
          shopPrice,
          storePrice,
          goodsSepID,
        } = payload;
        item.status = status;
        item.goodsId = id;
        item.goodsName = goodsName;
        item.goodsClassName = goodsClassName;
        item.unitName = unitName;
        item.goodsNo = goodsNo;
        item.shopPrice = shopPrice;
        item.storePrice = storePrice;
        item.goodsSepID = goodsSepID;
        list.push(item);

        list.forEach((v, i) => (v.serial = i));

        return {
          ...state,
          tableList: [...list],
        };
      }
      return state;
    }
    case "DelTableItem": {
      const list = state.tableList || [];

      list.splice(payload, 1);

      return {
        ...state,
        tableList: [...list],
      };
    }
    case "ChangeTableItem": {
      const { index, key, value } = payload;
      const list = state.tableList || [];

      return {
        ...state,
        tableList: [...list],
      };
    }
    case "ClearTableList":
      return {
        ...state,
        tableList: [],
      };
    //添加时段
    case "AddPeriodTime":
      if (payload.length == 0) {
        state.formData.PeriodTimeList = [];
      }
      for (let i = 0; i < payload.length; i++) {
        state.formData.PeriodTimeList.push(new PeriodTime());
      }
      return {
        ...state,
        formData: {
          ...state.formData,
          PeriodTimeList: [...state.formData.PeriodTimeList],
        },
      };
    case "DelPeriodTime":
      state.formData.PeriodTimeList.splice(payload.index, 1);
      return {
        ...state,
        formData: {
          ...state.formData,
          PeriodTimeList: [...state.formData.PeriodTimeList],
        },
      };
    case "UpdatePeriodTime":
      return {
        ...state,
        formData: {
          ...state.formData,
          PeriodTimeList: [...state.formData.PeriodTimeList],
        },
      };
    case "ClearPeriodTime":
      return {
        ...state,
        formData: {
          ...state.formData,
          PeriodTimeList: [],
        },
      };
    default:
      return state;
  }
}

export function FormStoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialArgs);

  const v = { state, dispatch };
  return <FormStore.Provider value={v}>{props.children}</FormStore.Provider>;
}
