import { createContext, useContext, useReducer } from "react";
interface IContextProps {
  state: IState;
  dispatch: ({ type }: Dispatch) => void;
}
type Dispatch = { type; payload };

class FormData {
  salerTaxNum: string //销方税号
  clerk: string //开票员名称
  appKey: string //
  appSecret: string
  token: string
  orderReceiptTimeOut: number | null //订单支付后开票时间限制 0不允许开票 null不限制
   status: boolean //是否允许开票
}
interface IState {
  formData: FormData;
  isLoading: boolean;
}
const initialArgs: IState = {
  formData: new FormData(),
  isLoading: false,
};
export const FaPiaoSettingStore = createContext<IContextProps>(null);
function reducer(state: IState, { type, payload }: Dispatch) {
  switch (type) {
    case "Loading":
      return {
        ...state,
        isLoading: payload,
      };
    case "Change_FormData":
      //   const { attr, value } = payload;
      //   let props = {};
      //   props[attr] = value;
      return {
        ...state,
        formData: {
          ...state.formData,
          ...payload,
        },
      };
    case "SetForm": {
      const { salerTaxNum, clerk, appKey, appSecret, token, orderReceiptTimeOut, } = payload;
      const item = new FormData();
      item.salerTaxNum = salerTaxNum
      item.clerk = clerk
      item.appKey = appKey
      item.appSecret = appSecret
      item.token = token
      item.status = orderReceiptTimeOut === 0? false: true
      item.orderReceiptTimeOut = orderReceiptTimeOut === 0 ? undefined: orderReceiptTimeOut
      return {
        ...state,
        formData: {
          ...item,
        },
      };
    }
    default:
      return state;
  }
}
export function FaPiaoSettingStoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialArgs);

  const v = { state, dispatch };
  return (
    <FaPiaoSettingStore.Provider value={v}>
      {props.children}
    </FaPiaoSettingStore.Provider>
  );
}
