import React, { useState } from "react";

import styles from "./index.module.scss";

interface ICommand {
    children?: any;
}

export function ButtonPanel({ children }: ICommand) {
    return (
        <div className={styles.root}>
            {
                children
            }
        </div>
    )
}