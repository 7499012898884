import moment from "moment";
import { useState, useEffect } from "react";
import { ApiCon } from "../com/apiCon";
import { ReportLayer } from "../com/reportLayer";
import { SearchLayer } from "./search/searchLayer";
import { getStoresProfitsStatsTK } from "./tableKey/storesProfitsStatsTK";
import { StoresProfitsStatsTable } from "./tableShow/storesProfitsStatsTable";
import { DateSelect } from "./select/dateSelect";
import { SelectStore } from "./store/SelectStore";
import { ISelectTime } from "../com/ISelectType";
import { Form, Button, message } from "antd";
import { Search } from "../../../component/search";
import { ButtonPanel } from "../../../component/button-panel/button_panel";
import { AuthLogic } from "../../../logic/auth_logic";
import styles from "../style/index.module.scss";
import { useDebounceFn } from "ahooks";
import { Buttons } from "./com/buttons";
import { SelectShop } from "./select/selectShop";

window.moment = moment;

/**
 * 门店利润统计
 */
export function StoresProfitsStats(viewPower) {
  const [data, setData] = useState(null);
  const [ifInit, setIfInit] = useState(false);
  const [queryState, setQueryState] = useState<ISelectTime>({
    beginTime: moment(Date.now()).startOf("month").format("Y-M-D"),
    endTime: moment(Date.now()).format("Y-M-D"),
  });
  // const action = fileAction;
  const [menuButton, setMenu] = useState([]);
  useEffect(() => {
    //   dispatch({ type: "isloading", payload: false });
    (async function () {
      let r = await new AuthLogic().selButton(viewPower.viewPower);
      if (r && r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          r[i] = r[i].split("_")[r[i].split("_").length - 1];
        }
      }
      setMenu(r);
    })();
    return () => {
      // dispatch({ type: "isloading", payload: false });
    };
  }, []);
  useEffect(() => {
    if (!ifInit) {
      setIfInit(true);
      queryClick();
    }
  });
  const { run } = useDebounceFn(
    (fn) => {
      fn && fn();
    },
    {
      wait: 1500,
    }
  );
  const queryClick = () => {
    //请求数据
    ApiCon.ShopProfitReport(queryState)
      .then((data) => {
        //console.log('门店利润数据', data);
        setData(getStoresProfitsStatsTK(data));
      })
      .catch((data) => {
        message.error(data.message);
      });
  };

  return (
    <SelectStore.Provider
      value={{
        state: queryState,
        dispatch: (payload) => {
          setQueryState({
            ...queryState,
            ...payload,
          });
        },
      }}
    >
      <ReportLayer
        queryItem={
          <SearchLayer
              queryClick={queryClick}
              notShowInput={true}
              speedQureyPlaceholder=""
              left={{left:0}}
          >
            <SelectShop treeCheckable={false}/>
            <DateSelect/>
          </SearchLayer>
          // <div className={styles.search_form}>
          //   <Form layout="inline">
          //     <div className={styles.search}>
          //       <DateSelect ifShowTab={false} />
          //       <input type="button" value="查找" onClick={queryClick} />
          //     </div>
          //   </Form>
          // </div>
        }
        bottom={
          data &&
          `门店利润=营业收入-预付款抵扣-门店进货款-损耗成本=${(
            (data.reduce((a, b) => {
              return (
                a -
                b.sonItem.reduce((a, b) => {
                  return a + b.number;
                }, 0)
              );
            }, 0) +
              data[0].sonItem.reduce((a, b) => {
                return a + b.number;
              }, 0) *
                2) as number
          ).toFixed(2)}`
        }
        button={
          false &&
          menuButton.indexOf("EXPORT") != -1 && (
            <Buttons
              exportExcel={async () => {
                //   menuButton.indexOf("EXPORT") != -1 &&
                run(async () => {
                  const r = await ApiCon.exportList({
                    search: queryState,
                    title: "门店利润统计",
                    api: "export.ExportShopProfitReport",
                  });
                });
              }}
            />
          )
        }
      >
        {/* <ButtonPanel>
                {
                    menuButton.indexOf("EXPORT") != -1 && <Button onClick={async () => {
                       run(async()=>{
                        await ApiCon.exportList({ search: queryState,title:'门店利润统计' })
                       })
                    }}>导出</Button>                   
                    }   </ButtonPanel> */}
        <StoresProfitsStatsTable data={data} />
      </ReportLayer>
    </SelectStore.Provider>
  );
}
