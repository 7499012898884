import { message } from "antd";
import moment from "moment";
import { createContext, useContext, useReducer } from "react";
import { List } from "../../../user/view/list/list";

export const DiscountFormStore = createContext<IContextProps>(null);

interface IContextProps {
  state: IState;
  dispatch: ({ type }: Dispatch) => void;
}

type Dispatch = { type; payload };

class Search {
  keyword: string;
  areaId: string;
  statusId: string;
  typeId: string;
}
export class FormData {
  activityName: string;
  discountType: number;
  useMemberClassID: [];
  activityStartDate: string;
  activityEndDate: string;
  activityStartTime: string;
  activityEndTime: string;
  isLimit: boolean;
  useType: any;
  useTypeValue: [];
  purposeType: number;
  range: number;
  activityTypes: any;
  discountZj: string;
  useweekTypeValue: [];
  activityRanges: [];
  shopIDs: string[];
  shopName: string;
}
export class TableItem {
  serial: number;
  goodsId: string;
  goodsNo: string;
  goodsName: string;
  goodsUnitName: string;
  inStockUnitId: string;
  goodsClassName: string;
  status: number;
  /**
   * 入库单价
   */
  inStockUnitPrice: number;
  inStockNumber: number;
  inStockPrice: number;
  barCode: string;
  // 分类
  id?: string
  classCode?: string
}
interface IState {
  searchList: any[];
  formData: FormData;
  search: Search;
  tableList: TableItem[];
  classifyList: TableItem[];
  TagList: any[];
  shoplist: any[];
}

export const initialArgs: IState = {
  searchList: [],
  formData: new FormData(),
  search: new Search(),
  tableList: [],
  classifyList: [],
  TagList: [],
  shoplist: [],
};

function reducer(state: IState, { type, payload }: Dispatch) {
  switch (type) {
    case "LoadShop": {
      return {
        ...state,
        shoplist: payload,
      };
    }
    //更新
    case "update":
      //console.log(state)
      return {
        ...state,
      };
    case "SetSearchList":
      return {
        ...state,
        searchList: payload,
      };
    case "SetFormData": {
      return {
        ...state,
        formData: {
          ...state.formData,
          ...payload,
        },
      };
    }
    // 列表
    case "LoadTag":
      return {
        ...state,
        TagList: payload,
      };
    case "SetForm": {
      const {
        activityName,
        discountType,
        useweekTypeValue,
        tableList,
        classifyList,
        isLimit,
        useMemberClassID,
        activityStartDate,
        activityEndDate,
        activityStartTime,
        activityEndTime,
        useType,
        useTypeValue,
        purposeType,
        range,
        discountZj,
        activityTypes,
        shopName,
        shopIDs,
        activityRanges,
        goodsList,
      } = payload;
      const item = new FormData();
      item.shopIDs = shopIDs;
      item.shopName = shopName;
      item.discountType = discountType;
      item.useweekTypeValue = useweekTypeValue;
      item.useMemberClassID = useMemberClassID == "" ? [] : useMemberClassID;
      item.activityStartDate =
        activityStartDate != null ? activityStartDate.slice(0, 10) : "";
      item.activityEndDate =
        activityEndDate != null ? activityEndDate.slice(0, 10) : "";
      item.activityStartTime =
        activityStartTime != null ? activityStartTime.slice(11, 22) : "";
      item.activityEndTime =
        activityEndTime != null ? activityEndTime.slice(11, 22) : "";
      item.useType = useType;
      item.useTypeValue = useTypeValue;
      item.purposeType = purposeType;
      item.range = range;
      item.activityTypes = activityTypes;
      item.discountZj = discountZj;
      item.activityRanges = activityRanges;
      item.isLimit = useMemberClassID == "" ? true : false;

      item.activityName = activityName;
      return {
        ...state,
        formData: item,
        tableList: tableList,
        classifyList: classifyList,
      };
    }
    case "AddTableItem": {
      const list = state.tableList || [];
      // 去重
      if (list.every((v, i) => v.barCode !== payload.barCode)) {
        const item = new TableItem();
        const {
          goodsNo,
          goodsName,
          id,
          goodsUnitID,
          goodsUnitName,
          inStockUnitPrice,
          inStockNumber,
          inStockPrice,
          goodsClassName,
          status,
          barCode
        } = payload;
        item.barCode = barCode
        item.goodsId = id;
        item.goodsNo = goodsNo;
        item.goodsName = goodsName;
        item.inStockUnitId = goodsUnitID;
        item.goodsUnitName = goodsUnitName;
        item.goodsClassName = goodsClassName;
        item.status = status;
        list.push(item);

        list.forEach((v, i) => (v.serial = i));

        return {
          ...state,
          tableList: [...list],
        };
      }
      return state;
    }
    case "DelTableItem": {
      const list = state.tableList || [];

      list.splice(payload, 1);
      list.length > 0 &&  list.forEach((v, i) => (v.serial = i));
      return {
        ...state,
        tableList: [...list],
      };
    }
    case "AddclassTableItem": {
      // if (payload.length > 0) {
      //   return {
      //     ...state,
      //     classifyList: [...payload],
      //   };
      // }
      let list = state.classifyList || []
      if (list.every((v, i) => v.classCode !== payload.classCode)) {
        let data = Object.assign({},payload,{
          goodsClassName: payload.title
        })
        data.children = null
        list.push(data)
      }
      list.length > 0 && list.forEach((v, i) => (v.serial = i));
      return {
              ...state,
              classifyList: [...list],
      };
    }

    case "AddGradeItem": {
      var list = state.formData.activityTypes || [];
      if (payload == "add") {
        if (list.length < 5) {
          let obj = {
            meetMoney: "",
            discount: "",
            discountClass: list.length + 1,
          };
          list.push(obj as never);
        } else {
          message.error("最多五级");
        }
      } else {
        if (payload == "addOne") {
          list = [];
          let obj = {
            meetMoney: "",
            discount: "",
            discountClass: list.length + 1,
          };
          list.push(obj as never);
        } else {
          if (list.length > 1) {
            list.pop();
          }
        }
      }
      state.formData.activityTypes = list;
      return {
        ...state,
        formData: state.formData,
      };
    }
    case "DelTableclassItem": {
      const list = state.classifyList || [];

      list.splice(payload, 1);
      list.length > 0 &&  list.forEach((v, i) => (v.serial = i));
      return {
        ...state,
        classifyList: [...list],
      };
    }
    case "ChangeTableItem": {
      return {
        ...state,
      };
    }
    case "ClearTableList":
      return {
        ...state,
        tableList: [],
      };
    default:
      return state;
  }
}

export function DiscountFormStoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialArgs);

  const v = { state, dispatch };
  return (
    <DiscountFormStore.Provider value={v}>
      {props.children}
    </DiscountFormStore.Provider>
  );
}
