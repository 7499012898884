import { useBoolean, useRequest } from "ahooks";
import { Button, message, Modal, Input, List, Checkbox, Menu } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import React, { useContext, useState } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { DataTable } from "../../../../component/table";
import { fileAction, roleAction } from "../../action/auth/file_action";
import { DataTableStore } from "../../../../component/table/store/data_table_store";

import { FileStore } from "../../store/auth/file_store";
import { defualtPageSize, Progress } from "../../../../logic/common_logic";
import { DataList } from "../../../../component/data-list";
import { DataListStore } from "../../../../component/data-list/store/data_table_store";
import { autoRem } from "../../../../global.style";
let isDefault = false;
export function Auth() {
    const [deleteState] = useBoolean(false);
    const [] = useBoolean(false);
    const action = fileAction;
    const { state, dispatch } = useContext(FileStore);
    const { state: menuState, dispatch: menuDispatch } = useContext(DataListStore);
    const { state: tableState, dispatch: tableDispatch } = useContext(DataTableStore);
    const [selectedRowKeys, setSelectedKeys] = useState([]);
    const onSelectChange = (selectedRowKeys, selectedRows) => {
        setSelectedKeys(selectedRowKeys)
        for (let i = 0; i < selectedRows.length; i++) {
            if (tableState.dataList[selectedRows[i].key - 1].powers && tableState.dataList[selectedRows[i].key - 1].powers.length > 0) {
                for (let k = 0; k < tableState.dataList[selectedRows[i].key - 1].powers.length; k++) {
                    tableState.dataList[selectedRows[i].key - 1].powers[k].checked = true

                }
            }

        }
        for (let i = 0; i < tableState.dataList.length; i++) {
            let arr = tableState.dataList[i].powers.filter((v) => { return v.checked })
            if (arr.length == tableState.dataList[i].powers.length && selectedRowKeys.indexOf(tableState.dataList[i].key) == -1) {
                tableState.dataList[i].powers.map(x => x.checked = false)
            }
        }

        tableState.dataList = tableState.dataList
    };
    if (tableState.dataList.length > 0 && selectedRowKeys.length == 0) {
        let selectedRowKeyq = []
        for (let i = 0; i < tableState.dataList.length; i++) {
            if (tableState.dataList[i].powers) {
                if (tableState.dataList[i].powers.filter((v) => { return v.checked }).length == tableState.dataList[i].powers.length) {
                    selectedRowKeyq.push(i + 1)
                }
            }
        }
        if (selectedRowKeyq.length == 0) {
            selectedRowKeyq = [{}]
        }
        setSelectedKeys(selectedRowKeyq)
    }
    const menuColumns: ColumnsType = [
        {
            title: '角色名称',
            dataIndex: 'name',
            key: '1',
            align: "center"
        }
    ];
    const columns: ColumnsType = [
        {
            title: '分组名称',
            dataIndex: 'page',
            key: '2',
            align: "center"
        },
        {
            title: '权限列表',
            key: '3',
            align: "left",
            render: (v) =>
                <>
                    {
                        v.powers.map(item => {
                            return (
                                <div style={{ float: 'left', marginLeft: '8px' }}>
                                    <Checkbox checked={item.checked} onChange={async (e) => {
                                        item.checked = !item.checked
                                        let selectedRowKeys = []
                                        for (let i = 0; i < tableState.dataList.length; i++) {
                                            if (tableState.dataList[i].powers) {
                                                if (tableState.dataList[i].powers.filter((v) => { return v.checked }).length == tableState.dataList[i].powers.length) {
                                                    selectedRowKeys.push(i + 1)
                                                }
                                            }
                                        }
                                        if (selectedRowKeys.length == 0) {
                                            selectedRowKeys = [{}]
                                        }
                                        setSelectedKeys(selectedRowKeys)
                                        await action.oneSelectPage({ pageIndex: 1, pageSize: defualtPageSize(), dispatch: tableDispatch, list: tableState.dataList })
                                    }}>{item.description}</Checkbox>

                                </div>
                            )
                        })
                    }
                </>
        },
    ];
    // 角色列表 
    const onSearchMenu = async (pageIndex) => {
        const r = await roleAction.rolePage({ pageIndex: pageIndex, pageSize: defualtPageSize(), dispatch: menuDispatch })
        if (r && r.length) {
            state.search.RoleId = r[0].id;
            action.setCondition("RoleId", r[0].id, dispatch)
            onSearch(1);
        }
    }
    const onSearch = async (pageIndex) => {
        await action.loadPage({ pageIndex: pageIndex, pageSize: defualtPageSize(), dispatch: tableDispatch, search: state.search })
    }

    const handleClick = async (e) => {
        tableState.dataList = []
        setSelectedKeys([])
        onSearch(1)
    }

    useRequest(async () => { await fileAction.initBasic({ state, dispatch }); Progress.done() });

    return (
        <>
            <div style={{ float: 'left', width: '18%', height: '100%' }}>
                <DataList columns={menuColumns} action={roleAction}
                    onLoad={() => onSearchMenu(1)}
                    onChange={(pageIndex) => { onSearchMenu(pageIndex) }}
                    onSelect={async ({ id, isdefault }) => {
                        state.search.RoleId = id;
                        isDefault = isdefault;
                        action.setCondition("RoleId", id, dispatch)
                        handleClick(1);
                    }}
                />
            </div>
            <div style={{ float: 'left', width: '80%', marginLeft: '2%', marginTop: "-0.61875rem" }}>
                <ButtonPanel>
                    <Button onClick={async () => {
                        await action.allSelectPage({ pageIndex: 1, pageSize: defualtPageSize(), dispatch: tableDispatch, list: tableState.dataList })
                        let selectedRowKeyq = []
                        for (let i = 0; i < tableState.dataList.length; i++) {
                            if (tableState.dataList[i].powers) {
                                selectedRowKeyq.push(i + 1)
                            }
                        }
                        setSelectedKeys(selectedRowKeyq)
                    }}>全部选择</Button>
                </ButtonPanel>
                <ButtonPanel>
                    <Button onClick={async () => {
                        if(isDefault){
                            message.error('不能修改该角色的权限')
                        }else{
                            await action.allcancelSelectPage({ pageIndex: 1, pageSize: defualtPageSize(), dispatch: tableDispatch, list: tableState.dataList })
                            setSelectedKeys([{}])
                        }
           

                    }}>全部取消</Button>
                </ButtonPanel>
                <ButtonPanel>
                    <Button onClick={async () => {
                        // if(isDefault){
                        //     message.error('不能修改该角色的权限')
                        // }else{
                            await action.selecRole({ dispatch, dataList: tableState.dataList, roleId: state.search.RoleId })
                        // }
                    }}>保存设置</Button>
                </ButtonPanel>
                <div className="authName">
                    <DataTable selectColumn={true} columns={columns} action={fileAction} pagination={true}
                        rowSelection={{
                            hideSelectAll: true,
                            selectedRowKeys,
                            onChange: onSelectChange,
                        }}
                        onLoad={() => { }}
                        onChange={(pageIndex) => { onSearch(pageIndex) }}
                        scroll={{ scrollToFirstRowOnChange: true, autoY: true }}
                        loading={tableState.loading}
                    />
                </div>
            </div>

            {
                deleteState && <Modal className="test" title="温馨提示" visible={true} okText="确定" cancelText="取消" width={366}>
                    <h1>确认删除商品？</h1>
                    <p>删除之后商品信息无法修复，请慎重考虑</p>
                </Modal>
            }
        </>
    )
}


