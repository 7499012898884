import { useBoolean } from "ahooks";
import { Button, Input, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect, useState } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { Search } from "../../../../component/search";
import { DataTable } from "../../../../component/table";
import { DataTableStore } from "../../../../component/table/store/data_table_store";
import { Form } from "./special_from";
import { specialAction } from "../../action/special/file_action";
import { SpecialStore } from "../../store/special/file_store";
import { SpecialFormStoreProvider } from "../../store/special/form_store";
import { TableExpand } from "../../../../component/table-expand";
import { ConfirmModal } from "../../../../component/modal";
import { Tips, TipsGroup } from "../../../../component/tips";
import { InventorySearch } from "./file_search";
import { Mask } from "../../../../component/mask";
import { defualtPageSize, Progress } from "../../../../logic/common_logic";
import { AuthLogic } from "../../../../logic/auth_logic";
import { Control, Del, Edit, See } from "../../../../component/control-column";
import img_stop from '../../../../assets/images/zz.png'
const formParams = {
    method: "Create",
    id: "",
    subId: "",
    item: null,
}

export function Special(viewPower) {
    const [createState, createFunc] = useBoolean(false);
    const [searchState, searchFunc] = useBoolean(false);
    const [confirmState, confirmFunc] = useBoolean(false);

    const { state, dispatch } = useContext(SpecialStore);
    const { state: tableState, dispatch: tableDispatch } = useContext(DataTableStore);
    const [] = useBoolean(false);

    const action = specialAction, formAction = specialAction;
    const [menuButton, setData] = useState([])
    useEffect(() => {
        (async function () {
            let r = await new AuthLogic().selButton(viewPower.viewPower);
            if (r&&r.length > 0) {
                for (let i = 0; i < r.length; i++) {
                    r[i] = r[i].split('_')[r[i].split('_').length - 1]
                }
            }
            setData(r);
        }())

        return () => {

        }
    }, []);
    const columns: ColumnsType = [
        {
            title: '操作',
            key: '1',
            dataIndex: 'oddNumbers',
            align: "center",
            width: Control.width(3),
            render: Control.render(
                (v, r) => menuButton.indexOf("VIEW") != -1 && <See click={() => {
                    formParams.id = r.id;
                    formParams.method = "Preview";

                    createFunc.setTrue();
                }} />,
                (v, r) => menuButton.indexOf("UPDATE") != -1 && r.specialState == 0 && <Edit click={() => {
                    formParams.id = r.id;
                    formParams.subId = r.id;
                    formParams.method = "Edit";
                    formParams.item = r;

                    createFunc.setTrue();
                }} />,
                (v, r) => menuButton.indexOf("STOP") != -1 && r.specialState != 2 && <img src={img_stop} onClick={() => {
                    formParams.id = r.id;
                    formParams.subId = r.id;                    
                    confirmFunc.setTrue();
                }}  alt=''/>
            )
        },
        {
            title: '活动名称',
            dataIndex: 'activityName',
            key: '2',
            align: "center",
            width: 140
        },
        {
            title: '活动类型',
            key: '3',
            align: "center",
            render: (v) => <span>特价</span>
        },
        {
            title: '活动方式',
            key: '4',
            align: "center",
            render: (v) => <span>直接特价</span>
        },
        {
            title: '活动起止日期',
            key: '6',
            width: 160,
            align: "center",
            render: (v) => <><span>{v.activityStartDate + '~' + v.activityEndDate}</span></>
        },
        {
            title: '活动起止时间段',
            key: '7',
            align: "center",
            width: 160,
            render: (v) => <span>{v.activityStartTime + '~' + v.activityEndTime}</span>
        },
        {
            title: '参与门店',
            dataIndex: 'shopName',
            key: '7',
            align: "center"
        },
        {
            title: '活动状态',
            key: '8',
            align: "center",
            render: (v) => <>
                {v.specialState == 0 && <Tag color="geekblue">未开始</Tag>}
                {v.specialState == 1 && <Tag color="success">进行中</Tag>}
                {v.specialState == 2 && <Tag color="volcano">已结束</Tag>}
            </>
        },
        {
            title: '创建人',
            dataIndex: 'addName',
            key: '8',
            align: "center"
        },
        {
            title: '创建时间',
            dataIndex: 'addDateTime',
            key: '8',
            align: "center"
        },
    ];

    const onSearch = async (pageIndex) => {
        await action.loadPage({ pageIndex: pageIndex, pageSize: defualtPageSize(), dispatch: tableDispatch, search: state.search })
    }

    return (
        <>
            <Search>
                <Input allowClear placeholder="请输入活动名称" onChange={e => action.setCondition({ dispatch, key: "activityName", value: e.target.value })} />
                <input type="button" value="快速查找" onClick={() => onSearch(1)} />

                <TipsGroup>
                    <input type="button" value="高级查找" onClick={() => searchFunc.toggle()} />
                    <Tips display={searchState} width="48rem">
                        <InventorySearch onSearch={() => {
                            onSearch(1)
                            searchFunc.setFalse()
                        }} />
                    </Tips>
                    <Mask visible={searchState} onClick={() => searchFunc.setFalse()} />
                </TipsGroup>
            </Search>

            <ButtonPanel>
                {menuButton.indexOf("ADD") != -1 && <Button onClick={() => {
                    formParams.id = "";
                    formParams.method = "Create";

                    createFunc.setTrue();
                }

                }>新增活动</Button>}
            </ButtonPanel>

            <DataTable columns={columns} action={action}
                onLoad={async () => { await onSearch(1); Progress.done() }}
                onChange={(pageIndex) => { onSearch(pageIndex) }}
                scroll={{ scrollToFirstRowOnChange: true, autoY: true, x: 1060 }}
                loading= { tableState.loading }
            />

            {
                createState && (

                    <SpecialFormStoreProvider>
                        <Form audit={true} close={(hasUpdate) => {
                            if (hasUpdate) {
                                onSearch(tableState.current);
                            }

                            createFunc.setFalse();
                        }} action={formAction} id={formParams.id} subId={formParams.subId} item={formParams.item} method={formParams.method} />
                    </SpecialFormStoreProvider>

                )
            }

            <ConfirmModal visible={confirmState} title="温馨提示" subTitle="确认终止？" message="终止之后活动无法恢复，请慎重考虑" close={async (r) => {
                if (r) {
                    await action.updateStatus({id:formParams.subId,status:false});

                    onSearch(tableState.current);
                }
                confirmFunc.setFalse();
            }} />
        </>
    )
}
//  class ErrorBoundary extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { hasError: false };
//   }

//   componentDidCatch(error, info) {
//     // Display fallback UI
//     this.setState({ hasError: true });
//     // You can also log the error to an error reporting service
//     // logErrorToMyService(error, info);
//   }

//   render() {
//     //console.log(this.state)
//     if (this.state) {
//       // You can render any custom fallback UI
//       return <span>出错了</span>;
//     }
//     return <Special />
//   }
// }
