import {
  TabsStyle,
  TabList,
  Tab,
  TabPanel,
} from "../../../../component/react-tabs";
import { Info } from "./config/info";
import { useState, useEffect } from "react";
import { Version } from "./version/version";
import { WxShopApiCon } from "./logic/WxShopApiCon";
import { Spin } from "antd";
import styles from "../../style/applet/index.module.scss";
import { DataTableStoreProvider } from "../../../../component/table/store/data_table_store";
import { Experience } from "./experience/experience";
import { AuthLogic } from "../../../../logic/auth_logic";
import { useBoolean } from "ahooks";
import Cookies from "js-cookie";
import moment from "moment";
/**
 * 小程序
 */
export function Applet(viewPower) {
  const [ifAu, setAu] = useState(false);
  const [data, setData] = useState(null);
  const [ifInit, setIfInit] = useState(false);
  const [power, powerFunc] = useBoolean(false);
  const [timeStamp, setTimeStamp] = useState("");
  useEffect(() => {
    (async function () {
      let r = await new AuthLogic().selButton(viewPower.viewPower);
      if (r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          r[i] = r[i].split("_")[r[i].split("_").length - 1];
        }
      }
      if (r.indexOf("UPDATE") == -1) {
        powerFunc.setTrue();
      } else {
        powerFunc.setFalse();
      }
    })();

    return () => {};
  }, []);

  const getInfo = () => {
    Promise.all([
      WxShopApiCon.GetUserWxSetting()
        .then((d) => {
          setData(d);
          setAu(true);
          //console.log('授权数据', d);
        })
        .catch((d) => {
          //未授权
          setAu(false);
        })
        .finally(() => {
          setTimeStamp(moment().format("yyyy-MM-DD hh:mm:ss"));
        }),
    ]).then((values) => {
      setIfInit(true);
    });
  };
  //获取授权数据
  useEffect(() => {
    getInfo();
    return () => {};
  }, []);
  useEffect(() => {
    console.log(Cookies.get("token"));
  }, []);
  if (!ifInit) {
    return (
      <div className={styles.loading}>
        <Spin />
      </div>
    );
  }

  return (
    <TabsStyle style="BottomLine">
      <TabList>
        <Tab>
          <span>基础信息</span>
          <hr />
        </Tab>
        {ifAu ? (
          <Tab>
            <span>版本管理</span>
            <hr />
          </Tab>
        ) : (
          <div></div>
        )}
        {/* {
                ifAu ?
                    <Tab>
                        <span>体验者管理</span>
                        <hr />
                    </Tab>
                    :
                    <div></div>
            } */}
        <hr />
      </TabList>
      <TabPanel>
        <Info data={data} key={timeStamp} disabled={power} />
      </TabPanel>
      {ifAu ? (
        <TabPanel>
          <Version info={data} disabled={power} />
        </TabPanel>
      ) : (
        <div></div>
      )}
      {/* {
            ifAu ?
                <TabPanel>
                    <DataTableStoreProvider>
                        <Experience />
                    </DataTableStoreProvider>
                </TabPanel>
                :
                <div></div>
        } */}
    </TabsStyle>
  );
}
