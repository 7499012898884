import { createContext, useContext, useReducer } from "react";

export const FullgiftStore = createContext<IContextProps>(null);

interface IContextProps {
    state: IState;
    dispatch: ({ type }: Dispatch) => void;
}

type Dispatch = { type, payload }

class Search {
    purposeType: string;
    activityManner:number;
    shopID:string;
    shopName:string;
};

interface IState {
    searchList: any[];
    search: Search;
}

const initialArgs: IState = {
    searchList: [],
    search: new Search()
}

function reducer(state: IState, { type, payload }: Dispatch) {
    switch (type) {
        case "SetSearch":
            return {
                ...state,
                search: {
                    ...state.search,
                    ...payload
                }
            }
        case "SetSearchList":
            return {
                ...state,
                searchList: payload
            }
        default:
            return state;
    }
}

export function FullgiftStoreProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialArgs);

    const v = { state, dispatch };
    return <FullgiftStore.Provider value={v}>{props.children}</FullgiftStore.Provider>
}