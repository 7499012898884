import { useBoolean } from "ahooks";
import { Button, Input, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect, useState } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { Search } from "../../../../component/search";
import { DataTable } from "../../../../component/table";
import { DataTableStore } from "../../../../component/table/store/data_table_store";
import { Form } from "./report_form";
import { TableExpand } from "../../../../component/table-expand";
import { ConfirmModal } from "../../../../component/modal";
import { Tips, TipsGroup } from "../../../../component/tips";
import { ReportSearch } from "./report_search";
import { ReportStore } from "../../store/report/report_store";
import { reportAction } from "../../action/report/report_action";
import { reportFormAction } from "../../action/report/report_form_action";
import { ReportFormStoreProvider } from "../../store/report/report_form_store";
import { Mask } from "../../../../component/mask";
import { defualtPageSize, Progress } from "../../../../logic/common_logic";
import { AuthLogic } from "../../../../logic/auth_logic";
import { Control, See, Edit, Del } from "../../../../component/control-column";

const formParams = {
  method: "Create",
  id: "",
  subId: "",
  item: null,
};

export function Report(viewPower) {
  const [createState, createFunc] = useBoolean(false);
  const [searchState, searchFunc] = useBoolean(false);
  const [confirmState, confirmFunc] = useBoolean(false);

  const { state, dispatch } = useContext(ReportStore);
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);
  const [] = useBoolean(false);

  const action = reportAction,
    formGoodsAction = reportFormAction;
  const [menuButton, setData] = useState([]);
  useEffect(() => {
    (async function () {
      let r = await new AuthLogic().selButton(viewPower.viewPower);
      if (r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          r[i] = r[i].split("_")[r[i].split("_").length - 1];
        }
      }
      setData(r);
    })();

    return () => {};
  }, []);
  const columns: ColumnsType = [
    {
      title: "操作",
      key: "1",
      align: "center",
      width: Control.width(3),
      render: Control.render(
        (v) =>
          menuButton.indexOf("VIEW") != -1 && (
            <See
              click={() => {
                formParams.id = v.id;
                formParams.subId = v.oddNumbers;
                formParams.method = "Preview";

                createFunc.setTrue();
              }}
            />
          ),
        (v, r) =>
          r.inventoryStatus !== 1 &&
          menuButton.indexOf("UPDATE") != -1 && (
            <Edit
              click={() => {
                formParams.id = v.id;
                formParams.subId = v.oddNumbers;
                formParams.method = "Edit";
                formParams.item = r;

                createFunc.setTrue();
              }}
            />
          ),
        (v, r) =>
          r.inventoryStatus !== 1 &&
          // menuButton.indexOf("DELETE") != -1 && (
          menuButton.indexOf("UPDATE") != -1 && (
            <Del
              click={() => {
                formParams.id = v.id;
                formParams.subId = r.oddNumbers;

                confirmFunc.setTrue();
              }}
            />
          )
      ),
    },
    {
      title: "单据号",
      dataIndex: "oddNumbers",
      key: "2",
      align: "center",
      width: 180,
    },
    {
      title: "门店",
      dataIndex: "shopName",
      key: "3",
      align: "center",
    },
    {
      title: "报损类型",
      dataIndex: "inventoryType",
      key: "4",
      align: "center",
      render: (v) =>
        v == 1 ? (
          <span>出库</span>
        ) : v == 2 ? (
          <span>入库</span>
        ) : v == 3 ? (
          <span>报损</span>
        ) : v == 4 ? (
          <span>报溢</span>
        ) : null,
    },
    {
      title: "来源",
      dataIndex: "source",
      key: "5",
      align: "center",
      render: (v) => (v == 1 ? <span>收银端</span> : <span>管理端</span>),
    },
    {
      title: "单据数量",
      dataIndex: "number",
      key: "6",
      align: "center",
    },
    {
      title: "单据金额",
      dataIndex: "money",
      key: "7",
      align: "center",
    },
    {
      title: "单据状态",
      dataIndex: "inventoryStatus",
      key: "8",
      align: "center",
      render: (v) => (
        <>
          {v == "1" && <Tag color="success">已审核</Tag>}
          {v == "2" && <Tag color="error">未审核</Tag>}
          {v == "3" && <Tag>作废</Tag>}
        </>
      ),
    },
    {
      title: "理由",
      dataIndex: "reason",
      key: "9",
      align: "center",
      render: (v) => (
        <>
          {"1" == v && <span>手动入库</span>}
          {"2" == v && <span>退货入库</span>}
          {"3" == v && <span>采购入库</span>}
          {"4" == v && <span>手动出库</span>}
          {"5" == v && <span>销售出库</span>}
          {"6" == v && <span>库存调整</span>}
          {"7" == v && <span>商品试吃</span>}
          {"8" == v && <span>日常报损</span>}
          {"9" == v && <span>商品过期</span>}
          {"10" == v && <span>领用</span>}
          {"11" == v && <span>日常报溢</span>}
        </>
      ),
    },
    {
      title: "制单人",
      dataIndex: "addUserName",
      key: "10",
      align: "center",
    },
    {
      title: "制单时间",
      dataIndex: "addDateTime",
      key: "11",
      align: "center",
      width: 140,
    },
    {
      title: "审核人",
      dataIndex: "checkUserName",
      key: "12",
      align: "center",
    },
    {
      title: "审核时间",
      dataIndex: "checkDateTime",
      key: "12",
      align: "center",
      width: 140,
    },
  ];

  const onSearch = async (pageIndex) => {
    await action.loadPage({
      pageIndex: pageIndex,
      pageSize: defualtPageSize(),
      dispatch: tableDispatch,
      search: state.search,
    });
  };

  const count = (key) =>
    tableState.dataList.map((v) => v[key]).reduce((p, c) => p + c, 0);

  return (
    <>
      <Search>
        <Input
          maxLength={50}
          allowClear
          placeholder="请输入单号，门店名称"
          onChange={(e) =>
            action.setCondition({
              dispatch,
              key: "keywords",
              value: e.target.value,
            })
          }
        />
        <input type="button" value="快速查找" onClick={() => onSearch(1)} />

        <TipsGroup>
          <input
            type="button"
            value="高级查找"
            onClick={() => searchFunc.toggle()}
          />
          <Tips width="536px" display={searchState}>
            <ReportSearch
              onSearch={(...arg: [any]) => {
                onSearch(...arg);
                searchFunc.setFalse();
              }}
            />
          </Tips>
          <Mask visible={searchState} onClick={() => searchFunc.setFalse()} />
        </TipsGroup>
      </Search>

      <ButtonPanel>
        {menuButton.indexOf("ADD") != -1 && (
          <Button
            onClick={() => {
              formParams.id = "";
              formParams.method = "Create";

              createFunc.setTrue();
            }}
          >
            新增单据
          </Button>
        )}
        {menuButton.indexOf("EXPORT") != -1 && (
          <Button
            onClick={async () => {
              await action.exportList({ search: state.search });
            }}
          >
            导出
          </Button>
        )}
      </ButtonPanel>

      <DataTable
        columns={columns}
        action={action}
        onLoad={async () => {
          await onSearch(1);
          Progress.done();
        }}
        onChange={(pageIndex) => {
          onSearch(pageIndex);
        }}
        scroll={{ scrollToFirstRowOnChange: true, autoY: true, x: 1060 }}
        loading={tableState.loading}
      />
      <TableExpand>
        单据数量合计: {count("number").toFixed(3)}
        <span style={{ marginLeft: 10 }}>
          单据金额合计: ¥ {count("money").toFixed(2)}
        </span>
      </TableExpand>

      {createState && (
        <ReportFormStoreProvider>
          <Form
            audit={menuButton.indexOf("AUDIT") != -1 ? true : false}
            close={(hasUpdate) => {
              if (hasUpdate) {
                onSearch(tableState.current);
              }

              createFunc.setFalse();
            }}
            action={formGoodsAction}
            id={formParams.id}
            subId={formParams.subId}
            item={formParams.item}
            method={formParams.method}
          />
        </ReportFormStoreProvider>
      )}

      <ConfirmModal
        visible={confirmState}
        title="温馨提示"
        subTitle="确认删除？"
        message="删除之后信息无法恢复，请慎重考虑"
        close={async (r) => {
          if (r) {
            await action.delRow(formParams.id);

            onSearch(tableState.current);
          }
          confirmFunc.setFalse();
        }}
      />
    </>
  );
}
