import message from "antd/lib/message"
import { DataTableAction } from "../../../../../component/table/action/data_table_action"
import { MarketingPointsExchangeGoods_logic } from "../../../../../logic/market/MarketingPointsExchangeGoods_logic"
import {CouponLogic} from '../../../../../logic/coupon_logic'
class FileAction extends DataTableAction {

    async delRow(id) {
        const r = await new CouponLogic().delData(id);

        if (r.data.statusCode == 200) {
            message.success("删除成功")
        } else {
            message.error("删除失败")
        }
    }

    delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {
        //
    }

    async loadPage({ dispatch, pageIndex, pageSize, search }) {
        this.setLoading({dispatch,loading: true})
        const {seacherKey } = search;
        const params: any = {
            'seacherKey':seacherKey,
            "pageIndex": pageIndex,
            "pageSize": pageSize,
            "orderBy": '',
            "sort": '',
        }
        if(seacherKey == undefined){
            delete params.seacherKey
        }
        // 档案列表
        const r = await MarketingPointsExchangeGoods_logic.QueryPage(params)

        if (r.data.statusCode == 200) {
            const dataList = r.data.data.dataSource || [];
            //
            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount || 0, dataList: dataList, pageSize });
        }
        this.setLoading({dispatch,loading: false})
    }

    setCondition({ dispatch, key, value }) {
        const params = {
        }
        params[key] = value;
        //
        dispatch({ type: "SetSearch", payload: params })
    }


    async addExchange({id,subId,barCode}){
        const r = await MarketingPointsExchangeGoods_logic.AddGoodsExchange({GoodsID:id, SepID: subId,BarCode: barCode})
        if(r&&r.data&&r.data.statusCode == 200){
            message.success('添加成功')
        }else{
            message.error(r.data&&r.data.returnMessage)
        }
    }
    async upExchange(id){
        const r = await MarketingPointsExchangeGoods_logic.Shelves({ID: id})
        if(r&&r.data&&r.data.statusCode == 200){
            message.success('上架成功')
        }else{
            message.error(r.data&&r.data.returnMessage)
        } 
    }
    async downExchange(id){
        const r = await MarketingPointsExchangeGoods_logic.OfftheShelf({ID: id})
        if(r&&r.data&&r.data.statusCode == 200){
            message.success('下架成功')
        }else{
            message.error(r.data&&r.data.returnMessage)
        } 
    }
    async stopExchange(id){
        const r = await MarketingPointsExchangeGoods_logic.VoidtheShelf({ID: id})
        if(r&&r.data&&r.data.statusCode == 200){
            message.success('作废成功')
        }else{
            message.error(r.data&&r.data.returnMessage)
        } 
    }
}
export const fileAction = new FileAction();