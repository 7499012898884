import { message } from "antd";
import axios from "axios";
import Cookies from "js-cookie";
import { DataTableAction } from "../../table/action/data_table_action";
import { Tools } from "../../../config";
import { CouponLogic } from "../../../logic/coupon_logic";
class ChooseAction extends DataTableAction {
    async delRow(id) {
        const r = await new CouponLogic().delData(id);

        if (r.data.statusCode == 200) {
            message.success("删除成功")
        }
    }
    delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {
        
    }
    async loadPage({ dispatch, pageIndex, pageSize, search }) {

        const { couponName, couponType, status,getCouponType } = search;
        const params: any = {
            "pageIndex": pageIndex,
            "pageSize": pageSize,
            "couponName":couponName,
            "getCouponType":getCouponType?getCouponType:2,
            "status":status==1?true:status==2?false:'',
            "orderBy":'',
            "sort":'',
        }
        // 档案列表
        const r = await new CouponLogic().selListByRecord(params);

        if (r.data.statusCode == 200) {
            const dataList = r.data.data.dataSource;

            const list = dataList.map((v, i) => {
                v.key = i + 1;
                v.validEndDate = v.validEndDate!=null?v.validEndDate.slice(0,10):''
                v.validStartDate = v.validStartDate!=null?v.validStartDate.slice(0,10):''
                return v;
            })

            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: list, pageSize });
        }

    }
 
    setCondition({ dispatch, key, value }) {
        const params = {
        }
        params[key] = value;

        dispatch({ type: "SetSearch", payload: params })
    }
    

}
export const chooseCouponAction = new ChooseAction();