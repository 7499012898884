import { message } from "antd";
// import axios from "axios";
import { saveAs } from 'file-saver';
import Cookies from "js-cookie";
import { Json } from "stb-tools";
import { DataTableAction } from "../../../../component/table/action/data_table_action";
import { Tools, axios } from "../../../../config";
import { DossierLogic } from "../../../../logic/dossier_logic";
import { GradeLogic } from "../../../../logic/grade_logic";
import { TagLogic } from "../../../../logic/tag_logic";


export class FileAction extends DataTableAction {
    private GradeLgc = new GradeLogic();
    private TagLogic = new TagLogic();
    async initBasic({ dispatch, state }) {
        let gradeList = await this.GradeLgc.gradeList()
        let obj = {
            gradeName: '全部',
            id: ''
        }
        gradeList.unshift(obj)
        dispatch({ type: "LoadGrade", payload: gradeList });
        dispatch({ type: "LoadTag", payload: await this.TagLogic.querysList() });
    }
    async delRow({ dispatch, rowItem }) {
        const r = await axios({
            method: "POST",
            url: Tools.getApiAddress("menber_delete"),
            data: {
                MemberID: rowItem.id.id
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })
        if (r.data.statusCode == 200) {
            message.success("删除成功")
        } else {
            message.error(r.data.returnMessage)
        }
    }
    async delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {
        const lis = selectList.map(async ({ id }) => {
            return axios({
                method: "POST",
                url: Tools.getApiAddress("menber_delete"),
                data: {
                    MemberID: id
                },
                headers: {
                    SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
                },
            })
        })

        await Promise.all(lis);

        message.success("删除成功");
    }
    // 导出
    async exportList({ search }) {
        const { keywords, membeClassID, label, source, status, addDateTimeStart, addDateTimeEnd, birthdayStart, birthdayEnd } = search;
        const r = await axios({
            method: "POST",
            url: Tools.getApiAddress("menber_query_export"),
            responseType: 'arraybuffer',
            data: {
                keyWords: keywords,
                status: status == 0 ? '' : status,
                membeClassID: membeClassID,
                gender: '',
                birthdayStart: birthdayStart,
                birthdayEnd: birthdayEnd,
                source: source == 0 ? '' : source,
                label: label,
                addDateTimeStart: addDateTimeStart,
                addDateTimeEnd: addDateTimeEnd,
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })
        if (r.status == 200) {
            let enc = new TextDecoder('utf-8')
            let data = enc.decode(new Uint8Array(r.data))
            if (data.indexOf('statusCode') != -1) {
                let datas = JSON.parse(data)
                if (datas.statusCode == 200) {
                    let blob = new Blob([r.data], { type: 'application/vnd.ms-excel' })
                    saveAs(blob, "会员档案.xlsx");
                } else {
                    message.error(datas.returnMessage)
                }
            } else {
                let blob = new Blob([r.data], { type: 'application/vnd.ms-excel' })
                saveAs(blob, "会员档案.xlsx");
            }

        }

    }

    async loadPage({ dispatch, pageIndex, pageSize, search }) {
        this.setLoading({dispatch, loading:true})
        // 会员列表
        const { keywords, membeClassID, label, source, status, addDateTimeStart, addDateTimeEnd, birthdayStart, birthdayEnd } = search;
        const params = {
            pageIndex: pageIndex,
            pageSize: pageSize,
            keyWords: keywords,
            gender: '',
            status: status == 0 ? '' : status,
            membeClassID: membeClassID,
            label: label,
            source: source == 0 ? '' : source,
            addDateTimeStart: addDateTimeStart,
            addDateTimeEnd: addDateTimeEnd,
            birthdayStart: birthdayStart,
            birthdayEnd: birthdayEnd,
        };
        const r = await new DossierLogic().querysList(params);

        if (r.data.statusCode == 200) {
            const dataList = r.data.data.dataSource;

            const list = dataList.map((v, i) => {
                v.source = v.source == 1 ? '管理端' : v.source == 2? '客户端' : '小程序'
                v.status = v.status === 1 ? '挂失' : v.status === 2 ? '停用' : '正常'
                v.gender = v.gender === 1 ? '男' : '女'
                v.key = i + 1;
                return v;
            })
            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: list, pageSize, loading: false });
        } 
        else{
            this.setLoading({dispatch, loading: false})
        }

    }
    setCondition(method: "Keywords" | "MembeClassID" | "Label" | "Source" | "Status" | "AddDateTimeStart" | "AddDateTimeEnd" | "BirthdayStart" | "BirthdayEnd", value, dispatch) {

        if ("Keywords" == method) {
            dispatch({ type: "SetSearch", payload: { keywords: value } });
        }
        else if ("MembeClassID" == method) {
            dispatch({ type: "SetSearch", payload: { membeClassID: value } });
        }
        else if ("Label" == method) {
            dispatch({ type: "SetSearch", payload: { label: value } });
        }
        else if ("Source" == method) {
            dispatch({ type: "SetSearch", payload: { source: value } });
        }
        else if ("Status" == method) {
            dispatch({ type: "SetSearch", payload: { status: value } });
        }
        else if ("AddDateTimeStart" == method) {
            dispatch({ type: "SetSearch", payload: { addDateTimeStart: value } });
        }
        else if ("AddDateTimeEnd" == method) {
            dispatch({ type: "SetSearch", payload: { addDateTimeEnd: value } });
        }
        else if ("BirthdayStart" == method) {
            dispatch({ type: "SetSearch", payload: { birthdayStart: value } });
        }
        else if ("BirthdayEnd" == method) {
            dispatch({ type: "SetSearch", payload: { birthdayEnd: value } });
        }
    }
   async resetPwd({dispatch,id}){
        const res = await new DossierLogic().resetPwd(id)
        if(res.data.statusCode === 200){
            message.success('重置成功')
        }else{
            message.error(res.data.returnMessage)
        }
    }
}
export const fileAction = new FileAction();