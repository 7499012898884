import { Result, message } from "antd";
import { Button } from "../../component/button";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { CopyOutlined } from '@ant-design/icons'

export function ApplyFapiaoSuccess(){
  const [flowNumber, setNum] = useState('')
    const his = useHistory()
    const   getUrlParams = (url :string):{num?: string} => {
      // 通过 ? 分割获取后面的参数字符串
      let urlStr = url.split('?')[1]
      // 创建空对象存储参数
    let obj = {};
      // 再通过 & 将每一个参数单独分割出来
    let paramsArr = urlStr.split('&')
    for(let i = 0,len = paramsArr.length;i < len;i++){
          // 再通过 = 将每一个参数分割为 key:value 的形式
      let arr = paramsArr[i].split('=')
      obj[arr[0]] = arr[1];
    }
    return obj
  }
  const handleCopy = e => {
    const textToCopy = e.modelPath
    // 创建一个虚拟input元素
    const input = document.createElement('input')
    input.value = flowNumber
    document.body.appendChild(input)
    // 选择输入框的内容
    input.select()
    input.setSelectionRange(0, 99999) // 兼容移动设备
    // 执行复制操作
    document.execCommand('copy')
    // 移除虚拟input元素
    document.body.removeChild(input)
    // 可以显示一条消息或者执行其他操作
    message.info('已复制到剪贴板！')
}
    useEffect(()=>{
      (async function () {
        document.title = "申请成功";
        let search = his.location.search
        let params = getUrlParams(search)  
        setNum(params.num)
      })()
      return()=>{
        document.title = "多点多生鲜新零售平台"
      }
    },[])
    return<>
    <Result
    status="success"
    title="提交申请成功！"
    // subTitle={`开票流水号为${flowNumber}，可保存便于查询开票信息。发票处理时长为1-3个工作日,请耐心等待。`}
    subTitle={
      <div style={{fontSize: 13}}>
        开票流水号为<span style={{color: '#1a1a1a',fontSize: 16}}>{flowNumber}</span><CopyOutlined style={{fontSize: 16, color: '#1890ff',marginLeft: 5}} onClick={()=>{
          handleCopy(flowNumber)
        }}/>，可保存便于查询开票信息。发票处理时长为1-3个工作日,请耐心等待。
      </div>
    }
  />
    </>
}