import message from "antd/lib/message"
// import axios from "axios"
import Cookies from "js-cookie"
import { DataTableAction } from "../../../../component/table/action/data_table_action"
import { Tools, axios } from "../../../../config"
import { SpecialLogic } from "../../../../logic/special_logic"

class WeckillAction extends DataTableAction {

    async delRow(id) {
        const r = await new SpecialLogic().delData(id);

        if (r.data.statusCode == 200) {
            message.success("删除成功")
        }
    }
    async updateStatus(params){
        const r = await new SpecialLogic().updateStatus(params)
        if (r&&r.data&&r.data.statusCode == 200) {
            message.success("终止成功")
        }else{
            message.error("更改失败")
        }
    }
    delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {
        selectList.map(async ({ id }) => {
            const r = await axios({
                method: "GET",
                url: Tools.getApiAddress("special_delete"),
                params: {
                    ID: id
                },
                headers: {
                    SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
                },
            })

            if (r.data.statusCode == 200) {
                message.success("删除成功")
            }
        })
    }
    async loadPage({ dispatch, pageIndex, pageSize, search }) {
        this.setLoading({dispatch,loading: true})
        const { goodsId, shopId, disState,purposeType,sort,activityName,useMemberClassID } = search;

        const params: any = {
            'activityName':activityName,
            'useMemberClassID':'',
            'activityStartDate':'',
            'activityEndDate':'',
            'discountType':'',
            'purposeType':!purposeType||purposeType==''?3:purposeType,
            'disState':'',
            "pageIndex": pageIndex,
            "pageSize": pageSize,
            "shopID":[],
            "goodsID":[],
            "memberClassID":[],
            "orderBy":'',
            "sort":'',
        }
       
        if(goodsId){
            if(goodsId.indexOf(',')){
                params.goodsID = goodsId.split(',')
            }else{
                params.goodsID.push(goodsId)
            }
        }
        if(shopId){
            params.shopID.push(shopId)
        }
        // 档案列表
        const r = await new SpecialLogic().selListByRecord(params);
        
        if (r.data.statusCode == 200) {
            const dataList = r.data.data.dataSource;

            const list = dataList.map((v, i) => {
                v['key'] = i + 1;
                v.activityStartDate = v.activityStartDate!=null?v.activityStartDate.slice(0,10):''
                v.activityEndDate = v.activityEndDate!=null?v.activityEndDate.slice(0,10):''
                v.activityStartTime = v.activityStartTime!=null?v.activityStartTime.slice(11,22):''
                v.activityEndTime = v.activityEndTime!=null?v.activityEndTime.slice(11,22):''
                return v;
            })

            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: list, pageSize });
        }  this.setLoading({dispatch,loading: false})

    }
    setCondition({ dispatch, key, value }) {
        const params = {
        }
        params[key] = value;
        dispatch({ type: "SetSearch", payload: params })
    }
}
export const specialAction = new WeckillAction();