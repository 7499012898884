import { createContext, useContext, useReducer } from "react";

export const FormStore = createContext<IContextProps>(null);
    export type FormAttribute = 'shopID' | 'wnN_shop_nick' | 'wnN_storage_code' | 'isMemberGrade' | 'memberClassTakeDay'
interface IContextProps {
    state: IState;
    dispatch: ({ type }: Dispatch) => void;
}

type Dispatch = { type, payload }

interface IState {
    shopID: string
    wnN_shop_nick?: string
    wnN_storage_code?: string
    isMemberGrade: boolean //是否启用自动降级
    memberClassTakeDay?: number
}

const initialArgs:IState = {
    shopID: undefined,
    wnN_shop_nick: undefined,
    wnN_storage_code: undefined,
    isMemberGrade: false,
    memberClassTakeDay: undefined
}

function reducer(state: IState, { type, payload }: Dispatch) {
    switch (type) {
        case "SetData":
            const {shopID, wnN_shop_nick, wnN_storage_code, isMemberGrade, memberClassTakeDay} = payload
            state.shopID = shopID
            state.wnN_shop_nick = wnN_shop_nick
            state.wnN_storage_code = wnN_storage_code
            state.isMemberGrade = isMemberGrade
            state.memberClassTakeDay = isMemberGrade ? memberClassTakeDay : undefined
            return{
                ...state
            }
        case 'Change_Data':
            return {
                ...state,
                ...payload
            }
        case "Reset":{
            state.shopID = state.shopID
            state.wnN_shop_nick = ''
            state.wnN_storage_code = ''
            state.isMemberGrade = false
            state.memberClassTakeDay = undefined
            return{
                ...state
            }
        }
        default:
            return state;
    }
}

export function FormStoreProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialArgs);

    const v = { state, dispatch };
    return <FormStore.Provider value={v}>{props.children}</FormStore.Provider>
}