import { message } from "antd";
import { DataTableAction } from "../../../component/table/action/data_table_action";
import { OrderLogic } from "../../../logic/order_logic";
import { ShopLogic } from "../../../logic/shop_logic";
import { FormAttribute } from "../store/form_store";
import { Order } from "..";

type Init = {
    state;
    dispatch;
    form;
    id;
    orderNumber;
}
interface InitBasic {
    dispatch;
    state;
}
interface InitEdit {
    state;
    dispatch;
    form;
    id;
    orderNumber;
}
interface ISetValue {
    attribute: FormAttribute;
    value: string | number | any[];
    dispatch;
}

export class FormAction extends DataTableAction{
    delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {
        
    }

    async init({ state, dispatch, id, form , orderNumber}: Init) {
        await this.initEdit({ dispatch, state, id, form , orderNumber})
    }
    private async initEdit({ dispatch, id, form, orderNumber }: InitEdit) {
        // 回填状态
        const item = await new OrderLogic().selData(id,false);
        
        // const r = await new OrderLogic().selDataMarketingInfo(orderNumber);
     if(item){
        let arr = []
        if(item && item.orderMarketing && item.orderMarketing.wholeMarketing&&item.orderMarketing.wholeMarketing.length>0){
            item.orderMarketing.wholeMarketing.map(ss=>{
                if(ss.giveGoods.length>0){
                   ss.giveGoods.map(ssg=>{
                       arr.push({
                           ...ssg,
                           ...ssg.sepID
                       })
                   })
                }
            })
        }
        item['giveGoods'] = arr
       let arr_detail = []
       if(item.detailVms.length>0){
           item.detailVms.map(de=>{
               arr_detail.push({
                   ...de,
                   outInventoryCount:de.goodsM.outInventoryCount,
                   outInventoryUnitName: de.goodsM.outInventoryUnitName,
                   unitContent: de.goodsM.unitContent,
                   unitName: de.goodsM.unitName
               })
           })
       }
       item['detailVms'] = arr_detail
       if(item.order_Address){
            item['receiptName'] = item.order_Address.receiptName
            item['receiptPhone'] = item.order_Address.receiptPhone
            item['address'] =   item.order_Address.province+item.order_Address.city+item.order_Address.county+item.order_Address.town+item.order_Address.detailed_Address
       }
       
        dispatch({ type: "SetForm", payload: item });
     }
    }
    async getFapiaoLogs(id){
        const r = await new OrderLogic().getFapiaoLogs(id)
        if(r.data.statusCode == 200){
            return r.data.data || []
        }else{
            message.error(r.data.returnMessage)
            return []
        }
    }
}
export const formAction = new FormAction();