import moment from "moment";
import { createContext, useContext, useReducer } from "react";

export const FormStore = createContext<IContextProps>(null);

interface IContextProps {
  state: IState;
  dispatch: ({ type }: Dispatch) => void;
}

type Dispatch = { type; payload };

export class FormData {
  ipAddress: string;
  equipmentName: string;
  id?: string;
}

interface IState {
  formData: FormData;
}

export const initialArgs: IState = {
  formData: new FormData(),
};

function reducer(state: IState, { type, payload }: Dispatch) {
  switch (type) {
    case "SetFormData": {
      return {
        ...state,
        formData: {
          ...state.formData,
          ...payload,
        },
      };
    }
    case "SetForm": {
      const { ipAddress, equipmentName } = payload;
      const item = new FormData();
      item.ipAddress = ipAddress;
      item.equipmentName = equipmentName;
      return {
        ...state,
        formData: item,
      };
    }
    default:
      return state;
  }
}

export function FormStoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialArgs);

  const v = { state, dispatch };
  return <FormStore.Provider value={v}>{props.children}</FormStore.Provider>;
}
