import { useBoolean } from "ahooks";
import { Col, Form, Form as FormAntd, Input, Row, Collapse, Modal } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useState } from "react";
import { Table } from "../../../../component/table";
import { TableExpand } from "../../../../component/table-expand";
import { TextArea } from "../../../../component/text-area";
import { autoRem } from "../../../../global.style";
import { NumberTool } from "../../../../tool/numberTool";
import { FormSubtitle } from "../../../commodity/view/form_subtitle";
import { formAction } from "../../../order/action/form_action";
import { FormStore } from "../../../order/store/form_store";
import styles from "../../style/form_basic.module.scss";

export function DataTable({ dataList }) {
  const { state, dispatch } = useContext(FormStore);
  const action = formAction;
  const { formData } = state;
  let allPrice = 0;
  let allDiscountPrice = 0;
  const columns: ColumnsType = [
    {
      title: "商品名称",
      dataIndex: "goodsName",
      key: "3",
      align: "center",
    },

    {
      title: "销售数量",
      dataIndex: "goodsNumber",
      key: "4",
      align: "center",
    },
    {
      title: "销售单位",
      dataIndex: "unitName",
      key: "5",
      align: "center",
    },

    {
      title: "出货数量",
      dataIndex: "outInventoryCount",
      key: "6",
      align: "center",
    },
    {
      title: "出货单位",
      dataIndex: "outInventoryUnitName",
      key: "7",
      align: "center",
    },
    {
      title: "商品原价",
      dataIndex: "originalPrice",
      key: "8",
      align: "center",
    },

    {
      title: "应付总金额",
      key: "9",
      align: "center",
      render: (v) => {
        return <span>{(v.originalPrice * v.goodsNumber).toFixed(2)}</span>;
      },
    },
    {
      title: "优惠总金额",
      dataIndex: "discountsPrice",
      key: "10",
      align: "center",
      render: (v) => {
        return <span>{v}</span>;
      },
    },
    {
      title: (
        <div>
          <div style={{ paddingBottom: autoRem(0.6) }}>优惠详情</div>
          <div className={styles.head}>
            <span>优惠活动</span>
            <span>活动名称</span>
            <span style={{ border: "none" }}>优惠金额</span>
          </div>
        </div>
      ),
      // dataIndex: 'expenditurePrice',
      key: "10",
      align: "center",
      render: (v) =>
        formData.orderMarketing.orderSingle.length > 0 && (
          <>
            <table className={styles.youhui}>
              <tbody>
                {formData.orderMarketing.orderSingle.map((item) => {
                  if (v.id == item.orderDetailID) {
                    return (
                      <tr>
                        <td align="center" width="33.3%">
                          {item.marketingTypeString}
                        </td>
                        <td align="center" width="33.3%">
                          {item.marketingName}
                        </td>
                        <td align="center" width="33.3%">
                          {" "}
                          -{item.marketingMoney}
                        </td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
          </>
        ),
    },
  ];

  return (
    <>
      <Table
        dataSource={dataList}
        columns={columns}
        pagination={false}
        scroll={{}}
      />
    </>
  );
}
function GiveGoodsDataTable({ dataList }) {
  const columns: ColumnsType = [
    {
      title: "商品名称",
      dataIndex: "goodsName",
      key: "1",
      align: "center",
    },

    {
      title: "赠送数量",
      dataIndex: "meetCount",
      key: "2",
      align: "center",
    },
    {
      title: "单位",
      // dataIndex: "originalPrice",
      key: "3",
      align: "center",
      render: (v) => (
        <span>
          {v.unitContent}/{v.unitName}
        </span>
      ),
    },

    {
      title: "商城价",
      dataIndex: "storePrice",
      key: "4",
      align: "center",
    },
    {
      title: "门店价",
      dataIndex: "shopPrice",
      key: "5",
      align: "center",
    },
  ];

  return (
    <>
      <Table
        dataSource={dataList}
        columns={columns}
        pagination={false}
        scroll={{}}
      />
    </>
  );
}
export function FormBasic({ disabled }) {
  const { state } = useContext(FormStore);
  const { formData } = state;
  const [list, setList] = useState([]);
  const { Panel } = Collapse;

  const getSum = (total, v) => {
    if (v) {
      // let multi = NumberTool.multiply(v.originalPrice, v.goodsNumber, 3)
      // let s = NumberTool.subtract(  , 3)
      // return NumberTool.add(total, s, 3)
    } else {
      return total;
    }
  };
  return (
    <div className={styles.root}>
      <div style={{ margin: "1.9375rem 0 0 20px" }} className={styles.content}>
        <FormSubtitle>基本信息</FormSubtitle>

        <Row gutter={24} style={{ marginTop: 10 }}>
          <Col span={6}>
            <FormAntd.Item label="订单编号" labelCol={{ span: 8 }}>
              <span>{formData.orderNumber}</span>
            </FormAntd.Item>
          </Col>
          <Col span={6}>
            <FormAntd.Item label="顾客类型" labelCol={{ span: 8 }}>
              <span>{1 === formData.expenditureType ? "会员" : "散客"}</span>
            </FormAntd.Item>
          </Col>
          <Col span={6}>
            <FormAntd.Item label="订单类型" labelCol={{ span: 8 }}>
              <span>{1 === formData.orderType ? "门店订单" : 2 === formData.orderType? "商城订单":'自助收银订单'}</span>
            </FormAntd.Item>
          </Col>
          <Col span={6}>
            <FormAntd.Item label="会员姓名" labelCol={{ span: 8 }}>
              <span>{formData.memberName}</span>
            </FormAntd.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <FormAntd.Item label="会员卡号" labelCol={{ span: 8 }}>
              <span>{formData.memberCard}</span>
            </FormAntd.Item>
          </Col>
          <Col span={6}>
            <FormAntd.Item label="手机号码" labelCol={{ span: 8 }}>
              <span>{formData.phone}</span>
            </FormAntd.Item>
          </Col>
          <Col span={6}>
            <FormAntd.Item label="订单状态" labelCol={{ span: 8 }}>
              {/* <span>{1 === formData.orderStatusString ? "完成" : "退单"}</span> */}
              <span>{formData.orderStatusString}</span>
            </FormAntd.Item>
          </Col>
          <Col span={6}>
            <FormAntd.Item label="退单状态" labelCol={{ span: 8 }}>
              {"1" == formData.salesReturnStatus && <span>已退</span>}
              {"2" == formData.salesReturnStatus && <span>未退</span>}
              {"3" == formData.salesReturnStatus && <span>部分退</span>}
            </FormAntd.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={6}>
            <FormAntd.Item label="操作用户" labelCol={{ span: 8 }}>
              {/* <span>{formData.addUserID}</span> */}
              <span>
                {formData.orderType == 1
                  ? formData.addUserID
                  : formData.orderType == 2
                  ? formData.memberName
                  : ""}
              </span>
            </FormAntd.Item>
          </Col>
          <Col span={6}>
            <FormAntd.Item label="添加时间" labelCol={{ span: 8 }}>
              <span>{formData.addDateTime}</span>
            </FormAntd.Item>
          </Col>
        </Row>
        {formData.orderType == 2 && (
          <Row gutter={24}>
            <Col span={6}>
              <FormAntd.Item label="收货人" labelCol={{ span: 8 }}>
                <span>{formData.receiptName}</span>
              </FormAntd.Item>
            </Col>
            <Col span={6}>
              <FormAntd.Item label="联系方式" labelCol={{ span: 8 }}>
                <span>{formData.receiptPhone}</span>
              </FormAntd.Item>
            </Col>
          </Row>
        )}
        {formData.orderType == 2 && (
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item name="address" label="收货地址" labelCol={{ span: 2 }}>
                <span>{formData.address}</span>
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name="remarks" label="备 注" labelCol={{ span: 2 }}>
              <span>{formData.remark}</span>
            </Form.Item>
          </Col>
        </Row>

        <FormSubtitle>商品明细</FormSubtitle>

        <div style={{ marginTop: 20 }}>
          <DataTable dataList={formData.detailVms || []} />
        </div>
        {formData.detailVms && (
          <TableExpand>
            <div style={{ fontSize: autoRem(1.2) }}>
              <div>
                {formData.orderMarketing &&
                  formData.orderMarketing.wholeMarketing &&
                  formData.orderMarketing.wholeMarketing.length > 0 && (
                    <span>
                      {/* 整单优惠：{formData.orderMarketing.wholeMarketing.map(item=>{
                                           
                                            return(
                                                <div className={styles.wholeDiscount}>
                                                <span  className={styles.wholediscount_items}>活动类型：{item.marketingTypeString} </span>
                                                <span className={styles.wholediscount_items}>活动名称：{item.marketingName}</span>
                                                <span className={styles.wholediscount_items}>优惠金额：<span style={{color:'#ffc80b'}}>￥{item.marketingMoney}</span></span>
                                                </div>
                                            )                                            
                                        })} */}
                      <Collapse ghost expandIconPosition="right">
                        <Panel header="整单优惠" key="1">
                          {formData.orderMarketing.wholeMarketing.map(
                            (item) => {
                              // if(item.giveGoods.length>0){
                              //     // setList(item.giveGoods)
                              //     list1= item.giveGoods
                              // }
                              return (
                                <div className={styles.wholeDiscount}>
                                  <span className={styles.wholediscount_items}>
                                    活动类型：{item.marketingTypeString}{" "}
                                  </span>
                                  <span className={styles.wholediscount_items}>
                                    活动名称：{item.marketingName}
                                  </span>
                                  {item.activityManner != 2 && (
                                    <span
                                      className={styles.wholediscount_items}
                                    >
                                      优惠金额：
                                      <span style={{ color: "#ffc80b" }}>
                                        -￥{item.marketingMoney}
                                      </span>
                                    </span>
                                  )}
                                  {item.activityManner == 2 && (
                                    <span
                                      className={styles.wholediscount_items}
                                    >
                                      加价金额：
                                      <span style={{ color: "#ffc80b" }}>
                                        +￥{item.fareIncrease}
                                      </span>
                                    </span>
                                  )}
                                </div>
                              );
                            }
                          )}
                        </Panel>
                      </Collapse>
                    </span>
                  )}
              </div>
              <div style={{ marginTop: 10 }}>
                商品总数：
                <span className={styles.order_num} style={{ color: "#ffc80b" }}>
                  {(
                    formData.detailVms.map((v) => (v ? v.goodsNumber : 0)) || []
                  )
                    .reduce((p, c) => p + c, 0)
                    .toFixed(2)}
                </span>{" "}
              </div>

              <div style={{ marginTop: 10 }}>
                金额合计：
                <span className={styles.order_num} style={{ color: "#ffc80b" }}>
                  ￥{formData.goodsOldPrice}
                </span>
              </div>

              {formData.payIntegral && formData.payIntegral > 0 && (
                <div style={{ marginTop: 10 }}>
                  消耗积分：
                  <span className={styles.order_num}>
                    {formData.payIntegral}
                  </span>
                </div>
              )}
              {formData.totoMarketingPrice &&
              formData.totoMarketingPrice > 0 ? (
                <div style={{ marginTop: 10 }}>
                  总优惠：
                  <span
                    className={styles.order_num}
                    style={{ color: "#ffc80b" }}
                  >
                    -￥{formData.totoMarketingPrice}
                  </span>
                </div>
              ) : null}

              {formData.payLogs && (
                <>
                  <div style={{ marginTop: 10 }}>
                    {/* 商品原价：<span className={styles.order_num} style={{color:'#ffc80b'}}>￥{formData.goodsOldPrice}</span> */}

                    {formData.payLogs.length > 0 && (
                      <>
                        {" "}
                        支付方式：
                        {formData.payLogs.map((item) => {
                          switch (item.paymentMethod) {
                            case 1:
                              return <span>支付宝</span>;
                            case 2:
                              return <span>微信</span>;
                            case 3:
                              return <span>银联</span>;
                            case 4:
                              return <span>现金</span>;
                            case 5:
                              return <span>钱包余额</span>;
                          }
                        })}
                      </>
                    )}
                  </div>
                  <div style={{ marginTop: 10 }}>
                    {formData.payLogs.length == 0 && (
                      <>
                        '应付：
                        <span
                          className={styles.order_num}
                          style={{ color: "#ffc80b" }}
                        >
                          ￥{formData.sellingPrice}
                        </span>
                      </>
                    )}
                    {formData.payLogs.length > 0 && (
                      <>
                        实收：
                        <span
                          className={styles.order_num}
                          style={{ color: "#ff746d" }}
                        >
                          ￥{formData.sellingPrice}
                        </span>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </TableExpand>
        )}
        {formData.giveGoods.length > 0 && (
          <>
            <FormSubtitle>赠品明细</FormSubtitle>

            <div style={{ marginTop: 20 }}>
              <GiveGoodsDataTable dataList={formData.giveGoods} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
