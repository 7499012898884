 import axios from 'axios'
 import {Tools} from '../../../config/config.tool'
 import {ConfigApi} from '../../../config/config.api'
 class HelpAction{
     init = async (dispatch) => {
         let list = []
        const r = await axios({
            method: "GET",
            url: Tools.getApiAddress("queryMenuHelp").replace(
              ConfigApi.serviceDomain,
              ConfigApi.operationDomain
            ),
          
          });
          
        if(r.data.code == 200){
            let arr = r.data.data || []
            arr.length>0 && arr.map(item=>{
               if(item.level == 0){

               }
            })
            list = r.data.data
        }
        dispatch({type:'Init',payload:list})
    }
    search = async (state,dispatch) => {
        let list = []
        const r = await axios({
            method: "GET",
            url: Tools.getApiAddress("querySearchHelp").replace(
              ConfigApi.serviceDomain,
              ConfigApi.operationDomain
            ),
          params:{
            SeacheKey: state.search.key
          }
          });
          
        if(r.data.code == 200){
            let list = r.data.data || []
            let datalist = []
            list.length>0&&list.map((item,index)=>{   
             let tab,menu,submenu = null
             let arr ;
             outer: for(let i = 0 ;i<state.menuList.length;i++){
                let obji = state.menuList[i]
                if(obji.childrens){            
                  let res = obji.childrens.filter(child=>child.ID == item.MenuID)[0]
                  if(res){
                 tab = i
                    arr = res
                  }else{
                    for(let j =0;j<obji.childrens.length;j++){
                      let secondMenu = obji.childrens[j]
                          if(secondMenu.childrens){
                            let level3 = secondMenu.childrens.filter(child=>child.ID == item.MenuID)[0]
                            if(level3){
                 tab = i

                              menu = j
                              arr = level3
                              break outer
                            }
                          }
                        
                    }  
                  }
                              
                }
         }
  // console.log(tab,menu,index,item.ID,arr)

if(arr!=undefined &&arr){
    if(menu==undefined || menu==null){
      datalist.push({
        CreateTime:item.CreateTime,
        Title:item.Title,
        ContentType:item.ContentType,
        ...arr,
        level0ID:state.menuList[tab].ID,
        tabIndex:tab,
        level1ID:arr.ID,
        menuTurn:{secondMenuName:arr.MenuName,thirdMenuName:null}
        // state.menuList[tab].MenuName + '-->' +arr.MenuName
      })
    }else{
      datalist.push({
        CreateTime:item.CreateTime,
        Title:item.Title,
        ...arr,
        ContentType:item.ContentType,
        tabIndex:tab,
        level0ID:state.menuList[tab].ID,
        level1ID:state.menuList[tab].childrens[menu].ID,
        menuTurn:{secondMenuName:state.menuList[tab].childrens[menu].MenuName,thirdMenuName:arr.MenuName}
        // state.menuList[tab].MenuName + '-->' +state.menuList[tab].childrens[menu].MenuName+ '-->' +arr.MenuName
    })
  }
}
      

            })
            console.log(datalist)
            dispatch({type:'searchList',payload:datalist})
        }
       
    }
   private getParent(list,state){
    
     
   }
}
export const  helpAction = new HelpAction()