import styles from "../style/index.module.scss";
import { DatePicker, Select } from "antd";
import { useContext, useEffect, useState } from "react";
import { useRequest } from "ahooks";
import { FileStore } from "../store";
import { fileAction } from "../action";
import moment from "moment";
export function Search() {
  const { RangePicker } = DatePicker;
  const progressNav = ["按月", "按日"];
  const [active, setActive] = useState(0);
  const { state, dispatch } = useContext(FileStore);
  // const { item } = state;
  // const [shopState,shopFunc] =  useBoolean(false)
  const action = fileAction;
  const { Option } = Select;
  useEffect(() => {
    // action.init({state,dispatch})
  });
  const [dates, setDates] = useState([]);
  const disabledDate = (current) => {
    if (!dates || dates.length === 0 || state.condition.dateType == 0) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], "days") > 31;
    const tooEarly = dates[1] && dates[1].diff(current, "days") > 31;
    return tooEarly || tooLate;
  };

  return (
    <div style={{ margin: "20px 0", width: "100%" }}>
      {/* <div className='selectBox'>
              <Select   style={{ width: "12rem"}}  autoFocus
                                onChange={(v) => {}}
                                defaultValue={-1}
                                showSearch
                                filterOption={(input:any, option:any) => {
                                    return option.children.indexOf(input) >= 0;
                                }}
                            >
                                <Option value={0}>按月</Option>
                                <Option value={1}>按日</Option>
                            </Select>
              </div> */}
      <div className={styles.btns_c}>
        {progressNav.map((v, i) => (
          <span
            key={i}
            className={`${styles.item} ${active == i ? styles.active : ""}`}
            onClick={() => {
              // 0月1日
              action.setType(i, {
                condition: state.condition,
                dispatch,
                state,
              });
              setActive(i);
            }}
          >
            {v}
          </span>
        ))}
        {
          <RangePicker
            value={[
              moment(state.condition.startTime || new Date()),
              moment(state.condition.endTime || new Date()),
            ]}
            className={styles.date}
            onChange={(v, arr) => {
              action.setDate(
                { startTime: arr[0], endTime: arr[1] },
                { condition: state.condition, dispatch, state }
              );
            }}
            disabledDate={disabledDate}
            onCalendarChange={(val) => setDates(val)}
          />
        }
      </div>
    </div>
  );
}
