import { useBoolean, useDebounceFn } from "ahooks";
import { Button, Input, message, Tag, Spin, Select } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
} from "react";
import Cookies from "js-cookie";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { DataTable } from "../../../../component/table";
import { fileAction } from "../../action/commission_logs_action";
import { FormStoreProvider } from "../../store/form_store";
import { Form } from "../form";
import { formAction } from "../../action/form_action";
import { DataTableStore } from "../../../../component/table/store/data_table_store";
import { Search } from "../../../../component/search";
import { Tips, TipsGroup } from "../../../../component/tips";
import { FileSearch } from "./file_search";
import { FileStore } from "../../store/commission_logs_store";
import { Mask } from "../../../../component/mask";
import { defualtPageSize, Progress } from "../../../../logic/common_logic";
import { AuthLogic } from "../../../../logic/auth_logic";
import { Control, Edit, Refund, See,Print, Stop } from "../../../../component/control-column";
import { ConfirmModal } from "../../../../component/modal";
const formParams = {
  method: "Create",
  id: "",
  orderNumber: "",
  state: 0,
  orderType: 2,
  toState: 2,
  returnState:null
};
const fileParams = {
  id: '',
  date: ''
}
export function File(viewPower) {
  const [createState, createFunc] = useBoolean(false);
  const [confirmState, confirmFunc] = useBoolean(false);
  const [loading, loadingFunc] = useBoolean(false)
  const action = fileAction;
  const { state: tableState, dispatch: tableDispatch } = useContext(DataTableStore);
  const [searchState, searchFunc] = useBoolean(false);
  const { state, dispatch } = useContext(FileStore);
  const [menuButton, setData] = useState([]);
  useEffect(() => {
    (async function () {
      dispatch({type:'isloading',payload:{isLoading: false,msg:''}});
      let r = await new AuthLogic().selButton(viewPower.viewPower);
      if (r && r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          r[i] = r[i].split("_")[r[i].split("_").length - 1];
        }
      }
      r=['CANCEL']
      setData(r);
    })();
    return () => {
      dispatch({type:'isloading',payload:{isLoading: false,msg:''}})
    };
  }, []);
 
  const columns: ColumnsType = [
    {
      title: '操作',
      key: '0',
      align: 'center',
      width: Control.width(1),
      render:  Control.render(
        (v) =>
          menuButton.indexOf("CANCEL") != -1 && v.status == 1 && (
            <Stop tips="作废"
              click={() => {
                fileParams.id = v.id;
                fileParams.date = v.addTime
                confirmFunc.setTrue()
              }}
            />
          ),
      )
    },
    {
      title: "订单单号",
      dataIndex: "orderNumber",
      key: "1",
      align: "center",
      width: 180,
      render: (v, r: any)=><span style={{color: '#1890ff', cursor: 'pointer'}} onClick={()=>{
        formParams.id = r.expenditureID;
        formParams.method = "Preview";
        formParams.orderNumber = r.orderNumber;
        // formParams.state = r.state;
        // formParams.orderType = r.orderType;
        createFunc.setTrue()
      }}>{v}</span>
    },
    {
      title: "提成规则编码",
      dataIndex: "ruleNumber",
      key: "2",
      align: "center",
      width: 120,
    },
    {
      title: "提成规则等级",
      dataIndex: "ruleGrade",
      key: "3",
      align: "center",
      width: 120,
    },
    {
      title: "提成规则名称",
      dataIndex: "ruleName",
      key: "4",
      align: "center",
      width: 100,
      ellipsis: true
    },
    {
      title: "提成方式",
      dataIndex: "ruleCtype",
      key: "5",
      align: "center",
      width: 80,
      render: v=>{ return v == 1?<Tag color="cyan">固定金额</Tag>: v == 2 ? <Tag color="blue">百分点</Tag>: <span>-</span>}
    },
    {
      title: "提成值",
      dataIndex: "commissionValue",
      key: "6",
      align: "center",
      width: 100,
    },
    {
      title: "订单实收金额",
      dataIndex: "orderSellingPrice",
      key: "7",
      align: "center",
      width: 100,
    },
    {
      title: "初始提成金额",
      dataIndex: "originalCommissionMoney",
      key: "8",
      align: "center",
      width: 100,
    },
    {
      title: "取消提成金额",
      dataIndex: "canalCommissionMoney",
      key: "9",
      align: "center",
      width: 100,
      render: (v) => <span>{v}</span>,
    },
    {
      title: "有效提成金额",
      dataIndex: "actualCommissionMoney",
      key: "10",
      align: "center",
      width: 100,
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "11",
      align: "center",
      width: 100,
      render: v=>{ return v == 1?<Tag color="success">正常</Tag>: <Tag color="error">作废</Tag>}
    },
    {
      title: "收银用户ID",
      dataIndex: "userID",
      key: "12",
      align: "center",
      width: 100,
    },
     {
      title: "收银用户",
      dataIndex: "userName",
      key: "13",
      align: "center",
      width: 100,
    },
    {
      title: "创建时间",
      dataIndex: "addTime",
      key: "14",
      align: "center",
      width: 130,
    },
    {
      title: "备注",
      dataIndex: "remark",
      key: "15",
      align: "center",
      width: 130,
      ellipsis: true,
    },
  ];

  const onSearch = async (pageIndex) => {
    await action.loadPage(
      {
        pageIndex: pageIndex,
        pageSize: defualtPageSize(),
        dispatch: tableDispatch,
        search: state.search,
      },
      tableDispatch
    );
  };
  const {run } = useDebounceFn(fn=>{
    fn && fn()
  },{
    wait:1500
  })
  return (
    <>
      <Spin
        spinning={loading}
        size="large"
        wrapperClassName="isloading"
      >
        <Search>
          <Input
            allowClear
            placeholder="请输入订单号、收银员"
            onChange={(e) =>
              action.setCondition({key: 'seackerKey', value: e.target.value, dispatch})
            }
          />
          <input type="button" value="快速查找" onClick={() => onSearch(1)} />

          <TipsGroup>
            <input
              type="button"
              value="高级查找"
              onClick={() => searchFunc.toggle()}
            />
            <Tips width="536px" display={searchState}>
              <FileSearch
                onSearch={(...arg: [any]) => {
                  onSearch(...arg);
                  searchFunc.setFalse();
                }}
              />
            </Tips>
            <Mask visible={searchState} onClick={() => searchFunc.setFalse()} />
          </TipsGroup>
        </Search>
        <DataTable
          columns={columns}
          action={fileAction}
          // rowSelection={{ type: "checkbox" }}
          onLoad={async () => {
            await onSearch(1);
            Progress.done();
          }}
          onChange={(pageIndex) => {
            onSearch(pageIndex);
          }}
          loading={tableState.loading}
          scroll={{ scrollToFirstRowOnChange: true, autoY: true, x: 1060 }}
          selectColumn={true}
        />
        {createState && (
          <FormStoreProvider>
            <Form
              close={(hasUpdate) => {
                // if (hasUpdate) {
                //   onSearch(tableState.current);
                // }

                createFunc.setFalse();
              }}
              action={formAction}
              id={formParams.id}
              method={formParams.method}
              orderNumber={formParams.orderNumber}
              status={formParams.state}
              orderType={formParams.orderType}
              returnState={formParams.returnState}
            />
          </FormStoreProvider>
        )}
       <ConfirmModal
          visible={confirmState}
          title="温馨提示"
          subTitle="确认作废该条记录？"
          message="作废后不可更改，确认作废？"
          close={async (r) => {
            if (r) {
              loadingFunc.setTrue()
              await action.cancelCommission(fileParams.id, fileParams.date);
              loadingFunc.setFalse()
              onSearch(tableState.current);
            }
            confirmFunc.setFalse();

          }}
        />
      </Spin>
    </>
  );
}
