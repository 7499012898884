import { _tr, _td } from "../../tool/TableTool";
import { goodsSalesRankTK } from "../tableKey/goodsSalesRankTK";
import * as _ from "lodash"

const goodsSalesRankSubtotalTK = [...goodsSalesRankTK].splice(3);

export function GoodsSalesRankingTable({ data, ifLastPage }: { data, ifLastPage: boolean }) {
    if (!data) { return <div></div> }

    /** 获取合计 */
    const getSubtotalTabTds = (data): _tr => {
        /** 总结数据 */
        if (ifLastPage) {
            return <tr>
                <td className="dark" colSpan={3}>总计</td>
                {
                    goodsSalesRankSubtotalTK.map((item) => {
                        let num = _.sumBy(data, item.key);
                        return <td>{_.round(num, 2)}</td>
                    })
                }
            </tr> as any;
        }
        return;
    }

    const getTabTds = (): _tr[] => {
        return data.map((data) => {
            return goodsSalesRankTK.map((item, index) => {
                return <td key={index}>{data[item.key]}</td> as _td;
            });
        });
    };

    return <>
        <thead>
            <tr>
                {goodsSalesRankTK.map((item, index) => {
                    return <th key={index}>{item.title}</th>
                })}
            </tr>
        </thead>
        <tbody>
            {
                getTabTds().map((item, index) => {
                    return <tr key={index}>{item}</tr>
                })
            }
            {data.length > 0 && getSubtotalTabTds(data)}
        </tbody>
    </>;
}