// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../config";
import { effeative } from "./common_logic";

interface IOperation {
    "shopID": string;
    "source": number;
    "inventoryType": number;
    "inventoryStatus": number;
    "reason": number;
    "remark": string;
    "details": [
        {
            "goodsID": string;
            "goodsName": string;
            "goodsUnitID": string;
            "unitName": string;
            "inventoryType": number;
            "putPrice": number;
            "putNumber": number;
            "remark": string;
        }
    ]
}
interface ISelListByRecord {
    "source"?: number;
    "inventoryType"?: number;
    "inventoryStatus"?: number;
    "reason"?: number;
    "keyWords"?: string;
    "pageIndex"?: number;
    "pageSize"?: number;
    "orderBy"?: string;
    "sort"?: string;
}
interface ISelList {
    "seacherKey"?: string;
    "id"?: string;
    "pageIndex"?: number;
    "pageSize"?: number;
    "orderBy"?: string;
    "sort"?: string;
}
interface ISelListByRunwater {
    "keyWords"?: string;
    "goodsClass"?: string;
    "shopID"?: string;
    "startTime"?: string;
    "endTime"?: string;
    "pageIndex"?: number;
    "pageSize"?: number;
    "orderBy"?: string;
    "sort"?: string;
}
export class PrecisionLogic {
    async addOperation(params) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("precision_add"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    /**
     * 分页查询库存记录信息
     */
    async selListByRecord(params) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("precision_list"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    } 
    /** 
     * 分页查询活动参与明细
    */
    async selList(params) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("precison_activity_list"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    /**
     * 单个活动
     * @param ID 活动id
     * @returns 
     */
     async selData(ID: string) {
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("precision_getone"),
            params: {
                ID:ID
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            }
        }))
        if (r.data && r.data.data) {
            return r.data.data;
        }
    }
    /**
     * 更新活动状态
     * @param params 
     * @returns 
     */
    async updOperationStatus(params) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("precision_update_status"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            }
        }))
        return r;
    }
    /**
     * 编辑更新
     * @param params 
     * @returns 
     */
    async updOperation(params) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("precision_update"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            }
        }))
        return r;
    }
    /**
     * 删除
     * @param id 
     * @returns 
     */
    async delData(id: string) {
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("precision_delete"),
            params: {
                ID:id
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            }
        }))
        return r;
    }
}