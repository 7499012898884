import React from "react";
import styles from '../style/tabs_content.module.scss'
interface ITab {
    children: JSX.Element | JSX.Element[] | string;
}

TabPanel['nodetype'] = 'TabPanel';

export function TabPanel({ children }: ITab) {
   let height =  document.body.clientHeight - 188
    return (
        <div className={styles.tab_content} style={{maxHeight: height+'px',overflowY:'auto',minHeight: 300 + 'px'}}>{children}</div>
    )
}