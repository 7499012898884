import { message } from "antd";
import { PrintingLogic } from "../../../../logic/printing_logic";
import { FormAttribute } from "../../store/printing/file_store";
type Init = {
    state;
    dispatch;
    form;
}
interface InitBasic {
    dispatch;
    state;
}
interface InitEdit {
    state;
    dispatch;
    form;
}
interface ISetValue {
    attribute: FormAttribute;
    value: string | number | any[] | any;
    dispatch;
}
var id = ''
export class FormAction {

    private printingLgc = new PrintingLogic();

    async init({ state, dispatch, form }: Init) {
        await this.initEdit({ dispatch, state, form })
    }
    private async initEdit({ dispatch, form }: InitEdit) {
        // 回填状态
        const item = await this.printingLgc.steelyardgetParam();
        if (item != null) {
            dispatch({ type: "SetForm", payload: item });
            // 填充节点
            form.setFieldsValue({
                "print-state": item.state,
                "print-printType": item.printType,
                "print-left": item.left,
                "print-right": item.right,
                "print-lin": item.lin,
                "print-shopConjunctions": item.shop_Conjunctions,
                "print-shopingConjunctions": item.shoping_Conjunctions,
                "print-shopingPreSaleConjunctions": item.shopingPreSale_Conjunctions,
                "print-shopPayConjunctions": item.shopPay_Conjunctions,
                "print-memberRecharge": item.member_Recharge,
                "print-changeShiftsConjunctions": item.changeShifts_Conjunctions,
            });
        }
    }
    setValue({ attribute, value, dispatch }: ISetValue) {
        dispatch({ type: "Change_FormData", payload: { attribute, value } });
    }
    private paddingData(states) {
        let params: any = {};
        {
            const { id, state, printType, left, right, lin, shop_Conjunctions, shoping_Conjunctions, shopingPreSale_Conjunctions, shopPay_Conjunctions, member_Recharge, changeShifts_Conjunctions } = states;

            params = {
                id,
                state: state,
                printType: printType,
                left: left,
                right: right,
                lin:lin,
                shop_Conjunctions:shop_Conjunctions?shop_Conjunctions:0,
                shoping_Conjunctions:shoping_Conjunctions?shoping_Conjunctions:0,
                shopingPreSale_Conjunctions:shopingPreSale_Conjunctions?shopingPreSale_Conjunctions:0,
                shopPay_Conjunctions:shopPay_Conjunctions?shopPay_Conjunctions:0,
                member_Recharge:member_Recharge?member_Recharge:0,
                changeShifts_Conjunctions:changeShifts_Conjunctions?changeShifts_Conjunctions:0
            }
        }
        return params;
    }
    async addSubmit({ states, stateh, dispatch, form }) {
        let param = {
            id: this.paddingData(states).id,
            state: this.paddingData(states).state,
            printType: this.paddingData(states).printType,
            left: this.paddingData(states).left,
            right: this.paddingData(states).right,
            lin: this.paddingData(states).lin,
            shop_Conjunctions: this.paddingData(states).shop_Conjunctions,
            shoping_Conjunctions: this.paddingData(states).shoping_Conjunctions,
            shopingPreSale_Conjunctions: this.paddingData(states).shopingPreSale_Conjunctions,
            shopPay_Conjunctions: this.paddingData(states).shopPay_Conjunctions,
            member_Recharge: this.paddingData(states).member_Recharge,
            changeShifts_Conjunctions: this.paddingData(states).changeShifts_Conjunctions,
        }
        const r = await this.printingLgc.steelyardgetAdd(param);

        if (r.statusCode == 200) {
            message.success("设置成功");
            await this.initEdit({ dispatch, state: stateh, form })
        } else {
            message.error(r.message);
        }
    }

}
export const formAction = new FormAction();