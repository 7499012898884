import styles from "../style/com/selectUrl.module.scss";
import { useState, useContext } from "react";
import { Modal } from 'antd';
import { ISelectUrl, ShowData } from "../view/modal/modal";
import { TopicStore } from "../store/topic";
/**
 * 选择url的公共组件
 * @param props
 */
export function SelectUrl({
  select,
  ifShowTitle = true,
  selectedBack,
}: {
  /** 当前的选择 */
  select?: ISelectUrl;
  /** 选择url后的回调 */
  selectedBack: (select: ISelectUrl) => void;
  /** 是否显示标题 */
  ifShowTitle?: boolean;
}) {
  const topicStoreState = useContext(TopicStore);
  const [ifShow, setIfShow] = useState(false);

  const close = () => {
    setIfShow(false);
  }

  return (
    <>
      <Modal title={<div>绑定链接</div>} footer={null} visible={ifShow} width={1000} onOk={close} onCancel={close}  >
        <ShowData dispatch={topicStoreState.dispatch} state={topicStoreState.state} selectedBack={(select: ISelectUrl) => {
          selectedBack(select);
        }} close={close} />
      </Modal>
      {ifShowTitle && select && select.url && (
        <a className={styles.on} href={select.url} target="_blank">
          {select.type} | {substringStr(select.title)}
        </a>
      )}
      
      {select&&select.url?<div className={styles.select} onClick={() => { setIfShow(true); }} style={{background:'#02ca74',color:'#fff',letterSpacing:5}}>
        <span style={{display:'inline-block',margin:'0 auto'}}>请选择</span>
        {/* <i></i> */}
      </div>:<div className={styles.select} onClick={() => { setIfShow(true); }} >
        <span>请选择</span>
        <i></i>
      </div>}
    </>
  );
}

/**
 * 截取字符串
 * @param str
 * @param length
 * @returns
 */
function substringStr(str: string, length: number = 10): string {
  if (str.length <= length) {
    return str;
  }
  return str.substring(0, length) + "...";
}
