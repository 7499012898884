import { createContext, useReducer } from "react";

class FormData {
    gradeName: string;
    remark: string;
    useProportion: number = 100
    isLock: boolean = false
    classLevel: number
    exConsumptionMoney: number = undefined
}

type Dispatch = { type, payload }

interface IState {
    formData: FormData;
}

const initialArgs = {
    formData: new FormData()
}

export type FormAttribute = "GradeName" | "Remark"  | "useProportion" | "isLock" | "classLevel" | "exConsumptionMoney";

interface IContextProps {
    state: IState;
    dispatch: ({ type }: Dispatch) => void;
}

export const FormStore = createContext<IContextProps>(null);

function reducer(state: IState, { type, payload }: Dispatch) {
    switch (type) {
        // 填充表单
        case "SetForm":
            {
                const data = payload;
                const form = new FormData();
                {
                    const { gradeName, remark, useProportion, isLock, classLevel, exConsumptionMoney } = data;
                    form.gradeName = gradeName;
                    form.remark = remark;
                    form.useProportion = useProportion
                    form.isLock = isLock
                    form.classLevel = classLevel
                    form.exConsumptionMoney = exConsumptionMoney
                }

                return {
                    ...state,
                    formData: form
                }
            }
        // 设置表单
        case "Change_FormData":
            {
                const { attribute, value } = payload, props: any = {};

                const key: FormAttribute = attribute;
                let attri = "";

                switch (key) {
                    case "GradeName":
                        attri = "gradeName";
                        break
                    case "Remark":
                        attri = "remark";
                        break
                    case "useProportion":
                        attri = "useProportion";
                        break
                    case "isLock":
                        attri = "isLock";
                        break
                    case "classLevel":
                        attri = "classLevel";
                        break
                    case "exConsumptionMoney":
                        attri = "exConsumptionMoney";
                        break
                    default:
                        break;
                }

                props[attri] = value;

                return {
                    ...state,
                    formData: {
                        ...state.formData,
                        ...props
                    }
                }
            }
        default:
            return state;
    }
}

export function FormStoreProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialArgs);

    const v = { state, dispatch };
    return <FormStore.Provider value={v}>{props.children}</FormStore.Provider>
}