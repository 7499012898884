import React, { useContext, useRef, useEffect } from "react";
import styles from "../style/form_details.module.scss";
import { FormSubtitle } from "./form_subtitle";
import { message } from "antd";
// import axios from "axios";
import { Tools, axios } from "../../../config";
import Cookies from "js-cookie";
import { FormStore } from "../store/form_store";
import "braft-editor/dist/index.css";
import { formAction } from "../action/form_action";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { zip } from "../../../logic/common_logic";
import { Button } from "../../../component/button";
import { ChooseImage } from "../../../component/choose-image";
import { useBoolean } from "ahooks";
import { ButtonPanel } from "../../../component/button-panel/button_panel";
function Image({ max, role, value, imageUrl, dispatch, disabled, goodsSource }) {
  const inputRef = useRef(null);

  const onChange = async (e) => {
    let file = e.target.files[0];

    // 压缩
    file = await zip(file);

    const formData = new FormData();
    formData.append("file", file);

    const r = await axios({
      method: "POST",
      url: Tools.getApiAddress("goods_update_file"),
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data;charset=UTF-8",
        SYSTOKEN: Cookies.get("token"),'ClientSource':'web',
      },
    });

    if (r.data.statusCode == 200) {
      const imgUrl = r.data.data;

      if (imageUrl.length > max - 1) {
        imageUrl.length = max - 1;
      }

      let list = [...imageUrl, imgUrl];
      // 保存数据
      if (role == "main") {
        formAction.setValue({ dispatch, attribute: "GoodsImage", value: list });
      } else {
        formAction.setValue({ dispatch, attribute: "SubImages", value: list });
      }

      message.success("上传成功");
    } else {
      message.error(r.data.returnMessage);
    }

    inputRef.current.value = "";
  };

  const removeAt = (index) => {
    imageUrl.splice(index, 1);

    // 保存数据
    if (role == "main") {
      formAction.setValue({
        dispatch,
        attribute: "GoodsImage",
        value: [...imageUrl],
      });
    } else {
      formAction.setValue({
        dispatch,
        attribute: "SubImages",
        value: [...imageUrl],
      });
    }
  };

  return (
    <div className={styles.img_group}>
      <span
        onClick={(e) => {
          if (disabled) {
            e.preventDefault();
          } else inputRef.current.click();
        }}
      ></span>
      <input
        ref={inputRef}
        type="file"
        onChange={onChange}
        style={{ display: "none" }}
      />
      <div className={styles.img_list}>
        {imageUrl.map((v, i) =>
          v ? (
            <div>
              <img src={Tools.getImageAddress(v, goodsSource)} />
              {!disabled ? (
                <p
                  className={styles.side}
                  onClick={() => {
                    removeAt(i);
                  }}
                >
                  删 除
                </p>
              ) : null}
            </div>
          ) : null
        )}
      </div>
    </div>
  );
}

const Edit = ({ description, dispatch, disabled }) => {
  let reactQuillRef;
  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],
    ["link", "image", "video"],
    ["clean"], // remove formatting button
  ];

  useEffect(() => {
    reactQuillRef.editor.enable(!disabled);
  }, []);

  return (
    <div className="editor-wrapper">
      <ReactQuill
        modules={{ toolbar: toolbarOptions }}
        style={{ height: 230,width:'65%' }}
        ref={(el) => {
          reactQuillRef = el;
        }}
        theme="snow"
        value={description}
        onChange={(v) => {
          formAction.setValue({
            dispatch: dispatch,
            value: v,
            attribute: "Description",
          });
        }}
      />
    </div>
  );
};

export function FormDetails({ disabled }) {
  const { state, dispatch } = useContext(FormStore);
  const { formData } = state;

  const [mainState, mainImgFunc] = useBoolean(false);
  const [subState, subImgFunc] = useBoolean(false);
  return (
    <div className={styles.root}>
      <FormSubtitle>详情描述</FormSubtitle>

      <div className={styles.details}>
      <div className={styles.detail_img_box}>
      <div>
        <div className={styles.item}>
          <span className={styles.title}>商品主图</span>
          <span style={{color:'#ff746d '}}>
            建议尺寸400x400px，小于500kb，支持JPG、PNG、JPEG，最多上传1张
          </span>
        </div>

        <div>
          <Image
            max={1}
            role="main"
            value={[]}
            dispatch={dispatch}
            imageUrl={formData.goodsImage}            
            goodsSource={formData.goodsSource}
            disabled={disabled}
          />
          <div className={styles.selectImg}>
            <span>上传图片或者</span>
            <ButtonPanel>
              <Button
                disabled={disabled}
                onClick={() => {
                  mainImgFunc.setTrue();
                }}
              >
                选择图片
              </Button>
            </ButtonPanel>
          </div>
        </div>
        </div><div><div className={styles.item}>
          <span className={styles.title}>商品辅图</span>
          <span style={{color:'#ff746d '}}>
            建议尺寸400x400px，小于500kb，支持JPG、PNG、JPEG，最多上传5张
          {/* （拖动排序） */}
          </span>
        </div>
        <div>
          <Image
            max={5}
            role=""
            value={[]}
            dispatch={dispatch}
            imageUrl={formData.subImages}
            goodsSource={formData.goodsSource}
            disabled={disabled}
          />
             <div className={styles.selectImg}>
          <span>上传图片或者</span>
          <ButtonPanel>
            <Button
              disabled={disabled}
              onClick={() => {
                subImgFunc.setTrue();
              }}
            >
              选择图片
            </Button>
          </ButtonPanel>
        </div>
        </div></div>
      </div>

        
     
        <div className={styles.item}>
          <span className={styles.title}>商品描述</span>
        </div>

        <Edit
          disabled={disabled}
          description={formData.description}
          dispatch={dispatch}
        />
      </div>
      <ChooseImage
        visibleModal={mainState}
        clearSelectedList={true}
        filter={{ type: "radio" }}
        close={(status, r) => {
          if (r && r.length) {
            const id = r[0].ID;
            formAction.getImageOne({ id, role: "main", dispatch, state });
          }
          mainImgFunc.setFalse();
        }}
      />
      <ChooseImage
        visibleModal={subState}
        filter={{ type: "multi" }}
        clearSelectedList={true}
        // type="checkbox"
        close={(status, r) => {
          if (r && r.length > 0) {
            const id = r[0].ID;
            r.map((item) => {
              formAction.getImageOne({
                id: item.ID,
                role: "sub",
                dispatch,
                state,
              });
            });
          }
          subImgFunc.setFalse();
        }}
      />
    </div>
  );
}
