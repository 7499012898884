import { message } from "antd";
import Cookies from "js-cookie";
import { Tools } from "../../../../config";
import { ClassesLogic } from "../../../../logic/classes_logic";
import { TaxCodeInfoLogic } from "../../../../logic/taxcode_logic";
import {
  FormData,
  FormAttribute,
} from "../../store/goods_classify_store/form_store";
type Init = {
  state;
  dispatch;
  id: string;
  form;
  method: "Create" | "Edit" | "See" | string;
};
interface InitBasic {
  dispatch;
  state;
  form;
}
interface InitEdit {
  state;
  dispatch;
  id: string;
  form;
}
interface ISetValue {
  attribute: FormAttribute;
  value: string | number | string[] | boolean;
  dispatch;
}

export class FormAction {
  private classesLgc = new ClassesLogic();

  async init({ state, dispatch, id, form, method }: Init) {
    await this.initBasic({ dispatch, state, form });

    if ("Create" !== method) {
      await this.initEdit({ dispatch, state, id, form });
    }
  }
  private async initBasic({ dispatch, state, form }: InitBasic) {
    form.setFieldsValue({
      shopShow: true,
      shopSort: 0,
      storeShow: true,
      storeSort: 0,
    });
    // 一级分类分类
    const res = await this.classesLgc.getFirstLevelGoodsClassify();
    if (res.data.statusCode === 200) {
      if (res.data.data && res.data.data instanceof Array) {
        let list = [{classesName:'一级分类',id: 'firstlevel'}].concat(res.data.data)
        dispatch({ type: "LoadFirstLevelClasses", payload: list });
      }
    }
    // 加载税收编码列表
    const taxCodeRes = await new TaxCodeInfoLogic().getListAll()
    if(taxCodeRes.statusCode == 200){
      let list = taxCodeRes.data || []
      dispatch({ type: "LoadTaxCode", payload: list });
    }
  }
  private async initEdit({ dispatch, id, form, state }: InitEdit) {
    // 回填状态
    const res = await this.classesLgc.getOneGoodsClassify({id: id});
    if(res.data.statusCode === 200 && res.data.data!=null){
        let item = res.data.data
        console.log(item)
        dispatch({
            type: "SetForm",
            payload: {
              id: item.id,
              iconPath: [item.iconPath],
              classesName: item.classesName,
              parentID: item.parentID,
              shopShow: item.shopShow,
              shopSort: item.shopSort,
              storeShow: item.storeShow,
              storeSort: item.storeSort,
              classCode: item.classCode,
              taxID: item.taxID
            },
          });
          // 填充节点
          form.setFieldsValue({
            iconPath: [item.iconPath],
            classesName: item.classesName,
            parentID: (item.parentID === "" || item.parentID === null)?'firstlevel':item.parentID,
            shopShow: item.shopShow,
            shopSort: item.shopSort,
            storeShow: item.storeShow,
            storeSort: item.storeSort,
            classCode: item.classCode,
            taxID: item.taxID
          });
    }
  
  }
  reset({ dispatch, form }) {
    dispatch({
      type: "SetForm",
      payload: {
        id: "",
        iconPath: [],
        classesName: "",
        parentID: "",
        shopShow: true,
        shopSort: 0,
        storeShow: true,
        storeSort: 0,
        subGoodsClassesVM: null,
        classCode: undefined,
        taxID: ''
      },
    });

    // 填充节点
    form.setFieldsValue({
      id: "",
      iconPath: [],
      classesName: "",
      parentID: "",
      shopShow: true,
      shopSort: 0,
      storeShow: true,
      storeSort: 0,     
      classCode: undefined,
      taxID: ''
    });
  }
  setValue({ attribute, value, dispatch }: ISetValue) {
    dispatch({ type: "Change_FormData", payload: { attribute, value } });
  }
  private paddingData(formData) {
    let params = {
      classesName: formData.classesName,
      iconPath: formData.iconPath.toString(),
      parentID: formData.parentID === 'firstlevel'?'':formData.parentID,
      shopSort: formData.shopSort,
      storeSort: formData.storeSort,
      shopShow: formData.shopShow,
      storeShow: formData.storeShow,
      classCode: formData.classCode,
      taxID: formData.taxID?formData.taxID: ''
    };
    return params;
   
  }
  private setLoading(dispatch,isloading) {
      dispatch({type:'isLoading',payload: isloading})
  }
  async addSubmit({ state, form, dispatch }) {
    this.setLoading(dispatch,true)
    const res = await this.classesLgc.addGoodsClassify(
      this.paddingData(state.formData)
    );
    this.setLoading(dispatch,false)
    if(res.data.statusCode === 200){
        message.success('添加成功')
        return true
    }else{
        message.error(res.data.returnMessage)
        return false
    }

  }

  async updSubmit({ state, dispatch,id, form }) {
    this.setLoading(dispatch,true)
    const res = await this.classesLgc.updateGoodsClassify(
        {...this.paddingData(state.formData),id:id}
      );
      this.setLoading(dispatch,false)
    if(res.data.statusCode === 200){
        message.success('修改成功')
        return true
    }else{
        message.error(res.data.returnMessage)
        return false
    }
  }
}
export const formAction = new FormAction();
