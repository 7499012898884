import { useBoolean } from "ahooks";
import { Button, Input, Tag, message, DatePicker, Spin } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect, useState } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { Search } from "../../../../component/search";
import { DataTable } from "../../../../component/table";
import { DataTableStore } from "../../../../component/table/store/data_table_store";

import { Form } from "./form";
import { TableExpand } from "../../../../component/table-expand";
import { ConfirmModal } from "../../../../component/modal";
import { Tips, TipsGroup } from "../../../../component/tips";
// import { InventorySearch } from "./search";
import { InventoryStore } from "../../store/withdraw/inventory_store";
import { InventoryGoodsFormStoreProvider } from "../../store/withdraw/inventory_goods_form_store";
import { inventoryAction } from "../../action/withdraw/inventory_action";
import { inventoryGoodsFormAction } from "../../action/withdraw/inventory_goods_form_action";
import { Mask } from "../../../../component/mask";
import { defualtPageSize, Progress } from "../../../../logic/common_logic";
import { AuthLogic } from "../../../../logic/auth_logic";
import { Control, See, Edit, Del } from "../../../../component/control-column";
import img_refund from "../../../../assets/images/icon/refund.png";
import { FileSearch } from "./file_search";
const formParams = {
  method: "Create",
  id: "",
  subId: "",
  item: null,
};

export function WithDraw(viewPower) {
  const { RangePicker } = DatePicker;
  const [createState, createFunc] = useBoolean(false);
  const [searchState, searchFunc] = useBoolean(false);
  const [confirmState, confirmFunc] = useBoolean(false);

  const { state, dispatch } = useContext(InventoryStore);
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);
  // const [] = useBoolean(false);

  const action = inventoryAction;
  const formGoodsAction = inventoryGoodsFormAction;
  const [menuButton, setData] = useState([]);
  useEffect(() => {
    (async function () {
      let r = await new AuthLogic().selButton(viewPower.viewPower);
      if (r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          r[i] = r[i].split("_")[r[i].split("_").length - 1];
        }
      }
      setData(r);
    })();
    dispatch({ type: "setLoading", payload: false });
    return () => {
      dispatch({ type: "setLoading", payload: false });
    };
  }, []);

  const columns: ColumnsType = [
    {
      title: "操作",
      key: "1",
      align: "center",
      width: Control.width(3),
      render: Control.render(
        (v) =>
          menuButton.indexOf("VIEW") != -1 && (
            <See
              click={() => {
                formParams.item = v;
                formParams.method = "Create";
                createFunc.setTrue();
              }}
            />
          ),
        (v) =>
          menuButton.indexOf("AUDIT") != -1 &&
          v.auditStatus == 1 && (
            <Edit
              click={() => {
                formParams.subId = v.id;
                formParams.item = v;
                createFunc.setTrue();
                formParams.method = "Edit";
              }}
            />
          ),
        (v) =>
          menuButton.indexOf("AUDIT") != -1 &&
          v.auditStatus == 2 && (
            <img
              src={img_refund}
              alt=""
              onClick={async () => {
                formParams.id = v.id;
                confirmFunc.setTrue();
              }}
            />
          )
      ),
    },
    {
      title: "提现用户",
      dataIndex: "memberName",
      key: "2",
      align: "center",
      // width: 100,
    },
    {
      title: "电话号码",
      dataIndex: "memberPhone",
      key: "3",
      align: "center",
      // width: 100,
    },
    {
      title: "申请金额",
      dataIndex: "money",
      key: "4",
      align: "center",
      // width: 140,
    },
    {
      title: "手续费",
      key: "5",
      align: "center",
      dataIndex: "premium",
      // render: (v) =>
      //   v ? <Tag color="success">启用</Tag> : <Tag color="default">禁用</Tag>,
    },
    // {
    //   title: "实际到账金额",
    //   dataIndex: "actualMoney",
    //   key: "8",
    //   align: "center",
    // },
    {
      title: "审核状态",
      key: "9",
      align: "center",
      dataIndex: "auditStatus",
      render: (v) => (
        // (1待审核2通过(放款中)3拒绝4已到账5已撤销
        <Tag
          color={
            v == 3 ? "error" : v == 2 ? "processing" : v == 4 ? "success" : ""
          }
        >
          {v == 1
            ? "待审核"
            : v == 2
            ? "通过(放款中)"
            : v == 3
            ? "拒绝"
            : v == 4
            ? "已到账"
            : "已撤销"}
        </Tag>
      ),
    },
    {
      title: "申请时间",
      dataIndex: "addTime",
      key: "11",
      align: "center",
    },
    {
      title: "审核人",
      dataIndex: "auditUserID",
      key: "10",
      align: "center",
    },
    {
      title: "审核时间",
      dataIndex: "auditTime",
      key: "12",
      align: "center",
    },
  ];
  const onSearch = async (pageIndex) => {
    await action.loadPage({
      pageIndex: pageIndex,
      pageSize: defualtPageSize(),
      dispatch: tableDispatch,
      search: state.search,
      state: state,
    });
  };

  const count = (dataList, field): number => {
    return dataList.reduce((a, b) => {
      return a + (b[field] || 0);
    }, 0);
  };

  return (
    <>
      <Spin
        tip="放款中..."
        spinning={state.isLoading}
        size="large"
        wrapperClassName="isloading"
      >
        <Search>
          <Input
            maxLength={50}
            allowClear
            placeholder="请输入申请人手机号、姓名"
            onChange={(e) => {
              action.setCondition("Keywords", e.target.value, dispatch);
            }}
          />
          <input type="button" value="快速查找" onClick={() => onSearch(1)} />
          <TipsGroup>
            <input
              type="button"
              value="高级查找"
              onClick={() => searchFunc.toggle()}
            />
            <Tips width="536px" display={searchState} left="0">
              <FileSearch
                onSearch={(...arg: [any]) => {
                  onSearch(...arg);
                  searchFunc.setFalse();
                }}
              />
            </Tips>
            <Mask visible={searchState} onClick={() => searchFunc.setFalse()} />
          </TipsGroup>
        </Search>

        <ButtonPanel></ButtonPanel>

        <DataTable
          columns={columns}
          action={action}
          onLoad={async () => {
            await onSearch(1);
            Progress.done();
          }}
          onChange={(pageIndex) => {
            onSearch(pageIndex);
          }}
          scroll={{ scrollToFirstRowOnChange: true, autoY: true, x: 1060 }}
          loading={tableState.loading}
        />

        {createState && (
          <InventoryGoodsFormStoreProvider>
            <Form
              audit={menuButton.indexOf("AUDIT") != -1 ? true : false}
              close={(hasUpdate) => {
                if (hasUpdate) {
                  onSearch(tableState.current);
                }

                createFunc.setFalse();
              }}
              action={formGoodsAction}
              id={formParams.subId}
              subId={formParams.subId}
              item={formParams.item}
              method={formParams.method}
            />
          </InventoryGoodsFormStoreProvider>
        )}

        <ConfirmModal
          visible={confirmState}
          title="温馨提示"
          subTitle="确认放款？"
          message="放款之后无法撤销操作，请慎重考虑"
          close={async (r) => {
            if (r) {
              await action.withdraw({ LogID: formParams.id }, dispatch);
              onSearch(1);
            }
            confirmFunc.setFalse();
          }}
        />
      </Spin>
    </>
  );
}
