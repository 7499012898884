export abstract class DataTableAction {
    abstract delSelectList({ dispatch, selectList });

    setSelectList({ dispatch, selectKeys }) {
        dispatch({ type: "Set_SelectList", payload: selectKeys })
    }
    clearSelectedRowKeys({ dispatch }) {
        dispatch({ type: "Clear_SelectedRowKeys" });
        this.setSelectList({dispatch,selectKeys:[]})
    }
    setSelectedRowKeys({ dispatch, selectKeys }) {
        dispatch({ type: "Set_SelectedRowKeys", payload: selectKeys })
    }
    setLoading( { dispatch, loading } ) {
        dispatch({ type: 'Loading', payload: loading || false})
    }
    setPaging({ dispatch, pageIndex, total, dataList, pageSize, loading }:{dispatch,pageIndex, total, dataList, pageSize, loading?}) {
        dispatch({ type: "Set_Current", payload: pageIndex })
        dispatch({ type: "Set_Total", payload: total })
        dispatch({ type: "Set_PageSize", payload: pageSize })
        dispatch({ type: "Set_DataList", payload: dataList })
        dispatch({ type: 'Loading', payload: loading || false})
    }
}