import { createContext, useReducer } from "react";
interface IOrderMarketing {
  order;
  /**整单优惠 */
  wholeMarketing: any[];
  /**单品优惠 */
  orderSingle: any[];
}
class FormData {
  shippingNumber:number
  salesReturnStatusString: string
  packingFee: number;
  shippingFees: number;
  addDateTime: string;
  addUserID: string;
  discountsPrice: number;
  expenditureType: number;
  id: string;
  memberCard: string;
  memberManagerID: string;
  memberName: string;
  orderNumber: string;
  orderStatus: number;
  orderType: number;
  phone: string;
  priceActuallyPayable: number;
  goodsOldPrice: number;
  remark: string;
  salesReturnStatus: string;
  sellingPrice: number;
  shopID: string;
  sumNumber: number;
  updateDateTime: string;
  updateUserID: string;
  detilSumNumber: string;
  detailVms = [];
  payLogs = [];
  payIntegral: number;
  orderStatusString: string;
  orderMarketing: IOrderMarketing;
  giveGoods = [];
  totoMarketingPrice: number;
  receiptName: string = "";
  receiptPhone: string = "";
  address: string = "";
  // 万里牛推送信息
  wnnResult?: {
    code: number,
    data: string,
    message: string
  }
  // 找零
  smallChange?: number
  // 获得积分 
  earnPoints?: number 
  invoicingStatus: number | null
  isExchangeGoods: boolean //是否换货订单
  refundMoney: number | null //退款金额
}

type Dispatch = { type; payload };

interface IState {
  formData: FormData;
  tableList: any[];
  allPrice: number;
  allDiscountPrice: number;
  isLoading: boolean
}

const initialArgs: IState = {
  formData: new FormData(),
  tableList: [],
  allPrice: 0,
  allDiscountPrice: 0,
  isLoading: false
};

export type FormAttribute =
  | "ShopCode"
  | "ShopName"
  | "MemoryCode"
  | "ShopPhone"
  | "Type"
  | "BusinessArea"
  | "PeopleCount"
  | "ContactPeople"
  | "ContactPhone"
  | "Status"
  | "City"
  | "Address"
  | "ReceiptName"
  | "ReceiptPhone"
  | "Remark";

interface IContextProps {
  state: IState;
  dispatch: ({ type }: Dispatch) => void;
}

export const FormStore = createContext<IContextProps>(null);

function reducer(state: IState, { type, payload }: Dispatch) {
  switch (type) {
    case "update": {
      if (payload.type == "allPrice") {
        let p = state.allPrice;
        state.allPrice = p + payload.num;
      }
      if (payload.type == "discount") {
        let p = state.allDiscountPrice;
        state.allDiscountPrice = p + payload.num;
      }
      return { ...state };
    }

    // 填充表单
    case "SetForm": {
      const form = payload || {};
      return {
        ...state,
        formData: form,
      };
    }
    case "isloading": {
      state.isLoading = payload;
      return { ...state };
    }
    default:
      return state;
  }
}

export function FormStoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialArgs);

  const v = { state, dispatch };
  return <FormStore.Provider value={v}>{props.children}</FormStore.Provider>;
}
