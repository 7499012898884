import { useState, useEffect } from "react";
import {
  TabList,
  TabsStyle,
  Tab,
  TabPanel,
} from "../../../../component/react-tabs";
import { DataTableStoreProvider } from "../../../../component/table/store/data_table_store";
import { AuthLogic } from "../../../../logic/auth_logic";
import { EquipMent } from "./equipment";
import { HotKey } from "./hotkey";
import { FileStoreProvider } from "../../store/equip/file_store";
import { HotKeyFileStoreProvider } from "../../store/hotkey/file_store";
import { ScaleFileStoreProvider } from "../../store/scale/file_store";
import { ScaleRecod } from "./scale/file";
const EquipMentManager = ({ component }) => {
  //获取菜单
  const [menuList, setMenu] = useState([]);

  useEffect(() => {
    const menu = localStorage.getItem("menu")
      ? JSON.parse(localStorage.getItem("menu"))
      : [];
    menu.map((item) => {
      let arr = [];
      if (item.path == "/system") {
        arr = item.children.filter((equi) => equi.component == component);
        if (arr.length > 0) {
          setMenu(arr[0].children || []);
        }
      }
    });
    return () => {};
  }, []);
  return (
    <>
      <TabsStyle style="BottomLine">
        <TabList>
          {menuList.length > 0 ? (
            menuList.map((item) => {
              return (
                <Tab>
                  <span>{item.name}</span>
                  <hr />
                </Tab>
              );
            })
          ) : (
            <div></div>
          )}
          {/* <Tab>
            <span>设备管理</span>
            <hr />
          </Tab> */}
          <hr />
        </TabList>
        {menuList.map((v) => {
          return v.component == "EquipMent" ? (
            <TabPanel>
              <FileStoreProvider>
                <DataTableStoreProvider>
                  <EquipMent viewPower={v.viewPower} />
                </DataTableStoreProvider>
              </FileStoreProvider>
            </TabPanel>
          ) : v.component == "scaleRecord" ? (
            <TabPanel>
              <ScaleFileStoreProvider>
                <DataTableStoreProvider>
                  <ScaleRecod viewPower={v.viewPower} />
                </DataTableStoreProvider>
              </ScaleFileStoreProvider>
            </TabPanel>
          ) : (
            <TabPanel>
              <HotKeyFileStoreProvider>
                <DataTableStoreProvider>
                  <HotKey viewPower={v.viewPower} />
                </DataTableStoreProvider>
              </HotKeyFileStoreProvider>
            </TabPanel>
          );
        })}
      </TabsStyle>
    </>
  );
};
export default EquipMentManager;
