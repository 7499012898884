import React from "react";
import { PrecinctCon, IHotsReducerPar, IHotsData } from "../../action/HotsAction";
import { ElementTransform, V2 } from "../../com/trasform";
import styles from "./../../style/hots.module.scss";
import { moveTouchType, precinctMiniSize } from "./editHost";

/** 控制类型，分别是移动，上下左右，左上，右上，左下，右下 */
type controlType =
  | ""
  | "move"
  | "u"
  | "d"
  | "l"
  | "r"
  | "lu"
  | "ru"
  | "ld"
  | "rd";

/**
 * 选区
 */
export class Precinct extends React.Component<
  {
    id: number;
    setMoveTouch: (moveTouch: moveTouchType) => void;
    getRootDivTransform: () => ElementTransform;
    editUrl: (id: number) => void;
    data: PrecinctCon;
    setPrecinct: (id: number, data: PrecinctCon) => void;
    removePrecinct: (id: number) => void;
  },
  {
    /** 开始位置，注意这里一定要是个相对于根元素的位置 */
    startPot: V2;
    /** 偏移位置 */
    deffPot: V2;
    /** 开始时的变换 */
    startTranform: ElementTransform;
    /** 操作类型 */
    type?: controlType;
  }
> {
  constructor(props) {
    super(props);
    //
    this.state = {
      startPot: null,
      deffPot: null,
      startTranform: null,
    };
    //指定一个绑定函数
    this.mouseUP = this.mouseUP.bind(this);
  }

  /** 删除 */
  private remove(e) {
    // //console.log("删除", this.props.id);
    this.props.removePrecinct(this.props.id);
  }

  /** 鼠标按下 */
  private onMouseDown(e: React.MouseEvent, type: controlType) {
    //添加鼠标释放事件
    window.addEventListener("mouseup", this.mouseUP as any);
    //记录鼠标和当前位置的差值
    let _startPot = V2.subtract(
      new V2(e.clientX, e.clientY),
      this.props.getRootDivTransform().pos
    );
    let _deffPot = V2.subtract(_startPot, this.props.data.pos);
    //设置状态
    this.setState({
      startPot: _startPot,
      deffPot: _deffPot,
      startTranform: new ElementTransform(
        this.props.data.pos.clone(),
        this.props.data.size.clone()
      ),
      type,
    });
    //打开移动检测区域并检测移动
    this.props.setMoveTouch({
      receive: true,
      changeF: (...arg) => {
        this.mouseMove(...arg);
      },
    });
  }

  /** 鼠标移动回调 */
  private mouseMove(pos: V2) {
    if (!this.state.startPot) {
      return;
    }
    //根元素变换
    let rootTransform = this.props.getRootDivTransform();
    /** 开始点击时的变换 */
    let startTranform: ElementTransform = this.state.startTranform;
    /** 当前的变换 */
    let _transform: ElementTransform = this.props.data;
    //循环函数
    let _f = (type: controlType, pos: V2) => {
      //一些临时变量
      let _pos: V2;
      let _deffNumber: number;
      //
      switch (type) {
        /** 移动 */
        case "move":
          //当前鼠标的相对位置加上偏移位置
          _pos = V2.subtract(pos, this.state.deffPot);
          //处理边界情况
          _pos.x = Math.max(0, _pos.x);
          _pos.y = Math.max(0, _pos.y);
          _pos.x = Math.min(
            rootTransform.size.x - startTranform.size.x,
            _pos.x
          );
          _pos.y = Math.min(
            rootTransform.size.y - startTranform.size.y,
            _pos.y
          );
          //
          _transform.pos = _pos;
          break;
        case "u":
        case "d":
        case "l":
        case "r":
          //获取轴名
          let _key: "x" | "y";
          if (/^u|d$/.test(type)) {
            _key = "y";
          } else {
            _key = "x";
          }
          //处理边界
          pos[_key] = Math.max(0, pos[_key]);
          pos[_key] = Math.min(rootTransform.size[_key], pos[_key]);
          //
          _deffNumber = pos[_key] - this.state.startPot[_key];
          if (type == "u" || type == "l") {
            if (
              _deffNumber <=
              startTranform.size[_key] - precinctMiniSize[_key]
            ) {
              _transform.size[_key] = startTranform.size[_key] - _deffNumber;
              _transform.pos[_key] = startTranform.pos[_key] + _deffNumber;
            } else {
              _transform.size[_key] = precinctMiniSize[_key];
              _transform.pos[_key] =
                startTranform.pos[_key] +
                startTranform.size[_key] -
                precinctMiniSize[_key];
            }
          } else {
            if (
              _deffNumber >=
              -startTranform.size[_key] + precinctMiniSize[_key]
            ) {
              _transform.size[_key] = startTranform.size[_key] + _deffNumber;
            } else {
              _transform.size[_key] = precinctMiniSize[_key];
            }
          }
          break;
        case "lu":
        case "ld":
        case "ru":
        case "rd":
          //四个角上的操作都分解到各个轴上进行变换
          _f((type as any)[0], new V2(pos.x, 0));
          _f((type as any)[1], new V2(0, pos.y));
          break;
      }
    };
    //
    _f(this.state.type, V2.subtract(pos, rootTransform.pos));
    //重新设置选区数据
    this.props.setPrecinct(this.props.id, this.props.data);
  }

  /** 鼠标按出 */
  private mouseUP(e: React.MouseEvent) {
    //移除鼠标释放事件
    window.removeEventListener("mouseup", this.mouseUP as any);
    //置空状态
    this.setState({
      startPot: null,
      deffPot: null,
      type: "",
    });
    //关闭鼠标移动检测
    this.props.setMoveTouch({
      receive: false,
    });
  }

  /** 鼠标双击 */
  private onDoubleClick() {
    this.props.editUrl(this.props.id);
  }

  /** 渲染方法 */
  render() {
    let data = this.props.data;
    //
    return (
      <div
        className={styles.precinct + (data.url ? ' ' + styles.on_url : '')}
        style={{
          left: data.pos.x + "px",
          top: data.pos.y + "px",
          width: data.size.x + "px",
          height: data.size.y + "px",
        }}
      >
        <span className={styles.number}>{this.props.id + 1}</span>
        {!data.ifCreate && (
          <>
            {/* 移动操作 */}
            <span
              className={styles.touch}
              onMouseDown={(e) => {
                this.onMouseDown(e, "move");
              }}
              onDoubleClick={() => {
                this.onDoubleClick();
              }}
            ></span>
            {/* 缩放操作 */}
            <span
              className={styles.handle + " " + styles.NE}
              onMouseDown={(e) => {
                this.onMouseDown(e, "ru");
              }}
            ></span>
            <span
              className={styles.handle + " " + styles.NN}
              onMouseDown={(e) => {
                this.onMouseDown(e, "u");
              }}
            ></span>
            <span
              className={styles.handle + " " + styles.NW}
              onMouseDown={(e) => {
                this.onMouseDown(e, "lu");
              }}
            ></span>
            <span
              className={styles.handle + " " + styles.WW}
              onMouseDown={(e) => {
                this.onMouseDown(e, "l");
              }}
            ></span>
            <span
              className={styles.handle + " " + styles.EE}
              onMouseDown={(e) => {
                this.onMouseDown(e, "r");
              }}
            ></span>
            <span
              className={styles.handle + " " + styles.SW}
              onMouseDown={(e) => {
                this.onMouseDown(e, "ld");
              }}
            ></span>
            <span
              className={styles.handle + " " + styles.SS}
              onMouseDown={(e) => {
                this.onMouseDown(e, "d");
              }}
            ></span>
            <span
              className={styles.handle + " " + styles.SE}
              onMouseDown={(e) => {
                this.onMouseDown(e, "rd");
              }}
            ></span>
            {/* 其它 */}
            <span className={styles.layer_tip}>
              双击{data.url ? "编辑" : "添加"}链接
            </span>
            <span
              className={styles.layer_close}
              onClick={(e) => {
                this.remove(e);
              }}
            ></span>
          </>
        )}
      </div>
    );
  }
}
