import { useRequest } from "ahooks";
import { useContext, useEffect,useState } from "react";
import { useHistory } from "react-router-dom";
import { Progress } from "../../../../logic/common_logic";
import { UnitBaseAction } from "../../action/unit_action";
import { UnitStore } from "../../store/unit_store";
import styles from "../../style/classify_command.module.scss";
import { AuthLogic } from "../../../../logic/auth_logic";

export function Unit({
  action,
  enableAddRow,
  viewPower
}: {
  action: UnitBaseAction;
  enableAddRow?: boolean;
  viewPower?: string
}) {
  const { state: stateArgs, dispatch } = useContext(UnitStore);

  const his = useHistory();
  const [menuButton, setData] = useState([]);
  useEffect(() => {
    (async function () {
      let r = await new AuthLogic().selButton(viewPower);
      if (r && r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          r[i] = r[i].split("_")[r[i].split("_").length - 1];
        }
      }
      setData(r);
    })();

    return () => {};
  }, []);
  useRequest(async () => {
    await action.fetchListAction({ state: stateArgs, dispatch });
    Progress.done();
  });

  return (
    <div className={styles.root}>
      {stateArgs.dataList.map((v, rowindex) => (
        <>
          <div className={styles.list}>
            {v.rows.map(({ state, value }, cellindex) => {
              if ("Text" == state) {
                return (
                  <div
                    className={styles.item}
                    onMouseOver={() =>
                      action.toggoleTipsAction({
                        rowindex,
                        cellindex,
                        dispatch,
                      })
                    }
                  >
                    <div className={styles.name}>
                      <span>{value}</span>
                    </div>
                  </div>
                );
              } else if ("Add" == state && menuButton.indexOf('ADD')!=-1) {
                return (
                  <div className={styles.item}>
                    <div className={styles.ico}>
                      <span
                        onClick={() =>
                          action.addCallAction({ rowindex, dispatch })
                        }
                      ></span>
                    </div>
                  </div>
                );
              } else if ("Input" == state) {
                return (
                  <div className={styles.item}>
                    <div className={styles.input_group}>
                      <input
                        type="text"
                        defaultValue={value}
                        maxLength={20}
                        onChange={(e) =>
                          action.inputTextAction({
                            rowindex,
                            cellindex,
                            dispatch,
                            value: e.target.value,
                          })
                        }
                      />
                      <span
                        onClick={() =>
                          action.saveCellAction({
                            rowindex,
                            cellindex,
                            dispatch,
                            state: stateArgs,
                            his,
                          })
                        }
                      ></span>
                      <span
                        onClick={() =>
                          action.cancelCallAction({
                            rowindex,
                            cellindex,
                            dispatch,
                          })
                        }
                      ></span>
                    </div>
                  </div>
                );
              } else if ("Tips" == state) {
                return (
                  <div
                    style={{ position: "relative" }}
                    className={styles.item}
                    onMouseOver={() =>
                      action.toggoleTipsAction({
                        rowindex,
                        cellindex,
                        dispatch,
                      })
                    }
                  >
                    <div className={styles.name}>
                      <span>{value}</span>
                    </div>

                    <div
                      style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        width: 400,
                      }}
                    >
                      <div
                        className={styles.item}
                        onMouseLeave={() =>
                          action.toggoleTextAction({
                            rowindex,
                            cellindex,
                            dispatch,
                          })
                        }
                      >
                        <div className={styles.name_group}>
                          <span style={{ color: "transparent" }}>{value}</span>
                          <span className={styles.point}>
                            <div className={styles.name_expand}>
                             {menuButton.indexOf('UPDATE')!==-1&& <span
                                onClick={() =>
                                  action.editAction({
                                    rowindex,
                                    cellindex,
                                    dispatch,
                                  })
                                }
                              ></span>}
                              {menuButton.indexOf('DELETE')!==-1&& <span
                                onClick={() =>
                                  action.deleteAction({
                                    rowindex,
                                    cellindex,
                                    dispatch,
                                    state: stateArgs,
                                  })
                                }
                              ></span>}
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
          <hr />
        </>
      ))}
    </div>
  );
}
