import { effeative } from "./common_logic";
import { Tools, axios } from "../config";
interface IParams{
    id?: string
    codeName: string
    taxCode: string
    rate: number
    remark?: string
}
export class TaxCodeInfoLogic {
    /**
     * @description 查询税收编码配置列表
     * @param data 
     * @returns r.data
     */
    async getList(data){
        const r = effeative(await axios({
            url: Tools.getApiAddress('tax_codeinfo_list'),
            method: 'POST',
            data
        }))
        return r.data
    }
    /**
     * @description 查询税收编码配置列表 全部
     * @param data 
     * @returns r.data
     */
    async getListAll(){
        const r = effeative(await axios({
            url: Tools.getApiAddress('tax_codeinfo_list_all'),
            method: 'GET',
        }))
        return r.data
    }
    /**
     * @description 获取税收编码配置单条记录
     * @param id string
     */
    async getDetailById(id){
        const r = effeative(await axios({
            url: Tools.getApiAddress('tax_codeinfo_get'),
            method: 'GET',
            params:{
                ID: id
            }
        }))
        return r.data
    }
    /**
     * @description 创建税收编码配置
     * @param codeName 税收编码名称
     * @param taxCode 税收编码
     * @param rate（number） 税率 
     * @param remark 备注
     */
    async addTaxCodeInfo(data: IParams){
        const r = effeative(await axios({
            url: Tools.getApiAddress('tax_codeinfo_add'),
            method: 'POST',
            data
        }))
        return r
    }
    /**
     * @description 编辑税收编码配置
     * @param id 主键id
     * @param codeName 税收编码名称
     * @param taxCode 税收编码
     * @param rate（number） 税率 
     * @param remark 备注
     */
    async updateTaxCodeInfo(data: IParams){
        const r = effeative(await axios({
            url: Tools.getApiAddress('tax_codeinfo_update'),
            method: 'POST',
            data
        }))
        return r
    }
    /**
     * @description 删除税收编码配置
     * @param ID 主键id
     */
    async delTaxCodeInfo(id){
        const r = effeative(await axios({
            url: Tools.getApiAddress('tax_codeinfo_del'),
            method: 'GET',
            params:{
                ID: id
            }
        }))
        return r
    }
}