import message from "antd/lib/message"
import { DataTableAction } from "../../../../component/table/action/data_table_action"
import { MarketingTimeSalesPromotion_logic } from "../../../../logic/market/MarketingTimeSalesPromotion_logic";

class FileAction extends DataTableAction {

    async delRow(id) {
        const r = await MarketingTimeSalesPromotion_logic.DeleteTemp({
            ID: id,
        });

        if (r.data.statusCode == 200) {
            message.success("删除成功")
        } else {
            message.error(r.data.returnMessage || "删除失败")
        }
    }

    delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {
        //
    }

    async loadPage({ dispatch, pageIndex, pageSize, search }) {
        this.setLoading({dispatch,loading: true})
        const params: any = {
            "pageIndex": pageIndex,
            "pageSize": pageSize,
            "orderBy": '',
            "sort": '',
            ...search,
        }

        // 档案列表
        const r = await MarketingTimeSalesPromotion_logic.QueryPage(params);

        if (r.data.statusCode == 200) {
            const dataList = r.data.data.dataSource;

            //
            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: dataList, pageSize });
        }
        this.setLoading({dispatch,loading: false})
    }

    setCondition({ dispatch, key, value }) {
        const params = {
        }
        params[key] = value;
        //
        dispatch({ type: "SetSearch", payload: params })
    }
    async stop(id){
        const r = await MarketingTimeSalesPromotion_logic.StopTimeSalesPromotion({ID: id})
        if(r&&r.data&&r.data.statusCode == 200){
            message.success('终止成功')
        }else{
            message.error(r.data.returnMessage || r.data.message || '终止失败')
        }
    }
}
export const fileAction = new FileAction();