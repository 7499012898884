import { message } from "antd";
import axios from "axios";
import Cookies from "js-cookie";
import { FuncLock } from "stb-tools";
import { Tools } from "../../../config";

export async function fetchCodeAction(state, dispatch) {
    const { data, status } = await axios({
        method: "GET",
        url: Tools.getApiAddress("auth_code")
    })
    if (200 == status) {
        dispatch({
            ...state,
            rawCode: data.data,
            code: data.data.captchaCode
        })
    }
}

const lock = new FuncLock();

export async function fetchLoginAction(state, setData, his) {
    const { account, passwd, inputCode, rawCode, code } = state;

    lock.enable(async () => {
        const { data, status } = await axios({
            method: "POST",
            url: Tools.getApiAddress("login"),
            data: {
                userName: account,
                passWord: passwd,
                code: inputCode,
                uuid: rawCode.captchaGUID
            }
        })
        if (200 == status) {
            if (200 != data.statusCode) {
                message.error(data.returnMessage)
                fetchCodeAction(state, setData);
                lock.clear();
            } else {
                Cookies.set("token", data.data.systoken);
                Cookies.set("loginMsg", JSON.stringify(data.data));
                localStorage.setItem('agentID',data.data.agentID)
                message.success('登录成功', .8, () => {
                    // setTimeout(() => {
                        his.replace('/survey');
                    // }, 200);

                    lock.clear();
                });
            }

        }
    })

}
