import { useBoolean } from "ahooks";
import { Button, Input, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect, useState } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { Search } from "../../../../component/search";
import { DataTable } from "../../../../component/table";
import { DataTableStore } from "../../../../component/table/store/data_table_store";

import { Form } from "./inventory_goods_form";
import { TableExpand } from "../../../../component/table-expand";
import { ConfirmModal } from "../../../../component/modal";
import { Tips, TipsGroup } from "../../../../component/tips";
import { InventorySearch } from "./inventory_search";
import { InventoryStore } from "../../store/inventory/inventory_store";
import { InventoryGoodsFormStoreProvider } from "../../store/inventory/inventory_goods_form_store";
import { inventoryAction } from "../../action/inventory/inventory_action";
import { inventoryGoodsFormAction } from "../../action/inventory/inventory_goods_form_action";
import { Mask } from "../../../../component/mask";
import { defualtPageSize, Progress } from "../../../../logic/common_logic";
import { AuthLogic } from "../../../../logic/auth_logic";
import { Control, See, Edit, Del } from "../../../../component/control-column";
const formParams = {
  method: "Create",
  id: "",
  subId: "",
  item: null,
};
// 盘点
export function Inventory(viewPower) {
  const [createState, createFunc] = useBoolean(false);
  const [searchState, searchFunc] = useBoolean(false);
  const [confirmState, confirmFunc] = useBoolean(false);

  const { state, dispatch } = useContext(InventoryStore);
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);
  const [] = useBoolean(false);

  const action = inventoryAction,
    formGoodsAction = inventoryGoodsFormAction;
  const [menuButton, setData] = useState([]);
  useEffect(() => {
    (async function () {
      let r = await new AuthLogic().selButton(viewPower.viewPower);
      if (r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          r[i] = r[i].split("_")[r[i].split("_").length - 1];
        }
      }
      setData(r);
    })();

    return () => {};
  }, []);
  const columns: ColumnsType = [
    {
      title: "操作",
      key: "1",
      align: "center",
      width: Control.width(3),
      render: Control.render(
        (v) =>
          menuButton.indexOf("VIEW") != -1 && (
            <See
              click={() => {
                formParams.id = v.id;
                formParams.method = "Preview";

                createFunc.setTrue();
              }}
            />
          ),
        (v, r) =>
          r.status !== 1 &&
          menuButton.indexOf("UPDATE") != -1 && (
            <Edit
              click={() => {
                formParams.id = v.id;
                formParams.subId = r.id;
                formParams.method = "Edit";
                formParams.item = r;

                createFunc.setTrue();
              }}
            />
          ),
        (v, r) =>
          r.status !== 1 &&
          menuButton.indexOf("DELETE") != -1 && (
            <Del
              click={() => {
                formParams.id = v.id;
                formParams.subId = r.id;

                confirmFunc.setTrue();
              }}
            />
          )
      ),
    },
    {
      title: "盘点单号",
      dataIndex: "checkOddNumbers",
      key: "2",
      align: "center",
      width: 190,
    },
    {
      title: "门店",
      dataIndex: "shopName",
      key: "3",
      align: "center",
      width: 120,
    },
    {
      title: "系统数量",
      dataIndex: "sysNumber",
      key: "4",
      align: "center",
      width: 100,
    },
    {
      title: "实盘数量",
      dataIndex: "checkNumber",
      key: "5",
      align: "center",
      width: 100,
    },
    {
      title: "差异数量",
      dataIndex: "differenceNumber",
      key: "6",
      align: "center",
      width: 100,
    },
    {
      title: "盘盈金额",
      dataIndex: "differencePrice",
      key: "7",
      align: "center",
      render: (v) => <span>{v >= 0 ? v : 0}</span>,
      width: 100,
    },
    {
      title: "盘亏金额",
      dataIndex: "differencePrice",
      key: "8",
      align: "center",
      render: (v) => <span>{v < 0 ? v : 0}</span>,
      width: 100,
    },
    {
      title: "盘点原因",
      dataIndex: "cause",
      key: "8",
      align: "center",
      render: (v) => (
        <>
          {"1" == v && <span>日常盘点</span>}
          {"2" == v && <span>月度盘点</span>}
        </>
      ),
      width: 80,
    },
    {
      title: "单据状态",
      dataIndex: "status",
      key: "8",
      align: "center",
      render: (v) => (
        <>
          {v == "1" && <Tag color="success">已审核</Tag>}
          {v == "2" && <Tag color="error">未审核</Tag>}
          {v == "3" && <Tag>作废</Tag>}
        </>
      ),
      width: 80,
    },
    {
      title: "制单人",
      dataIndex: "userName",
      key: "8",
      align: "center",
      width: 80,
    },
    {
      title: "制单时间",
      dataIndex: "addDateTime",
      key: "8",
      align: "center",
      width: 140,
    },
    {
      title: "盘点时间",
      dataIndex: "updateDateTime",
      key: "8",
      align: "center",
      width: 140,
    },
  ];

  const onSearch = async (pageIndex) => {
    await action.loadPage({
      pageIndex: pageIndex,
      pageSize: defualtPageSize(),
      dispatch: tableDispatch,
      search: state.search,
    });
  };

  const count = (dataList, field): number => {
    return dataList.reduce((a, b) => {
      return a + (b[field] || 0);
    }, 0);
  };

  return (
    <>
      <Search>
        <Input
          maxLength={50}
          allowClear
          placeholder="请输入商品名称，编码，盘点单号"
          onChange={(e) =>
            action.setCondition({
              dispatch,
              key: "keywords",
              value: e.target.value,
            })
          }
        />
        <input type="button" value="快速查找" onClick={() => onSearch(1)} />

        <TipsGroup>
          <input
            type="button"
            value="高级查找"
            onClick={() => searchFunc.toggle()}
          />
          <Tips display={searchState} width="536px">
            <InventorySearch
              onSearch={(...arg: [any]) => {
                onSearch(...arg);
                searchFunc.setFalse();
              }}
            />
          </Tips>
          <Mask visible={searchState} onClick={() => searchFunc.setFalse()} />
        </TipsGroup>
      </Search>

      <ButtonPanel>
        {menuButton.indexOf("ADD") != -1 && (
          <Button
            onClick={() => {
              formParams.id = "";
              formParams.method = "Create";

              createFunc.setTrue();
            }}
          >
            商品盘点
          </Button>
        )}
      </ButtonPanel>

      <DataTable
        columns={columns}
        action={action}
        onLoad={async () => {
          await onSearch(1);
          Progress.done();
        }}
        onChange={(pageIndex) => {
          onSearch(pageIndex);
        }}
        scroll={{ scrollToFirstRowOnChange: true, autoY: true, x: 1060 }}
        loading={tableState.loading}
      />
      <TableExpand>
        系统数量合计:{count(tableState.dataList, "sysNumber").toFixed(3)}
        <span style={{ marginLeft: 10 }}>
          实盘数量合计: {count(tableState.dataList, "checkNumber").toFixed(3)}
        </span>
        <span style={{ marginLeft: 10 }}>
          差异数量合计:{" "}
          {count(tableState.dataList, "differenceNumber").toFixed(3)}
        </span>
        <span style={{ marginLeft: 10 }}>
          盘盈金额合计: ¥{" "}
          {count(
            tableState.dataList.filter((v) => v.differencePrice >= 0),
            "differencePrice"
          ).toFixed(2)}
        </span>
        <span style={{ marginLeft: 10, marginRight: 10 }}>
          盘亏金额合计: ¥{" "}
          {count(
            tableState.dataList.filter((v) => v.differencePrice < 0),
            "differencePrice"
          ).toFixed(2)}
        </span>
        差异金额合计: ¥{" "}
        {count(tableState.dataList, "differencePrice").toFixed(2)}
      </TableExpand>

      {createState && (
        <InventoryGoodsFormStoreProvider>
          <Form
            audit={menuButton.indexOf("AUDIT") != -1 ? true : false}
            close={(hasUpdate) => {
              if (hasUpdate) {
                onSearch(tableState.current);
              }

              createFunc.setFalse();
            }}
            action={formGoodsAction}
            id={formParams.id}
            subId={formParams.subId}
            item={formParams.item}
            method={formParams.method}
          />
        </InventoryGoodsFormStoreProvider>
      )}

      <ConfirmModal
        visible={confirmState}
        title="温馨提示"
        subTitle="确认删除？"
        message="删除之后信息无法恢复，请慎重考虑"
        close={async (r) => {
          if (r) {
            await action.delRow(formParams.subId);

            onSearch(tableState.current);
          }
          confirmFunc.setFalse();
        }}
      />
    </>
  );
}
