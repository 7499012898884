import { useBoolean } from "ahooks";
import { Button, Input, message, Spin, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { Search } from "../../../../component/search";
import { DataTable } from "../../../../component/table";
import { DataTableStore } from "../../../../component/table/store/data_table_store";
import { Form } from "./form";
import { purchaseAction } from "../../action/purchase/purchase_action";
import { PurchaseStore } from "../../store/purchase/purchase_store";
import { PurchaseFormStoreProvider } from "../../store/purchase/purchase_form_store";
import { TableExpand } from "../../../../component/table-expand";
import { ConfirmModal } from "../../../../component/modal";
import { Tips, TipsGroup } from "../../../../component/tips";
import { PurchaseSearch } from "./file_search";
import { Mask } from "../../../../component/mask";
import { defualtPageSize, Progress } from "../../../../logic/common_logic";
import { AuthLogic } from "../../../../logic/auth_logic";
import { Control, Del, Edit, See, SubmitAudit, Receipt } from "../../../../component/control-column";
import Cookies from "js-cookie";
// import axios from "axios";
import { Tools, axios } from "../../../../config";
import { AuditStatus, SendStatus } from "../../enum";
import {Reciept_Form} from './reciept_form'
const formParams = { 
  method: "Create",
  id: "",
  subId: "",
  item: null,
};

export function Purchase(viewPower) {
  const [createState, createFunc] = useBoolean(false);
  const [searchState, searchFunc] = useBoolean(false);
  const [confirmState, confirmFunc] = useBoolean(false);
  const [submitAuditState, submitAuditFunc] = useBoolean(false)
  // 收货单
  const [recieptState, recieptFunc] = useBoolean(false)
  const { state, dispatch } = useContext(PurchaseStore);
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);
  const [] = useBoolean(false);
 
  const action = purchaseAction,
    formAction = purchaseAction;
    // 'VIEW','UPDATE','DELETE','ADD','AUDIT','Receipt'
  const [menuButton, setData] = useState([]);
  useEffect(() => {
    (async function () {
      dispatch({type:'SetLoading',payload:{loading: false, msg:''}})
      let r = await new AuthLogic().selButton(viewPower.viewPower);
      if (r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          r[i] = r[i].split("_")[r[i].split("_").length - 1];
        }
      }
      setData(r);
    })();

    return () => {};
  }, []);
  const columns: ColumnsType = [
    {
      title: "操作",
      key: "1",
      dataIndex: "id",
      align: "center",
      width: Control.width(4),
      render: Control.render(
        (v, r) =>
          menuButton.indexOf("VIEW") != -1 && (
            <See
              click={() => {
                formParams.id = v;
                formParams.method = "Preview";

                createFunc.setTrue();
              }}
            />
          ),
          (v, r) =>
          menuButton.indexOf("AUDIT") != -1 && r.auditStatus == 1 && (
            <SubmitAudit
              click={() => {
                formParams.id = v;
                formParams.method = "Audit";
                submitAuditFunc.setTrue();
              }}
            />
          ),
          (v, r) =>
          // 
          menuButton.indexOf("RECEIPT") != -1 && r.sendStaus != 1 && (
            <Receipt
              click={() => {
                formParams.id = v;
                formParams.method = "Receipt";
                recieptFunc.setTrue();
              }}
            />
          ),
        (v, r) =>
          r.inventoryStatus !== 1 &&
          menuButton.indexOf("UPDATE") != -1 && r.auditStatus == 1 && (
            <Edit
              click={() => {
                formParams.id = v;
                formParams.subId = r.id;
                formParams.method = "Edit";
                formParams.item = r;

                createFunc.setTrue();
              }}
            />
          ),
        // (v, r) =>
        //   menuButton.indexOf("DELETE") != -1 && (r.auditStatus == 1 ) && (
        //     <Del
        //       click={() => {
        //         formParams.id = v;
        //         formParams.subId = r.id;
        //         confirmFunc.setTrue();
        //       }}
        //     />
        //   )
      ),
    },
    {
      title: "采购单号",
      dataIndex: "purchaseNumber",
      key: "2",
      align: "center",
      width: 170,
    },
    {
      title: "采购标题",
      dataIndex: "purchaseTitle",
      key: "3",
      align: "center",
      width: 140,
    },
    {
      title: "采购数量",
      dataIndex: "totalGoodsNum",
      key: "4",
      align: "center",
      width: 140,
    },
    {
      title: "实际金额",
      dataIndex: "realTotalAmount",
      key: "5",
      align: "center",
      width: 140,
    },
    {
      title: "商品总金额",
      dataIndex: "totalAmount",
      key: "6",
      align: "center",
      width: 140,
    },
    {
      title: "优惠总金额",
      dataIndex: "discountAmount",
      key: "7",
      align: "center",
      width: 140,
    },   
    {
      title: "发货状态",
      dataIndex: "sendStaus",
      key: "8",
      align: "center",
      width: 80,
      render: (v) => (
        <>
         <Tag color={SendStatus[v].tagClass}>{SendStatus[v].label}</Tag>
        </>
      ),
    },  
    {
      title: "单据状态",
      dataIndex: "auditStatus",
      key: "9",
      align: "center",
      width: 80,
      render: (v) => (
        <>
         <Tag color={AuditStatus[v].tagClass}>{AuditStatus[v].label}</Tag>
        </>
      ),
    },  
    {
      title: "审核时间",
      dataIndex: "auditTime",
      key: "10",
      align: "center",
      width: 140,
    },
    {
      title: "制单人",
      dataIndex: "createUserName",
      key: "11",
      align: "center",
      width: 140,
    },
    {
      title: "制单时间",
      dataIndex: "createTime",
      key: "12",
      align: "center",
      width: 140,
    },
    // {
    //   title: "备注",
    //   dataIndex: "remark",
    //   key: "13",
    //   align: "center",
    // },
  ];

  const onSearch = async (pageIndex) => {
    
    await action.loadPage({
      pageIndex: pageIndex,
      pageSize: defualtPageSize(),
      dispatch: tableDispatch,
      search: state.search,
    });
  };
  const totalNumber = () => {
    let total = 0;
    tableState.dataList.forEach((v) => {
      total += v.number;
    });
    return total.toFixed(3);
  };
  const totalPrice = () => {
    let total = 0;
    tableState.dataList.forEach((v) => {
      total += v.money;
    });
    return total;
  };

  return (
    <>
        <Spin
        tip={state.msg}
        spinning={state.auditLoading}
        size="large"
        wrapperClassName="isloading"
      >
        <Search>
          <Input
            maxLength={50}
            allowClear
            placeholder="请输入采购标题"
            onChange={(e) =>
              action.setCondition({
                dispatch,
                key: "purchaseTitle",
                value: e.target.value,
              })
            }
          />
          <input type="button" value="快速查找" onClick={() => onSearch(1)} />

          <TipsGroup>
            <input
              type="button"
              value="高级查找"
              onClick={() => searchFunc.toggle()}
            />
            <Tips display={searchState} width="48rem">
              <PurchaseSearch
                onSearch={() => {
                  searchFunc.setFalse();
                  onSearch(1);
                }}
              />
            </Tips>
            <Mask visible={searchState} onClick={() => searchFunc.setFalse()} />
          </TipsGroup>
        </Search>

        <ButtonPanel>
          {menuButton.indexOf("ADD") != -1 && (
            <Button
              onClick={() => {
                formParams.id = "";
                formParams.method = "Create";

                createFunc.setTrue();
              }}
            >
              新增采购单
            </Button>
          )}

         
        </ButtonPanel>

        <DataTable
          columns={columns}
          action={action}
          onLoad={async () => {
            await onSearch(1);
            Progress.done();
          }}
          onChange={(pageIndex) => {
            onSearch(pageIndex);
          }}
          scroll={{ scrollToFirstRowOnChange: true, autoY: true, x: 1060 }}
          loading={tableState.loading}
        />
        {/* <TableExpand>
          <span>采购数量合计: {totalNumber()}</span>
          <span style={{ marginLeft: 20 }}>
            采购金额合计: ¥ {totalPrice().toFixed(2)}
          </span>
        </TableExpand> */}

        {createState && (
          <PurchaseFormStoreProvider>
            <Form
              audit={menuButton.indexOf("AUDIT") != -1 ? true : false}
              close={(hasUpdate) => {
                if (hasUpdate) {
                  onSearch(tableState.current);
                }

                createFunc.setFalse();
              }}
              action={formAction}
              id={formParams.id}
              subId={formParams.subId}
              item={formParams.item}
              method={formParams.method}
            />
          </PurchaseFormStoreProvider>
        )}
        {
          recieptState && (
            <Reciept_Form
            close={(hasUpdate) => {
              if (hasUpdate) {
                onSearch(tableState.current);
              }

              recieptFunc.setFalse();
            }}
            id={formParams.id}
            item={formParams.item}
            method={formParams.method}
            />
          )
        }

        <ConfirmModal
          visible={confirmState}
          title="温馨提示"
          subTitle="确认删除？"
          message="删除之后信息无法恢复，请慎重考虑"
          close={async (r) => {
            if (r) {
              await action.delRow(formParams.id);

              onSearch(tableState.current);
            }
            confirmFunc.setFalse();
          }}
        />
        {/* 提交审核modal */}
        <ConfirmModal
          visible={submitAuditState}
          title="温馨提示"
          subTitle="确认提交审核？"
          message="提交审核之后订单信息无法修改，请慎重考虑"
          close={async (r) => {
            if (r) {
              // await action.delRow(formParams.subId);
              await action.submitAudit({dispatch},'提交审核中',formParams.id)
              onSearch(tableState.current);
            }
            submitAuditFunc.setFalse();
          }}
        />
      </Spin>
    </>
  );
}
