import { Button } from "../component/button";
import { useEffect, useState } from "react";

/**
 * 协议
 */
export function Agreement({ ok }) {
    const [time, setTime] = useState(5);
    useEffect(() => {
        let i = setTimeout(() => {
            setTime(time - 1);
        }, 1000);
        return () => {
            clearTimeout(i);
        }
    }, [time]);
    return <Button style={time < 0? {
        backgroundColor: '#f7ba00',
        borderColor: '#f7ba00',
        color: 'white'
    }: {}} disabled={time >= 0} onClick={ok}>阅读并同意{time >= 0 ? <>（{time}）</> : ''}</Button>
}