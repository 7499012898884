import { message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import styles from "../../style/file_search.module.scss";
import advertisement from '../../../../assets/images/xinxiao/advertisement.png';
import tejiajipiaobiaoqian from '../../../../assets/images/xinxiao/tejiajipiaobiaoqian.png';
import manjian from '../../../../assets/images/xinxiao/manjian.png';
import miaosha from '../../../../assets/images/xinxiao/miaosha.png';
import yushoudanrenwu from '../../../../assets/images/xinxiao/yushoudanrenwu.png';
import youhuiquan from '../../../../assets/images/xinxiao/youhuiquan.png';
import jifen from '../../../../assets/images/xinxiao/jifen.png';
import yingxiao from '../../../../assets/images/xinxiao/yingxiao.png';
import ziyuan from '../../../../assets/images/xinxiao/ziyuan.png';
import { MenuLogic } from "../../../../logic/menu_logic";
export function Home() {
    const [menuList,setData] = useState([])
    useEffect(() => {
        (async function () {
          let r = await new MenuLogic().menuData();
          //console.log(r)
          var arr = [];
          if (r && r.length > 0) {
            r = r.filter(function (v) {
              return !v.hidden;
            });
    
            for (let i = 0; i < r.length; i++) {
              if (r[i].path == "/marketing" && r[i].children) {
                arr = r[i].children.filter(function (v) {
                  return !v.hidden
                  // return true;
                });
              }
            }
           
          }
          let components = arr.length>0?arr.map(item=>item.component):[]
          setData(components);
        })();
    
        return () => {};
      }, []);
    return (
        <>
            <div className={styles.homeTop}>
                <div className={styles.topTitle}>
                    <span></span>
                    <span>折扣/满减/秒杀</span>
                </div>
                <div className={styles.active}>
                    {menuList.indexOf('Discount')!==-1 && <Link to="/market/page?text=Discount">
                        <div className={styles.activeItem} style={{ boxShadow: '0 0 10px 0 rgba(75,159,254,0.4)' }}>
                            <div className={styles.itemBox}>
                                <div style={{ color: 'rgb(53,151,254)' }}>
                                    <img src={advertisement} alt="" />
                                    <span>折扣活动</span>
                                </div>
                                <div>单品折扣，优惠多多。</div>
                            </div>
                        </div>
                    </Link>}
                    {menuList.indexOf('Special')!==-1 && <Link to="/market/page?text=Special">
                        <div className={styles.activeItem} style={{ boxShadow: '0 0 10px 0 rgba(255,172,7,0.4)' }}>
                            <div className={styles.itemBox}>
                                <div style={{ color: 'rgb(255,172,7)' }}>
                                    <img src={tejiajipiaobiaoqian} alt="" />
                                    <span>特价活动</span>
                                </div>
                                <div>特价活动，不容错过。</div>
                            </div>
                        </div>
                    </Link>}
                    {menuList.indexOf('Fullgift')!==-1 && <Link to="/market/page?text=Fullgift">
                        <div className={styles.activeItem} style={{ boxShadow: '0 0 10px 0 rgba(2,202,116,0.4)' }}>
                            <div className={styles.itemBox}>
                                <div style={{ color: 'rgb(2,202,116)' }}>
                                    <img src={manjian} alt="" />
                                    <span>满减活动</span>
                                </div>
                                <div>来就送，买即赠。</div>
                            </div>
                        </div>
                    </Link>}
                   {menuList.indexOf('Promotion')!==-1 &&  <Link to="/market/page?text=Promotion">
                        <div className={styles.activeItem} style={{ boxShadow: '0 0 10px 0 rgba(205,99,255,0.4)' }}>
                            <div className={styles.itemBox}>
                                <div style={{ color: 'rgb(205,99,255)' }}>
                                    <img src={manjian} alt="" />
                                    <span>时段促销</span>
                                </div>
                                <div>不同的时间，不同的折扣优惠。</div>
                            </div>
                        </div>
                    </Link>}
                   {menuList.indexOf('Seckill')!==-1 && <Link to="/market/page?text=Seckill">
                        <div className={styles.activeItem} style={{ boxShadow: '0 0 10px 0 rgba(255,132,99,0.4)' }}>
                            <div className={styles.itemBox}>
                                <div style={{ color: 'rgb(255,132,99)' }}>
                                    <img src={miaosha} alt="" />
                                    <span>商城秒杀</span>
                                </div>
                                <div>限时秒杀，惊喜连连。</div>
                            </div>
                        </div>
                    </Link>}
                   {menuList.indexOf('Booking')!==-1 && <Link to="/market/page?text=Booking">
                        <div className={styles.activeItem} style={{ boxShadow: '0 0 10px 0 rgba(11,234,189,0.4)' }}>
                            <div className={styles.itemBox}>
                                <div style={{ color: 'rgb(0,225,180)' }}>
                                    <img src={yushoudanrenwu} alt="" />
                                    <span>商城预售</span>
                                </div>
                                <div>预约省时争朝夕，生活处处抢先机。</div>
                            </div>
                        </div>
                    </Link>}
                    {menuList.indexOf('Coupon')!==-1 && <Link to="/market/page?text=Coupon">
                        <div className={styles.activeItem} style={{ boxShadow: '0 0 10px 0 rgba(99,154,255,0.4)' }}>
                            <div className={styles.itemBox}>
                                <div style={{ color: 'rgb(53,151,254)' }}>
                                    <img src={youhuiquan} alt="" />
                                    <span>优惠券</span>
                                </div>
                                <div>集天下礼券，惠亿万人民。</div>
                            </div>
                        </div>
                    </Link>}
                   {menuList.indexOf('Exchange')!==-1 &&  <Link to="/market/page?text=Exchange">
                        <div className={styles.activeItem} style={{ boxShadow: '0 0 10px 0 rgba(255,106,99,0.4)' }}>
                            <div className={styles.itemBox}>
                                <div style={{ color: 'rgb(255,106,99)' }}>
                                    <img src={jifen} alt="" />
                                    <span>积分兑换</span>
                                </div>
                                <div>机会难得、数量有限，赶快行动吧。</div>
                            </div>
                        </div>
                    </Link>}
                </div>
            </div>
           {(menuList.indexOf('Precision')!==-1 || menuList.indexOf('BatchCoupon')!==-1) && <div className={styles.homeTop} >
                <div className={styles.topTitle}>
                    <span></span>
                    <span>营销/发券</span>
                </div>

                <div className={styles.active}>
                 {menuList.indexOf('Precision')!==-1 && <Link to="/market/page?text=Precision">
                        <div className={styles.activeItem} style={{ boxShadow: '0 0 10px 0 rgba(136,222,0,0.4)' }}>
                            <div className={styles.itemBox}>
                                <div style={{ color: 'rgb(132,215,0)' }}>
                                    <img src={yingxiao} alt="" />
                                    <span>精准营销</span>
                                </div>
                                <div>办理会员，会员充值，赠品多多，福利多多。</div>
                            </div>
                        </div>
                    </Link>}
                   {menuList.indexOf('BatchCoupon')!==-1 && <Link to='/market/page?text=BatchCoupon'>
                        <div className={styles.activeItem} style={{ boxShadow: '0 0 10px 0 rgba(255,106,99,0.4)' }}>
                            <div className={styles.itemBox}>
                                <div style={{ color: 'rgb(255,106,99)' }}>
                                    <img src={ziyuan} alt="" />
                                    <span>精准发券</span>
                                </div>
                                <div>千万礼券发放中，券券在手，优惠不停。</div>
                            </div>
                        </div>
                    </Link>}
                </div>
            </div>}
        </>
    )
}