import { useContext, useEffect, useState } from "react";
import { AuthLogic } from "../../../../logic/auth_logic";
import { ColumnsType } from "antd/lib/table";
import { Control, See, Edit, SubmitAudit } from "../../../../component/control-column";
import { useBoolean } from "ahooks";
import { Search } from "../../../../component/search";
import { Input,Button } from "antd";
import { Tips, TipsGroup } from "../../../../component/tips";
import { Mask } from "../../../../component/mask";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { DataTable } from "../../../../component/table";
import { Progress, defualtPageSize } from "../../../../logic/common_logic";
import { AfterSalesFormStoreProvider } from "../../store/afterSale/form_store";
import { ConfirmModal } from "../../../../component/modal";
import {FileSearch} from './file_search'
import { Form } from "./form";
import { fileAction } from "../../action/afterSale/file_action";
import { AfterSalesStore } from "../../store/afterSale/file_store";
import { DataTableStore } from "../../../../component/table/store/data_table_store";
import { formAction } from '../../action/afterSale/form_action'

/**
 * 售后单
 */
const formParams = {
    id: '',
    method:''
}
export function InvioceAfterSales(viewPower){
    const [createState, createFunc] = useBoolean(false)
    const [confirmState, confirmFunc] = useBoolean(false)
    const [searchState, searchFunc] = useBoolean(false)
    const action = fileAction
    const { state, dispatch} = useContext(AfterSalesStore)
    const {state: tableState ,dispatch: tableDispatch} = useContext(DataTableStore)
    const [menuButton, setData] = useState([]);
    useEffect(() => {
      (async function () {
        let r = await new AuthLogic().selButton(viewPower.viewPower) || [];
        if (r && r.length > 0) {
          for (let i = 0; i < r.length; i++) {
            r[i] = r[i].split("_")[r[i].split("_").length - 1];
          }
        }
        r = r.concat(['VIEW','ADD','UPDATE','AUDIT'])
        setData(r);
      })();
  
      return () => {};
    }, []);
    
    const columns: ColumnsType = [
        {
        key: 0,
        title:'操作',
        dataIndex: 'id',
        render: Control.render(
            (v, r)=>
                menuButton.indexOf("VIEW") != -1 && (
                    <See
                    click={() => {
                        formParams.id = v;
                        formParams.method = "Preview";
        
                        createFunc.setTrue();
                    }}
                    />
                ),
            (v, r)=>
                menuButton.indexOf("UPDATE") != -1 && (
                    <Edit
                        click={() => {
                            formParams.id = v;
                            formParams.method = "Update";        
                            createFunc.setTrue();
                        }}
                    />
                ),
            (v, r)=>
                menuButton.indexOf("AUDIT") != -1 && (
                    <SubmitAudit
                        click={() => {
                            formParams.id = v;
                            formParams.method = "Audit";        
                            // createFunc.setTrue();
                        }}
                    />
                ),
        )
        },
        {
            title: '售后单号',
            key: 1,
            dataIndex:'afterSalesNumber',
            align: 'center'
        },
        {
            title: '发货单号',
            key: 2,
            dataIndex:'invoiceNumber',
            align: 'center'
        },
        {
            title: '售后商品数量',
            key: 3,
            dataIndex:'totalNum',
            align: 'center'
        },
        {
            title: '退款总金额',
            key: 4,
            dataIndex:'totalNum',
            align: 'center'
        },
        {
            title: '售后类型',
            key: 5,
            dataIndex:'afterSalesType',
            align: 'center'
        },
        {
            title: '售后状态',
            key: 6,
            dataIndex:'salesStatus',
            align: 'center'
        },
        {
            title: '审核状态',
            key: 7,
            dataIndex:'auditStatus',
            align: 'center'
        },
        {
            title: '创建时间',
            key: 8,
            dataIndex:'createTime',
            align: 'center'
        },
    ]
    const onSearch = async (pageIndex) => {
        await action.loadPage({
          pageIndex: pageIndex,
          pageSize: defualtPageSize(),
          dispatch: tableDispatch,
          search: state.search,
        });
      };
    return (
        <>
        <Search>
          <Input
            maxLength={50}
            placeholder="请输入单号"
            onChange={(e) =>
              action.setCondition({
                dispatch,
                key: "keywords",
                value: e.target.value,
              })
            }
          />
          <input type="button" value="快速查找" onClick={() => onSearch(1)} />
  
          <TipsGroup>
            <input
              type="button"
              value="高级查找"
              onClick={() => searchFunc.toggle()}
            />
            <Tips width="536px" display={searchState}>
              <FileSearch
                // onSearch={(...arg: [any]) => {
                //   onSearch(...arg);
                //   searchFunc.setFalse();
                // }}
              />
            </Tips>
            <Mask visible={searchState} onClick={() => searchFunc.setFalse()} />
          </TipsGroup>
        </Search>
  
        <ButtonPanel>
          {menuButton.indexOf("ADD") != -1 && (
            <Button
              onClick={() => {
                formParams.id = "";
                formParams.method = "Create";
                createFunc.setTrue();
              }}
            >
              新增售后单
            </Button>
          )}
         
        </ButtonPanel>
  
        <DataTable
          columns={columns}
          action={action}
          onLoad={async () => {
            await onSearch(1);
            Progress.done();
          }}
          onChange={(pageIndex) => {
            onSearch(pageIndex);
          }}
          scroll={{ scrollToFirstRowOnChange: true, autoY: true }}
          loading={tableState.loading}
        />
  
        {createState && (
          <AfterSalesFormStoreProvider>
            <Form
              close={(hasUpdate) => {
                if (hasUpdate) {
                  onSearch(tableState.current);
                }
  
                createFunc.setFalse();
              }}
              action={formAction}
              id={formParams.id}
              method={formParams.method}
            />
          </AfterSalesFormStoreProvider>
        )}
  
        <ConfirmModal
          visible={confirmState}
          title="温馨提示"
          subTitle="确认删除？"
          message="删除之后信息无法恢复，请慎重考虑"
          close={async (r) => {
            if (r) {
              await action.delRow(formParams.id);
  
              onSearch(tableState.current);
            }
            confirmFunc.setFalse();
          }}
        />
      </>
    )
}