
import { useBoolean, useRequest } from "ahooks";
import { DatePicker,Tooltip, Select } from "antd";
import React, { useState, useContext, useRef, useEffect} from "react";
import { transactionAction } from "../action/transaction_action";
import styles from "../index.module.scss";
import { TransactionStore } from "../store/transaction_store";

import img_xiaoshoue from "../../../assets/images/survey/xiaoshoue.png";
import img_huiyuan from "../../../assets/images/survey/huiyuan.png";
import img_dingdan from "../../../assets/images/survey/dingdan.png";
import img_tuikuan from "../../../assets/images/survey/tuikuan.png";
import img_kedanjia from "../../../assets/images/survey/kedanjia.png";
import moment from "moment";
import { ChooseShop } from '../../../component/choose-shop'

let number = 1000000
const { RangePicker } = DatePicker;
const progressNav = ["今天", "昨天", "最近7天", "最近30天", "自定义"];

const propressList = [
    {
        icon: img_xiaoshoue,
        title: "销售金额",
        money: "¥4300.00",
        sun: "20%",
        moon: "80%",
    },
    {
        icon: img_kedanjia,
        title: "客单价",
        money: "¥1430.00",
        sun: "20%",
        moon: "80%",
    },
    {
        icon: img_huiyuan,
        title: "会员充值",
        money: "¥1430.00",
        sun: "20%",
        moon: "80%",
    },
    {
        icon: img_dingdan,
        title: "订单数量",
        money: "430笔",
        sun: "20%",
        moon: "80%",
    },
    {
        icon: img_tuikuan,
        title: "退款金额",
        money: "¥216.00",
        sun: "20%",
        moon: "80%",
    },
]

export function Transaction() {
    const [active, setActive] = useState(0);
    const { state, dispatch } = useContext(TransactionStore);
    const { item } = state;
    const [shopState,shopFunc] =  useBoolean(false)
    const action = transactionAction;
    const {Option} = Select
    useRequest(() => action.init({ state, dispatch }));

    return (
        <div className={styles.top}>

            <div className={styles.head_c}>
                <span className={styles.point} />
                <span className={styles.title} >
                    交易情况
                </span>
                <div className={styles.btns_c}>
              {/* <div className='selectBox'>
              <Select   style={{ width: "12rem"}}  autoFocus
                                onChange={(v) => {}}
                                defaultValue={-1}
                                showSearch
                                filterOption={(input:any, option:any) => {
                                    return option.children.indexOf(input) >= 0;
                                }}
                            >
                                <Option value={-1}>全部店铺</Option>
                                <Option value="1">店铺1</Option>
                                <Option value="2">店铺2</Option>
                            </Select>
              </div> */}
                    {
                        progressNav.map((v, i) => <span key={i} className={`${styles.item} ${active == i ? styles.active : ''}`} onClick={
                            () => {
                                // 1：今天；2：昨天；3：最近7天；4：近30天；5：自定义
                                action.setType(i + 1, { condition: state.condition, dispatch });
                                setActive(i)
                            }
                        }>{v}</span>)
                    }
                    {
                        active == progressNav.length - 1 && <RangePicker defaultValue={[moment(state.condition.startTime || new Date()), moment(state.condition.endTime || new Date())]} className={styles.date} onChange={(v, arr) => {
                            action.setDate({ startTime: arr[0], endTime: arr[1] }, { condition: state.condition, dispatch })
                        }} />
                    }
                </div>
            </div>

            <div className={styles.body_c}>
                <div className={styles.item_group}>
                    {
                        propressList.map((v, i) => {
                            return (
                                <div key={i} className={styles.item}>
                                    <div className={styles.top_c}>
                                        <i>
                                            <img src={v.icon} />
                                        </i>
                                        <span>{v.title}</span>
                                    </div>
                                <Tooltip 
                                // placement='bottom'
                                    title={i==0?item.salesAmount.toLocaleString():
                                    i==1?item.quantityOfOrder!=0?(item.salesAmount/item.quantityOfOrder).toFixed(2):'0.00':
                                    i==2?item.memberRecharge.toLocaleString():
                                    i==3?item.quantityOfOrder.toLocaleString():
                                    i==4?item.refundAmount.toLocaleString():null} 
                                     >    
                                        <span className={styles.cent_c} >
                                            {i == 0 && `¥${item.salesAmount>1000000?'100w+':item.salesAmount.toLocaleString()}`}
                                            {i == 1 && `¥${item.quantityOfOrder!=0?item.salesAmount/item.quantityOfOrder>1000000?'100w+':(Number((item.salesAmount/item.quantityOfOrder).toFixed(2)).toLocaleString()):0.00}`}
                                            {i == 2 && `¥${item.memberRecharge>1000000?'100w+':item.memberRecharge.toLocaleString()}`}
                                            {i == 3 && `${item.quantityOfOrder>1000000?'100w+':item.quantityOfOrder.toLocaleString()}`}
                                            {i == 4 && `¥${item.refundAmount>1000000?'':item.refundAmount.toLocaleString()}`}
                                        </span>
                                    </Tooltip>
                                    {/* <div className={styles.bottom_c}>
                                        <span className={styles.left}>日环比 {v.sun}</span>
                                        <span className={styles.right}>月环比 {v.moon}</span>
                                    </div>
                                    <div className={styles.round}>
                                        <Pie index={i} rate={0} />
                                    </div>*/}
                                    <div className={styles.line}></div> 
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <ChooseShop
        visibleModal={shopState}
        close={(status, r) => {
          if (r) {
            // action.setCondition({
            //   dispatch,
            //   key: "shopName",
            //   value: r[0].shopName,
            // });
            // action.setCondition({ dispatch, key: "shop", value: r });
          }
          shopFunc.setFalse();
        }}
      />
        </div>

    )
}

declare var echarts: any;

function Pie({ index, rate }) {
    const divRef = useRef();

    useEffect(() => {
        const dataList = [[
            { value: 80, name: "完成率", itemStyle: { color: "#C94ED0" } },
            { value: 20, name: "未完成率", itemStyle: { color: "#F7B151" } },
        ], [
            { value: 70, name: "完成率", itemStyle: { color: "#52DBFA" } },
            { value: 30, name: "未完成率", itemStyle: { color: "#FF746D" } },
        ], [
            { value: 90, name: "完成率", itemStyle: { color: "#31B573" } },
            { value: 10, name: "未完成率", itemStyle: { color: "#F4B57F" } },
        ], [
            { value: 60, name: "完成率", itemStyle: { color: "#FAB952" } },
            { value: 40, name: "未完成率", itemStyle: { color: "#0A66F6" } },
        ]]

        var dom = divRef.current;

        var myChart = echarts.init(dom);
        var app = {};
        var option = null;
        option = {
            tooltip: {
                trigger: "item",
                formatter: "{b}:{d}%",
            },
            legend: {
                left: "center",
                top: "bottom",
                data: ["完成率", "未完成率"],
            },
            series: [
                {
                    name: "访问来源",
                    type: "pie",
                    radius: ["60%", "70%"],
                    avoidLabelOverlap: false,
                    data: dataList[index],
                    label: {
                        normal: {
                            show: true,
                            position: "center",
                            formatter: function () {
                                return [
                                    `{b|${80 + "%"}}`,
                                    `{a|目标完成率}`,
                                ].join("\n");
                            },
                            rich: {
                                a: {
                                    color: "#333333",
                                    fontSize: 12,
                                    lineHeight: 30,
                                },
                                b: {
                                    color: "#666666",
                                    fontSize: 12,
                                    foneWeight: "bold",
                                    textShadowBlur: 20,
                                },
                            },
                        },
                    },
                },
            ],
        }
        if (option && typeof option === "object") {
            myChart.setOption(option, true);
        }
    }, []);

    return (
        <div ref={divRef} style={{ height: "100%" }}></div>
    )
}