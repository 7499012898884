import { message } from "antd";
// import axios from "axios";
import Cookies from "js-cookie";
import { DataTableAction } from "../../../../component/table/action/data_table_action";
import { Tools, axios } from "../../../../config";
import { ShopLogic } from "../../../../logic/shop_logic";
import { UserLogic } from "../../../../logic/user_logic";
import { DistributionRecordsLogic } from "../../../../logic/distributionRecords_login";

class InventoryAction extends DataTableAction {
  async delRow(id) {
    // const r = await new CheckLogic().delData(id);
    // if (r.data.statusCode == 200) {
    //     message.success("删除成功")
    // }
  }
  delSelectList({ selectList }: { dispatch: any; selectList: any }) {
    // selectList.map(async ({ id }) => {
    //     const r = await axios({
    //         method: "GET",
    //         url: Tools.getApiAddress("shop_delete"),
    //         params: {
    //             shopId: id
    //         },
    //         headers: {
    //             SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
    //         },
    //     })
    //     if (r.data.statusCode == 200) {
    //         message.success("删除成功")
    //     }
    // })
  }
  async loadPage({ dispatch, pageIndex, pageSize, search, state }) {
    this.setLoading({ dispatch, loading: true });
    const { keywords, beginTime, endTime, actionType, auditStatus } = search;
    const selParams = {
      seacherKey:keywords,
      beginTime: beginTime,
      endTime: endTime,
      memberIds: state.searchList
        ? state.searchList.map((item) => item.id)
        : [],
      pageIndex,
      auditStatus: auditStatus || null,
      pageSize,
      orderBy: "",
      sort: "",
    };

    // 列表
    const r = await new DistributionRecordsLogic().queryWithDrawList(selParams);
    // console.log(r);
    if (r && r.data && r.data.dataSource) {
      const dataList = r.data.dataSource;

      const list = dataList.map((v, i) => {
        v.key = i + 1;
        return v;
      });
      this.setPaging({
        dispatch,
        pageIndex,
        total: r.data.totalCount,
        dataList: list,
        pageSize,
      });
    }
    this.setLoading({ dispatch, loading: false });
  }
  setCondition(method, value, dispatch) {
    if ("Keywords" == method) {
      dispatch({ type: "SetSearch", payload: { keywords: value } });
    }
    if (method == "beginTime") {
      dispatch({ type: "SetSearch", payload: { beginTime: value } });
    }
    if (method == "endTime") {
      dispatch({ type: "SetSearch", payload: { endTime: value } });
    }
    if (method == "SetSearchList") {
      dispatch({ type: "SetSearchList", payload: value });
    }
  }
  async withdraw(params, dispatch) {
    dispatch({ type: "setLoading", payload: true });
    const r = await new DistributionRecordsLogic().withdraw(params);
    if (r.data.statusCode == 200) {
      dispatch({ type: "setLoading", payload: false });
      message.success("确认成功");
    } else {
      dispatch({ type: "setLoading", payload: false });
      message.error(r.data.returnMessage || "确认失败");
    }
  }
}
export const inventoryAction = new InventoryAction();
