import { message } from "antd";
import { GoodsEntity } from "../../../../entity/goods_entity";
import { CheckLogic } from "../../../../logic/check_logic";
import { defualtPageSize } from "../../../../logic/common_logic";
import { CostLogic } from "../../../../logic/cost_logic";
import { GoodsLogic,ISelList } from "../../../../logic/goods_logic";
import { ReportLogic } from "../../../../logic/report_logic";
import { UserLogic } from "../../../../logic/user_logic";
import { IState, TableItem } from "../../store/cost/cost_form_store";
import Cookies from 'js-cookie'
type Init = {
    state;
    dispatch;
    form;
    id: string;
    method: string;
}
interface InitBasic {
    dispatch;
    search;
}
interface InitEdit {
    state;
    dispatch;
    form;
    id;
}

class CostFormAction {
    async init({ state, dispatch, id, method, form }: Init) {
        await this.initBasic({ dispatch, search: state.search });
        if ("Create" !== method && id) {
            await this.initEdit({ dispatch, state, id, form })
        }
    }
    private async initBasic({ search, dispatch }: InitBasic) {
        this.clearTableList({ dispatch });
    //     const list = await (await new ShopLogic().selListpost()).data.data.dataSource || []
    //     dispatch({type: 'LoadShop',payload: list})
    //    let defaultShop = list.filter(item=> item.isdefault == true)
    //    if(defaultShop.length>0){
    //        let shopinfo = defaultShop[0]
    //     this.setFormData({ dispatch, key: "shop", value: shopinfo });
    //    }
       let { shopID, shopName } = JSON.parse(Cookies.get('loginMsg'))
       this.setFormData({ dispatch, key: "shop", value: {id:shopID,shopName: shopName} });
    //    this.setFormData({
    //      dispatch,
    //      key: "shopName",
    //      value: shopName,
    //    });
        // 用户信息
        {
            const r = await new UserLogic().selData();

            this.setFormData({ dispatch, key: "userName", value: r.userName });
        }
        // 商品搜索
        {
            const { keywords, classesId, statusId, typeId, brandId } = search;

            const params = {
                pageIndex: 1, pageSize: defualtPageSize(), goodsBrandID: brandId, goodsClassesID: classesId, goodsType: typeId, status: statusId, goodsNameOrNo: keywords
            }
            if (undefined == keywords) {
                delete params.goodsNameOrNo;
            }
            if (undefined == brandId) {
                delete params.goodsBrandID;
            }
            if (undefined == classesId) {
                delete params.goodsClassesID;
            }
            if (undefined == typeId) {
                delete params.goodsType;
            }
            if (undefined == statusId) {
                delete params.status;
            }

            const r = await new GoodsLogic().selListByZeroInventory(params);

            if (r.data.statusCode == 200) {
                const dataList = r.data.data.dataSource;
                let arr = []
            //     dataList.map((goods_v, i) => {
            //         if(goods_v.goods_SpecificationList&&goods_v.goods_SpecificationList.length>0){
            //          for(let i =0;i<goods_v.goods_SpecificationList.length;i++){
            //              let good = goods_v.goods_SpecificationList[i]
            //                  if(arr.filter(ss=>ss.id == good.id).length>0){
            //                      continue;
            //                  }
            //                  delete goods_v.id;
            //                  arr.push({
            //                      key: i + 1,
            //                      ...goods_v,
            //                      ...good,
            //                      goodsUnitName: goods_v.unitName,
            //                      goodsSpecifications:[good],
            //                      goodsName:goods_v.goodsName,
            //                      id: good.id,
            //                      value: goods_v.goodsName,
            //                      storePrice:good.storePrice
            //                  })
                        
            //          }
            //         }
               
            //  })

                dispatch({ type: "SetSearchList", payload: dataList });

            }
        }

    }
    private async initEdit({ dispatch, id }: InitEdit) {
        //console.log(4474)
        // 回填状态
        const item = await new CostLogic().selData(id);
        //console.log(item)
        //先把提取出规格对于的入库数据
        let goodsList: any[] = [];
        let goods;
        // item.goodslist.forEach((item) => {
        //     goods = goodsList.find((_item) => {
        //         return item.goodsID == _item.goodsID;
        //     });
        //     if (!goods) {
        //         goods = item;
        //         goodsList.push(goods);
        //     }
        //     (goods.goodsSpecifications___ || (goods.goodsSpecifications___ = [])).push({
        //         id: item.specificationID,
        //         latestPrice: item.latestPrice,
        //         beforePrice: item.beforePrice,
        //         difference: item.difference,
        //     });
        // });
        // item.goodslist = goodsList;
        // let _data;
        // //添加进商品规格
        // for (let _item of item.goodslist) {
        //     //请求商品详情数据
        //     _item.goodsSpecifications = (await new GoodsLogic().selData(_item.goodsID)).goodsSpecifications;
        //     //根据规格id合并入库数据
        //     _item.goodsSpecifications.forEach((__item) => {
        //         _data = _item.goodsSpecifications___.find((___item) => {
        //             return __item.id == ___item.id;
        //         });
        //         __item.latestPrice = _data.latestPrice;
        //         __item.beforePrice = _data.beforePrice;
        //         __item.difference = _data.difference;
        //     });
        // }
        //console.log(item)
        // let list = []
        // for(let good of item.goodslist) {
        //    let seplist =  (await new GoodsLogic().selData(good.goodsID)).goodsSpecifications
        //    let sep;
        //    if(seplist){
        //         sep = seplist.filter((s)=>s.id == good.specificationID)
        //         if(sep.length>0){
        //             sep[0]['latestPrice'] = good.latestPrice
        //             sep[0]['beforePrice'] = good.beforePrice
        //             sep[0]['difference'] = good.difference
        //         }
              
        //     }
        //     list.push({
        //         ...good,              
        //         goodsSpecifications: sep,
        //         id: good.specificationID
        //     })

        // }
        // item.goodslist = list;
        dispatch({ type: "SetForm", payload: item });
    }
    private paddingData(state: IState, status: 1 | 2 | 3) {
        let params: any = {};
        {
            const { formData, tableList } = state;

            {
                const { reasonId, remark, shop } = formData;
                params.remark = remark;
                params.shopID = shop.id;
                params.status = status;
                params.reason = reasonId;
                // params.numberNo = numberNo;

                params.goodslist = [];

                tableList.forEach((v, i) => {
                    // v.goodsSpecifications.forEach((s) => {
                        // let latestPrice = typeof v.latestPrice == 'number' ? v.latestPrice : v.latestPrice;
                        params.goodslist.push({
                            "storageBatchNumber": v.storageBatchNumber,
                            "goodsNo": v.goodsNo,
                            "goodsID": v.goodsId,
                            // "specificationID": v.id,
                            "goodsName": v.goodsName,
                            'goodsUnit': v.goodsUnitID,
                            //
                            "beforePrice": v.beforePrice,
                            "afterPrice": v.latestPrice,
                            "difference": (v.latestPrice - v.beforePrice).toFixed(2),
                        })
                    // });
                })

            }
        }
        return params;
    }
    async addSubmit({ state, status }) {
        console.log(state)
        if (!state.formData.shop.id || state.formData.shop.id == undefined) {
            message.error("请选择门店");
            return false;
        }
        if (!state.formData.reasonId || state.formData.reasonId == undefined) {
            message.error("请选择变更理由");
            return false;
        }
        if (!state.tableList || !state.tableList.length) {
            message.error("请选择变更商品");
            return false;
        }
        if (!state.tableList || !state.tableList.length) {
            message.error("请选择变更商品");
            return false;
        }
        let num = 0
        for (let i = 0; i < state.tableList.length; i++) {
            //console.log(state.tableList[i].goodsSpecifications)
            // for (let k = 0; k < state.tableList[i].goodsSpecifications.length; k++) {
                if (!state.tableList[i].latestPrice || state.tableList[i].latestPrice <= 0) {
                    num = num + 1
                }
            // }
        }
        if (num > 0) {
            message.error("商品变更信息未填写完整");
            return false;
        }
        if (!state.tableList || !state.tableList.length) {
            message.error("请选择商品");
            return false;
        }
        const r = await new CostLogic().addData(this.paddingData(state, status));
        if (r.data.statusCode == 200) {
            message.success("保存成功");
        } else {
            message.error(r.data.message);
        }
        return true;
    }
    async updSubmit({ state, status }, id, numberNo) {
        if (!state.formData.shop.id || state.formData.shop.id == undefined) {
            message.error("请选择门店");
            return false;
        }
        if (!state.formData.reasonId || state.formData.reasonId == undefined) {
            message.error("请选择变更理由");
            return false;
        }
        if (!state.tableList || !state.tableList.length) {
            message.error("请选择变更商品");
            return false;
        }
        if (!state.tableList || !state.tableList.length) {
            message.error("请选择变更商品");
            return false;
        }
        let num = 0
        for (let i = 0; i < state.tableList.length; i++) {
            //console.log(state.tableList[i].goodsSpecifications)
            // for (let k = 0; k < state.tableList[i].goodsSpecifications.length; k++) {
                if (!state.tableList[i].latestPrice || state.tableList[i].latestPrice <= 0) {
                    num = num + 1
                }
            // }
        }
        if (num > 0) {
            message.error("商品变更信息未填写完整");
            return false;
        }
        const params = this.paddingData(state, status);

        params.id = id;
        params.numberNo = numberNo;

        const r = await new CostLogic().updData(params);
        if (r.data.statusCode == 200) {
            message.success("更新成功");
        } else {
            message.error(r.data.message);
        }
        return true;
    }
    async addTableItemBySimple({ dispatch, state }: { dispatch, state: IState }) {
        const { searchList, formData } = state;

        if (formData.keyword) {
            let goods: GoodsEntity;
            searchList.forEach(v => {
                if (v.id == formData.keyword) {
                    goods = v;
                }
            })
            if (goods) {
                const item = new TableItem(goods);
                item.countLatestInventoryPrice(state.formData.latestPrice || 0);

                // const r = await new UnitLogic().selData(goods.goodsUnitID);

                //获取商品规格信息
                // item.goodsSpecifications = (await new GoodsLogic().selData(goods.id)).goodsSpecifications;

                // item.unitName = r.unitName;

                dispatch({ type: "AddTableItem", payload: item });

                this.setFormData({ dispatch, key: "keyword", value: "" });
                this.setFormData({ dispatch, key: "latestPrice", value: "" });
            }
        }
    }
    addTableItem({ dispatch, list }) {
        list.forEach(async goods => {
            const item = new TableItem(goods);

            // const r = await new UnitLogic().selData(goods.goodsUnitID);
            //获取商品规格信息
            // item.goodsSpecifications = (await new GoodsLogic().selData(goods.id)).goodsSpecifications;
            // item.goodsSpecifications = goods.goods_SpecificationList
            // item.unitName = r.unitName;
            dispatch({ type: "AddTableItem", payload: item });
        })
    }
    delTableItem(dispatch, index) {
        dispatch({ type: "DelTableItem", payload: index });
    }
    setFormData({ dispatch, key, value }) {
        const params = {
        }
        params[key] = value;

        dispatch({ type: "SetFormData", payload: params })
    }
    setKeywords({ dispatch, value, state }) {
        this.setFormData({ dispatch, key: "keyword", value: value });

        state.searchList.forEach(e => {
            if (e.id == value) {
                this.setFormData({ dispatch, key: "keyword", value: value });

                dispatch({ type: "SetFormData", payload: { difPrice: e.putNewPrice } })
            }

        })
    }
    changeTableItem({ dispatch, key, value, index }) {
        dispatch({ type: "ChangeTableItem", payload: { key, value, index } });
    }
    clearTableList({ dispatch }) {
        dispatch({ type: "ClearTableList" });
    }
    async getGoods({id,dispatch,state}){
        const that = this
        let param:ISelList = {
            goodsClassesID: [],
            goodsNameOrNo: id,
            pageIndex: 1,
            pageSize: 10,
            goodsBrandID:''
        }
            const r = await new GoodsLogic().selList(param)
            if( r.data.statusCode == 200 && r.data.data &&r.data.data.dataSource && r.data.data.dataSource.length>0){
                const goods = r.data.data.dataSource[0]
                const item = new TableItem(goods);
                item.countLatestInventoryPrice(state.formData.latestPrice || 0);
                dispatch({ type: "AddTableItem", payload: item });

                this.setFormData({ dispatch, key: "keyword", value: "" });
                this.setFormData({ dispatch, key: "latestPrice", value: "" });
            }
    }
}
export const costFormAction = new CostFormAction();