import { useBoolean, useRequest } from "ahooks";
import { Checkbox, Col, Input, Row, Select } from "antd";
import { Form as FormAntd } from "antd";
import { useContext, useEffect, useState } from "react";
import { ChooseShop } from "../../../../component/choose-shop";
import { autoRem } from "../../../../global.style";
import { IntegralRuleStore } from "../../store/integralRule/form_store";
import { formAction } from "../../action/integralRule/form_action";
import { Button } from "../../../../component/button";
import { AuthLogic } from "../../../../logic/auth_logic";
import { Progress } from "../../../../logic/common_logic";
import styles from "../../style/integralRule.module.scss";
export function IntegralRule(viewPower) {
  const [form] = FormAntd.useForm();
  const [shopState, shopFunc] = useBoolean(false);
  const { state, dispatch } = useContext(IntegralRuleStore);
  const { formData } = state;
  const action = formAction;
  const [power, powerFunc] = useBoolean(true);
  useRequest(() => {
    formAction.init({ dispatch, form });
    Progress.done();
  });
  function Radio({ onChange, defaultValue, disabled }) {
    const [state, stateFunc] = useBoolean(false);

    useEffect(() => {
      if (defaultValue == 0) {
        stateFunc.setTrue();
      } else {
        stateFunc.setFalse();
      }

      return () => {};
    }, [defaultValue]);

    return (
      <div
        className={`${styles.radio} ${state ? styles.active : ""} ${
          disabled ? styles.disabled : ""
        }`}
        onClick={() => {
          if (disabled) return;
          onChange(!state);
          stateFunc.toggle();
        }}
      ></div>
    );
  }

  return (
    <div
      style={{
        margin: "" + autoRem(1.9375) + " 0 0 0",
        padding: "1rem",
        boxShadow:
          "0px " +
          autoRem(0.3125) +
          " " +
          autoRem(1.25) +
          " 0px rgba(209, 206, 206, 0.3)",
      }}
    >
      <FormAntd form={form}>
        <Row gutter={16}>
          <Col span={10}>
            <FormAntd.Item
              name="status"
              label="是否启用"
              labelCol={{ span: 8 }}
            >
              <Radio
                disabled={false}
                defaultValue={formData.status}
                onChange={(v) =>
                  action.setFormData({
                    dispatch,
                    key: "status",
                    value: v ? 0 : 1,
                  })
                }
              />
            </FormAntd.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={10}>
            <FormAntd.Item
              name="integralType"
              label="规则类型"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: true,
                  message: "请选择规则类型",
                },
              ]}
            >
              <Select
                onChange={(e) =>
                  action.setFormData({
                    dispatch,
                    key: "integralType",
                    value: e,
                  })
                }
              >
                <Select.Option value={1}>向上取整</Select.Option>
                <Select.Option value={2}>向下取整</Select.Option>
                <Select.Option value={3}>四舍五入</Select.Option>
              </Select>
            </FormAntd.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={10}>
            <FormAntd.Item
              name="money"
              label="消费金额"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: true,
                  message: "请输入消费金额",
                },
                {
                  pattern: /^([1-9][0-9]*)$/,
                  message: "请输入大于0的整数",
                },
              ]}
            >
              <Input
                type="number"
                min="1"
                onChange={(e) =>
                  action.setFormData({
                    dispatch,
                    key: "money",
                    value: e.target.value,
                  })
                }
              ></Input>
            </FormAntd.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={10}>
            <FormAntd.Item
              name="giveIntegral"
              label="赠送积分"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: true,
                  message: "请输入赠送积分",
                },
                {
                  pattern: /^([1-9][0-9]*)$/,
                  message: "请输入大于0的整数",
                },
              ]}
            >
              <Input
                type="number"
                min="1"
                onChange={(e) =>
                  action.setFormData({
                    dispatch,
                    key: "giveIntegral",
                    value: e.target.value,
                  })
                }
              ></Input>
            </FormAntd.Item>
          </Col>
        </Row>
        {/* <Row gutter={16} style={{ marginTop: 0 }}>
          <Col span={10}>
            <FormAntd.Item
              label="分配门店"
              labelCol={{ span: 8 }}
              name="shopName"
              rules={[
                {
                  required: true,
                  validator: (_, __, callBack: (error?: string) => void) => {
                    if (
                      state.formData.shopID == undefined ||
                      state.formData.shopID.length == 0
                    ) {
                      callBack("请选择分配门店");
                      return;
                    }
                    callBack();
                  },
                },
              ]}
            >
              {formData.shopName ? "" : ""}
              <Input
                // style={{ width: "300px" }}
                disabled={false}
                value={formData.shopName}
                autoComplete="off"
                placeholder="请选择门店"
                onClick={() => shopFunc.setTrue()}
              />
            </FormAntd.Item>
          </Col>
        </Row> */}
      </FormAntd>
      <Button
        type="primary"
        color="Red"
        style={{ marginLeft: "1.25rem" }}
        loading={state.isLoading}
        onClick={async () => {
          if (!power) {
            return false;
          }
          await form.validateFields();
          action.save({
            state: state,
            dispatch,
          });
        }}
      >
        保存设置
      </Button>
      <ChooseShop
        visibleModal={shopState}
        close={(status, r) => {
          if (r) {
            let arr = [];
            let nameArr = [];
            if (r.length > 0) {
              r.map((item) => {
                arr.push(item.id);
                nameArr.push(item.shopName);
              });
            }
            action.setFormData({ dispatch, key: "shopID", value: arr });
            action.setFormData({
              dispatch,
              key: "shopName",
              value: nameArr.toString(),
            });
            //为表单某个字段设置数据
            form.setFieldsValue({
              shopName: nameArr.toString(),
            });
            //验证表单的某个字段
            // form.validateFields(["shopName"]);
          }

          shopFunc.setFalse();
        }}
      />
    </div>
  );
}
