import { Form_Basic } from "./form_basic";
import { Button } from "../../../../component/button";
import { Layer } from "../../../../component/layer/layer";
import { Form as FormAntd,message } from "antd";
import {
  formAction,
  FormAction,
} from "../../action/goodsClassifyAction/form_action";
import { useContext, useEffect } from "react";
import { FormStore } from "../../store/goods_classify_store/form_store";
import { useBoolean } from "ahooks";
interface IForm {
  children?: any;
  action: FormAction;
  close: (hasUpdate?: boolean) => void;
  id?: string;
  method?: string;
}
export function Form({ close, action, id, method }: IForm) {
  const [form] = FormAntd.useForm();
  const [loading, loadFunc] = useBoolean(false)
  const { state, dispatch: formDispatch } = useContext(FormStore);
  useEffect(() => {
    formAction.init({
      state: state,
      dispatch: formDispatch,
      id: id,
      form,
      method,
    });

    return () => {};
  }, []);
  const title = () => {
    if ("Create" === method) {
      return "新增商品分类";
    } else if ("Edit" === method) {
      return "编辑商品分类";
    } else {
      return "查看商品分类";
    }
  };

  return (
    <FormAntd form={form}>
      <Layer layerWith={3}>
        <Layer.Title onClick={() => close(true)}>{title()} </Layer.Title>
        <Layer.Body>
          <Form_Basic
            action={formAction}
            disabled={title() == "查看商品分类"}
            title={title()}
            form={form}
            updateFormField={(field, value = "") => {
              //为表单某个字段设置数据
              form.setFieldsValue({
                [field]: value,
              });
              //验证表单的某个字段
              form.validateFields([field]);
            }}
          />
        </Layer.Body>
        <Layer.Foot
          borderStyle={title() == "查看商品分类" ? { border: "none" } : {}}
        >
          {"查看商品分类" !== title() ? (
            <>
              <Button
                loading={state.loading}
                type="primary"
                color="Red"
                style={{ marginRight: "1.25rem" }}
                onClick={async () => {
                  const r = await form.validateFields();
                  if(loading){
                    message.loading('正在提交数据')
                    return false
                }
                  let status = false;

                  if ("Edit" == method) {
                    loadFunc.setTrue()
                    status = await action.updSubmit({
                      state: state,
                      dispatch: formDispatch,
                      form,
                      id: id,
                    });
                    loadFunc.setFalse()
                  } else {
                    loadFunc.setTrue()
                    status = await action.addSubmit({
                      state: state,
                      dispatch: formDispatch,
                      form,
                    });
                    loadFunc.setFalse()
                  }

                  status && close && close(true);
                }}
              >
                保存
              </Button>
              <Button
                type="primary"
                color="Yellow"
                onClick={() => action.reset({ dispatch: formDispatch, form })}
                disabled={state.loading}
              >
                重置
              </Button>
            </>
          ) : null}
          {/* <Button type="primary" color="Yellow" onClick={()=>close(true)} style={{position:'absolute',right:100,}}>返回</Button> */}
        </Layer.Foot>
      </Layer>
    </FormAntd>
  );
}
