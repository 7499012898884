import { createContext, useReducer } from "react";

export const AdvStoreForm = createContext(null);

type Dispatch = { type; payload };

class LinkParam {
  snappID?: string;
  id?: string;
  name?: string;
}
class FormData {
  isLink: boolean = true;
  linkType?: number = 0;
  linkParam?: LinkParam;
  imgUrl: "";
  startTime: string;
  endTime: string;
  status: boolean = true;
  isRepetition: boolean = false;
  advertisingSort: number = 0;
}
interface IState {
  formData: FormData;
  loading: boolean;
}
const initState: IState = {
  formData: new FormData(),
  loading: false,
};

function reducer(state: IState, { type, payload }: Dispatch) {
  switch (type) {
    case "Loading":
      state.loading = payload;
      return {
        ...state,
      };
    case "Reset_Link":
      state.formData.linkType = 0;
      state.formData.linkParam = {};
      return {
        ...state,
        formData: {
          ...state.formData,
        },
      };
    case "SetForm":
      const item = new FormData();
      const {
        jumpType,
        startTime,
        endTime,
        image,
        status,
        jumpValue,
        isRepetition,
      } = payload;
      item.isLink =
        jumpType !== null && jumpType !== false && jumpType !== undefined
          ? true
          : false;
      item.linkType = jumpType;
      item.imgUrl = image;
      item.startTime = startTime;
      item.endTime = endTime;
      item.status = status == 0 ? true : false;
      item.linkParam = jumpValue != "" && JSON.parse(jumpValue);
      item.isRepetition = isRepetition;
      return {
        ...state,
        formData: {
          ...item,
        },
      };
    // case "SetSearch":
    //     return {
    //         ...state,
    //         search: {
    //             ...state.search,
    //             ...payload
    //         }
    //     }
    // 设置表单
    case "Change_FormData": {
      const { attribute, value } = payload,
        props: any = {};

      props[attribute] = value;

      return {
        ...state,
        formData: {
          ...state.formData,
          ...props,
        },
      };
    }
    case "reset":
      return {
        ...state,
        formData: new FormData(),
      };
    default:
      return { ...state };
  }
}
export function AdvStoreFormProvider(props) {
  const [state, dispatch] = useReducer(reducer, initState);
  const v: any = { state, dispatch };
  return (
    <AdvStoreForm.Provider value={v}>{props.children}</AdvStoreForm.Provider>
  );
}
