import React, { useContext, useEffect } from "react";
import { Button } from "../../../component/button";
import { Layer } from "../../../component/layer/layer";
import {
  Tab,
  TabList,
  TabPanel,
  TabsStyle,
} from "../../../component/react-tabs";
import { FormBasic } from "./form_basic";
import { FormDetails } from "./form_details";
import { FormFormat } from "./form_format";
import { FormSupplement } from "./form_supplement";
import { Form as FormAntd, message } from "antd";
import { formAction, FormAction } from "../action/form_action";
import { FormStore } from "../store/form_store";
import {CloseOutlined} from '@ant-design/icons'
import { useBoolean } from "ahooks";
interface IForm {
  children?: any;
  action: FormAction;
  close: (hasUpdate?: boolean) => void;
  id?: string;
  method?: string;
}
export function Form({ close, action, id, method }: IForm) {
  const [form] = FormAntd.useForm();
  const [loading, loadFunc] = useBoolean(false)
  const { state, dispatch: formDispatch } = useContext(FormStore);

  useEffect(() => {
    formAction.init({
      state: state,
      dispatch: formDispatch,
      goodsId: id,
      form,
      method,
    });

    return () => {};
  }, []);

  const title = () => {
    if ("Create" === method) {
      return "新增商品";
    } else if ("Edit" === method) {
      return "编辑商品";
    } else {
      return "查看商品";
    }
  };

  return (
    <FormAntd form={form}>
      <Layer layerWith={8} width={'100%'} onClick={() => {}}>
        <Layer.Title onClick={()=>close(true)}>{title()} </Layer.Title>
        <Layer.Body>
        <FormBasic
                action={formAction}
                disabled={title() == "查看商品"}
                title={title()}
                form={form}
              />
          {/* <TabsStyle style="Capsule">
            <TabList>
              <Tab>基础信息</Tab>
              <Tab>商品规格</Tab>
              <Tab>详情描述</Tab>
              <Tab>补充资料</Tab>
            </TabList>

            基础信息
            <TabPanel>
              <FormBasic
                action={formAction}
                disabled={title() == "查看商品"}
                title={title()}
                form={form}
              />
            </TabPanel>

            商品规格
            <TabPanel>
              <FormFormat disabled={title() == "查看商品"} />
            </TabPanel>

            详情描述
            <TabPanel>
              <FormDetails disabled={title() == "查看商品"} />
            </TabPanel>

            补充资料
            <TabPanel>
              <FormSupplement
                action={formAction}
                form={form}
                disabled={title() == "查看商品"}
              />
            </TabPanel>
          </TabsStyle> */}
        </Layer.Body>
        <Layer.Foot
          borderStyle={title() == "查看商品" ? { border: "none" } : {}}
        >
          {"查看商品" !== title() ? (
            <>
              <Button
                type="primary"
                color="Red"
                style={{ marginRight: "1.25rem" }}
                onClick={async () => {
                  const r = await form.validateFields();
                  if(loading){
                    message.loading('正在提交数据')
                    return false
                }
                  let status = false;

                  if ("Edit" == method) {
                    loadFunc.setTrue()
                    status = await action.updSubmit({
                      state: state.formData,
                      form,
                    });
                    loadFunc.setFalse()
                  } else {
                    loadFunc.setTrue()
                    status = await action.addSubmit({
                      state: state.formData,
                      form,
                      unitList: state.unitList,
                    });
                    loadFunc.setFalse()
                  }

                  status && close && close(true);
                }}
              >
                保存
              </Button>
              <Button
                type="primary"
                color="Yellow"
                onClick={() => action.reset({ dispatch: formDispatch, form })}
              >
                重置
              </Button>
            </>
          ) : null}
          {/* <Button type="primary" color="Yellow" onClick={()=>close(true)} style={{position:'absolute',right:100,}}>返回</Button> */}
        </Layer.Foot>
      </Layer>
    </FormAntd>
  );
}
