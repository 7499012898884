import { createContext, useContext, useReducer } from "react";

export const InventoryStore = createContext<IContextProps>(null);

interface IContextProps {
  state: IState;
  dispatch: ({ type }: Dispatch) => void;
}

type Dispatch = { type; payload };

class Search {
  keywords: string;
  startDate: string;
  endDate: string;
  goods: any;
  shop: any;
  auditStatus: number;
}

interface IState {
  searchList: any[];
  search: Search;
  isLoading: boolean;
  auditStatus: boolean;
}

const initialArgs: IState = {
  searchList: [],
  search: new Search(),
  isLoading: false,
  auditStatus: false,
};

function reducer(state: IState, { type, payload }: Dispatch) {
  switch (type) {
    case "SetSearch":
      return {
        ...state,
        search: {
          ...state.search,
          ...payload,
        },
      };
    case "setLoading":
      state.isLoading = payload;
      return {
        ...state,
      };

    case "SetSearchList":
      return {
        ...state,
        searchList: payload,
      };
    default:
      return state;
  }
}

export function InventoryStoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialArgs);

  const v = { state, dispatch };
  return (
    <InventoryStore.Provider value={v}>
      {props.children}
    </InventoryStore.Provider>
  );
}
