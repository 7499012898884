import { useBoolean } from "ahooks";
import { Col, Form, Form as FormAntd, Input, Row, Collapse, Modal } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useState } from "react";
import { Table } from "../../../../component/table";
import { TableExpand } from "../../../../component/table-expand";
import { TextArea } from "../../../../component/text-area";
import { autoRem } from "../../../../global.style";
import { FormSubtitle } from "../../../commodity/view/form_subtitle";
import styles from "../../style/form_basic.module.scss";
import { Demo } from "../../../../component/treeData";
interface IForm {
  editId?: string;
  seeId?: string;
  passwordId?: string;
  title?: any;
  isdefault?: boolean;
}
export function FormBasic({ disabled, formData, id }) {
  const [list, setList] = useState([]);
  const { Panel } = Collapse;
  const [item, setItem] = useState(formData);

  let height = document.body.clientHeight - 188;
  return (
    <div className={styles.root} style={{ height: height + "px" }}>
      <div style={{ margin: "1.9375rem 0 0 20px" }} className={styles.content}>
        <FormSubtitle>点击展开关系图</FormSubtitle>
        <Demo item={item} key={item || ""} id={id} />
      </div>
    </div>
  );
}
