import { createContext, useReducer } from "react";

export class FormatData {
  id: string;
  goodsID?: string
  image: string;
  unitContentId: string;
  unitContentValue: string = "1";
  unitId: string;
  barCode: string; //规格条码 默认规格条目为商品编码
  pluCode: number
  /**
   * 门店价格
   */
  shopPrice: string;
  /**
   * 商城价格
   */
  storePrice: string;
  lineationPrice: string;
  // lowestPrice: string;
  // highestPrice: string;
  isShop: boolean = true;
  isStore: boolean = false;
  remark: string = "";
  isDefault: boolean = false
  isNegativeInventory: boolean = false
  goodsSource?: number = 1
  wnnCode: string
}

export class FormData {
  id: string;
  type: number = 1;
  classesId: string;
  goodsName: string;
  unitId: string;
  goodsCode: string;
  simpleCode: string;
  brandId: string;
  remark: string;
  formatList: FormatData[] = [];
  goodsImage: string[] = [];
  subImages: string[] = [];
  description: string;
  // default:正常
  statusId: number = 1;
  sort: string;
  english: string;
  selectClasses: string[] = [];
  isNoMarketing: boolean = false; //是否参与优惠
  isManualDiscounts: boolean = false; //手动折扣
  goodsNumber: number; //入库数量
  goodsPrice: number; //入库单价
  manufactureDate: string; //生产日期
  shelfLifeDay: number; //保质期天数
  unitName: string;
  goodsSource: number = 1
}

export type Dispatch = { type; payload };

interface IState {
  classifyList: any[];
  unitList: any[];
  brandList: any[];

  formData: FormData;
}

const initialArgs = {
  classifyList: [],
  unitList: [],
  brandList: [],

  formData: new FormData(),
};

export type FormAttribute =
  | "isManualDiscounts"
  | "isNoMarketing"
  | "Type"
  | "ClassesId"
  | "GoodsName"
  | "UnitId"
  | "GoodsCode"
  | "SimpleCode"
  | "BrandId"
  | "Remark"
  | "GoodsImage"
  | "SubImages"
  | "Description"
  | "StatusId"
  | "Sort"
  | "English"
  | "GoodsNumber"
  | "GoodsPrice"
  | "UnitName"
  | "ShelfLifeDay"
  | "ManufactureDate";
export type FormatAttribute =
  | "Image"
  | "UnitContentId"
  | "UnitContentValue"
  | "UnitId"
  | "Barcode"
  | "PluCode"
  | "ShopPrice"
  | "StorePrice"
  | "LineationPrice"
  | "LowestPrice"
  | "HighestPrice"
  | "IsShop"
  | "IsStore"
  | "IsNegativeInventory"
  | "wnnCode"
  | "Remark";

interface IContextProps {
  state: IState;
  dispatch: ({ type }: Dispatch) => void;
}

export const FormStore = createContext<IContextProps>(null);

function reducer(state: IState, { type, payload }: Dispatch) {
  switch (type) {
    // 加载分类列表
    case "LoadClasses":
      return {
        ...state,
        classifyList: payload,
      };
    // 加载单位列表
    case "LoadUnit":
      return {
        ...state,
        unitList: payload || [],
      };
    // 加载品牌列表
    case "LoadBrand":
      return {
        ...state,
        brandList: payload,
      };
    case 'InitForm':
      {
        let form = new FormData()
        return {
          ...state,
          formData: { ...form},
        };
      }
    // 填充表单
    case "SetForm": {
      const { data, selectClasses } = payload;
      const form = new FormData();

      {
        const {
          goodsName,
          goodsType,
          goodsClassesID,
          goodsUnitID,
          goodsNo,
          simpCode,
          goodsBrandID,
          remark,
          goodsImage,
          subImages,
          detailed,
          status,
          sort,
          englishName,
          goodsSpecifications,
          id,
          isManualDiscounts,
          isNoMarketing,
          unitName,
          goodsNumber,
          goodsPrice,
          goodsSource
        } = data;
        form.unitName = unitName;
        form.id = id;
        form.type = goodsType;
        form.classesId = goodsClassesID;
        form.goodsName = goodsName;
        form.unitId = goodsUnitID;
        form.goodsCode = goodsNo;
        form.simpleCode = simpCode;
        form.brandId = goodsBrandID;
        form.remark = remark;
        form.goodsSource = goodsSource
        form.goodsImage = goodsImage instanceof Array|| !goodsImage ? [] : goodsImage.split(",");
        form.subImages = subImages instanceof Array || !subImages ? [] : subImages.split(",");
        form.description = detailed;
        form.statusId = status;
        form.sort = sort;
        form.english = englishName;
        form.selectClasses = selectClasses;
        form.isNoMarketing = isNoMarketing;
        form.isManualDiscounts = isManualDiscounts;
        form.goodsNumber = goodsNumber;
        form.goodsPrice = goodsPrice;
      }

      // 商品规格
      {

        const { goodsSpecifications } = data;
         let formatList = goodsSpecifications.map((v) => {
          const {
            specificationImage,
            goodsUnitID,
            unitContent,
            barCode,
            pluCode,
            shopPrice,
            storePrice,
            isStore,
            isShop,
            isNegativeInventory,
            isDefault,
            id,
            remark,
            lineationPrice,
            goodsID,
            goodsSource,
            wnnCode
          } = v;
          const ntt = new FormatData();
          // ntt.goodsID = form.id 
          ntt.id = id;
          ntt.goodsID = goodsID
          ntt.image = specificationImage;
          ntt.unitId = goodsUnitID;
          ntt.unitContentValue = unitContent;
          ntt.unitContentId = data.goodsUnitID;
          ntt.barCode = barCode;
          ntt.pluCode = pluCode
          ntt.shopPrice = shopPrice;
          ntt.storePrice = storePrice;
          ntt.isStore = isStore;
          ntt.isShop = isShop;
          ntt.isNegativeInventory = isNegativeInventory
          ntt.isDefault = isDefault
          ntt.remark = remark;
          ntt.lineationPrice = lineationPrice;
          ntt.goodsSource = goodsSource
          ntt.wnnCode = wnnCode
          return ntt;
        });
        let firstData;
        let arr = []
        formatList.length> 0 && formatList.map(fdata=>{
          if(fdata.isDefault){
            firstData = fdata
          }else{
            arr.push(fdata)
          }
        })
        firstData && arr.unshift(firstData)
        form.formatList = arr
      }
      console.log(form)
      return {
        ...state,
        formData: { ...form },
      };
    }
    // 设置表单
    case "Change_FormData": {
      const { attribute, value } = payload,
        props: any = {};

      const key: FormAttribute = attribute;
      let attri = "";

      switch (key) {
        case "ClassesId":
          attri = "classesId";
          break;
        case "GoodsName":
          attri = "goodsName";
          break;
        case "UnitId":
          attri = "unitId";
          const arr = state.unitList.filter((item) => item.id == value);
          if (arr.length > 0) {
            state.formData.unitName = arr[0].unitName;
          }
          // console.log(arr[0]);
          // 联动商品规格下单位
          state.formData.formatList.forEach((v,i) => {
            if(i == 0)
             {v.unitId = value;}
            v.unitContentId = value});
          break;
        case "ManufactureDate":
          attri = "manufactureDate";
          break;
        case "ShelfLifeDay":
          attri = "shelfLifeDay";
          break;
        case "GoodsCode":
          attri = "goodsCode";
          break;
        case "SimpleCode":
          attri = "simpleCode";
          break;
        case "BrandId":
          attri = "brandId";
          break;
        case "Remark":
          attri = "remark";
          break;
        case "GoodsImage":
          attri = "goodsImage";
          break;
        case "SubImages":
          attri = "subImages";
          break;
        case "Description":
          attri = "description";
          break;
        case "StatusId":
          attri = "statusId";
          break;
        case "Sort":
          attri = "sort";
          break;
        case "English":
          attri = "english";
          break;
        case "Type":
          if (value == 2 || value == "2" && state.formData.formatList.length > 0) {
            state.formData.formatList.splice(1);
            if(state.formData.formatList[0]){
              state.formData.formatList[0].pluCode = undefined
            }
          }
          attri = "type";
          break;
        case "isNoMarketing":
          attri = "isNoMarketing";
          break;
        case "isManualDiscounts":
          attri = "isManualDiscounts";
          break;
        case "GoodsNumber":
          attri = "goodsNumber";
          break;
        case "GoodsPrice":
          attri = "goodsPrice";
          break;
        default:
          break;
      }

      props[attri] = value;
      return {
        ...state,
        formData: {
          ...state.formData,
          ...props,
        },
      };
    }
    // 设置规格
    case "Change_FormatData": {
      const { rowIndex, attribute, value } = payload,
      key: FormatAttribute = attribute;
      const formatList = state.formData.formatList || []
      if(formatList.length == 0){
        return {
          ...state,
          formData: {
            ...state.formData,
            formatList,
          },
        };
      }
      let item = formatList[rowIndex];
      let attr = "";

      switch (key) {
        case "Image":
          attr = "image";
          break;
        case "UnitId":
          attr = "unitId";
          //   state.formData[attr] = value;
          break;
        case "UnitContentId":
          attr = "unitContentId";
          break;
        case "UnitContentValue":
          attr = "unitContentValue";
          break;
        case "Barcode":
          attr = "barCode";
          break;
          case "PluCode":
            attr = "pluCode";
            break;
        case "ShopPrice":
          attr = "shopPrice";
          break;
        case "StorePrice":
          attr = "storePrice";
          break;
        case "LineationPrice":
          attr = "lineationPrice";
          break;
        case "LowestPrice":
          attr = "lowestPrice";
          break;
        case "HighestPrice":
          attr = "highestPrice";
          break;
        case "IsShop":
          attr = "isShop";
          break;
        case "IsStore":
          attr = "isStore";
          break;
        case "IsNegativeInventory":
          attr = 'isNegativeInventory'
          break;
        case 'wnnCode':
          attr = 'wnnCode'
          break;
        default:
          break;
      }

      item[attr] = value;
      // console.log(item);
      if (attr == "pluCode") {
        item[attr] = parseInt(value);
      }
      formatList.splice(rowIndex, 1, item);

      return {
        ...state,
        formData: {
          ...state.formData,
          formatList,
        },
      };
    }
    // 添加一行规格
    case "Add_FormatRow": {
      const row = new FormatData();
      const formatList = state.formData.formatList;
      if(formatList.length  == 0){
           row.unitId = state.formData.unitId;
           row.isDefault = true
      }      
      formatList.push(row);
      // 联动商品规格下单位
      formatList.forEach((v) => (v.unitContentId = state.formData.unitId));
     
      return {
        ...state,
        formData: {
          ...state.formData,
          formatList,
        },
      };
    }
    // 删除一行规格
    case "Delete_FormatRow": {
      const rowIndex = payload;

      const formatList = state.formData.formatList;
      formatList.splice(rowIndex, 1);

      return {
        ...state,
        formData: {
          ...state.formData,
          formatList,
        },
      };
    }
    default:
      return state;
  }
}

export function FormStoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialArgs);

  const v = { state, dispatch };
  return <FormStore.Provider value={v}>{props.children}</FormStore.Provider>;
}
