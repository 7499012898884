import { message } from "antd";
import { FapiaoSettingLogic } from "../../../../logic/fapiao_setting_logic";
export class FormAction {
  async init({ dispatch, form }) {
    const res = await new FapiaoSettingLogic().getInfo();
    if(res.data.statusCode === 200 ){
     let item = res.data.data
     if(item){
       dispatch({type:'SetForm',payload: res.data.data})
      form.setFieldsValue({salerTaxNum: item.salerTaxNum})
      form.setFieldsValue({clerk: item.clerk})
      form.setFieldsValue({status: item.orderReceiptTimeOut === 0 ? false: true})
      form.setFieldsValue({appKey: item.appKey})
      form.setFieldsValue({appSecret: item.appSecret})
      form.setFieldsValue({token: item.token})
      form.setFieldsValue({orderReceiptTimeOut: item.orderReceiptTimeOut === 0? undefined: item.orderReceiptTimeOut})
     }
       
    }else{
      message.error(res.data.returnMessage)
    }
  }
  setFormData({ dispatch, key, value }) {
    const params = {};
    params[key] = value;
    dispatch({ type: "Change_FormData", payload: params });
  }
  async save({ state, dispatch }) {
    const { formData } = state;
    let params = {
      salerTaxNum: formData.salerTaxNum,
      clerk: formData.clerk, //开票员名称
      appKey: formData.appKey,
      appSecret: formData.appSecret,
      token: formData.token,
      orderReceiptTimeOut: formData.status ? formData.orderReceiptTimeOut : 0 //订单支付后开票时间限制 0不允许开票 null不限制
    };
    if(formData.id){
        params['id'] = formData.id
    }
    // dispatch({type:'Loading',payload: true})
    const res = await new FapiaoSettingLogic().save(params);
    // dispatch({type:'Loading',payload: false})
    if(res.data.statusCode === 200){
      message.success(res.data.returnMessage)
    }else{
      message.error(res.data.returnMessage)
    }
  }
}
export const formAction = new FormAction();
