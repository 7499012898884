import styles from '../style/editor.module.scss';
import { useContext,useState ,useEffect, useRef} from "react";
import { useBoolean } from 'ahooks'
import ReactQuill from 'react-quill';
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss'
import { TopicDataAction } from "../action/TopicDataAction";
import { ITopicValue, TopicStore } from "../store/topic";
import { topicActions } from '../action/topic_ation';
interface IEditor{  
  description:string,
  color: string,
  isInitCom: boolean
}
export function Editor({id, con}) {
    const topicStoreState = useContext(TopicStore)
    const [color,setColor] = useState('#fffff')
   const [displayColorPicker,displayColorPickerFunc] = useBoolean(false);
   const [length,setLength] =  useState(0); 

    const changeValue = (e) =>{
        data.description = e;
        TopicDataAction.setData<IEditor>(...actionArg, data);
    }   
      const initEditor:IEditor = {
      isInitCom:true,
      description:'',
      color:'#ffffff'
    }
let actionArg: [ITopicValue, number] = [topicStoreState, id];
const data: IEditor = TopicDataAction.getData<IEditor>(...actionArg);
if(data&&data.color){

}
    const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],

        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],
        ['link'],
        ['clean'],                                         // remove formatting button
            ]
        const colorStyle = reactCSS({
                'default': {
                  color: {
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px',
                    background:`${color}`,
                  },
                  swatch: {
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                  },
                  popover: {
                    position: 'absolute',
                    zIndex: '2',
                  },
                  cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                  },
                },
       });
       let reactQuillRef;   
 useEffect(()=>{

        if(reactQuillRef){
          setLength(reactQuillRef.unprivilegedEditor.getLength())};
        const editorDom = document.getElementById('editor')
        if(data&&editorDom){
            editorDom.style.backgroundColor = `${data.color}`;
        } 
        if (!data) {
          TopicDataAction.setData<IEditor>(...actionArg, initEditor);
        }  
       return () => {
       }
    },[color,length]) 
    if(!data){
      return<div></div>
  }

  //数据提取
  TopicDataAction.setEF(topicStoreState,id,() => {
    return new Promise((r, e) => {
      if (data.description=='') {
        e('存在富文本内容没有填写');
      } else {
        r(data);
      }
    });
  })
        if(con =='show'){
          return(
            <div className={ styles.container }  onClick={()=>{topicStoreState.dispatch({type:'ACTIVE',payload:id})}}>
               <div id='editor' >
                  {data.description==''? <div style={{padding:'8px 5px'}}>点击此处编辑文本内容，可以自由改变文本样式等</div>:<ReactQuill style={{border: 'none'}} readOnly  modules={{toolbar: false}} value={data.description}/>} 
                </div>            
            </div>)
        }
        else{
          return(
            
              <div className={styles.title_ctrl} id='ctrl_selected'>
                  <h2>标题设置</h2>
                  <div style={{position: 'relative',display:'flex',padding:'20px 0',borderTop:'1px solid #eee'}} >
                      <label>背景颜色：</label>
                      <div style={ colorStyle.swatch } onClick={ ()=>{if(!displayColorPicker)displayColorPickerFunc.setTrue(); else displayColorPickerFunc.setFalse()} }>
                          <div style={ colorStyle.color } />
                      </div>
                      { displayColorPicker ? 
                      <div style={ colorStyle.popover }>
                          <div style={ colorStyle.cover } onClick={()=>displayColorPickerFunc.setFalse() }/>
                          <SketchPicker color={ data.color } onChange={ (color)=>{ data.color = color.hex;setColor(color.hex); TopicDataAction.setData<IEditor>(...actionArg, data);} } />
                      </div> : null }
  
                  </div>
                  <div className="quilleditor-wrapper" style={{background:'white',minHeight:500}}>        
                      <ReactQuill  modules={{ toolbar: toolbarOptions }}  style={{ height: 432 }} ref={(el) => { reactQuillRef = el;}} theme="snow" value={data.description}  
                          onChange={changeValue} />
                  </div>
                  {/* <div style={{textAlign:'right',fontSize: 12,color: 'gray'}}>已输入{length}个字符，还可以输入{1000-length}个字符</div> */}
  
              </div>
          )
        }
}


