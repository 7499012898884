// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../../../../../config";

export class ApiCon {
    static query(): Promise<any> {
        return axios({
            method: "POST",
            url: Tools.getApiAddress("pay_look"),
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }).then(d => {
            if (d.data.statusCode == 200) {
                return d.data.data
            } else {
                throw d.data.returnMessage;
            }
        });
    }
    static getWX(): Promise<any> {
        return this.query().then(d => d.weChatPayParm);
    }
    static getZFB(): Promise<any> {
        return this.query().then(d => d.aliPayParm);
    }
    static getJLJH(): Promise<any> {
        return axios({
            method: "GET",
            url: Tools.getApiAddress("jljh_pay"),
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })
        .then(d => {
            console.log(d)
            if (d.data.statusCode == 200) {
                return d.data.data
            } else {
                throw d.data.returnMessage;
            }
        });
    }
    static updateJLJH(op): Promise<any>{
        return axios({
            method: "GET",
            url: Tools.getApiAddress('jljh_pay_update'),
            params: op,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }).then(d => {
            if (d.data.statusCode == 200) {
                return d.data.data
            } else {
                throw d.data.returnMessage;
            }
        });;
    }
    static getGT(): Promise<any> {
        return axios({
            method: "GET",
            url: Tools.getApiAddress("gt_pay"),
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })
        .then(d => {
            console.log(d)
            if (d.data.statusCode == 200) {
                return d.data.data
            } else {
                throw d.data.returnMessage;
            }
        });
    }
    static updateGT(op): Promise<any>{
        return axios({
            method: "GET",
            url: Tools.getApiAddress('gt_pay_update'),
            params: op,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }).then(d => {
            if (d.data.statusCode == 200) {
                return d.data.data
            } else {
                throw d.data.returnMessage;
            }
        });;
    }
    static update(op, _name: 'AliPayAddOrUpdate' | 'WeChatPayAddOrUpdate'): Promise<any> {
        return axios({
            method: "POST",
            url: Tools.getApiAddress(_name),
            data: op,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }).then(d => {
            if (d.data.statusCode == 200) {
                return d.data.data
            } else {
                throw d.data.returnMessage;
            }
        });;
    }
    static updateWX(op): Promise<any> {
        return this.update(op, 'WeChatPayAddOrUpdate');
    }
    static updateZFB(op): Promise<any> {
        return this.update(op, 'AliPayAddOrUpdate');
    }
    static uplodaFile(file: File): Promise<string> {
        let form = new FormData();
        form.set('FileName', '支付');
        form.set('Length', '1');
        form.set('file', file);
        return axios({
            method: 'POST',
            url: Tools.getApiAddress('pay_upload'),
            data: form,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }).then((d) => {
            if (d.data.statusCode == 200) {
                return d.data.data
            } else {
                throw d.data.returnMessage;
            }
        });
    }
}