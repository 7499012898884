// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../config";
import { effeative } from "./common_logic";

interface ISelListByRecord {   
    "activityName"?: string;
    "pageIndex"?: number;
    "pageSize"?: number;
    "orderBy"?: string;
    "sort"?: string;
}
interface ISelList {
    "activityName"?: string;
    'sendTargets':string;
    'sendType'?:number;
    "sendMemberType"?: number;
    "couponQuantity"?: number;
    "couponID"?: string;
    "pageIndex"?: number;
    "pageSize"?: number;
    "orderBy"?: string;
    "sort"?: string;
}


export class SendCouponLogic{
    async addOperation(params) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("precision_send_add"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    /**
     * 分页查询库存记录信息
     */
    async selListByRecord(params: ISelListByRecord) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("precision_send_list"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    async selData(id: string){
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("precision_send_getone"),
            params: {
                id: id
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
  

}
