import useRequest from "@ahooksjs/use-request";
import { useEffect, useState, useContext } from "react";
import { useBoolean } from "ahooks";
import { Modal } from "../../../../component/modal";
import { Input, Form, message, Checkbox, Button } from "antd";
import { useForm } from "antd/lib/form/Form";
import { AuthLogic } from "../../../../logic/auth_logic";
import styles from "../../style/switch.module.scss";
import { fileAction } from "../../action/clear/file_action";
import { FileStore } from "../../store/clear/file_store";
// import { Button } from "../../../../component/button";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
export function ClearData(viewPower) {
  const [form] = useForm();
  const action = fileAction;
  const [menuButton, setData] = useState([]);
  const { state, dispatch } = useContext(FileStore);
  const [visibleModal, modalFunc] = useBoolean(false);
  useEffect(() => {
    (async function () {
      let r = await new AuthLogic().selButton(viewPower.viewPower);
      if (r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          r[i] = r[i].split("_")[r[i].split("_").length - 1];
        }
      }
      setData(r);
    })();

    return () => {};
  }, []);
  const handleOk = async () => {
    await form.validateFields();
    const password = form.getFieldValue("password");
    action.clear({ list: state.modleList, password });
    modalFunc.setFalse();
    dispatch({ type: "Clear" });
  };

  useRequest(() => {
    action.init({ dispatch });
  });
  return state.dataList && state.dataList.length ? (
    <div className={styles.root}>
      {menuButton.indexOf("VIEW") != -1 &&
        state.dataList &&
        state.dataList.length &&
        state.dataList.map((item, index) => {
          return (
            <div className={styles.container} key={index}>
              <div className={styles.title}>{item.groupName}</div>
              <div className={styles.checkbox_group}>
                {item.list &&
                  item.list.length &&
                  item.list.map((l) => {
                    return (
                      <div className={styles.checkbox_item}>
                        <Checkbox
                          key={l.key}
                          checked={state.modleList.indexOf(l.id) != -1}
                          value={l.id}
                          style={{ minWidth: 100, margin: "20px 0 10px 0" }}
                          onChange={(e) => {
                            action.setIds({
                              dispatch,
                              selected: e.target.checked,
                              value: e.target.value,
                            });
                          }}
                        >
                          {l.moduleName}
                        </Checkbox>
                        {state.modleList.indexOf(l.id) != -1 && (
                          <p>{l.clearTips}</p>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        })}
      {menuButton.indexOf("DELETE") != -1 && (
        <Button
          type="primary"
          style={{ marginTop: 10 }}
          disabled={menuButton.indexOf("DELETE") == -1}
          onClick={() => {
            if (state.modleList == null || state.modleList.length == 0) {
              message.error("请先选择清除项");
              return;
            } else {
              modalFunc.setTrue();
            }
          }}
        >
          清除
        </Button>
      )}
      <Modal
        title="请输入密码"
        style={{ top: 120 }}
        visible={visibleModal}
        onOk={handleOk}
        onCancel={() => {
          form.resetFields();
          modalFunc.setFalse();
        }}
        width={400}
        cancelText="取消"
        okText="确定"
      >
        <div className={styles.root}>
          <Form form={form}>
            <Form.Item
              name="password"
              rules={[{ required: true, message: "请输入密码!" }]}
            >
              <Input.Password placeholder="请输入密码" />
            </Form.Item>
          </Form>
        </div>
      </Modal>
      {/* <Button type='primary' color='Red' style={{margin:' 20px 15px'}}>保存</Button> */}
    </div>
  ) : (
    <div style={{ textAlign: "center", marginTop: 100 }}>暂无可操作选项</div>
  );
}
