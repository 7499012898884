import { message } from "antd";
// import axios from "axios";
import Cookies from "js-cookie";
import { DataTableAction } from "../../table/action/data_table_action";
import { Tools, axios } from "../../../config";
import { SeckillLogic } from "../../../logic/seckill_logic";

class ChooseAction extends DataTableAction {
    async delRow(id) {
       
    }
    delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {
        
    }
    async loadPage({ dispatch, pageIndex, pageSize, search }) {
        this.setLoading({dispatch,loading: true})
        const { shopID, goodsId, seacherKey } = search;
        const params: any = {
          
            "pageIndex": pageIndex,
            "pageSize": pageSize,
            "keyWords": seacherKey,
            "orderBy":'',
            "sort":'',
        }
        // 档案列表
        const r = await  new SeckillLogic().selListByRecord(params);

        
        if (r.data.statusCode == 200) {
            const dataList = r.data.data.dataSource;

            const list = dataList.map((v, i) => {
                v.key = i + 1;
                v.beginTime = v.beginTime==null?'':v.beginTime.slice(0,10);
                v.endTime = v.endTime==null?'':v.endTime.slice(0,10);
                return v;
            })

            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: list, pageSize });
        }  this.setLoading({dispatch,loading: false})

    }
 
    setCondition({ dispatch, key, value }) {
        const params = {
        }
        params[key] = value;

        dispatch({ type: "SetSearch", payload: params })
    }
    async getGoodsList({id,dispatch}){
    const r = await new  SeckillLogic().selDataByWarehousing(id)
            if(r && r.goodsList && r.goodsList instanceof Array){
                let arr = []
                for(let i =0;i<r.goodsList.length;i++){
                    r.goodsList[i].goodsSep.forEach(sep => {
                        arr.push({
                            ...r.goodsList[i],
                            ...sep,
                            sepID: sep.id,
                            goodsName:r.goodsList[i].goodsName,
                            goodsNo: r.goodsList[i].goodsNo
                        }) 
                    });
                  
                }
                dispatch({type:'GoodsList', payload: arr})
            }
    }

}
export const chooseSeckillAction = new ChooseAction();