import { FormAttribute } from "../../store/flowChargeoff/form_store";
import { FlowChargeOffdLogic } from "../../../../logic/flowChargeoff_logic";
import { message } from "antd";
interface ISetValue{
    attribute:FormAttribute
    value?
    dispatch
}
export class FormAction {
   
  async  addSubmit({state,form,dispatch}){
        let param = {
            vipId: state.formData.vipId,
            money: state.formData.money,
            remark: state.formData.remark
        }
        dispatch({type:'Loading',payload: true})
        const res = await new FlowChargeOffdLogic().addChargeoff(param)
        console.log(res)
        if(res.data.statusCode == 200){
            dispatch({type:'Loading',payload: false})
            // if(res.data.data ){
                message.success('扣款成功')
                return true
            // }
        }else{
            message.error(res.data.returnMessage || '扣除失败')
            dispatch({type:'Loading',payload: false})
            return false
        }
        
    }
    reset({form,dispatch}){
        let item = {
            memberName:'',
            vipId:'',
            money:null,
            remark:''
        }
        dispatch({type:'SetForm',payload:item})
        form.setFieldsValue({
            'member-name':'',
            'member-money':null,
            'member-remark':''
        })
    }
    setValue({ attribute, value, dispatch }: ISetValue) {
        // //console.log(attribute, value, dispatch);
        dispatch({ type: "Change_FormData", payload: { attribute, value } });
    }
}
export const formAction = new FormAction()