import { createContext, useContext, useReducer } from "react";
interface IContextProps {
  state: IState;
  dispatch: ({ type }: Dispatch) => void;
}
type Dispatch = { type; payload };

class FormData {
  //     "id": "string",
  //   "shopID": "string",
  //   "integralType": 0,
  //   "status": 0,
  //   "money": 0,
  //   "giveIntegral": 0,
  //   "addDateTime": "2022-07-21T01:32:41.941Z",
  //   "addUserID": "string",
  //   "updateTime": "2022-07-21T01:32:41.941Z"
  id: string;
  shopID: string[];
  shopName: string;
  integralType: number;
  status: number = 0;
  money: number;
  giveIntegral: number;
}
interface IState {
  formData: FormData;
  isLoading: boolean;
}
const initialArgs: IState = {
  formData: new FormData(),
  isLoading: false,
};
export const IntegralRuleStore = createContext<IContextProps>(null);
function reducer(state: IState, { type, payload }: Dispatch) {
  switch (type) {
    case "Loading":
      return {
        ...state,
        isLoading: payload,
      };
    case "Change_FormData":
      //   const { attr, value } = payload;
      //   let props = {};
      //   props[attr] = value;
      return {
        ...state,
        formData: {
          ...state.formData,
          ...payload,
        },
      };
    case "SetForm": {
      const { giveIntegral, money, status, integralType, id } = payload;
      const item = new FormData();
      item.integralType = integralType;
      item.id = id;
      item.giveIntegral = giveIntegral;
      item.money = money;
      item.status = status;
      return {
        ...state,
        formData: {
          ...item,
        },
      };
    }
    default:
      return state;
  }
}
export function IntegralRuleStoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialArgs);

  const v = { state, dispatch };
  return (
    <IntegralRuleStore.Provider value={v}>
      {props.children}
    </IntegralRuleStore.Provider>
  );
}
