import { message } from "antd";
import { DataTableAction } from "../../../../component/table/action/data_table_action";
import { defualtPageSize } from "../../../../logic/common_logic";
import { GoodsLogic,ISelList } from "../../../../logic/goods_logic";
import { InventoryLogic } from "../../../../logic/inventory_logic";
import { ShopLogic } from "../../../../logic/shop_logic";
import { UnitLogic } from "../../../../logic/unit_logic";
import { UserLogic } from "../../../../logic/user_logic";
import  Cookies  from 'js-cookie'
type Init = {
    state;
    dispatch;
    form;
    id: string;
    method: string;
}
interface InitBasic {
    dispatch;
    search;
}
interface InitEdit {
    state;
    dispatch;
    form;
    id;
}
interface ISetValue {
    attribute: any;
    value: string | number | any[];
    dispatch;
}

class WarehousingFormAction {

    private shopLgc = new ShopLogic();

    async init({ state, dispatch, id, method, form }: Init) {
        await this.initBasic({ dispatch, search: state.search });

        if ("Create" !== method && id) {
            await this.initEdit({ dispatch, state, id, form })
        }
    }
    private async initBasic({ search, dispatch }: InitBasic) {
        this.clearTableList({ dispatch });

    //     const list = await (await new ShopLogic().selListpost()).data.data.dataSource || []
    //     dispatch({type: 'LoadShop',payload: list})
    //    let defaultShop = list.filter(item=> item.isdefault == true)
    //    if(defaultShop.length>0){
    //        let shopinfo = defaultShop[0]
    //     this.setFormData({ dispatch, key: "shopId", value: shopID });
    //     this.setFormData({
    //       dispatch,
    //       key: "shopName",
    //       value: shopName,
    //     });
    //    }       
       let { shopID, shopName } = JSON.parse(Cookies.get('loginMsg'))
       this.setFormData({ dispatch, key: "shopId", value: shopID });
        this.setFormData({
          dispatch,
          key: "shopName",
          value: shopName,
        });

        // 用户信息
        {
            const r = await new UserLogic().selData();

            this.setFormData({ dispatch, key: "userName", value: r.userName })
        }
        // 商品搜索
        {
            const { keywords, classesId, statusId, typeId, brandId } = search;

            const params = {
                pageIndex: 1, pageSize: defualtPageSize(), goodsBrandID: brandId, goodsClassesID: classesId, goodsType: typeId, status: statusId, goodsNameOrNo: keywords
            }
            if (undefined == keywords) {
                delete params.goodsNameOrNo;
            }
            if (undefined == brandId) {
                delete params.goodsBrandID;
            }
            if (undefined == classesId) {
                delete params.goodsClassesID;
            }
            if (undefined == typeId) {
                delete params.goodsType;
            }
            if (undefined == statusId) {
                delete params.status;
            }
            const r = await new GoodsLogic().selList({...params});

            if (r.data.statusCode == 200) {
                const dataList = r.data.data.dataSource;            
               
                dispatch({ type: "SetSearchList", payload: dataList });

            }
        }


    }
    private async initEdit({ dispatch, id, form }: InitEdit) {
        // 回填状态
        const item = await new InventoryLogic().selDataByWarehousing(id);

        //先把提取出规格对于的入库数据
        let goodsList: any[] = [];
        let goods;
        // item.goodsList.forEach((item) => {
        //     goods = goodsList.find((_item) => {
        //         return item.goodsID == _item.goodsID;
        //     });
        //     if (!goods) {
        //         goods = item;
        //         goodsList.push(goods);
        //     }
        //     (goods.goodsSpecifications___ || (goods.goodsSpecifications___ = [])).push({
        //         id: item.specificationID,
        //         putPrice: item.putPrice,
        //         putNumber: item.putNumber,
        //     });
        // });
        
        // item.goodsList = goodsList;
        // let _data;
        // //添加进商品规格
        // for (let _item of item.goodsList) {
        //     //请求商品详情数据
        //     _item.goodsSpecifications = (await new GoodsLogic().selData(_item.goodsID)).goodsSpecifications;
        //     //根据规格id合并入库数据
        //     _item.goodsSpecifications.forEach((__item) => {
        //         _data = _item.goodsSpecifications___.find((___item) => {
        //             return __item.id == ___item.id;
        //         });
        //         __item.inStockUnitPrice = _data.putPrice;
        //         __item.inStockNumber = _data.putNumber;
        //     });
        // }
        let list = []
        for(let good of item.goodsList) {
        //    let seplist =  (await new GoodsLogic().selData(good.goodsID)).goodsSpecifications
        //    let sep;
        //    if(seplist){
        //         sep = seplist.filter((s)=>s.id == good.specificationID)
        //         if(sep.length>0){
        //             sep[0]['inStockUnitPrice'] = good.putPrice
        //             sep[0]['inStockNumber'] = good.putNumber
        //         }
              
        //     }
            list.push({
                ...good,              
            })

        }
        item.goodsList = list;
        // console.log(list)
        dispatch({ type: "SetForm", payload: item });
        //console.log(item)
        // 填充节点
        form.setFieldsValue({
            "in-shopName": item.shopName,
            "in-reasonId": item.reason
        });

    }
    private paddingData(state, status: 1 | 2 | 3) {
        let params: any = {};
       {
            const { formData, tableList } = state;

            {
                const { shopId, remark, reasonId } = formData;
                params.shopID = shopId;
                params.source = 2;
                // 1. 出库 2入库 3报损 4报溢
                params.inventoryType = 2;
                // 1已审核 2未审核 3作废
                params.inventoryStatus = status;
                params.reason = reasonId;
                params.remark = remark;

                params.details = [

                ];

                tableList.forEach((v, i) => {
                    // console.log(v)
                        params.details.push({
                            "goodsID": v.goodsId,
                            // "specificationID": s.id,
                            "goodsName": v.goodsName,
                            'goodsUnitID': v.inStockUnitId,
                            "unitName": v.unitName,
                            "inventoryType": 2,
                            "putPrice": v.inStockUnitPrice || 0,
                            "putNumber": v.inStockNumber || 0,
                            "remark": "",
                            "manufactureDate":v.manufactureDate,
                            "shelfLifeDay":v.shelfLifeDay
                        })
                })

            }
        }
        return params;
    }
    async addSubmit({ state, status }) {
        if (!state.formData.shopId || state.formData.shopId == undefined) {
            message.error("请选择门店");
            return false;
        }
        if (!state.formData.reasonId || state.formData.reasonId == undefined) {
            message.error("请选择理由");
            return false;
        }
        if (!state.tableList || !state.tableList.length) {
            message.error("请选择入库商品");
            return false;
        }
        let num = 0
        for (let i = 0; i < state.tableList.length; i++) {
            //console.log(state.tableList[i].goodsSpecifications)
                if (!state.tableList[i].inStockNumber || state.tableList[i].inStockNumber < 0 || !state.tableList[i].inStockUnitPrice || state.tableList[i].inStockUnitPrice < 0) {
                    num = num + 1
                }
                if(state.tableList[i].manufactureDate){
                    if(state.tableList[i].shelfLifeDay == null || state.tableList[i].shelfLifeDay == undefined || state.tableList[i].shelfLifeDay == ''){
                        message.error('请输入商品保质期')
                        return false
                    }else 
                    if(!/^\+?[1-9][0-9]*$/.test(state.tableList[i].shelfLifeDay)) {
                        message.error('商品保质期应该是大于0的整数')
                        return false
                    }
                }
                if(state.tableList[i].shelfLifeDay){
                    if(state.tableList[i].manufactureDate == null || state.tableList[i].manufactureDate == undefined || state.tableList[i].manufactureDate == ''){
                        message.error('请选择生产日期')
                        return false
                    }
                        if(!/^\+?[1-9][0-9]*$/.test(state.tableList[i].shelfLifeDay)){
                            message.error('商品保质期应该是大于0的整数')
                            return false
                        }
                }
        }
        if (num>0) {
            message.error("商品入库信息未填写完整");
            return false;
        }
        const r = await new InventoryLogic().addOperation(this.paddingData(state, status));
        if (r.data.statusCode == 200) {
            message.success("保存成功");
        } else {
            message.error(r.data.message);
        }
        return true;
    }
    async updSubmit({ state, status }, id) {
        if (!state.formData.shopId || state.formData.shopId == undefined) {
            message.error("请选择门店");
            return false;
        }
        if (!state.formData.reasonId || state.formData.reasonId == undefined) {
            message.error("请选择理由");
            return false;
        }
        if (!state.tableList || !state.tableList.length) {
            message.error("请选择入库商品");
            return false;
        }
        let num = 0
        for (let i = 0; i < state.tableList.length; i++) {
            //console.log(state.tableList[i].goodsSpecifications)
                if (!state.tableList[i].inStockNumber || state.tableList[i].inStockNumber < 0 || !state.tableList[i].inStockUnitPrice || state.tableList[i].inStockUnitPrice < 0) {
                    num = num + 1
                }
                if(state.tableList[i].manufactureDate){
                    if(state.tableList[i].shelfLifeDay == null || state.tableList[i].shelfLifeDay == undefined || state.tableList[i].shelfLifeDay == ''){
                        message.error('请输入商品保质期')
                        return false
                    }else 
                    if(!/^\+?[1-9][0-9]*$/.test(state.tableList[i].shelfLifeDay)) {
                        message.error('商品保质期应该是大于0的整数')
                        return false
                    }
                }
                if(state.tableList[i].shelfLifeDay){
                    if(state.tableList[i].manufactureDate == null || state.tableList[i].manufactureDate == undefined || state.tableList[i].manufactureDate == ''){
                        message.error('请选择生产日期')
                        return false
                    }
                        if(!/^\+?[1-9][0-9]*$/.test(state.tableList[i].shelfLifeDay)){
                            message.error('商品保质期应该是大于0的整数')
                            return false
                        }
                }
        }
        if (num>0) {
            message.error("商品入库信息未填写完整");
            return false;
        }
        const params = this.paddingData(state, status);
        params.id = id;

        const r = await new InventoryLogic().updOperation(params);
        if (r.data.statusCode == 200) {
            message.success("更新成功");
        } else {
            message.error(r.data.message);
        }
        return true;
    }
    async addTableItemBySimple({ dispatch, state }) {
        const { searchList, formData } = state;
        if (formData.keyword) {
            let item;
            searchList.forEach(v => {
                if (v.id == formData.keyword) {
                    item = v;
                }
            })
            if (item) {
                //获取商品规格信息
                // item.goodsSpecifications = (await new GoodsLogic().selData(item.id)).goodsSpecifications;
                // item.inStockUnitPrice = state.formData.inStockUnitPrice;
                // item.inStockNumber = state.formData.inStockNumber;

                if (false == Boolean(item.inStockUnitPrice)) {
                    item.inStockUnitPrice = 0;
                }
                if (false == Boolean(item.inStockNumber)) {
                    item.inStockNumber = 0;
                }
                item['manufactureDate'] = ''
                item['shelfLifeDay'] = ''
                item.inStockPrice = item.inStockUnitPrice * item.inStockNumber;
                dispatch({ type: "AddTableItem", payload: item });

            }
        }
    }
    addTableItem({ dispatch, list }) {
        list.forEach(async v => {

            //获取商品规格信息
            v.goodsSpecifications = v.goods_SpecificationList
            v.inStockUnitPrice =  0;
            v.inStockNumber = 0;
            v.inStockPrice = 0;
            v['manufactureDate'] = ''
            v['shelfLifeDay'] = ''
            dispatch({ type: "AddTableItem", payload: v });
        })
    }
    delTableItem(dispatch, index) {
        dispatch({ type: "DelTableItem", payload: index });
    }
    setFormData({ dispatch, key, value }) {
        const params = {
        }
        params[key] = value;

        dispatch({ type: "SetFormData", payload: params })
    }
    setKeywords({ dispatch, value, state }) {
        this.setFormData({ dispatch, key: "keyword", value: value });
        state.searchList.forEach(e => {
            if (e.id == value) {
                this.setFormData({ dispatch, key: "keyword", value: value });

                dispatch({ type: "SetFormData", payload: { inStockUnitPrice: e.putNewPrice } })
            }

        })
    }
    changeTableItem({ dispatch, key, value, index }) {
        dispatch({ type: "ChangeTableItem", payload: { key, value, index } });
    }
    clearTableList({ dispatch }) {
        dispatch({ type: "ClearTableList" });
    }
    changeTableList({dispatch}){
        dispatch({ type: "ChangeTableList" });
    }
    async getGoods({id,dispatch,state}){
        const that = this
        let param:ISelList = {
            goodsClassesID: [],
            goodsNameOrNo: id,
            pageIndex: 1,
            pageSize: 10,
            goodsBrandID:''
        }
            const r = await new GoodsLogic().selList(param)
            if( r.data.statusCode == 200 && r.data.data &&r.data.data.dataSource && r.data.data.dataSource.length>0){
                const item = r.data.data.dataSource[0]
             
                if (false == Boolean(item.inStockUnitPrice)) {
                    item.inStockUnitPrice = 0;
                }
                if (false == Boolean(item.inStockNumber)) {
                    item.inStockNumber = 0;
                }
                item['manufactureDate'] = ''
                item['shelfLifeDay'] = ''
                item.inStockPrice = item.inStockUnitPrice * item.inStockNumber;
                dispatch({ type: "AddTableItem", payload: item });
            }
    }
}
export const warehousingformAction = new WarehousingFormAction();