import { createContext, useReducer } from "react";

type Dispatch = { type, payload }
export const AfterSalesFormStore = createContext<IContextProps>(null)
interface IContextProps {
    state: IState;
    dispatch: ({ type }: Dispatch) => void;
}

export class TableItem{
    invoiceGoodsNum: Number
    refundGoodsNumber: Number
    goodsID: string
    goodsPrice: Number | string
    refundMoney: Number 
    specID: string
    specUnit: string
    goodsName: string
    invoiceID: string
    invoiceDetailID: string
}
class FormData{
    invoiceID: string
    afterSalesSource: 1
    afterSalesType: undefined
    afterSalesReason: string
    remark: string
}
interface IState{
    tableList: TableItem[]
    formData:  FormData
}

const initialArgs: IState = {
    tableList: [],
    formData: new FormData()
}
function reducer(state: IState, { type, payload }: Dispatch) {
    switch (type) {
        default:
            return state;
    }
}
export function AfterSalesFormStoreProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialArgs);

    const v = { state, dispatch };
    return <AfterSalesFormStore.Provider value={v}>{props.children}</AfterSalesFormStore.Provider>
}