import { Modal, TableProps } from "antd";
import React, { Children, cloneElement } from "react";
import styles from "../style/table.module.scss";
import { Table as AntdTable } from "antd"

interface ITableStyle {
    children?: any;
}

export function Table(params: ITableStyle & TableProps<any>) {
    const { children } = params;

    const rootStyle = (params.className) ? params.className : "";

    return <AntdTable {...params} {...{ className: rootStyle + " " + styles.root }} >{children}</AntdTable>
}