import Cookies from "js-cookie";
import { effeative } from "../common_logic";
// import axios from "axios";
import { Tools, axios } from "../../config";

/**
 * 促销逻辑
 */
export class Market_logic {
    /**
     * 获取api
     * @param _name 名字
     * @param arg 其他参数
     * @returns 
     */
    static getApi(_name: string, ...arg: any): string {
        return Tools.getApiAddress(_name, ...arg);
    }

    /**
     * 发送axios请求
     * @param _url 请求url
     * @param _method 请求方法
     * @param _params 请求参数
     * @returns 
     */
    static async axios(_url: string, _method: 'GET' | 'POST' = 'GET', _params?: any, _getParam?: any) {
        if(_getParam){

        }
        return effeative(await axios({
            method: _method,
            url: _url,
            data: _params,
            params: _getParam,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }));
    }
}