import { useBoolean } from "ahooks";
import { Button, Input, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect, useState } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { Search } from "../../../../component/search";
import { DataTable } from "../../../../component/table";
import { DataTableStore } from "../../../../component/table/store/data_table_store";
import { Form } from "./from";
import { ConfirmModal } from "../../../../component/modal";
import { Tips, TipsGroup } from "../../../../component/tips";
import { FileSearch } from "./file_search";
import { Mask } from "../../../../component/mask";
import { defualtPageSize, Progress } from "../../../../logic/common_logic";
import { AuthLogic } from "../../../../logic/auth_logic";
import { Control, Del, Edit, See } from "../../../../component/control-column";
import img_stop from "../../../../assets/images/zz.png";
import { FileStore } from "../../store/booking/file_store";
import { fileAction } from "../../action/booking/file_action";
import { FormStoreProvider } from "../../store/booking/form_store";

const formParams = {
  method: "Create",
  id: "",
  subId: "",
  item: null,
};

export function Booking(viewPower) {
  //是否打开创建页面
  const [createState, createFunc] = useBoolean(false);
  //是否打开高级搜索
  const [searchState, searchFunc] = useBoolean(false);
  //是否删除
  const [confirmState, confirmFunc] = useBoolean(false);
  //全局state
  const { state, dispatch } = useContext(FileStore);
  //表单state
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);
  /** 表单Action */
  const formAction = fileAction;
  //权限
  const [menuButton, setData] = useState([]);
  useEffect(() => {
    (async function () {
      let r = await new AuthLogic().selButton(viewPower.viewPower);
      if (r && r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          r[i] = r[i].split("_")[r[i].split("_").length - 1];
        }
      }
      setData(r);
    })();

    return () => {};
  }, []);
  const columns: ColumnsType = [
    {
      title: "操作",
      key: "1",
      dataIndex: "oddNumbers",
      align: "center",
      width: Control.width(4),
      render: Control.render(
        (v, r) =>
          menuButton.indexOf("VIEW") != -1 && (
            <See
              click={() => {
                formParams.id = r.id;
                formParams.method = "Preview";
                //
                createFunc.setTrue();
              }}
            />
          ),
        (v, r) =>
          menuButton.indexOf("UPDATE") != -1 &&
          r.dataState == 0 && (
            <Edit
              click={() => {
                formParams.id = r.id;
                formParams.subId = r.id;
                formParams.method = "Edit";
                formParams.item = r;
                //
                createFunc.setTrue();
              }}
            />
          ),
        (v, r) =>
          menuButton.indexOf("STOP") != -1 &&
          r.dataState != 2 && (
            <img
              src={img_stop}
              alt=""
              onClick={() => {
                formParams.id = r.id;
                formParams.subId = r.id;
                //
                confirmFunc.setTrue();
              }}
            />
          )
      ),
    },
    {
      title: "活动名称",
      dataIndex: "activityName",
      align: "center",
      width: 140,
    },
    {
      title: "添加人",
      dataIndex: "addName",
      align: "center",
    },
    {
      title: "参与店铺",
      dataIndex: "shopName",
      align: "center",
    },
    // {
    //     title: '是否限制会员等级',
    //     dataIndex: 'isMemberClass',
    //     align: "center",
    // },
    {
      title: "活动状态",
      align: "center",
      render: (v) => {
        return (
          <>
            <Tag
              color={
                v.dataState == 0
                  ? "geekblue"
                  : v.dataState == 1
                  ? "success"
                  : "volcano"
              }
            >
              {v.dataStateString}
            </Tag>
          </>
        );
      },
    },
    {
      title: "开始日期",
      dataIndex: "beginDate",
      align: "center",
      // render: (v) => <span>{v ? v.substring(0, 10) : ""}</span>,
    },
    {
      title: "结束日期",
      dataIndex: "endDate",
      align: "center",
      // render: (v) => <span>{v ? v.substring(0, 10) : ""}</span>,
    },
    {
      title: "到货日期",
      dataIndex: "arrivalDate",
      align: "center",
      render: (v) => <span>{v ? v.substring(0, 10) : ""}</span>,
    },
    {
      title: "添加时间",
      dataIndex: "addTime",
      align: "center",
    },
  ];

  const onSearch = async (pageIndex) => {
    searchFunc.setFalse();
    await formAction.loadPage({
      pageIndex: pageIndex,
      pageSize: defualtPageSize(),
      dispatch: tableDispatch,
      search: state.search,
    });
  };

  return (
    <>
      <Search>
        <Input
          maxLength={20}
          allowClear
          placeholder="请输入活动名称"
          onChange={(e) =>
            formAction.setCondition({
              dispatch,
              key: "seacherKey",
              value: e.target.value,
            })
          }
        />
        <input type="button" value="快速查找" onClick={() => onSearch(1)} />

        <TipsGroup>
          <input
            type="button"
            value="高级查找"
            onClick={() => searchFunc.toggle()}
          />
          <Tips display={searchState} width="48rem">
            <FileSearch onSearch={() => onSearch(1)} />
          </Tips>
          <Mask visible={searchState} onClick={() => searchFunc.setFalse()} />
        </TipsGroup>
      </Search>

      <ButtonPanel>
        {menuButton.indexOf("ADD") != -1 && (
          <Button
            onClick={() => {
              formParams.id = "";
              formParams.method = "Create";

              createFunc.setTrue();
            }}
          >
            新增商城预售
          </Button>
        )}
      </ButtonPanel>

      <DataTable
        columns={columns}
        action={formAction}
        onLoad={async () => {
          await onSearch(1);
          Progress.done();
        }}
        onChange={(pageIndex) => {
          onSearch(pageIndex);
        }}
        scroll={{ scrollToFirstRowOnChange: true, autoY: true, x: 1060 }}
        loading={tableState.loading}
      />

      {createState && (
        <FormStoreProvider>
          <Form
            audit={true}
            close={(hasUpdate) => {
              if (hasUpdate) {
                onSearch(tableState.current);
              }

              createFunc.setFalse();
            }}
            action={formAction}
            id={formParams.id}
            subId={formParams.subId}
            item={formParams.item}
            method={formParams.method}
          />
        </FormStoreProvider>
      )}

      <ConfirmModal
        visible={confirmState}
        title="温馨提示"
        subTitle="确认终止？"
        message="终止之后活动无法恢复，请慎重考虑"
        close={async (r) => {
          if (r) {
            await formAction.stop(formParams.subId);

            onSearch(tableState.current);
          }
          confirmFunc.setFalse();
        }}
      />
    </>
  );
}
