import { Button } from '../../../../component/button'
import { Layer } from '../../../../component/layer/layer'
import { Form as FormAntd,} from 'antd'
import { Form_Basic } from './form_basic'
import { formAction } from '../../action/afterSale/form_action';
import { AfterSalesFormStore } from '../../store/afterSale/form_store';
import { useContext } from 'react';
interface IForm {
    children?: any;
    action: any;
    close: (hasUpdate?: boolean) => void;
    id?: string;
    method?: string;
}
export function Form({close, id, method}:IForm){
    const [form] = FormAntd.useForm();
    const action = formAction
    const {state, dispatch} = useContext(AfterSalesFormStore)
    const title = () => {
        if ("Create" === method) {
            return "新增售后单";
        }
        else if ("Edit" === method) {
            return "编辑售后单";
        }
        else {
            return "预览售后单";
        }
    }
    return(
        <FormAntd form={form}>
        <Layer layerWith={4}>
            <Layer.Title onClick={()=>close()}>{title()}</Layer.Title>
            <Layer.Body>
                <Form_Basic disabled={!("Create" == method || "Edit" == method)} updateFormField={(field, value = '') => {
                        form.setFieldsValue({
                            [field]: value,
                        });
                    }} />
            </Layer.Body>
            <Layer.Foot borderStyle={title().indexOf('预览')!==-1 ? { border: 'none' } : {}}>
                <Button type='primary' color='Red' onClick={async ()=>{
                    let  r = false
                    if(method == 'Create'){
                        r = await action.addSubmit({dispatch, state})
                    }
                    if(method == 'Edit'){
                        r = await action.updSubmit({dispatch, state, id})
                    }
                    if(r){
                        close()
                    }

                }}>保存</Button>
                <Button  type="primary" color="Yellow" onClick={()=>{
                    close()
                }}>返回</Button>
            </Layer.Foot>
        </Layer>
       </FormAntd>
    )
}