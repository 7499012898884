import { Button,  Col,  Form,  Input,  Row,  Select,  Form as FormAntd,  DatePicker,  message, Tag, InputNumber, Tooltip} from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useState } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { FormSubtitle } from "../../../commodity/view/form_subtitle";
import styles from "../../style/inventory_goods_formbasic.module.scss";
import moment from "moment";
import img_del from "../../../../assets/images/shanchu.png";
import { PurchaseFormStore, PurchaseFormStoreProvider } from "../../store/purchase/purchase_form_store";
import { purchaseFormAction } from "../../action/purchase/purchase_form_action";
import { useBoolean } from "ahooks";
import { ChooseGoodsPurchase } from "../../../../component/choose-goods-purchase";
import { Table } from "../../../../component/table";
import { ChooseShop } from "../../../../component/choose-shop";
import { autoRem } from "../../../../global.style";
import { count } from "console";
import { TableExpand } from "../../../../component/table-expand";
import { Tools } from "../../../../config";
import { NumberTool } from "../../../../tool/numberTool";
import {QuestionCircleFilled} from '@ant-design/icons'

import { AuditStatus, SendStatus } from "../../enum";
const { TextArea } = Input;
const { Option } = Select;

export function DataTable({ dataList, disabled }) {
  const { dispatch, state } = useContext(PurchaseFormStore);
  const [current, setCurrent] = useState(1);
  const [pageSize, setSize] = useState(10);
  const action = purchaseFormAction;
  const disabledDate = (current) => {
    return current > moment().startOf("second");
  };
  const byStringGetMoment = (string) => {
    if (!string) {
      return undefined;
    }
    return moment(string);
  };
  const columns: ColumnsType = [
   
    {
      title: "操作",
      // dataIndex: "serial",
      key: "1",
      align: "center",
      width: 100,
      render: (v, r, i) => (
        <div>
          <img
            src={img_del}
            style={{ width: 20 }}
            onClick={() => {
              if (disabled) return;              
              action.delTableItem(dispatch, v);
            }}
          />
        </div>
      ),
    },
    {
      title: "商品名称",
      dataIndex: "goodsName",
      key: "2",
      align: "center",
    },
    {
      title: "规格单位",
      dataIndex: "specUnit",
      key: "3",
      align: "center",
      width: 100,
    },   
    {
      title: "采购数量",
      // dataIndex: "goodsNum",
      key: "4",
      align: "center",
      width: 140,
      render: (v, r: any, i) => {
        return (
          <div style={{display: 'flex', alignItems: 'center',justifyContent:'flex-start'}}>
            <InputNumber key='goodsNum'
          precision={6}
          style={{ width: 100,marginRight: 10 }}
          min={0}
          value={v.goodsNum}
          disabled={disabled}
          onChange={(value) =>{
            // 计算商品总价
            action.changeTableItem({
              dispatch,
              key: "goodsNum",
              value: value,
              index: i + (current - 1) * pageSize,
            })
          }           
          }
          /> 
          {v.procurementMagnification ? <Tooltip title={`限制为${v.procurementMagnification}的倍数`}>
              <QuestionCircleFilled style={{fontSize:14}}/>
          </Tooltip>:null}
          </div>        
        );
      },
    }, 
    {
      title: "单价",
      dataIndex: "purchasePrice",
      key: "5",
      align: "center",
      width: 100,
    },   
    {
      title: "商品总金额",
      dataIndex: "totalAmount",
      key: "7",
      align: "center",
      width: 100,
    },   
    // {
    //   title: "优惠单价",
    //   dataIndex: "disscountPrice",
    //   key: "6",
    //   align: "center",
    // },   
   
    // {
    //   title: "优惠总金额",
    //   dataIndex: "disscountTotal",
    //   key: "8",
    //   align: "center",
    // },   
  ];
  const { formData } = state;
  return (
    <Table
      scroll={{ y: 275 }}
      dataSource={dataList}
      columns={columns}
      onChange={(pagination) => {        
        setCurrent(pagination.current);
        setSize(pagination.pageSize);
      }}
    />
  );
}

export function PurchaseFormBasic({ disabled, updateFormField, method }) {
  const [shopState, shopFunc] = useBoolean(false);
  const [goodsState, goodsFunc] = useBoolean(false);
  const disabledDate = (current) => {
    return current > moment().startOf("second");
  };
  const byStringGetMoment = (string) => {
    if (!string) {
      return undefined;
    }
    return moment(string);
  };
  const { state, dispatch } = useContext(PurchaseFormStore);
  const { formData } = state;

  const action = purchaseFormAction;

  /** 获取总结数量 规格 */
  // const allNumber = () => {
  //     return state.tableList.reduce((a, b) => {
  //         let p = b.goodsSpecifications.reduce((a, b) => {
  //             return NumberTool.add(a, b.inStockNumber || 0, 2);
  //         }, 0);
  //         return NumberTool.add(a, p, 2);
  //     }, 0);
  // };
  /** 获取总结数量 无规格 */
  // const allNumber = () => {
  //   return state.tableList.reduce((a, b) => {
  //     return NumberTool.add(a, b.inStockNumber || 0, 3);
  //   }, 0);
  // };
  /** 获取总结金额 */
  // const allAmount = () => {
  //   return state.tableList.reduce((a, b) => {
  //     let p = NumberTool.multiply(
  //       b.inStockNumber || 0,
  //       b.inStockUnitPrice || 0,
  //       2
  //     );

  //     return NumberTool.add(a, p, 2);
  //   }, 0);
  // };
  let height = document.body.clientHeight - 188 + 26;
// 审核后审核信息
  const ViewFormDataPart = () =>{
    return<>
         <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={6}>
              <Form.Item  label="制单人" labelCol={{ span: 8 }}>
                  <span>{formData.createUserName}</span>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="制单时间" labelCol={{ span: 8 }}>
               <span>{formData.createTime}</span>
              </Form.Item>
            </Col>
         
          {/* <Col span={6}>
              <Form.Item  label="审核人" labelCol={{ span: 8 }}>
                  <span>{formData.auditUserName}</span>
              </Form.Item>
          </Col> */}
          <Col span={6}>
              <Form.Item  label="审核时间" labelCol={{ span: 8 }}>
                  <span>{formData.auditTime}</span>
              </Form.Item>
          </Col>
          <Col span={6}>
              <Form.Item  label="审核状态" labelCol={{ span: 8 }}>
                 {AuditStatus[formData.auditStatus] && <Tag color={AuditStatus[formData.auditStatus].tagClass}>{AuditStatus[formData.auditStatus].label}</Tag>}
              </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
        <Col span={12}>
              <Form.Item  label="审核备注" labelCol={{ span: 4 }}>
                 <TextArea rows={1} disabled value={formData.auditRemark}  autoSize={{ minRows: 1, maxRows: 2 }}></TextArea>
              </Form.Item>
          </Col>
        </Row>
    </>
  }
  // 选择地址
  const handleChangeAddr = ( v: string ) => {
      let item = state.addressList.filter(item=> item.addressID == v)
      dispatch({type: 'SetAddressForm', payload: item[0]})
  }
  return (
    <div className={styles.root} style={{ height: height + "px" }}>
      <div className={styles.content}>
        <FormSubtitle>基础信息</FormSubtitle>
        <div
          style={{
            margin: "" + autoRem(1.9375) + " " + autoRem(1.25) + " 0 0",
          }}
        >
            {disabled && formData.auditTime && <ViewFormDataPart/>}
            {method != 'Create' && <Row gutter={16}>
              <Col span={6}>
                  <Form.Item label="采购标题" labelCol={{span: 8}}>
                      <Input disabled={disabled}
                      value={formData.purchaseTitle}
                      onChange={(v) =>
                        action.setFormData({
                          dispatch,
                          key: "purchaseTitle",
                          value: v.target.value,
                        })}
                      />
                  </Form.Item>
              </Col>
              </Row>}
            <Row gutter={16}>
              <Col span={6}>
                  <Form.Item label="收货人" labelCol={{span: 8}}>
                      <span>{formData.purchaseReceipt.consigneeName}</span>
                  </Form.Item>
              </Col>
              <Col span={6}>
                  <Form.Item name="consigneePhone" label="联系电话" labelCol={{span: 8}}>
                      <span>{formData.purchaseReceipt.consigneePhone}</span>
                  </Form.Item>
              </Col>
              <Col span={12}>
                  <Form.Item label="收货地址" name='detailAddress' labelCol={{span: 4}}
                    rules={[{
                      required: true,
                      message: '请选择收货地址'
                    }]}
                  >
                    {updateFormField('detailAddress',formData.detailAddress)}
                  <Select style={{ width: "100%" }}
                                onChange={handleChangeAddr}
                                showSearch
                                allowClear
                                value={formData.purchaseReceipt.addressID}
                                disabled={disabled}
                            >
                               {state.addressList.length > 0 && state.addressList.map(item=>{
                                return <Option value={item.addressID}>
                                    <div>
                                      <div>{item.consigneeProvince + item.consigneeCity + item.consigneeDistrict + item.consigneeAddress}</div>
                                      <div style={{display: 'flex', justifyContent:'space-between',alignItems:'center'}}>
                                          <div>{item.consigneeName}</div><div>{item.consigneePhone}</div>
                                      </div> 
                                    </div>
                                </Option>
                               })}
                            </Select>
                  </Form.Item>
              </Col>
            </Row>
          <Row gutter={16}>
          <Col span={12}>
              <Form.Item label="仓库" labelCol={{ span: 4 }}>
               <span>{formData.purchaseReceipt.whName}</span>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="备注" labelCol={{ span: 4 }}>
                <TextArea
                  autoSize={{ minRows: 1, maxRows: 2 }}
                  maxLength={200}
                  disabled={disabled}
                  value={formData.remark}
                  onChange={(v) =>
                    action.setFormData({
                      dispatch,
                      key: "remark",
                      value: v.target.value,
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div style={{ marginTop: -10 }}>
          <FormSubtitle>商品信息</FormSubtitle>
        </div>
        <div
          style={{
            margin: "" + autoRem(1) + " " + autoRem(1.25) + " 0 0",
          }}
        ></div>
        <div style={{  minHeight: 20 }}>
          <ButtonPanel>
            <Button disabled={disabled} onClick={() => goodsFunc.setTrue()}>
              选择商品
            </Button>
          </ButtonPanel>
        </div>
      
        <DataTable dataList={state.tableList} disabled={disabled} />
        {/* <TableExpand>
          数量合计：{allNumber().toFixed(3)}
          <span style={{ marginLeft: 10 }}>
            金额合计：￥{NumberTool.toAmount(allAmount())}
          </span>
        </TableExpand> */}
      </div> 

      <ChooseShop
        visibleModal={shopState}
        close={(status, r) => {
          if (r) {
            action.setFormData({ dispatch, key: "shopId", value: r[0].id });
            action.setFormData({
              dispatch,
              key: "shopName",
              value: r[0].shopName,
            });
          }
          updateFormField("in-shop");
          shopFunc.setFalse();
        }}
      />
     {goodsState && <ChooseGoodsPurchase
        visibleModal={goodsState}
        close={(status, r) => {
          if (r) {
            action.addTableItem({ dispatch, list: r });
          }
          goodsFunc.setFalse();
        }}
      />}
    </div>
  );
}
