import { message } from "antd";
import { StoreLogic } from "../../../../logic/store_logic";
import { FormAttribute } from "../../store/stored/form_store";

type Init = {
    state;
    dispatch;
    form;
    editId: string;
    seeId: string;
}
interface InitBasic {
    dispatch;
    state;
}
interface InitEdit {
    state;
    dispatch;
    form;
    id;
}
interface ISetValue {
    attribute: FormAttribute;
    value: string | number | any;
    dispatch;
}
var id = ''
export class FormAction {

    private storedLgc = new StoreLogic();

    async init({ state, dispatch, seeId, editId, form }: Init) {
        await this.initBasic({ dispatch, state });
        if (editId || seeId) {
            await this.initEdit({ dispatch, state, id: editId || seeId, form })
        }
        id = editId || seeId
    }
    private async initBasic({ }: InitBasic) {

    }
    private async initEdit({ dispatch, id, form }: InitEdit) {
        // 回填状态
        const item = await this.storedLgc.selData(id);

        // selectClasses
        dispatch({ type: "SetForm", payload: item });

        // 填充节点
        form.setFieldsValue({
            "rule-name": item.ruleName,
            "store-money": item.storedValue,
            "store-type": item.grantType == 1 ? '金额' : item.grantType == 2 ? '积分' : '不赠送',
            "store-give": item.grantValue,
            "remark": item.remark,
            "store-user": item.addUserID,
            "store-time": item.addDateTime,
        });
    }
    reset({ dispatch, form }) {

        // selectClasses
        dispatch({
            type: "SetForm", payload: {
                ruleName: "", storedValue: "", remark: "", grantType: "", grantValue: ""
            }
        });

        // 填充节点
        form.setFieldsValue({
            "rule-name": "",
            "store-money": "",
            "store-type": "",
            "store-give": "",
            "remark": "",
            "store-user": "",
            "store-time": "",
        });
    }
    setValue({ attribute, value, dispatch }: ISetValue) {
        dispatch({ type: "Change_FormData", payload: { attribute, value } });
    }
    private paddingData(state) {
        let params: any = {};
        {
            let { id, ruleName, storedValue, grantType, grantValue, remark } = state;
            grantValue = grantValue!=''?grantValue:0
            params = {
                id,
                ruleName,
                storedValue,
                grantType,
                grantValue,
                remark
            }
        }
        return params;
    }
    async addSubmit({ state }) {
        let param = this.paddingData(state)
        const r = await this.storedLgc.addData(param);
        if (r.data.statusCode == 200) {
            message.success("保存成功");
        } else {
            message.error(r.data.message);
        }
        return r.data.statusCode == 200;
    }
    // 更新规则
    async updSubmit({ state }) {
        state['id'] = id
        let param = this.paddingData(state)
        const r = await this.storedLgc.updData(param);
        if (r.data.statusCode == 200) {
            message.success("更新成功");
        } else {
            message.error(r.data.message);
        }
        return r.data.statusCode == 200;
    }
}
export const formAction = new FormAction();