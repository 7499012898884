import {
  FormAction,
} from "../../action/taxCodeInfo/form_action";
import { FormSubtitle } from "../form_subtitle";
import styles from "../../style/goods_classify.module.scss";
import { Col, Form, Input, InputNumber, Row } from "antd";
import { useContext } from "react";
import { FormStore } from "../../store/taxCodeInfo/form_store";
import { TextArea } from "../../../../component/text-area";
interface IFormBasic {
    action: FormAction;
    disabled: boolean;
    form;
    title;
    updateFormField?;
  }
export function FormBasic({
    action,
    disabled,
    form,
    title,
    updateFormField,
  }: IFormBasic){
    const {state, dispatch} = useContext(FormStore)
    return<>
    <div className={styles.root}>
        <FormSubtitle>税收编码配置信息</FormSubtitle>
        <div style={{ marginTop: "1.5625rem", marginLeft: "13px" }}></div>
        <Row gutter={16}>
          <Col span={20}>
            <Form.Item
              labelAlign="left"
              name="codeName"
              label="编码名称"
              labelCol={{ span: 4 }}
              rules={[
                {
                  required: true,
                  message: "请输入税收编码名称",
                },
              ]}
            >
              <Input
                allowClear
                maxLength={20}
                autoComplete="off"
                disabled={disabled}
                placeholder="请输入税收编码名称"
                onChange={(v) => {
                  action.setValue({
                    dispatch,
                    value: v.target.value,
                    attribute: "codeName",
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={20}>
            <Form.Item
              labelAlign="left"
              name="taxCode"
              label="税收编码"
              labelCol={{ span: 4 }}
              rules={[
                {
                  required: true,
                  message: "请输入税收编码",
                },
              ]}
            >
              <Input
                allowClear
                maxLength={30}
                autoComplete="off"
                disabled={disabled}
                placeholder="请输入税收编码"
                onChange={(v) => {
                  action.setValue({
                    dispatch,
                    value: v.target.value,
                    attribute: "taxCode",
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={20}>
            <Form.Item
              labelAlign="left"
              name="rate"
              label="税率(%)"
              labelCol={{ span: 4 }}
              rules={[
                {
                  required: true,
                  message: "请输入税率",
                },
              ]}
            >
              <InputNumber
              size='large' 
                autoComplete="off"
                disabled={disabled}
                placeholder="请输入税率"
                min={0}
                max={100}
                precision={0}
                onChange={(v) => {
                  action.setValue({
                    dispatch,
                    value: v,
                    attribute: "rate",
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={20}>
            <Form.Item
              labelAlign="left"
              name="remark"
              label="备注"
              labelCol={{ span: 4 }}
            >
              <Input.TextArea
               allowClear
               maxLength={100}
               autoComplete="off"
               disabled={disabled}
               autoSize={{ minRows: 6, maxRows: 9 }}
               placeholder="请输入备注"
               onChange={(v) => {
                 action.setValue({
                   dispatch,
                   value: v.target.value,
                   attribute: "remark",
                 });
               }}
              >
              </Input.TextArea>
            </Form.Item>
          </Col>
        </Row>
        </div>
    </>
}