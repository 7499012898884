import { message } from "antd";
import { AuthxtLogic } from "../../../../logic/authxt_logic";
import { FormAttribute } from "../../store/auth/form_store";
import { MenuLogic } from "../../../../logic/menu_logic";
type Init = {
    state;
    dispatch;
    form;
    editId: string;
    seeId: string;
}
interface InitBasic {
    dispatch;
    state;
}
interface InitEdit {
    state;
    dispatch;
    form;
    id;
}
interface ISetValue {
    attribute: FormAttribute;
    value: string | number | any[] | any;
    dispatch;
}
var id = ''
export class FormAction {

    private authLgc = new AuthxtLogic();
    private menuLgc = new MenuLogic();

    async init({ state, dispatch, seeId, editId, form }: Init) {
        await this.initBasic({ dispatch, state });
        if (editId || seeId) {
            await this.initEdit({ dispatch, state, id: editId || seeId, form })
        }
        id = editId || seeId
    }
    private async initBasic({ dispatch, state }: InitBasic) {
        let pageSize = 1000
        let pageIndex = 1
        dispatch({ type: "MenuList", payload: await (await this.menuLgc.querysList({ pageIndex, pageSize })).data.data });
    }
    private async initEdit({ dispatch, id, form }: InitEdit) {
        // 回填状态
        const data = await this.authLgc.selData(id);
        // selectClasses
        let item =data instanceof Array? data[0]: data

        dispatch({ type: "SetForm", payload: item });
        if(item){
              // 填充节点
        form.setFieldsValue({
            "auth-name": item.name,
            "auth-description": item.description,
            "auth-page": item.page,
            "auth-keepAlive": item.keepAlive,
            "remark": item.remark,
        });
        }
      
    }
    reset({ dispatch, form }) {
        // selectClasses
        dispatch({
            type: "SetForm", payload: {
                gradeName: "",
                remark: "",
                page: "",
                name: "",
                description: "",
            }
        });

        // 填充节点
        form.setFieldsValue({
            "auth-name": "",
            "auth-description": "",
            "auth-page": "",
            "auth-keepAlive": "",
            "remark": "",
        });
    }
    setValue({ attribute, value, dispatch }: ISetValue) {
        dispatch({ type: "Change_FormData", payload: { attribute, value } });
    }
    private paddingData(state) {
        let params: any = {};
        {
            const { id, name, page, description, remark, keepAlive } = state;
            params = {
                id,
                name,
                description,
                page,
                remark,
                keepAlive
            }
        }
        return params;
    }
    async addSubmit({ state }) {
        const r = await this.authLgc.addData(this.paddingData(state));
        if (r.data.statusCode == 200) {
            message.success("保存成功");
        } else {
            message.error(r.data.message);
        }
        return r.data.statusCode == 200;
    }
    // 更新等级
    async updSubmit({ state }) {
        state['id'] = id
        const r = await this.authLgc.updData(this.paddingData(state));
        if (r.data.statusCode == 200) {
            message.success("更新成功");
        } else {
            message.error(r.data.message);
        }
        return r.data.statusCode == 200;
    }
}
export const formAction = new FormAction();