import { message } from "antd";
import axios from "axios";
import Cookies from "js-cookie";
import { DataTableAction } from "../../table/action/data_table_action";
import { Tools } from "../../../config";
import { ShopLogic } from "../../../logic/shop_logic";
import { ClassesLogic } from "../../../logic/classes_logic";
import { GoodsLogic } from "../../../logic/goods_logic";
class ChooseAction extends DataTableAction {
    async delRow(id) {
    }
    delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {
    }
    loadPageByZeroInventory({ dispatch,  state, pageIndex, pageSize, search }) {
        let { shopID} = JSON.parse(Cookies.get('loginMsg'))
        this._loadPage({ dispatch,  state, pageIndex, pageSize, search }, new GoodsLogic().selListByZeroInventory,
        {
            source: 'PC',
            shopID: shopID,
            // type:'specification'
        });
    }
    loadPageForSpecification({ dispatch,  state, pageIndex, pageSize, search }) {
        this._loadPage({ dispatch,  state, pageIndex, pageSize, search }, new GoodsLogic().selListForSpecification);
    }
    loadPage({ dispatch,  state, pageIndex, pageSize, search }) {
        this._loadPage({ dispatch,  state, pageIndex, pageSize, search }, new GoodsLogic().selList);
    }
    loadPageSpecification({ dispatch,  state, pageIndex, pageSize, search }) {
        let { shopID, shopName } = JSON.parse(Cookies.get('loginMsg'))
        let { source, shopId } = search
        this._loadPage(
            { dispatch,  state, pageIndex, pageSize, search },
            new GoodsLogic().selListForSpecification,
            {
                source: source || 'PC',
                shopID: shopID || shopId,
                type:'specification'
            }
        );
    }
    loadPageSpecificationForMini({ dispatch,  state, pageIndex, pageSize, search }) {
        let { shopID, shopName } = JSON.parse(Cookies.get('loginMsg'))
        let { type } = search
        this._loadPage(
            { dispatch,  state, pageIndex, pageSize, search },
            new GoodsLogic().selListForSpecification,
            {
                source: 'WX',
                shopID: shopID,
                type: type
            }
        );
    }
    private async _loadPage({ dispatch,  state, pageIndex, pageSize, search }, callback, _params?: any) {

        // 商品列表
        const { keywords, classesId, statusId, typeId, brandId, isStore } = search;
        const params = {
             pageIndex, pageSize, goodsBrandID: brandId, goodsClassesID: classesId, goodsType: typeId, status: statusId, goodsNameOrNo: keywords,isStore: isStore,
            //合并额外的参数
            ..._params,
        }
        let type;
        if(_params&&_params.type){
            type = _params.type
        }
        delete params.type
        if (undefined == keywords) {
            delete params.goodsNameOrNo;
        }
        if (undefined == brandId) {
            delete params.goodsBrandID;
        }
        if (undefined == classesId) {
            delete params.goodsClassesID;
        }
        if (undefined == typeId) {
            delete params.goodsType;
        }
        if (undefined == statusId) {
            delete params.status;
        }
        if (undefined == isStore) {
            delete params.isStore;
        }
        if (-1 == statusId) {
            delete params.status;
        }
        const r = await callback(params);
        if (r.data.statusCode == 200) {
            const dataList = r.data.data.dataSource || [];
            let arr = []
       

            let list = []
            let curpageSize = 10
            if(type&&type == 'specification'){
                dataList.map((goods_v, d) => {
                       if(goods_v.goods_SpecificationList&&goods_v.goods_SpecificationList.length>0){
                        for(let i =0;i<goods_v.goods_SpecificationList.length;i++){
                            let good = goods_v.goods_SpecificationList[i]
                                if(list.filter(ss=>ss.goodsSepID == good.id).length>0){
                                    continue;
                                }
                                delete goods_v.id;
                                // if(good.sumNumber > 0)
                                    list.push({
                                        key: d + 1,
                                        ...goods_v,
                                        ...good,
                                        goodsUnitName: goods_v.unitName,
                                        goods_SpecificationList:[good],
                                        goodsName:goods_v.goodsName,
                                        goodsSepID: good.id,
                                        id: good.id,
                                        storePrice:good.storePrice,
                                        goodsNo: goods_v.goodsNo,
                                        goodsSource: goods_v.goodsSource,
                                        isNoMarketing: goods_v.isNoMarketing
                                    })
                           
                        }
                       }
                  
                })
                // list = dataList
                console.log(list)
           curpageSize = list.length
         let  diff = list.length - 10>0?list.length - 10:0
           dispatch({type: 'Diff',payload: diff})
            }else{
                 list = dataList.map((goods_v, i) => {
                      
                       return {
                        ...goods_v,
                        // goodsClassify: clsItem.length && clsItem[0].classesName,
                        // unit: uniItem.length && uniItem[0].unitName,
                        key: i + 1,
    
                    }
                })
            }
            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount+ state.diff, dataList: list, pageSize:  curpageSize>pageSize?curpageSize:pageSize });
        }

    }

    setCondition(method: "Keywords" | "Classes" | "Status", value, dispatch) {
        if ("Keywords" == method) {
            dispatch({ type: "SetSearch", payload: { keywords: value } });
        }
        else if ("Classes" == method) {
            dispatch({ type: "SetSearch", payload: { classesId: value } });
        }
        else if ("Status" == method) {
            dispatch({ type: "SetSearch", payload: { statusId: value } });
        }
    }
    async init({ dispatch }) {
        // 商品分类
        const list = await new ClassesLogic().selListByTree();
        
        dispatch({ type: "SetAreaList", payload: list });
    }
}
export const chooseGoodsAction = new ChooseAction();