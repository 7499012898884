import { useBoolean, useDebounce, useDebounceFn, useRequest } from "ahooks";
import { Button, Input, List, message, Modal, Radio } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect, useState } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { DataList } from "../../../../component/data-list";
import { DataListStore } from "../../../../component/data-list/store/data_table_store";
import { Search } from "../../../../component/search";
import { DataTable } from "../../../../component/table";
import { DataTableStore } from "../../../../component/table/store/data_table_store";
import { TipsGroup, Tips } from "../../../../component/tips";

import img_edit from "../../../../assets/images/bianji.png";
import img_see from "../../../../assets/images/chakan1.png";
import img_del from "../../../../assets/images/shanchu.png";
import { AlarmValueSearch } from "../alarm_value/alarmvalue_search";
import { AlarmSearch } from "./alarm_search";
import { ChooseShop } from "../../../../component/choose-shop";
import { alarmAction } from "../../action/alarm_action";
import { AlarmStore } from "../../store/alarm_store";
import { Mask } from "../../../../component/mask";
import { defualtPageSize } from "../../../../logic/common_logic";
import { Progress } from "../../../../logic/common_logic";
import { AuthLogic } from "../../../../logic/auth_logic";
import Cookies from "js-cookie";

export function Alarm(viewPower) {
  const [createState, createFunc] = useBoolean(false);
  const [deleteState] = useBoolean(false);

  const [searchState, searchFunc] = useBoolean(false);

  const { state, dispatch } = useContext(AlarmStore);
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);

  const action = alarmAction;
  const [menuButton, setData] = useState([]);

  useEffect(() => {
    (async function () {
      let r = await new AuthLogic().selButton(viewPower.viewPower);

      if (!r) return;
      if (r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          r[i] = r[i].split("_")[r[i].split("_").length - 1];
        }
      }
      setData(r);
    })();

    return () => {};
  }, []);
  useEffect(() => {
    let { shopID } = JSON.parse(Cookies.get("loginMsg"));
    state.search.shopId = shopID;
    return () => {};
  });
  const columns: ColumnsType = [
    {
      title: "门店名称",
      dataIndex: "shopName",
      key: "1",
      align: "center",
    },
    {
      title: "商品分类",
      dataIndex: "goodsClassName",
      key: "2",
      align: "center",
    },
    {
      title: "商品编码",
      dataIndex: "goodsNo",
      key: "3",
      align: "center",
    },
    {
      title: "商品名称",
      dataIndex: "goodsName",
      key: "4",
      align: "center",
    },
    {
      title: "库存单位",
      dataIndex: "goodsUnitName",
      key: "5",
      align: "center",
    },
    {
      title: "库存数量",
      dataIndex: "sumNumber",
      key: "6",
      align: "center",
    },
    {
      title: "库存下限",
      dataIndex: "minNumber",
      key: "7",
      align: "center",
    },
    {
      title: "库存上限",
      dataIndex: "maxNumber",
      key: "8",
      align: "center",
    },
    {
      title: "库存状态",
      dataIndex: "ewState",
      key: "9",
      align: "center",
    },
    {
      title: "商品状态",
      dataIndex: "goodsType",
      key: "10",
      align: "center",
      render: (v) => <span>{v == 1 ? "上架" : "下架"}</span>,
    },
  ];

  const onSearch = async (pageIndex) => {
    let { shopID } = JSON.parse(Cookies.get("loginMsg"));
    state.search.shopId =
      state.search.shopId == "" || state.search.shopId == undefined
        ? shopID
        : state.search.shopId;
    await action.loadPage({
      pageIndex: pageIndex,
      pageSize: defualtPageSize(),
      dispatch: tableDispatch,
      search: state.search,
    });
  };

  useRequest(() => action.init({ dispatch }));
  const { run } = useDebounceFn(
    (fn) => {
      fn && fn();
    },
    {
      wait: 1500,
    }
  );
  return (
    <>
      <Search>
        <Input
          maxLength={20}
          allowClear
          placeholder="请输入商品编码，名称"
          onChange={(e) =>
            action.setCondition({
              dispatch,
              key: "keywords",
              value: e.target.value,
            })
          }
        />
        <input type="button" value="快速查找" onClick={() => onSearch(1)} />

        <TipsGroup>
          <input
            type="button"
            value="高级查找"
            onClick={() => searchFunc.toggle()}
          />
          <Tips width="45rem" display={searchState}>
            <AlarmSearch
              onSearch={(...arg: [any]) => {
                onSearch(1);
                searchFunc.setFalse();
              }}
            />
          </Tips>
          <Mask visible={searchState} onClick={() => searchFunc.setFalse()} />
        </TipsGroup>
      </Search>

      {false && (
        <ButtonPanel>
          <Button
            onClick={async () => {
              run(await action.exportList());
            }}
          >
            导出waiting
          </Button>
        </ButtonPanel>
      )}

      <DataTable
        columns={columns}
        action={action}
        onLoad={async () => {
          await onSearch(1);
          Progress.done();
        }}
        onChange={(pageIndex) => {
          onSearch(pageIndex);
        }}
        scroll={{ scrollToFirstRowOnChange: true, autoY: true }}
        loading={tableState.loading}
      />

      {deleteState && (
        <Modal
          className="test"
          title="温馨提示"
          visible={true}
          okText="确定"
          cancelText="取消"
          width={366}
        >
          <h1>确认删除商品？</h1>
          <p>删除之后商品信息无法修复，请慎重考虑</p>
        </Modal>
      )}
    </>
  );
}
