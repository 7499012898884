import { Button } from "../../../../component/button";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { autoRem } from "../../../../global.style";

export function Buttons(props: {
  /** 导出成Excel */
  exportExcel?: () => void;
  /** 原样导出成Excel */
  rootExportExcel?: () => void;
  /** 导出成PDF */
  exportPDF?: () => void;
  /** 打印 */
  console?: () => void;
}) {
  //   return <div></div>;
  return (
    <div style={{ height: autoRem(3.33) }}>
      <ButtonPanel>
        <Button onClick={props.exportExcel}>导出成Excel</Button>
      </ButtonPanel>
      {/* <ButtonPanel>
        <Button onClick={props.rootExportExcel}>原样导出成Excel</Button>
      </ButtonPanel>
      <ButtonPanel>
        <Button onClick={props.exportPDF}>导出成PDF</Button>
      </ButtonPanel>
      <ButtonPanel>
        <Button onClick={props.console}>打印</Button>
      </ButtonPanel> */}
    </div>
  );
}
