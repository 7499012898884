import { CommissionRuleLogic } from '../../../../logic/commissionrule_logic'
import { message } from "antd";
import Cookies from 'js-cookie'
type Init = {
    state;
    dispatch;
    form;
    id: string;
    method: string;
}
interface InitBasic {
    dispatch;
    search;
}
interface InitEdit {
    state;
    dispatch;
    form;
    id;
}

class FormAction {
    //
    async init({ state, dispatch, id, method, form }: Init) {
        //
        await this.initBasic({ dispatch, search: state.search });
        //
        if ("Create" !== method && id) {
            await this.initEdit({ dispatch, state, id, form })
        }
    }
    private async initBasic({ search, dispatch }: InitBasic) {
        this.clearTableList({ dispatch })
    }
    private async initEdit({ dispatch, id, form, state }: InitEdit) {
        //回填表单
        let item;
        const r = await new CommissionRuleLogic().getDetailById(id)
        if(r&&r.data&&r.data.statusCode == 200){
            item = r.data.data
        }else{
            message.error('获取信息失败')
        }
        if(item){
            form.setFieldsValue({
                "ruleName":item.ruleName,
                "ctype": item.ctype,
            })
        }
      
        dispatch({ type: "SetForm", payload: item });
     
    }
     private paddingData(state){
        const { formData, tableList } = state
        if(tableList.length == 0){
            message.error('请设置提成规则')
            return false
        }
        for(let i = 0; i < tableList.length; i++){
            let item = tableList[i]
            if(item.minSellingPrice){
                if(isNaN(Number(item.minSellingPrice))){
                    message.error(`第${item.ruleGrade}条开始值(订单实收总金额)应为数字`)
                    return false  
                }
                if(item.minSellingPrice < 0.01 || item.minSellingPrice > item.maxSellingPrice){
                    message.error(`第${item.ruleGrade}条开始值(订单实收总金额)应该大于等于0.01，小于结束值`)
                    return false  
                }                
            }else{
                message.error(`请设置第${item.ruleGrade}条开始值(订单实收总金额)`)
                return false  
            }
            if(item.maxSellingPrice){
                if(isNaN(Number(item.maxSellingPrice))){
                    message.error(`第${item.ruleGrade}条结束值(订单实收总金额)应为数字`)
                    return false  
                }
                if(item.maxSellingPrice < item.minSellingPrice){
                    message.error(`第${item.ruleGrade}条结束值(订单实收总金额)应该大于开始值`)
                    return false  
                }                
            }else{
                message.error(`请设置第${item.ruleGrade}条开始值(订单实收总金额)`)
                return false  
            }
            if(item.commissionValue){
                if(formData.ctype == 1){
                    if(item.commissionValue < 0.01){
                        message.error(`请设置第${item.ruleGrade}条提成值应该大于0.01`)
                        return false  
                    }
                }
                if(formData.ctype == 2){
                    if(item.commissionValue < 1 || item.commissionValue > 99){
                        message.error(`请设置第${item.ruleGrade}条提成值应该为1-99的整数`)
                        return false  
                    }
                }
            }else{
                message.error(`请设置第${item.ruleGrade}条提成值`)
                return false  
            }
        }
        const params = Object.assign({},formData, {details: tableList})
        return params
    }
    async addSubmit({ state }) {
        // const { formData, tableList } = state
        const params = this.paddingData(state)
        if(!params){
            return false
        }
        const r =await new CommissionRuleLogic().add(params)
        if(r&&r.data&&r.data.statusCode == 200){
                message.success('添加成功')
                return true
        }
        else{
            message.error(r.data.returnMessage ||r.data.message)
            return false
        }
    }

    async updSubmit({ state }, id) {
        const params = this.paddingData(state)
        if(!params){
            return false
        }
        const r = await new CommissionRuleLogic().update(params)
        if(r&&r.data&&r.data.statusCode == 200){
            message.success('修改成功')
            return true
        }
        else{
            message.error(r.data.returnMessage ||r.data.message)
            return false
        }
    }
    addTableItem({ dispatch }) {
        dispatch({ type: "AddTableItem"});
    }
    changeTableItem({ dispatch, payload}){
        dispatch({ type: 'ChangeTableItem', payload})
    }
    delTableItem(dispatch, index) {
        dispatch({ type: "DelTableItem", payload: index });
    }

    clearTableList({ dispatch }) {
        dispatch({ type: "ClearTableList" });
    }
    setFormData({ dispatch, key, value }) {
        const params = {
        }
        params[key] = value;
        if(key == 'ctype'){
            this.resetCommissionValue({dispatch})
        }
        dispatch({ type: "SetFormData", payload: params })
    }

    resetCommissionValue({ dispatch }) {
        dispatch({type:'ResetCommissionValue'})
    }
}
export const formAction = new FormAction();