import {
  Cascader,
  Col,
  Form,
  Form as FormAntd,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  TreeSelect,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useContext, useEffect, useState } from "react";
import { RadioStyle } from "../../../../component/radio-style";
import { formAction } from "../../action/menu/form_action";
import { FormStore } from "../../store/menu/form_store";
import survey from "../../../../assets/images/navicon/gaikuang.png";
import member from "../../../../assets/images/navicon/huiyuanguanli.png";
import user from "../../../../assets/images/navicon/lipin.png";
import commodity from "../../../../assets/images/navicon/shangpin.png";
import stock from "../../../../assets/images/navicon/xiaoshouguanli.png";
import shop from "../../../../assets/images/navicon/ziyuan.png";
import order from "../../../../assets/images/navicon/bianji.png";
import system from "../../../../assets/images/navicon/xitongguanli1.png";
import marketing from "../../../../assets/images/navicon/dingdan.png";
import editor from "../../../../assets/images/navicon/bianji.png";
import special from "../../../../assets/images/navicon/ztbk.png";
import report from "../../../../assets/images/navicon/bb.png";
import { Select as SelectAntd } from "antd";
import { autoRem } from "../../../../global.style";
const { Option } = Select;
const { TreeNode } = TreeSelect;
function ShopType({ value, swtichValue, disabled }) {
  const [] = useState(1);
  const { dispatch } = useContext(FormStore);

  const onChange = (e) => {
    if (!disabled) {
      formAction.setValue({
        dispatch,
        value: e.target.value,
        attribute: "KeepAlive",
      });
    }
  };

  return (
    <RadioStyle>
      <Radio.Group onChange={onChange} value={swtichValue}>
        <Radio value={true}>是</Radio>
        <Radio value={false}>否</Radio>
      </Radio.Group>
    </RadioStyle>
  );
}
// 是否缓存
function IsFrameType({ value, IsFrameValue, disabled }) {
  const [] = useState(1);
  const { dispatch } = useContext(FormStore);

  const onIsFrame = (e) => {
    if (!disabled) {
      formAction.setValue({
        dispatch,
        value: e.target.value,
        attribute: "IsFrame",
      });
    }
  };

  return (
    <RadioStyle>
      <Radio.Group onChange={onIsFrame} value={IsFrameValue}>
        <Radio value={true}>是</Radio>
        <Radio value={false}>否</Radio>
      </Radio.Group>
    </RadioStyle>
  );
}
// 是否显示
function HiddenType({ value, hiddenValue, disabled }) {
  const [] = useState(1);
  const { dispatch } = useContext(FormStore);

  const onHidden = (e) => {
    if (!disabled) {
      formAction.setValue({
        dispatch,
        value: e.target.value,
        attribute: "Hidden",
      });
    }
  };

  return (
    <RadioStyle>
      <Radio.Group onChange={onHidden} value={hiddenValue}>
        <Radio value={false}>显示</Radio>
        <Radio value={true}>隐藏</Radio>
      </Radio.Group>
    </RadioStyle>
  );
}
export function FormBasic(title) {
  const { state, dispatch: formDispatch } = useContext(FormStore);
  const action = formAction;
  const { formData, menuList, authxtList } = state;
  var isLook = false;
  if (title.title == "查看菜单") {
    isLook = true;
  } else {
    isLook = false;
  }
  return (
    <>
      <div style={{ margin: "1.9375rem 0 0 0" }}>
        <Row gutter={16}>
          <Col span={11}>
            <FormAntd.Item
              name="menu-parentUID"
              label="上级菜单"
              labelCol={{ span: 8 }}
            >
              <TreeSelect
                showSearch
                disabled={isLook}
                style={{ width: "100%" }}
                placeholder="请选择上级菜单"
                allowClear
                onChange={(v) =>
                  action.setValue({
                    dispatch: formDispatch,
                    value: v,
                    attribute: "ParentUID",
                  })
                }
              >
                {menuList.map((v) => (
                  <TreeNode value={v.id} title={v.name}>
                    {v.second.map((k) => (
                      <TreeNode value={k.id} title={k.name}>
                        {k.children &&
                          k.children.map((q) => (
                            <TreeNode value={q.id} title={q.name} />
                          ))}
                      </TreeNode>
                    ))}
                  </TreeNode>
                ))}
              </TreeSelect>
            </FormAntd.Item>
          </Col>
          <Col span={11}>
            <FormAntd.Item
              name="menu-ico"
              label="菜单图标"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: true,
                  message: "请输入菜单图标",
                },
              ]}
            >
              <Select
                disabled={isLook}
                onChange={(v) =>
                  action.setValue({
                    dispatch: formDispatch,
                    value: v,
                    attribute: "Icon",
                  })
                }
              >
                <Option value="commodity">
                  <img src={commodity} alt="" style={{ width: "22px" }} />
                  <span style={{ marginLeft: "8px" }}>commodity</span>
                </Option>
                <Option value="member">
                  <img src={member} alt="" style={{ width: "22px" }} />
                  <span style={{ marginLeft: "8px" }}>member</span>
                </Option>
                <Option value="order">
                  <img src={order} alt="" style={{ width: "22px" }} />
                  <span style={{ marginLeft: "8px" }}>order</span>
                </Option>
                <Option value="shop">
                  <img src={shop} alt="" style={{ width: "22px" }} />
                  <span style={{ marginLeft: "8px" }}>shop</span>
                </Option>
                <Option value="stock">
                  <img src={stock} alt="" style={{ width: "22px" }} />
                  <span style={{ marginLeft: "8px" }}>stock</span>
                </Option>
                <Option value="survey">
                  <img src={survey} alt="" style={{ width: "22px" }} />
                  <span style={{ marginLeft: "8px" }}>survey</span>
                </Option>
                <Option value="system">
                  <img src={system} alt="" style={{ width: "22px" }} />
                  <span style={{ marginLeft: "8px" }}>system</span>
                </Option>
                <Option value="user">
                  <img src={user} alt="" style={{ width: "22px" }} />
                  <span style={{ marginLeft: "8px" }}>user</span>
                </Option>
                <Option value="marketing">
                  <img src={marketing} alt="" style={{ width: "22px" }} />
                  <span style={{ marginLeft: "8px" }}>marketing</span>
                </Option>
                <Option value="editor">
                  <img src={editor} alt="" style={{ width: "22px" }} />
                  <span style={{ marginLeft: "8px" }}>editor</span>
                </Option>
                <Option value="special">
                  <img src={special} alt="" style={{ width: "22px" }} />
                  <span style={{ marginLeft: "8px" }}>special</span>
                </Option>
                <Option value="report">
                  <img src={report} alt="" style={{ width: "22px" }} />
                  <span style={{ marginLeft: "8px" }}>report</span>
                </Option>
              </Select>
            </FormAntd.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={11}>
            <FormAntd.Item
              name="menu-name"
              label="菜单名称"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: true,
                  message: "请输入菜单名称",
                },
              ]}
            >
              <Input
                disabled={isLook}
                maxLength={20}
                autoComplete="off"
                placeholder="请输入菜单名称"
                onChange={(v) =>
                  action.setValue({
                    dispatch: formDispatch,
                    value: v.target.value,
                    attribute: "Name",
                  })
                }
              />
            </FormAntd.Item>
          </Col>

          <Col span={11}>
            <FormAntd.Item
              name="menu-sort"
              label="显示排序"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: true,
                  message: "请输入排序值",
                },
              ]}
            >
              <InputNumber
                maxLength={20}
                disabled={isLook}
                size="large"
                onChange={(v) =>
                  action.setValue({
                    dispatch: formDispatch,
                    value: v,
                    attribute: "SortIndex",
                  })
                }
              />
            </FormAntd.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={11}>
            <FormAntd.Item
              name="menu-path"
              label="路由地址"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: true,
                  message: "请输入路由地址",
                },
              ]}
            >
              <Input
                disabled={isLook}
                maxLength={60}
                autoComplete="off"
                placeholder="请输入路由地址"
                onChange={(v) =>
                  action.setValue({
                    dispatch: formDispatch,
                    value: v.target.value,
                    attribute: "Path",
                  })
                }
              />
            </FormAntd.Item>
          </Col>
          <Col span={11}>
            <FormAntd.Item
              name="menu-component"
              label="组件路径"
              labelCol={{ span: 8 }}
            >
              <Input
                disabled={isLook}
                autoComplete="off"
                maxLength={60}
                placeholder="请输入组件路径"
                onChange={(v) =>
                  action.setValue({
                    dispatch: formDispatch,
                    value: v.target.value,
                    attribute: "Component",
                  })
                }
              />
            </FormAntd.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={11}>
            <FormAntd.Item
              name="menu-viewPower"
              label="权限标识"
              labelCol={{ span: 8 }}
            >
              <Select
                disabled={isLook}
                onChange={(v) =>
                  action.setValue({
                    dispatch: formDispatch,
                    value: v,
                    attribute: "ViewPower",
                  })
                }
              >
                {authxtList.map((k) => (
                  <Option value={k.name}>{k.name}</Option>
                ))}
              </Select>
            </FormAntd.Item>
          </Col>
          <Col span={11}>
            <FormAntd.Item
              name="menu-keepAlive"
              label="是否外链"
              labelCol={{ span: 8 }}
              className="custom-radio"
            >
              <ShopType
                disabled={isLook}
                value={true}
                swtichValue={formData.keepAlive}
              />
            </FormAntd.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={11}>
            <FormAntd.Item
              name="menu-hidden"
              label="显示状态"
              className="custom-radio"
              labelCol={{ span: 8 }}
            >
              <HiddenType
                disabled={isLook}
                value={true}
                hiddenValue={formData.hidden}
              />
            </FormAntd.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={11}>
            <FormAntd.Item
              name="menu-isFrame"
              label="是否缓存"
              labelCol={{ span: 8 }}
              className="custom-radio"
            >
              <IsFrameType
                disabled={isLook}
                value={false}
                IsFrameValue={formData.isFrame}
              />
            </FormAntd.Item>
          </Col>
        </Row>
      </div>
    </>
  );
}
