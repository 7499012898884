import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
  TimePicker,
  DatePicker,
  message,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { FormSubtitle } from "../../../commodity/view/form_subtitle";
import styles from "../../style/inventory_goods_formbasic.module.scss";

import img_del from "../../../../assets/images/shanchu.png";
import { seckillformAction } from "../../action/seckill/form_action";
import { useBoolean } from "ahooks";
import { ChooseGoods } from "../../../../component/choose-goods";
import { Table } from "../../../../component/table";
import { ChooseShop } from "../../../../component/choose-shop";
import { SeckillFormStore } from "../../store/seckill/form_store";
import { autoRem } from "../../../../global.style";
import moment from "moment";
const { Option } = Select;
const { RangePicker } = DatePicker;
export function DataTable({ dataList, disabled }) {
  const { dispatch } = useContext(SeckillFormStore);
  const action = seckillformAction;

  const columns: ColumnsType = [
    {
      title: "操作",
      dataIndex: "serial",
      key: "1",
      align: "center",
      width: 100,

      render: (v, r, i) => (
        <div>
          <img
            src={img_del}
            style={{ width: 20 }}
            onClick={() => {
              !disabled && action.delTableItem(dispatch, i);
            }}
          />
        </div>
      ),
    },
    {
      title: "商品编码",
      dataIndex: "goodsCode",
      key: "2",
      align: "center",
      width: 100,
    },
    {
      title: "商品名称",
      dataIndex: "goodsName",
      key: "3",
      align: "center",
      width: 100,
    },
    {
      title: "商品分类",
      dataIndex: "goodsClassName",
      key: "4",
      align: "center",
      width: 100,
    },
    // {
    //     title: '商城名',
    //     dataIndex: 'inStockUnitPrice',
    //     key: '7',
    //     align: "center",
    //     width: 120,
    // },
    {
      title: "单位",
      dataIndex: "goodsUnitName",
      key: "8",
      align: "center",
      width: 120,
    },
    {
      title: "商城价",
      // dataIndex: 'storePrice',
      key: "8",
      align: "center",
      width: 120,
      render: (e, r, i) => {
        return <div>****</div>;
      },
    },
    {
      title: "商品规格",
      dataIndex: "",
      key: "7",
      align: "center",
      width: 120,
      render: (e, r, i) => {
        return <div>****</div>;
      },
    },
    // {
    //     title: '库存',
    //     dataIndex: '',
    //     key: '7',
    //     align: "center",
    //     width: 120,
    //     render: (e, r, i) => {
    //         return <div>****</div>;
    //     }
    // },
    {
      title: "活动价",
      dataIndex: "snappedPrice",
      key: "8",
      align: "center",
      width: 120,
      render: (e, r, i) => {
        // return <Input style={{ width: 100 }} value={e} disabled={disabled} onChange={e => action.changeTableItem({ dispatch, key: "snappedPrice", value: e.target.value, index: i })} />
        return <div>***</div>;
      },
    },
    {
      title: "秒杀库存",
      dataIndex: "snappedCount",
      key: "8",
      align: "center",
      width: 120,
      render: (e, r, i) => {
        // return <Input style={{ width: 100 }} value={e} disabled={disabled} onChange={e => action.changeTableItem({ dispatch, key: "snappedCount", value: e.target.value, index: i })} />
        return <div>***</div>;
      },
    },
    {
      title: "单人限购",
      dataIndex: "singleCount",
      key: "8",
      align: "center",
      width: 120,
      render: (e, r, i) => {
        // return <Input style={{ width: 100 }} disabled={disabled} value={e} onChange={e => action.changeTableItem({ dispatch, key: "singleCount", value: e.target.value, index: i })} />
        return <div>***</div>;
      },
    },
  ];

  return (
    <Table
      dataSource={dataList}
      columns={columns}
      expandable={{
        expandedRowRender: (record) => {
          if (
            record &&
            record.goodsSpecifications &&
            record.goodsSpecifications.length > 0
          )
            return record.goodsSpecifications.map((item) => {
              return (
                <GetSpecifications
                  specifications={item}
                  unitName={record.goodsUnitName}
                  disabled={disabled}
                />
              );
            });
        },
        rowExpandable: (record) => record.name !== "Not Expandable",
      }}
    />
  );
}

function GetSpecifications({ specifications, unitName, disabled }) {
  const { state, dispatch } = useContext(SeckillFormStore);
  return (
    <div className={styles.specifications}>
      {/* 商城价格 */}
      <div
        className={styles.input}
        style={{ width: autoRem(9.4), textAlign: "center" }}
      >
        <span>{specifications.storePrice}</span>
      </div>
      <div
        className={styles.info}
        style={{ width: autoRem(9.4), textAlign: "center" }}
      >
        <span>{specifications.unitContent}</span>
        <span>{unitName}</span>
      </div>
      {/* 规格库存 */}
      {/* <div className={styles.input} style={{ width: autoRem(9.4) }}><span>{specifications.sumNumber}</span></div> */}
      {/* 活动价 */}
      <div className={styles.input} style={{ width: autoRem(9.4) }}>
        <Input
          type="number"
          defaultValue={specifications.snappedPrice}
          disabled={disabled}
          onChange={(e) => {
            specifications.snappedPrice = parseFloat(e.currentTarget.value);
            dispatch({
              type: "update",
              payload: null,
            });
            if (parseFloat(e.currentTarget.value) <= 0) {
              message.error("请输入大于零的数");
              return false;
            } else {
            }
          }}
        />
      </div>
      {/* 秒杀库存 */}
      <div className={styles.input} style={{ width: autoRem(9.4) }}>
        <Input
          type="number"
          defaultValue={specifications.snappedCount}
          disabled={disabled}
          onChange={(e) => {
            specifications.snappedCount = parseInt(e.currentTarget.value);
            dispatch({
              type: "update",
              payload: null,
            });
            if (
              e.currentTarget.value != undefined &&
              e.currentTarget.value != null &&
              e.currentTarget.value != ""
            ) {
              if (!/^[1-9]\d*$/.test(e.currentTarget.value)) {
                message.error("秒杀库存应是大于零的整数，请重新输入");
                return false;
              } else if (
                parseInt(specifications.snappedCount) > specifications.sumNumber
              ) {
                message.error("秒杀库存不能大于该规格总库存");
                return false;
              } else {
                specifications.snappedCount = parseInt(
                  specifications.snappedCount
                );
                dispatch({
                  type: "update",
                  payload: null,
                });
              }
            } else {
              message.error("请输入秒杀库存");
              return false;
            }
          }}
        />
      </div>
      {/* 单人限购 */}
      <div className={styles.input} style={{ width: autoRem(9.4) }}>
        <Input
          type="number"
          name="single"
          defaultValue={specifications.singleCount}
          disabled={disabled}
          onChange={(e) => {
            let single = e.currentTarget.value;
            specifications.singleCount = single;
            //console.log(specifications.singleCount)
            dispatch({
              type: "update",
              payload: null,
            });

            if (single == undefined || single == null || single == "") {
              message.error("请输入单人限购数量");
              return false;
            } else {
              if (!/^[1-9]\d*$/.test(single)) {
                message.error("单人限购数量应是大于零的整数，请重新输入");
                return false;
              } else {
                specifications.singleCount = parseInt(single);
                if (specifications.singleCount > specifications.snappedCount) {
                  message.error("单人限购数量应小于秒杀库存，请重新输入");
                  return false;
                }
              }
            }
          }}
        />
      </div>
    </div>
  );
}

export function InventoryGoodsFormBasic({ title, disabled, updateFormField }) {
  const [shopState, shopFunc] = useBoolean(false);
  const [goodsState, goodsFunc] = useBoolean(false);

  const { state, dispatch } = useContext(SeckillFormStore);
  const { formData } = state;

  const action = seckillformAction;
  var isLook = false;
  if (title == "查看秒杀") {
    isLook = true;
  } else {
    isLook = false;
  }
  const onChangeCheck = (e) => {
    if (!disabled) {
      action.setFormData({
        dispatch,
        key: "isMemberClass",
        value: e.target.checked ? 2 : 1,
      });
      if (e.target.checked) {
        action.setFormData({ dispatch, key: "classList", value: [] });
      }
    }
  };
  // 日期段选择
  const onangeChange = (time, timeString) => {
    console.log(timeString);
    action.setFormData({ dispatch, key: "beginTime", value: timeString[0] });
    action.setFormData({ dispatch, key: "endTime", value: timeString[1] });
  };
  const disabledDate = (current) => {
    return current < moment().startOf("day");
  };
  const count = (key) =>
    state.tableList.map((v) => v[key]).reduce((p, c) => p + c, 0);

  const handleChange = (value, option) => {
    let arr = [];
    let arrayId = [];
    for (let i = 0; i < option.length; i++) {
      let obj = {};
      obj["memberClassID"] = option[i].key;
      arrayId.push(option[i].key);
      arr.push(obj);
    }
    action.setFormData({ dispatch, key: "memberClassList", value: arr });
    action.setFormData({ dispatch, key: "classList", value: arrayId });
  };
  let height = document.body.clientHeight - 188;
  return (
    <div className={styles.root} style={{ height: height + "px" }}>
      <div className={styles.content}>
        <FormSubtitle>基础信息</FormSubtitle>

        <div
          style={{
            margin: "" + autoRem(1.9375) + " " + autoRem(1.25) + " 0 0",
          }}
        >
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                label="活动名称"
                labelCol={{ span: 3 }}
                name="snappedName"
                rules={[
                  {
                    required: true,
                    validator: (_, __, callBack: (error?: string) => void) => {
                      if (!formData.snappedName) {
                        callBack("请输入活动名称");
                        return;
                      }
                      callBack();
                    },
                  },
                ]}
              >
                {formData.snappedName ? "" : ""}
                <Input
                  style={{ width: "400px" }}
                  maxLength={15}
                  disabled={disabled}
                  value={formData.snappedName}
                  autoComplete="off"
                  placeholder="请输入"
                  onChange={(e) => {
                    updateFormField("snappedName");
                    action.setFormData({
                      dispatch,
                      key: "snappedName",
                      value: e.target.value,
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                label="分配门店"
                labelCol={{ span: 3 }}
                name="shopName"
                rules={[
                  {
                    required: true,
                    validator: (_, __, callBack: (error?: string) => void) => {
                      if (
                        state.formData.shopList == undefined ||
                        state.formData.shopList.length == 0
                      ) {
                        callBack("请选择分配门店");
                        return;
                      }
                      callBack();
                    },
                  },
                ]}
              >
                {formData.shopName ? "" : ""}
                <Input
                  style={{ width: "400px" }}
                  disabled={disabled}
                  value={formData.shopName}
                  autoComplete="off"
                  placeholder="请选择门店"
                  onClick={() => shopFunc.setTrue()}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 3 }}
                label={<span className={styles.alarm}>会员等级</span>}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Checkbox
                    checked={formData.isMemberClass == 2 ? true : false}
                    onChange={onChangeCheck}
                  >
                    不限制
                  </Checkbox>
                  <Select
                    disabled={
                      disabled || formData.isMemberClass == 2 ? true : false
                    }
                    mode="multiple"
                    style={{ width: "400px", marginTop: "10px" }}
                    value={formData.classList}
                    placeholder="请选择"
                    onChange={handleChange}
                  >
                    {state.TagList.map((v) => (
                      <Option value={v.id} key={v.id}>
                        {v.gradeName}
                      </Option>
                    ))}
                  </Select>
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 3 }}
                label={<span className={styles.alarm}>活动时间</span>}
              >
                {formData.beginTime == "" ||
                formData.beginTime == null ||
                formData.beginTime == undefined ? (
                  <RangePicker
                    showTime
                    disabledDate={disabledDate}
                    disabled={disabled}
                    style={{ width: "400px" }}
                    onChange={onangeChange}
                  />
                ) : (
                  <RangePicker
                    showTime
                    disabledDate={disabledDate}
                    disabled={disabled}
                    style={{ width: "400px" }}
                    onChange={onangeChange}
                    value={[
                      moment(formData.beginTime, "YYYY-MM-DD hh:mm:ss"),
                      moment(formData.endTime, "YYYY-MM-DD hh:mm:ss"),
                    ]}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 3 }}
                label={<span className={styles.alarm}>活动商品</span>}
              >
                <div
                  style={{
                    fontSize: "10px",
                    width: "60px",
                    height: "30px",
                    textAlign: "center",
                    lineHeight: "30px",
                    background: "#ff746d",
                    color: "#fff",
                    cursor: "pointer",
                  }}
                  onClick={() => !disabled && goodsFunc.setTrue()}
                >
                  添加商品
                </div>
              </Form.Item>
            </Col>
          </Row>
        </div>

        <DataTable dataList={state.tableList} disabled={disabled} />
      </div>

      <ChooseShop
        visibleModal={shopState}
        type='checkbox'
        close={(status, r) => {
          if (r) {
            let arr = [];
            let shopName = []
            for (let i = 0; i < r.length; i++) {
              let obj = {};
              obj["shopID"] = r[i].id;
              arr.push(obj);
              shopName.push(r[i].shopName)
            }
            action.setFormData({ dispatch, key: "shopList", value: arr });
            action.setFormData({
              dispatch,
              key: "shopName",
              value: shopName,
            });
          }
          updateFormField("shopName");
          shopFunc.setFalse();
        }}
      />
      <ChooseGoods
        visibleModal={goodsState}
        filter={{
          source: "wx",
          specifications: true,
          isMarketing: true,
          type: true,
        }}
        close={(status, r) => {
          if (r) {
            console.log(r);
            action.addTableItem({ dispatch, list: r });
          }
          goodsFunc.setFalse();
        }}
      />
    </div>
  );
}
