import { Col, Form, Row } from "antd";
import React, { useContext, useEffect } from "react";
import { Input } from "../../../component/input";
import { Select } from "../../../component/select";
import styles from "../style/form_basic.module.scss";
import { FormSubtitle } from "./form_subtitle";
import { Select as SelectAntd } from "antd";
import { FormAction } from "../action/form_action";
import { FormStore } from "../store/form_store";
import { autoRem } from "../../../global.style";

interface IFormBasic {
  action: FormAction;
  form;
  disabled;
}

export function FormSupplement({ action, disabled }: IFormBasic) {
  const { state, dispatch } = useContext(FormStore);
  const { formData } = state;

  return (
    <div className={styles.root}>
      <FormSubtitle>补充资料</FormSubtitle>

      <div style={{ margin: "1.9375rem 0 0 0" }}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="goods-status"
              label="商品状态"
              labelCol={{ span: 8 }}
            >
              <Select
                disabled={disabled}
                placeholder="请选择商品状态"
                defaultValue={formData.statusId}
                onChange={(v) =>
                  action.setValue({ dispatch, value: v, attribute: "StatusId" })
                }
              >
                <SelectAntd.Option value={1}>正常</SelectAntd.Option>
                <SelectAntd.Option value={2}>停售</SelectAntd.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="goods-sort"
              label="商品排序"
              labelCol={{ span: 8 }}
            >
              <Input
                disabled={disabled}
                placeholder="请输入商品排序"
                maxLength={20}
                defaultValue={formData.sort}
                onChange={(v) =>
                  action.setValue({
                    dispatch,
                    value: v.target.value,
                    attribute: "Sort",
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="goods-english"
              label="英文名"
              labelCol={{ span: 8 }}
            >
              <Input
                disabled={disabled}
                placeholder="请输入英文名"
                maxLength={20}
                defaultValue={formData.english}
                onChange={(v) =>
                  action.setValue({
                    dispatch,
                    value: v.target.value,
                    attribute: "English",
                  })
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </div>
  );
}
