import { ColumnsType } from "antd/lib/table";
import { Tips, TipsGroup } from "../../../../component/tips";
import { ExpiredSearch } from "./warehousing_search";
import { Mask } from "../../../../component/mask";
import { Button, Input, Tag } from "antd";
import { defualtPageSize, Progress } from "../../../../logic/common_logic";
import { Search } from "../../../../component/search";
import { DataTable } from "../../../../component/table";
import { DataTableStore } from "../../../../component/table/store/data_table_store";
import { expiredAlarmAction } from "../../action/expired-alarm/expiredalarm_action";
import { ExpiredAlarmStore } from "../../store/expired-alarm/file_store";
import { useBoolean } from "ahooks";
import { useContext } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
export function ExpiredAlarm(viewPower) {
  const action = expiredAlarmAction;
  const [searchState, searchFunc] = useBoolean(false);
  const { state, dispatch } = useContext(ExpiredAlarmStore);
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);
  const onSearch = async (pageIndex) => {
    await action.loadPage({
      pageIndex: pageIndex,
      pageSize: defualtPageSize(),
      dispatch: tableDispatch,
      search: state.search,
    });
  };
  const columns: ColumnsType = [
    {
      title: "商品编码",
      dataIndex: "goodsNo",
      key: "2",
      align: "center",
      width: 160,
    },
    {
      title: "商品名称",
      dataIndex: "goodsName",
      key: "3",
      align: "center",
    },
    {
      title: "入库单号",
      dataIndex: "oddNumbers",
      key: "4",
      align: "center",
    },
    {
      title: "生产日期",
      dataIndex: "manufactureDate",
      key: "5",
      align: "center",
    },
    {
      title: "保质期",
      dataIndex: "shelfLifeDay",
      key: "6",
      align: "center",
    },
    {
      title: "过期时间",
      dataIndex: "expirationDate",
      key: "7",
      align: "center",
    },
  ];
  return (
    <div>
      <Search>
        <Input
          maxLength={50}
          allowClear
          placeholder="请输入商品编码，名称"
          onChange={(e) => {
            action.setCondition({
              dispatch,
              key: "keywords",
              value: e.target.value,
            });
          }}
        />
        <input type="button" value="快速查找" onClick={() => onSearch(1)} />

        <TipsGroup>
          <input
            type="button"
            value="高级查找"
            onClick={() => searchFunc.toggle()}
          />
          <Tips display={searchState} width="48rem">
            <ExpiredSearch
              onSearch={() => {
                searchFunc.setFalse();
                onSearch(1);
              }}
            />
          </Tips>
          <Mask visible={searchState} onClick={() => searchFunc.setFalse()} />
        </TipsGroup>
      </Search>
      <ButtonPanel></ButtonPanel>
      <DataTable
        columns={columns}
        action={action}
        onLoad={async () => {
          await onSearch(1);
          Progress.done();
        }}
        onChange={(pageIndex) => {
          onSearch(pageIndex);
        }}
        scroll={{ scrollToFirstRowOnChange: true, autoY: true, x: 1060 }}
        loading={tableState.loading}
      />
    </div>
  );
}
