// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../config";
import { effeative, maxPageSize } from "./common_logic";

export class TagLogic {
    async querysList() {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("menber_tag_list"),
            data: {
                labelName: '',
                pageIndex: 1,
                pageSize: maxPageSize(),
                orderBy: '',
                sort: ''
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r.data.data.dataSource;
    }
}