import { Button } from "../../../../component/button";
import { Layer } from "../../../../component/layer/layer";
import {
  Upload,
  Cascader,
  Checkbox,
  Button as AntdButton,
  DatePicker,
  Col,
  Form as FormAntd,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  TreeSelect,
  message,
} from "antd";
import { useRef, useState, useEffect, useContext } from "react";
import defalutImg from "../../../../assets/images/goods/ico_dotted_line_camera.png";
import { AdLogin } from "../../../../logic/ad_login";
import moment from "moment";
import styles from "../../style/ad.module.scss";

const { Option } = Select;

type dataType = {
  title: string;
  conElement: JSX.Element;
};

export function Form({
  close,
  data,
  ifEdit,
  update,
}: {
  close: Function;
  data?: any;
  ifEdit?: boolean;
  update?: () => void;
}) {
  const [form] = FormAntd.useForm();
  const inputRef = useRef(null);
  const [imgUrl, setImgUrl] = useState("");
  const [isEnable, setIsEnable] = useState(false);

  let ifDisabled: boolean = false;

  /** 填充数据 */
  const setData = () => {
    if (data) {
      //填充数据
      form.setFieldsValue({
        ...data,
        expirationDate: data.expirationDate
          ? moment(data.expirationDate)
          : null,
      });
      setImgUrl(data.imageUrl);
      setIsEnable(data.isEnable);
    } else {
      form.resetFields();
      if (imgUrl) {
        setImgUrl("");
      }
    }
  };
  /** 保存数据 */
  const save = () => {
    form
      .validateFields()
      .then((data) => {
        //上传数据
        AdLogin.Add({
          ...data,
          expirationDate: data.expirationDate?.format("Y-M-D"),
        }).then((res) => {
          //更新
          if (res.statusCode == 200) {
            update();
          } else {
            message.error(res.returnMessage || "新增失败");
          }
        });
      })
      .catch((err) => {});
  };
  const edit = () => {
    form
      .validateFields()
      .then((_data) => {
        //上传数据
        AdLogin.Update({
          ..._data,
          id: data.id,
          expirationDate: _data.expirationDate?.format("Y-M-D"),
        }).then((res) => {
          //更新
          if (res.statusCode == 200) {
            update();
          } else {
            message.error(res.returnMessage || "新增失败");
          }
        });
      })
      .catch(() => {});
  };

  useEffect(() => {
    setData();
  }, []);

  let dataType: dataType;
  if (data) {
    if (ifEdit) {
      ifDisabled = false;
      dataType = {
        title: "编辑",
        conElement: (
          <>
            <Button
              type="primary"
              color="Red"
              style={{ marginRight: "1.25rem" }}
              onClick={edit}
            >
              保存
            </Button>
            <Button type="primary" color="Yellow" onClick={setData}>
              重置
            </Button>
          </>
        ),
      };
    } else {
      ifDisabled = true;
      dataType = {
        title: "查看",
        conElement: <></>,
      };
    }
  } else {
    ifDisabled = false;
    dataType = {
      title: "新增",
      conElement: (
        <>
          <Button
            type="primary"
            color="Red"
            style={{ marginRight: "1.25rem" }}
            onClick={save}
          >
            保存
          </Button>
          <Button type="primary" color="Yellow" onClick={setData}>
            重置
          </Button>
        </>
      ),
    };
  }

  /** 获取图片 */
  const inputChange = () => {
    let _file: File = inputRef.current.files[0];
    if (!_file) {
      return;
    }
    //上传图片
    AdLogin.updateImg(_file).then((url) => {
      form.setFieldsValue({
        imageUrl: url,
      });
      setImgUrl(url);
    });
  };

  return (
    <FormAntd form={form}>
      <Layer layerWith={2} >
        <Layer.Title onClick={() => close()}>{dataType.title}</Layer.Title>
        <Layer.Body>
          <div style={{ margin: "1.9375rem 0 0 0" }}>
            <Row gutter={18}>
              <Col span={18}>
                <FormAntd.Item
                  name="name"
                  label="广告名称"
                  labelCol={{ span: 5 }}
                  rules={[
                    {
                      required: true,
                      message: "请输入广告名称",
                    },
                  ]}
                >
                  <Input
                    maxLength={20}
                    disabled={ifDisabled}
                    autoComplete="off"
                    placeholder="广告名称"
                  />
                </FormAntd.Item>
              </Col>
            </Row>
            <Row gutter={18}>
              <Col span={18}>
                <FormAntd.Item
                  name="imageUrl"
                  label="广告图片"
                  labelCol={{ span: 5 }}
                  rules={[
                    {
                      required: true,
                      message: "请选择图片",
                    },
                  ]}
                >
                  <div className={styles.img}>
                    <img
                      style={{
                        cursor: ifDisabled ? "not-allowed" : "pointer",
                      }}
                      src={imgUrl ? AdLogin.getImgUrl(imgUrl) : defalutImg}
                      alt=""
                      onClick={() => {
                        if (ifDisabled) {
                          return;
                        }
                        inputRef.current.click();
                      }}
                    />
                    <span>点击选择图片</span>
                  </div>
                  <input
                    style={{ display: "none" }}
                    ref={inputRef}
                    onChange={inputChange}
                    type="file"
                    accept="image/*"
                  />
                  <div>
                    <div>小图：430x157
</div><div>大图：839x800</div>
                  </div>
                </FormAntd.Item>
              </Col>
            </Row>
            <Row gutter={18}>
              <Col span={18}>
                <FormAntd.Item
                  name="order"
                  label="显示排序"
                  labelCol={{ span: 5 }}
                  rules={[
                    {
                      required: true,
                      message: "请输入排序值",
                    },
                  ]}
                >
                  <InputNumber
                    maxLength={20}
                    disabled={ifDisabled}
                    size="large"
                  />
                </FormAntd.Item>
              </Col>
            </Row>
            <Row gutter={18}>
              <Col span={18}>
                <FormAntd.Item
                  name="position"
                  label="屏幕位置"
                  labelCol={{ span: 5 }}
                  rules={[
                    {
                      required: true,
                      message: "请选择适屏幕位置",
                    },
                  ]}
                >
                  <Select disabled={ifDisabled}>
                    <Option value={1}>屏幕上方</Option>
                    <Option value={2}>屏幕下方</Option>
                  </Select>
                </FormAntd.Item>
              </Col>
            </Row>
            <Row gutter={18}>
              <Col span={18}>
                <FormAntd.Item
                  name="expirationDate"
                  label="失效日期"
                  labelCol={{ span: 5 }}
                >
                  <DatePicker disabled={ifDisabled} placeholder="失效时间" />
                </FormAntd.Item>
              </Col>
            </Row>
            {!!data && (
              <Row gutter={18}>
                <Col span={18}>
                  <FormAntd.Item
                    name="isEnable"
                    label="是否启用"
                    labelCol={{ span: 5 }}
                  >
                    <Checkbox
                      disabled={ifDisabled}
                      checked={isEnable}
                      onChange={(e) => {
                        setIsEnable(e.target.checked);
                        form.setFieldsValue({
                          isEnable: e.target.checked,
                        });
                      }}
                    ></Checkbox>
                  </FormAntd.Item>
                </Col>
              </Row>
            )}
          </div>
        </Layer.Body>
        <Layer.Foot>{dataType.conElement}</Layer.Foot>
      </Layer>
    </FormAntd>
  );
}
