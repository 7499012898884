import React, { useContext, useEffect } from "react";
import { Button } from "../../../../component/button";
import { Layer } from "../../../../component/layer/layer";
import { Form as FormAntd, message } from "antd";
import { formAction } from "../../action/equip/form_action";
import { FormStore } from "../../store/equip/form_store";
import { FormBasic } from "./form_basic";
import "../../style/index.scss";

import { FileStore } from "../../store/equip/file_store";
import { fileAction } from "../../action/equip/file_action";
import { DataTableStore } from "../../../../component/table/store/data_table_store";
import { defualtPageSize } from "../../../../logic/common_logic";
import { useBoolean } from "ahooks";
interface IForm {
  children?: any;
  close: (hasUpdate?: boolean) => void;
  editId?: string;
  seeId?: string;
  method?: string;
  item?: Object;
}

export function Form({ close, seeId, editId, method, item }: IForm) {
  console.log(method);
  const [form] = FormAntd.useForm();
  const { state, dispatch } = useContext(FormStore);
  const [loading, loadFunc] = useBoolean(false)
  console.log(useContext(FormStore));
  const action = formAction;
  useEffect(() => {
    formAction.init({
      state: state,
      dispatch,
      id: editId || seeId,
      form,
      method: method,
      item,
    });
    return () => {};
  }, []);

  function title() {
    if (editId) {
      return "编辑条码秤";
    } else if (seeId) {
      return "查看条码秤";
    } else {
      return "新建条码秤";
    }
  }

  return (
    <FormAntd form={form}>
      <Layer layerWith={2}>
        <Layer.Title  onClick={() => close()}>{title()}</Layer.Title>
        <Layer.Body>
          <FormBasic disabled={title() == "查看条码秤"} />
        </Layer.Body>
        {title() != "查看条码秤" && (
          <>
            <Layer.Foot>
              <Button
                type="primary"
                color="Red"
                style={{ marginRight: "1.25rem" }}
                onClick={async () => {
                  const r = await form.validateFields();
                  if(loading){
                    message.loading('正在提交数据')
                    return false
                }
                  let status = false;
                  if (editId) {
                    loadFunc.setTrue()
                    status = await action.updSubmit({ state, id: editId });
                    loadFunc.setFalse()
                  } else {
                    loadFunc.setTrue()
                    status = await action.addSubmit({ state });
                    loadFunc.setFalse()
                  }
                  status && close && close(true);
                }}
              >
                保存
              </Button>
              <Button
                type="primary"
                color="Yellow"
                onClick={() => action.reset({ dispatch, form })}
              >
                重置
              </Button>
            </Layer.Foot>
          </>
        )}
        {title() == "查看条码秤" && (
          <>
            <Layer.Foot></Layer.Foot>
          </>
        )}
      </Layer>
    </FormAntd>
  );
}
