import { Row, Col, Form, Input, message } from "antd";
import styles from "../../style/goods_classify.module.scss";
import { Select } from "../../../../component/select";
import { Select as SelectAntd } from "antd";
import { FormSubtitle } from "../form_subtitle";
import {
  formAction,
  FormAction,
} from "../../action/goodsClassifyAction/form_action";
import { useContext, useEffect, useRef } from "react";
import { FormStore } from "../../store/goods_classify_store/form_store";
import { useBoolean } from "ahooks";
// import axios from "axios";
import { Tools, axios } from "../../../../config";
import Cookies from "js-cookie";
import { zip } from "../../../../logic/common_logic";
interface IFormBasic {
  action: FormAction;
  disabled: boolean;
  form;
  title;
  updateFormField?;
}
function Radio({ onChange, defaultValue, disabled }) {
  const [state, stateFunc] = useBoolean(false);

  useEffect(() => {
    if (defaultValue) {
      stateFunc.setTrue();
    } else {
      stateFunc.setFalse();
    }

    return () => {};
  }, [defaultValue]);

  return (
    <div
      className={`${styles.radio} ${state ? styles.active : ""} ${
        disabled ? styles.disabled : ""
      }`}
      onClick={() => {
        if (disabled) return;
        onChange(!state);
        stateFunc.toggle();
      }}
    ></div>
  );
}
function Image({ max, value, imageUrl, dispatch, disabled, goodsSource }) {
  const inputRef = useRef(null);

  const onChange = async (e) => {
    let file = e.target.files[0];

    // 压缩
    file = await zip(file);

    const formData = new FormData();
    formData.append("file", file);

    const r = await axios({
      method: "POST",
      url: Tools.getApiAddress("smallProgram.UploadImages"),
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data;charset=UTF-8",
        SYSTOKEN: Cookies.get("token"),
        ClientSource: "web",
      },
    });

    if (r.data.statusCode == 200) {
      const imgUrl = r.data.data;

      if (imageUrl.length > max - 1) {
        imageUrl.length = max - 1;
      }

      let list = [...imageUrl, imgUrl];
      // 保存数据
      formAction.setValue({ dispatch, attribute: "iconPath", value: list });
      message.success("上传成功");
    } else {
      message.error(r.data.returnMessage);
    }

    inputRef.current.value = "";
  };

  const removeAt = (index) => {
    imageUrl.splice(index, 1);

    // 保存数据

    formAction.setValue({
      dispatch,
      attribute: "iconPath",
      value: [...imageUrl],
    });
  };

  return (
    <div className={styles.img_group}>
      <span
        onClick={(e) => {
          if (disabled) {
            e.preventDefault();
          } else inputRef.current.click();
        }}
      ></span>
      <input
        ref={inputRef}
        type="file"
        onChange={onChange}
        style={{ display: "none" }}
      />

      <div className={styles.img_list}>
        {imageUrl.map((v, i) =>
          v ? (
            <div>
              <img src={Tools.getImageAddress(v, goodsSource)} />
              {!disabled ? (
                <p
                  className={styles.side}
                  onClick={() => {
                    removeAt(i);
                  }}
                >
                  删 除
                </p>
              ) : null}
            </div>
          ) : null
        )}
      </div>
    </div>
  );
}
export function Form_Basic({
  action,
  disabled,
  form,
  title,
  updateFormField,
}: IFormBasic) {
  const { state, dispatch } = useContext(FormStore);
  const { formData } = state;
  return (
    <>
      <div className={styles.root}>
        <FormSubtitle>分类信息</FormSubtitle>
        <div style={{ marginTop: "1.5625rem", marginLeft: "13px" }}></div>
        <Row gutter={16}>
          <Col span={10}>
            <Form.Item
              labelAlign="left"
              name="classesName"
              label="分类名称"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: true,
                  message: "请输入分类名称",
                },
              ]}
            >
              <Input
                allowClear
                maxLength={20}
                autoComplete="off"
                disabled={disabled}
                placeholder="请输入分类名称"
                onChange={(v) => {
                  action.setValue({
                    dispatch,
                    value: v.target.value,
                    attribute: "classesName",
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
        <Col span={10}>
            <Form.Item
              labelAlign="left"
              name="classCode"
              label="分类编码"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: true,
                  message: "请输入分类编码",
                },
              ]}
            >
              <Input
                allowClear
                maxLength={20}
                autoComplete="off"
                disabled={disabled}
                placeholder="请输入分类编码"
                onChange={(v) => {
                  action.setValue({
                    dispatch,
                    value: v.target.value,
                    attribute: "classCode",
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              labelAlign="left"
              name="parentID"
              label="上级分类"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: true,
                  message: "请选择上级分类",
                },
              ]}
            >
              <Select
                allowClear
                disabled={
                  disabled ||
                  (title == "编辑商品分类" && formData.parentID == "firstlevel")
                }
                placeholder="请选择上级分类"
                onChange={(v) =>
                  action.setValue({ dispatch, value: v, attribute: "parentID" })
                }
              >
                {state.parentList.map((v) => (
                  <SelectAntd.Option
                    key={v.id}
                    value={v.id}
                    style={
                      v.id == "firstlevel"
                        ? { background: "#f7ba00 ", color: "#fff" }
                        : {}
                    }
                  >
                    {v.classesName}
                  </SelectAntd.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={10}>
            <Form.Item
              labelAlign="left"
              name="shopShow"
              label="门店展示"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: true,
                  message: "请选择是否在门店展示该分类",
                },
              ]}
            >
              <Radio
                disabled={disabled}
                defaultValue={formData.shopShow}
                onChange={(v) => {
                  // updateFormField("shopShow");
                  action.setValue({
                    dispatch,
                    attribute: "shopShow",
                    value: v,
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              labelAlign="left"
              name="shopSort"
              label="门店序号"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: true,
                  message: "请输入门店序号",
                },
                {
                  pattern: /^([0]|[1-9][0-9]*)$/,
                  message: "请输入大于等于0的整数",
                },
              ]}
            >
              <Input
                type="number"
                value={formData.shopSort}
                disabled={disabled}
                style={{ width: "80%" }}
                onChange={(e) => {
                  action.setValue({
                    attribute: "shopSort",
                    value: e.target.value,
                    dispatch,
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={10}>
            <Form.Item
              labelAlign="left"
              name="storeShow"
              label="商城展示"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: true,
                  message: "请选择是否展示",
                },
              ]}
            >
              <Radio
                disabled={disabled}
                defaultValue={formData.storeShow}
                onChange={(v) => {
                  // updateFormField("shopShow");
                  action.setValue({
                    dispatch,
                    attribute: "storeShow",
                    value: v,
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              labelAlign="left"
              name="storeSort"
              label="商城序号"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: true,
                  message: "请输入商城分类序号",
                },
                {
                  pattern: /^([0]|[1-9][0-9]*)$/,
                  message: "请输入大于等于0的整数",
                },
              ]}
            >
              <Input
                type="number"
                value={formData.storeSort}
                disabled={disabled}
                style={{ width: "80%" }}
                onChange={(e) => {
                  action.setValue({
                    attribute: "storeSort",
                    value: e.target.value,
                    dispatch,
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={10}>
            <Form.Item
              labelAlign="left"
              name="iconPath"
              label="分类图标"
              labelCol={{ span: 8 }}
            >
              <Image
                max={1}
                value={[]}
                dispatch={dispatch}
                imageUrl={formData.iconPath}
                disabled={disabled}
                goodsSource={formData.goodsSource}
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              labelAlign="left"
              name="taxID"
              label="税收编码"
              labelCol={{ span: 8 }}
              // rules={[
              //   {
              //     required: true,
              //     message: "请选择税收编码",
              //   },
              // ]}
            >
              <Select
                allowClear
                disabled={ disabled }
                placeholder="请选择税收编码"
                onChange={(v) =>
                  action.setValue({ dispatch, value: v, attribute: "taxID" })
                }
              >
                {state.taxCodeList.map((v) => (
                  <SelectAntd.Option
                    key={v.id}
                    value={v.id}
                  >
                    {v.codeName}
                  </SelectAntd.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </div>
    </>
  );
}
