import React, { useEffect, useState } from "react";
import { Layout } from "../../component/layout";
import { TabsStyle, TabList, Tab, TabPanel } from "../../component/react-tabs";
import { DataTableStoreProvider } from "../../component/table/store/data_table_store";
import { DictStoreProvider } from "./store/dict/file_store";
import { OnlineStoreProvider } from "./store/online/file_store";
import { PaymentStoreProvider } from "./store/payment/file_store";
import { LogStoreProvider } from "./store/log/file_store";
import { MenuStoreProvider } from "./store/menu/file_store";
import { AuthStoreProvider } from "./store/auth/file_store";
import { FormStoreProvider } from "./store/steelyard/file_store";
import { PrintingStoreProvider } from "./store/printing/file_store";
import { TasksStoreProvider } from "./store/tasks/file_store";
import { SwitchStoreProvider } from "./store/switch/file_store";
import { ClearStoreProvider } from "./store/clear/file_store";
import { Auth } from "./view/auth/auth";
import { Dict } from "./view/dict/dict";
import { Payment } from "./view/payment/new/payment";
import { Steelyard } from "./view/steelyard/steelyard";
import { Online } from "./view/online/online";
import { Printing } from "./view/printing/printing";
import { MenuMannage } from "./view/menu/menu";
import { MenuLogic } from "../../logic/menu_logic";
import { Ad } from "./view/ad/ad";
import { Applet } from "./view/applet";
import { SwitchControl } from "./view/switch/switch";
import { ClearData } from "./view/clear/clear";
import EquipMentManager from "./view/equipment/index";
import { MiniAdv } from "../miniAdvertise";

import { IntegralRule } from "./view/integralRule";
import { IntegralRuleStoreProvider } from "./store/integralRule/form_store";
import { PrintSetList } from "./view/printSet/file";
import { PrintSetFileStoreProvider } from "./store/printSet/file_store";
import { FormStoreProvider as PrintSetFormStoreProvider } from "./store/printSet/print_set_store";
// 发票设置
import{ FaPiaoSettingStoreProvider } from './store/fapiaoSetting/form_store'
import { FaPiaoSetting } from './view/fapiaoSetting/index'
export function System() {
  const [menuLists, setData] = useState([]);
  const getMenuList = async (url: string) => {
    let r = await new MenuLogic().menuData();
    var arr = [];
    if (r && r.length > 0) {
      r = r.filter(function (v) {
        return !v.hidden;
      });
      for (let i = 0; i < r.length; i++) {
        if (r[i].path == url && r[i].children) {
          arr = r[i].children.filter(function (v) {
            return !v.hidden;
          });
        }
      }
    }
    // arr.push({
    //   viewPower: 'FapiaoSetting',
    //   component:'FapiaoSetting',
    //   name:'发票设置'
    // })
    setData(arr);
  };
  useEffect(() => {
    getMenuList("/system");

    return () => {};
  }, []);
  return (
    // <Layout fullWidth={true}>
    <TabsStyle style="Full">
      <TabList>
        {/* <Tab>积分赠送规则</Tab> */}
        {menuLists.map((v, i) => (
          <Tab key={i}>{v.name}</Tab>
        ))}
      </TabList>

      {/* <TabPanel>
        <IntegralRuleStoreProvider>
          <IntegralRule />
        </IntegralRuleStoreProvider>
      </TabPanel> */}
      {menuLists.map((v) =>
        v.component == "MenuMannage" ? (
          //菜单管理
          <TabPanel>
            <MenuStoreProvider>
              <DataTableStoreProvider>
                <MenuMannage viewPower={v.viewPower} />
              </DataTableStoreProvider>
            </MenuStoreProvider>
          </TabPanel>
        ) : v.component == "Auth" ? (
          //权限定义
          <TabPanel>
            <AuthStoreProvider>
              <DataTableStoreProvider>
                <Auth viewPower={v.viewPower} />
              </DataTableStoreProvider>
            </AuthStoreProvider>
          </TabPanel>
        ) : v.component == "Dict" ? (
          //字典定义
          <TabPanel>
            <DictStoreProvider>
              <DataTableStoreProvider>
                <Dict viewPower={v.viewPower} />
              </DataTableStoreProvider>
            </DictStoreProvider>
          </TabPanel>
        ) : v.component == "Online" ? (
          //在线统计
          <TabPanel>
            <OnlineStoreProvider>
              <DataTableStoreProvider>
                <Online viewPower={v.viewPower} />
              </DataTableStoreProvider>
            </OnlineStoreProvider>
          </TabPanel>
        ) : v.component == "Payment" ? (
          //支付配置
          <TabPanel>
            <PaymentStoreProvider>
              <DataTableStoreProvider>
                {/* <Payment viewPower={v.viewPower} /> */}
                <Payment viewPower={v.viewPower} />
              </DataTableStoreProvider>
            </PaymentStoreProvider>
          </TabPanel>
        ) : v.component == "Steelyard" ? (
          // 电子秤
          <TabPanel>
            <TabsStyle style="BottomLine">
              <TabList>
                <Tab>
                  <span>电子秤</span>
                  <hr />
                </Tab>
                <Tab>
                  <span>打印页面设置</span>
                  <hr />
                </Tab>
                <hr />
              </TabList>
              <TabPanel>
                <FormStoreProvider>
                  <DataTableStoreProvider>
                    <Steelyard viewPower={v.viewPower} />
                  </DataTableStoreProvider>
                </FormStoreProvider>
              </TabPanel>
              <TabPanel>
                <PrintingStoreProvider>
                  <DataTableStoreProvider>
                    <Printing viewPower={v.viewPower} />
                  </DataTableStoreProvider>
                </PrintingStoreProvider>
              </TabPanel>
            </TabsStyle>
          </TabPanel>
        ) : v.component == "Layout" ? (
          <TabPanel>
            <TabsStyle style="BottomLine">
              <TabList>
                <Tab>
                  <span>小程序广告</span>
                  <hr />
                </Tab>
                <Tab>
                  <span>收银端广告</span>
                  <hr />
                </Tab>
                <hr />
              </TabList>

              <TabPanel>
                <DataTableStoreProvider>
                  <MiniAdv viewPower={v.viewPower} />
                </DataTableStoreProvider>
              </TabPanel>
              <TabPanel>
                <DataTableStoreProvider>
                  <Ad viewPower={v.viewPower} />
                </DataTableStoreProvider>
              </TabPanel>
            </TabsStyle>
          </TabPanel>
        ) : // v.component == "adConfig" ? (
        //   //广告配置
        //   <TabPanel>
        //     <DataTableStoreProvider>
        //       <Ad viewPower={v.viewPower} />
        //     </DataTableStoreProvider>
        //   </TabPanel>
        // ) :
        v.component == "EquipMent" ? ( //设备管理
          <TabPanel>
            <EquipMentManager component={v.component} />
          </TabPanel>
        ) : v.component == "SwitchControl" ? (
          //功能开关
          <TabPanel>
            <SwitchStoreProvider>
              <SwitchControl viewPower={v.viewPower} />
            </SwitchStoreProvider>
          </TabPanel>
        ) : v.component == "ClearData" ? (
          //数据清理
          <TabPanel>
            <ClearStoreProvider>
              <ClearData viewPower={v.viewPower} />
            </ClearStoreProvider>
          </TabPanel>
        ) : v.component == "appletConfig" ? (
          //小程序商城
          <TabPanel>
            <DataTableStoreProvider>
              <Applet viewPower={v.viewPower} />
            </DataTableStoreProvider>
          </TabPanel>
        ) : v.component == "PrintSet" ? (
          // 配置打印 -- 可自定义布局 58纸张打印价签
          <TabPanel>
            <PrintSetFileStoreProvider>
              <DataTableStoreProvider>
                <PrintSetList viewPower={v.viewPower} />
              </DataTableStoreProvider>
            </PrintSetFileStoreProvider>
          </TabPanel>
        ) : v.component == "IntegralRule" ? (
          // 积分赠送规则
          <TabPanel>
            <IntegralRuleStoreProvider>
              <IntegralRule />
            </IntegralRuleStoreProvider>
          </TabPanel>
        ) : v.component == "FapiaoSetting" ? (
          // 发票设置
          <TabPanel>
            <FaPiaoSettingStoreProvider>
              <FaPiaoSetting />
            </FaPiaoSettingStoreProvider>
          </TabPanel>
        ) : (
          <div></div>
        )
      )}
    </TabsStyle>
    // </Layout>
  );
}
