import React, { Component, useEffect } from "react";
import { animated } from "react-spring";
import {
  maxWidth,
  midWidth,
  minWidth,
  moreMaxWidth,
} from "../../logic/common_logic";
import styles from "./index.module.scss";
import {CloseOutlined} from '@ant-design/icons'

interface IParams {
  onClick?: () => void;
  children?: any;
  width?: string | number;
  layerWith?: number | "";
}

interface IRightTitle {
  children?: any;
  borderStyle?: any;
  onClick?:()=>void
}
function Title({ children,onClick }: IRightTitle) {
  return <div className={styles.head}>{children} <CloseOutlined style={{marginRight: 20,fontSize: 20}} onClick={()=>onClick && onClick()}/></div>;
}
function Body({ children }: IRightTitle) {
  return <div className={styles.body}>{children}</div>;
}
function Foot({ children, borderStyle }: IRightTitle) {
  return (
    <div className={styles.footer} style={borderStyle}>
      {children}
    </div>
  );
}

function LayerContent({ width, onClick, children, layerWith }: any) {
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.documentElement.style.overflowY = "hidden";
    console.log(width,layerWith)
    return () => {
      document.documentElement.style.overflowY = "";
    };
  }, []);
  width =
    layerWith == 1
      ? `${minWidth()}px`
      : layerWith == 2
      ? `${midWidth()}px`
      : layerWith == 3
      ? `${maxWidth()}px`
      : layerWith == 4
      ? `${moreMaxWidth()}px`
      : width;
  return (
    <div className={styles.layer_c} onClick={() => onClick && onClick()}>
      <div className={styles.layer_blank}></div>
      <animated.div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={styles.layer}
        style={{ width }}
      >
        {children}
      </animated.div>
    </div>
  );
}

export class Layer extends Component<IParams, any> {
  static Title = Title;
  static Body = Body;
  static Foot = Foot;

  render = () => <LayerContent {...this.props} />;
}
