/** 2维向量 */
export class V2 {
    constructor(public x: number = 0, public y: number = 0) { }

    /** 长度 */
    public get lenth(): number {
        return Math.sqrt(Math.abs(this.x) ** 2 + Math.abs(this.y) ** 2);
    }

    /**
     * 设置
     * @param x 
     * @param y 
     */
    public set(x: number = this.x, y: number = this.y) {
        this.x = x;
        this.y = y;
    }

    /** 克隆一个二位向量向量 */
    public clone(): V2 {
        return new V2(this.x, this.y);
    }

    /**
     * 转成整数
     */
    public toInt(): V2 {
        this.x = parseInt(this.x + '');
        this.y = parseInt(this.y + '');
        return this;
    }

    /**
     * 获取两点间的距离
     * @param a
     * @param b
     */
    public static getDistance(a: V2, b: V2): number {
        return Math.sqrt(Math.abs(a.x - b.x) ** 2 + Math.abs(a.y - b.y) ** 2);
    }

    /**
     * 两点相加
     * @param a
     * @param b
     */
    public static add(a: V2, b: V2): V2 {
        return new V2(a.x + b.x, a.y + b.y);
    }

    /**
     * 两点相减
     * @param a
     * @param b
     */
    public static subtract(a: V2, b: V2): V2 {
        return new V2(a.x - b.x, a.y - b.y);
    }

    /**
     * 获取a向量相对于b向量的比例向量
     * @param a 
     * @param b 
     */
    public static proportion(a: V2, b: V2): V2 {
        return new V2(a.x / b.x, a.y / b.y);
    }

    /**
     * proportion的反向操作
     * @param a 
     * @param b 
     */
    public static unProportion(a: V2, b: V2): V2 {
        return new V2(a.x * b.x, a.y * b.y);
    }
}

/**
 * 元素变换
 */
export class ElementTransform {
    /** 位置 */
    pos: V2;
    /** 尺寸 */
    size: V2;

    constructor(pos: V2 = new V2(), size: V2 = new V2()) {
        this.pos = pos;
        this.size = size;
    }

    /**
     * 获取一个元素的变换信息
     * @param e 该元素
     */
    static getElementTransform(e: HTMLElement): ElementTransform {
        let _transform = e.getBoundingClientRect();
        return new ElementTransform(new V2(_transform.left, _transform.top), new V2(_transform.width, _transform.height));
    }
}