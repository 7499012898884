import { message } from "antd";
import { DataTableAction } from "../../../../component/table/action/data_table_action";
import { defualtPageSize } from "../../../../logic/common_logic";
import { GoodsLogic,ISelList } from "../../../../logic/goods_logic";
import { PurchaseLogic } from "../../../../logic/warehouse/purchase_logic";
import { ShopLogic } from "../../../../logic/shop_logic";
import { UnitLogic } from "../../../../logic/unit_logic";
import  Cookies  from 'js-cookie'
import {TableItem} from '../../store/purchase/purchase_form_store'
type Init = {
    state;
    dispatch;
    form;
    id: string;
    method: string;
}
interface InitBasic {
    dispatch;
}
interface InitEdit {
    state;
    dispatch;
    form;
    id;
}
class PurchaseFormAction {
    // 
    // 初始化
    async init({state, dispatch, id, method, form}: Init) {
        await this.initBasic({ dispatch })
        method !== 'Create' && this.initEdit({state, dispatch, form, id})
    }
    
    async initBasic({dispatch}: InitBasic) {
        let { shopID, shopName } = JSON.parse(Cookies.get('loginMsg'))
        this.clearTableList({dispatch})
         // 地址信息
         {
            const r = await new PurchaseLogic().getAddressList();
           if(r.data.statusCode == 200 ) {
            let list = r.data.data || []
            list.length > 0 && list.forEach(item => {
                item['addressID'] = item.id
            });
                dispatch({type: 'SetAddressList', payload: list})
           }else{
            message.error(r.data.returnMessage || r.data.message)
           }
         }
    }

    // 表单回显
     async initEdit({state, dispatch, form, id}: InitEdit) {
        this.setLoading(dispatch,true)
            const r = await new PurchaseLogic().getPurchase(id)
        this.setLoading(dispatch,false)
            if(r.data.statusCode == 200 ) {
                let item = r.data.data 
                // console.log(r.data.data)
      let detailAddress =  item.purchaseReceipt.consigneeProvince?  item.purchaseReceipt.consigneeProvince+ item.purchaseReceipt.consigneeCity+ item.purchaseReceipt.consigneeDistrict+ item.purchaseReceipt.consigneeAddress:''
                item['detailAddress'] = detailAddress
               dispatch({type:'SetForm', payload: item})
               form.setFieldsValue({
                "detailAddress": detailAddress,})
            }else{
                message.error(r.data.returnMessage || r.data.message)
            }
     }
     private  paddingData(state, type) {
        let {formData, tableList} = state
         let data = null
        if(tableList.length  == 0 ) {
            message.error('请选择采购商品')
            return null
        }else{
            for(let i = 0; i < tableList.length; i++) {
                if(tableList[i].goodsNum === null || tableList[i].goodsNum  === undefined || tableList[i].goodsNum  === ''){
                    message.error(`请填写第${i+1}条采购数量`)
                    return null
                }else{
                    
                }
            }
        }
        data = {
            purchaseTitle: formData.purchaseTitle,
            dataDource: formData.dataDource,
            customerID: formData.customerID,
            customerType: formData.customerType,
            remark: formData.remark,
            applicationDetails: tableList,
            purchaseReceipt: formData.purchaseReceipt
        }
        if(type == 'add') {
            let y = new Date().toLocaleDateString('zh-CN', {day: '2-digit', month: '2-digit', year: '2-digit'})
            let title = y.split('/').join('')
            data['purchaseTitle'] = `${title}-${formData.purchaseReceipt.consigneeName?formData.purchaseReceipt.consigneeName: ''}`
        }
        
        return data
    }
    // 提交 
    async addSubmit({state, status}, dispatch) {
        let data =  this.paddingData(state, 'add')
       if(data){
            this.setLoading(dispatch,true)
            const r = await new PurchaseLogic().addPurchase(data)
            this.setLoading(dispatch, false)
           if(r.data.statusCode == 200) {
                message.success('添加成功')
                return true
           }else{
            message.error(r.data.returnMessage || r.data.message)
            return false
           }
       }else{
        return false
       }
    }
    async updSubmit({state, status},dispatch, id){
        let data =  this.paddingData(state, 'update')
        if(data){
            data['id'] = id
             this.setLoading(dispatch,true)
             const r = await new PurchaseLogic().updatePurchase(data)
             this.setLoading(dispatch, false)
            if(r.data.statusCode == 200) {
                 message.success('修改成功')
                 return true
            }else{
             message.error(r.data.returnMessage || r.data.message)
             return false
            }
        }else{
            return false
        }
    }
    // 审核
    async audit(data){
        // const r = 
    }

    // 设置数据
    setFormData({ dispatch, key, value }) {
        const params = {
        }
        params[key] = value;
        dispatch({ type: "SetFormData", payload: params })
    }
    setLoading(dispatch, loading){
        dispatch({type: 'SetLoading', payload: loading})
    }
//    采购单商品详细
// 清除
    clearTableList({dispatch}){
        dispatch({ type: "ClearTableList" });
    }
    // 添加
    addTableItem({ dispatch, list}) {
        list.map((item) => {
            item['specID'] = item.goodsSpecsID
            item['specUnit'] = item.specsUnit
            dispatch({type:'AddTableItem', payload: item})
        })
    }
    // 修改
    changeTableItem({ dispatch, key, value, index }) {
        dispatch({ type: "ChangeTableItem", payload: { key, value, index } });
    }
    // 删除
    delTableItem(dispatch, item) {
        dispatch({ type: "DelTableItem", payload: item });
    }


}
export const purchaseFormAction = new PurchaseFormAction()