import React, { useContext, useEffect } from "react";
import { Button } from "../../../../component/button";
import { Layer } from "../../../../component/layer/layer";
import {
  Tab,
  TabList,
  TabPanel,
  TabsStyle,
} from "../../../../component/react-tabs";
import { FormBasic } from "./form_basic";
import { Form as FormAntd } from "antd";
import { FileTableAction, fileAction } from "../../action/update_price_action";
import { FormStore } from "../../store/update_price_store";
import { ResultList } from "./result_list";

interface IForm {
  children?: any;
  action: FileTableAction;
  close: (hasUpdate?: boolean) => void;
  id?: string;
  method?: string;
}
export function Form({ close, action, id, method }: IForm) {
  const [form] = FormAntd.useForm();

  const { state, dispatch } = useContext(FormStore);

  useEffect(() => {
    fileAction.init({
      //   state: state,
      dispatch,
      id,
      form,
      method,
    });

    return () => {};
  }, []);

  const title = () => {
    if ("Create" === method) {
      return "新增改价单";
    } else if ("Edit" === method) {
      return "编辑改价单";
    } else {
      return "查看改价结果";
    }
  };

  return (
    <FormAntd form={form}>
      <Layer width={'100%'} onClick={() => {}}>
        <Layer.Title onClick={()=>close(true)}>{title()}</Layer.Title>
        <Layer.Body>
          {/* 基础信息 */}
          {method == 'Create'&&<TabPanel>
            <FormBasic
              disabled={title() == "查看改价结果"}
              title={title()}
              form={form}
              updateFormField={(field, value = "") => {
                form.setFieldsValue({
                  [field]: value,
                });
              }}
            />
          </TabPanel>}
          {title() == "查看改价结果"&&<TabPanel>
            <ResultList             
            />
          </TabPanel>}
        </Layer.Body>
        <Layer.Foot
          borderStyle={title() == "查看改价结果" ? { } : {}}
        >
          {"查看改价结果" !== title() ? (
            <>
              <Button
                type="primary"
                color="Red"
                style={{ marginRight: "1.25rem" }}
                onClick={async () => {
                  const r = await form.validateFields();

                  let status = false;

                  if ("Edit" == method) {
                    // status = await action.updSubmit({
                    //   state: state.formData,
                    //   form,
                    // });
                  } else if ("Create" == method) {
                    status = await action.addSubmit({
                      state: state,
                    });
                  }

                  status && close && close(true);
                }}
              >
                保存
              </Button>
              <Button
                type="primary"
                color="Yellow"
                onClick={() => action.reset({ dispatch: dispatch, form })}
              >
                重置
              </Button>
            </>
          ) :  <Button
          type="primary"
          color="Yellow"
          onClick={() => close(true)}
        >
          返回
        </Button>}
        </Layer.Foot>
      </Layer>
    </FormAntd>
  );
}
