import { message } from "antd";
// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../../../config";
import { IState } from "../store/transaction_store";

interface Init {
    state: IState;
    dispatch;
}

class TransactionAction {
    async init({ state, dispatch }: Init) {

        this.loadTransaction({ condition: state.condition, dispatch });

    }
    setType(type, { condition, dispatch }) {
        dispatch({ type: "SetCondition", payload: { ...condition, dateType:type } });

        this.loadTransaction({ condition: { ...condition, dateType: type }, dispatch });
    }
    setDate({ startTime, endTime }, { condition, dispatch }) {
        dispatch({ type: "SetCondition", payload: { ...condition, startTime, endTime } });

        this.loadTransaction({ condition: { ...condition, startTime, endTime }, dispatch });
    }
    async loadTransaction({ condition, dispatch }) {
        const { dateType, startTime, endTime } = condition;
// console.log(condition)
        const r = await axios({
            method: "POST",
            url: Tools.getApiAddress("statistics_transaction"),
            data: {
                "dateType": dateType,
                "startTime": startTime,
                "endTime": endTime
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        });
        if (r.data.statusCode == 200) {
            dispatch({ type: "SetItem", payload: r.data.data })
        }

    }
    async loadSaleMember (){
        // statistics_sale
    }
}
export const transactionAction = new TransactionAction();