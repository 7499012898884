import { DataTree } from "./DataTree";
import { TabKeys } from "./TabKeys";

export type _th = never;
/** td元素假类型 */
export type _td = never;
/** tr元素假类型 */
export type _tr = (_td | _th)[];

/**
 * 表格数据
 */
export class TableTool {
    /**
     * 获取树表格ths
     * @param tabKeys 
     */
    static getTreeTabThs(tabKeys: TabKeys[]): _tr[] {
        let _trs: _tr[] = [];
        if (tabKeys.length == 0) { return _trs; }
        let _deep = TabKeys.getTabKeysDeep(tabKeys);
        for (let i = 0; i < _deep; i++) {
            let _onDeepTabKeys = TabKeys.getDeepTabKey(tabKeys, i);
            _trs.push(
                _onDeepTabKeys.map((item, index) => {
                    let __deep = TabKeys.getTabKeyDeep(item, 0);
                    return <th
                        key={index}
                        rowSpan={__deep > 0 ? 1 : _deep - i}
                        //注意这里不是直接获取子节点数量，因该是没有子节点的子节点数量
                        colSpan={Math.max(1, TabKeys.getEndChilds(item).length)}>
                        {item.title}
                    </th> as _th;
                })
            );
        }
        //
        return _trs;
    }

    /**
     * 获取树表格tbs
     * @param dataTree 数据树
     * @param _op 选项
     */
    static getTreeTabTds<T>(dataTree: DataTree, _op: {
        /** 根据数据直接获取tds */
        getDataTds?: (data: T) => _td[];
        /** 添加底部tds */
        addBottomTds?: (dataTree: DataTree, datas: T[]) => _tr[];
    }, _addRowSpan?: (n: number) => void): _tr[] {
        let _trs: _tr[] = [];
        //获取该树的所有非树数据
        let _datas = DataTree.getNotTreeDatas(dataTree);
        let _rowSpan: number = _datas.length;
        //检测是否是一个树干
        if (!dataTree.ifTrunk) {
            //如果不是直接返回数据td
            _trs = dataTree.child.map((item) => {
                return _op.getDataTds ? _op.getDataTds(item) : [] as _td[];
            });
        } else {
            //递归获取子树
            dataTree.child.forEach((item) => {
                _trs.push(...TableTool.getTreeTabTds(item, _op, (n) => {
                    _rowSpan += n;
                }));
            });
            //添加底部td
            if (_op.addBottomTds) {
                let __trs: _tr[] = _op.addBottomTds(dataTree, _datas);
                if (__trs.length > 0) {
                    _trs.push(...__trs);
                    //递增跨度
                    _rowSpan += __trs.length;
                    _addRowSpan?.(__trs.length);
                }
            }
        }
        //每个树结构都在tr头部添加一个类别td
        _trs[0].unshift(<td className="dark" rowSpan={_rowSpan}>{dataTree.keyValue}</td> as _td);
        //
        return _trs;
    }
}