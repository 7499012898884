import { message } from "antd";
import { UserRoleLogic } from "../../../../logic/userRole_logic";
import { FormAttribute } from "../../store/userRole/form_store";
import { RoleLogic } from "../../../../logic/role_logic";
import { defualtPageSize } from "../../../../logic/common_logic";
type Init = {
    state;
    dispatch;
    form;
    editId: string;
    seeId: string;
}
interface InitBasic {
    dispatch;
    state;
}
interface InitEdit {
    state;
    dispatch;
    form;
    id;
}
interface ISetValue {
    attribute: FormAttribute;
    value: string | number | any[];
    dispatch;
}
var id = ''
export class FormAction {
    private RoleLgc = new RoleLogic();
    private gradeLgc = new UserRoleLogic();

    async init({ state, dispatch, seeId, editId, form }: Init) {

        if (editId || seeId) {
            await this.initEdit({ dispatch, state, id: editId || seeId, form })
        }
        id = editId || seeId
        // await this.initBasic({ dispatch, state});
    }
    async initBasic({ dispatch, state, roleId }) {
        if (roleId == '') {
            let parm = {
                name: '',
                pageIndex: 1,
                pageSize: defualtPageSize(),
                orderBy: '',
                sort: ''
            }
            roleId = await (await this.RoleLgc.querysList(parm)).data.data.dataSource[0].id
        }
        const r = await new UserRoleLogic().searchData(roleId);

        if (r.data.statusCode == 200) {
            const dataList = r.data.data;
            const list = dataList.map((v, i) => {
                v.key = i + 1;
                return v;
            })
            dispatch({ type: "LoadUser", payload: list })
        }
    }
    private async initEdit({ dispatch, id, form }: InitEdit) {

    }
    setValue({ attribute, value, dispatch }: ISetValue) {
        dispatch({ type: "Change_FormData", payload: { attribute, value } });
    }
}
export const formAction = new FormAction();