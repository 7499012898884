import { TabKeys } from "../../tool/TabKeys";

export const goodsTreeDataTablKey: (TabKeys & { subtotal?: string })[] = [
    {
        key: 'dianpu',
        title: '门店名称',
        // subtotal: '门店小计',
    },
    // {
    //     key: 'leibie',
    //     title: '商品类别',
    //     subtotal: '',
    // },
];

export const googsDataTablKey: TabKeys[] = [
    {
        key: 'goodsNo',
        title: "商品编码"
    },
    {
        key: 'goodsName',
        title: "商品名称"
    },
    {
        key: 'classesName',
        title: "商品分类"
    },
    {
        key:'unitName',
        title:'商品单位'
    },
    {
        key:'refundMoney',
        title:'退款金额'
    },
    {
        key:'refundNumber',
        title:'退货数量'
    }
   
];