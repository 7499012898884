import { message } from "antd";
import { DistributionLogic } from "../../../../logic/distribution_login";
import { FormAttribute } from "../../store/distribution/file_store";
type Init = {
    state;
    dispatch;
    form;
}
interface InitBasic {
    dispatch;
    state;
}
interface InitEdit {
    state;
    dispatch;
    form;
}
interface ISetValue {
    attribute: FormAttribute;
    value: string | number | any[] | any;
    dispatch;
}
var id = ''
export class FormAction {

    private steelyardLgc = new DistributionLogic();

    async init({ state, dispatch, form }: Init) {
        await this.initEdit({ dispatch, state, form })
    }
    private async initEdit({ dispatch, form }: InitEdit) {
        // 回填状态
        const item = await this.steelyardLgc.steelyardgetParam();
        if (item != null) {
            dispatch({ type: "SetForm", payload: item });
            // console.log(item)
            // 填充节点
            form.setFieldsValue({
                "distribution-oneProfit": item.oneProfit,
                "distribution-twoProfit": item.twoProfit,
                "distribution-premium": item.premium,
                "distribution-minWithdrawalsMoney": item.minWithdrawalsMoney,
                "distribution-backGroundPath": item.backGroundPath,
            });
        }
    }
    setValue({ attribute, value, dispatch }: ISetValue) {
        dispatch({ type: "Change_FormData", payload: { attribute, value } });
    }
    private paddingData(state) {
        let params: any = {};
        {
            const { id, oneProfit,twoProfit, premium, minWithdrawalsMoney, backGroundPath } = state;

            params = {
                id,
                oneProfit: oneProfit,
                twoProfit: twoProfit,
                premium: premium,
                minWithdrawalsMoney: minWithdrawalsMoney,
                backGroundPath: backGroundPath,
            }
        }
        return params;
    }
    async addSubmit({ state, dispatch, stateh, form }) {
        console.log(state)
        let param = {
            id: this.paddingData(state).id,
            oneProfit: this.paddingData(state).oneProfit,
            twoProfit: this.paddingData(state).twoProfit,
            premium: this.paddingData(state).premium,
            minWithdrawalsMoney: this.paddingData(state).minWithdrawalsMoney,
            backGroundPath: this.paddingData(state).backGroundPath,
        }
        console.log(44)
        const r = await this.steelyardLgc.steelyardgetAdd(param);
        if (r.statusCode == 200) {
            message.success("设置成功");
            await this.initEdit({ dispatch, state: stateh, form })
        } else {
            message.error(r.message);
        }
    }

}
export const formAction = new FormAction();