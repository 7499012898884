import { Market_logic } from "./Market_logic";

/**
 * 商城预售逻辑
 */
export class MarketingPresale_logic extends Market_logic {
    /** 添加商城预售 */
    static AddPresale(_op: any) {
        return this.axios(this.getApi('MarketingPresale.AddPresale'), 'POST', _op);
    }

    /** 修改商城预售 */
    static UpdatePresale(_op: any) {
        return this.axios(this.getApi('MarketingPresale.UpdatePresale'), 'POST', _op);
    }

    /** 分页 */
    static QueryPage(_op: any) {
        return this.axios(this.getApi('MarketingPresale.QueryPage'), 'POST', _op);
    }

    /** 获取单个 */
    static GetFirstMarkPresasle(_op: {
        ID: string;
    }) {
        return this.axios(this.getApi('MarketingPresale.GetFirstMarkPresasle'), 'GET',{}, _op);
    }

    /** 终止活动 */
    static StopMarkPresasle(_op: {
        ID: string;
    }) {
        return this.axios(this.getApi('MarketingPresale.StopMarkPresasle'), 'GET',{}, _op);
    }
}