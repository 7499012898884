// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../config";
import { effeative } from "./common_logic";

export class DossierLogic {
    async selData(userId : string) {
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("menber_look"),
            params: {
                userId 
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r.data.data;
    }
    async querysList(params) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("menber_query_page"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    async addData(params) {
        return effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("menber_add"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
    }
    async updData(params) {
        return effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("menber_query_update"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
    }
    /**充值支付密码
     * @param MemberID 会员id
     */
    async resetPwd(MemberID){
        return effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("member_reset_pwd"),
            params: {MemberID:MemberID},
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
    }
}