// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../config";
import { effeative } from "./common_logic";

export class ShiftChangeLogic {
    async query (params) {
        const r = effeative(await axios({
            method: 'POST',
            url: Tools.getApiAddress('shift_change_list'),
            data: params,
            headers:{
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            }
        }))
        return r
    }
    async selData (id: string,now: string) {
        const r = effeative(await axios({ 
            method: 'GET',
            url: Tools.getApiAddress('shift_change_sel'),
            params:{
                userid: id,
                now: now
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            }
        }))
        return r
    }
    async add(params) {
        const r = effeative( await axios({ 
            method: 'POST',
            url: Tools.getApiAddress('shift_change_add'),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get('token')
            }
        }))
        return r
    }
}