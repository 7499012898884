import { useBoolean } from "ahooks";
import { Button, Col, Form, Input, Row, Select } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect, useState } from "react";
import { formAction } from "../../action/steelyard/file_action";
import { FormStore } from "../../store/steelyard/file_store";
import { SteelyardLogic } from "../../../../logic/steelyard_logic";
import { Form as FormAntd } from "antd";
import { autoRem } from "../../../../global.style";
import { Progress } from "../../../../logic/common_logic";
import { AuthLogic } from "../../../../logic/auth_logic";
const { Option } = Select;
export function Steelyard(viewPower) {
  const [form] = FormAntd.useForm();
  const [] = useBoolean(false);
  const action = formAction;
  const { state, dispatch } = useContext(FormStore);
  const [modelList, setDataMode] = useState([]);
  const [portBaudList, setDataPortBaud] = useState([]);
  const [serialPort, setDataSerialPort] = useState([]);
  const { formData } = state;
  const [menuButton, setData] = useState([]);
  const [power, powerFunc] = useBoolean(false);
  useEffect(() => {
    (async function () {
      let r = await new AuthLogic().selButton(viewPower.viewPower);
      if (r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          r[i] = r[i].split("_")[r[i].split("_").length - 1];
        }
      }
      setData(r);
      if (r.indexOf("UPDATE") == -1) {
        powerFunc.setFalse();
      } else {
        powerFunc.setTrue();
      }
    })();

    return () => {};
  }, []);
  useEffect(() => {
    (async function () {
      await formAction.init({ state: state, dispatch, form });
      let r = await new SteelyardLogic().steelyardGet();
      setDataMode(r.model);
      setDataPortBaud(r.portBaud);
      setDataSerialPort(r.serialPort);

      Progress.done();
    })();

    return () => {};
  }, []);

  const labelInValueClick = async (v) => {
    let choose = modelList.filter((k) => {
      return (k.modelCode == v);
    });
    action.setValue({ dispatch, value: v, attribute: "ModelCode" });
    action.setValue({
      dispatch,
      value: choose[0].modelName,
      attribute: "ModelName",
    });
  };
  const initialValue = { key: "" };
  return (
    <>
      <div
        style={{
          margin: "" + autoRem(1.9375) + " 0 0 0",
          padding: "1rem",
          boxShadow:
            "0px " +
            autoRem(0.3125) +
            " " +
            autoRem(1.25) +
            " 0px rgba(209, 206, 206, 0.3)",
        }}
      >
        <FormAntd form={form}>
          <Row gutter={10}>
            <Col span={10}>
              <FormAntd.Item
                name="steelyard-type"
                label="电子秤型号"
                labelCol={{ span: 5 }}
              >
                <Select
                  value={formData.modelCode}
                  disabled={!power}
                  onChange={(v) => {
                    labelInValueClick(v);
                  }}
                >
                  {modelList &&
                    modelList.map((v, i) => (
                      <Option value={v.modelCode} key={v.modelCode}>
                        {v.modelName}
                      </Option>
                    ))}
                </Select>
              </FormAntd.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={10}>
              <FormAntd.Item
                name="steelyard-serialPort"
                label="电子秤串口"
                labelCol={{ span: 5 }}
              >
                <Select
                  disabled={!power}
                  value={formData.serialPort}
                  onChange={(v) =>
                    action.setValue({
                      dispatch,
                      value: v,
                      attribute: "SerialPort",
                    })
                  }
                >
                  {serialPort &&
                    serialPort.map((v, i) => (
                      <Option value={v} key={i}>
                        {v}
                      </Option>
                    ))}
                </Select>
              </FormAntd.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={10}>
              <FormAntd.Item
                name="steelyard-portBaud"
                label="串口波特率"
                labelCol={{ span: 5 }}
              >
                <Select
                  disabled={!power}
                  onChange={(v) =>
                    action.setValue({
                      dispatch,
                      value: v,
                      attribute: "PortBaud",
                    })
                  }
                >
                  {portBaudList &&
                    portBaudList.map((v, i) => (
                      <Option value={v} key={v + i}>
                        {v}
                      </Option>
                    ))}
                </Select>
              </FormAntd.Item>
            </Col>
          </Row>
        </FormAntd>
        <Button
          type="primary"
          color="Red"
          style={{ marginLeft: "1.25rem" }}
          onClick={() => {
            if (!power) {
              return false;
            }
            action.addSubmit({
              state: state.formData,
              stateh: state,
              dispatch,
              form,
            });
          }}
        >
          保存设置
        </Button>
      </div>
    </>
  );
}
