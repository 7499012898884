import { useBoolean, useRequest } from "ahooks";
import { Input, Modal, Select, Tag ,Table} from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext } from "react";
import { Search } from "../search";
import { DataTable } from "../table";
import {
  DataTableStore,
  DataTableStoreProvider,
} from "../table/store/data_table_store";
import { ChooseSeckillStore, ChooseStoreProvider } from "./store/choose_store";
import styles from "./style/index.module.scss";
import { chooseSeckillAction } from "./action/choose_action";
import { defualtPageSize } from "../../logic/common_logic";

function Seckill(params: IChooseSeckill) {
  const { visibleModal, close, type } = params;
  const { Option } = Select
   const action = chooseSeckillAction;

  const { state, dispatch } = useContext(ChooseSeckillStore);
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);

  const handleOk = () => {
       close && close(true, tableState.selectList);
  };

  const handleCancel = () => {
    close && close(false, null);
  };

  const columns: ColumnsType = [
  
    {
      title: "活动名称",
      dataIndex: "snappedName",
      key: "2",
      align: "center",
      width: 140,
    },
    {
      title: "开始时间",
      dataIndex: "beginTime",
      key: "3",
      align: "center",
    },
    {
      title: "结束时间",
      dataIndex: "endTime",
      key: "4",
      align: "center",
    },
    // {
    //     title: '参与门店',
    //     dataIndex: 'money',
    //     key: '5',
    //     align: "center"
    // },
    {
      title: "状态",
      // dataIndex: 'status',
      key: "6",
      align: "center",
      render: (v) => (
        <>
          <Tag
            color={
              v.dataState == 0
                ? "geekblue"
                : v.dataState == 1
                ? "success"
                : "volcano"
            }
          >
            {v.dataStateString}
          </Tag>
        </>
      ),
    },
    {
      title: "创建人",
      dataIndex: "addUID",
      key: "7",
      align: "center",
    },
    {
      title: "创建时间",
      dataIndex: "addTime",
      key: "8",
      align: "center",
    },
  ];

  const onSearch = async (pageIndex) => {
    await action.loadPage({
      pageIndex: pageIndex,
      pageSize: defualtPageSize(),
      dispatch: tableDispatch,
      search: state.search,
    });
  };

  let tableRowSelection: any = {};
  if (type == "radio") {
    tableRowSelection = {
      rowSelection: {
        type: "radio",
        getCheckboxProps: (record) => ({
          disabled: record.dataState !=1, // Column configuration not to be checked
        }),
      },
    };
  }
  const checkSelectable = (record) => {
    let flag = false;
    if (record.status == 2) {
      flag = true;
    }
    return flag;
  };
 
  return (
    <Modal
      title="选择秒杀活动"
      visible={visibleModal}
      onOk={handleOk}
      onCancel={handleCancel}
      width={1000}
      cancelText="取消"
      okText="确定"
    >
      <div className={styles.root}>
        <Search>
          <Input
            allowClear
            placeholder="请输入活动名称"
            maxLength={20}
            onChange={(e) =>
              action.setCondition({
                dispatch,
                key: "seacherKey",
                value: e.target.value,
              })
            }
          />
          <input type="button" value="快速查找" onClick={() => onSearch(1)} />
        </Search>

        <div style={{ marginTop: 10 }}>
          <DataTable
            columns={columns}
            action={action}
            // rowKey={(record) => record.id}
            rowSelection={{
              getCheckboxProps: (record) => ({
                disabled: record.dataState !=1, // Column configuration not to be checked
              }),
            }}
            {...tableRowSelection}
            onLoad={() => onSearch(1)}
            onChange={(pageIndex) => {
              onSearch(pageIndex);
            }}
            selectColumn={true}
          />
        </div>
        {/* {tableState.selectList.length>0 && <div>
          <Goods  id={tableState.selectList[0].id} dispatch={dispatch}/>
        </div>} */}
      </div>
    </Modal>
  );
}

interface IChooseSeckill {
  visibleModal: boolean;
  type?: string;
  close?: (status, r) => void;
  getSeckillType?: number;
}
export function ChooseSeckill(params: IChooseSeckill) {
  return (
    <ChooseStoreProvider>
      <DataTableStoreProvider>
        <Seckill {...params} />
      </DataTableStoreProvider>
    </ChooseStoreProvider>
  );
}

function Goods({id,dispatch}){
  const { state } = useContext(ChooseSeckillStore);
  const  action = chooseSeckillAction;
 useRequest(async ()=>  await chooseSeckillAction.getGoodsList({id,dispatch}))
//  console.log(state.tableList)
  const columnsGoods: ColumnsType = [
  
    {
      title: "商品编码",
      dataIndex: "goodsNo",
      key: "2",
      align: "center",
      width: 100,
    },
    {
      title: "商品名称",
      dataIndex: "goodsName",
      key: "3",
      align: "center",
      width: 100,
    },
    {
      title: "商品分类",
      dataIndex: "classesName",
      key: "4",
      align: "center",
      width: 100,
    },

    {
      title: "单位",
      // dataIndex: 'goodsUnitName',
      key: "8",
      align: "center",
      width: 120,
      render: (record) => (
        <span>
          {record.unitContent}
          {record.unitName}
        </span>
      ),
    },
    {
      title: "商城价",
      dataIndex: "storePrice",
      key: "8",
      align: "center",
      width: 120,
    },
    {
      title: "活动价",
      dataIndex: "snappedPrice",
      key: "8",
      align: "center",
      width: 120,
    },
    {
      title: "秒杀库存",
      dataIndex: "snappedCount",
      key: "8",
      align: "center",
      width: 120,
    },
    {
      title: "单人限购",
      dataIndex: "singleCount",
      key: "8",
      align: "center",
      width: 120,
    },
  ];
  const paginationPropsGoods = {
    total: state.tableList.length,
    showTotal: (total) => `共 ${total} 条`,
    showSizeChanger: false,
    pageSize: 5,
  };
 const onSelectChange  = (selectedRowKeys ) =>{
    let item = state.tableList.filter(item=>item.sepID == selectedRowKeys[0])
    if(item ){
      dispatch({type:'GoodsItem',payload: item})
    }
  }
 return    <div style={{ marginTop: 10 }}>
 <Table
            columns={columnsGoods}
            rowKey={(record:any) => record.sepID}
            dataSource={state.tableList}
             rowSelection={{
                type:'radio',
                onChange: onSelectChange 
             }}
            pagination={paginationPropsGoods}
            scroll={{ y: 300 }}
          />
</div>
}