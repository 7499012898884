import { createContext, useContext, useReducer } from "react";

export const AlarmStore = createContext<IContextProps>(null);

interface IContextProps {
  state: IState;
  dispatch: ({ type }: Dispatch) => void;
}

type Dispatch = { type; payload };

class Search {
  keywords: string;
  classesId: string[];
  statusId: number;
  shopName: string;
  shopId: string;
}

interface IState {
  search: Search;
  classesList: any[];
}

const initialArgs = {
  classesList: [],
  search: new Search(),
};

function reducer(state: IState, { type, payload }: Dispatch) {
  switch (type) {
    case "LoadClasses":
      return {
        ...state,
        classesList: payload,
      };
    case "SetSearch":
      return {
        ...state,
        search: {
          ...state.search,
          ...payload,
        },
      };
    default:
      return state;
  }
}

export function AlarmStoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialArgs);

  const v = { state, dispatch };
  return <AlarmStore.Provider value={v}>{props.children}</AlarmStore.Provider>;
}
