import { Cascader, Col, Form, Form as FormAntd, Input, message, Radio, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
// import axios from "axios";
import Cookies from "js-cookie";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Selection } from "../../../component/GDMap/selection";
import { RadioStyle } from "../../../component/radio-style";
import { Tools, axios } from "../../../config";
import { autoRem } from "../../../global.style";
import { zip } from "../../../logic/common_logic";
import { formAction } from "../action/form_action";
import { FormStore } from "../store/form_store";
import styles from "../style/form_basic.module.scss";
import camera_png from "../../../assets/images/goods/ico_dotted_line_camera.png";
import { ButtonPanel } from "../../../component/button-panel/button_panel";
import { Button } from "../../../component/button";
import { ShopParamSet } from './setting/file'
import { useBoolean } from "ahooks";
import { FormStoreProvider } from "../store/setting/form_store";
interface IStatus {
  value;
  swtichValue;
  disabled?: boolean;
  isdefault?: boolean;
}

function ShopType({ value, swtichValue, disabled, isdefault }: IStatus) {
  const [] = useState(1);
  const { dispatch } = useContext(FormStore);

  const onChange = (e) => {
    formAction.setValue({ dispatch, value: e.target.value, attribute: "Type" });
  };

  return (
    <RadioStyle>
      <Radio.Group onChange={onChange} value={swtichValue}>
        <Radio value={1}>加盟</Radio>
        {/* <Radio disabled={disabled} value={2}>直营</Radio> */}
      </Radio.Group>
    </RadioStyle>
  );
}

function Status({ value, swtichValue, disabled }: IStatus) {
  const { dispatch } = useContext(FormStore);

  const onChange = (e) => {
    // setValue(e.target.value);

    formAction.setValue({
      dispatch,
      value: e.target.value,
      attribute: "Status",
    });
  };

  return (
    <RadioStyle>
      <Radio.Group onChange={onChange} value={swtichValue}>
        <Radio disabled={disabled} value={1}>
          启用
        </Radio>
        {/* <Radio disabled={disabled} value={2}>停用</Radio> */}
      </Radio.Group>
    </RadioStyle>
  );
}

// const cityList = [
//     {
//         value: '1',
//         label: '四川省',
//         children: [
//             {
//                 value: '2',
//                 label: '成都市',
//                 children: [
//                     {
//                         value: '3',
//                         label: '武侯区'
//                     },
//                 ],
//             },
//         ],
//     },
// ]

export function FormBasic({ disabled, isdefault }) {
  const { state, dispatch: formDispatch } = useContext(FormStore);
  const [cityList, setCityList] = useState([]);
  const [showParam, showParamFunc] = useBoolean(false)
  const action = formAction;
  const { formData } = state;
  const closeParamSet = () => {
    showParamFunc.setFalse()
  }
  useEffect(() => {
    (async function () {
      const r = await axios({
        method: "GET",
        url: "./lib/area.json",
        responseType: "json",
      });

      setCityList(r.data);
    })();

    return () => {};
  }, []);
  const inputRef = useRef(null)
   /** 获取图片 */
   const onChange = async (e) => {
   
    let file = e.target.files[0];

    // 压缩
    file = await zip(file);

    const formData = new FormData();
    formData.append("file", file);

    const r = await axios({
      method: "POST",
      url: Tools.getApiAddress("miniAdv.uploadImage"),
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data;charset=UTF-8",
        SYSTOKEN: Cookies.get("token"),'ClientSource':'web',
      },
    });

    if (r.data.statusCode == 200) {
      const imgUrl = r.data.data;
      action.setValue({
        dispatch: formDispatch,
        value: imgUrl,
        attribute: "Logo",
      })
      // formAction.setValue({
      //   dispatch,
      //   attribute: "imgUrl",
      //   value: imgUrl,
      // });

      message.success("上传成功");
    } else {
      message.error(r.data.returnMessage);
    }

    inputRef.current.value = null;
  };
  return (
    <>
      <div style={{ margin: "1.9375rem 24px 0 0" }}>
        <Row gutter={24}>
        
          <Col span={12}>
            <FormAntd.Item
              name="shop-name"
              label="门店名称"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: true,
                  message: "请输入门店名称",
                },
              ]}
            >
              <Input
                disabled={disabled}
                maxLength={50}
                autoComplete="off"
                onChange={(v) =>
                  action.setValue({
                    dispatch: formDispatch,
                    value: v.target.value,
                    attribute: "ShopName",
                  })
                }
              />
            </FormAntd.Item>
          </Col>
          <Col span={12}>
            <FormAntd.Item
              name="shop-phone"
              label="门店电话"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: true,
                  message: "请输入门店电话",
                },
                {
                  pattern: /^[1][34578][0-9]{9}$|^0\d{2,3}-?\d{7,8}$/,
                  message: "格式不正确",
                },
              ]}
            >
              <Input
                disabled={disabled}
                maxLength={20}
                autoComplete="off"
                onChange={(v) =>
                  action.setValue({
                    dispatch: formDispatch,
                    value: v.target.value,
                    attribute: "ShopPhone",
                  })
                }
              />
            </FormAntd.Item>
          </Col>
        </Row>
        <Row gutter={24}>
        
        <Col span={12}>
          <FormAntd.Item
            name="contact-people"
            label="联系人"
            labelCol={{ span: 8 }}
            rules={[
              {
                required: true,
                message: "请输入联系人",
              },
            ]}
          >
            <Input
              disabled={disabled}
              maxLength={20}
              autoComplete="off"
              onChange={(v) =>
                action.setValue({
                  dispatch: formDispatch,
                  value: v.target.value,
                  attribute: "ContactPeople",
                })
              }
            />
          </FormAntd.Item>
        </Col>
        <Col span={12}>
          <FormAntd.Item
            name="contact-phone"
            label="联系电话"
            labelCol={{ span: 8 }}
            rules={[
              {
                required: true,
                message: "请输入联系电话",
              },
              {
                pattern: /^[1][34578][0-9]{9}$|^0\d{2,3}-?\d{7,8}$/,
                message: "格式不正确",
              },
            ]}
          >
            <Input
              disabled={disabled}
              maxLength={20}
              autoComplete="off"
              onChange={(v) =>
                action.setValue({
                  dispatch: formDispatch,
                  value: v.target.value,
                  attribute: "ContactPhone",
                })
              }
            />
          </FormAntd.Item>
        </Col>
      </Row>
        <Row gutter={24}>
        <Col span={12}>
            <FormAntd.Item
              name="shop-code"
              label="门店编码"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: false,
                  message: "请输入门店编码",
                },
              ]}
            >
              <Input
                disabled={disabled}
                maxLength={20}
                autoComplete="off"
                onChange={(v) =>
                  action.setValue({
                    dispatch: formDispatch,
                    value: v.target.value,
                    attribute: "ShopCode",
                  })
                }
              />
            </FormAntd.Item>
          </Col>
          <Col span={12}>
            <FormAntd.Item
              name="memory-code"
              label="助记码"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: false,
                  message: "请输入助记码",
                },
              ]}
            >
              <Input
                disabled={disabled}
                maxLength={50}
                autoComplete="off"
                onChange={(v) =>
                  action.setValue({
                    dispatch: formDispatch,
                    value: v.target.value,
                    attribute: "MemoryCode",
                  })
                }
              />
            </FormAntd.Item>
          </Col>

        
        </Row>
        <Row gutter={24}>
          {/* <Col span={12} >
                        <FormAntd.Item
                            name="shop-type"
                            label="门店类型"
                            labelCol={{ span: 8 }}
                            className="custom-radio"
                        >
                            <ShopType value={1} swtichValue={formData.type} isdefault={isdefault} />
                        </FormAntd.Item>
                    </Col> */}
          <Col span={12}>
            <FormAntd.Item
              name="status"
              label="状态"
              labelCol={{ span: 8 }}
              className="custom-radio"
            >
              <Status value={1} swtichValue={formData.status} />
            </FormAntd.Item>
          </Col>

          <Col span={12}>
            <FormAntd.Item
              name="business-area"
              label="营业面积"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: false,
                  message: "请输入营业面积",
                },
                {
                  pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
                  message: "营业面积应该大于0",
                },
                // {
                //     pattern: /^((0{1}\.\d{1,2})|([1-9]\d*\.{1}\d{1,2})|([1-9]+\d*))$/,
                //     message: '营业面积应该大于0'
                // }
              ]}
            >
              <Input
                disabled={disabled}
                maxLength={20}
                autoComplete="off"
                onChange={(v) =>
                  action.setValue({
                    dispatch: formDispatch,
                    value: v.target.value,
                    attribute: "BusinessArea",
                  })
                }
              />
            </FormAntd.Item>
          </Col>
        </Row>
       
        <Row gutter={24}>
        <Col span={12}>
            <FormAntd.Item
              name="shop-people-count"
              label="店员人数"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: false,
                  message: "请输入店员人数",
                },
                {
                  pattern: /^[1-9]\d*$/,
                  message: "请输入大于0的整数",
                },
              ]}
            >
              <Input
                disabled={disabled}
                maxLength={20}
                autoComplete="off"
                onChange={(v) =>
                  action.setValue({
                    dispatch: formDispatch,
                    value: v.target.value,
                    attribute: "PeopleCount",
                  })
                }
              />
            </FormAntd.Item>
          </Col>
          {formData.id &&<Col span={12}>
            <FormAntd.Item
              label="门店参数"
              labelCol={{ span: 8 }}
            >
               <ButtonPanel>
                <Button
                  onClick={() => {
                  showParamFunc.setTrue()
                  }}
                >
                  门店参数设置
                </Button>
              </ButtonPanel>
            </FormAntd.Item>
          </Col>}
        </Row>
        {/* <Row gutter={24}>
                    <Col span={12} >
                        <FormAntd.Item
                            name="area"
                            label="所在地区"
                            labelCol={{ span: 8 }}
                            rules={[
                                {
                                    required: true,
                                    message: '请选择所在地区',
                                },
                            ]}
                        >
                            <Cascader defaultValue={formData.city} value={formData.city} options={cityList} placeholder="请选择" onChange={(v) => action.setValue({ dispatch: formDispatch, value: v, attribute: "City" })} />
                        </FormAntd.Item>
                    </Col>
                    <Col span={12} >
                        <FormAntd.Item
                            name="address"
                            label="详细地址"
                            labelCol={{ span: 8 }}
                            rules={[
                                {
                                    required: true,
                                    message: '请输入详细地址',
                                },
                            ]}
                        >
                            <Input autoComplete="off" onChange={(v) => action.setValue({ dispatch: formDispatch, value: v.target.value, attribute: "Address" })} />
                        </FormAntd.Item>
                    </Col>
                </Row> */}

<Row gutter={24}>
          <Col span={24}>
          <FormAntd.Item              
              name="shopLogo"
              label="门店图片"
              labelCol={{ span: 4 }}
              rules={[
                {
                  required: true,
                  message: "请选择门店图片",
                  validator: (_, __, callBack: (error?: string) => void) => {
                
                    if (
                      state.formData.shopLogo == ''
                    ) {
                     callBack('请选择门店图片')
                     return;
                    }
                    callBack();
                  },
                },
              ]}
              >
            <div className={styles.img}>
                  <img
                    style={{
                      cursor: false ? "not-allowed" : "pointer",
                    }}
                    src={state.formData.shopLogo?Tools.getImageAddress(state.formData.shopLogo) :
                      camera_png
                    }
                    alt=""
                    onClick={() => {
                      if (disabled) {
                        return;
                      }
                      inputRef.current.click();
                    }}
                  />
                  <span>点击选择图片</span>
                </div>
                <input
                  style={{ display: "none" }}
                  ref={inputRef}
                  onChange={onChange}
                  type="file"
                  accept="image/*"
                />
                <div style={{color:'#ff4d4f',marginLeft:'10px'}}>建议尺寸200x200</div>
                </FormAntd.Item>
          </Col>
        </Row>    
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name="remarks" label="备 注" labelCol={{ span: 4 }}>
              <TextArea
                maxLength={200}
                disabled={disabled}
                rows={2}
                onChange={(v) =>
                  action.setValue({
                    dispatch: formDispatch,
                    value: v.target.value,
                    attribute: "Remark",
                  })
                }
              ></TextArea>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <div className={styles.path_select}>
              <span>地址选择：</span>
              {state.ifInit && (
                <Selection
                  disabled={disabled}
                  className={styles.selection}
                  address={{
                    provincialCapital: formData.city?.[0],
                    city: formData.city?.[1],
                    district: formData.city?.[0],
                    detailedAddress: formData.address,
                    longitude: formData.longitude,
                    latitude: formData.latitude,
                  }}
                  setAddress={(addres) => {
                    //设置地区
                    action.setValue({
                      dispatch: formDispatch,
                      value: [
                        addres.provincialCapital,
                        addres.city,
                        addres.district,
                      ],
                      attribute: "City",
                    });
                    //设置详细地址
                    action.setValue({
                      dispatch: formDispatch,
                      value: addres.detailedAddress,
                      attribute: "Address",
                    });
                    //设置经纬度
                    action.setValue({
                      dispatch: formDispatch,
                      value: addres.longitude,
                      attribute: "Longitude",
                    });
                    action.setValue({
                      dispatch: formDispatch,
                      value: addres.latitude,
                      attribute: "Latitude",
                    });
                  }}
                />
              )}
            </div>
          </Col>
        </Row>
      </div>
     <FormStoreProvider> <ShopParamSet disabled={disabled} visibleModal={showParam} close={closeParamSet} id={formData.id}/></FormStoreProvider>
    </>
  );
}
