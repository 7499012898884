import { useBoolean, useRequest } from "ahooks";
import { Input, Modal, Select, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext } from "react";
import { Search } from "../search";
import { DataTable } from "../table";
import {
  DataTableStore,
  DataTableStoreProvider,
} from "../table/store/data_table_store";
import { ChooseMemberStore, ChooseStoreProvider } from "./store/choose_store";
import styles from "./style/index.module.scss";
import { Select as SelectAntd } from "antd";
import { chooseCouponAction } from "./action/choose_action";
import { defualtPageSize } from "../../logic/common_logic";
let classId = "";

function Member(params: IChooseMember) {
  const { visibleModal, close,type } = params;
  const { Option } = Select,
    action = chooseCouponAction;

  const { state, dispatch } = useContext(ChooseMemberStore);
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);

  const handleOk = () => {
    close && close(true, tableState.selectList);
  };

  const handleCancel = () => {
    close && close(false, null);
  };
  let tableRowSelection: any = {};
  if (type == "radio") {
    tableRowSelection = {
      rowSelection: {
        type: "radio",
        getCheckboxProps: (record) => ({
          disabled: params.filter && (params.filter.balance?record.balanceMoney===0:false || params.filter.banId?(params.filter.banId === record): false), // Column configuration not to be checked
        }),
      },
    };
  }else{
    tableRowSelection = {
    rowSelection:{
      type: "checkbox",
      getCheckboxProps: (record) => ({
        disabled: record.status != "正常",
      }),
    }}
  }
  const columns: ColumnsType = [
    {
      title: "会员姓名",
      dataIndex: "memberName",
      key: "2",
      align: "center",
    },
    {
      title: "手机号码",
      dataIndex: "phone",
      key: "3",
      align: "center",
    },
    {
      title: "等级",
      dataIndex: "gradeName",
      key: "4",
      align: "center",
    },

    {
      title: "账户余额",
      dataIndex: "balanceMoney",
      key: "5",
      align: "center",
    },
    {
      title: "账户积分",
      dataIndex: "accountIntegral",
      key: "6",
      align: "center",
    },
    {
      title: "会员来源",
      key: "7",
      align: "center",
      render: (v) => <Tag color="default">{v.source}</Tag>,
    },

    {
      title: "会员标签",
      dataIndex: "label",
      key: "8",
      width: 150,
      align: "center",
    },
    {
      title: "会员状态",
      key: "9",
      align: "center",
      dataIndex: "status",
      render: (v) =>
        v == "挂失" ? (
          <Tag color="warning">挂失</Tag>
        ) : v == "停用" ? (
          <Tag color="error">停用</Tag>
        ) : (
          <Tag color="success">正常</Tag>
        ),
    },
    {
      title: "注册时间",
      dataIndex: "addDateTime",
      key: "10",
      align: "center",
      width: 150,
    },
  ];

  // useRequest(() => action.init({ dispatch }));
  const onSearch = async (pageIndex) => {
    await action.loadPage({
      pageIndex: pageIndex,
      pageSize: defualtPageSize(),
      dispatch: tableDispatch,
      search: state.search,
    });
  };
  return (
    <Modal
      title="选择会员"
      visible={visibleModal}
      onOk={handleOk}
      onCancel={handleCancel}
      width={1000}
      cancelText="取消"
      okText="确定"
    >
      <div className={styles.root}>
        <Search>
          <Input
            maxLength={20}
            allowClear
            placeholder="请输入会员姓名/手机号码"
            onChange={(e) =>
              action.setCondition("Keywords", e.target.value, dispatch)
            }
          />
          <input type="button" value="快速查找" onClick={() => onSearch(1)} />
        </Search>

        <div style={{ marginTop: 10 }}>
          <DataTable
            columns={columns}
            action={action}
            saveKeys={type === 'radio'?false:true}
           {...tableRowSelection}
            onLoad={() => {
              onSearch(1);
            }}
            onChange={(pageIndex) => {
              onSearch(pageIndex);
            }}
            selectColumn={true}
            scroll={{ y: 300 }}
          />
        </div>
      </div>
    </Modal>
  );
}

interface IChooseMember {
  visibleModal: boolean;
  close?: (status, r) => void;
  type?:string;
  filter?:{balance: boolean, banId?: string}
}
export function ChooseMember(params: IChooseMember) {
  return (
    <ChooseStoreProvider>
      <DataTableStoreProvider>
        <Member {...params} />
      </DataTableStoreProvider>
    </ChooseStoreProvider>
  );
}
