import { message } from "antd";
// import axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";
import { DataTableAction } from "../../../../component/table/action/data_table_action";
import { Tools, axios } from "../../../../config";
import { ShiftChangeLogic } from "../../../../logic/shift_change_logic";

export class FileAction extends DataTableAction {
    async delRow(id) {}
    async delSelectList(){}
    async loadPage({ dispatch, pageIndex, pageSize, search }) {
        this.setLoading({dispatch, loading: false})
        const { keywords, userID, beginTime, endTime,statusId, areaId } = search;
        const params = {
            pageIndex,
            pageSize,
            keyWords: keywords,
            userID: userID,
            beginTime: beginTime || moment(new Date()).subtract(1,'months').format('YYYY-MM-DD hh:mm:ss'),
            endTime: endTime || moment().format('YYYY-MM-DD hh:mm:ss'),
            orderBy: "",
            sort: ""
        };
      

        // 档案列表
        const r = await new ShiftChangeLogic().query(params);

        if (r.data.statusCode == 200) {
            const dataList = r.data.data.dataSource;

            const list = dataList.map((v, i) => {
                v.key = i + 1;
                return v;
            })

            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: list, pageSize, loading: false });
        }else{
            this.setLoading({dispatch,loading: false})
        }

    }
    setCondition(method: "Keywords" | "beginTime" | "addTime" | "userID", value, dispatch) {
        if ("Keywords" == method) {
            dispatch({ type: "SetSearch", payload: { keywords: value } });
        }
        else if ("beginTime" == method) {
            dispatch({ type: "SetSearch", payload: { beginTime: value } });
        }
        else if ("addTime" == method) {
            dispatch({ type: "SetSearch", payload: { addTime: value } });
        }
        else if ("userID" == method) {
            dispatch({ type: "SetSearch", payload: { userID: value } });
        }
    }
}
export const fileAction = new FileAction();