import { message } from "antd";
import { GoodsEntity } from "../../../../entity/goods_entity";
import { CheckLogic } from "../../../../logic/check_logic";
import { defualtPageSize } from "../../../../logic/common_logic";
import { GoodsLogic, ISelList } from "../../../../logic/goods_logic";
import { UnitLogic } from "../../../../logic/unit_logic";
import { UserLogic } from "../../../../logic/user_logic";
import Cookies from 'js-cookie'
import { DistributionRecordsLogic } from "../../../../logic/distributionRecords_login";
type Init = {
    state;
    dispatch;
    form;
    id: string;
    method: string;
}
interface InitBasic {
    dispatch;
    search;
}
interface InitEdit {
    state;
    dispatch;
    form;
    id;
}

class InventoryGoodsFormAction {
 

    async aduit(params,state){
        const param = {
            logID: params.id,
            ispasses:params.Ispasses,
            remark:state.remark||""
        }
        const r = await new DistributionRecordsLogic().auditData(param)
        if(r.data.statusCode == 200){
            message.success('审核成功')
            // !params.Ispasses&&message.success('拒绝成功')
            return true
        }else{
            message.error(r.data.returnMessage || '审核失败')
            return false
        }
    }
}
export const inventoryGoodsFormAction = new InventoryGoodsFormAction();