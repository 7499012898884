import React, { useEffect } from "react";
import styles from "./index.module.scss";
import { Layout } from "../../component/layout";
import { TransactionStoreProvider } from "./store/transaction_store";
import { Transaction } from "./view/transaction";
import { Ranking } from "./view/ranking";
import { Analysis } from "./view/analysis";
import { MemberSale } from "./view/memberSale";
import { autoRem } from "../../global.style";
const Survey = () => {
  let height = document.body.clientHeight - 188;
  useEffect(() => {
    return () => {};
  });
  return (
    // <Layout>
    <TransactionStoreProvider>
      <div className={styles.root_c} style={{ height: `${height}px` }}>
        <div className={styles.left_c}>
          <Transaction />

          <div className={styles.bottom}>
            <div className={styles.bottom_left}>
              <MemberSale />
            </div>
            <div className={styles.bottom_right}>
              <Analysis />
              <Ranking />
            </div>
          </div>
        </div>
      </div>
    </TransactionStoreProvider>
    // </Layout>
  );
};
export default Survey;
