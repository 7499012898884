import message from "antd/lib/message"
// import axios from "axios"
import Cookies from "js-cookie"
import { DataTableAction } from "../../../../component/table/action/data_table_action"
import { Tools, axios } from "../../../../config"
import { SeckillLogic } from "../../../../logic/seckill_logic"
import { ShopLogic } from "../../../../logic/shop_logic"
import { UserLogic } from "../../../../logic/user_logic"
import { CouponLogic } from "../../../../logic/coupon_logic"
import { saveAs } from 'file-saver';

class WeckillAction extends DataTableAction {

    async delRow(id) {
        const r = await new CouponLogic().delData(id);

        if (r.data.statusCode == 200) {
            message.success("删除成功")
        }else{
            message.error("删除失败")
        }
    }
    async changeStatus(params){
        const r = await new CouponLogic().ChangeStatus(params)
        if (r.data.statusCode == 200) {
            message.success("终止成功")
        }else{
            message.error("终止失败")
        }
    }
    delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {
        selectList.map(async ({ id }) => {
            const r = await axios({
                method: "GET",
                url: Tools.getApiAddress("shop_delete"),
                params: {
                    shopId: id
                },
                headers: {
                    SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
                },
            })

            if (r.data.statusCode == 200) {
                message.success("删除成功")
            }
        })
    }
    async loadPage({ dispatch, pageIndex, pageSize, search }) {
        this.setLoading({dispatch,loading: true})
        const { couponName, couponType, status, } = search;
        const params: any = {
            "pageIndex": pageIndex,
            "pageSize": pageSize,
            "couponName":couponName,
            "couponType":couponType==-1||couponType==undefined?'':couponType,
            "status":status==1?true:status==2?false:'',
            "orderBy":'',
            "sort":'',
        }
        // 档案列表
        const r = await new CouponLogic().selListByRecord(params);

        if (r.data.statusCode == 200) {
            const dataList = r.data.data.dataSource;

            const list = dataList.map((v, i) => {
                v.key = i + 1;
                v.validEndDate = v.validEndDate!=null?v.validEndDate.slice(0,10):''
                v.validStartDate = v.validStartDate!=null?v.validStartDate.slice(0,10):''
                return v;
            })

            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: list, pageSize });
        }
        this.setLoading({dispatch,loading: false})

    }
    setCondition({ dispatch, key, value }) {
        const params = {
        }
   params[key] = value;
        dispatch({ type: "SetSearch", payload: params })
    }
    // 导出
    // async exportList({ search }) {
    //     const { keywords, statusId, shop, goods } = search;

    //     const params: any = {
    //         // "source": 2,
    //         "inventoryType": 2,
    //         // "inventoryStatus": 2,
    //         // "reason": 1,
    //         // "pageIndex": pageIndex,
    //         // "pageSize": pageSize
    //         // "orderBy": "",
    //         // "sort": ""
    //     }
    //     if (keywords) {
    //         params.keyWords = keywords;
    //     }
    //     if (statusId) {
    //         params.inventoryStatus = statusId;
    //     }
    //     if (statusId == -1) {
    //         delete params.inventoryStatus;
    //     }
    //     if (shop && shop.length) {
    //         params.shopID = shop[0].id;
    //     }
    //     if (goods && goods.length) {
    //         params.goodsID = goods.map(v => v.id);
    //     }

    //     // 档案列表
    //     const r = await new SeckillLogic().selListByExportRecord(params);

    //     if (r.status == 200) {
    //         let enc = new TextDecoder('utf-8')
    //         let data = enc.decode(new Uint8Array(r.data))
    //         if (data.indexOf('statusCode') != -1) {
    //             let datas = JSON.parse(data)
    //             if (datas.statusCode == 200) {
    //                 let blob = new Blob([r.data], { type: 'application/vnd.ms-excel' })
    //                 saveAs(blob, "入库.xlsx");
    //             } else {
    //                 message.error(datas.data)
    //             }
    //         } else {
    //             let blob = new Blob([r.data], { type: 'application/vnd.ms-excel' })
    //             saveAs(blob, "入库.xlsx");
    //         }
    //     }

    // }
}
export const seckillAction = new WeckillAction();