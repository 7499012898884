// import axios from "axios";
// import Cookies from "js-cookie";
// import { Tools } from "../../../config";
import { effeative } from "../../../logic/common_logic";




import message from "antd/lib/message"
// import axios from "axios"
import Cookies from "js-cookie"
import { DataTableAction } from "../../../component/table/action/data_table_action"
import { Tools, axios } from "../../../config"
// import {TopicLogic} from '../com/topic_logic'
interface ISelListByRecord {
   page:number,
    size:number
   
}
export class TopicLogic{
    async selListByRecord(params: ISelListByRecord) {
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("smallProgram.QueryPage"),
            params: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    async  delData(TempID:string) {
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("smallProgram.Delete"),
            params: {
                TempID:TempID
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            }
        }))
        return r;
    }
    async setIndex(TempID:string){
        const r = effeative(  await axios({
            method:'GET',
            url:Tools.getApiAddress('smallProgram.SetIndex'),
            params: {
                TempID: TempID
            },
            headers:{
                SYSTOKEN: Cookies.get('token')
            }
        }))
        return r;
    }

    /**验证商品 */
    async checkGoodsStatus(param) {
        const r = effeative(await axios({
            method: 'POST',
            url: Tools.getApiAddress('smallProgram.CheckGoodsStatus'),
            data:param,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))

        return r;
    }
    /**验证秒杀 */
    async checkSeckillStatus( param ) {
        const r = effeative(await axios({
            method: 'POST',
            url: Tools.getApiAddress('smallProgram.CheckSeckillStatus'),
            data: param,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r
    }
    async checkPresaleStauts( param ) {
        const r = effeative(await axios({
            method: 'POST',
            url: Tools.getApiAddress('smallProgram.CheckPresaleStatus'),
            data: param,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            }
        }))
        return r
    }
   
}


export  class TopicAction extends DataTableAction {

    async delRow(id) {
        const r = await new TopicLogic().delData(id);

        if (r.data.statusCode == 200) {
            message.success("删除成功")
        }
    }
    async delSelectList(list:any){
        let flag = false
        list.map( async (topic)=>{
           const r =  await axios({
                method: "GET",
                url: Tools.getApiAddress("smallProgram.Delete"),
                params: {
                    TempID:topic.id
                },
                headers: {
                    SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
                }
            })
            
            if (r.data.statusCode == 200) {      
                flag = true        
            }else{
                message.error(r.data.returnMessage)
            }
        })
        if(flag) { 
            message.success('删除成功')
        }
    }
    
    async loadPage({ dispatch, pageIndex, pageSize }) {

        this.setLoading({dispatch, loading: true})
        const params: any = {
          'page':pageIndex,
          'size': pageSize
        }
       
        // 档案列表
        const r = await new TopicLogic().selListByRecord(params);

        if (r&&r.data&&r.data.statusCode == 200) {
            const dataList = r.data.data.dataSource;         
            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: dataList, pageSize });
        }
        this.setLoading({dispatch,loading: false})

    }
    async setIndex(TempID:string) {
        const r = await new TopicLogic().setIndex(TempID);

           if (r.data.statusCode == 200) {
               message.success('设置成功')
               return r.data.statusCode;
           } else {
            message.error(r.data.returnMessage)
           }        
   }




    // setCondition({ dispatch, key, value }) {
    //     const params = {
    //     }
    //     params[key] = value;

    //     dispatch({ type: "SetSearch", payload: params })
    // }
 
}
export const topicfileAction = new TopicAction();