import { message } from "antd";
// import axios from "axios";
import Cookies from "js-cookie";
import { DataListAction } from "../../../component/data-list/action/data_table_action";
import { DataTableAction } from "../../../component/table/action/data_table_action";
import { Tools, axios } from "../../../config";
import { AlarmLogic } from "../../../logic/alarm_logic";
import { BrandLogic } from "../../../logic/brand_logic";
import { ClassesLogic } from "../../../logic/classes_logic";
import { InventoryLogic } from "../../../logic/inventory_logic";
import { ShopLogic } from "../../../logic/shop_logic";
import { UnitLogic } from "../../../logic/unit_logic";
import { FileGoodsAction } from "./file/file_goods_action";

export class AlarmAction extends DataTableAction {
    private classesLgc = new ClassesLogic();
    private unitLgc = new UnitLogic();
    private brandLgc = new BrandLogic();

    async init({ dispatch }) {
        // 商品分类
        dispatch({ type: "LoadClasses", payload: await this.classesLgc.selListBySelect() });
    }
    async delRow(id) {
        const r = await axios({
            method: "GET",
            url: Tools.getApiAddress("goods_del"),
            params: {
                goodsId: id
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })

        if (r.data.statusCode == 200) {
            message.success("删除成功")
        }
    }
    delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {
        selectList.map(async ({ id }) => {
            const r = await axios({
                method: "GET",
                url: Tools.getApiAddress("goods_del"),
                params: {
                    goodsId: id
                },
                headers: {
                    SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
                },
            })

            if (r.data.statusCode == 200) {
                message.success("删除成功")
            }
        })
    }
    async loadPage({ dispatch, pageIndex, pageSize, search }) {
        this.setLoading({dispatch,loading: true})
        // 商品列表
        const { keywords, classesId, iszero, shopId, statusId } = search;
//console.log(search)
        const params: any = {
            // "keyWords": string;
            // "shopID": string;
            // "goodsClass": string;
            pageIndex,
            pageSize
            // "orderBy": string;
            // "sort": string;
        }
        if (keywords) {
            params.keyWords = keywords;
        }
        if (undefined != shopId) {
            params.shopID = [shopId]
        }
        if (classesId) {
            params.goodsClass = classesId;
        }
        if (statusId) {
            params.earlyWarningReoa = statusId;
        }
        if (-1 == statusId) {
            delete params.earlyWarningReoa;
        }
        params.orderBy = '';
        params.sort = '';
//console.log(params)
        const r = await new AlarmLogic().selList(params)

        if (r.data.statusCode == 200) {
            const dataList = r.data.data.dataSource;

            const list = dataList.map((goods_v, i) => {
                // let clsItem = classifyList.filter(v => v.id == goods_v.goodsClassesID);
                // let uniItem = unitList.filter(v => v.id == goods_v.goodsUnitID);

                return {
                    ...goods_v,
                    // goodsClassify: clsItem.length && clsItem[0].classesName,
                    // unit: uniItem.length && uniItem[0].unitName,
                    key: i + 1
                }
            })

            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: list, pageSize,loading: false });
        } else{
            this.setLoading({dispatch, loading: false})
        }

    }
    setCondition({ dispatch, key, value }) {
        const params = {
        }
        params[key] = value;

        dispatch({ type: "SetSearch", payload: params })
    }
    async exportList(){
        
    }
}
export const alarmAction = new AlarmAction();