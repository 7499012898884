import { cloneElement } from "react";
import img_edit from "../../assets/images/icon/bianji.png";
import img_see from "../../assets/images/icon/chakan.png";
import img_del from "../../assets/images/icon/shanchu.png";
import img_lock from "../../assets/images/icon/mima.png";
import img_send from "../../assets/images/icon/fahuo.png";
import img_reciept from "../../assets/images/icon/shouhuo.png";
import img_refund from "../../assets/images/icon/refund.png";
import img_print from "../../assets/images/print.png";
import img_resetpwd from "../../assets/images/icon/resetpwd.png";
import audit from "../../assets/images/icon/audit.png";
import styles from "./index.module.scss";
import { Tooltip } from "antd";
import { StopOutlined, CheckCircleOutlined, PrinterOutlined } from '@ant-design/icons'
interface IControl {
  see?: (v) => void;
  edit?: (v) => void;
  del?: (v) => void;
}
// export function control(params: IControl) {
//     const len = Object.keys(params).length;

//     return {
//         width: function () {
//             return len * 33.3;
//         },
//         render: function (v, renderParams?: IControl) {
//             const newObj = { ...params, ...renderParams };
//             const r = <div>
//                 {
//                     newObj.see ? (
//                         <img src={img_see} style={{ width: 20, marginRight: "1.4375rem" }} onClick={e => newObj.see(v)} />
//                     ) : null
//                 }
//                 {
//                     newObj.edit ? (
//                         <img src={img_edit} style={{ width: 19, marginRight: "1.4375rem" }} onClick={e => newObj.edit(v)} />
//                     ) : null
//                 }
//                 {
//                     newObj.del ? (
//                         <img src={img_del} style={{ width: 20 }} onClick={e => newObj.del(v)} />
//                     ) : null
//                 }
//             </div>

//             return r;
//         }
//     }
// }

export const Control = {
  width: (maxItems: number) => {
    return maxItems * 33.3;
  },
  render: (...args) => {
    return function () {
      const backArg: any = arguments;

      const r: any = args.map((v) =>
        cloneElement(v(...backArg) || <div></div>)
      );

      return cloneElement(<div className={styles.root}>{r}</div>);
    };
  },
};
class IParams {
  click: (any) => void;
  tips?: string; //提示
  color?: string
}
export function See({ click }: IParams) {
  return (
    <Tooltip title={"查看"}>
      <img src={img_see} onClick={click} />
    </Tooltip>
  );
}
export function Edit({ click, tips }: IParams) {
  return (
    <Tooltip title={tips ? tips : "编辑"}>
      <img src={img_edit} onClick={click} />
    </Tooltip>
  );
}
export function Del({ click, tips }: IParams) {
  return (
    <Tooltip title={tips ? tips : "删除"}>
      <img src={img_del} onClick={click} />
    </Tooltip>
  );
}
export function Lock({ click }) {
  return <img src={img_lock} onClick={click} />;
}
export function Send({ click, tips }: IParams) {
  return (
    <Tooltip title={tips ? tips : "发货"}>
      <img src={img_send} onClick={click} />
    </Tooltip>
  );
}
export function Receipt({ click, tips }: IParams) {
  return (
    <Tooltip title={tips ? tips : "收货"}>
      <img src={img_reciept} onClick={click} />
    </Tooltip>
  );
}
export function Refund({ click }) {
  return (
    <Tooltip title="退款">
      <img src={img_refund} onClick={click} />
    </Tooltip>
  );
}
export function Print({ click, tips='打印', color }: IParams) {
  return (
    <Tooltip title={tips}>
      <PrinterOutlined  onClick={click}  className={styles.icons} style={color?{color: color}:{color: '#02ca74'}}/>
    </Tooltip>
  );
}
export function ResetPwd({ click }) {
  return (
    <Tooltip title="重置密码">
      <img src={img_resetpwd} onClick={click} />
    </Tooltip>
  );
}
export function SubmitAudit({ click }) {
  return (
    <Tooltip title='提交审核'>
      <img src={audit} onClick={click}/>
    </Tooltip>
  )
}
export function Stop({click, tips}: IParams){
  return (
    <Tooltip title={tips?tips:'禁用'}>
      <StopOutlined onClick={click} className={styles.icons} style={{color: '#ffc80b'}}/>
    </Tooltip>
  )
}
export function Enable({click}){
  return (
    <Tooltip title="启用">
      <CheckCircleOutlined onClick={click} className={styles.icons} style={{color: '#02ca74'}}/>
    </Tooltip>
  )
}
