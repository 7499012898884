import React from "react";
import { Info } from "../login";
import styles from "../login/style/index.module.scss";
import { Form } from "./view/form";

export const Forgot = () => {
    return (
        <div className={styles.root_c}>
            <div className={styles.form_c}>
                <div className={styles.form}>
                    <div className={styles.logo_c}>
                        <div className={styles.logo}>
                        </div>
                    </div>

                    <div className={styles.title_c}>
                        <span>找回密码</span>
                    </div>

                    <Form />

                    <Info />
                </div>
            </div>
        </div>
    )
}