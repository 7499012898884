import React, { useContext, useEffect } from "react";
import { Button } from "../../../../component/button";
import { Layer } from "../../../../component/layer/layer";
import { Form as FormAntd } from "antd";
import { formAction } from "../../action/hotkey/form_action";
import { FormStore } from "../../store/hotkey/form_store";
import { FormBasic } from "./hotkey_form_basic";
import "../../style/index.scss";

import { FileStore } from "../../store/hotkey/file_store";
import { fileAction } from "../../action/hotkey/file_action";
import { DataTableStore } from "../../../../component/table/store/data_table_store";
import { defualtPageSize } from "../../../../logic/common_logic";
import ReactToPrint from "react-to-print";
interface IForm {
  children?: any;
  close: (hasUpdate?: boolean) => void;
  editId?: string;
  seeId?: string;
  method?: string;
  item?: Object;
  list?;
}

export function Form({ close, seeId, editId, method, item, list }: IForm) {
  const [form] = FormAntd.useForm();
  // const { state, dispatch } = useContext(FormStore);
  // const action = formAction;
  // useEffect(() => {
  //   formAction.init({
  //     state: state,
  //     dispatch,
  //     id: editId || seeId,
  //     form,
  //     method: method,
  //     item,
  //   });
  //   return () => {};
  // }, []);

  function title() {
    if (editId) {
      return "编辑热键";
    } else if (seeId) {
      return "查看热键";
    } else {
      return "打印热键";
    }
  }
  const print = () => {
    const newWindow = window.open("打印窗口", "_blank");
    let printhtml = window.document.getElementById("printhtml");
    const docStr = printhtml.innerHTML; //需要打印的内容
    newWindow.document.write(docStr);
    const styles = document.createElement("style");
    styles.setAttribute("type", "text/css"); //media="print"
    styles.innerHTML = "";
    newWindow.document.getElementsByTagName("head")[0].appendChild(styles);
    newWindow.print();
    newWindow.close();
  };
  return (
    <FormAntd form={form}>
      <Layer layerWith={2} onClick={() => close()}>
        {/* <Layer.Title>{title()}</Layer.Title> */}
        <Layer.Body>
          <FormBasic
            title={title()}
            disabled={title() == "查看热键"}
            tableList={list}
          />
        </Layer.Body>
        {title() != "查看热键" && (
          <>
            <Layer.Foot>
              {/* <Button
                type="primary"
                color="Red"
                style={{ marginRight: "1.25rem" }}
                onClick={async () => {
                  const r = await form.validateFields();
                  let status = false;
                  if (editId) {
                    status = await action.updSubmit({ state, id: editId });
                  } else {
                    status = await action.addSubmit({ state });
                  }
                  status && close && close(true);
                }}
              >
                保存
              </Button> */}
              <Button
                type="primary"
                color="Yellow"
                onClick={() => {
                  print();
                }}
              >
                重置
              </Button>
            </Layer.Foot>
          </>
        )}
        {title() == "查看热键" && (
          <>
            <Layer.Foot></Layer.Foot>
          </>
        )}
      </Layer>
    </FormAntd>
  );
}
