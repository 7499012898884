import message from "antd/lib/message"
import { DataTableAction } from "../../../../component/table/action/data_table_action"
import { MarketingPresale_logic } from "../../../../logic/market/MarketingPresale_logic";

class FileAction extends DataTableAction {

    async delRow(id) {
        const r = await MarketingPresale_logic.StopMarkPresasle(id);

        if (r.data.statusCode == 200) {
            message.success("删除成功")
        } else {
            message.error("删除失败")
        }
    }

    delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {
        //
    }

    async loadPage({ dispatch, pageIndex, pageSize, search }) {
        this.setLoading({dispatch,loading: true})
        const { shopID, goodsId, seacherKey } = search;
        const params: any = {
            "shopID": shopID && shopID.map((item) => {
                return item.id;
            }),
            "goodsID": goodsId && goodsId.map((item) => {
                return item.id;
            }),
            "seachkey": seacherKey,
            "pageIndex": pageIndex,
            "pageSize": pageSize,
            "orderBy": '',
            "sort": '',
        }

        // 档案列表
        const r = await MarketingPresale_logic.QueryPage(params);

        if (r&&r.data&&r.data.statusCode == 200) {
            const dataList = r.data.data.dataSource;
            //
            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: dataList, pageSize });
        }
            this.setLoading({dispatch,loading: false})
        

    }

    setCondition({ dispatch, key, value }) {
        const params = {
        }
        params[key] = value;
        //
        dispatch({ type: "SetSearch", payload: params })
    }

    async stop(id){
        const r = await MarketingPresale_logic.StopMarkPresasle({ID: id})
        if(r&&r.data&&r.data.statusCode == 200){
            message.success('终止成功')

        }
        else{
            message.error(r.data.returnMessage || r.data.message || '终止失败')
        }
    }
}
export const fileAction = new FileAction();