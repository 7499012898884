import { Input, message, Empty  } from "antd";
import  styles  from './style/result.module.scss'
import { ScanOutlined, RightOutlined } from "@ant-design/icons";
import { useState } from "react";
import { fileAction } from './file_action'
import { Link } from "react-router-dom";
import { ConfigApi } from "../../config";
import { useBoolean } from "ahooks";
import { Pdf } from "./PDF";
import { ConfirmModal } from "../../component/modal";
interface IFormData{
    resultStatus: number // 1开票中 2.开票成功 3.开票失败
    invoicingPath?: string //ftp地址 前缀加上url
    invoicingTime: string //申请时间
    callBackTime?: string //收到开票结果回调时间
    buyerType: number //抬头类型 1、个人 2、企业
    invoicingPrice: number //金额
    buyerName: string // 发票抬头
    buyerTaxNum?: string //纳税人识别号
    buyerEmail: string //邮箱地址
    id: string
    url?: string
}
export function FapiaoResult(){
    const [confirmState, confirmFunc] = useBoolean(false)
    const [showPdf, showPdfFunc] = useBoolean(false)
    const [imgArr, setData] = useState([])

    const action = fileAction
    const { Search } = Input
    const [formData, setFormData] = useState<IFormData>({
        id: '',
        resultStatus: undefined,
        invoicingPath:'',
        invoicingTime:'',
        callBackTime: '',
        buyerType: 1,
        invoicingPrice: undefined,
        buyerName: '',
        buyerTaxNum: '',
        buyerEmail: '',
        url: ''
    })
    const onSearch = async (value) => {
        // if(value){
            const res = await action.applyFapiaoLogs(value)
            if(res){
                const r = await action.convertPdfToPng(res.invoicingPath)
                if(r && r.length > 0){
                  let list = []
                    r.map(item=>{
                        list.push((ConfigApi.serviceDomain + item).replace(/\\/g, '/'))
                    })
                    setData(list)
                }
            setFormData(res)

            }
        // }else{
        //     message.warning('请输入订单号或开票流水号')
        // }
       
    }
    
    return<>
    {!showPdf?
        <div className={styles.resultContainer}>
            <div className={styles.search}>
            <Search
                // prefix={<ScanOutlined  size={20} onClick={
                //     async ()=>{
                //       const navigator = window.navigator.mediaDevices
                //         const devices = await navigator.enumerateDevices()
                //         console.log(devices)
                //     }
                // }/> }
                placeholder="输入订单号或开票流水号查询"
                allowClear
                enterButton="搜索"
                size="large"
                onSearch={onSearch}
                />
            </div>
            { formData.id ? 
                <div className={styles.form}>
                    <div className={styles.formItem}>
                        <div className={styles.title}>发票类型</div>
                        <div className={styles.content}>电子发票</div>
                        <div className={styles.more} onClick={ async () => {
                            if(imgArr.length == 0){
                                const r = await action.convertPdfToPng(formData.invoicingPath)
                                if(r && r.length > 0){
                                  let list = []
                                    r.map(item=>{
                                        list.push((ConfigApi.serviceDomain + item).replace(/\\/g, '/'))
                                    })
                                    setData(list)
                                    showPdfFunc.setTrue()
                                }else{
                                    message.error('获取图片失败，请重新尝试')
                                    showPdfFunc.setFalse()
                                }
                            } else                           
                              showPdfFunc.setTrue()
                        }}>
                            <span onClick={()=>{                              
                            }}>查看电子发票</span>
                            <RightOutlined size={15} color="#666666"/>
                        </div>
                    </div>
                    <div className={styles.formItem}>
                        <div className={styles.title}>开票状态</div>
                        <div className={styles.content} style={formData.resultStatus == 1?{color: '#1890FF'}:formData.resultStatus ==3?{color: '#ff746d'}:{}}>{formData.resultStatus == 1?'开票中': formData.resultStatus == 2?'开票成功':'开票失败'}</div>
                    </div>
                    <div className={styles.formItem}>
                        <div className={styles.title}>发票金额</div>
                        <div className={styles.content}>￥{formData.invoicingPrice}</div>
                    </div>
                    <div className={styles.formItem}>
                        <div className={styles.title}>抬头类型</div>
                        <div className={styles.content}>{formData.buyerType == 1?'个人/非企业单位':'企业'}</div>
                    </div>
                    <div className={styles.formItem}>
                        <div className={styles.title}>申请时间</div>
                        <div className={styles.content}>{formData.invoicingTime}</div>
                    </div>
                    <div className={styles.formItem}>
                        <div className={styles.title}>邮箱地址</div>
                        <div className={styles.content}>{formData.buyerEmail}</div>
                    </div>
                </div>  
            :   <div style={{marginTop: 20}}>
                <Empty description="暂无信息"/>
                </div>}
            {formData.id && formData.resultStatus != 3 && <div className={styles.btnBox}>
                <div className={styles.btn} onClick={async () => {
                    confirmFunc.setTrue()
                   
                }}>重发发票</div>
            </div>}
        </div>
        :<Pdf  imgArr={imgArr} hidePdf={()=>{
            showPdfFunc.setFalse()
        }} />}
       <ConfirmModal
        visible={confirmState}
        title="温馨提示"
        subTitle=""
        message="是否重发发票至邮箱？"
        close={async (r) => {
          if (r) {
            const hide = message.loading('数据处理中', 0)
            await action.reSendFapiaoByLogID(formData.id)
            hide()
          }
          confirmFunc.setFalse();
        }}
      />
    </>
}