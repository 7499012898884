// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../config";
import { effeative } from "./common_logic";

function parseClassifyBySelect(v) {
    return v ? v.map(v => {
        return {
            value: v.id,
            label: v.classesName,
            children: parseClassifyBySelect(v.subGoodsClassesVM)
        };
    }) : []
}
function parseClassifyByTree(v) {
    return v ? v.map(v => {
        return {
            title: v.classesName,
            key: v.id,
            parentID:v.parentID,
            ...v,
            children: v.subGoodsClassesVM?parseClassifyByTree(v.subGoodsClassesVM):null
        };
    }) : []
}

export class ClassesLogic {
    async hiddenData(id: string, isHidden: boolean) {
        const r = effeative(await axios({
            method:'GET',
            url: Tools.getApiAddress('ggoods_classes_hidden'),
            params:{
                id: id,
                IsHidden : isHidden  
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            }
        }))
        return r
    }
    async querysList() {
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("goods_classes_select"),
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r.data.data;
    }

    /**
     * 分类列表（级联关系、下拉框格式）
     */
    async selListBySelect() {
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("goods_classes_queryrelation"),
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return parseClassifyBySelect(r.data.data)
    }
    async selListByTree() {
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("goods_classes_queryrelation"),
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return parseClassifyByTree(r.data.data)
    }
    /**新增分类 rewrite */
    async addGoodsClassify(params){
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("goods_classes_add"),
            headers: {
                SYSTOKEN: Cookies.get("token"),
                'ClientSource':'web'
            },
            data: params
        }))
        return r
    }
    /**编辑分类 rewrite */
    async updateGoodsClassify(params){
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("goods_classes_update"),
            headers: {
                SYSTOKEN: Cookies.get("token"),
                'ClientSource':'web'
            },
            data: params
        }))
        return r
    }
    /**分类详情
     *@param id 分类id
    */
    async getOneGoodsClassify(params){
        const r = effeative( await axios({
            method:'GET',
            url:Tools.getApiAddress('goods_classes_detail'),
            headers:{
                SYSTOKEN: Cookies.get("token"),
                'ClientSource':'web'
            },
            params:params
        }))
        return r
    }
    /**删除分类 rewrite
     * @param id 分类id
     */
    async delGoodsClassify(params){
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("goods_classes_remove"),
            headers: {
                SYSTOKEN: Cookies.get("token"),
                'ClientSource':'web'
            },
            params: params
        }))
        return r
    }
    /**获取所有一级分类 */
    async getFirstLevelGoodsClassify(){
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("goods_classes_firstlevel"),
            headers: {
                SYSTOKEN: Cookies.get("token"),
                'ClientSource':'web'
            },
        }))
        return r
    }
}