
import { TextAreaProps } from "antd/lib/input";
import AntdTextArea from "antd/lib/input/TextArea";
import React, { Children, cloneElement } from "react";
import styles from "./index.module.scss";

interface ITextAreaStyle {
    children?: any;
}

export function TextArea(params: ITextAreaStyle & TextAreaProps) {
    const { children } = params;

    const rootStyle = (params.className) ? params.className : "";
    
    return <AntdTextArea {...params} {...{ className: rootStyle + " " + styles.root }} >{children}</AntdTextArea>
}