import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
  TimePicker,
  DatePicker,
  Tag,
  InputNumber
} from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { FormSubtitle } from "../../../commodity/view/form_subtitle";
import styles from "../../style/commission_rule.module.scss";
import { useBoolean } from "ahooks";
import { formAction } from "../../action/commissionRule/form_action";
import { FormStore } from "../../store/commissionRule/form_store";
import { RadioStyle } from "../../../../component/radio-style";
import img_del from "../../../../assets/images/shanchu.png";
import { Table } from "../../../../component/table";

function Type({ value, disabled }) {
  const {  dispatch } = useContext(FormStore);
  const onChange = (e) => {
    if (!disabled) {
      formAction.setFormData({
        dispatch,
        value: e.target.value,
        key: "ctype",
      });
    }
  };
  useEffect(() => {
    formAction.setFormData({ dispatch, value: value, key: "ctype" });
  }, []);

  return (
    <RadioStyle>
      <Radio.Group
        className="custom-radio"
        onChange={onChange}
        value={value}
      >
        <Radio value={1}>固定金额</Radio>
        <Radio value={2}>百分点</Radio>
      </Radio.Group>
    </RadioStyle>
  );
}
export function FormBasic({ title, disabled, updateFormField }) {

  const { state, dispatch } = useContext(FormStore);
  const { formData } = state;

  /** 表单action */
  const action = formAction;


  

  let height = document.body.clientHeight - 188;
  return (
    <div className={styles.root} style={{ height: height + "px" }}>
      <div className={styles.content}>
        <FormSubtitle>基础信息</FormSubtitle>
        <Row gutter={16} style={{ marginTop: 0 }}>
          <Col span={24}>
            <Form.Item
              label="规格名称"
              labelCol={{ span: 3 }}
              name="ruleName"
              rules={[
                {
                  required: true,
                  message:'请输入规则名称'
                }
              ]}
            >
              <Input
                style={{ width: "400px" }}
                maxLength={15}
                disabled={disabled}
                value={formData.ruleName}
                autoComplete="off"
                placeholder="请输入"
                onChange={(e) => {
                  action.setFormData({
                    dispatch,
                    key: "ruleName",
                    value: e.target.value,
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>       
        <Row gutter={16} style={{ marginTop: 0 }}>
          <Col span={24}>
            <Form.Item
              label="提成方式"
              labelCol={{ span: 3 }}
              name="ctype"
              rules={[
                {
                  required: true,
                  validator: (_, __, callBack: (error?: string) => void) => {
                    if(formData.ctype ==undefined){
                      callBack('请选择提成方式')
                    }else{
                      callBack()
                    }
                  },
                }
              ]}
            >
              {formData.ctype?'':''}
                <Type value={formData.ctype} disabled={disabled}/>
            </Form.Item>
            </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 0 }}>
        <Col span={24}>
        <Form.Item
              labelCol={{ span: 3 }}
              label={<span className={styles.alarm}>规则等级</span>}
            >
             <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <div
                  style={{
                    fontSize: "10px",
                    height: "30px",
                    textAlign: "center",
                    lineHeight: "30px",
                    background: "#ff746d",
                    color: "#fff",
                    cursor: "pointer",
                    padding: "0 5px",
                    marginBottom: "10px",
                  }}
                  onClick={() => {
                    if (disabled) {
                      return;
                    }
                    action.addTableItem({dispatch})
                  }}
                >
                  添加规则等级
                </div>
              </div>
            </Form.Item>
        </Col>
        </Row>
        <DataTable dataList={state.tableList} disabled={disabled}/>
      </div>
    </div>
  );
}

/** 商品列表 */
export function DataTable({ dataList, disabled }) {
  /** 表单状态 */
  const { state, dispatch } = useContext(FormStore);
  /** 表单 */
  const { formData } = state;

  /** 表单action */
  const action = formAction;
  const columns: ColumnsType = [
    {
      title: "操作",
      dataIndex: "serial",
      key: "1",
      align: "center",
      width: 60,
      render: (v, r, i) => (
        <div>
          <img
            src={img_del}
            style={{ width: 20 }}
            onClick={() => {
              !disabled && action.delTableItem(dispatch, i);
            }}
          />
        </div>
      ),
    },
    {
      title: "规则等级",
      dataIndex: "ruleGrade",
      key: "2",
      align: "center",
      width: 100,
    },
    {
      title: "开始值(订单实收金额)",
      dataIndex: "minSellingPrice",
      key: "3",
      align: "center",
      width: 120,
      render: (v, r:any, i) => (
        <>
          <InputNumber
            min={0.01}
            max={r.maxSellingPrice ? r.maxSellingPrice: Number.MAX_SAFE_INTEGER}
            value={v}
            disabled={disabled}
            style={{ width: "80%" }}
            onChange={(val) => {
              action.changeTableItem({
                dispatch,
                payload: {
                  key: "minSellingPrice",
                  value: val,
                  index: i,
                },
              });
            }}
          />
        </>
      ),
    },
    {
      title: "结束值(订单实收金额)",
      dataIndex: "maxSellingPrice",
      key: "4",
      align: "center",
      width: 120,
      render: (v, r: any, i) => (
        <>
         <InputNumber
          min={r.minSellingPrice? r.minSellingPrice : 0.01}
          precision={2}
            value={v}
            disabled={disabled}
            style={{ width: "80%" }}
            onChange={(val) => {
              action.changeTableItem({
                dispatch,
                payload: {
                  key: "maxSellingPrice",
                  value: val,
                  index: i,
                },
              });
            }}
          />
        </>
      )
    },
    {
      title: `提成值${formData.ctype == 1?'(元)':'(%)'}`,
      dataIndex: "commissionValue",
      key: "8",
      align: "center",
      width: 120,
      render: (v, r, i) => (
        <>
          <InputNumber
          min={formData.ctype == 1? 0.01 : 1}
          max={formData.ctype == 1? Number.MAX_SAFE_INTEGER : 99}
          precision={formData.ctype == 1? 2 : 0}
            value={v}
            disabled={disabled}
            style={{ width: "80%" }}
            onChange={(val) => {
              action.changeTableItem({
                dispatch,
                payload: {
                  key: "commissionValue",
                  value: val,
                  index: i,
                },
              });
            }}
          />
        </>
      )
    },
  ];

  return <Table rowKey='ruleGrade' dataSource={dataList} columns={columns} />;
}
