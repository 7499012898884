import { Button, Col, Form, Input, Row, Select } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useState } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { FormSubtitle } from "../../../commodity/view/form_subtitle";
import styles from "../../style/inventory_goods_formbasic.module.scss";

import img_del from "../../../../assets/images/shanchu.png";
import { warehousingformAction } from "../../action/in-stock/warehousing_form_action";
import { useBoolean } from "ahooks";
import { ChooseGoods } from "../../../../component/choose-goods";
import { Table } from "../../../../component/table";
import { ChooseShop } from "../../../../component/choose-shop";
import { OutStockFormStore } from "../../store/out-stock/out_stock_form_store";
import { autoRem } from "../../../../global.style";
import { TableExpand } from "../../../../component/table-expand";
import { NumberTool } from "../../../../tool/numberTool";

const { Option } = Select;
export function DataTable({ dataList, disabled }) {
  const { dispatch } = useContext(OutStockFormStore);
  const [current, setCurrent] = useState(1);
  const [pageSize, setSize] = useState(10);
  const action = warehousingformAction;

  const columns: ColumnsType = [
    {
      title: "操作",
      dataIndex: "serial",
      key: "1",
      align: "center",
      width: 100,
      render: (v, r, i) => (
        <div>
          <img
            src={img_del}
            style={{ width: 20 }}
            onClick={() => {
              if (disabled) return;
              action.delTableItem(dispatch, v);
            }}
          />
        </div>
      ),
    },
    {
      title: "批次号",
      dataIndex: "storageBatchNumber",
      key: "0",
      align: "center",
      width: 185
    },
    {
      title: "商品编码",
      dataIndex: "goodsCode",
      key: "2",
      align: "center",
    },
    {
      title: "商品名称",
      dataIndex: "goodsName",
      key: "3",
      align: "center",
    },
    {
      title: "库存单位",
      dataIndex: "unitName",
      key: "4",
      align: "center",
    },
    // {
    //     title: '商品规格',
    //     dataIndex: '',
    //     key: '7',
    //     align: "center",
    //     width: 120,
    //     render: (e, r, i) => {
    //         return <div>****</div>;
    //     }
    // },
    {
      title: "出库单价",
      dataIndex: "inStockUnitPrice",
      key: "7",
      align: "center",
      width: 120,
      render: (v, r: any, i) => {
        return (
          <Input
            maxLength={200}
            type="number"
            disabled={disabled}
            style={{ width: 100 }}
            value={v}
            onChange={(e) =>
              action.changeTableItem({
                dispatch,
                key: "inStockUnitPrice",
                value: e.target.value,
                index: i + (current - 1) * pageSize,
              })
            }
          />
        );
        // return (
        //   <div>
        //     {r.goodsSpecifications.reduce((a, b) => {
        //       return a + (b.inStockUnitPrice || 0);
        //     }, 0)}
        //   </div>
        // );
      },
    },
    {
      title: "出库数量",
      dataIndex: "inStockNumber",
      key: "8",
      align: "center",
      width: 120,
      render: (v, r: any, i) => {
        return (
          <Input
            maxLength={200}
            type="number"
            disabled={disabled}
            style={{ width: 100 }}
            value={v}
            onChange={(e) =>
              action.changeTableItem({
                dispatch,
                key: "inStockNumber",
                value: e.target.value,
                index: i + (current - 1) * pageSize,
              })
            }
          />
        );
        // return (
        //   <div>
        //     {r.goodsSpecifications.reduce((a, b) => {
        //       return a + (b.inStockNumber || 0);
        //     }, 0)}
        //   </div>
        // );
      },
    },
    {
      title: "出库金额",
      dataIndex: "inStockPrice",
      key: "9",
      align: "center",
      width: 120,
      // render: (e, r: any, i) => {
      //   // return <Input style={{ width: 100 }} value={e} onChange={e => action.changeTableItem({ dispatch, key: "inStockPrice", value: e.target.value, index: i })} />
      //   return (
      //     <div>
      //       ￥{NumberTool.toAmount(r.inStockNumber * r.inStockUnitPrice || 0)}
      //     </div>
      //   );
      // },
    },
  ];

  return (
    <Table
      scroll={{ y: 275 }}
      dataSource={dataList}
      columns={columns}
      onChange={(pagination) => {
        setCurrent(pagination.current);
        setSize(pagination.pageSize);
      }}
      //   expandable={{
      //     expandedRowRender: (record) => {
      //       return record.goodsSpecifications.map((item) => {
      //         return (
      //           <GetSpecifications
      //             disabled={disabled}
      //             specifications={item}
      //             unitName={record.unitName}
      //           />
      //         );
      //       });
      //     },
      //     rowExpandable: (record) => record.name !== "Not Expandable",
      //   }}
    />
  );
}

function GetSpecifications({ specifications, unitName, disabled }) {
  const { state, dispatch } = useContext(OutStockFormStore);
  return (
    <div className={styles.specifications}>
      <div className={styles.info}>
        <span>{specifications.unitContent}</span>
        <span>{specifications.unitName}</span>
      </div>
      <div className={styles.input}>
        <Input
          disabled={disabled}
          type="number"
          value={specifications.inStockUnitPrice}
          onChange={(e) => {
            specifications.inStockUnitPrice = Math.abs(
              parseFloat(e.currentTarget.value)
            );
            dispatch({
              type: "update",
              payload: null,
            });
          }}
        />
      </div>
      <div className={styles.input}>
        <Input
          disabled={disabled}
          type="number"
          value={specifications.inStockNumber}
          onChange={(e) => {
            specifications.inStockNumber = Math.abs(
              parseFloat(e.currentTarget.value)
            );
            dispatch({
              type: "update",
              payload: null,
            });
          }}
        />
      </div>
      <div className={styles.input}>
        <span>
          ￥
          {NumberTool.toAmount(
            specifications.inStockUnitPrice * specifications.inStockNumber || 0
          )}
        </span>
      </div>
    </div>
  );
}

export function InventoryGoodsFormBasic({ disabled, updateFormField }) {
  const [shopState, shopFunc] = useBoolean(false);
  const [goodsState, goodsFunc] = useBoolean(false);

  const { state, dispatch } = useContext(OutStockFormStore);
  const { formData } = state;

  const action = warehousingformAction;

  //   /** 获取总结数量 */
  //   const allNumber = () => {
  //     return state.tableList.reduce((a, b) => {
  //       let p = b.goodsSpecifications.reduce((a, b) => {
  //         return a + (b.inStockNumber || 0);
  //       }, 0);
  //       return a + p;
  //     }, 0);
  //   };
  /** 获取总结数量 无规格*/
  const allNumber = () => {
    return state.tableList.reduce((a, b) => {
      return a + (b.inStockNumber || 0);
    }, 0);
  };
  /** 获取总结金额 无规格*/
  const allAmount = () => {
    return state.tableList.reduce((a, b) => {
      return (
        a +
        NumberTool.multiply(b.inStockNumber || 0, b.inStockUnitPrice || 0, 2)
      );
    }, 0);
  };
  //   /** 获取总结金额 */
  //   const allAmount = () => {
  //     return state.tableList.reduce((a, b) => {
  //       let p = b.goodsSpecifications.reduce((a, b) => {
  //         return (
  //           a +
  //           NumberTool.multiply(b.inStockNumber || 0, b.inStockUnitPrice || 0, 2)
  //         );
  //       }, 0);
  //       return a + p;
  //     }, 0);
  //   };

  let height = document.body.clientHeight - 188 + 26;
  return (
    <div className={styles.root} style={{ height: height + "px" }}>
      <div className={styles.content}>
        <FormSubtitle>基础信息</FormSubtitle>

        <div
          style={{
            margin: "" + autoRem(1.9375) + " " + autoRem(1.25) + " 0 0",
          }}
        >
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={6}>
              <Form.Item name="" label="制单人" labelCol={{ span: 8 }}>
                <span>{formData.userName}</span>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="制单时间" labelCol={{ span: 8 }}>
                <span style={{ display: "block", minWidth: 200 }}>
                  {formData.date}
                </span>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={6}>
              <Form.Item
                label="门店"
                name="out-shopName"
                labelCol={{ span: 8 }}
                rules={[
                  {
                    required: true,
                    message: "请选择门店",
                    validator: (
                      rule,
                      value,
                      callback: (error?: string) => void
                    ) => {
                      if (!formData.shopName) {
                        callback("请选择门店");
                      } else {
                        callback();
                      }
                    },
                  },
                ]}
              >
                {formData.shopName ? "" : ""}
                <Input
                  disabled={disabled}
                  value={formData.shopName}
                  autoComplete="off"
                  placeholder="请选择"
                  onClick={() => shopFunc.setTrue()}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="理由"
                name="out-reasonId"
                labelCol={{ span: 8 }}
                rules={[
                  {
                    required: true,
                    message: "请选择理由",
                    validator: (
                      rule,
                      value,
                      callback: (error?: string) => void
                    ) => {
                      if (!formData.reasonId) {
                        callback("请选择理由");
                      } else {
                        callback();
                      }
                    },
                  },
                ]}
              >
                {formData.reasonId ? "" : ""}
                <Select
                  disabled={disabled}
                  value={formData.reasonId}
                  allowClear
                  onChange={(v) => {
                    action.setFormData({ dispatch, key: "reasonId", value: v });
                    updateFormField("out-reasonId");
                  }}
                >
                  {/* <Option value={1}>手动入库</Option>
                  <Option value={2}>退货入库</Option>
                  <Option value={3}>采购入库</Option> */}
                  <Option value={4}>手动出库</Option>
                  <Option value={5}>销售出库</Option>
                  {/* <Option value={6}>库存调整</Option>
                  <Option value={7}>商品试吃</Option>
                  <Option value={8}>日常报损</Option>
                  <Option value={9}>商品过期</Option>
                  <Option value={10}>领用</Option>
                  <Option value={11}>日常报溢</Option> */}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="备注" labelCol={{ span: 8 }}>
                <Input
                  maxLength={200}
                  disabled={disabled}
                  value={formData.remark}
                  autoComplete="off"
                  onChange={(v) =>
                    action.setFormData({
                      dispatch,
                      key: "remark",
                      value: v.target.value,
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <div style={{ marginTop: -20 }}>
          <FormSubtitle>商品信息</FormSubtitle>
        </div>

        <div
          style={{
            margin: "" + autoRem(1.9375) + " " + autoRem(1.25) + " 0 0",
          }}
        >
          {/* 添加批次号，不能直接添加 <Row gutter={16} style={{ marginTop: 10 }}>
            <Col span={10}>
              <Form.Item label="快速添加" labelCol={{ span: 6 }}>
                <Input
                  autoFocus
                  allowClear
                  disabled={disabled}
                  placeholder="扫描商品条形码，快速添加"
                  value={state.code}
                  onPressEnter={async (e) => {
                    await action.getGoods({
                      id: e.currentTarget.value,
                      dispatch,
                      state,
                    });
                    dispatch({ type: "InputCode", payload: "" });
                  }}
                  onChange={(e) => {
                    dispatch({ type: "InputCode", payload: e.target.value });
                  }}
                />
              </Form.Item>
            </Col>
          </Row> */}
         
        </div>

        <div style={{ marginTop: -20 }}>
          <ButtonPanel>
            <Button disabled={disabled} onClick={() => goodsFunc.setTrue()}>
              选择商品
            </Button>
          </ButtonPanel>
        </div>

        <DataTable dataList={state.tableList} disabled={disabled} />
        <TableExpand>
          数量合计：{allNumber().toFixed(3)}
          <span style={{ marginLeft: 10 }}>
            金额合计：￥{NumberTool.toAmount(allAmount())}
          </span>
        </TableExpand>
      </div>

      <ChooseShop
        visibleModal={shopState}
        close={(status, r) => {
          if (r) {
            action.setFormData({ dispatch, key: "shopId", value: r[0].id });
            action.setFormData({
              dispatch,
              key: "shopName",
              value: r[0].shopName,
            });
          }
          updateFormField("out-shopName");
          shopFunc.setFalse();
        }}
      />
      <ChooseGoods
        visibleModal={goodsState}
        rowKey="storageBatchNumber"
        close={(status, r) => {
          if (r) {
            action.addTableItem({ dispatch, list: r });
          }
          goodsFunc.setFalse();
        }}
        filter={{ zeroInventory: true, checkInvcount: true }}
      />
    </div>
  );
}
