import { Tools, axios } from "../config";
/**
 * 申请开票 applyFapiao
 */
export class FapiaoReceiptLogic{
    /**申请开票 */
    async applyFapiao(data) {
        const r =  await axios({
            url: Tools.getApiAddress('fapiao_apply'),
            method: 'POST',
            data
        })
        return r
    }/**
     * @description 根据订单号检查是否已开票 开票结果
     * @param id 
     */
    async applyFapiaoResult(id){
        const r = await axios({
            url: Tools.getApiAddress('fapiao_apply_result'),
            method: 'GET',
            params:{
                ID: id
            }
        })
        return r
    }
    
    /**
     * @description 查询开票记录
     * @param  SeacherKey 
     */
    async applyFapiaoLogs(SeacherKey){
        const r = await axios({
            url: Tools.getApiAddress('fapiao_apply_logs'),
            method: 'GET',
            params:{
                SeacherKey: SeacherKey
            }
        })
        return r
    }
    /**
     * 
     * @param LogID 发票记录id
     */
    async resendFaPiao(LogID){
        const r = await axios({
            url: Tools.getApiAddress('fapiao_resend'),
            method: 'GET',
            params:{
                LogID: LogID
            }
        })
        return r
    }
    /**
     * @description pdf转为图片
     * @param PDFPath  string
     */
    async convertPdfToPng(PDFPath){
        const r = await axios({
            url: Tools.getApiAddress('pdf_to_png'),
            method: 'GET',
            params:{
                PDFPath: PDFPath
            }
        })
        return r
    }
}