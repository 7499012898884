

export function StoresProfitsStatsTable({ data }: { data }) {
    if (!data) { return <div></div> }

    const dataListItemMaxLength: number = Math.max(...data.map((item) => {
        return item.sonItem.length;
    }));

    //
    return <>
        <thead>
            <tr>
                {data.map((item, index) => {
                   
                        return (
                            <th  key={index} colSpan={2}>{item.title}</th>
                        );
                   
                })}                
            </tr>
        </thead>
        <tbody>
            {
                Array(dataListItemMaxLength).fill(0).map((_, index) => {
                    return <tr>
                        {
                            data.map((item) => {
                                if(item.sameGrade&&item.sameGrade.length>0){
                                    if(index == 0){
                                        return(
                                            <td colSpan={2} style={{padding:0,}}><tr >
                                            {item.sameGrade.map((s,i)=>{
                                             return<> <td style={{minWidth:50, borderTop:'none',borderBottom:'none',borderRight:'none',borderLeft:i==0?'none':''}}>{s.title}</td></>
                                         })}
                                        </tr>
                                        </td>
                                        )
                                    }else{
                                        return(
                                            <td colSpan={2} style={{padding:0,}}><tr >
                                            {item.sameGrade.map((s,i)=>{
                                             return<> <td style={{minWidth:50,borderTop:'none',borderBottom:'none',borderRight:'none',borderLeft:i==0?'none':''}}>{s.number}</td></>
                                         })}
                                        </tr>
                                        </td>
                                        )
                                    }
                                   
                                   
                                }else{
                                    return (
                                        <>
                                            <td className={'dark'}>{item.sonItem[index]?.title}</td>
                                            <td>{item.sonItem[index]?.number}</td>
                                        </>
                                    )
                                }
                                
                            })
                        }
                    </tr>
                })
            }
            <tr>
                {
                    data.map((item) => {
                        return (<>
                            <td className={'dark'}>合计</td>
                            {item.sameGrade?<td>{item.sameGrade.length == 0 ? 0.0 : (item.sameGrade.reduce((a, b) => {
                                return a + b.number;
                            }, 0)).toFixed(2)}</td>:<td>{item.sonItem.length == 0 ? 0.0 : (item.sonItem.reduce((a, b) => {
                                return a + b.number;
                            }, 0)).toFixed(2)}</td>}
                        </>);
                    })
                }
            </tr>
        </tbody>
    </>
}