import { message } from "antd";
import axios from "axios";
import Cookies from "js-cookie";
import { Tools } from "../../../config";

export async function fetchCodeAction(state, dispatch) {
    const { data, status } = await axios({
        method: "GET",
        url: Tools.getApiAddress("auth_code")
    })
    if (200 == status) {
        dispatch({
            ...state,
            rawCode: data.data,
            code: data.data.captchaCode
        })
    }
}
export async function fetchLoginAction(state, setData, his) {
    //代理商id
    let agentID: string = his.location.search.match(/agentID=(.*)&?/)?.[1];
    const { account, passwd, inputCode, rawCode, phoneCode, isCheck } = state;
    if (!isCheck) {
        message.error('请勾选用户协议')
        return false
    }
    const { data, status } = await axios({
        method: "POST",
        url: Tools.getApiAddress("register"),
        data: {
            uphone: account,
            uPass: passwd,
            userName: account,
            codeNumber: phoneCode,
            agentID: agentID,
        }
    })
    if (200 == status) {
        if (200 != data.statusCode) {
            message.error(data.returnMessage == null ? data.message : data.returnMessage)
            fetchCodeAction(state, setData)
        } else {
            message.success('注册成功')
            Cookies.set("token", "");
            Cookies.set("loginMsg", '');
            setTimeout(() => {
                his.replace('/login');
            }, 1000);
        }
    }
}