import { useBoolean } from "ahooks";
import { Modal as AntdModal, ModalProps } from "antd";
import React, { Children, cloneElement } from "react";
import { render, unmountComponentAtNode } from "react-dom";
import styles from "./index.module.scss";

interface IModalStyle {
    children?: any;
}

export function Modal(params: IModalStyle & ModalProps) {
    const { children } = params;

    const rootStyle = (params.className) ? params.className : "";

    return <AntdModal {...params} {...{ className: rootStyle + " " + styles.root }} >{children}</AntdModal>
}

type IConfirmModal = {
    title,
    subTitle,
    message,
    visible;
    close: (status: boolean, type?:string) => void;
    cancelText?:string 
    okText?:string 
}
export function ConfirmModal({ title, subTitle, message, close, visible, okText, cancelText }: IConfirmModal) {
    return (
        <Modal title={title} visible={visible} okText={okText?okText:"确定"} cancelText={cancelText?cancelText:"取消"} width={366} onOk={() => close(true)} onCancel={() => close(false,'cancel')}>
            <h1>{subTitle}</h1>
            <p>{message}</p>
        </Modal>
    )
}
