import { useContext, useEffect } from "react";
import { Button } from "../../../../component/button";
import { Modal } from "../../../../component/modal";
import { Col, Form, Input, InputNumber, Row, message } from "antd";
import {formAction} from '../../action/setting/form_action'
import { FormStore } from "../../store/setting/form_store";
import styles from '../../style/form_basic.module.scss'
import { useBoolean } from "ahooks";
interface IParams {
    visibleModal: boolean;
    close?: () => void;
    id: string
    disabled: boolean
  }

export function ShopParamSet({id, visibleModal, close, disabled}: IParams){
    const [loading, loadingFunc] = useBoolean(false)
    const action = formAction
    const [form] = Form.useForm();
    const {state, dispatch} = useContext(FormStore)
    const handleCancel = () =>{
        action.reset({dispatch, form})
        close()
    }
    useEffect(()=>{
        (async function () {
            visibleModal &&  await formAction.init({id, form, dispatch})
        })()
        return () => {
            action.reset({dispatch, form})
        }
    },[visibleModal])
    function Radio({ onChange, defaultValue, disabled }) {
        const [state, stateFunc] = useBoolean(false);
      
        useEffect(() => {
          if (defaultValue) {
            stateFunc.setTrue();
          } else {
            stateFunc.setFalse();
          }
      
          return () => {};
        }, [defaultValue]);
      
        return (
          <div
            className={`${styles.radio} ${state ? styles.active : ""} ${
              disabled ? styles.disabled : ""
            }`}
            onClick={() => {
              if (disabled) return;
              onChange(!state);
              stateFunc.toggle();
            }}
          ></div>
        );
      }
    return<>
    <Modal 
    title='门店参数设置'
    visible={visibleModal}
    width={500}
    destroyOnClose={true}
    footer={
      <>
        <Button type="primary" color="Red" style={{ marginRight: "1.25rem" }}
        onClick={async ()=>{
            await form.validateFields();
            if(loading){
                message.loading('正在提交数据')
                return false
            }
            loadingFunc.setTrue()
            const flag = await action.submit({state})
            loadingFunc.setFalse()
            if(flag){
                handleCancel()
            }else{

            }
        }}
        >确认</Button>
        <Button
          type="primary"
          color="Yellow"
          onClick={()=>handleCancel()}
        >
          取消
        </Button>
     
      </>
    }
    >
        <Form form={form}>
    <Row gutter={24}>
        <Col span={24}>
          <Form.Item
            name="wnN_shop_nick"
            label="万里牛门店编码"
            labelCol={{ span: 8 }}
            rules={[
              {
                required: false,
                message: "请输入万里牛门店编码",
              },
            ]}
          >
            <Input
                disabled={disabled}
                autoComplete="off"
                onChange={(v) =>
                  action.changeData({
                    dispatch: dispatch,
                    value: v.target.value,
                    key: "wnN_shop_nick",
                  })
                }
              />
          </Form.Item>
        </Col>
        </Row>
    <Row gutter={24}>
        <Col span={24}>
          <Form.Item
            name="wnN_storage_code"
            label="万里牛仓库编码"
            labelCol={{ span: 8 }}
            rules={[
              {
                required: false,
                message: "请输入万里牛仓库编码",
              },
            ]}
          >
            <Input
                disabled={disabled}
                autoComplete="off"
                onChange={(v) =>
                  action.changeData({
                    dispatch: dispatch,
                    value: v.target.value,
                    key: "wnN_storage_code",
                  })
                }
              />
          </Form.Item>
        </Col>
        </Row>
    <Row gutter={24}>
        <Col span={24}>
          <Form.Item
            name="isMemberGrade"
            label="是否自动降级"
            labelCol={{ span: 8 }}
          >
           <Radio onChange={(v)=>{
            action.changeData({
                dispatch,
                value: v,
                key: 'isMemberGrade'
            })
           }} defaultValue={state.isMemberGrade} disabled={disabled}/>
          </Form.Item>
        </Col>
        </Row>
    {state.isMemberGrade && <Row gutter={24}>
        <Col span={24}>
          <Form.Item
            name="memberClassTakeDay"
            label="未消费天数"
            labelCol={{ span: 8 }}
            extra="连续N天未消费自动降到下一级"
            rules={[
                {
                    required: true,
                    message: "请输入未消费天数",
                }
              ]}
          >
           <InputNumber  defaultValue={state.memberClassTakeDay} size='large' 
               min={1} precision={0}  step={1}  onChange={(v) =>
                  {
                    action.changeData({
                    dispatch,
                    value: v,
                    key: "memberClassTakeDay",
                  })
                }
                }/>
          </Form.Item>
        </Col>
        </Row>}
        </Form>
    </Modal>
    </>
}