import { useBoolean } from "ahooks";
import { TabKeys } from "../../tool/TabKeys";
import { _tr, _td, TableTool } from "../../tool/TableTool";
import { salesRWTK, salesRWTreeTK } from "../tableKey/salesRWTK";

const salesRWEndTK = TabKeys.byTabKeysGetEndChilds(salesRWTK);
const salesRWSubtotalTK = [...salesRWEndTK].splice(8);

export function SalesRWTable({
  data,
  ifLastPage,
  handleClickTd
}: {
  data;
  ifLastPage: boolean;
  handleClickTd?: (val)=> void
}) {

  if (!data) {
    return <div></div>;
  }

  /** 获取合计 */
  const getSubtotalTabTds = (data): _tr => {
    /** 总结数据 */
    if (ifLastPage) {
      return (
        <tr>
          <td className="dark" colSpan={9}>
            总计
          </td>
          {salesRWSubtotalTK.map((item) => {
            return <td>{data[item.key]}</td>;
          })}
        </tr>
      ) as any;
    }
    return;
  };

  const getTabTds1 = (data): _tr => {
    return salesRWEndTK.map((item, index) => {
      return (
        <td onClick={()=>{
          if(item.key == 'orderNumber'){
            handleClickTd(data)
          }
         
        }} style={item.styles}>
          {item.render ? item.render(index, data[item.key]) : data[item.key]}
        </td>
      ) as _td;
    });
  };
  const getTabTds = (data): _tr[] => {
    let _trs: _tr[] = data.details.map((item) => {
      let payLog = item.payLog || []
      if(payLog.length > 0){
        payLog.map(pay=>{
          switch(pay.paymentMethod){
            case 1: 
              item['aliPay'] = pay.paymentAmount
              break;
              case 2: 
              item['weChatPay'] = pay.paymentAmount
              break;
              case 3: 
              item['unionPay'] = pay.paymentAmount
              break;
              case 4: 
              item['cashPay'] = pay.paymentAmount
              item['smallChange'] = pay.smallChange
              break;
              case 5: 
              item['walletPay'] = pay.paymentAmount
              break;
          }
        })
      }
      return getTabTds1(item);
    });
    //第一列数据加上分类信息
    _trs[0] &&  _trs[0].unshift(
      (
        <td className="dark" rowSpan={_trs.length} >
          {data.shopName}
        </td>
      ) as _td
    );
    return _trs;
  };

  return (
    <>
      <thead>
        {TableTool.getTreeTabThs([...salesRWTreeTK, ...salesRWTK]).map(
          (item, index) => {
            return <tr key={index}>{item}</tr>;
          }
        )}
      </thead>
      <tbody>
        {data.length> 0 && data.map((item) => {
          return getTabTds(item).map((item, index) => {
            return <tr key={index}>{item}</tr>;
          });
        })}
        {/* {
                data.list.length > 0 && getSubtotalTabTds(data.total)
            } */}
      </tbody>
     
    </>
  );
}
