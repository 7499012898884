import axios, { AxiosPromise } from "axios";
import Cookies from "js-cookie";
import { Tools } from "../../../config";
import { maxPageSize } from "../../../logic/common_logic";
import { UnitBaseAction } from "./unit_action";

class BrandAction extends UnitBaseAction {
    protected callbackText(params: any): string {
        return params.brandName;
    }
    protected getList() {
        return axios({
            method: "POST",
            url: Tools.getApiAddress("goods_brand_select_page"),
            data: {
                brandNo: "",
                brandName: "",
                pageIndex: 1,
                pageSize: maxPageSize(),
                orderBy: "",
                sort: ""
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })
    }
    protected addUnit(params) {
        return axios({
            method: "POST",
            url: Tools.getApiAddress("goods_brand_add"),
            data: {
                brandNo: "1",
                brandName: params.entranceValue,
                remark: ""
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })
    }
    protected updateUnit(params) {
        return axios({
            method: "POST",
            url: Tools.getApiAddress("goods_brand_update"),
            data: {
                id: params.rawData.id,
                brandName: params.entranceValue,
                brandNo: "",
                remark: params.rawData.remark
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })
    }
    protected deleteUnit(params) {
        return axios({
            method: "GET",
            url: Tools.getApiAddress("goods_brand_delete"),
            params: {
                brandId: params.rawData.id
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })
    }
}

export const brandAction = new BrandAction();