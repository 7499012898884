import { Cascader, Col, Form, Form as FormAntd, Input,  Row, InputNumber, Switch } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useContext, useEffect, useState } from "react";
import { RadioStyle } from "../../../../component/radio-style";
import { autoRem } from "../../../../global.style";
import { formAction } from "../../action/grade/form_action";
import { FormStore } from "../../store/grade/form_store";
import { useBoolean } from "ahooks";
import styles from '../../style/form_grade.module.scss'


export function FormBasic(title) {
  const { state, dispatch: formDispatch } = useContext(FormStore);
  const action = formAction;
  var isLook = false;
  if (title.title == "查看等级") {
    isLook = true;
  } else {
    isLook = false;
  }
  function Radio({ onChange, defaultValue, disabled }) {
    const [state, stateFunc] = useBoolean(false);
  
    useEffect(() => {
      if (defaultValue) {
        stateFunc.setTrue();
      } else {
        stateFunc.setFalse();
      }
  
      return () => {};
    }, [defaultValue]);
  
    return (
      <div
        className={`${styles.radio} ${state ? styles.active : ""} ${
          disabled ? styles.disabled : ""
        }`}
        onClick={() => {
          if (disabled) return;
          onChange(!state);
          stateFunc.toggle();
        }}
      ></div>
    );
  }
  

  return (
    <>
      <div style={{ margin: "1.9375rem 0 0 0" }}>
        <Row gutter={20}>
          <Col span={18}>
            <Form.Item
              name="grade-name"
              label="等级名称"
              labelCol={{ span: 6 }}
              rules={[
                {
                  required: true,
                  message: "请输入等级名称",
                },
              ]}
            >
              <Input
                disabled={isLook}
                placeholder="请输入等级名称"
                maxLength={10}
                onChange={(v) =>
                  action.setValue({
                    dispatch: formDispatch,
                    value: v.target.value,
                    attribute: "GradeName",
                  })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={18}>
            <Form.Item
              name="grade-useProportion"
              label="使用比列"
              labelCol={{ span: 6 }}
              rules={[
                {
                  required: false,
                  message: "请输入结算时可使用比例",
                },
              ]}
            >
              {/* {state.form.} */}
              <InputNumber  defaultValue={state.formData.useProportion} size='large' 
               formatter={(value) => `${value}%`}
              max={100} min={1} precision={0}  step={1}  onChange={(v) =>
                  {
                    action.setValue({
                    dispatch: formDispatch,
                    value: v,
                    attribute: "useProportion",
                  })
                }
                }/>
              {/* <Input
                disabled={isLook}
                placeholder="请输入结算时可使用比例"
                maxLength={10}
                onChange={(v) =>
                  action.setValue({
                    dispatch: formDispatch,
                    value: v.target.value,
                    attribute: "useProportion",
                  })
                }
              /> */}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={18}>
            <Form.Item
              name="isLock"
              label="是否锁定"
              labelCol={{ span: 6 }}
              rules={[
                {
                  required: true,
                  message: "请选择是否锁定",
                },
              ]}
              extra="锁定后不能自动升级到该等级，也不会从该等级降级"
            >
               <Radio
                disabled={isLook}
                defaultValue={state.formData.isLock}
                onChange={(v: boolean) =>
                  action.setValue({
                    dispatch: formDispatch,
                    value: v,
                    attribute: "isLock",
                  })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={18}>
            <Form.Item
              name="classLevel"
              label="层级"
              labelCol={{ span: 6 }}
              rules={[
                {
                  required: true,
                  message: "请输入层级",
                },
              ]}
              extra="值越大越高"
            >
              <InputNumber  defaultValue={state.formData.classLevel} size='large' 
              min={1} precision={0}  step={1}  onChange={(v) =>
                  {
                    action.setValue({
                    dispatch: formDispatch,
                    value: v,
                    attribute: "classLevel",
                  })
                }
                }/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={18}>
            <Form.Item
              name="exConsumptionMoney"
              label="升级金额(￥)"
              labelCol={{ span: 6 }}
              rules={[
                {
                  required: true,
                  message: "请输入升级金额",
                },
              ]}
              extra="消费累计满多少元自动晋升到当前等级"
            >
              <InputNumber  defaultValue={state.formData.exConsumptionMoney} size='large' 
               min={0.01} precision={2}  step={1}  onChange={(v) =>
                  {
                    action.setValue({
                    dispatch: formDispatch,
                    value: v,
                    attribute: "exConsumptionMoney",
                  })
                }
                }/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={18}>
            <Form.Item name="remark" label="备注" labelCol={{ span: 6 }}>
              <TextArea
                disabled={isLook}
                rows={2}
                placeholder="请输入备注"
                onChange={(v) =>
                  action.setValue({
                    dispatch: formDispatch,
                    value: v.target.value,
                    attribute: "Remark",
                  })
                }
              ></TextArea>
            </Form.Item>
          </Col>
        </Row>
      </div>
    </>
  );
}
