import { useContext, useRef, useState, useEffect } from "react";
import { comConType } from "..";
import { HotsAction, IHotsData } from "../action/HotsAction";
import { ITopicValue, TopicStore } from "../store/topic";
import styles from "./../style/hots.module.scss";
import { EditHost } from "./hots/editHost";
import defaultImgUrl from "../../../assets/images/mini/group_2_5.png";
import { Tools } from "../../../config";
import { ApiCon } from "../com/apiCon";
import { message } from "antd";

/**
 * 热图
 */
export function Hots(props: { id: number, con: comConType }) {
  const topicStoreState = useContext(TopicStore);
  const inputRef = useRef(null);
  const [ifShowEditHost, setShowEditHost] = useState(false);
  /** 如果不存在数据的话就初始化一下数据 */
  let actionArg: [ITopicValue, number] = [topicStoreState, props.id];
  const data: IHotsData = HotsAction.getData(...actionArg);
  useEffect(() => {
    if (!data) {
      // //console.log('初始化数据');
      HotsAction.initData(...actionArg);
    }
  });
  if (!data) {
    return <div></div>;
  }

  //注册数据提取方法
  HotsAction.setEF(topicStoreState, props.id, () => {
    return new Promise((r, e) => {
      if (data.imgUrl && data.precinctList.length > 0) {
        ApiCon.turnBlobImgUrl({
          url: data.imgUrl,
          name: data.imgName,
        }).then((urls) => {
          r({
            ...data,
            imgUrl: urls[0],
          });
        });
      } else {
        message.error('有热图没有上传图片或者没有设置选区');
      }
    });
  });

  /** 选择图片 */
  const selectImage = () => {
    inputRef.current.click();
  };
  /** 获取图片 */
  const inputChange = () => {
    let _file: File = inputRef.current.files[0];
    if (!_file) { return; }
    HotsAction.setFile(...actionArg, URL.createObjectURL(_file), _file.name);
  };

  /** 判断类型 */
  if (props.con == 'show') {
    return (<div className={styles.hots}>
      <img className={styles.img} src={ApiCon.getImageUrl(data.imgUrl) || defaultImgUrl} alt="" />
    </div>)
  } else {
    return (
      <>
        {ifShowEditHost && (
          <EditHost
            close={() => {
              setShowEditHost(false);
            }}
            hostData={data}
            conPrecinctData={(...arg) => {
              HotsAction.conPrecinctData(...actionArg, ...arg);
            }}
          />
        )}
        <div className={styles.hots_ctrl}>
          <span>图片热区</span>
          <div className={styles.right}>
            <img src={ApiCon.getImageUrl(data.imgUrl) || defaultImgUrl} alt="" />
            <span>建议图片宽度为：320</span>
            <input
              ref={inputRef}
              onChange={inputChange}
              type="file"
              accept="image/*"
            />
            <button onClick={selectImage} className={styles.a}>
              选择文件
            </button>
            <button
              onClick={() => {
                if (!data.imgUrl) {
                  alert('请先上传图片!');
                  return;
                }
                setShowEditHost(true);
              }}
              className={styles.b}
            >
              修改热区
            </button>
          </div>
        </div>
      </>
    );
  }
}
