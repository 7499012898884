import React, { useContext, useState } from "react";
import {
  Button,
  Cascader,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  TreeSelect,
} from "antd";
import styles from "../../../style/file_search.module.scss";
import { FileStore } from "../../../store/file/file_goods_store";
import { fileGoodsAction } from "../../../action/file/file_goods_action";

const { Option } = Select;
const { RangePicker } = DatePicker;

export function FileSearch({ onSearch }) {
  const { state, dispatch } = useContext(FileStore);
  const action = fileGoodsAction;

  return (
    <div>
      <Form layout="horizontal">
        <div className={styles.row}>
          <div className={styles.cell}>
            <Form.Item label="选择分类">
              <TreeSelect
                style={{ width: 175 }}
                showCheckedStrategy={TreeSelect.SHOW_PARENT}
                dropdownMatchSelectWidth={false}
                defaultValue={state.search.classesId}
                value={state.search.classesId}
                treeCheckable={true}
                treeData={state.classesList}
                onChange={(v) => {
                  action.setCondition({
                    dispatch,
                    key: "classesId",
                    value: v.length > 0 ? [v[v.length - 1]] : [],
                  });
                }}
                placeholder="选择商品分类"
              />

              {/* <Cascader style={{ width: "15rem" }} options={state.classesList} placeholder="请选择"
                                onChange={(v) => action.setCondition({ dispatch, key: "classesId", value: v[v.length - 1] })}
                            /> */}
            </Form.Item>
          </div>
        </div>
        <div className={styles.row}>
          <Button type="primary" danger onClick={() => onSearch(1)}>
            搜 索
          </Button>
        </div>
      </Form>
    </div>
  );
}
