import { ConfigApi } from "../config";
import { BaseApiCon } from "../page/system/view/applet/logic/BaseApiCon";
/**
 * 协议逻辑
 */
export class AgreementLogin extends BaseApiCon {
    /** 获取api */
    static getApi(name: string, ...args): string {
        let reg = /\{.*?\}/g;
        let path: any = ConfigApi.apiPath;
        let url: string = path;
        for (let _o of name.split('.')) {
            url = url[_o];
        }
        if (url) {
            let arr: any;
            arr = url.match(reg)
            for (let i = 0; i < args.length; i++) {
                url = url.replace(arr[i], args[i]);
            }
        }
        return `${ConfigApi.operationDomain}/${url}`;
    }

    /** 获取代理商申请协议 */
    static getAgent(): Promise<{
        ID: string;
        Grouping: string;
        Name: string;
        Type: number;
        Content: string;
    }> {
        return fetch(this.getApi('Agreement.Get') + `?type=2`, {
            method: 'GET',
        }).then((data) => {
            return data.json();
        }).then((data) => {
            if (data.code == 200) {
                return data.data || {};
            } else {
                return {};
            }
        });
    }
    /** 获取商家注册协议 */
    static getRegister(): Promise<{
        ID: string;
        Grouping: string;
        Name: string;
        Type: number;
        Content: string;
    }> {
        return fetch(this.getApi('Agreement.Get') + `?type=1`, {
            method: 'GET',
        }).then((data) => {
            return data.json();
        }).then((data) => {
            if (data.code == 200) {
                return data.data || {};
            } else {
                return {};
            }
        });
    }
}