import { createContext, useReducer } from "react";

export const FileStore = createContext<IContextProps>(null);

interface IContextProps {
  state: IState;
  dispatch: ({ type }: Dispatch) => void;
}

type Dispatch = { type; payload };
class Search {
  keywords: string;
  classesId: string[] = [];
  statusId: string;
  typeId: string;
  brandId: string;
}

interface IState {
  classesList: any[];
  statusList: any[];
  typeList: any[];
  brandList: any[];
  search: Search;
  modleFile: any;
  isLoading: boolean;
}

const initialArgs: IState = {
  classesList: [],
  statusList: [],
  typeList: [],
  brandList: [],
  search: new Search(),
  modleFile: "",
  isLoading: false,
};

function reducer(state: IState, { type, payload }: Dispatch) {
  switch (type) {
    case "isloading": {
      state.isLoading = payload;
      return { ...state };
    }
    case "LoadClasses":
      return {
        ...state,
        classesList: payload,
      };
    case "LoadStatus":
      return {
        ...state,
        statusList: payload,
      };
    case "LoadType":
      return {
        ...state,
        typeList: payload,
      };
    case "LoadBrand":
      return {
        ...state,
        brandList: payload,
      };
    case "LoadModelFile":
      return {
        ...state,
        modleFile: payload,
      };
    case "SetSearch":
      return {
        ...state,
        search: {
          ...state.search,
          ...payload,
        },
      };
    default:
      return state;
  }
}

export function ClassicFileStoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialArgs);

  const v: any = { state, dispatch };
  return <FileStore.Provider value={v}>{props.children}</FileStore.Provider>;
}
