import { Input as AntdInput, InputProps } from "antd";
import React, { Children, cloneElement } from "react";
import styles from "./index.module.scss";

interface IInputStyle {
    children?: any;
}

export function Input(params: IInputStyle & InputProps) {
    const { children } = params;

    const rootStyle = (params.className) ? params.className : "";

    return <AntdInput {...params} {...{ className: rootStyle + " " + styles.root }} >{children}</AntdInput>
}