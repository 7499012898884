import React, { useContext, useEffect } from "react";
import { Button } from "../../../../component/button";
import { Layer } from "../../../../component/layer/layer";
import { Form as FormAntd, message } from "antd";
import { formAction, FormAction } from "../../action/flowChargeoff/form_action";
import { FormStore } from "../../store/flowChargeoff/form_store";
import {FormBasic} from './form_basic'
import { useBoolean } from "ahooks";
interface IForm {
  children?: any;
  action: FormAction;
  close: (status: boolean) => void;
  editId?: string;
  seeId?: string;
}
export function Form({ close, action, editId, seeId }: IForm) {
  const [form] = FormAntd.useForm();
  const [loading, loadFunc] = useBoolean(false)
  const { state, dispatch: formDispatch } = useContext(FormStore);

 

  useEffect(() => {
   
    return () => {};
  }, []);

  function title() {
    if (editId) {
      return "编辑扣款单";
    } else if (seeId) {
      return "查看扣款单";
    } else {
      return "新增扣款单";
    }
  }
 
  return (
    <FormAntd form={form}>
      <Layer layerWith={2} >
        <Layer.Title onClick={() => close(false)}>{title()}</Layer.Title>
        <Layer.Body>
        <FormBasic  updateFormField={(field, value = '') => {
                        //为表单某个字段设置数据
                        form.setFieldsValue({
                            [field]: value,
                        });
                        //验证表单的某个字段
                        form.validateFields([field]);
                    }} />
        </Layer.Body>
        <Layer.Foot
          borderStyle={title() == "查看扣款单" ? { border: "none" } : {}}
        >
          {title() != "查看扣款单" ? (
            <>
              <Button loading = { state.loading}
                type="primary"
                color="Red"
                style={{ marginRight: "1.25rem" }}
                onClick={async () => {
                  const r = await form.validateFields();
                  if(loading){
                    message.loading('正在提交数据')
                    return false
                }
                  let status = false;
                  if (editId) {
                    // status = await action.updSubmit({
                    //   state: state.formData,
                    //   form,
                    //   dispatch: formDispatch,
                    // });
                  } else {
                    loadFunc.setTrue()
                    status = await action.addSubmit({
                      state: state,
                      form,
                      dispatch: formDispatch,
                    });
                    loadFunc.setFalse()
                  }
                  status && close && close(true);
                }}
              >
                保存
              </Button>
              <Button
                type="primary"
                color="Yellow"
                onClick={() => action.reset({ form, dispatch: formDispatch })}
              >
                重置
              </Button>
            </>
          ) : null}
        </Layer.Foot>
      </Layer>
    </FormAntd>
  );
}
