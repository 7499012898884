// 开发票页面
import { useEffect, useState } from "react";
import styles from "./style/index.module.scss";
import { Input, Radio, message } from "antd";
import { RadioStyle } from "../../component/radio-style";
import { useBoolean } from "ahooks";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { fileAction } from "./file_action";

// 已开票 查询
import { FapiaoResult } from './result'

interface IFormData {
  expenditureID: string //订单id
  fapiaoType: string; //发票类型
  fapiaoMoney: number | string; //发票金额

  buyerName: string; //发票抬头
  buyerType: 1 | 2; //抬头类型 1个人 2企业
  email: string; //邮箱

  buyerTaxNum?: string; //纳税人识别号 企业必填
  buyerAddress?: string; //注册地址
  buyerTel?: string; //注册电话
  buyerAccountName?: string; //开户银行
  buyerAccountNum?: number; //银行账号
}
export function Fapiao() {
  const action = fileAction
  const [showState, showFunc] = useBoolean(false);
  const [canSubmit,canSubmitFunc] = useBoolean(false)
  const [loading, loadingFunc] = useBoolean(false)
  const [hasApply, hasApplyFunc] = useBoolean(false)
  
  // 表单
  const [formData, setFormData] = useState<IFormData>({
    fapiaoType: "电子发票",
    fapiaoMoney: '',
    expenditureID: '',
    buyerName: "",
    buyerType: 1,
    email: "",
    buyerTaxNum: ''
  });
  const changeFormData = (params) => {
    let data = Object.assign({}, formData, params);
    setFormData(data);
  };

  const his = useHistory()
  useEffect(() => {
    (async function () {
      document.title = "开票信息";
      let search = his.location.search
       let params = getUrlParams(search)  
       const r = await action.checkhasApply(params.id)
      if(r){
        // his.replace('/result')
        hasApplyFunc.setTrue()
      }else{
        changeFormData({fapiaoMoney: params.money, expenditureID: params.id})
      }
    })();
    return () => {
      document.title = "多点多生鲜新零售平台"
    };
  }, []);
const   getUrlParams = (url :string):{ id?: string, money?: string} => {
    // 通过 ? 分割获取后面的参数字符串
    let urlStr = url.split('?')[1]
    // 创建空对象存储参数
	let obj = {};
    // 再通过 & 将每一个参数单独分割出来
	let paramsArr = urlStr.split('&')
	for(let i = 0,len = paramsArr.length;i < len;i++){
        // 再通过 = 将每一个参数分割为 key:value 的形式
		let arr = paramsArr[i].split('=')
		obj[arr[0]] = arr[1];
	}
	return obj
}

  useEffect(()=>{
     if(formData.email && formData.buyerName){
        if(formData.buyerType == 2 ){
          if(formData.buyerTaxNum){
            canSubmitFunc.setTrue()
          }else{
            canSubmitFunc.setFalse()
          }
        }else{
          canSubmitFunc.setTrue()
        }
     }else{
      canSubmitFunc.setFalse()
     }
  },[formData])
  return (
   <> { !hasApply ?<>
      <div className={styles.container}>
        <div className={styles.contentBox}>
          <div className={styles.formItems}>
            <div className={styles.label}>发票类型</div>
            <div className={styles.content}>
              <Input value={formData.fapiaoType} disabled></Input>
            </div>
          </div>
          <div className={styles.formItems}>
            <div className={styles.label}>发票金额</div>
            <div className={styles.content}>
              <Input value={formData.fapiaoMoney} disabled prefix="￥"></Input>
            </div>
          </div>
          <div className={styles.formItems}>
            <div className={styles.label}>抬头类型</div>
            <div className={styles.content}>
              <RadioStyle>
                <Radio.Group 
                  className="custom-radio"
                  onChange={(e) => {
                    if(loading){
                      return
                    }
                    changeFormData({buyerType: e.target.value});
                  }}
                  value={formData.buyerType}
                >
                  <Radio value={1} >
                    个人/非企业单位
                  </Radio>
                  <Radio value={2}>企业</Radio>
                </Radio.Group>
              </RadioStyle>
            </div>
          </div>
          <div className={styles.formItems}>
            <div className={styles.label}>发票抬头</div>
            <div className={styles.content}>
              <Input disabled={loading}
                placeholder="(必填)"
                value={formData.buyerName}
                onChange={(e) => {
                  changeFormData({buyerName: e.target.value});
                }}
              ></Input>
            </div>
          </div>
          {formData.buyerType == 2 && (
            <>
              <div className={styles.formItems}>
                <div className={styles.label}>纳税人识别号</div>
                <div className={styles.content}>
                  <Input disabled={loading}
                    placeholder="(必填)"
                    value={formData.buyerTaxNum}
                    onChange={(e) => {
                      changeFormData({buyerTaxNum: e.target.value});
                    }}
                  ></Input>
                </div>
              </div>
            </>
          )}
          {showState && (
            <>
              <div className={styles.formItems}>
                <div className={styles.label}>注册地址</div>
                <div className={styles.content}>
                  <Input disabled={loading}
                    placeholder="(选填)"
                    value={formData.buyerAddress}
                    onChange={(e) => {
                      changeFormData({buyerAddress: e.target.value});
                    }}
                  ></Input>
                </div>
              </div>
              <div className={styles.formItems}>
                <div className={styles.label}>注册电话</div>
                <div className={styles.content}>
                  <Input disabled={loading}
                    placeholder="(选填)"
                    value={formData.buyerTel}
                    onChange={(e) => {
                      changeFormData({buyerTel: e.target.value});
                    }}
                  ></Input>
                </div>
              </div>
              <div className={styles.formItems}>
                <div className={styles.label}>开户银行</div>
                <div className={styles.content}>
                  <Input disabled={loading}
                    placeholder="(选填)"
                    value={formData.buyerAccountName}
                    onChange={(e) => {
                      changeFormData({buyerAccountName: e.target.value});
                    }}
                  ></Input>
                </div>
              </div>
              <div className={styles.formItems}>
                <div className={styles.label}>银行账号</div>
                <div className={styles.content}>
                  <Input disabled={loading}
                    placeholder="(选填)"
                    value={formData.buyerAccountNum}
                    onChange={(e) => {
                      changeFormData({buyerAccountNum: e.target.value});
                    }}
                  ></Input>
                </div>
              </div>
            </>
          )}
          <div
            className={styles.showMore}
            onClick={() => {
              if (showState) {
                showFunc.setFalse();
              } else {
                showFunc.setTrue();
              }
            }}
          >
            其他发票信息
            {!showState ? (
              <DownOutlined style={{ fontSize: 15, color: "#999999" }} />
            ) : (
              <UpOutlined style={{ fontSize: 15, color: "#999999" }} />
            )}
          </div>
        </div>
        <div
          className={styles.contentBox}
          style={{ marginTop: 20, border: "none" }}
        >
          <div className={styles.formItems}>
            <div className={styles.label}>邮箱地址</div>
            <div className={styles.content}>
              <Input disabled={loading}
                placeholder="用于向您发送电子发票(必填)"
                value={formData.email}
                onChange={(e) => {
                  changeFormData({email: e.target.value});
                }}
              ></Input>
            </div>
          </div>
        </div>
        <div className={styles.alarm}>
            <div>温馨提示：</div>
            <div>1、应国家税务总局要求，您若开具增值税普通发票，须同时提供企业抬头及纳税人识别号，否则发票将无法用于企业报销；</div>
            <div>2、发票将由订单所属城市的公司为您开具；</div>
            <div>3、配合国家税务总局推进全面数字化的电子发票，部分城市已转为全电发票，发票处理时长为1-3个工作日,请耐心等待。</div>
        </div>
      </div>
      <div className={styles.bottom}>
        <div className={styles.btn} onClick={ async ()=>{
          if(canSubmit){
            if(loading){
              // message.loading('正在提交数据')
              return
            }
            let params = Object.assign({}, formData)
            loadingFunc.setTrue()
           const r =  await action.submit(params)
            loadingFunc.setFalse()
            if(r){
              his.replace(`/success?num=${r}`)
            }
          }
        }}>提交申请</div>
      </div>
      { !canSubmit && <div className={styles.mask}></div>}
    </>: <FapiaoResult />}
    </>
  );
}
