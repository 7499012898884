import { useBoolean } from "ahooks";
import { Button, Input, Modal, Spin, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect, useState } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { DataTable } from "../../../../component/table";

import img_edit from "../../../../assets/images/bianji.png";
import img_see from "../../../../assets/images/chakan1.png";
import img_del from "../../../../assets/images/shanchu.png";
import { fileAction } from "../../action/printSet/file_action";
import { FileStore } from "../../store/printSet/file_store";
import { FormStoreProvider } from "../../store/printSet/print_set_store";
import { Form } from "./form";
import { formAction } from "../../action/printSet/print_set_action";
import { DataTableStore } from "../../../../component/table/store/data_table_store";
import { Search } from "../../../../component/search";
import { defualtPageSize, Progress } from "../../../../logic/common_logic";
import { AuthLogic } from "../../../../logic/auth_logic";
import { Control, See, Edit, Del } from "../../../../component/control-column";
import { ConfirmModal } from "../../../../component/modal";
import styles from "../../style/equip.module.scss";
import { getGSCloudPlugin } from "../../../../assets/js/GSCloudPluginFuncs";
import Cookies from "js-cookie";
import { Tips, TipsGroup } from "../../../../component/tips";
import { FileSearch } from "./file_search";
import { Mask } from "../../../../component/mask";

let editId = "";
let seeId = "";
let item;
const formParams = {
  method: "Create",
  id: "",
};
let GSCloudPlugin = getGSCloudPlugin();
export function PrintSetList(viewPower) {
  const [createState, createFunc] = useBoolean(false);
  const [confirmState, confirmFunc] = useBoolean(false);
  const [searchState, searchFunc] = useBoolean(false);
  const [checkStrictly, setCheckStrictly] = React.useState(false);
  const action = fileAction;
  const { state, dispatch } = useContext(FileStore);
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);
  const [menuButton, setData] = useState([]);
  useEffect(() => {
    (async function () {
      dispatch({
        type: "isloading",
        payload: { isLoading: false, msg: "" },
      });
      let r = await new AuthLogic().selButton(viewPower.viewPower);
      if (r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          r[i] = r[i].split("_")[r[i].split("_").length - 1];
        }
      }
      // console.log(r);

      setData(r);
    })();
    return () => {};
  }, []);
  const columns: ColumnsType = [
    {
      title: "操作",
      key: "1",
      align: "center",
      width: Control.width(4),
      render: Control.render(
        (v) =>
          menuButton.indexOf("UPDATE") != -1 && (
            <Edit
              click={() => {
                editId = v.id;
                seeId = "";
                item = v;
                formParams.method = "Edit";
                createFunc.setTrue();
              }}
            />
          ),
        (v) =>
          menuButton.indexOf("DELETE") != -1 && (
            <Del
              click={() => {
                formParams.id = v.id;
                confirmFunc.setTrue();
              }}
            />
          ),
        (v) =>
          menuButton.indexOf("VIEW") != -1 && (
            <span
              style={{
                cursor: "pointer",
                color: "#ffc80b",
                margin: "0 0.5rem",
              }}
              onClick={async () => {
                await action.setInuse({ dispatch, ID: v.id });
                await onSearch(tableState.current);
              }}
            >
              是否应用
            </span>
          )
      ),
    },
    {
      title: "打印类型",
      dataIndex: "ticketsType",
      key: "2",
      align: "center",
      render: (v) => (
        <span>
          {v === 1
            ? "58收银"
            : v === 2
            ? "80收银"
            : v === 3
            ? "820配送"
            : "58价签"}
        </span>
      ),
    },
    {
      key: "3",
      title: "创建人",
      dataIndex: "addUserID",
      align: "center",
    },
    {
      key: "4",
      title: "创建时间",
      dataIndex: "addTime",
      align: "center",
    },
    {
      key: "5",
      title: "是否使用",
      dataIndex: "isStencil",
      align: "center",
      render: (v) => (v ? <Tag color="#52c41a">使用中</Tag> : <Tag></Tag>),
    },
  ];
  const onSearch = async (pageIndex) => {
    await action.loadPage({
      pageIndex: pageIndex,
      pageSize: defualtPageSize(),
      dispatch: tableDispatch,
      search: state.search,
    });
  };
  // 光速云打印 -- start
  // 打印
  const printFunction = (dispatch, type) => {
    GSCloudPlugin.SetAppKey(
      "BC85333E6E2345A9AC2C39635C722682",
      "四川智如客科技有限公司"
    );
    GSCloudPlugin.GetPrintQueue({
      OnSuccess: function (result) {
        console.log(result);
      },
      OnError: function (result) {
        console.log(result);
      },
    });
    dispatch({
      type: "isloading",
      payload: { isLoading: true, msg: "打印中..." },
    });
    var url = document.location.toString().split("/");

    // setTimeout(() => {
      GSCloudPlugin.PrintHtml({
        Title: "HTML0001",
        Width: 220,
        Height: 100,
        Url:
          url[0] +
          "//" +
          url[1] +
          url[2] +
          "/printGrid.html?ticketType=" +
          type +
          "&token=" +
          Cookies.get("token"),
        PrinterName: "测试", // app.getPrinterName()方法仅用于在线演示
        OnSuccess: function (result) {
          dispatch({
            type: "isloading",
            payload: { isLoading: false, msg: "打印中..." },
          });
        },
        OnError: function (result) {
          dispatch({
            type: "isloading",
            payload: { isLoading: false, msg: "打印中..." },
          });
        },
      });
    // }, 2000);
  };
  // 光速云打印 -- end

  return (
    <Spin
      tip={state.msg}
      spinning={state.isLoading}
      size="large"
      wrapperClassName="isloading"
    >
      <div className={styles.root}>
        <Search>
          {/* <Input
            maxLength={30}
            allowClear
            placeholder="请输入条码秤IP或别名"
            onChange={(e) =>
              action.setCondition({
                dispatch,
                key: "SearchKey",
                value: e.target.value,
              })
            }
          />
          <input type="button" value="快速查找" onClick={() => onSearch(1)} /> */}
          <TipsGroup>
            <input
              type="button"
              value="高级查找"
              onClick={() => searchFunc.toggle()}
            />
            <Tips width="30rem" display={searchState} left="0">
              <FileSearch
                onSearch={(...arg: [any]) => {
                  onSearch(...arg);
                  searchFunc.setFalse();
                }}
              />
            </Tips>
            <Mask visible={searchState} onClick={() => searchFunc.setFalse()} />
          </TipsGroup>
        </Search>
        <ButtonPanel>
          {menuButton.indexOf("ADD") != -1 && (
            <Button
              onClick={() => {
                formParams.id = "";
                formParams.method = "Create";

                editId = "";
                seeId = "";
                createFunc.setTrue();
              }}
            >
              新增模板
            </Button>
          )}
          {menuButton.indexOf("VIEW") != -1 && (
            <Button
              onClick={() => {
                printFunction(dispatch, 4);
              }}
            >
              打印测试
            </Button>
          )}
        </ButtonPanel>
        <DataTable
          columns={columns}
          action={fileAction}
          rowSelection={{ checkStrictly }}
          onLoad={async () => {
            await onSearch(1);
            Progress.done();
          }}
          onChange={(pageIndex) => {
            onSearch(pageIndex);
          }}
          scroll={{ scrollToFirstRowOnChange: true, autoY: true, x: 1060 }}
          loading={tableState.loading}
        />
        {createState && (
          <FormStoreProvider>
            <Form
              close={(hasUpdate) => {
                if (hasUpdate) {
                  onSearch(tableState.current);
                }

                createFunc.setFalse();
              }}
              seeId={seeId}
              editId={editId}
              method={formParams.method}
              item={item}
            />
          </FormStoreProvider>
        )}

        <ConfirmModal
          visible={confirmState}
          title="温馨提示"
          subTitle="确认删除？"
          message="删除之后信息无法恢复，请慎重考虑"
          close={async (r) => {
            if (r) {
              await action.delRow({ dispatch, id: formParams.id });
              onSearch(tableState.current);
            }
            confirmFunc.setFalse();
          }}
        />
      </div>
    </Spin>
  );
}
