import { Cascader, Col, Form, Form as FormAntd, Input, Radio, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Selection } from "../../../component/GDMap/selection";
import { RadioStyle } from "../../../component/radio-style";
import { autoRem } from "../../../global.style";
import { formAction } from "../action/form_action";
import { AdvStoreForm } from "../store/form_store";
import styles from "../style/form_basic.module.scss";
import {MiniAdvertise} from './advertise'
interface IStatus {
  value;
  swtichValue;
  disabled?: boolean;
  isdefault?: boolean;
}






export function FormBasic({ disabled,form}) {
  const { state, dispatch: formDispatch } = useContext(AdvStoreForm);
  const [cityList, setCityList] = useState([]);

  const action = formAction;
  const { formData } = state;

  useEffect(() => {
    (async function () {
      const r = await axios({
        method: "GET",
        url: "./lib/area.json",
        responseType: "json",
      });

      setCityList(r.data);
    })();

    return () => {};
  }, []);

  return (
    <>
      <div style={{ margin: "1.9375rem 24px 0 0" }}>
          <MiniAdvertise form={form} disabled={disabled}/>
       </div>
    </>
  );
}
