import moment from "moment";
import { createContext, useContext, useReducer } from "react";

export const FormStore = createContext<IContextProps>(null);

interface IContextProps {
  state: IState;
  dispatch: ({ type }: Dispatch) => void;
}

type Dispatch = { type; payload };

export class FormData {
  /** 活动名称 */
  activityName: string;
  shopList;
  shopName;
  isMemberClass: number = 1;
  classList;
  /** 日期相关 */
  beginDate;
  endDate;
  /** 到货日期 */
  arrivalDate;
}

export class TableItem {
  serial: number;
  goodsID: string;
  goodsName: string;
  goodsClassName: string;
  unitName: string;
  inStockUnit: string;
  goodsNo: string;
  storePrice: string;
  shopPrice: string;
  status: number;
  sepID: string;
  sumNumber: number;
}

interface IState {
  /** 会员等级列表 */
  TagList;
  /** 指定商品 */
  tableList: TableItem[];
  formData: FormData;
  shoplist: any[];
}

export const initialArgs: IState = {
  TagList: [],
  tableList: [],
  formData: new FormData(),
  shoplist: [],
};

function reducer(state: IState, { type, payload }: Dispatch) {
  switch (type) {
    case " LoadShop":
      return {
        ...state,
        shoplist: payload,
      };
    // 等级列表
    case "LoadTag":
      return {
        ...state,
        TagList: payload,
      };
    case "SetFormData": {
      return {
        ...state,
        formData: {
          ...state.formData,
          ...payload,
        },
      };
    }
    case "SetForm": {
      const {
        shopList,
        activityName,
        shopName,
        isMemberClass,
        classList,
        beginDate,
        endDate,
        arrivalDate,
        tableList,
      } = payload;
      const item = new FormData();
      item.activityName = activityName;
      item.arrivalDate = arrivalDate ? arrivalDate.substring(0, 10) : "";
      item.endDate = endDate;
      item.beginDate = beginDate;
      item.classList = classList;
      item.isMemberClass = isMemberClass;
      item.shopName = shopName;
      item.shopList = shopList;
      state.formData = item;
      return {
        ...state,
        tableList: tableList,
      };
    }
    case "AddTableItem": {
      const list = state.tableList || [];
      // 去重
      if (list.every((v, i) => v.sepID !== payload.goodsSepID)) {
        const item = new TableItem();
        const {
          status,
          goodsID,
          goodsNo,
          goodsName,
          goodsClassName,
          unitName,
          shopPrice,
          storePrice,
          goodsSepID,
          sumNumber,
        } = payload;
        item.status = status;
        item.goodsID = goodsID;
        item.goodsName = goodsName;
        item.goodsClassName = goodsClassName;
        item.unitName = unitName;
        item.goodsNo = goodsNo;
        item.shopPrice = shopPrice;
        item.storePrice = storePrice;
        item.sepID = goodsSepID;
        item.sumNumber = sumNumber;
        list.push(item);
        list.forEach((v, i) => (v.serial = i));
        return {
          ...state,
          tableList: [...list],
        };
      }
      return state;
    }
    case "DelTableItem": {
      const list = state.tableList || [];

      list.splice(payload, 1);

      return {
        ...state,
        tableList: [...list],
      };
    }
    case "ChangeTableItem": {
      const { index, key, value } = payload;
      const list = state.tableList || [];
      list[index][key] = value;
      return {
        ...state,
        tableList: [...list],
      };
    }
    case "ClearTableList":
      return {
        ...state,
        tableList: [],
      };
    default:
      return state;
  }
}

export function FormStoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialArgs);

  const v = { state, dispatch };
  return <FormStore.Provider value={v}>{props.children}</FormStore.Provider>;
}
