import styles from "../../../style/applet/version.module.scss";
import { Tooltip, Button, Empty } from "antd";
import { Modal, message, Spin, Tag } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import { Preview } from "./preview";
import { WxSystemApiCon } from "../logic/WxSystemApiCon";
import { WxShopApiCon } from "../logic/WxShopApiCon";
import { ConfirmModal } from "../../../../../component/modal";
import moment from "moment";
import { BaseApiCon } from "../logic/BaseApiCon";

/**
 * 小程序版本信息
 */
export function Version({ info, disabled }) {
  const [ifPreview, setIfPreview] = useState(false);
  const [limit, setLimit] = useState(null);
  const [newCommitRecord, setNewCommitRecord] = useState(null);
  const [ifInit, setIfInit] = useState(false);

  useEffect(() => {
    update();
  }, []);

  const update = () => {
    Promise.all([
      WxShopApiCon.Queryquota().then((data) => {
        setLimit(data);
      }).catch(()=>{
        setIfInit(true);
      }),
      WxShopApiCon.GetShopCommitemplate().then((data) => {
        setNewCommitRecord(data);
      }),
    ]).then(() => {
      if (!ifInit) {
        setIfInit(true);
      }
    });
  };

  if (!ifInit) {
    return (
      <div className={styles.loading}>
        <Spin />
        <span>加载中。。。</span>
      </div>
    );
  }

  return (
    <>
     {limit? <div className={styles.root}>
        <OnV
          update={update}
          newCommitRecord={newCommitRecord}
          limit={limit}
          info={info}
          disabled={disabled}
          setIfPreview={setIfPreview}
        />
        <AuditV
          update={update}
          newCommitRecord={newCommitRecord}
          limit={limit}
          info={info}
          disabled={disabled}
          setIfPreview={setIfPreview}
        />
        <NewV
          update={update}
          newCommitRecord={newCommitRecord}
          limit={limit}
          info={info}
          disabled={disabled}
          setIfPreview={setIfPreview}
        />
      </div>:<div>
          <Empty></Empty>
        </div>}
      {
        <Modal
          visible={ifPreview}
          footer={null}
          title="版本预览"
          onCancel={() => setIfPreview(false)}
        >
          <Preview />
        </Modal>
      }
    </>
  );
}

type VPar = {
  info: any;
  disabled: boolean;
  limit: {
    /** 状态码 */
    errcode: number;
    /** 说明 */
    errmsg: string;
    /** 提审剩余次数 */
    rest: number;
    /** 当月分配的剩余次数 */
    limit: number;
    /** 剩余加急次数 */
    speedup_rest: number;
    /** 当月分配的加急次数 */
    speedup_limit: number;
  };
  /** 最新提交记录 */
  newCommitRecord: any;
  update: () => void;
  setIfPreview: (b) => void;
};

/** 当前版本 */
function OnV({ info, setIfPreview, newCommitRecord, disabled }: VPar) {
  let v: string;
  let pushTime: string;
  let addTime: string;
  let imageUrl: string;
  if (newCommitRecord && newCommitRecord.pushState == 1) {
    v = newCommitRecord.version;
    pushTime = newCommitRecord.pushTime;
    addTime = newCommitRecord.addTime;
    imageUrl = BaseApiCon.getImageURL(info.saveqrcode_url);
  }

  return (
    <div className={styles.item}>
      <VersionTitle title="线上版本" alert="系统当前使用中的小程序版本" />
      {v ? (
        <>
          <div className={styles.vertion}>v{v}</div>
          <span className={styles.time}>于{pushTime}创建版本</span>
          <img className={styles.img} src={imageUrl} alt="" />
        </>
      ) : (
        <div className={styles.no_vertion}>
          <div></div>
          <span>尚未提交线上版本</span>
          <div></div>
        </div>
      )}
    </div>
  );
}

/** 审核版本 */
function AuditV({
  info,
  limit,
  newCommitRecord,
  update,
  setIfPreview,
  disabled,
}: VPar) {
  //最近的提交记录
  const [ifShowUndocodeaudit, setIfShowUndocodeaudit] = useState(false);

  let v: string;
  let commitTime: string;
  /** 0:未提交；1：审核中；2：审核通过；3审核失败4审核撤回；5:审核延后 */
  let auditState: 0 | 1 | 2 | 3 | 4 | 5;
  let addTime: string;
  let auditMsg: string;
  if (newCommitRecord && newCommitRecord.pushState == 2) {
    v = newCommitRecord.version;
    commitTime = newCommitRecord.addTime;
    auditState = newCommitRecord.auditState;
    addTime = newCommitRecord.addTime;
    auditMsg = newCommitRecord.auditMsg;
  }

  /** 审核类型 */
  let submitType: {
    type: "com" | "speedup" | "no";
    text: string;
  };
  if (limit.speedup_rest > 0) {
    submitType = {
      type: "speedup",
      text: "加急",
    };
  } else if (limit.rest > 0) {
    submitType = {
      type: "com",
      text: "普通",
    };
  } else {
    submitType = {
      type: "no",
      text: "审核次数已用完",
    };
  }

  const Submit_audit = () => {
    if (disabled) {
      return false;
    }
    let _submitAuditP: Promise<any>;
    //判断还有多少审核次数
    if (submitType.type == "speedup") {
      _submitAuditP = WxShopApiCon.Submit_Speedupaudit();
    } else if (submitType.type == "com") {
      _submitAuditP = WxShopApiCon.Submit_audit();
    } else {
      message.warn("本月提审次数已用完，不能再提审了");
      return;
    }
    _submitAuditP
      .then(() => {
        message.success("提交成功");
      })
      .catch(({ returnMessage }) => {
        message.error(returnMessage);
      })
      .finally(() => {
        update();
      });
  };

  return (
    <>
      <div className={styles.item}>
        <VersionTitle title="审核版本" alert="提交至微信官方审核的小程序版本" />
        {v ? (
          <>
            <div className={styles.vertion}>v{v}</div>
            {auditState == 0 && <span>于{addTime}上传代码</span>}
            {auditState != 0 && (
              <span className={styles.time}>于{commitTime}提交审核</span>
            )}
            {auditState == 1 && (
              <span className={styles.onAudit}>
                微信审核中，请耐心等待。一般审核周期为1-7个工作日，审核通过后就能发布了。
              </span>
            )}
            {/^[345]$/.test(auditState + "") && (
              <>
                <div className={styles.failure}>
                  {
                    {
                      3: <Tag color="#ff0c00">审核失败</Tag>,
                      4: <Tag color="#ffa500">审核撤回</Tag>,
                      5: <Tag color="#ffa500">审核延后</Tag>,
                    }[auditState]
                  }
                  <div className={styles.alert}>
                    {auditMsg.split("<br>").map((item) => {
                      return <span>{item.replace(/\\/g, "")}</span>;
                    })}
                  </div>
                </div>
              </>
            )}
            <div className={styles.button}>
              <Button
                onClick={() => {
                  setIfPreview(true);
                }}
              >
                版本预览
              </Button>
              {(() => {
                if (auditState != 1 && auditState != 2) {
                  if (auditState == 0) {
                    return (
                      <Button type="primary" onClick={Submit_audit}>
                        提交审核[{submitType.text}]
                      </Button>
                    );
                  } else {
                    return (
                      <Button type="primary" onClick={Submit_audit}>
                        重新审核[{submitType.text}]
                      </Button>
                    );
                  }
                } else if (auditState == 2) {
                  return (
                    <Button
                      type="primary"
                      onClick={() => {
                        WxShopApiCon.ReleaseVersion()
                          .then(() => {
                            message.success("发布成功");
                          })
                          .catch(({ returnMessage }) => {
                            message.error(returnMessage);
                          })
                          .finally(() => {
                            update();
                          });
                      }}
                    >
                      发布
                    </Button>
                  );
                } else {
                  return (
                    <Button
                      type="primary"
                      danger
                      onClick={() => {
                        setIfShowUndocodeaudit(true);
                      }}
                    >
                      撤销审核
                    </Button>
                  );
                }
              })()}
            </div>
          </>
        ) : (
          <div className={styles.no_vertion}>
            <div></div>
            <span>尚未提交版本审核</span>
            <div></div>
          </div>
        )}
      </div>
      <ConfirmModal
        visible={ifShowUndocodeaudit}
        title="温馨提示"
        subTitle="确认撤销小程序审核"
        message="一个月内只能提交一定次数的审核，超过这个次数后本月将不能提交审核了，确认撤销小程序审核？"
        close={(r) => {
          if (r) {
            //
            WxShopApiCon.Undocodeaudit()
              .then(() => {
                message.success("撤销成功");
              })
              .catch(({ returnMessage }) => {
                message.error(returnMessage);
              })
              .finally(() => {
                update();
              });
          }
          setIfShowUndocodeaudit(false);
        }}
      />
    </>
  );
}

/** 新版本 */
function NewV({
  info,
  limit,
  setIfPreview,
  newCommitRecord,
  update,
  disabled,
}: VPar) {
  //最新版本信息
  const [newVerstionData, setNewVerstionData] = useState(null);
  const [loading, setLoading] = useState(false);

  let addTime: string;
  let version: string;
  /** 0:未提交；1：审核中；2：审核通过；3审核失败4审核撤回；5:审核延后 */
  let auditState: 0 | 1 | 2 | 3 | 4 | 5;
  if (newCommitRecord && newCommitRecord.pushState == 2) {
    auditState = newCommitRecord.auditState;
    addTime = newCommitRecord.addTime;
    version = newCommitRecord.version;
  }

  useEffect(() => {
    WxSystemApiCon.GetNewtemplate()
      .then((data) => {
        setNewVerstionData(data);
      })
      .catch(({ returnMessage }) => {
        message.error(returnMessage);
      });
  }, []);

  if (!newVerstionData) {
    return (
      <div className={styles.loading}>
        <Spin />
      </div>
    );
  }
  return (
    <div className={styles.item}>
      <VersionTitle title="系统最新版本" alert="系统小程序最新可用版本" />
      <div className={styles.vertion}>v{newVerstionData.user_version}</div>
      {addTime && <span className={styles.time}>于{addTime}创建版本</span>}
      {auditState == 1 && (
        <span className={styles.alert}>
          有版本正在审核，暂时不能上传新的版本
        </span>
      )}
      {version != newVerstionData.user_version ? (
        <div className={styles.button}>
          <Button
            disabled={auditState == 1}
            type="primary"
            loading={loading}
            onClick={() => {
              if (disabled) {
                return false;
              }
              setLoading(true);
              WxShopApiCon.Committemplate({
                template_id: newVerstionData.template_id,
                user_version: newVerstionData.user_version,
                user_desc: newVerstionData.user_desc,
              })
                .then(() => {
                  message.success("上传成功");
                  update();
                })
                .catch((data) => {
                  message.error(data.returnMessage);
                })
                .finally(() => {
                  setLoading(false);
                });
            }}
          >
            上传代码
          </Button>
        </div>
      ) : (
        <span className={styles.new_vertion}>暂无版本需要更新</span>
      )}
    </div>
  );
}

function VersionTitle({ title, alert }) {
  return (
    <>
      <li></li>
      <div className={styles.title}>
        <span>{title}</span>
        <Tooltip placement="top" title={alert}>
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    </>
  );
}
