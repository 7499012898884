import { Tools, axios } from "../config";
import { effeative } from "./common_logic";
export class AfterSalesLogic{
    async addData(params) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress(""),
            data: params,
        }))
        return r;
    }
    async updateData(params) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress(""),
            data: params,
        }))
        return r;
    }
    async getList(params){
        const r = effeative(await axios({
            method: 'POST',
            url: Tools.getApiAddress(''),
            data: params
        }))
        return r
    }
}