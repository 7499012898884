// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../config";

export class PrintingLogic {
    async steelyardgetParam() {
        const r = await axios({
            method: "GET",
            url: Tools.getApiAddress("Printing_get"),
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })
        return r.data.data;
    }
    async steelyardgetAdd(param) {
        const r = await axios({
            method: "POST",
            url: Tools.getApiAddress("Printing_set"),
            data:param,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })
        return r.data;
    }
}