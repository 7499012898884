import Cookies from "js-cookie";
import { Tools, axios } from "../config";
import { effeative } from "./common_logic";

/**
 * 广告逻辑
 */
export class AdLogin {
    /** 获取图片路径 */
    static getImgUrl(_url: string): string {
        return Tools.getImageAddress(_url);
    }
    /** 添加广告 */
    static async Add(params): Promise<any> {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("ad.Add"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r.data;
    }
    /** 删除广告 */
    static async Delete(id: string): Promise<any> {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("ad.Delete") + `?id=${id}`,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r.data;
    }
    /** 编辑广告 */
    static async Update(params): Promise<any> {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("ad.Update"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r.data;
    }
    /** 分页查询 */
    static async QueryPages(params): Promise<any> {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("ad.QueryPages"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r.data;
    }
    /** 查询 */
    static async Query(): Promise<any> {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("ad.Query"),
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r.data;
    }
    /** 开关广告 */
    static async AdvertisementsOpenOrClose(params): Promise<any> {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("ad.AdvertisementsOpenOrClose"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r.data;
    }
    /** 查询是否开启副屏 */
    static async QueryIsOpenExtendScreen(): Promise<any> {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("ad.QueryIsOpenExtendScreen"),
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r.data;
    }
    /** 开启或关闭副屏 */
    static async ExtendScreenOpenOrClose(_if: boolean): Promise<any> {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("ad.ExtendScreenOpenOrClose") + `?isOpen=${_if.toString()}`,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r.data;
    }
    /** 上传图片 */
    static async updateImg(file: File): Promise<string> {
        let _formData = new FormData();
        _formData.set('file', file);
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("ad.UploadImages"),
            data: _formData,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r.data.data;
    }
}