import { createContext, useContext, useReducer } from "react";

export const ChooseGoodsPurchaseStore = createContext<IContextProps>(null);

interface IContextProps {
    state: IState;
    dispatch: ({ type }: Dispatch) => void;
}

type Dispatch = { type, payload }

class Search {
    keywords: string;
    classesId: string;
    statusId: string;
};

interface IState {
    areaList: any[],
    statusList: any[],
    typeList: any[],
    search: Search;
}

const initialArgs = {
    diff: 0,
    dataList: [],
    areaList: [],
    statusList: [
        { id: -1, value: "全部" },
        { id: 1, value: "正常" },
        { id: 2, value: "停用" }
    ],
    typeList: [
        { id: -1, value: "全部" },
        { id: 1, value: "加盟" },
        { id: 2, value: "直营" }
    ],
    search: new Search()
}

function reducer(state: IState, { type, payload }: Dispatch) {
    switch (type) {
        case "SetSearch":
            return {
                ...state,
                search: {
                    ...state.search,
                    ...payload
                }
            }
        case "SetAreaList":
            return {
                ...state,
                areaList: payload
            }
        default:
            return state;
    }
}

export function ChooseStoreProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialArgs);

    const v = { state, dispatch };
    return <ChooseGoodsPurchaseStore.Provider value={v}>{props.children}</ChooseGoodsPurchaseStore.Provider>
}