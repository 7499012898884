import moment from "moment";
import { createContext, useContext, useReducer } from "react";
import { GoodsEntity } from "../../../../entity/goods_entity";
import { ShopEntity } from "../../../../entity/shop_entity";
import { UnitLogic } from "../../../../logic/unit_logic";

export const InventoryGoodsFormStore = createContext<IContextProps>(null);

interface IContextProps {
  state: IState;
  dispatch: ({ type }: Dispatch) => void;
}

type Dispatch = { type; payload };

class Search {
  keyword: string;
  areaId: string;
  statusId: string;
  typeId: string;
}
export class FormData {
  keyword: string;
  shop = new ShopEntity();
  remark: string;
  userName: string;
  inStockUnitPrice: number;
  date: string = moment().format("YYYY-MM-DD HH:mm:ss");
  checkNumber: number;
  reasonId: number = 1;
}

export class TableItem {
  serial: number;
  goodsID: string;
  goodsSepID: string;
  goodsCode: string;
  goodsName: string;
  unitName: string;
  systemNumber: number;
  checkNumber: number;
  averagePrice: number;
  differenceNumber: number;
  differencePrice: number;
  unitNameId: string;
  goodsSpecifications: any[];
  // 批次号
  storageBatchNumber: string

  constructor(data) {
    {
      const {
        goodsID,
        id,
        goodsNo,
        goodsSepID,
        goodsUnitID,
        goodsName,
        goodsUnit,
        sumNumber,
        inventoryPrice,
        differenceNumber,
        checkNumber,
        differencePrice,
        unitName,
        sysNumber,
        inventoryAvg,
        storageBatchNumber
      } = data;
      this.goodsID = goodsID || id;
      this.goodsCode = goodsNo;
      this.goodsName = goodsName;
      this.goodsSepID = goodsSepID;
      this.unitNameId = goodsUnit ? goodsUnit : goodsUnitID;
      this.unitName = unitName;
      this.systemNumber = sysNumber || sumNumber || 0;
      this.averagePrice = inventoryPrice || inventoryAvg || 0;
      
      // init custom
      this.checkNumber = checkNumber | 0;
      this.differenceNumber = differenceNumber | 0;
      this.differencePrice = differencePrice | 0;

      this.goodsSpecifications = data.goodsSpecifications;
      this.storageBatchNumber = storageBatchNumber

      this.countRealNumber(this.checkNumber);
    }
  }
  countRealNumber(value: number) {
    this.checkNumber = value;

    this.differenceNumber = Number(
      (this.checkNumber - this.systemNumber).toFixed(3)
    );
    this.differencePrice = Number(
      (this.differenceNumber * this.averagePrice).toFixed(2)
    );
  }
}

export class IState {
  searchList: any[];
  formData: FormData;
  search: Search;
  tableList: TableItem[];
  shoplist: any[];
  code: string;
}

const initialArgs: IState = {
  searchList: [],
  formData: new FormData(),
  search: new Search(),
  tableList: [],
  shoplist: [],
  code: "",
};

function reducer(state: IState, { type, payload }: Dispatch): IState {
  switch (type) {
    case "InputCode":
      state.code = payload;
      console.log(payload);
      return {
        ...state,
      };
    case "LoadShop":
      return {
        ...state,
        shoplist: payload,
      };
    /** 更新 */
    case "update":
      return {
        ...state,
      };

    case "SetSearchList":
      return {
        ...state,
        searchList: payload,
      };
    case "SetFormData": {
      return {
        ...state,
        formData: {
          ...state.formData,
          ...payload,
        },
      };
    }
    case "SetForm": {
      const { shopID, shopName, remark, detail, cause, addDateTime } = payload;
      let formData = new FormData();
      formData = state.formData;

      // shop
      const shopItem = new ShopEntity();
      shopItem.id = shopID;
      shopItem.shopName = shopName;
      formData.date = addDateTime;
      formData.shop = shopItem;
      formData.remark = remark;
      formData.reasonId = cause;

      const tableList = detail.map((v) => new TableItem(v));
      tableList.length > 0 && tableList.forEach((v, i) => (v.serial = i));
      return {
        ...state,
        formData,
        tableList: [...tableList],
      };
    }
    case "AddTableItem": {
      const list = state.tableList || [];
      // console.log(payload);
      // 去重
      if (list.every((v, i) => v.storageBatchNumber !== payload.storageBatchNumber)) {
        payload["sysNumber"] = payload.sumNumber || 0;
        payload["inventoryAvg"] = payload.inventoryPrice || 0;
        list.push(payload);
        list.length > 0 && list.forEach((v, i) => (v.serial = i));
        return {
          ...state,
          tableList: [...list],
        };
      }
      return state;
    }
    case "DelTableItem": {
      const list = state.tableList || [];

      list.splice(payload, 1);
      list.length > 0 && list.forEach((v, i) => (v.serial = i));
      return {
        ...state,
        tableList: [...list],
      };
    }
    case "ChangeTableItem": {
      const { index, key, value } = payload;

      const list = state.tableList || [];

      const item = list[index];

      // item[key] = Number(value);

      // if (false == Boolean(item.differencePrice)) {
      //   item.differencePrice = 0;
      // }
      // if (false == Boolean(item.differenceNumber)) {
      //   item.differenceNumber = 0;
      // }
      if (value == "" || value == undefined || value == null) {
        item[key] = "";
        item.differenceNumber = item.systemNumber || 0;
        item.differencePrice = Number(
          (item.differenceNumber * item.averagePrice).toFixed(2)
        );
      } else {
        item[key] = Number(value);
        if (Number(value) < 0) {
          item[key] = 0;
        }
        let num = (item.checkNumber - (item.systemNumber || 0)).toFixed(3);
        item.differenceNumber = Number(num);
        let price = (
          (item.checkNumber - (item.systemNumber || 0)) *
          item.averagePrice
        ).toFixed(2);
        item.differencePrice = Number(price);
      }

      return {
        ...state,
        tableList: [...list],
      };
    }
    case "ClearTableList":
      return {
        ...state,
        tableList: [],
      };
    default:
      return state;
  }
}

export function InventoryGoodsFormStoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialArgs);

  const v = { state, dispatch };
  return (
    <InventoryGoodsFormStore.Provider value={v}>
      {props.children}
    </InventoryGoodsFormStore.Provider>
  );
}
