import { Button, Col, Form as FormAntd, Input, Row, Select } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useState } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { FormSubtitle } from "../../../commodity/view/form_subtitle";
import styles from "../../style/inventory_goods_formbasic.module.scss";
import { TextArea } from "../../../../component/text-area";
import img_del from "../../../../assets/images/shanchu.png";
// import { warehousingformAction } from "../../action/in-stock/warehousing_form_action";
import { useBoolean } from "ahooks";
import { ChooseGoods } from "../../../../component/choose-goods";
import { Table } from "../../../../component/table";
import { ChooseShop } from "../../../../component/choose-shop";
import { inventoryGoodsFormAction } from "../../action/withdraw/inventory_goods_form_action";
import { InventoryGoodsFormStore } from "../../store/withdraw/inventory_goods_form_store";
import { TableExpand } from "../../../../component/table-expand";
import { UnitLogic } from "../../../../logic/unit_logic";
import { autoRem } from "../../../../global.style";
import { NumberTool } from "../../../../tool/numberTool";

const { Option } = Select;

export function InventoryGoodsFormBasic({
  disabled,
  updateFormField,
  data,
  close,
}) {
  const [shopState, shopFunc] = useBoolean(false);
  const [goodsState, goodsFunc] = useBoolean(false);
  const [item, setItem] = useState(data);
  const { state, dispatch } = useContext(InventoryGoodsFormStore);
  const action = inventoryGoodsFormAction;

  let height = document.body.clientHeight - 188 + 26;
  if (!item) {
    close();
  }
  return (
    <div className={styles.root} style={{ height: height + "px" }}>
      <div style={{ margin: "1.9375rem 0 0 20px" }} className={styles.content}>
        <FormSubtitle>基本信息</FormSubtitle>

        <Row gutter={24} style={{ marginTop: 10 }}>
          <Col span={8}>
            <FormAntd.Item label="提现用户" labelCol={{ span: 10 }}>
              <span>{item.memberName}</span>
            </FormAntd.Item>
          </Col>
          <Col span={8}>
            <FormAntd.Item label="提交时间" labelCol={{ span: 10 }}>
              <span>{item.addTime}</span>
            </FormAntd.Item>
          </Col>

          {/* <Col span={6}>
            <FormAntd.Item label="会员姓名" labelCol={{ span: 8 }}>
              <span>{item.memberName}</span>
            </FormAntd.Item>
          </Col> */}
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <FormAntd.Item label="提现金额" labelCol={{ span: 10 }}>
              <span>{item.money}</span>
            </FormAntd.Item>
          </Col>
          <Col span={8}>
            <FormAntd.Item label="手续费" labelCol={{ span: 10 }}>
              <span>{item.premium}</span>
            </FormAntd.Item>
          </Col>
          <Col span={8}>
            <FormAntd.Item label="实际到账金额" labelCol={{ span: 10 }}>
              {/* <span>{1 === item.orderStatusString ? "完成" : "退单"}</span> */}
              <span>{item.actualMoney}</span>
            </FormAntd.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          {"1" != item.auditStatus && (
            <Col span={8}>
              <FormAntd.Item label="审核人" labelCol={{ span: 10 }}>
                <span>{item.auditUserID}</span>
              </FormAntd.Item>
            </Col>
          )}
          {"1" != item.auditStatus && (
            <Col span={8}>
              <FormAntd.Item label="审核时间" labelCol={{ span: 10 }}>
                <span>{item.auditTime}</span>
              </FormAntd.Item>
            </Col>
          )}
          <Col span={8}>
            <FormAntd.Item label="审核状态" labelCol={{ span: 10 }}>
              {"1" == item.auditStatus && <span>待审核</span>}
              {"2" == item.auditStatus && <span>通过(放款中)</span>}
              {"3" == item.auditStatus && <span>拒绝</span>}
              {"4" == item.auditStatus && <span>已到账</span>}
              {"5" == item.auditStatus && <span>已撤销</span>}
            </FormAntd.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={20}>
            <FormAntd.Item
              name="remarks"
              label="审核说明"
              labelCol={{ span: 4 }}
            >
              <TextArea
                style={{ resize: "none" }}
                maxLength={150}
                disabled={disabled}
                value={item.auditRemark}
                rows={4}
                onChange={(v) => {
                  dispatch({ type: "SetRemark", payload: v });
                }}
              ></TextArea>
            </FormAntd.Item>
          </Col>
        </Row>
      </div>
    </div>
  );
}
