import { ColumnsType } from "antd/lib/table";
import { Image, Tag, message } from "antd";
import { useContext, useEffect, useState } from "react";
import { useBoolean, useRequest } from "ahooks";

import styles from "../../style/file.module.scss";
import { ConfirmModal, Modal } from "../../../../component/modal";
import { Control, Del, Edit, See } from "../../../../component/control-column";
import { Tools } from "../../../../config";
import img_def from "../../../../assets/images/goods/goods_def.jpg";
import { Mask } from "../../../../component/mask";
import { Table } from "../../../../component/table";
import { fileAction } from "../../action/goodsClassifyAction/file_action";
import { defualtPageSize, Progress } from "../../../../logic/common_logic";
import { FileStore } from "../../store/goods_classify_store/file_store";
import { DataTableStore } from "../../../../component/table/store/data_table_store";
import { DataTable } from "../../../../component/table/view/data_table";
import { autoRem } from "../../../../global.style";
import { AuthLogic } from "../../../../logic/auth_logic";
import { Form } from "./form";
import { FormStoreProvider } from "../../store/goods_classify_store/form_store";
import { formAction } from "../../action/goodsClassifyAction/form_action";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { Button } from "../../../../component/button";
const formParams = {
  method: "Create",
  id: "",
};
const imgParams = {
  url: "",
};

export function GoodsClassify(viewPower) {
  const action = fileAction;
  const { state, dispatch } = useContext(FileStore);
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);

  const [isModalVisible, isModalFunc] = useBoolean(false);
  const [createState, createFunc] = useBoolean(false);
  const [confirmState, confirmFunc] = useBoolean(false);
  //   初始化
  useRequest(() => action.init({ dispatch }));
  const onSearch = async (pageIndex) => {
    await action.loadPage({
      pageIndex: pageIndex,
      pageSize: defualtPageSize(),
      dispatch: tableDispatch,
      search: state.search,
    });
  };
  //   权限
  const [menuButton, setData] = useState([]);
  useEffect(() => {
    dispatch({ type: "isloading", payload: false });
    (async function () {
      let r = await new AuthLogic().selButton(viewPower.viewPower);
      if (r && r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          r[i] = r[i].split("_")[r[i].split("_").length - 1];
        }
      }
      setData(r);
    })();
    return () => {
      dispatch({ type: "isloading", payload: false });
    };
  }, []);

  let obj: any = {};
  if (menuButton.indexOf("VIEW") != -1) {
    obj["see"] = (v) => {
      formParams.id = v;
      formParams.method = "Preview";

      createFunc.setTrue();
    };
  }
  if (menuButton.indexOf("UPDATE") != -1) {
    obj["edit"] = (v, goodsSource) => {
      formParams.id = v;
      formParams.method = "Edit";
      if(goodsSource == 2){
        message.warning('采购商品不能修改')
      }else{
        createFunc.setTrue();
      }
      // createFunc.setTrue();
    };
  }
  if (menuButton.indexOf("DELETE") != -1) {
    obj["del"] = (v, goodsSource) => {
      formParams.id = v;
      if(goodsSource == 2){
        message.warning('采购商品不能删除')
      }else{
        confirmFunc.setTrue();
      }
    };
  }
  const columns: ColumnsType = [
    {
      title: "操作",
      // dataIndex: "id",
      key: "1",
      align: "center",
      width: Control.width(5),
      render: Control.render(
        (v) => <See click={() => obj.see(v.id, v.goodsSource)} />,
        (v) => <Edit click={() => obj.edit(v.id, v.goodsSource)} />,
        (v) => <Del click={() => obj.del(v.id, v.goodsSource)} />
      ),
    },
    {
      title: "分类图标",
      // dataIndex: "iconPath",
      key: "2",
      align: "center",
      render: (v) => (
        <div
          style={{
            width: 80,
            height: 80,
            margin: ".625rem auto",
            cursor: "pointer",
          }}
          onClick={() => {
            imgParams.url = v.iconPath ? Tools.getImageAddress(v.iconPath, v.goodsSource) : img_def;
            isModalFunc.setTrue();
          }}
        >
          <img
            src={v.iconPath ? Tools.getImageAddress(v.iconPath, v.goodsSource) : img_def}
            width={80}
            height={80}
            alt=""
          />
        </div>
      ),
    },
    {
      title: "分类名称",
      dataIndex: "classesName",
      key: "3",
      align: "center",
    },
    {
      title: "门店是否展示",
      dataIndex: "shopShow",
      key: "4",
      align: "center",
      render: (v) => (
        <Tag color={v ? "success" : "error"}>{v ? "门店展示" : "门店隐藏"}</Tag>
      ),
    },
    {
      title: "门店序号",
      dataIndex: "shopSort",
      key: "5",
      align: "center",
    },
    {
      title: "商城是否展示",
      dataIndex: "storeShow",
      key: "6",
      align: "center",
      render: (v) => (
        <Tag color={v ? "success" : "error"}>{v ? "商城展示" : "商城隐藏"}</Tag>
      ),
    },
    {
      title: "商城序号",
      dataIndex: "storeSort",
      key: "7",
      align: "center",
    },
    {
      title: "商品来源",
      dataIndex: "goodsSource",
      key: "8",
      align: "center",
      render: (v) => (
        <>
          {v == 1 && <Tag color="blue">自定义</Tag>}
          {v == 2 && <Tag color="red">仓库采购</Tag>}
        </>
      ),
    },
  ];
  return (
    <>
      <ButtonPanel>
        {menuButton.indexOf("ADD") != -1 && (
          <Button
            onClick={() => {
              formParams.id = "";
              formParams.method = "Create";

              createFunc.setTrue();
            }}
          >
            新建分类
          </Button>
        )}
      </ButtonPanel>
      <DataTable
        columns={columns}
        action={action}
        onLoad={async () => {
          await onSearch(1);
          Progress.done();
        }}
        onChange={(pageIndex) => {
          onSearch(pageIndex);
        }}
        scroll={{
          scrollToFirstRowOnChange: true,
          autoY: true,
          y: autoRem(53),
        }}
        loading={tableState.loading}
        selectColumn={false}
      />
      <Modal
        className={styles.root}
        visible={isModalVisible}
        onOk={() => isModalFunc.setFalse()}
        onCancel={() => isModalFunc.setFalse()}
        centered
        width={"auto"}
      >
        <img src={imgParams.url} />
      </Modal>
      {createState && (
        <FormStoreProvider>
          <Form
            close={(hasUpdate) => {
              if (hasUpdate) {
                onSearch(tableState.current);
              }

              createFunc.setFalse();
            }}
            action={formAction}
            id={formParams.id}
            method={formParams.method}
          />
        </FormStoreProvider>
      )}
      <ConfirmModal
        visible={confirmState}
        title="温馨提示"
        subTitle="确认删除？"
        message="删除之后信息无法恢复，请慎重考虑"
        close={async (r) => {
          if (r) {
            await action.delRow(formParams.id);

            onSearch(tableState.current);
          }
          confirmFunc.setFalse();
        }}
      />
    </>
  );
}
