import { Cascader, Col, Form, Form as FormAntd, Input, Radio, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useContext, useEffect, useState } from "react";
import { RadioStyle } from "../../../../component/radio-style";
import { formAction } from "../../action/dict/form_action";
import { FormStore } from "../../store/dict/form_store";
import { InputNumber } from "antd";
import { autoRem } from "../../../../global.style";

export function FormBasic(title) {
  const { state, dispatch: formDispatch } = useContext(FormStore);
  const action = formAction;
  var isLook = false;
  if (title.title == "查看字典") {
    isLook = true;
  } else {
    isLook = false;
  }
  return (
    <>
      <div style={{ margin: "1.9375rem 0 0 0" }}>
        <Row gutter={16}>
          <Col span={11}>
            <Form.Item
              name="dict-option"
              label="字典名称"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: true,
                  message: "请输入字典名称",
                },
              ]}
            >
              <Input
                disabled={isLook}
                maxLength={20}
                placeholder="请输入字典名称"
                onChange={(v) =>
                  action.setValue({
                    dispatch: formDispatch,
                    value: v.target.value,
                    attribute: "Option",
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              name="dict-label"
              label="标签"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: true,
                  message: "请输入标签",
                },
              ]}
            >
              <Input
                maxLength={20}
                disabled={isLook}
                placeholder="请输入标签"
                onChange={(v) =>
                  action.setValue({
                    dispatch: formDispatch,
                    value: v.target.value,
                    attribute: "Label",
                  })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={11}>
            <Form.Item
              name="dict-worth"
              label="值"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: true,
                  message: "请输入值",
                },
              ]}
            >
              <Input
                maxLength={20}
                disabled={isLook}
                placeholder="请输入值"
                onChange={(v) =>
                  action.setValue({
                    dispatch: formDispatch,
                    value: v.target.value,
                    attribute: "Value",
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              name="dict-sortIndex"
              label="排序"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: true,
                  message: "请输入排序",
                },
              ]}
            >
              <InputNumber
                disabled={isLook}
                size="large"
                onChange={(v) =>
                  action.setValue({
                    dispatch: formDispatch,
                    value: v,
                    attribute: "SortIndex",
                  })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={22}>
            <Form.Item name="remark" label="备注" labelCol={{ span: 4 }}>
              <TextArea
                disabled={isLook}
                rows={2}
                onChange={(v) =>
                  action.setValue({
                    dispatch: formDispatch,
                    value: v.target.value,
                    attribute: "Remark",
                  })
                }
              ></TextArea>
            </Form.Item>
          </Col>
        </Row>
      </div>
    </>
  );
}
