import styles from "../style/advone.module.scss";
import { useContext, useRef, useEffect } from "react";
import { TopicStore, ITopicValue } from "../store/topic";
import { TopicDataAction } from "../action/TopicDataAction";
import { ApiCon } from "./../com/apiCon";
import defaultImgUrl from "../../../assets/images/mini/group_350_90.png";
import { message, Input, Select, Modal, Tag } from "antd";
import { autoRem } from "../../../global.style";
import { useBoolean } from "ahooks";
import { topicfileAction } from "../com/topic_logic";
import {
  DataTableStore,
  DataTableStoreProvider,
} from "../../../component/table/store/data_table_store";
import { ColumnsType } from "antd/lib/table";
import { DataTable } from "../../../component/table";
import { fileAction } from "../../marketing/action/booking/file_action";
import {
  FileStoreProvider,
  FileStore,
} from "../../marketing/store/booking/file_store";
import { Search } from "../../../component/search";
import { Tips, TipsGroup } from "../../../component/tips";
import { Button } from "../../../component/button";
import { FileSearch } from "../../marketing/view/booking/file_search";
import { Mask } from "../../../component/mask";
interface IData {
  fileUrl: string;
  fileName: string;
  linkUrl: string;
  linkType: number;
  ID?: string;
  linkName?: string;
}

export function AdvOne({ id, con }) {
  const { Option } = Select;
  const [modleState, modalFunc] = useBoolean(false);
  const [modlePresaleState, presaleFunc] = useBoolean(false);
  const topicStoreState = useContext(TopicStore);
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);
  let actionArg: [ITopicValue, number] = [topicStoreState, id];
  const data: IData = TopicDataAction.getData<IData>(...actionArg);
  const inputRef = useRef(null);

  /** 选择图片 */
  const selectImage = () => {
    inputRef.current.click();
  };
  const inputChange = () => {
    const _file = inputRef.current.files[0];
    ApiCon.uploadImgGetUrl(_file).then((res) => {
      data.fileUrl = res;
      data.fileName = _file.name;
      TopicDataAction.setData<any>(...actionArg, data);
    });
  };
  const changeLinkType = (e) => {
    data.linkType = e.target.value;
    data.linkUrl = "";
    TopicDataAction.setData<any>(...actionArg, data);
    if (e.target.value == 3) {
      modalFunc.setTrue();
    }
  };
  const handleOk = () => {
    console.log(tableState.selectList);
    data.linkUrl = "/pages/topic/topic?id=" + tableState.selectList[0].id;
    TopicDataAction.setData<any>(...actionArg, data);
    modalFunc.setFalse();
  };
  const handleCancel = () => {
    modalFunc.setFalse();
  };

  const handleCancelPresale = () => {
    presaleFunc.setFalse();
  };
  useEffect(() => {
    if (!data) {
      TopicDataAction.setData<IData>(...actionArg, {
        fileUrl: "",
        fileName: "",
        linkUrl: "",
        linkType: 1,
      });
    }
  });
  if (!data) {
    return <div></div>;
  }
  TopicDataAction.setEF(topicStoreState, id, () => {
    return new Promise((r, e) => {
      if (data.fileUrl == "") {
        message.error("广告横幅没有选择图片");
      } else if (
        data.linkUrl == "" ||
        data.linkUrl == null ||
        data.linkUrl == undefined
      ) {
        message.error("广告横幅没有填写链接");
      } else if (
        data.linkType == 1 &&
        data.linkUrl == "预售活动" &&
        (data.ID == null || data.ID == undefined || data.ID == "")
      ) {
        message.error("请选择预售活动");
      } else {
        ApiCon.turnBlobImgUrl({
          url: data.fileUrl,
          name: data.fileName,
        }).then((urls) => {
          r({
            ...data,
            fileUrl: urls[0],
          });
        });
      }
    });
  });

  if (con == "show") {
    return (
      <>
        <div className={styles.advone}>
          <img src={ApiCon.getImageUrl(data.fileUrl) || defaultImgUrl} alt="" />
        </div>
      </>
    );
  } else {
    return (
      <div className={styles.advone_ctrl}>
        <h2>广告横幅设置</h2>
        <div className={styles.right}>
          <img
            src={ApiCon.getImageUrl(data.fileUrl) || defaultImgUrl}
            alt=""
            style={{ marginTop: autoRem(1) }}
          />
          <span>建议图片宽度为：300*90 </span>
          {/* <span>链接跳转为积分商城</span> */}
          <input
            ref={inputRef}
            onChange={inputChange}
            type="file"
            accept="image/*"
            style={{ display: "none" }}
          />
          <button onClick={selectImage} className={styles.a}>
            选择文件
          </button>
          <div>
            <div className={styles.input_radio}>
              <input
                type="radio"
                name="linktype"
                value={1}
                id="link2"
                checked={data.linkType == 1}
                onChange={changeLinkType}
              />
              <label htmlFor="link2">小程序内部链接</label>
            </div>

            <div className={styles.input_radio}>
              <input
                type="radio"
                name="linktype"
                value={2}
                id="link1"
                checked={data.linkType == 2}
                onChange={changeLinkType}
              />
              <label htmlFor="link1">自定义链接</label>
            </div>
            {/* <div  className={styles.input_radio}>
                                  <input type="radio" name='linktype' value={3} id='link3' checked={data.linkType == 3}
                                  onChange={changeLinkType}
                                  /><label htmlFor='link3'>专题链接</label>
                          </div> */}
          </div>
          {data.linkType == 2 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: "#8e8e8e",
              }}
            >
              <label htmlFor="link">链接到：</label>
              <Input
                placeholder="填写链接"
                id="link"
                defaultValue={data.linkUrl}
                style={{ width: "250px" }}
                onChange={(e) => {
                  data.linkUrl = e.target.value;
                  TopicDataAction.setData<any>(...actionArg, data);
                }}
              />
            </div>
          )}
          {data.linkType == 1 && (
            <>
              {" "}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "#8e8e8e",
                }}
              >
                <label htmlFor="link">链接到：</label>
                <Select
                  style={{ minWidth: 120 }}
                  value={data.linkUrl}
                  allowClear
                  onChange={(v) => {
                    data.ID = null;
                    data.linkName = null;
                    data.linkUrl = v;
                    TopicDataAction.setData<any>(...actionArg, data);
                  }}
                >
                  <Option value={"积分商城"}>积分商城</Option>
                  <Option value={"优惠券"}>优惠券</Option>
                  <Option value={"预售活动"}>预售活动</Option>
                </Select>
                {data.linkUrl == "预售活动" && (
                  <Button
                    type="primary"
                    color="Yellow"
                    style={{ marginLeft: 10 }}
                    onClick={() => presaleFunc.setTrue()}
                  >
                    选择活动
                  </Button>
                )}
              </div>
              <div style={{ marginTop: autoRem(1) }}>
                {data.ID && (
                  <>
                    活动名称：
                    <span style={{ marginLeft: 10, color: "#02ca74" }}>
                      {data.linkName}
                    </span>
                  </>
                )}
              </div>
            </>
          )}
          <Modal
            title={<div>选择专题页面</div>}
            visible={modleState}
            width={1000}
            onOk={handleOk}
            onCancel={handleCancel}
            okText="确定"
            cancelText="取消"
          >
            <ShowList />
          </Modal>
          <Modal
            title={<div>选择预售活动</div>}
            visible={modlePresaleState}
            width={1000}
            footer={null}
            onCancel={handleCancelPresale}
            okText="确定"
            cancelText="取消"
          >
            <FileStoreProvider>
              <DataTableStoreProvider>
                <PresaleList
                  preSaleTopicStore={topicStoreState}
                  id={id}
                  clo={handleCancelPresale}
                  data={data}
                />
              </DataTableStoreProvider>
            </FileStoreProvider>
          </Modal>
        </div>
      </div>
    );
  }
}
const PresaleList = ({ preSaleTopicStore, clo, data, id }) => {
  const action = fileAction;
  const columns: ColumnsType = [
    {
      title: "活动名称",
      dataIndex: "activityName",
      align: "center",
      width: 140,
    },
    {
      title: "添加人",
      dataIndex: "addName",
      align: "center",
    },
    {
      title: "参与店铺",
      dataIndex: "shopName",
      align: "center",
    },
    // {
    //     title: '是否限制会员等级',
    //     dataIndex: 'isMemberClass',
    //     align: "center",
    // },
    {
      title: "状态",
      // dataIndex: "state",
      align: "center",
      render: (v) => {
        return (
          <>
            <>
              <Tag
                color={
                  v.dataState == 0
                    ? "geekblue"
                    : v.dataState == 1
                    ? "success"
                    : "volcano"
                }
              >
                {v.dataStateString}
              </Tag>
            </>
          </>
        );
      },
    },
    {
      title: "开始日期",
      dataIndex: "beginDate",
      align: "center",
      render: (v) => <span>{v ? v.substring(0, 10) : ""}</span>,
    },
    {
      title: "结束日期",
      dataIndex: "endDate",
      align: "center",
      render: (v) => <span>{v ? v.substring(0, 10) : ""}</span>,
    },
    {
      title: "到货日期",
      dataIndex: "arrivalDate",
      align: "center",
      render: (v) => <span>{v ? v.substring(0, 10) : ""}</span>,
    },
    {
      title: "添加时间",
      dataIndex: "addTime",
      align: "center",
    },
  ];
  //是否打开高级搜索
  const [searchState, searchFunc] = useBoolean(false);
  //是否删除
  //全局state
  const { state, dispatch } = useContext(FileStore);
  //表单state
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);
  const onSearch = async (pageIndex) => {
    searchFunc.setFalse();
    await action.loadPage({
      pageIndex: pageIndex,
      pageSize: 10,
      dispatch: tableDispatch,
      search: state.search,
    });
  };
  /**预售活动选中 */
  const handleOkPreslae = () => {
    data.ID = tableState.selectList[0].id;
    data.linkName = tableState.selectList[0].activityName;
    let actionArg: [ITopicValue, number] = [preSaleTopicStore, id];
    TopicDataAction.setData<any>(...actionArg, data);
    clo();
  };

  return (
    <div className={styles.root}>
      <div className={styles.search}>
        <Search>
          <Input
            allowClear
            placeholder="请输入活动名称"
            onChange={(e) =>
              action.setCondition({
                dispatch,
                key: "seacherKey",
                value: e.target.value,
              })
            }
          />
          <input type="button" value="快速查找" onClick={() => onSearch(1)} />

          <TipsGroup>
            <input
              type="button"
              value="高级查找"
              onClick={() => searchFunc.toggle()}
            />
            <Tips display={searchState} width="48rem">
              <FileSearch onSearch={() => onSearch(1)} />
            </Tips>
            <Mask visible={searchState} onClick={() => searchFunc.setFalse()} />
          </TipsGroup>
        </Search>
      </div>
      <DataTable
        columns={columns}
        action={action}
        onLoad={async () => {
          await onSearch(1);
        }}
        onChange={(pageIndex) => {
          onSearch(pageIndex);
        }}
        scroll={{ scrollToFirstRowOnChange: true, autoY: true }}
        selectColumn={true}
        // rowKey={(record) => record.id}
        rowSelection={{
          type: "radio",
          getCheckboxProps: (record) => ({ disabled: record.dataState == 2 }),
        }}
      />
      {tableState.dataList.length != 0 ? (
        <div style={{ paddingTop: "24px" }}>
          <Button
            style={{
              background: "#f7ba00",
              borderColor: "#f7ba00",
              marginRight: 10,
            }}
            type="primary"
            onClick={() => {
              clo();
            }}
          >
            取消
          </Button>
          <Button
            type="primary"
            onClick={() => {
              handleOkPreslae();
            }}
          >
            确定
          </Button>
        </div>
      ) : null}
    </div>
  );
};
const ShowList = () => {
  const action = topicfileAction;
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);
  let id = localStorage.getItem("tempID");
  const onSearch = async (pageIndex) => {
    await action.loadPage({
      dispatch: tableDispatch,
      pageIndex: pageIndex,
      pageSize: 10,
    });
  };
  const columns: ColumnsType = [
    {
      key: "2",
      title: "专题名称",
      dataIndex: "title",
      align: "center",
    },
    {
      key: "3",
      title: "标签",
      dataIndex: "title",
      align: "center",
    },
  ];
  return (
    <div>
      <DataTable
        columns={columns}
        action={action}
        onLoad={async () => {
          await onSearch(1);
        }}
        onChange={(pageIndex) => {
          onSearch(pageIndex);
        }}
        scroll={{ scrollToFirstRowOnChange: true, autoY: true }}
        selectColumn={true}
        // rowKey={(record) => record.id}
        rowSelection={{
          type: "radio",
          getCheckboxProps: (record) => ({
            disabled: record.isSetIndex || record.id == id,
          }),
        }}
      />
    </div>
  );
};
