// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../config";
import { effeative, maxPageSize } from "./common_logic";

export class UpdatePriceLogic {
    async selData(ID: string) {
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("goods_updateprice_queryone"),
            params: {
                ID
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r.data.data;
    }
    async querysList(param) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("goods_updateprice_querypage"),
            data: param,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    

    async addData(params) {

        return effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("goods_updateprice_add"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))

    }
    async updData(params) {
        return effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("menber_grade_update"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))

    }
    async getResultList(params){
        return effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("goods_updateprice_queryResult"),
            params: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
    }

}