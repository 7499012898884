import { message } from "antd";
// import axios from "axios";
import Cookies from "js-cookie";
import { DataListAction } from "../../../../component/data-list/action/data_table_action";
import { DataTableAction } from "../../../../component/table/action/data_table_action";
import { Tools, axios } from "../../../../config";
import { TaxCodeInfoLogic } from "../../../../logic/taxcode_logic";
import { effeative } from "../../../../logic/common_logic";
import { UnitLogic } from "../../../../logic/unit_logic";
import { saveAs } from "file-saver";
export class FileTableAction extends DataTableAction {
  private taxCodeLgc = new TaxCodeInfoLogic();
  private unitLgc = new UnitLogic();

  async init({ dispatch }) {
   
  }
  async delRow(id) {
    const r = await this.taxCodeLgc.delTaxCodeInfo(id)
    if(r.data.statusCode === 200){
      message.success("删除成功")
    }else{
      message.error(r.data.returnMessage)
    }
  }
  async delSelectList({
    dispatch,
    selectList,
  }: {
    dispatch: any;
    selectList: any;
  }) {
   
  }
  async loadPage({ dispatch, pageIndex, pageSize, search }) {
    this.setLoading({ dispatch, loading: true });
    // 
    let params = {
      pageIndex,
      pageSize,
      seacherKey: search.seacherKey
    }
    let list = []
    let total = 0
    const res = await this.taxCodeLgc.getList(params)
    if(res.statusCode == 200){
        list = res.data.dataSource || []
        total = res.data.totalCount
    }
    this.setPaging({
      dispatch,
      pageIndex,
      total: total,
      dataList: list,
      pageSize,
      loading: false,
    });
  }
  setCondition({ dispatch, key, value}) {
    let params = {}
    params[key] = value
    dispatch({type: 'SetSearch', paylod: params})
  }
}
export const fileAction = new FileTableAction();
