import moment from "moment";
import { createContext, useContext, useReducer } from "react";

export const OutStockFormStore = createContext<IContextProps>(null);

interface IContextProps {
  state: IState;
  dispatch: ({ type }: Dispatch) => void;
}

type Dispatch = { type; payload };

class Search {
  keyword: string;
  areaId: string;
  statusId: string;
  typeId: string;
}
export class FormData {
  keyword: string;
  shopId: string;
  shopName: string;
  remark: string;
  inStockPrice: number;
  inStockNumber: number;
  userName: string;
  inStockUnitPrice: number;
  date: string = moment().format("YYYY-MM-DD HH:mm:ss");
  reasonId: number = 4;
}
export class TableItem {
  serial: number;
  goodsId: string;
  id: string;
  goodsCode: string;
  goodsName: string;
  unitName: string;
  inStockUnitId: string;
  sumNumber: number;
  /**
   * 入库单价
   */
  inStockUnitPrice: number;
  inStockNumber: number;
  inStockPrice: number;
  /** 规格列表 */
  goodsSpecifications: any[] = [];
  // 批次号
  storageBatchNumber: string
}
interface IState {
  searchList: any[];
  formData: FormData;
  search: Search;
  tableList: TableItem[];
  shoplist: any[];
  code: string;
}

const initialArgs: IState = {
  searchList: [],
  formData: new FormData(),
  search: new Search(),
  tableList: [],
  code: "",
  shoplist: [],
};

function reducer(state: IState, { type, payload }: Dispatch) {
  switch (type) {
    case "InputCode":
      state.code = payload;
      console.log(payload);
      return {
        ...state,
      };
    case "LoadShop":
      return {
        ...state,
        shoplist: payload,
      };
    /** 更新 */
    case "update":
      return {
        ...state,
      };

    case "SetSearchList":
      return {
        ...state,
        searchList: payload,
      };
    case "SetFormData": {
      return {
        ...state,
        formData: {
          ...state.formData,
          ...payload,
        },
      };
    }
    case "SetForm": {
      const {
        shopID,
        shopName,
        remark,
        goodsList,
        userName,
        addDateTime,
        reason,
      } = payload;
      const item = new FormData();

      item.shopId = shopID;
      item.shopName = shopName;
      item.remark = remark;
      item.userName = userName;
      item.date = addDateTime;
      item.reasonId = reason;

      const list = [];
      goodsList.forEach(
        ({
          goodsID,
          goodsNo,
          id,
          goodsName,
          goodsUnitID,
          unitName,
          putPrice,
          putNumber,
          putSumPrice,
          // goodsSpecifications,
          sumNumber,
          storageBatchNumber
        }, i) => {
          const item = new TableItem();
          item.goodsId = goodsID;
          item.id = id;
          item.goodsCode = goodsNo;
          item.goodsName = goodsName;
          item.inStockUnitId = goodsUnitID;
          item.unitName = unitName;
          item.inStockUnitPrice = putPrice;
          item.inStockNumber = putNumber;
          item.inStockPrice = putSumPrice;
          item.sumNumber = sumNumber;
          item.storageBatchNumber = storageBatchNumber
          // item.goodsSpecifications = goodsSpecifications;
          item.serial = i
          list.push(item);
        }
      );

      return {
        ...state,
        formData: item,
        tableList: list,
      };
    }
    case "AddTableItem": {
      const list = state.tableList || [];

      // 去重
      if (list.every((v, i) => v.storageBatchNumber !== payload.storageBatchNumber)) {
        const item = new TableItem();
        const {
          goodsNo,
          goodsName,
          id,
          goodsID,
          goodsUnitID,
          unitName,
          inStockUnitPrice,
          inStockNumber,
          inStockPrice,
          sumNumber,
          storageBatchNumber
        } = payload;
        // item.id = id;
        item.goodsId = goodsID || id;
        item.goodsCode = goodsNo;
        item.goodsName = goodsName;
        item.inStockUnitId = goodsUnitID;
        item.unitName = unitName;
        item.inStockNumber = inStockNumber;
        item.inStockUnitPrice = inStockUnitPrice;
        item.inStockPrice = inStockPrice;
        item.sumNumber = sumNumber;
        item.storageBatchNumber = storageBatchNumber
        // item.goodsSpecifications = payload.goodsSpecifications;

        list.push(item);

        list.length > 0 && list.forEach((v, i) => (v.serial = i));
        return {
          ...state,
          tableList: [...list],
        };
      }
      return state;
    }
    case "DelTableItem": {
      const list = state.tableList || [];

      list.splice(payload, 1);
      list.length > 0 && list.forEach((v, i) => (v.serial = i));
      return {
        ...state,
        tableList: [...list],
      };
    }
    case "ChangeTableItem": {
      const { index, key, value } = payload;

      // inStockUnitPrice: number;
      // inStockNumber: number;
      // inStockPrice: number;

      const list = state.tableList || [];

      const item = list[index];

      item[key] = Number(value);
      if (undefined == value || value == "" || value == null) {
        // item[key] = 0;
        item[key] = "";
        item.inStockPrice = 0;
      } else {
        item[key] = Number(value);
        if (Number(value) < 0) {
          item[key] = 0;
        }
        let price = (item.inStockNumber * item.inStockUnitPrice).toFixed(2);
        item.inStockPrice = Number(price);
      }
      // if (undefined == item.inStockPrice) {
      //   item.inStockPrice = 0;
      // }
      // if (undefined == item.inStockNumber) {
      //   item.inStockNumber = 0;
      // }
      // if (undefined == item.inStockUnitPrice) {
      //   item.inStockUnitPrice = 0;
      // }

      // // 入库单价
      // if ("inStockUnitPrice" == key) {
      //   item.inStockPrice = item.inStockNumber * item.inStockUnitPrice;
      // }

      // // 入库数量
      // if ("inStockNumber" == key) {
      //   item.inStockPrice = item.inStockNumber * item.inStockUnitPrice;
      // }

      // // 入库金额
      // if ("inStockPrice" == key) {
      //   item.inStockUnitPrice = item.inStockPrice / item.inStockNumber;
      //   item.inStockUnitPrice = Number(item.inStockUnitPrice.toFixed(2));
      // }

      return {
        ...state,
        tableList: [...list],
      };
    }
    case "ClearTableList":
      return {
        ...state,
        tableList: [],
      };
    default:
      return state;
  }
}

export function OutStockFormStoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialArgs);

  const v = { state, dispatch };
  return (
    <OutStockFormStore.Provider value={v}>
      {props.children}
    </OutStockFormStore.Provider>
  );
}
