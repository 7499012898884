import moment from "moment";
import { createContext, useContext, useReducer } from "react";

export const CouponFormStore = createContext<IContextProps>(null);

interface IContextProps {
  state: IState;
  dispatch: ({ type }: Dispatch) => void;
}

type Dispatch = { type; payload };

class Search {
  keyword: string;
  areaId: string;
  statusId: string;
  typeId: string;
}
export class FormData {
  id: string;
  couponName: string;
  couponDetail: string;

  couponNumber: string;
  couponType: number;
  subMoney: string;
  discount: string;
  validType: number;
  validStartDate: string;
  validEndDate: string;
  validDay: string;
  meetMoney: string;
  useTargetType: number;
  isOverlay: number;
  getCouponType: number;
  range: number;
  validDate: [];

  keyword: string;
  shopID: string[];
  shopName: string;
  remark: string;
  date: string = moment().format("YYYY-MM-DD HH:mm:ss");
}
export class TableItem {
  serial: number;
  barCode: string;//条码
  goodsId: string;
  goodsSepID: string;
  goodsName: string;
  goodsClassName: string;
  goodsUnitName: string;
  goodsNo: string;
  // 分类
  id?: string
  classCode?: string
}
interface IState {
  searchList: any[];
  formData: FormData;
  search: Search;
  tableList: TableItem[];
  classifyList: TableItem[];
  shoplist: any[];
}

export const initialArgs: IState = {
  searchList: [],
  formData: new FormData(),
  search: new Search(),
  tableList: [],
  classifyList: [],
  shoplist: [],
};

function reducer(state: IState, { type, payload }: Dispatch) {
  switch (type) {
    case "LoadShop": {
      return {
        ...state,
        shoplist: payload,
      };
    }
    case "SetSearchList":
      return {
        ...state,
        searchList: payload,
      };

    case "SetFormData": {
      return {
        ...state,
        formData: {
          ...state.formData,
          ...payload,
        },
      };
    }
    case "SetForm": {
      const {
        id,
        couponName,
        couponDetail,
        goodsList,
        couponNumber,
        tableList,
        couponType,
        classifyList,
        discount,
        getCouponType,
        isOverlay,
        meetMoney,
        shopName,
        range,
        shopID,
        subMoney,
        useTargetType,
        validDay,
        validEndDate,
        validStartDate,
        validType,
      } = payload;
      const item = new FormData();

      item.id = id;
      item.couponName = couponName;
      item.couponDetail = couponDetail;
      item.couponNumber = couponNumber;
      item.couponType = couponType;
      item.discount = discount;
      item.getCouponType = getCouponType;
      item.isOverlay = isOverlay;
      item.meetMoney = meetMoney;
      item.range = range;
      item.shopID = shopID;
      item.subMoney = subMoney;
      item.useTargetType = useTargetType;
      item.validDay = validDay;
      item.validType = validType;
      item.validEndDate =
        validEndDate != "" && validEndDate != null
          ? validEndDate.slice(0, 10)
          : "";
      item.validStartDate =
        validStartDate != "" && validStartDate != null
          ? validStartDate.slice(0, 10)
          : "";
      item.shopName = shopName;
      const list = tableList;
      return {
        ...state,
        formData: item,
        tableList: list,
        classifyList: classifyList,
      };
    }
    case "AddclassTableItem": {
      let list = state.classifyList || []
      if (list.every((v, i) => v.classCode !== payload.classCode)) {
        let data = Object.assign({},payload,{
          goodsClassName: payload.title
        })
        data.children = null
        list.push(data)
      }
      list.length > 0 && list.forEach((v, i) => (v.serial = i));
      return {
              ...state,
              classifyList: [...list],
      };
    }
    case "DelTableclassItem": {
      const list = state.classifyList || [];
      list.splice(payload, 1);
      list.length > 0 &&  list.forEach((v, i) => (v.serial = i));
      return {
        ...state,
        classifyList: [...list],
      };
    }
    case "AddTableItem": {
      const list = state.tableList || [];
      // 去重
      if (list.every((v, i) => v.barCode !== payload.barCode)) {
        const item = new TableItem();
        const {
          barCode,
          status,
          id,
          goodsSepID,
          goodsID,
          goodsNo,
          goodsName,
          goodsClassName,
          goodsUnitName,
        } = payload;
        item.barCode = barCode
        item.goodsSepID = goodsSepID;
        item.goodsId = goodsID;
        item.goodsName = goodsName;
        item.goodsClassName = goodsClassName;
        item.goodsUnitName = goodsUnitName;
        item.goodsNo = goodsNo;

        list.push(item);

        list.length > 0 &&  list.forEach((v, i) => (v.serial = i));

        return {
          ...state,
          tableList: [...list],
        };
      }
      return state;
    }
    case "DelTableItem": {
      const list = state.tableList || [];

        list.splice(payload, 1);
        list.length > 0 &&  list.forEach((v, i) => (v.serial = i));

      return {
        ...state,
        tableList: [...list],
      };
    }
    case "ChangeTableItem": {
      const { index, key, value } = payload;
      const list = state.tableList || [];

      return {
        ...state,
        tableList: [...list],
      };
    }
    case "ClearTableList":
      return {
        ...state,
        tableList: [],
      };
    default:
      return state;
  }
}

export function CouponFormStoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialArgs);

  const v = { state, dispatch };
  return (
    <CouponFormStore.Provider value={v}>
      {props.children}
    </CouponFormStore.Provider>
  );
}
