import { useBoolean, useRequest } from "ahooks";
import { Button, message, Modal, Input, List, Checkbox, Menu, Tag } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect, useState } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { DataTable } from "../../../../component/table";

import img_edit from "../../../../assets/images/bianji.png"
import img_see from "../../../../assets/images/chakan1.png"
import img_del from "../../../../assets/images/shanchu.png"
import img_check from "../../../../assets/images/icon/status/zhengque.png"
import no_check from "../../../../assets/images/icon/status/jinyong.png"
import { fileAction, roleAction } from "../../action/userRole/file_action";
import { FormStoreProvider } from "../../store/userRole/form_store";
import { Form } from "./form";
import { formAction } from "../../action/userRole/form_action";
import { DataTableStore } from "../../../../component/table/store/data_table_store";

import { FileStore } from "../../store/userRole/file_store";
import { Search } from "../../../../component/search";
import { defualtPageSize, Progress } from "../../../../logic/common_logic";
import { AuthLogic } from "../../../../logic/auth_logic";
import { DataList } from "../../../../component/data-list";
import { DataListStore } from "../../../../component/data-list/store/data_table_store";
import { ConfirmModal } from "../../../../component/modal";

let editId = "";
let seeId = "";
let isDefault = false;
export function UserRole(viewPower) {
    const [createState, createFunc] = useBoolean(false);
    const [deleteState] = useBoolean(false);
    const [] = useBoolean(false);
    const [confirmState, confirmFunc] = useBoolean(false);
    const action = fileAction;
    const { state, dispatch } = useContext(FileStore);
    useRequest(() => fileAction.initBasic({ state, dispatch }));
    const { state: tableState, dispatch: tableDispatch } = useContext(DataTableStore);
    const { state: menuState, dispatch: menuDispatch } = useContext(DataListStore);

    const [menuButton, setData] = useState([])

    useEffect(() => {
        (async function () {
            let r = await new AuthLogic().selButton(viewPower.viewPower);
            if (r.length > 0) {
                for (let i = 0; i < r.length; i++) {

                    r[i] = r[i].split('_')[r[i].split('_').length - 1]
                }
            }
            setData(r);
        }())

        return () => {

        }
    }, []);
    const columns: ColumnsType = [
        {
            title: '用户账号',
            dataIndex: 'userID',
            key: '2',
            align: "center"
        },
        {
            title: '用户名称',
            dataIndex: 'userName',
            key: '2',
            align: "center"
        },
        {
            title: '性别',
            dataIndex: 'sex',
            key: '2',
            align: "center"
        },
        {
            title: '邮箱',
            dataIndex: 'email',
            key: '2',
            align: "center"
        },
        {
            title: '是否启用',
            key: '5',
            align: "center",
            dataIndex: "enabled",
            render: v => v ?
                <Tag color="success">启用</Tag> :
                <Tag color="default">禁用</Tag>
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: '2',
            align: "center"
        },
    ];
    const menuColumns: ColumnsType = [
        {
            title: '角色名称',
            dataIndex: 'name',
            key: '1',
            align: "center"
        }
    ];
    // 角色列表
    const onSearchMenu = async (pageIndex) => {
        const r = await roleAction.rolePage({ pageIndex: pageIndex, pageSize: defualtPageSize(), dispatch: menuDispatch })
        if (r && r.length) {
            state.search.RoleId = r[0].id;
            action.setCondition("RoleId", r[0].id, dispatch)
            onSearch(1);
        }
    }
    const onSearch = async (pageIndex) => {
        await action.loadPage({ pageIndex: pageIndex, pageSize: defualtPageSize(), dispatch: tableDispatch, search: state.search })
    }
    const handleClick = async (e) => {
        tableState.dataList = []
        onSearch(1)
    }
    return (
        <>
            <div style={{ float: 'left', width: '18%', height: '100%' }}>
                <DataList columns={menuColumns} action={roleAction}
                    onLoad={() => onSearchMenu(1)}
                    onChange={(pageIndex) => { onSearchMenu(pageIndex) }}
                    onSelect={async ({ id, isdefault }) => {
                        state.search.RoleId = id;
                        isDefault = isdefault;
                        action.setCondition("RoleId", id, dispatch)
                        handleClick(1);
                    }}
                />
            </div>
            <div style={{ float: 'left', width: '80%', marginLeft: '2%', marginTop: "-0.61875rem" }}>
                <ButtonPanel>
                    {menuButton.indexOf("ADD") != -1 && <Button onClick={() => {editId = "";seeId = "";createFunc.setTrue()}}>添加用户</Button>}
                </ButtonPanel>
                <ButtonPanel>
                    {menuButton.indexOf("DELETE") != -1 &&
                        <Button onClick={async () => {
                            if (!tableState.selectList.length) {
                                message.warning("请选择删除项")
                            } else {
                                let arr = tableState.selectList.filter(item=>{return item.isdefault == true})
                                if(arr.length>0){
                                    message.error('存在不能的删除的用户')
                                }else{
                                confirmFunc.setTrue();
                                }
                            }
                        }}>删除用户</Button>
                    }
                    {menuButton.indexOf("EXPORT") != -1 && <Button>导出</Button>}
                </ButtonPanel>
                <DataTable columns={columns} action={fileAction}
                    onLoad={async () => { await onSearch(1); Progress.done() }}
                    onChange={(pageIndex) => { onSearch(pageIndex) }}
                    scroll={{ scrollToFirstRowOnChange: true, autoY: true }}
                    selectColumn={true}
                    loading={tableState.loading}
                />
            </div>

            {
                createState && (
                    <FormStoreProvider>
                        <Form close={(hasUpdate) => {
                            if (hasUpdate) {
                                onSearch(tableState.current);
                            }

                            createFunc.setFalse();

                        }} action={formAction} editId={editId} seeId={seeId} roleId={state.search.RoleId} isdefault={isDefault}/>
                    </FormStoreProvider>
                )
            }

            <ConfirmModal visible={confirmState} title="温馨提示" subTitle="确认删除？" message="删除之后信息无法恢复，请慎重考虑" close={async (r) => {
                if (r) {
                    await action.delSelectList({ dispatch, selectList: tableState.selectList, roleId: state.search.RoleId })

                    action.clearSelectedRowKeys({ dispatch: tableDispatch });

                    await onSearch(tableState.current);
                }
                confirmFunc.setFalse();
            }} />
        </>
    )
}