import styles from "../style/container.module.scss";
import { useContext, useRef, useEffect } from 'react'
import { TopicStore } from './../store/topic'
import { ConfirmModal } from "../../../component/modal";
import { useBoolean } from 'ahooks'
import { ElementTransform } from "../com/trasform";
interface IContainer {
    children?;
    id?;
    /** 设置当前激活组件的高度 */
    setActiveComTop: (top: number) => void;
}

export function Container({ children, id, setActiveComTop }: IContainer) {
    const { state, dispatch } = useContext(TopicStore);
    const [confirmState, confirmFunc] = useBoolean(false);
    let ifActive = state.activeId == id;
    const ref = useRef(null);

    useEffect(() => {
        if (ifActive) {
            let transform = ElementTransform.getElementTransform(ref.current);
            setActiveComTop(transform.pos.y);
        }
    });

    return (
        <>
            <div
                ref={ref}
                className={`${styles.root} container`}
                style={{ border: ifActive ? "1px dashed #0380f4" : "none" }}
                onClick={() => {
                    dispatch({ type: 'ACTIVE', payload: id })
                }}>
                <div className={styles.control} >
                    <span className={styles.close} onClick={() => confirmFunc.setTrue()}>删除</span>
                    {/* <span className={styles.edit} onClick={()=>{dispatch({type:'ACTIVE',payload:id})}}>编辑</span> */}
                </div>
                {children}
            </div>
            <ConfirmModal visible={confirmState} title="温馨提示" subTitle="确认删除？" message="删除之后信息无法恢复，请慎重考虑" close={(r) => {
                if (r) {
                    dispatch({ type: 'DELETE', payload: id })
                }
                confirmFunc.setFalse();
            }} />
        </>
    )
}
