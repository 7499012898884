import { useBoolean } from "ahooks";
import { Button, Input, Tag, Table, Modal, message } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect, useState } from "react";
import { ButtonPanel } from "../../../../../component/button-panel/button_panel";
import { Search } from "../../../../../component/search";
import { DataTable } from "../../../../../component/table";
import { DataTableStore } from "../../../../../component/table/store/data_table_store";
import { Form } from "./from";
import { ConfirmModal } from "../../../../../component/modal";
import { defualtPageSize, Progress } from "../../../../../logic/common_logic";
import { AuthLogic } from "../../../../../logic/auth_logic";
import {
  Control,
  Del,
  Edit,
  See,
} from "../../../../../component/control-column";
import { FileStore } from "../../../store/exchange/coupon/file_store";
import { fileAction } from "../../../action/exchange/coupon/file_action";
import { FormStoreProvider } from "../../../store/exchange/coupon/form_store";
import { ChooseCoupon } from "../../../../../component/choose-coupon";
import up_img from "../../../../../assets/images/icon/upEC.png";
import down_img from "../../../../../assets/images/icon/downEC.png";
import stop_img from "../../../../../assets/images/zz.png";
import img_detail from "../../../../../assets/images/mx.png";
import { ChooseCouponExchange } from "../../../../../component/couponExchange-modal";
const formParams = {
  method: "Create",
  id: "",
  subId: "",
  item: null,
};

export function ExchangeCoupon(viewPower) {
  //打开选择优惠券弹窗
  const [couponState, couponFunc] = useBoolean(false);
  //是否打开创建页面
  const [createState, createFunc] = useBoolean(false);
  //是否打开高级搜索
  const [searchState, searchFunc] = useBoolean(false);
  //是否上架
  const [confirmState, confirmFunc] = useBoolean(false);
  //是否下架
  const [confirmdownState, confirmdownFunc] = useBoolean(false);
  //是否作废
  const [confirmStopState, confirmStopFunc] = useBoolean(false);
  //全局state
  const { state, dispatch } = useContext(FileStore);
  //表单state
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);
  /** 表单Action */
  const action = fileAction;
  //权限
  const [menuButton, setData] = useState([]);
  useEffect(() => {
    (async function () {
      let r = await new AuthLogic().selButton(viewPower.viewPower);
      if (r && r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          r[i] = r[i].split("_")[r[i].split("_").length - 1];
        }
      }
      setData(r);
    })();
    return () => {};
  }, []);

  //明细弹窗
  const [detailState, detailFunc] = useBoolean(false);

  const columns: ColumnsType = [
    {
      title: "操作",
      key: "1",
      dataIndex: "oddNumbers",
      align: "center",
      width: Control.width(4),
      render: Control.render(
        (v, r) =>
          menuButton.indexOf("VIEW") != -1 && (
            <See
              click={() => {
                formParams.id = r.id;
                formParams.subId = r.pointsExchangeID;
                formParams.method = "Preview";
                formParams.item = r;
                //
                createFunc.setTrue();
              }}
            />
          ),
        (v, r) =>
          menuButton.indexOf("UPDATE") != -1 &&
          r.state != 3 &&
          r.state != 1 && (
            <Edit
              click={() => {
                formParams.id = r.id;
                formParams.subId = r.pointsExchangeID;
                formParams.method = "Edit";
                formParams.item = r;
                //
                createFunc.setTrue();
              }}
            />
          ),
        (v, r) =>
          menuButton.indexOf("UPDATE") != -1 &&
          r.state != 3 && (
            <img
              src={r.state == 1 ? down_img : r.state == 2 ? up_img : ""}
              alt=""
              onClick={() => {
                formParams.id = r.id;
                formParams.subId = r.pointsExchangeID;
                formParams.method = "Edit";
                formParams.item = r;
                if (r.points == null) {
                  message.error("未配置规则，不能上架");
                } else {
                  r.state == 2 && confirmFunc.setTrue();
                  r.state == 1 && confirmdownFunc.setTrue();
                }
              }}
            />
          ),
        // (v, r) => menuButton.indexOf("UPDATE") != -1 && r.state!= 3  && <img src ={down_img} alt='' onClick={() => {
        //     formParams.id = r.id;
        //     formParams.subId = r.pointsExchangeID;
        //     formParams.method = "Edit";
        //     formParams.item = r;
        //     //
        //     confirmdownFunc.setTrue();
        // }} />,
        (v, r) =>
          menuButton.indexOf("STOP") != -1 &&
          r.state != 3 && (
            <img
              src={stop_img}
              alt=""
              onClick={() => {
                formParams.id = r.id;
                formParams.subId = r.pointsExchangeID;
                //
                confirmStopFunc.setTrue();
              }}
            />
          )
        // (v, r) => menuButton.indexOf("VIEW") != -1 && <img src={img_detail} alt='' onClick={() => {
        //     formParams.id = r.id;
        //     formParams.subId = r.pointsExchangeID;
        //     formParams.method = "Preview";
        //     formParams.item = r;
        //     //
        //     detailFunc.setTrue();
        // }} />,
      ),
    },
    {
      title: "优惠券名称",
      key: "2",
      dataIndex: "couponName",
      align: "center",
    },

    {
      title: "所需积分值",
      key: "4",
      dataIndex: "points",
      align: "center",
    },
    {
      title: "总发放量",
      key: "5",
      dataIndex: "issueCount",
      align: "center",
      render: (v) => (
        <>
          {v == null && <span>不限</span>}
          {v != null && <span>{v}</span>}
        </>
      ),
    },
    {
      title: "已兑换数量",
      key: "6",
      dataIndex: "exchangeCount",
      align: "center",
    },
    {
      title: "活动状态",
      key: "7",
      dataIndex: "state",
      align: "center",
      render: (v) => (
        <>
          {v == 1 && <Tag color="success">已上架</Tag>}
          {v == 2 && <Tag color="warning">已下架</Tag>}
          {v == 3 && <Tag color="error">已作废</Tag>}
        </>
      ),
    },
    {
      title: "优惠券状态",
      key: "8",
      dataIndex: "status",
      align: "center",
      render: (v) => (
        <>
          {v == 1 && <Tag color="success">启用</Tag>}
          {v == 2 && <Tag color="error">禁用</Tag>}
        </>
      ),
    },
    {
      title: "上架时间",
      key: "3",
      dataIndex: "shelvesDateTime",
      align: "center",
      width: 120,
    },
    // {
    //   title: "制单人",
    //   key: "9",
    //   dataIndex: "addName",
    //   align: "center",
    // },
  ];

  const onSearch = async (pageIndex) => {
    searchFunc.setFalse();
    //
    await action.loadPage({
      pageIndex: pageIndex,
      pageSize: defualtPageSize(),
      dispatch: tableDispatch,
      search: state.search,
    });
  };

  return (
    <>
      <Search>
        <Input
          allowClear
          placeholder="请输入优惠券名称"
          maxLength={20}
          onChange={(e) =>
            action.setCondition({
              dispatch,
              key: "seacherKey",
              value: e.target.value,
            })
          }
        />
        <input type="button" value="快速查找" onClick={() => onSearch(1)} />

        {/* <TipsGroup>
                    <input type="button" value="高级查找" onClick={() => searchFunc.toggle()} />
                    <Tips display={searchState} width="48rem">
                        <FileSearch onSearch={() => onSearch(1)} />
                    </Tips>
                    <Mask visible={searchState} onClick={() => searchFunc.setFalse()} />
                </TipsGroup> */}
      </Search>

      <ButtonPanel>
        {menuButton.indexOf("ADD") != -1 && (
          <>
            {/* <div > <label htmlFor='name'>快速添加</label>
                        <input type="text"  name='name' onClick={() => onSearch(1)} />
                    </div> */}
            <Button
              onClick={() => {
                formParams.id = "";
                formParams.method = "Create";
                couponFunc.setTrue();
                // createFunc.setTrue()
              }}
            >
              选择优惠券
            </Button>
          </>
        )}
      </ButtonPanel>

      <DataTable
        columns={columns}
        action={action}
        onLoad={async () => {
          await onSearch(1);
          Progress.done();
        }}
        onChange={(pageIndex) => {
          onSearch(pageIndex);
        }}
        scroll={{ scrollToFirstRowOnChange: true, autoY: true, x: 1060 }}
        loading={tableState.loading}
      />

      {createState && (
        <FormStoreProvider>
          <Form
            audit={true}
            close={(hasUpdate) => {
              if (hasUpdate) {
                onSearch(tableState.current);
              }

              createFunc.setFalse();
            }}
            action={action}
            id={formParams.id}
            subId={formParams.subId}
            item={formParams.item}
            method={formParams.method}
          />
        </FormStoreProvider>
      )}

      <ChooseCouponExchange
        visibleModal={detailState}
        id={formParams.subId}
        close={() => detailFunc.setFalse()}
      />

      <ConfirmModal
        visible={confirmState}
        title="温馨提示"
        subTitle="确认上架？"
        message="上架之后活动开始"
        close={async (r) => {
          if (r) {
            await action.upExchange(formParams.subId);

            onSearch(tableState.current);
          }
          confirmFunc.setFalse();
        }}
      />
      <ConfirmModal
        visible={confirmdownState}
        title="温馨提示"
        subTitle="确认下架？"
        message="下架之后活动停止"
        close={async (r) => {
          if (r) {
            await action.downExchange(formParams.subId);

            onSearch(tableState.current);
          }
          confirmdownFunc.setFalse();
        }}
      />
      <ConfirmModal
        visible={confirmStopState}
        title="温馨提示"
        subTitle="确认作废？"
        message="作废之后活动无法恢复，请慎重考虑"
        close={async (r) => {
          if (r) {
            await action.delExchang(formParams.subId);

            onSearch(tableState.current);
          }
          confirmStopFunc.setFalse();
        }}
      />
      <ChooseCoupon
        visibleModal={couponState}
        getCouponType={3}
        type="radio"
        close={async (status, r) => {
          if (r) {
            let CouponID = r[0].id;
            await action.addExchangeCoupon(CouponID);
            onSearch(tableState.current);
          }
          couponFunc.setFalse();
        }}
      />
    </>
  );
}
