import { message } from "antd";
// import axios from "axios";
import Cookies from "js-cookie";
import { DataTableAction } from "../../table/action/data_table_action";
import { Tools, axios } from "../../../config";
import {EquipmentLogic} from '../../../logic/equip_logic'
class ChooseAction extends DataTableAction {
    async initBasic({ dispatch, state }) {
     
    }
    async delRow({ dispatch, rowItem }) {
        const r = await axios({
            method: "POST",
            url: Tools.getApiAddress("menber_delete"),
            data: {
                MemberID: rowItem.id.id
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })
        if (r.data.statusCode == 200) {
            message.success("删除成功")
        } else {
            message.error(r.data.message)
        }
    }
    async delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {
        const lis = selectList.map(async ({ id }) => {
            return axios({
                method: "POST",
                url: Tools.getApiAddress("menber_delete"),
                data: {
                    MemberID: id
                },
                headers: {
                    SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
                },
            })
        })

        await Promise.all(lis);

        message.success("删除成功");
    }
   

    async loadPage({ dispatch, pageIndex, pageSize,logId, search }) {
        // 会员列表
        const { keywords, membeClassID, label, source, status, addDateTimeStart, addDateTimeEnd, birthdayStart, birthdayEnd } = search;
        const params = {
            pageIndex: pageIndex,
            pageSize: pageSize,
        };
          const r = await new EquipmentLogic().getOneScale(logId)

        if (r.data.statusCode == 200) {
   const dataList = r.data.data || [];

            
            this.setPaging({ dispatch, pageIndex, total: dataList.length, dataList: dataList, pageSize });
        }

    }
    setCondition(method: "Keywords" | "MembeClassID" | "Label" | "Source" | "Status" | "AddDateTimeStart" | "AddDateTimeEnd" | "BirthdayStart" | "BirthdayEnd", value, dispatch) {

        if ("Keywords" == method) {
            dispatch({ type: "SetSearch", payload: { keywords: value } });
        }
        else if ("MembeClassID" == method) {
            dispatch({ type: "SetSearch", payload: { membeClassID: value } });
        }
        else if ("Label" == method) {
            dispatch({ type: "SetSearch", payload: { label: value } });
        }
        else if ("Source" == method) {
            dispatch({ type: "SetSearch", payload: { source: value } });
        }
        else if ("Status" == method) {
            dispatch({ type: "SetSearch", payload: { status: value } });
        }
        else if ("AddDateTimeStart" == method) {
            dispatch({ type: "SetSearch", payload: { addDateTimeStart: value } });
        }
        else if ("AddDateTimeEnd" == method) {
            dispatch({ type: "SetSearch", payload: { addDateTimeEnd: value } });
        }
        else if ("BirthdayStart" == method) {
            dispatch({ type: "SetSearch", payload: { birthdayStart: value } });
        }
        else if ("BirthdayEnd" == method) {
            dispatch({ type: "SetSearch", payload: { birthdayEnd: value } });
        }
    }
}
export const chooseCouponAction = new ChooseAction();