import { message } from "antd";
// import axios from "axios";
import Cookies from "js-cookie";
import { DataTableAction } from "../../table/action/data_table_action";
import { Tools, axios } from "../../../config";
import { CouponLogic } from "../../../logic/coupon_logic";
import { DossierLogic } from "../../../logic/dossier_logic";
import { GradeLogic } from "../../../logic/grade_logic";
import { TagLogic } from "../../../logic/tag_logic";
class ChooseAction extends DataTableAction {
    private GradeLgc = new GradeLogic();
    private TagLogic = new TagLogic();
    async initBasic({ dispatch, state }) {
        let gradeList = await this.GradeLgc.gradeList()
        let obj = {
            gradeName: '全部',
            id: ''
        }
        gradeList.unshift(obj)
        dispatch({ type: "LoadGrade", payload: gradeList });
        dispatch({ type: "LoadTag", payload: await this.TagLogic.querysList() });
    }
    async delRow({ dispatch, rowItem }) {
       
    }
    async delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {
        
    }
   

    async loadPage({ dispatch, pageIndex, pageSize, search }) {
        // 会员列表
        const { keywords, membeClassID, label, source, status, addDateTimeStart, addDateTimeEnd, birthdayStart, birthdayEnd } = search;
        const params = {
            pageIndex: pageIndex,
            pageSize: pageSize,
            keyWords: keywords,
            // gender: '',
            status: status == 0 ? '' : status,
            membeClassID: membeClassID,
            label: label,
            source: source == 0 ? '' : source,
            addDateTimeStart: addDateTimeStart,
            addDateTimeEnd: addDateTimeEnd,
            birthdayStart: birthdayStart,
            birthdayEnd: birthdayEnd,
        };
           const r = await new DossierLogic().querysList(params);

        if (r.data.statusCode == 200) {
   const dataList = r.data.data.dataSource;

            const list = dataList.map((v, i) => {
                v.source = v.source == 1 ? '管理端' : v.source == 2? '客户端' : '小程序'
                v.status = v.status === 1 ? '挂失' : v.status === 2 ? '停用' : '正常'
                v.gender = v.gender === 1 ? '男' : '女'
                v.key = i + 1;
                return v;
            })
            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: list, pageSize });
        }

    }
    setCondition(method: "Keywords" | "MembeClassID" | "Label" | "Source" | "Status" | "AddDateTimeStart" | "AddDateTimeEnd" | "BirthdayStart" | "BirthdayEnd", value, dispatch) {

        if ("Keywords" == method) {
            dispatch({ type: "SetSearch", payload: { keywords: value } });
        }
        else if ("MembeClassID" == method) {
            dispatch({ type: "SetSearch", payload: { membeClassID: value } });
        }
        else if ("Label" == method) {
            dispatch({ type: "SetSearch", payload: { label: value } });
        }
        else if ("Source" == method) {
            dispatch({ type: "SetSearch", payload: { source: value } });
        }
        else if ("Status" == method) {
            dispatch({ type: "SetSearch", payload: { status: value } });
        }
        else if ("AddDateTimeStart" == method) {
            dispatch({ type: "SetSearch", payload: { addDateTimeStart: value } });
        }
        else if ("AddDateTimeEnd" == method) {
            dispatch({ type: "SetSearch", payload: { addDateTimeEnd: value } });
        }
        else if ("BirthdayStart" == method) {
            dispatch({ type: "SetSearch", payload: { birthdayStart: value } });
        }
        else if ("BirthdayEnd" == method) {
            dispatch({ type: "SetSearch", payload: { birthdayEnd: value } });
        }
    }
}
export const chooseCouponAction = new ChooseAction();