// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../../../../../config";

/**
 * api控制器基类
 */
export class BaseApiCon {
    private static _loginMsg: any;
    private static _token: string;
    /** 获取登录信息 */
    static get loginMsg(): any {
        if (!this._loginMsg) {
            this._loginMsg = JSON.parse(Cookies.get('loginMsg'));
        }
        return this._loginMsg;
    }

    /** 获取token */
    static get token(): string {
        if (!this._token) {
            this._token = Cookies.get("token");
        }
        return this._token;
    }

    /**
     * 通过名字获取url
     * @param _name 名字
     */
    static byNameGetURl(_name: string): string {
        return Tools.getApiAddress(_name);
    }

    /** 获取图片路径 */
    static getImageURL(_url: string): string {
        return Tools.getImageAddress(_url);
    }

    /**
     * 发送请求
     * @param url 地址
     * @param method 请求方法
     * @param op 选项
     */
    static rootRequest(url: string, method: 'GET' | 'POST', op?): Promise<any> {
        
        return axios({
            method: method,
            url: url,
            data: op,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web',
            },
        });
    }

    /**
     * 发送请求，并且包装返回的结果
     * @param url 地址
     * @param method 请求方法
     * @param op 选项
     */
    static request(url: string, method: 'GET' | 'POST', op?): Promise<any> {
        return this.rootRequest(url, method, op).then(d => {
            if (d.data.statusCode == 200) {
                return d.data.data
            } else {
                throw d.data;
            }
        });
    }

    /**
     * 根据对象生成FormData
     * @param _obj 源对象
     */
    static getFormData(_obj: any): FormData {
        let formData = new FormData;
        let _data;
        for (let i in _obj) {
            _data = _obj[i];
            if (!(_data instanceof Blob)) {
                _data = _data.toString();
            }
            formData.set(i, _data);
        }
        return formData;
    }
}