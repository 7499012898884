import { Form, TreeSelect, Cascader } from "antd";
import { useEffect } from "react";
import { useState, useContext } from "react";
import { ApiCon } from "../../com/apiCon";
import { ISelectShop } from "../../com/ISelectType";
import { SelectStore } from "../store/SelectStore";

const formatList = (data) => {
    let list = []
    data.length>0 && data.map(item=>{
        list.push({
            label: item.shopName,
            value: item.id
        })
    })
    return list
}

let getShopListPromise: Promise<any>;
/** 获取所有商品分类 */
function getGoodsClass(): Promise<any[]> {
  if (!getShopListPromise) {
    //加载数据
    getShopListPromise = ApiCon.getShopList().then((data) => {
      return formatList(data || [])
    });
  }
  return getShopListPromise;
}

/**
 * 选择门店
 */
export function SelectShop(params) {
  
  const { state, dispatch } = useContext<{
    state: ISelectShop;
    dispatch: (state: ISelectShop) => void;
  }>(SelectStore);

  const [shopList, setShopList] = useState([]);

  useEffect(() => {
    if (shopList.length == 0) {
      getGoodsClass().then((data) => {
        setShopList(data);
      });
    }
  });

  return (
    <div className="item_  selectShop">
      <Form.Item label="选择门店">
        <TreeSelect
          style={{ width: 175 }}
        //   showCheckedStrategy={TreeSelect.SHOW_PARENT}
          dropdownMatchSelectWidth={false}
          defaultValue={state.shopIDs}
          value={state.shopIDs}
          treeCheckable={params.treeCheckable===undefined?true:params.treeCheckable}
          treeData={shopList}
          onChange={(data) => {
            dispatch({
              ...state,
              // goodsClassID: data.length > 0 ? [data[data.length - 1]] : [],
              shopIDs: data || [],
            });
          }}
          placeholder="选择门店"
        />
      </Form.Item>
      {/* <Form.Item label="商品分类">
        <Cascader allowClear autoComplete="off"  style={{ width: "160px" }} options={shopList} placeholder="请选择商品分类" 
            onChange={(v) =>{let id ;if(v.length ==1){id=v[0]}else{id=v[1]}
            // action.setValue({ dispatch, value: id, attribute: "ClassesId" })
            dispatch({
                ...state,
                goodsClassID: [id],
            });
            }} />
         </Form.Item> */}
    </div>
  );
}
