
import { message } from "antd";
// import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useRef, useState } from "react";
import { Tools, axios } from "../../../config";
import { Progress } from "../../../logic/common_logic";
import styles from "../index.module.scss";

const menuList = [
    "销售额",
    "新增会员",
    "会员储值",
    "订单数量"
]

let type = 1;

export function Analysis() {

    const [active, setActive] = useState(0);
    const [dataList, setDataList] = useState([]);

    async function load() {
        const r = await axios({
            method: "GET",
            url: Tools.getApiAddress("statistics_echartstj"),
            params: {
                type
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        });
        if (r && r.data.statusCode == 200) {
            const list = r.data.data;

            setDataList([]);

            setDataList(list);

            Progress.done();
        }
    }

    useEffect(() => {

        load();

        return () => {

        }
    }, [type]);

    return (
        <div className={styles.right}>
            <div className={styles.head_c}>
                <div className={styles.title}>数据分析</div>
                <div className={styles.head}>
                    {
                        menuList.map((v, i) => (
                            <div key={i} className={`${styles.item} ${active == i && styles.active}`} onClick={() => {
                                setActive(i)
                                type = i + 1;
                            }}>
                                <i>
                                    <span className={styles.def}></span>
                                    <span className={styles.act}></span>
                                </i><span>{v}</span>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className={styles.body_c}>
                {/* <span className={styles.point}></span>
                <span className={styles.title}>数据分析</span> */}
                <div className={styles.status}>
                    <div className={styles.item}>
                        {/* <i></i><span>目标</span> */}
                    </div>
                    <div className={styles.item}>
                        {/* <i></i><span>完成率</span> */}
                    </div>
                    <div style={{ clear: "both", width: 100, height: 10 }}></div>
                </div>
            </div>
            <div className={styles.column}>
                {dataList.length ? <Column dataList={dataList} /> : null}
            </div>
        </div>
    )
}

declare var echarts: any;

export const Column = ({ dataList }) => {
    const rootRef = useRef(null);

    useEffect(() => {

        var dom = rootRef.current;
        var myChart = echarts.init(dom);
        var app = {};
        var option = null;
        option = {
            xAxis: {
                type: "category",
                // data: [
                //     "一月",
                //     "二月",
                //     "三月",
                //     "四月",
                //     "五月",
                //     "六月",
                //     "七月",
                //     "八月",
                //     "九月",
                //     "十月",
                //     "十一月",
                //     "十二月",
                // ],
                data: dataList.map(v => v.xName)
            },
            yAxis: {
                type: "value",
            },
            series: [
                {
                    type: "bar",
                    itemStyle: {
                        normal: {
                            color: "#f3f3f3", // 定义柱形的背景色
                            barBorderRadius: [5, 5, 5, 5], // 定义背景柱形的圆角
                        },
                    },
                    barGap: "-100%", // 设置柱形重合的重要步骤
                    // data: [90, 60, 70, 90, 78, 65, 55, 90, 60, 70, 90, 78],
                    // data: Array(12).fill(100),

                    data: Array(12).fill(Math.max(...dataList.map(v => v.number))),
                    z: 0,
                    silent: true,
                    animation: false, // 关闭动画效果
                    barWidth: "20px", // 设置柱形宽度
                },
                {
                    type: "bar",
                    // data: [50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50],
                    data: dataList.map(v => v.number),
                    barWidth: "20px",
                    barGap: "-100%", // 设置柱形重合的重要步骤
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            { offset: 0, color: "#fede6f" },
                            { offset: 0.5, color: "#04ca74" },
                            { offset: 1, color: "#04ca74" },
                        ]),
                    },
                },
            ],
        }

        if (option && typeof option === "object") {
            myChart.setOption(option, true);
        }
        window.onresize = function(){
            myChart.resize()
        }

    }, []);
    return (
        <div ref={rootRef} style={{ height: "100%" }}></div>
    )
}