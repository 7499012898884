import { useBoolean, useRequest } from "ahooks";
import { Input, Modal, Select, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext } from "react";
import { Search } from "../search";
import { DataTable } from "../table";
import {
  DataTableStore,
  DataTableStoreProvider,
} from "../table/store/data_table_store";
import { ChooseCouponStore, ChooseStoreProvider } from "./store/choose_store";
import styles from "./style/index.module.scss";
import { Select as SelectAntd } from "antd";
import { chooseCouponAction } from "./action/choose_action";
import { defualtPageSize } from "../../logic/common_logic";
let classId = "";

function Coupon(params: IChooseCoupon) {
  const { visibleModal, close, type, getCouponType } = params;
  const { Option } = Select,
    action = chooseCouponAction;

  const { state, dispatch } = useContext(ChooseCouponStore);
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);

  const handleOk = () => {
    close && close(true, tableState.selectList);
  };

  const handleCancel = () => {
    close && close(false, null);
  };

  const columns: ColumnsType = [
    {
      title: "优惠券名称",
      dataIndex: "couponName",
      key: "3",
      align: "center",
    },
    {
      title: "类型",
      dataIndex: "couponType",
      key: "4",
      align: "center",
      render: (v) => (
        <>
          {v == 2 && <Tag color="success">直减券</Tag>}
          {v == 1 && <Tag color="error">折扣券</Tag>}
        </>
      ),
    },
    {
      title: "面额/折扣",
      key: "5",
      align: "center",
      render: (v) => (
        <span>
          {v.couponType == 2 ? v.subMoney + "元" : v.discount / 10 + "折"}
        </span>
      ),
    },
    {
      title: "使用条件",
      key: "6",
      align: "center",
      render: (v) => <span>满{v.meetMoney}元可用</span>,
    },
    {
      title: "领券方式",
      dataIndex: "getCouponType",
      key: "7",
      align: "center",
      render: (v) => (
        <>
          {v == 1 && <Tag color="success">主动领取</Tag>}
          {v == 2 && <Tag color="error">系统发放</Tag>}
          {v == 3 && <Tag color="geekblue">积分兑换</Tag>}
        </>
      ),
    },
    {
      title: "剩余数量",
      dataIndex: "couponNumber",
      key: "8",
      align: "center",
    },
    // {
    //     title: '领取数量',
    //     dataIndex: 'getCouponCount',
    //     key: '8',
    //     align: "center"
    // },
    // {
    //     title: '使用数量',
    //     dataIndex: 'useCouponCount',
    //     key: '8',
    //     align: "center"
    // },
    {
      title: "有效期",
      key: "8",
      align: "center",
      width: 160,
      render: (v) => (
        <>
          {v.validType == 1 ? (
            <span>
              {v.validStartDate}~{v.validEndDate}
            </span>
          ) : (
            <span>领取后{v.validDay}天有效</span>
          )}
        </>
      ),
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "8",
      align: "center",
      render: (v) => (
        <>
          {v == 1 && <Tag color="success">启用</Tag>}
          {v == 2 && <Tag color="error">禁用</Tag>}
        </>
      ),
    },
  ];
  let tableRowSelection: any = {};
  if (type == "radio") {
    tableRowSelection = {
      rowSelection: {
        type: "radio",
        getCheckboxProps: (record) => ({
          disabled: record.status == 2, // Column configuration not to be checked
        }),
      },
    };
  }
  const checkSelectable = (record) => {
    let flag = false;
    if (record.status == 2) {
      flag = true;
    }
    return flag;
  };
  // useRequest(() => action.init({ dispatch }));
  const onSearch = async (pageIndex) => {
    await action.loadPage({
      pageIndex: pageIndex,
      pageSize: defualtPageSize(),
      dispatch: tableDispatch,
      search: { ...state.search, getCouponType: getCouponType },
    });
  };
  return (
    <Modal
      title="选择优惠券"
      visible={visibleModal}
      onOk={handleOk}
      onCancel={handleCancel}
      width={1000}
      cancelText="取消"
      okText="确定"
    >
      <div className={styles.root}>
        <Search>
          <Input
            allowClear
            placeholder="请输入优惠券名称"
            maxLength={20}
            onChange={(e) =>
              action.setCondition({
                dispatch,
                key: "couponName",
                value: e.target.value,
              })
            }
          />
          <input type="button" value="快速查找" onClick={() => onSearch(1)} />
        </Search>

        <div style={{ marginTop: 10 }}>
          <DataTable
            columns={columns}
            action={action}
            // rowKey={(record) => record.id}
            rowSelection={{
              getCheckboxProps: (record) => ({
                disabled: record.status == 2, // Column configuration not to be checked
              }),
            }}
            {...tableRowSelection}
            onLoad={() => onSearch(1)}
            onChange={(pageIndex) => {
              onSearch(pageIndex);
            }}
            selectColumn={true}
          />
        </div>
      </div>
    </Modal>
  );
}

interface IChooseCoupon {
  visibleModal: boolean;
  type?: string;
  close?: (status, r) => void;
  getCouponType?: number;
}
export function ChooseCoupon(params: IChooseCoupon) {
  return (
    <ChooseStoreProvider>
      <DataTableStoreProvider>
        <Coupon {...params} />
      </DataTableStoreProvider>
    </ChooseStoreProvider>
  );
}
