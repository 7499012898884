import React, { useContext, useState } from "react";
import { Button, Checkbox, Col, DatePicker, Form, Input, Row, Select } from "antd";
import styles from "../../style/file_search.module.scss";
import Search from "antd/lib/input/Search";
import { seckillAction } from "../../action/coupon/file_action";
import { CouponStore } from "../../store/coupon/file_store";
import { useBoolean } from "ahooks";
import { ChooseShop } from "../../../../component/choose-shop";
import { ChooseGoods } from "../../../../component/choose-goods";

const { Option } = Select;

export function InventorySearch({onSearch}) {
    const [shopState, shopFunc] = useBoolean(false);
    const [goodsState, goodsFunc] = useBoolean(false);
    const { state, dispatch } = useContext(CouponStore);
    const action = seckillAction;

    return (
        <div>
            <Form layout="horizontal">
                <div className={styles.row}>
                    <div className={styles.cell}>
                        {/* <Form.Item label="活动状态">
                            <Select style={{ width: "15rem" }}
                                onChange={(v) => action.setCondition({ dispatch, key: "statusId", value: v })}
                                defaultValue={-1}
                                showSearch
                                filterOption={(input:any, option:any) => {
                                    return option.children.indexOf(input) >= 0;
                                }}
                            >
                                <Option value={-1}>全部</Option>
                                <Option value="1">已作废</Option>
                                <Option value="2">生效中</Option>
                                <Option value="3">已过期</Option>
                            </Select>
                        </Form.Item> */}
                        <Form.Item label="优惠券类型">
                            <Select style={{ width: "15rem" }}
                                onChange={(v) => action.setCondition({ dispatch, key: "couponType", value: v })}
                                defaultValue={-1}
                                showSearch
                                filterOption={(input:any, option:any) => {
                                    return option.children.indexOf(input) >= 0;
                                }}
                            >
                                <Option value={-1}>全部</Option>
                                <Option value="1">折扣券</Option>
                                <Option value="2">直减券</Option>
                            </Select>
                        </Form.Item>
                    </div>
                    <div className={styles.cell}>
                    <Form.Item label="优惠券状态">
                            <Select style={{ width: "15rem" }}
                                onChange={(v) => action.setCondition({ dispatch, key: "status", value: v })}
                                defaultValue={-1}
                                showSearch
                                filterOption={(input:any, option:any) => {
                                    return option.children.indexOf(input) >= 0;
                                }}
                            >
                                <Option value={-1}>全部</Option>
                                <Option value="1">启用</Option>
                                <Option value="2">禁用</Option>
                            </Select>
                        </Form.Item>
                    </div>
                </div>
                <div className={styles.row}>
                    <Button type="primary" danger onClick={()=> onSearch()}>搜  索</Button>
                </div>
            </Form>

            <ChooseShop visibleModal={shopState} close={(status, r) => {
                if (r) {
                    action.setCondition({ dispatch, key: "shopName", value: r[0].shopName });
                    action.setCondition({ dispatch, key: "shop", value: r });
                }
                shopFunc.setFalse()
            }} />
            <ChooseGoods visibleModal={goodsState} close={(status, r) => {
                if (r) {
                    action.setCondition({ dispatch, key: "goodsName", value: r.map(v => v.goodsName).toString() });
                    action.setCondition({ dispatch, key: "goods", value: r });
                }
                goodsFunc.setFalse()
            }} />
        </div>
    )
}