import { createContext, useContext, useReducer } from "react";

export const ChooseStore = createContext<IContextProps>(null);

interface IContextProps {
    state: IState;
    dispatch: ({ type }: Dispatch) => void;
}

type Dispatch = { type, payload }

class Search {
    keywords: string;
    areaId: string;
    statusId: string;
    typeId: string;
};

interface IState {
    areaList: any[],
    statusList: any[],
    typeList: any[],
    search: Search;
}

const initialArgs = {
    dataList: [],
    areaList: [
        {
            title: '四川省',
            key: '0-0',
            children: [
                {
                    title: '成都市',
                    key: '0-0-0',
                    children: [
                        {
                            title: '高新区',
                            key: '0-0-0-0'
                        },
                        {
                            title: '武侯区',
                            key: '0-0-0-1',
                        },
                    ],
                },
                {
                    title: '宜宾市',
                    key: '0-0-1',
                    children: [
                        {
                            title: "叙府区",
                            key: '0-0-1-0'
                        }
                    ],
                },
            ],
        },
    ],
    statusList: [
        { id: 1, value: "正常" },
        { id: 2, value: "停用" }
    ],
    typeList: [
        { id: 1, value: "加盟" },
        { id: 2, value: "直营" }
    ],
    search: new Search()
}

function reducer(state: IState, { type, payload }: Dispatch) {
    switch (type) {
        case "SetSearch":
            return {
                ...state,
                search: {
                    ...state.search,
                    ...payload
                }
            }
        default:
            return state;
    }
}

export function ChooseStoreProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialArgs);

    const v = { state, dispatch };
    return <ChooseStore.Provider value={v}>{props.children}</ChooseStore.Provider>
}