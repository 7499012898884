import moment from "moment";
import { createContext, useContext, useReducer } from "react";

export const FormStore = createContext<IContextProps>(null);

interface IContextProps {
    state: IState;
    dispatch: ({ type }: Dispatch) => void;
}

type Dispatch = { type, payload }

export class FormData {
    id: string;
    /**是否限制会员等级true 限制 */
    isMemberClass: number = 1;
    exchangeCount: number = null;
    takeType: number = 1;
    takeBeginDate: string;
    takeEndDate: string;
    points: number;
    memberClasss : any[];
    shopID: any[];
    shopName: string;
    isSetCount: number = 1;
    isLimit: boolean = true;
}

interface IState {
    tagList: any[];
    formData: FormData;
}

export const initialArgs: IState = {
    tagList: [],
    formData: new FormData(),
}

function reducer(state: IState, { type, payload }: Dispatch) {
    switch (type) {
        case 'LoadTag':
            {
                state.tagList = payload
                return{
                    ...state
                }
            }
        case "SetFormData":
            {
                return {
                    ...state,
                    formData: {
                        ...state.formData,
                        ...payload
                    }
                }
            }
        case "SetForm":
            {
                const {shopName,shopID,points,takeType,takeBeginDate,takeEndDate,exchangeCount,isMemberClass,memberClasss} = payload
                const item = new FormData();
                item.shopName = shopName
                item.shopID = shopID
                item.isSetCount = exchangeCount==null?1:2
                item.exchangeCount = exchangeCount
                item.isLimit = isMemberClass==1?true:false
                item.memberClasss = memberClasss
                item.points = points
                item.takeType = takeType
                item.takeBeginDate = takeBeginDate
                item.takeEndDate = takeEndDate
                item.isMemberClass = isMemberClass
                // state.formData = payload
                return {
                    ...state,
                    formData:item
                }
            }
        default:
            return state;
    }
}

export function FormStoreProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialArgs);

    const v = { state, dispatch };
    return <FormStore.Provider value={v}>{props.children}</FormStore.Provider>
}