import { createContext, useReducer } from "react";

class FormData {
    ruleName: string;
    storedValue: string;
    grantType: string;
    grantValue: string;
    remark: string;
}

type Dispatch = { type, payload }

interface IState {
    formData: FormData;
}

const initialArgs = {
    formData: new FormData(),
    gradeList: [],
}

export type FormAttribute = "RuleName" | "StoredValue" | "GrantType" | "GrantValue" | "Remark";

interface IContextProps {
    state: IState;
    dispatch: ({ type }: Dispatch) => void;
}

export const FormStore = createContext<IContextProps>(null);

function reducer(state: IState, { type, payload }: Dispatch) {
    switch (type) {
        // 填充表单
        case "SetForm":
            {
                const data = payload;
                const form = new FormData();
                {
                    const { ruleName, storedValue, remark, grantType, grantValue } = data;
                    form.ruleName = ruleName;
                    form.storedValue = storedValue;
                    form.grantType = grantType;
                    form.grantValue = grantValue;
                    form.remark = remark;
                }

                return {
                    ...state,
                    formData: form
                }
            }
        // 设置表单
        case "Change_FormData":
            {
                const { attribute, value } = payload, props: any = {};

                const key: FormAttribute = attribute;
                let attri = "";

                switch (key) {
                    case "RuleName":
                        attri = "ruleName";
                        break
                    case "StoredValue":
                        attri = "storedValue";
                        break
                    case "GrantType":
                        attri = "grantType";
                        break
                    case "GrantValue":
                        attri = "grantValue";
                        break
                    case "Remark":
                        attri = "remark";
                        break
                    default:
                        break;
                }

                props[attri] = value;
                
                return {
                    ...state,
                    formData: {
                        ...state.formData,
                        ...props
                    }
                }
            }
        default:
            return state;
    }
}

export function FormStoreProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialArgs);

    const v = { state, dispatch };
    return <FormStore.Provider value={v}>{props.children}</FormStore.Provider>
}