import { useBoolean } from "ahooks";
import { Button, Input, message, Modal, Tag, DatePicker } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect, useState } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { DataTable } from "../../../../component/table";

import img_check from "../../../../assets/images/icon/status/zhengque.png";
import no_check from "../../../../assets/images/icon/status/jinyong.png";

import { fileAction } from "../../action/distributionRecords/list_action";
import { ListFormStoreProvider } from "../../store/distributionRecords/list_form_store";
import { Form } from "./list_form";
import { formAction } from "../../action/distributionRecords/list_form_action";
import { DataTableStore } from "../../../../component/table/store/data_table_store";
import { Search } from "../../../../component/search";
import { ListStore } from "../../store/distributionRecords/list_store";
import { defualtPageSize, Progress } from "../../../../logic/common_logic";
import { AuthLogic } from "../../../../logic/auth_logic";
import { FormStoreProvider } from "../../../order/store/form_store";
import { Tips, TipsGroup } from "../../../../component/tips";
import { Mask } from "../../../../component/mask";
import {
  Control,
  Del,
  Edit,
  Lock,
  See,
} from "../../../../component/control-column";
import { ConfirmModal } from "../../../../component/modal";
import { FileSearch } from "./file_search";
let editId = "";
let seeId = "";
let passwordId = "";
let isdefault = false;
const formParams = {
  method: "Create",
  id: "",
  item: null,
  orderid: "",
};
export function DistributionRecords(viewPower) {
  const { RangePicker } = DatePicker;
  const [createState, createFunc] = useBoolean(false);
  const action = fileAction;
  const [confirmState, confirmFunc] = useBoolean(false);
  const [qiyongState, qiyongFunc] = useBoolean(false);
  const [searchState, searchFunc] = useBoolean(false);
  const [delState, delFunc] = useBoolean(false);
  const { state, dispatch } = useContext(ListStore);
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);
  const [] = useBoolean(false);

  const [menuButton, setData] = useState([]);
  useEffect(() => {
    (async function () {
      let r = await new AuthLogic().selButton(viewPower.viewPower);
      if (r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          r[i] = r[i].split("_")[r[i].split("_").length - 1];
        }
      }
      // console.log(r);

      setData(r);
    })();

    return () => {};
  }, []);

  const columns: ColumnsType = [
    {
      title: "操作",
      key: "1",
      align: "center",
      width: Control.width(2),
      render: Control.render(
        (v) =>
          menuButton.indexOf("VIEW") != -1 && (
            <See
              click={() => {
                if (v.expenditureID) {
                  formParams.orderid = v.expenditureID;
                  createFunc.setTrue();
                }
              }}
            />
          )
      ),
    },
    {
      title: "分润会员",
      dataIndex: "memberName",
      key: "2",
      align: "center",
      // width: 120,
    },
    {
      title: "分润类型",
      dataIndex: "actionType",
      key: "8",
      align: "center",
      render: (v) => <span>{v == 1 ? "一级分润" : "二级分润"}</span>,
    },
    {
      title: "消费会员",
      dataIndex: "consumeMemberName",
      key: "3",
      align: "center",
      // width: 120,
    },
    {
      title: "消费金额",
      dataIndex: "consumeMoney",
      key: "4",
      align: "center",
    },
    {
      title: "分润金额",
      dataIndex: "money",
      key: "5",
      align: "center",
      // width: 140,
    },
    // {
    //   title: "手续费",
    //   key: "5",
    //   align: "center",
    //   dataIndex: "premium",
    //   // render: (v) =>
    //   //   v ? <Tag color="success">启用</Tag> : <Tag color="default">禁用</Tag>,
    // },
    {
      title: "实际到账金额",
      dataIndex: "actualMoney",
      key: "8",
      align: "center",
    },
    // {
    //   title: "审核状态",
    //   key: "9",
    //   align: "center",
    //   dataIndex: "auditStatus",
    //   render: (v) => (
    //     // (1待审核2通过(放款中)3拒绝4已到账5已撤销
    //     <Tag>
    //       {v == 1
    //         ? "待审核"
    //         : v == 2
    //         ? "通过(放款中)"
    //         : v == 3
    //         ? "拒绝"
    //         : v == 4
    //         ? "已到账"
    //         : "已撤销"}
    //     </Tag>
    //   ),
    // },
    {
      title: "分润时间",
      dataIndex: "addTime",
      key: "10",
      align: "center",
    },
  ];

  const onSearch = async (pageIndex) => {
    await action.loadPage({
      pageIndex: pageIndex,
      pageSize: defualtPageSize(),
      dispatch: tableDispatch,
      search: state.search,
    });
  };

  return (
    <>
      <Search>
        <Input
          maxLength={50}
          allowClear
          placeholder="请输入会员姓名、卡号"
          onChange={(e) => {
            action.setCondition("Keywords", e.target.value, dispatch);
          }}
        />
        <input type="button" value="快速查找" onClick={() => onSearch(1)} />

        <TipsGroup>
          <input
            type="button"
            value="高级查找"
            onClick={() => searchFunc.toggle()}
          />
          <Tips width="536px" display={searchState} left="0">
            <>
              <FileSearch
                onSearch={(...arg: [any]) => {
                  onSearch(...arg);
                  searchFunc.setFalse();
                }}
              />
            </>
          </Tips>
          <Mask visible={searchState} onClick={() => searchFunc.setFalse()} />
        </TipsGroup>
      </Search>
      <ButtonPanel></ButtonPanel>

      <DataTable
        columns={columns}
        action={action}
        onLoad={async () => {
          await onSearch(1);
          Progress.done();
        }}
        onChange={(pageIndex) => {
          onSearch(pageIndex);
        }}
        scroll={{ scrollToFirstRowOnChange: true, autoY: true }}
        // selectColumn={true}
        loading={tableState.loading}
      />

      {createState && (
        <FormStoreProvider>
          <Form
            close={(hasUpdate) => {
              if (hasUpdate) {
                onSearch(tableState.current);
              }
              createFunc.setFalse();
            }}
            id={formParams.orderid}
          />
        </FormStoreProvider>
      )}

      <ConfirmModal
        visible={confirmState}
        title="温馨提示"
        subTitle="确认删除？"
        message="删除之后信息无法恢复，请慎重考虑"
        close={async (r) => {
          if (r) {
            await action.delRow({ dispatch, rowItem: formParams });
            onSearch(tableState.current);
          }
          confirmFunc.setFalse();
        }}
      />
      <ConfirmModal
        visible={qiyongState}
        title="温馨提示"
        subTitle="确认启用？"
        message="启用之后用户正常使用"
        close={async (r) => {
          if (r) {
            let status = true;
            await action.enableSelectList({
              dispatch,
              selectList: tableState.selectList,
              status,
            });

            action.clearSelectedRowKeys({ dispatch: tableDispatch });

            await onSearch(tableState.current);
          }
          qiyongFunc.setFalse();
        }}
      />

      <ConfirmModal
        visible={delState}
        title="温馨提示"
        subTitle="确认删除？"
        message="删除之后信息无法恢复，请慎重考虑"
        close={async (r) => {
          if (r) {
            await action.delSelectList({
              dispatch,
              selectList: tableState.selectList,
            });

            action.clearSelectedRowKeys({ dispatch: tableDispatch });

            await onSearch(tableState.current);
          }
          delFunc.setFalse();
        }}
      />
    </>
  );
}
