import { useBoolean, useRequest } from "ahooks";
import { Input, Modal, Select, Tree } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useState } from "react";
import { Search } from "../search";
import { DataTable, Table } from "../table";
import {
  DataTableStore,
  DataTableStoreProvider,
} from "../table/store/data_table_store";
import { ChooseGoodsStore, ChooseStoreProvider } from "./store/choose_store";
import styles from "./style/index.module.scss";
import { Select as SelectAntd } from "antd";
import { chooseGoodsAction } from "./action/choose_action";
import { defualtPageSize } from "../../logic/common_logic";
import { ClassesLogic } from "../../logic/classes_logic";
import flattenDeep from 'lodash/flattenDeep'
let classId = "";
interface IParent {
  children?: any[];
}
function Goods(params: IChooseGoods) {
  const { visibleModal, close, type } = params;
  const { Option } = Select,
    action = chooseGoodsAction;
  const [checkStrictly, setCheckStrictly] = React.useState(false);
  const { state, dispatch } = useContext(ChooseGoodsStore);
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);
  const handleOk = () => {
  //  const list =
  //     tableState.selectList.length > 0 &&
  //     tableState.selectList.filter((item) => {
  //       return item.children ? item.children.length == 0 : true;
  //     });
      let list = tableState.selectList.length > 0 ? flattenDeep(tableState.selectList):[]
      
    close && close(true, list);
  };

  const handleCancel = () => {
    close && close(false, null);
  };

  const columns: ColumnsType = [
    {
      title: "分类编码",
      dataIndex: "classCode",
      align: "center",
    },
    {
      title: "商品分类",
      dataIndex: "title",
      align: "center",
    },
   
  ];

  const onSearch = async (pageIndex) => {
    if (params.filter && params.filter.zeroInventory) {
      await action.loadPageByZeroInventory({
        pageIndex: pageIndex,
        pageSize: defualtPageSize(),
        dispatch: tableDispatch,
        search: state.search,
      });
    } else {
      await action.loadPage({
        pageIndex: pageIndex,
        pageSize: defualtPageSize(),
        dispatch: tableDispatch,
        search: { ...state.search, classesId: classId },
      });
    }
  };
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      tableState.selectList = selectedRows;
    },
    onSelect: (record, selected, selectedRows) => {
      //console.log(record, selected, selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      //console.log(selected, selectedRows, changeRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.classCode === null, // Column configuration not to be checked
    }),
  };

  useRequest(() => action.init({ dispatch }));

  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([
    "0-0-0",
    "0-0-1",
  ]);
  const [checkedKeys, setCheckedKeys] = useState<React.Key[]>(["0-0-0"]);
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);

  const onExpand = (expandedKeysValue: React.Key[]) => {
    //console.log('onExpand', expandedKeysValue);
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  const onCheck = (checkedKeysValue: React.Key[]) => {
    //console.log('onCheck', checkedKeysValue);
    setCheckedKeys(checkedKeysValue);
    let arr = [];
    state.areaList.map((item, i) => {
      checkedKeysValue.map((keys) => {
        if (keys == item.key) {
          arr.push(item);
        }
      });
    });
  };

  const onSelect = (selectedKeysValue: React.Key[], info: any) => {
    //console.log('onSelect', info);
    setSelectedKeys(selectedKeysValue);
  };
  let tableRowSelection: any = {};
  if (type == "radio") {
    tableRowSelection = {
      rowSelection: {
        type: "radio",
        getCheckboxProps: (record) => ({
          disabled: record.parentID != "", // Column configuration not to be checked
        }),
      },
    };
  }
  return (
    <Modal
      title="选择分类"
      visible={visibleModal}
      onOk={handleOk}
      onCancel={handleCancel}
      width={900}
      cancelText="取消"
      okText="确定"
    >
      <div className={styles.root}>
        <div style={{ marginTop: 10, height: 250 }}>
          <div className={styles.list} style={{ width: "100%" }}>
            <Table
              columns={columns}
              dataSource={state.areaList}
              // rowKey={(record) => record.key}
              rowKey='id'
              rowSelection={{ ...rowSelection, checkStrictly }}
              onChange={(pageIndex) => {
                onSearch(pageIndex);
              }}
            />
            {/* <Tree
                            checkable
                            onExpand={onExpand}
                            expandedKeys={expandedKeys}
                            autoExpandParent={autoExpandParent}
                            onCheck={onCheck}
                            checkedKeys={checkedKeys}
                            onSelect={onSelect}
                            selectedKeys={selectedKeys}
                            treeData={state.areaList}
    /> */}
          </div>
        </div>
      </div>
    </Modal>
  );
}

type Filter = {
  zeroInventory?: boolean;
};
interface IChooseGoods {
  visibleModal: boolean;
  close?: (status, r) => void;
  filter?: Filter;
  type?: string;
}
export function ChooseClassify(params: IChooseGoods) {
  return (
    <ChooseStoreProvider>
      <DataTableStoreProvider>
        <Goods {...params} />
      </DataTableStoreProvider>
    </ChooseStoreProvider>
  );
}
