import { useContext, useRef, useState, useEffect } from "react";
import { comConType } from "..";
import { TopicDataAction } from "../action/TopicDataAction";
import { SelectUrl } from "../com/selectUrl";
import { ITopicValue, TopicStore } from "../store/topic";
import styles from "../style/shopwindow.module.scss";
import defuateImgUrl from "../../../assets/images/mini/w1.png";
import defuateImg2Url from "../../../assets/images/mini/w2.png";
import { ISelectUrl } from "./modal/modal";
import { ApiCon } from "../com/apiCon";
import { message } from "antd";

interface ISelectType {
  size: string;
  imgUrl: string;
}

const selectTypeList: [ISelectType, ISelectType, ISelectType] = [
  {
    size: "370*410",
    imgUrl: defuateImgUrl,
  },
  {
    size: "370*200",
    imgUrl: defuateImg2Url,
  },
  {
    size: "370*200",
    imgUrl: defuateImg2Url,
  },
];

interface IData {
  fileUrl: string;
  fileName: string;
  selectUrl?: ISelectUrl;
}

type dataType = [IData, IData, IData];

/**
 * 橱窗
 * @param props
 */
export function Shopwindow(props: { id: number; con: comConType }) {
  const topicStoreState = useContext(TopicStore);
  const inputRef = useRef(null);
  const [onSelectId, setOnSelectId] = useState(-1);

  let actionArg: [ITopicValue, number] = [topicStoreState, props.id];
  const data: dataType = TopicDataAction.getData<dataType>(...actionArg);
  useEffect(() => {
    if (!data) {
      TopicDataAction.setData<dataType>(
        ...actionArg,
        Array(3)
          .fill(0)
          .map(() => {
            return {
              fileUrl: "",
              fileName: "",
            };
          }) as any
      );
    }
  });
  if (!data) {
    return <div></div>;
  }

  //注册数据提取方法
  TopicDataAction.setEF(topicStoreState, props.id, () => {
    return new Promise((r, e) => {
      const advdata = data.filter((item) => {
        return item.selectUrl != null && item.selectUrl != undefined;
      });
      let coupon = advdata.some((adv) => {
        return adv.selectUrl.type == "优惠券" && adv.fileUrl == "";
      });
      let classify = advdata.some((adv) => {
        if (adv.selectUrl.type == "分类") return adv.fileUrl == "";
      });
      if (advdata.length < data.length) {
        message.error("有橱窗内容没有填写完整");
      } else if (coupon) {
        message.error("存在优惠券没有设置图片");
      } else if (classify) {
        message.error("存在分类没有图片");
      } else {
        ApiCon.turnBlobImgUrl(
          data.map((item) => {
            return {
              url: item.fileUrl,
              name: item.fileName,
            };
          })
        ).then((urls) => {
          r(
            data.map((item, index) => {
              return {
                ...item,
                fileUrl: urls[index],
              };
            })
          );
        });
      }
    });
  });

  /** 选择文件 */
  const inputChange = (e) => {
    let _file: File = inputRef.current.files[0];
    //
    data[onSelectId].fileUrl = URL.createObjectURL(_file);
    data[onSelectId].fileName = _file.name;
    TopicDataAction.setData(...actionArg, data);
  };

  /** 编辑列表url */
  const editLinkItemUrl = (i: number, selectUrl: ISelectUrl) => {
    data[i].selectUrl = selectUrl;
    data[i].fileUrl = "";
    data[i].fileName = "";
    TopicDataAction.setData(...actionArg, data);
  };

  if (props.con == "show") {
    return (
      <div className={styles.shopwindow}>
        <div className={styles.left}>
          <div className={styles.img}>
            <img
              src={
                ApiCon.getImageUrl(
                  data[0].fileUrl != ""
                    ? data[0].fileUrl
                    : data[0].selectUrl && data[0].selectUrl.imgUrl
                ,data[0].selectUrl ? data[0].selectUrl.goodsSource: 1) || defuateImgUrl
              }
              alt=""
            />
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.img}>
            <img
              src={
                ApiCon.getImageUrl(
                  data[1].fileUrl != ""
                    ? data[1].fileUrl
                    : data[1].selectUrl && data[1].selectUrl.imgUrl
                    ,data[1].selectUrl ? data[1].selectUrl.goodsSource: 1) || defuateImg2Url
              }
              alt=""
            />
          </div>
          <div className={styles.img}>
            <img
              src={
                ApiCon.getImageUrl(
                  data[2].fileUrl != ""
                    ? data[2].fileUrl
                    : data[2].selectUrl && data[2].selectUrl.imgUrl
                    ,data[2].selectUrl ? data[2].selectUrl.goodsSource: 1) || defuateImg2Url
              }
              alt=""
            />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className={styles.shopwindow_ctrl}>
          <input
            ref={inputRef}
            onChange={inputChange}
            type="file"
            accept="image/*"
          />
          <h2>橱窗设置</h2>
          <ul className={styles.list}>
            {data.map((item, index) => {
              return (
                <li key={index} className={styles.item}>
                  <div
                    className={styles.left}
                    onClick={() => {
                      setOnSelectId(index);
                      inputRef.current.click();
                    }}
                  >
                    <img
                      src={
                        ApiCon.getImageUrl(
                          item.fileUrl != ""
                            ? item.fileUrl
                            : item.selectUrl && item.selectUrl.imgUrl,
                            item.selectUrl ? item.selectUrl.goodsSource: 1) || selectTypeList[index].imgUrl
                      }
                      alt=""
                    />
                    <div className={styles.bottom}>重新选择</div>
                  </div>
                  <div className={styles.right}>
                    <div className={styles.from_item}>
                      <span>链接到：</span>
                      <SelectUrl
                        select={item.selectUrl}
                        selectedBack={(select: ISelectUrl) => {
                          editLinkItemUrl(index, select);
                        }}
                      />
                    </div>
                    <div className={styles.from_item}>
                      <span>图片尺寸</span>
                      <span>{selectTypeList[index].size}</span>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </>
    );
  }
}
