import { message } from "antd";
import { MenuLogic } from "../../../../logic/menu_logic";
import { FormAttribute } from "../../store/menu/form_store";
import { AuthxtLogic } from "../../../../logic/authxt_logic";
type Init = {
    state;
    dispatch;
    form;
    editId: string;
    seeId: string;
}
interface InitBasic {
    dispatch;
    state;
}
interface InitEdit {
    state;
    dispatch;
    form;
    id;
}
interface ISetValue {
    attribute: FormAttribute;
    value: string | number | any[] | any;
    dispatch;
}
var id = ''
export class FormAction {

    private menuLgc = new MenuLogic();
    private AuthxtLgc = new AuthxtLogic();

    async init({ state, dispatch, seeId, editId, form }: Init) {
        await this.initBasic({ dispatch, state });
        if (editId || seeId) {
            await this.initEdit({ dispatch, state, id: editId || seeId, form })
        }
        id = editId || seeId
    }
    private async initBasic({ dispatch, state }: InitBasic) {
        let pageSize = 1000
        let pageIndex = 1
        let list = [
            {
                name: '根菜单',
                id: '',
                second: await (await this.menuLgc.querysList({ pageIndex, pageSize })).data.data
            }
        ]
        dispatch({ type: "MenuList", payload: list });
        dispatch({ type: "AuthxtList", payload: await (await this.AuthxtLgc.querysList({ pageIndex, pageSize })).data.data.dataSource });
    }
    private async initEdit({ dispatch, id, form }: InitEdit) {
        // 回填状态
        const item = await this.menuLgc.selData(id);

        // selectClasses
        dispatch({ type: "SetForm", payload: item });

        // 填充节点
        form.setFieldsValue({
            "menu-parentUID": item.parentUID == null ? '' : item.parentUID,
            "menu-ico": item.icon,
            "menu-name": item.name,
            "menu-sort": item.sortIndex,
            "menu-path": item.path,
            "menu-component": item.component,
            "menu-viewPower": item.viewPower,
            "menu-keepAlive": item.keepAlive,
            "menu-hidden": item.hidden,
            "menu-isFrame": item.isFrame,
        });
    }
    reset({ dispatch, form }) {
        // selectClasses
        dispatch({
            type: "SetForm", payload: {
                parentUID: "",
                name: "",
                icon: "",
                sortIndex: "",
                path: "",
                component: "",
                viewPower: "",
                keepAlive: "",
                hidden: "",
                isFrame: "",
                phone: "",
                status: "",
                provincialCapital: "",
                city: "",
                district: "",
                detailedAddress: "",
                usableArea: "",
            }
        });

        // 填充节点
        form.setFieldsValue({
            "menu-parentUID": "",
            "menu-ico": "",
            "menu-name": "",
            "menu-sort": "",
            "menu-path": "",
            "menu-component": "",
            "menu-viewPower": "",
            "menu-keepAlive": "",
            "menu-hidden": "",
            "menu-isFrame": "",
        });
    }
    setValue({ attribute, value, dispatch }: ISetValue) {
        dispatch({ type: "Change_FormData", payload: { attribute, value } });
    }
    private paddingData(state) {
        let params: any = {};
        {
            const { id, parentUID, icon, name, sortIndex, path, component, viewPower, keepAlive, isFrame, hidden } = state;

            params = {
                id,
                parentUID: parentUID == '' ? null : parentUID,
                icon,
                name,
                sortIndex,
                path,
                component,
                viewPower,
                keepAlive,
                isFrame,
                hidden,
                system: 0
            }
        }
        return params;
    }
    async addSubmit({ state }) {
        const r = await this.menuLgc.addData(this.paddingData(state));
        if (r.data.statusCode == 200) {
            message.success("保存成功");
        } else {
            message.error(r.data.message);
        }
        return r.data.statusCode == 200;
    }
    async updSubmit({ state }) {
        state['id'] = id
        const r = await this.menuLgc.updData(this.paddingData(state));
        if (r.data.statusCode == 200) {
            message.success("更新成功");
        } else {
            message.error(r.data.message);
        }
        return r.data.statusCode == 200;
    }

}
export const formAction = new FormAction();