import { message } from "antd";
// import axios from "axios";
import Cookies from "js-cookie";
import { DataTableAction } from "../../table/action/data_table_action";
import { Tools, axios } from "../../../config";
import { ShopLogic } from "../../../logic/shop_logic";
import { ClassesLogic } from "../../../logic/classes_logic";
import { GoodsLogic } from "../../../logic/goods_logic";

class ChooseAction extends DataTableAction {
    async delRow(id) {
    }
    delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {
    }
    loadPageByZeroInventory({ dispatch, pageIndex, pageSize, search }) {
        this._loadPage({ dispatch, pageIndex, pageSize, search }, new GoodsLogic().selListByZeroInventory);
    }
    loadPage({ dispatch, pageIndex, pageSize, search }) {
        this._loadPage({ dispatch, pageIndex, pageSize, search },   new ClassesLogic().selListByTree);
    }
    private async _loadPage({ dispatch, pageIndex, pageSize, search }, callback) {
        // // 商品分类
        // const classifyList = await new ClassesLogic().querysList();

        // // 商品单位
        // const unitList = await new UnitLogic().querysList() || [];

        // 商品列表
        const { keywords, classesId, statusId, typeId, brandId } = search;

        const params = {
            pageIndex, pageSize, goodsBrandID: brandId, goodsClassesID: classesId, goodsType: typeId, status: statusId, goodsNameOrNo: keywords
        }
        if (undefined == keywords) {
            delete params.goodsNameOrNo;
        }
        if (undefined == brandId) {
            delete params.goodsBrandID;
        }
        if (undefined == classesId) {
            delete params.goodsClassesID;
        }
        if (undefined == typeId) {
            delete params.goodsType;
        }
        if (undefined == statusId) {
            delete params.status;
        }
        if (-1 == statusId) {
            delete params.status;
        }

        const list = await callback(params);
       if(list && list instanceof Array)
        this.setPaging({ dispatch, pageIndex, total: list.length, dataList: list, pageSize });
       

    }

    setCondition(method: "Keywords" | "Classes" | "Status", value, dispatch) {
        if ("Keywords" == method) {
            dispatch({ type: "SetSearch", payload: { keywords: value } });
        }
        else if ("Classes" == method) {
            dispatch({ type: "SetSearch", payload: { classesId: value } });
        }
        else if ("Status" == method) {
            dispatch({ type: "SetSearch", payload: { statusId: value } });
        }
    }
    async init({ dispatch }) {
        // 商品分类
        const list = await new ClassesLogic().selListByTree();
        console.log(list)
        dispatch({ type: "SetAreaList", payload: list });
    }
}
export const chooseGoodsAction = new ChooseAction();