import { message } from "antd";
import { RoleLogic } from "../../../../logic/role_logic";
import { UserLogic } from "../../../../logic/user_logic";
import { FormAttribute } from "../../store/list/list_form_store";
import { CommissionRuleLogic } from "../../../../logic/commissionrule_logic";
type Init = {
    state;
    dispatch;
    form;
    editId: string;
    seeId: string;
    passwordId?: string;
}
interface InitBasic {
    dispatch;
    state;
}
interface InitEdit {
    state;
    dispatch;
    form;
    id;
}
interface ISetValue {
    attribute: FormAttribute;
    value: string | number | any[] | boolean | any;
    dispatch;
}

export class ListFormAction {

    private userLgc = new UserLogic();
    private roleLgc = new RoleLogic();

    async init({ state, dispatch, seeId, editId, passwordId, form }: Init) {
        await this.initBasic({ dispatch, state });

        if (editId || seeId || passwordId) {
            await this.initEdit({ dispatch, state, id: editId || seeId || passwordId, form })
        }
    }
    private async initBasic({ dispatch, state }: InitBasic) {
        let parm = {
            pageIndex: 1,
            pageSize: 10000
        }
        dispatch({ type: "RoletList", payload: await (await this.roleLgc.querysList(parm)).data.data.dataSource });
        const r = await new CommissionRuleLogic().getListAll()
        let list = []
        if(r.data.statusCode == 200){
            list = r.data.data || []
        }
        dispatch({type: 'CommissionRuleList', payload: list})
    }
    private async initEdit({ dispatch, id, form }: InitEdit) {
        // 回填状态
        const item = await this.userLgc.lookData(id);
        console.log(item instanceof Array)
       // selectClasses
        dispatch({ type: "SetForm", payload: item });
        // 填充节点
        form.setFieldsValue({
            "user-code": item.userID,
            "user-name": item.userName,
            "user-password": item.password,
            "user-sex": item.sex,
            "user-qq": item.qq,
            "user-email": item.email,
            "user-phone": item.phone,
            "user-birthday": item.birthday,
            "user-identityCard": item.identityCard,
            "user-address": item.address,
            "user-enabled": item.enabled,
            "user-roleID": item.roleID,
            "remarks": item.remark,
            "commissionRuleID": item.commissionRuleID
        });
    }
    reset({ dispatch, form }) {
        // selectClasses
        dispatch({
            type: "SetForm", payload: {
                userID: "",
                userName: "",
                nickName: "",
                email: "",
                password: "",
                sex: "",
                avatarUrl: "",
                qq: "",
                phone: "",
                provinceID: "",
                province: "",
                cityID: "",
                city: "",
                countyID: "",
                county: "",
                address: "",
                remark: "",
                identityCard: "",
                birthday: "",
                enabled: "",
                roleID: "",
                oneSession: "",
                commissionRuleID: ''
            }
        });
        // 填充节点
        form.setFieldsValue({
            "user-code": "",
            "user-name": "",
            "user-password": "",
            "user-sex": "",
            "user-qq": "",
            "user-email": "",
            "user-phone": "",
            "user-birthday": "",
            "user-identityCard": "",
            "user-address": "",
            "user-enabled": "",
            "user-roleID": "",
            "remarks": "",
            "commissionRuleID":''
        });
    }
    setValue({ attribute, value, dispatch }: ISetValue) {
        dispatch({ type: "Change_FormData", payload: { attribute, value } });
    }
    private paddingData(state) {
        let params: any = {};
        {
            const { userID, userName, roleID, nickName, email, password, sex, avatarUrl, qq, phone, provinceID, province, cityID, city, countyID, county, address, remark, identityCard, birthday, enabled, oneSession, commissionRuleID } = state;

            params = {
                userID, userName, roleID, nickName, email, password, sex, avatarUrl, qq, phone, provinceID, province, cityID, city, countyID, county, address, remark, identityCard, birthday, enabled, oneSession, commissionRuleID
            }
        }
        return params;
    }
    private resetData(state) {
        let params: any = {};
        {
            const { userID, newPassword, confirmPassword, } = state;

            params = {
                userID, newPassword, confirmPassword,
            }
        }
        return params;
    }
    async addSubmit({ state }) {
        let parm = this.paddingData(state)
        parm.sex = parm.sex == undefined ? '未知' : parm.sex
        const r = await this.userLgc.addData(parm);
        if (r.statusCode == 200) {
            message.success("保存成功");
        } else {
            message.error(r.returnMessage);
        }
        return r.statusCode == 200;
    }
    async resetSubmit({ state }) {
        if (this.resetData(state).newPassword != this.resetData(state).confirmPassword) {
            message.error('两次输入密码不同');
            return false
        }
        const r = await this.userLgc.resetData(this.resetData(state));
        if (r.statusCode == 200) {
            message.success("重置成功");
        } else {
            message.error(r.returnMessage);
        }
        return r.statusCode == 200;
    }
    async updSubmit({ state }) {
        let parm = this.paddingData(state)
        parm.sex = parm.sex == undefined ? '未知' : parm.sex
        const r = await this.userLgc.updData(parm);
        if (r.statusCode == 200) {
            message.success("更新成功");
        } else {
            message.error(r.returnMessage);
        }
        return r.statusCode == 200;
    }
}
export const formAction = new ListFormAction();