import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
  TimePicker,
  DatePicker,
  Tag,
} from "antd";
import React, { useContext } from "react";
import { FormSubtitle } from "../../../../commodity/view/form_subtitle";
import styles from "../../../style/inventory_goods_formbasic.module.scss";

import { useBoolean } from "ahooks";
import { ChooseShop } from "../../../../../component/choose-shop";
import { formAction } from "../../../action/exchange/coupon/form_action";
import { FormStore } from "../../../store/exchange/coupon/form_store";
import { autoRem } from "../../../../../global.style";
import { useState } from "react";
import { RadioStyle } from "../../../../../component/radio-style";

import moment from "moment";

export function FormBasic({ title, disabled, updateFormField, coupon }) {
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  /** 是否选择店铺 */
  const [shopState, shopFunc] = useBoolean(false);
  /** 是否选择商品 */
  const [goodsState, goodsFunc] = useBoolean(false);

  /** 表单状态 */
  const { state, dispatch } = useContext(FormStore);
  /** 表单 */
  const { formData } = state;
  /** 表单action */
  const action = formAction;
  /**生效时间 */
  function TimeType({ value, takeType }) {
    const [] = useState(1);
    // const { dispatch } = useContext(PrecisionFormStore);

    const onChange = (e) => {
      if (!disabled) {
        action.setFormData({
          dispatch,
          key: "takeType",
          value: e.target.value,
        });
      }
      if (e.target.value == 1) {
        action.setFormData({ dispatch, key: "takeBeginDate", value: "" });
        action.setFormData({ dispatch, key: "takeEndDate", value: "" });
      }
    };
    return (
      <RadioStyle>
        <Radio.Group onChange={onChange} value={takeType}>
          <Radio
            value={1}
            style={{ marginLeft: "15px", height: "30px", lineHeight: "30px" }}
          >
            立即生效
          </Radio>
          <Radio
            value={2}
            style={{ marginLeft: "10px", height: "30px", lineHeight: "30px" }}
          >
            指定时间段生效
          </Radio>
        </Radio.Group>
      </RadioStyle>
    );
  }
  /**兑换次数 */
  function ExchangeTimesType({ value, switchValue }) {
    const [] = useState(1);
    const { dispatch } = useContext(FormStore);

    const onChange = (e) => {
      if (!disabled) {
        action.setFormData({
          dispatch,
          key: "isSetCount",
          value: e.target.value,
        });
      }
      if (e.target.value == 1) {
        action.setFormData({ dispatch, key: "exchangeCount", value: 0 });
      }
    };
    return (
      <RadioStyle>
        <Radio.Group onChange={onChange} value={switchValue}>
          <Radio
            value={1}
            style={{ marginLeft: "15px", height: "30px", lineHeight: "30px" }}
          >
            不限制
          </Radio>
          <Radio
            value={2}
            style={{ marginLeft: "10px", height: "30px", lineHeight: "30px" }}
          >
            限制
          </Radio>
        </Radio.Group>
      </RadioStyle>
    );
  }
  const onangeChange = (time, timeString) => {
    action.setFormData({
      dispatch,
      key: "takeBeginDate",
      value: timeString[0],
    });
    action.setFormData({ dispatch, key: "takeEndDate", value: timeString[1] });
  };
  const disabledDate = (current) => {
    return current < moment().startOf("day");
  };
  let gradecheck =
    formData.isMemberClass == 1
      ? {}
      : {
          required: true,
          validator: (_, __, callBack: (error?: string) => void) => {
            if (
              state.formData.memberClasss == undefined ||
              state.formData.memberClasss.length == 0
            ) {
              callBack("请选择会员等级");
              return;
            }
            callBack();
          },
        };
  let exchangeCountCheck =
    formData.isSetCount == 1
      ? [{}]
      : [
          {
            require: true,
            validator: (_, __, callBack: (error?: string) => void) => {
              if (state.formData.exchangeCount == undefined) {
                callBack("请输入每人兑换次数");
                return;
              } else {
                if (
                  !/^\+?[1-9][0-9]*$/.test(
                    state.formData.exchangeCount.toString()
                  )
                ) {
                  callBack("兑换次数应为大于0的整数");
                  return;
                } else if (state.formData.exchangeCount > coupon.couponNumber) {
                  callBack("兑换次数不能大于优惠券总量");
                  return;
                }
              }
              callBack();
            },
          },
        ];
  let height = document.body.clientHeight - 188;
  return (
    <div className={styles.root} style={{ height: height + "px" }}>
      <div className={styles.content}>
        <FormSubtitle>优惠券信息</FormSubtitle>
        {coupon && (
          <div
            className={styles.coupon_info}
            style={{
              margin: "" + autoRem(1.9375) + " " + autoRem(1.25) + " 0 0",
            }}
          >
            <span>优惠券名称：{coupon.couponName}</span>
            <span>
              优惠券类型：
              {coupon.couponType == 2 && <Tag color="success">直减券</Tag>}
              {coupon.couponType == 1 && <Tag color="error">折扣券</Tag>}
            </span>
            <span>
              {coupon.couponType == 2
                ? "面额：" + coupon.subMoney + "元"
                : "折扣：" + coupon.discount / 10 + "折"}
            </span>
            <span>满{coupon.meetMoney}元可用</span>
            {coupon.validType == 1 && (
              <span>
                有效期：{coupon.validStartDate.substring(0, 10)}~
                {coupon.validEndDate.substring(0, 10)}
              </span>
            )}
            {coupon.validType == 2 && (
              <span>领取后{coupon.validDay}天内有效</span>
            )}
            <span>剩余量：{coupon.couponNumber}</span>
          </div>
        )}
      </div>
      <div className={styles.content}>
        <FormSubtitle>规则信息</FormSubtitle>

        <div
          className="custom-radio"
          style={{
            margin: "" + autoRem(1.9375) + " " + autoRem(1.25) + " 0 0",
          }}
        >
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 3 }}
                label="适应会员等级"
                style={{ marginBottom: 0 }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Checkbox
                    style={{ marginLeft: "15px" }}
                    disabled={disabled}
                    checked={formData.isMemberClass == 1}
                    onChange={(e) => {
                      if (e.target.checked) {
                        action.setFormData({
                          dispatch,
                          key: "memberClasss",
                          value: [],
                        });
                      }
                      action.setFormData({
                        dispatch,
                        key: "isMemberClass",
                        value: e.target.checked ? 1 : 2,
                      });
                    }}
                  >
                    不限制
                  </Checkbox>
                  <Form.Item name="memberClasss" rules={[gradecheck]}>
                    {formData.memberClasss ? "" : ""}
                    <Select
                      mode="multiple"
                      style={{ width: "400px", marginTop: "10px" }}
                      placeholder="请选择"
                      disabled={disabled || formData.isMemberClass == 1}
                      value={formData.memberClasss}
                      onChange={(v) =>
                        action.setFormData({
                          dispatch,
                          key: "memberClasss",
                          value: v,
                        })
                      }
                    >
                      {state.tagList.map((v) => (
                        <Option value={v.id} key={v.id}>
                          {v.gradeName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item labelCol={{ span: 3 }} label="每人兑换次数">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <ExchangeTimesType
                    value={1}
                    switchValue={formData.isSetCount}
                  />
                  {formData.isSetCount == 2 && (
                    <Form.Item name="exchangeCount" rules={exchangeCountCheck}>
                      {formData.exchangeCount ? "" : ""}
                      <Input
                        placeholder="请输入次数"
                        maxLength={10}
                        disabled={disabled}
                        autoComplete="off"
                        value={formData.exchangeCount}
                        style={{ width: "400px", marginTop: "10px" }}
                        onChange={(e) => {
                          action.setFormData({
                            dispatch,
                            key: "exchangeCount",
                            value: e.target.value,
                          });
                          updateFormField("exchangeCount", e.target.value);
                        }}
                      />
                    </Form.Item>
                  )}
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 3 }}
                label="生效时间"
                // name="takeType"
              >
                {/* <TimeType  value={1} takeType={formData.takeType}/> */}
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <TimeType
                    value={1}
                    takeType={formData.takeType ? formData.takeType : 1}
                  />
                  {formData.takeType == 2 && (
                    <Form.Item
                      name="takeBeginDate,takeBeginDate"
                      rules={[
                        {
                          required: true,
                          validator: (
                            _,
                            __,
                            callBack: (error?: string) => void
                          ) => {
                            if (
                              formData.takeBeginDate == undefined ||
                              formData.takeBeginDate == null ||
                              formData.takeBeginDate == ""
                            ) {
                              callBack("请选择生效日期");
                              return;
                            }
                            callBack();
                          },
                        },
                      ]}
                    >
                      <RangePicker
                        disabled={disabled}
                        disabledDate={disabledDate}
                        defaultValue={[
                          formData.takeBeginDate != "" &&
                          formData.takeBeginDate != null &&
                          formData.takeBeginDate != undefined
                            ? moment(formData.takeBeginDate, "YYYY-MM-DD")
                            : null,
                          formData.takeEndDate != "" &&
                          formData.takeEndDate != null &&
                          formData.takeEndDate != undefined
                            ? moment(formData.takeEndDate, "YYYY-MM-DD")
                            : null,
                        ]}
                        style={{ width: "400px", marginTop: "10px" }}
                        onChange={onangeChange}
                      />
                    </Form.Item>
                  )}
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 3 }}
                label="所需积分值"
                name="points"
                rules={[
                  {
                    required: true,
                    message: "请输入兑换所需积分值",
                  },
                  {
                    pattern: /^\+?[1-9][0-9]*$/,
                    message: "兑换所需积分值应为大于0的整数",
                  },
                ]}
              >
                {/* {formData.points ? '' : ''} */}
                <Input
                  placeholder="所需积分值"
                  maxLength={10}
                  value={formData.points}
                  autoComplete="off"
                  style={{ width: "400px" }}
                  disabled={disabled}
                  onChange={(e) => {
                    action.setFormData({
                      dispatch,
                      key: "points",
                      value: e.target.value,
                    });
                    // updateFormField('points')
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                label="分配门店"
                labelCol={{ span: 3 }}
                name="shopName"
                rules={[
                  {
                    required: true,
                    validator: (_, __, callBack: (error?: string) => void) => {
                      if (
                        state.formData.shopID == undefined ||
                        state.formData.shopID.length == 0
                      ) {
                        callBack("请选择分配门店");
                        return;
                      }
                      callBack();
                    },
                  },
                ]}
              >
                {formData.shopName ? "" : ""}
                <Input
                  style={{ width: "400px" }}
                  disabled={disabled}
                  value={formData.shopName}
                  autoComplete="off"
                  placeholder="请选择门店"
                  onClick={() => shopFunc.setTrue()}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <ChooseShop
          visibleModal={shopState}
          type="checkbox"
          close={(status, r) => {
            if (r) {
              let arr = [];
              let shopNames = []
              if (r.length > 0) {
                r.map((item) => {
                  arr.push(item.id);
                  shopNames.push(item.shopName)
                });
              }
              action.setFormData({ dispatch, key: "shopID", value: arr });
              action.setFormData({
                dispatch,
                key: "shopName",
                value: shopNames.toString(),
              });
            }
            updateFormField("shopName");
            shopFunc.setFalse();
          }}
        />
      </div>
    </div>
  );
}
