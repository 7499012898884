/**
 * 函数工具
 */
export class FunctionTool {
    /**
     * 节流
     * @param _f 原方法
     * @param _time 间隔时间
     */
    static throttle(_f: Function, _time: number): Function {
        let _timeCon;
        return (...arg) => {
            clearTimeout(_timeCon);
            _timeCon = setTimeout(() => {
                _f(...arg);
            }, _time);
        };
    }

    /**
     * 防抖
     * @param _f 原方法
     * @param _time 间隔时间
     */
    static antiShake(_f: Function, _time: number): {
        (): void;
        /** 是否能运行 */
        ifCanRun: boolean;
    } {
        let _onTime = 0;
        let __f = (...arg) => {
            //获取时间差
            let _differ = Date.now() - _onTime;
            if (_differ >= _time) {
                _onTime = Date.now();
                _f(...arg);
            }
        };
        //
        Object.defineProperty(__f, 'ifCanRun', {
            configurable: false,
            enumerable: true,
            get() {
                let _differ = Date.now() - _onTime;
                return _differ >= _time;
            },
        });
        //
        return __f as any;
    }
}
export default function printHtml(html) {
    let style = getStyle();
    let container = getContainer(html);
   
    document.body.appendChild(style);
    document.body.appendChild(container);
   
    // getLoadPromise(container).then(() => {
      window.print();
      document.body.removeChild(style);
      document.body.removeChild(container);
    // });
  }
   
  // 设置打印样式
  function getStyle() {
  let styleContent = `#print-container {
      display: none;
  }
  @media print {
      body > :not(.print-container) {
          display: none;
      }
      html,
      body {
          display: block !important;
      }
      #print-container {
          display: block;
      }
  }`;
    let style = document.createElement("style");
    style.innerHTML = styleContent;
    return style;
  }
   
  // 清空打印内容
  function cleanPrint() {
    let div = document.getElementById('print-container')
    if (!!div) {
      document.querySelector('body').removeChild(div)
    }
  }
   
  // 新建DOM，将需要打印的内容填充到DOM
  function getContainer(html) {
    // cleanPrint()
    let container = document.createElement("div");
    container.setAttribute("id", "print-container");
    container.innerHTML = html;
    return container;
  }
   
//   // 图片完全加载后再调用打印方法
//   function getLoadPromise(dom) {
//     let imgs = dom.querySelectorAll("img");
//     imgs = [].slice.call(imgs);
   
//     if (imgs.length === 0) {
//       return Promise.resolve();
//     }
   
//     let finishedCount = 0;
//     return new Promise(resolve => {
//       function check() {
//         finishedCount++;
//         if (finishedCount === imgs.length) {
//           resolve();
//         }
//       }
//       imgs.forEach(img => {
//         img.addEventListener("load", check);
//         img.addEventListener("error", check);
//       })
//     });
//   }