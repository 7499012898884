import errorImgUrl from '../assets/images/error/20210830154122.png';
import styles from "./styles/index.module.scss";
import { useHistory } from 'react-router-dom';

/**
 * 错误页面
 */
export function Error() {
    const his = useHistory();
    let alert: string = his.location.search.match(/alert=(.*)&?/)?.[1];
    alert = decodeURI(alert);
    return <div className={styles.root}>
        <img src={errorImgUrl} alt="" />
        <span>{alert || '出错了'}</span>
    </div>
}