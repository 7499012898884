import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
  TimePicker,
  DatePicker,
  Tag,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { FormSubtitle } from "../../../commodity/view/form_subtitle";
import styles from "../../style/equip.module.scss";

import img_del from "../../../../assets/images/shanchu.png";
import { formAction } from "../../action/equip/form_action";
import { useBoolean } from "ahooks";
import { ChooseGoods } from "../../../../component/choose-goods";
import { Table } from "../../../../component/table";
import { ChooseShop } from "../../../../component/choose-shop";
import { FormStore } from "../../store/equip/form_store";
import { autoRem } from "../../../../global.style";
import { useState } from "react";

export const FormBasic = ({ disabled }) => {
  const action = formAction;
  const { state, dispatch } = useContext(FormStore);

  let height = document.body.clientHeight - 188;
  return (
    <div className={styles.root} style={{ height: height + "px" }}>
      <div className={styles.content}>
        <FormSubtitle>设备信息</FormSubtitle>

        <div
          className="custom-radio"
          style={{
            margin: "" + autoRem(1.9375) + " " + autoRem(1.25) + " 0 0",
          }}
        >
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={16}>
              <Form.Item
                name="ipAddress"
                labelCol={{ span: 5 }}
                label="设备IP地址"
                rules={[{ required: true }]}
              >
                <Input
                  maxLength={30}
                  type="text"
                  disabled={disabled}
                  onChange={(e) =>
                    action.setFormData({
                      dispatch,
                      key: "ipAddress",
                      value: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={16}>
              <Form.Item
                name="equipmentName"
                labelCol={{ span: 5 }}
                label="设备别名"
              >
                <Input
                  maxLength={30}
                  type="text"
                  disabled={disabled}
                  onChange={(e) =>
                    action.setFormData({
                      dispatch,
                      key: "equipmentName",
                      value: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};
