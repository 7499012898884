import { message } from "antd";
import { GradeLogic } from "../../../../logic/grade_logic";
import { ShopLogic } from "../../../../logic/shop_logic";
import { ClassesLogic} from '../../../../logic/classes_logic'
import { GoodsLogic } from '../../../../logic/goods_logic'
import {MarketingTimeSalesPromotion_logic} from '../../../../logic/market/MarketingTimeSalesPromotion_logic'
import Cookies from 'js-cookie'
type Init = {
    state;
    dispatch;
    form;
    id: string;
    method: string;
}
interface InitBasic {
    dispatch;
    search;
}
interface InitEdit {
    state;
    dispatch;
    form;
    id;
}

class FormAction {
    private GradeLogic = new GradeLogic();
    //
    setDay(state){
        let date1 = state.formData.beginDate;
        let date2 = state.formData.endDate;
       let monthBegin = (new Date(date1)).getMonth();
       let monthEnd = (new Date(date2)).getMonth();
       let dayBegin = (new Date(date1)).getDate();
       let dayEnd = (new Date(date2)).getDate();
       let dayList=[]
   
           if(monthBegin!=null&&monthEnd!=null&&monthBegin == monthEnd){
               for(let i= dayBegin; i<=dayEnd ;i++){
                  dayList.push(i)
               }
           }else{
              for(let i = 0; i< 31;i++){
                  dayList.push(i+1)
               }
           }
           return dayList;
    }
    async init({ state, dispatch, id, method, form }: Init) {
        //
        await this.initBasic({ dispatch, search: state.search });
        //
        if ("Create" !== method && id) {
            await this.initEdit({ dispatch, state, id, form })
        }
    }
    private async initBasic({ search, dispatch }: InitBasic) {
        //设置等级列表
        dispatch({ type: "LoadTag", payload: await this.GradeLogic.gradeList() });
        this.setFormData({ dispatch, key: "useweekTypeValue", value: ['1','2','3','4','5','6','7'] })
        this.clearTableList({dispatch})
        this.clearTableclassItem({dispatch})
        dispatch({ type: 'ClearPeriodTime'})
        this.AddPeriodTime({dispatch,length: 2})

    //     const list = await (await new ShopLogic().selListpost()).data.data.dataSource || []
    //     dispatch({type: 'LoadShop',payload: list})
    //    let defaultShop = list.filter(item=> item.isdefault == true)
    //    if(defaultShop.length>0){
    //        let shopinfo = defaultShop[0]
    //     this.setFormData({ dispatch, key: "shopList", value: [shopinfo.id] });
    //     this.setFormData({
    //       dispatch,
    //       key: "shopName",
    //       value: shopinfo.shopName,
    //     });
    //    }
    let { shopID, shopName } = JSON.parse(Cookies.get('loginMsg'))
    this.setFormData({ dispatch, key: "shopList", value: [shopID] });
    this.setFormData({
      dispatch,
      key: "shopName",
      value: shopName,
    });

    }
    private async initEdit({ dispatch, id, form }: InitEdit) {
        //回填表单
        let item;
        const r = await MarketingTimeSalesPromotion_logic.GetByID({ID: id})
        if(r&&r.data&&r.data.statusCode == 200){
            item = r.data.data
        }else{
            message.error('获取信息失败')
            return false
        }
        //固定星期| 日期
        if(item.useType == 1){
            item['useweekTypeValue'] = item.useTypeValue.split(',')
        }else if(item.useType == 2){
            let arr = item.useTypeValue.split(',')
           
            item['useTypeValue'] = arr
        }
        //时间段
        item['PeriodTimeList'] = item.timeSpan
        //会员等级
        item['isMemberClass'] = item.isMemberClass == true?2:1
        item['classList'] = item.memberClass.map(s=>s.memberClassID)
        //店铺
        let shoplist = await (await new ShopLogic().selListpost()).data.data.dataSource;
        let shopIDarr = []
        let shopName = []
        item.shopIDs.length&& item.shopIDs.map(shop=>{
            shoplist.map(s=>{if(s.id == shop.shopID){
                shopIDarr.push(s.id)
                shopName.push(s.shopName)
            }})
        })
        item['shopList'] = shopIDarr
        item['shopName'] = shopName.toString()


        item['activitySubsidiary'] = item.targetType
        //分类
        if (item.targets.length != 0 && item.targetType == 2) {
        const list = await new ClassesLogic().selListBySelect();

            let arr = []
            for (let i = 0; i < list.length; i++) {
                for (let k = 0; k < item.targets.length; k++) {
                    if (list[i].value == item.targets[k].target) {
                        arr.push(list[i])
                    }
                }
                for (let j = 0; j < list[i].children.length; j++) {
                    for (let k = 0; k < item.targets.length; k++) {
                        if (list[i].children[j].value == item.targets[k].target) {
                            arr.push(list[i].children[j])
                        }

                    }
                }
            }
            let array = []
            for (let j = 0; j < arr.length; j++) {
                let obj = {}
                obj['title'] = arr[j].label
                obj['key'] = arr[j].value
                array.push(obj)
            }
            item['classifyList'] = array
        }

        //商品
     if(item.targetType == 3){
        const shopList = []
        const dataList = (await new GoodsLogic().selListForSpecification({pageIndex:1,pageSize:999,shopID:shopIDarr[0],source:'PC',goodsClassesID:''})).data.data.dataSource;
         
        dataList.length&&dataList.map((goods_v, i) => {
         if(goods_v.goods_SpecificationList&&goods_v.goods_SpecificationList.length>0){
          goods_v.goods_SpecificationList.map((good,index)=>{
             delete good.goodsName
          
             shopList.push({
                  key: i + 1,
                  ...goods_v,
                  ...good,
                 goodsName:goods_v.goodsName,
                  goodsSepID:good.id,
                  goodsNo: goods_v.goodsNo
              })
          })
         }
    
    })

  if (item.targets.length != 0 && shopList ) {
    let arr = []
    for (let i = 0; i < shopList.length; i++) {
        for (let k = 0; k < item.targets.length; k++) {
            if (shopList[i].goodsSepID == item.targets[k].target) {
                arr.push(shopList[i])
            }
        }
    }
    item['tableList'] = arr            

}
     }
        dispatch({ type: "SetForm", payload: item });

        
     form.setFieldsValue({
       'activityName': item.activityName,
        'shopName' : shopName,
        'shopList' : item.shopList,
        'isMemberClass' :item.isMemberClass==true?2:1,
        'PeriodTimeList' : item.PeriodTimeList,
        'classList': item.classList,
        'classifyList' : item.classifyList,
        'purposeType':  item.purposeType,
        'activitySubsidiary': item.activitySubsidiary,
       'useType':item.useType,
       'beginDate' :item.beginDate,
       'endDate' :item.endDate,
       'useweekTypeValue': item.useweekTypeValue,
        'useTypeValue' : item.useTypeValue,
     })
    }
    async addSubmit({ state, status }) {
       const { formData } = state;

       if(formData.isMemberClass == 2){
           if(formData.classList == undefined ||formData.classList.length == 0 ){
            message.error('请选择会员等级')
            return false;
           }
       }
        if(formData.useType == 1){
           if( formData.useweekTypeValue == undefined || formData.useweekTypeValue == '' ||  formData.useweekTypeValue.length == 0 ||  formData.useweekTypeValue == null){
               message.error('请选择日期集合')
               return false;
           }
           formData.useweekTypeValue = formData.useweekTypeValue.toString()
        }
        if(formData.useType == 2){
            if( formData.useTypeValue == undefined ||  formData.useTypeValue.length == 0 ||  formData.useTypeValue == null){
              let dayList =  this.setDay(state)
              formData.useTypeValue = dayList
                // message.error('请选择日期集合')
                // return false;
            }

            formData.useTypeValue =  formData.useTypeValue.toString()
         }
         let num = 0
         for(let i = 0 ; i < formData.PeriodTimeList.length; i++){
             let item = formData.PeriodTimeList[i]
             item['stage'] = i+1
             if(item.beginTime == '' || item.beginTime == undefined || item.endTime == '' || item.endTime == undefined || item.markProportion == '' || item.markProportion == undefined){
                num++;
              }else if(item.beginTime == item.endTime){
                 message.error(`第${i+1}时间段开始时间和结束时间不能相同`)
                 return false;
                }else if(Date.parse('1970-01-01 ' + item.beginTime)-Date.parse('1970-01-01 ' + item.endTime)>0){
                  message.error(`第${i+1}时间段开始时间不能比结束时间晚`)
                  return false;
               }
               else if((formData.PeriodTimeList.filter(t=>{if(Date.parse('1970-01-01 '+item.beginTime)>Date.parse('1970-01-01 '+t.beginTime)&&Date.parse('1970-01-01 '+item.beginTime)<Date.parse('1970-01-01 '+t.endTime)){return t}})).length>0){
                  message.error(`第${i+1}活动时段与其他时间段有重合，请重新设置`)
                 return false;
              }
             
            
         }
         if(num > 0){
              message.error('活动时段未设置完整')
              num = 0;
              return false;
         }
       
         let targets = [];
         if(formData.activitySubsidiary == 2){
            if(state.classifyList && state.classifyList.length == 0){
                message.error('请选择指定分类')
                return false; 
            }else{
                for(let i = 0; i< state.classifyList.length;i++){
                  targets.push({
                    "target":state.classifyList[i].key,
                    targetType: 2
                  })
                }
            }
        }
        if(formData.activitySubsidiary == 3){
            if(state.tableList && state.tableList.length == 0){
                message.error('请选择指定商品')
                return false; 
            }else{
                for(let i = 0; i< state.tableList.length;i++){
                    targets.push({
                      "target":state.tableList[i].goodsSepID,
                      targetType: 3
                    })
                  }
            }
     }

      
        // return true;
        //console.log(state)
       const params = {
            "activityName": formData.activityName,
            "isMemberClass": formData.isMemberClass == 1?false:true,
            "beginDate": formData.beginDate,
            "endDate": formData.endDate,
            "useType": formData.useType,
            "useTypeValue": formData.useType==2?formData.useTypeValue:formData.useweekTypeValue,
            "purposeType": formData.purposeType,
            "targetType": formData.activitySubsidiary,
            "memberClass": formData.isMemberClass == 2?formData.classList:[],
            "targets":targets,
            "timeSpan": formData.PeriodTimeList,
            "shopID": formData.shopList
          }

      const r =   await  MarketingTimeSalesPromotion_logic.AddTimeSalesPromotion(params);
      if(r&&r.data&&r.data.statusCode == 200){
          message.success('添加成功')
          return true
      }else{
          message.error( r.data.returnMessage || r.data.message)
          return false;
      }

    }
    async updSubmit({ state, status }, id) {
        const { formData} = state
        if(formData.isMemberClass == 2){
            if(formData.classList == undefined ||formData.classList.length == 0 ){
             message.error('请选择会员等级')
             return false;
            }
        }
         if(formData.useType == 1){
            if( formData.useweekTypeValue == undefined || formData.useweekTypeValue == '' ||  formData.useweekTypeValue.length == 0 ||  formData.useweekTypeValue == null){
                message.error('请选择日期集合')
                return false;
            }
            formData.useweekTypeValue = formData.useweekTypeValue.toString()
         }
         if(formData.useType == 2){
             if( formData.useTypeValue == undefined ||  formData.useTypeValue.length == 0 ||  formData.useTypeValue == null){
               let dayList =  this.setDay(state)
               formData.useTypeValue = dayList
              }
 
             formData.useTypeValue =  formData.useTypeValue.toString()
          }
        let num = 0
        for(let i = 0 ; i < formData.PeriodTimeList.length; i++){
            let item = formData.PeriodTimeList[i]
            item['stage'] = i+1
            if(item.beginTime == '' || item.beginTime == undefined || item.endTime == '' || item.endTime == undefined || item.markProportion == '' || item.markProportion == undefined){
              num++;
            }else if(item.beginTime == item.endTime){
               message.error(`第${i+1}时间段开始时间和结束时间不能相同`)
               return false;
              }else if(Date.parse('1970-01-01 ' + item.beginTime)-Date.parse('1970-01-01 ' + item.endTime)>0){
                message.error(`第${i+1}时间段开始时间不能比结束时间晚`)
                return false;
             }
             else if((formData.PeriodTimeList.filter(t=>{if(Date.parse('1970-01-01 '+item.beginTime)>Date.parse('1970-01-01 '+t.beginTime)&&Date.parse('1970-01-01 '+item.beginTime)<Date.parse('1970-01-01 '+t.endTime)){return t}})).length>0){
                message.error(`第${i+1}活动时段与其他时间段有重合，请重新设置`)
               return false;
            }
           
        }
        if(num > 0){
             message.error('活动时段未设置完整')
             num = 0;
             return false;
        }

        let targets = [];
        if(formData.activitySubsidiary == 2){
           if(state.classifyList && state.classifyList.length == 0){
               message.error('请选择指定分类')
               return false; 
           }else{
               for(let i = 0; i< state.classifyList.length;i++){
                 targets.push({
                   "target":state.classifyList[i].key,
                   targetType: 2
                 })
               }
           }
       }
       if(formData.activitySubsidiary == 3){
           if(state.tableList && state.tableList.length == 0){
               message.error('请选择指定商品')
               return false; 
           }else{
               for(let i = 0; i< state.tableList.length;i++){
                   targets.push({
                     "target":state.tableList[i].goodsSepID,
                     targetType: 3
                   })
                 }
           }
    }
        const params = {
            'id':id,
            "activityName": formData.activityName,
            "isMemberClass": formData.isMemberClass == 1?false:true,
            "beginDate": formData.beginDate,
            "endDate": formData.endDate,
            "useType": formData.useType,
            "useTypeValue": formData.useType==2?formData.useTypeValue.toString():formData.useweekTypeValue.toString(),
            "purposeType": formData.purposeType,
            "targetType": formData.activitySubsidiary,
            "memberClass": formData.isMemberClass==2?formData.classList:[],
            "targets":targets,
            "timeSpan": formData.PeriodTimeList,
            "shopID": formData.shopList
          }
     const r =   await  MarketingTimeSalesPromotion_logic.UpdateTimeSalesPromotion(params);
     if(r&&r.data&&r.data.statusCode == 200){
         message.success('修改成功')
         return true
     }else{
         message.error( r.data.returnMessage || r.data.message)
         return false;
     }
    }

   
    AddclassTableItem({ dispatch, list }) {
        dispatch({ type: "AddclassTableItem", payload: list });
    }
    delTableclassItem({dispatch, index}) {
        dispatch({ type: "DelTableclassItem", payload: index });
    }
    clearTableclassItem({dispatch}) {
        dispatch({ type: "ClearTableclassItem" });
    }
s
    delTableItem({dispatch, index}) {
        dispatch({ type: "DelTableItem", payload: index });
    }
    addTableItem({ dispatch, list }) {
        list.forEach(async v => {
            dispatch({ type: "AddTableItem", payload: v });
        })
    }
    clearTableList({ dispatch }) {
        dispatch({ type: "ClearTableList" });
    }

    AddPeriodTime({ dispatch, length }) {
        dispatch({
            type: "AddPeriodTime", payload: {
                length: length,
            }
        });
    }
    DelPeriodTime({ dispatch, id }) {
        dispatch({
            type: "DelPeriodTime", payload: {
                index: id,
            }
        });
    }
    /** 更新时间段数据 */
    UpdatePeriodTime({ dispatch ,index}) {
        dispatch({ type: "UpdatePeriodTime", index});
    }

    setFormData({ dispatch, key, value }) {
        const params = {
        }
        params[key] = value;

        dispatch({ type: "SetFormData", payload: params })
    }

    reset({ dispatch }) {
        //
    }
}
export const formAction = new FormAction();