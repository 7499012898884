import { ElementTransform, V2 } from "../com/trasform";
import { ITopicValue } from "../store/topic";
import { ISelectUrl } from "../view/modal/modal";
import { TopicDataAction } from "./TopicDataAction";

/**
 * 选区数据
 * 百分比位置和尺寸
 */
export class PrecinctData extends ElementTransform {
  /** 链接 */
  url?: ISelectUrl;
  /** 是否新窗口打开 */
  ifNewWinOpen?: boolean;

  constructor(
    pos: V2 = new V2(),
    size: V2 = new V2(),
    url?: ISelectUrl,
    ifNWO?: boolean
  ) {
    super(pos, size);
    this.url = url;
    this.ifNewWinOpen = ifNWO;
  }
}

/**
 * 选项操控数据
 * px位置和尺寸
 */
export class PrecinctCon extends PrecinctData {
  /** 是否在创建 */
  ifCreate?: boolean;

  constructor(
    pos: V2 = new V2(),
    size: V2 = new V2(),
    url?: ISelectUrl,
    ifNWO?: boolean,
    ifC?: boolean
  ) {
    super(pos, size, url, ifNWO);
    this.ifCreate = ifC;
  }
}

export interface IHotsReducerPar {
  type: "reset";
  payload: PrecinctData[];
}

export interface IHotsData {
  imgUrl: string;
  imgName: string;
  precinctList: PrecinctData[];
}

/**
 * hots操作
 */
export class HotsAction extends TopicDataAction {
  /** 初始化数据 */
  static initData(store: ITopicValue, id: number) {
    this.setData(store, id, {
      imgUrl: '',
      imgName: '',
      precinctList: [],
    });
  }

  /** 设置文件 */
  static setFile(store: ITopicValue, id: number, imgUrl: string, imgName: string) {
    let data = this.getData<IHotsData>(store, id);
    data.imgUrl = imgUrl;
    data.imgName = imgName;
    this.setData(store, id, data);
  }

  /** 操作选区数据 */
  static conPrecinctData(
    store: ITopicValue, id: number,
    { type, payload }: IHotsReducerPar
  ) {
    let data = this.getData<IHotsData>(store, id);
    switch (type) {
      case "reset":
        data.precinctList = payload as PrecinctData[];
        break;
    }
    //
    this.setData(store, id, data);
  }
}