import React, { useContext, useEffect } from "react";
import { Button } from "../../../../component/button";
import { Layer } from "../../../../component/layer/layer";
import { Form as FormAntd } from "antd";
import { formAction, ListFormAction } from "../../action/list/list_form_action";
import { ListFormBasic } from "./list_form_basic";
import { PswFormBasic } from "./list_form_password";
import { ListFormStore } from "../../store/list/list_form_store";

import { ListStore } from "../../store/list/list_store";
import { fileAction } from "../../action/list/list_action";
import { DataTableStore } from "../../../../component/table/store/data_table_store";
import { defualtPageSize } from "../../../../logic/common_logic";
interface IForm {
  children?: any;
  action: ListFormAction;
  close: (hasUpdate?: boolean) => void;
  editId?: string;
  seeId?: string;
  passwordId?: string;
  isdefault: boolean;
}
export function Form({
  close,
  action,
  editId,
  seeId,
  passwordId,
  isdefault,
}: IForm) {
  const [form] = FormAntd.useForm();

  const { state, dispatch: formDispatch } = useContext(ListFormStore);

  const { state: fileState, dispatch: fileDispatch } = useContext(ListStore);
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);

  useEffect(() => {
    formAction.init({
      state: state,
      dispatch: formDispatch,
      editId: editId,
      passwordId,
      seeId,
      form,
    });
    return () => {};
  }, []);

  function title() {
    if (editId) {
      return "编辑用户";
    } else if (seeId) {
      return "查看用户";
    } else if (passwordId) {
      return "重置密码";
    } else {
      return "新建用户";
    }
  }
  const onSearch = async (pageIndex) => {
    await fileAction.loadPage({
      pageIndex: pageIndex,
      pageSize: defualtPageSize(),
      dispatch: tableDispatch,
      search: fileState.search,
    });
  };
  return (
    <FormAntd form={form}>
      <Layer layerWith={2}>
        <Layer.Title onClick={() => close()}>{title()}</Layer.Title>
        <Layer.Body>
          {title() != "重置密码" && (
            <>
              <ListFormBasic
                editId={editId}
                passwordId={passwordId}
                seeId={seeId}
                title={title()}
                isdefault={isdefault}
              />
            </>
          )}
          <>
            {title() == "重置密码" && <PswFormBasic passwordId={passwordId} />}
          </>
        </Layer.Body>
        {title() != "查看用户" && (
          <>
            <Layer.Foot>
              <Button
                type="primary"
                color="Red"
                style={{ marginRight: "1.25rem" }}
                onClick={async () => {
                  const r = await form.validateFields();
                  let status = false;
                  if (editId) {
                    status = await action.updSubmit({ state: state.formData });
                  }
                  if (!editId) {
                    if (passwordId) {
                      status = await action.resetSubmit({
                        state: state.formData,
                      });
                    } else {
                      status = await action.addSubmit({
                        state: state.formData,
                      });
                    }
                  }
                  status && close && close(true);
                }}
              >
                保存
              </Button>
              {title() != "重置密码" && (
                <Button
                  type="primary"
                  color="Yellow"
                  onClick={() => action.reset({ dispatch: formDispatch, form })}
                >
                  重置
                </Button>
              )}
            </Layer.Foot>
          </>
        )}

        {title() == "查看用户" && (
          <>
            <Layer.Foot></Layer.Foot>
          </>
        )}
      </Layer>
    </FormAntd>
  );
}
