import { useRef } from "react";
import { fillHeight } from "../../logic/common_logic";
import styles from "./index.module.scss";

export function Scroll({ dif, children }) {
    
    const tagRef = useRef(null);
    const height = fillHeight(tagRef.current, dif);
    return (
        <div className={styles.root} ref={tagRef} style={{ height }}>
            {children}
        </div>
    )
}