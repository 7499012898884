import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
} from "antd";
import styles from "../../style/file_search.module.scss";
import { fileAction } from "../../action/commission_logs_action";
import { FileStore } from "../../store/commission_logs_store";
import moment from "moment";

const { Option } = Select;
const { RangePicker } = DatePicker;


export function FileSearch({ onSearch }) {
  const { state, dispatch } = useContext(FileStore);
  const action = fileAction;
  return (
    <div>
      <Form layout="horizontal">
        <div className={styles.row}>
          <div className={styles.cell}>
            <Form.Item label="制单时间">
              <RangePicker
                allowClear
                style={{ width: 432 }}
                value={[moment(state.search.beginTime), moment(state.search.endTime)]}
                onChange={(e) => {
                  if (e == null) {
                    action.setCondition({dispatch, key: 'beginTime', value: moment(Date.now()).startOf("month").format("Y-M-D")})
                    action.setCondition({dispatch, key: 'endTime', value: moment(Date.now()).format("Y-M-D")})
                  } else {
                    action.setCondition({dispatch, key:'beginTime', value:  e[0].format("yyyy-MM-DD")});
                    action.setCondition({dispatch, key:'endTime', value:  e[1].format("yyyy-MM-DD")});
                  }
                }}
              />
            </Form.Item>
          </div>
        </div>
        <div className={styles.row}>
          <Button type="primary" danger onClick={() => onSearch(1)}>
            搜 索
          </Button>
        </div>
      </Form>
     
    </div>
  );
}
