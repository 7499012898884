import { useBoolean } from "ahooks";
import { Button, Input, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect, useState } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { DataTable } from "../../../../component/table";
import { fileAction } from "../../action/integral/file_action";
import { DataTableStore } from "../../../../component/table/store/data_table_store";

import { Search } from "../../../../component/search";
import { Tips, TipsGroup } from "../../../../component/tips";
import { FileSearch } from "./file_search";
import { FileStore } from "../../store/integral/file_store";
import { Mask } from "../../../../component/mask";
import { AuthLogic } from "../../../../logic/auth_logic";
import { defualtPageSize, Progress } from "../../../../logic/common_logic";
export function Integral(viewPower) {
    const [] = useBoolean(false);
    const action = fileAction;
    const [searchState, searchFunc] = useBoolean(false);
    const { state, dispatch } = useContext(FileStore);
    const { state: tableState, dispatch: tableDispatch } = useContext(DataTableStore);
    const [menuButton, setData] = useState([])
    useEffect(() => {
        (async function () {
            let r = await new AuthLogic().selButton(viewPower.viewPower);
            if (r&&r.length > 0) {
                for (let i = 0; i < r.length; i++) {

                    r[i] = r[i].split('_')[r[i].split('_').length - 1]
                }
            }
            setData(r);
        }())

        return () => {

        }
    }, []);
    const columns: ColumnsType = [
        {
            title: '积分单号',
            dataIndex: 'oddNumbers',
            key: '1',
            align: "center",
            width: 140
        },
        {
            title: '会员姓名',
            dataIndex: 'memberName',
            key: '2',
            align: "center"
        },
        {
            title: '手机号码',
            dataIndex: 'phone',
            key: '3',
            align: "center"
        },
        {
            title: '收支类型',
            dataIndex: 'integralTypeString',
            key: '4',
            align: "center",
            render: v => v && <Tag color={v.indexOf('增加')!=-1?'success':v.indexOf('减少')!=-1?'error':'default'}>{v}</Tag>
        },
        {
            title: '操作类型',
            dataIndex: 'operationTypeString',
            key: '5',
            align: "center"
        },
        {
            title: '变动积分',
            dataIndex: 'variation',
            key: '6',
            align: "center"
        },
        {
            title: '变动后积分',
            dataIndex: 'variationAfter',
            key: '7',
            align: "center"
        },
        {
            title: '变动门店',
            dataIndex: 'shopName',
            key: '8',
            align: "center"
        },
        {
            title: '创建时间',
            dataIndex: 'addDateTime',
            key: '9',
            align: "center",
            width: 140
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: '10',
            align: "center"
        }
    ];
    const onSearch = async (pageIndex) => {
        await action.loadPage({ pageIndex: pageIndex, pageSize: defualtPageSize(), dispatch: tableDispatch, search: state.search })
    }
    return (
        <>
            <Search>
                <Input allowClear placeholder="请输入会员卡号/姓名/手机号码" onChange={e => action.setCondition("Keywords", e.target.value, dispatch)} />
                <input type="button" value="快速查找" onClick={() => onSearch(1)} />

                <TipsGroup>
                    <input type="button" value="高级查找" onClick={() => searchFunc.toggle()} />
                    <Tips width="45rem" display={searchState}>
                        <FileSearch onSearch={(...arg: [any]) => {
                            onSearch(...arg);
                            searchFunc.setFalse();
                        }} />
                    </Tips>
                    <Mask visible={searchState} onClick={() => searchFunc.setFalse()} />
                </TipsGroup>
            </Search>
            <ButtonPanel>
                {
                    menuButton.indexOf("EXPORT") != -1 && <Button onClick={async () => {
                        await action.exportList({ search: state.search })
                    }}>导出</Button>}
            </ButtonPanel>
            <DataTable columns={columns} action={fileAction}
                onLoad={async () => { await onSearch(1); Progress.done() }}
                onChange={(pageIndex) => {onSearch(pageIndex) }}
                scroll={{ scrollToFirstRowOnChange: true, autoY: true }}
                selectColumn={false}
                loading= { tableState.loading }
            />
        </>
    )
}