import { createContext, useReducer } from "react";

export class FormData {
  id: string;
 codeName: string; //税收编码名称
 taxCode: string;
 rate: number //税率
 remark?:string
}

export type Dispatch = { type; payload };

interface IState {
  formData: FormData;
  loading: boolean;
}

const initialArgs = {
  loading: false,
  formData: new FormData(),
};

export type FormAttribute = "codeName" | 'taxCode' | 'rate' | 'remark'
  

interface IContextProps {
  state: IState;
  dispatch: ({ type }: Dispatch) => void;
}

export const FormStore = createContext<IContextProps>(null);

function reducer(state: IState, { type, payload }: Dispatch) {
  switch (type) {
    case "isLoading":
      state.loading = payload;
      return {
        ...state,
      };
    // 填充表单
    case "SetForm": {
      const {
        id,
       codeName,
       taxCode,
       rate,
       remark
      } = payload;
      const form = new FormData();
      form.id = id;
      form.codeName = codeName
      form.taxCode = taxCode
      form.rate = rate
      form.remark = remark

      return {
        ...state,
        formData: { ...form },
      };
    }
    // 设置表单
    case "Change_FormData": {
      const { attribute, value } = payload,
        props: any = {};
      const key: FormAttribute = attribute;
      let attri = key;
      props[attri] = value;
      return {
        ...state,
        formData: {
          ...state.formData,
          ...props,
        },
      };
    }

    default:
      return state;
  }
}

export function FormStoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialArgs);

  const v = { state, dispatch };
  return <FormStore.Provider value={v}>{props.children}</FormStore.Provider>;
}
