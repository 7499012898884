import moment from "moment";
import { useState, useEffect } from "react";
import { ReportLayer } from "../com/reportLayer";
import { Buttons } from "./com/buttons";
import { DateSelect } from "./select/dateSelect";
import { StoresSalesStatsTable } from "./tableShow/storesSalesStatsTable";
import { SearchLayer } from "./search/searchLayer";
import { SelectStore } from "./store/SelectStore";
import { ISelectTime, ISelectGoods, ISpeedQuery } from "../com/ISelectType";
import { SelectGoods } from "./select/selectGoods";
import { ApiCon } from "../com/apiCon";
import { Form, Select, message } from "antd";
import { QueryType } from "../com/QueryType";
import { useBoolean, useDebounceFn } from "ahooks";
import { AuthLogic } from "../../../logic/auth_logic";
import { ButtonPanel } from "../../../component/button-panel/button_panel";
import { Button } from "../../../component/button";
import { SelectShop } from "./select/selectShop";
import { StoreSalesStatsForm } from './form/storeSalesStatsForm'
const { Option } = Select;
let formParams = {
  goodsNo: ''
}
/**
 * 门店销售统计
 */
export function StoresSalesStats(viewPower) {
  const [createState, createFunc] = useBoolean(false)
  const [data, setData] = useState(null);
  const [ifInit, setIfInit] = useState(false);
  const [ifLastPage, setIfLastPage] = useState(false);
  const [queryType, setQueryType] = useState<QueryType>("n");
  const [queryState, setQueryState] = useState<
    {
      saleType?: number;
    } & ISelectTime &
      ISelectGoods &
      ISpeedQuery
  >({
    seacherKey: "",
    beginTime: moment(Date.now()).startOf("month").format("Y-M-D"),
    endTime: moment(Date.now()).format("Y-M-D"),
    goodsClassID: [],
    goodsID: [],
  });
  const [paging, setPaging] = useState<{
    pageSize: number;
    current: number;
    total: number;
  }>({
    pageSize: 20,
    current: 1,
    total: 0,
  });

  useEffect(() => {
    if (!ifInit) {
      setIfInit(true);
      queryClick();
    }
  });

  const queryClick = (_queryType: QueryType = queryType) => {
    setQueryType(_queryType);
    let op: any = {
      ...queryState,
      ...{
        pageIndex: paging.current,
        pageSize: paging.pageSize,
      },
    };
    //根据不同的查询模式修剪参数
    switch (_queryType) {
      case "c":
        op.seacherKey = "";
        break;
      case "s":
        break;
      case "n":
        op.seacherKey = "";
        break;
    }
    ApiCon.ShopSaleReport(op)
      .then((data) => {
        //console.log('门店销售统计数据', data);
        setPaging({
          ...paging,
          total: data.totalCount,
        });
        setIfLastPage(!data.hasNextPage);
        if (data.dataSource) {
          setData(data.dataSource);
        } else {
          setData([]);
        }
      })
      .catch((data) => {
        setData([]);
        //提示
        message.error(data.message);
      });
  };

  const setQuery = (query) => {
    setQueryState({
      ...queryState,
      ...query,
    });
  };
  const [menuButton, setMenu] = useState([]);
  useEffect(() => {
    //   dispatch({ type: "isloading", payload: false });
    (async function () {
      let r = await new AuthLogic().selButton(viewPower.viewPower);
      if (r && r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          r[i] = r[i].split("_")[r[i].split("_").length - 1];
        }
      }
      setMenu(r);
    })();
    return () => {
      // dispatch({ type: "isloading", payload: false });
    };
  }, []);
  const { run } = useDebounceFn(
    (fn) => {
      fn && fn();
    },
    {
      wait: 1500,
    }
  );
  const handleClickTd = (data) => {
    formParams.goodsNo = data.goodsNo;
    createFunc.setTrue();
  }
  return (
    <SelectStore.Provider
      value={{
        state: queryState,
        dispatch: setQuery,
      }}
    >
      <ReportLayer
        queryItem={
          <SearchLayer
            queryClick={queryClick}
            speedQureyPlaceholder="请输入商品分类，商品名称，商品编码"
          >
            <SelectGoods />
            <div className="item_">
              <Form.Item label="销售类型">
                <Select
                  style={{ width: 175 }}
                  allowClear
                  value={queryState.saleType}
                  onChange={(value) => {
                    setQuery({ saleType: value });
                  }}
                >
                  <Option value={1}>门店</Option>
                  <Option value={2}>商城</Option>
                </Select>
              </Form.Item>
            </div>
            <SelectShop treeCheckable={true}/>
            <DateSelect />
          </SearchLayer>
        }
        button={
          (
           <Buttons
             exportExcel={() => {
               run(async () => {
                 let today =  moment(Date.now()).format("yyyy-MM-DD")
                 const r = await ApiCon.exportExcel('export_table',`门店销售统计${today}.xlsx`);
               });
             }}
           />
         )
       }
        paging={{
          ...paging,
          onChange: (page, pageSize) => {
            paging.current = page;
            paging.pageSize = pageSize;
            setPaging({
              ...paging,
            });
            queryClick();
          },
        }}
      >
        <StoresSalesStatsTable data={data} ifLastPage={ifLastPage} handleClickTd={(val)=>handleClickTd(val)}/>
      </ReportLayer>
      {
        createState && <StoreSalesStatsForm close={()=>{
          createFunc.setFalse()
        }}
        goodsNo={formParams.goodsNo}
        searchState={queryState}
        ></StoreSalesStatsForm>
      }
    </SelectStore.Provider>
  );
}
