import {
  Upload,
  Col,
  Form,
  Form as FormAntd,
  Input,
  Radio,
  Row,
  Select,
  DatePicker,
  message,
  Button as AntdButton,
} from "antd";
import { PaymentCon } from "./paymentCon";
import TextArea from "antd/lib/input/TextArea";
import { useState, useEffect } from "react";
import { ApiCon } from "./apiCon";

const { Option } = Select;

/**
 * 支付宝表单
 */
export function ZFBForm({ disabled }) {
  const [form] = Form.useForm();
  const [linkType, setLinkType] = useState(0);

  //填充数据
  useEffect(() => {
    ApiCon.getZFB().then((d) => {
      if (!d) {
        return;
      }
      d["linkType"] = 1;
      setLinkType(d["linkType"]);
      //填充form表单
      form.setFieldsValue({
        ...d,
      });
    });
  }, []);

  return (
    <>
      <Form form={form}>
        <div style={{ margin: "1.9375rem 0 0 0" }}>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                name="linkType"
                label="模式"
                labelCol={{ span: 5 }}
                rules={[
                  {
                    required: true,
                    message: "请选择模式",
                  },
                ]}
              >
                <Select
                  // disabled
                  onChange={(value) => {
                    setLinkType(value as any);
                  }}
                >
                  <Option value={1}>直连模式</Option>
                  <Option value={2}>商户模式</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                name="appID"
                label="APPID"
                labelCol={{ span: 5 }}
                rules={[
                  {
                    required: true,
                    message: "请输入APPID",
                  },
                ]}
              >
                <Input placeholder="请输入APPID" disabled={disabled} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                name="publicKey"
                label="支付公钥"
                labelCol={{ span: 5 }}
                rules={[
                  {
                    required: true,
                    message: "请输入支付公钥",
                  },
                ]}
              >
                <TextArea placeholder="请输入支付公钥" disabled={disabled} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                name="privateKey"
                label="支付私钥"
                labelCol={{ span: 5 }}
                rules={[
                  {
                    required: true,
                    message: "请输入支付私钥",
                  },
                ]}
              >
                <TextArea placeholder="请输入支付私钥" disabled={disabled} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12} style={{ display: linkType == 1 ? "none" : "" }}>
            <Col span={12}>
              <Form.Item
                name="sellerID"
                label="卖家支付宝ID"
                labelCol={{ span: 5 }}
                rules={[
                  {
                    required: linkType == 2,
                    message: "请输入卖家支付宝ID",
                  },
                ]}
              >
                <Input placeholder="请输入卖家支付宝ID" disabled={disabled} />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
      <PaymentCon
        save={() => {
          if (disabled) {
            return false;
          }
          form.validateFields().then(() => {
            ApiCon.updateZFB({
              ...form.getFieldsValue(),
            })
              .then(() => {
                message.success("保存成功");
              })
              .catch((e) => {
                message.error(e);
              });
          });
        }}
        reset={() => {
          form.resetFields();
        }}
      />
    </>
  );
}
