// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../config";
import { effeative } from "./common_logic";

export class RoleLogic {
    async addData(params) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("role_add"),
            data:params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r.data;
    }
    async querysList(param) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("user_roles_list"),
            data: param,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    async selData(id) {
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("role_sel"),
            params: {
                id
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r.data.data;
    }
    async updData(params) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("role_upd"),
            data:params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r.data;
    }

}