import React, { useContext, useEffect } from "react";
import { Button } from "../../../../component/button";
import { Layer } from "../../../../component/layer/layer";
import { Form as FormAntd, message } from "antd";
import { formAction, FormAction } from "../../action/reduceGrade/form_action";
import { FormStore } from "../../store/grade/form_store";
import { FormBasic } from "./form_basic";

import { FileStore } from "../../store/grade/file_store";
import { fileAction } from "../../action/grade/file_action";
import { DataTableStore } from "../../../../component/table/store/data_table_store";
import { defualtPageSize } from "../../../../logic/common_logic";
import { useBoolean } from "ahooks";

interface IForm {
    children?: any;
    action: FormAction;
    close: (status: boolean) => void;
    id?: string;
    method: string
}
export function Form({ close, action, id ,method}: IForm) {
    const [form] = FormAntd.useForm();

    const { state, dispatch: formDispatch } = useContext(FormStore);

    const [loading, loadFunc] = useBoolean(false)
    const { state: fileState, dispatch: fileDispatch } = useContext(FileStore);
    const { state: tableState, dispatch: tableDispatch } = useContext(DataTableStore);

    useEffect(() => {
        formAction.init({ state: state, dispatch: formDispatch, id: id, form });

        return () => {

        }
    }, []);

    function title() {
        if (method == 'Update') {
            return "编辑降级规则";
        }
        else if (method == 'Preview') {
            return "查看降级规则";
        } else {
            return "新建降级规则";
        }
    }
    const onSearch = async (pageIndex) => {
        await fileAction.loadPage({ pageIndex: pageIndex, pageSize: defualtPageSize(), dispatch: tableDispatch, search: fileState.search })
    }
    return (
        <FormAntd form={form}>
            <Layer layerWith={1} >
                <Layer.Title onClick={() => close(false)}>{title()}</Layer.Title>
                <Layer.Body>
                    <FormBasic title={title()} />
                </Layer.Body>
                {
                    title() != "查看降级规则" && (
                        <>
                            <Layer.Foot>
                                <Button type="primary" color="Red" style={{ marginRight: "1.25rem" }} onClick={async () => {
                                    const r = await form.validateFields();
                                    if(loading){
                                        message.loading('正在提交数据')
                                        return false
                                    }
                                    let status = false;
                                    if (id) {
                                        loadFunc.setTrue()
                                        status = await action.updateSubmit({ state: state.formData });
                                        loadFunc.setFalse()
                                    } else {
                                        loadFunc.setTrue()
                                        status = await action.addSubmit({ state: state.formData });
                                        loadFunc.setFalse()
                                    }
                                    status && (close && close(true))
                                }}>
                                    保存
                                </Button>
                                <Button type="primary" color="Yellow" onClick={() => action.reset({ dispatch: formDispatch, form })}>
                                    重置
                                </Button>
                            </Layer.Foot>
                        </>
                    )
                }
                {
                    title() == "查看降级规则" && (
                        <>
                            <Layer.Foot borderStyle={title()=='查看降级规则'?{border:'none'}:{}}>
                                
                            </Layer.Foot>
                        </>
                    )
                }

            </Layer>
        </FormAntd>
    )
}