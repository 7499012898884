import React, { useContext, useState } from "react";
import {
  Button,
  Cascader,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  TreeSelect,
} from "antd";
import styles from "../../style/file_search.module.scss";
import { ChooseShop } from "../../../../component/choose-shop";
import { useBoolean } from "ahooks";
import Search from "antd/lib/input/Search";
import { AlarmStore } from "../../store/alarm_store";
import { alarmAction } from "../../action/alarm_action";

const { Option } = Select;
const { RangePicker } = DatePicker;

export function AlarmSearch({ onSearch }) {
  const [shopState, shopFunc] = useBoolean(false);
  const [visibleModal, visibleModalFunc] = useBoolean(false);
  const { state, dispatch } = useContext(AlarmStore);
  const action = alarmAction;
  const onangeChange = () => {
    action.setCondition({ dispatch, key: "shopId", value: "" });
    action.setCondition({ dispatch, key: "shopName", value: "" });
  };
  //console.log(state.classesList)
  return (
    <>
      <div>
        <Form layout="horizontal">
          <div className={styles.row}>
            <div className={styles.cell}>
              <Form.Item label="选择分类">
                <TreeSelect
                  style={{ width: "15rem" }}
                  showCheckedStrategy={TreeSelect.SHOW_PARENT}
                  dropdownMatchSelectWidth={false}
                  defaultValue={state.search.classesId}
                  value={state.search.classesId}
                  treeCheckable={true}
                  treeData={state.classesList}
                  onChange={(v) => {
                    action.setCondition({
                      dispatch,
                      key: "classesId",
                      value: v.length > 0 ? [v[v.length - 1]] : [],
                    });
                  }}
                  placeholder="选择商品分类"
                />

                {/* <Cascader style={{ width: "15rem" }} options={state.classesList} placeholder="请选择"
                                    onChange={(v) => action.setCondition({ dispatch, key: "classesId", value: v.length > 0 ? [v[v.length - 1]] : [] })}
                                /> */}
              </Form.Item>
            </div>
            <div className={styles.cell}>
              <Form.Item label="选择门店">
                <Input
                  style={{ width: "15rem" }}
                  value={state.search.shopName}
                  placeholder="请选择"
                  onClick={() => shopFunc.setTrue()}
                />
                <span
                  className="ant-input-suffix"
                  style={{ marginLeft: "-24px", zIndex: 99 }}
                  onClick={onangeChange}
                >
                  <span
                    role="button"
                    aria-label="close-circle"
                    className="anticon anticon-close-circle ant-input-clear-icon"
                  >
                    <svg
                      viewBox="64 64 896 896"
                      focusable="false"
                      data-icon="close-circle"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 01-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"></path>
                    </svg>
                  </span>
                </span>
              </Form.Item>
            </div>

            <div className={styles.cell}>
              <Form.Item label="选择状态">
                <Select
                  style={{ width: "15rem" }}
                  onChange={(v) =>
                    action.setCondition({ dispatch, key: "statusId", value: v })
                  }
                  defaultValue={-1}
                  showSearch
                  filterOption={(input:any, option:any) => {
                    return option.children.indexOf(input) >= 0;
                  }}
                >
                  <Option value={-1}>全部</Option>
                  <Option value={1}>高于上限</Option>
                  <Option value={2}>低于下限</Option>
                </Select>
              </Form.Item>
            </div>
          </div>
          <div className={styles.row}>
            <Button type="primary" danger onClick={() => onSearch(1)}>
              搜 索
            </Button>
          </div>
        </Form>
      </div>
      <ChooseShop
        visibleModal={shopState}
        close={(status, r) => {
          if (r) {
            action.setCondition({ dispatch, key: "shopId", value: [r[0].id] });
            action.setCondition({
              dispatch,
              key: "shopName",
              value: r[0].shopName,
            });
          }
          shopFunc.setFalse();
        }}
      />
    </>
  );
}
