import { useContext } from "react";
import { comConType } from "..";
import { TopicStore } from "../store/topic";
import styles from "../style/divider.module.scss";
/**
 * 分割线
 * @param props
 */
export function Divider(props: { id: number, con: comConType }) {
  const { state, dispatch } = useContext(TopicStore);
  if (props.con == 'show') {
    return (
      <div className={styles.divider}>
        <section>
          <hr />
        </section>
      </div>
    );
  } else {
    return (
      <>
        <div className={styles.divider_ctrl}>
          <span>分割线</span>
        </div>
      </>
    );
  }
}
