import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
} from "antd";
import styles from "../../style/distribution/file_search.module.scss";
import { fileAction } from "../../action/distributionRecords/list_action";
import { ListStore } from "../../store/distributionRecords/list_store";
// import axios from "axios";
import { Tools, axios } from "../../../../config";
import Cookies from "js-cookie";
import { ChooseMember } from "../../../../component/choose-member";
import { useBoolean } from "ahooks";

const { Option } = Select;
const { RangePicker } = DatePicker;

let globalPeopleList = [];

export function FileSearch({ onSearch }) {
  const { state, dispatch } = useContext(ListStore);
  const [peopleList, setPeopleList] = useState([]);
  const action = fileAction;
  const [showState, showFunc] = useBoolean(false);

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div>
      <Form layout="horizontal">
        <div className={styles.row}>
          <div className={styles.cell}>
            <Form.Item label="分润时间">
              <RangePicker
                style={{ width: 432 }}
                onChange={(e) => {
                  action.setCondition(
                    "beginTime",
                    e ? e[0].format("yyyy-MM-DD") : "",
                    dispatch
                  );
                  action.setCondition(
                    "endTime",
                    e ? e[1].format("yyyy-MM-DD") : "",
                    dispatch
                  );
                }}
              />
            </Form.Item>
          </div>
        </div>
        <div className={styles.row}>
          <Button type="primary" danger onClick={() => onSearch(1)}>
            搜 索
          </Button>
        </div>
      </Form>
    </div>
  );
}
