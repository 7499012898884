import { useBoolean } from "ahooks";
import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Progress } from "../../logic/common_logic";
import styles from "./index.module.scss";
import { DueToAlert } from "./view/DueToAlert";
import { Head } from "./view/head";
import { Info } from "./view/info";
import { Menu } from "./view/menu";
import help_btn from "../../assets/images/help/help_btn.png";
import {CloseCircleOutlined, QuestionCircleOutlined} from '@ant-design/icons'
import Cookies from 'js-cookie'
import { autoRem } from "../../global.style";
interface ILayoutPage {
  children?: any;
  fullWidth?: boolean;
}
export function Layout({ children, fullWidth }: ILayoutPage) {
  let height = document.body.clientHeight - 58;
  const [full, fullFunc] = useBoolean(true);
  const [close,setClose]  = useState('open')
  const [show,setShow] = useState(false)
  const his = useHistory()
  useEffect(() => {
    if(Cookies.get("loginMsg")==undefined){
      // message.error('登录失效，请登录')
      his.replace('/login')
     
    }
    if (window.location.href.indexOf("/survey") != -1) {
      fullFunc.setFalse();
    } else {
      fullFunc.setTrue();
    }
    // console.log(full);
  });
  let containerHeight = document.body.clientHeight - 58 - 20;
  return (
    <div className={styles.root}>
     <div className={styles.helpBox} style={close=='open'?{width:'120px',height:'120px'}:{width:'30px',height:'30px'}}>
      {close == 'open' ?<div className={styles.helpContent} onMouseEnter={()=>{
        setShow(true)
      }} onMouseLeave={()=>{
        setShow(false)
      }}>
      <Link to="/help" target="_blank" >
        <img
          src={help_btn}
          alt=""
        />
      </Link>
     {show && <div className={styles.closeIcon} onClick={()=>{
        setClose('close')
      }}><CloseCircleOutlined style={{fontSize:16,fontWeight:500,color:'#999'}}/></div>}
      </div>:
        /**隐藏帮助中西图标 */
      <div style={{cursor:'pointer'}} onClick={()=>{
        setClose('open')
      }}>
          <QuestionCircleOutlined style={{fontSize: 24,fontWeight: 600}}/>
      </div>}
     </div>
      <Head />
      <div className={styles.left} style={{maxHeight:height+'px',overflowY:'auto'}}>
        <Menu helpClose={close}/>
      </div>
      <div className={`${styles.main} ${full ? styles.full_main : ""}`}>
        <div className={styles.container} style={{ height: `${containerHeight}` + "px",overflowY:'auto' }}>
          {children}
        </div>
      </div>
      {!full ? (
        <div className={styles.right}>
          <Info />
        </div>
      ) : null}
      <DueToAlert />
    </div>
  );
}
