import { Select, Checkbox, Col, Form, Input, message, Row } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { FormSubtitle } from "../../../commodity/view/form_subtitle";
import styles from "../../style/equip.module.scss";

import img_del from "../../../../assets/images/shanchu.png";
import { formAction } from "../../action/hotkey/form_action";
import { useBoolean } from "ahooks";
import { ChooseGoods } from "../../../../component/choose-goods";
import { Table } from "../../../../component/table";
import { ChooseShop } from "../../../../component/choose-shop";
import { FormStore } from "../../store/hotkey/form_store";
import { autoRem } from "../../../../global.style";
import { useState } from "react";

export const FormBasic = ({ title, disabled, tableList }) => {
  // const { Option } = Select;
  // const action = formAction;
  // const { state, dispatch } = useContext(FormStore);
  let height = document.body.clientHeight - 188;
  const handleChange = (e) => {
    // action.setFormData({ dispatch, key: "BarCodeScalesID", value: e });
  };
  return (
    <div className={styles.root} style={{ height: height + "px" }}>
      <div className={styles.content}>
        <FormSubtitle>热键信息</FormSubtitle>

        <div
          className="custom-radio"
          style={{
            margin: "" + autoRem(1.9375) + " " + autoRem(1.25) + " 0 0",
          }}
        >
          <div id="printhtml" style={{ display: "none" }}>
            {tableList.map((item) => {
              return (
                <div
                  style={{
                    float: "left",

                    border: "solid 1px",
                    width: "70px",
                    height: "70px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {item.top && <div>{item.top.goodsName}</div>}
                  {item.top && item.bottom && (
                    <div
                      style={{
                        borderTop: "dashed 1px #333333",
                        margin: "5px auto",
                        width: "80%",
                        height: 1,
                      }}
                    ></div>
                  )}
                  {item.bottom && <div>{item.bottom.goodsName}</div>}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
