import { useBoolean } from "ahooks";
import { Tabs, Radio, Space, Button, Table, message, Empty } from "antd";
import { Form } from "./hotkey_form";
import React, { useContext, useEffect, useRef, useState } from "react";
import { fileAction } from "../../action/hotkey/file_action";
import { FileStore } from "../../store/hotkey/file_store";
import { DataTableStore } from "../../../../component/table/store/data_table_store";
import { AuthLogic } from "../../../../logic/auth_logic";
import styles from "../../style/hotkey.module.scss";
import { ChooseGoodsPlu } from "../../../../component/choose-plu";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import {CloseCircleOutlined} from '@ant-design/icons'

let selectKey = 0
let editId = "";
let seeId = "";
const formParams = {
  method: "Create",
  id: "",
};
export function HotKey(viewPower) {
  const { TabPane } = Tabs;

  const [chooseState, chooseFunc] = useBoolean(false);
  const [createState, createFunc] = useBoolean(false);
  const action = fileAction;
  const { state, dispatch } = useContext(FileStore);
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);
  const [menuButton, setData] = useState([]);
  useEffect(() => {
    (async function () {
      let r = await new AuthLogic().selButton(viewPower.viewPower);
      if (r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          r[i] = r[i].split("_")[r[i].split("_").length - 1];
        }
      }

      setData(r);
      await action.getEquipment({ dispatch });
      // await action.getAllHotKey({ dispatch, id: state.equipLlist[0].id });
      onSearch(1);
    })();
    return () => {};
  }, []);

  const onSearch = async (pageIndex) => {
    await action.loadPage({
      id: state.selectId,
      pageIndex: pageIndex,
      pageSize: 224,
      dispatch: dispatch,
      search: state.search,
    });
  };
  let list = [];
  for (let i = 0; i < 112; i++) {
    list.push(i + 1);
  }
  let height = document.body.clientHeight - 188;
  // 打印去掉页眉页脚
  //  function printPage(callback) {//除ie外，其余浏览器需要给最外层容器添加css：@page{margin:0}
  //   function pagesetup_null() {
  //       var HKEY_Path = 'HKEY_CURRENT_USER\\Software\\Microsoft\\Internet Explorer\\PageSetup\\'; // 定义注册表位置
  //       // name的值可为header（页眉），footer（页脚），margin_bottom（下边距），margin_left（左边距），margin_right（右边距），margin_top（上边距）。
  //       function PageSetup(name, value, onSuccess, onFail) {
  //           try {
  //               var Wsh = new window.ActiveXObject('WScript.Shell');
  //               Wsh.RegWrite(HKEY_Path + name, value); // 修改注册表值
  //               onSuccess && onSuccess();
  //           } catch (e) {
  //               onFail & onFail();
  //           }
  //       }
  //       PageSetup(
  //           'footer',
  //           '',
  //           () => PageSetup('header', '', () => window.print(), () => message.error('需要运行ActiveX对象后，才能进行打印设置。')),
  //           () => message.error('需要运行ActiveX对象后，才能进行打印设置。')
  //       );
  //   }
  //   if (Util.isIE()) {
  //       pagesetup_null();
  //   } else {
  //       window.print();
  //   }
  //   callback && callback();
  // },

  const print = () => {
    const newWindow = window.open("打印窗口", "_blank");
    let printhtml = window.document.getElementById("printhtml");
    const docStr = printhtml.innerHTML; //需要打印的内容
    newWindow.document.write(docStr);
    const styles = document.createElement("style");
    styles.setAttribute("type", "text/css"); //media="print"
    styles.innerHTML = "@page { size: landscape;}";
    newWindow.document.title = "热键打印";
    newWindow.document.getElementsByTagName("head")[0].appendChild(styles);
    newWindow.print();
    newWindow.close();
  };
  const [arrList, setList] = useState([]);
  useEffect(() => {
    let len = state.tableList.length;
    let n = 14; //假设每行显示4个
    let lineNum = len > 0 && len % 14 === 0 ? len / 14 : Math.floor(len / 14 + 1);
    let res = [];
    for (let i = 0; i < lineNum; i++) {
      // slice() 方法返回一个从开始到结束（不包括结束）选择的数组的一部分浅拷贝到一个新数组对象。且原始数组不会被修改。
      let temp = state.tableList.slice(i * n, i * n + n);
      res.push(temp);
    }
    setList(res);
  }, [state.tableList]);
  // display: "none",
  return (
    <div className={styles.root} style={{ height: height + "px" }}>
      <div
        id="printhtml"
        style={{ width: 750, display: "none", margin: "10px 10px" }}
      >
        <table cellSpacing="0" cellPadding="0" style={{ margin: "20px 20px" }}>
          {arrList.map((itemr, index1) => {
            return (
              <tr>
                {itemr.map((itemCol, index2) => {
                  return (
                    <td
                      style={{
                        borderTop: "solid 1px",
                        borderRight: "1px solid",
                        borderLeft: `${
                          index2 % 14 == 0 ? "1px solid" : "none"
                        }`,
                        borderBottom: `${index1 == 7 ? "solid 1px" : "none"}`,
                      }}
                    >
                      <div
                        style={{
                          width: `${index2 < 8 ? 55 + index2 * 0.5 : 55}px`,
                          height: `${index1 < 5 ? 53 : 53 - 2}px`,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {itemCol.top && (
                          <div style={{ overflow: "hidden" }} >
                            {itemCol.top.goodsName}
                          </div>
                        )}
                        {itemCol.top && itemCol.bottom && (
                          <div
                            style={{
                              borderTop: "dashed 1px #333333",
                              margin: "5px auto",
                              width: "80%",
                              height: 1,
                            }}
                          ></div>
                        )}
                        {itemCol.bottom && (
                          <div style={{ overflow: "hidden" }}>
                            {itemCol.bottom.goodsName}
                          </div>
                        )}
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </table>
        {false &&
          state.tableList.map((item) => {
            return (
              <div
                style={{
                  float: "left",
                  border: "solid 1px",
                  width: "55px",
                  height: "55px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {item.top && <div>{item.top.goodsName}</div>}
                {item.top && item.bottom && (
                  <div
                    style={{
                      borderTop: "dashed 1px #333333",
                      margin: "5px auto",
                      width: "80%",
                      height: 1,
                    }}
                  ></div>
                )}
                {item.bottom && <div>{item.bottom.goodsName}</div>}
              </div>
            );
          })}
      </div>
      <ButtonPanel>
        <Button
          onClick={() => {
            print();
            // createFunc.setTrue();
          }}
        >
          打印
        </Button>
      </ButtonPanel>
      {/* <div className={styles.left_tabs}>
        <div>设备1</div>
        <div>设备2</div>
      </div> */}
      <div className={styles.table_container}>
        <div className={styles.left_tabs}>
          <Tabs
            centered
            defaultActiveKey="0"
            tabPosition={"left"}
            style={{ height: "100%" }}
            onTabClick={(key, e) => {
              dispatch({ type: "SelectID", payload: state.equipLlist[key].id });
            }}
            onChange={() => {
              onSearch(1);
            }}
          >
            {state.equipLlist.map((item, index) => {
              return (
                <TabPane
                  tab={
                    <span
                      style={
                        item.id == state.selectId
                          ? { fontSize: 14, color: "#ffc80b" }
                          : { fontSize: 14, color: "#02ca74" }
                      }
                    >
                      {item.equipmentName}
                    </span>
                  }
                  key={index}
                ></TabPane>
              );
            })}
          </Tabs>
        </div>
        <div className={styles.table_b}>
          {state.tableList.map((item, index) => {
            return (
              <div className={styles.cell} >
                <div className={styles.top_left}><span>{index + 1}</span>{item.top &&<div onClick={async ()=>{
                  editId = item.top.id
                  action.setCondition({dispatch,key:'id',value:item.top.id})
                await  action.delRow({id:item.top.id})
                await onSearch(1)
                }}><CloseCircleOutlined/></div>}</div>                

                <div className={styles.content}>
                  <div
                    style={item.top ? { color: "#02ca74", } : {}}
                    className={styles.selectBox}
                    onClick={() => {
                      dispatch({
                        type: "KeyCode",
                        payload: index + 1,
                      });
                      chooseFunc.setTrue();
                    }}
                  
                  >
                    {item.top ? item.top.goodsName : "选择商品"}
                  </div>
                  <div
                    style={{
                      borderTop: "solid 1px #d9d9d9",
                      margin: " 5px auto",
                      width: "80%",
                    }}
                  ></div>
                  <div
                    style={item.bottom ? { color: "#02ca74" } : {}}
                    className={styles.selectBox}
                    onClick={() => {
                      dispatch({
                        type: "KeyCode",
                        payload: index + 1 + 112,
                      });
                      chooseFunc.setTrue();
                    }}
                  >
                    {" "}
                    {item.bottom ? item.bottom.goodsName : "选择商品"}
                  </div>
                </div>
                <div className={styles.bottom_right}>{item.bottom && <div onClick={async ()=>{
                   editId = item.bottom.id
                   action.setCondition({dispatch,key:'id',value:item.bottom.id})
                  await action.delRow({id:item.bottom.id})
                  await onSearch(1)
                }}><CloseCircleOutlined/></div>}<span>{index + 112 + 1}</span></div>

              </div>
            );
          })}
        </div>
      </div>
      {createState && (
        <Form
          close={(hasUpdate) => {
            if (hasUpdate) {
              onSearch(tableState.current);
            }

            createFunc.setFalse();
          }}
          seeId={seeId}
          editId={editId}
          method={formParams.method}
          item={null}
          list={state.tableList}
        />
      )}
      <ChooseGoodsPlu
        key={state.search.id?state.search.id:null}
        clearSelectedList={true}
        visibleModal={chooseState}
        close={async (status, r) => {
          if (r) {
            let item =
              state.keyCode <= 112
                ? state.tableList[state.keyCode - 1].top
                : state.tableList[state.keyCode - 1 - 112].bottom;
            let pluCode = r[0].pluCode;
            if (item) {
              await action.updSubmit({ ID: item.id, PluCode: pluCode });
              onSearch(1);
            } else {
              let params = {
                KeyCode: state.keyCode,
                PluCode: pluCode,
                BarCodeScalesID: state.selectId,
              };
              await action.addSubmit({ params });
              onSearch(1);
            }
          }
          chooseFunc.setFalse();
        }}
      ></ChooseGoodsPlu>
    </div>
  );
}
