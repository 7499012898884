import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
  TimePicker,
  DatePicker,
  Tag,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { FormSubtitle } from "../../../commodity/view/form_subtitle";
import styles from "../../style/inventory_goods_formbasic.module.scss";

import { useBoolean } from "ahooks";
import { ChooseGoods } from "../../../../component/choose-goods";
import { ChooseShop } from "../../../../component/choose-shop";
import { formAction } from "../../action/booking/form_action";
import { FormStore } from "../../store/booking/form_store";
import { autoRem } from "../../../../global.style";
import { useState } from "react";
import { ChooseClassify } from "../../../../component/choose-classify";
import { RadioStyle } from "../../../../component/radio-style";
import { start } from "repl";
import moment from "moment";
import img_del from "../../../../assets/images/shanchu.png";
import { Table } from "../../../../component/table";

const { Option } = Select;
const { RangePicker } = DatePicker;

export function FormBasic({ title, disabled, updateFormField }) {
  /** 是否选择店铺 */
  const [shopState, shopFunc] = useBoolean(false);
  /** 是否选择商品 */
  const [goodsState, goodsFunc] = useBoolean(false);

  /** 表单状态 */
  const { state, dispatch } = useContext(FormStore);
  /** 表单 */
  const { formData } = state;

  /** 表单action */
  const action = formAction;

  const onChangeCheck = (e) => {
    if (!disabled) {
      action.setFormData({
        dispatch,
        key: "isMemberClass",
        value: e.target.checked ? 1 : 2,
      });
      if (e.target.checked) {
        action.setFormData({ dispatch, key: "classList", value: [] });
      }
    }
  };
  const handleChange = (value, option) => {
    let arr = [];
    let arrayId = [];
    for (let i = 0; i < option.length; i++) {
      let obj = {};
      obj["memberClassID"] = option[i].key;
      arrayId.push(option[i].key);
      arr.push(obj);
    }
    action.setFormData({ dispatch, key: "memberClassList", value: arr });
    action.setFormData({ dispatch, key: "classList", value: arrayId });
  };

  const byStringGetMoment = (string) => {
    if (!string) {
      return undefined;
    }
    return moment(string);
  };
  let checkGrade =
    formData.isMemberClass == 1
      ? {}
      : {
          required: true,
          validator: (_, __, callBack: (error?: string) => void) => {
            if (
              state.formData.classList == undefined ||
              state.formData.classList.length == 0
            ) {
              callBack("请选择等级");
              return;
            }
            callBack();
          },
        };
  /**不可选时间 */
  const disabledDate = (current) => {
    return current < moment().startOf("second");
  };
  /**到货时间比活动时间晚 */
  const disabledArrivalDate = (current) => {
    return (
      formData.endDate &&
      current < byStringGetMoment(formData.endDate).startOf("second")
    );
  };
  let height = document.body.clientHeight - 188;
  return (
    <div className={styles.root} style={{ height: height + "px" }}>
      <div className={styles.content}>
        <FormSubtitle>基础信息</FormSubtitle>
        <Row gutter={16} style={{ marginTop: 0 }}>
          <Col span={24}>
            <Form.Item
              label="活动名称"
              labelCol={{ span: 3 }}
              name="activityName"
              rules={[
                {
                  required: true,
                  validator: (_, __, callBack: (error?: string) => void) => {
                    if (
                      state.formData.activityName == undefined ||
                      state.formData.activityName == ""
                    ) {
                      callBack("请输入活动名称");
                      return;
                    }
                    callBack();
                  },
                },
              ]}
            >
              {formData.activityName ? "" : ""}
              <Input
                style={{ width: "400px" }}
                maxLength={15}
                disabled={disabled}
                value={formData.activityName}
                autoComplete="off"
                placeholder="请输入"
                onChange={(e) => {
                  updateFormField("activityName");
                  action.setFormData({
                    dispatch,
                    key: "activityName",
                    value: e.target.value,
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 0 }}>
          <Col span={24}>
            <Form.Item
              label="分配门店"
              labelCol={{ span: 3 }}
              name="shopName"
              rules={[
                {
                  required: true,
                  validator: (_, __, callBack: (error?: string) => void) => {
                    if (
                      state.formData.shopList == undefined ||
                      state.formData.shopList.length == 0
                    ) {
                      callBack("请选择分配门店");
                      return;
                    }
                    callBack();
                  },
                },
              ]}
            >
              {formData.shopName ? "" : ""}
              <Input
                style={{ width: "400px" }}
                disabled={disabled}
                value={formData.shopName}
                autoComplete="off"
                placeholder="请选择门店"
                onClick={() => shopFunc.setTrue()}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 0 }}>
          <Col span={24}>
            <Form.Item
              labelCol={{ span: 3 }}
              label={<span className={styles.alarm}>会员等级</span>}
              style={{ marginBottom: 0 }}
            >
              {formData.isMemberClass ? "" : ""}
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Checkbox
                  checked={formData.isMemberClass == 1 ? true : false}
                  onChange={onChangeCheck}
                >
                  不限制
                </Checkbox>
                <Form.Item name="classList" rules={[checkGrade]}>
                  {" "}
                  {formData.classList ? "" : ""}
                  <Select
                    disabled={
                      disabled || formData.isMemberClass == 1 ? true : false
                    }
                    mode="multiple"
                    style={{ width: "400px", marginTop: "10px" }}
                    value={formData.classList}
                    placeholder="请选择"
                    onChange={handleChange}
                  >
                    {state.TagList.map((v) => (
                      <Option value={v.id} key={v.id}>
                        {v.gradeName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 0 }}>
          <Col span={24}>
            <Form.Item
              labelCol={{ span: 3 }}
              label={<span>活动时间</span>}
              name="beginDate,endDate"
              rules={[
                {
                  required: true,
                  validator: (_, __, callBack: (error?: string) => void) => {
                    if (
                      state.formData.beginDate == undefined ||
                      state.formData.beginDate == "" ||
                      state.formData.beginDate == ""
                    ) {
                      callBack("请选择活动时间");
                      return;
                    }
                    if (
                      new Date(formData.endDate).getTime() -
                        new Date(formData.beginDate).getTime() <
                      0
                    ) {
                      callBack("结束时间不能早于开始时间");
                      return;
                    }
                    callBack();
                  },
                },
              ]}
            >
              {formData.beginDate ? "" : ""}
              <RangePicker
                showTime
                disabledDate={disabledDate}
                value={[
                  byStringGetMoment(formData.beginDate),
                  byStringGetMoment(formData.endDate),
                ]}
                disabled={disabled}
                style={{ width: "400px" }}
                onChange={(time, timsString) => {
                  action.setFormData({
                    dispatch,
                    key: "beginDate",
                    value: timsString ? timsString[0] : "",
                  });
                  action.setFormData({
                    dispatch,
                    key: "endDate",
                    value: timsString ? timsString[1] : "",
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 0 }}>
          <Col span={24}>
            <Form.Item
              labelCol={{ span: 3 }}
              name="arrivalDate"
              label={<span>到货时间</span>}
              rules={[
                {
                  required: true,
                  validator: (_, __, callBack: (error?: string) => void) => {
                    if (
                      state.formData.arrivalDate == undefined ||
                      state.formData.arrivalDate == ""
                    ) {
                      callBack("请选择到货时间");
                      return;
                    } else if (
                      new Date(formData.arrivalDate + " 23:59:59").getTime() -
                        new Date(formData.endDate).getTime() <
                      0
                    ) {
                      callBack("到货时间不能比结束时间早");
                      return;
                    }
                    callBack();
                  },
                },
              ]}
            >
              {formData.arrivalDate ? "" : ""}
              <DatePicker
                disabledDate={disabledArrivalDate}
                value={byStringGetMoment(formData.arrivalDate)}
                disabled={disabled}
                style={{ width: "400px" }}
                onChange={(e, timsString) => {
                  action.setFormData({
                    dispatch,
                    key: "arrivalDate",
                    value: timsString,
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 0 }}>
          <Col span={24}>
            <Form.Item
              labelCol={{ span: 3 }}
              label={<span className={styles.alarm}>活动商品</span>}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <div
                  style={{
                    fontSize: "10px",
                    height: "30px",
                    textAlign: "center",
                    lineHeight: "30px",
                    background: "#ff746d",
                    color: "#fff",
                    cursor: "pointer",
                    padding: "0 5px",
                    marginBottom: "10px",
                  }}
                  onClick={() => {
                    if (disabled) {
                      return;
                    }
                    goodsFunc.setTrue();
                  }}
                >
                  添加商品
                </div>
              </div>
            </Form.Item>
          </Col>
        </Row>
        <DataTable dataList={state.tableList} disabled={disabled} />
      </div>
      <ChooseShop
      type="checkbox"
        visibleModal={shopState}
        close={(status, r) => {
          if (r) {
            let arr = [];
            let shopNameArr = []
            if (r.length > 0) {
              r.map((item) => {
                arr.push(item.id);
                shopNameArr.push(item.shopName)
              });
            }
            action.setFormData({ dispatch, key: "shopList", value: arr });
            action.setFormData({
              dispatch,
              key: "shopName",
              value: shopNameArr.toString(),
            });
          }
          updateFormField("shopName");
          shopFunc.setFalse();
        }}
      />
      <ChooseGoods
        visibleModal={goodsState}
        filter={{
          source: "wx",
          specifications: true,
          isMarketing: true,
          type: true,
        }}
        close={(status, r) => {
          if (r) {
            action.addTableItem({ dispatch, list: r });
          }
          goodsFunc.setFalse();
        }}
      />
    </div>
  );
}

/** 商品列表 */
export function DataTable({ dataList, disabled }) {
  /** 表单状态 */
  const { state, dispatch } = useContext(FormStore);
  /** 表单 */
  const { formData } = state;

  /** 表单action */
  const action = formAction;
  const columns: ColumnsType = [
    {
      title: "操作",
      dataIndex: "serial",
      key: "1",
      align: "center",
      width: 100,
      render: (v, r, i) => (
        <div>
          <img
            src={img_del}
            style={{ width: 20 }}
            onClick={() => {
              !disabled && action.delTableItem(dispatch, i);
            }}
          />
        </div>
      ),
    },
    {
      title: "商品编码",
      dataIndex: "goodsNo",
      key: "2",
      align: "center",
      width: 100,
    },
    {
      title: "商品名称",
      dataIndex: "goodsName",
      key: "3",
      align: "center",
      width: 100,
    },
    {
      title: "商品分类",
      dataIndex: "goodsClassName",
      key: "4",
      align: "center",
      width: 100,
    },
    {
      title: "单位",
      dataIndex: "unitName",
      key: "8",
      align: "center",
      width: 120,
    },
    {
      title: "门店价",
      dataIndex: "shopPrice",
      key: "8",
      align: "center",
      width: 120,
    },
    {
      title: "商城价",
      dataIndex: "storePrice",
      key: "8",
      align: "center",
      width: 120,
    },
    {
      title: "规格库存",
      dataIndex: "sumNumber",
      key: "8",
      align: "center",
      width: 120,
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "8",
      align: "center",
      width: 120,
      render: (v) => (
        <>
          {v == 1 && <Tag color="success">正常</Tag>}
          {v == 2 && <Tag color="error">停售</Tag>}
        </>
      ),
    },
    {
      title: "预售价",
      dataIndex: "presaleMoney",
      key: "8",
      align: "center",
      width: 120,
      render: (v, r, i) => (
        <>
          <Input
            value={v}
            disabled={disabled}
            style={{ width: "80%" }}
            onChange={(e) => {
              action.changeTableItem({
                dispatch,
                payload: {
                  key: "presaleMoney",
                  value: e.target.value,
                  index: i,
                },
              });
            }}
          />
        </>
      ),
    },
    {
      title: "预售库存",
      dataIndex: "presaleCount",
      key: "8",
      align: "center",
      width: 120,
      render: (v, r, i) => (
        <>
          <Input
            value={v}
            disabled={disabled}
            style={{ width: "80%" }}
            onChange={(e) => {
              action.changeTableItem({
                dispatch,
                payload: {
                  key: "presaleCount",
                  value: e.target.value,
                  index: i,
                },
              });
            }}
          />
        </>
      ),
    },
    {
      title: "单人限购",
      dataIndex: "presaleUseCount",
      key: "8",
      align: "center",
      width: 120,
      render: (v, r, i) => (
        <>
          <Input
            value={v}
            disabled={disabled}
            style={{ width: "80%" }}
            onChange={(e) => {
              action.changeTableItem({
                dispatch,
                payload: {
                  key: "presaleUseCount",
                  value: e.target.value,
                  index: i,
                },
              });
            }}
          />
        </>
      ),
    },
  ];

  return <Table dataSource={dataList} columns={columns} />;
}
