import React, { useState, useRef, useEffect, useContext } from "react";
import { Tree } from "antd";
import { treeAction } from "./action";
import { TreeStoreProvider, ChooseCouponStore } from "./store";
import { useRequest } from "ahooks";

declare var echarts: any;

const TreeData = ({ id, item }) => {
  const rootRef = useRef(null);
  const { state, dispatch } = useContext(ChooseCouponStore);
  useRequest(async () => await treeAction.getRootNode(item, dispatch));
  useRequest(async () => await treeAction.getchild(id, 0, dispatch));
  useEffect(() => {
    var dom = rootRef.current;
    dom.style.margin = "1rem auto";
    // dom.style.height = chartWidth;
    // dom.style.width = chartHeight;

    var myChart = echarts.init(dom);
    // var app = {};
    myChart.on("click", clickFun);

    var option = {
      tooltip: {
        trigger: "item",
        formatter: "{b}:{c}",
        hideDelay: 0, // chart.refresh();刷新时会维持当时图表的所有状态，所以设置隐藏延迟为0，否则在快速选择另一个节点时（尤其是数据比较多时）导致无法显示选择中的tooltip
        //无法完全避免但是很大减轻了副作用
      },
      series: [
        {
          name: "树图",
          type: "tree",
          orient: "horizontal", // vertical horizontal
          // rootLocation: { x: "center", y: "60%" }, // 根节点位置  {x: 'center',y: 10}
          // nodePadding: 100, //智能定义全局最小节点间距，不能定义层级节点间距，有点搓。
          symbol: "circle",
          symbolSize: 20,
          clickable: false,
          left: "100px",
          right: "100px",
          top: "150px",
          bottom: "20%",
          roam: false, //是否开启鼠标平移和缩放
          data: state.treeData,
          label: {
            position: "top",
          },
        },
      ],
    };
    if (option && typeof option === "object") {
      myChart.setOption(option, true);
    }
    if (myChart._chartsViews) {
      let elesArr = Array.from(
        new Set(myChart._chartsViews[0]._data._graphicEls)
      );
      let dep = myChart._chartsViews[0]._data.tree.root.height; //获取树高
      let layer_height = 150; //层级之间的高度
      let currentHeight = layer_height * (dep + 1) || layer_height;
      let newHeight = Math.max(currentHeight, layer_height) + 20;
      let chartHeight = newHeight + "px";
      let layer_width = 100; // 兄弟节点之间的距离
      let currentWidth = layer_width * (elesArr.length - 1) || layer_width;
      let newWidth = Math.max(currentWidth, layer_width);
      let chartWidth = newWidth + 200 + "px";
      dom.style.height = chartHeight;
      dom.style.width = chartWidth;
    }
    async function clickFun(param) {
      // param.data.symbol =
      // "image://http://www.viastreaming.com/images/apple_logo2.png";
      //远程加载图片最好先预加载过来，避免下载图标延迟。 var oImg = new Image();oImg.src = img;
      myChart.showLoading();
      console.log(param);
      if (param.data.index == 1) {
        let isShow = true;

        param.data.children.length > 0 &&
          param.data.children.map((item) => {
            if (item.children && item.children.length > 0) {
              isShow = false;
            }
          });
        isShow &&
          (await treeAction.getchild(
            param.data.id,
            state.treeData[0].children && state.treeData[0].children.length > 0
              ? param.data.index
              : 1,
            dispatch
          ));
      }

      myChart.hideLoading();

      // myChart.refresh(); //一定要refresh，否则不起作用
    }
    myChart.resize();

    return () => {};
  }, [state.id]);
  //关键点！

  return <div ref={rootRef} style={{ height: "200px", width: 400 }}></div>;
};
export const Demo = ({ item, id }) => {
  // console.log(item);

  return (
    <div style={{ minHeight: "200px" }}>
      <TreeStoreProvider>
        <TreeData id={id || ""} item={item} />
      </TreeStoreProvider>
    </div>
  );
};
