import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.scss";
import zh_CN from "antd/lib/locale-provider/zh_CN";
import "moment/locale/zh-cn";
import { ConfigProvider } from "antd";
import { HashRouter } from "react-router-dom";
// "antd": "^4.11.1",
ReactDOM.render(
  <ConfigProvider locale={zh_CN}>
    <HashRouter basename="">
      <App />
    </HashRouter>
  </ConfigProvider>,
  document.getElementById("root")
);
