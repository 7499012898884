import { message } from "antd";
import { DataTableAction } from "../../../component/table/action/data_table_action";
import { CommissionRuleLogic } from "../../../logic/commissionrule_logic";
class CommissionLogsAction extends DataTableAction  {
    async delRow(){}
    delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {}
    async loadPage(params, dispatch) {
        const { pageIndex, pageSize } = params;
        const { beginTime, endTime, seackerKey, } = params.search;
        const selParams = {
            beginTime: beginTime,
            endTime: endTime,
            seackerKey: seackerKey,
            
            pageIndex:pageIndex,
            pageSize: pageSize,
        }
        // 档案列表
        const r = await new CommissionRuleLogic().getCommissionLogs(selParams)

        if (r.data.statusCode == 200) {
            const dataList = r.data.data.dataSource || [];

            const list = dataList.map((v, i) => {
                v.key = i + 1;
                return v;
            })

            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: list, pageSize, loading: false });
        }else{
            this.setLoading({dispatch, loading: false})
        }

    }
    setCondition({ dispatch, key, value }) {
        const params = {
        }
        params[key] = value;
        dispatch({ type: "SetSearch", payload: params })
    }

    /**作废 */
    async cancelCommission(id, date){
        const r = await new CommissionRuleLogic().cancelCommission(id, date)
        if(r.data.statusCode == 200) {
            message.success('处理成功')
        } else{
            message.error(r.data.returnMessage)
        }
    }
}
export const fileAction = new CommissionLogsAction()