import moment from "moment";
import { MemberFlowTable } from "./tableShow/memberFlowTable";
import { useState, useEffect } from "react";
import { useBoolean, useDebounce, useDebounceFn}  from 'ahooks'
import { ReportLayer } from "../com/reportLayer";
import { Buttons } from "./com/buttons";
import { DateSelect } from "./select/dateSelect";
import { SearchLayer } from "./search/searchLayer";
import { SelectStore } from "./store/SelectStore";
import { ISelectGoods, ISelectTime, ISpeedQuery } from "../com/ISelectType";
import { SelectGoods } from "./select/selectGoods";
import { ApiCon } from "../com/apiCon";
import { Form, Select, message, Spin } from "antd";
import { QueryType } from "../com/QueryType";
const { Option } = Select;
/**
 * 会员收支
 */
export function MemberFlow(viewPower) {
    const [data, setData] = useState(null);
    const [ifInit, setIfInit] = useState(false);
    const [queryType, setQueryType] = useState<QueryType>('n');
    const [loading, loadingFunc] = useBoolean(true)
    const [queryState, setQueryState] = useState<{
        saleType?: number;
    } & ISelectTime  & ISpeedQuery>({
        seacherKey: '',
        beginTime: moment(Date.now()).startOf("month").format('Y-M-D'),
        endTime: moment(Date.now()).format('Y-M-D'),
    });
    const [paging, setPaging] = useState<{
        pageSize: number,
        current: number;
        total: number;
    }>({
        pageSize: 20,
        current: 1,
        total: 0,
    });

    useEffect(() => {
        if (!ifInit) {
            setIfInit(true);
            queryClick();
        }
        // loadingFunc.setTrue()
    });

    const queryClick = (_queryType: QueryType = queryType) => {
        setQueryType(_queryType);
        let op: any = {
            ...queryState,
            // ...{
            //     pageIndex: paging.current,
            //     pageSize: paging.pageSize,
            // },
        };
        //根据不同的查询模式修剪参数
        switch (_queryType) {
            case 'c':
                op.seacherKey = '';
                break;
            case 's':
                break;
            case 'n':
                op.seacherKey = '';
                break;
        }
        ApiCon.MemberFlowRecord(op).then((data) => {
            console.log('进销存数据', data);
            setPaging({
                ...paging,
                total: data.totalCount,
            });
            if (data.dataSource) {
                setData(data.dataSource);
            } else {
                setData([]);
            }
            loadingFunc.setFalse()

        }).catch((data) => {
            loadingFunc.setFalse()
            console.log(data)
            setData([]);
            //提示
            message.error(data.message);
        });
    }

    const setQuery = (query) => {
        setQueryState({
            ...queryState,
            ...query,
        });
    }
    const { run } = useDebounceFn(
        (fn) => {
          fn && fn();
        },
        {
          wait: 1500,
        }
      );
    return <SelectStore.Provider
        value={{
            state: queryState,
            dispatch: setQuery,
        }}
    >
        {/* <Spin tip='数据加载中' spinning={loading}> */}
        <ReportLayer
        queryItem={
            <SearchLayer
                queryClick={queryClick}
                speedQureyPlaceholder="请输入商品分类，商品名称，商品编码"
                notShowInput={true}
                left={{left:'0'}}
            >
                {/* <SelectGoods />
                <div className="item_">
                    <Form.Item label="销售类型">
                        <Select style={{ width: 175 }} allowClear value={queryState.saleType} onChange={(value) => {
                            setQuery({ 'saleType': value });
                        }}>
                            <Option value={1}>门店</Option>
                            <Option value={2}>商城</Option>
                        </Select>
                    </Form.Item>
                </div> */}
                <DateSelect />
            </SearchLayer>
        }
        button={
            (
             <Buttons
               exportExcel={() => {
                 run(async () => {
                   let today =  moment(Date.now()).format("yyyy-MM-DD")
                   const r = await ApiCon.exportExcel('export_table',`会员收支统计${today}.xlsx`);
                 });
               }}
             />
           )
         }
        paging={{
            ...paging,
            onChange: (page, pageSize) => {
                paging.current = page;
                paging.pageSize = pageSize;
                setPaging({
                    ...paging,  
                });
                queryClick();
            },
        }}
    >
            <MemberFlowTable data={data} />
        </ReportLayer>
        {/* </Spin> */}
    </SelectStore.Provider>
}