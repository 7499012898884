import { message } from "antd";
import { PrintSetLogic } from "../../../../logic/print_set_logic";

export class FormAction {
  initForm({ state, dispatch, id, form, method, item }) {
    if (method != "Create") {
      // console.log(item);
      dispatch({type:'Clear'})
      const { ticketsJson, ticketsType, isStencil } = item;
      dispatch({
        type: "SetInitData",
        payload: { ticketsJson, ticketsType, isStencil },
      });
    }else{
      dispatch({type:'Clear'})
    }
  }
  setFormData({ dispatch, key, value }) {
    const params = {};
    params[key] = value;
    dispatch({ type: "SetFormData", payload: params });
  }
  setActive({ dispatch, items }) {
    dispatch({ type: "SetActive", payload: items });
    // for(let keys in items.)
    // form.setFieldsValue({ title: "", content: "" });
  }
  init({ dispatch, form, item }) {
    // console.log(item)
    form.setFieldsValue({ componentType: item.componentType });
    form.setFieldsValue({ title: item.title });
    form.setFieldsValue({ titleFontSize: item.titleFontSize });
    form.setFieldsValue({ titleFontWeight: item.titleFontWeight });
    form.setFieldsValue({ value: item.value });
    form.setFieldsValue({ valueFontSize: item.valueFontSize });
    form.setFieldsValue({ valueFontWeight: item.valueFontWeight });
    form.setFieldsValue({ i: item.i });
    form.setFieldsValue({ x: item.x });
    form.setFieldsValue({ y: item.y });
    form.setFieldsValue({ w: item.w });
    form.setFieldsValue({ h: item.h });
    form.setFieldsValue({ isEllipsis: item.isEllipsis });
    form.setFieldsValue({ isDisplay: item.isDisplay });
    form.setFieldsValue({ isBorder: item.isBorder });
    form.setFieldsValue({ verticalAlign: item.verticalAlign });
    form.setFieldsValue({textAlign:item.textAlign})
  }
  saveComponent({ dispatch, title }) {
    dispatch({ type: "SaveComponent", payload: title });
  }
  changeFormData({ dispatch, data }) {
    dispatch({ type: "SetDataList", payload: data });
  }
  delItems({ dispatch, index }) {
    dispatch({ type: "DelItems", payload: index });
  }
  setPageSize({ dispatch, data }) {
    dispatch({ type: "SetPageSize", payload: data });
  }

  private paddingData(state) {
    const { pageSize, baseUnit, baseUnitHeight, formData,isStencil } = state;
    let ticketsJson = JSON.stringify({
      pageSize,
      baseUnit,
      baseUnitHeight,
      dataList: formData.dataList,
    });
    let params = {
      ticketsType: 4,
      ticketsJson: ticketsJson,
      isStencil: isStencil,
    };
    return params;
  }
  async addSubmit({ dispatch, state }) {
    dispatch({ type: "Loading", payload: true });
    const r = await new PrintSetLogic().add(this.paddingData(state));
    dispatch({ type: "Loading", payload: false });
    if (r.data.statusCode === 200) {
      message.success("保存成功");
      return true;
    } else {
      message.error(r.data.returnMessage);
      return false;
    }
  }
async  updSubmit({ dispatch, state, id }) {
    dispatch({ type: "Loading", payload: true });
    const r = await new PrintSetLogic().update({...this.paddingData(state),id:id});
    dispatch({ type: "Loading", payload: false });
    if (r.data.statusCode === 200) {
      message.success("修改成功");
      return true;
    } else {
      message.error(r.data.returnMessage);
      return false;
    }
  }
  async getTemplate({ticketsJson, dispatch}){
    if(ticketsJson == '' ){
      message.error('请输入模板')
    }else{
      dispatch({
        type: "SetInitData",
        payload: { ticketsJson, ticketsType: 4, isStencil: false },
      });
    }
    
  }
}
export const formAction = new FormAction();
