import React, { useContext, useState } from "react";
import { Button, Cascader, Checkbox, Col, Form, Input, Row, Select } from "antd";
import styles from "../style/file_search.module.scss";
import { FileStore } from "../store/file_store";
import { fileAction } from "../action/file_action";
import { useRequest } from "ahooks";
import { Select as SelectAntd } from "antd";
import { useEffect } from "react";
import { CityLogic } from "../../../logic/city";
import axios from "axios";

const { Option } = Select;

export function FileSearch({ onSearch }) {
    const { state, dispatch } = useContext(FileStore);
    const action = fileAction;

    useEffect(() => {
        axios({
            method: "GET",
            url: "/lib/area.json",
            responseType: "json",
        }).then(({ data }) => {
            dispatch({
                type: 'SetArgs',
                payload: {
                    areaList: data,
                },
            });
        });
    }, []);

    return (
        <div>
            <Form layout="horizontal">
                <div className={styles.row}>
                    <div className={styles.cell}>
                        <Form.Item label="店铺区域">
                            <Cascader style={{ width: "15rem" }} options={state.areaList} placeholder="请选择"
                                onChange={(v, selectedOptions) => {
                                    action.setCondition("Area", selectedOptions.map((item) => {
                                        return item.label;
                                    }), dispatch);
                                }}
                            />
                        </Form.Item>
                    </div>
                    <div className={styles.cell}>
                        <Form.Item label="店铺状态">
                            <Select style={{ width: "15rem" }}
                                onChange={(v) => action.setCondition("Status", v, dispatch)}
                                defaultValue={-1}
                                showSearch
                                filterOption={(input:any, option:any) => {
                                    return option.children.indexOf(input) >= 0;
                                }}
                            >
                                {
                                    state.statusList.map(v => (
                                        <SelectAntd.Option value={v.id}>{v.value}</SelectAntd.Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </div>
                    {/* <div className={styles.cell}>
                        <Form.Item label="店铺类型">
                            <Select style={{ width: "15rem" }}
                                onChange={(v) => action.setCondition("Type", v, dispatch)}
                                defaultValue={-1}
                                showSearch
                                filterOption={(input:any, option:any) => {
                                    return option.children.indexOf(input) >= 0;
                                }}
                            >
                                {
                                    state.typeList.map(v => (
                                        <SelectAntd.Option value={v.id}>{v.value}</SelectAntd.Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </div> */}
                </div>
                <div className={styles.row}>
                    <Button type="primary" danger onClick={() => onSearch(1)}>搜  索</Button>
                </div>
            </Form>
        </div>
    )
}