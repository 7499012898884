import { useBoolean } from "ahooks";
import { message, Select, InputNumber } from "antd";
// import axios from "axios";
import Cookies from "js-cookie";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Input } from "../../../component/input";
import { TextArea } from "../../../component/text-area";
import { Tools, axios } from "../../../config";
import { fillHeight, zip } from "../../../logic/common_logic";
import { formAction } from "../action/form_action";
import { FormStore } from "../store/form_store";
import styles from "../style/form_format.module.scss";
import { FormSubtitle } from "./form_subtitle";
import { Button } from "../../../component/button";
import { ButtonPanel } from "../../../component/button-panel/button_panel";
import { ChooseImage } from "../../../component/choose-image";
interface IEditColumn {
  title: string;
  width?: number | string;
  render?: (i, v) => JSX.Element;
}

const { Option } = Select;

function Radio({ onChange, defaultValue, disabled }) {
  const [state, stateFunc] = useBoolean(false);

  useEffect(() => {
    if (defaultValue == 1) {
      stateFunc.setTrue();
    } else {
      stateFunc.setFalse();
    }

    return () => {};
  }, [defaultValue]);

  return (
    <div
      className={`${styles.radio} ${state ? styles.active : ""} ${
        disabled ? styles.disabled : ""
      }`}
      onClick={() => {
        if (disabled) return;
        onChange(!state);
        stateFunc.toggle();
      }}
    ></div>
  );
}

function Image({ imageUrl, action, dispatch, rowIndex, disabled, state, goodsSource }) {
  const inputRef = useRef(null);
  const onChange = async (e) => {
    let file = e.target.files[0];

    // 压缩
    file = await zip(file);

    const formData = new FormData();
    formData.append("file", file);

    const r = await axios({
      method: "POST",
      url: Tools.getApiAddress("goods_update_file"),
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data;charset=UTF-8",
        SYSTOKEN: Cookies.get("token"),
        ClientSource: "web",
      },
    });

    if (r.data.statusCode == 200) {
      action.setFormatValue({
        dispatch,
        value: r.data.data,
        attribute: "Image",
        rowIndex,
      });

      message.success("上传成功");
    } else {
      message.error("上传失败");
    }

    inputRef.current.value = "";
  };
  const [imgState, imgFunc] = useBoolean(false);
  return (
    <div className={styles.camera}>
      <span
        style={{
          cursor: disabled ? "not-allowed" : "pointer",
        }}
      >
        {imageUrl ? (
          <img
            src={Tools.getImageAddress(imageUrl, goodsSource)}
            onClick={() => !disabled && inputRef.current.click()}
          />
        ) : (
          <span onClick={() => !disabled && inputRef.current.click()}></span>
        )}

        <input ref={inputRef} type="file" onChange={onChange} />
      </span>
      <ButtonPanel>
        <Button
          disabled={disabled}
          onClick={() => {
            imgFunc.setTrue();
          }}
        >
          选择图片
        </Button>
      </ButtonPanel>
      <ChooseImage
        visibleModal={imgState}
        clearSelectedList={true}
        filter={{ type: "radio" }}
        close={(status, r) => {
          console.log(r);
          if (r) {
            const id = r[0].ID;
            formAction.getImageOne({
              id,
              role: "",
              dispatch,
              state,
              rowIndex: rowIndex,
            });
          }
          imgFunc.setFalse();
        }}
      />
    </div>
  );
}

function EditTable({ disabled }) {
  // const { TextArea } = AntdInput;
  const { state, dispatch } = useContext(FormStore);
  const tagRef = useRef(null);
  const action = formAction;
  const { formData } = state;
  const columns: IEditColumn[] = [
    {
      title: "操作",
      width: "10rem",
      render: (i,v) => (
        <div className={styles.opration}>
          <div
            className={styles.item}
            onClick={(e) => {
              if (disabled || state.formData.type == 2) e.preventDefault();
              else return action.addFormatRow({ dispatch });
            }}
          ></div>
          <div
            className={styles.item}
            onClick={() => {
              !disabled &&
                state.formData.formatList.length > 1 && (!v.isDefault) &&
                action.delFormatRow({ dispatch, rowIndex: i });
            }}
          ></div>
        </div>
      ),
    },
    {
      title: "图片",
      width: "5rem",
      render: (i, v) => {
        return (
          <>
            <Image
              disabled={disabled}
              imageUrl={v && v.image}
              goodsSource={v && v.goodsSource}
              action={action}
              dispatch={dispatch}
              rowIndex={i}
              state={state}
            />
          </>
        );
      },
    },
    {
      title: "规格单位",
      width: "200px",
      render: (i, v) => {
        return (
          <div className={styles.unit_group}>
            <span>1</span>
            {
              <Select
                disabled={disabled || state.formData.type == 2 || v.isDefault}
                value={v.unitId}
                onChange={(e) =>
                  action.setFormatValue({
                    dispatch,
                    attribute: "UnitId",
                    value: e,
                    rowIndex: i,
                  })
                }
              >
                {state.unitList.map((v) =>(
                  <Option key={v.id} value={v.id} disabled={i != 0 && v.id==formData.unitId}>
                    {v.unitName}
                  </Option>
                ))}
              </Select>
            }
          </div>
        );
      },
    },
    {
      title: "",
      render: () => {
        return <div>=</div>;
      },
    },
    {
      title: "换算规则",
      width: "200px",
      render: (i, v) => {
        return (
          <div className={styles.content_group}>
            {/* <Input disabled={disabled}  defaultValue={v.unitContentValue} onChange={(e) => action.setFormatValue({ dispatch, attribute: "UnitContentValue", value: e.target.value, rowIndex: i })} /> */}

            <input
              maxLength={20}
              disabled={
                disabled ||
                v.unitContentId == v.unitId ||
                state.formData.type == 2 
              }
              style={
                v.unitContentId == v.unitId ? { background: "#f3f3f3" } : {}
              }
              type="number"
              value={v.unitContentValue}
              onChange={(e) =>
                action.setFormatValue({
                  dispatch,
                  attribute: "UnitContentValue",
                  value: e.target.value,
                  rowIndex: i,
                })
              }
            />
            <Select
              disabled={true}
              value={v.unitContentId}
              onChange={(v) =>
                action.setFormatValue({
                  dispatch,
                  attribute: "UnitContentId",
                  value: v.toString(),
                  rowIndex: i,
                })
              }
            >
              {state.unitList.map((v) => (
                <Option value={v.id}>{v.unitName}</Option>
              ))}
            </Select>
          </div>
        );
      },
    },
    {
      title: "万里牛条码",
      width: "180px",
      render: (i, v) => (
        <input 
          className={styles.input_group}
          value={(v && v.wnnCode) || ""}
          disabled={disabled}
          onChange={(e) =>
            action.setFormatValue({
              dispatch,
              attribute: "wnnCode",
              value: e.target.value,
              rowIndex: i,
            })
          }
        />
      ),
    },
    {
      title: "规格条码",
      width: "200px",
      render: (i, v) => (
        <input type="number"
          className={styles.input_group}
          value={(v && v.barCode) || ""}
          disabled={disabled || v.isDefault}
          onChange={(e) =>
            action.setFormatValue({
              dispatch,
              attribute: "Barcode",
              value: e.target.value,
              rowIndex: i,
            })
          }
        />
      ),
    },
    {
      title: "PLU码",
      width: "160px",
      render: (i, v) => (
        <input type="number"
          className={styles.input_group}
          value={(v && v.pluCode) || ""}
          disabled={state.formData.type == 1 || disabled}
          placeholder="1-6位数字"
          onChange={(e) =>
            action.setFormatValue({
              dispatch,
              attribute: "PluCode",
              value: e.target.value,
              rowIndex: i,
            })
          }
        />
      ),
    },
    {
      title: "门店价格",
      width: "120px",
      render: (i, v) => (
        <input
          className={styles.input_group}
          type="number"
          disabled={disabled}
          value={(v && v.shopPrice) || ""}
          onChange={(e) =>
            action.setFormatValue({
              dispatch,
              attribute: "ShopPrice",
              value: e.target.value,
              rowIndex: i,
            })
          }
        />
      ),
    },
    {
      title: "商城价格",
      width: "120px",
      render: (i, v) => (
        <input
          type="number"
          className={styles.input_group}
          disabled={disabled}
          value={(v && v.storePrice) || ""}
          onChange={(e) =>
            action.setFormatValue({
              dispatch,
              attribute: "StorePrice",
              value: e.target.value,
              rowIndex: i,
            })
          }
        />
      ),
    },
    {
      title: "划线价格",
      width: "120px",
      render: (i, v) => (
        <input
          className={styles.input_group}
          type="number"
          min={0.01}
          disabled={disabled}
          value={(v && v.lineationPrice) || ""}
          onChange={(e) =>
            action.setFormatValue({
              dispatch,
              attribute: "LineationPrice",
              value: e.target.value,
              rowIndex: i,
            })
          }
        />
      ),
    },
    // {
    //     title: "最低售价",
    //     width: "180px",
    //     render: (i, v) => (
    //         <input className={styles.input_group} type="number" disabled={disabled} value={v && v.lowestPrice || ''} onChange={(e) => action.setFormatValue({ dispatch, attribute: "LowestPrice", value: e.target.value, rowIndex: i })} />
    //     )
    // },
    // {
    //     title: "最高售价",
    //     width: "180px",
    //     render: (i, v) => (
    //         <input className={styles.input_group} disabled={disabled} type="number" value={v && v.highestPrice || ''} onChange={(e) => action.setFormatValue({ dispatch, attribute: "HighestPrice", value: e.target.value, rowIndex: i })} />
    //     )
    // },
    {
      title: "负库存销售",
      width: "120px",
      render: (i, v) => (
        <Radio
          disabled={disabled}
          defaultValue={v.isNegativeInventory}
          onChange={(v: boolean) =>
            action.setFormatValue({
              dispatch,
              attribute: "IsNegativeInventory",
              value: v,
              rowIndex: i,
            })
          }
        />
      ),
    },
    {
      title: "门店售卖",
      width: "120px",
      render: (i, v) => (
        <Radio
          disabled={disabled}
          defaultValue={v.isShop}
          onChange={(v: number | string) =>
            action.setFormatValue({
              dispatch,
              attribute: "IsShop",
              value: v,
              rowIndex: i,
            })
          }
        />
      ),
    },
    {
      title: "商城售卖",
      width: "120px",
      render: (i, v) => (
        <Radio
          disabled={disabled}
          defaultValue={v.isStore}
          onChange={(v: boolean) =>
            action.setFormatValue({
              dispatch,
              attribute: "IsStore",
              value: v,
              rowIndex: i,
            })
          }
        />
      ),
    },

    {
      title: "规格说明",
      width: "200px",
      render: (i, v) => {
        return (
          <TextArea
            allowClear
            maxLength={100}
            autoSize={{ minRows: 2, maxRows: 3 }}
            defaultValue={v.remark}
            onChange={(e) => {
              action.setFormatValue({
                attribute: "Remark",
                value: e.target.value,
                dispatch,
                rowIndex: i,
              });
            }}
          />
        );
      },
    },
  ];
  // console.log(state.formData);
  return (
    <div className={styles.table_c} >
      <div className={styles.table}>
        <div className={styles.thead}>
          <div className={styles.tr}>
            {columns.map((v, i) => (
              <div key={v.title+i} className={styles.th}>
                {v.title}
              </div>
            ))}
          </div>
        </div>

        <div className={styles.tbody}>
          {formData.formatList.length > 0
            ? formData.formatList.map((v, index) => (
                <React.Fragment key={index}>
                  <div key={index} className={styles.tr}>
                    {columns.map(({ width, render }, i) => {
                      return (
                        <div className={styles.td} style={{ width }}>
                          {render && render(index, v)}
                        </div>
                      );
                    })}
                  </div>
                  <div style={{ height: "1.375rem" }}></div>
                </React.Fragment>
              ))
            : null}
        </div>
      </div>
    </div>
  );
}

export function FormFormat({ disabled }) {
  return (
    <div className={styles.root}>
      <FormSubtitle>商品规格</FormSubtitle>

      <EditTable disabled={disabled} />
    </div>
  );
}
