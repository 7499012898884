// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../config";
import { effeative } from "./common_logic";

export class FlowStoredLogic {
    async querysList(param) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("menber_store_page"),
            data: param,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    // member_store_addPC
    /**手动储值 
     *  @param memberManagerID 会员id
     *  @param topUpMoney  金额
     *  @param pay 4现金支付 支付方式
     *  @param  remark 备注
     *  @param  storedType 来源3
    */
    async addStore(param) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("member_store_addPC"),
            data: param,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }

}