import React from "react";
import { autoRem } from "../../global.style";
import { Info } from "../login";
import styles from "../login/style/index.module.scss";
import { Form } from "./view/form";
import { useHistory } from "react-router-dom";
import { ApiCon } from "../../agent/logic/ApiCon";

 const Register = () => {
    const his = useHistory();
    //代理商id
    let id: string = his.location.search.match(/agentID=(.*)&?/)?.[1];

    React.useEffect(() => {
        //有id的话就验证代理商
        if (id) {
            ApiCon.AgentCheck(id).catch((e) => {
                // 代理商验证错误，直接跳转到错误页面
                his.replace(`/error?alert=${e}`);
            });
        }
        return()=>{
            
        }
    }, []);

    return (
        <div className={styles.root_c}>
            <div className={styles.form_c}>
                <div className={styles.form}>
                    <div className={styles.logo_c}>
                        <div className={styles.logo}>
                        </div>
                    </div>

                    <div className={styles.title_c}>
                        <span>用户注册</span>
                    </div>

                    <div style={{ marginTop: autoRem(0) }}>
                        <Form />
                    </div>

                    <Info style={{ top: "36.65rem" }} />
                </div>
            </div>
        </div>
    )
}
export default Register;