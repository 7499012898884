import React, { useContext, useEffect } from "react";
import { Form as FormAntd, message } from "antd";
import { Layer } from "../../../../component/layer/layer";
import { InventoryGoodsFormBasic } from "./form_basic";
import { Button } from "../../../../component/button";
// import { outStockFormAction } from "../../action/out-stock/out_stock_form_action";
import { InventoryGoodsFormStore } from "../../store/withdraw/inventory_goods_form_store";
import { inventoryAction } from "../../action/withdraw/inventory_action";
import { inventoryGoodsFormAction } from "../../action/withdraw/inventory_goods_form_action";
import { useBoolean } from "ahooks";

interface IForm {
  children?: any;
  action: any;
  close: (hasUpdate?: boolean) => void;
  id?: string;
  subId: string;
  method?: string;
  audit: boolean;
  item;
}
export function Form({ close, method, id, subId, item, audit }: IForm) {
  const [form] = FormAntd.useForm();

  const { state, dispatch } = useContext(InventoryGoodsFormStore);
  const [loading, loadFunc] = useBoolean(false)
  const action = inventoryGoodsFormAction;

  useEffect(() => {
    // action.init({ state: state, dispatch, id, form, method });

    return () => {};
  }, []);

  const title = () => {
    if ("Create" === method) {
      return "预览提现记录";
    } else if ("Edit" === method) {
      return "审核提现";
    } else {
      return "预览提现记录";
    }
  };

  return (
    <FormAntd form={form}>
      <Layer layerWith={2} onClick={() => {}}>
        <Layer.Title onClick={() => close()}>{title()}</Layer.Title>
        <Layer.Body>
          <InventoryGoodsFormBasic
            disabled={!(method == "Edit")}
            updateFormField={(field, value = "") => {
              form.setFieldsValue({
                [field]: value,
              });
            }}
            data={item}
            close={() => close(true)}
          />
        </Layer.Body>
        <Layer.Foot
          borderStyle={title() == "预览提现记录" ? { border: "none" } : {}}
        >
          {"Edit" == method && audit && item.auditStatus == 1 && (
            <Button
              type="primary"
              color="Yellow"
              style={{ marginRight: "1.25rem" }}
              onClick={async () => {
                let r = false;
                if(loading){
                  message.loading('正在提交数据')
                  return false
              }
              loadFunc.setTrue()
                r = await action.aduit({ id, Ispasses: true }, state);
                loadFunc.setFalse()
                close(r);
                // }
              }}
            >
              审核通过
            </Button>
          )}
          {"Edit" == method && audit && item.auditStatus == 1 && (
            <Button
              type="primary"
              color="Red"
              onClick={async () => {
                // await form.validateFields();
                let formV = await form.validateFields();
                if(loading){
                  message.loading('正在提交数据')
                  return false
              }
                let r = false;
                loadFunc.setTrue()
                r = await action.aduit({ id, Ispasses: false }, state);
                loadFunc.setFalse()
                if (r) {
                  close(true);
                }
              }}
            >
              审核拒绝
            </Button>
          )}
        </Layer.Foot>
      </Layer>
    </FormAntd>
  );
}
