import styles from "./style/index.module.scss";
import {
  advimg,
  advimg_s,
  fuwenben,
  fuwenben_s,
  blank,
  blank_s,
  category,
  category_s,
  chuchuang,
  chuchuang_s,
  fenge,
  fenge_s,
  requ,
  requ_s,
  shangpin,
  shangpin_s,
  video,
  video_s,
  txtlink,
  txtlink_s,
  flashsale,
  flashsale_s,
} from "./../../component/variable";
import React, { useEffect, useState, useContext, useRef } from "react";
import { Layout } from "../../component/layout";
import { TabsStyle, TabList, Tab, TabPanel } from "../../component/react-tabs";
import { Adv } from "./view/adv";
import { Container } from "./view/container";
import { Hots } from "./view/hots";
import { TopicStore, TopicStoreProvider } from "./store/topic";
import { Editor } from "./view/editor";
import { topicActions } from "./action/topic_ation";
import { Search } from "./view/search";
import { Navimg } from "./view/navigation";
import { Goods } from "./view/goods";
import { FlashSale } from "./view/flashsale";
import { ETopicType } from "./enum/ETopicType";
import { Shopwindow } from "./view/shopwindow";
import { Textlink } from "./view/textlink";
import { Divider } from "./view/divider";
import { Blank } from "./view/blank";
import { Video } from "./view/video";
import { AdvOne } from "./view/advOne";
import { DrageList } from "./com/dragList";
import { ElementTransform } from "./com/trasform";
import { FileStoreProvider, FileStore } from "./../commodity/store/file_store";
import {
  DataTableStoreProvider,
  DataTableStore,
} from "./../../component/table/store/data_table_store";
import { TopicDataAction } from "./action/TopicDataAction";
import { ApiCon } from "./com/apiCon";
import { message } from "antd";
import { autoRem } from "../../global.style";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { Modal } from "../../component/modal";
import { Input } from "../../component/input";
import { Button } from "../../component/button";
import { useBoolean } from "ahooks";
import img_back from "../../assets/images/mini/icon-arrow-12-12.png";
import { PreSale } from "./view/presale";
import { MarketingPresale_logic } from "../../logic/market/MarketingPresale_logic";
interface ICom {
  /** 类型 */
  type: ETopicType;

  /** 数据 */
  data: any;
  /** 数据提取方法 */
  extractDataF?: Function;
}
/** 组件控制类型 */
export type comConType = "show" | "ctrl";

/**
 * 获取组件
 * @param v
 * @param id
 * @param con
 */
function getCom(v: ICom, id: number, con: comConType = "show") {
  switch (v.type) {
    case ETopicType.advimg:
      return <Adv id={id} con={con} />;
    case ETopicType.editor:
      return <Editor id={id} con={con} />;
    case ETopicType.search:
      return <Search id={id} con={con} />;
    case ETopicType.navimg:
      return <Navimg id={id} con={con} />;
    case ETopicType.goods:
      return (
        <FileStoreProvider>
          <Goods id={id} con={con} />
        </FileStoreProvider>
      );
    case ETopicType.flashsale:
      return <FlashSale id={id} con={con} />;
    case ETopicType.shopwindow:
      return <Shopwindow id={id} con={con} />;
    case ETopicType.textlink:
      return <Textlink id={id} con={con} />;
    case ETopicType.divider:
      return <Divider id={id} con={con} />;
    case ETopicType.blank:
      return <Blank id={id} con={con} />;
    case ETopicType.video:
      return <Video id={id} con={con} />;
    case ETopicType.hots:
      return <Hots id={id} con={con} />;
    case ETopicType.adbannerone:
      return (
        <DataTableStoreProvider>
          {" "}
          <AdvOne id={id} con={con} />{" "}
        </DataTableStoreProvider>
      );
    case ETopicType.presale:
      return <PreSale id={id} con={con} />;
  }
}

function Screen() {
  const { state, dispatch } = useContext(TopicStore);
  const [ctrlTop, setCtrlTop] = useState(0);
  const ref = useRef();
  const parage = useParams();
  const location = useLocation();

  let activeComTop: number = 0;

  useEffect(() => {
    setCtrlTop(
      activeComTop - ElementTransform.getElementTransform(ref.current).pos.y
    );
  });
  useEffect(() => {
    //如果当前没有未激活的组件就算了
    if (state.activeId == -1) {
      return;
    }
    //滚动到视图，一定要下一次任务执行，否则滚动不到最底部
    setTimeout(() => {
      window.scrollTo({
        top: document.documentElement.scrollTop + activeComTop - 100,
        behavior: "smooth",
      });
    }, 0);
  }, [state.activeId]);

  useEffect(() => {
    //获取数据
    // //console.log(parage, location);
  });

  return (
    <div className={styles.screen}>
      <div className={styles.phone} ref={ref}>
        {/* <AdvStoreProvider> */}
        <div className={styles.header}>
          <i></i>
          <div></div>
        </div>
        <div className={styles.phone_main}>
          <div className={styles.content_right}></div>
          <div className={styles.content}>
            {/* <div className={styles.screen_page_title}>         </div> */}
            {state && state.comList && (
              <DrageList
                list={state.comList}
                onDragEnd={(list, si, ei) => {
                  //重置组件列表
                  dispatch({
                    type: "RESET_COM",
                    payload: list,
                  });
                  //重置当前激活的id
                  dispatch({
                    type: "ACTIVE",
                    payload: ei,
                  });
                }}
              >
                {(v, i) => {
                  return (
                    <Container
                      key={i}
                      id={i}
                      setActiveComTop={(n) => {
                        activeComTop = n;
                      }}
                    >
                      {v && getCom(v, i, "show")}
                    </Container>
                  );
                }}
              </DrageList>
            )}
          </div>
          <div className={styles.content_right}></div>
        </div>

        {/* <button></button> */}
        <div className={styles.phone_bottom}></div>
        {/* </AdvStoreProvider> */}
      </div>
      {state.activeId >= 0 && (
        <div
          className={styles.ctrl}
          style={{
            marginTop: ctrlTop + "px",
          }}
        >
          {state.comList.length > 0 &&
            getCom(state.comList[state.activeId], state.activeId, "ctrl")}
        </div>
      )}
    </div>
  );
}

function Menu({}) {
  const action = topicActions;
  const { state, dispatch } = useContext(TopicStore);
  const addCon = (type) => {
    action.add({ dispatch, type });
  };
  const menus = [
    {
      type: ETopicType.editor,
      name: "富文本",
      icon: fuwenben,
      icon_s: fuwenben_s,
    },
    {
      type: ETopicType.adbannerone,
      name: "广告横幅",
      icon: category,
      icon_s: category_s,
    },
    {
      type: ETopicType.advimg,
      name: "广告图片",
      icon: advimg,
      icon_s: advimg_s,
    },
    {
      type: ETopicType.navimg,
      name: "图片导航",
      icon: fuwenben,
      icon_s: fuwenben_s,
    },
    {
      type: ETopicType.search,
      name: "搜索",
      icon: category,
      icon_s: category_s,
    },
    {
      type: ETopicType.goods,
      name: "商品",
      icon: shangpin,
      icon_s: shangpin_s,
    },
    {
      type: ETopicType.flashsale,
      name: "秒杀活动",
      icon: flashsale,
      icon_s: flashsale_s,
    },
    {
      type: ETopicType.shopwindow,
      name: "橱窗",
      icon: chuchuang,
      icon_s: chuchuang_s,
    },
    {
      type: ETopicType.textlink,
      name: "文字链接",
      icon: txtlink,
      icon_s: txtlink_s,
    },
    { type: ETopicType.divider, name: "分割线", icon: fenge, icon_s: fenge_s },
    { type: ETopicType.blank, name: "辅助空白", icon: blank, icon_s: blank_s },
    { type: ETopicType.video, name: "视频", icon: video, icon_s: video_s },
    { type: ETopicType.hots, name: "热区", icon: requ, icon_s: requ_s },
  ];
  const [selected, setSelect] = useState(null);
  const clientHeight = document.body.clientHeight;
  let menuHeight = clientHeight / 12;
  if (document.body.clientWidth >= 1346) {
    menuHeight = clientHeight / 13;
  }
  return (
    <div className={styles.menu}>
      <div
        className={styles.item}
        style={{
          backgroundColor: "#02ca74",
          borderRadius: "20px 20px 0px 0px",
          marginTop: 0,
        }}
      ></div>
      {menus.map((menu, i) => {
        return (
          <div
            className={`${styles.item} ${styles.menulink} ${
              selected == i ? styles.active : ""
            }`}
            key={i}
            onClick={() => {
              addCon(`${menu.type}`);
              setSelect(i);
            }}
          >
            <i>
              <img className={styles.icon} src={menu.icon} alt={menu.name} />
              <img className={styles.iconactive} src={menu.icon_s} />
            </i>
            {menu.name}
          </div>
        );
      })}
    </div>
  );
}

function Bottom({ record }) {
  let TempID = null;
  let title = "";
  if (record) {
    TempID = record.id;
    title = record.title;
  } else {
    let id = localStorage.getItem("tempID");
    let t = localStorage.getItem("fitmentName");
    if (id) {
      TempID = id;
      title = t;
    }
  }
  const topicStoreState = useContext(TopicStore);
  const [visibleModal, modalFunc] = useBoolean(false);
  const [name, setName] = useState(title);
  const handleOk = (e) => {
    if (name == "") {
      message.warn("模板名称不能为空");
    } else {
      saveData(name);
    }
  };
  const history = useHistory();
  /** 保存数据 */
  const saveData = (mubanName) => {
    //收集数据
    let _comList = [...topicStoreState.state.comList];
    if (_comList.length == 0) {
      message.warn("请至少添加一个内容!");
      return;
    }

    const _f = async () => {
      let _comData: {
        type: ETopicType;
        data: any;
      }[] = [];
      let _goodsList = [];
      for (let index = 0, l = _comList.length; index < l; index++) {
        let _data: any;
        let _id: number = index;
        if (TopicDataAction.getEF(topicStoreState, index)) {
          _data = await TopicDataAction.getEF(topicStoreState, index)().catch(
            (mes) => {
              //包装一下错误消息
              throw {
                mes: mes,
                id: _id,
              };
            }
          );
        } else {
          _data = _comList[index].data;
        }

        _comData.push({
          type: _comList[index].type,
          data: _data,
        });
      }
      return _comData;
    };

    _f()
      .then((dataList) => {
        //上传数据
        let goods = TopicDataAction.getGoods(dataList);
        console.log("页面数据", dataList);

        if (TempID != null) {
          ApiCon.UpdateFitmentSetting(
            TempID,
            JSON.stringify({ ids: goods, dataList: dataList }),
            name
          )
            .then((data) => {
              message.success("修改成功");
              modalFunc.setFalse();
              setTimeout(() => {
                history.push("/topic");
              }, 500);
            })
            .catch((err) => {
              setName(title);
              message.error(err);
            });
        } else {
          ApiCon.AddFitmentSetting(
            undefined,
            JSON.stringify({ ids: goods, dataList: dataList }),
            mubanName
          )
            .then((data) => {
              message.success("保存成功");
              modalFunc.setFalse();
              setTimeout(() => {
                history.push("/topic");
              }, 500);
            })
            .catch((err) => {
              setName(title);
              message.error(err);
            });
        }
      })
      .catch(({ mes, id }) => {
        //提示用户
        alert(mes);
        //并定位到这个组件
        topicStoreState.dispatch({
          type: "ACTIVE",
          payload: id,
        });
      });
  };

  return (
    <div className={styles.bottom}>
      {/* <div style={{float:'left', cursor:'pointer'}} ><img className={styles.back_img} src={img_back}/>返回</div>   */}
      <Button type="primary" color="Red" onClick={() => modalFunc.setTrue()}>
        保存
      </Button>
      <Button
        type="primary"
        color="Yellow"
        onClick={() => history.goBack()}
        style={{}}
      >
        返回
      </Button>

      <Modal
        title="模板名称设置"
        visible={visibleModal}
        onOk={handleOk}
        onCancel={() => {
          modalFunc.setFalse();
        }}
        width={600}
        cancelText="取消"
        okText="确定"
      >
        <div className={styles.root}>
          <Input
            maxLength={20}
            placeholder="请输入模板名称"
            defaultValue={name}
            onBlur={(e) => setName(e.target.value)}
          />
        </div>
      </Modal>
    </div>
  );
}

export function TopicBlank() {
  const [dataList, setDataList] = useState([]);
  const [list, setList] = useState([]);
  const [activeId, setId] = useState(0);

  const initList = (index) => {
    const initlist = [];

    setList(initlist);
  };

  const scrollToAnchor = () => {
    let anchorElement = document.getElementById("selected");
    // 如果对应id的锚点存在，就跳转到锚点
    if (anchorElement) {
      anchorElement.scrollIntoView({ block: "start", behavior: "smooth" });
    }
    // }
  };
  interface IRecord {
    title: string;
    id: string;
  }
  const r = useLocation<IRecord>().state;
  const topicStoreState = useContext(TopicStore);
  const checkPage = async (page) => {
    const checkResultGoods = page.ids.goods
      ? (await TopicDataAction.checkGoods(page.ids.goods)) || []
      : [];
    const checkResultScals = page.ids.scales
      ? (await TopicDataAction.checkSeckill(page.ids.scales)) || []
      : [];
    const checkResultPresale = page.ids.presales
      ? (await TopicDataAction.checkPresale(page.ids.presales)) || []
      : [];

    const r =
      page.ids.presales &&
      page.ids.presales.length > 0 &&
      (await MarketingPresale_logic.GetFirstMarkPresasle({
        ID: page.ids.presales[0],
      }));
    let item;
    if (r && r.data && r.data.statusCode == 200) {
      item = r.data.data;
    }
    let pagedata = [];
    console.log(page.dataList);
    for (let i = 0; i < page.dataList.length; i++) {
      let com = page.dataList[i];
      switch (com.type) {
        case "0": {
          //轮播，
          let list = [];
          com.data.datalist.map((item, index) => {
            let id;
            if (item.selectUrl.type == "商品") {
              checkResultGoods.length > 0 &&
                checkResultGoods.map((r) => {
                  if (r.id == item.selectUrl.key) {
                    if (r.isShow == 1) {
                      list.push({
                        ...item,
                        selectUrl: {
                          ...item.selectUrl,
                          ...r,
                          imgUrl: r.goodsImage,
                          title: r.goodsName,
                        },
                      });
                    }
                  }
                });
            }
            if (item.selectUrl.type == "限时购") {
              id = item.selectUrl.key.goodsID;
              checkResultScals.length > 0 &&
                checkResultScals.map((scal) => {
                  if (
                    scal.id == item.selectUrl.key.seckillID &&
                    scal.isShow == 1
                  ) {
                    checkResultGoods.length > 0 &&
                      checkResultGoods.map((r) => {
                        if (r.id == item.selectUrl.key.goodsID) {
                          if (r.isShow == 1) {
                            list.push({
                              ...item,
                              selectUrl: {
                                ...item.selectUrl,
                                ...r,
                                imgUrl: r.goodsImage,
                                title: r.goodsName,
                              },
                            });
                          }
                        }
                      });
                  }
                });
            }
            if (
              item.selectUrl.type == "优惠券" ||
              item.selectUrl.type == "分类"
            ) {
              list.push(item);
            }
          });
          let paged = {
            ...com.data,
            datalist: list,
          };
          if (list.length > 0) {
            pagedata.push({ ...com, data: { ...paged } });
          }
          break;
        }
        case "1":
        case "4":
        case "9":
        case "10":
        case "11": {
          pagedata.push({ ...com });
          break;
        }
        case "2": {
          let list = [];
          com.data.map((item, index) => {
            let id;
            if (item.selectUrl.type == "商品") {
              checkResultGoods.length > 0 &&
                checkResultGoods.map((r) => {
                  if (r.id == item.selectUrl.key) {
                    if (r.isShow == 1) {
                      list.push({
                        ...item,
                        selectUrl: {
                          ...item.selectUrl,
                          ...r,
                          imgUrl: r.goodsImage,
                          title: r.goodsName,
                        },
                      });
                    }
                  }
                });
            }
            if (item.selectUrl.type == "限时购") {
              checkResultScals.length > 0 &&
                checkResultScals.map((scal) => {
                  if (
                    scal.id == item.selectUrl.key.seckillID &&
                    scal.isShow == 1
                  ) {
                    checkResultGoods.length > 0 &&
                      checkResultGoods.map((r) => {
                        if (r.id == item.selectUrl.key.goodsID) {
                          if (r.isShow == 1) {
                            list.push({
                              ...item,
                              selectUrl: {
                                ...item.selectUrl,
                                ...r,
                                imgUrl: r.goodsImage,
                                title: r.goodsName,
                              },
                            });
                          }
                        }
                      });
                  }
                });
            }
            if (
              item.selectUrl.type == "优惠券" ||
              item.selectUrl.type == "分类"
            ) {
              list.push(item);
            }
          });
          let paged = {
            list,
          };
          if (list.length > 0) {
            pagedata.push({ ...com, data: list });
          }
          console.log(pagedata);
          break;
        }
        case "5": {
          //商品
          let list = [];
          com.data.datalist.length > 0 &&
            com.data.datalist.map((item, index) => {
              checkResultGoods.length > 0 &&
                checkResultGoods.map((r) => {
                  if (r.id == item.id && r.isShow == 1) {
                    list.push({ ...r, ...item });
                  }
                });
            });
          let paged = {
            ...com.data,
            datalist: list,
          };
          if (list.length > 0) {
            pagedata.push({ ...com, data: { ...paged } });
          }
          break;
        }
        case "6": {
          //限时购
          let list = [];
          com.data.length > 0 &&
            com.data.map((item, index) => {
              checkResultScals.length > 0 &&
                checkResultScals.map((r) => {
                  if (r.id == item.selectUrl.id && r.isShow == 1) {
                    let g = item.selectUrl;
                    let arr = [];
                    r.goods.map((rg) => {
                      if (rg.isShow == 1) {
                        arr.push({
                          ...rg,
                          ...rg.spInfo,
                          goodsSep: rg.spInfo,
                          goodsImage: rg.spInfo.specificationImage
                            ? rg.spInfo.specificationImage
                            : rg.goodsImage,
                        });
                      }
                    });
                    g.goodsList = arr;
                    list.push({ ...item, selectUrl: g });
                  }
                });
            });
          let paged = {
            ...com,
            data: list,
          };
          if (list.length > 0) {
            pagedata.push(paged);
          }
          break;
        }

        case "3": {
          //预售
          // let goods = []
          if (com.data.linkType == 1 && com.data.linkUrl == "预售活动") {
            checkResultPresale.length > 0 &&
              checkResultPresale.map((r) => {
                if (r.id == com.data.ID && r.isShow == 1) {
                  pagedata.push(com);
                  // r.goods.length>0&&r.goods.map(g=>{
                  //     item.presalegoods&&item.presalegoods.length>0&&item.presalegoods.map(pg=>{
                  //            if(g.goodsSpID == pg.sepID && g.isShow == 1){
                  //             goods.push(pg)
                  //            }
                  //     })
                  // })
                }
              });
            // console.log(goods)
          } else {
            pagedata.push(com);
          }
          break;
        }

        case "7": //橱窗
        case "8": {
          //文字链接
          let list = [];
          com.data.length > 0 &&
            com.data.map((item) => {
              if (item.selectUrl.type == "商品") {
                checkResultGoods.length > 0 &&
                  checkResultGoods.map((r) => {
                    if (r.id == item.selectUrl.key && r.isShow == 1) {
                      list.push({
                        ...item,
                        selectUrl: {
                          ...item.selectUrl,
                          ...r,
                          imgUrl: r.goodsImage,
                          title: r.goodsName,
                        },
                      });
                    }
                  });
              }
              if (item.selectUrl.type == "限时购") {
                checkResultScals.length > 0 &&
                  checkResultScals.map((scal) => {
                    if (
                      scal.id == item.selectUrl.key.seckillID &&
                      scal.isShow == 1
                    ) {
                      checkResultGoods.length > 0 &&
                        checkResultGoods.map((r) => {
                          if (r.id == item.selectUrl.key.goodsID) {
                            if (r.isShow == 1) {
                              list.push({
                                ...item,
                                selectUrl: {
                                  ...item.selectUrl,
                                  ...r,
                                  imgUrl: r.goodsImage,
                                  title: r.goodsName,
                                },
                              });
                            }
                          }
                        });
                    }
                  });
              }
              if (
                item.selectUrl.type == "优惠券" ||
                item.selectUrl.type == "分类"
              ) {
                list.push(item);
              }
            });
          let paged = {
            ...com,
            data: list,
          };
          if (com.type == "8" && list.length > 0) {
            pagedata.push(paged);
          }
          if (com.type == "7" && list.length == 3) {
            pagedata.push(paged);
          }
          break;
        }
        case "12": {
          //热区
          let list = [];
          com.data.precinctList.length > 0 &&
            com.data.precinctList.map((item, index) => {
              if (item.url.type == "商品") {
                checkResultGoods.length > 0 &&
                  checkResultGoods.map((r) => {
                    if (r.id == item.url.key && r.isShow == 1) {
                      list.push(item);
                    }
                  });
              }
              if (item.url.type == "限时购") {
                checkResultScals.length > 0 &&
                  checkResultScals.map((scal) => {
                    if (scal.id == item.url.key.seckillID && scal.isShow == 1) {
                      checkResultGoods.length > 0 &&
                        checkResultGoods.map((r) => {
                          if (r.id == item.url.key.goodsID) {
                            if (r.isShow == 1) {
                              list.push(item);
                            }
                          }
                        });
                    }
                  });
              }
              if (item.url.type == "优惠券" || item.url.type == "分类") {
                list.push(item);
              }
            });
          let data = com.data;
          data.precinctList = list;
          let paged = {
            ...com,
            data: data,
          };
          if (list.length > 0) {
            pagedata.push(paged);
          }
          break;
        }
      }
    }
    localStorage.setItem("fitmentSetting", JSON.stringify(pagedata));
    TopicDataAction.setPage(topicStoreState, pagedata);

    return pagedata;
  };
  useEffect(() => {
    TopicDataAction.setPage(topicStoreState, []);
    if (r) {
      ApiCon.GetSettingJson(r.id)
        .then((data) => {
          let pagedata = JSON.parse(data.jsonText);
          let list = pagedata;
          if (pagedata.dataList) {
            list = pagedata.dataList;
          }
          pagedata.ids && checkPage(pagedata);
          localStorage.setItem("fitmentName", data.title);
          localStorage.setItem("tempID", r.id);
        })
        .catch((err) => {
          message.error("获取数据失败");
        });
    } else if (localStorage.getItem("fitmentSetting")) {
      let pagedata = localStorage.getItem("fitmentSetting");
      TopicDataAction.setPage(topicStoreState, JSON.parse(pagedata));
    }
  }, []);

  useEffect(() => {
    initList(activeId);
    return () => {
      localStorage.removeItem("fitmentSetting");
      localStorage.removeItem("fitmentName");
      localStorage.removeItem("tempID");
    };
  }, [activeId, dataList]);
  let height = document.body.clientHeight - 171;
  return (
    // <Layout fullWidth={true} >
    // <div style={{ height: height + "px", overflowY: "auto" }}>
    <div style={{ height: height + "px", overflowY: "scroll" }}>
      <Screen />
      <Menu />
      <Bottom record={r} />
    </div>
    // </Layout>
  );
}
// export function
