import React, { useContext, useEffect, useState } from "react";
import { Alert, Col, Form as FormAntd, Row, Tag } from "antd";
import  { ColumnsType } from "antd/lib/table";
import { Table } from "../../../../component/table";
import { Layer } from "../../../../component/layer/layer";
import { PurchaseFormBasic } from "./form_basic";
import { Button } from "../../../../component/button";
import { PurchaseStore,  } from "../../store/purchase/purchase_store";
import { purchaseAction } from "../../action/purchase/purchase_action";
import { useBoolean } from "ahooks";
import { Modal } from "../../../../component/modal";
import { AuditStatus, SendStatus } from "../../enum";

interface IForm {
    children?: any;
    close: (hasUpdate?: boolean) => void;
    id: string;
    method?: string;
    item;
}
export function Reciept_Form ({close, id, method}: IForm){
    const { state, dispatch } = useContext(PurchaseStore);
    const [loading, loadingFunc] = useBoolean(false)
    const [detailList, setDetailList] = useState([])
    const action = purchaseAction;
    const [showDetail, showDetailFunc] = useBoolean(false)
    const [rowItem,setRow] = useState(null)
    useEffect(() => {
        action.getRecieptList({dispatch, purchaseID: id });
        return () => {
        }
    }, []);
    // 收货单
    const DataTable = () => {
      
        const{ recieptList } = state
        const columns: ColumnsType = [
            {
                title: "发货单号",
                dataIndex: "invoiceNumber",
                key: "1",
                align: "center",
                width: 170,
              },
              // {
              //   title: "采购单号",
              //   dataIndex: "purchaseNumber",
              //   key: "2",
              //   align: "center",
              //   width: 170,
              // },
              {
                title: "发货状态",
                dataIndex: "status",
                key: "10",
                align: "center",
                width: 120,
                render: (v) => (
                  <>
                   {v == 1&& <Tag color="blue">待发货</Tag>}
                   {v == 2&& <Tag color="warning">已发货</Tag>}
                   {v == 3&& <Tag color="success">已收货</Tag>}
                  </>
                ),
              }, 
              {
                title: "商品总数",
                dataIndex: "totalNum",
                key: "3",
                align: "center",
                width: 120,
              },
              {
                title: "总金额",
                dataIndex: "totalAmount",
                key: "4",
                align: "center",
                width: 100,
              },
              {
                title: "已结算金额",
                dataIndex: "settlementAmount",
                key: "5",
                align: "center",
                width: 100,
              },
              {
                title: "未结算金额",
                dataIndex: "unsettledAmount",
                key: "6",
                align: "center",
                width: 100,
              },
              {
                title: "承运机构",
                dataIndex: "carrierOrganization",
                key: "7",
                align: "center",
                width: 100,
              },
              {
                title: "运单号",
                dataIndex: "waybillNumber",
                key: "8",
                align: "center",
                width: 100,
              },
              {
                title: "运费",
                dataIndex: "freight",
                key: "9",
                align: "center",
                width: 100,
              },

          ];
        const handleRowClick = async (record) => {
            setRow(record)
            const r = await action.getRecieptGoodsList(record.id) 
            const list = r.invoiceDetails? r.invoiceDetails|| [] : []
            setDetailList(list)
            showDetailFunc.setTrue()
        }
       return   <Table
                    scroll={{y: 600}}
                    pagination={false}
                    columns={columns}
                    dataSource={recieptList}
                    onRow={record => {
                        return {
                          onClick: (e)=>handleRowClick(record), // 点击行
                        };
                      }}
                   
        />
    }
    // 收货单商品列表
    const DetailTable = () => {
        const columns: ColumnsType = [
            {
                title: "商品名称",
                dataIndex: "goodsName",
                key: "1",
                align: "center",
                width: 170,
              },
              {
                title: "规格单位",
                dataIndex: "specUnit",
                key: "2",
                align: "center",
                width: 100,
              },
              {
                title: "商品单价",
                dataIndex: "goodsPrice",
                key: "3",
                align: "center",
                width: 100,
              },
              {
                title: "商品总价",
                dataIndex: "totalAmout",
                key: "4",
                align: "center",
                width: 100,
              },
              {
                title: "发货数量",
                dataIndex: "goodsNum",
                key: "5",
                align: "center",
                width: 100,
              },
           

          ];
          const [confirmLoading , confirmLoadingFunc] = useBoolean(false)
        const  handleOk = async () => {
            let invoiceID  = rowItem.id
            confirmLoadingFunc.setTrue()
                const r = await action.confirmReciept(invoiceID)
                if(r){
                    confirmLoadingFunc.setFalse()
                    showDetailFunc.setFalse()
                    action.getRecieptList({dispatch, purchaseID: id });
                }else{
                    confirmLoadingFunc.setFalse()
                }
        }
       return <Modal 
       title={rowItem&&rowItem.status == 2?"已发货商品":'已收货商品'}
       visible={showDetail}
       width={800}
       footer={
         <>
           <Button
             type="primary"
             style={{
               backgroundColor: "#f7ba00",
               borderColor: "#f7ba00",
               color: "white",
             }}
             onClick={()=>{
                showDetailFunc.setFalse()
             }}
             loading={confirmLoading}
           >
             取消
           </Button>
         {rowItem && rowItem.status == 2 && ( <Button type="primary" onClick={handleOk}  loading={confirmLoading}>
             确认收货
           </Button>)}
         </>
       }
       >
        <Table         
            columns={columns}
            dataSource={detailList}
            scroll={{y: 500}}
            pagination={false}
            />

       </Modal>
    }
    return<>
          <Layer layerWith={4} >
                <Layer.Title onClick={() => close()}>查看发货单</Layer.Title>
                <Layer.Body>     
               <div style={{padding: '0 10px'}}>
               <Alert
                message="！点击行查看商品列表和进行收货确认"
                    type="warning"
                    showIcon
                    closable
                    />        
                    <DataTable/>
                    {showDetail && (<DetailTable/>)}
               </div>
                </Layer.Body>
                <Layer.Foot >
                    {
                        (method == 'Create' || method == 'Edit') && (
                            <Button type="primary" color="Red" style={{ marginRight: "1.25rem" }} loading={loading} disabled={loading} onClick={async () => {
                                    let r = false;
                                    
                                    if (r) {
                                        // action.clearTableList({ dispatch });

                                        close(true);
                                    }
                                }
                            }>确认收货</Button>
                        )
                    }
                   <Button
                        type="primary"
                        color="Yellow"
                        onClick={() => { 
                            close(true)}}
                    >
                        返回
                    </Button>
                    </Layer.Foot>
                    </Layer>
    </>
}