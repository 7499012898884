import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Form as FormAntd,
  DatePicker,
  message,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useState } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { FormSubtitle } from "../../../commodity/view/form_subtitle";
import styles from "../../style/inventory_goods_formbasic.module.scss";
import moment from "moment";
import img_del from "../../../../assets/images/shanchu.png";
import { warehousingformAction } from "../../action/in-stock/warehousing_form_action";
import { useBoolean } from "ahooks";
import { ChooseGoods } from "../../../../component/choose-goods";
import { Table } from "../../../../component/table";
import { ChooseShop } from "../../../../component/choose-shop";
import { WarehousingFormStore } from "../../store/in-stock/warehousing_form_store";
import { autoRem } from "../../../../global.style";
import { count } from "console";
import { TableExpand } from "../../../../component/table-expand";
import { Tools } from "../../../../config";
import { NumberTool } from "../../../../tool/numberTool";

const { Option } = Select;

export function DataTable({ dataList, disabled }) {
  const { dispatch, state } = useContext(WarehousingFormStore);
  const [current, setCurrent] = useState(1);
  const [pageSize, setSize] = useState(10);
  const action = warehousingformAction;
  const disabledDate = (current) => {
    return current > moment().startOf("second");
  };
  const byStringGetMoment = (string) => {
    if (!string) {
      return undefined;
    }
    return moment(string);
  };
  const columns: ColumnsType = [
    {
      title: "操作",
      dataIndex: "serial",
      key: "1",
      align: "center",
      width: 50,
      render: (v, r, i) => (
        <div>
          <img
            src={img_del}
            style={{ width: 20 }}
            onClick={() => {
              if (disabled) return;
              action.delTableItem(dispatch, v);
            }}
          />
        </div>
      ),
    },
    {
      title: "商品编码",
      dataIndex: "goodsCode",
      key: "2",
      align: "center",
    },
    {
      title: "商品名称",
      dataIndex: "goodsName",
      key: "3",
      align: "center",
    },
    {
      title: "库存单位",
      dataIndex: "unitName",
      key: "4",
      align: "center",
    },
    // {
    //   title: "商品规格",
    //   dataIndex: "",
    //   key: "7",
    //   align: "center",
    //   width: 120,
    //   render: (e, r, i) => {
    //     return <div>****</div>;
    //   },
    // },
    {
      title: "入库单价",
      dataIndex: "inStockUnitPrice",
      key: "7",
      align: "center",
      width: 120,
      render: (v, r: any, i) => {
        return (
          <Input
            type="number"
            style={{ width: 100 }}
            value={v}
            disabled={disabled}
            onChange={(e) =>
              action.changeTableItem({
                dispatch,
                key: "inStockUnitPrice",
                value: e.target.value,
                index: i + (current - 1) * pageSize,
              })
            }
          />
        );
        // return (
        //   <div>
        //     {NumberTool.toAmount(
        //       r.goodsSpecifications.reduce((a, b) => {
        //         return a + (b.inStockUnitPrice || 0);
        //       }, 0)
        //     )}
        //   </div>
        // );
      },
    },
    {
      title: "入库数量",
      dataIndex: "inStockNumber",
      key: "8",
      align: "center",
      width: 120,
      render: (v, r: any, i) => {
        return (
          <Input
            type="number"
            disabled={disabled}
            style={{ width: 100 }}
            value={v}
            onChange={(e) => {
              action.changeTableItem({
                dispatch,
                key: "inStockNumber",
                value: e.target.value,
                index: i + (current - 1) * pageSize,
              });
            }}
          />
        );
        // return (
        //   <div>
        //     {r.goodsSpecifications.reduce((a, b) => {
        //       return a + (b.inStockNumber || 0);
        //     }, 0)}
        //   </div>
        // );
      },
    },
    {
      title: "入库金额",
      //   dataIndex: "inStockPrice",
      key: "8",
      align: "center",
      width: 120,
      render: (e, r: any, i) => {
        return (
          <div>
            ￥{NumberTool.toAmount(r.inStockNumber * r.inStockUnitPrice)}
          </div>
        );
      },
    },
    {
      title: "生产日期",
      //   dataIndex: "inStockPrice",
      key: "9",
      align: "center",
      width: 150,
      render: (e, r: any, i) => {
        return (
          <div>
            <DatePicker
              style={{ width: "90%" }}
              disabled={disabled}
              disabledDate={disabledDate}
              value={byStringGetMoment(r.manufactureDate)}
              placeholder="请选择生产日期"
              onChange={(date, dateString) => {
                action.changeTableItem({
                  dispatch,
                  key: "manufactureDate",
                  value: dateString,
                  index: i + (current - 1) * pageSize,
                });
              }}
            />
          </div>
        );
      },
    },
    {
      title: "保质期",
      //   dataIndex: "inStockPrice",
      key: "10",
      align: "center",
      width: 120,
      render: (e, r: any, i) => {
        return (
          <div>
            <Input
              style={{ width: "90%" }}
              min={1}
              disabled={disabled}
              value={r.shelfLifeDay}
              placeholder="请输入保质期"
              type="number"
              onChange={(e) => {
                action.changeTableItem({
                  dispatch,
                  key: "shelfLifeDay",
                  value: e.target.value,
                  index: i + (current - 1) * pageSize,
                });
              }}
            />
          </div>
        );
      },
    },
  ];
  const { formData } = state;
  return (
    <Table
      scroll={{ y: 275 }}
      dataSource={dataList}
      columns={columns}
      onChange={(pagination) => {
        setCurrent(pagination.current);
        setSize(pagination.pageSize);
      }}
      //   expandable={{
      //     expandedRowRender: (record) => {
      //       return record.goodsSpecifications.map((item) => {
      //         return (
      //           <GetSpecifications
      //             disabled={disabled}
      //             specifications={item}
      //             unitName={record.inStockUnit}
      //           />
      //         );
      //       });
      //     },
      //     rowExpandable: (record) => record.name !== "Not Expandable",
      //   }}
    />
  );
}

export function InventoryGoodsFormBasic({ disabled, updateFormField }) {
  const [shopState, shopFunc] = useBoolean(false);
  const [goodsState, goodsFunc] = useBoolean(false);
  const disabledDate = (current) => {
    return current > moment().startOf("second");
  };
  const byStringGetMoment = (string) => {
    if (!string) {
      return undefined;
    }
    return moment(string);
  };
  const { state, dispatch } = useContext(WarehousingFormStore);
  const { formData } = state;

  const action = warehousingformAction;

  /** 获取总结数量 规格 */
  // const allNumber = () => {
  //     return state.tableList.reduce((a, b) => {
  //         let p = b.goodsSpecifications.reduce((a, b) => {
  //             return NumberTool.add(a, b.inStockNumber || 0, 2);
  //         }, 0);
  //         return NumberTool.add(a, p, 2);
  //     }, 0);
  // };
  /** 获取总结数量 无规格 */
  const allNumber = () => {
    return state.tableList.reduce((a, b) => {
      return NumberTool.add(a, b.inStockNumber || 0, 3);
    }, 0);
  };
  /** 获取总结金额 */
  const allAmount = () => {
    return state.tableList.reduce((a, b) => {
      let p = NumberTool.multiply(
        b.inStockNumber || 0,
        b.inStockUnitPrice || 0,
        2
      );

      return NumberTool.add(a, p, 2);
    }, 0);
  };
  let height = document.body.clientHeight - 188 + 26;
  return (
    <div className={styles.root} style={{ height: height + "px" }}>
      <div className={styles.content}>
        <FormSubtitle>基础信息</FormSubtitle>

        <div
          style={{
            margin: "" + autoRem(1.9375) + " " + autoRem(1.25) + " 0 0",
          }}
        >
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={6}>
              <Form.Item name="" label="制单人" labelCol={{ span: 8 }}>
                <span>{formData.userName}</span>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="制单时间" labelCol={{ span: 8 }}>
                <span style={{ display: "block", minWidth: 200 }}>
                  {formData.date}
                </span>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item
                label="门店"
                name="in-shop"
                labelCol={{ span: 8 }}
                // initialValue={formData.shopName}
                rules={[
                  {
                    required: true,
                    message: "请选择门店",
                    validator: (
                      rule,
                      value,
                      callback: (error?: string) => void
                    ) => {
                      if (!formData.shopName) {
                        callback("请选择门店");
                      } else {
                        callback();
                      }
                    },
                  },
                ]}
              >
                {formData.shopName ? "" : ""}
                <Input
                  disabled={disabled}
                  value={formData.shopName}
                  autoComplete="off"
                  placeholder="请选择"
                  onClick={() => shopFunc.setTrue()}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="理由"
                name="reasonId"
                labelCol={{ span: 8 }}
                rules={[
                  {
                    required: true,
                    message: "请选择理由",
                    validator: (
                      rule,
                      value,
                      callback: (error?: string) => void
                    ) => {
                      if (!formData.reasonId) {
                        callback("请选择理由");
                      } else {
                        callback();
                      }
                    },
                  },
                ]}
              >
                {formData.reasonId ? "" : ""}
                <Select
                  disabled={disabled}
                  value={formData.reasonId}
                  allowClear
                  onChange={(v) => {
                    action.setFormData({ dispatch, key: "reasonId", value: v });
                    updateFormField("reasonId");
                  }}
                >
                  <Option value={1}>手动入库</Option>
                  <Option value={2}>退货入库</Option>
                  <Option value={3}>采购入库</Option>
                  {/* <Option value={4}>手动出库</Option>
                  <Option value={5}>销售出库</Option>
                  <Option value={6}>库存调整</Option>
                  <Option value={7}>商品试吃</Option>
                  <Option value={8}>日常报损</Option>
                  <Option value={9}>商品过期</Option>
                  <Option value={10}>领用</Option>
                  <Option value={11}>日常报溢</Option> */}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="备注" labelCol={{ span: 8 }}>
                <Input
                  maxLength={200}
                  disabled={disabled}
                  value={formData.remark}
                  autoComplete="off"
                  onChange={(v) =>
                    action.setFormData({
                      dispatch,
                      key: "remark",
                      value: v.target.value,
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <div style={{ marginTop: -20 }}>
          <FormSubtitle>商品信息</FormSubtitle>
        </div>

        <div
          style={{
            margin: "" + autoRem(1.9375) + " " + autoRem(1.25) + " 0 0",
          }}
        >
          <Row gutter={16} style={{ marginTop: 10 }}>
            <Col span={10}>
              <Form.Item label="快速添加" labelCol={{ span: 6 }}>
                <Input
                  autoFocus
                  allowClear
                  disabled={disabled}
                  placeholder="扫描商品条形码，快速添加"
                  value={state.code}
                  onPressEnter={async (e) => {
                    await action.getGoods({
                      id: e.currentTarget.value,
                      dispatch,
                      state,
                    });
                    dispatch({ type: "InputCode", payload: "" });
                  }}
                  onChange={(e) => {
                    dispatch({ type: "InputCode", payload: e.target.value });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <div style={{ marginTop: -20, minHeight: 20 }}>
          <ButtonPanel>
            <Button disabled={disabled} onClick={() => goodsFunc.setTrue()}>
              选择商品
            </Button>
          </ButtonPanel>
        </div>
        <div
          style={{
            width: "100%",
            marginTop: 20,
            marginBottom: 20,
            display: "flex",
          }}
        >
          {" "}
          <ButtonPanel>
            <Button   disabled={disabled}
              onClick={() => {
                if (state.tableList.length == 0) {
                  message.warning("请选择商品");
                } else {
                  if (formData.productionDate == "") {
                    message.warning("请选择生产日期");
                    return;
                  }
                  if (
                    formData.expirationDate == "" ||
                    formData.expirationDate == undefined
                  ) {
                    message.warning("请输入保质期");
                    return;
                  }
                  action.changeTableList({ dispatch });
                }
              }}
            >
              确认批量修改
            </Button>
          </ButtonPanel>
          <DatePicker
            style={{ width: 150, marginLeft: 20 }}
            disabled={disabled}
            disabledDate={disabledDate}
            value={byStringGetMoment(formData.productionDate)}
            placeholder="请选择生产日期"
            onChange={(date, dateString) => {
              action.setFormData({
                dispatch,
                key: "productionDate",
                value: dateString,
              });
            }}
          />
          <Input
            disabled={disabled}
            style={{ width: 150, marginLeft: 20, marginRight: 20 }}
            value={formData.expirationDate}
            type="number"
            placeholder="请输入保质期天数"
            min={1}
            onChange={(e) => {
              action.setFormData({
                dispatch,
                key: "expirationDate",
                value: e.target.value,
              });
            }}
          />
        </div>
        <DataTable dataList={state.tableList} disabled={disabled} />
        <TableExpand>
          数量合计：{allNumber().toFixed(3)}
          <span style={{ marginLeft: 10 }}>
            金额合计：￥{NumberTool.toAmount(allAmount())}
          </span>
        </TableExpand>
      </div>

      <ChooseShop
        visibleModal={shopState}
        close={(status, r) => {
          if (r) {
            action.setFormData({ dispatch, key: "shopId", value: r[0].id });
            action.setFormData({
              dispatch,
              key: "shopName",
              value: r[0].shopName,
            });
          }
          updateFormField("in-shop");
          shopFunc.setFalse();
        }}
      />
      <ChooseGoods
        visibleModal={goodsState}
        filter={{ specifications: false, checkInvcount: false }}
        close={(status, r) => {
          if (r) {
            action.addTableItem({ dispatch, list: r });
          }
          goodsFunc.setFalse();
        }}
      />
    </div>
  );
}
