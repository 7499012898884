// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../config";
import { effeative } from "./common_logic";
export class ClearDataLogic{
    async clearModle({list, password}){
        return  effeative(await axios({
            method:'POST',
            url: Tools.getApiAddress('clear_data_by_id'),
            data:{
                moduleIds: list,
                password: password
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        
    }
    async queryPage(){
        return  effeative(await axios({
            method:'POST',
            url: Tools.getApiAddress('clear_data_query'),
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        
    }
}