import { createContext, useContext, useReducer } from "react";

export const ShopSettingStore = createContext<IContextProps>(null);

interface IContextProps {
    state: IState;
    dispatch: ({ type }: Dispatch) => void;
}

type Dispatch = { type, payload }

class Search {
    keywords: string;
    areaId: string;
    statusId: string;
    typeId: string;
    shopID:string
    shopName: string
};

class FormData{
    isBusinessStatus: boolean = true //营业状态(true正常营业/false关闭营业)
    businessBeginHours: string
    businessEndHours: string    
    storeLimitBusinessHours:boolean  = false//非营业时间商城是否可以下单
    shippingRange: number //配送范围
    sendOffMoney:number //起送费
    shippingFees: number //配送费
    distributionPlatform: number  = 1// 1、商家自送2、达达配送
    fullReductionNoShippingFees: number //满额免配送费
    packingFee: number //打包费
    isRefundAudit: number = 0 //订单退款是否需要审核true 0需要审核false 1直接退款
    shippingRangeIsRefund: boolean = true // 配送费是否退款 默认退
    packingFeeIsRefund: boolean = true // 打包费是否退款 默认退
    isPrint: boolean = false //是否自动打印订单
}
interface IState {
    loading: boolean
    search: Search;
    formData: FormData
}
const initialArgs ={
    loading: false,
    search: new Search(),
    formData: new FormData()
}
function reducer(state:IState, {type, payload}: Dispatch) {
    switch(type) {
        case 'Loading':
            state.loading = payload
            return{
                ...state
            }
        case 'SetForm':
            const { fullReductionNoShippingFees,shippingRangeIsRefund,packingFeeIsRefund, isRefundAudit, packingFee,distributionPlatform,shippingFees,sendOffMoney, isBusinessStatus,businessBeginHours,businessEndHours,storeLimitBusinessHours,shippingRange, isPrint} = payload;
            const item = new FormData()
            item.fullReductionNoShippingFees = fullReductionNoShippingFees
            item.distributionPlatform = distributionPlatform
            item.shippingFees = shippingFees
            item.sendOffMoney = sendOffMoney
            item.isBusinessStatus = isBusinessStatus
            item.businessEndHours = businessEndHours
            item.businessBeginHours = businessBeginHours
            item.storeLimitBusinessHours = storeLimitBusinessHours
            item.shippingRange = shippingRange
            item.packingFee = packingFee
            item.isRefundAudit = isRefundAudit?0:1
            item.packingFeeIsRefund = packingFeeIsRefund !==null?packingFeeIsRefund : true
            item.shippingRangeIsRefund = shippingRangeIsRefund!==null?shippingRangeIsRefund: true
            item.isPrint = isPrint
            return{
                ...state,
                formData:{
                    ...item
                }
            }
        case "SetSearch":
            return {
                ...state,
                search: {
                    ...state.search,
                    ...payload
                }
            }
         // 设置表单
         case "Change_FormData":
            {
                const { attribute, value } = payload, props: any = {};


                props[attribute] = value;

                return {
                    ...state,
                    formData: {
                        ...state.formData,
                        ...props
                    }
                }
            }
        default: return {...state }
    }
}
export function ShopSettingFileProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialArgs);

    const v = { state, dispatch };
    return <ShopSettingStore.Provider value={v}>{props.children}</ShopSettingStore.Provider>
}