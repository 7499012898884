import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
  TimePicker,
  DatePicker,
  Tag,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { FormSubtitle } from "../../../commodity/view/form_subtitle";
import styles from "../../style/print_set.module.scss";

import img_del from "../../../../assets/images/shanchu.png";
import { formAction } from "../../action/printSet/print_set_action";
import { useBoolean } from "ahooks";
import { ChooseGoods } from "../../../../component/choose-goods";
import { Table } from "../../../../component/table";
import { ChooseShop } from "../../../../component/choose-shop";
import { FormStore } from "../../store/printSet/print_set_store";
import { autoRem } from "../../../../global.style";
import { useState } from "react";
import { PrintSet } from "./index";
export const FormBasic = ({ disabled }) => {
  const action = formAction;
  const { state, dispatch } = useContext(FormStore);

  let height = document.body.clientHeight - 188;
  return (
    <div className={styles.root} style={{ height: height + "px" }}>
      <div className={styles.content}>
        <FormSubtitle>模板信息</FormSubtitle>

        <div
          className="custom-radio"
          style={{
            margin:
              "" +
              autoRem(1.9375) +
              " " +
              autoRem(1.25) +
              " 0 " +
              autoRem(1.25),
          }}
        >
          <PrintSet />
        </div>
      </div>
    </div>
  );
};
