import { Button } from "antd";
/**
 * 支付配置控制器
 */
export function PaymentCon({
    save,
    reset,
}: {
    save: () => void;
    reset: () => void;
}) {
    return <>
        <Button type="primary" color="Red" style={{ marginRight: "1.25rem" }} onClick={save}>
            保存设置
        </Button>
        <Button type="primary" color="Yellow" onClick={reset}>
            重置
        </Button>
    </>
}