// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../config";
import { effeative } from "./common_logic";

export class AlarmSetLogic {
    // alarmset_list
    async getList(params){
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("alarmset_list"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r
    }
    async deleteAlarmSet(id:string){
        const r = effeative(await axios({
            method: 'GET',
            url: Tools.getApiAddress('alarmset_delete'),
            params:{
                ID: id
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r
    }
    async addAlarmSet(params){
        const r = effeative(await axios({
            method: 'POST',
            url: Tools.getApiAddress('alarmset_add'),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r
    }
}