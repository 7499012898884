import moment from "moment";
import { createContext, useContext, useReducer } from "react";
import { ShopEntity } from "../../../../entity/shop_entity";
export const WarehousingFormStore = createContext<IContextProps>(null);

interface IContextProps {
  state: IState;
  dispatch: ({ type }: Dispatch) => void;
}

type Dispatch = { type; payload };

class Search {
  keyword: string;
  areaId: string;
  statusId: string;
  typeId: string;
}
export class FormData {
  productionDate: string;
  expirationDate: string;
  keyword: string;
  shop = new ShopEntity();
  shopId: string;
  shopName: string;
  remark: string;
  inStockPrice: number;
  inStockNumber: number;
  userName: string;
  inStockUnitPrice: number;
  date: string = moment().format("YYYY-MM-DD HH:mm:ss");
  reasonId: number = 1;
}
export class TableItem {
  serial: number;
  goodsId: string;
  id: string;
  goodsCode: string;
  goodsName: string;
  inStockUnit: string;
  inStockUnitId: string;
  unitName: string;
  /**
   * 入库单价
   */
  inStockUnitPrice: number;
  inStockNumber: number;
  inStockPrice: number;
  /** 规格列表 */
  goodsSpecifications: any[] = [];

  manufactureDate: string;
  shelfLifeDay: string;
}
interface IState {
  searchList: any[];
  formData: FormData;
  search: Search;
  tableList: TableItem[];
  shoplist: any[];
  code: string;
}

const initialArgs: IState = {
  searchList: [],
  formData: new FormData(),
  search: new Search(),
  tableList: [],
  shoplist: [],
  code: "",
};

function reducer(state: IState, { type, payload }: Dispatch) {
  switch (type) {
    case "InputCode":
      state.code = payload;
      console.log(payload);
      return {
        ...state,
      };
    case "LoadShop":
      return {
        ...state,
        shoplist: payload,
      };
    /** 更新 */
    case "update":
      return {
        ...state,
      };

    case "SetSearchList":
      return {
        ...state,
        searchList: payload,
      };
    case "SetFormData": {
      return {
        ...state,
        formData: {
          ...state.formData,
          ...payload,
        },
      };
    }
    case "SetForm": {
      const {
        shopID,
        shopName,
        remark,
        goodsList,
        userName,
        addDateTime,
        reason,
      } = payload;
      const item = new FormData();
      const shopItem = new ShopEntity();
      shopItem.id = shopID;
      shopItem.shopName = shopName;

      item.shopId = shopID;
      item.shopName = shopName;
      item.remark = remark;
      item.userName = userName;
      item.date = addDateTime;
      item.reasonId = reason;

      const list = [];
      goodsList.forEach(
        ({
          goodsID,
          goodsUnitName,
          goodsNo,
          id,
          goodsName,
          goodsUnitID,
          goodsClassesID,
          unitName,
          putPrice,
          putNumber,
          putSumPrice,
          goodsId,
          shelfLifeDay,
          manufactureDate,
          // goodsSpecifications,
        },i) => {
          const item = new TableItem();
          item.goodsId = goodsID || goodsId;
          // item.goodsId = goodsID;
          item.goodsCode = goodsNo;
          item.goodsName = goodsName;
          item.inStockUnitId = goodsClassesID || goodsUnitID;
          item.inStockUnit = goodsUnitName ? goodsUnitName : unitName;
          item.unitName = unitName;
          item.inStockUnitPrice = putPrice;
          item.inStockNumber = putNumber;
          item.inStockPrice = putSumPrice;
          item.shelfLifeDay = shelfLifeDay;
          item.manufactureDate = manufactureDate;
          // item.goodsSpecifications = goodsSpecifications;
          item.serial = i
          list.push(item);
        }
      );

      return {
        ...state,
        formData: item,
        tableList: list,
      };
    }
    case "AddTableItem": {
      const list = state.tableList || [];
      // 去重
      // console.log(payload);
      if (list.every((v, i) => v.goodsId !== payload.id)) {
        const item = new TableItem();
        const {
          goodsNo,
          goodsName,
          id,
          goodsID,
          goodsUnitID,
          goodsUnitName,
          inStockUnitPrice,
          inStockNumber,
          inStockPrice,
          unitName,
          goodsId,
          manufactureDate,
          shelfLifeDay,
        } = payload;
        item.manufactureDate = manufactureDate;
        item.shelfLifeDay = shelfLifeDay;
        item.unitName = unitName;
        // item.goodsId = id;
        item.goodsId = goodsID || goodsId || id;
        item.goodsCode = goodsNo;
        item.goodsName = goodsName;
        item.inStockUnitId = goodsUnitID;
        item.inStockUnit = goodsUnitName;
        item.inStockNumber = inStockNumber;
        item.inStockUnitPrice = inStockUnitPrice;
        item.inStockPrice = inStockPrice;
        //
        item.goodsSpecifications = payload.goodsSpecifications;

        list.push(item);

        list.length > 0 && list.forEach((v, i) => (v.serial = i));
        return {
          ...state,
          tableList: [...list],
        };
      }
      return state;
    }
    case "DelTableItem": {
      const list = state.tableList || [];

      list.splice(payload, 1);
      list.length > 0 && list.forEach((v, i) => (v.serial = i));
      return {
        ...state,
        tableList: [...list],
      };
    }
    case "ChangeTableItem": {
      const { index, key, value } = payload;

      // inStockUnitPrice: number;
      // inStockNumber: number;
      // inStockPrice: number;

      const list = state.tableList || [];

      const item = list[index];

      if (undefined == value || value == "" || value == null) {
        // item[key] = 0;
        item[key] = "";
        item.inStockPrice = 0;
      } else if (key == "manufactureDate" || key == "shelfLifeDay") {
        item[key] = value;
      } else {
        item[key] = Number(value);
        if (Number(value) < 0) {
          item[key] = 0;
        }
        item.inStockPrice = Number(
          (item.inStockNumber * item.inStockUnitPrice).toFixed(2)
        );
      }

      return {
        ...state,
        tableList: [...list],
      };
    }
    case "ClearTableList":
      return {
        ...state,
        tableList: [],
      };
    case "ChangeTableList": {
      let arr = state.tableList || [];
      let list = [];
      arr.length > 0 &&
        arr.map((item) => {
          item.manufactureDate = state.formData.productionDate;
          item.shelfLifeDay = state.formData.expirationDate;
          list.push(item);
        });
      return {
        ...state,
        tableList: list,
      };
    }
    default:
      return state;
  }
}

export function WarehousingFormStoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialArgs);

  const v = { state, dispatch };
  return (
    <WarehousingFormStore.Provider value={v}>
      {props.children}
    </WarehousingFormStore.Provider>
  );
}
