import moment from "moment";
import { createContext, useContext, useReducer } from "react";

export const FileStore = createContext<IContextProps>(null);

interface IContextProps {
  state: IState;
  dispatch: ({ type }: Dispatch) => void;
}

type Dispatch = { type; payload };

class Search {
  seackerKey: string;
  beginTime: string = moment(Date.now()).startOf("month").format("Y-M-D");
  endTime: string = moment(Date.now()).format("Y-M-D");
}

interface IState {
  search: Search;
}

const initialArgs = {
  search: new Search(),
};

function reducer(state: IState, { type, payload }: Dispatch) {
  switch (type) {
    case "SetSearch":
      return {
        ...state,
        search: {
          ...state.search,
          ...payload,
        },
      };
    default:
      return state;
  }
}

export function FileStoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialArgs);

  const v = { state, dispatch };
  return <FileStore.Provider value={v}>{props.children}</FileStore.Provider>;
}
