import styles from "./../style/navigation.module.scss";
import { TopicDataAction } from "../action/TopicDataAction";
import { ITopicValue, TopicStore } from "../store/topic";
import { useContext, useEffect, useState, useRef } from "react";
import { Tools } from "../../../config";
import { INavData } from "../enum/dataType";
import img_120_120 from "../../../assets/images/mini/group_120_120.png";
import { DrageList } from "../com/dragList";
import { SelectUrl } from "../com/selectUrl";
import { ISelectUrl } from "./modal/modal";
import { Input, message } from "antd";
import { ApiCon } from "./../com/apiCon";
import waitupload from "../../../assets/images/mini/waitupload.png";
export function Navimg({ id, con }) {
  const topicStoreState = useContext(TopicStore);
  const listDel = (index) => {
    data.splice(index, 1);
    TopicDataAction.setData<INavData[]>(...actionArg, data);
  };
  const [selectId, setSelectId] = useState(null);
  const inputRef = useRef(null);

  const dufaultItems = [];
  const waitImg = waitupload;

  for (let i = 0; i < 5; i++) {
    dufaultItems.push(waitImg);
  }

  const add = () => {
    data.push({
      fileUrl: "",
      selectUrl: {
        key: "",
        imgUrl: "",
        type: "",
        url: "",
        title: "标题",
        isInit: true,
        goodsSource: 1,
      },
    });
    TopicDataAction.setData<INavData[]>(...actionArg, data);
  };
  let initData: INavData[] = [];
  for (let i = 0; i < 5; i++) {
    initData.push({
      fileUrl: "",
      selectUrl: {
        key: "",
        imgUrl: "",
        url: "",
        type: "",
        title: "标题",
        isInit: true,
        goodsSource: 1,
      },
    });
  }
  let actionArg: [ITopicValue, number] = [topicStoreState, id];
  const data: INavData[] = TopicDataAction.getData<INavData[]>(...actionArg);

  /** 选择图片 */
  // const selectImage = () => {
  //   inputRef.current.click();
  // };
  /** 获取图片 */
  const inputChange = () => {
    const _file = inputRef.current.files[0];

    ApiCon.uploadImgGetUrl(_file).then((res) => {
      data[selectId].fileUrl = res;
      data[selectId].fileName = _file.name;
      TopicDataAction.setData<any>(...actionArg, data);
    });
  };
  /** 编辑列表url */
  const editLinkItemUrl = (i: number, selectUrl) => {
    data[i].fileUrl = "";
    data[i].fileName = "";
    data[i].selectUrl = selectUrl;
    TopicDataAction.setData(...actionArg, data);
  };

  //注册数据提取方法
  TopicDataAction.setEF(topicStoreState, id, () => {
    const advdata = data.filter((item) => {
      return !item.selectUrl.isInit;
    });
    let coupon = advdata.some((adv) => {
      return adv.selectUrl.type == "优惠券" && adv.fileUrl == "";
    });
    let classify = advdata.some((adv) => {
      if (adv.selectUrl.type == "分类") return adv.fileUrl == "";
    });
    return new Promise((r, e) => {
      if (advdata.length == 0) {
        message.error("有图片导航没有进行设置");
      } else if (coupon) {
        message.error("存在优惠券没有设置图片");
      } else if (classify) {
        message.error("存在分类没有图片");
      } else {
        ApiCon.turnBlobImgUrl(
          advdata.map((item) => {
            return {
              url: item.fileUrl,
              name: item.fileName,
            };
          })
        ).then((urls) => {
          r(
            advdata.map((item, index) => {
              return {
                ...item,
                fileUrl: urls[index],
              };
            })
          );
        });
      }
    });
  });

  useEffect(() => {
    if (!data) {
      TopicDataAction.setData<INavData[]>(...actionArg, initData);
    }
  }, []);
  if (!data) {
    return <div></div>;
  }

  if (con == "show") {
    return (
      <div className={styles.container}>
        <ul className={styles.nav_img}>
          {data && data.length > 0
            ? data.map((item, i) => {
                return (
                  <li key={i} className={styles.goodsimg}>
                    {/* <img style={{width:`${items.length ==1 ?'100%':''}`}} src ={`${item.data.img}`}  /> <span className={styles.good_name}>{item.data.goodName}</span></li>        */}
                    <img
                      src={
                        item && item.fileUrl != ""
                          ? Tools.getImageAddress(item.fileUrl)
                          : item.selectUrl.imgUrl != ""
                          ? Tools.getImageAddress(item.selectUrl.imgUrl, item.selectUrl.goodsSource)
                          : img_120_120
                      }
                    />
                  </li>
                );
              })
            : dufaultItems.map((item) => (
                <li className={styles.goodsimg}>
                  <img src={item} />
                  导航文字
                </li>
              ))}
        </ul>
      </div>
    );
  } else {
    return (
      <div className={styles.ctrl}>
        <input
          ref={inputRef}
          onChange={inputChange}
          type="file"
          accept="image/*"
          style={{ display: "none" }}
        />
        <h2>图片导航设置</h2>
        <div style={{ padding: "20px 0", borderTop: "1px solid #eee" }}>
          <ul>
            {data &&
              data.map((item, i) => {
                return <li key={i}></li>;
              })}
            {data && (
              <DrageList
                list={data}
                onDragEnd={(list, oldi, newi) => {
                  topicStoreState.dispatch({
                    type: "LIST_MOVE",
                    payload: list,
                  });
                }}
              >
                {(good, i) => {
                  return (
                    <div className={styles.ctrl_item_list}>
                      <div className={styles.advlist_operation}>
                        <span onClick={() => listDel(i)}>×</span>
                      </div>
                      {/* {good.name} */}
                      <div className={styles.ctrl_item_list_content}>
                        <div
                          className={styles.img_reselect}
                          onClick={() => {
                            setSelectId(i);
                            inputRef.current.click();
                          }}
                        >
                          <img
                            src={
                              good.fileUrl != ""
                                ? Tools.getImageAddress(good.fileUrl)
                                : good.selectUrl.imgUrl
                                ? Tools.getImageAddress(good.selectUrl.imgUrl, good.selectUrl.goodsSource)
                                : img_120_120
                            }
                            alt=""
                          />
                          <span className={styles.img_reselect_op}>
                            重新选择
                          </span>
                        </div>
                        <div className={styles.img_info}>
                          <div className={styles.formitems}>
                            <label className={styles.formitems_name}>
                              链接到：{" "}
                            </label>
                            <div className={styles.formitems_cols}>
                              {/* {good.selectUrl.url!=''?<Link to='' className={styles.selecteditems}>{good.selectUrl.type}<span>{good.selectUrl.title}</span></Link>:null} */}
                              {/* <span onClick={()=>showModal()} className={styles.select_modal}>选择连接</span> */}

                              <SelectUrl
                                select={good.selectUrl}
                                selectedBack={(select: ISelectUrl) => {
                                  editLinkItemUrl(i, select);
                                }}
                              />
                            </div>
                          </div>
                          <div className={styles.formitems}>
                            <label className={styles.formitems_name}>
                              标题：{" "}
                            </label>
                            <div className={styles.formitems_cols}>
                              <Input
                                size="small"
                                disabled
                                value={good.selectUrl.title}
                                style={{
                                  padding: "2px 10px",
                                  fontSize: 12,
                                  width: 240,
                                }}
                              ></Input>{" "}
                            </div>
                          </div>
                          <div className={styles.formitems}>
                            <label className={styles.formitems_name}>
                              建议尺寸：{" "}
                            </label>
                            <span className={styles.formitems_cols}>
                              750*350
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }}
              </DrageList>
            )}
            {data.length < 5 ? (
              <AddList
                addGood={() => {
                  add();
                }}
              />
            ) : null}
          </ul>
        </div>
      </div>
    );
  }
}
function AddList({ addGood }) {
  return (
    <div className={styles.ctrl_list_add} onClick={() => addGood()}>
      +
    </div>
  );
}
