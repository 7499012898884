import React from "react";
import styles from "./index.module.scss";

interface ITips {
  children?: any;
  width?: string;
  display?: boolean;
  left?: string;
}
export function Tips({ children, width, display, left }: ITips) {
  return (
    <div
      className={styles.tips}
      style={{ width, display: !display ? "none" : "block", left }}
    >
      <span></span>
      {children}
    </div>
  );
}

interface ITipsGroup {
  children?: any;
}
export function TipsGroup({ children }: ITipsGroup) {
  return <div className={styles.tips_group}>{children}</div>;
}
