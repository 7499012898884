import { useBoolean, useRequest } from "ahooks";
import { Button, Input, Modal, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect, useState } from "react";
import { ButtonPanel } from "../../../../../component/button-panel/button_panel";
import { DataTable } from "../../../../../component/table";

import img_edit from "../../../../assets/images/bianji.png";
import img_see from "../../../../assets/images/chakan1.png";
import img_del from "../../../../assets/images/shanchu.png";
import { fileAction } from "../../../action/scale/file_action";
import { ScaleFileStore } from "../../../store/scale/file_store";
import { FormStoreProvider } from "../../../store/scale/form_store";
import { formAction } from "../../../action/scale/form_action";
import { DataTableStore } from "../../../../../component/table/store/data_table_store";
import { Search } from "../../../../../component/search";
import { defualtPageSize, Progress } from "../../../../../logic/common_logic";
import { AuthLogic } from "../../../../../logic/auth_logic";
import { ScaleDetail } from "../../../../../component/scale-detail";
import {
  Control,
  See,
  Edit,
  Del,
} from "../../../../../component/control-column";
import { ConfirmModal } from "../../../../../component/modal";
import styles from "../../../style/equip.module.scss";
import { FileSearch } from "./file_search";
import { Mask } from "../../../../../component/mask";
import { TipsGroup, Tips } from "../../../../../component/tips";
let editId = "";
let seeId = "";
let item;
const formParams = {
  method: "Create",
  id: "",
};
export function ScaleRecod(viewPower) {
  const action = fileAction;
  const [confirmState, confirmFunc] = useBoolean(false);
  const [createState, createFunc] = useBoolean(false);
  const [searchState, searchFunc] = useBoolean(false);
  const { state, dispatch } = useContext(ScaleFileStore);
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);
  const [menuButton, setData] = useState([]);
  useEffect(() => {
    (async function () {
      let r = await new AuthLogic().selButton(viewPower.viewPower);
      if (r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          r[i] = r[i].split("_")[r[i].split("_").length - 1];
        }
      }
      setData(r);
    })();
    return () => {};
  }, []);
  const columns: ColumnsType = [
    {
      title: "操作",
      key: "1",
      align: "center",
      width: Control.width(2),
      render: Control.render(
        (v) =>
          menuButton.indexOf("VIEW") != -1 && (
            <See
              click={() => {
                editId = "";
                seeId = v.id;
                item = v;
                formParams.method = "View";
                formParams.id = v.id;
                createFunc.setTrue();
              }}
            />
          )
      ),
    },
    {
      title: "设备名称",
      dataIndex: "barCodeName",
      key: "2",
      align: "center",
    },
    {
      title: "设备IP地址",
      dataIndex: "barCodeIP",
      key: "3",
      align: "center",
    },
    {
      title: "下发商品数量",
      dataIndex: "actionCount",
      key: "4",
      align: "center",
    },
    {
      title: "下发时间",
      dataIndex: "createTime",
      key: "5",
      align: "center",
    },
  ];
  const onSearch = async (pageIndex) => {
    await action.loadPage({
      pageIndex: pageIndex,
      pageSize: defualtPageSize(),
      dispatch: tableDispatch,
      search: state.search,
    });
  };
  useRequest(async () => await action.init(dispatch));
  return (
    <div className={styles.root}>
      <Search>
        <Input
          maxLength={30}
          allowClear
          // placeholder="请输入条码秤IP或别名"
          onChange={(e) => {
            action.setCondition({
              dispatch,
              key: "searchKey",
              value: e.target.value,
            });
          }}
        />
        <input type="button" value="快速查找" onClick={() => onSearch(1)} />
        <TipsGroup>
          <input
            type="button"
            value="高级查找"
            onClick={() => searchFunc.toggle()}
          />
          <Tips width="45rem" display={searchState}>
            <FileSearch
              onSearch={(...arg: [any]) => {
                onSearch(...arg);
                searchFunc.setFalse();
              }}
            />
          </Tips>
          <Mask visible={searchState} onClick={() => searchFunc.setFalse()} />
        </TipsGroup>
      </Search>
      <DataTable
        columns={columns}
        action={fileAction}
        onLoad={async () => {
          await onSearch(1);
          Progress.done();
        }}
        onChange={(pageIndex) => {
          onSearch(pageIndex);
        }}
        scroll={{ scrollToFirstRowOnChange: true, autoY: true, x: 1060 }}
        loading={tableState.loading}
      />
      {createState && (
        <ScaleDetail
          visibleModal={createState}
          logId={formParams.id}
          close={async (r) => {
            createFunc.setFalse();
          }}
        />
      )}
      <ConfirmModal
        visible={confirmState}
        title="温馨提示"
        subTitle="确认删除？"
        message="删除之后信息无法恢复，请慎重考虑"
        close={async (r) => {
          if (r) {
            //   await action.delRow({ dispatch, id: formParams.id });
            //   onSearch(tableState.current);
          }
          confirmFunc.setFalse();
        }}
      />
    </div>
  );
}
