import styles from "./help.module.scss";
import { Input } from "../../component/input";
import search_btn from "../../assets/images/help/search.png";
import { HelpStore } from "./store/help_store";
import { useContext, useEffect, useState } from "react";
import { Text } from "./Text";
import { Empty, message } from "antd";
import { helpAction } from "./action/help_action";
import { useRequest } from "ahooks";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import banner_img from "../../assets/images/help/banner.png";
import { TextTabContent, VideoTabContent, QuestionTabContent} from "./Text";
export function HelpContainer() {
  const action = helpAction;
  const [current, setCurrent] = useState("0");
  const { state, dispatch } = useContext(HelpStore);
  const [menuList, setMenu] = useState([
    {
      name: "文本教程",
      id: "0",
      type: "text",
    },
    {
      name: "视频教程",
      id: "1",
      type: "video",
    },
    {
      name: "常见问题",
      id: "2",
      type: "question",
    },
  ]);
  const getparentId = (arr, id) => {
    let list = arr || [];
    let parentID;
    for (let i = 0; i < list.length; i++) {
      let obji = list[i];
      if (obji.childrens) {
        getparentId(obji.childrens, id);
      } else {
        if (obji.ID == id) {
          parentID = obji.ParentID;
          dispatch({
            type: "Key",
            payload: { type: "key", key: obji },
          });
        }
      }
    }
  };
  const selectOne = (item) => {
    // getparentId(state.menuList, item.MenuID);

    dispatch({ type: "searchClick", payload: item });
    dispatch({ type: "type", payload: item.ContentType });
    dispatch({
      type: "search",
      payload: { key: state.search.key, isSearch: false },
    });
  };
  const his = useHistory();
  // useEffect(() => {
  //   let token = Cookies.get("token");
  //   if (!token) {
  //     message.error("请登录!");
  //     his.replace("/login");
  //   }
  // }, []);
  const [id, setId] = useState(0);
  useRequest(async () => await action.init(dispatch));
  return (
    <div className={styles.help}>
      <div className={styles.banner}>
        <img src={banner_img} />
        <div className={styles.search_b}>
          <div className={styles.search}>
            <Input
              className={styles.searchInput}
              placeholder="搜索"
              maxLength={50}
              onChange={(e) => {
                dispatch({
                  type: "search",
                  payload: { key: e.target.value, isSearch: false },
                });
              }}
              onPressEnter={() => {
                if (state.search.key == "") {
                  return;
                }
                action.search(state, dispatch);
              }}
            ></Input>
            <div
              className={styles.search_btn_b}
              onClick={() => {
                if (state.search.key == "") {
                  return;
                }
                action.search(state, dispatch);
              }}
              style={{ cursor: "pointer" }}
            >
              <img alt="" src={search_btn} />
              <span>搜索</span>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.content_b}>
        {!state.search.isSearch ? (
          <div className={styles.content}>
            <div className={styles.tabs}>
              {state.menuList.map((menu, index) => {
                return (
                  <div
                    onClick={async () => {
                      setCurrent(menu.id);
                      setId(index);
                      await dispatch({ type: "tab", payload: index });
                    }}
                    className={[
                      `${styles.tab_list}`,
                      `${
                        index == state.menuList.length - 1
                          ? state.tab_index == index
                            ? styles.tab_list_right_active
                            : styles.tab_list_right
                          : ""
                      }`,
                      `${
                        index == 0
                          ? state.tab_index == index
                            ? styles.tab_list_left_active
                            : styles.tab_list_left
                          : ""
                      }`,
                      `${
                        state.tab_index == index &&
                        index != 0 &&
                        index != state.menuList.length - 1
                          ? styles.tab_list_active
                          : ""
                      }`,
                    ].join(" ")}
                  >
                    <span>{menu.MenuName}</span>
                  </div>
                );
              })}
            </div>

            {state.currentMenu && state.currentMenu.childrens ? (
              <Text index={id} />
            ) : state.currentMenu.IsParent == 0?state.currentType == 1?<TextTabContent id={state.selectKey}/>:state.currentType == 2?<VideoTabContent/>:<QuestionTabContent/>:(
              <div
                style={{
                  width: "100%",
                  background: "#fff",
                  padding: "5rem 0",
                }}
              >
                {" "}
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </div>
            )}
          </div>
        ) : (
          <div className={styles.search_content} style={{ background: "#fff" }}>
            {state.searchList.length > 0 ? (
              state.searchList.map((item, index) => {
                return (
                  <div
                    className={styles.search_content_item}
                    onClick={() => {
                      selectOne(item);
                    }}
                    style={{
                      marginBottom: 20
                    }}
                  >
                   <div><span style={{color:'#333333',fontSize:22}}>{item.menuTurn.secondMenuName}</span> {item.menuTurn.thirdMenuName?<span style={{color:'#666666',fontSize:18}}> —— {item.menuTurn.thirdMenuName}</span>:<span></span>}</div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        color:'#999999',
                        fontSize: 14,
                        marginTop:12
                      }}
                    >
                      <span>
                        {item.ContentType == 1 ? "文本教程" : "视频教程"}
                      </span>
                      <span >
                        {moment(item.CreateTime).format("yyyy-MM-DD HH:mm:ss")}
                      </span>
                    </div>
                  </div>
                );
              })
            ) : (
              <div
                style={{
                  width: "100%",
                  background: "#fff",
                  padding: "5rem 0",
                }}
              >
                {" "}
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
