// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../config";
import { effeative, maxPageSize } from "./common_logic";

export class GradeChangeLogsLogic {
    async querysList(param) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("member_grade_change_logs"),
            data: param,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
}