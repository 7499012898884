// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../config";
import { effeative, maxPageSize } from "./common_logic";

export class BrandLogic {
    async querysList(){
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("goods_brand_select_page"),
            data: {
                brandNo: "",
                brandName: "",
                pageIndex: 0,
                pageSize: maxPageSize(),
                orderBy: "",
                sort: ""
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
}