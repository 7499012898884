import Cookies from "js-cookie";
import ReactDOM from "react-dom";
import styles from "../style/dueToAlert.module.scss";
import { Button, message } from "antd";
import { useEffect, useState } from "react";
import { FuncLock, SetInterval } from "stb-tools";
import { CloseCircleOutlined } from "@ant-design/icons";
import img_renew from "../../../assets/images/renew.png";
import closeImg from "../../../assets/images/close.png";
import { useHistory } from "react-router-dom";

//添加一个用来提示的元素
let popHtml = document.body.appendChild(document.createElement("div"));

/** 获取时间差天数 */
function getTimeDiffDay(_start: string | Date, _end: string | Date): number {
  let dateDiff =
    (typeof _end == "string" ? new Date(_end) : _end).getTime() -
    (typeof _start == "string" ? new Date(_start) : _start).getTime(); //时间差的毫秒数
  return Math.floor(dateDiff / (24 * 3600 * 1000));
}

/** 是否显示了一次 */
let ifOneShow: boolean = false;

//一个以秒为单位的计时器
const t = new SetInterval(1000);
/**
 * 到期提示
 */
export function DueToAlert() {
  let [ifShow, setShow] = useState(false);
  const [time, setTime] = useState(6);
  const [_endTime, setEndTime] = useState("");
  const his = useHistory()
  useEffect(() => {
    // message.info(Cookies.get("loginMsg"))
    if(Cookies.get("loginMsg")==undefined){
      // message.error('登录失效，请登录')
      his.replace('/login')
      return
    }
    let msg = JSON.parse(Cookies.get("loginMsg"))
   
    if (typeof msg.versionType == "number" && msg.versionType != 3) {
      if (!ifOneShow && getTimeDiffDay(new Date(), msg.expireTime) <= 30) {
        ifOneShow = true;
        setShow(true);
        setEndTime(msg.expireTime);
      }
      if (getTimeDiffDay(new Date(), msg.expireTime) <= 0) {
        ifOneShow = true;
        setShow(false);
        setEndTime(msg.expireTime);
      }
    }
  }, []);

  useEffect(() => {
    t.enable(() => {
      if (time >= 1) {
        setTime(time - 1);
      } else {
        setShow(false);
        //
        t.clear();
      }
    });
    return () => {
      t.clear();
    };
  }, [time]);

  //
  if (!ifShow) {
    return <div></div>;
  }
  return ReactDOM.createPortal(
    <div className={styles.root}>
      <div className={styles.content}>
        <img
          src={closeImg}
          alt=""
          onClick={() => {
            setShow(false);
          }}
        />
        <div className={styles.content_head}>
          <img src={img_renew} />
          <span>到期提醒</span>
        </div>
        <div className={styles.alert}>
          <span>您的店铺版本即将到期，请尽快续费</span>
          <span>
            {_endTime}到期，还剩{getTimeDiffDay(new Date(), _endTime)}天
          </span>
        </div>
        <div className={styles.content_bottom}>
          <span>{time}秒后自动关闭</span>
        </div>
      </div>
    </div>,
    popHtml
  );
}
