import { createContext, useContext, useReducer } from "react";

export const TransactionStore = createContext<IContextProps>(null);

interface IContextProps {
    state: IState;
    dispatch: ({ type }: Dispatch) => void;
}

type Dispatch = { type, payload }

class Condition {
    // 时间类型 1：今天；2：昨天；3：最近7天；4：近30天；5：自定义
    dateType: number = 1;
    startTime: string = "";
    endTime: string = "";
}
class Item {
    /**
     * 销售金额
     */
    salesAmount: number = 0;
    /**
     * 会员充值
     */
    memberRecharge: number = 0;
    /**
     * 订单数量
     */
    quantityOfOrder: number = 0;
    /**
     * 退款金额
     */
    refundAmount: number = 0;
}
export interface IState {
    condition: Condition;
    item: Item;
    member?: Member
}
class Member {
    salesPrice: number = 0
    orderCount: number = 0
    isMember: number
}
const initialArgs: IState = {
    condition: new Condition(),
    item: new Item(),
    member: new Member()
}

function reducer(state: IState, { type, payload }: Dispatch): IState {
    switch (type) {
        case "SetCondition":
            return {
                ...state,
                condition: {
                    ...state.condition,
                    ...payload
                }
            }
        case "SetData":
            return {
                ...state,
                ...payload
            }
        case "SetItem":
            {
                const item = new Item();
                item.memberRecharge = payload.member_Recharge;
                item.quantityOfOrder = payload.orderNumber;
                item.refundAmount = payload.refund_Amount;
                item.salesAmount = payload.sales_Amount;

                return {
                    ...state,
                    item: item
                }
            }
        case 'Member': 
        {   
            state.member = payload
            return {...state}
        }
        default:
            return state;
    }
}

export function TransactionStoreProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialArgs);

    const v = { state, dispatch };
    return <TransactionStore.Provider value={v}>{props.children}</TransactionStore.Provider>
}