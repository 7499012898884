/**
 * 储值规则
 */
import { useBoolean } from "ahooks";
import { Button, message, Modal } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect, useState } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { DataTable } from "../../../../component/table";

import img_edit from "../../../../assets/images/bianji.png"
import img_see from "../../../../assets/images/chakan1.png"
import img_del from "../../../../assets/images/shanchu.png"
import { fileAction } from "../../action/stored/file_action";
import { FormStoreProvider } from "../../store/stored/form_store";
import { Form } from "./form";
import { formAction } from "../../action/stored/form_action";
import { DataTableStore } from "../../../../component/table/store/data_table_store";
import { ConfirmModal } from "../../../../component/modal";
import { defualtPageSize, Progress } from "../../../../logic/common_logic";
import { AuthLogic } from "../../../../logic/auth_logic";
import { Control, Del, Edit, See } from "../../../../component/control-column";
let editId = "";
let seeId = "";
const formParams = {
    method: "Create",
    id: ""
}
export function Stored(viewPower) {
    const [createState, createFunc] = useBoolean(false);
    const [deleteState] = useBoolean(false);
    const [] = useBoolean(false);
    const action = fileAction;
    const [confirmState, confirmFunc] = useBoolean(false);
    const { state: tableState, dispatch: dispatch } = useContext(DataTableStore);

    const [menuButton, setData] = useState([])
    useEffect(() => {
        (async function () {
            let r = await new AuthLogic().selButton(viewPower.viewPower);
            if (r&&r.length > 0) {
                for (let i = 0; i < r.length; i++) {

                    r[i] = r[i].split('_')[r[i].split('_').length - 1]
                }
            }
            setData(r);
        }())

        return () => {

        }
    }, []);
    const columns: ColumnsType = [
        {
            title: '操作',
            key: '1',
            align: "center",
            width: Control.width(3),
            render: Control.render(
                v => menuButton.indexOf("VIEW") != -1 && <See click={() => {
                    editId = "";
                    seeId = v.id;
                    createFunc.setTrue();
                }} />,
                v => menuButton.indexOf("UPDATE") != -1 && <Edit click={() => {
                    editId = v.id;
                    seeId = "";
                    createFunc.setTrue();
                }} />,
                v => menuButton.indexOf("DELETE") != -1 && <Del click={() => {
                    formParams.id = v;

                    confirmFunc.setTrue();
                }} />
            )
        },
        {
            title: '规则名称',
            dataIndex: 'ruleName',
            key: '1',
            align: "center"
        },
        {
            title: '储值金额',
            dataIndex: 'storedValue',
            key: '2',
            align: "center"
        },
        {
            title: '赠送类型',
            dataIndex: 'grantType',
            key: '3',
            align: "center"
        },
        {
            title: '赠送值',
            dataIndex: 'grantValue',
            key: '4',
            align: "center"
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: '5',
            align: "center"
        },
    ];

    return (
        <>
            <ButtonPanel>
                {menuButton.indexOf("ADD") != -1 &&
                    <Button onClick={() => {
                        formParams.id = "";
                        formParams.method = "Create";
    
                        editId = "";
                        seeId = "";
                        createFunc.setTrue();
                    }}>新建规则</Button>}
                {menuButton.indexOf("DELETE") != -1 &&
                    <Button onClick={async () => {
                        if (!tableState.selectList.length) {
                            message.warning("请选择删除项")
                        } else {
                          
                            confirmFunc.setTrue();
                        }
                    }}>批量删除</Button>}
                {menuButton.indexOf("EXPORT") != -1 && <Button>导出</Button>}
            </ButtonPanel>

            <DataTable columns={columns} action={fileAction}
                onLoad={async () => { await action.loadPage({ pageIndex: 1, pageSize: defualtPageSize(), dispatch }); Progress.done() }}
                onChange={(pageIndex) => { action.loadPage({ pageIndex: pageIndex, pageSize: defualtPageSize(), dispatch }) }}
                scroll={{ scrollToFirstRowOnChange: true, autoY: true }}
                selectColumn={true}
                loading={ tableState.loading }
            />

            {
                createState && (
                    <FormStoreProvider>
                        <Form close={async (hasUpdate) => {
                            if (hasUpdate) {
                                await action.loadPage({ dispatch, pageIndex: tableState.current, pageSize: tableState.pageSize })
                            }

                            createFunc.setFalse();

                        }} action={formAction} editId={editId} seeId={seeId} />
                    </FormStoreProvider>
                )
            }

            <ConfirmModal visible={confirmState} title="温馨提示" subTitle="确认删除？" message="删除之后信息无法恢复，请慎重考虑" close={async (r) => {
                if (r) {
                    if (tableState.selectList.length>0) {
                        await action.delSelectList({ dispatch, selectList: tableState.selectList })
                        let index = tableState.current
                        action.loadPage({ pageIndex: index, pageSize: tableState.pageSize, dispatch })
                        action.clearSelectedRowKeys({ dispatch });
                    }else{
                    await action.delRow({ dispatch, rowItem: formParams })
                    action.loadPage({ pageIndex: tableState.current, pageSize: tableState.pageSize, dispatch })

                    }
                }
                confirmFunc.setFalse();
            }} />
        </>
    )
}