import { useBoolean, useRequest } from "ahooks";
import { Checkbox, Col, Input, InputNumber, Row, Select, message } from "antd";
import { Form as FormAntd } from "antd";
import { useContext, useEffect, useState } from "react";
import { ChooseShop } from "../../../../component/choose-shop";
import { autoRem } from "../../../../global.style";
import { FaPiaoSettingStore } from "../../store/fapiaoSetting/form_store";
import { formAction } from "../../action/fapiaoSetting/form_action";
import { Button } from "../../../../component/button";
import { AuthLogic } from "../../../../logic/auth_logic";
import { Progress } from "../../../../logic/common_logic";
import styles from "../../style/integralRule.module.scss";
export function FaPiaoSetting(viewPower) {
  const [form] = FormAntd.useForm();
  const { state, dispatch } = useContext(FaPiaoSettingStore);
  const { formData } = state;
  const action = formAction;
  const [loading, loadingFunc] = useBoolean(false)
  const [power, powerFunc] = useBoolean(true);
  useEffect(() => {
   (async function(){
    formAction.init({ dispatch, form });
    Progress.done();
   })()
  },[]);
  function Radio({ onChange, defaultValue, disabled }) {
    const [state, stateFunc] = useBoolean(false);

    useEffect(() => {
      if (defaultValue) {
        stateFunc.setTrue();
      } else {
        stateFunc.setFalse();
      }

      return () => {};
    }, [defaultValue]);

    return (
      <div
        className={`${styles.radio} ${state ? styles.active : ""} ${
          disabled ? styles.disabled : ""
        }`}
        onClick={() => {
          if (disabled) return;
          onChange(!state);
          stateFunc.toggle();
        }}
      ></div>
    );
  }

  return (
    <div
      style={{
        margin: "" + autoRem(1.9375) + " 0 0 0",
        padding: "1rem",
        boxShadow:
          "0px " +
          autoRem(0.3125) +
          " " +
          autoRem(1.25) +
          " 0px rgba(209, 206, 206, 0.3)",
      }}
    >
      <FormAntd form={form}>
        <Row gutter={16}>
          <Col span={10}>
            <FormAntd.Item
              name="status"
              label="是否允许开票"
              labelCol={{ span: 8 }}
            >
              <Radio
                disabled={false}
                defaultValue={formData.status}
                onChange={(v) =>
                    action.setFormData({
                    dispatch,
                    key: "status",
                    value: v ,
                  })
                }
              />
            </FormAntd.Item>
          </Col>
        </Row>
       
        <Row gutter={16}>
          <Col span={10}>
            <FormAntd.Item
              name="salerTaxNum"
              label="销方税号"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: true,
                  message: "请输入销方税号",
                },               
              ]}
            >
              <Input
                onChange={(e) =>
                  action.setFormData({
                    dispatch,
                    key: "salerTaxNum",
                    value: e.target.value,
                  })
                }
              ></Input>
            </FormAntd.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={10}>
            <FormAntd.Item
              name="clerk"
              label="开票员名称"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: true,
                  message: "请输入开票员名称",
                },
              ]}
            >
              <Input
                onChange={(e) =>
                  action.setFormData({
                    dispatch,
                    key: "clerk",
                    value: e.target.value,
                  })
                }
              ></Input>
            </FormAntd.Item>
          </Col>
        </Row>        
        <Row gutter={16}>
          <Col span={10}>
            <FormAntd.Item
              name="appKey"
              label="appKey"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: true,
                  message: "请输入appKey",
                },
              ]}
            >
              <Input
                onChange={(e) =>
                  action.setFormData({
                    dispatch,
                    key: "appKey",
                    value: e.target.value,
                  })
                }
              ></Input>
            </FormAntd.Item>
          </Col>
        </Row>        
        <Row gutter={16}>
          <Col span={10}>
            <FormAntd.Item
              name="appSecret"
              label="appSecret"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: true,
                  message: "请输入appSecret",
                },
              ]}
            >
              <Input
                onChange={(e) =>
                  action.setFormData({
                    dispatch,
                    key: "appSecret",
                    value: e.target.value,
                  })
                }
              ></Input>
            </FormAntd.Item>
          </Col>
        </Row>        
        <Row gutter={16}>
          <Col span={10}>
            <FormAntd.Item
              name="token"
              label="token"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: true,
                  message: "请输入token",
                },
              ]}
            >
              <Input
                onChange={(e) =>
                  action.setFormData({
                    dispatch,
                    key: "token",
                    value: e.target.value,
                  })
                }
              ></Input>
            </FormAntd.Item>
          </Col>
        </Row>        
        { formData.status && <Row gutter={16}>
          <Col span={10}>
            <FormAntd.Item
              name="orderReceiptTimeOut"
              label="时间限制"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: true,
                  validator(rule, value, callback) {
                      if(formData.status){
                          if(formData.orderReceiptTimeOut === null){
                            callback()
                          }else if(formData.orderReceiptTimeOut){
                            callback()
                          }else{
                            callback('请输入时间限制')
                          }
                      }else{
                        callback()
                      }
                  },
                }
              ]}
            >
              <InputNumber size="large" disabled={formData.orderReceiptTimeOut === null}
                min={1}
                precision={0}
                onChange={(v) =>{
                  if(v){
                    action.setFormData({
                      dispatch,
                      key: "orderReceiptTimeOut",
                      value: v,
                    })
                    form.setFieldsValue({
                      'orderReceiptTimeOut': v,
                    });
                  }else{
                    action.setFormData({
                      dispatch,
                      key: "orderReceiptTimeOut",
                      value: undefined,
                    })
                    form.setFieldsValue({
                      'orderReceiptTimeOut': undefined,
                    });
                  }
                  
                }}
              ></InputNumber>
             
            </FormAntd.Item>
          </Col>
          <Col span={10}> <Checkbox checked={formData.orderReceiptTimeOut === null} onChange={(e)=>{
           if(e.target.checked){
            action.setFormData({
              dispatch,
              key: "orderReceiptTimeOut",
              value: null,
            })
           }else{
            action.setFormData({
              dispatch,
              key: "orderReceiptTimeOut",
              value: undefined,
            })           
           }
           form.setFieldsValue({
            'orderReceiptTimeOut': undefined,
          });
          }}>不限制</Checkbox>
          <span>订单支付后多少小时内可以申请开票</span>
          </Col>
        </Row> }       
      </FormAntd>
      <Button
        type="primary"
        color="Red"
        style={{ marginLeft: "1.25rem" }}
        loading={state.isLoading}
        onClick={async () => {
          if (!power) {
            return false;
          }
          if(loading){
            message.loading('正在提交数据')
            return false
          }
          await form.validateFields();
          loadingFunc.setTrue()
          await  action.save({state: state,dispatch,});
          loadingFunc.setFalse()
        }}
      >
        保存设置
      </Button>
    </div>
  );
}
