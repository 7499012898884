import { Form } from "antd";
import { stat } from "fs";
import moment from "moment";
import { createContext, useContext, useReducer } from "react";
import { List } from "../../../user/view/list/list";

export const PrecisionFormStore = createContext<IContextProps>(null);

interface IContextProps {
    state: IState;
    dispatch: ({ type }: Dispatch) => void;
}

type Dispatch = { type, payload }

class Search {
    keyword: string;
    areaId: string;
    statusId: string;
    typeId: string;
};


class PrecisionUser{
    wtype:number;
    wid:[]
}
class PrecisionSettings{
    wType:number;
    wid:[];
}
export class FormData {
    actionName: string;
    actionDescription:string;
    taskType: number;
    scenarioType: number;
    beginDate: string;
    endDate: string;
    rechargeMinMoney: number;
    rechargeMaxMoney: number;
    givePoints: number;
    isGivePoints:boolean;
    isGiveConpou: boolean;
    precisionUser: PrecisionUser[];
    precisionSettings: PrecisionSettings[];
    conditionType:number;
    channelType:number;
   shopList: any[];
    couponList: any[];
    gradeList: any[];
    tagList: any[];
    memberList: any[];
    outMemberList: any[];

    
   

}
export class TableItem {
    serial: number;
    id: string;
    couponName: string;
    couponDetail: string;
    couponNumber: string;
    couponType: number;
    subMoney: string;
    discount: string;
    validType: number;
    validStartDate: string;
    validEndDate: string;
    validDay: string;
    meetMoney: string;
    useTargetType: number;
    isOverlay: number;
    getCouponType: number;
    range: number;
    validDate: [];
    couponAmount?: number;

}
interface IState {
    searchList: any[];
    formData: FormData;
    search: Search;
    couponList: any[];
    gradeList: any[];
    tagList: any[];
    memberList: any[];
    outMemberList: any[];
    precisionUser: PrecisionUser[];
    precisionSettings: PrecisionSettings[];
    channelType: number
}
const form = new FormData();
form.scenarioType = 1;
form.taskType = 1;
form.isGiveConpou = false;
form.isGivePoints = false;
form.givePoints = null;
const initialArgs: IState = {
    searchList: [],
    formData: form,
    search: new Search(),
    couponList: [],
    gradeList: [],
    tagList: [],
    memberList:[],
    outMemberList:[],
    precisionUser:[],
    precisionSettings:[],
    channelType:1
}

function reducer(state: IState, { type, payload }: Dispatch) {
    switch (type) {
        case "SetSearchList":
            return {
                ...state,
                searchList: payload
            }
        case "SetFormData":
            {
                 return {
                    ...state,
                    formData: {
                        ...state.formData,
                        ...payload
                    }
                }
            }
        // 列表
        case "LoadTag":
            return {
                ...state,
                tagList: payload
            }
        case 'LoadGrade':
            return{
                ...state,
                gradeList: payload
            }
        
        case "SetForm":
            {
                const {channelType,isGivePoints,memberList,outMemberList, actionName,actionDescription, taskType, scenarioType, rechargeMinMoney, rechargeMaxMoney, endDate, beginDate, givePoints, isGiveConpou, precisionUser,precisionSettings,couponList, shopList,tagList,gradeList } = payload.p;
                const item = new FormData()
                item.actionDescription = actionDescription;
               
                item.beginDate = beginDate != null ? beginDate.slice(0, 10) : '';
                item.endDate = endDate != null ? endDate.slice(0, 10) : '';
                item.actionName = actionName;
                item.actionDescription = actionDescription;
                item.taskType = taskType;//生效时间类型
                item.scenarioType = scenarioType;//会员注册，充值有礼
                item.rechargeMinMoney = rechargeMinMoney
                item.rechargeMaxMoney = rechargeMaxMoney;
                item.isGivePoints = isGivePoints
                item.givePoints = isGivePoints?givePoints:'';
                item.isGiveConpou = isGiveConpou;
                item.shopList = shopList;
                item.tagList = tagList;
                item.gradeList = gradeList;        

                item.precisionUser = precisionUser;
                item.precisionSettings = precisionSettings;
                item.couponList = couponList;
                item.memberList = memberList;
                item.outMemberList = outMemberList;
                return {
                    ...state,
                    formData: {...item},
                    couponList: couponList,
                    memberList: memberList,
                    channelType : channelType,
                    // precisionUser: [...precisionUser],              
                    // precisionSettings: [...precisionSettings]
                }
            }
       case "ChangePrecisionUser":
           {
            const  list = state.formData.precisionUser || [];
     
        let arr=[];
        let id;
      
        let flag = true;
           for(let i = 0;i<list.length;i++){
               if(list[i].wtype == payload .wtype){
                   list.splice(i,1,payload)
                    flag = false;
                   break;
               }
           }
           if(flag){
               list.push(payload)
           }
           
   
       state.formData.precisionUser = [...list]

           //需要去重
            return{
                ...state,
                // precisionUser: [...list]
            }
        }
        case 'DelPrecisionUser': 
        {
            const  list = state.formData.precisionUser || [];
            if(list.length>0){
                list.splice(payload,1)
                state.formData.precisionUser = [...list]
            }
            return{
                ...state
            }
        }
     
        case "ClearPrecisionUser":
             {
                 const {formData} = state
                formData.memberList = [];
                 formData.tagList = [];
                 formData.gradeList = [];
                formData.outMemberList= [];
                state.formData.precisionUser = [];
                state.precisionUser = [];
                state.memberList=[]
                
                return{
                    ...state,
                }
            }
        case "ChangeSettings":
            {
              const  list = state.formData.precisionSettings || [];
             
                let flag = true;
                for(let i = 0;i<list.length;i++){
                    if(list[i].wType == payload.wType){
                        flag = false;
                        list.splice(i,1,payload)
                        break;
                    }
                }
                if(flag){
                    list.push(payload)
                }
                state.formData.precisionSettings = [...list]

                return{
                    ...state,
                    precisionSettings : [...list]
                }
            }
            case 'DelSetting':{
                const  list = state.formData.precisionSettings || [];
                if(list.length>0){
                    if(list[payload].wType == 1){
                        state.formData.shopList = []
                    }
                    list.splice(payload,1)
                    state.formData.precisionSettings = [...list]
                }
                return {
                    ...state
                }
            }
            case "ClearSettings":
                {
                    const {formData} = state
                    formData.channelType = null;
                    formData.shopList = [];
                    state.precisionSettings = [];
                    state.formData.precisionSettings = []

                   return{
                       ...state,
                        formData:{...formData},
                   }
               }

        case "AddCouponTableItem":
            {
                if (payload&&payload.length > 0) {
                    const list = state.couponList
                    return {
                        ...state,
                        couponList: payload
                    }
                }else {return {...state,couponList:[]};}

                return {...state,couponList:[]};
            }
        case 'AddMemberList':
            {
                if (payload.length > 0) {
                    const list = state.memberList
                    return {
                        ...state,
                        memberList: [...payload]
                    }
                }

                return state;
            }   
            case 'AddOutMemberList':
                {
                    if (payload.length > 0) {
                        const list = state.outMemberList
                        return {
                            ...state,
                            outMemberList: [...payload]
                        }
                    }
    
                    return state;
                }   
            case 'DelCouponTableItem':
                {
                    const list = state.couponList || [];
                    list.splice(payload, 1);
                    state.formData.couponList = [...list]
                    return{
                        ...state,
                        couponList: [...list]
                    }
                }

        case "AddclassTableItem":
            {
                if (payload.length > 0) {
                    return {
                        ...state,
                        classifyList: [...payload]
                    }
                }

                return state;
            }
             
          
        case "ClearTableList":
            return {
                ...state,
                couponList: [],
                shopList:[],
                memberList:[],
                outMemberList:[],
                tagList:[],
                gradeList:[]
            }
      
        case "ClearSetting":
            {
                return{
                    ...state,
                    channelList:[]
                }
            }
        default:
            return state;
    }
}

export function PrecisionFormStoreProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialArgs);

    const v = { state, dispatch };
    return <PrecisionFormStore.Provider value={v}>{props.children}</PrecisionFormStore.Provider>
}