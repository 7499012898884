import moment from "moment";
import { createContext, useContext, useReducer } from "react";

export const FormStore = createContext<IContextProps>(null);

interface IContextProps {
  state: IState;
  dispatch: ({ type }: Dispatch) => void;
}

type Dispatch = { type; payload };

export class FormData {
  shippingFees: number //配送费 可不填
  /**兑换方式1.积分 2.积分+金额 */
  exchangeType: number = 1;
  /**兑换所需积分1 */
  exchangePoints1: number = null;
  /**兑换所需积分2 */
  exchangePoints2: number = null;
  /**兑换所需金额 */
  exchangeMoney: number = null;
  /**发放总量 */
  issueCount: number = null;
  /**每人兑换次数 */
  exchangeCount: number = null;
  /**是否限制每人兑换次数1.不限制 */
  isLimitCount: number = 1;
  /**是否限制会员等级 */
  isMemberClass: number = 1;
  isLimit: boolean = true;
  /**会员等级 */
  memberClasss: any[] = null;
  /** 店铺ID*/
  shopID: any[] = [];
  shopName: string = "";
  /**生效时间 */
  takeType: number = 1;
  takeBeginDate: string;
  takeEndDate: string;
  shopPrice: number;
  storePrice: number;
  sumNumber: number;
}
class Good {
  goodsNo: string;
  goodsName: string;
  shopPrice: number;
  storePrice: number;
}
interface IState {
  tagList: any[];
  formData: FormData;
  // good: Good
}

export const initialArgs: IState = {
  tagList: [],
  formData: new FormData(),
  // good: new Good()
};

function reducer(state: IState, { type, payload }: Dispatch) {
  switch (type) {
    case "LoadTag": {
      state.tagList = payload;
      return {
        ...state,
      };
    }
    case "SetFormData": {
      return {
        ...state,
        formData: {
          ...state.formData,
          ...payload,
        },
      };
    }
    case "SetForm": {
      const {
        shopPrice,
        storePrice,
        sumNumber,
        exchangeType,
        exchangePoints,
        isLimitCount,
        exchangeMoney,
        issueCount,
        exchangeCount,
        isMemberClass,
        memberClasss,
        shopID,
        shopName,
        takeType,
        takeBeginDate,
        takeEndDate,
        shippingFees
      } = payload;
      const item = new FormData();
      item.shippingFees = shippingFees
      item.exchangeType = exchangeType ? exchangeType : 1;
      item.exchangeMoney = exchangeMoney;
      item.exchangePoints1 = exchangeType == 1 ? exchangePoints : null;
      item.exchangePoints2 = exchangeType == 2 ? exchangePoints : null;
      item.exchangeCount = exchangeCount;
      item.issueCount = issueCount;
      item.isMemberClass = isMemberClass;
      item.memberClasss = memberClasss;
      item.shopID = shopID;
      item.shopName = shopName;
      item.takeType = takeType ? takeType : 1;
      item.takeBeginDate = takeBeginDate;
      item.takeEndDate = takeEndDate;
      item.isLimitCount = isLimitCount;
      item.isLimit = isMemberClass == 1 ? true : false;
      item.shopPrice = shopPrice;
      item.storePrice = storePrice;
      item.sumNumber = sumNumber;
      state.formData = { ...item };
      //   console.log(item);
      return {
        ...state,
      };
    }
    default:
      return state;
  }
}

export function FormStoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialArgs);

  const v = { state, dispatch };
  return <FormStore.Provider value={v}>{props.children}</FormStore.Provider>;
}
