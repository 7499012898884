import { message } from "antd";
// import axios from "axios";
import { saveAs } from 'file-saver';
import Cookies from "js-cookie";
import { DataTableAction } from "../../../../component/table/action/data_table_action";
import { Tools, axios } from "../../../../config";
import { FlowChargeOffdLogic } from "../../../../logic/flowChargeoff_logic";

export class FileAction extends DataTableAction {
    delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {
        throw new Error("Method not implemented.");
    }
    async loadPage({ dispatch, pageIndex, pageSize, search }) {
        this.setLoading({dispatch, loading:true})
        // 储值流水列表
        const { keywords, storedNumber, startDateTime, endDateTime,shopId } = search;
        let param: any = {
            // startDateTime: startDateTime,
            // endDateTime: endDateTime,
            // storedNumber: storedNumber,
            shopId:shopId==''?null:shopId,
            seacherKey: keywords,
            pageIndex,
            pageSize,
            orderBy: '',
            sort: ''
        }
        

        const r = await new FlowChargeOffdLogic().querysList(param);

        if (r.data.statusCode == 200) {
            const dataList = r.data.data.dataSource;

            const list = dataList.map((v, i) => {
                v.pay = v.pay == 1 ? '支付宝支付' : v.pay == 2 ? '微信支付' : v.pay == 3 ? '银联支付' : '现金支付' 
                v.key = i + 1;
                return v;
            })

            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: list, pageSize, loading: false });
        } else{
            this.setLoading({dispatch, loading: false})
        }
    }
    // 导出
    async exportList({ search }) {
        // 储值流水列表
        const { keywords, storedNumber, operationName, startDateTime, endDateTime,shopID } = search;
        let param: any = {
            startDateTime: startDateTime,
            endDateTime: endDateTime,
            storedNumber: storedNumber,
            keyWords: keywords,
            shopID:shopID==''?null:shopID,
            orderBy: '',
            sort: ''
        }
        if (-1 != operationName) {
            param.operationId = operationName;
        }

        const r = await axios({
            method: "POST",
            url: Tools.getApiAddress("menber_store_export"),
            responseType: 'arraybuffer',
            data: param,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })
        if (r.status == 200) {
            let enc = new TextDecoder('utf-8')
            let data = enc.decode(new Uint8Array(r.data))
            if (data.indexOf('statusCode') != -1) {
                let datas = JSON.parse(data)
                if (datas.statusCode == 200) {
                    let blob = new Blob([r.data], { type: 'application/vnd.ms-excel' })
                    saveAs(blob, "储值流水.xlsx");
                } else {
                    message.error(datas.returnMessage)
                }
            } else {
                let blob = new Blob([r.data], { type: 'application/vnd.ms-excel' })
                saveAs(blob, "储值流水.xlsx");
            }
        }

    }
    setCondition(method: "Keywords" | "StoredNumber" | "OperationName" | "StartDateTime" | "EndDateTime" | 'shopName' | 'shopId', value, dispatch) {

        if ("Keywords" == method) {
            dispatch({ type: "SetSearch", payload: { keywords: value } });
        }
        else if ("StoredNumber" == method) {
            dispatch({ type: "SetSearch", payload: { storedNumber: value } });
        }
        else if ("OperationName" == method) {
            dispatch({ type: "SetSearch", payload: { operationName: value } });
        }
        else if ("StartDateTime" == method) {
            dispatch({ type: "SetSearch", payload: { startDateTime: value } });
        }
        else if ("EndDateTime" == method) {
            dispatch({ type: "SetSearch", payload: { endDateTime: value } });
        } else if ("shopName" == method) {
            dispatch({ type: "SetSearch", payload: { shopName: value } });
        } else if ("shopId" == method) {
            dispatch({ type: "SetSearch", payload: { shopId: value } });
        }
    }
}
export const fileAction = new FileAction();