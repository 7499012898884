import { useBoolean } from "ahooks";
import { ColumnsType } from "antd/lib/table";
import styles from "./modal.module.scss";
import { TopicStore, ITopicValue } from "../../store/topic";
import { TopicDataAction } from "../../action/TopicDataAction";
import {
  Button,
  Table,
  Input,
  Select,
  Form,
  Cascader,
  Tag,
  message,
} from "antd";
import { useContext, useState, useEffect } from "react";
import { useRequest } from "ahooks";
import { Select as SelectAntd } from "antd";
import {
  TabsStyle,
  TabList,
  Tab,
  TabPanel,
} from "./../../../../component/react-tabs";
import { Search } from "./../../../../component/search";
import { DataTable } from "../../../../component/table/view/data_table";
import { Tips, TipsGroup } from "../../../../component/tips";
import { Tools } from "../../../../config";
import { Mask } from "../../../../component/mask";
import img_def from "../../../../assets/images/goods/goods_def.jpg";
import {
  ChooseGoodsStore,
  ChooseStoreProvider,
} from "../../../../component/choose-goods/store/choose_store";
import { chooseGoodsAction } from "../../../../component/choose-goods/action/choose_action";
import { defualtPageSize, Progress } from "../../../../logic/common_logic";
import { InventorySearch } from "../../../marketing/view/coupon/file_search";
import { seckillAction as couponAction } from "../../../marketing/action/coupon/file_action";
import {
  CouponStore,
  CouponStoreProvider,
} from "../../../marketing//store/coupon/file_store";
import {
  SeckillStore,
  SeckillStoreProvider,
} from "../../../marketing/store/seckill/file_store";
import { InventorySearch as InventorySearchSeckill } from "../../../marketing/view/seckill/file_search";
import { seckillAction } from "../../../marketing/action/seckill/file_action";
import {
  DataTableStoreProvider,
  DataTableStore,
} from "../../../../component/table/store/data_table_store";
import { INavData, IAdvData } from "../../enum/dataType";
import { DataTable as FlashGoodsTable } from "../../../marketing/view/seckill/seckill_from_basic";
import { SeckillFormStoreProvider } from "../../../marketing/store/seckill/form_store";
import { SeckillLogic } from "../../../../logic/seckill_logic";

import {
  ChooseGoodsStore as ChooseClassifyStore,
  ChooseStoreProvider as ChooseClassifyStoreProvider,
} from "../../../../component/choose-classify/store/choose_store";
import { chooseGoodsAction as chooseClassifyAction } from "../../../../component/choose-classify/action/choose_action";
import Cookies from "js-cookie";
/** 选择后的数据 */
export interface ISelectUrl {
  /** 一个key */
  key;
  /** 类别 */
  type: string;
  /** 标题 */
  title: string;
  /** 路径 */
  url: string;
  /** 图片路径 */
  imgUrl?: string;
  isInit?: boolean;
  goodsSource?:number
}

interface IShowdataParams {
  comId?;
  index?;
  close?;
  state?;
  dispatch?;
  selectedBack?: (select: ISelectUrl) => void;
}
interface ICommodity {
  comId?;
  index?;
  clo?;
  dispatch?;
  paginationProps?;
  selectedBack: (select: ISelectUrl) => void;
}
const imgParams = {
  url: "",
};
export function ShowData({
  comId,
  index,
  close,
  state,
  dispatch,
  selectedBack,
}: IShowdataParams) {
  const {
    total,
    pageSize,
    current,
    dataList,
    selectedRowKeys,
    comList,
    activeId,
  } = state;
  const paginationProps = {
    showSizeChanger: false,
    showQuickJumper: false,
    showTotal: () => `共${total}条`,
    pageSize: pageSize,
    current: current,
    total: total,
    onChange: (current) => {
      // clearSelectedRowKeys({ dispatch });
      // onChange && onChange(current);
    },
  };
  const topicStoreState = useContext(TopicStore);
  //   const selected =(tableState) => {
  //     const actionArg: [ITopicValue, number] = [topicStoreState, comId]
  //     if(tableState.selectList.length>0)
  //     {
  //         switch(topicStoreState.state.comList[comId].type){
  //             case 'navimg':
  //                 const navdata: INavData[] = TopicDataAction.getData<INavData[]>(...actionArg);
  //                 navdata[index].selectUrl = tableState.selectList[0]
  //                 TopicDataAction.setData<INavData[]>(...actionArg,navdata)
  //             break;
  //             case 'advimg':
  //                 const advdata: IAdvData = TopicDataAction.getData<IAdvData>(...actionArg);
  //                 advdata.datalist[index].selectUrl = tableState.selectList[0]
  //                 TopicDataAction.setData<IAdvData>(...actionArg, advdata);
  //             break;        }}
  // }

  return (
    <div style={{ marginTop: "-10px" }}>
      <TabsStyle style="Full">
        <TabList>
          <Tab>商品</Tab>
          <Tab>优惠券</Tab>
          <Tab>秒杀</Tab>
          <Tab>分类</Tab>
        </TabList>
        <TabPanel>
          <ChooseStoreProvider>
            <DataTableStoreProvider>
              {" "}
              <Commodity selectedBack={selectedBack} clo={close} />
            </DataTableStoreProvider>
          </ChooseStoreProvider>
        </TabPanel>
        <TabPanel>
          <CouponStoreProvider>
            <DataTableStoreProvider>
              <Coupon
                clo={close}
                selectedBack={selectedBack}
                paginationProps={paginationProps}
              />
            </DataTableStoreProvider>
          </CouponStoreProvider>
        </TabPanel>
        <TabPanel>
          <SeckillStoreProvider>
            <DataTableStoreProvider>
              <FlashsaleCom
                clo={close}
                selectedBack={selectedBack}
                paginationProps={paginationProps}
              />
            </DataTableStoreProvider>
          </SeckillStoreProvider>
        </TabPanel>
        <TabPanel>
          <ChooseClassifyStoreProvider>
            <DataTableStoreProvider>
              <Classify
                clo={close}
                selectedBack={selectedBack}
                paginationProps={paginationProps}
              />
            </DataTableStoreProvider>
          </ChooseClassifyStoreProvider>
        </TabPanel>
      </TabsStyle>
    </div>
  );
}

export function Commodity({ clo, selectedBack, paginationProps }: ICommodity) {
  const { state, dispatch } = useContext(ChooseGoodsStore);
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);
  const columns: ColumnsType = [
    {
      title: "商品图片",
      // dataIndex: "goodsImage",
      key: "2",
      align: "center",
      render: (v,r:any,i) => (
        <div
          style={{
            width: 80,
            height: 60,
            backgroundColor: "#fefaeb",
            margin: ".625rem auto",
            cursor: "pointer",
          }}
          onClick={() => {
            imgParams.url = r.goodsImage ? Tools.getImageAddress(r.goodsImage,v.goodsSource) : img_def;
          }}
        >
          <img
            src={r.goodsImage ? Tools.getImageAddress(r.goodsImage,v.goodsSource) : img_def}
            width={80}
            height={60}
          />
        </div>
      ),
    },
    {
      title: "商品名称",
      dataIndex: "goodsName",
      key: "3",
      align: "center",
    },
    {
      title: "商品编码",
      dataIndex: "goodsNo",
      key: "4",
      align: "center",
    },
    {
      title: "商品分类",
      dataIndex: "goodsClassName",
      key: "5",
      align: "center",
    },
    {
      title: "基础单位",
      // dataIndex: 'unit',
      key: "6",
      align: "center",
      render: (v) => (
        <span>
          {v.unitContent}
          {v.unitName}
        </span>
      ),
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "7",
      align: "center",
      render: (v) => (
        <Tag color={1 === v ? "success" : "error"}>
          {1 === v ? "正常" : "停售"}
        </Tag>
      ),
    },
  ];

  const selected = () => {
    if (tableState.selectList.length > 0) {
      let select = tableState.selectList[0];
      selectedBack({
        key: select.id,
        type: "商品",
        title: select.goodsName,
        imgUrl: select.goodsImage,
        goodsSource: select.goodsSource,
        url: "___",
      });
      clo();
    } else {
      message.error("没有选择商品");
    }
  };

  const action = chooseGoodsAction;
  const onSearch = async (pageIndex) => {
    await action.loadPageSpecificationForMini({
      pageIndex: pageIndex,
      state,
      pageSize: defualtPageSize(),
      dispatch: tableDispatch,
      search: state.search,
    });
  };

  useEffect(() => {
    return () => {};
  }, []);
  useRequest(() => action.init({ dispatch }));
  return (
    <div style={{ marginTop: "-0.9rem" }}>
      <div className={styles.header_search}>
        <Search>
          <Input
            maxLength={20}
            allowClear
            placeholder="请输入商品名称、商品编码"
            onChange={(e) =>
              action.setCondition("Keywords", e.target.value, dispatch)
            }
          />
          <input type="button" value="快速查找" onClick={() => onSearch(1)} />

          {/* <TipsGroup>
                        <input type="button" value="高级查找" onClick={() => searchFunc.toggle()} /> 
                        <Tips width="45rem" display={searchState}>
                            <FileSearch onSearch={onSearch} />
                        </Tips>
                        <Mask visible={searchState} onClick={() => searchFunc.setFalse()} /> 
                    </TipsGroup> */}
        </Search>
      </div>
      <DataTable
        columns={columns}
        action={action}
        onLoad={async () => {
          await onSearch(1);
        }}
        onChange={(pageIndex) => {
          onSearch(pageIndex);
        }}
        scroll={{ y: 400 }}
        rowSelection={{
          type: "radio",
          getCheckboxProps: (record) => ({
            disabled: record.status == 2 || record.sumNumber == 0, // Column configuration not to be checked
          }),
        }}
        selectColumn={true}
        // rowKey={(record) => record.id}
      />
      {tableState.dataList.length != 0 ? (
        <div style={{ paddingTop: "24px" }}>
          <Button
            style={{
              background: "#f7ba00",
              borderColor: "#f7ba00",
              marginRight: 10,
            }}
            type="primary"
            onClick={() => {
              clo();
            }}
          >
            取消
          </Button>
          <Button
            type="primary"
            onClick={() => {
              selected();
            }}
          >
            确定
          </Button>
        </div>
      ) : null}
    </div>
  );
}

// 优惠券名称，面额，商家，使用条件，有效期，操作
function Coupon({ clo, selectedBack, paginationProps }) {
  const [searchState, searchFunc] = useBoolean(false);

  const { state, dispatch } = useContext(CouponStore);
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);

  const action = couponAction;
  const columns: ColumnsType = [
    {
      title: "优惠券名称",
      dataIndex: "couponName",
      key: "3",
      align: "center",
    },
    {
      title: "类型",
      dataIndex: "couponType",
      key: "4",
      align: "center",
      render: (v) => (
        <>
          {v == 2 && <Tag color="success">直减券</Tag>}
          {v == 1 && <Tag color="error">折扣券</Tag>}
        </>
      ),
    },
    {
      title: "面额/折扣",
      key: "5",
      align: "center",
      render: (v) => (
        <span>
          {v.couponType == 2 ? v.subMoney + "元" : v.discount / 10 + "折"}
        </span>
      ),
    },
    {
      title: "使用条件",
      key: "6",
      align: "center",
      render: (v) => <span>满{v.meetMoney}元可用</span>,
    },
    {
      title: "领券方式",
      dataIndex: "getCouponType",
      key: "7",
      align: "center",
      render: (v) => (
        <>
          {v == 1 && <Tag color="success">主动领取</Tag>}
          {v == 2 && <Tag color="error">系统发放</Tag>}
          {v == 3 && <Tag color="geekblue">积分兑换</Tag>}
        </>
      ),
    },
    {
      title: "剩余数量",
      dataIndex: "couponNumber",
      key: "8",
      align: "center",
    },
    {
      title: "领取数量",
      dataIndex: "getCouponCount",
      key: "8",
      align: "center",
    },
    {
      title: "使用数量",
      dataIndex: "useCouponCount",
      key: "8",
      align: "center",
    },
    {
      title: "有效期",
      key: "8",
      align: "center",
      width: 160,
      render: (v) => (
        <span>
          {v.validStartDate}~{v.validEndDate}
        </span>
      ),
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "8",
      align: "center",
      render: (v) => (
        <>
          {v == 1 && <Tag color="success">启用</Tag>}
          {v == 2 && <Tag color="error">禁用</Tag>}
        </>
      ),
    },
  ];

  const selected = () => {
    if (tableState.selectList.length > 0) {
      let select = tableState.selectList[0];
      selectedBack({
        key: select.id,
        type: "优惠券",
        title: select.couponName,
        getCouponType: select.getCouponType,
        url: "___",
      });
      clo();
    } else {
      message.error("还没有选择优惠劵");
    }
  };
  const onSearch = async (pageIndex) => {
    await action.loadPage({
      pageIndex: pageIndex,
      pageSize: defualtPageSize(),
      dispatch: tableDispatch,
      search: state.search,
    });
  };
  return (
    <div style={{ marginTop: "-0.9rem" }}>
      <div className={styles.header_search}>
        <Search>
          <Input
            maxLength={20}
            allowClear
            placeholder="请输入优惠券名称、编码"
            onChange={(e) =>
              action.setCondition({
                dispatch,
                key: "couponName",
                value: e.target.value,
              })
            }
          />
          <input type="button" value="快速查找" onClick={() => onSearch(1)} />

          {/* <TipsGroup>
                    <input type="button" value="高级查找" onClick={() => searchFunc.toggle()} />
                    <Tips display={searchState} width="48rem">
                        <InventorySearch onSearch={()=> onSearch(1)} />
                    </Tips>
                    <Mask visible={searchState} onClick={() => searchFunc.setFalse()} />
                </TipsGroup> */}
        </Search>
      </div>
      <DataTable
        columns={columns}
        action={action}
        onLoad={async () => {
          await onSearch(1);
          Progress.done();
        }}
        onChange={(pageIndex) => {
          onSearch(pageIndex);
        }}
        scroll={{ scrollToFirstRowOnChange: true, autoY: true, x: 1060 }}
        rowSelection={{ type: "radio" }}
        selectColumn={true}
      />
      {tableState.dataList.length != 0 ? (
        <div style={{ paddingTop: "24px" }}>
          <Button
            style={{
              background: "#f7ba00",
              borderColor: "#f7ba00",
              marginRight: 10,
            }}
            type="primary"
            onClick={() => {
              clo();
            }}
          >
            取消
          </Button>
          <Button
            type="primary"
            onClick={() => {
              selected();
              clo();
            }}
          >
            确定
          </Button>
        </div>
      ) : null}
    </div>
  );
}

function FlashsaleCom({ clo, selectedBack, paginationProps }) {
  const action = seckillAction;
  const [searchState, searchFunc] = useBoolean(false);
  const [scaleId, setScaleId] = useState(null);

  const { state, dispatch } = useContext(SeckillStore);
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);
  const [dataList, setList] = useState([]);
  const getSnap = async (id) => {
    const item = await new SeckillLogic().selDataByWarehousing(id);
    let list = item ? item.goodsList : [];
    let ll = [];
    if (list.length > 0) {
      list.map((l) => {
        if (l.goodsSep && l.goodsSep.length > 0) {
          l.goodsSep.map((sep) => {
            ll.push({
              specificationID: sep.id,
              ...sep,
              ...l,
            });
          });
        }
      });
      setList(ll);
    }
  };
  const columns: ColumnsType = [
    {
      title: "",
      dataIndex: "",
      render: (record, index) => {
        return (
          <input
            type="radio"
            name="goods"
            value={record}
            disabled={record.dataState == 2}
            onChange={(e) => {
              getSnap(record.id);
              setScaleId(record.id);
            }}
          ></input>
        );
      },
    },
    {
      title: "活动名称",
      dataIndex: "snappedName",
      key: "2",
      align: "center",
      width: 140,
    },
    {
      title: "开始时间",
      dataIndex: "beginTime",
      key: "3",
      align: "center",
    },
    {
      title: "结束时间",
      dataIndex: "endTime",
      key: "4",
      align: "center",
    },
    {
      title: "活动状态",
      // dataIndex: "dataState",
      key: "6",
      align: "center",
      render: (v) => (
        <>
          <Tag
            color={
              v.dataState == 0
                ? "geekblue"
                : v.dataState == 1
                ? "success"
                : "volcano"
            }
          >
            {v.dataStateString}
          </Tag>
        </>
      ),
    },
    {
      title: "创建人",
      dataIndex: "addUID",
      key: "7",
      align: "center",
    },
    {
      title: "创建时间",
      dataIndex: "addTime",
      key: "8",
      align: "center",
    },
  ];
  const [selectGood, setGood] = useState(null);
  if (tableState.selectList.length > 0) {
  }
  const columnsGoods: ColumnsType = [
    {
      title: "",
      dataIndex: "",
      render: (record, index) => {
        return (
          <input
            type="radio"
            name="goods"
            value={record}
            onChange={(e) => {
              setGood(record);
            }}
          ></input>
        );
      },
    },
    {
      title: "商品编码",
      dataIndex: "goodsCode",
      key: "2",
      align: "center",
      width: 100,
    },
    {
      title: "商品名称",
      dataIndex: "goodsName",
      key: "3",
      align: "center",
      width: 100,
    },
    {
      title: "商品分类",
      dataIndex: "classesName",
      key: "4",
      align: "center",
      width: 100,
    },

    {
      title: "单位",
      // dataIndex: 'goodsUnitName',
      key: "8",
      align: "center",
      width: 120,
      render: (record) => (
        <span>
          {record.unitContent}
          {record.unitName}
        </span>
      ),
    },
    {
      title: "商城价",
      dataIndex: "storePrice",
      key: "8",
      align: "center",
      width: 120,
    },
    {
      title: "活动价",
      dataIndex: "snappedPrice",
      key: "8",
      align: "center",
      width: 120,
    },
    {
      title: "秒杀库存",
      dataIndex: "snappedCount",
      key: "8",
      align: "center",
      width: 120,
    },
    {
      title: "单人限购",
      dataIndex: "singleCount",
      key: "8",
      align: "center",
      width: 120,
    },
  ];
  const paginationPropsGoods = {
    total: dataList.length,
    showTotal: (total) => `共 ${total} 条`,
    showSizeChanger: false,
    pageSize: 10,
  };

  const selected = () => {
    if (selectGood) {
      let select = selectGood;
      console.log(select);
      selectedBack({
        key: {
          goodsID: select.goodsID,
          seckillID: scaleId,
          sepID: select.specificationID,
        },
        type: "限时购",
        title: select.goodsName,
        imgUrl: select.specificationImage || select.goodsImage,
        url: "___",
      });
      clo();
    } else {
      message.error("还没有选择限时购商品");
    }
  };
  let { shopID, shopName } = JSON.parse(Cookies.get("loginMsg"));
  const onSearch = async (pageIndex) => {
    await action.loadPage({
      pageIndex: pageIndex,
      pageSize: 1,
      dispatch: tableDispatch,
      search: { shopId: [shopID], ...state.search },
    });
  };

  return (
    <div style={{ marginTop: "-0.9rem" }}>
      <div className={styles.header_search}>
        <Search>
          <Input
            maxLength={20}
            allowClear
            placeholder="请输入活动名称"
            onChange={(e) =>
              action.setCondition({
                dispatch,
                key: "name",
                value: e.target.value,
              })
            }
          />
          <input type="button" value="快速查找" onClick={() => onSearch(1)} />

          {/* <TipsGroup>
                    <input type="button" value="高级查找" onClick={() => searchFunc.toggle()} />
                    <Tips display={searchState} width="48rem">
                        <InventorySearchSeckill onSearch={()=> onSearch(1)} />
                    </Tips>
                    <Mask visible={searchState} onClick={() => searchFunc.setFalse()} />
                </TipsGroup> */}
        </Search>
      </div>
      <DataTable
        columns={columns}
        action={action}
        // rowKey={(record) => record.id}
        onLoad={async () => {
          await onSearch(1);
          Progress.done();
        }}
        onChange={(pageIndex) => {
          onSearch(pageIndex);
        }}
        scroll={{ scrollToFirstRowOnChange: true, y: 800 }}
        // rowSelection={{ type:'radio' }}
        // selectColumn={true}
        // expandable={{}}
      />
      {
        <SeckillFormStoreProvider>
          <Table
            columns={columnsGoods}
            // rowKey={(record: any) => record.id}
            dataSource={dataList}
            pagination={paginationPropsGoods}
            scroll={{ y: 300 }}
          />
        </SeckillFormStoreProvider>
      }
      {tableState.dataList.length != 0 ? (
        <div style={{ paddingTop: "24px" }}>
          <Button
            style={{
              background: "#f7ba00",
              borderColor: "#f7ba00",
              marginRight: 10,
            }}
            type="primary"
            onClick={() => {
              clo();
            }}
          >
            取消
          </Button>
          <Button
            type="primary"
            onClick={() => {
              selected();
            }}
          >
            确定
          </Button>
        </div>
      ) : null}
    </div>
  );
}
// export function Classify({ clo, selectedBack, paginationProps }) {
//   const { state, dispatch } = useContext(ChooseGoodsStore);
//   const { state: tableState, dispatch: tableDispatch } =
//     useContext(DataTableStore);
//   const columns: ColumnsType = [
//     {
//       title: "商品分类",
//       dataIndex: "title",
//       key: "key",
//       align: "center",
//     },
//   ];

//   const selected = () => {
//     if (tableState.selectList.length > 0) {
//       let select = tableState.selectList[0];
//       selectedBack({
//         key: select.id,
//         type: "商品",
//         title: select.goodsName,
//         imgUrl: select.goodsImage,
//         url: "___",
//       });
//       clo();
//     } else {
//       message.error("没有选择商品");
//     }
//   };

//   const action = chooseGoodsAction;
//   const onSearch = async (pageIndex) => {
//     await action.loadPageSpecificationForMini({
//       pageIndex: pageIndex,
//       state,
//       pageSize: defualtPageSize(),
//       dispatch: tableDispatch,
//       search: state.search,
//     });
//   };

//   useEffect(() => {
//     return () => {};
//   }, []);
//   useRequest(() => action.init({ dispatch }));
//   return (
//     <div style={{ marginTop: "-0.9rem" }}>
//       <div className={styles.header_search}>
//         <Search>
//           <Input
//             maxLength={20}
//             allowClear
//             placeholder="请输入商品名称、商品编码"
//             onChange={(e) =>
//               action.setCondition("Keywords", e.target.value, dispatch)
//             }
//           />
//           <input type="button" value="快速查找" onClick={() => onSearch(1)} />

//           {/* <TipsGroup>
//                         <input type="button" value="高级查找" onClick={() => searchFunc.toggle()} />
//                         <Tips width="45rem" display={searchState}>
//                             <FileSearch onSearch={onSearch} />
//                         </Tips>
//                         <Mask visible={searchState} onClick={() => searchFunc.setFalse()} />
//                     </TipsGroup> */}
//         </Search>
//       </div>
//       <DataTable
//         columns={columns}
//         action={action}
//         onLoad={async () => {
//           await onSearch(1);
//         }}
//         onChange={(pageIndex) => {
//           onSearch(pageIndex);
//         }}
//         scroll={{ y: 400 }}
//         rowSelection={{
//           type: "radio",
//           getCheckboxProps: (record) => ({
//             disabled: record.status == 2 || record.sumNumber == 0, // Column configuration not to be checked
//           }),
//         }}
//         selectColumn={true}
//         rowKey={(record) => record.id}
//       />
//       {tableState.dataList.length != 0 ? (
//         <div style={{ paddingTop: "24px" }}>
//           <Button
//             style={{
//               background: "#f7ba00",
//               borderColor: "#f7ba00",
//               marginRight: 10,
//             }}
//             type="primary"
//             onClick={() => {
//               clo();
//             }}
//           >
//             取消
//           </Button>
//           <Button
//             type="primary"
//             onClick={() => {
//               selected();
//             }}
//           >
//             确定
//           </Button>
//         </div>
//       ) : null}
//     </div>
//   );
// }

// 优惠券名称，面额，商家，使用条件，有效期，操作
function Classify({ clo, selectedBack, paginationProps }) {
  const [searchState, searchFunc] = useBoolean(false);

  const { state, dispatch } = useContext(ChooseClassifyStore);
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);
  tableState.dataList = tableState.dataList.map((v) => {
    v.children = null;
    return {
      children: v.children,
      parentID: v.parentID,
      key: v.key,
      title: v.title,
    };
  });
  const columns: ColumnsType = [
    {
      title: "商品分类",
      dataIndex: "title",
      key: "key",
      align: "center",
    },
  ];

  const selected = () => {
    if (tableState.selectList.length > 0) {
      let select = tableState.selectList[0];
      selectedBack({
        key: select.key,
        type: "分类",
        title: select.title,
        url: "___",
      });
      clo();
    } else {
      message.error("还没有选择分类");
    }
  };
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      tableState.selectList = selectedRows;
    },
    onSelect: (record, selected, selectedRows) => {
      //console.log(record, selected, selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      //console.log(selected, selectedRows, changeRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.parentID != "", // Column configuration not to be checked
    }),
  };
  let tableRowSelection: any = {};
  tableRowSelection = {
    rowSelection: {
      type: "radio",
    },
  };
  const onSearch = async (pageIndex) => {
    await action.loadPage({
      pageIndex: pageIndex,
      pageSize: defualtPageSize(),
      dispatch: tableDispatch,
      search: state.search,
    });
  };
  const action = chooseClassifyAction;
  useRequest(() => action.init({ dispatch }));
  return (
    <div>
      {/* <Table
            columns={columns}
            rowKey={(record: any) => record.id}
            dataSource={tableState.dataList}

            scroll={{ y: 300 }}
          /> */}
      <DataTable
        action={action}
        onLoad={async () => {
          await onSearch(1);
        }}
        columns={columns}
        scroll={{ y: 400 }}
        // rowKey={(record) => record.key}
        rowKey='key'
        rowSelection={{ ...rowSelection }}
        {...tableRowSelection}
        onChange={(pageIndex) => {
          onSearch(pageIndex);
        }}
        selectColumn={true}
      />

      {tableState.dataList.length != 0 ? (
        <div style={{ paddingTop: "24px" }}>
          <Button
            style={{
              background: "#f7ba00",
              borderColor: "#f7ba00",
              marginRight: 10,
            }}
            type="primary"
            onClick={() => {
              clo();
            }}
          >
            取消
          </Button>
          <Button
            type="primary"
            onClick={() => {
              selected();
              clo();
            }}
          >
            确定
          </Button>
        </div>
      ) : null}
    </div>
  );
}
export function DataTableseckill({ dataList, disabled }) {
  return;
}
