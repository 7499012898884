// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../config";
import { effeative } from "./common_logic";


export class EquipmentLogic {
    async addOperation(params) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("addBarCodeScales"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    async updateOperation(params) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("updateBarCodeScales"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    /**
     * 分页查询
     */

    async selList(params) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("queryBarCodeScalesByPage"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    
    async selData(id) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("updateBarCodeScales"),
            params: {
                id
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            }
        }))
        if (r.data && r.data.data) {
            return r.data.data;
        }
    }   

    async delData(id: string) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("deleteBarCodeScales"),
            params: {
                ID:id
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            }
        }))
        return r;
    }
    async getAll(){
        const r = effeative(await axios({
            method: 'GET',
            url: Tools.getApiAddress('queryAllBarCodeScales'),
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            }
        }))
        return r;
    }


    /**分页查询下发记录 */
   async getScaleRecordByPage(params){
    const r = effeative(await axios({
        method: "POST",
        url: Tools.getApiAddress("getScaleRecord"),
        data: params,
        headers: {
            SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
        },
    }))
    return r;
    }
    /**下发记录详细 */
    async getOneScale(LogID){
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("getScaleOne"),
            params: {
                LogID
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            }
        }))
        return r
    }
}