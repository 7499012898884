import { message } from "antd";
// import axios from "axios";
import Cookies from "js-cookie";
import { DataListAction } from "../../../../component/data-list/action/data_table_action";
import { DataTableAction } from "../../../../component/table/action/data_table_action";
import { Tools, axios } from "../../../../config";
import { ClassesLogic } from "../../../../logic/classes_logic";
import { effeative } from "../../../../logic/common_logic";
import { UnitLogic } from "../../../../logic/unit_logic";
import { saveAs } from "file-saver";
export class FileTableAction extends DataTableAction {
  private classesLgc = new ClassesLogic();
  private unitLgc = new UnitLogic();

  async init({ dispatch }) {
    // 商品分类
    dispatch({
      type: "LoadClasses",
      payload: await this.classesLgc.selListByTree(),
    });
    // 商品单位
  }
  async delRow(id) {
    const r = await this.classesLgc.delGoodsClassify({id:id})
    if(r.data.statusCode === 200){
      message.success("删除成功")
    }else{
      message.error(r.data.returnMessage)
    }
  }
  async delSelectList({
    dispatch,
    selectList,
  }: {
    dispatch: any;
    selectList: any;
  }) {
    const lis = selectList.map(async ({ id }) => {
      return axios({
        method: "GET",
        url: Tools.getApiAddress("goods_del"),
        params: {
          goodsId: id,
        },
        headers: {
          SYSTOKEN: Cookies.get("token"),
          ClientSource: "web",
        },
      });
    });
    await Promise.all(lis);

    message.success("删除成功");
  }
  async loadPage({ dispatch, pageIndex, pageSize, search }) {
    this.setLoading({ dispatch, loading: true });
    // 商品分类
    const res = await this.classesLgc.selListByTree()  || []
   
    this.setPaging({
      dispatch,
      pageIndex,
      total: res.length,
      dataList: res,
      pageSize,
      loading: false,
    });
  }
  setCondition(
    method: "Keywords" | "Classes" | "Status" | "Type" | "Brand",
    value,
    dispatch
  ) {
    if ("Keywords" == method) {
      dispatch({ type: "SetSearch", payload: { keywords: value } });
    } else if ("Classes" == method) {
      dispatch({ type: "SetSearch", payload: { classesId: value } });
    } else if ("Status" == method) {
      let s = value;
      if (value == -1) {
        s = "";
      }
      dispatch({ type: "SetSearch", payload: { statusId: s } });
    } else if ("Type" == method) {
      dispatch({ type: "SetSearch", payload: { typeId: value } });
    } else if ("Brand" == method) {
      dispatch({ type: "SetSearch", payload: { brandId: value } });
    }
  }
}
export const fileAction = new FileTableAction();
