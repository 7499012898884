import { createContext, useReducer } from "react";
import { Tools } from "../../../config";
type Dispatch = { type; payload };
class menuItem {
  name: string;
  InfoContentType: number;
  id: string;
  childrens?: any[];
  IsParent:number = 0 ;
}
interface IState {
  menuList: any[];
  currentMenu: menuItem;
  selectKey: string;
  parentKey: string;
  currentType: number;
  content: any;
  search: {
    key: string;
    isSearch: boolean;
  };
  searchList: any[];
  tab_index: number;
}
const initialArgs = {
  tab_index: 0,
  currentMenu: new menuItem(),
  menuList: [],
  selectKey: "",
  parentKey: "",
  currentType: 1,
  content: "",
  search: {
    key: "",
    isSearch: false,
  },
  searchList: [],
};
interface IContextProps {
  state: IState;
  dispatch: ({ type }: Dispatch) => void;
}

export const HelpStore = createContext<IContextProps>(null);

function reducer(state: IState, { type, payload }: Dispatch) {
  switch (type) {
    case "Init":
      state.menuList = payload;
      state.currentMenu = payload[0];
      state.selectKey = "";
      if (state.menuList.length > 0 && state.menuList[0].childrens) {
        if (state.menuList[0].childrens[0].childrens) {
          state.selectKey = state.menuList[0].childrens[0].childrens[0].ID;
          state.parentKey = state.menuList[0].childrens[0].ID;
        } else {
          state.selectKey = state.menuList[0].childrens[0].ID;
        }
      }else if(payload[0].IsParent == 0){
        state.selectKey = state.menuList[payload].ID
        state.currentType = state.menuList[payload].InfoContentType
      }
      return {
        ...state,
      };
    case "MenuItem":
      return {
        ...state,
      };
    case "tab":
      state.tab_index = payload;
      state.currentMenu = state.menuList[payload];
      // state.selectKey = "";
      // state.parentKey = ''
      // state.currentType = null
     if (state.menuList[payload].childrens) {
        if (state.menuList[payload].childrens[0].childrens) {
          state.selectKey =
            state.menuList[payload].childrens[0].childrens[0].ID;
            state.currentType =  state.menuList[payload].childrens[0].childrens[0].InfoContentType
          state.parentKey = state.menuList[payload].childrens[0].ID;
        } else {
          state.selectKey = state.menuList[payload].childrens[0].ID;
          state.parentKey = state.menuList[payload].childrens[0].ID;
          state.currentType = state.menuList[payload].childrens[0].InfoContentType
        }
      }else if(state.menuList[payload].IsParent == 0){
        state.selectKey = state.menuList[payload].ID
        state.currentType = state.menuList[payload].InfoContentType
      }
      return {
        ...state,
      };
    case "searchClick":
      // state.selectKey = payload;
      // if (payload.type) {
      //   state.parentKey = payload.key;
      //   if (payload.key.Level == 1) {
      //     state.menuList.map((item, index) => {
      //       if (item.ID == payload.key.ParentID) {
      //         state.tab_index = index;
      //       }
      //     });
      //   } else if (payload.key.Level == 2) {
      //     state.parentKey = payload.key.ParentID;
      //     for (let i = 0; i < state.menuList.length; i++) {
      //       let item = state.menuList[i];
      //       for (let j = 0; j < item.childrens.length; j++) {
      //         let objj = item.childrens[j];
      //         if (objj.ID == payload.key.ParentID) {
      //           state.tab_index = i;
      //         }
      //       }
      //     }
      //   }
      //   state.currentMenu = state.menuList[state.tab_index];
      // } else {
      state.selectKey = payload.ID;
      state.tab_index = payload.tabIndex;
      state.parentKey = payload.level1ID ? payload.level1ID : "";
      state.currentMenu = state.menuList[payload.tabIndex];
      // }
      return {
        ...state,
      };
    case "Key":
      state.selectKey = payload;
      return {
        ...state,
      };
    case "type":
      state.currentType = payload;
      return {
        ...state,
      };
    case "content":
      state.content = payload;
      if(state.currentType ==1 && payload[0]&&payload[0].Content){
        let newstr = 'img class="contentImg"'
        let num = payload[0].Content.split('img').length - 1
        
       let str =   payload[0].Content.replace(/img/g,newstr)
         state.content = [{...payload[0],Content:str}]
      }
      return {
        ...state,
      };
    case "search":
      state.search = {
        key: payload.key,
        isSearch: payload.isSearch,
      };
      return {
        ...state,
      };
    case "searchList":
      state.searchList = payload;
      state.search = {
        key: state.search.key,
        isSearch: true,
      };
      return {
        ...state,
      };
    default:
      return {
        ...state,
      };
  }
}

export function HelpStoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialArgs);

  const v = { state, dispatch };
  return <HelpStore.Provider value={v}>{props.children}</HelpStore.Provider>;
}
