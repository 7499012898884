import { _tr, _td } from "../../tool/TableTool";
import {
  goodsTreeDataTablKey,
  googsDataTablKey,
} from "../tableKey/goodsRefundStatsTk";

export function GoodsRefundStatsTable({
  data,
  ifLastPage,
}: {
  data;
  ifLastPage: boolean;
}) {
  if (!data) {
    return <div></div>;
  }

  const subtotalTabSpan: number = 5;
  /** 总结需要用的key */
  const subtotalTabKeys = [...googsDataTablKey].splice(subtotalTabSpan - 2);
  /** 获取小计tabTds */
  const getSubtotalTabTr = (data): _tr => {
    return subtotalTabKeys.map((item) => {
      return (<td>{data[item.key]}</td>) as _td;
    });
  };

  const getTabTr = (data): _tr => {
    return googsDataTablKey.map((item) => {
      return (<td>{data[item.key]}</td>) as _td;
    });
  };
  // const getTabTrs1 = (data): _tr[] => {
  //   let _trs: _tr[] = [];
  //   data.forEach((item) => {
  //     _trs.push(getTabTr(item));
  //   });
  //   _trs[0].unshift((<td rowSpan={_trs.length}>{data.className}</td>) as _td);
  //   return _trs;
  // };
  const getTabTrs = (data, last: boolean): _tr[] => {
  debugger;  let _trs: _tr[] = [];
    data.goodsClass.forEach((item) => {
      _trs.push(...getTabTr(item));
    });
    //判断是否需要添加小计
    if (!last || ifLastPage) {
      // let _lastTr = getSubtotalTabTr(data.totoShopSale);
      // _lastTr.unshift(<td colSpan={4}>{data.shopName}小计</td> as _td);
      // _trs.push(_lastTr);
    }
    _trs.unshift((<td rowSpan={_trs.length}>{data.shopName}</td>) as _td);
    return _trs;
  };

  let _lastTr: _tr;
  //   if (ifLastPage && data.length > 0) {
  //     _lastTr = getSubtotalTabTr(data[0].totoSale);
  //     _lastTr.unshift((<td colSpan={5}>总计</td>) as _td);
  //   }
  const getTabTds1 = (data): _tr => {
    return googsDataTablKey.map((item, index) => {
      return (
        <td>
          { data[item.key]}
        </td>
      ) as _td;
    });
  };
  const getTabTds = (data): _tr[] => {
    let _trs: _tr[] = data.goodsRefund.map((item) => {
      return getTabTds1(item);
    });
   
    _trs[0] && _trs[0].unshift(
      (
        <td className="dark" rowSpan={_trs.length}>
          {data.shopName}
        </td>
      ) as _td
    );
    return _trs;
  };
  return (
    <>
      <thead>
        <tr>
          {[...goodsTreeDataTablKey, ...googsDataTablKey].map((item, index) => {
            return <th key={index + item.title}>{item.title}</th>;
          })}
        </tr>
      </thead>
      <tbody>
      {data.map((item) => {
          return getTabTds(item).map((item, index) => {
            return <tr key={index}>{item}</tr>;
          });
        })}
        {/* {_lastTr && <tr>{_lastTr}</tr>} */}
      </tbody>
    </>
  );
}
