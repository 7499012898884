import { createContext, useContext, useReducer } from "react";
import moment from "moment";

export const FileStore = createContext<IContextProps>(null);

let day = new Date().getDate();
let start = new Date().getTime() - (day - 1) * 24 * 60 * 60 * 1000;
let startTime = moment(start).format("yyyy-MM-DD");
let endTime = moment().format("yyyy-MM-DD");

class Condition {
  // 时间类型 0 月 1 日
  dateType: number = 0;
  startTime: string = startTime;
  endTime: string = endTime;
}
class GoodsTop {
  name: string = "";
  goodsNum: number = 0;
}

interface IState {
  searchDate: string;
  condition: Condition;
  goodsTop: GoodsTop[];
  memberSaleTop: any[];
  memberStoreTop: any[];
  newMemberLine: any[];
  memberExpendLine: any[];
  memberIncomeLine: any[];
  memberIncomeExpendLine: any[];
  refundListLine: any[];
  saleListLine: any[];
  orderTypePie: any[];
  payWayPie: any[];
  classSalePie: any[];
}

interface IContextProps {
  state: IState;
  dispatch: ({ type }: Dispatch) => void;
}

type Dispatch = { type; payload };
const initialArgs: IState = {
  searchDate: "",
  condition: new Condition(),
  goodsTop: [],
  memberSaleTop: [],
  memberStoreTop: [],
  newMemberLine: [],
  memberExpendLine: [],
  memberIncomeLine: [],
  memberIncomeExpendLine: [],
  refundListLine: [],
  saleListLine: [],
  orderTypePie: [],
  payWayPie: [],
  classSalePie: [],
};
function reducer(state: IState, { type, payload }: Dispatch) {
  switch (type) {
    case "SetCondition":
      return {
        ...state,
        condition: {
          ...state.condition,
          ...payload,
        },
      };
    case "InitList":
      // console.log(payload)
      state[payload.attribute] = payload.value;
      return {
        ...state,
        // ...payload
      };
    default:
      return {
        ...state,
      };
  }
}
export function FileStoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialArgs);
  const v = { state, dispatch };
  return <FileStore.Provider value={v}>{props.children}</FileStore.Provider>;
}
