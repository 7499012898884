// import axios from "axios";
import Cookies from "js-cookie";
import { Tools , axios } from "../config";
import { effeative } from "./common_logic";

interface ISelListByValue {
    "keyWords"?: string;
    "shopName"?: string;
    "pageIndex"?: number;
    "pageSize"?: number;
    "orderBy"?: string;
    "sort"?: string;
}
type IUpdWarningValue = {
    "id": string;
    "shopID": string;
    "goodsID": string;
    "maxNumber": number;
    "minNumber": number;
    "addTime": string;
    "addUID": string;
}[]
interface ISelList {
    "keyWords": string;
    "shopID": string;
    "goodsClass": string;
    "pageIndex": number;
    "pageSize": number;
    "orderBy": string;
    "sort": string;
}
export class AlarmLogic {
    /**
     * 预警值列表
     * @param params 
     */
    async selListByValue(params: ISelListByValue) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("alarmvalue_select_list"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    /**
     * 修改预警值
     * @param params 
     */
    async updWarningValue(params: IUpdWarningValue) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("alarmvalue_update_warningvalue_data"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    async selList(params: ISelList) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("alarm_select_list"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
}