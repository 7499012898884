import { useBoolean, useRequest } from "ahooks";
import { Input, Modal, Select, Tree, Button, TreeSelect,Image } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect, useState } from "react";
import { Search } from "../search";
import { DataTable } from "../table";
import {
  DataTableStore,
  DataTableStoreProvider,
} from "../table/store/data_table_store";
import { ChooseGoodsPurchaseStore, ChooseStoreProvider } from "./store/choose_store";
import styles from "./style/index.module.scss";
import { Select as SelectAntd } from "antd";
import { chooseGoodsAction } from "./action/choose_action";
import { defualtPageSize } from "../../logic/common_logic";
import img_def from "../../assets/images/goods/goods_def.jpg";
import { Tools } from "../../config";
let classId: string[] = [];
const imgParams = {
  url: "",
};
function Goods(params: IChooseGoodsPurchase) {
  let {
    visibleModal,
    close,
    filter,
    type,
    clearSelectedList,
  } = params;
  const { Option } = Select,
    action = chooseGoodsAction;
  const { state, dispatch } = useContext(ChooseGoodsPurchaseStore);
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);
    const [isModalVisible, isModalFunc] = useBoolean(false);
  const handleOk = () => {
    close && close(true, tableState.selectList);
  };

  const handleCancel = () => {
    close && close(false, null);
  };

  const columns: ColumnsType = [
    {
      title: "商品图片",
      // dataIndex: "goodsImage",
      key: "2",
      align: "center",
      width: 86,
      render: (v) => (
        <div
          style={{
            width: 60,
            height: 60,
            // backgroundColor: "#fefaeb",
            margin: ".52rem auto",
            cursor: "pointer",
          }}
          // onClick={() => {
          //   imgParams.url = v.goodsImg ? Tools.getImageAddress(v.goodsImg, 2) : img_def;
          //   isModalFunc.setTrue();
          // }}
        >
          {v.goodsImg && <Image  width={60}
            height={60}  src={ Tools.getImageAddress(v.goodsImg, 2)}/>}
         {!v.goodsImg && <img
            src={img_def}
            width={60}
            height={60}
            alt=""
          />}
        </div>
      ),
    },
    {
      title: "商品名称",
      dataIndex: "goodsName",
      key: "1",
      align: "center",
    },   
    {
      title: "商品分类",
      dataIndex: "goodsClassName",
      key: "3",
      align: "center",
    },
    {
      title: "规格单位",
      // dataIndex: 'goodsUnit',
      key: "4",
      align: "center",
      render: (v) => (
        <span>
          {/* {v.unitContent} */}
          1/
          {v.specsUnit}
        </span>
      ),
    },
    {
      title: "单价(元)",
      dataIndex: "purchasePrice",
      key: "6",
      align: "center",
    },
  ];

  const onSearch = async (pageIndex, classId?) => {
      await action.loadPage({
        pageIndex: pageIndex,
        pageSize: defualtPageSize(),
        dispatch: tableDispatch,
        state,
        search: { ...state.search, classesId: classId },
      });
  };

  useRequest(() => action.init({ dispatch }));

  const checkSelectable = (record) => {
    return false;
  };
  let tableRowSelection: any = {};
  if (type == "radio") {
    tableRowSelection = {
      rowSelection: {
        type: "radio",
        getCheckboxProps: (record) => ({
          disabled: checkSelectable(record), // Column configuration not to be checked
        }),
      },
    };
  }
 
  
  useEffect(() => {
    if (clearSelectedList) {
      action.clearSelectedRowKeys({ dispatch });
    }
    return () => {};
  });
  return (
    <Modal
      title="选择商品"
      visible={visibleModal}
      onOk={handleOk}
      onCancel={handleCancel}
      width={1000}
      cancelText="取消"
      okText="确定"
      footer={
        <>
          <Button
            type="primary"
            style={{
              backgroundColor: "#f7ba00",
              borderColor: "#f7ba00",
              color: "white",
            }}
            onClick={handleCancel}
          >
            取消
          </Button>
          <Button type="primary" onClick={handleOk}>
            确认
          </Button>
        </>
      }
    >
      <div className={styles.root}>
        <Search>
          {/* <Select
            style={{ width: "12rem", marginLeft: 10 }}
            size="small"
            onChange={(v) => action.setCondition("Status", v, dispatch)}
            defaultValue={-1}
          >
            {state.statusList.map((v) => (
              <SelectAntd.Option key={v.id} value={v.id}>
                {v.value}
              </SelectAntd.Option>
            ))}
          </Select> */}
          <Input
            maxLength={20}
            allowClear
            placeholder="请输入商品名称、编码"
            onChange={(e) =>
              action.setCondition("Keywords", e.target.value, dispatch)
            }
          />
          <input type="button" value="快速查找" onClick={() => onSearch(1)} />
        </Search>

        <div style={{ marginTop: 10, height: 400 }}>
          {/* <div className={styles.tree}>
            <TreeSelect
              style={{ width: "12rem", marginLeft: 10 }}
              showCheckedStrategy={TreeSelect.SHOW_PARENT}
              dropdownMatchSelectWidth={false}
              defaultValue={state.search.classesId}
              value={state.search.classesId}
              treeCheckable={true}
              treeData={state.areaList}
              onChange={(data) => {
                action.setCondition("Classes", data, dispatch);
                onSearch(1, data);
              }}
              placeholder="选择商品分类"
            />
          </div> */}
          <div className={styles.list} style={{width: '100%'}}>
            <DataTable
              columns={columns}
              action={action}
              // saveKeys={true}
              rowKey='goodsSpecsID'
              // rowSelection={{
              //   type: "checkbox",
              //   getCheckboxProps: (record) => ({
              //     disabled: checkSelectable(record), // Column configuration not to be checked
              //   }),
              // }}
              // {...tableRowSelection}
              onLoad={() => onSearch(1)}
              onChange={(pageIndex) => {
                onSearch(pageIndex);
              }}
              selectColumn={true}
            />
          </div>
        </div>
        <Modal
          className={styles.root}
          visible={isModalVisible}
          onOk={() => isModalFunc.setFalse()}
          onCancel={() => isModalFunc.setFalse()}
          centered
          width={"auto"}
        >
          <img src={imgParams.url} style={{maxWidth:'80%',maxHeight:'80%'}}/>
        </Modal>
      </div>
    </Modal>
  );
}

type Filter = {
  type?: boolean;
};
interface IChooseGoodsPurchase {
  visibleModal: boolean;
  close?: (status, r) => void;
  filter?: Filter;
  type?;
  shopID?;
  clearSelectedList?: boolean;
}
export function ChooseGoodsPurchase(params: IChooseGoodsPurchase) {
  return (
    <ChooseStoreProvider>
      <DataTableStoreProvider>
        <Goods {...params} />
      </DataTableStoreProvider>
    </ChooseStoreProvider>
  );
}
