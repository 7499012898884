import styles from "./help.module.scss";
import { Tabs, Menu, Empty,Image } from "antd";
import { useEffect, useState, useContext, useRef} from "react";
import { HelpStore } from "./store/help_store";
import { useBoolean, useDocumentVisibility, useRequest } from "ahooks";
import { helpAction } from "./action/help_action";
import play_img from "../../assets/images/help/play.png";
import cover_img from "../../assets/images/help/cover.png";
import { useHistory } from "react-router-dom";
//import axios from "axios";
import { Tools, ConfigApi, axios } from "../../config";
export function Text({ index }) {
  const action = helpAction;
  const { TabPane } = Tabs;
  const { SubMenu } = Menu;
  const [id, setId] = useState("01");
  const [curType, setCurType] = useState(1);
  const { state, dispatch } = useContext(HelpStore);
  const getCurrentType = (arr) => {
    let list = arr || [];
    for (let i = 0; i < list.length; i++) {
      let item = list[i];
      if (item.childrens) {
        getCurrentType(item.childrens);
      } else {
        if (item.ID == state.selectKey) {
          dispatch({ type: "type", payload: item.InfoContentType });
        }
      }
    }
  };
  useEffect(() => {
    return () => {};
  }, [state.selectKey, state.tab_index]);
  // useRequest(async () => {
  //   state.currentMenu.childrens
  //     ? getCurrentType(state.currentMenu.childrens)
  //     : dispatch({ type: "type", payload: state.currentMenu.InfoContentType });
  //   getCurrentType(state.currentMenu.childrens);
  //   await dispatch({ type: "MenuItem", payload: index });
  // });
  // console.log(state.currentKey);
  return (
    <div className={styles.text_b}>
      <div className={styles.menu}>
        <Menu key={index}
          defaultSelectedKeys={[state.selectKey]}
          defaultOpenKeys={[state.parentKey]}
          mode="inline"
          theme="light"
          //   inlineCollapsed={this.state.collapsed}
          style={{ fontSize: 18, background: "#F9F8F8" }}
          onClick={(key) => {
            console.log(key);
            let keyNum = "";

            keyNum = key.key;
            // }
            dispatch({ type: "Key", payload: keyNum });
            state.currentMenu.childrens
              ? getCurrentType(state.currentMenu.childrens)
              : dispatch({
                  type: "type",
                  payload: state.currentMenu.InfoContentType,
                });
            // console.log(state);
          }}
        >
          {state.currentMenu &&
            state.currentMenu.childrens &&
            state.currentMenu.childrens.length > 0 &&
            state.currentMenu.childrens.map((item, index) => {
              return !item.childrens ? (
                <Menu.Item key={item.ID} style={{ color: "#969696" }}>
                  {item.MenuName}
                </Menu.Item>
              ) : (
                <SubMenu
                  key={item.ID}
                  title={item.MenuName}
                  style={{ color: "#969696" }}
                >
                  {item.childrens &&
                    item.childrens.length > 0 &&
                    item.childrens.map((subMenu) => {
                      return (
                        <Menu.Item
                          key={subMenu.ID}
                          style={{ color: "#969696" }}
                        >
                          {subMenu.MenuName}
                        </Menu.Item>
                      );
                    })}
                </SubMenu>
              );
            })}
        </Menu>
      </div>
      {state.currentMenu ? (
        <div className={styles.content}>
          {state.currentType == 1 ? (
            <TextTabContent id={state.selectKey} />
          ) : state.currentType == 2 ? (
            <VideoTabContent />
          ) : (
            <QuestionTabContent></QuestionTabContent>
          )}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}
export const TextTabContent = ({ id }) => {
  const [visible, setVisible] = useState(false);
  const [imgUrl,setImgUrl] = useState('')
  const { state, dispatch } = useContext(HelpStore);
  async function* fetchData() {
    const r = await axios({
      method: "GET",
      url: Tools.getApiAddress("queryContentHelp").replace(
        ConfigApi.serviceDomain,
        ConfigApi.operationDomain
      ),
      params: {
        MenuID: state.selectKey,
      },
    });
    yield r.data.data;
  }
  useEffect(() => {
    (async function () {
      const fetch = fetchData();
      const r = (await fetch.next()).value;
      // setContent(r);
      dispatch({ type: "content", payload: r });
      console.log(r);
    })();
  }, [state.selectKey, state.tab_index]);
  const ref = useRef<HTMLImageElement>()
  useEffect(()=>{
   document.addEventListener('click',e=>{
    let arr = document.getElementsByClassName('contentImg')
    
   })
  })

  return (
    <div style={{ padding: "15px 20px" }} className={styles.contentInfo}>
           <Image
            width={200}
            style={{ display: 'none' }}
            src={imgUrl}
            preview={{
              visible,
              src: imgUrl,
              onVisibleChange: value => {
                setVisible(value);
              },
            }}
      />
      {state.content[0] ? (
        <div
          dangerouslySetInnerHTML={{ __html: state.content[0].Content }}
        ></div>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </div>
  );
};
export const VideoTabContent = () => {
  const { state, dispatch } = useContext(HelpStore);
  async function* fetchData() {
    const r = await axios({
      method: "GET",
      url: Tools.getApiAddress("queryContentHelp").replace(
        ConfigApi.serviceDomain,
        ConfigApi.operationDomain
      ),
      params: {
        MenuID: state.selectKey,
      },
    });
    yield r.data.data;
  }
  useEffect(() => {
    (async function () {
      const fetch = fetchData();
      const r = (await fetch.next()).value;
      // setContent(r);
      dispatch({ type: "content", payload: r });
      console.log(r);
    })();
  }, [state.selectKey, state.tab_index]);
  const his = useHistory();
  return (
    <div className={styles.videoContainer}>
      {state.content && state.content.length > 0 ? (
        state.content.map((item, index) => {
          return (
            <div
              className={styles.videoItems}
              onClick={() => {
                let url =
                  window.location.href.split("#")[0] +
                  "#/video?videosrc=" +
                  Tools.getImageAddress(item.Content).replace(
                    ConfigApi.serviceDomain,
                    ConfigApi.operationDomain
                  );
                window.open(url, "_blank");
              }}
            >
              <div>
                {/* <img src={item.img} alt={item.name} />*/}
                <div className={styles.mask}>
                  <img src={play_img} alt="mask" />
                </div>
                <video
                  style={{ width: "100%", height: "100%" }}
                  src={
                    Tools.getImageAddress(item.Content).replace(
                      ConfigApi.serviceDomain,
                      ConfigApi.operationDomain
                    ) || "https://www.w3school.com.cn/i/movie.ogg"
                  }
                ></video>
              </div>
              <span>{item.Title}</span>
            </div>
          );
        })
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </div>
  );
};
export const QuestionTabContent = () => {
  const [content, setContent] = useState();
  const { state, dispatch } = useContext(HelpStore);
  async function* fetchData() {
    const r = await axios({
      method: "GET",
      url: Tools.getApiAddress("queryContentHelp").replace(
        ConfigApi.serviceDomain,
        ConfigApi.operationDomain
      ),
      params: {
        MenuID: state.selectKey,
      },
    });
    yield r.data.data;
  }
  useEffect(() => {
    (async function () {
      const fetch = fetchData();
      const r = (await fetch.next()).value;
      // setContent(r);
      dispatch({ type: "content", payload: r });
      console.log(r);
    })();
  }, [state.selectKey, state.tab_index]);

  return (
    <div style={{ padding: "15px 20px" }} className={styles.contentInfo}>
      {state.content[0] ? (
        <div
          dangerouslySetInnerHTML={{ __html: state.content[0].Content }}
        ></div>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </div>
  );
};
