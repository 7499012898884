import { useBoolean } from "ahooks";
import { Button, message, Modal, Input } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect, useState } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { DataTable } from "../../../../component/table";
import { fileAction } from "../../action/online/file_action";
import { DataTableStore } from "../../../../component/table/store/data_table_store";

import { FileStore } from "../../store/online/file_store";
import { Search } from "../../../../component/search";
import { defualtPageSize, Progress } from "../../../../logic/common_logic";
import { AuthLogic } from "../../../../logic/auth_logic";

export function Online(viewPower) {
  const [deleteState] = useBoolean(false);
  const [] = useBoolean(false);
  const action = fileAction;
  const { state, dispatch } = useContext(FileStore);
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);
  const [menuButton, setData] = useState([]);
  useEffect(() => {
    (async function () {
      let r = await new AuthLogic().selButton(viewPower.viewPower);
      if (r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          r[i] = r[i].split("_")[r[i].split("_").length - 1];
        }
      }
      setData(r);
    })();

    return () => {};
  }, []);
  const columns: ColumnsType = [
    {
      title: "用户编号",
      dataIndex: "userID",
      key: "2",
      align: "center",
    },
    {
      title: "登录时间",
      dataIndex: "loginTime",
      key: "3",
      align: "center",
      width: 140,
    },
    {
      title: "会话ID",
      dataIndex: "sessionID",
      key: "4",
      align: "center",
      width: 300,
    },
    {
      title: "IP地址",
      dataIndex: "ipAddress",
      key: "5",
      align: "center",
    },
    {
      title: "最后访问",
      dataIndex: "sortIndex",
      key: "6",
      align: "center",
    },
    {
      title: "来源",
      dataIndex: "source",
      key: "7",
      align: "center",
    },
  ];
  const onSearch = async (pageIndex) => {
    await action.loadPage({
      pageIndex: pageIndex,
      pageSize: defualtPageSize(),
      dispatch: tableDispatch,
      search: state.search,
    });
  };
  return (
    <>
      <Search>
        <Input
          allowClear
          placeholder="请输入用户编号"
          maxLength={50}
          onChange={(e) =>
            action.setCondition("Keywords", e.target.value, dispatch)
          }
        />
        <input type="button" value="快速查找" onClick={() => onSearch(1)} />
      </Search>
      <ButtonPanel>
        <Button
          onClick={async () => {
            if (!tableState.selectList.length) {
              message.warning("请选择删除项");
            } else {
              await action.delSelectList({
                dispatch,
                selectList: tableState.selectList,
              });

              action.clearSelectedRowKeys({ dispatch: tableDispatch });

              await onSearch(tableState.current);
            }
          }}
        >
          踢出用户
        </Button>
        {menuButton.indexOf("EXPORT") != -1 && <Button>导出</Button>}
      </ButtonPanel>

      <DataTable
        columns={columns}
        action={fileAction}
        onLoad={async () => {
          await onSearch(1);
          Progress.done();
        }}
        onChange={(pageIndex) => {
          onSearch(pageIndex);
        }}
        scroll={{ scrollToFirstRowOnChange: true, autoY: true }}
        selectColumn={true}
        loading={tableState.loading}
      />

      {deleteState && (
        <Modal
          className="test"
          title="温馨提示"
          visible={true}
          okText="确定"
          cancelText="取消"
          width={366}
        >
          <h1>确认删除商品？</h1>
          <p>删除之后商品信息无法修复，请慎重考虑</p>
        </Modal>
      )}
    </>
  );
}
