import styles from "../../../style/applet/info.module.scss";
import { NoAuthorize } from "./noAuthorize";
import { OnAuthorize } from "./onAuthorize";

/**
 * 基本信息
 */
export function Info({ data, disabled }) {
  return (
    <>
      {data ? <OnAuthorize data={data} disabled={disabled} /> : <NoAuthorize />}
    </>
  );
}
