import { createContext, useContext, useReducer } from "react";
import { auditStatus, sendStatus } from '../../enum'
import * as _ from 'lodash'
type Dispatch = { type; payload };

interface IContextProps {
  state: IState;
  dispatch: ({ type }: Dispatch) => void;
}
export const PurchaseFormStore = createContext<IContextProps>(null);

class ReciepInfo{
  customerID?: string
  addressID?: string
  consigneeName: string = ''
  consigneePhone: string = ''
  consigneeProvince?: string
  consigneeCity?: string
  consigneeDistrict?: string
  consigneeAddress: string = ''
  longitude?: string
  latitude?: string
  whName?: string
}

class FormDataView {
  purchaseNumber?: string
  auditTime?: string
  auditStatus?: auditStatus
  auditUserName?: string
  auditRemark?: string
  freight?: number
  totalAmount?: number
  realTotalAmount?: number
  totalGoodsNum?: number
  discountAmount?: number
  sendStatus?: sendStatus
  createTime?: string
  createUserName?: string
}
class FormData extends FormDataView{
  id?: string
  purchaseTitle?: string
  dataDource: number = 1 //收银门店
  customerID?: string
  customerName?: string
  customerType: number =  1 //客户类型 对内客户
  remark: string = ''
  purchaseReceipt: ReciepInfo
  detailAddress: string
  posShopID: string //门店id
  whName?: string = ''
}
export class TableItem{
  id?: string //修改时有
  goodsID: string //商品名称
  goodsName: string 
  specID: string //规格ID
  goodsNum: number //数量
  totalAmount: number //总价
  specUnit?: string // 规格单位名称
  disscountPrice?: number //优惠总价 暂时屏蔽
  disscountTotal?: number // 单个优惠金额
  shippedNum?: number //已发货数量
  purchasePrice?: number // 采购价
  procurementMagnification?: number
}

interface IState {
  formData:  FormData,
  tableList: Array<TableItem>
  addressList: Array<ReciepInfo>
  loading: boolean
}
const initialArgs: IState = {
  formData: Object.assign({},new FormData(),{purchaseReceipt: new ReciepInfo()}),
  tableList: [],
  addressList: [],
  loading: false
};
function reducer(state: IState, { type, payload }: Dispatch) {
  switch (type) {
    case 'SetLoading':{
      state.loading = payload
      return{...state}
    }
    case "SetAddressList":{
      let list = payload || []
      // console.log(list)
        return{
          ...state,
          addressList: list.length > 0 ? [...list] : []
        }
    }
    case "SetAddressForm":{
      let { formData } = state
     let  detailAddress =payload? payload.consigneeProvince + payload.consigneeCity + payload.consigneeDistrict + payload.consigneeAddress:''
        formData = {
          ...formData,
          detailAddress: detailAddress,
          customerID: payload? payload.customerID : null,
          customerName: payload? payload.customerName : null,
          purchaseReceipt: payload ? {...payload} : new ReciepInfo()}
        return{
          ...state,
         formData: formData
        }
    }
    case "SetFormData": {
      return {
        ...state,
        formData: {
          ...state.formData,
          ...payload,
        },
      };
    }
    case "SetForm": {
    const {purchaseNumber, purchaseReceipt, purchaseTitle, dataDource,freight,totalAmount,realTotalAmount,discountAmount,totalGoodsNum,
        customerID,customerName,customerType,remark,posShopID,auditTime,auditStatus,auditRemark,createTime,createUserName,sendStatus,auditUserName,
        applicationDetails, detailAddress} = payload
      let data = new FormData()
      data.purchaseNumber = purchaseNumber
      data.purchaseTitle = purchaseTitle
      data.auditRemark = auditRemark
      data.auditTime = auditTime
      data.auditStatus = auditStatus
      data.auditUserName = auditUserName
      data.dataDource = dataDource
      data.freight = freight || 0
      data.totalAmount = totalAmount || 0
      data.realTotalAmount = realTotalAmount
      data.discountAmount = discountAmount
      data.totalGoodsNum = totalGoodsNum
      data.customerID = customerID
      data.customerName = customerName
      data.customerType = customerType
      data.remark = remark
      data.posShopID = posShopID
      data.createTime = createTime
      data.createUserName = createUserName
      data.sendStatus = sendStatus
      data.purchaseReceipt = Object.assign({}, purchaseReceipt)
      data.detailAddress = detailAddress
      let list = applicationDetails || [] //商品列表
      let arr = []
        list.length > 0 && list.map(({id, goodsID,goodsName,specID,goodsNum,goodsPrice,totalAmout,specUnit,disscountPrice,disscountTotal})=>{
            let item = new TableItem()
            item.purchasePrice = goodsPrice
            item.id = id
            item.goodsID = goodsID
            item.goodsName = goodsName
            item.specID = specID
            item.specUnit = specUnit
            item.goodsNum = goodsNum || 0
            item.totalAmount = totalAmout || 0
            item.disscountPrice = disscountPrice || 0
            item.disscountTotal = disscountTotal || 0
            arr.push(item)
        })
      return {
        ...state,
        formData: data,
        tableList: arr.length > 0 ? [...arr] : []
      };
    }
    // 添加商品 根据规格id去重
    case 'AddTableItem': {
      let list = state.tableList || []
      if(list.every(v=>v.specID != payload.specID)){
          let item = new TableItem()
          const {id, specID,specUnit, goodsName, purchasePrice,procurementMagnification} = payload
          item.goodsID = id
          item.specID = specID
          item.specUnit = specUnit
          item.goodsNum = undefined 
          item.goodsName = goodsName
          item.purchasePrice = purchasePrice
          item.procurementMagnification = procurementMagnification
          list.push(item)
      }
      return{
        ...state,
        tableList: list.length > 0 ? [...list] : []
      }
    }
    // 修改商品信息
    case 'ChangeTableItem':{  
      let list = state.tableList || []
      const { key, value, index} = payload
      let item = list[index]
      item[key] = value
      // 计算金额
      let goodsNum = isNaN(parseFloat(value))? 0: parseFloat(value)
      let price = item.purchasePrice? item.purchasePrice : 0
      item.totalAmount = _.round(goodsNum * price, 2)
      list.splice(index, 1, item)
        return{
          ...state,
          tableList: list.length > 0 ? [...list] : []
        }
    }
    // 删除商品
    case 'DelTableItem': {
       let list = state.tableList || []
      let index = _.findIndex(list, function(o){return o.specID == payload.specID})
      if(index!=-1){
        list.splice(index, 1)
      }
      return{
        ...state,
        tableList: list.length > 0 ? [...list] : []
      }
    }
    // 清除商品列表
    case "ClearTableList":
      return {
        ...state,
        tableList: [],
      };
    default:
      return { ...state };
  }
}
export function PurchaseFormStoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialArgs);

  const v = { state, dispatch };
  return (
    <PurchaseFormStore.Provider value={v}>
      {props.children}
    </PurchaseFormStore.Provider>
  );
}
