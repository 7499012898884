
import { SelectProps } from "antd/lib/select/index";
import React, { Children, cloneElement } from "react";
import { Select as SelectAntd } from "antd";
import styles from "./index.module.scss";

interface ITextAreaStyle {
    children?: any;
}

export function Select(params: ITextAreaStyle & SelectProps<any>) {
    const { children } = params;

    const rootStyle = (params.className) ? params.className : "";

    return <SelectAntd {...params} {...{ className: rootStyle + " " + styles.root }} >{children}</SelectAntd>
}