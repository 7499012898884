import { message } from "antd";
import { GoodsEntity } from "../../../../entity/goods_entity";
import { CheckLogic } from "../../../../logic/check_logic";
import { defualtPageSize } from "../../../../logic/common_logic";
import { AlarmSetLogic } from "../../../../logic/alarm_set_logic";
import { IState, TableItem } from "../../store/alarm_set/alarmSet_form_store";
import Cookies from 'js-cookie'
type Init = {
    state;
    dispatch;
    form;
    id: string;
    method: string;
}
interface InitBasic {
    dispatch;
    search;
}
interface InitEdit {
    state;
    dispatch;
    form;
    id;
}

class AlarmSetFormAction {
    async init({ state, dispatch, id, method, form }: Init) {
        await this.initBasic({ dispatch, search: state.search });
        if ("Create" !== method && id) {
            await this.initEdit({ dispatch, state, id, form })
        }
    }
    private async initBasic({ search, dispatch }: InitBasic) {
        this.clearTableList({ dispatch });
   
       let { shopID, shopName } = JSON.parse(Cookies.get('loginMsg'))
       this.setFormData({ dispatch, key: "shopID", value: shopID });
       this.setFormData({ dispatch, key: "shopName", value: shopName });
    }
    private async initEdit({ dispatch, id }: InitEdit) {
        // // 回填状态
        // // const item = await new AlarmSetLogic().selData(id);
        // //console.log(item)
        // //先把提取出规格对于的入库数据
        // let goodsList: any[] = [];
        // dispatch({ type: "SetForm", payload: item });
    }
    private paddingData(state: IState) {
        let goodsList = []

        {
            const { formData, tableList } = state;
            {


                tableList.forEach((v, i) => {
                    goodsList.push({
                           ...v,
                           shopID: formData.shopID
                        })
                })

            }
        }
        return goodsList;
    }
    async addSubmit({ state }) {
        if (!state.tableList || !state.tableList.length) {
            message.error("请选择商品");
            return false;
        }
        for (let i = 0; i < state.tableList.length; i++) {
               let item = state.tableList[i]
               if(item.minNumber || item.minNumber === 0){
                    if(item.minNumber < 0.001){
                        message.error(`第${i+1}个商品下限预警值应该大于0.001`)
                        return false
                    }
               }else{
                message.error(`请填写第${i+1}个商品下限预警值`)
                return false
               }
               if(item.maxNumber || item.maxNumber === 0){
                    if(item.maxNumber < 0.001){
                        message.error(`第${i+1}个商品上限预警值应该大于0.001`)
                        return false
                    }
               }else{
                message.error(`请填写第${i+1}个商品上限预警值`)
                return false
               }
        }
        
       state.tableList.forEach(item => {
                    item.minNumber = Number(item.minNumber)
					item.maxNumber = Number(item.maxNumber)
                    item.shopID= state.formData.shopID
       });
       console.log(state.tableList)
    //    return
        const r = await new AlarmSetLogic().addAlarmSet(state.tableList);
        if (r.data.statusCode == 200) {
            message.success("保存成功");
        } else {
            message.error(r.data.message);
        }
        return true;
    }
   
    addTableItem({ dispatch, list }) {
        list.forEach(async goods => {
            const item = new TableItem(goods);
            dispatch({ type: "AddTableItem", payload: item });
        })
    }
    delTableItem(dispatch, id) {
        dispatch({ type: "DelTableItem", payload: id });
    }
    setFormData({ dispatch, key, value }) {
        const params = {
        }
        params[key] = value;

        dispatch({ type: "SetFormData", payload: params })
    }
   
    changeTableItem({ dispatch, key, value, index }) {
        dispatch({ type: "ChangeTableItem", payload: { key, value, index } });
    }
    clearTableList({ dispatch }) {
        dispatch({ type: "ClearTableList" });
    }
    
}
export const alarmSetFormAction = new AlarmSetFormAction();