import { createContext, useReducer } from "react";

class FormData {
    id: string;
    printType: number;
    right: number;
    left: number;
    lin: number;
    shop_Conjunctions: number;
    shoping_Conjunctions: number;
    shopingPreSale_Conjunctions: number;
    shopPay_Conjunctions: number;
    member_Recharge: number;
    changeShifts_Conjunctions: number;
    state: number;
}

type Dispatch = { type, payload }

interface IState {
    formData: FormData;
    menuList: any[]
    authxtList: any[]
}

const initialArgs = {
    formData: new FormData(),
    menuList: [],
    authxtList: [],
}

export type FormAttribute =  "Id" | "PrintType" | "Right" | "Left" | "Lin" | "Shop_Conjunctions" | "Shoping_Conjunctions" | "ShopingPreSale_Conjunctions" | "ShopPay_Conjunctions" | "Member_Recharge" | "ChangeShifts_Conjunctions" | "State";

interface IContextProps {
    state: IState;
    dispatch: ({ type }: Dispatch) => void;
}

export const FormStore = createContext<IContextProps>(null);

function reducer(state: IState, { type, payload }: Dispatch) {
    switch (type) {
        // 菜单列表
        case "MenuList":
            return {
                ...state,
                menuList: payload
            }
        // 菜单列表
        case "AuthxtList":
            return {
                ...state,
                authxtList: payload
            }
        // 填充表单
        case "SetForm":
            {
                const data = payload;
                const form = new FormData();

                {
                    const {id,  printType, right, left, lin, shop_Conjunctions, shoping_Conjunctions, shopingPreSale_Conjunctions, shopPay_Conjunctions, member_Recharge, changeShifts_Conjunctions, state } = data;
                    form.id = id;
                    form.printType = printType;
                    form.right = right;
                    form.left = left;
                    form.lin = lin;
                    form.shop_Conjunctions = shop_Conjunctions;
                    form.shoping_Conjunctions = shoping_Conjunctions;
                    form.shopingPreSale_Conjunctions = shopingPreSale_Conjunctions;
                    form.shopPay_Conjunctions = shopPay_Conjunctions;
                    form.member_Recharge = member_Recharge;
                    form.changeShifts_Conjunctions = changeShifts_Conjunctions;
                    form.state = state;
                }
                return {
                    ...state,
                    formData: form
                }
            }
        // 设置表单
        case "Change_FormData":
            {
                const { attribute, value } = payload, props: any = {};

                const key: FormAttribute = attribute;
                let attri = "";

                switch (key) {
                    case "PrintType":
                        attri = "printType";
                        break
                    case "Right":
                        attri = "right";
                        break
                    case "Left":
                        attri = "left";
                        break
                    case "Lin":
                        attri = "lin";
                        break
                    case "Shop_Conjunctions":
                        attri = "shop_Conjunctions";
                        break
                    case "Shoping_Conjunctions":
                        attri = "shoping_Conjunctions";
                        break
                    case "ShopingPreSale_Conjunctions":
                        attri = "shopingPreSale_Conjunctions";
                        break
                    case "ShopPay_Conjunctions":
                        attri = "shopPay_Conjunctions";
                        break
                    case "Member_Recharge":
                        attri = "member_Recharge";
                        break
                    case "ChangeShifts_Conjunctions":
                        attri = "changeShifts_Conjunctions";
                        break
                    case "State":
                        attri = "state";
                        break
                    case "Id":
                        attri = "id";
                        break

                    default:
                        break;
                }

                props[attri] = value;

                return {
                    ...state,
                    formData: {
                        ...state.formData,
                        ...props
                    }
                }
            }
        default:
            return state;
    }
}

export function PrintingStoreProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialArgs);

    const v = { state, dispatch };
    return <FormStore.Provider value={v}>{props.children}</FormStore.Provider>
}