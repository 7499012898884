import Cookies from "js-cookie";
import { Tools, axios } from "../config";
import { effeative } from "./common_logic";

export class FapiaoSettingLogic {
     /**编辑保存 */
     async save(params) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("fapiao_setting_save"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
     /**查看 */
     async getInfo() {
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("fapiao_setting_view"),
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
}