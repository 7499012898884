import React, { useContext, useEffect } from "react";
import { Form as FormAntd, message } from "antd";
import { Layer } from "../../../../component/layer/layer";
import { FormBasic } from "./special_from_basic";
import { Button } from "../../../../component/button";
import {
  SpecialFormStore,
  SpecialFormStoreProvider,
} from "../../store/special/form_store";
import { specialformAction } from "../../action/special/form_action";
import { useBoolean } from "ahooks";

interface IForm {
  children?: any;
  action: any;
  close: (hasUpdate?: boolean) => void;
  id?: string;
  subId: string;
  method?: string;
  audit: boolean;
  item;
}
export function Form({ close, method, id, subId, item, audit }: IForm) {
  const [form] = FormAntd.useForm();

  const { state, dispatch } = useContext(SpecialFormStore);
  const [loading, loadFunc] = useBoolean(false)
  const action = specialformAction;

  useEffect(() => {
    action.init({ state: state, dispatch, id, form, method });

    return () => {};
  }, []);

  const title = () => {
    if ("Create" === method) {
      return "新增特价活动";
    } else if ("Edit" === method) {
      return "编辑特价活动";
    } else {
      return "预览特价活动";
    }
  };

  return (
    <FormAntd form={form}>
      <Layer layerWith={3} >
        <Layer.Title onClick={() => close()}>{title()}</Layer.Title>
        <Layer.Body>
          <FormBasic
            disabled={title() == "预览特价活动"}
            title={title()}
            updateFormField={(field, value = "") => {
              form.setFieldsValue({
                [field]: value,
              });
              form.validateFields([field]);
            }}
          />
        </Layer.Body>
        <Layer.Foot
          borderStyle={title() == "预览特价活动" ? { border: "none" } : {}}
        >
          {("Create" == method || "Edit" == method) && audit && (
            <>
              {" "}
              <Button
                type="primary"
                color="Red"
                onClick={async () => {
                  const s = await form.validateFields();
                  if(loading){
                    message.loading('正在提交数据')
                    return false
                }
                  let r = false;
                  if ("Create" == method) {
                    loadFunc.setTrue()
                    r = await action.addSubmit({ state, status: 1 });
                    loadFunc.setFalse()
                  } else {
                    loadFunc.setTrue()
                    r = await action.updSubmit({ state, status: 1 }, subId);
                    loadFunc.setFalse()
                  }
                  if (r) {
                    action.clearTableList({ dispatch });

                    close(true);
                  }
                }}
              >
                保存
              </Button>
              <Button
                type="primary"
                color="Yellow"
                style={{ marginLeft: "1.25rem" }}
                onClick={async () => {
                  // let r = false;
                  // if (r) {
                    close(true);
                  // }
                }}
              >
                返回
              </Button>
            </>
          )}
        </Layer.Foot>
      </Layer>
    </FormAntd>
  );
}
