import React, { useContext, useEffect } from "react";
import { Form as FormAntd, message } from "antd";
import { Layer } from "../../../../component/layer/layer";
import { Button } from "../../../../component/button";
import { ReportFormBasic } from "./report_form_basic";
import { ReportFormStore } from "../../store/report/report_form_store";
import { reportFormAction } from "../../action/report/report_form_action";
import { useBoolean } from "ahooks";

interface IForm {
    children?: any;
    action: any;
    close: (hasUpdate?: boolean) => void;
    id?: string;
    subId: string;
    method?: string;
    audit: boolean;
    item;
}
export function Form({ close, method, id, subId, item, audit }: IForm) {
    const [form] = FormAntd.useForm();

    const { state, dispatch } = useContext(ReportFormStore);
    const [loading, loadFunc] = useBoolean(false)
    const action = reportFormAction;

    useEffect(() => {

        action.init({ state: state, dispatch, id: subId, form, method });

        return () => {

        }
    }, []);

    const title = () => {
        if ("Create" === method) {
            return "新增报溢报损";
        }
        else if ("Edit" === method) {
            return "编辑报溢报损";
        }
        else {
            return "查看报溢报损";
        }
    }

    return (
        <FormAntd form={form}>
            <Layer layerWith={4}>
                <Layer.Title  onClick={() => close()}>{title()}</Layer.Title>
                <Layer.Body>
                    <ReportFormBasic disabled={!("Create" == method || "Edit" == method)} updateFormField={(field, value = '') => {
                        form.setFieldsValue({
                            [field]: value,
                        });
                    }} />
                </Layer.Body>
                <Layer.Foot borderStyle={title() == '查看报溢报损' ? { border: 'none' } : {}}>
                    {
                        ("Create" == method || "Edit" == method) && (
                            <Button type="primary" color="Yellow" style={{ marginRight: "1.25rem" }} onClick={async () => {
                                // const rform = await form.validateFields();
                                let formV = await form.validateFields();
                                if(loading){
                                    message.loading('正在提交数据')
                                    return false
                                }
                                let r = false;
                                if ("Create" == method) {
                                    loadFunc.setTrue()
                                    r = await action.addSubmit({ state, status: 2 });
                                    loadFunc.setFalse()
                                } else {
                                    loadFunc.setTrue()
                                    r = await action.updSubmit({ state, status: 2 }, id);
                                    loadFunc.setFalse()
                                }
                                if (r) {
                                    action.clearTableList({ dispatch });

                                    close(true);
                                }
                            }}>
                                保存并返回
                            </Button>
                        )
                    }
                    {
                        ("Create" == method || "Edit" == method && item.inventoryStatus == 2) && audit && (
                            <Button type="primary" color="Red" onClick={async () => {
                                // await form.validateFields();
                                let formV = await form.validateFields();
                                let r = false;
                                if ("Create" == method) {
                                    r = await action.addSubmit({ state, status: 1 });
                                } else {
                                    r = await action.updSubmit({ state, status: 1 }, id);
                                }
                                if (r) {
                                    action.clearTableList({ dispatch });

                                    close(true);
                                }
                            }}>
                                保存并审核
                            </Button>
                        )
                    }
                </Layer.Foot>

            </Layer>
        </FormAntd>
    )
}