import { message } from "antd";
// import axios from "axios";
import Cookies from "js-cookie";
import { DataTableAction } from "../../table/action/data_table_action";
import { Tools, axios } from "../../../config";
import { ClassesLogic } from "../../../logic/classes_logic";
import { GoodsLogic } from "../../../logic/goods_logic";
import {HotKeyLogic} from "../../../logic/hotkey_quip_logic"
class ChooseAction extends DataTableAction {
    async delRow(id) {
        
    }
    delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {
       
    }
 
    
    loadPage({ dispatch,  state, pageIndex, pageSize, search }) {
        this._loadPage({ dispatch,  state, pageIndex, pageSize, search }, new HotKeyLogic().getAllPlu);
    }
   
    
    private async _loadPage({ dispatch,  state, pageIndex, pageSize, search }, callback, _params?: any) {

        // 商品列表
        const { keywords, classesId, statusId, typeId, brandId, isStore } = search;
        const params = {
             pageIndex, pageSize, goodsBrandID: brandId, goodsclasss: classesId, goodsType: typeId, status: statusId, seacherKey: keywords,isStore: isStore,
            //合并额外的参数
            ..._params,
        }
        let type;
        if(_params&&_params.type){
            type = _params.type
        }
        delete params.type
        if (undefined == keywords) {
            delete params.seacherKey;
        }
        if (undefined == brandId) {
            delete params.goodsBrandID;
        }
        if (undefined == classesId) {
            delete params.goodsclasss;
        }
       
      
        if (undefined == isStore) {
            delete params.isStore;
        }
       
        const r = await callback(params);

        if (r.data.statusCode == 200) {
            const dataList = r.data.data.dataSource ||[];            
            
            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: dataList, pageSize: pageSize });
        }

    }

    setCondition(method: "Keywords" | "Classes" | "Status", value, dispatch) {
        if ("Keywords" == method) {
            dispatch({ type: "SetSearch", payload: { keywords: value } });
        }
        else if ("Classes" == method) {
            dispatch({ type: "SetSearch", payload: { classesId: value } });
        }
        else if ("Status" == method) {
            dispatch({ type: "SetSearch", payload: { statusId: value } });
        }
    }
    async init({ dispatch }) {
        // 商品分类
        const list = await new ClassesLogic().selListByTree();
        
        dispatch({ type: "SetAreaList", payload: list });
    }
}
export const chooseGoodsAction = new ChooseAction();