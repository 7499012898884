import { createContext, useReducer } from "react";

class FormData {
    modelName: any;
    serialPort: string;
    id: string;
    portBaud: string;
    modelCode: any;
}

type Dispatch = { type, payload }

interface IState {
    formData: FormData;
    menuList: any[]
    authxtList: any[]
}

const initialArgs = {
    formData: new FormData(),
    menuList: [],
    authxtList: [],
}

export type FormAttribute = "ModelName" | "SerialPort" | "PortBaud" | "Id" | "ModelCode";

interface IContextProps {
    state: IState;
    dispatch: ({ type }: Dispatch) => void;
}

export const FormStore = createContext<IContextProps>(null);

function reducer(state: IState, { type, payload }: Dispatch) {
    switch (type) {
        // 菜单列表
        case "MenuList":
            return {
                ...state,
                menuList: payload
            }
        // 菜单列表
        case "AuthxtList":
            return {
                ...state,
                authxtList: payload
            }
        // 填充表单
        case "SetForm":
            {
                const data = payload;
                const form = new FormData();

                {
                    const { modelName,serialPort,id,portBaud,modelCode } = data;
                    form.modelName = modelName;
                    form.portBaud = portBaud;
                    form.modelCode = modelCode;
                    form.serialPort = serialPort;
                    form.id = id;
                }

                return {
                    ...state,
                    formData: form
                }
            }
        // 设置表单
        case "Change_FormData":
            {
                const { attribute, value } = payload, props: any = {};

                const key: FormAttribute = attribute;
                let attri = "";

                switch (key) {
                    case "ModelName":
                        attri = "modelName";
                        break
                    case "ModelCode":
                        attri = "modelCode";
                        break
                    case "PortBaud":
                        attri = "portBaud";
                        break
                    case "SerialPort":
                        attri = "serialPort";
                        break
                    case "Id":
                        attri = "id";
                        break
                    default:
                        break;
                }

                props[attri] = value;

                return {
                    ...state,
                    formData: {
                        ...state.formData,
                        ...props
                    }
                }
            }
        default:
            return state;
    }
}

export function FormStoreProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialArgs);

    const v = { state, dispatch };
    return <FormStore.Provider value={v}>{props.children}</FormStore.Provider>
}