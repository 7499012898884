import { message } from "antd";
// import axios from "axios";
import Cookies from "js-cookie";
import { DataTableAction } from "../../table/action/data_table_action";
import { Tools, axios } from "../../../config";
import { MarketingPresale_logic } from "../../../logic/market/MarketingPresale_logic";
class ChooseAction extends DataTableAction {
    async delRow(id) {
       
    }
    delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {
        
    }
    async loadPage({ dispatch, pageIndex, pageSize, search }) {
        this.setLoading({dispatch,loading: true})
        const { shopID, goodsId, seacherKey } = search;
        const params: any = {
            "pageIndex": pageIndex,
            "pageSize": pageSize,
            "seachkey": seacherKey,
            "orderBy":'',
            "sort":'',
        }
        // 档案列表
        const r = await  MarketingPresale_logic.QueryPage(params);

        
        if (r&&r.data&&r.data.statusCode == 200) {
            const dataList = r.data.data.dataSource;
            //
            // let list = dataList.filter(s=>s.dataState==1)
            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: dataList, pageSize });
        }
        this.setLoading({dispatch,loading: false})

    }
 
    setCondition({ dispatch, key, value }) {
        const params = {
        }
        params[key] = value;

        dispatch({ type: "SetSearch", payload: params })
    }
    

}
export const choosePresaleAction = new ChooseAction();