import { useBoolean } from "ahooks";
import { Button, Input, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect, useState } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { Search } from "../../../../component/search";
import { DataTable } from "../../../../component/table";
import { DataTableStore } from "../../../../component/table/store/data_table_store";
import { AuthLogic } from "../../../../logic/auth_logic";
import { Control, Del, Edit, See } from "../../../../component/control-column";
import { defualtPageSize, Progress } from "../../../../logic/common_logic";
import { InventorySearch } from "./file_search";
import { Tips, TipsGroup } from "../../../../component/tips";
import { Mask } from "../../../../component/mask";
import { sendCouponAction } from "../../action/batchcoupon/file_action";
import { Form } from "./batchCoupon_form";
import { BatchCouponFormStoreProvider } from "../../store/batchCoupon/form_store";
import { BatchCouponStore } from "../../store/batchCoupon/file_store";
const formParams = {
  method: "Create",
  couponID: "",
  id: "",
  item: null,
};
export function BatchCoupon(viewPower) {
  const [menuButton, setData] = useState([]);
  const [createState, createFunc] = useBoolean(false);
  const [searchState, searchFunc] = useBoolean(false);
  const [confirmState, confirmFunc] = useBoolean(false);
  const action = sendCouponAction;
  const { state, dispatch } = useContext(BatchCouponStore);
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);
  const [] = useBoolean(false);
  useEffect(() => {
    (async function () {
      let r = await new AuthLogic().selButton(viewPower.viewPower);
      if (r && r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          r[i] = r[i].split("_")[r[i].split("_").length - 1];
        }
      }

      setData(r);
    })();

    return () => {};
  }, []);
  const columns: ColumnsType = [
    {
      title: "操作",
      key: "1",
      dataIndex: "oddNumbers",
      align: "center",
      width: Control.width(1),
      render: Control.render(
        (v, r) =>
          menuButton.indexOf("VIEW") != -1 && (
            <See
              click={() => {
                formParams.couponID = r.couponID;
                formParams.id = r.id;
                formParams.method = "Preview";

                createFunc.setTrue();
              }}
            />
          ),
        (v, r) =>
          menuButton.indexOf("UPDATE") != -1 && (
            <Edit
              click={() => {
                formParams.couponID = r.couponID;
                formParams.id = r.id;
                formParams.method = "Edit";
                formParams.item = r;

                createFunc.setTrue();
              }}
            />
          ),
        (v, r) =>
          menuButton.indexOf("DELETE") != -1 && (
            <Del
              click={() => {
                formParams.couponID = r.couponID;
                formParams.id = r.id;

                confirmFunc.setTrue();
              }}
            />
          )
      ),
    },
    {
      title: "活动名称",
      dataIndex: "activityName",
      key: "2",
      align: "center",
    },
    {
      title: "发券总量",
      dataIndex: "couponAmount",
      key: "3",
      align: "center",
    },
    {
      title: "规则类型",
      dataIndex: "sendType",
      key: "4",
      align: "center",
      render: (v) => (
        <>
          {v == 1 && <span>立即发券</span>}
          {v == 2 && <span>定时发券</span>}
        </>
      ),
    },
    {
      title: "创建人",
      dataIndex: "userName",
      key: "5",
      align: "center",
      width: 140,
    },
    {
      title: "创建时间",
      dataIndex: "addDateTime",
      key: "6",
      align: "center",
    },
  ];
  const onSearch = async (pageIndex) => {
    searchFunc.setFalse();
    await action.loadPage({
      pageIndex: pageIndex,
      pageSize: defualtPageSize(),
      dispatch: tableDispatch,
      search: state.search,
    });
  };
  return (
    <>
      <Search>
        <Input
          allowClear
          placeholder="请输入活动名称"
          maxLength={20}
          onChange={(e) =>
            action.setCondition({
              dispatch,
              key: "activityName",
              value: e.target.value,
            })
          }
        />
        <input type="button" value="快速查找" onClick={() => onSearch(1)} />

        {/* <TipsGroup>
                    <input type="button" value="高级查找" onClick={() => searchFunc.toggle()} />
                    <Tips display={searchState} width="48rem">
                        <InventorySearch onSearch={()=> onSearch(1)} />
                    </Tips>
                    <Mask visible={searchState} onClick={() => searchFunc.setFalse()} />
                </TipsGroup> */}
      </Search>

      <ButtonPanel>
        {menuButton.indexOf("ADD") != -1 && (
          <Button
            onClick={() => {
              formParams.id = "";
              formParams.method = "Create";

              createFunc.setTrue();
            }}
          >
            新增
          </Button>
        )}
      </ButtonPanel>

      <DataTable
        columns={columns}
        action={action}
        onLoad={async () => {
          await onSearch(1);
          Progress.done();
        }}
        onChange={(pageIndex) => {
          onSearch(pageIndex);
        }}
        scroll={{ scrollToFirstRowOnChange: true, autoY: true, x: 1060 }}
        loading={tableState.loading}
      />

      {createState && (
        <BatchCouponFormStoreProvider>
          {" "}
          <Form
            audit={true}
            close={(hasUpdate) => {
              if (hasUpdate) {
                onSearch(tableState.current);
              }

              createFunc.setFalse();
            }}
            action={action}
            couponID={formParams.couponID}
            id={formParams.id}
            item={formParams.item}
            method={formParams.method}
          />
        </BatchCouponFormStoreProvider>
      )}
    </>
  );
}
