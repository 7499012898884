import { message } from "antd";
import { ShopLogic } from "../../../logic/shop_logic";
import { FormAttribute } from "../store/form_store";

type Init = {
    state;
    dispatch;
    form;
    id: string;
    method: string;
}
interface InitBasic {
    dispatch;
    state;
}
interface InitEdit {
    state;
    dispatch;
    form;
    id;
}
interface ISetValue {
    attribute: FormAttribute;
    value: string | number | any[];
    dispatch;
}

export class FormAction {

    private shopLgc = new ShopLogic();

    async init({ state, dispatch, id, method, form }: Init) {
        await this.initBasic({ dispatch, state });

        if ("Create" !== method) {
            await this.initEdit({ dispatch, state, id, form })
        }

        //初始化
        dispatch({ type: "init", payload: method });
    }
    private async initBasic({ }: InitBasic) {

    }
    private async initEdit({ dispatch, id, form }: InitEdit) {
        // 回填状态
        const item = await this.shopLgc.selData(id);

        // selectClasses
        dispatch({ type: "SetForm", payload: item });

        // 填充节点
        form.setFieldsValue({
            "shop-code": item.shopCode,
            "shop-name": item.shopName,
            "memory-code": item.helpCode,
            "shop-phone": item.shopPhone,
            "shop-type": item.shopClasses,
            "business-area": item.usableArea,
            "shop-people-count": item.personNumber,
            "contact-people": item.linkman,
            "contact-phone": item.phone,
            "status": item.status,
            "address": item.detailedAddress,
            "remarks": item.remark,
            "area": [item.provincialCapital, item.city, item.district],
            'shopLogo': item.shopLogo
        });
    }
    reset({ dispatch, form }) {
        // selectClasses
        dispatch({
            type: "SetForm", payload: {
                id: "",
                shopCode: "",
                shopName: "",
                helpCode: "",
                shopPhone: "",
                shopClasses: "",
                personNumber: "",
                linkman: "",
                phone: "",
                status: "",
                provincialCapital: "",
                city: "",
                distric: "",
                detailedAddress: "",
                usableArea: "",
                shopLogo:''
            }
        });

        // 填充节点
        form.setFieldsValue({
            "shop-code": "",
            "shop-name": "",
            "memory-code": "",
            "shop-phone": "",
            "shop-type": "",
            "business-area": "",
            "shop-people-count": "",
            "contact-people": "",
            "contact-phone": "",
            "status": "",
            "address": "",
            "remarks": "",
            "area": "",
            'shopLogo':''
        });
    }
    setValue({ attribute, value, dispatch }: ISetValue) {
        dispatch({ type: "Change_FormData", payload: { attribute, value } });
    }
    private paddingData(state) {
        let params: any = {};
        {
            const {shopLogo, id, shopCode, shopName, memoryCode, shopPhone, type, businessArea, peopleCount, contactPeople, contactPhone, status, city, address, remark, longitude, latitude, } = state;

            params = {
                shopLogo,
                id,
                shopCode,
                shopName,
                helpCode: memoryCode,
                shopPhone,
                shopClasses: type,
                usableArea: businessArea,
                personNumber: peopleCount,
                linkman: contactPeople,
                phone: contactPhone,
                status,
                provincialCapital: (city && city.length >= 1) && city[0],
                city: (city && city.length >= 2) && city[1],
                district: (city && city.length >= 3) && city[2],
                detailedAddress: address,
                //经度和纬度
                longitude: longitude,
                latitude: latitude,
                //
                parentID: "",
                remark
            }
        }
        return params;
    }
    async addSubmit({ state }) {
        const r = await this.shopLgc.addData(this.paddingData(state));
        if (r.data.statusCode == 200) {
            message.success("保存成功");
        } else {
            message.error(r.data.message);
        }
    }
    async updSubmit({ state }) {
        const r = await this.shopLgc.updData(this.paddingData(state));
        if (r.data.statusCode == 200) {
            message.success("更新成功");
        } else {
            message.error(r.data.returnMessage);
        }
    }
}
export const formAction = new FormAction();