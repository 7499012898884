import { message } from "antd";
import { defualtPageSize, maxPageSize } from "../../../../logic/common_logic";
import { GoodsLogic } from "../../../../logic/goods_logic";
import { ShopLogic } from "../../../../logic/shop_logic";
import { UnitLogic } from "../../../../logic/unit_logic";
import { UserLogic } from "../../../../logic/user_logic";
import { GradeLogic } from "../../../../logic/grade_logic";
import { SpecialLogic } from "../../../../logic/special_logic";
import Cookies from 'js-cookie'
type Init = {
    state;
    dispatch;
    form;
    id: string;
    method: string;
}
interface InitBasic {
    dispatch;
    search;
}
interface InitEdit {
    state;
    dispatch;
    form;
    id;
}
interface ISetValue {
    attribute: any;
    value: string | number | any[];
    dispatch;
}

class SpecialFormAction {
    setValue(arg0: { dispatch: ({ type }: { type: any; payload: any; }) => void; value: any; attribute: string; }) {
        throw new Error("Method not implemented.");
    }

    private shopLgc = new ShopLogic();
    private GradeLogic = new GradeLogic();
    async init({ state, dispatch, id, method, form }: Init) {
        await this.initBasic({ dispatch, search: state.search });

        if ("Create" !== method && id) {
            await this.initEdit({ dispatch, state, id, form })
        }
    }
    private async initBasic({ search, dispatch }: InitBasic) {
        this.clearTableList({ dispatch });
        dispatch({ type: "LoadTag", payload: await this.GradeLogic.gradeList() });
        this.setFormData({ dispatch, key: "useweekTypeValue", value: ['1', '2', '3', '4', '5', '6', '7'] })

    this.setFormData({ dispatch, key: "shopID", value: [] });
    this.setFormData({
      dispatch,
      key: "shopName",
      value: '',
    });   

    }
    private async initEdit({ dispatch, id, form }: InitEdit) {
        // 回填状态
    
      
        const item = await new SpecialLogic().selDataByWarehousing(id);
        if(!item){
            message.error('获取信息失败')
            return false
        }
       
        // let ids = []
        // goodsList.length>0&&goodsList.map((l,index)=>{
        //     if(ids.indexOf(l.goodsID) !=-1){
        //         goodsList.splice(index,1)
        //     }else if(ids.indexOf(l.goodsID) == -1){
        //     ids.push(l.goodsID)

        //     }
        // })
       let list = item.targets || []
       list.length > 0 && list.forEach((v, i) => (v['serial'] = i));
        item['goodsList'] = list

      let shopID = item.shopID && item.shopID.length>0? item.shopID.map(s=>s.shopID):[]
    let shopNameArr = item.shopID && item.shopID.length>0? item.shopID.map(s=>s.shopName):[]
        
        item['shopName'] = shopNameArr.toString()
        item['shopID'] = shopID
        item['isMemberClass'] = 1
        if(item.useMemberClassID){
            let useMemberClassID = item.useMemberClassID.split(',')
            let arr = []
            for (let i = 0; i < useMemberClassID.length; i++) {
                arr.push(useMemberClassID[i])
            }
            item['useMemberClassID'] = arr
            item['isMemberClass'] = 2
        }else{
            item['useMemberClassID'] = []
        }
        
       
        //console.log(item)
        dispatch({ type: "SetForm", payload: item });
    }
   

    setDay(state){
        let date1 = state.formData.activityStartDate;
        let date2 = state.formData.activityEndDate
       let monthBegin = (new Date(date1)).getMonth();
       let monthEnd = (new Date(date2)).getMonth();
       let dayBegin = (new Date(date1)).getDate();
       let dayEnd = (new Date(date2)).getDate();
       let dayList=[]
   
           if(monthBegin!=null&&monthEnd!=null&&monthBegin == monthEnd){
               for(let i= dayBegin; i<=dayEnd ;i++){
                  dayList.push(i)
               }
           }else{
              for(let i = 0; i< 31;i++){
                  dayList.push(i+1)
               }
           }
           return dayList;
    }
    private paddingData(state){
        if (state.formData.activityName == undefined || state.formData.activityName == '') {
            message.error("请输入活动名称");
            return false;
        }
        if (state.formData.shopID == undefined || state.formData.shopID.length == 0) {
            message.error("请选择分配门店");
            return false;
        }
        if (state.formData.isMemberClass == undefined || state.formData.isMemberClass == 2) {
            state.formData.isMemberClass = 2
            if (state.formData.useMemberClassID == undefined || state.formData.useMemberClassID.length == 0) {
                message.error("请选择会员等级");

                return false;
            }

        }
        if (state.formData.activityStartDate == undefined || state.formData.activityEndDate == undefined) {
            message.error("请选择活动日期");
            return false;
        }
        if (state.formData.activityStartTime == undefined || state.formData.activityEndTime == undefined) {
            message.error("请选择活动时间段");
            return false;
        }
        if(state.formData.activityStartTime == state.formData.activityEndTime ){
            message.error("请选择不同活动时间段");
            return false;
        }
        if (state.formData.useType == 2) {
            if (state.formData.useTypeValue == undefined || state.formData.useTypeValue.length == 0) {
                let dayList = this.setDay(state)
                state.formData.useTypeValue = dayList.toString()
            }
        } else {
            if (state.formData.useweekTypeValue == undefined || state.formData.useweekTypeValue.length == 0) {
                message.error("请选择日期集合");
                return false
            }
        }
        if (state.tableList.length == 0) {
            message.error("请选择特价商品");
            return false;
        }
        let goodsList = []
       for(let i = 0; i< state.tableList.length;i++){
            let item = state.tableList[i]
            if(item.specialPrice === 0 || item.specialPrice){
                if(!(/^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/).test(item.specialPrice)){
                    message.error(`商品[${item.barCode}]特价应该大于等于0.01`)
                    return false
                }
            }else{
                message.error(`请填写商品[${item.barCode}]的商品特价`)
                return false
            }
            
        }
       state.tableList.map(item=>{
        goodsList.push({
            ...item,
            target: item.barCode,
            specialPrice: item.specialPrice
        })
       })
        let param = {...state.formData}

        param['activityName'] = state.formData.activityName
        param['discountType'] = 3
        param['useMemberClassID'] = (state.formData.useMemberClassID || []).join(',')
        param['activityStartDate'] = state.formData.activityStartDate
        param['activityEndDate'] = state.formData.activityEndDate
        param['activityStartTime'] = state.formData.activityStartTime
        param['activityEndTime'] = state.formData.activityEndTime
        param['useType'] = state.formData.useType == undefined ? 1 : state.formData.useType
        param['useTypeValue'] = (state.formData.useType == 2 ? (Array.isArray(state.formData.useTypeValue) ? state.formData.useTypeValue.join(',') : state.formData.useTypeValue) : (Array.isArray(state.formData.useweekTypeValue) ? state.formData.useweekTypeValue.join(',') : state.formData.useweekTypeValue))//日期集合
        param['purposeType'] = state.formData.purposeType == undefined ? 1 : state.formData.purposeType
        param['activityRanges'] = goodsList
        param['shopID'] = state.formData.shopID
        return param
    }
    // 新增秒杀
    async addSubmit({ state, status }) {
        let param = this.paddingData(state)
        if(!param){
            return
        }
        //console.log(param)
        const r = await new SpecialLogic().addOperation(param);
        if (r.data.statusCode == 200) {
            message.success("保存成功");
            return true;
        } else {
            message.error('保存失败');
            return false;
        }

    }
    async updSubmit({ state, status }, id) {
        let param = this.paddingData(state)
        if(!param){
            return
        }
        param['id'] = id
        //console.log(param)
        const r = await new SpecialLogic().updOperation(param);
        if (r.data.statusCode == 200) {
            message.success("更新成功");
        } else {
            message.error('更新失败');
        }
        return r.data.statusCode == 200;
    }
    addTableItem({ dispatch, list }) {
        list.forEach(async v => {
            v.goodsUnitName = v.unitName;         
            v.specialPrice = undefined


            dispatch({ type: "AddTableItem", payload: v });
        })
    }
    delTableItem(dispatch, index) {
        dispatch({ type: "DelTableItem", payload: index });
    }
    setFormData({ dispatch, key, value }) {
        const params = {
        }
        params[key] = value;

        dispatch({ type: "SetFormData", payload: params })
    }
    setKeywords({ dispatch, value, state }) {
        this.setFormData({ dispatch, key: "keyword", value: value });

        state.searchList.forEach(e => {
            if (e.id == value) {
                this.setFormData({ dispatch, key: "keyword", value: value });

                dispatch({ type: "SetFormData", payload: { inStockUnitPrice: e.putNewPrice } })
            }

        })
    }

    changeTableItem({ dispatch, key, value, index }) {
        dispatch({ type: "ChangeTableItem", payload: { key, value, index } });
    }
    clearTableList({ dispatch }) {
        dispatch({ type: "ClearTableList" });
    }
}
export const specialformAction = new SpecialFormAction();