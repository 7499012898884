import { message } from "antd";
import { defualtPageSize } from "../../../../logic/common_logic";
import { GoodsLogic } from "../../../../logic/goods_logic";
import { DiscountLogic } from "../../../../logic/discount_logic";
import { ShopLogic } from "../../../../logic/shop_logic";
import { UnitLogic } from "../../../../logic/unit_logic";
import { UserLogic } from "../../../../logic/user_logic";
import { GradeLogic } from "../../../../logic/grade_logic";
import { ClassesLogic } from "../../../../logic/classes_logic";
import { initialArgs } from "../../store/discount/form_store";
import Cookies from "js-cookie";
type Init = {
    state;
    dispatch;
    form;
    id: string;
    method: string;
};
interface InitBasic {
    dispatch;
    search;
}
interface InitEdit {
    state;
    dispatch;
    form;
    id;
}
interface ISetValue {
    attribute: any;
    value: string | number | any[];
    dispatch;
}

class WarehousingFormAction {
    private GradeLogic = new GradeLogic();
    private shopLgc = new ShopLogic();
    setDay(state) {
        let date1 = state.formData.activityStartDate;
        let date2 = state.formData.activityEndDate;
        let monthBegin = new Date(date1).getMonth();
        let monthEnd = new Date(date2).getMonth();
        let dayBegin = new Date(date1).getDate();
        let dayEnd = new Date(date2).getDate();
        let dayList = [];

        if (monthBegin != null && monthEnd != null && monthBegin == monthEnd) {
            for (let i = dayBegin; i <= dayEnd; i++) {
                dayList.push(i);
            }
        } else {
            for (let i = 0; i < 31; i++) {
                dayList.push(i + 1);
            }
        }
        return dayList;
    }
    async init({ state, dispatch, id, method, form }: Init) {
        await this.initBasic({ dispatch, search: state.search });
        if ("Create" !== method && id) {
            await this.initEdit({ dispatch, state, id, form });
        }
    }
    private async initBasic({ search, dispatch }: InitBasic) {
        this.clearTableList({ dispatch });
        dispatch({ type: "LoadTag", payload: await this.GradeLogic.gradeList() });
        this.setFormData({ dispatch, key: "useweekTypeValue", value: ['1', '2', '3', '4', '5', '6', '7'] })
        this.setFormData({ dispatch, key: "discountType", value: 1, });
        // let { shopID, shopName } = JSON.parse(Cookies.get("loginMsg"));
        // this.setFormData({ dispatch, key: "shopIDs", value: [shopID] });
        // this.setFormData({
        //     dispatch,
        //     key: "shopName",
        //     value: shopName,
        // });

    }
    private async initEdit({ dispatch, id, form }: InitEdit) {
        // 回填状态
        // const r = await new ShopLogic().selListpost();
        const item = await new DiscountLogic().selDataByWarehousing(id);
        if (!item) {
            return false;
        }
        item.useMemberClassID = item.useMemberClassID
            ? item.useMemberClassID.split(",")
            : "";

        item["activityTypes"] = item.types;
        item["discountZj"] =
            item.discountType == 1 || item.discountType == undefined
                ? item.types[0].discount
                : "";
        if (item.useType != 2) {
            item["useweekTypeValue"] = item.useTypeValue.split(",");
        } else {
            item.useTypeValue = item.useTypeValue.split(",");
        }

        
        let list = item.targets || []
        //目标 分类
        item['classifyList'] = []
        if (list.length > 0 && item.range == 2) {
            list.forEach((v, i) => (v['serial'] = i));
            item["classifyList"] = list || [];
        }
        // 目标 商品
        item["tableList"] = [];
        if (list.length > 0 && item.range == 3) {
            list.forEach((v, i) => (v['serial'] = i));
            item["tableList"] = list|| [];
        }
       
        if (item.types.length > 0) {
            let arr = [];
            item.types.map((ss) => {
                arr[ss.discountClass] = ss;
            });
            arr.splice(0, 1);
            item["activityTypes"] = arr;
        }
        let shopids = []
        let shopNames = []
        if (item.shopIds && item.shopIds.length > 0) {
           shopids = item.shopIds.map(s=>s.shopID)
           shopNames = item.shopIds.map(s=>s.shopName)
           
        }
        item["shopIDs"] = shopids;
        item["shopName"] = shopNames.toString();

        dispatch({ type: "SetForm", payload: item });
    }
    private paddingData(state) {
        if (state.formData.activityName == undefined) {
            message.error("请输入活动名称");
            return false;
        }
        if (state.formData.activityTypes == undefined) {
            state.formData.activityTypes = [
                { discount: "", discountClass: 1, meetMoney: "" },
            ];
        }
        if (state.formData.discountType == 1) {
            state.formData.activityTypes = [];
            let obj = {
                meetMoney: "",
                discount: state.formData.discountZj,
                discountClass: 1,
            };
            if (state.formData.discountZj) {
                if (!/^(([1-9][0-9])|[1-9])$/.test(state.formData.discountZj)) {
                    message.error("折扣请输入1~99的整数");
                    return false;
                }                
            }else{
                message.error("请输入折扣");
                return false;
            }
           
            state.formData.activityTypes.push(obj);
        } else {
            let list = state.formData.activityTypes || [];
            for (let i = 0; i < list.length; i++) {
                if (list[i].meetMoney || list[i].meetMoney === 0) {
                    if (
                        !/^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(
                            list[i].meetMoney
                        )
                    ) {
                        message.error(`第${i + 1}级金额大于等于0.01`);
                        return false;
                    }
                } else {
                    message.error(`请填写第${i + 1}级金额`);
                    return false;
                }
                if (list[i].discount) {
                    if(!(/^(([1-9][0-9])|[1-9])$/).test(list[i].discount)){
                        message.error(`第${i + 1}级折扣请输入1~99的整数`);
                        return false;
                    }
                }else{
                    message.error(`请填写第${i + 1}级折扣`);
                    return false;
                }
              
            }
        }

        if (
            state.formData.shopIDs == undefined ||
            state.formData.shopIDs.length == 0
        ) {
            message.error("请选择门店");
            return false;
        }
        if (state.formData.isLimit) {
        } else {
            if (
                state.formData.useMemberClassID == undefined ||
                state.formData.useMemberClassID.length == 0
            ) {
                message.error("请选择会员等级");
                return false;
            }
        }
        if (
            state.formData.activityStartDate == undefined ||
            state.formData.activityEndDate == undefined
        ) {
            message.error("请选择活动日期");
            return false;
        }
        if (
            state.formData.activityStartTime == undefined ||
            state.formData.activityEndTime == undefined
        ) {
            message.error("请选择活动时间段");
            return false;
        }
        if (state.formData.activityStartTime == state.formData.activityEndTime) {
            message.error("不能选择相同的时间段");
            return false;
        }
        if (state.formData.useType == 2) {
            if (
                state.formData.useTypeValue == undefined ||
                state.formData.useTypeValue.length == 0
            ) {
                // message.error("请选择日期集合");
                // return false
                let dayList = this.setDay(state);
                state.formData.useTypeValue = dayList.toString();
            }
        } else {
            if (
                state.formData.useweekTypeValue == undefined ||
                state.formData.useweekTypeValue.length == 0
            ) {
                message.error("请选择日期集合");
                return false;
            }
        }
        if (state.formData.purposeType == 2 && state.formData.discountType == 2) {
            message.error("线上商城不能设置满额折扣");
            return false;
        }

        if (state.formData.range == 2 && state.classifyList.length == 0) {
            message.error("请选择指定分类");
            return false;
        }
        if (state.formData.range == 3 && state.tableList.length == 0) {
            message.error("请选择指定商品");
            return false;
        }
        let activityRanges = [];

        if (state.formData.range == 3) {
            if (state.tableList.length == 0) {
                message.error("请选择商品");
                return false;
            }
            for (let i = 0; i < state.tableList.length; i++) {
                let obj = {};
                // console.log(state.tableList[i])
                obj["target"] = state.tableList[i].barCode;
                obj["targetType"] = 2; //目标类型 商品
                activityRanges.push(obj);
            }
        }
        if (state.formData.range == 2) {
            if (state.classifyList.length == 0) {
                message.error("请选择商品");
                return false;
            }
            for (let i = 0; i < state.classifyList.length; i++) {
                let obj = {};
                // console.log(state.tableList[i])
                obj["target"] = state.classifyList[i].classCode;
                obj["targetType"] = 1; //目标类型 分类
                activityRanges.push(obj);
            }
        }
        let parm = {
            activityName: state.formData.activityName, //名称
            discountType:
                state.formData.discountType == undefined
                    ? 1
                    : state.formData.discountType, //活动方式
            useMemberClassID: state.formData.isLimit ? '' : state.formData.useMemberClassID.join(","), //等级集合
            activityStartDate: state.formData.activityStartDate, //活动开始时间
            activityEndDate: state.formData.activityEndDate, //活动结束时间
            activityStartTime: state.formData.activityStartTime, //活动开始时间段
            activityEndTime: state.formData.activityEndTime, //活动结束时间段
            useType:
                (state.formData.useType == undefined || state.formData.useType == 1)
                    ? 1
                    : 2, //Week按星期，Day按日
            useTypeValue:
                state.formData.useType == 2
                    ? Array.isArray(state.formData.useTypeValue)
                        ? state.formData.useTypeValue.join(",")
                        : state.formData.useTypeValue
                    : Array.isArray(state.formData.useweekTypeValue)
                        ? state.formData.useweekTypeValue.join(",")
                        : state.formData.useweekTypeValue, //日期集合
            purposeType:
                state.formData.purposeType == undefined
                    ? 1
                    : state.formData.purposeType, //使用途径
            // state.formData.purposeType == undefined ? 1 : state.formData.purposeType
            range: state.formData.range == undefined ? 1 : state.formData.range, //折扣活动
            activityTypes: state.formData.activityTypes, //折扣方式
            activityRanges: activityRanges,
            shopIDs: state.formData.shopIDs, //门店
        };
        return parm;
    }
    // 提交折扣
    async addSubmit({ state, status }) {
        let parm = this.paddingData(state);
        if (!parm) {
            return;
        }
        const r = await new DiscountLogic().addOperation(parm);
        if (r.data.statusCode == 200) {
            message.success("保存成功");
            return true;
        } else {
            message.error(r.data.message);
            return false;
        }
    }
    async updSubmit({ state, status }, id) {
       let parm = this.paddingData(state)
       if(!parm){
        return
       }
       parm['id'] = id
        const r = await new DiscountLogic().updOperation(parm);
        if (r.data.statusCode == 200) {
            message.success("更新成功");
            return true;
        } else {
            message.error(r.data.message);
            return false;
        }
    }
    setValue({ attribute, value, dispatch }: ISetValue) {
        dispatch({ type: "Change_FormData", payload: { attribute, value } });
    }
    addTableItem({ dispatch, list }) {
        list.forEach(async (v) => {
            // const r = await new UnitLogic().selData(v.goodsUnitID);
            v.goodsUnitName = v.unitName;

            dispatch({ type: "AddTableItem", payload: v });
        });
    }
    AddclassTableItem({ dispatch, list }) {
        list.length > 0 && list.forEach(v => {
            dispatch({ type: "AddclassTableItem", payload: v });
        })
    }
    delTableItem(dispatch, index) {
        dispatch({ type: "DelTableItem", payload: index });
    }
    delTableclassItem(dispatch, index) {
        dispatch({ type: "DelTableclassItem", payload: index });
    }
    addGradeItem(dispatch, text) {
        dispatch({ type: "AddGradeItem", payload: text });
    }
    setFormData({ dispatch, key, value }) {
        const params = {};
        params[key] = value;

        dispatch({ type: "SetFormData", payload: params });
    }
    setKeywords({ dispatch, value, state }) {
        this.setFormData({ dispatch, key: "keyword", value: value });

        state.searchList.forEach((e) => {
            if (e.id == value) {
                this.setFormData({ dispatch, key: "keyword", value: value });

                dispatch({
                    type: "SetFormData",
                    payload: { inStockUnitPrice: e.putNewPrice },
                });
            }
        });
    }
    changeTableItem({ dispatch, key, value, index }) {
        dispatch({ type: "ChangeTableItem", payload: { key, value, index } });
    }
    clearTableList({ dispatch }) {
        dispatch({ type: "ClearTableList" });
    }
    reset({ dispatch }) {
        dispatch({
            type: "SetForm",
            payload: {
                ...initialArgs,
            },
        });
    }
}
export const warehousingformAction = new WarehousingFormAction();
