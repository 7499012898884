import React, { useContext, useState } from "react";
import {
  Button,
  Cascader,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  TreeSelect,
} from "antd";
import styles from "../../style/search.module.scss";
import { FileStore } from "../../store/printSet/file_store";
import { fileAction } from "../../action/printSet/file_action";
import { useRequest } from "ahooks";
import { Select as SelectAntd } from "antd";

const { Option } = Select;

export function FileSearch({ onSearch }) {
  const { state, dispatch } = useContext(FileStore);
  const action = fileAction;

  return (
    <div>
      <Form layout="horizontal">
        <div className={styles.row}>
          <div className={styles.cell}></div>
          <div className={styles.cell}>
            <Form.Item label="小票类型">
              <Select
                style={{ width: "15rem" }}
                value={state.search.ticketsType}
                onChange={(v, option) => {
                  action.setCondition({
                    dispatch,
                    key: "ticketsType",
                    value: v,
                  });
                }}
              >
                {/* 1:58收银2:80收银;3:80配送;4:58价签 */}
                <Select.Option value={1} key={1}>
                  58收银
                </Select.Option>
                <Select.Option value={2} key={2}>
                  80收银
                </Select.Option>
                <Select.Option value={3} key={3}>
                  80配送
                </Select.Option>
                <Select.Option value={4} key={4}>
                  58价签
                </Select.Option>
              </Select>
            </Form.Item>
          </div>
        </div>
        <div className={styles.row}>
          <Button type="primary" danger onClick={() => onSearch(1)}>
            搜 索
          </Button>
        </div>
      </Form>
    </div>
  );
}
