// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../config";
import { effeative } from "./common_logic";
// import axios from '../tool/request'
// import 
interface ISelList {
    startDate: string;
    endDate: string;
    keyWords: string;
    orderNumber: string;
    operationID: string;
    pageIndex: number;
    pageSize: number;
    orderBy?: string;
    sort?: string;
}
export class OrderLogic {
    async selList(params: ISelList) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("order_sel"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r.data;
    }
    async selData(orderId,IsPrint) {
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("order_sel_data"),
            params: {
                orderId,
                IsPrint
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r.data.data;
    }
    async prevConnectServer() {
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("order_sel_prevConnectServer"),
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r && r.data && r.data.data;
    }
    async selDataMarketingInfo(OrderNumber: string) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress('order_sel_marketinginfo'),
            params:{
                OrderNumber:OrderNumber
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r
    }
    async changeSatte({params}) {
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("order_change_state"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r.data;
    }
    async printOrder(orderId) {
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("order_refund_print"),
            params: {
                orderId
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r.data;
    }
    /**根据订单id获取开票记录 */
    async getFapiaoLogs(orderId){
        const r = effeative(await axios({
            url: Tools.getApiAddress('fapiao_logs_byorderid'),
            method: 'GET',
            params:{
                ExpenditureID: orderId
            }
        }))
        return r
    }
}