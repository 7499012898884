import React, { useContext, useEffect } from "react";
import { Form as FormAntd, message } from "antd";
import { Layer } from "../../../../component/layer/layer";
import { PurchaseFormBasic } from "./form_basic";
import { Button } from "../../../../component/button";
import { PurchaseFormStore, PurchaseFormStoreProvider } from "../../store/purchase/purchase_form_store";
import { purchaseFormAction } from "../../action/purchase/purchase_form_action";
import { useBoolean } from "ahooks";

interface IForm {
    children?: any;
    action: any;
    close: (hasUpdate?: boolean) => void;
    id?: string;
    subId: string;
    method?: string;
    audit: boolean;
    item;
}
export function Form({ close, method, id, subId, item, audit }: IForm) {
    const [form] = FormAntd.useForm();

    const { state, dispatch } = useContext(PurchaseFormStore);
    const [loading, loadFunc] = useBoolean(false)
    const action = purchaseFormAction;
    useEffect(() => {
        action.init({ state: state, dispatch, id, form, method });

        return () => {

        }
    }, []);

    const title = () => {
        if ("Create" === method) {
            return "新增采购单";
        }
        else if ("Edit" === method) {
            return "编辑采购单";
        }
        else {
            return "预览采购单";
        }
    }

    return (
        <FormAntd form={form}>
            <Layer layerWith={4} >
                <Layer.Title onClick={() => close()}>{title()}</Layer.Title>
                <Layer.Body>
                    <PurchaseFormBasic disabled={!("Create" == method || "Edit" == method)} updateFormField={(field, value = '') => {
                        form.setFieldsValue({
                            [field]: value,
                        });
                    }} method={method}/>
                </Layer.Body>
                <Layer.Foot borderStyle={title() == '预览采购单' ? { border: 'none' } : {}}>
                    {
                        (method == 'Create' || method == 'Edit') && (
                            <Button type="primary" color="Red" style={{ marginRight: "1.25rem" }} loading={state.loading} disabled={state.loading} onClick={async () => {
                                    let formV = await form.validateFields();
                                    if(loading){
                                        message.loading('正在提交数据')
                                        return false
                                    }
                                    let r = false;
                                    if ("Create" == method) {
                                        loadFunc.setTrue()
                                        r = await action.addSubmit({ state, status: 2 },dispatch);
                                        loadFunc.setFalse()
                                    } else {
                                        loadFunc.setTrue()
                                        r = await action.updSubmit({ state, status: 2 },dispatch, subId);
                                        loadFunc.setFalse()
                                    }
                                    if (r) {
                                        action.clearTableList({ dispatch });

                                        close(true);
                                    }
                                }
                            }>保存</Button>
                        )
                    }
                   <Button
                        type="primary"
                        color="Yellow"
                        onClick={() => { 
                            action.clearTableList({ dispatch }); 
                            close(true)}}
                    >
                        返回
                    </Button>


                    {/* {
                        ("Create" == method || "Edit" == method) && (
                            <Button type="primary" color="Yellow" style={{ marginRight: "1.25rem" }} onClick={async () => {
                                let formV = await form.validateFields();
                                let r = false;
                                if ("Create" == method) {
                                    r = await action.addSubmit({ state, status: 2 });
                                } else {
                                    r = await action.updSubmit({ state, status: 2 }, subId);
                                }
                                if (r) {
                                    action.clearTableList({ dispatch });

                                    close(true);
                                }
                            }}>
                                保存并返回
                            </Button>
                        )
                    }
                    {
                        ("Create" == method || "Edit" == method && item.inventoryStatus == 2) && audit && (
                            <Button type="primary" color="Red" onClick={async () => {
                                let formV = await form.validateFields();
                                let r = false;
                                if ("Create" == method) {
                                    r = await action.addSubmit({ state, status: 1 });
                                } else {
                                    r = await action.updSubmit({ state, status: 1 }, subId);
                                }
                                if (r) {
                                    action.clearTableList({ dispatch });

                                    close(true);
                                }
                            }}>
                                保存并审核
                            </Button>
                        )
                    } */}
                </Layer.Foot>

            </Layer>
        </FormAntd>
    )
}