import { Button, Col, Form, Input, Row, Select } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useState } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { FormSubtitle } from "../../../commodity/view/form_subtitle";
import styles from "../../style/inventory_goods_formbasic.module.scss";

import img_del from "../../../../assets/images/shanchu.png";
import { warehousingformAction } from "../../action/in-stock/warehousing_form_action";
import { useBoolean, useSafeState } from "ahooks";
import { ChooseGoods } from "../../../../component/choose-goods";
import { Table } from "../../../../component/table";
import { ChooseShop } from "../../../../component/choose-shop";
import { TableExpand } from "../../../../component/table-expand";
import { ReportFormStore } from "../../store/report/report_form_store";
import { reportFormAction } from "../../action/report/report_form_action";
import { autoRem } from "../../../../global.style";
import { NumberTool } from "../../../../tool/numberTool";
const { Option } = Select;

export function DataTable({ dataList, disabled }) {
  const { dispatch } = useContext(ReportFormStore);
  const action = warehousingformAction;
  const [current, setCurrent] = useState(1);
  const [pageSize, setSize] = useState(10);
  const columns: ColumnsType = [
    {
      title: "操作",
      dataIndex: "serial",
      key: "1",
      align: "center",
      width: 50,
      render: (v, r, i) => (
        <div>
          <img
            src={img_del}
            style={{ width: 20 }}
            onClick={() => {
              if (disabled) return;
              action.delTableItem(dispatch, v);
            }}
          />
        </div>
      ),
    },
    {
      title: "批次号",
      dataIndex: "storageBatchNumber",
      key: "0",
      align: "center",
      width: 190
    },
    {
      title: "商品编码",
      dataIndex: "goodsCode",
      key: "2",
      align: "center",
      width: 120
    },
    {
      title: "商品名称",
      dataIndex: "goodsName",
      key: "4",
      align: "center",
      width: 120
    },
    {
      title: "商品库存",
      dataIndex: "systemNumber",
      key: "5",
      align: "center",
      width: 100
    },
    {
      title: "库存单位",
      dataIndex: "unitName",
      key: "6",
      align: "center",
      width: 80
    },
    // {
    //   title: "商品规格",
    //   dataIndex: "",
    //   key: "7",
    //   align: "center",
    //   width: 120,
    //   render: (e, r, i) => {
    //     return <div>****</div>;
    //   },
    // },
    {
      title: "库存价",
      dataIndex: "inventoryAvg",
      key: "7",
      align: "center",
      width: 100
      // render: (e, r: any, i) => {
      //   return (
      //     <div>
      //       {r.goodsSpecifications.reduce((a, b) => {
      //         return a + (b.inventoryAvg || 0);
      //       }, 0)}
      //     </div>
      //   );
      // },
    },
    {
      title: "数量",
      dataIndex: "number",
      key: "8",
      align: "center",
      width: 120,
      render: (v, r: any, i) => {
        return (
          <Input
            style={{ width: 100 }}
            disabled={disabled}
            type="number"
            value={v}
            onChange={(e) => {
              r.number = parseFloat(e.currentTarget.value);
              action.changeTableItem({
                dispatch,
                index: i + (current - 1) * pageSize,
                key: "number",
                value: parseFloat(e.currentTarget.value),
              });
            }}
          />
        );
      },
      //   render: (e, r: any, i) => {
      //     return (
      //       <div>
      //         {r.goodsSpecifications.reduce((a, b) => {
      //           return a + (b.inventoryNumber || 0);
      //         }, 0)}
      //       </div>
      //     );
      //   },
    },
    {
      title: "金额",
      dataIndex: "price",
      key: "9",
      align: "center",
      width: 120,
      render: (v, r: any, i) => {
        return (
          <Input
            style={{ width: 100 }}
            disabled={true}
            type="number"
            value={v}
            onChange={(e) => {
              r.price = parseFloat(e.currentTarget.value);
              action.changeTableItem({
                dispatch,
                index: i,
                key: "price",
                value: parseFloat(e.currentTarget.value),
              });
            }}
          />
        );
      },
      //   render: (e, r: any, i) => {
      //     return (
      //       <div>
      //         ￥
      //         {NumberTool.toAmount(
      //           r.goodsSpecifications.reduce((a, b) => {
      //             return a + (b.price || 0);
      //           }, 0)
      //         )}
      //       </div>
      //     );
      //   },
    },
  ];

  return (
    <Table
      scroll={{ y: 275 }}
      dataSource={dataList}
      columns={columns}
      onChange={(pagination) => {
        setCurrent(pagination.current);
        setSize(pagination.pageSize);
      }}
      //   expandable={{
      //     expandedRowRender: (record) => {
      //       return record.goodsSpecifications.map((item) => {
      //         return (
      //           <GetSpecifications
      //             disabled={disabled}
      //             specifications={item}
      //             unitName={record.unitName}
      //           />
      //         );
      //       });
      //     },
      //     rowExpandable: (record) => record.name !== "Not Expandable",
      //   }}
    />
  );
}

function GetSpecifications({ specifications, unitName, disabled }) {
  const { state, dispatch } = useContext(ReportFormStore);

  return (
    <div className={styles.specifications}>
      <div className={styles.info} style={{ width: 120 }}>
        <span>{specifications.unitContent}</span>
        <span>{specifications.unitName}</span>
      </div>
      <div className={styles.input} style={{ width: 150 }}>
        <span>{specifications.inventoryAvg}</span>
      </div>
      <div className={styles.input} style={{ width: 120 }}>
        <Input
          disabled={disabled}
          type="number"
          value={specifications.inventoryNumber}
          onChange={(e) => {
            specifications.inventoryNumber = Math.abs(
              parseFloat(e.currentTarget.value)
            );
            //    let price = specifications.inventoryNumber * NumberTool.toInteger(specifications.inventoryAvg).num / NumberTool.toInteger(specifications.inventoryAvg).times
            let price = NumberTool.multiply(
              specifications.inventoryNumber,
              specifications.inventoryAvg,
              2
            );
            specifications.price = price.toFixed(2);
            dispatch({
              type: "update",
              payload: null,
            });
          }}
        />
      </div>
      <div className={styles.input} style={{ width: 90 }}>
        <Input
          disabled={true}
          type="number"
          value={specifications.price}
          onChange={(e) => {
            specifications.price = parseFloat(e.currentTarget.value);
            dispatch({
              type: "update",
              payload: null,
            });
          }}
        />
      </div>
    </div>
  );
}

export function ReportFormBasic({ disabled, updateFormField }) {
  const [shopState, shopFunc] = useBoolean(false);
  const [goodsState, goodsFunc] = useBoolean(false);

  const { state, dispatch } = useContext(ReportFormStore);
  const { formData } = state;

  const action = reportFormAction;

  const combined = (key) => {
    return state.tableList.reduce((a, b) => {
      // return (
      //   a +
      //   b.goodsSpecifications.reduce((a, b) => {
      return a + (b[key] || 0);
      //   }, 0)
      // );
    }, 0);
  };

  let height = document.body.clientHeight - 188 + 26;
  return (
    <div className={styles.root} style={{ height: height + "px" }}>
      <div className={styles.content}>
        <FormSubtitle>基础信息</FormSubtitle>

        <div
          style={{
            margin: "" + autoRem(1.9375) + " " + autoRem(1.25) + " 0 0",
          }}
        >
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={6}>
              <Form.Item name="" label="制单人" labelCol={{ span: 8 }}>
                <span>{formData.userName}</span>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="制单时间" labelCol={{ span: 8 }}>
                <span style={{ display: "block", minWidth: 200 }}>
                  {formData.date}
                </span>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item
                label="门店"
                name="shop"
                labelCol={{ span: 8 }}
                rules={[
                  {
                    required: true,
                    message: "请选择门店",
                    validator: (
                      rule,
                      value,
                      callback: (error?: string) => void
                    ) => {
                      if (!formData.shop?.shopName) {
                        callback("请选择门店");
                      } else {
                        callback();
                      }
                    },
                  },
                ]}
              >
                {formData.shop.shopName ? "" : ""}
                <Input
                  disabled={disabled}
                  value={formData.shop && formData.shop.shopName}
                  autoComplete="off"
                  placeholder="请选择"
                  onClick={() => shopFunc.setTrue()}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="报损类型"
                name="typeId"
                labelCol={{ span: 10 }}
                rules={[
                  {
                    required: true,
                    message: "请选择报损类型",
                    validator: (
                      rule,
                      value,
                      callback: (error?: string) => void
                    ) => {
                      if (!formData.typeId) {
                        callback("请选择报损类型");
                      } else {
                        callback();
                      }
                    },
                  },
                ]}
              >
                {formData.typeId ? "" : ""}
                <Select
                  disabled={disabled}
                  value={formData.typeId}
                  onChange={(v) => {
                    action.setFormData({ dispatch, key: "typeId", value: v });
                    updateFormField("typeId");
                  }}
                >
                  <Option value={3}>报损</Option>
                  <Option value={4}>报溢</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="理由"
                name="reasonId"
                labelCol={{ span: 8 }}
                rules={[
                  {
                    required: true,
                    message: "请选择理由",
                    validator: (
                      rule,
                      value,
                      callback: (error?: string) => void
                    ) => {
                      if (!formData.reasonId) {
                        callback("请选择理由");
                      } else {
                        callback();
                      }
                    },
                  },
                ]}
              >
                {formData.reasonId ? "" : ""}
                <Select
                  disabled={disabled}
                  value={formData.reasonId}
                  onChange={(v) => {
                    action.setFormData({ dispatch, key: "reasonId", value: v });
                    updateFormField("reasonId");
                  }}
                >
                  {/* <Option value={1}>手动入库</Option>
                                    <Option value={2}>退货入库</Option>
                                    <Option value={3}>采购入库</Option>
                                    <Option value={4}>手动出库</Option>
                                    <Option value={5}>销售出库</Option>
                                    <Option value={6}>库存调整</Option> */}
                  <Option value={7} disabled={formData.typeId == 4}>
                    商品试吃
                  </Option>
                  <Option value={8} disabled={formData.typeId == 4}>
                    日常报损
                  </Option>
                  <Option value={9} disabled={formData.typeId == 4}>
                    商品过期
                  </Option>
                  <Option value={10} disabled={formData.typeId == 4}>
                    领用
                  </Option>
                  <Option value={11} disabled={formData.typeId == 3}>
                    日常报溢
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="备注" labelCol={{ span: 8 }}>
                <Input
                  maxLength={200}
                  disabled={disabled}
                  value={formData.remark}
                  autoComplete="off"
                  onChange={(v) =>
                    action.setFormData({
                      dispatch,
                      key: "remark",
                      value: v.target.value,
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <div style={{ marginTop: -20 }}>
          <FormSubtitle>商品信息</FormSubtitle>
        </div>

        <div
          style={{
            margin: "" + autoRem(1.9375) + " " + autoRem(1.25) + " 0 0",
          }}
        >
          {/* <Row gutter={16} style={{ marginTop: 10 }}>
            <Col span={10}>
              <Form.Item label="快速添加" labelCol={{ span: 6 }}>
                <Input
                  autoFocus
                  allowClear
                  disabled={disabled}
                  placeholder="扫描商品条形码，快速添加"
                  value={state.code}
                  onPressEnter={async (e) => {
                    await action.getGoods({
                      id: e.currentTarget.value,
                      dispatch,
                      state,
                    });
                    dispatch({ type: "InputCode", payload: "" });
                  }}
                  onChange={(e) => {
                    dispatch({ type: "InputCode", payload: e.target.value });
                  }}
                />
              </Form.Item>
            </Col>
          </Row> */}
          
        </div>

        <div style={{ marginTop: -20 }}>
          <ButtonPanel>
            <Button disabled={disabled} onClick={() => goodsFunc.setTrue()}>
              选择商品
            </Button>
          </ButtonPanel>
        </div>

        <DataTable dataList={state.tableList} disabled={disabled} />

        <TableExpand>
          数量合计： {combined("number").toFixed(3)}
          <span style={{ marginLeft: 10 }}>
            金额合计：￥{NumberTool.toAmount(combined("price"))}
          </span>
        </TableExpand>
      </div>

      <ChooseShop
        visibleModal={shopState}
        close={(status, r) => {
          if (r) {
            action.setFormData({ dispatch, key: "shop", value: r[0] });
          }
          updateFormField("shop");
          shopFunc.setFalse();
        }}
      />
      <ChooseGoods
        rowKey="storageBatchNumber"
        visibleModal={goodsState}
        close={(status, r) => {
          if (r) {
            action.addTableItem({ dispatch, list: r });
          }
          goodsFunc.setFalse();
        }}
        filter={{ zeroInventory: true, inventoryType:'overOrloss' }}
      />
    </div>
  );
}
