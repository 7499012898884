import { Button, Checkbox, Input } from "antd";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { Search } from "../../../../component/search";
import { useState, useEffect, useContext } from "react";
import { AdLogin } from "../../../../logic/ad_login";
import { message } from "antd";
import { ColumnsType } from "antd/lib/table";
import { DataTable } from "../../../../component/table";
import { tableAction } from "../../action/ad/ad_action";
import { defualtPageSize, Progress } from "../../../../logic/common_logic";
import { DataTableStore } from "../../../../component/table/store/data_table_store";
import { Control, Del, Edit, See } from "../../../../component/control-column";
import { Form } from "./ad_form";
import { ConfirmModal } from "../../../../component/modal";
import { AuthLogic } from "../../../../logic/auth_logic";
function getTime(str: string): string {
  return str ? str.split(" ")[0] : "永久有效";
}

/**
 * 广告配置
 */
export function Ad(viewPower) {
  const [ifOpenFP, setIfOpenFP] = useState(false);
  const [conAd, setConAd] = useState({
    ifShow: false,
    data: null,
    ifEdit: false,
  });
  const [queryState, setQueryState] = useState({
    name: "",
    isEnable: true,
  });
  const [confirmState, setConfirmState] = useState({
    id: "",
    show: false,
  });
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);
  const action = tableAction;
  const [menuButton, setData] = useState([]);
  useEffect(() => {
    (async function () {
      let r = await new AuthLogic().selButton(viewPower.viewPower);
      if (r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          r[i] = r[i].split("_")[r[i].split("_").length - 1];
        }
      }
      setData(r);
    })();

    return () => {};
  }, []);
  const adCon = (
    ifShow: boolean,
    data: any = null,
    ifEdit: boolean = false
  ) => {
    setConAd({
      ifShow,
      data,
      ifEdit,
    });
  };

  const columns: ColumnsType = [
    {
      title: "操作",
      align: "center",
      width: Control.width(3),
      render: Control.render(
        (v) =>
          menuButton.indexOf("VIEW") != -1 && (
            <See
              click={() => {
                adCon(true, v);
              }}
            />
          ),
        (v) =>
          menuButton.indexOf("UPDATE") != -1 && (
            <Edit
              click={() => {
                adCon(true, v, true);
              }}
            />
          ),
        (v) =>
          menuButton.indexOf("DELETE") != -1 && (
            <Del
              click={() => {
                setConfirmState({
                  id: v.id,
                  show: true,
                });
              }}
            />
          )
      ),
    },
    {
      dataIndex: "name",
      title: "广告名称",
      align: "center",
    },
    {
      dataIndex: "imageUrl",
      title: "广告图片",
      align: "center",
      render: (value) => {
        return (
          <img
            style={{
              width: "50px",
              height: "50px",
              margin: "10px 0",
            }}
            src={AdLogin.getImgUrl(value)}
          ></img>
        );
      },
    },
    {
      dataIndex: "position",
      title: "位置",
      align: "center",
      render: (value: any, record: unknown, index: number) => {
        switch (value) {
          case 1:
            return "屏幕上方";
          case 2:
            return "屏幕下方";
        }
      },
    },
    {
      dataIndex: "isEnable",
      title: "是否启用",
      align: "center",
      render: (value) => {
        return <Checkbox disabled checked={value}></Checkbox>;
      },
    },
    {
      dataIndex: "order",
      title: "排序",
      align: "center",
    },
    {
      dataIndex: "expirationDate",
      title: "失效日期",
      align: "center",
      render: (value) => {
        return getTime(value);
      },
    },
    {
      dataIndex: "addUserID",
      title: "添加人",
      align: "center",
    },
    {
      dataIndex: "addDateTime",
      title: "添加时间",
      align: "center",
      render: (value) => {
        return getTime(value);
      },
    },
  ];

  useEffect(() => {
    //获取是否开启副屏
    AdLogin.QueryIsOpenExtendScreen().then(({ data }) => {
      setIfOpenFP(data);
    });
  }, []);

  /** 请求某一页的数据 */
  const queryData = (pageIndex) => {
    action.queryData({
      ...queryState,
      pageIndex: pageIndex,
      pageSize: defualtPageSize(),
      dispatch: tableDispatch,
    });
  };
  /** 刷新数据 */
  const update = () => {
    //刷新当前页数据
    queryData(tableState.current);
  };

  return (
    <>
      <Search>
        <Input
          maxLength={20}
          allowClear
          value={queryState.name}
          placeholder="请输入广告名称"
          onChange={(e) => {
            setQueryState({
              ...queryState,
              name: e.target.value,
            });
          }}
        />

        <input
          type="button"
          value="快速查找"
          onClick={() => {
            queryData(1);
          }}
        />
        <Checkbox
          checked={queryState.isEnable}
          onChange={(e) => {
            setQueryState({
              ...queryState,
              isEnable: e.target.checked,
            });
          }}
        >
          是否启用
        </Checkbox>
        {/* <Checkbox
          checked={ifOpenFP}
          onChange={(e) => {
            AdLogin.ExtendScreenOpenOrClose(e.target.checked).then((data) => {
              if (data.statusCode == 200) {
                setIfOpenFP(!ifOpenFP);
              } else {
                message.error(data.returnMessage);
              }
            });
          }}
        >
          是否开启副屏
        </Checkbox> */}
      </Search>
      {menuButton.indexOf("ADD") != -1 && (
        <ButtonPanel>
          <Button
            onClick={() => {
              adCon(true);
            }}
          >
            新增广告
          </Button>
        </ButtonPanel>
      )}
      <DataTable
        columns={columns}
        action={action}
        onLoad={async () => {
          await queryData(1);
          Progress.done();
        }}
        onChange={(pageIndex) => {
          queryData(pageIndex);
        }}
        scroll={{ scrollToFirstRowOnChange: true, autoY: true, x: 1060 }}
      />
      {conAd.ifShow && (
        <Form
          close={() => {
            adCon(false);
          }}
          data={conAd.data}
          ifEdit={conAd.ifEdit}
          update={() => {
            adCon(false);
            update();
          }}
        />
      )}
      <ConfirmModal
        visible={confirmState.show}
        title="温馨提示"
        subTitle="确认删除？"
        message="删除之后信息无法恢复，请慎重考虑"
        close={(r) => {
          if (r) {
            AdLogin.Delete(confirmState.id).then(() => {
              update();
            });
          }
          setConfirmState({
            id: "",
            show: false,
          });
        }}
      />
    </>
  );
}
