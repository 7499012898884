import { Input, Modal, Select, Tree, Button } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext } from "react";
import { Search } from "../search";
import { DataTable } from "../table";
import { DataTableStore, DataTableStoreProvider } from "../table/store/data_table_store";
import { chooseAction } from "./action/choose_action";
import { ChooseStore, ChooseStoreProvider } from "./store/choose_store";
import styles from "./style/index.module.scss";
import { Select as SelectAntd } from "antd";
import { defualtPageSize } from "../../logic/common_logic";

function Shop(params: IChooseCoupon) {
    const { visibleModal, close, type ,saveKeys} = params;
    const action = chooseAction;

    const { state, dispatch } = useContext(ChooseStore);
    const { state: tableState, dispatch: tableDispatch } = useContext(DataTableStore);


    const handleOk = () => {
        close && close(true, tableState.selectList);
    };

    const handleCancel = () => {
        close && close(false);
    };

    const columns: ColumnsType = [
        {
            title: '门店名称',
            dataIndex: 'shopName',
            key: '2',
            align: "center"
        },
        {
            title: '门店编码',
            dataIndex: 'shopCode',
            key: '3',
            align: "center"
        },
        {
            title: '门店类型',
            dataIndex: 'shopClasses',
            key: '4',
            align: "center",
            render: v => <span>{v == 1 ? "加盟" : "直营"}</span>
        },
        {
            title: '门店状态',
            dataIndex: 'status',
            key: '5',
            align: "center",
            render: v => <span>{v == 1 ? "开启" : "关闭"}</span>
        }
    ];
    let tableRowSelection: any = {};
    // if (type == "radio") {
    //     tableRowSelection = {
    //       rowSelection: {
    //         type: "radio",
    //         getCheckboxProps: (record) => ({
    //           disabled: checkSelectable(record), // Column configuration not to be checked
    //         }),
    //       },
    //     };
    //   }
    if (type == 'checkbox') {
        tableRowSelection = {
            rowSelection: {
                type: "checkbox",
            }
        }
    } else {
        tableRowSelection = {
            rowSelection: {
                type: "radio",
            }
        }
    }
   
    const onSearch = async (pageIndex) => {
        await action.loadPage({ pageIndex: pageIndex, pageSize: defualtPageSize(), dispatch: tableDispatch, search: state.search })
    }

    return (
        <Modal title="选择门店" visible={visibleModal} onOk={handleOk} onCancel={handleCancel} width={1000}
            cancelText="取消" okText="确定"
            footer={<>
                <Button type="primary" style={{
                    backgroundColor: '#f7ba00',
                    borderColor: '#f7ba00',
                    color: 'white'
                }} onClick={handleCancel}>取消</Button>
                <Button type="primary" onClick={handleOk}>确认</Button>
            </>}
        >

            <div className={styles.root}>
            

                <DataTable columns={columns} action={action} {...tableRowSelection} saveKeys={saveKeys}
                    onLoad={() => onSearch(1)}
                    onChange={(pageIndex) => { onSearch(pageIndex) }}
                    selectColumn={true}
                />
            </div>
        </Modal>
    )
}
interface IChooseCoupon {
    visibleModal?: boolean;
    type?: string;
    close?: (status?, r?) => void;
    saveKeys?:boolean;
}
export function ChooseShop(params: IChooseCoupon) {
    return (
        <ChooseStoreProvider>
            <DataTableStoreProvider>
                <Shop {...params} />
            </DataTableStoreProvider>
        </ChooseStoreProvider>
    )
}