import React, { useEffect, useState } from "react";
import { Layout } from "../../component/layout";
import { TabsStyle, Tab, TabList, TabPanel } from "../../component/react-tabs";
import { DataTableStoreProvider } from "../../component/table/store/data_table_store";
import { brandAction } from "./action/brand_action";
import { classifyAction } from "./action/classify_action";
import { unitAction } from "./action/unit_action";
import { FileStoreProvider } from "./store/file_store";
import { UnitStoreProvider } from "./store/unit_store";
import { PrintStoreProvider } from "./store/print_store";
import { Classify } from "./view/classify";
import { File } from "./view/file";
import { Unit } from "./view/unit";
import { Print } from "./view/print";
import { MenuLogic } from "../../logic/menu_logic";
import { UpdatePrice } from "./view/updatePrice/file";
import { UpdatePriceProvider } from "./store/update_price_store";
import { ClassicFileStoreProvider } from "./store/goods_classify_store/file_store";
// 商品分类 rewrite
import { GoodsClassify } from "./view/goodsClassify/goodsClassify";

// 税收编码配置
import { TaxCodeInfo } from "./view/taxCodeInfo/file";
import { FileStoreProvider as TaxCodeInfoFileStoreProvider } from "./store/taxCodeInfo/file_store";
function Commodity() {
  const [menuLists, setData] = useState([]);
  useEffect(() => {
    (async function () {
      let r = await new MenuLogic().menuData();
      var arr = [];
      if (r && r.length > 0) {
        r = r.filter(function (v) {
          return !v.hidden;
        });
        for (let i = 0; i < r.length; i++) {
          if (r[i].path == "/commodity" && r[i].children) {
            arr = r[i].children.filter(function (v) {
              return !v.hidden;
            });
          }
        }
      }
      // arr.push({
      //   component: 'TaxCodeInfo',
      //   name:'税收编码配置',
      //   viewPower: ''
      // })
      setData(arr);
    })();

    return () => {};
  }, []);
  return (
    // <Layout fullWidth={true}>
    <TabsStyle style="Full">
      <TabList>
        {menuLists.map((v, i) => (
          <Tab key={i}>{v.name}</Tab>
        ))}
        {/* <Tab key={-1}>商品改价</Tab> */}
      </TabList>
      {menuLists.map((v) =>
        v.component == "Dossier" ? (
          // 商品档案
          <TabPanel key={v.component}>
            <FileStoreProvider>
              <DataTableStoreProvider>
                <File viewPower={v.viewPower} />
              </DataTableStoreProvider>
            </FileStoreProvider>
          </TabPanel>
        ) : v.component == "Classify" ? (
          // 商品分类
          <TabPanel key={v.component}>
            {/* <UnitStoreProvider>
              <Classify action={classifyAction} />
            </UnitStoreProvider> */}

            <ClassicFileStoreProvider>
              <DataTableStoreProvider>
                <GoodsClassify viewPower={v.viewPower} />
              </DataTableStoreProvider>
            </ClassicFileStoreProvider>
          </TabPanel>
        ) : v.component == "Unit" ? (
          // 商品单位
          <TabPanel key={v.component}>
            <UnitStoreProvider>
              <Unit action={unitAction} viewPower={v.viewPower}/>
            </UnitStoreProvider>
          </TabPanel>
        ) : v.component == "Brand" ? (
          // 商品品牌
          <TabPanel key={v.component}>
            <UnitStoreProvider>
              <Unit action={brandAction} viewPower={v.viewPower}/>
            </UnitStoreProvider>
          </TabPanel>
        ) : v.component == "updatePrice" ? (
          // 改价
          <TabPanel key={v.component}>
            <UpdatePriceProvider>
              <DataTableStoreProvider>
                <UpdatePrice viewPower={v.viewPower} />
              </DataTableStoreProvider>
            </UpdatePriceProvider>
          </TabPanel>
        ) : v.component == "TaxCodeInfo" ?(
          <TabPanel>
            <TaxCodeInfoFileStoreProvider>
              <DataTableStoreProvider>
                <TaxCodeInfo viewPower={v.viewPower} />
              </DataTableStoreProvider>
            </TaxCodeInfoFileStoreProvider>
          </TabPanel>
        ): (
          <TabPanel key={v.component}>
            <PrintStoreProvider>
              <DataTableStoreProvider>
                <Print viewPower={v.viewPower} />
              </DataTableStoreProvider>
            </PrintStoreProvider>
          </TabPanel>
        ) 
      )}
      {/* <TabPanel>
        <UpdatePriceProvider>
          <DataTableStoreProvider>
            <UpdatePrice />
          </DataTableStoreProvider>
        </UpdatePriceProvider>
      </TabPanel> */}
    </TabsStyle>
    // </Layout>
  );
}
export default Commodity;
