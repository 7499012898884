import { CheckCircleOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import { loadImg } from "../../../../../tool/loadImg";
import styles from "../../../style/applet/perview.module.scss";
import { WxShopApiCon } from "../logic/WxShopApiCon";

/**
 * 预览
 */
export function Preview() {
  const [imgUrl, setImgUrl] = useState("");

  useEffect(() => {
    WxShopApiCon.GetexperienceCode().then((data) => {
      setImgUrl(URL.createObjectURL(data));
    });
  }, []);

  return (
    <>
      <div className={styles.root}>
        <CheckCircleOutlined className={styles.img} />
        <span>体验版已生效</span>
        <span>体验者可扫描下方二维码体验此版本</span>
        <div>
          {imgUrl && <img src={imgUrl} alt="" />}
          <span
            className={styles.span_button}
            onClick={() => {
              loadImg(imgUrl);
            }}
          >
            下载二维码
          </span>
          {/* <span>还没添加体验者？<span className={styles.span_button} onClick={() => {
                    //
                }}>点击添加</span></span> */}
        </div>
      </div>
    </>
  );
}
