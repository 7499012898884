import React, { useContext, useEffect } from "react";
import { Form as FormAntd, message } from "antd";
import { Layer } from "../../../../component/layer/layer";
import { Button } from "../../../../component/button";
import { AlarmSetFormBasic } from "./form_basic";
import { AlarmSetFormStore } from "../../store/alarm_set/alarmSet_form_store";
import { alarmSetFormAction } from "../../action/alarmSet/form_action";
import { useBoolean } from "ahooks";

interface IForm {
    children?: any;
    action: any;
    close: (hasUpdate?: boolean) => void;
    id?: string;
    numberNo?: string;
    subId: string;
    audit: boolean;
    method?: string;
    item;
}
export function Form({ close, method, id, item, audit, numberNo }: IForm) {
    const [form] = FormAntd.useForm();

    const { state, dispatch } = useContext(AlarmSetFormStore);
    const [loading, loadFunc] = useBoolean(false)
    const action = alarmSetFormAction;

    useEffect(() => {

        action.init({ state: state, dispatch, id: '', form, method });

        return () => {

        }
    }, []);

    const title = () => {
        if ("Create" === method) {
            return "新增预警值设置";
        }
       
    }

    return (
        <FormAntd form={form}>
            <Layer layerWith={4} >
                <Layer.Title onClick={() => close()}>{title()}</Layer.Title>
                <Layer.Body>
                    <AlarmSetFormBasic disabled={!("Create" == method || "Edit" == method)} updateFormField={(field, value = '') => {
                        form.setFieldsValue({
                            [field]: value,
                        });
                    }} />
                </Layer.Body>
                <Layer.Foot borderStyle={title() == '预览成本变更' ? { border: 'none' } : {}}>
                    {
                        ("Create" == method || "Edit" == method) && (
                            <Button type="primary" color="Red" style={{ marginRight: "1.25rem" }} onClick={async () => {
                                // await form.validateFields();
                                let formV = await form.validateFields();
                                if(loading){
                                    message.loading('正在提交数据')
                                    return false
                                }
                                let r = false;
                                if ("Create" == method) {
                                    loadFunc.setTrue()
                                    r = await action.addSubmit({ state});
                                    loadFunc.setFalse()
                                }
                                if (r) {
                                    action.clearTableList({ dispatch });

                                    close(true);
                                }
                            }}>
                                保存
                            </Button>
                        )
                    }
                   <Button
                type="primary"
                color="Yellow"
                onClick={() => {
                    action.clearTableList({ dispatch })
                    close(true);
                }}
              >
                取消
              </Button>
                </Layer.Foot>

            </Layer>
        </FormAntd>
    )
}