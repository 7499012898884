import { DataTableAction } from "../../../../component/table/action/data_table_action";

class FileAction extends DataTableAction{
    delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {
        
    }
    async delRow({ dispatch, rowItem }) {}
    async loadPage({ dispatch, pageIndex, pageSize, search }) {
        // this.setLoading({dispatch, loading:true})

        const { keywords } = search;
        let param = {
            gradeName: keywords,
            pageIndex: 1,
            pageSize,
            orderBy: '',
            sort: ''
        }
        // const r = await new GradeLogic().querysList(param);

        // if (r.data.statusCode == 200) {
        //     const dataList = r.data.data.dataSource;

        //     const list = dataList.map((v, i) => {
        //         v.key = i + 1;
        //         return v;
        //     })

        //     this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: list, pageSize, loading: false });
        // } else{
        //     this.setLoading({dispatch, loading: false})
        // }
    }
    setCondition(key, value, dispatch) {
        let params = {}
        params[key] = value
        dispatch({type: 'SetCondition', payload: params})
    }
}
export const fileAction = new FileAction()