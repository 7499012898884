import message from "antd/lib/message"
// import axios from "axios"
import Cookies from "js-cookie"
import { DataTableAction } from "../../../../component/table/action/data_table_action"
import { Tools, axios } from "../../../../config"
import { SendCouponLogic } from "../../../../logic/sendCoupon_logic"
import { ShopLogic } from "../../../../logic/shop_logic"
import { UserLogic } from "../../../../logic/user_logic"
import { CouponLogic } from "../../../../logic/coupon_logic"
import { saveAs } from 'file-saver';

class SendCouponAction extends DataTableAction {

    async delRow(id) {
        const r = await new CouponLogic().delData(id);

        if (r.data.statusCode == 200) {
            message.success("删除成功")
        }
    }
    delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {
        selectList.map(async ({ id }) => {
            const r = await axios({
                method: "GET",
                url: Tools.getApiAddress("shop_delete"),
                params: {
                    shopId: id
                },
                headers: {
                    SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
                },
            })

            if (r.data.statusCode == 200) {
                message.success("删除成功")
            }
        })
    }
    async loadPage({ dispatch, pageIndex, pageSize, search }) {
        this.setLoading({dispatch,loading: true})
        const { activityName } = search;
        const params: any = {
            'activityName': activityName,
            "pageIndex": pageIndex,
            "pageSize": pageSize,          
            "orderBy":'',
            "sort":'',
        }
        // 档案列表
        const r = await new SendCouponLogic().selListByRecord(params);

        if (r.data.statusCode == 200) {
            const dataList = r.data.data.dataSource;

            const list = dataList.map((v, i) => {
                v.key = i + 1;
                v.validEndDate = v.validEndDate!=null?v.validEndDate.slice(0,10):''
                v.validStartDate = v.validStartDate!=null?v.validStartDate.slice(0,10):''
                return v;
            })

            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: list, pageSize,loading: false });
        }else{
            this.setLoading({dispatch,loading: false})
        }

    }
    setCondition({ dispatch, key, value }) {
        const params = {
        }
        params[key] = value;

        dispatch({ type: "SetSearch", payload: params })
    }
   
}
export const sendCouponAction = new SendCouponAction();