import React, { useEffect, useState } from "react";
import { Layout } from "../../component/layout";
import { TabsStyle, TabList, Tab, TabPanel } from "../../component/react-tabs";
import { DataTableStoreProvider } from "../../component/table/store/data_table_store";
import { FileStoreProvider } from "./store/file_store";
import { File } from "./view/file";
import { File as ShiftChange } from "./view/shiftChanges/file";
import { MenuLogic } from "../../logic/menu_logic";
import { ShiftChangeFileProvider } from "./store/shiftChange/file_store";
import {ShopSettingFileProvider} from './store/shop_setting_store'
import {ShopSetting} from './view/shop_setting'
// 收银提成规则
import { CommissionRule } from './view/commissionRule/file'
import { FileStoreProvider as CommissionRuleStoreProvider } from "./store/commissionRule/file_store";
export function Shop() {
  const [menuLists, setData] = useState([]);
  useEffect(() => {
    (async function () {
      let r = await new MenuLogic().menuData();
      var arr = [];
      if (r.length > 0) {
        r = r.filter(function (v) {
          return !v.hidden;
        });
        for (let i = 0; i < r.length; i++) {
          if (r[i].path == "/shop" && r[i].children) {
            arr = r[i].children.filter(function (v) {
              return !v.hidden;
            });
          }
        }
      }
      // arr.push({
      //   viewPower:'CommissionRule',
      //   component:'CommissionRule',
      //   name:'收银提成规则'
      // })
      setData(arr);
    })();

    return () => {};
  }, []);
  return (
    // <Layout fullWidth={true}>
    <TabsStyle style="Full">
      <TabList>
        {menuLists.map((v, i) => (
          <Tab key={i}>{v.name}</Tab>
        ))}
      </TabList>
      {/* 门店档案 */}
      {menuLists.map((v) =>
        v.component == "Dossier" ? (
          <TabPanel>
            <FileStoreProvider>
              <DataTableStoreProvider>
                <File viewPower={v.viewPower} />
              </DataTableStoreProvider>
            </FileStoreProvider>
          </TabPanel>
        ) : v.component == "ShiftChange" ? (
          <TabPanel>
            <ShiftChangeFileProvider>
              <DataTableStoreProvider>
                <ShiftChange viewPower={v.viewPower} />
              </DataTableStoreProvider>
            </ShiftChangeFileProvider>
          </TabPanel>
        ) : v.component == 'ShopSetting'?(
          <TabPanel>
          <ShopSettingFileProvider>
          <ShopSetting viewPower={v.viewPower}></ShopSetting>
          </ShopSettingFileProvider>
        </TabPanel>
        ): v.component == 'CommissionRule'?(
          <TabPanel>
          <CommissionRuleStoreProvider>
            <DataTableStoreProvider>
              <CommissionRule viewPower={v.viewPower}></CommissionRule>
            </DataTableStoreProvider>
          </CommissionRuleStoreProvider>
        </TabPanel>
        ):(
          <TabPanel>
            <div></div>
          </TabPanel>
        )
      )}
    </TabsStyle>
    // </Layout>
  );
}
