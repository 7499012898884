import React, { useContext, useEffect } from "react";
import { Button } from "../../../../component/button";
import { Layer } from "../../../../component/layer/layer";
import { Form as FormAntd, message } from "antd";
import { formAction, FormAction } from "../../action/stored/form_action";
import { FormStore } from "../../store/stored/form_store";
import { FormBasic } from "./form_basic";

import { FileStore } from "../../store/stored/file_store";
import { fileAction } from "../../action/stored/file_action";
import { DataTableStore } from "../../../../component/table/store/data_table_store";
import { defualtPageSize } from "../../../../logic/common_logic";
import { useBoolean } from "ahooks";
interface IForm {
    children?: any;
    action: FormAction;
    close: (status: boolean) => void;
    editId?: string;
    seeId?: string;
}
export function Form({ close, action, editId, seeId }: IForm) {
    const [form] = FormAntd.useForm();

    const { state, dispatch: formDispatch } = useContext(FormStore);
    const [loading, loadFunc] = useBoolean(false)
    const { state: fileState, dispatch: fileDispatch } = useContext(FileStore);
    const { state: tableState, dispatch: tableDispatch } = useContext(DataTableStore);
    useEffect(() => {
        formAction.init({ state: state, dispatch: formDispatch, editId: editId, seeId, form });

        return () => {

        }
    }, []);

    function title() {
        if (editId) {
            return "编辑规则";
        }
        else if (seeId) {
            return "查看规则";
        } else {
            return "新建规则";
        }
    }
    const onSearch = async (pageIndex) => {
        await fileAction.loadPage({ pageIndex: pageIndex, pageSize: defualtPageSize(), dispatch: tableDispatch, })
    }
    return (
        <FormAntd form={form}>
            <Layer layerWith={2} >
                <Layer.Title onClick={() => close(false)}>{title()}</Layer.Title>
                <Layer.Body>
                    <FormBasic title={title()} updateFormField={(field, value = '') => {
                        form.setFieldsValue({
                            [field]: value,
                        });
                        form.validateFields([field]);
                    }} />
                </Layer.Body>
                {
                    title() != "查看规则" && (
                        <>
                            <Layer.Foot>
                                <Button type="primary" color="Red" style={{ marginRight: "1.25rem" }} onClick={async () => {
                                    const r = await form.validateFields();
                                    if(loading){
                                        message.loading('正在提交数据')
                                        return false
                                    }
                                    let status = false;
                                    if (editId) {
                                        loadFunc.setTrue()
                                        status = await action.updSubmit({ state: state.formData });
                                        loadFunc.setFalse()
                                    } else {
                                        loadFunc.setTrue()
                                        status = await action.addSubmit({ state: state.formData });
                                        loadFunc.setFalse()
                                    }
                                    status && (close && close(true))
                                }}>
                                    保存
                                </Button>
                                <Button type="primary" color="Yellow" onClick={() => action.reset({ dispatch: formDispatch, form })}>
                                    重置
                                </Button>
                            </Layer.Foot>
                        </>
                    )
                }
                {
                    title() == "查看规则" && (
                        <>
                            <Layer.Foot borderStyle={title()=='查看规则'?{border:'none'}:{}}>
                            </Layer.Foot>
                        </>
                    )
                }
            </Layer>
        </FormAntd>
    )
}