import { TabKeys } from "../../tool/TabKeys";

export const salesRWTreeTK: TabKeys[] = [
    {
        key: 'mingcheng',
        title: '门店名称',
        width: 120
    },
];

export const salesRWTK: TabKeys[] = [
    // {
    //     key: 'rowNumber',
    //     title: '序号',
    //     width: 80
    // },
    {
        key: 'orderNumber',
        title: '订单号',
        width: 180,
        styles:{
            color: '#2db7f5',
            cursor:'pointer'
        }
    },
    {
        key: 'orderType',
        title: '类型',
        render: (i: number, value) => {
            switch (value) {
                case 1:
                    return '门店订单';
                case 2:
                    return '商城订单';
                case 3:
                    return '自助收银订单'
            }
        }
    },
    {
        key: 'state',
        title: '状态',
        render: (i: number, value) => {
            switch (value) {
                case 1:
                    return '待付款';
                case 2:
                    return '待收货';
                case 3:
                    return '待发货';
                case 4:
                    return '待提货';
                case 5:
                    return '已完成';
                case 6:
                    return '退单';
                case 7:
                    return '作废';
            }
        }
    },
    {
        key: 'userName',
        title: '制单人',
    },
    {
        key: 'orderAddTime',
        title: '制单时间',
    },
    {
        key: 'memberName',
        title: '会员姓名',
    },
    {
        key: 'phone',
        title: '会员手机',
    },
    {
        key: 'goodsNumber',
        title: '销售数量',
    },
    {
        key: 'sellingPrice',
        title: '销售金额',
    },
    {
        key: 'originalPrice',
        title: '原价金额',
    },
    {
        key: 'discountsPrice',
        title: '优惠金额',
    },
    {
        key: 'discountRate',
        title: '优惠率%',
    },
    {
        key: '',
        title: '支付明细(元)',
        child: [
            {
                key: 'aliPay',
                title: '支付宝',
                width: 80,
            },
            {
                key: 'weChatPay',
                title: '微信',
                width: 80,
            },
            {
                key: 'unionPay',
                title: '银联',
                width: 80,
            },
            {
                key: 'cashPay',
                title: '现金',
                width: 80,
            },
            {
                key: 'walletPay',
                title: '钱包',
                width: 80,
            },
            {
                key: 'smallChange',
                title: '找零',
                width: 80,
            },
        ],
    },
];

