// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../config";
import { effeative, maxPageSize } from "./common_logic";

export class UnitLogic {
    async querysList() {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("goods_unit_select_page"),
            data: {
                unitName: "",
                status: 1,
                pageIndex: 1,
                pageSize: maxPageSize(),
                orderBy: "",
                sort: ""
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        if (r.data && r.data.data) {
            return r.data.data.dataSource;
        }
    }
    async selData(unitId) {
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("goods_unit_sel_data"),
            params: {
                unitId
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        if (r.data && r.data.data) {
            return r.data.data;
        }
    }

}