import { createContext, useContext, useReducer } from "react";

export const FileStore = createContext<IContextProps>(null);

interface IContextProps {
  state: IState;
  dispatch: ({ type }: Dispatch) => void;
}

type Dispatch = { type; payload };

class Search {
  seachKey: string;
  id:string
}
class codeItems {
  id: string;
  goodsName: string;
  keyCode: number;
  equipID: string;
  plu: string;
  goodsNo: string;
}
class TableItem {
  id: number;
  top: codeItems;
  bottom: codeItems;
}
interface IState {
  search: Search;
  dataList: any[];
  equipLlist: any[];
  selectId: string;
  keyCode: number;
  tableList: TableItem[];
}

const initialArgs: IState = {
  search: new Search(),
  dataList: [],
  equipLlist: [],
  selectId: "",
  keyCode: 0,
  tableList: new Array(112).fill(new TableItem(), 0, 112),
};

function reducer(state: IState, { type, payload }: Dispatch) {
  switch (type) {
    case "SetSearch":
      return {
        ...state,
        search: {
          ...state.search,
          ...payload,
        },
      };
    case "Equipment": {
      state.equipLlist = payload;
      let table = [];
      state.tableList.length > 0 &&  state.tableList.map((item, index) => {
        table.push({ ...item, id: index + 1 });
      });
      state.tableList = table;
      state.selectId = payload[0]?payload[0].id : "";
      return {
        ...state,
      };
    }
    case "DataList": {
      state.dataList = payload;
      let arr = new Array(112).fill(new TableItem(), 0, 112);
      let Arr = [];
      arr.map((item, index) => {
        Arr.push({ ...item, id: index + 1 });
      });
      let table = Arr;
      if (payload instanceof Array) {
        if (payload.length > 0) {
          payload.map((item, index) => {
            if (
              item.goodsName == null ||
              item.goodsName == "" ||
              item.goodsName == undefined
            ) {
              if (item.keyCode <= 112) {
                table[item.keyCode - 1].top = null;
              } else {
                table[item.keyCode - 1 - 112].bottom = null;
              }
            } else {
              if (item.keyCode <= 112) {
                table[item.keyCode - 1].top = {
                  ...item,
                };
              } else {
                table[item.keyCode - 1 - 112].bottom = {
                  ...item,
                };
              }
            }
          });
        } else {
          // let arr = new Array(112).fill(new TableItem(), 0, 112);
          // let Arr = [];
          // arr.map((item, index) => {
          //   Arr.push({ ...item, id: index + 1 });
          // });
        }
      }
      state.tableList = table;
      return {
        ...state,
      };
    }
    case "SelectID":
      state.selectId = payload;
      return {
        ...state,
      };
    case "KeyCode":
      state.keyCode = payload;
      return {
        ...state,
      };
    default:
      return state;
  }
}

export function HotKeyFileStoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialArgs);

  const v = { state, dispatch };
  return <FileStore.Provider value={v}>{props.children}</FileStore.Provider>;
}
