// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../config";
import { effeative } from "./common_logic";

export class DistributionLogic {
    async steelyardGet() {
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("steelyard_param"),
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r.data.data;
    }
    async steelyardgetParam() {
        const r = await axios({
            method: "GET",
            url: Tools.getApiAddress("getDistribution"),
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })
        return r.data.data;
    }
    async steelyardgetAdd(param) {
        const r = await axios({
            method: "POST",
            url: Tools.getApiAddress("setDistribution"),
            data:param,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })
        return r.data;
    }

 
}