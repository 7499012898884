import { message } from "antd";
// import axios from "axios";
import Cookies from "js-cookie";
import { DataListAction } from "../../../component/data-list/action/data_table_action";
import { DataTableAction } from "../../../component/table/action/data_table_action";
import { Tools, axios } from "../../../config";
import { BrandLogic } from "../../../logic/brand_logic";
import { ClassesLogic } from "../../../logic/classes_logic";
import { effeative } from "../../../logic/common_logic";
import { GoodsLogic } from "../../../logic/goods_logic";
import { UnitLogic } from "../../../logic/unit_logic";
import { saveAs } from 'file-saver';
export class FileTableAction extends DataTableAction {
    private classesLgc = new ClassesLogic();
    private unitLgc = new UnitLogic();
    private brandLgc = new BrandLogic();

    async init({ dispatch }) {
        // 商品分类
        dispatch({ type: "LoadClasses", payload: await this.classesLgc.selListBySelect() });
        // 商品单位
        dispatch({ type: "LoadUnit", payload: await this.unitLgc.querysList() });
        // 商品品牌
        const r = await this.brandLgc.querysList();
        dispatch({ type: "LoadBrand", payload: [{ id: -1, brandName: "全部" }, ...r.data.data.dataSource] });
        // 商品类型
        dispatch({ type: "LoadType", payload: [{ id: -1, value: "全部" }, { id: 1, value: "标准商品" }, { id: 2, value: "称重商品" }] });
        // 商品状态
        dispatch({ type: "LoadStatus", payload: [{ id: -1, value: "全部" }, { id: 1, value: "正常" }, { id: 2, value: "停售" }] });
        // const downloadmodel = this.downloadMode()
        // dispatch({typpe:'LoadModelFile',payload: ['downloadmodel']})
    }
    async delRow(id) {
        const r = await axios({
            method: "GET",
            url: Tools.getApiAddress("goods_del"),
            params: {
                goodsId: id
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })

        if (r.data.statusCode == 200) {
            message.success("删除成功")
        }
    }
    async delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {

        const lis = selectList.map(async ({ id }) => {
            return axios({
                method: "GET",
                url: Tools.getApiAddress("goods_del"),
                params: {
                    goodsId: id
                },
                headers: {
                    SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
                },
            })
        })
        await Promise.all(lis);

        message.success("删除成功");
    }
    async loadPage({ dispatch, pageIndex, pageSize, search }) {
        this.setLoading({dispatch, loading:true})
        // 商品分类
        // const classifyList = await new ClassesLogic().querysList();

        // 商品单位
        // const unitList = await new UnitLogic().querysList() || [];

        // 商品列表
        const { keywords, classesId, statusId, typeId, brandId } = search;

        const params = {
            pageIndex, pageSize, goodsBrandID: brandId==-1?'':brandId, goodsClassesID: classesId, goodsType: typeId==-1?'':typeId, status: statusId==-1?'':statusId, goodsNameOrNo: keywords
        }
        if (undefined == keywords) {
            delete params.goodsNameOrNo;
        }
        if (undefined == brandId) {
            delete params.goodsBrandID;
        }
        if (undefined == classesId) {
            delete params.goodsClassesID;
        }
        if (undefined == typeId) {
            delete params.goodsType;
        }
        if (undefined == statusId) {
             params.status = '';
        }
        const r = await new GoodsLogic().selList(params);

        if (r.data.statusCode == 200) {
            const dataList = r.data.data.dataSource;

            const list = dataList.map((goods_v, i) => {
                // let clsItem = classifyList.filter(v => v.id == goods_v.goodsClassesID);
                // let uniItem = unitList.filter(v => v.id == goods_v.goodsUnitID);

                return {
                    ...goods_v,
                    goodsClassify: goods_v.goodsClassName,
                    unit: goods_v.unitName,
                    key: i + 1
                }
            })

            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: list, pageSize, loading: false });
        } else{
            this.setLoading({dispatch, loading: false})
        }

    }
    setCondition(method: "Keywords" | "Classes" | "Status" | "Type" | "Brand", value, dispatch) {
        if ("Keywords" == method) {
            dispatch({ type: "SetSearch", payload: { keywords: value } });
        }
        else if ("Classes" == method) {
            dispatch({ type: "SetSearch", payload: { classesId: value } });
        }
        else if ("Status" == method) {
            let s = value;
            if(value == -1){
                s=''
            }
            dispatch({ type: "SetSearch", payload: { statusId: s } });
        }
        else if ("Type" == method) {
            dispatch({ type: "SetSearch", payload: { typeId: value } });
        }
        else if ("Brand" == method) {
            dispatch({ type: "SetSearch", payload: { brandId: value } });
        }
    }
    async AsyncData(){
       const r = await axios({
            method: "POST",
            url: Tools.getApiAddress("goods_add_default"),           
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })
        if (r.data.statusCode == 200) {
            message.success("导入成功")
        }else{
            message.error(r.data.returnMessage)
        }
    }
    async importData(fileData){
        const r = effeative(await axios({
            method:'POST',
            url: Tools.getApiAddress('goods_import'),
            data: fileData,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        if(r.data.statusCode == 200){
            message.success("导入成功")
        }else{
            message.error(r.data.returnMessage)
        }
    }
    async downloadMode(){
        const r =  await axios({
            method:'GET',
            url:Tools.getApiAddress('goods_module'),
            // responseType: 'arraybuffer',
            headers: {
                
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
         
        })
        // console.log(r)
        if (r.status == 200) {
            let enc = new TextDecoder('utf-8')
            let data = enc.decode(new Uint8Array(r.data))
            if (data.indexOf('statusCode') != -1) {
                let datas = JSON.parse(data)
                if (datas.statusCode == 200) {
                    let blob = new Blob([r.data], { type: 'application/vnd.ms-excel' })
                    saveAs(blob, "商品导入模板.xlsx");
                } else {
                    message.error(datas.returnMessage)
                }
            } else {
                let blob = new Blob([r.data], { type: 'application/vnd.ms-excel' })
                saveAs(blob, "商品导入模板.xlsx");
            }
        }

        // if(r.status == 200){
        //     let enc = new TextDecoder('utf-8')
        //     let data = enc.decode(new Uint8Array(r.data))
        //     if (data.indexOf('status') != -1) {
        //         let datas = JSON.parse(data)
        //         if (datas.status == 200) {
        //             let blob = new Blob([r.data], { type: 'application/vnd.ms-excel' })
        //             saveAs(blob, "商品导入模板.xlsx");
        //         } else {
        //             message.error(datas.returnMessage)
        //         }
        //     } else {
        //         let blob = new Blob([r.data], { type: 'application/vnd.ms-excel' })
        //         saveAs(blob, "商品导入模板.xlsx");
        //     }
        //     // return r.data.data
        // }
    }


    /**批量上下架 */
  async updateState(params){
  const r = await  new GoodsLogic().updateStatus(params)
  let info = ''

    if(r&&r.data&&r.data.statusCode == 200){
        if(params&&params.state == 1)
        {
            info = '上架成功'
        }else{
            info = '下架成功'
        }
        message.success(info)
    }else{
        message.error(r.data.returnMessage)
    }
  }
}
export const fileAction = new FileTableAction();