import { useEffect, useRef } from "react";
import { autoRem } from "../../../global.style";
export class ITitle{
    show?:boolean = true
    text?:string=''
    subText?:string = ''
    left?:String
    right?:string
    top?:string
    bottom?:string
    backgroundColor?:string
    color?:string
    fontSize?:string
}
export class ILegend{
    show?:boolean = false
    type?:'plain' | 'scroll'
    left?:String
    right?:string
    top?:string
    bottom?:string
    orient?:'horizontal' | 'vertical' = 'vertical'
    selectedMode?:boolean = true
    icon?:'circle'| 'rect'| 'roundRect'| 'triangle'| 'diamond'| 'pin'| 'arrow'|'none' = 'rect'
}
class IParam{
    divStyles?:any
        title?: ITitle
    data: any[]
    legend?: ILegend
    name?:string 
    // center?:string[] = ['50%','50%']
    radius?: Array<Number|String> = [0,'50%']//number：直接指定外半径值。string：例如，'20%'，表示外半径为可视区尺寸（容器高宽中较小一项）的 20% 长度。    Array.<number|string>：数组的第一项是内半径，第二项是外半径。每一项遵从上述 number string 的描述。
}
declare var echarts: any;
export function Pie({title,data,legend,name,divStyles,radius}:IParam){
    const pieRef = useRef(null)
   useEffect(()=>{
    var dom = pieRef.current;
    var myChart = echarts.init(dom);
    var option;
    option = {
        title: {...title,textStyle:{
            color:title.color?title.color:'#333',
            fontSize:title.fontSize?title.fontSize:'18'
        }},
        tooltip: {
          trigger: 'item'
        },
        legend: legend,
        series: [
          {
            name: name,
            type: 'pie',
            radius: radius,
            data: data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      option && myChart.setOption(option);
   })
   let styles = divStyles?divStyles:{height:autoRem(35),width:autoRem(40)}
    return<div ref={pieRef} style={{...styles,background:'#fff'}} ></div>
    // return <div>111</div>
}





