import styles from './../style/flashsale.module.scss'
import { Tabs, Button, Modal,Table, Input, message, Tag } from 'antd';
import { useBoolean } from 'ahooks';
import {useState,useContext, useEffect } from 'react'
import { ColumnsType } from "antd/lib/table";
import { TopicDataAction } from "../action/TopicDataAction";
import { ITopicValue, TopicStore } from "../store/topic";
import {SeckillStore,SeckillStoreProvider} from '../../marketing/store/seckill/file_store'
import { InventorySearch as InventorySearchSeckill} from "../../marketing/view/seckill/file_search";
import { seckillAction   } from "../../marketing/action/seckill/file_action";
import { DataTableStoreProvider, DataTableStore } from '../../../component/table/store/data_table_store'
import { DataTable, } from "../../../component/table/view/data_table";
import { defualtPageSize, Progress } from "../../../logic/common_logic";
import { Search } from "./../../../component/search";
import { Tips, TipsGroup } from "../../../component/tips";
import { Mask } from "../../../component/mask";
import { Tools } from '../../../config';
import img_120_120 from '../../../assets/images/mini/group_120_120.png'
import {SeckillLogic} from '../../../logic/seckill_logic'
/**
 * 链接数据接口
 */
 interface IData {   
    /** 秒杀活动名称 */
    title?:string,
     /** 开始时间 */
    beginTime?:string,
     /** 结束时间 */
    endTime?:string,
 /** 秒杀商品列表 */
    datalist?:any [],
    /**是否是初始状态 */
    isInit:boolean
 
  }
  interface IFlashData{
      name:string,
      selectUrl:any
  }
export function FlashSale({id, con}) {
    const topicStoreState = useContext(TopicStore);
    const { TabPane } = Tabs;
    // 弹窗
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [activityId, setId] = useState(0)
    const showModal = (index) => {
        setId(index)
        setIsModalVisible(true);
    };
    const handleOk = () => {
        setIsModalVisible(false);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };
   
    const flashGoodsList = []
    for(let i=0;i<4;i++){
        flashGoodsList.push({
            create_time: "",
            endTime: "2021/8/31",
            item_id: 526,
            original_price: "1699.00",
            pic: img_120_120,
            snappedPrice: "1099.00",
            product_id: 1799,
            shopName: "店铺名",
            beginTime: "2021/7/29",
            goodsName: "商品名称",
            goodsSource: 1,
        })
    }
    const activitylist = [];
    for(let i=0;i<2;i++){
        activitylist.push({
            name:null,
            selectUrl:{
                snappedName:`秒杀活动`,
                beginTime:'',
                endTime: '',
                goodsList:flashGoodsList,
                isInit:true,
            }
        })
    }
   
    // const selectedlist = []   

let actionArg: [ITopicValue, number] = [topicStoreState, id];
const data: IFlashData[] = TopicDataAction.getData<IFlashData[]>(...actionArg);
const del = (index) =>{
    data.splice(index,1);
    TopicDataAction.setData<IFlashData[]>(...actionArg, data);
}
const add = () =>{
    data.push({
        name: null,
        selectUrl:{
            snappedName:`秒杀活动`,
            beginTime:'',
            endTime: '',
            goodsList:flashGoodsList,
            isInit:true
        }
    })
    TopicDataAction.setData<IFlashData[]>(...actionArg, data);
}

 useEffect(() => {
    if (!data) {
      TopicDataAction.setData<IFlashData[]>(...actionArg, activitylist);
    }   
  });
  if(!data){
      return<div></div>
  }

  TopicDataAction.setEF(topicStoreState, id, () => {
    return new Promise((r, e) => {
      if (data.some((item) => {
        return (item.selectUrl.isInit)
      })) {
        message.error('秒杀没有填写完整');
      } else {
        r(data);
      }
    });
  });
// const [activityName,setName] = useState('')
    if(con == 'show'){
        return(
            <div className={styles.container}>
                <Tabs defaultActiveKey="0" >
                    {data&&data.length>0?data.map((tab,index)=>(
                        <TabPane tab={tab.name&&tab.name!=null?tab.name:tab.selectUrl.snappedName} key={index}>
                            <List list={tab.selectUrl.goodsList} type={tab.name}/>
                        </TabPane> 
                    )):activitylist.map((tab,index)=>(
                        <TabPane tab={tab.selectUrl.snappedName} key={index}>
                            <List list={tab.selectUrl.goodsList} type='defualt'/>
                        </TabPane> ))}                   
                                 
                </Tabs>
                <div className={styles.ctrl}>
                            
                </div>  
            </div>
        )
    }else{
        return (
        <div className={styles.ctrl}>
            <h2>秒杀商品设置</h2>
            <div style={{padding:'20px 0',borderTop:'1px solid #eee'}}>
                <ul>
                   {data&&data.length>0&&data.map((activity,index)=>{
                       return(
                        <li className={styles.flashsale_list} key={index}>
                            <span>活动名称：<Input style={{ width: "20rem" }} maxLength={20} value={activity.name!=null?activity.name:activity.selectUrl.snappedName} onChange={(e)=>{ data[index].name = e.target.value; TopicDataAction.setData<IFlashData[]>(...actionArg, data);}}/></span>
                            <span style={{color:'#eee',fontSize:12}}>最多输入20个字</span>
                            <Button type="primary" style={{width:'20%'}} onClick={()=>showModal(index)}>选择秒杀</Button>   
                            <span className={styles.activity_del} onClick={()=>del(index)}>×</span>                            
                        </li>
                       )
                   })} 
                   <Modal title={<div>选择秒杀活动</div>} footer={null} visible={isModalVisible} width={1000} onOk={handleOk} onCancel={handleCancel} >
                       <SeckillStoreProvider><DataTableStoreProvider> 
                            <ShowActivity close={() => handleCancel()} list={data} activityId={activityId} id={id}/>
                            </DataTableStoreProvider>
                        </SeckillStoreProvider> 
                    </Modal>
                    <div className={styles.ctrl_list_add} onClick={add} >
                        +
                    </div>                    
                </ul>
            </div>
        </div>  )
    }
    
}
const List = ({list,type}) => {
    return(
        <ul className={styles.flashsale_good_list}>
           {list&&list.length>0&&list.map((item,index)=>(
                <li key={index}>
                <span>
                    <img src={item.goodsImage&&item.goodsImage!=''?Tools.getImageAddress(item.goodsImage,item.goodsSource):item.pic} alt={item.goodsName}/>
                </span>
                <div className={styles.price_and_title}>
                    <p style={{color:'#000',fontSize:13,}}>{item.goodsName}</p>
                   { item.storePrice>item.snappedPrice&& <span className={styles.origin_price}>￥{item.storePrice}</span>}
                    <span>￥{item.snappedPrice}</span>
                </div>
            </li>         
           ))}   
        </ul>
    )
}

const ShowActivity = ({close, list, id, activityId}) =>{
    const store = useContext(TopicStore)
    const actionArg: [ITopicValue, number]  = [store, id]
     const [selectList, setSelectList] = useState(list);

  
    const columns: ColumnsType = [
        {
            title: '活动名称',
            dataIndex:'snappedName',
            align:'center'
        },
        {
            title:'开始时间',
            dataIndex:'beginTime',
            align:'center'
        },
        {
            title:'结束时间',
            dataIndex:'endTime',
            align:'center'
        },
        {
            title: '活动状态',
            // dataIndex: 'status',
            key: '6',
            align: "center",
            render: (v) => (
                <>
                  <Tag
                    color={
                      v.dataState == 0
                        ? "geekblue"
                        : v.dataState == 1
                        ? "success"
                        : "volcano"
                    }
                  >
                    {v.dataStateString}
                  </Tag>
                </>
              ),
        },
        {
            title:'创建人',
            dataIndex:'addUID',
            align:'center'
        },
        {
            title:'创建时间',
            dataIndex:'addTime',
            align:'center'
        }
    ];
    const { total, pageSize, current, dataList, selectedRowKeys } = store.state as any;
    const paginationProps = {
        showSizeChanger: false,
        showQuickJumper: false,
        showTotal: () => `共${total}条`,
        pageSize: pageSize,
        current: current,
        total: total,
        onChange: (current) => {
            // clearSelectedRowKeys({ dispatch });
            // onChange && onChange(current);
        }
    }
    interface DataType {
        key: React.Key;
        title: string;
        endTime: string,
        beginTime:string
    }   

    const action  = seckillAction;
    const [searchState, searchFunc] = useBoolean(false);

    const { state, dispatch } = useContext(SeckillStore);
    const { state: tableState, dispatch: tableDispatch } = useContext(DataTableStore);
    const onSearch = async (pageIndex) => {
        await action.loadPage({ pageIndex: pageIndex, pageSize: defualtPageSize(), dispatch: tableDispatch, search: state.search }) 
     }
        const selected = async() => {
                    const item = await new SeckillLogic().selDataByWarehousing(tableState.selectList[0].id);
                    let list = item?item.goodsList:[]
                 let datalist =  []
                 if( list.length>0){
                     list.map(l=>{
                        if(l.goodsSep&&l.goodsSep.length>0){
                            l.goodsSep.map(sep=>{
                            datalist.push({
                                ...sep,
                                ...l
                            })
                            })
                        }
                      
                    })}
                const data: IFlashData[] = TopicDataAction.getData<IFlashData[]>(...actionArg);
                data[activityId].selectUrl = tableState.selectList[0]
                data[activityId].selectUrl.goodsList= datalist
                //console.log(data)
                TopicDataAction.setData<IFlashData[]>(...actionArg,data)
        }
    return(
       <div style={{marginTop: -20}}>
               <div className={styles.header_search}>
            <Search>
                <Input allowClear placeholder="请输入活动名称" onChange={e => action.setCondition({ dispatch, key: "name", value: e.target.value })} />
                <input type="button" value="快速查找" onClick={() => onSearch(1)} />

                <TipsGroup>
                    <input type="button" value="高级查找" onClick={() => searchFunc.toggle()} />
                    <Tips display={searchState} width="48rem">
                        <InventorySearchSeckill onSearch={()=> onSearch(1)} />
                    </Tips>
                    <Mask visible={searchState} onClick={() => searchFunc.setFalse()} />
                </TipsGroup>
            </Search>
            </div>
        
          {/* <Table
        showHeader={true}
        columns={columns}
        dataSource={data}
        scroll={{ y: 500 }}
        {...paginationProps}
    /> */}

                    <DataTable columns={columns} action={action}
                        onLoad={async () => { await onSearch(1); Progress.done() }}
                        onChange={(pageIndex) => { onSearch(pageIndex) }}
                        scroll={{ scrollToFirstRowOnChange: true, autoY: true }}
                        rowSelection={{type:'radio',getCheckboxProps:(record)=>({disabled:record.dataState !=1})}}
                        selectColumn={true}
                    />
                    {tableState.dataList.length !=0?<div>
                <Button style={{background:'#f7ba00',borderColor:'#f7ba00',marginRight:10}} type="primary" onClick={()=>{close();}}>取消</Button>
                <Button type="primary" onClick={()=>{selected();close();}}>确定</Button>
            </div>:null}
       </div>
    )
}
