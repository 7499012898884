import { Button } from '../../../../../component/button';
import appletImgUrl from '../../../../../assets/images/applet/weapp_hasxcx.png';
import styles from "../../../style/applet/noAu.module.scss";
import { WxSystemApiCon } from '../logic/WxSystemApiCon';
/**
 * 小程序未授权
 */
export function NoAuthorize() {
    return <div className={styles.root}>
        <img src={appletImgUrl} alt="" />
        <span className={styles.a}>我已拥有微信小程序</span>
        <span className={styles.b}>小程序管理员可一键将小程序授权绑定到店铺</span>
        <Button className={styles.button} type="primary" onClick={() => {
            //获取授权链接
            WxSystemApiCon.componentloginpage();
        }}>一键授权绑定小程序</Button>
    </div>
}