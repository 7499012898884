import React, { useContext, useState } from "react";
import {
  Button,
  Cascader,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  TreeSelect,
} from "antd";
import styles from "../style/file_search.module.scss";
import { fileAction } from "../action/file_action";
import { useRequest } from "ahooks";
import { Select as SelectAntd } from "antd";
import { PrintStore } from "../store/print_store";

const { Option } = Select;

export function PrintSearch({ onSearch }) {
  const { state, dispatch } = useContext(PrintStore);
  const action = fileAction;

  return (
    <div>
      <Form layout="horizontal">
        <div className={styles.row}>
          <div className={styles.cell}>
            <Form.Item label="商品分类">
              <TreeSelect
                style={{ width: "15rem" }}
                showCheckedStrategy={TreeSelect.SHOW_PARENT}
                dropdownMatchSelectWidth={false}
                defaultValue={state.search.classesId}
                treeCheckable={true}
                treeData={state.classesList}
                onChange={(v) => {
                  action.setCondition(
                    "Classes",
                    v.length > 0 ? v : [],
                    dispatch
                  );
                }}
                placeholder="选择商品分类"
              />
            </Form.Item>
          </div>
          <div className={styles.cell}>
            <Form.Item label="商品状态">
              <Select
                style={{ width: "15rem" }}
                onChange={(v) => {
                  action.setCondition("Status", v, dispatch);
                }}
                defaultValue={-1}
                showSearch
                filterOption={(input:any, option:any) => {
                  return option.children.indexOf(input) >= 0;
                }}
              >
                {state.statusList.map((v) => (
                  <SelectAntd.Option key={v.id} value={v.id}>
                    {v.value}
                  </SelectAntd.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className={styles.cell}>
            <Form.Item label="商品类型">
              <Select
                style={{ width: "15rem" }}
                onChange={(v) => action.setCondition("Type", v, dispatch)}
                defaultValue={-1}
                showSearch
                filterOption={(input:any, option:any) => {
                  return option.children.indexOf(input) >= 0;
                }}
              >
                {state.typeList.map((v) => (
                  <SelectAntd.Option key={v.id} value={v.id}>
                    {v.value}
                  </SelectAntd.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className={styles.cell}>
            <Form.Item label="商品品牌">
              <Select
                style={{ width: "15rem" }}
                onChange={(v) => action.setCondition("Brand", v, dispatch)}
                defaultValue={-1}
                showSearch
                filterOption={(input:any, option:any) => {
                  return option.children.indexOf(input) >= 0;
                }}
              >
                {state.brandList.map((v) => (
                  <SelectAntd.Option key={v.id} value={v.id}>
                    {v.brandName}
                  </SelectAntd.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </div>
        <div className={styles.row}>
          <Button type="primary" danger onClick={() => onSearch(1)}>
            搜 索
          </Button>
        </div>
      </Form>
    </div>
  );
}
