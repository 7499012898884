import { useBoolean } from "ahooks";
import { Button, message, Modal, Input, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect, useState } from "react";
import { DataTable } from "../../../../component/table";

import { fileAction } from "../../action/gradeChangeLogs/file_action";
import { DataTableStore } from "../../../../component/table/store/data_table_store";

import { FileStore, CHANGE_TYPE} from "../../store/gradeChangeLogs/file_store";
import { Search } from "../../../../component/search";
import { defualtPageSize, Progress } from "../../../../logic/common_logic";
import { AuthLogic } from "../../../../logic/auth_logic";

let editId = "";
let seeId = "";
const formParams = {
    method: "Create",
    id: ""
}
export function GradeChangeLogs(viewPower) {
    const [createState, createFunc] = useBoolean(false);
    const [confirmState, confirmFunc] = useBoolean(false);
    const [deleteState] = useBoolean(false);
    const [] = useBoolean(false);
    const action = fileAction;
    const { state, dispatch } = useContext(FileStore);
    const { state: tableState, dispatch: tableDispatch } = useContext(DataTableStore);
    const [menuButton, setData] = useState([])
    useEffect(() => {
        (async function () {
            let r = await new AuthLogic().selButton(viewPower.viewPower);
            if (r&&r.length > 0) {
                for (let i = 0; i < r.length; i++) {

                    r[i] = r[i].split('_')[r[i].split('_').length - 1]
                }
            }
            setData(r);
        }())

        return () => {

        }
    }, []);
    const columns: ColumnsType = [
        {
            title: '会员卡号',
            dataIndex: 'memberCard',
            key: '2',
            align: "center",
            width: 120
        },
        
        {
            title: '会员姓名',
            dataIndex: 'memberName',
            key: '3',
            align: "center",
            width: 150
        },
        {
            title: '变更前等级',
            dataIndex: 'beforeClassName',
            key: '4',
            align: "center",
        },
        {
            title: '变更后等级',
            dataIndex: 'afterClassName',
            key: '5',
            align: "center",
        },
        {
            title: '变更时间',
            dataIndex: 'changeTime',
            key: '6',
            align: "center",
            width: 150
        },
        {
            title: '变更类型',
            dataIndex: 'changeType',
            key: '7',
            align: "center",
            width: 100,
            render: v=><span>{CHANGE_TYPE[v]?CHANGE_TYPE[v].text: ''}</span>
        },
        {
            title: '关联订单号',
            dataIndex: 'linkNumber',
            key: '8',
            align: "center",
            width: 190
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: '9',
            align: "center"
        },
    ];
    const onSearch = async (pageIndex) => {
        await action.loadPage({ pageIndex: pageIndex, pageSize: defualtPageSize(), dispatch: tableDispatch, search: state.search })
    }
    return (
        <>
            <Search>
                <Input allowClear placeholder="请输入会员名称、手机号" onChange={e => action.setCondition({key: 'seackerKey', value: e.target.value, dispatch})} 
                onPressEnter={()=>onSearch(1)}/>
                <input type="button" value="快速查找" onClick={() => onSearch(1)} />
            </Search>
            <DataTable columns={columns} action={fileAction}
                onLoad={async () => { await onSearch(1); Progress.done() }}
                onChange={(pageIndex) => { onSearch(pageIndex) }}
                scroll={{ scrollToFirstRowOnChange: true, autoY: true }}
                selectColumn={false}
                loading={ tableState.loading }
            />
        </>
    )
}

function setData(r: any) {
    throw new Error("Function not implemented.");
}
