import { useState, useRef, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { message, Modal } from "antd";
import styles from "./apply.module.scss";
import useIcon from "../assets/images/agent/20210819182523.png";
import phoneIcon from "../assets/images/agent/20210819182519.png";
import idIcon from "../assets/images/agent/20210819182532.png";
import vCodeIcon from "../assets/images/agent/20210819182529.png";
import nSelectIcon from "../assets/images/agent/20210819182535.png";
import zzIcon from "../assets/images/agent/20210819182526.png";
import ySelectIcon from "../assets/images/agent/20210819182538.png";
import selectImgIcon from "../assets/images/agent/add.png";
import { ApiCon } from "./logic/ApiCon";
import { Agreement } from "./agreement";
import {
    LoadingOutlined,
} from '@ant-design/icons';
import { AgreementLogin } from "../logic/agreement_login";

/** 设置验证点击时间 */
function setCodeTime() {
    localStorage.setItem('codeVTime', Date.now() + '');
}
/** 获取验证码点击时间 */
function getCodeTime(): number {
    return parseInt(localStorage.getItem('codeVTime') || '0');
}
const codeTime: number = 1000 * 60;

/** 代理商页面 */
export function AgentApply() {
    //设置title
    document.title = '多点多-代理申请';
    const his = useHistory();
    const [nav, setNav] = useState<'1' | '2'>('1');
    const [select, setSelect] = useState(false);
    const inputRef = useRef(null);
    const [formData, setFromData] = useState({
        /** 名字 */
        name: '',
        /** 手机号 */
        phone: '',
        /** 身份证号 */
        sfz: '',
        /** 验证码 */
        vCode: '',
        /** 证书 */
        yyzz: '',
    });
    const [fromError, setFormError] = useState<{
        [index: string]: string,
    }>({});
    const [ifSubmit, setIfSubmit] = useState(false);
    //获取验证码时间差异
    const [codeTimeDeff, setCodeTimeDeff] = useState(Infinity);
    const [timeCon, setTimeCon] = useState(null);
    const [showXY, setShowXY] = useState(false);
    const [agreementContent, setAgreementContent] = useState<any>({});

    /** id */
    let id: string = his.location.search.match(/id=(.*)&?/)?.[1];
    useEffect(() => {
        // clerar
        if (id) {
            //获取数据
            ApiCon.GetInfo(id).then((data) => {
                if (!data) {
                    message.error('找不到这个用户的数据');
                    return;
                }
                //设置导航
                setNav(data.AppType);
                //填充数据
                setFromData({
                    ...formData,
                    name: data.AppName,
                    phone: data.AppPhone,
                    sfz: data.ID_Number,
                    yyzz: data.EnterpriseLicense,
                });
            }).catch((e) => {
                message.error(e);
            });
        }
        //添加一个计时器
        timeCon && clearInterval(timeCon);
        setTimeCon(setInterval(() => {
            /** 设置时间差 */
            setCodeTimeDeff(Date.now() - getCodeTime());
        }, 1000) as any);
        //
        return () => {
            clearInterval(timeCon);
        };
    }, []);

    useEffect(() => {
        //获取多点多代理商申请协议内容
        AgreementLogin.getAgent().then(data => {
            setAgreementContent(data);
        });
    }, []);

    /** 设置表单数据 */
    const setForm = (key: string, value: any) => {
        setFromData({
            ...formData,
            [key]: value,
        });
    }

    /** 表单验证规则 */
    const fromV: {
        [index: string]: {
            v: (d: any) => string;
        }
    } = {
        name: {
            v: (d: any) => {
                if (!d) { return '请输入用户姓名' }
                return '';
            }
        },
        phone: {
            v: (d: any) => {
                if (!d) { return '请输入手机号码' }
                if (!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(d)) {
                    return '请输入正确的手机号码';
                }
                return '';
            }
        },
        sfz: {
            v: (d: any) => {
                if (!d) { return '请输入身份证号' }
                if (!/^\d{15}|\d{18}$/.test(d)) {
                    return '请输入正确的身份证号';
                }
                return '';
            }
        },
        vCode: {
            v: (d: any) => {
                if (!d) { return '请输入验证码' }
                return '';
            }
        },
        yyzz: {
            v: (d: any) => {
                if (nav != '2') { return; }
                if (!d) { return '请上传营业执照' }
                return '';
            }
        },
    }
    /** 提交 */
    const submitClick = () => {
        if (ifSubmit) { return; }
        //验证规则
        for (let key in fromV) {
            let s: string = fromV[key].v(formData[key]);
            if (s) {
                message.error(s);
                // setFormError({
                //     [key]: s,
                // });
                return;
            }
        }
        if (!select) {
            message.error('请阅读并同意《多点多代理申请用户协议》');
            return;
        }
        //提交
        setIfSubmit(true);
        let op = {
            id: id || '',
            AppType: nav,
            AppName: formData.name,
            AppPhone: formData.phone,
            ID_Number: formData.sfz,
            EnterpriseLicense: formData.yyzz,
            Captcha: formData.vCode,
        };
        ApiCon.AddOrUpdateInfo((op)).then((data) => {
            message.success('提交成功');
            //清理数据
            setFromData({
                name: '',
                phone: '',
                sfz: '',
                vCode: '',
                yyzz: '',
            });
            setFormError({});
        }).catch((e) => {
            message.error(e);
        }).finally(() => {
            setIfSubmit(false);
        });
    };

    /** 发送验证码 */
    const sendVCode = () => {
        let s: string = fromV['phone'].v(formData.phone);
        if (s) {
            message.error(s);
            return;
        }
        if (Date.now() - getCodeTime() <= codeTime) {
            return;
        }
        const hideLoading = message.loading('验证码发送中..', 0);
        ApiCon.SendCode(formData.phone, !id).then(() => {

            message.success('验证码已发送，请注意查收！');
            //设置获取验证码的时间，只有成功才会设置,失败是不会设置的
            setCodeTime();
        }).catch((e) => {
            message.error(e);
        }).finally(() => {
            hideLoading();
        });
    }

    /** 选择图片 */
    const selectImage = () => {
        inputRef.current.click();
    };
    /** 获取图片 */
    const inputChange = () => {
        let _file: File = inputRef.current.files[0];
        if (!_file) { return; }
        //上传文件
        let fromData = new FormData();
        fromData.set('file', _file);
        ApiCon.UploadFile(fromData).then((data) => {
            message.success('上传成功');
            setForm('yyzz', data);
        }).catch((e) => {
            message.error(e);
        });
    };

    let imgUrl: string = formData.yyzz;
    if (imgUrl) {
        imgUrl = ApiCon.getImgURl(imgUrl);
    }

    return <div className={styles.root}>
        <div className={styles.content}>
            <h1>多点多代理申请</h1>
            <div className={styles.media}>
                <div className={styles.content}>
                    <div className={styles.nav}>
                        <span className={nav == '1' ? styles.on : ''} onClick={() => {
                            setNav('1');
                        }}>个人申请</span>
                        <span>|</span>
                        <span className={nav == '2' ? styles.on : ''} onClick={() => {
                            setNav('2');
                        }}>企业申请</span>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.form}>
                            <div className={styles.item}>
                                <div className={styles.title}>
                                    <img src={useIcon} alt="" />
                                    <span className={styles.must}>*</span>
                                    <span>用户姓名</span>
                                    {fromError['name'] && <span className={styles.error}>{fromError['name']}</span>}
                                </div>
                                <div className={styles.input}>
                                    <input maxLength={50} value={formData.name} onChange={(e) => {
                                        setForm('name', e.target.value);
                                    }} placeholder="请输入用户姓名" type="text" />
                                </div>
                            </div>
                            <div className={styles.item}>
                                <div className={styles.title}>
                                    <img src={phoneIcon} alt="" />
                                    <span className={styles.must}>*</span>
                                    <span>手机号码</span>
                                    {fromError['phone'] && <span className={styles.error}>{fromError['phone']}</span>}
                                </div>
                                <div className={styles.input}>
                                    <input value={formData.phone} onChange={(e) => {
                                        setForm('phone', e.target.value);
                                    }} placeholder="请输入手机号码" type="number" />
                                </div>
                            </div>
                            <div className={styles.item}>
                                <div className={styles.title}>
                                    <img src={idIcon} alt="" />
                                    <span className={styles.must}>*</span>
                                    <span>身份证码</span>
                                    {fromError['sfz'] && <span className={styles.error}>{fromError['sfz']}</span>}
                                </div>
                                <div className={styles.input}>
                                    <input value={formData.sfz} onChange={(e) => {
                                        setForm('sfz', e.target.value);
                                    }} placeholder="请输入身份证码" type="text" />
                                </div>
                            </div>
                            <div className={styles.item}>
                                <div className={styles.title}>
                                    <img src={vCodeIcon} alt="" />
                                    <span className={styles.must}>*</span>
                                    <span>短信验证码</span>
                                    {fromError['vCode'] && <span className={styles.error}>{fromError['vCode']}</span>}
                                </div>
                                <div className={styles.input}>
                                    <input maxLength={10} value={formData.vCode} onChange={(e) => {
                                        setForm('vCode', e.target.value);
                                    }} placeholder="请输入短信验证码" className={styles._input} type="text" />
                                    {codeTimeDeff <= codeTime
                                        ?
                                        <span className={styles.__span}>{Math.ceil((codeTime - codeTimeDeff) / 1000)}秒后重新获取</span>
                                        :
                                        <span className={styles._span} onClick={sendVCode}>获取验证码</span>
                                    }
                                </div>
                            </div>
                            {
                                nav == '2' && <div className={styles.item}>
                                    <div className={styles.title}>
                                        <img src={zzIcon} alt="" />
                                        <span className={styles.must}>*</span>
                                        <span>营业执照</span>
                                        {fromError['yyzz'] && <span className={styles.error}>{fromError['yyzz']}</span>}
                                    </div>
                                    <div className={styles.input + ' ' + styles.file_input}>
                                        <input
                                            ref={inputRef}
                                            onChange={inputChange}
                                            type="file"
                                            accept="image/*"
                                        />
                                        <img src={imgUrl || selectImgIcon} alt="" onClick={() => { selectImage() }} />
                                    </div>
                                </div>
                            }
                        </div>
                        <div className={styles.button}>
                            <button onClick={submitClick} disabled={ifSubmit} style={ifSubmit ? {
                                opacity: 0.8,
                                cursor: 'not-allowed',
                            } : {}}>
                                {
                                    ifSubmit && <LoadingOutlined style={{ marginRight: 10 }} />
                                }
                                提交</button>
                            <div className={styles.agreement}>
                                <img src={select ? ySelectIcon : nSelectIcon} alt="" onClick={() => {
                                    //可以直接取消但不能直接选择
                                    if (select) {
                                        setSelect(false);
                                    } else {
                                        setShowXY(true);
                                    }
                                }} />
                                <span>阅读并同意<span onClick={() => {
                                    setShowXY(true);
                                }}>《多点多代理申请用户协议》</span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.gs}>
                <span>技术支持：四川智如客科技有限公司</span>
            </div>
        </div>
        <Modal
            className={styles.agreement_modal}
            title="多点多代理申请用户协议"
            visible={showXY}
            centered
            width={680}
            footer={<Agreement ok={() => {
                setSelect(true);
                setShowXY(false)
            }} />}
        >
            <div dangerouslySetInnerHTML={{ __html: agreementContent.Content }}></div>
        </Modal>
    </div>
}