import { message } from "antd";
// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../../../config";

export async function fetchCodeAction(state, dispatch) {
    const { data, status } = await axios({
        method: "GET",
        url: Tools.getApiAddress("auth_code")
    })
    if (200 == status) {
        dispatch({
            ...state,
            rawCode: data.data,
            code: data.data.captchaCode
        })
    }
}
export async function fetchLoginAction(state, setData, his) {
    const { account, passwd, inputCode, rawCode, code, phoneCode } = state;
    const { data, status } = await axios({
        method: "POST",
        url: Tools.getApiAddress("reset_psw"),
        data: {
            "phoneNumber": account,
            "passWord": passwd,
            "codeNumber": phoneCode,
        }
    })
    if (200 == status) {

        if (200 != data.statusCode) {
            message.error(data.returnMessage == null ? data.message : data.returnMessage)
            fetchCodeAction(state, setData)
        } else {
            message.success('保存成功');
            Cookies.set("token", "");
            Cookies.set("loginMsg", '');
            setTimeout(() => {
                his.replace('/login');
            }, 1000);
        }
    }
}

