// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../config";
import { effeative } from "./common_logic";
export class SwitchParamLogic{
    async setParams(op){
        const r =   effeative(await axios({
            method:'GET',
            url: Tools.getApiAddress('switch_params_set'),
            params: op,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r
    }
    async queryPage(){
        return  effeative(await axios({
            method:'GET',
            url: Tools.getApiAddress('switch_params_query'),
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        
    }
}