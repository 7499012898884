import { createContext, useContext, useReducer } from "react";

export const FormStore = createContext<IContextProps>(null);

interface IContextProps {
    state: IState;
    dispatch: ({ type }: Dispatch) => void;
}

type Dispatch = { type, payload }

class FormData{
    name: ''
}
interface IState {
    formData: FormData
}

const initialArgs:IState = {
    formData: new FormData()
}

function reducer(state: IState, { type, payload }: Dispatch) {
    switch (type) {
        case 'ChangeFormData':
            return{
                ...state,
                formData:{
                    ...state.formData,
                    ...payload,
                }
            }
        default:
            return state;
    }
}

export function FormStoreProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialArgs);

    const v = { state, dispatch };
    return <FormStore.Provider value={v}>{props.children}</FormStore.Provider>
}