import { message } from "antd";
import { DataTableAction } from "../../../../component/table/action/data_table_action";
import { defualtPageSize } from "../../../../logic/common_logic";
import { GoodsLogic } from "../../../../logic/goods_logic";
import { InventoryLogic } from "../../../../logic/inventory_logic";
import { ShopLogic } from "../../../../logic/shop_logic";
import { UnitLogic } from "../../../../logic/unit_logic";
import { TagLogic } from "../../../../logic/tag_logic";
import { CouponLogic } from "../../../../logic/coupon_logic";
import { ClassesLogic } from "../../../../logic/classes_logic";
import { SendCouponLogic } from '../../../../logic/sendCoupon_logic'
import {initialArgs} from '../../store/batchCoupon/form_store'
type Init = {
    state;
    dispatch;
    form;
    couponID: string;
    method: string;
    id:string;
}
interface InitBasic {
    dispatch;
    search;
}
interface InitEdit {
    state;
    dispatch;
    form;
    couponID?;
    id?;
}
interface ISetValue {
    attribute: any;
    value: string | number | any[];
    dispatch;
}

class BatchCouponFormAction {
    setValue(arg0: { dispatch: ({ type }: { type: any; payload: any; }) => void; value: any; attribute: string; }) {
        throw new Error("Method not implemented.");
    }


    async init({ state, dispatch, couponID, method, form, id }: Init) {

        await this.initBasic({ dispatch, search: state.search });
        if ("Create" !== method && couponID) {
            await this.initEdit({ dispatch, state, couponID, form ,id})
        }
    }
    private async initBasic({ search, dispatch }: InitBasic) {
        this.clearTableList({ dispatch });
        dispatch({ type: "LoadTag", payload: await new TagLogic().querysList() });
        // 用户信息
        // {
        //     const r = await new UserLogic().selData();

        //     this.setFormData({ dispatch, key: "userName", value: r.userName })
        // }
        // 商品搜索
        {
            const { keywords, sendStatus } = search;

            const params = {
                pageIndex: 1, pageSize: defualtPageSize(),activityNameOrNo: keywords, sendStatus:sendStatus
            }
            if (undefined == keywords) {
                delete params.activityNameOrNo;
            }
            if (undefined == sendStatus) {
                delete params.sendStatus;
            }          

            const r = await new SendCouponLogic().selListByRecord(params)
            if (r.data.statusCode == 200) {
                const dataList = r.data.data.dataSource || [];

                dispatch({ type: "SetSearchList", payload: dataList });

            }
  
        }
    }
    private async initEdit({ dispatch, couponID, form , id}: InitEdit) {
        // 回填状态
        // 优惠券列表
        //会员列表
        const r = await new ShopLogic().selListpost();
        
        dispatch({ type: "LoadTag", payload: await new TagLogic().querysList() });

        const sendCoupon = await new SendCouponLogic().selData(id)

        const item = await new CouponLogic().selDataByWarehousing(couponID);

        const params = {
            pageIndex: 1, pageSize: 999, couponID: '', goodsClassesID: '', goodsType: '', status: '', goodsNameOrNo: ''
        }
      
        if (sendCoupon&&sendCoupon.data&&sendCoupon.data.statusCode == 200) {
         let  dataList = sendCoupon.data.data;
            
            this.AddMemberTableItem({dispatch,list: dataList['members']})

            item['couponQuantity'] = dataList.couponQuantity
            dataList['coupon'] = [item]
            let tags =  []
         if(dataList.labels.length>0){
                dataList.labels.map(tag=>{
                    tags.push(tag.id)
                })
            }
            dataList['labels'] = tags
            dispatch({ type: "AddCouponTableItem", payload: [item] });
            dispatch({ type: "SetForm", payload: dataList });
         }
    
    }



    async addSubmit({ state, status }) {
        //console.log(state)
        if (state.formData.activityName == undefined || state.formData.activityName == '') {
            message.error("请输入名称");
            return false;
        }
        if (state.formData.sendType == 2) {//定时发券选择日期
           
        }
      
        if (state.formData.couponID == undefined || state.formData.couponID == '') {
            message.error("请选择优惠券");
            return false;
        }
       
        if (state.formData.couponQuantity == undefined || state.formData.couponQuantity == '') {
            message.error("请填写发券数量");
            return false;
        }else{
            if (!(/^[1-9]\d*$/).test(state.formData.couponQuantity)) {
                message.error("请填写正整数");
                return false;
            }
        }
       
       

        let sendTargets = []
        if(state.formData.sendMemberType == 1){
            if (state.formData.members == undefined || state.formData.members.length  == 0) {
                message.error("请选择发券会员");
                return false;
            }
            else if(state.formData.members&&state.formData.members.length>0){
                state.formData.members.forEach(m => {
                    sendTargets.push({targetID:m.id})
                });
            }
        }
        if(state.formData.sendMemberType ==2){
            if (state.formData.labels == undefined || state.formData.labels.length  == 0) {
                message.error("请选择会员标签");
                return false;
            }
            if(state.formData.labels&&state.formData.labels.length>0){
                state.formData.labels.forEach(m => {
                    sendTargets.push({targetID:m})
                });
            }
        }
        let param = {}
      param['activityName'] = state.formData.activityName
        param['couponID'] = state.formData.couponID
        param['couponQuantity'] = parseInt( state.formData.couponQuantity )
        param['sendMemberType'] = state.formData.sendMemberType
        param['sendType'] = state.formData.sendType
        param['sendTargets'] = sendTargets

        const r = await new SendCouponLogic().addOperation(param);
        if (r.data.statusCode == 200) {
            message.success("保存成功");
            return true;
        } else {
            message.error(r.data.message);
            return false;
        }

    }
   
    AddMemberTableItem({ dispatch, list }) {      
            dispatch({ type: "AddMemberTableItem", payload: list});  
    }
    AddCouponTableItem({ dispatch, list }) {
        dispatch({ type: "AddCouponTableItem", payload: list });
    }
    DelCouponTableItem(dispatch, index) {
        dispatch({ type: "DelCouponTableItem", payload: index });
    }
    DelMemberTableItem(dispatch, index) {
        dispatch({ type: "DelMemberTableItem", payload: index });
    }
    setFormData({ dispatch, key, value }) {
        const params = {
        }
        params[key] = value;
        dispatch({ type: "SetFormData", payload: params })
    }
    setKeywords({ dispatch, value, state }) {
        this.setFormData({ dispatch, key: "keyword", value: value });

        state.searchList.forEach(e => {
            if (e.id == value) {
                this.setFormData({ dispatch, key: "keyword", value: value });

                dispatch({ type: "SetFormData", payload: { inStockUnitPrice: e.putNewPrice } })
            }

        })
    }
    changeTableItem({ dispatch, value, index }) {
        dispatch({ type: "ChangeTableItem", payload: { value, index } });
    }
    clearTableList({ dispatch }) {
        dispatch({ type: "ClearTableList" });
    }

    reset({dispatch}){
        dispatch({type:'SetForm',payload:{
            ...initialArgs
        }})
    }
}
export const batchcouponformAction = new BatchCouponFormAction();