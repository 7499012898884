import { Input, message } from "antd";
import { useEffect } from "react";
import { useContext } from "react";
import { comConType } from "..";
import { TopicDataAction } from "../action/TopicDataAction";
import { DrageList } from "../com/dragList";
import { SelectUrl } from "../com/selectUrl";
import { ITopicValue, TopicStore } from "../store/topic";
import styles from "../style/textlink.module.scss";
import { ISelectUrl } from "./modal/modal";

/**
 * 链接数据接口
 */
interface IData {
  /** 标题 */
  title: string;
  /** 选择 */
  selectUrl?: ISelectUrl;
}

/**
 * 文字链接
 * @param props
 */
export function Textlink(props: { id: number, con: comConType }) {
  const topicStoreState = useContext(TopicStore);

  let actionArg: [ITopicValue, number] = [topicStoreState, props.id];
  const data: IData[] = TopicDataAction.getData<IData[]>(...actionArg);
  useEffect(() => {
    if (!data) {
      TopicDataAction.setData<IData[]>(...actionArg, [
        {
          title: "",
        },
      ]);
    }
  });
  if (!data) {
    return <div></div>;
  }

  //注册数据提取方法
  TopicDataAction.setEF(topicStoreState, props.id, () => {
    return new Promise((r, e) => {
      if (data.length == 0 || data.some((item) => {
        return (!item.title) || (!item.selectUrl);
      })) {
        message.error('有文字链接没有填写完整');
      } else {
        r(data);
      }
    });
  });

  /** 添加一个空链接 */
  const addLink = () => {
    data.push({
      title: "",
    });
    TopicDataAction.setData(...actionArg, data);
  };

  /** 编辑一个列表元素 */
  const edit = (i: number, title?: string, selectUrl?: ISelectUrl) => {
    typeof title != 'undefined' && (data[i].title = title);
    typeof selectUrl != 'undefined' && (data[i].selectUrl = selectUrl);
    //
    TopicDataAction.setData(...actionArg, data);
  };

  /** 删除一个列表元素 */
  const remove = (i) => {
    if (data.length == 1) {
      return;
    }
    data.splice(i, 1);
    TopicDataAction.setData(...actionArg, data);
  };

  if (props.con == 'show') {
    return (
      <div className={styles.textlink}>
        {data.map((item, index) => {
          return (
            <div key={index} className={styles.item}>
              <span>{item.title || "请添加导航链接"}</span>
              <i></i>
            </div>
          );
        })}
      </div>
    );
  } else {
    return (
      <>
        <div className={styles.textlink_ctrl}>
          <h2>文字链接设置</h2>
          <ul className={styles.list}>
            <DrageList list={data} onDragEnd={(_list) => {
              TopicDataAction.setData(...actionArg, _list);
            }}>
              {(item, index) => {
                return (
                  <li key={index} className={styles.item}>
                    <div className={styles.from_item}>
                      <span>链接到：</span>
                      <SelectUrl
                        select={item.selectUrl}
                        selectedBack={(select: ISelectUrl) => {
                          edit(index, undefined, select);
                        }}
                      />
                    </div>
                    <div className={styles.from_item}>
                      <span>导航名称：</span>
                      <Input
                        placeholder="导航名称"
                        style={{ width: "200px" }}
                        value={item.title}
                        onChange={(e) => {
                          edit(index, e.target.value);
                        }}
                      />
                    </div>
                    <div className={styles.actions}>
                      <i
                        onClick={() => {
                          remove(index);
                        }}
                      ></i>
                    </div>
                  </li>
                );
              }}
            </DrageList>
          </ul>
          <button onClick={addLink}>+</button>
        </div>
      </>
    );
  }
}
