import { useContext, useEffect, useState } from "react"
import { ButtonPanel } from "../../../../component/button-panel/button_panel"
import { Button } from "../../../../component/button"
import { useBoolean } from "ahooks"
import { DataTable } from "../../../../component/table"
import { ColumnsType } from "antd/lib/table"
import { Progress, defualtPageSize } from "../../../../logic/common_logic"
import { autoRem } from "../../../../global.style"
import { DataTableStore } from "../../../../component/table/store/data_table_store";
import { ConfirmModal } from "../../../../component/modal"
import {fileAction } from '../../action/taxCodeInfo/file_action'
import { formAction } from "../../action/taxCodeInfo/form_action"
import { FileStore } from "../../store/taxCodeInfo/file_store"
import { FormStoreProvider } from "../../store/taxCodeInfo/form_store"
import { Form } from "./form"
import { Control, Del, Edit, See } from "../../../../component/control-column"
import { AuthLogic } from "../../../../logic/auth_logic"
import { Input } from "antd"
import { Search } from "../../../../component/search"
const formParams = {
    id: '',
    method:''
}
export function TaxCodeInfo(viewPower){
    const [menuButton, setData] = useState([])
    const [createState, createFunc] = useBoolean(false)
    const {state: tableState, dispatch: tableDispatch} = useContext(DataTableStore)
    const {state, dispatch } = useContext(FileStore)
    const [confirmState, confirmFunc] = useBoolean(false)
    const action = fileAction
    useEffect(() => {
      dispatch({ type: "isloading", payload: false });
      (async function () {
        let r = await new AuthLogic().selButton(viewPower.viewPower);
        if (r && r.length > 0) {
          for (let i = 0; i < r.length; i++) {
            r[i] = r[i].split("_")[r[i].split("_").length - 1];
          }
        }
        setData(r);
      })();
      return () => {
        dispatch({ type: "isloading", payload: false });
      };
    }, []);
    const columns: ColumnsType = [
      {
        title: "操作",
        key: "1",
        align: "center",
        width: Control.width(3),
        render: Control.render(
          (v, r) =>
            menuButton.indexOf("VIEW") != -1 && (
              <See
                click={() => {
                  formParams.id = r.id;
                  formParams.method = "Preview";  
                  createFunc.setTrue();
                }}
              />
            ),
          (v, r) =>
            menuButton.indexOf("UPDATE") != -1 && (
              <Edit
                click={() => {
                  formParams.id = r.id;
                  formParams.method = "Edit";  
                  createFunc.setTrue();
                }}
              />
            ),
          (v, r) =>
            menuButton.indexOf("DELETE") != -1 && (
              <Del
                click={() => {
                  formParams.id = r.id;  
                  confirmFunc.setTrue();
                }}
              />
            )
        ),
      },
      {
        title:'税收编码名称',
        dataIndex: 'codeName',
        align: 'center',
        key: '2'
      },
      {
        title:'税收编码',
        dataIndex: 'taxCode',
        align: 'center',
        key: '3'
      },
      {
        title:'税率',
        dataIndex: 'rate(%)',
        align: 'center',
        key: '4'
      },
      {
        title:'创建时间',
        dataIndex: 'addTime',
        align: 'center',
        key: '5'
      },
      {
        title:'创建人',
        dataIndex: 'addUserName',
        align: 'center',
        key: '6'
      },
      {
        title:'备注',
        dataIndex: 'remark',
        align: 'center',
        key: '7'
      },
    ]
    const  onSearch = async (pageIndex) => {
        await action.loadPage({
            pageIndex: pageIndex,
            pageSize: defualtPageSize(),
            dispatch: tableDispatch,
            search: state.search,
        })
    }
    return(
        <>
         <Search>
          <Input
            maxLength={20}
            allowClear
            placeholder="请输入税收编码、名称"
            onChange={(e) =>
              action.setCondition({dispatch, key: 'seacherKey', value: e.target.value})
            }
          />
          <input type="button" value="快速查找" onClick={() => onSearch(1)} />
        </Search>
         <ButtonPanel>
        {menuButton.indexOf("ADD") != -1 && (
          <Button
            onClick={() => {
              formParams.id = "";
              formParams.method = "Create";
              createFunc.setTrue();
            }}
          >
            新建税收配置
          </Button>
        )}
      </ButtonPanel>
      <DataTable
        columns={columns}
        action={action}
        onLoad={async () => {
          await onSearch(1);
          Progress.done();
        }}
        onChange={(pageIndex) => {
          onSearch(pageIndex);
        }}
        scroll={{
          scrollToFirstRowOnChange: true,
          autoY: true,
          y: autoRem(53),
        }}
        loading={tableState.loading}
        selectColumn={false}
      />
      
      {createState && (
        <FormStoreProvider>
          <Form
            close={(hasUpdate) => {
              if (hasUpdate) {
                onSearch(tableState.current);
              }

              createFunc.setFalse();
            }}
            action={formAction}
            id={formParams.id}
            method={formParams.method}
          />
        </FormStoreProvider>
      )}
      <ConfirmModal
        visible={confirmState}
        title="温馨提示"
        subTitle="确认删除？"
        message="删除之后信息无法恢复，请慎重考虑"
        close={async (r) => {
          if (r) {
            await action.delRow(formParams.id);

            onSearch(tableState.current);
          }
          confirmFunc.setFalse();
        }}
      />
        </>
    )
}