import { Button, DatePicker, Form, Input, Select } from "antd";
import styles from "../../style/file_search.module.scss";
import { fileAction } from "../../action/afterSale/file_action";
import { useContext } from "react";
import { AfterSalesStore } from "../../store/afterSale/file_store";

export function FileSearch(onSearch){
    const action = fileAction
    const { dispatch, state } = useContext(AfterSalesStore)
    const { RangePicker } = DatePicker
    return (
        <div>
        <Form layout="horizontal">
            <div className={styles.row}>
                <div className={styles.cell}>
                    <Form.Item label="选择状态">
                        <Select style={{ width: 175 }}
                            onChange={(v) => action.setCondition({ dispatch, key: "statusId", value: v })}
                            defaultValue={-1}
                            showSearch
                        >
                           
                        </Select>
                    </Form.Item>
                </div>
                <div className={styles.cell}>
                    <Form.Item label="制单时间">
                        <RangePicker style={{ width: 432 }} onChange={e => {
                            action.setCondition({ dispatch, key: "startDate", value: e ? e[0].format("yyyy-MM-DD") : '' });
                            action.setCondition({ dispatch, key: "endDate", value: e ? e[1].format("yyyy-MM-DD") : '' });
                        }} />
                    </Form.Item>
                </div>
            </div>
            <div className={styles.row}>
                <Button type="primary" danger onClick={() => onSearch(1)}>搜  索</Button>
            </div>
        </Form>

        {/* <ChooseShop visibleModal={shopState} close={(status, r) => {
            if (r) {
                action.setCondition({ dispatch, key: "shop", value: r });
            }
            shopFunc.setFalse()
        }} /> */}
        {/* <ChooseGoods visibleModal={goodsState} close={(status, r) => {
            if (r) {
                action.setCondition({ dispatch, key: "goods", value: r });
            }
            goodsFunc.setFalse()
        }} /> */}
    </div>
    )
}