import { useBoolean, useRequest } from "ahooks";
import React, { useContext, useRef,useState,useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ConfirmModal } from "../../../component/modal";
import { Scroll } from "../../../component/scroll";
import { Tools } from "../../../config";
import { Progress } from "../../../logic/common_logic";
import { UnitBaseAction } from "../action/unit_action";
import { UnitStore } from "../store/unit_store";
import styles from "../style/classify_command.module.scss";
import addIcon from "../../../assets/images/goods/ico_camera.png";
import { message } from "antd";
import yincang from "../../../assets/images/icon/xianshi.png";
import xianshi from "../../../assets/images/icon/xianshi.png";
import { AuthLogic } from "../../../logic/auth_logic";
const params = {
  rowindex: 0,
  cellindex: 0,
};

export function Unit({
  action,
  enableAddRow,
  ifIcon,
  viewPower
}: {
  action: UnitBaseAction;
  enableAddRow?: boolean;
  ifIcon?: boolean;
  viewPower?:string
}) {
  const [confirmState, confirmFunc] = useBoolean(false);
  const { state: stateArgs, dispatch } = useContext(UnitStore);
  const [menuButton, setData] = useState([]);
  useEffect(() => {
    (async function () {
      let r = await new AuthLogic().selButton(viewPower);
      if (r && r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          r[i] = r[i].split("_")[r[i].split("_").length - 1];
        }
      }
      console.log(r)
      setData(r);
    })();

    return () => {};
  }, []);
  const his = useHistory();
  useRequest(async () => {
    await action.fetchListAction({ state: stateArgs, dispatch });
    Progress.done();
  });

  return (
    <div className={styles.root}>
      {enableAddRow && (
        <>
          <div className={styles.command}>
            <span
              className={styles.item}
              onClick={() => action.addRow({ dispatch })}
            >
              增加大分类
            </span>
          </div>
          <hr />
        </>
      )}
      <Scroll dif={70}>
        {stateArgs.dataList.map((v, rowindex) =>
          v.rows.length ? (
            <>
              <div key={rowindex} className={styles.list}>
                {v.rows.map(
                  ({ state, value, iconPath, isHidden }, cellindex) => {
                    if ("Text" == state) {
                      return (
                        <div key={rowindex+cellindex}
                          className={styles.item}
                          onMouseOver={() =>
                            action.toggoleTipsAction({
                              rowindex,
                              cellindex,
                              dispatch,
                            })
                          }
                        >
                          <div className={styles.name}>
                            {ifIcon && iconPath && (
                              <>
                                <img
                                  src={Tools.getImageAddress(iconPath)}
                                  alt=""
                                />
                              </>
                            )}
                            <span
                              className={
                                enableAddRow && !cellindex && styles.first
                              }
                            >
                              {value}
                            </span>
                          </div>
                        </div>
                      );
                    } else if ("Add" == state && menuButton.indexOf('ADD')!==-1) {
                      return (
                        <div className={styles.item}>
                          <div className={styles.ico}>
                            <span
                              className={
                                enableAddRow && !cellindex && styles.first
                              }
                              onClick={() =>
                                action.addCallAction({ rowindex, dispatch })
                              }
                            ></span>
                          </div>
                        </div>
                      );
                    } else if ("Input" == state) {
                      return (
                        <div className={styles.item}>
                          <div className={styles.input_group}>
                            <GoodsClassIconSelect
                              ifIcon={ifIcon}
                              rowindex={rowindex}
                              cellindex={cellindex}
                              action={action}
                            />
                            <input
                              maxLength={15}
                              type="text"
                              defaultValue={value}
                              onChange={(e) =>
                                action.inputTextAction({
                                  rowindex,
                                  cellindex,
                                  dispatch,
                                  value: e.target.value,
                                })
                              }
                            />
                            <span
                              onClick={() =>
                                action.saveCellAction({
                                  rowindex,
                                  cellindex,
                                  dispatch,
                                  state: stateArgs,
                                  his,
                                })
                              }
                            ></span>
                            <span
                              onClick={() =>
                                action.cancelCallAction({
                                  rowindex,
                                  cellindex,
                                  dispatch,
                                })
                              }
                            ></span>
                          </div>
                        </div>
                      );
                    } else if ("Tips" == state) {
                      return (
                        <div
                          style={{ position: "relative" }}
                          className={styles.item}
                          onMouseOver={() =>
                            action.toggoleTipsAction({
                              rowindex,
                              cellindex,
                              dispatch,
                            })
                          }
                        >
                          <div className={styles.name}>
                            {ifIcon && iconPath && (
                              <>
                                <img
                                  src={Tools.getImageAddress(iconPath)}
                                  alt=""
                                />
                              </>
                            )}
                            <span
                              className={
                                enableAddRow && !cellindex && styles.first
                              }
                            >
                              {value}
                            </span>
                          </div>

                          <div
                            style={{
                              position: "absolute",
                              right: -40,
                              top: 0,
                              width: 400,
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-end",
                              alignContent: "center",
                            }}
                          >
                            <div
                              className={styles.item}
                              onMouseLeave={() =>
                                action.toggoleTextAction({
                                  rowindex,
                                  cellindex,
                                  dispatch,
                                })
                              }
                            >
                              <div className={styles.name_group}>
                                <span style={{ color: "transparent" }}>
                                  {value}
                                </span>
                                <span className={styles.point}>
                                  <div className={styles.name_expand}>
                                   {menuButton.indexOf('UPDATE')!==-1 && <span
                                      onClick={() =>
                                        action.editAction({
                                          rowindex,
                                          cellindex,
                                          dispatch,
                                        })
                                      }
                                    ></span>}
                                    {enableAddRow && !isHidden && (
                                      <span
                                        className={styles.yincang}
                                        onClick={() =>
                                          action.isHidden({
                                            stateArgs,
                                            isHidden,
                                            rowindex,
                                            cellindex,
                                            dispatch,
                                          })
                                        }
                                      ></span>
                                    )}
                                    {enableAddRow && isHidden && (
                                      <span
                                        className={styles.xianshi}
                                        onClick={() =>
                                          action.isHidden({
                                            stateArgs,
                                            isHidden,
                                            rowindex,
                                            cellindex,
                                            dispatch,
                                          })
                                        }
                                      ></span>
                                    )}
                                   {menuButton.indexOf('DELETE')!==-1 &&  <span
                                      onClick={() => {
                                        params.cellindex = cellindex;
                                        params.rowindex = rowindex;
                                        confirmFunc.setTrue();
                                      }}
                                    ></span>}
                                  </div>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  }
                )}
              </div>
              <hr />
            </>
          ) : null
        )}
      </Scroll>

      <ConfirmModal
        visible={confirmState}
        title="温馨提示"
        subTitle="确认删除？"
        message="删除之后信息无法恢复，请慎重考虑"
        close={async (r) => {
          if (r) {
            const { rowindex, cellindex } = params;
            action.deleteAction({
              rowindex,
              cellindex,
              dispatch,
              state: stateArgs,
            });
          }
          confirmFunc.setFalse();
        }}
      />
    </div>
  );
}

/**
 * 商品图标选择
 */
function GoodsClassIconSelect({
  ifIcon,
  rowindex,
  cellindex,
  action,
}: {
  ifIcon: boolean;
  rowindex: number;
  cellindex: number;
  action: UnitBaseAction;
}) {
  const { state: stateArgs, dispatch } = useContext(UnitStore);
  const inputRef = useRef(null);

  /** 选择图片 */
  const selectImage = () => {
    inputRef.current.click();
  };
  /** 获取图片 */
  const inputChange = () => {
    let _file: File = inputRef.current.files[0];
    if (!_file) {
      return;
    }
    //上传图片并设置到状态管理中
    action.uploadImgGetUrl(_file).then((_icon) => {
      message.success("上传成功");
      action.inputIcon({
        rowindex,
        cellindex,
        iconPath: _icon,
        dispatch: dispatch,
      });
    });
  };

  let iconPath: string =
    stateArgs.dataList[rowindex].rows[cellindex].getOnIconPath;

  if (iconPath) {
    iconPath = Tools.getImageAddress(iconPath);
  } else {
    iconPath = addIcon;
  }

  return (
    <>
      <input
        ref={inputRef}
        onChange={inputChange}
        type="file"
        accept="image/*"
        style={{ display: "none" }}
      />
      {ifIcon && <img src={iconPath} alt="" onClick={selectImage} />}
    </>
  );
}
