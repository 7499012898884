import React, { useContext, useEffect, useState } from "react";
import { Button, Cascader, Checkbox, Col, Form, Input, Row, Select } from "antd";
import styles from "../../style/file_search.module.scss";
import { useRequest,useBoolean } from "ahooks";
import { Select as SelectAntd, DatePicker, Space } from "antd";
import { fileAction } from "../../action/flowChargeoff/file_action";
import { FileStore } from "../../store/flowChargeoff/file_store";
import moment from 'moment';
import Cookies from 'js-cookie'
import { ChooseShop} from '../../../../component/choose-shop'
import { fetchPeopleList } from "../../../order/view/file_search";

const { RangePicker } = DatePicker;
const { Option } = Select;

let globalPeopleList = [];

export function FileSearch({ onSearch }) {
    const {state,dispatch} = useContext(FileStore)
    const action = fileAction;
const [shopState,shopFunc] = useBoolean(false)
    const [peopleList, setPeopleList] = useState([]);
    useEffect(() => {
        fetchPeopleList().then((l) => {
            globalPeopleList = l;
            setPeopleList(l);
        });

        return () => {

        }
    }, []);

    return (
        <div>
            <Form layout="horizontal">
                {/* <div className={styles.row}>
                    <div className={styles.cell}>
                        <Form.Item label="储值单号">
                            <Input autoComplete="off" style={{ width: "15rem" }} placeholder="请输入储值单号" onChange={(v) => action.setCondition("StoredNumber", v.target.value, dispatch)} />
                        </Form.Item>
                    </div>
                    <div className={styles.cell}>
                        <Form.Item label="操作用户">
                            <Select showSearch style={{ width: "15rem" }} onChange={(v) => action.setCondition("OperationName", v, dispatch)}
                                defaultValue={-1}
                                filterOption={(input:any, option:any) => {
                                    return option.children.indexOf(input) >= 0;
                                }
                                }
                            >
                                <Option value={-1}>全部</Option>
                                {
                                    peopleList.map(v => <Option value={v.userID}>{v.userName}</Option>)
                                }
                            </Select>
                        </Form.Item>
                            </div>*/}
                    { (<div className={styles.cell}>
                        <Form.Item label="选择门店">
                            <Input style={{ width: 175 }} value={state.search.shopName} placeholder="请选择" onClick={() => shopFunc.setTrue()} />
                            <span className="ant-input-suffix" style={{ marginLeft: '-24px', zIndex: 99 }} onClick={()=>{
                                    action.setCondition('shopName','',dispatch)           
                                    action.setCondition('shopId' ,'',dispatch);
                            }}>
                                <span role="button" aria-label="close-circle" className="anticon anticon-close-circle ant-input-clear-icon"><svg viewBox="64 64 896 896" focusable="false" data-icon="close-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 01-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"></path></svg></span>
                            </span>
                        </Form.Item>
                    </div>)}
                  {/*  <div className={styles.cell}>
                        <Form.Item label="储值时间">
                            <Space style={{ width: "36.5rem" }} direction="vertical" size={12} >
                                <RangePicker onChange={function (date, dateString) {
                                    action.setCondition("StartDateTime", dateString[0], dispatch)
                                    action.setCondition("EndDateTime", dateString[1], dispatch)

                                }} />
                            </Space>
                        </Form.Item>
                    </div>
                </div>*/}
                <div className={styles.row}>
                    <Button type="primary" danger onClick={() => onSearch(1)}>搜  索</Button>
                </div> 
            </Form>

            <ChooseShop
        visibleModal={shopState}
        close={(status, r) => {
          if (r) {
              action.setCondition('shopName',r[0].shopName.toString(),dispatch)           
            action.setCondition('shopId' ,r[0].id,dispatch);
          }
          shopFunc.setFalse();
        }}
      />
        </div>
    )
}