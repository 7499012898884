export class FormAction{
    async init({ state, dispatch, id, form }){
        await this.initBasic({ dispatch, state, form });
        if(id){
            await this.initEdit({dispatch, id, form, state})
        }
    }
    async initBasic({dispatch, state, form}){}
    async initEdit({dispatch, state, form,id}){}
    async addSubmit({state}){
        return false
    }
    async updateSubmit({state}){
        return false
    }
    async reset({dispatch, form}){}
}
export const formAction = new FormAction()