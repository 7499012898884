import { Cascader, Col, Form, Form as FormAntd, Input, Radio, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useContext, useEffect, useState } from "react";
import { formAction } from "../../action/dossier/form_action";
import { FormStore } from "../../store/dossier/form_store";
import { Select } from "antd";
import { DatePicker } from "antd";
import { useBoolean, useRequest } from "ahooks";
import moment from "moment";
import { RadioStyle } from "../../../../component/radio-style";
import { autoRem } from "../../../../global.style";
import { ChooseMember } from '../../../../component/choose-member'
const { Option } = Select;
export function FormBasic({ title, disabled, form, updateFormField }) {
  const [memberState, memberFunc] = useBoolean(false)
  const [isRead, readFunc] = useBoolean(true);
  const { state, dispatch: formDispatch } = useContext(FormStore);
  useEffect(() => {
    readFunc.setTrue();
    return () => {};
  }, []);
  useRequest(() =>
    formAction.initBasic({ state, dispatch: formDispatch, form })
  );
  const action = formAction;
  const { formData } = state;
  var isLook = false;
  if (title == "查看档案") {
    isLook = true;
  } else {
    isLook = false;
  }
  function ShopType({ value, swtichValue }) {
    const [] = useState(1);
    const { dispatch } = useContext(FormStore);

    const onChange = (e) => {
      if (!disabled) {
        action.setValue({
          dispatch,
          value: e.target.value,
          attribute: "Gender",
        });
      }
    };
    return (
      <RadioStyle>
        <Radio.Group onChange={onChange} value={swtichValue}>
          <Radio value={1} style={{ marginLeft: "15px" }}>
            男
          </Radio>
          <Radio value={2} style={{ marginLeft: "10px" }}>
            女
          </Radio>
        </Radio.Group>
      </RadioStyle>
    );
  }
  // console.log(formData)
  const selectTag = (value, option) => {
    let arr = [];
    for (let i = 0; i < option.length; i++) {
      arr.push(option[i].value);
    }
    // //console.log(option);
    action.setValue({ dispatch: formDispatch, value: arr, attribute: "Label" });
  };
  return (
    <>
      <div style={{ margin: "1.9375rem 24px 0 0" }}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="member-card"
              label="会员卡号"
              labelCol={{ span: 8 }}
              rules={[
                {
                  //   required: true,
                  //   message: "请输入会员卡号",
                },
                {
                  pattern: /.*[^ ].*/,
                  message: "会员卡号不能为空字符",
                },
                {},
              ]}
            >
              <Input
                // allowClear
                maxLength={20}
                disabled={isLook || title == "编辑档案"}
                autoComplete="off"
                placeholder="请输入会员卡号"
                onChange={(v) =>
                  action.setValue({
                    dispatch: formDispatch,
                    value: v.target.value,
                    attribute: "Card",
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="member-name"
              label="会员姓名"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: true,
                  message: "请输入会员姓名",
                },
              ]}
            >
              <Input
                allowClear
                autoComplete="off"
                disabled={isLook}
                type="text"
                maxLength={5}
                placeholder="请输入会员姓名"
                onChange={(v) => {
                  action.setValue({
                    dispatch: formDispatch,
                    value: v.target.value,
                    attribute: "MemberName",
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="member-phone"
              label="手机号码"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: true,
                  message: "请输入手机号码",
                },
                {
                  pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/,
                  message: "手机号码格式不正确",
                },
              ]}
            >
              <Input
                allowClear
                autoComplete="off"
                maxLength={12}
                disabled={isLook}
                placeholder="请输入手机号码"
                onChange={(v) => {
                  action.setValue({
                    dispatch: formDispatch,
                    value: v.target.value,
                    attribute: "Phone",
                  });
                  action.setValue({
                    dispatch: formDispatch,
                    value: v.target.value,
                    attribute: "MemberCard",
                  });
                  state.formData.card == "" &&
                    form.setFieldsValue({ "member-card": v.target.value });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="member-gender"
              label="会员性别"
              labelCol={{ span: 8 }}
              className={"custom-radio"}
            >
              <ShopType
                value={1}
                swtichValue={formData.gender ? formData.gender : 1}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="出生日期"
              name="member-birthday"
              labelCol={{ span: 8 }}
            >
              {formData.birthday != undefined && formData.birthday != "" && (
                <DatePicker
                  disabled={isLook}
                  placeholder="请选择出生日期"
                  defaultValue={
                    formData.birthday
                      ? moment(
                          formData.birthday.slice(0, 11).replace(/-/g, "/"),
                          "YYYY-MM-DD"
                        )
                      : undefined
                  }
                  format={"YYYY-MM-DD"}
                  onChange={function (date, dateString) {
                    action.setValue({
                      dispatch: formDispatch,
                      value: dateString,
                      attribute: "Birthday",
                    });
                  }}
                />
              )}
              {(formData.birthday == undefined || formData.birthday == "") && (
                <DatePicker
                  disabled={isLook}
                  placeholder="请选择出生日期"
                  format={"YYYY-MM-DD"}
                  onChange={function (date, dateString) {
                    action.setValue({
                      dispatch: formDispatch,
                      value: dateString,
                      attribute: "Birthday",
                    });
                  }}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="会员标签"
              name="member-label"
              labelCol={{ span: 8 }}
            >
              <Select
                disabled={isLook}
                mode="multiple"
                allowClear
                placeholder="请选择会员标签"
                onChange={function (v, option) {
                  selectTag(v, option);
                }}
                style={{ maxWidth: 218 }}
              >
                {state.TagList.length > 0
                  ? state.TagList.map((v) => (
                      <Option value={v.id}>{v.labelName}</Option>
                    ))
                  : ""}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="member-membeClassID"
              label="会员等级"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: true,
                  message: "请选择会员等级",
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                disabled={isLook}
                optionFilterProp="children"
                placeholder="请选择会员等级"
                filterOption={(input:any, option:any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={(v) =>
                  action.setValue({
                    dispatch: formDispatch,
                    value: v,
                    attribute: "MembeClassID",
                  })
                }
              >
                {state.gradeList.length > 0
                  ? state.gradeList.map((v) => (
                      <Option value={v.id} key={v.id}>
                        {v.gradeName}
                      </Option>
                    ))
                  : ""}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="member-memberPwd"
              label="会员密码"
              labelCol={{ span: 8 }}
            >
              <Input.Password
                autoComplete="off"
                disabled={isLook}
                placeholder="123456"
                defaultValue="123456"
                maxLength={20}
                readOnly={isRead}
                onFocus={() => {
                  readFunc.setFalse();
                }}
                onChange={(e) => {
                  action.setValue({
                    dispatch: formDispatch,
                    value: e.target.value,
                    attribute: "MemberPwd",
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="member-status"
              label="会员状态"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: true,
                  message: "请选择会员状态",
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                disabled={isLook}
                optionFilterProp="children"
                placeholder="请选择会员状态"
                onChange={(v) =>
                  action.setValue({
                    dispatch: formDispatch,
                    value: v,
                    attribute: "Status",
                  })
                }
              >
                <Option value="1">挂失</Option>
                <Option value="2">停用</Option>
                <Option value="3">正常</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="member-superiorPhone"
              label="上级会员"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: false,
                  message: "请选择上级会员",
                },
              ]}
            >
               {formData.superiorMemberPhone ? "" : ""}
             <Input   disabled={isLook}  value={formData.superiorMemberPhone}
                autoComplete="off" onClick={()=>memberFunc.setTrue()}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item labelCol={{ span: 4 }} name="member-remark" label="备注">
              <TextArea
                allowClear
                autoComplete="off"
                maxLength={200}
                disabled={isLook}
                rows={2}
                onChange={(v) =>
                  action.setValue({
                    dispatch: formDispatch,
                    value: v.target.value,
                    attribute: "Remark",
                  })
                }
              ></TextArea>
            </Form.Item>
          </Col>
        </Row>
        <ChooseMember
        type="radio"
        visibleModal={memberState}
        close={(status, r) => {
          if (r) {
            if(r.length > 0) {
              action.setValue({
                dispatch: formDispatch,
                value: r[0].id,
                attribute: "SuperiorMemberId",
              })
              action.setValue({
                dispatch: formDispatch,
                value: r[0].phone,
                attribute: "SuperiorMemberPhone",
              })
            }          
          }
          updateFormField("member-superiorPhone");
          memberFunc.setFalse();
        }}
      />
      </div>
    </>
  );
}
