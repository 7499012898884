import { useState } from 'react';
import { useEffect } from 'react';
import styles from "./style/selection.module.scss";

/**
 * 地址
 */
interface IAddress {
    /** 省 */
    provincialCapital: string,
    /** 市 */
    city: string;
    /** 地区 */
    district: string;
    /** 详细地址 */
    detailedAddress: string;
    /** 经度 */
    longitude: number;
    /** 纬度 */
    latitude: number;
}

/**
 * 选址
 */
export function Selection(props: {
    /** 是否禁用 */
    disabled?: boolean;
    /** 地址详情 */
    address?: IAddress;
    /** 设置位置 */
    setAddress?: (address: IAddress) => void;
    //
    [index: string]: any,
}) {
    const [formattedAddress, setFormattedAddress] = useState(' ');
    const [init, setInit] = useState(false);
    const [canvasName] = useState(Date.now() + 'canvas');
    const [inputName] = useState(Date.now() + 'input');

    useEffect(() => {
        if (init) { return; }
        setInit(true);
        // 加载PositionPicker，loadUI的路径参数为模块名中 'ui/' 之后的部分
        window['AMapUI'].loadUI(['misc/PositionPicker'], function (PositionPicker) {
            var map = new window['AMap'].Map(canvasName, {
                zoom: 16,
            })

            // 如果有中心点就默认设置一下中心点
            if (props.address && props.address.longitude && props.address.latitude) {
                var position = new window['AMap'].LngLat(props.address.longitude, props.address.latitude);  // 标准写法
                map.setCenter(position);
            }

            //添加定位插件
            window['AMap'].plugin('AMap.Geolocation', function () {
                let geolocation = new window['AMap'].Geolocation();
                map.addControl(geolocation);
            });

            //添加搜索插件
            window['AMap'].plugin('AMap.Autocomplete', function () {
                var autoOptions = {
                    // input 为绑定输入提示功能的input的DOM ID
                    input: inputName
                }
                var autoComplete = new window['AMap'].Autocomplete(autoOptions);
                autoComplete.on('select', (data) => {
                    //设置当前中心点
                    map.setCenter(data.poi.location);
                });
                map.addControl(autoComplete);
            })

            //添加拖拽插件
            var positionPicker = new PositionPicker({
                mode: 'dragMap',//设定为拖拽地图模式，可选'dragMap'、'dragMarker'，默认为'dragMap'
                map: map//依赖地图对象
            });
            // 添加中心点改变事件
            positionPicker.on('success', (e) => {
                setFormattedAddress(e.address);
                //调用回调
                props.setAddress && props.setAddress({
                    provincialCapital: e.regeocode.addressComponent.province,
                    city: e.regeocode.addressComponent.city,
                    district: e.regeocode.addressComponent.district,
                    detailedAddress: e.address,
                    longitude: e.position.getLng(),
                    latitude: e.position.getLat(),
                });
            });
            //开始拖拽
            positionPicker.start();
        });
    });

    return <div {...props}>
        <div className={styles.root} >
            {props.disabled && <div className={styles.disabled}></div>}
            <span className={styles.address}>{formattedAddress}</span>
            <div className={styles.input}>
                <input placeholder="请输入关键字" type="text" id={inputName} />
            </div>
            <div id={canvasName} className={styles.container}></div>
        </div>
    </div>
}