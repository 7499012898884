import { message } from "antd";
import { DictLogic } from "../../../../logic/dict_logic";
import { FormAttribute } from "../../store/dict/form_store";

type Init = {
    state;
    dispatch;
    form;
    editId: string;
    seeId: string;
}
interface InitBasic {
    dispatch;
    state;
}
interface InitEdit {
    state;
    dispatch;
    form;
    id;
}
interface ISetValue {
    attribute: FormAttribute;
    value: string | number | any[];
    dispatch;
}
var id = ''
export class FormAction {

    private dictLgc = new DictLogic();

    async init({ state, dispatch, seeId, editId, form }: Init) {
        await this.initBasic({ dispatch, state });
        if (editId || seeId) {
            await this.initEdit({ dispatch, state, id: editId || seeId, form })
        }
        id = editId || seeId
    }
    private async initBasic({ }: InitBasic) {

    }
    private async initEdit({ dispatch, id, form }: InitEdit) {
        // 回填状态
        const item = await this.dictLgc.selData(id);

        // selectClasses
        dispatch({ type: "SetForm", payload: item });

        // 填充节点
        form.setFieldsValue({
            "dict-option": item.option,
            "dict-sortIndex": item.sortIndex,
            "dict-worth": item.value,
            "dict-label": item.label,
            "remark": item.remark,
        });
    }
    reset({ dispatch, form }) {

        // selectClasses
        dispatch({
            type: "SetForm", payload: {
                gradeName: "",
                remark: "",
                option: "",
                label: "",
                value: "",
                sortIndex: "",
            }
        });

        // 填充节点
        form.setFieldsValue({
            "dict-option": "",
            "dict-sortIndex": "",
            "dict-worth": "",
            "dict-label": "",
            "remark": "",
        });
    }
    setValue({ attribute, value, dispatch }: ISetValue) {
        dispatch({ type: "Change_FormData", payload: { attribute, value } });
    }
    private paddingData(state) {
        let params: any = {};
        {
            const { id, option, sortIndex, label, value, remark } = state;
            params = {
                id,
                option,
                sortIndex,
                value,
                label,
                remark
            }
        }
        return params;
    }
    async addSubmit({ state }) {
        const r = await this.dictLgc.addData(this.paddingData(state));
        if (r.data.statusCode == 200) {
            message.success("保存成功");
        } else {
            message.error(r.data.message);
        }
        return r.data.statusCode == 200;
    }
    // 更新字典
    async updSubmit({ state }) {
        state['id'] = id
        const r = await this.dictLgc.updData(this.paddingData(state));
        if (r.data.statusCode == 200) {
            message.success("更新成功");
        } else {
            message.error(r.data.message);
        }
        return r.data.statusCode == 200;
    }
}
export const formAction = new FormAction();