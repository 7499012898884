// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../config";
import { effeative, maxPageSize } from "./common_logic";

export class StoreLogic {
    async selData(id: string) {
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("menber_rule_look"),
            params: {
                id
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r.data.data;
    }
    async querysList() {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("menber_rule_page"),
            data: {
                ruleName: '',
                grantType: '',
                pageIndex: 1,
                pageSize: maxPageSize(),
                orderBy: '',
                sort: ''
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    async addData(params) {

        return effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("menber_rule_add"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))

    }
    async updData(params) {
        return effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("menber_rule_update"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
    }

}