import { autoRem } from "../../global.style";

interface ITableExpand {
    children?
}
export function TableExpand({ children }: ITableExpand) {
    return (
        <p style={{ padding: "10px 0", textAlign: "right", marginTop: "10px", color: "#999999", fontSize: 12 }}>
            {children}
        </p>
    )
}