import { BaseApiCon } from "./BaseApiCon";
/**
 * 微信第三方api控制器
 */
export class WxSystemApiCon extends BaseApiCon {
    /** 构建PC端授权链接 */
    static componentloginpage() {
        let SysUID: string = this.loginMsg.userID;
        window.open(`${this.byNameGetURl('WxSystem.componentloginpage')}?SysUID=${SysUID}`);
    }
    /** 获取最新版的小程序模板 */
    static GetNewtemplate(): Promise<any> {
        return this.request(this.byNameGetURl('WxSystem.GetNewtemplate'), 'GET');
    }
}