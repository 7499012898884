import message from "antd/lib/message"
// import axios from "axios"
import Cookies from "js-cookie"
import { DataTableAction } from "../../../../component/table/action/data_table_action"
import { Tools, axios } from "../../../../config"
import { PrecisionLogic } from "../../../../logic/precision_logic"

class PrecisionAction extends DataTableAction {

    async delRow(id) {
        const r = await new PrecisionLogic().delData(id);

        if (r.data.statusCode == 200) {
            message.success("更新成功")
        }
        else{
            message.error(r.data.returnMessage)
        }
    }
    delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {
        selectList.map(async ({ id }) => {
            const r = await axios({
                method: "GET",
                url: Tools.getApiAddress("shop_delete"),
                params: {
                    shopId: id
                },
                headers: {
                    SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
                },
            })

            if (r.data.statusCode == 200) {
                message.success("删除成功")
            }
            else{
                message.error(r.data.returnMessage)
            }
        })
    }
    async loadPage({ dispatch, pageIndex, pageSize, search }) {
        this.setLoading({dispatch,loading: true})
        const { seacherKey, goodsId,shopID,purposeType,activityManner } = search;
        const params: any = {
            "pageIndex": pageIndex,
            "pageSize": pageSize,
            // "purposeType":purposeType==undefined?3:purposeType,
            "seacherKey":seacherKey,
            // "shopID":shopID?shopID:'',
            // "activityManner":activityManner==undefined||activityManner==-1?'':activityManner,
            "orderBy":'',
            "sort":'',

        }
        // if(goodsId){
        //     if(goodsId.indexOf(',')){
        //         params.goodsID = goodsId.split(',')
        //     }else{
        //         params.goodsID.push(goodsId)
        //     }
        // }

        // 档案列表
        const r = await new PrecisionLogic().selListByRecord(params);

        if (r.data.statusCode == 200) {
            const dataList = r.data.data.dataSource;
            const list = dataList.map((v, i) => {
                v.key = i + 1;
                v.beginDate = v.beginDate!=null?v.beginDate.slice(0,10):''
                v.endDate = v.endDate!=null?v.endDate.slice(0,10):''
                v.beginTime = v.beginTime!=null?v.beginTime.slice(11,22):''
                v.endTime = v.endTime!=null?v.endTime.slice(11,22):''
                return v;
            })

            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: list, pageSize });
        }  this.setLoading({dispatch,loading: false})

    }
    setCondition({ dispatch, key, value }) {
        const params = {
        }
        params[key] = value;

        dispatch({ type: "SetSearch", payload: params })
    }
    async selList({ dispatch, pageIndex, pageSize, search, precisionId }){
        const { seacherKey} = search;
        const r = await new PrecisionLogic().selList({seacherKey,pageIndex,pageSize,id:precisionId})
        // this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: list, pageSize });
        //console.log(r)
    }
    async stopPrecision(id){
      const r =  await axios({
            method:'GET',
            url:Tools.getApiAddress('precision_update_status'),
            params:{
                ID:id,
                State:2
            },  headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })
        if(r.data.statusCode == 200){
            message.success('状态更新成功')
        }else{
            message.error(r.data.returnMessage)
        }

    }
}
export const precisionAction = new PrecisionAction();