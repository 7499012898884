import { message } from "antd";
import axios, { AxiosPromise } from "axios";
import Cookies from "js-cookie";
import { Tools } from "../../../config";
import { maxPageSize } from "../../../logic/common_logic";
import { ClassesLogic } from '../../../logic/classes_logic'


export abstract class UnitBaseAction {
    protected abstract addUnit({ unitName }): AxiosPromise<any>;
    protected abstract updateUnit({ id, unitName, status, sort, remark }): AxiosPromise<any>;
    protected abstract deleteUnit({ unitId }): AxiosPromise<any>;
    protected abstract getList(): AxiosPromise<any>;
    protected abstract callbackText(params): string;
    /** 保存验证 */
        protected saveCellActionV({ rowindex, cellindex, state }): boolean {
    const call = state.dataList[rowindex].rows[cellindex];
        //默认检测第一个大分类的子分类
        let rows = state.dataList[rowindex].rows;
        let length: number = rows.length;
        if (length > 1) {
            for (let i = 0; i < length; i++) {
                if (i == cellindex) { continue; }
                if (call.entranceValue == rows[i].value) {
                    message.warn('不能出现重名！');
                    return false;
                }
            }
        }
        return true;
    }

    async fetchListAction({ state, dispatch }) {
        const { data, status } = await this.getList();

        if (200 == status) {
            dispatch({ type: "INIT_LIST", payload: { dataList: [data.data.dataSource], callbackText: this.callbackText } })
        }

    }
    toggoleTipsAction({ rowindex, cellindex, dispatch }) {
        dispatch({ type: "TOGGOLE_TIPS", payload: { rowindex: rowindex, cellindex: cellindex } })
    }
    addCallAction({ dispatch, rowindex }) {
        dispatch({ type: "ADD_CELL", payload: { rowindex: rowindex } })
    }
    async saveCellAction({ rowindex, cellindex, dispatch, state, his }) {
        const firstData = state.dataList[rowindex].rows[0] ? state.dataList[rowindex].rows[0].rawData : {};
        const call = state.dataList[rowindex].rows[cellindex];
        const entranceValue = call.entranceValue;
        if (!entranceValue) {
            message.warn('名称不能为空！');
            return;
        }
          //console.log(state)

        if (!this.saveCellActionV({ rowindex, cellindex, state })) return;

        // 新增
        if (!call.rawData) {

            const { data, status } = await this.addUnit({ ...call, parentRawData: firstData });

            if (200 == status) {
                // 401 登录失效
                if (401 == data.statusCode) {
                    message.error("登录失效，请重新登录")
                    his.replace("/login")
                } else if (200 == data.statusCode) {
                    message.success("保存成功")
                    dispatch({ type: "SAVE_INPUT", payload: { rowindex: rowindex, cellindex: cellindex, rawData: data.data } })
                } else {
                    message.error(data.returnMessage)
                }
            }
        }
        // 更新
        else {
            const { data, status } = await this.updateUnit(call);

            if (200 == status) {
                // 401 登录失效
                if (401 == data.statusCode) {
                    message.error("登录失效，请重新登录")
                    his.replace("/login")
                } else if (200 == data.statusCode) {
                    message.success("更新成功")
                    dispatch({ type: "SAVE_INPUT", payload: { rowindex: rowindex, cellindex: cellindex, rawData: call.rawData } })
                } else {
                    message.error(data.returnMessage)
                }
            }
        }

    }
    cancelCallAction({ rowindex, cellindex, dispatch }) {
        dispatch({ type: "CANCEL_INPUT", payload: { rowindex: rowindex, cellindex: cellindex, } })
    }
    editAction({ rowindex, cellindex, dispatch }) {
        dispatch({ type: "TOGGOLE_EDIT", payload: { rowindex: rowindex, cellindex: cellindex, } })
    }
    async deleteAction({ rowindex, cellindex, dispatch, state }) {
        const call = state.dataList[rowindex].rows[cellindex];

        const { data, status } = await this.deleteUnit(call);

        if (200 == status) {
            if (200 == data.statusCode) {
                message.success("删除成功")
                dispatch({ type: "TOGGOLE_DELETE", payload: { rowindex: rowindex, cellindex: cellindex, } })
            } else {
                message.error(data.returnMessage)
            }
        }


    }
     async isHidden({stateArgs, isHidden, rowindex, cellindex, dispatch }) {
        let id = stateArgs.dataList[rowindex].rows[cellindex].rawData.id
        const r =  await new ClassesLogic().hiddenData(id, !isHidden)
        if(r.data.statusCode == 200){
            this.fetchListAction({state: stateArgs, dispatch})
        }
        
      }
    inputTextAction({ rowindex, cellindex, dispatch, value }) {
        dispatch({ type: "INPUT_TEXT", payload: { rowindex: rowindex, cellindex: cellindex, value: value } })
    }
    toggoleTextAction({ rowindex, cellindex, dispatch }) {
        dispatch({ type: "TOGGOLE_TEXT", payload: { rowindex: rowindex, cellindex: cellindex } })
    }
    addRow({ dispatch }) {
        dispatch({ type: "ADD_ROW", payload: {} })
    }

    /** 输入图标 */
    inputIcon({ rowindex, cellindex, iconPath, dispatch }) {
        dispatch({
            type: "INPUT_ICON", payload: {
                rowindex,
                cellindex,
                iconPath,
            }
        })
    }

    async delRowAction({ dispatch, rowindex, state }) {
        const call = state.dataList[rowindex].rows[0];

        const { data, status } = await this.deleteUnit(call);

        if (200 == status) {
            if (200 == data.statusCode) {
                message.success("删除成功")

                dispatch({ type: "DELETE_ROW_ACTION", payload: { rowindex: rowindex } })
            } else {
                message.error(data.returnMessage)
            }
        }
    }

    /**
     * 上传图片获取链接
     * @param file 文件
     */
    uploadImgGetUrl(file: File): Promise<string> {
        //发送请求
        const formData = new FormData();
        formData.append("file", file);

        return axios({
            method: "POST",
            url: Tools.getApiAddress('smallProgram.UploadImages'),
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;charset=UTF-8',
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }).then((r) => {
            if (r.data.statusCode == 200) {
                return r.data.data;
            } else {
                throw r.data.message;
            }
        });
    }
}

class UnitAction extends UnitBaseAction {
    protected callbackText(params: any): string {
        return params.unitName;
    }
    protected getList() {
        return axios({
            method: "POST",
            url: Tools.getApiAddress("goods_unit_select_page"),
            data: {
                unitName: "",
                status: 1,
                pageIndex: 1,
                pageSize: maxPageSize(),
                orderBy: "",
                sort: ""
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })
    }
    protected addUnit(params) {
        return axios({
            method: "POST",
            url: Tools.getApiAddress("goods_unit_add"),
            data: {
                unitName: params.entranceValue,
                status: 1,
                sort: 0,
                remark: ""
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })
    }
    protected updateUnit(params) {
        return axios({
            method: "POST",
            url: Tools.getApiAddress("goods_unit_update"),
            data: {
                id: params.rawData.id,
                unitName: params.entranceValue,
                status: params.rawData.status,
                sort: params.rawData.sort,
                remark: params.rawData.remark
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })
    }
    protected deleteUnit(params) {
        return axios({
            method: "GET",
            url: Tools.getApiAddress("goods_unit_select_delete"),
            params: {
                unitId: params.rawData.id,
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })
    }
}

export const unitAction = new UnitAction();