import { ColumnsType } from "antd/lib/table";
import { Button } from "../../../component/button"
import { Modal } from "../../../component/modal"
import { Table } from "../../../component/table";
import { useEffect, useState } from "react";
import { formAction } from "../action/form_action";
import { Tag } from "antd";
import { ConfigApi } from "../../../config";
interface IParams {
    visibleModal: boolean;
    close?: (status, r) => void;
    id: string
  }
export const FaPiaoLogs = ({id, visibleModal, close}: IParams) => {
    const [list, setData] = useState([])
    const handleCancel = () => {
        close && close(false, null);
      };
    useEffect(()=>{
        (async function(){
            const res = await formAction.getFapiaoLogs(id)
            setData(res)
        })()
    },[id])
    const columns:ColumnsType = [
        {
            title: "抬头类型",
            dataIndex: 'buyerType',
            key: "0",
            align: "center",
            render: (v)=>{return v==1?'个人/非企业单位':'企业'}
          },
        {
            title: "发票抬头",
            dataIndex: 'buyerName',
            align: "center",
          },
        {
            title: "纳税人识别号",
            dataIndex: 'buyerTaxNum',
            align: "center",
            render: v=><span>{v?v:'-'}</span>
          },
          {
            title: "发票金额",
            dataIndex: 'invoicingPrice',
            align: "center",
          },
          {
            title: "开票状态",
            dataIndex: 'resultStatus',
            align: "center",
            render: v=><Tag color={v== 1?'cyan': v== 2?'success':'error'}>{v == 1?'开票中': v== 2?"开票成功":'开票失败'}</Tag>
          },
          {
            title: "开票发起时间",
            dataIndex: 'invoicingTime',
            align: "center",
            width: 120
          },
          {
            title: "票据",
            dataIndex: 'invoicingPath',
            align: "center",
            render: v=><span style={{color: '#1890ff', cursor: 'pointer'}} onClick={()=>{
                let str = (ConfigApi.serviceDomain+v).replace(/\\/g, '/')  
               window.open(str+'#toolbar=0')  
            }}>点击查看</span>
          },
    ]
    return <Modal 
    title='开票记录'
    visible={visibleModal}
    width={1000}
    footer={
      <>
        <Button
          type="primary"
          style={{
            backgroundColor: "#f7ba00",
            borderColor: "#f7ba00",
            color: "white",
          }}
          onClick={()=>handleCancel()}
        >
          取消
        </Button>
     
      </>
    }
    >
     <Table         
         columns={columns}
         dataSource={list}
         scroll={{y: 500}}
         />
    </Modal>
}