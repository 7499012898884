import moment from "moment";
import { createContext, useContext, useReducer } from "react";

export const SpecialFormStore = createContext<IContextProps>(null);

interface IContextProps {
  state: IState;
  dispatch: ({ type }: Dispatch) => void;
}

type Dispatch = { type; payload };

class Search {
  keyword: string;
  areaId: string;
  statusId: string;
  typeId: string;
}
export class FormData {
  activityName: string;
  discountType: 3;
  useMemberClassID: [];
  activityStartDate: string;
  activityEndDate: string;
  activityStartTime: string;
  activityEndTime: string;
  useType: number = 1;
  useTypeValue: [];
  purposeType: number;
  activityRanges: [];
  shopID: string[];
  useweekTypeValue: [];
  isMemberClass: number = 1;
  shopName: string;
}
export class TableItem {
  serial: number;
  goodsId: string;
  goodsCode: string;
  goodsName: string;
 
  specialPrice: number;
  goodsUnitName: string;
  storePrice: number;
  shopPrice: number;
  goodsClassName: string;
  goodsNo: string;
  barCode: string
}
interface IState {
  searchList: any[];
  formData: FormData;
  search: Search;
  tableList: TableItem[];
  TagList: any[];
  shoplist: any[];
}

const initialArgs: IState = {
  searchList: [],
  formData: new FormData(),
  search: new Search(),
  tableList: [],
  TagList: [],
  shoplist: [],
};

function reducer(state: IState, { type, payload }: Dispatch) {
  switch (type) {
    case "LoadShop":
      return {
        ...state,
        shoplist: payload,
      };
    case "update":
      return {
        ...state,
      };
    // 等级列表
    case "LoadTag":
      return {
        ...state,
        TagList: payload,
      };
    case "SetSearchList":
      return {
        ...state,
        searchList: payload,
      };
    case "SetFormData": {
      return {
        ...state,
        formData: {
          ...state.formData,
          ...payload,
        },
      };
    }
    case "SetForm": {
      const {
        activityName,
        discountType,
        isMemberClass,
        goodsList,
        shopName,
        activityEndDate,
        useMemberClassID,
        activityStartDate,
        activityStartTime,
        activityEndTime,
        useType,
        useTypeValue,
        purposeType,
        activityRanges,
        shopID,
      } = payload;
      const item = new FormData();
      item.activityName = activityName;
      item.discountType = 3;
      item.useMemberClassID = isMemberClass == 1 ? [] : useMemberClassID;
      item.activityStartDate = activityStartDate;
      item.activityStartTime = activityStartTime;
      item.activityEndTime = activityEndTime;
      item.useType = useType;
      item.isMemberClass = isMemberClass;
      item.purposeType = purposeType;
      item.activityRanges = activityRanges;
      
      item.shopID = shopID;
      item.shopName = shopName;

      item.activityStartDate =
        activityStartDate != null ? activityStartDate.slice(0, 10) : "";
      item.activityEndDate =
        activityEndDate != null ? activityEndDate.slice(0, 10) : "";
      item.activityStartTime =
        activityStartTime != null ? activityStartTime.slice(11, 22) : "";
      item.activityEndTime =
        activityEndTime != null ? activityEndTime.slice(11, 22) : "";

      item.useTypeValue =
        useType == 2 ? (useTypeValue ? useTypeValue.split(",") : []) : [];
      item.useweekTypeValue =
        useType == 1 ? (useTypeValue ? useTypeValue.split(",") : []) : [];

        goodsList.length > 0 && goodsList.forEach((v, i) => (v.serial = i));
      return {
        ...state,
        formData: item,
        tableList: goodsList,
      };
    }
    case "AddTableItem": {
      const list = state.tableList || [];

      // 去重
      if (list.every((v, i) => v.barCode !== payload.barCode)) {
        const item = new TableItem();
        const {
          goodsNo,
          goodsName,
          id,
          goodsUnitName,
          shopPrice,
          storePrice,
          specialPrice,
          goodsClassName,
          barCode,

        } = payload;
        item.barCode = barCode
        item.goodsId = id;
        item.goodsNo = goodsNo;
        item.goodsCode = goodsNo;
        item.goodsName = goodsName;
        item.goodsUnitName = goodsUnitName;
        item.shopPrice = shopPrice;
        item.storePrice = storePrice;
        item.specialPrice = specialPrice;
        item.goodsClassName = goodsClassName;
        list.push(item);

        list.length > 0 && list.forEach((v, i) => (v.serial = i));

        return {
          ...state,
          tableList: [...list],
        };
      }
      return state;
    }
    case "DelTableItem": {
   const list = state.tableList || [];

      list.splice(payload, 1);
      list.length > 0 && list.forEach((v, i) => (v.serial = i));
      return {
        ...state,
        tableList: [...list],
      };
    }
    case "ChangeTableItem": {
      const { index, key, value } = payload;

      const list = state.tableList || [];

      const item = list[index];

      item[key] = parseFloat(value);

   
      if ((item.specialPrice && item.specialPrice >= 0.01) || item.specialPrice === 0) {        
      }else{
        item.specialPrice = undefined;
      }
     

      return {
        ...state,
        tableList: [...list],
      };
    }
    case "ClearTableList":
      return {
        ...state,
        tableList: [],
      };
    default:
      return state;
  }
}

export function SpecialFormStoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialArgs);

  const v = { state, dispatch };
  return (
    <SpecialFormStore.Provider value={v}>
      {props.children}
    </SpecialFormStore.Provider>
  );
}
