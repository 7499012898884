import React, { useContext, useEffect, useState } from "react";
import { Button, Cascader, Checkbox, Col, Form, Input, Row, Select } from "antd";
import styles from "../../style/file_search.module.scss";
import { useRequest } from "ahooks";
import { Select as SelectAntd, DatePicker, Space } from "antd";
import { fileAction } from "../../action/integral/file_action";
import { FileStore } from "../../store/integral/file_store";
import moment from 'moment';
import { fetchPeopleList } from "../../../order/view/file_search";

const { RangePicker } = DatePicker;
const { Option } = Select;

let globalPeopleList = [];
export function FileSearch({ onSearch }) {
    const { state, dispatch } = useContext(FileStore);
    const action = fileAction;
    
    const [peopleList, setPeopleList] = useState([]);
    useEffect(() => {
        fetchPeopleList().then((l) => {
            globalPeopleList = l;
            setPeopleList(l);
        });

        return () => {

        }
    }, []);

    return (
        <div>
            <Form layout="horizontal">
                <div className={styles.row}>
                    <div className={styles.cell}>
                        <Form.Item label="积分单号">
                            <Input autoComplete="off" style={{ width: "15rem" }} placeholder="请输入积分单号" onChange={(v) => action.setCondition("OddNumbers",v.target.value, dispatch)} />
                        </Form.Item>
                    </div>
                    <div className={styles.cell}>
                        <Form.Item label="操作用户">
                            <Select showSearch style={{ width: "15rem" }} onChange={(v) => action.setCondition("OperationName", v, dispatch)}
                                defaultValue={-1}
                                filterOption={(input:any, option:any) => {
                                    return option.children.indexOf(input) >= 0;
                                }
                                }
                            >
                                <Option value={-1}>全部</Option>
                                {
                                    peopleList.map(v => <Option value={v.userID}>{v.userName}</Option>)
                                }
                            </Select>
                        </Form.Item>
                    </div>
                    <div className={styles.cell}>
                        <Form.Item label="创建时间">
                            <Space style={{ width: "36.5rem" }} direction="vertical" size={12} >
                                <RangePicker onChange={function (date, dateString) {
                                    action.setCondition("StartDateTime", dateString[0], dispatch)
                                    action.setCondition("EndDateTime", dateString[1], dispatch)
                                    
                                }} />
                            </Space>
                        </Form.Item>
                    </div>
                </div>
                <div className={styles.row}>
                    <Button type="primary" danger onClick={() => onSearch(1)}>搜  索</Button>
                </div>
            </Form>
        </div>
    )
}