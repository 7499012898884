/**
 * 数据树
 * 按数据的键的某个顺序生成一个层级的树形结构数据
 */
export class DataTree {
    /** 键 */
    key: string;
    /** 键值 */
    keyValue: string;
    /** 深度 */
    deep: number;
    /** 子内容，可以是其他非树数据 */
    child: (DataTree | any)[];
    /** 是否是树干，如果是则表示子内容为树数据，否则则不是 */
    ifTrunk: boolean = true;

    constructor(key: string, keyValue: string, deep: number) {
        this.key = key;
        this.keyValue = keyValue;
        this.deep = deep;
        this.child = [];
    }

    /**
     * 生成数据树
     * @param _data 源数据雷列表
     * @param _keyLayer 键层级
     */
    static generateData(_data: any[], _keyLayers: string[]): DataTree[] {
        let dataTree: DataTree[] = [];
        _data.forEach((item) => {
            DataTree.addData(dataTree, _keyLayers, item);
        });
        //
        return dataTree;
    }

    /** 根据key链添加一个数据到某个数据树中 */
    static addData(_dataTrees: DataTree[], _keyLayers: string[], _data: any) {
        //找到_keyValues对于的那个子内容数组
        let _dataTree: DataTree;
        let _child: any[] = _dataTrees;
        for (let [index,_keyLayer] of _keyLayers.entries() as any) {
            _dataTree = _child.find((item) => {
                return item.keyValue == _data[_keyLayer];
            });
            if (!_dataTree) {
                _dataTree = new DataTree(_keyLayer, _data[_keyLayer], index);
                _child.push(_dataTree);
            }
            //跳到下一层
            _child = _dataTree.child;
        }
        //添加到当前树中且设置当前树为非树干
        _dataTree.ifTrunk = false;
        _child.push(_data);
    }

    /** 获取一个树中的非树内容 */
    static getNotTreeDatas<T = any>(_dataTree: DataTree): T[] {
        //如果当前树为非树干则直接返回数据
        if (!_dataTree.ifTrunk) {
            return _dataTree.child;
        }
        let _datas: T[] = [];
        //
        for (let o of _dataTree.child) {
            _datas.push(...DataTree.getNotTreeDatas(o));
        }
        //
        return _datas;
    }
}