import { useRequest } from "ahooks";
import { ColumnsType } from "antd/lib/table";
import { TableRowSelection } from "antd/lib/table/interface";
import { ReactText, useContext, useEffect, useRef, useState } from "react";
import { SetTimeout } from "stb-tools";
import { fillHeight } from "../../../logic/common_logic";
import { DataTableAction } from "../action/data_table_action";
import { DataTableStore } from "../store/data_table_store";
import { Table } from "./table";

interface IDataTable {
  columns: ColumnsType;
  action: DataTableAction;
  pagination?: boolean;
  onLoad: () => void;
  onChange: (pageIndex: number) => void;
  rowSelection?: TableRowSelection<any>;
  scroll?: { x?: string | number | true; y?: ReactText; autoY?: boolean } & {
    scrollToFirstRowOnChange?: boolean;
  };
  selectColumn?: boolean;
  rowKey?;
  saveKeys?: boolean;
  selKeys?: any[];
  selRows?: any[];
  loading?: boolean;
  showHeader?: boolean;
  rowClassName?;
  expandable?;
}

const timer = new SetTimeout(100);

export function DataTable({
  rowKey = 'id',
  columns,
  action,
  onLoad,
  onChange,
  pagination,
  rowSelection,
  scroll,
  selectColumn,
  saveKeys,
  selKeys,
  selRows,
  loading,
  showHeader = true,
  rowClassName,
}: IDataTable) {
  const { state, dispatch: dispatch } = useContext(DataTableStore);
  const { total, pageSize, current, dataList, selectedRowKeys, selectList } =
    state;
  const { clearSelectedRowKeys, setSelectList, setSelectedRowKeys } = action;
  const [keys, setKeys] = useState([]);
  const [rows, setRows] = useState([]);
  const paginationProps = {
    showSizeChanger: false,
    showQuickJumper: false,
    showTotal: () => `共${total}条`,
    pageSize: pageSize,
    current: current,
    total: total,
    onChange: (current) => {
      // clearSelectedRowKeys({ dispatch });
      onChange && onChange(current);
    },
  };

  useRequest(() => {
    return new Promise((resolve) => {
      onLoad && onLoad();
      resolve(true);
    });
  });

  const tagRef = useRef(null);
  const scrollSetting = () => {
    if (tagRef.current && scroll) {
      if (undefined == scroll.y) {
        // 内容不足，高度不滚动
        if (scroll.autoY) {
          scroll.y = fillHeight(tagRef.current, 210);

          // const tag = document.getElementsByClassName("ant-table-body").item(0)

          // if (tag) {
          //     //console.log(tag.clientHeight, tag.scrollHeight);

          //     timer.enable(v => {
          //         if (tag.clientHeight == tag.scrollHeight) {
          //             tag.setAttribute("style", `overflow-y: hidden;max-height: ${scroll.y}px;`)
          //         } else {
          //             tag.setAttribute("style", `overflow-y: scroll;max-height: ${scroll.y}px;`)
          //         }
          //     })
          // }

          return { scroll: scroll };
        }
      } else {
        return { scroll: scroll };
      }
    }
    return {};
  };

  let tableRowSelection;
  if (saveKeys) {
    tableRowSelection = {
      rowSelection: {
        type: "checkbox",
        selectedRowKeys,
        defaultSelectedRowKeys: [1],
        onChange: (selectedRowKeys, selectedRows) => {
          // setSelectedRowKeys({ dispatch, selectKeys: keys });
          // setSelectList({ dispatch, selectKeys: rows.map(v => v) })
        },
        onSelect: (record, selected, selectedRows, nativeEvent) => {
          let key = keys;
          let row = rows;
          if (selected) {
            //
            if (!key.includes(record[rowKey])) {
              key.push(record[rowKey]);
              row.push(record);
            }
          }
          if (!selected) {
            //
            if (key.includes(record[rowKey])) {
              key = key.filter((item) => {
                return item != record[rowKey];
              });
              row = row.filter((item) => {
                return item[rowKey] != record[rowKey];
              });
            }
          }
          //同步数据
          setKeys(key);
          setRows(row);
          setSelectedRowKeys({ dispatch, selectKeys: key });
          setSelectList({ dispatch, selectKeys: row.map((v) => v) });
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
          let key = [...keys];
          let row = [...rows];
          if (selected) {
            //遍历选择的列表，没选择的就添加进去
            changeRows.forEach((item) => {
              if (!key.includes(item[rowKey])) {
                key.push(item[rowKey]);
                row.push(item);
              }
            });
          }
          if (!selected) {
            let delIndex = [];

            //遍历选择的列表，如果选择了就删除
            changeRows.forEach((item) => {
              if (key.includes(item[rowKey])) {
                delIndex.push(item[rowKey]);
              }
            });

            //去除需要删除的内容
            key = key.filter((item) => {
              return !delIndex.includes(item);
            });
            row = row.filter((item) => {
              return !delIndex.includes(item[rowKey]);
            });
          }
          //同步数据
          setKeys(key);
          setRows(row);
          setSelectedRowKeys({ dispatch, selectKeys: key });
          setSelectList({ dispatch, selectKeys: row.map((v) => v) });
        },

        ...rowSelection,
      },
    };
  } else {
    tableRowSelection = {
      rowSelection: {
        type: "checkbox",
        selectedRowKeys,
        preserveSelectedRowKeys: true,
        onChange: (selectedRowKeys, selectedRows) => {
          setSelectedRowKeys({ dispatch, selectKeys: selectedRowKeys });
          setSelectList({ dispatch, selectKeys: selectedRows.map((v) => v) });
        },
        ...rowSelection,
      },
    };
  }

  useEffect(() => {
    return () => {};
  });
  if (!selectColumn) tableRowSelection = {};

  return (
    <div ref={tagRef}>
      <Table
        rowClassName={(record, index) => {
          if (rowClassName) {
            return record.expirationDate && new Date(record.expirationDate).getTime() < new Date().getTime()?'redRow':'';
          }
        }}
        showHeader={showHeader}
        dataSource={dataList}
        columns={columns}
        {...tableRowSelection}
        {...scrollSetting()}
        rowKey={rowKey}
        loading={loading}
        pagination={pagination ? {} : paginationProps}
      />
    </div>
  );
}
