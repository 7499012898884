import { createContext, useContext, useReducer } from "react";

export const FileStore = createContext<IContextProps>(null);

interface IContextProps {
    state: IState;
    dispatch: ({ type }: Dispatch) => void;
}
type Dispatch = { type, payload? }

interface IState {
    dataList: any[];
    modleList: any[];
    search: Search;
}
class Search {
    keywords: string;
    membeClassID: string;
};
const initialArgs = {
    dataList: [],
    search: new Search(),
    modleList:[]
}
function reducer(state: IState, { type, payload }: Dispatch) {
    switch (type) {
        case "SetSearch":
            return {
                ...state,
                search: {
                    ...state.search,
                    ...payload
                }
            }
        case 'Init':
            state.dataList = payload
            state.modleList = []
            return{
                ...state
            }
        case 'Clear':
            state.modleList = []
                return{
                    ...state
                }
        case 'ModleList':
            {
                const list = state.modleList || []
                if(payload.selected){
                    list.push(payload.value)
                }else{
                   let id =  list.indexOf(payload.value)
                   list.splice(id,1)
                }
                state.modleList = list
                return{
                    ...state
                }
            }

        default:
            return state;
    }
}

export function ClearStoreProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialArgs);

    const v = { state, dispatch };
    return <FileStore.Provider value={v}>{props.children}</FileStore.Provider>
}