import { createContext, useContext, useReducer } from "react";

export const ListStore = createContext<IContextProps>(null);

interface IContextProps {
  state: IState;
  dispatch: ({ type }: Dispatch) => void;
}

type Dispatch = { type; payload };

class Search {
  keywords: string;
  beginTime: string;
  endTime: string;
  actionType: string;
}

interface IState {
  search: Search;
}

const initialArgs = {
  search: new Search(),
};

function reducer(state: IState, { type, payload }: Dispatch) {
  switch (type) {
    case "SetSearch":
      //   console.log(payload);
      return {
        ...state,
        search: {
          ...state.search,
          ...payload,
        },
      };
    default:
      return state;
  }
}

export function ListStoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialArgs);

  const v = { state, dispatch };
  return <ListStore.Provider value={v}>{props.children}</ListStore.Provider>;
}
