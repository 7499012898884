import { Market_logic } from "./Market_logic";

/**
 * 积分兑换商品逻辑
 */
export class MarketingPointsExchangeGoods_logic extends Market_logic {
    /** 添加商品 */
    static AddGoodsExchange(_op: {
        /** 商品id */
        GoodsID: string;
        /** 商品规格id */
        SepID: string;
        BarCode: string | Number
    }) {
        return this.axios(this.getApi('MarketingPointsExchangeGoods.AddGoodsExchange'), 'GET',{}, _op);
    }

    /** 设置商品兑换规则 */
    static SetExchangeRules(_op: any) {
        return this.axios(this.getApi('MarketingPointsExchangeGoods.SetExchangeRules'), 'POST', _op, {});
    }

    /** 分页查询 */
    static QueryPage(_op: any) {
        return this.axios(this.getApi('MarketingPointsExchangeGoods.QueryPage'), 'POST', _op,{});
    }

    /** 获取单个 */
    static GetFirstModel(_op: {
        /** 兑换活动ID */
        ID: string;
    }) {
        return this.axios(this.getApi('MarketingPointsExchangeGoods.GetFirstModel'), 'GET',{}, _op);
    }

    /** 上架兑换活动 */
    static Shelves(_op: {
        /** 兑换活动ID */
        ID: string;
    }) {
        return this.axios(this.getApi('MarketingPointsExchangeGoods.Shelves'), 'GET',{}, _op);
    }

    /** 下架兑换活动 */
    static OfftheShelf(_op: {
        /** 兑换活动ID */
        ID: string;
    }) {
        return this.axios(this.getApi('MarketingPointsExchangeGoods.OfftheShelf'), 'GET',{}, _op);
    }

    /** 作废兑换活动 */
    static VoidtheShelf(_op: {
        /** 兑换活动ID */
        ID: string;
    }) {
        return this.axios(this.getApi('MarketingPointsExchangeGoods.VoidtheShelf'), 'GET',{}, _op);
    }
}