import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
  TimePicker,
  DatePicker,
  Tag,
  InputNumber,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { FormSubtitle } from "../../../commodity/view/form_subtitle";
import styles from "../../style/inventory_goods_formbasic.module.scss";

import img_del from "../../../../assets/images/shanchu.png";
import { couponformAction } from "../../action/coupon/form_action";
import { useBoolean } from "ahooks";
import { ChooseGoods } from "../../../../component/choose-goods";
import { Table } from "../../../../component/table";
import { ChooseShop } from "../../../../component/choose-shop";
import { CouponFormStore } from "../../store/coupon/form_store";
import { autoRem } from "../../../../global.style";
import { useState } from "react";
import { ChooseClassify } from "../../../../component/choose-classify";
import { RadioStyle } from "../../../../component/radio-style";
import { start } from "repl";
import moment from "moment";
import { NumberTool } from "../../../../tool/numberTool";

const { Option } = Select;
const { RangePicker } = DatePicker;
export function DataTable({ dataList, disabled }) {
  const { dispatch, state } = useContext(CouponFormStore);
  const action = couponformAction;
  
  const columns: ColumnsType = [
    {
      title: "操作",
      dataIndex: "serial",
      key: "1",
      align: "center",
      width: 100,
      render: (v, r, i) => (
        <div>
          <img
            src={img_del}
            style={{ width: 20 }}
            onClick={() => {
              !disabled && action.delTableItem(dispatch, v);
            }}
          />
        </div>
      ),
    },
    {
      title: "商品条码",
      dataIndex: "barCode",
      key: "2",
      align: "center",
      width: 120,
    },
    {
      title: "商品名称",
      dataIndex: "goodsName",
      key: "3",
      align: "center",
      width: 100,
    },
    {
      title: "商品分类",
      dataIndex: "goodsClassName",
      key: "4",
      align: "center",
      width: 100,
    },
    {
      title: "单位",
      dataIndex: "goodsUnitName",
      key: "8",
      align: "center",
      width: 120,
    },   
  ];
if(state.formData.couponType == 3){
  columns.push( {
    title: "数量",
    dataIndex: "num",
    key: "9",
    align: "center",
    width: 150,
    render: v=>{
      return <InputNumber 
      precision={0}

      value={v}
      onChange={e=>{
        console.log(e)
      }}
      />
    }
  },   )
}
  return <Table dataSource={dataList} columns={columns} />;
}
export function DataTableclassity({ dataList, disabled }) {
  const { dispatch } = useContext(CouponFormStore);
  const action = couponformAction;

  const columns: ColumnsType = [
    {
      title: "操作",
      dataIndex: "serial",
      key: "1",
      align: "center",
      width: 100,
      render: (v, r:any, i) => (
        <div>
          <img
            src={img_del}
            style={{ width: 20 }}
            onClick={() => {
              !disabled && action.delTableclassItem(dispatch, v);
            }}
          />
        </div>
      ),
    },
    {
      title: "分类编码",
      dataIndex: "classCode",
      key: "2",
      align: "center",
    },
    {
      title: "商品分类",
      dataIndex: "goodsClassName",
      key: "3",
      align: "center",
    },
  ];
  return <Table dataSource={dataList} columns={columns} />;
}

function TimeType({ value, swtichValue, disabled }) {
  const [] = useState(1);
  const { state, dispatch } = useContext(CouponFormStore);
  const { formData } = state;

  const action = couponformAction;

  const onchannelChange = (e) => {
    if (!disabled) {
      action.setFormData({
        dispatch,
        key: "couponType",
        value: e.target.value,
      });
    }
  };
  return (
    <RadioStyle>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Radio.Group onChange={onchannelChange} value={swtichValue}>
          <Radio
            value={1}
            style={{ marginLeft: "10px", height: "30px", lineHeight: "30px" }}
          >
            折扣券
          </Radio>
          <Radio value={2} style={{ height: "30px", lineHeight: "30px" }}>
            直减券
          </Radio>
        </Radio.Group>

        {formData.couponType == 2 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <span>订单中满足条件的商品减免</span>
            <Input
              type="number"
              disabled={disabled}
              style={{ width: "70px", margin: "0 6px", textAlign: "center" }}
              value={formData.subMoney}
              onChange={(v) =>
                action.setFormData({
                  dispatch,
                  key: "subMoney",
                  value: parseFloat(v.target.value),
                })
              }
            />
            <span>元优惠</span>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <span>订单中满足条件的商品折扣</span>
            <Input
              type="number"
              disabled={disabled}
              style={{ width: "70px", margin: "0 6px", textAlign: "center" }}
              value={formData.discount}
              onChange={(v) =>
                action.setFormData({
                  dispatch,
                  key: "discount",
                  value:
                    v.target.value &&
                    NumberTool.setRange(v.target.value, [1, 99]),
                })
              }
            />
            <span>% (折扣请输入1~99)</span>
          </div>
        )}
      </div>
    </RadioStyle>
  );
}

export function InventoryGoodsFormBasic({
  title,
  disabled,
  updateFormField,
  isEdit,
}) {
  const [shopState, shopFunc] = useBoolean(false);
  const [goodsState, goodsFunc] = useBoolean(false);
  const [classifyState, classifyFunc] = useBoolean(false);

  const { state, dispatch } = useContext(CouponFormStore);
  const { formData } = state;

  const action = couponformAction;
  var isLook = false;
  if (title == "查看秒杀") {
    isLook = true;
  } else {
    isLook = false;
  }
  const count = (key) =>
    state.tableList.map((v) => v[key]).reduce((p, c) => p + c, 0);
  function ShopType({ value, swtichValue }) {
    const [] = useState(1);
    const { dispatch } = useContext(CouponFormStore);

    const onChange = (e) => {
      if (!disabled) {
        action.setFormData({
          dispatch,
          key: "validType",
          value: e.target.value,
        });
      }
    };
    // 日期段选择
    const onRangeChange = (time, timeString) => {
      action.setFormData({
        dispatch,
        key: "validStartDate",
        value: timeString[0],
      });
      action.setFormData({
        dispatch,
        key: "validEndDate",
        value: timeString[1],
      });
    };
    const disabledDate = (current) => {
      return current < moment().startOf("day");
    };
    return (
      <RadioStyle>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Radio.Group onChange={onChange} value={swtichValue}>
            <Radio value={1}>指定日期</Radio>
            <Radio value={2} style={{ marginLeft: "10px" }}>
              获取后指定日期
            </Radio>
          </Radio.Group>

          {formData.validType == 2 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <span>获取优惠券后</span>
              <Input
                disabled={disabled}
                type="number"
                style={{ width: "40px", margin: "0 6px" }}
                value={formData.validDay}
                onChange={(v) =>
                  action.setFormData({
                    dispatch,
                    key: "validDay",
                    value: v.target.value,
                  })
                }
              />
              <span>天内有效</span>
            </div>
          ) : formData.validStartDate == undefined ||
            formData.validStartDate == "" ? (
            <RangePicker
              style={{ width: "300px", marginTop: "10px" }}
              disabled={disabled}
              disabledDate={disabledDate}
              onChange={onRangeChange}
            />
          ) : (
            <RangePicker
              disabled={disabled}
              disabledDate={disabledDate}
              style={{ width: "300px", marginTop: "10px" }}
              defaultValue={[
                moment(
                  formData.validStartDate != ""
                    ? formData.validStartDate
                    : null,
                  "YYYY-MM-DD"
                ),
                moment(
                  formData.validEndDate != "" ? formData.validEndDate : null,
                  "YYYY-MM-DD"
                ),
              ]}
              onChange={onRangeChange}
            />
          )}
        </div>
      </RadioStyle>
    );
  }
  // value={formData.birthday != undefined && moment(formData.birthday.slice(0, 12), 'YYYY-MM-DD')}
  function EffectiveType({ value, swtichValue }) {
    const [] = useState(1);
    const { dispatch } = useContext(CouponFormStore);

    const onChange = (e) => {
      if (!isLook && !disabled) {
        action.setFormData({
          dispatch,
          key: "getCouponType",
          value: e.target.value,
        });
      }
    };
    return (
      <RadioStyle>
        <Radio.Group onChange={onChange} value={swtichValue}>
          <Radio value={1}>主动领取</Radio>
          <Radio value={2} style={{ marginLeft: "10px" }}>
            系统发放
          </Radio>
          <Radio value={3} style={{ marginLeft: "10px" }}>
            积分兑券
          </Radio>
        </Radio.Group>
      </RadioStyle>
    );
  }
  function ChannelType({ value, swtichValue }) {
    const [] = useState(1);
    const { dispatch } = useContext(CouponFormStore);

    const onchannelChange = (e) => {
      if (!disabled && !isEdit) {
        action.setFormData({
          dispatch,
          key: "useTargetType",
          value: e.target.value,
        });
        action.clearTableList({ dispatch });
      }
    };
    return (
      <RadioStyle>
        <Radio.Group onChange={onchannelChange} value={swtichValue}>
          <Radio value={1} style={{ height: "30px", lineHeight: "30px" }}>
            门店收银
          </Radio>
          <Radio
            value={2}
            style={{ marginLeft: "10px", height: "30px", lineHeight: "30px" }}
          >
            线上商城
          </Radio>
          <Radio
            value={3}
            style={{ marginLeft: "10px", height: "30px", lineHeight: "30px" }}
          >
            全部
          </Radio>
        </Radio.Group>
      </RadioStyle>
    );
  }
  function DetailedChannelType({ value, swtichValue }) {
    const [] = useState(1);
    const { dispatch } = useContext(CouponFormStore);

    const ondetailedChange = (e) => {
      if (!disabled) {
        action.setFormData({ dispatch, key: "range", value: e.target.value });
      }
    };
    return (
      <RadioStyle>
        <Radio.Group
          onChange={ondetailedChange}
          value={swtichValue}
          style={{ display: "flex", justifyContent: "start" }}
        >
          <Radio value={1} style={{ height: "30px", lineHeight: "30px" }}>
            全场商品
          </Radio>
          <Radio
            value={2}
            style={{ marginLeft: "10px", height: "30px", lineHeight: "30px" }}
          >
            指定分类
          </Radio>
          <Radio
            value={3}
            style={{ marginLeft: "10px", height: "30px", lineHeight: "30px" }}
          >
            指定商品
          </Radio>
          {formData.range == 3 && (
            <div
              style={{
                fontSize: "10px",
                width: "60px",
                height: "30px",
                textAlign: "center",
                lineHeight: "30px",
                background: "#ff746d",
                color: "#fff",
                cursor: "pointer",
              }}
              onClick={() => !disabled && goodsFunc.setTrue()}
            >
              添加商品
            </div>
          )}
          {formData.range == 2 && (
            <div
              style={{
                fontSize: "10px",
                width: "60px",
                height: "30px",
                textAlign: "center",
                lineHeight: "30px",
                background: "#ff746d",
                color: "#fff",
                cursor: "pointer",
              }}
              onClick={() => !disabled && classifyFunc.setTrue()}
            >
              添加分类
            </div>
          )}
        </Radio.Group>
      </RadioStyle>
    );
  }
  const onChangeCheck = (e) => {
    if (!disabled) {
      action.setFormData({
        dispatch,
        key: "isOverlay",
        value: e.target.checked == true ? 1 : 0,
      });
    }
  };
  const onChange = (e) => {
    if (!disabled) {
      action.setFormData({ dispatch, key: "validType", value: e.target.value });
    }
  };
  // 日期段选择
  const onRangeChange = (time, timeString) => {
    action.setFormData({
      dispatch,
      key: "validStartDate",
      value: timeString[0],
    });
    action.setFormData({ dispatch, key: "validEndDate", value: timeString[1] });
  };
  const disabledDate = (current) => {
    return current < moment().startOf("day");
  };
  let height = document.body.clientHeight - 188;
  return (
    <div className={styles.root} style={{ height: height + "px" }}>
      <div className={styles.content}>
        <FormSubtitle>优惠券信息</FormSubtitle>

        <div
          style={{
            margin: "" + autoRem(1.9375) + " " + autoRem(1.25) + " 0 0",
          }}
          className="custom-radio"
        >
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                label="优惠券名称"
                labelCol={{ span: 3 }}
                name="couponName"
                rules={[
                  {
                    required: true,
                    validator: (_, __, callBack: (error?: string) => void) => {
                      if (!formData.couponName) {
                        callBack("请输入优惠券名称");
                        return;
                      }
                      callBack();
                    },
                  },
                ]}
              >
                {formData.couponName ? "" : ""}
                <Input
                  style={{ width: "400px" }}
                  disabled={disabled}
                  value={formData.couponName}
                  autoComplete="off"
                  placeholder="请选择"
                  maxLength={10}
                  onChange={(v) => {
                    updateFormField("couponName");
                    action.setFormData({
                      dispatch,
                      key: "couponName",
                      value: v.target.value,
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                label="优惠券描述"
                labelCol={{ span: 3 }}
                name="couponDetail"
              >
                {formData.couponDetail ? "" : ""}
                <Input
                  style={{
                    width: "400px",
                    border: "1px solid #d9d9d9",
                    padding: "4px 11px",
                  }}
                  disabled={disabled}
                  value={formData.couponDetail}
                  autoComplete="off"
                  placeholder="请输入备注"
                  onChange={(v) => {
                    action.setFormData({
                      dispatch,
                      key: "couponDetail",
                      value: v.target.value,
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 3 }}
                label={<span className={styles.alarm}>优惠券类型</span>}
              >
                <TimeType
                  disabled={disabled}
                  value={1}
                  swtichValue={formData.couponType ? formData.couponType : 1}
                />
              </Form.Item>
            </Col>
          </Row>
          {formData.getCouponType != 2 && (
            <Row gutter={16} style={{ marginTop: 0 }}>
              <Col span={24}>
                <Form.Item
                  labelCol={{ span: 3 }}
                  label="优惠券数量"
                  name="couponNumber"
                  rules={[
                    {
                      required: true,
                      validator(_, __, callback: (error?: string) => void) {
                        if (state.formData.getCouponType != 2) {
                          if (
                            state.formData.couponNumber == undefined ||
                            state.formData.couponNumber == ""
                          ) {
                            callback("请输入优惠券数量");
                            return;
                          }
                          if (!/^[1-9]\d*$/.test(state.formData.couponNumber)) {
                            callback("优惠券数量应该是正整数");
                            return;
                          }
                        }
                        callback();
                      },
                    },
                  ]}
                >
                  {formData.couponNumber ? "" : ""}
                  <Input
                    type="number"
                    style={{ width: "400px" }}
                    disabled={disabled}
                    value={formData.couponNumber}
                    autoComplete="off"
                    placeholder="请输入"
                    onChange={(v) =>
                      action.setFormData({
                        dispatch,
                        key: "couponNumber",
                        value: v.target.value,
                      })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
        </div>
        <FormSubtitle>优惠券设置</FormSubtitle>
        <div
          style={{
            margin: "" + autoRem(1.9375) + " " + autoRem(1.25) + " 0 0",
          }}
          className="custom-radio"
        >
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 3 }}
                label={<span className={styles.alarm}>有效日期</span>}
              >
                {/* <ShopType value={1} swtichValue={formData.validType ? formData.validType : 1} /> */}

                <RadioStyle>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Radio.Group
                      onChange={onChange}
                      value={formData.validType ? formData.validType : 1}
                    >
                      <Radio value={1}>指定日期</Radio>
                      <Radio value={2} style={{ marginLeft: "10px" }}>
                        获取后指定日期
                      </Radio>
                    </Radio.Group>

                    {formData.validType == 2 ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                          marginTop: "10px",
                        }}
                      >
                        <span>获取优惠券后</span>
                        <Input
                          disabled={disabled}
                          type="number"
                          style={{ width: "70px", margin: "0 6px" }}
                          value={formData.validDay}
                          onChange={(v) =>
                            action.setFormData({
                              dispatch,
                              key: "validDay",
                              value: v.target.value,
                            })
                          }
                        />
                        <span>天内有效</span>
                      </div>
                    ) : formData.validStartDate == undefined ||
                      formData.validStartDate == "" ? (
                      <RangePicker
                        style={{ width: "300px", marginTop: "10px" }}
                        disabled={disabled}
                        disabledDate={disabledDate}
                        onChange={onRangeChange}
                      />
                    ) : (
                      <RangePicker
                        disabled={disabled}
                        disabledDate={disabledDate}
                        style={{ width: "300px", marginTop: "10px" }}
                        value={[
                          moment(
                            formData.validStartDate != ""
                              ? formData.validStartDate
                              : null,
                            "YYYY-MM-DD"
                          ),
                          moment(
                            formData.validEndDate != ""
                              ? formData.validEndDate
                              : null,
                            "YYYY-MM-DD"
                          ),
                        ]}
                        onChange={onRangeChange}
                      />
                    )}
                  </div>
                </RadioStyle>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 3 }}
                label="可用门店"
                name="shopName"
                rules={[
                  {
                    required: true,
                    validator(_, __, callback: (error?: string) => void) {
                      if (
                        state.formData.shopID == undefined ||
                        state.formData.shopID.length == 0
                      ) {
                        callback("请选择可用门店");
                        return;
                      }
                      callback();
                    },
                  },
                ]}
              >
                {formData.shopName ? "" : ""}
                <Input
                  style={{ width: "400px" }}
                  disabled={disabled}
                  value={formData.shopName}
                  autoComplete="off"
                  placeholder="请选择门店"
                  onClick={() => shopFunc.setTrue()}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 3 }}
                label="使用条件"
                name="meetMoney"
                rules={[
                  {
                    required: true,
                    validator(_, __, callback: (error?: string) => void) {
                      if (
                        state.formData.meetMoney === undefined ||
                        state.formData.meetMoney === "" ||
                        state.formData.meetMoney === null
                      ) {
                        callback("请输入指定金额");
                        return;
                      }
                      if (parseFloat(state.formData.meetMoney) <= 0) {
                        callback("指定金额应该大于0");
                        return;
                      }
                      callback();
                    },
                  },
                ]}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <span>订单中满足条件的商品满</span>
                  <Input
                    type="number"
                    style={{
                      width: "70px",
                      margin: "0 6px",
                      textAlign: "center",
                    }}
                    disabled={disabled}
                    value={formData.meetMoney}
                    onChange={(v) =>
                      action.setFormData({
                        dispatch,
                        key: "meetMoney",
                        value: parseFloat(v.target.value),
                      })
                    }
                  />
                  <span>元可用</span>
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 3 }}
                label={<span className={styles.alarm}>使用途径</span>}
              >
                <ChannelType
                  value={1}
                  swtichValue={
                    formData.useTargetType ? formData.useTargetType : 1
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item label="优惠同享" labelCol={{ span: 3 }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Checkbox
                    checked={formData.isOverlay == 1 ? true : false}
                    onChange={onChangeCheck}
                  >
                    允许与其他优惠同时使用
                  </Checkbox>
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 3 }}
                label={<span className={styles.alarm}>领券设置</span>}
              >
                <EffectiveType
                  value={1}
                  swtichValue={
                    formData.getCouponType ? formData.getCouponType : 1
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 3 }}
                label={<span className={styles.alarm}>适应范围</span>}
              >
                <DetailedChannelType
                  value={1}
                  swtichValue={formData.range ? formData.range : 1}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        {formData.range == 3 && (
          <DataTable dataList={state.tableList} disabled={disabled} />
        )}
        {formData.range == 2 && (
          <DataTableclassity
            dataList={state.classifyList}
            disabled={disabled}
          />
        )}
      </div>

      <ChooseShop
        visibleModal={shopState}
        type='checkbox'
        close={(status, r) => {
          if (r) {
            let arr = []
            let shopName = []
            r.map(item=>{
              arr.push(item.id)
              shopName.push(item.shopName)
            })
            action.setFormData({ dispatch, key: "shopID", value: arr });
            action.setFormData({
              dispatch,
              key: "shopName",
              value: shopName.toString(),
            });
            updateFormField("shopName");
          }
          shopFunc.setFalse();
        }}
      />
      <ChooseGoods
        visibleModal={goodsState}
        chooseType="coupon"
        rowKey="barCode"
        shopID={formData.shopID}
        filter={{
          isMarketing: true,
          specifications: true,
          source:
            formData.useTargetType == 1
              ? "CS"
              : formData.useTargetType == 2
              ? "WX"
              : formData.useTargetType == 3
              ? "PC"
              : "CS",
        }}
        close={(status, r) => {
          if (r) {
            action.addTableItem({ dispatch, list: r });
          }
          goodsFunc.setFalse();
        }}
      />
      <ChooseClassify
        visibleModal={classifyState}
        close={(status, r) => {
          if (r) {
            action.AddclassTableItem({ dispatch, list: r });
          }
          classifyFunc.setFalse();
        }}
      />
    </div>
  );
}
