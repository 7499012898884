import { Market_logic } from "./Market_logic";

/**
 * 时段促销逻辑
 */
export class MarketingTimeSalesPromotion_logic extends Market_logic {
    /** 分页查询时段促销 */
    static QueryPage(_op: any) {
        return this.axios(this.getApi('MarketingTimeSalesPromotion.QueryPage'), 'POST', _op);
    }

    /** 获取单条 */
    static GetByID(_op: {
        /** 活动主键ID */
        ID: string;
    }) {
        return this.axios(this.getApi('MarketingTimeSalesPromotion.GetByID'), 'GET',{}, _op);
    }

    /** 添加时段促销 */
    static AddTimeSalesPromotion(_op: any) {
        return this.axios(this.getApi('MarketingTimeSalesPromotion.AddTimeSalesPromotion'), 'POST', _op);
    }

    /** 修改时段促销 */
    static UpdateTimeSalesPromotion(_op: any) {
        return this.axios(this.getApi('MarketingTimeSalesPromotion.UpdateTimeSalesPromotion'), 'POST', _op);
    }

    /** 删除时段促销 */
    static DeleteTemp(_op: {
        /** 活动主键ID */
        ID: string;
    }) {
        return this.axios(this.getApi('MarketingTimeSalesPromotion.DeleteTemp'), 'GET',{}, _op);
    }

    /** 终止活动 */
    static StopTimeSalesPromotion(_op: {
        /** 活动主键ID */
        ID: string;
    }) {
        return this.axios(this.getApi('MarketingTimeSalesPromotion.StopTimeSalesPromotion'), 'GET', {}, _op);
    }
}