import { useBoolean } from "ahooks";
import { Button, Input, message, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect, useState } from "react";
import { ButtonPanel } from "../../../../../component/button-panel/button_panel";
import { Search } from "../../../../../component/search";
import { DataTable } from "../../../../../component/table";
import { DataTableStore } from "../../../../../component/table/store/data_table_store";
import { Form } from "./from";
import { ConfirmModal } from "../../../../../component/modal";
import { Tips, TipsGroup } from "../../../../../component/tips";
import { FileSearch } from "./file_search";
import { Mask } from "../../../../../component/mask";
import { defualtPageSize, Progress } from "../../../../../logic/common_logic";
import { AuthLogic } from "../../../../../logic/auth_logic";
import {
  Control,
  Del,
  Edit,
  See,
} from "../../../../../component/control-column";
import stop_img from "../../../../../assets/images/zz.png";
import { FileStore } from "../../../store/exchange/goods/file_store";
import { fileAction } from "../../../action/exchange/goods/file_action";
import { FormStoreProvider } from "../../../store/exchange/goods/form_store";
import { ChooseGoods } from "../../../../../component/choose-goods";
import up_img from "../../../../../assets/images/icon/upEC.png";
import down_img from "../../../../../assets/images/icon/downEC.png";
import img_detail from "../../../../../assets/images/mx.png";
import { ChooseGoodsExchange } from "../../../../../component/goodsExchange-modal";
const formParams = {
  method: "Create",
  id: "",
  subId: "",
  item: null,
};

export function ExchangeGoods(viewPower) {
  //是否打开创建页面
  const [createState, createFunc] = useBoolean(false);
  //是否打开商品弹窗
  const [openState, openFunc] = useBoolean(false);
  //是否打开高级搜索
  const [searchState, searchFunc] = useBoolean(false);
  //是否上架
  const [confirmState, confirmFunc] = useBoolean(false);
  //是否下架
  const [confirmdownState, confirmdownFunc] = useBoolean(false);
  //是否终止
  const [stopState, stopFunc] = useBoolean(false);

  //明细弹窗
  const [detailState, detailFunc] = useBoolean(false);

  //全局state
  const { state, dispatch } = useContext(FileStore);
  //表单state
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);
  /** 表单Action */
  const action = fileAction;
  //权限
  const [menuButton, setData] = useState([]);
  useEffect(() => {
    (async function () {
      let r = await new AuthLogic().selButton(viewPower.viewPower);
      if (r && r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          r[i] = r[i].split("_")[r[i].split("_").length - 1];
        }
      }
      setData(r);
    })();

    return () => {};
  }, []);
  const columns: ColumnsType = [
    {
      title: "操作",
      key: "0",
      dataIndex: "oddNumbers",
      align: "center",
      width: Control.width(4),
      render: Control.render(
        (v, r) =>
          menuButton.indexOf("VIEW") != -1 && (
            <See
              click={() => {
                formParams.id = r.id;
                formParams.method = "Preview";
                formParams.subId = r.pointsExchangeID;
                formParams.item = r;
                //
                createFunc.setTrue();
              }}
            />
          ),
        (v, r) =>
          menuButton.indexOf("UPDATE") != -1 &&
          r.state != 3 &&
          r.state != 1 && (
            <Edit
              click={() => {
                formParams.id = r.id;
                formParams.subId = r.pointsExchangeID;
                formParams.method = "Edit";
                formParams.item = r;
                //
                createFunc.setTrue();
              }}
            />
          ),
        (v, r) =>
          menuButton.indexOf("UPDATE") != -1 &&
          r.state != 3 && (
            <img
              src={r.state == 1 ? down_img : r.state == 2 ? up_img : ""}
              alt=""
              onClick={() => {
                formParams.id = r.id;
                formParams.subId = r.pointsExchangeID;
                formParams.method = "Edit";
                formParams.item = r;
                if (r.exchangeTxt == null) {
                  message.error("未配置规则，不能上架");
                } else {
                  r.state == 2 && confirmFunc.setTrue();
                  r.state == 1 && confirmdownFunc.setTrue();
                }
              }}
            />
          ),
        // (v, r) => menuButton.indexOf("UPDATE") != -1 && r.state != 3 && <img src ={down_img} alt='' onClick={() => {
        //     formParams.id = r.id;
        //     formParams.subId = r.pointsExchangeID;
        //     formParams.method = "Edit";
        //     formParams.item = r;
        //     //
        //     confirmdownFunc.setTrue();
        // }} />,
        (v, r) =>
          menuButton.indexOf("UPDATE") != -1 &&
          r.state != 3 && (
            <img
              src={stop_img}
              alt=""
              onClick={() => {
                formParams.id = r.id;
                formParams.subId = r.pointsExchangeID;
                //
                stopFunc.setTrue();
              }}
            />
          )
      ),
    },
    {
      title: "商品编码",
      key: 1,
      align: "center",
      dataIndex: "goodsNo",
    },
    {
      title: "规格条码",
      key: 2,
      align: "center",
      dataIndex: "barCode",
    },
    {
      title: "商品名称",
      key: 3,
      align: "center",
      dataIndex: "goodsName",
    },
    {
      title: "单位",
      key: 4,
      align: "center",
      dataIndex: "utilName",
    },

    {
      title: "兑换所需",
      key: 7,
      align: "center",
      dataIndex: "exchangeTxt",
    },
    {
      title: "活动状态",
      key: 8,
      dataIndex: "state",
      align: "center",
      width: 80,
      render: (v) => (
        <>
          {v == 1 && <Tag color="success">已上架</Tag>}
          {v == 2 && <Tag color="warning">已下架</Tag>}
          {v == 3 && <Tag color="error">已作废</Tag>}
        </>
      ),
    },
    {
      title: "上架时间",
      key: 9,
      align: "center",
      dataIndex: "shelvesDateTime",
    },
    // {
    //   title: "添加人",
    //   key: 10,
    //   align: "center",
    //   dataIndex: "addName",
    // },
  ];

  const onSearch = async (pageIndex) => {
    searchFunc.setFalse();
    //
    await action.loadPage({
      pageIndex: pageIndex,
      pageSize: defualtPageSize(),
      dispatch: tableDispatch,
      search: state.search,
    });
  };

  return (
    <>
      <Search>
        <Input
          allowClear
          placeholder="请输入商品名称、编码"
          maxLength={20}
          onChange={(e) =>
            action.setCondition({
              dispatch,
              key: "seacherKey",
              value: e.target.value,
            })
          }
        />
        <input type="button" value="快速查找" onClick={() => onSearch(1)} />

        {/* <TipsGroup>
                    <input type="button" value="高级查找" onClick={() => searchFunc.toggle()} />
                    <Tips display={searchState} width="48rem">
                        <FileSearch onSearch={() => onSearch(1)} />
                    </Tips>
                    <Mask visible={searchState} onClick={() => searchFunc.setFalse()} />
                </TipsGroup> */}
      </Search>

      <ButtonPanel>
        {menuButton.indexOf("ADD") != -1 && (
          <Button
            onClick={() => {
              formParams.id = "";
              formParams.method = "Create";

              openFunc.setTrue();
            }}
          >
            添加商品
          </Button>
        )}
      </ButtonPanel>

      <DataTable
        columns={columns}
        action={action}
        rowKey='pointsExchangeID'
        onLoad={async () => {
          await onSearch(1);
          Progress.done();
        }}
        onChange={(pageIndex) => {
          onSearch(pageIndex);
        }}
        scroll={{ scrollToFirstRowOnChange: true, autoY: true, x: 1060 }}
        loading={tableState.loading}
      />

      {createState && (
        <FormStoreProvider>
          <Form
            audit={true}
            close={(hasUpdate) => {
              if (hasUpdate) {
                onSearch(tableState.current);
              }

              createFunc.setFalse();
            }}
            action={action}
            id={formParams.id}
            subId={formParams.subId}
            item={formParams.item}
            method={formParams.method}
          />
        </FormStoreProvider>
      )}
      <ChooseGoodsExchange
        visibleModal={detailState}
        id={formParams.subId}
        close={() => detailFunc.setFalse()}
      />
      <ChooseGoods
        visibleModal={openState}
        savekeysNo={true}
        chooseType="exchange"
        filter={{ specifications: true, isMarketing: true, type: true,source:'WX' }}
        type="radio"
        close={async (status, r) => {
          if (r) {
            let id = r[0].goodsID;
            let subId = r[0].goodsSepID;
            let barCode = r[0].barCode
            await action.addExchange({ id, subId, barCode });
            onSearch(tableState.current);
          }
          openFunc.setFalse();
        }}
      />
      <ConfirmModal
        visible={confirmState}
        title="温馨提示"
        subTitle="确认上架？"
        message="上架之后活动开始"
        close={async (r) => {
          if (r) {
            await action.upExchange(formParams.subId);

            onSearch(tableState.current);
          }
          confirmFunc.setFalse();
        }}
      />
      <ConfirmModal
        visible={confirmdownState}
        title="温馨提示"
        subTitle="确认下架？"
        message="下架之后活动停止"
        close={async (r) => {
          if (r) {
            await action.downExchange(formParams.subId);

            onSearch(tableState.current);
          }
          confirmdownFunc.setFalse();
        }}
      />
      <ConfirmModal
        visible={stopState}
        title="温馨提示"
        subTitle="确认作废？"
        message="作废之后无法再次上架，请慎重考虑"
        close={async (r) => {
          if (r) {
            await action.stopExchange(formParams.subId);

            onSearch(tableState.current);
          }
          stopFunc.setFalse();
        }}
      />
    </>
  );
}
