import { useBoolean, useRequest } from "ahooks";
import { Alert, Button, Checkbox, Input, message, Modal, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect, useState } from "react";
import { ButtonPanel } from "../../../../../component/button-panel/button_panel";
import { Mask } from "../../../../../component/mask";
import { Search } from "../../../../../component/search";
import { DataTable } from "../../../../../component/table";
import { TableExpand } from "../../../../../component/table-expand";
import { DataTableStore } from "../../../../../component/table/store/data_table_store";
import { TipsGroup, Tips } from "../../../../../component/tips";
import { defualtPageSize, Progress } from "../../../../../logic/common_logic";

import img_edit from "../../../../assets/images/bianji.png";
import img_see from "../../../../assets/images/chakan1.png";
import img_del from "../../../../assets/images/shanchu.png";
import { fileGoodsAction } from "../../../action/file/file_goods_action";
import { FileStore } from "../../../store/file/file_goods_store";
import { FileSearch } from "./goods_search";
import { AuthLogic } from "../../../../../logic/auth_logic";
import Spinner from "react-spinkit";
import { NumberTool } from "../../../../../tool/numberTool";
import {
  Control,
  See,
  Edit,
  Del,
} from "../../../../../component/control-column";
import { ConfirmModal } from "../../../../../component/modal";
let editId = "";
let seeId = "";
const formParams = {
  method: "Create",
  id: "",
  subId: "",
  item: null,
};
export function FileGoods(viewPower) {
  const [searchState, searchFunc] = useBoolean(false);
  const [reportState, reportFunc] = useBoolean(false);
  const [confirmState, confirmFunc] = useBoolean(false);
  const { state, dispatch } = useContext(FileStore);
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);

  const action = fileGoodsAction;
  const [menuButton, setData] = useState([]);
  useEffect(() => {
    (async function () {
      let r = await new AuthLogic().selButton(viewPower.viewPower);
      if (!r) return;
      if (r && r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          r[i] = r[i].split("_")[r[i].split("_").length - 1];
        }
      }
      setData(r);
    })();

    return () => {};
  }, []);
  const parseTime = (time: string) => {
    if(time && time.split(' ')[0]){
      return  time.split(' ')[0]
    }else{
     return ''
    }
  }
  const columns: ColumnsType = [
    // {
    //   title: "操作",
    //   key: "1",
    //   align: "center",
    //   width: Control.width(1),
    //   render: Control.render(
    //     (v, r) =>
    //       r.status !== 1 &&
    //       menuButton.indexOf("DELETE") != -1 && (
    //         <Del
    //           click={() => {
    //             formParams.id = v.id;
    //             formParams.subId = r.goodsID;

    //             confirmFunc.setTrue();
    //           }}
    //         />
    //       )
    //   ),
    // },
    {
      title: "批次号",
      dataIndex: "storageBatchNumber",
      key: "0",
      align: "center",
      width: 190
    },
    {
      title: "商品编码",
      dataIndex: "goodsNo",
      key: "2",
      align: "center",
      width: 120
    },
    {
      title: "商品名称",
      dataIndex: "goodsName",
      key: "3",
      align: "center",
      width: 100
    },
    {
      title: "商品分类",
      dataIndex: "goodsClass",
      key: "4",
      align: "center",
      width: 90
    },
    {
      title: "库存单位",
      dataIndex: "goodsUnit",
      key: "5",
      align: "center",
      width: 80,
      render: (v) => <Tag>{v}</Tag>,
    },
    {
      title: "库存数量",
      dataIndex: "invNumber",
      key: "6",
      align: "center",
      width: 100
    },
    {
      title: "库存价",
      dataIndex: "inventoryPrice",
      key: "7",
      align: "center",
      width: 100
    },
    {
      title: "库存成本",
      key: "8",
      align: "center",
      width: 100,
      render: (v, r: any) => (
        <span>
          {((NumberTool.toInteger(r.invNumber).num *
            NumberTool.toInteger(r.averageRate).num) /
            (NumberTool.toInteger(r.invNumber).times *
              NumberTool.toInteger(r.averageRate).times)).toFixed(2)}
        </span>
      ),
    },
    {
      title: "过期时间",
      dataIndex: "expirationDate",
      key: "9",
      align: "center",
      width: 100,
      render:v=><span>{v && parseTime(v)}</span>
    },
  ];

  const onSearch = async (pageIndex) => {
    await action.loadPage({
      pageIndex: pageIndex,
      pageSize: defualtPageSize(),
      dispatch: tableDispatch,
      search: state.search,
    });
  };

  useRequest(() => action.init({ dispatch }));

  return (
    <>
      <Search>
        <Input
          maxLength={20}
          allowClear
          placeholder="请输入商品编码，名称"
          onChange={(e) =>
            action.setCondition({
              dispatch,
              key: "keywords",
              value: e.target.value,
            })
          }
        />
        <input type="button" value="快速查找" onClick={() => onSearch(1)} />

        <TipsGroup>
          <input
            type="button"
            value="高级查找"
            onClick={() => searchFunc.toggle()}
          />
          <Tips width="24rem" display={searchState}>
            <FileSearch
              onSearch={(...arg: [any]) => {
                onSearch(...arg);
                searchFunc.setFalse();
              }}
            />
          </Tips>
          <Mask visible={searchState} onClick={() => searchFunc.setFalse()} />
        </TipsGroup>

        <Checkbox
          onChange={(e) =>
            action.setCondition({
              dispatch,
              key: "iszero",
              value: e.target.checked,
            })
          }
        >
          是否显示库存为0的商品
        </Checkbox>
      </Search>

      <ButtonPanel>
        {menuButton.indexOf("EXPORT") != -1 && (
          <Button
            onClick={async () => {
              await action.exportList({ search: state.search });
            }}
          >
            导出
          </Button>
        )}
      </ButtonPanel>

      <DataTable
        columns={columns}
        action={action}
        onLoad={async () => {
          await onSearch(1);
          Progress.done();
        }}
        onChange={(pageIndex) => {
          onSearch(pageIndex);
        }}
        scroll={{ scrollToFirstRowOnChange: true, autoY: true }}
        selectColumn={false}
        rowClassName={true}
        loading={tableState.loading}
      />
      <TableExpand>
        库存数量合计:{" "}
        {tableState.dataList
          .reduce((s, c) => s + Number(c.invNumber), 0)
          .toFixed(3)}
        <span style={{ marginLeft: 10 }}>
          库存成本合计: ¥{" "}
          {tableState.dataList
            .map((r) => Number(r.invNumber) * Number(r.averageRate))
            .reduce((s, c) => s + c, 0)
            .toFixed(2)}
        </span>
      </TableExpand>
      <ConfirmModal
        visible={confirmState}
        title="温馨提示"
        subTitle="确认删除？"
        message="删除之后商品所有库存信息全部清除，请慎重考虑"
        close={async (r) => {
          if (r) {
            await action.delRow(formParams.subId);

            onSearch(tableState.current);
          }
          confirmFunc.setFalse();
        }}
      />
    </>
  );
}
