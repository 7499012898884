// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../config";
import { effeative } from "./common_logic";

export class AuthLogic {
    async selData(classId: string) {
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("menber_grade_look"),
            params: {
                classId
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r.data.data;
    }
    //菜单权限按钮
    async selButton(Power : string) {
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("menu_button"),
            params: {
                Power:Power
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))         
        return r?r.data.data:null;
    }
    async querysList(roleId) {
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("auth_list"),
            params:{
                RoleID:roleId
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    async addData(params) {
        return effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("menber_grade_add"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
    }
    async updData(params) {
        return effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("menber_grade_update"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
    }

}