import { message } from "antd";
// import axios from "axios";
import Cookies from "js-cookie";
import { DataListAction } from "../../../../component/data-list/action/data_table_action";
import { DataTableAction } from "../../../../component/table/action/data_table_action";
import { Tools, axios } from "../../../../config";
import { AuthLogic } from "../../../../logic/auth_logic";
import { defualtPageSize } from "../../../../logic/common_logic";
import { RoleLogic } from "../../../../logic/role_logic";
type Init = {
    state;
    dispatch;
    form;
}
export class FileAction extends DataTableAction {
    private RoleLgc = new RoleLogic();
    async init({ state, dispatch, form }: Init) {
        await this.initBasic({ dispatch, state });
    }
    async initBasic({ dispatch, state }) {
        let parm = {
            name: '',
            pageIndex: 1,
            pageSize: defualtPageSize(),
            orderBy: '',
            sort: ''
        }
        dispatch({ type: "LoadRole", payload: await (await this.RoleLgc.querysList(parm)).data.data.dataSource });
    }
   
    async delRow({ dispatch, rowItem }) {
        const r = await axios({
            method: "GET",
            url: Tools.getApiAddress("menber_grade_delete"),
            params: {
                id: rowItem.id
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })

        if (r.data.statusCode == 200) {
            message.success("删除成功")
        } else {
            message.error(r.data.returnMessage)
        }
    }
    delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {
        selectList.map(async ({ id }) => {
            const r = await axios({
                method: "GET",
                url: Tools.getApiAddress("menber_grade_delete"),
                params: {
                    id: id
                },
                headers: {
                    SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
                },
            })

            if (r.data.statusCode == 200) {
                message.success("删除成功")
            } else {
                message.error(r.data.returnMessage)
            }
        })
    }
    async selecRole({ dispatch, dataList, roleId }: { dispatch: any; dataList: any; roleId: any }) {
        let powerIds = []
        for (let i = 0; i < dataList.length; i++) {
            for (let k = 0; k < dataList[i].powers.length; k++) {
                if (dataList[i].powers[k].checked) {
                    powerIds.push(dataList[i].powers[k].id)
                }
            }
        }
        const r = await axios({
            method: "POST",
            url: Tools.getApiAddress("auth_upd"),
            data: {
                powerIds: powerIds,
                roleId: roleId
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })
        if (r.data.statusCode == 200) {
            message.success("设置成功")
        } else {
            message.error(r.data.message)
        }
    }
    async loadPage({ dispatch, pageIndex, pageSize,search }) {
        this.setLoading({dispatch,loading: true})
        const { RoleId } = search;
        const r = await new AuthLogic().querysList(RoleId);

        if (r.data.statusCode == 200) {
            const dataList = r.data.data;
            const list = dataList.map((v, i) => {
                v.key = i + 1;
                return v;
            })
            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: list, pageSize });
        }  this.setLoading({dispatch,loading: false})

    }
    // 全选
    async allSelectPage({ dispatch, pageIndex, pageSize, list }) {
        for (let i = 0; i < list.length; i++) {
            for (let k = 0; k < list[i].powers.length; k++) {
                list[i].powers[k].checked = true
            }
        }
        this.setPaging({ dispatch, pageIndex, total: list.length, dataList: list, pageSize });
    }
    // 取消
    async allcancelSelectPage({ dispatch, pageIndex, pageSize, list }) {
        for (let i = 0; i < list.length; i++) {
            for (let k = 0; k < list[i].powers.length; k++) {
                list[i].powers[k].checked = false
            }
        }
        this.setPaging({ dispatch, pageIndex, total: list.length, dataList: list, pageSize });

    }
    // 单选
    async oneSelectPage({ dispatch, pageIndex, pageSize, list }) {
        this.setPaging({ dispatch, pageIndex, total: list.length, dataList: list, pageSize });
    }

    setCondition(method: "Keywords" | "RoleId", value, dispatch) {

        if ("Keywords" == method) {
            dispatch({ type: "SetSearch", payload: { keywords: value } });
        }
        if ("RoleId" == method) {
            dispatch({ type: "SetSearch", payload: { RoleId: value } });
        }
    }
}
export class RoleListAction extends DataListAction {
    private RoleLgc = new RoleLogic();
    async delRow(id) {
       
    }
    delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {
      
    }
    // 角色列表
    async rolePage({ dispatch, pageIndex, pageSize }) {
        let parm = {
            name: '',
            pageIndex: 1,
            pageSize: defualtPageSize(),
            orderBy: '',
            sort: ''
        }
        const r = await this.RoleLgc.querysList(parm);

        if (r.data.statusCode == 200) {
            const dataList = r.data.data.dataSource;

            const list = dataList.map((v, i) => {
                v.key = i + 1;
                return v;
            })
            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: list, pageSize });

            return dataList;
        }

    }
}
export const fileAction = new FileAction();
export const roleAction = new RoleListAction();