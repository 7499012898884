// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../config";
import { effeative } from "./common_logic";

interface IAddData {
    id: string;
    "source": number;
    "inventoryType": number;
    "inventoryStatus": number;
    "reason": number;
    "remark": string;
    "lossReporteds": [
        {
            "goodsID": string;
            "goodsName": string;
            "goodsUnit": string;
            "inventoryNumber": number;
            "price": number;
            "remark": string;
        }
    ]
}
interface ISelListByRecord {
    "source"?: number;
    "inventoryType"?: number;
    "inventoryStatus"?: number;
    "reason"?: number;
    "keyWords"?: string;
    "pageIndex"?: number;
    "pageSize"?: number;
    "orderBy"?: string;
    "sort"?: string;
}

export class ReportLogic {
    async addData(params: IAddData) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("report_add_data"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    async updData(params: IAddData) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("report_update_data"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    async selList(params: ISelListByRecord) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("report_select_page"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    async selExportList(params: ISelListByRecord) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("report_export"),
            responseType: 'arraybuffer',
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r;
    }
    async selData(id: string) {
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("report_select_data"),
            params: {
                oddNumbers: id
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r.data.data;
    }

}