import { message } from "antd";
// import axios from "axios";
import Cookies from "js-cookie";
import { DataTableAction } from "../../../../component/table/action/data_table_action";
import { Tools, axios } from "../../../../config";
import { MenuLogic } from "../../../../logic/menu_logic";

export class FileAction extends DataTableAction {
    async delRow({ dispatch, rowItem }) {
        const r = await axios({
            method: "GET",
            url: Tools.getApiAddress("menu_delete"),
            params: {
                id: rowItem.id
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })

        if (r.data.statusCode == 200) {
            message.success("删除成功")
        }
    }
    delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {
        selectList.map(async ({ id }) => {
            const r = await axios({
                method: "GET",
                url: Tools.getApiAddress("menu_delete"),
                params: {
                    id: id
                },
                headers: {
                    SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
                },
            })

            if (r.data.statusCode == 200) {
                message.success("删除成功")
            }
        })
    }
    async loadPage({ dispatch, pageIndex, pageSize, search }) {
        this.setLoading({dispatch, loading: true})
        // 菜单列表
        const { keywords } = search;
        let param = {
            name: keywords?keywords:'',
        }
        const r = await new MenuLogic().querysList(param);

        if (r.data.statusCode == 200) {
            const dataList = r.data.data;

            const list = dataList.map((v, i) => {
                v.keepAlive = v.keepAlive == true ? '是' : '否'
                v.isFrame = v.isFrame == true ? '是' : '否'
                v.hidden = v.hidden == true ? '隐藏' : '显示'
                v.key = i + 1;
                if (v.children && v.children.length > 0) {
                    v.children.map((t, k) => {
                        t.keepAlive = t.keepAlive == true ? '是' : '否'
                        t.isFrame = t.isFrame == true ? '是' : '否'
                        t.hidden = t.hidden == true ? '隐藏' : '显示'
                        t.key = parseInt(((i + 1).toString() + (k + 1).toString()));
                    })
                }

                return v;
            })
            this.setPaging({ dispatch, pageIndex, total: r.data.data.length, dataList: list, pageSize });
        }  this.setLoading({dispatch, loading: false})
    }
    setCondition(method: "Keywords", value, dispatch) {

        if ("Keywords" == method) {
            dispatch({ type: "SetSearch", payload: { keywords: value } });
        }
    }
}
export const fileAction = new FileAction();