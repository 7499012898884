/**
 * 数值工具
 */
export class NumberTool {
    /**
     * 转成金额
     * @param n 数值
     * @param symbol 符号
     */
    static toAmount(n: string | number, symbol?: -1 | 1): string {
        if (symbol) {
            return (Math.abs(parseFloat(n + '')) * symbol).toFixed(2);
        } else {
            return parseFloat(n + '').toFixed(2);
        }
    }

    /**
     * 设置范围
     * @param s 源字符串
     * @param range 范围，[a~b]
     */
    static setRange(s: string | number, range: [number, number]): number {
        let value: number = parseFloat(s + '') || 0;
        value = Math.max(value, range[0]);
        value = Math.min(value, range[1]);
        return value;
    }

    /**
     * 判断是否为整数
     */
    static isInteger(obj: number) {
        return Math.floor(obj) === obj;
    }


    /**
     * 将一个数装换为整数，返回整数和倍数
     * @param floatNum 浮点数
     * @return {times: number, num: number}     * 
     */
    static toInteger(floatNum: number) {
        let ret = { times: 1, num: 0}
        if(this.isInteger(floatNum)) {
            ret.num = floatNum
            return ret
        }
        let strfl = floatNum + ''
        let dotPos = strfl.indexOf('.')
        let len = strfl.substr(dotPos + 1).length
        let times = Math.pow(10, len)
        let intNum = parseInt(floatNum * times+'',10)
        ret.times = times
        ret.num = intNum
        return ret
    }

    /**
     * 加减乘除，不丢失精度
     */
    static operate(a: number, b: number, digits: number, op) {
            let intA = this.toInteger(a)
            let intB = this.toInteger(b)
            let numA = intA.num
            let timesA = intA.times
            let numB = intB.num
            let timesB = intB.times
            let max = timesA > timesB ? timesA : timesB
            let result = null
            switch(op) {
                case  'add' :
                    {  if(timesA || timesB){
                             
                        if(timesA == timesB) {
                            result = numA + numB
                        }
                        else if( timesA > timesB) {
                            result =  numA + numB * (timesA / timesB)
                        }
                        else {
                            result = numA * (timesB / timesA) + numB
                        }
                        return result / max
                        }else{
                            return numA + numB
                        }
                    }
                case 'substract' :
                    {
                        if(timesA || timesB) {
                            if(timesA == timesB) {
                                result = numA - numB
                            } else if( timesA > timesB) {
                                result = numA - numB * (timesA / timesB)
                            }
                            else{
                                result  = numA * (timesB / timesA) - numB
                            }
                            return result / max
                        } else {
                            return numA - numB
                        }
                    }
                case 'multiply': 
                {
                    result = (numA * numB) / (timesA * timesB)
                    return result
                }
                case 'divide':
                    {
                        result = (numA / numB) * (timesB / timesA)
                        return result
                    }
            }

    }
        static add(a, b, digits) {
        return this.operate(a, b, digits, 'add')
       }
       static subtract(a, b, digits) {
        return this.operate(a, b, digits, 'substract')
       }
       static multiply(a, b, digits) {
        return this.operate(a, b, digits, 'multiply')
       }
       static divide(a, b, digits) {
        return this.operate(a, b, digits, 'divide')
       }
}