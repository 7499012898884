import React, { useEffect, useState } from "react";
import { Layout } from "../../component/layout";
import { TabsStyle, TabList, Tab, TabPanel } from "../../component/react-tabs";
import { DataTableStoreProvider } from "../../component/table/store/data_table_store";
import { FileStoreProvider } from "./store/file_store";
import { File } from "./view/file";
import { MenuLogic } from "../../logic/menu_logic";

// 收银提成记录
import { File as CommissionLogs } from './view/commissionLogs/file'
import { FileStoreProvider as CommissionLogsStoreProvider } from './store/commission_logs_store'
export function Order() {
  const [menuLists, setData] = useState([]);
  useEffect(() => {
    (async function () {
      let r = await new MenuLogic().menuData();
      var arr = [];
      if (r && r.length > 0) {
        r = r.filter(function (v) {
          return !v.hidden;
        });
        for (let i = 0; i < r.length; i++) {
          if (r[i].path == "/order" && r[i].children) {
            arr = r[i].children.filter(function (v) {
              return !v.hidden;
            });
          }
        }
      }
      // arr.push({
      //   component: 'CommissionLogs',
      //   viewPower: 'CommissionLogs',
      //   name:'提成记录'
      // })
      setData(arr);
    })();

    return () => {};
  }, []);
  return (
    // <Layout fullWidth={true}>
    <TabsStyle style="Full">
      <TabList>
        {menuLists.map((v) => (
          <Tab>{v.name}</Tab>
        ))}
      </TabList>
      {menuLists.map((v) =>
        v.component == "Order" ? (
          //门店订单
          <TabPanel>
            <FileStoreProvider>
              <DataTableStoreProvider>
                <File viewPower={v.viewPower} />
              </DataTableStoreProvider>
            </FileStoreProvider>
          </TabPanel>
        ) : v.component == 'CommissionLogs'?
        <TabPanel>
          <CommissionLogsStoreProvider>
            <DataTableStoreProvider>
              <CommissionLogs viewPower={v.viewPower}/>
            </DataTableStoreProvider>
          </CommissionLogsStoreProvider>
        </TabPanel>
        : (
          <TabPanel>
            <div></div>
          </TabPanel>
        )
      )}
    </TabsStyle>
    // </Layout>
  );
}
