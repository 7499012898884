// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../config";
import { effeative } from "./common_logic";

export class UserLogic {
    async addData(params) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("user_add"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r.data;
    }
    async selList(params) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("user_sel_list"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r.data.data;
    }
    async updData(params) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("user_upd"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r.data;
    }
    async lookData(id: string) {
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("user_look"),
            params: {
                id
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r.data.data;
    }
    async selData() {
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("user_info"),
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r.data.data;
    }
    async resetData(params) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("user_psw_reset"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r.data;
    }

    /** 更新密码 */
    async undatePasswor(_op) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("UpdatePassword"),
            data: _op,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }));
        return r.data;
    }
}