import { TabKeys } from "../../tool/TabKeys";

export const goodsSalesRankTK: TabKeys[] = [
    // {
    //     key: 'rowNumber',
    //     title: '商品序号',
    // },
    {
        key: 'goodsName',
        title: '商品名称',
    },
    {
        key: 'unitName',
        title: '商品规格',
    },
    {
        key: 'rate',
        title: '占比',
    },
    {
        key: 'saleNumber',
        title: '销售数量',
    },
    {
        key: 'saleAmount',
        title: '销售金额',
    },
    {
        key: 'saleCost',
        title: '销售成本',
    },
    {
        key: 'saleGrossProfit',
        title: '销售毛利',
    },
];