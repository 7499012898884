import { BaseApiCon } from "./BaseApiCon";

/**
 * 微信店铺api控制器
 */
export class WxShopApiCon extends BaseApiCon {
    /** 获取当前微信小程序授权配置信息 */
    static GetUserWxSetting(): Promise<any> {
        return this.request(this.byNameGetURl('WxShop.GetUserWxSetting'), 'GET');
    }
    /**获取需要申请的接口授权列表 */
    static GetApplyInterface(): Promise<any>{
        return this.request(this.byNameGetURl('WxShop.QueryApplyPrivacyInterface'),'GET')
    }
    /**提交授权 */
    static ApplyInterface(): Promise<any> {
        return this.request(this.byNameGetURl('WxShop.ApplyPrivacyInterface'),'GET')
    }
    /** 获取商家当月剩余 提审次数和加急次数 */
    static Queryquota(): Promise<any> {
        return this.request(this.byNameGetURl('WxShop.Queryquota'), 'GET');
    }
    /** 获取体验者列表 */
    static Querymemberauth(): Promise<any> {
        return this.request(this.byNameGetURl('WxShop.Querymemberauth'), 'GET');
    }
    /** 绑定体验者 */
    static bind_tester(wechatid: string): Promise<any> {
        return this.request(this.byNameGetURl('WxShop.bind_tester') + `?wechatid=${wechatid}`, 'GET');
    }
    /** 解除绑定者 */
    static unbind_tester(wechatid: string): Promise<any> {
        return this.request(this.byNameGetURl('WxShop.unbind_tester') + `?wechatid=${wechatid}`, 'GET');
    }
    /** 获取体验版二维码 */
    static GetexperienceCode(): Promise<any> {
        return fetch(this.byNameGetURl('WxShop.GetexperienceCode'), {
            headers: {
                SYSTOKEN: this.token,
            },
        }).then(data => {
            return data.blob();
        });
    }
    /** 获取商家最近的一次小程序代码提交记录 */
    static GetShopCommitemplate(): Promise<any> {
        return this.request(this.byNameGetURl('WxShop.GetShopCommitemplate'), 'GET');
    }
    /** 商家提交小程序模版 */
    static Committemplate(op: {
        template_id: string;
        user_version: string;
        user_desc: string;
    }): Promise<any> {
        return this.request(this.byNameGetURl('WxShop.Committemplate'), 'POST', op);
    }
    /** 商家提交小程序审核 普通审核 */
    static Submit_audit(): Promise<any> {
        return this.request(this.byNameGetURl('WxShop.Submit_audit'), 'GET');
    }
    /** 商家提交小程序审核 加急审核 */
    static Submit_Speedupaudit(): Promise<any> {
        return this.request(this.byNameGetURl('WxShop.Submit_Speedupaudit'), 'GET');
    }
    /** 审核撤回 */
    static Undocodeaudit(): Promise<any> {
        return this.request(this.byNameGetURl('WxShop.Undocodeaudit'), 'GET');
    }
    /** 发布已审核通过的小程序 */
    static ReleaseVersion(): Promise<any> {
        return this.request(this.byNameGetURl('WxShop.ReleaseVersion'), 'GET');
    }
}