import { createContext, useContext, useReducer } from "react";
import { auditStatus, sendStatus } from '../../enum'
interface IContextProps {
    state: IState,
    dispatch: ({type} : Dispatch) => void
}
export const  PurchaseStore = createContext<IContextProps>(null);

type Dispatch = {type, payload}
class Search{
    auditStatus?: auditStatus
    sendStatus?: sendStatus
    purchaseNumber?: string
    purchaseTitle?: string
    posShopID?: string
}
interface IState{
 search?: Search,
 auditLoading: boolean
 msg?: string
 recieptList?: any[]
}
const initState: IState = {
    search: new Search(),
    auditLoading: false,
    msg: '',
    recieptList: []
}
function reducer(state:IState, {type, payload}: Dispatch){
    switch (type) {
        case "SetLoading":{
         state.msg = payload.msg
            state.auditLoading = payload.loading
            return{...state}
        }
        case "SetSearch":
            return {
                ...state,
                search: {
                    ...state.search,
                    ...payload
                }
            }
        case "SetSearchList":
            return {
                ...state,
                searchList: payload
            }
        case "SetRecieptList": 
            return{
                ...state,
                recieptList: payload
            }
        
        default:
            return state;
    }
}
export function PurchaseStoreProdiver(props) {
    const [state,dispatch] = useReducer(reducer, initState)
    const v = { state, dispatch }
    return (
        <PurchaseStore.Provider value={v}>
            {props.children}
        </PurchaseStore.Provider>
    )
}
