import styles from "../../../style/applet/onAu.module.scss";
import { Image, message, Table, Modal, Spin } from "antd";
import { useEffect, useState } from "react";
import { BaseApiCon } from "../logic/BaseApiCon";
import { ColumnsType } from "antd/lib/table";
import { DataTable } from "../../../../../component/table";
import { useBoolean } from "ahooks";
import { WxShopApiCon } from "../logic/WxShopApiCon";

/**
 * 小程序已授权
 */
export function OnAuthorize({ data, disabled }) {
  const [visible, visibleFunc] = useBoolean(false);
  const [loading, loadingFunc] = useBoolean(false);
  const [applyList, setApplyList] = useState([]);

  const handleCancel = () => {
    visibleFunc.setFalse();
  };
  const handleOk = () => {
    if (loading || disabled) {
    } else {
      loadingFunc.setTrue();
      WxShopApiCon.ApplyInterface()
        .then((res) => {})
        .catch((err) => {
          message.error(err.returnMessage);
        })
        .finally(() => {
          loadingFunc.setFalse();
          visibleFunc.setFalse();
        });
    }
  };
  const getList = () => {
    WxShopApiCon.GetApplyInterface()
      .then((res) => {
        setApplyList(res);
        visibleFunc.setTrue();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };
  if (!data) {
    return <div></div>;
  }
  return (
    <div className={styles.root}>
      <table>
        <thead>
          <tr>
            <th>基本信息</th>
            <th></th>
            <th>说明</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>小程序名称</td>
            <td>
              <div className={styles.img}>
                <Image
                  width={30}
                  height={30}
                  src={BaseApiCon.getImageURL(data.savehead_img)}
                  alt=""
                />
                <span>{data.nick_name}</span>
              </div>
            </td>
            <td>小程序发布后，非个人账户可通过认证方式改名</td>
            <td></td>
          </tr>
          <tr>
            <td>小程序ID</td>
            <td>{data.id}</td>
            <td>每个小程序特有的ID号</td>
            <td></td>
          </tr>
          <tr>
            <td>小程序码</td>
            <td>
              <Image
                width={100}
                height={100}
                src={BaseApiCon.getImageURL(data.saveqrcode_url)}
                alt=""
              />
            </td>
            <td>只可访问线上版本小程序</td>
            <td></td>
          </tr>
          <tr>
            <td>小程序介绍</td>
            <td>{data.signature}</td>
            <td>一个月内可申请5次修改</td>
            <td></td>
          </tr>
          <tr>
            <td>主体名称</td>
            <td>{data.principal_name}</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>版本号</td>
            <td>{data.user_version || "-"}</td>
            <td>当前线上版本</td>
            <td></td>
          </tr>
          <tr>
            <td>授权申请</td>
            <td></td>
            <td>小程序正常使用需要的微信权限</td>
            <td
              onClick={() => getList()}
              style={{ cursor: "pointer", color: "#ffc80b" }}
            >
              点击查看
            </td>
          </tr>
        </tbody>
      </table>
      {visible && (
        <ApplyTable
          list={applyList}
          visibleModal={visible}
          handleCancel={handleCancel}
          handleOk={handleOk}
          loading={loading}
        />
      )}
    </div>
  );
}
function ApplyTable({ list, visibleModal, handleOk, handleCancel, loading }) {
  const columns: ColumnsType = [
    {
      title: "接口描述",
      key: 1,
      dataIndex: "api_desc",
      align: "center",
    },
    {
      title: "接口状态",
      key: 2,
      dataIndex: "status",
      align: "center",
      // 接口状态(1 未开通 2 无权限 3 申请中 4 申请失败 5 已开通
      render: (v) => (
        <span>
          {v == 1
            ? "未开通"
            : v == 2
            ? "无权限"
            : v == 3
            ? "申请中"
            : v == 4
            ? "申请失败"
            : "已开通"}
        </span>
      ),
    },
    {
      title: "驳回原因",
      key: 3,
      dataIndex: "fail_reason",
      align: "center",
    },
    {
      title: "申请时间",
      key: 4,
      dataIndex: "apply_time",
      align: "center",
    },
  ];

  return (
    <>
      <Modal
        destroyOnClose={true}
        title="授权列表"
        visible={visibleModal}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
        cancelText="取消"
        okText="申请"
      >
        {" "}
        <Spin
          tip={"提交申请中..."}
          spinning={loading}
          size="large"
          wrapperClassName="isloading"
        >
          <Table columns={columns} dataSource={list} />
        </Spin>
      </Modal>
    </>
  );
}
