import { message } from "antd";
// import axios from "axios";
import Cookies from "js-cookie";
import { DataTableAction } from "../../../component/table/action/data_table_action";
import { Tools, axios } from "../../../config";
import {UpdatePriceLogic} from '../../../logic/goods_updateprice'
import {GoodsLogic} from '../../../logic/goods_logic'
import {ShopLogic} from '../../../logic/shop_logic'
import * as _ from 'lodash'
export class FileTableAction extends DataTableAction {

    async init({ dispatch,id,form,method }) {
        this.clearTableList({dispatch})
        let { shopID, shopName } = JSON.parse(Cookies.get('loginMsg'))
        this.setFormData({ dispatch, key: "shopID", value: [shopID] });
        this.setFormData({
          dispatch,
          key: "shopName",
          value: [shopName],
        });
        if(method != 'Create'){
                this.initEdit({dispatch,id})
        }
    }
    async initEdit({dispatch,id}){
        // let item = await new UpdatePriceLogic().selData(id)
       
        // let shoplist = await (await new ShopLogic().selListpost()).data.data.dataSource || []
        // dispatch({type: 'LoadShop',payload: shoplist})
        // let shop;
        // if(item){
        //    shop =  shoplist.filter(shop=>shop.id == item.shopID)
        //    if(shop.length>0){
        //     item['shopName'] = shop.map(sh=>sh.shopName) || []
        //     item['shopID'] = shop.map(sh=>sh.id) || []
        //    }
        //  if(item.detals instanceof Array){
        //     item['goodsSep'] = item.detals || []
        //  }
        //  item.remark = item.remark?item.remark:''
        // dispatch({type:'SetForm',payload: item})

        // }
        // 结果查看
        let params = {
            ID: id
        }
        let item = await new UpdatePriceLogic().getResultList(params)
        if(item){
            if(item.data.statusCode == 200){
               let list = item.data.data || []
               dispatch({type:'SetResultList',payload: list})
            }else{
                message.error(item.data.returnMessage)
            }
        }
    }
    async delRow(id) {
        const r = await axios({
            method: "GET",
            url: Tools.getApiAddress("goods_updateprice_del"),
            params: {
                ID: id
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })

        if (r.data.statusCode == 200) {
            message.success("删除成功")
        }else{
            message.error(r.data.returnMessage || '删除失败')
        }
    }
    async delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {

        
    }
    async loadPage({ dispatch, pageIndex, pageSize, search }) {
        this.setLoading({dispatch, loading:true})
    

        const { keywords, beginTime, endTime,  goodsID } = search;

        const params = {
            pageIndex, pageSize, beginTime, endTime,likeGoodsName:keywords,goodsID: goodsID
        }
       
        
        const r = await new UpdatePriceLogic().querysList(params)
        if (r.data.statusCode == 200 && r.data.data) {
            const dataList = r.data.data.dataSource || [];         

            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: dataList, pageSize, loading: false });
        } else{
            this.setLoading({dispatch, loading: false})
        }

    }
    setCondition({ dispatch, key, value }) {
        const params = {
        }
        params[key] = value;

        dispatch({ type: "SetSearch", payload: params })
    }
  

    // 表单
    addTableItem({ dispatch, list }) {
        list.forEach(async v => {
            // console.log(v)
            //获取商品规格信息
            // v.oldShopPrice = v.shopPrice || v.oldShopPrice || 0;
            // v.oldStorePrice = v.storePrice || v.oldStorePrice || 0
            // v['goodsID'] = v.id
            // v['goodsSepID'] = v.specificationID
            v['goodsUnitName'] = v.unitName
            dispatch({ type: "AddTableItem", payload: v });
        })
    }
    changeTableItem({ dispatch, key, value, index }) {
        dispatch({ type: "ChangeTableItem", payload: { key, value, index } });
    }
    delTableItem(dispatch, index) {
        dispatch({ type: "DelTableItem", payload: index });
    }
    clearTableList({ dispatch }) {
        dispatch({ type: "ClearTableList" });
    }
    setFormData({ dispatch, key, value }) {
        const params = {
        }
        params[key] = value;

        dispatch({ type: "SetFormData", payload: params })
    }

    confirmData(state){
        let flag = true
        const {formData, tableList} = state
        if(tableList.length == 0 ){
            flag = false
            message.error('请选择商品')
        }else{
            for(let i =0;i<tableList.length;i++){
                let item = tableList[i]
                if(item.isShop){
                    if(item.newShopPrice == undefined || item.newShopPrice == null || item.newShopPrice == ''){
                        flag = false
                        message.error(`第${i+1}个商品门店价格不能为空`)
                        break;
                    }else if(item.newShopPrice <= 0){
                        flag = false
                        message.error(`第${i+1}个商品门店价格应该大于0`)
                        break
                    }
                }else{
                    if(item.newShopPrice){}else{ item.newShopPrice = 0}
                }
                if(item.isStore){
                    if(item.newStorePrice == undefined || item.newStorePrice == null || item.newStorePrice == ''){
                        flag = false
                        message.error(`第${i+1}个商品商城价格不能为空`)
                        break;
                    }else if(item.newStorePrice <= 0){
                        flag = false
                        message.error(`第${i+1}个商品商城价格应该大于0`)
                        break
                    }
                }else{
                    if(item.newStorePrice){}else{ item.newStorePrice = 0}
                }
                
                // if(item.newShopPrice == undefined || item.newShopPrice == null || item.newStorePrice == undefined || item.newStorePrice == null){
                //     flag = false
                //     message.error(`第${i+1}个商品价格不能为空`)
                //     break;
                // }else  if(item.newShopPrice <= 0 || item.newStorePrice <=0 ){
                //     flag = false
                //     message.error(`第${i+1}个商品价格应该大于0`)
                //     break
                // }
            }
            tableList.map((item,index)=>{
                // console.log(item)
                
            })
        }
        return flag
    }
    paddingData(data){
        let arr = []
        data.map(item=>{       
            arr.push({
                // goodsID:item.goodsID,
                // goodsSepID:item.goodsSepID,
                // oldShopPrice: item.oldShopPrice,
                // oldStorePrice: item.oldStorePrice,
                barCode: item.barCode,
                newShopPrice: item.newShopPrice,
                newStorePrice: item.newStorePrice,
                goodsSource: item.goodsSource
            })
        })
        return arr
    }
  async  addSubmit({state}){
        let goodsSep = []
        const { formData } = state
       if(this.confirmData(state)) {
            goodsSep = this.paddingData(state.tableList)
       }else{
           return false
       }
       const params = {
        shopID: formData.shopID,
        isIssued: formData.isIssued,
        remark:formData.remark,
        goodsSep: goodsSep
       }
       const r = await new UpdatePriceLogic().addData(params)
       console.log(r)
       if(r.data.statusCode == 200){
        message.success('添加成功')

           return true
       }else{
           message.error(r.data.returnMessage || '添加失败')
           return false
       }
    }
    reset({dispatch,form}){
            this.clearTableList({dispatch})
            this.setFormData({
                dispatch,
                key: "remark",
                value: '',
              });
    }

    async getGoodsByBarcode ({barCode,dispatch,state}) {
        let params = {
            BarCode: barCode
        }
         const r = await new GoodsLogic().getGoodsInfoByBarCode(params)
        if(r.data.statusCode == 200){
            if(r.data.data){
                let v = r.data.data
                // v['goodsID'] = v.id
                // v['goodsSepID'] = v.specificationID
                // v.oldShopPrice = v.shopPrice || v.oldShopPrice || 0;
                // v.oldStorePrice = v.storePrice || v.oldStorePrice || 0
                dispatch({ type: "AddTableItem", payload: v });
            }else{
                message.error('暂无商品信息')
            }
           
        }else{
            message.error(r.data.returnMessage)
        }
    }

  
}
export const fileAction = new FileTableAction();