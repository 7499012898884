import { createContext, useContext, useReducer } from "react";

export const AlarmSetStore = createContext<IContextProps>(null);

interface IContextProps {
    state: IState;
    dispatch: ({ type }: Dispatch) => void;
}

type Dispatch = { type, payload }

class Search {
    keyWords: string;
    goodsClass: string[]
    goodsClassesNames: string
};

interface IState {
    searchList: any[];
    search: Search;
    classesList: []
}

const initialArgs: IState = {
    searchList: [],
    search: new Search(),
    classesList: []
}

function reducer(state: IState, { type, payload }: Dispatch) {
    switch (type) {
        case "LoadClasses":
            return {
              ...state,
              classesList: payload,
            };
        case "SetSearch":
            return {
                ...state,
                search: {
                    ...state.search,
                    ...payload
                }
            }
        case "SetSearchList":
            return {
                ...state,
                searchList: payload
            }
        default:
            return state;
    }
}

export function AlarmSetStoreProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialArgs);

    const v = { state, dispatch };
    return <AlarmSetStore.Provider value={v}>{props.children}</AlarmSetStore.Provider>
}