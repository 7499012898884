import { message } from "antd";
import { createContext, useReducer } from "react";
import * as _ from 'lodash'
interface IContextProps {
    state: IState;
    dispatch: ({ type }: Dispatch) => void;
  }
  type Dispatch = { type; payload };
export const AlarmSetFormStore = createContext<IContextProps>(null);

export class TableItem{
    shopID: string
    goodsID: string
    goodsName: string
    goodsNo: string
    goodsType: number
    goodsClassName: string
    unitName: string
    goodsSource: number = 1 | 2
    maxNumber: number = undefined //上限
    minNumber: number = undefined //下限
    constructor(data) {
      {
        const {id,goodsName,goodsNo,goodsType,goodsClassName,unitName, goodsSource} = data
        this.goodsID = id
        this.goodsName = goodsName
        this.goodsNo = goodsNo
        this.goodsType = goodsType
        this.goodsClassName = goodsClassName
        this.unitName = unitName
        this.goodsSource = goodsSource
      }}
}
export class FormData{
    shopID: string
    shopName: string
}
export interface IState{
    formData: FormData,
    tableList: TableItem[]
}
const initialArgs: IState = {
    formData: new FormData(),
    tableList: [],
  };
  function reducer(state: IState, { type, payload }: Dispatch): IState {
    switch (type) {
        case "SetFormData": {
          return {
            ...state,
            formData: {
              ...state.formData,
              ...payload,
            },
          };
        }
        case "AddTableItem": {
            const list = state.tableList || [];
            // 去重
            if (list.every((v, i) => v.goodsID !== payload.goodsID)) {
              list.push(payload);
              list.length > 0 && list.forEach((item,i)=>{
                item['index'] = i+1
              })
              return {
                ...state,
                tableList: [...list],
              };
            }
            return state;
          }
          case "ChangeTableItem": {
            const { id, key, value } = payload;
            const list = state.tableList || [];
            let index = _.findIndex(list, function(o){return o.goodsID == id})
            if(index == -1){
              return state
            }
            const item = list[index];
            let num = 0
            if(isNaN(value)){
                item[key] = ''
            }else{
               item[key] = Number(value)
            }
           
            return {
                ...state,
                tableList: [...list],
              };
          }
        case "DelTableItem": {
            const list = state.tableList || [];
            let index = _.findIndex(list, function(o){return o.goodsID == payload})
            if(index == -1){
              return state
            }
            list.splice(index, 1);
            list.length > 0 && list.forEach((item,i)=>{
                item['index'] = i+1
              })
            return {
              ...state,
              tableList: [...list],
            };
          }
        case "ClearTableList":
            return {
              ...state,
              tableList: [],
            };
          default:
            return state;
    }}
    export function AlarmSetFormStoreProvider(props) {
        const [state, dispatch] = useReducer(reducer, initialArgs);
      
        const v = { state, dispatch };
        return (
          <AlarmSetFormStore.Provider value={v}>{props.children}</AlarmSetFormStore.Provider>
        );
      }
      
