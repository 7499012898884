import React, { useContext, useEffect } from "react";
import { Button } from "../../../../component/button";
import { Layer } from "../../../../component/layer/layer";
import { Form as FormAntd } from "antd";
import { formAction, FormAction } from "../../action/menu/form_action";
import { FormStore } from "../../store/menu/form_store";
import { FormBasic } from "./menu_form_basic";
import '../../style/index.scss';

import { FileStore } from "../../store/menu/file_store";
import { fileAction } from "../../action/menu/file_action";
import { DataTableStore } from "../../../../component/table/store/data_table_store";
import { defualtPageSize } from "../../../../logic/common_logic";
interface IForm {
    children?: any;
    action: FormAction;
    close: (hasUpdate?: boolean) => void;
    editId?: string;
    seeId?: string;
}
export function Form({ close, action, editId, seeId }: IForm) {
    const [form] = FormAntd.useForm();
    const { state, dispatch: formDispatch } = useContext(FormStore);

    const { state: fileState, dispatch: fileDispatch } = useContext(FileStore);
    const { state: tableState, dispatch: tableDispatch } = useContext(DataTableStore);
    useEffect(() => {
        formAction.init({ state: state, dispatch: formDispatch, editId: editId, seeId, form });
        return () => {

        }
    }, []);

    function title() {
        if (editId) {
            return "编辑菜单";
        }
        else if (seeId) {
            return "查看菜单";
        } else {
            return "新建菜单";
        }
    }
    const onSearch = async (pageIndex) => {
        await fileAction.loadPage({ pageIndex: pageIndex, pageSize: defualtPageSize(), dispatch: tableDispatch, search: fileState.search })
    }
    return (
        <FormAntd form={form}>
            <Layer layerWith={2} >
                <Layer.Title onClick={() => close()}>{title()}</Layer.Title>
                <Layer.Body>
                    <FormBasic title={title()} />
                </Layer.Body>
                {
                    title() != "查看菜单" && (
                        <>
                            <Layer.Foot>
                                <Button type="primary" color="Red" style={{ marginRight: "1.25rem" }} onClick={async () => {
                                    const r = await form.validateFields();
                                    let status = false;
                                    if (editId) {
                                        status = await action.updSubmit({ state: state.formData });
                                    } else {
                                        status = await action.addSubmit({ state: state.formData });
                                    }
                                    status && (close && close(true))
                                }}>
                                    保存
                                </Button>
                                <Button type="primary" color="Yellow" onClick={() => action.reset({ dispatch: formDispatch, form })}>
                                    重置
                                </Button>
                            </Layer.Foot>
                        </>
                    )
                }
                {
                    title() == "查看菜单" && (
                        <>
                            <Layer.Foot>
                            </Layer.Foot>
                        </>
                    )
                }
            </Layer>
        </FormAntd>
    )
}