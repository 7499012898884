import { message } from "antd";
// import axios from "axios";
import Cookies from "js-cookie";
import { DataTableAction } from "../../../../component/table/action/data_table_action";
import { Tools, axios } from "../../../../config";
import { ClearDataLogic } from "../../../../logic/clear_data";

export class FileAction extends DataTableAction {
    async delRow({ dispatch, rowItem }) {
        const r = await axios({
            method: "GET",
            url: Tools.getApiAddress("dict_delete"),
            params: {
                id: rowItem.id
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })

        if (r.data.statusCode == 200) {
            message.success("删除成功")
        } else {
            message.error(r.data.returnMessage)
        }
    }
    delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {
        selectList.map(async ({ id }) => {
            const r = await axios({
                method: "GET",
                url: Tools.getApiAddress("dict_delete"),
                params: {
                    id: id
                },
                headers: {
                    SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
                },
            })

            if (r.data.statusCode == 200) {
                message.success("删除成功")
            } else {
                message.error(r.data.returnMessage)
            }
        })
    }
    async loadPage({ dispatch, pageIndex, pageSize, search }) {
        const { keywords } = search;
        let param = {
            queryText: keywords,
            pageIndex: pageIndex,
            pageSize: pageSize,
            orderBy: '',
            sort: ''

        }
        // 字典列表
        const r = await new ClearDataLogic().queryPage();

        if (r.data.statusCode == 200) {
            const dataList = r.data.data.dataSource;

            const list = dataList.map((v, i) => {
                v.key = i + 1;
                return v;
            })

            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: list, pageSize });
        }

    }
    setCondition(method: "Keywords", value, dispatch) {

        if ("Keywords" == method) {
            dispatch({ type: "SetSearch", payload: { keywords: value } });
        }
    }

    async init({dispatch}){
        const r = await new ClearDataLogic().queryPage();
        if(r&&r.data&&r.data.statusCode == 200){
            // let arr = []
            // r.data.data.map(item=>{
            //     item.list.length&&item.list.map(l=>{
                    
            //     })
            // })
            dispatch({type:'Init',payload:r.data.data})
            // return r.data.data
        }
        // else{
        //     message.error('请求失败')
        // }
        }
 
    setIds({dispatch, selected, value}){
       
        dispatch({type: 'ModleList', payload: {selected, value}})
    }
    async clear({list, password}){
        const r = await new ClearDataLogic().clearModle({list, password})
        if(r&&r.data&&r.data.statusCode == 200 ){
            message.success('清除成功')
        }else{
            message.error(r.data.returnMessage || '清除失败')
        }

    }
}
export const fileAction = new FileAction();