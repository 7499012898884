import {
  Cascader,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Form as FormAntd,
  Input,
  message,
  Radio,
  Row,
  Select,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { RadioStyle } from "../../../../component/radio-style";
import {
  Tab,
  TabList,
  TabPanel,
  TabsStyle,
} from "../../../../component/react-tabs";
import { autoRem } from "../../../../global.style";
import { formAction } from "../../action/list/list_form_action";
import { ListFormStore } from "../../store/list/list_form_store";
const { Option } = Select;
function Type({ value, swtichValue, isLook }) {
  const { state, dispatch } = useContext(ListFormStore);
  const [] = useState(1);

  const onChange = (e) => {
    if (!isLook) {
      formAction.setValue({
        dispatch,
        value: e.target.value,
        attribute: "Sex",
      });
    }
  };
  return (
    <RadioStyle>
      <Radio.Group onChange={onChange} value={swtichValue}>
        <Radio value={"男"}>男</Radio>
        <Radio value={"女"}>女</Radio>
        <Radio value={"未知"}>未知</Radio>
      </Radio.Group>
    </RadioStyle>
  );
}
interface IForm {
  editId?: string;
  seeId?: string;
  passwordId?: string;
  title?: any;
  isdefault?: boolean;
}
export function ListFormBasic({
  editId,
  seeId,
  passwordId,
  title,
  isdefault,
}: IForm) {
  const { state, dispatch: formDispatch } = useContext(ListFormStore);
  const action = formAction;
  const { formData, roletList, commissionRuleList } = state;
  var isLook = false;
  if (title == "查看用户") {
    isLook = true;
  } else {
    isLook = false;
  }
  // function title() {
  //     if (editId) {
  //         return "编辑用户";
  //     }
  //     else if (seeId) {
  //         isLook = true
  //         return "查看用户";

  //     }
  //     else if (passwordId) {
  //         return "重置密码";
  //     }
  //     else {
  //         return "新建用户";
  //     }
  // }
  return (
    passwordId && (
      <>
        <TabsStyle style="Capsule">
          <TabPanel>
            <div
              style={{
                margin: "" + autoRem(1.9375) + " " + autoRem(1.25) + " 0 0",
              }}
            >
              <Row gutter={16}>
                <Col span={11}>
                  <FormAntd.Item
                    label="新密码"
                    labelCol={{ span: 8 }}
                    rules={[
                      {
                        required: true,
                        message: "请输入新密码",
                      },
                    ]}
                  >
                    <Input
                      disabled={isLook}
                      maxLength={20}
                      autoComplete="off"
                      placeholder="请输入新密码"
                      onChange={(v) =>
                        action.setValue({
                          dispatch: formDispatch,
                          value: v.target.value,
                          attribute: "NewPassword",
                        })
                      }
                    />
                  </FormAntd.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={11}>
                  <FormAntd.Item
                    label="确认密码"
                    labelCol={{ span: 8 }}
                    rules={[
                      {
                        required: true,
                        message: "请输入确认密码",
                      },
                    ]}
                  >
                    <Input
                      disabled={isLook}
                      maxLength={20}
                      autoComplete="off"
                      placeholder="请输入确认密码"
                      onChange={(v) =>
                        action.setValue({
                          dispatch: formDispatch,
                          value: v.target.value,
                          attribute: "ConfirmPassword",
                        })
                      }
                    />
                  </FormAntd.Item>
                </Col>
              </Row>
            </div>
          </TabPanel>
        </TabsStyle>
      </>
    ),
    !passwordId && (
      <>
        <TabsStyle style="Capsule">
          <TabPanel>
            <div style={{ marginTop: "1.9375rem" }}>
              <Row gutter={16}>
                <Col span={11}>
                  <FormAntd.Item
                    name="user-code"
                    label="用户编号"
                    labelCol={{ span: 8 }}
                    rules={[
                      {
                        // required: true,
                        // message: '请输入用户编号',
                      },
                    ]}
                  >
                    <Input
                      disabled={true}
                      maxLength={20}
                      autoComplete="off"
                      placeholder="请输入用户编号"
                    />
                  </FormAntd.Item>
                </Col>
                <Col span={6}>
                  <FormAntd.Item
                    name="user-enabled"
                    label=""
                    labelCol={{ span: 8 }}
                    style={{ marginLeft: "80px" }}
                  >
                    <Checkbox
                      disabled={isdefault ? isdefault : isLook}
                      checked={formData.enabled}
                      style={{ marginLeft: "11px" }}
                      onChange={(v) =>
                        action.setValue({
                          dispatch: formDispatch,
                          value: v.target.checked,
                          attribute: "Enabled",
                        })
                      }
                    >
                      启用
                    </Checkbox>
                  </FormAntd.Item>
                </Col>
                <Col span={6}>
                  <FormAntd.Item
                    name="storage-number"
                    label=""
                    labelCol={{ span: 8 }}
                  >
                    <Checkbox
                      disabled={isLook}
                      checked={formData.oneSession}
                      style={{ marginLeft: "11px" }}
                      onChange={(v) =>
                        action.setValue({
                          dispatch: formDispatch,
                          value: v.target.checked,
                          attribute: "OneSession",
                        })
                      }
                    >
                      单用户
                    </Checkbox>
                  </FormAntd.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={11}>
                  <FormAntd.Item
                    name="user-name"
                    label="用户名称"
                    labelCol={{ span: 8 }}
                    rules={[
                      {
                        required: true,
                        message: "请输入用户名称",
                      },
                    ]}
                  >
                    <Input
                      disabled={isLook}
                      maxLength={20}
                      autoComplete="off"
                      placeholder="请输入用户名称"
                      onChange={(v) =>
                        action.setValue({
                          dispatch: formDispatch,
                          value: v.target.value,
                          attribute: "UserName",
                        })
                      }
                    />
                  </FormAntd.Item>
                </Col>

                <Col span={11}>
                  <FormAntd.Item
                    name="user-password"
                    label="用户密码"
                    labelCol={{ span: 8 }}
                    rules={[
                      {
                        required: true,
                        message: "请输入用户密码",
                      },
                    ]}
                  >
                    <Input.Password
                      disabled={isLook}
                      maxLength={20}
                      autoComplete="off"
                      placeholder="请输入用户密码"
                      onChange={(v) =>
                        action.setValue({
                          dispatch: formDispatch,
                          value: v.target.value,
                          attribute: "Password",
                        })
                      }
                    />
                  </FormAntd.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={11}>
                  <FormAntd.Item
                    name="user-roleID"
                    label="用户角色"
                    labelCol={{ span: 8 }}
                    rules={[
                      {
                        required: true,
                        message: "请选择用户角色",
                      },
                    ]}
                  >
                    <Select
                      disabled={isLook}
                      onChange={(v) => {
                        action.setValue({
                          dispatch: formDispatch,
                          value: v,
                          attribute: "RoleID",
                        });
                      }}
                    >
                      {roletList.map((k) => (
                        <Option key={k.id} value={k.id}>
                          {k.name}
                        </Option>
                      ))}
                    </Select>
                  </FormAntd.Item>
                </Col>
                <Col span={11}>
                  <FormAntd.Item
                    name="user-phone"
                    label="手机号码"
                    labelCol={{ span: 8 }}
                    rules={[
                      {
                        required: true,
                        message: "请输入手机号码",
                      },
                      {
                        pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/,
                        message: "手机号码格式不正确",
                      },
                    ]}
                  >
                    <Input
                      disabled={isLook}
                      maxLength={11}
                      autoComplete="off"
                      placeholder="请输入手机号码"
                      onChange={(v) => {
                        action.setValue({
                          dispatch: formDispatch,
                          value: v.target.value,
                          attribute: "Phone",
                        });
                        action.setValue({
                          dispatch: formDispatch,
                          value: v.target.value,
                          attribute: "UserID",
                        });
                      }}
                    />
                  </FormAntd.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={11}>
                  <FormAntd.Item
                    name="user-qq"
                    label="QQ"
                    labelCol={{ span: 8 }}
                  >
                    <Input
                      disabled={isLook}
                      maxLength={20}
                      autoComplete="off"
                      placeholder="请输入QQ"
                      onChange={(v) =>
                        action.setValue({
                          dispatch: formDispatch,
                          value: v.target.value,
                          attribute: "QQ",
                        })
                      }
                    />
                  </FormAntd.Item>
                </Col>
                <Col span={11}>
                  <FormAntd.Item
                    name="user-email"
                    label="邮箱"
                    labelCol={{ span: 8 }}
                  >
                    <Input
                      disabled={isLook}
                      autoComplete="off"
                      maxLength={60}
                      placeholder="请输入邮箱"
                      onChange={(v) =>
                        action.setValue({
                          dispatch: formDispatch,
                          value: v.target.value,
                          attribute: "Email",
                        })
                      }
                    />
                  </FormAntd.Item>
                </Col>
              </Row>
              <Row gutter={16}>
              <Col span={11}>
                  <FormAntd.Item
                    label="性别"
                    labelCol={{ span: 8 }}
                    className={"custom-radio"}
                    rules={[
                      {
                        required: true,
                        message: "请选择性别",
                      },
                    ]}
                  >
                    <Type
                      value={"未知"}
                      swtichValue={formData.sex ? formData.sex : "未知"}
                      isLook={isLook}
                    />
                  </FormAntd.Item>
                </Col>
                
                <Col span={11}>
                  <FormAntd.Item
                    name="user-identityCard"
                    label="身份证号"
                    labelCol={{ span: 8 }}
                    rules={[
                      {
                        pattern:
                          /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$|^([1-6][1-9]|50)\d{4}\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}$/,
                        message: "请输入正确的身份证号码",
                      },
                    ]}
                  >
                    <Input
                      disabled={isLook}
                      autoComplete="off"
                      maxLength={20}
                      placeholder="请输入身份证号"
                      onChange={(v) =>
                        action.setValue({
                          dispatch: formDispatch,
                          value: v.target.value,
                          attribute: "IdentityCard",
                        })
                      }
                    />
                  </FormAntd.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={11}>
                  <FormAntd.Item label="生日" labelCol={{ span: 8 }}>
                    <DatePicker
                      disabled={isLook}
                      value={
                        formData.birthday == null ||
                        formData.birthday == undefined ||
                        formData.birthday == ""
                          ? undefined
                          : moment(formData.birthday.slice(0, 10), "YYYY-MM-DD")
                      }
                      placeholder="请选择生日"
                      onChange={(date, dateString) =>
                        action.setValue({
                          dispatch: formDispatch,
                          value: dateString,
                          attribute: "Birthday",
                        })
                      }
                    />
                  </FormAntd.Item>
                </Col>
                <Col span={11}>
                  <FormAntd.Item
                    name="address"
                    label="地址"
                    labelCol={{ span: 8 }}
                  >
                    <Input
                      disabled={isLook}
                      maxLength={200}
                      autoComplete="off"
                      placeholder="请输入地址"
                      onChange={(v) =>
                        action.setValue({
                          dispatch: formDispatch,
                          value: v.target.value,
                          attribute: "Address",
                        })
                      }
                    />
                  </FormAntd.Item>
                </Col>
              </Row>
              <Row gutter={16}>
              <Col span={11}>
                  <FormAntd.Item
                    name="commissionRuleID"
                    label="提成规则"
                    labelCol={{ span: 8 }}
                  >
                    <Select allowClear
                      disabled={isLook}
                      onChange={(v) => {
                        action.setValue({
                          dispatch: formDispatch,
                          value: v,
                          attribute: "commissionRuleID",
                        });
                      }}
                    >
                      {commissionRuleList.map((k) => (
                        <Option key={k.id} value={k.id}>
                          {k.ruleName}
                        </Option>
                      ))}
                    </Select>
                  </FormAntd.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={22}>
                  <Form.Item
                    name="remarks"
                    label="备 注"
                    labelCol={{ span: 4 }}
                  >
                    <TextArea
                      disabled={isLook}
                      maxLength={200}
                      rows={2}
                      onChange={(v) =>
                        action.setValue({
                          dispatch: formDispatch,
                          value: v.target.value,
                          attribute: "Remark",
                        })
                      }
                    ></TextArea>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </TabPanel>
        </TabsStyle>
      </>
    )
  );
}

function dateFormat(arg0: string, dateFormat: any): moment.Moment {
  throw new Error("Function not implemented.");
}
