// 审核状态
export const AuditStatus = { 
    1: {
      label:'待提审',
      tagClass:'default'
    },
    2: {
      label: '审核中',
      tagClass: 'processing'
    },
    3: {
      label: '审核不通过',
      tagClass: 'error'
    },
    4: {
      label: '审核通过',
      tagClass:'success'
    }
  }
  // 发货状态
  export const SendStatus = { 
    1: {
      label: '待发货',
      tagClass:'processing'
    },
    2: {
      label: '部分发货',
      tagClass: 'warning'
    },
    3: {
      label: '发货完成',
      tagClass: 'success'
    },
    4:{
      label: '待发货和部分发货',
      tagClass: 'info'
    }
  }
  // 售后状态
export const SalesStatus = {
  1: {
      label:'处理中',
      listClass:'processing'
  },
  2: {
      label:'已关闭',
      listClass:'warning'
  },
  3: {
      label:'处理完成',
      listClass:'success'
  },
}
// 售后类型
export const AfterSalesType = {
  1: {
    label:'仅退款',
    listClass:'cyan'
},
2: {
    label:'仅退货',
    listClass:'blue'
},
3: {
    label:'退货退款',
    listClass:'orange'
},
}
  export type sendStatus = 1 | 2 | 3
  export type auditStatus = 1 | 2 | 3 | 4