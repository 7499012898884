import { message } from "antd";
// import axios from "axios";
import Cookies from "js-cookie";
import { DataTableAction } from "../../../../component/table/action/data_table_action";
import { Tools, axios } from "../../../../config";
import { OnlineLogic } from "../../../../logic/online_logic";

export class FileAction extends DataTableAction {
    async delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {
        let sessionID = []
        for(let i=0;i<selectList.length;i++){
            sessionID.push(selectList[i].sessionID)
        }
        const r = await axios({
            method: "POST",
            url: Tools.getApiAddress("online_delete"),
            data: {
                sessionIds: sessionID
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })

        if (r.data.statusCode == 200) {
            message.success("删除成功")
        } else {
            message.error(r.data.returnMessage)
        }
    }
    async loadPage({ dispatch, pageIndex, pageSize, search }) {
        this.setLoading({dispatch, loading: true})
        const { keywords } = search;
        let param = {
            queryText: keywords,
            pageIndex: pageIndex,
            pageSize: pageSize,
            orderBy: '',
            sort: ''

        }
        // 字典列表
        const r = await new OnlineLogic().querysList(param);

        if (r.data.statusCode == 200) {
            const dataList = r.data.data.dataSource;

            const list = dataList.map((v, i) => {
                v.key = i + 1;
                return v;
            })

            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: list, pageSize });
        }  this.setLoading({dispatch, loading: false})

    }
    setCondition(method: "Keywords", value, dispatch) {

        if ("Keywords" == method) {
            dispatch({ type: "SetSearch", payload: { keywords: value } });
        }
    }
}
export const fileAction = new FileAction();