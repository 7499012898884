import { Market_logic } from "./Market_logic";

/**
 * 积分兑换优惠劵逻辑
 */
export class MarketingPointsExchangeOffer_logic extends Market_logic {
    /** 添加优惠劵 */
    static AddGoodsExchange(_data,_op: {
        /** 优惠劵ID */
        CouponID: string;
    }) {
        return this.axios(this.getApi('MarketingPointsExchangeOffer.AddGoodsExchange'), 'GET',_data, _op);
    }

    /** 设置优惠劵兑换规则 */
    static SetExchangeRules(_data: any, _op: any) {
        return this.axios(this.getApi('MarketingPointsExchangeOffer.SetExchangeRules'), 'POST',_data, _op);
    }

    /** 获取单个 */
    static GetFirstModel(_data: any, _op: {
        ID: string;
    }) {
        return this.axios(this.getApi('MarketingPointsExchangeOffer.GetFirstModel'), 'GET',_data, _op);
    }

    /** 分页 */
    static QueryPage(_data: any, _op: any) {
        return this.axios(this.getApi('MarketingPointsExchangeOffer.QueryPage'), 'POST',_data, _op);
    }

    /** 上架兑换活动 */
    static Shelves( _data: any, _op: {
        ID: string;
    }) {
        return this.axios(this.getApi('MarketingPointsExchangeOffer.Shelves'), 'GET',_data, _op);
    }

    /** 下架兑换活动 */
    static OfftheShelf( _data: any,  _op: {
        ID: string;
    }) {
        return this.axios(this.getApi('MarketingPointsExchangeOffer.OfftheShelf'), 'GET',_data, _op);
    }

    /** 作废兑换活动 */
    static VoidtheShelf( _data: any,  _op: {
        ID: string;
    }) {
        return this.axios(this.getApi('MarketingPointsExchangeOffer.VoidtheShelf'), 'GET',_data, _op);
    }
}