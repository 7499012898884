import {
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
  TimePicker,
  DatePicker,
  Tag,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { FormSubtitle } from "../../../commodity/view/form_subtitle";
import styles from "../../style/inventory_goods_formbasic.module.scss";
import img_del from "../../../../assets/images/shanchu.png";
import { couponformAction } from "../../action/coupon/form_action";
import { useBoolean } from "ahooks";
import { Table } from "../../../../component/table";
import { CouponFormStore } from "../../store/coupon/form_store";
import { autoRem } from "../../../../global.style";
import { useState } from "react";
import { RadioStyle } from "../../../../component/radio-style";
import { ChooseCoupon } from "../../../../component/choose-coupon";
import { ChooseMember } from "../../../../component/choose-member";
import { BatchCouponFormStore } from "../../store/batchCoupon/form_store";
import { batchcouponformAction } from "../../action/batchcoupon/form_action";
import { Button } from "../../../../component/button";
const { Option } = Select;
const { RangePicker } = DatePicker;

export function InventoryGoodsFormBasic({ title, disabled, updateFormField }) {
  const [couponState, couponFunc] = useBoolean(false);
  const [memberState, memberFunc] = useBoolean(false);
  const [classifyState, classifyFunc] = useBoolean(false);

  const { state, dispatch } = useContext(BatchCouponFormStore);
  const { formData } = state;
  const action = batchcouponformAction;
  var isLook = false;
  if (title == "查看精准发券") {
    isLook = true;
  } else {
    isLook = false;
  }

  //console.log(formData)
  let height = document.body.clientHeight - 188;
  return (
    <div className={styles.root} style={{ height: height + "px" }}>
      <div className={styles.content}>
        <FormSubtitle>基础信息</FormSubtitle>

        <div
          style={{
            margin: "" + autoRem(1.9375) + " " + autoRem(1.25) + " 0 0",
          }}
          className="custom-radio"
        >
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 3 }}
                label="活动名称"
                name="activityName"
                rules={[
                  {
                    required: true,
                    validator: (_, __, callBack: (error?: string) => void) => {
                      if (!formData.activityName) {
                        callBack("请输入活动名称");
                        return;
                      }

                      callBack();
                    },
                  },
                ]}
              >
                {formData.activityName ? "" : ""}
                <Input
                  style={{ width: "400px" }}
                  disabled={disabled}
                  maxLength={15}
                  value={formData.activityName}
                  autoComplete="off"
                  placeholder="请输入活动名称"
                  onChange={(v) => {
                    updateFormField("activityName");
                    action.setFormData({
                      dispatch,
                      key: "activityName",
                      value: v.target.value,
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <FormSubtitle>优惠券列表</FormSubtitle>
        <div
          style={{
            margin: "" + autoRem(1.9375) + " " + autoRem(1.25) + " 0 0",
          }}
          className="custom-radio"
        >
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 3 }}
                label={<span className={styles.alarm}>添加优惠券</span>}
              >
                <Button
                  type="primary"
                  color="Red"
                  style={{
                    fontSize: "10px",
                    height: "30px",
                    padding: "0 10px",
                    textAlign: "center",
                    lineHeight: "30px",
                    background: "#4164E3",
                    color: "#fff",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (disabled) return false;
                    couponFunc.setTrue();
                  }}
                >
                  添加优惠券
                </Button>
                <span style={{ marginLeft: 10, padding: "5px 10px" }}>
                  发券数量为推送给单个会员的券数量
                </span>
              </Form.Item>
            </Col>
          </Row>
          <CouPonDataTable dataList={state.coupon} disabled={disabled} />
        </div>
        <FormSubtitle>发券规则</FormSubtitle>
        <div
          style={{
            margin: "" + autoRem(1.9375) + " " + autoRem(1.25) + " 0 0",
          }}
          className="custom-radio"
        >
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 3 }}
                label={<span className={styles.alarm}>生效时间</span>}
                style={{ marginBottom: 0 }}
              >
                <RadioStyle>
                  <Radio.Group
                    defaultValue={1}
                    value={formData.sendType ? formData.sendType : 1}
                    onChange={(e) => {
                      if (!disabled) {
                        action.setFormData({
                          dispatch,
                          key: "sendType",
                          value: e.target.value,
                        });
                      }
                    }}
                  >
                    <Radio value={1}>立即发券</Radio>
                    {/* <Radio value={2}>定时发券</Radio> */}
                  </Radio.Group>
                </RadioStyle>
                {formData.sendType == 2 && <div>定时发券在每日凌晨执行</div>}
              </Form.Item>
              {formData.sendType == 2 && (
                <Form.Item label="发券日期" labelCol={{ span: 4 }}>
                  <RangePicker
                    disabled={disabled}
                    style={{ width: "300px" }}
                    // defaultValue={[moment(formData.validStartDate, 'YYYY-MM-DD'), moment(formData.validStartDate, 'YYYY-MM-DD')]}
                    onChange={function (date, dateString) {
                      // action.setFormData({ dispatch, key: "validStartDate", value: dateString[0] })
                      // action.setFormData({ dispatch, key: "validEndDate", value: dateString[0] })
                    }}
                  />
                </Form.Item>
              )}
            </Col>
          </Row>
        </div>
        <FormSubtitle>发券会员</FormSubtitle>
        <div
          style={{
            margin: "" + autoRem(1.9375) + " " + autoRem(1.25) + " 0 0",
          }}
          className="custom-radio"
        >
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 3 }}
                label={<span className={styles.alarm}>指定会员</span>}
              >
                <RadioStyle>
                  <Radio.Group
                    defaultValue={1}
                    value={
                      formData.sendMemberType ? formData.sendMemberType : 1
                    }
                    onChange={(e) => {
                      if (!disabled) {
                        action.setFormData({
                          dispatch,
                          key: "sendMemberType",
                          value: e.target.value,
                        });
                      }
                    }}
                  >
                    <Radio value={1}>按会员发券</Radio>
                    <Radio value={2} style={{ position: "relative" }}>
                      按标签发券
                    </Radio>
                    <Radio value={3}>全体会员</Radio>
                  </Radio.Group>
                </RadioStyle>
                {formData.sendMemberType == 2 && (
                  <Select
                    disabled={disabled}
                    mode="multiple"
                    placeholder="请选择"
                    style={{ width: "30%", position: "absolute", top: "50px" }}
                    defaultValue={formData.labels}
                    onChange={(v) =>
                      action.setFormData({ dispatch, key: "labels", value: v })
                    }
                  >
                    {state.labels &&
                      state.labels.length > 0 &&
                      state.labels.map((item, i) => {
                        return (
                          <Option value={item.id} key={i}>
                            {item.labelName}
                          </Option>
                        );
                      })}
                  </Select>
                )}
                {!disabled && formData.sendMemberType == 1 && (
                  <Button
                    type="primary"
                    color="Red"
                    style={{
                      fontSize: "10px",
                      height: "30px",
                      padding: "0 10px",
                      textAlign: "center",
                      lineHeight: "30px",
                      background: "#ff746d",
                      color: "#fff",
                      cursor: "pointer",
                    }}
                    onClick={() => memberFunc.setTrue()}
                  >
                    添加会员
                  </Button>
                )}
              </Form.Item>
            </Col>
          </Row>

          {formData.sendMemberType == 1 && (
            <MenberDataTable dataList={state.memberList} disabled={disabled} />
          )}
        </div>
      </div>

      <ChooseCoupon
        visibleModal={couponState}
        type="radio"
        close={(status, r) => {
          if (r) {
            action.setFormData({ dispatch, key: "coupon", value: r });
            action.setFormData({ dispatch, key: "couponID", value: r[0].id });
            action.AddCouponTableItem({ dispatch, list: r });
          }
          couponFunc.setFalse();
        }}
      />
      <ChooseMember
        visibleModal={memberState}
        close={(status, r) => {
          if (r) {
            action.setFormData({ dispatch, key: "members", value: r });
            action.AddMemberTableItem({ dispatch, list: r });
          }
          memberFunc.setFalse();
        }}
      />
    </div>
  );
}
export function CouPonDataTable({ dataList, disabled }) {
  const action = batchcouponformAction;
  const { state, dispatch } = useContext(BatchCouponFormStore);
  const columns: ColumnsType = [
    {
      title: "操作",
      dataIndex: "serial",
      key: "1",
      align: "center",
      width: 100,
      render: (v, r, i) => (
        <div>
          <img
            src={img_del}
            style={{ width: 20 }}
            onClick={() => {
              !disabled && action.DelCouponTableItem(dispatch, i);
            }}
            alt=""
          />
        </div>
      ),
    },
    {
      title: "优惠券名称",
      dataIndex: "couponName",
      key: "3",
      align: "center",
    },
    {
      title: "类型",
      dataIndex: "couponType",
      key: "4",
      align: "center",
      render: (v) => (
        <>
          {v == 2 && <Tag color="success">直减券</Tag>}
          {v == 1 && <Tag color="error">折扣券</Tag>}
        </>
      ),
    },
    {
      title: "面额/折扣",
      key: "5",
      align: "center",
      render: (v) => (
        <span>
          {v.couponType == 2 ? v.subMoney + "元" : v.discount / 10 + "折"}
        </span>
      ),
    },
    {
      title: "使用条件",
      key: "6",
      align: "center",
      render: (v) => <span>满{v.meetMoney}元可用</span>,
    },
    {
      title: "领券方式",
      dataIndex: "getCouponType",
      key: "7",
      align: "center",
      render: (v) => (
        <>
          {v == 1 && <Tag color="success">主动领取</Tag>}
          {v == 2 && <Tag color="error">系统发放</Tag>}
          {v == 3 && <Tag color="geekblue">积分兑换</Tag>}
        </>
      ),
    },
    {
      title: "发券数量",
      // dataIndex: 'couponAmount',
      key: "8",
      align: "center",
      render: (v, r, i) => (
        <div>
          <Input
            maxLength={20}
            type="number"
            style={{ width: 100 }}
            disabled={disabled}
            defaultValue={v.couponQuantity}
            onChange={(e) => {
              if (!disabled) {
                action.setFormData({
                  dispatch,
                  key: "couponQuantity",
                  value: e.target.value,
                });
                action.changeTableItem({
                  dispatch,
                  value: e.target.value,
                  index: i,
                });
              }
            }}
          />
        </div>
      ),
    },
    {
      title: "有效期",
      key: "8",
      align: "center",
      width: 160,
      // render: v => <span>{v.validStartDate}~{v.validEndDate}</span>
      render: (v) => (
        <>
          {v.validType == 1 ? (
            <span>
              {v.validStartDate}~{v.validEndDate}
            </span>
          ) : (
            <span>领取后{v.validDay}天有效</span>
          )}
        </>
      ),
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "8",
      align: "center",
      render: (v) => (
        <>
          {v == 1 && <Tag color="success">启用</Tag>}
          {v == 2 && <Tag color="error">禁用</Tag>}
        </>
      ),
    },
  ];

  useEffect(() => {}, [dataList]);
  return <Table dataSource={dataList} columns={columns} />;
}
function MenberDataTable({ dataList, disabled }) {
  const action = batchcouponformAction;
  const { state, dispatch } = useContext(BatchCouponFormStore);
  // const data = state.memberList;
  const [list, setList] = useState(null);
  const columns: ColumnsType = [
    {
      title: "操作",
      align: "center",
      dataIndex:'serial',
      width: 100,
      render: (v, r, i) => (
        <div>
          <img
            src={img_del}
            style={{ width: 20, cursor: "pointer" }}
            onClick={() => {
              console.log(v)
              !disabled && action.DelMemberTableItem(dispatch, v);
            }}
          />
        </div>
      ),
    },
    {
      title: "会员姓名",
      dataIndex: "memberName",
      align: "center",
    },
    {
      title: "手机号码",
      dataIndex: "phone",
      align: "center",
    },
    {
      title: "等级",
      dataIndex: "gradeName",
      align: "center",
    },

    {
      title: "账户余额",
      dataIndex: "balanceMoney",
      align: "center",
    },
    {
      title: "账户积分",
      dataIndex: "accountIntegral",
      key: "6",
      align: "center",
    },
    {
      title: "会员来源",
      align: "center",
      render: (v) => (
        <Tag color="default">{v.source == 1 ? "管理端" : "客户端"}</Tag>
      ),
    },

    {
      title: "会员标签",
      dataIndex: "label",
      width: 150,
      align: "center",
    },
    {
      title: "会员状态",
      align: "center",
      dataIndex: "status",
      render: (v) =>
        v == "挂失" ? (
          <Tag color="warning">挂失</Tag>
        ) : v == "停用" ? (
          <Tag color="error">停用</Tag>
        ) : (
          <Tag color="success">正常</Tag>
        ),
    },
  ];
  useEffect(() => {
    setList(dataList);
  }, [list, dataList]);
  return <Table dataSource={list} columns={columns} />;
}
