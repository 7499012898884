import {
  Switch,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
  TimePicker,
  DatePicker,
  Tag,
  message,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { FormSubtitle } from "../../../commodity/view/form_subtitle";
import styles from "../../style/inventory_goods_formbasic.module.scss";

import img_del from "../../../../assets/images/shanchu.png";
import { precisionformAction } from "../../action/precision/form_action";
import { useBoolean } from "ahooks";
import { ChooseCoupon } from "../../../../component/choose-coupon";
import { ChooseClassify } from "../../../../component/choose-classify";
import { Table } from "../../../../component/table";
import { ChooseShop } from "../../../../component/choose-shop";
import { PrecisionFormStore } from "../../store/precision/form_store";
import { autoRem } from "../../../../global.style";
import { useState } from "react";
import { RadioStyle } from "../../../../component/radio-style";
import addImg from "../../../../assets/images/add.png";
import reduceImg from "../../../../assets/images/reduce.png";
import moment from "moment";
import { ChooseMember } from "../../../../component/choose-member";
import { Button } from "../../../../component/button";
let userClear = false;
let settingClear = false;
const { Option } = Select;
const { RangePicker } = DatePicker;
export function DataTable({ dataList, disabled }) {
  const { dispatch } = useContext(PrecisionFormStore);
  const action = precisionformAction;

  const columns: ColumnsType = [
    {
      title: "操作",
      dataIndex: "serial",
      key: "1",
      align: "center",
      width: 100,
      render: (v, r, i) => (
        <div>
          <img
            src={img_del}
            style={{ width: 20 }}
            onClick={() => {
              !disabled && action.delTableItem(dispatch, i);
            }}
          />
        </div>
      ),
    },
    {
      title: "商品编码",
      dataIndex: "goodsNo",
      key: "2",
      align: "center",
    },
    {
      title: "商品名称",
      dataIndex: "goodsName",
      key: "3",
      align: "center",
    },
    {
      title: "商品分类",
      dataIndex: "goodsClassName",
      key: "4",
      align: "center",
    },
    {
      title: "单位",
      dataIndex: "unitName",
      key: "5",
      align: "center",
    },
    {
      title: "门店价",
      dataIndex: "storePrice",
      key: "5",
      align: "center",
    },
    {
      title: "商城价",
      dataIndex: "putNewPrice",
      key: "5",
      align: "center",
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "6",
      align: "center",
      render: (v) => (
        <Tag color={1 === v ? "success" : "error"}>
          {1 === v ? "正常" : "停售"}
        </Tag>
      ),
    },
  ];
  return <Table dataSource={dataList} columns={columns} />;
}

export function DataTableclassity({ dataList, disabled }) {
  const { dispatch } = useContext(PrecisionFormStore);
  const action = precisionformAction;

  const columns: ColumnsType = [
    {
      title: "操作",
      dataIndex: "serial",
      key: "1",
      align: "center",
      width: 100,
      render: (v, r, i) => (
        <div>
          <img
            src={img_del}
            style={{ width: 20 }}
            onClick={() => {
              !disabled && action.delTableclassItem(dispatch, i);
            }}
          />
        </div>
      ),
    },
    {
      title: "商品分类",
      dataIndex: "title",
      key: "2",
      align: "center",
    },
  ];
  return <Table dataSource={dataList} columns={columns} />;
}
export function DataTableAdd({ dataList, disabled }) {
  const { dispatch } = useContext(PrecisionFormStore);
  const action = precisionformAction;

  const columns: ColumnsType = [
    {
      title: "操作",
      dataIndex: "serial",
      key: "1",
      align: "center",
      width: 100,
      render: (v, r, i) => (
        <div>
          <img
            src={img_del}
            style={{ width: 20 }}
            onClick={() => {
              !disabled && action.delTableItemAdd(dispatch, i);
            }}
          />
        </div>
      ),
    },
    {
      title: "商品编码",
      dataIndex: "goodsNo",
      key: "2",
      align: "center",
    },
    {
      title: "商品名称",
      dataIndex: "goodsName",
      key: "3",
      align: "center",
    },
    {
      title: "商品分类",
      dataIndex: "goodsClassName",
      key: "4",
      align: "center",
    },
    {
      title: "单位",
      dataIndex: "unitName",
      key: "5",
      align: "center",
    },
    {
      title: "零售价",
      dataIndex: "putNewPrice",
      key: "5",
      align: "center",
    },
    {
      title: "赠送总库存",
      dataIndex: "giveCount",
      key: "8",
      align: "center",
      width: 120,
      render: (e, r, i) => {
        return (
          <Input
            type="number"
            style={{ width: 100 }}
            value={e}
            disabled={disabled}
            onChange={(e) => {
              action.changeTableItemAdd({
                dispatch,
                key: "giveCount",
                value: e.target.value,
                index: i,
              });
            }}
          />
        );
      },
    },
  ];
  return <Table dataSource={dataList} columns={columns} />;
}

export function CouPonDataTable({ dataList, disabled }) {
  const action = precisionformAction;
  const { state, dispatch } = useContext(PrecisionFormStore);
  const columns: ColumnsType = [
    {
      title: "操作",
      dataIndex: "serial",
      key: "1",
      align: "center",
      width: 100,
      render: (v, r, i) => (
        <div>
          <img
            src={img_del}
            style={{ width: 20 }}
            onClick={() => {
              !disabled && action.delCouponTableItem(dispatch, i);
            }}
            alt=""
          />
        </div>
      ),
    },
    {
      title: "优惠券名称",
      dataIndex: "couponName",
      key: "3",
      align: "center",
    },
    {
      title: "类型",
      dataIndex: "couponType",
      key: "4",
      align: "center",
      render: (v) => (
        <>
          {v == 2 && <Tag color="success">直减券</Tag>}
          {v == 1 && <Tag color="error">折扣券</Tag>}
        </>
      ),
    },
    {
      title: "面额/折扣",
      key: "5",
      align: "center",
      render: (v) => (
        <span>
          {v.couponType == 2 ? v.subMoney + "元" : v.discount / 10 + "折"}
        </span>
      ),
    },
    {
      title: "使用条件",
      key: "6",
      align: "center",
      render: (v) => <span>满{v.meetMoney}元可用</span>,
    },
    {
      title: "领券方式",
      dataIndex: "getCouponType",
      key: "7",
      align: "center",
      render: (v) => (
        <>
          {v == 1 && <Tag color="success">主动领取</Tag>}
          {v == 2 && <Tag color="error">系统发放</Tag>}
          {v == 3 && <Tag color="geekblue">积分兑换</Tag>}
        </>
      ),
    },

    {
      title: "有效期",
      key: "8",
      align: "center",
      width: 160,
      render: (v) => (
        <span>
          {v.validStartDate}~{v.validEndDate}
        </span>
      ),
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "8",
      align: "center",
      render: (v) => (
        <>
          {v == 1 && <Tag color="success">启用</Tag>}
          {v == 2 && <Tag color="error">禁用</Tag>}
        </>
      ),
    },
  ];

  useEffect(() => {}, [dataList]);
  return <Table dataSource={dataList} columns={columns} />;
}
function MessageTemplateTable({ dataList, disabled }) {
  const action = precisionformAction;
  const { state, dispatch } = useContext(PrecisionFormStore);
  const columns: ColumnsType = [
    {
      title: "操作",
      dataIndex: "serial",
      key: "1",
      align: "center",
      width: 100,
      render: (v, r, i) => (
        <div>
          <img
            src={img_del}
            style={{ width: 20 }}
            onClick={() => {
              // !disabled && action.delCouponTableItem(dispatch, i);
            }}
            alt=""
          />
        </div>
      ),
    },
    {
      title: "模板ID",
      dataIndex: "couponName",
      key: "3",
      align: "center",
    },
    {
      title: "模版名称",
      dataIndex: "couponType",
      key: "4",
      align: "center",
    },
    {
      title: "	类型",
      key: "5",
      align: "center",
    },
    {
      title: "内容",
      key: "6",
      align: "center",
    },
  ];

  useEffect(() => {}, [dataList]);
  return <Table dataSource={dataList} columns={columns} />;
}
export function InventoryGoodsFormBasic({ title, disabled, updateFormField }) {
  const [shopState, shopFunc] = useBoolean(false);
  const [couponState, couponFunc] = useBoolean(false);
  const [memberState, memberFunc] = useBoolean(false);
  const [classifyState, classifyFunc] = useBoolean(false);
  const { TextArea } = Input;
  const { state, dispatch } = useContext(PrecisionFormStore);
  const { formData } = state;
  const action = precisionformAction;
  var isLook = false;
  if (title == "查看营销活动") {
    isLook = true;
  } else {
    isLook = false;
  }

  // 时间段选择
  const timePickeronChange = (time, timeString) => {
    action.setFormData({ dispatch, key: "beginTime", value: timeString[0] });
    action.setFormData({ dispatch, key: "endTime", value: timeString[1] });
  };
  // 日期段选择
  const onangeChange = (time, timeString) => {
    action.setFormData({ dispatch, key: "beginDate", value: timeString[0] });
    action.setFormData({ dispatch, key: "endDate", value: timeString[1] });
  };
  // const onChangeCheckbox = e => {

  //     if (formData.useType != 2) {
  //         //console.log(formData.useType)
  //         action.setFormData({ dispatch, key: "useweekTypeValue", value: e })
  //         action.setFormData({ dispatch, key: "useTypeValue", value: e })
  //     }
  // };
  const count = (key) =>
    state.couponList.map((v) => v[key]).reduce((p, c) => p + c, 0);

  function WeekType({ value, taskType }) {
    const [] = useState(1);
    const { dispatch } = useContext(PrecisionFormStore);

    const onChange = (e) => {
      if (!disabled) {
        action.setFormData({
          dispatch,
          key: "taskType",
          value: e.target.value,
        });
      }
    };
    return (
      <RadioStyle>
        <Radio.Group onChange={onChange} value={taskType}>
          <Radio
            value={1}
            style={{ marginLeft: "15px", height: "30px", lineHeight: "30px" }}
          >
            立即生效
          </Radio>
          <Radio
            value={2}
            style={{ marginLeft: "10px", height: "30px", lineHeight: "30px" }}
          >
            指定时间段生效
          </Radio>
        </Radio.Group>
      </RadioStyle>
    );
  }

  const [userType, setUserType] = useState(null);
  const [channelType, setChannelType] = useState(null);
  const [clearSetting, setClearSetting] = useState(false);

  const [clearUser, SetClearUser] = useState(false);

  const disabledDate = (current) => {
    return current < moment().startOf("day");
  };

  let height = document.body.clientHeight - 188;
  return (
    <div className={styles.root} style={{ height: height + "px" }}>
      <div className={styles.content}>
        <FormSubtitle>基础信息</FormSubtitle>

        <div
          className="custom-radio"
          style={{
            margin: "" + autoRem(1.9375) + " " + autoRem(1.25) + " 0 0",
          }}
        >
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 3 }}
                label="活动名称"
                name="actionName"
                rules={[
                  {
                    required: true,
                    validator: (_, __, callBack: (error?: string) => void) => {
                      if (!formData.actionName) {
                        callBack("请输入活动名称");
                        return;
                      }
                      callBack();
                    },
                  },
                ]}
              >
                {formData.actionName ? "" : ""}
                <Input
                  maxLength={15}
                  style={{ width: "400px" }}
                  disabled={disabled}
                  value={formData.actionName}
                  autoComplete="off"
                  placeholder="请输入"
                  onChange={(v) => {
                    updateFormField("actionName");
                    action.setFormData({
                      dispatch,
                      key: "actionName",
                      value: v.target.value,
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                label="活动描述"
                labelCol={{ span: 3 }}
                name="actionDescription"
                rules={[
                  {
                    required: true,
                    validator: (_, __, callBack: (error?: string) => void) => {
                      if (!formData.actionDescription) {
                        callBack("请输入活动描述");
                        return;
                      }
                      callBack();
                    },
                  },
                ]}
              >
                {formData.actionDescription ? "" : ""}
                <TextArea
                  style={{ width: "400px" }}
                  showCount
                  maxLength={100}
                  disabled={disabled}
                  value={formData.actionDescription}
                  autoComplete="off"
                  placeholder="请输入"
                  onChange={(v) => {
                    updateFormField("actionDescription");
                    action.setFormData({
                      dispatch,
                      key: "actionDescription",
                      value: v.target.value,
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 3 }}
                label={<span className={styles.alarm}>生效时间</span>}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <WeekType
                    value={1}
                    taskType={formData.taskType ? formData.taskType : 1}
                  />
                  {formData.taskType == 2 && (
                    <RangePicker
                      disabledDate={disabledDate}
                      defaultValue={[
                        formData.beginDate != "" &&
                        formData.beginDate != null &&
                        formData.beginDate != undefined
                          ? moment(formData.beginDate, "YYYY-MM-DD")
                          : moment(),
                        formData.endDate != "" &&
                        formData.endDate != null &&
                        formData.endDate != undefined
                          ? moment(formData.endDate, "YYYY-MM-DD")
                          : moment(),
                      ]}
                      style={{ width: "400px", marginTop: "10px" }}
                      onChange={onangeChange}
                    />
                  )}
                </div>
              </Form.Item>
            </Col>
          </Row>
          <FormSubtitle>营销场景</FormSubtitle>
          <div
            className="custom-radio"
            style={{
              margin: "" + autoRem(1.9375) + " " + autoRem(1.25) + " 0 0",
            }}
          >
            <Row gutter={16} style={{ marginTop: 0 }}>
              <Col span={24}>
                <Form.Item
                  label={<span className={styles.alarm}>场景模板</span>}
                  labelCol={{ span: 3 }}
                >
                  <RadioStyle>
                    <Radio.Group
                      value={formData.scenarioType}
                      onChange={(e) => {
                        if (!disabled) {
                          action.setFormData({
                            dispatch,
                            key: "scenarioType",
                            value: e.target.value,
                          });
                          /* action.clearSetting({dispatch})}*/
                        }
                      }}
                    >
                      <Radio value={1}>新会员注册</Radio>
                      <Radio value={2}>充值有礼</Radio>
                      {/* <Radio value={3}>会员生日有礼</Radio> */}
                    </Radio.Group>
                  </RadioStyle>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: 0 }}>
              <Col span={24}>
                <Form.Item
                  labelCol={{ span: 3 }}
                  label={
                    formData.scenarioType == 2 ? (
                      <span className={styles.alarm}>触发方式</span>
                    ) : (
                      "触发方式"
                    )
                  }
                >
                  <table style={{ width: "100%" }}>
                    <tr
                      style={{
                        color: "#666666",
                        fontSize: "14px",
                        background: "#EEEDED",
                        height: "40px",
                      }}
                    >
                      <th style={{ textAlign: "left", width: "10%" }}></th>
                      <th
                        style={{
                          padding: "0 25px",
                          textAlign: "left",
                          width: "37%",
                        }}
                      >
                        条件
                      </th>
                      <th style={{ textAlign: "left", width: "37%" }}>规则</th>
                      <th
                        style={{
                          color: "blue",
                          textAlign: "right",
                          padding: "0 25px",
                          width: "20px",
                          cursor: "pointer",
                        }}
                      ></th>
                    </tr>
                    <tr>
                      <td style={{ width: 20 }}></td>
                      <td>
                        {formData.scenarioType == 1 && (
                          <Select
                            value={"注册会员"}
                            disabled
                            style={{ width: "70%" }}
                          ></Select>
                        )}
                        {formData.scenarioType == 2 && (
                          <Select
                            value={"充值有礼"}
                            disabled
                            style={{ width: "70%" }}
                          ></Select>
                        )}
                        {formData.scenarioType == 3 && (
                          <Select
                            value={"会员生日有礼"}
                            disabled
                            style={{ width: "70%" }}
                          ></Select>
                        )}
                      </td>
                      <td style={{ display: "flex", alignItems: "center" }}>
                        {formData.scenarioType == 2 && (
                          <>
                            <span>金额达到：</span>
                            <Input
                              type="number"
                              value={formData.rechargeMinMoney}
                              disabled={disabled}
                              style={{
                                width: "80px",
                                height: "28px",
                                marginRight: 10,
                              }}
                              onChange={(e) => {
                                action.setFormData({
                                  dispatch,
                                  key: "rechargeMinMoney",
                                  value: e.target.value,
                                });
                              }}
                            />{" "}
                            <span>-</span>{" "}
                            <Input
                              type="number"
                              value={formData.rechargeMaxMoney}
                              disabled={disabled}
                              style={{
                                width: "80px",
                                height: "28px",
                                marginLeft: 10,
                              }}
                              onChange={(e) =>
                                action.setFormData({
                                  dispatch,
                                  key: "rechargeMaxMoney",
                                  value: e.target.value,
                                })
                              }
                            />
                          </>
                        )}
                      </td>
                    </tr>
                  </table>
                </Form.Item>
              </Col>
            </Row>
            {formData.scenarioType == 2 && (
              <Row gutter={16} style={{ marginTop: 0 }}>
                <Col span={24}>
                  <Form.Item label="指定用户：" labelCol={{ span: 3 }}>
                    <table style={{ width: "100%" }}>
                      <tr
                        style={{
                          color: "#666666",
                          fontSize: "14px",
                          background: "#EEEDED",
                          height: "40px",
                        }}
                      >
                        <th style={{ textAlign: "left", width: "10%" }}>
                          {" "}
                          {!disabled && "操作"}
                        </th>
                        <th
                          style={{
                            padding: "0 25px",
                            textAlign: "left",
                            width: "37%",
                          }}
                        >
                          条件
                        </th>
                        <th style={{ textAlign: "left", width: "37%" }}>
                          规则
                        </th>
                        <th
                          style={{
                            color: "blue",
                            textAlign: "right",
                            padding: "0 25px",
                            width: "20px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (!disabled) {
                              action.clearPrecisionUser({ dispatch });
                              setUserType(null);
                              userClear = true;
                            }
                          }}
                        >
                          {!disabled && "清空"}
                        </th>
                      </tr>
                      {disabled && formData.precisionUser.length == 0 ? (
                        <tr
                          style={{
                            height: "58px",
                            lineHeight: "58px",
                            textAlign: "center",
                          }}
                        >
                          <td colSpan={4}>暂无数据</td>
                        </tr>
                      ) : (
                        <SelectPart disabled={disabled} />
                      )}

                      {/* </tr> */}
                    </table>
                  </Form.Item>
                </Col>
              </Row>
            )}

            <Row gutter={16} style={{ marginTop: 0 }}>
              <Col span={24}>
                <Form.Item label="高级设置：" labelCol={{ span: 3 }}>
                  <table style={{ width: "100%" }}>
                    <tr
                      style={{
                        color: "#666666",
                        fontSize: "14px",
                        background: "#EEEDED",
                        height: "40px",
                      }}
                    >
                      <th style={{ textAlign: "left", width: "10%" }}>
                        {!disabled && "操作"}
                      </th>
                      <th
                        style={{
                          padding: "0 25px",
                          textAlign: "left",
                          width: "37%",
                        }}
                      >
                        条件
                      </th>
                      <th style={{ textAlign: "left", width: "37%" }}>规则</th>
                      <th
                        style={{
                          color: "blue",
                          textAlign: "right",
                          padding: "0 25px",
                          width: "20px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (!disabled) {
                            action.clearSetting({ dispatch });
                            setChannelType(null);
                            settingClear = true;
                          }
                        }}
                      >
                        {!disabled && "清空"}
                      </th>
                    </tr>

                    {/* {((!disabled)||(formData.precisionSettings&&formData.precisionSettings.length>0))?
                                        :(disabled)&&} */}
                    {disabled &&
                    formData.precisionSettings &&
                    formData.precisionSettings.length == 0 ? (
                      <tr
                        style={{
                          height: "58px",
                          lineHeight: "58px",
                          textAlign: "center",
                        }}
                      >
                        <td colSpan={4}>暂无数据</td>
                      </tr>
                    ) : (
                      <SelectChannel disabled={disabled} clear={settingClear} />
                    )}
                  </table>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <FormSubtitle>赠送权益</FormSubtitle>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item label="赠送积分" labelCol={{ span: 3 }}>
                <Switch
                  style={{ outline: "none" }}
                  checked={formData.isGivePoints}
                  disabled={disabled}
                  onChange={(checked, e) => {
                    action.setFormData({
                      dispatch,
                      key: "isGivePoints",
                      value: checked,
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          {formData.isGivePoints && (
            <Row gutter={16} style={{ marginTop: 0 }}>
              <Col span={24}>
                <Form.Item label="积分值：" labelCol={{ span: 4 }}>
                  <Input
                    type="number"
                    placeholder="请输入需要赠送的积分值"
                    disabled={disabled}
                    value={formData.givePoints}
                    style={{ width: "240px" }}
                    onChange={(e) => {
                      action.setFormData({
                        dispatch,
                        key: "givePoints",
                        value: e.target.value,
                      });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item label="赠送优惠券" labelCol={{ span: 3 }}>
                <Switch
                  style={{ outline: "none" }}
                  checked={formData.isGiveConpou}
                  disabled={disabled}
                  onChange={(checked, event) => {
                    if (!checked) {
                      action.setFormData({
                        dispatch,
                        key: "couponList",
                        value: [],
                      });
                      action.addCouponTableItem({ dispatch, payload: [] });
                    }
                    action.setFormData({
                      dispatch,
                      key: "isGiveConpou",
                      value: checked,
                    });
                  }}
                />

                {formData.isGiveConpou && (
                  <Button
                    type="primary"
                    color="Red"
                    style={{
                      marginLeft: 10,
                      fontSize: "10px",
                      height: "30px",
                      padding: "0 10px",
                      textAlign: "center",
                      lineHeight: "30px",
                      background: "#ff746d",
                      color: "#fff",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (disabled) {
                        return false;
                      }
                      couponFunc.setTrue();
                    }}
                  >
                    添加优惠券
                  </Button>
                )}
              </Form.Item>
            </Col>
          </Row>
          <CouPonDataTable disabled={disabled} dataList={state.couponList} />
          {/* 短信 */}
          {false && (
            <div
              className="custom-radio"
              style={{
                margin: "" + autoRem(1.9375) + " " + autoRem(1.25) + " 0 0",
              }}
            >
              <FormSubtitle>营销场景</FormSubtitle>
              <div
                className="custom-radio"
                style={{
                  margin: "" + autoRem(1.9375) + "0 0 0",
                }}
              >
                <Row gutter={16} style={{ marginTop: 0 }}>
                  <Col span={24}>
                    <Form.Item
                      label={<span>是否开启短信通知</span>}
                      labelCol={{ span: 4 }}
                      labelAlign="right"
                    >
                      <Switch />
                      <span>
                        开启后达到发券条件及发券规则的用户将自动收到一条短信通知
                      </span>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16} style={{ marginTop: 0 }}>
                  <Col span={24}>
                    <Form.Item
                      labelCol={{ span: 3 }}
                      label={"短信模板"}
                      labelAlign="right"
                    >
                      <Button
                        type="primary"
                        color="Red"
                        style={{
                          marginLeft: 10,
                          fontSize: "10px",
                          height: "30px",
                          padding: "0 10px",
                          textAlign: "center",
                          lineHeight: "30px",
                          background: "#ff746d",
                          color: "#fff",
                          cursor: "pointer",
                        }}
                      >
                        选择短信模板
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
                <MessageTemplateTable dataList={[]} disabled={false} />
              </div>
            </div>
          )}
        </div>
      </div>

      <ChooseShop
        visibleModal={shopState}
        type="checkbox"
        close={(status, r) => {
          if (r) {
            action.setFormData({ dispatch, key: "shopList", value: r });
            let arr = [];
            if (r.length > 0) {
              r.map((item) => {
                arr.push(item.id);
              });
            }
            // 需要处理id
            action.changeSetting({ dispatch, payload: { wType: 1, wid: arr } });
          }
          shopFunc.setFalse();
        }}
      />
      <ChooseCoupon
        visibleModal={couponState}
        close={(status, r) => {
          if (r) {
            action.setFormData({ dispatch, key: "couponList", value: r });
            action.addCouponTableItem({ dispatch, payload: r });
          }
          couponFunc.setFalse();
        }}
      />
      <ChooseMember
        visibleModal={memberState}
        close={(status, r) => {
          if (r) {
            if (userType == 4) {
              action.setFormData({ dispatch, key: "outMemberList", value: r });
              let arr = [];
              if (r.length > 0) {
                r.map((item) => {
                  arr.push(item.id);
                });
              }
              action.changePrecisionUser({
                dispatch,
                payload: { wtype: 4, wid: arr },
              });
            }
            if (userType == 3) {
              action.setFormData({ dispatch, key: "memberList", value: r });
              let arr = [];
              if (r.length > 0) {
                r.map((item) => {
                  arr.push(item.id);
                });
              }
              action.changePrecisionUser({
                dispatch,
                payload: { wtype: 3, wid: arr },
              });
              // action.
            }
          }
          memberFunc.setFalse();
        }}
      />
    </div>
  );
}
const SelectChannel = ({ disabled, clear }) => {
  const level1 = [
    { name: "选择门店", id: 1 },
    { name: "选择渠道", id: 2 },
  ];
  const { state, dispatch } = useContext(PrecisionFormStore);
  const action = precisionformAction;
  const [type, setType] = useState(null);
  const [shopState, shopFunc] = useBoolean(false);
  let [com, setCom] = useState([
    [
      { name: "选择门店", id: 1 },
      { name: "选择渠道", id: 2 },
    ],
  ]);
  const handleClick = () => {
    let arr = [...com];

    if (arr.length < 2) {
      arr.push(level1);
      setCom([...arr]);
    } else message.error("最多两个");
  };
  const handleClickDecs = (i) => {
    let arr = [...com];
    let l = [...selList];
    if (arr.length > 0) {
      arr.splice(i, 1);
      l.splice(i, 1);
      setSelList(l);
      setCom(arr);
    }
    action.delSetting({ dispatch, index: i });
  };
  const settypenum = (i, v) => {
    switch (i) {
      case 1:
        {
          let arr = selList;
          if (arr.length != 0) {
            arr.splice(0, 1, v);
          } else {
            arr.push(v);
          }
          setSelList([...arr]);
        }
        break;
      case 2:
        {
          let arr = selList;
          if (arr.length != 0) {
            arr.splice(1, 1, v);
          } else {
            arr.push(v);
          }
          setSelList([...arr]);
        }
        break;
    }
  };
  const { formData } = state;
  const [selList, setSelList] = useState([]);
  const [list, setList] = useState([]);
  const data = state.formData.precisionSettings;

  let c = [];
  let s = [];
  let l = [];
  if (settingClear) {
    setCom([
      [
        { name: "选择门店", id: 1 },
        { name: "选择渠道", id: 2 },
      ],
    ]);
    setSelList([]);
    setList([]);
    settingClear = false;
  }
  useEffect(() => {
    setCom([
      [
        { name: "选择门店", id: 1 },
        { name: "选择渠道", id: 2 },
      ],
    ]);
    setSelList([]);
    setList([]);
    if (data && data.length > 0) {
      data.map((item) => {
        c.push(level1);
        s.push(item.wType);
        l.push(item.wid);
      });
      setCom([...c]);
      setSelList([...s]);
      setList([...l]);
    }
  }, [data]);
  const Part = ({ i, item }) => {
    return (
      <>
        <td>
          <Select
            placeholder="请选择"
            disabled={disabled}
            defaultValue={selList[i]}
            onChange={(v) => {
              settypenum(i + 1, v);
              setType(v);
              // action.clearSettingOne({dispatch,payload:{wType:v,wid:[],index:i}})
            }}
            style={{ width: "70%" }}
          >
            {/* <Option value={2} key={item[i].id+i} disabled={selList.indexOf(item[i].id)!=-1}>选择渠道</Option> */}
            {item.map((c, index) => {
              return (
                <Option
                  value={c.id}
                  key={c.id + index}
                  disabled={selList.indexOf(c.id) != -1}
                >
                  {c.name}
                </Option>
              );
            })}
          </Select>
        </td>
        <td style={{ display: "flex", alignItems: "center" }}>
          {selList[i] == 2 && (
            <Select
              mode="multiple"
              style={{ width: "70%" }}
              defaultValue={list[i]}
              disabled={disabled}
              placeholder="请选择"
              onChange={(v) => {
                if (!disabled) {
                  action.changeSetting({
                    dispatch,
                    payload: { wType: 2, wid: v },
                  });
                }
              }}
            >
              <Option value={"POS"}>POS终端</Option>
              <Option value={"Shopp"}>线上商城</Option>
            </Select>
          )}
          {selList[i] == 1 && (
            <div style={{ display: "flex" }}>
              <div
                style={{
                  fontSize: "10px",
                  height: "30px",
                  padding: "0 10px",
                  textAlign: "center",
                  lineHeight: "30px",
                  background: "#ff746d",
                  color: "#fff",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (disabled) {
                    return false;
                  }
                  shopFunc.setTrue();
                }}
              >
                选择门店
              </div>
              <span>
                已选择
                {state.formData.shopList ? state.formData.shopList.length : 0}个
              </span>
            </div>
          )}
        </td>
        <ChooseShop
          visibleModal={shopState}
          type="checkbox"
          saveKeys={true}
          close={(status, r) => {
            if (r) {
              //console.log(r)
              let arr = [];
              if (r.length > 0) {
                r.map((item) => {
                  arr.push(item.id);
                });
              }
              action.setFormData({ dispatch, key: "shopList", value: arr });

              // 需要处理id
              action.changeSetting({
                dispatch,
                payload: { wType: 1, wid: arr },
              });
            }
            shopFunc.setFalse();
          }}
        />
      </>
    );
  };
  return (
    <>
      {com &&
        com.length > 0 &&
        com.map((item, i) => {
          return (
            <tr key={i}>
              <td style={{ width: 20 }}>
                {!disabled && (
                  <>
                    {" "}
                    <img
                      src={addImg}
                      style={{
                        width: "10px",
                        cursor: "pointer",
                        marginRight: "10px",
                      }}
                      onClick={handleClick}
                    />
                    {(i != 0 || com.length >= 2) && (
                      <img
                        src={reduceImg}
                        style={{ width: "10px", cursor: "pointer" }}
                        onClick={() => handleClickDecs(i)}
                      />
                    )}
                  </>
                )}
              </td>

              <Part item={item} i={i} />
            </tr>
          );
        })}
    </>
  );
};
const SelectPart = ({ disabled }) => {
  const [memberState, memberFunc] = useBoolean(false);
  const { state, dispatch } = useContext(PrecisionFormStore);
  const action = precisionformAction;
  const { formData } = state;
  const [type, setType] = useState(0);
  const level1 = [
    { name: "会员标签", id: 1 },
    { name: "会员等级", id: 2 },
    { name: "指定会员", id: 3 },
    { name: "排除会员", id: 4 },
  ];
  // let com = [[{name:'会员标签',id:1},{name:'会员等级',id:2},{name:'指定会员',id:3},{name:'排除会员',id:4}]];
  let [com, setCom] = useState([
    [
      { name: "会员标签", id: 1 },
      { name: "会员等级", id: 2 },
      { name: "指定会员", id: 3 },
      { name: "排除会员", id: 4 },
    ],
  ]);
  const handleClick = () => {
    let arr = [...com];

    if (arr.length < 4) {
      arr.push(level1);
      setCom([...arr]);
    } else message.error("最多四个");
  };
  const handleClickDecs = (i) => {
    let arr = [...com];
    let list = [...selList];
    if (arr.length > 0) {
      arr.splice(i, 1);
      list.splice(i, 1);
      setSelList([...list]);
      setCom([...arr]);
    }
    action.delPrecisionUser({ dispatch, index: i });
  };

  const settypenum = (i, v) => {
    switch (i) {
      case 1:
        {
          let arr = selList;
          if (arr.length != 0) {
            arr.splice(0, 1, v);
          } else {
            arr.push(v);
          }
          setSelList([...arr]);
        }
        break;
      case 2:
        {
          let arr = selList;
          if (arr.length != 0) {
            arr.splice(1, 1, v);
          } else {
            arr.push(v);
          }
          setSelList([...arr]);
        }
        break;
      case 3:
        {
          let arr = selList;
          if (arr.length != 0) {
            arr.splice(2, 1, v);
          } else {
            arr.push(v);
          }
          setSelList([...arr]);
        }
        break;
      case 4:
        {
          let arr = selList;
          if (arr.length != 0) {
            arr.splice(3, 1, v);
          } else {
            arr.push(v);
          }
          setSelList([...arr]);
        }
        break;
    }
  };
  const [selList, setSelList] = useState([]);
  const [list, setList] = useState([]);
  const data = formData.precisionUser;
  let c = [];
  let s = [];
  let l = [];
  if (userClear) {
    setCom([
      [
        { name: "会员标签", id: 1 },
        { name: "会员等级", id: 2 },
        { name: "指定会员", id: 3 },
        { name: "排除会员", id: 4 },
      ],
    ]);
    setSelList([]);
    setList([]);

    userClear = false;
  }
  useEffect(() => {
    setCom([
      [
        { name: "会员标签", id: 1 },
        { name: "会员等级", id: 2 },
        { name: "指定会员", id: 3 },
        { name: "排除会员", id: 4 },
      ],
    ]);
    setSelList([]);
    setList([]);
    if (data && data.length > 0) {
      data.map((item) => {
        c.push(level1);
        s.push(item.wtype);
        l.push(item.wid);
      });
      setCom(c);
      setSelList([...s]);
      setList([...l]);
    }
  }, [data]);
  const Part = ({ item, i }) => {
    return (
      <>
        <td>
          <Select
            placeholder="请选择"
            disabled={disabled}
            defaultValue={selList[i]}
            style={{ width: "70%" }}
            onChange={(v) => {
              action.clearPrecisionUserOne({
                dispatch,
                payload: { wtype: v, wid: [], index: i },
              });
              let l = list;
              l[i] = [];
              setList(l);
              settypenum(i + 1, v);
              setType(v);
            }}
          >
            {item.map((selitem) => {
              return (
                <Option
                  value={selitem.id}
                  key={selitem.id + i}
                  disabled={selList.indexOf(selitem.id) != -1}
                >
                  {selitem.name}
                </Option>
              );
            })}
          </Select>
        </td>

        <td style={{ display: "flex", alignItems: "center" }}>
          {selList[i] == 1 && (
            <Select
              placeholder="请选择"
              mode="multiple"
              style={{ width: "70%" }}
              disabled={disabled}
              defaultValue={list[i]}
              onChange={(v) => {
                action.changePrecisionUser({
                  dispatch,
                  payload: { wtype: 1, wid: v },
                });
              }}
            >
              {state.tagList.map((v) => {
                return (
                  <Option value={v.id} key={v.id + i}>
                    {v.labelName}
                  </Option>
                );
              })}
            </Select>
          )}
          {selList[i] == 2 ? (
            <Select
              placeholder="请选择"
              mode="multiple"
              style={{ width: "70%" }}
              disabled={disabled}
              defaultValue={list[i]}
              onChange={(v) => {
                action.changePrecisionUser({
                  dispatch,
                  payload: { wtype: 2, wid: v },
                });
              }}
            >
              {state.gradeList.map((v) => {
                return (
                  <Option value={v.id} key={v.id + i}>
                    {v.gradeName}
                  </Option>
                );
              })}
            </Select>
          ) : selList[i] == 3 ? (
            <>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    fontSize: "10px",
                    height: "30px",
                    padding: "0 10px",
                    textAlign: "center",
                    lineHeight: "30px",
                    background: "#ff746d",
                    color: "#fff",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (disabled) {
                      return false;
                    }
                    memberFunc.setTrue();
                  }}
                >
                  选择会员
                </div>
                <span>
                  已选择{formData.memberList ? formData.memberList.length : 0}个
                </span>
              </div>
            </>
          ) : selList[i] == 4 ? (
            <div style={{ display: "flex" }}>
              <div
                style={{
                  fontSize: "10px",
                  height: "30px",
                  padding: "0 10px",
                  textAlign: "center",
                  lineHeight: "30px",
                  background: "#ff746d",
                  color: "#fff",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (disabled) {
                    return false;
                  }
                  memberFunc.setTrue();
                }}
              >
                选择会员
              </div>
              <span>
                已选择
                {formData.outMemberList ? formData.outMemberList.length : 0}个
              </span>
            </div>
          ) : null}
          {}
        </td>
      </>
    );
  };
  return (
    <>
      {com &&
        com.length > 0 &&
        com.map((item, i) => {
          return (
            <tr>
              {" "}
              <td style={{ width: 20 }}>
                {!disabled && (
                  <>
                    {" "}
                    <img
                      src={addImg}
                      style={{
                        width: "10px",
                        cursor: "pointer",
                        marginRight: "10px",
                      }}
                      onClick={handleClick}
                    />
                    {(i != 0 || com.length >= 2) && (
                      <img
                        src={reduceImg}
                        style={{ width: "10px", cursor: "pointer" }}
                        onClick={() => handleClickDecs(i)}
                      />
                    )}
                  </>
                )}
              </td>
              <Part item={item} i={i} />
              <ChooseMember
                visibleModal={memberState}
                close={(status, r) => {
                  if (r) {
                    if (selList[i] == 4) {
                      action.setFormData({
                        dispatch,
                        key: "outMemberList",
                        value: r,
                      });
                      let arr = [];
                      if (r.length > 0) {
                        r.map((item) => {
                          arr.push(item.id);
                        });
                      }
                      action.changePrecisionUser({
                        dispatch,
                        payload: { wtype: 4, wid: arr },
                      });
                    }
                    if (selList[i] == 3) {
                      action.setFormData({
                        dispatch,
                        key: "memberList",
                        value: r,
                      });
                      let arr = [];
                      if (r.length > 0) {
                        r.map((item) => {
                          arr.push(item.id);
                        });
                      }
                      action.changePrecisionUser({
                        dispatch,
                        payload: { wtype: 3, wid: arr },
                      });
                      // action.
                    }
                  }
                  memberFunc.setFalse();
                }}
              />
            </tr>
          );
        })}
    </>
  );
};

const SelectChannelNew = ({ disabled }) => {
  const data1 = [
    { name: "选择门店", id: 1 },
    { name: "选择渠道", id: 2 },
  ];

  return <div></div>;
};
