import { message } from "antd";
// import axios from "axios";
import Cookies from "js-cookie";
import { DataTableAction } from "../../../../component/table/action/data_table_action";
import { DataListAction } from "../../../../component/data-list/action/data_table_action";
import { Tools, axios } from "../../../../config";
import { UserRoleLogic } from "../../../../logic/userRole_logic";
import { RoleLogic } from "../../../../logic/role_logic";
import { defualtPageSize } from "../../../../logic/common_logic";
type Init = {
    state;
    dispatch;
    form;
}
export class FileAction extends DataTableAction {
    private RoleLgc = new RoleLogic();
    private UserRoleLgc = new UserRoleLogic();
    async init({ state, dispatch, form }: Init) {
        await this.initBasic({ dispatch, state });
    }
    async initBasic({ dispatch, state }) {
        let parm = {
            name: '',
            pageIndex: 1,
            pageSize: defualtPageSize(),
            orderBy: '',
            sort: ''
        }
        dispatch({ type: "LoadRole", payload: await (await this.RoleLgc.querysList(parm)).data.data.dataSource });
    }

    async delSelectList({ dispatch, selectList, roleId }: { dispatch: any; selectList: any; roleId: any }) {

        let userIds = []
        for (let i = 0; i < selectList.length; i++) {
            userIds.push(selectList[i].userID)
        }
        let parm = {
            name: '',
            pageIndex: 1,
            pageSize: defualtPageSize(),
            orderBy: '',
            sort: ''
        }
        if (roleId == '') {
            let roleIdt = await (await this.RoleLgc.querysList(parm)).data.data.dataSource
            roleId = roleIdt.length > 0 ? roleIdt[0].id : ''
        }
        const r = await axios({
            method: "POST",
            url: Tools.getApiAddress("userrole_delete"),
            data: {
                roleId: roleId,
                userIds: userIds
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })

        if (r.data.statusCode == 200) {
            message.success("删除成功")
        } else {
            message.error(r.data.returnMessage)
        }
    }
    async selecRole({ dispatch, dataList, roleId }: { dispatch: any; dataList: any; roleId: any }) {
        let powerIds = []
        for (let i = 0; i < dataList.length; i++) {
            for (let k = 0; k < dataList[i].powers.length; k++) {
                if (dataList[i].powers[k].checked) {
                    powerIds.push(dataList[i].powers[k].id)
                }
            }
        }
        const r = await axios({
            method: "POST",
            url: Tools.getApiAddress("auth_upd"),
            data: {
                powerIds: powerIds,
                roleId: roleId
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })
        if (r.data.statusCode == 200) {
            message.success("设置成功")
        } else {
            message.error(r.data.message)
        }
    }
    async loadPage({ dispatch, pageIndex, pageSize, search }) {
        this.setLoading({dispatch,loading: true})
        const { RoleId } = search;

        const r = await new UserRoleLogic().querysList(RoleId);

        if (r.data.statusCode == 200) {
            const dataList = r.data.data;
            const list = dataList.map((v, i) => {
                v.key = i + 1;
                return v;
            })
            this.setPaging({ dispatch, pageIndex, total: list.length, dataList: list, pageSize });
        }  this.setLoading({dispatch,loading: false})

    }
    async addsSubmit({ dispatch, roleId, selectLists }) {
        let userIds = []
        for (let i = 0; i < selectLists.length; i++) {
            userIds.push(selectLists[i].userID)
        }
        let param = {
            roleId,
            userIds: userIds
        }

        const r = await this.UserRoleLgc.addData(param);
        if (r.data.statusCode == 200) {
            message.success("添加成功");
        } else {
            message.error(r.data.message);
        }
        return r.data.statusCode == 200;
    }
    async loadSearchPage({ dispatch, pageIndex, pageSize, roleId }) {
        // 权限列表
        let parm = {
            roleId: roleId
        }
        if (roleId == '') {
            let roleIdt = await (await this.UserRoleLgc.querysList(parm)).data.data.dataSource
            roleId = roleIdt.length > 0 ? roleIdt[0].id : ''
        }


        const r = await new UserRoleLogic().searchData(roleId);

        if (r.data.statusCode == 200) {
            const dataList = r.data.data;
            const list = dataList.map((v, i) => {
                v.key = i + 1;
                return v;
            })
            dispatch({ type: "userList", payload: { UserList: list } })
            // this.setPaging({ dispatch, pageIndex, total: list.length, dataList: list, pageSize });
        }

    }

    setCondition(method: "Keywords" | "RoleId", value, dispatch) {

        if ("Keywords" == method) {
            dispatch({ type: "SetSearch", payload: { keywords: value } });
        }
        if ("RoleId" == method) {
            dispatch({ type: "SetSearch", payload: { RoleId: value } });
        }
    }
}
export class RoleListAction extends DataListAction {
    private RoleLgc = new RoleLogic();
    async delRow(id) {

    }
    delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {

    }
    // 角色列表
    async rolePage({ dispatch, pageIndex, pageSize }) {
        let parm = {
            name: '',
            pageIndex: 1,
            pageSize: defualtPageSize(),
            orderBy: '',
            sort: ''
        }
        const r = await this.RoleLgc.querysList(parm);

        if (r.data.statusCode == 200) {
            const dataList = r.data.data.dataSource;

            const list = dataList.map((v, i) => {
                v.key = i + 1;
                return v;
            })
            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: list, pageSize });

            return dataList;
        }

    }
}
export const fileAction = new FileAction();
export const roleAction = new RoleListAction();