import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
  TimePicker,
  DatePicker,
  Tag,
} from "antd";
import React, { useContext } from "react";
import { FormSubtitle } from "../../../../commodity/view/form_subtitle";
import styles from "../../../style/inventory_goods_formbasic.module.scss";

import { useBoolean } from "ahooks";
import { ChooseShop } from "../../../../../component/choose-shop";
import { formAction } from "../../../action/exchange/goods/form_action";
import { FormStore } from "../../../store/exchange/goods/form_store";
import { autoRem } from "../../../../../global.style";
import { useState } from "react";
import { RadioStyle } from "../../../../../component/radio-style";
import moment from "moment";

export function FormBasic({ title, disabled, updateFormField, good }) {
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  /** 是否选择店铺 */
  const [shopState, shopFunc] = useBoolean(false);
  /** 是否选择商品 */
  const [goodsState, goodsFunc] = useBoolean(false);

  /** 表单状态 */
  const { state, dispatch } = useContext(FormStore);
  /** 表单 */
  const { formData } = state;

  /** 表单action */
  const action = formAction;
  /**兑换方式 */
  function ExchangeType({ value, switchValue }) {
    const [] = useState(1);
    const { dispatch } = useContext(FormStore);

    const onChange = (e) => {
      if (!disabled) {
        action.setFormData({
          dispatch,
          key: "exchangeType",
          value: e.target.value,
        });
        if (formData.exchangeType == 1) {
          action.setFormData({ dispatch, key: "exchangeMoney", value: null });
          action.setFormData({ dispatch, key: "exchangePoints2", value: null });
        }
        if (formData.exchangeType == 2) {
          action.setFormData({ dispatch, key: "exchangePoints1", value: null });
          updateFormField("exchangePoints1", null);
        }
      }
    };
    return (
      <RadioStyle>
        <Radio.Group onChange={onChange} value={switchValue}>
          <Radio
            value={1}
            style={{ marginLeft: "15px", height: "30px", lineHeight: "30px" }}
          >
            自定义积分兑换
          </Radio>
          <Radio
            value={2}
            style={{ marginLeft: "15px", height: "30px", lineHeight: "30px" }}
          >
            金额+积分
          </Radio>
        </Radio.Group>
      </RadioStyle>
    );
  }
  /**生效时间 */
  function TimeType({ value, takeType }) {
    const [] = useState(1);
    // const { dispatch } = useContext(PrecisionFormStore);
    const onChange = (e) => {
      if (!disabled) {
        action.setFormData({
          dispatch,
          key: "takeType",
          value: e.target.value,
        });
      }
      if (e.target.value == 1) {
        action.setFormData({ dispatch, key: "takeBeginDate", value: "" });
        action.setFormData({ dispatch, key: "takeEndDate", value: "" });
      }
    };
    return (
      <RadioStyle>
        <Radio.Group onChange={onChange} value={takeType}>
          <Radio
            value={1}
            style={{ marginLeft: "15px", height: "30px", lineHeight: "30px" }}
          >
            立即生效
          </Radio>
          <Radio
            value={2}
            style={{ marginLeft: "10px", height: "30px", lineHeight: "30px" }}
          >
            指定时间段生效
          </Radio>
        </Radio.Group>
      </RadioStyle>
    );
  }
  /**限制每人兑换次数 */
  function ExchangeTimes({ value, switchValue }) {
    const [] = useState(1);
    // const { dispatch } = useContext(PrecisionFormStore);
    const onChange = (e) => {
      if (!disabled) {
        action.setFormData({
          dispatch,
          key: "isLimitCount",
          value: e.target.value,
        });
      }
      if (e.target.value == 1) {
        action.setFormData({ dispatch, key: "exchangeCount", value: 0 });
      }
    };
    return (
      <RadioStyle>
        <Radio.Group onChange={onChange} value={switchValue}>
          <Radio
            value={1}
            style={{ marginLeft: "15px", height: "30px", lineHeight: "30px" }}
          >
            不限制
          </Radio>
          <Radio
            value={2}
            style={{ marginLeft: "10px", height: "30px", lineHeight: "30px" }}
          >
            限制
          </Radio>
        </Radio.Group>
      </RadioStyle>
    );
  }
  const onangeChange = (time, timeString) => {
    action.setFormData({
      dispatch,
      key: "takeBeginDate",
      value: timeString[0],
    });
    action.setFormData({ dispatch, key: "takeEndDate", value: timeString[1] });
  };
  const disabledDate = (current) => {
    return current < moment().startOf("day");
  };
  let gradecheck =
    formData.isMemberClass == 1
      ? {}
      : {
          required: true,
          validator: (_, __, callBack: (error?: string) => void) => {
            if (
              state.formData.memberClasss == undefined ||
              state.formData.memberClasss.length == 0
            ) {
              callBack("请选择会员等级");
              return;
            }
            callBack();
          },
        };
  let checkMoney = [
    {
      required: true,
      validator: (_, __, callBack: (error?: string) => void) => {
        if (
          state.formData.exchangeMoney == undefined ||
          state.formData.exchangeMoney == null
        ) {
          callBack("请输入金额");
          return;
        } else if (
          state.formData.exchangeMoney == 0 ||
          !/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/.test(
            formData.exchangeMoney.toString()
          )
        ) {
          callBack("金额应为大于0的数");
          return;
        }
        callBack();
      },
    },
  ];
  let checkPoints = [
    {
      required: true,
      validator: (_, __, callBack: (error?: string) => void) => {
        if (state.formData.exchangePoints2 == undefined) {
          callBack("请输入积分");
          return;
        } else if (
          !/^\+?[1-9][0-9]*$/.test(formData.exchangePoints2.toString())
        ) {
          callBack("积分应为大于0的整数");
          return;
        }
        callBack();
      },
    },
  ];
  let height = document.body.clientHeight - 188;
  return (
    <div className={styles.root} style={{ height: height + "px" }}>
      {good && (
        <div className={styles.content}>
          <FormSubtitle>商品信息</FormSubtitle>
          <div
            className={styles.good_info}
          >
            <span style={{width: 200}}>商品编号：{good.goodsNo}</span>
            {/* <span>规格条码：{good.barCode}</span> */}
            <span>商品名称：{good.goodsName}</span>
            {/* <span>
              店铺档案价：￥{formData.shopPrice ? formData.shopPrice : 0}
            </span> */}
            {/* <span>
              商城档案价：￥{formData.storePrice ? formData.storePrice : 0}
            </span> */}
            {/* <span>兑换价值：10积分</span> */}
          </div>
          <div
            className={styles.good_info}
          >
            {/* <span>商品编号：{good.goodsNo}</span> */}
            <span style={{width: 200}}>规格条码：{good.barCode}</span>
            {/* <span>商品名：{good.goodsName}</span> */}
            <span  style={{width: 200}}> 
              店铺档案价：￥{formData.shopPrice ? formData.shopPrice : 0}
            </span>
            <span>
              商城档案价：￥{formData.storePrice ? formData.storePrice : 0}
            </span>
            {/* <span>兑换价值：10积分</span> */}
          </div>
        </div>
      )}
      <div className={styles.content}>
        <FormSubtitle>规则信息</FormSubtitle>

        <div
          className="custom-radio"
          style={{
            margin: "" + autoRem(1.9375) + " " + autoRem(1.25) + " 0 0",
          }}
        >
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 3 }}
                label="兑换方式"
                //   name="exchangeType"
                style={{ marginBottom: 0 }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <ExchangeType value={1} switchValue={formData.exchangeType} />
                  {formData.exchangeType == 1 && (
                    <>
                      <Form.Item
                        name="exchangePoints1"
                        style={{ display: "inline-block" }}
                        rules={[
                          {
                            required: true,
                            message: "请输入积分",
                          },
                          {
                            pattern: /^\+?[1-9][0-9]*$/,
                            message: "积分应为大于0的整数",
                          },
                        ]}
                      >
                        <Input
                          maxLength={20}
                          placeholder="请输入积分"
                          style={{ width: "400px", marginTop: "10px" }}
                          disabled={disabled}
                          onChange={(e) =>
                            action.setFormData({
                              dispatch,
                              key: "exchangePoints1",
                              value: e.target.value,
                            })
                          }
                        />
                      </Form.Item>
                    </>
                  )}
                  {formData.exchangeType == 2 && (
                    <div
                      style={{
                        marginTop: "10px",
                        display: "flex",
                        alignContent: "center",
                      }}
                    >
                      <Form.Item
                        name="exchangeMoney"
                        style={{ display: "inline-block" }}
                        rules={checkMoney}
                      >
                        <span>
                          {" "}
                          使用
                          <Input
                            maxLength={20}
                            placeholder="请输入金额"
                            style={{ width: "100px", margin: "0 5px" }}
                            value={formData.exchangeMoney}
                            disabled={disabled}
                            onChange={(e) =>
                              action.setFormData({
                                dispatch,
                                key: "exchangeMoney",
                                value: e.target.value,
                              })
                            }
                          />
                          金额+
                        </span>
                      </Form.Item>
                      <Form.Item
                        name="exchangePoints2"
                        style={{ display: "inline-block" }}
                        rules={checkPoints}
                      >
                        <Input
                          maxLength={20}
                          placeholder="请输入积分"
                          style={{ width: "100px", margin: "0 5px" }}
                          value={formData.exchangePoints2}
                          disabled={disabled}
                          onChange={(e) => {
                            action.setFormData({
                              dispatch,
                              key: "exchangePoints2",
                              value: e.target.value,
                            });
                            updateFormField("exchangePoints2", e.target.value);
                          }}
                        />
                        积分兑换商品
                      </Form.Item>
                    </div>
                  )}
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              {formData.shippingFees ? "" : ""}
              <Form.Item
                labelCol={{ span: 3 }}
                label="配送费"
                name="shippingFees"
                rules={[
                  // {
                  //   required: true,
                  //   message: "请输入配送费",
                  // },
                  // {
                  //   pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
                  //   message: "配送费应该大于0.01的数",
                  // },
                  {
                    validator: (_, __, callBack: (error?: string) => void) => {
                      if (
                        formData.shippingFees != undefined &&
                        formData.shippingFees != null &&
                        formData.shippingFees != 0
                      ) {
                        if (formData.shippingFees < 0) {
                          callBack("配送费应该大于0");
                          return;
                        }
                        if (formData.shippingFees < 0.01) {
                          callBack("配送费应该大于0.01");
                          return;
                        }
                      }
                      callBack();
                    },
                  },
                ]}
              >
                <Input
                  maxLength={20}
                  placeholder="请输入配送费"
                  type="number"
                  min={0}
                  style={{ width: "400px" }}
                  disabled={disabled}
                  // value={formData.issueCount}
                  onChange={(e) => {
                    action.setFormData({
                      dispatch,
                      key: "shippingFees",
                      value: e.target.value,
                    });
                    updateFormField("shippingFees", e.target.value);
                    // formData.isLimitCount == 2 &&
                    //   updateFormField("shippingFees", formData.exchangeCount);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              {formData.exchangeCount ? "" : ""}
              {formData.issueCount ? "" : ""}
              <Form.Item
                labelCol={{ span: 3 }}
                label="可发放数量"
                name="issueCount"
                rules={[
                  {
                    required: true,
                    message: "请输入可发放的数量",
                  },
                  {
                    pattern: /^\+?[1-9][0-9]*$/,
                    message: "可发放数量应为大于0的整数",
                  },
                  {
                    validator: (_, __, callBack: (error?: string) => void) => {
                      // if ( 
                      //   Number(formData.issueCount) > Number(formData.sumNumber)
                      // ) {
                      //   callBack("发放数量不能大于该商品库存");
                      //   return;
                      // }
                      callBack();
                    },
                  },
                ]}
              >
                <Input
                  maxLength={20}
                  placeholder="请输入发放数量"
                  style={{ width: "400px" }}
                  disabled={disabled}
                  // value={formData.issueCount}
                  onChange={(e) => {
                    action.setFormData({
                      dispatch,
                      key: "issueCount",
                      value: e.target.value,
                    });
                    updateFormField("issueCount", e.target.value);
                    formData.isLimitCount == 2 &&
                      updateFormField("exchangeCount", formData.exchangeCount);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 3 }}
                label="生效时间"
                name="takeType"
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <TimeType value={1} takeType={formData.takeType} />
                  {formData.takeType == 2 && (
                    <Form.Item
                      name="takeBeginDate,takeBeginDate"
                      rules={[
                        {
                          required: true,
                          validator: (
                            _,
                            __,
                            callBack: (error?: string) => void
                          ) => {
                            if (
                              formData.takeBeginDate == undefined ||
                              formData.takeBeginDate == "" ||
                              formData.takeBeginDate == null
                            ) {
                              callBack("请选择生效日期");
                              return;
                            }
                            callBack();
                          },
                        },
                      ]}
                    >
                      <RangePicker
                        disabledDate={disabledDate}
                        disabled={disabled}
                        defaultValue={[
                          formData.takeBeginDate != "" &&
                          formData.takeBeginDate != undefined &&
                          formData.takeBeginDate != null
                            ? moment(formData.takeBeginDate, "YYYY-MM-DD")
                            : null,
                          formData.takeEndDate != "" &&
                          formData.takeEndDate != undefined &&
                          formData.takeEndDate != null
                            ? moment(formData.takeEndDate, "YYYY-MM-DD")
                            : null,
                        ]}
                        style={{ width: "400px", marginTop: "10px" }}
                        onChange={onangeChange}
                      />
                    </Form.Item>
                  )}
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 3 }}
                label="会员等级"
                style={{ marginBottom: 0 }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Checkbox
                    style={{ marginLeft: "15px" }}
                    disabled={disabled}
                    checked={formData.isMemberClass == 1}
                    onChange={(e) => {
                      if (e.target.checked) {
                        action.setFormData({
                          dispatch,
                          key: "memberClasss",
                          value: null,
                        });
                      }
                      action.setFormData({
                        dispatch,
                        key: "isMemberClass",
                        value: e.target.checked ? 1 : 2,
                      });
                    }}
                  >
                    不限制
                  </Checkbox>
                  <Form.Item name="memberClasss" rules={[gradecheck]}>
                    {formData.memberClasss ? "" : ""}
                    <Select
                      mode="multiple"
                      style={{ width: "400px", marginTop: "10px" }}
                      placeholder="请选择"
                      disabled={disabled || formData.isMemberClass == 1}
                      value={formData.memberClasss}
                      onChange={(v) =>
                        action.setFormData({
                          dispatch,
                          key: "memberClasss",
                          value: v,
                        })
                      }
                    >
                      {state.tagList.map((v) => (
                        <Option value={v.id} key={v.id}>
                          {v.gradeName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 3 }}
                label="每人兑换数量"
                name="isLimitCount"
                // style={{marginBottom: 0}}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <ExchangeTimes
                    value={1}
                    switchValue={formData.isLimitCount}
                  />

                  {formData.isLimitCount == 2 && (
                    <>
                      {formData.exchangeCount ? "" : ""}
                      {formData.issueCount ? "" : ""}
                      <Form.Item
                        name="exchangeCount"
                        rules={[
                          {
                            required: true,
                            message: "请输入兑换数量",
                          },
                          {
                            pattern: /^\+?[1-9][0-9]*$/,
                            message: "兑换数量应为大于0的整数",
                          },
                          {
                            validator: (
                              _,
                              __,
                              callBack: (error?: string) => void
                            ) => {
                              if (
                                Number(formData.exchangeCount) >
                                Number(formData.issueCount)
                              ) {
                                callBack("限制数量不能大于发放量");
                                return;
                              }
                              callBack();
                            },
                          },
                        ]}
                      >
                        {formData.exchangeCount ? "" : ""}
                        {formData.issueCount ? "" : ""}
                        <Input
                          maxLength={20}
                          placeholder="请输入限制兑换数量"
                          disabled={disabled}
                          style={{ width: "400px", marginTop: 10 }}
                          value={formData.exchangeCount}
                          onChange={(e) => {
                            action.setFormData({
                              dispatch,
                              key: "exchangeCount",
                              value: e.target.value,
                            });
                            updateFormField("exchangeCount", e.target.value);
                            updateFormField("issueCount", formData.issueCount);
                            // console.log(
                            //   formData.exchangeCount,
                            //   formData.issueCount,
                            //   formData.exchangeCount > formData.issueCount
                            // );
                          }}
                        />
                      </Form.Item>
                    </>
                  )}
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 0 }}>
            <Col span={24}>
              <Form.Item
                label="分配门店"
                labelCol={{ span: 3 }}
                name="shopName"
                rules={[
                  {
                    required: true,
                    validator: (_, __, callBack: (error?: string) => void) => {
                      if (
                        state.formData.shopID == undefined ||
                        state.formData.shopID.length == 0
                      ) {
                        callBack("请选择分配门店");
                        return;
                      }
                      callBack();
                    },
                  },
                ]}
              >
                {formData.shopName ? "" : ""}
                <Input
                  maxLength={20}
                  style={{ width: "400px" }}
                  disabled={disabled}
                  value={formData.shopName}
                  autoComplete="off"
                  placeholder="请选择门店"
                  onClick={() => shopFunc.setTrue()}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <ChooseShop
          visibleModal={shopState}
          type="checkbox"
          close={(status, r) => {
            if (r) {
              let arr = [];
              let shopNames = []
              if (r.length > 0) {
                r.map((item) => {
                  arr.push(item.id);
                  shopNames.push(item.shopName)
                });
              }
              action.setFormData({ dispatch, key: "shopID", value: arr });
              action.setFormData({
                dispatch,
                key: "shopName",
                value: shopNames.toString(),
              });
            }
            updateFormField("shopName");
            shopFunc.setFalse();
          }}
        />
      </div>
    </div>
  );
}
