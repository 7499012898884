import { useBoolean, useRequest } from "ahooks";
import { Input, Modal, Select, Tree, Button, TreeSelect, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect, useState } from "react";
import { Search } from "../search";
import { DataTable } from "../table";
import {
  DataTableStore,
  DataTableStoreProvider,
} from "../table/store/data_table_store";
import { ChooseGoodsStore, ChooseStoreProvider } from "./store/choose_store";
import styles from "./style/index.module.scss";
import { Select as SelectAntd } from "antd";
import { chooseGoodsAction } from "./action/choose_action";
import { defualtPageSize } from "../../logic/common_logic";

let classId: string[] = [];

function Goods(params: IChooseGoods) {
  let {
    visibleModal,
    close,
    filter,
    type,
    shopID,
    savekeysNo,
    clearSelectedList,
    rowKey,
    chooseType
  } = params;
  const { Option } = Select,
    action = chooseGoodsAction;
  const { state, dispatch } = useContext(ChooseGoodsStore);
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);

  const handleOk = () => {
    close && close(true, tableState.selectList);
  };

  const handleCancel = () => {
    close && close(false, null);
  };

  const columns: ColumnsType = [
   
    {
      title: "商品名称",
      dataIndex: "goodsName",
      key: "1",
      align: "center",
    },
    {
      title: "商品编码",
      dataIndex: "goodsNo",
      key: "2",
      align: "center",
    },
    {
      title: "商品分类",
      dataIndex: "goodsClassName",
      key: "3",
      align: "center",
    },
    {
      title: "单位",
      // dataIndex: 'unitName',
      key: "4",
      align: "center",
      render: (v) => (
        <span>
          {/* {v.unitContent} */}
          1/
          {v.unitName}
        </span>
      ),
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "6",
      align: "center",
      render: (v) => <span>{v == 1 ? "上架" : "下架"}</span>,
    },
    {
      title: "商品来源",
      dataIndex: "goodsSource",
      key: "7",
      align: "center",
      render: (v) => (
        <>
          {v == 1 && <Tag color="blue">自定义</Tag>}
          {v == 2 && <Tag color="red">仓库采购</Tag>}
        </>
      ),
    },
  ];
if(rowKey == 'storageBatchNumber'){
  columns.unshift({
      title: "批次号",
      dataIndex: "storageBatchNumber",
      key: "0",
      align: "center",
      width: 190
  })
}
if(rowKey == 'barCode'){
  columns[1]  = {
  title: "规格条码",
  dataIndex: "barCode",
  key: "2",
  align: "center",
  }
  
}
  const onSearch = async (pageIndex, classId?) => {
    // 折扣活动 特价活动
    if(chooseType == 'discount' || chooseType == 'special' || chooseType == 'coupon'){
      await action.loadPageSpecification({
        pageIndex: pageIndex,
        pageSize: defualtPageSize(),
        dispatch: tableDispatch,
        state,
        search: {
          ...state.search,
          classesId: classId,
          shopID: shopID,
          source: params.filter.source || 'CS',
        },
      });
    } else if(chooseType == 'AlarmSet'){
      await action.loadPage({
        pageIndex: pageIndex,
        pageSize: defualtPageSize(),
        dispatch: tableDispatch,
        state,
        search: { ...state.search, classesId: classId },
      });
    } else if (chooseType == 'exchange'){
      await action.loadPageSpecification({
        pageIndex: pageIndex,
        pageSize: defualtPageSize(),
        dispatch: tableDispatch,
        state,
        search: {
          ...state.search,
          classesId: classId,
          shopID: shopID,
          source: 'WX',
        },
      });
    }
    else{
      if (params.filter && params.filter.zeroInventory) {
        await action.loadPageByZeroInventory({
          pageIndex: pageIndex,
          pageSize: defualtPageSize(),
          dispatch: tableDispatch,
          state,
          search: { ...state.search, classesId: classId },
        });
      } else if (params.filter && params.filter.source) {
        if (params.filter.source == "wx" || params.filter.source == "WX") {
          await action.loadPageSpecificationForMini({
            pageIndex: pageIndex,
            pageSize: defualtPageSize(),
            dispatch: tableDispatch,
            state,
            search: {
              ...state.search,
              classesId: classId,
              type: params.filter.type ? "specification" : null,
            },
          });
        } else {
          await action.loadPageSpecification({
            pageIndex: pageIndex,
            pageSize: defualtPageSize(),
            dispatch: tableDispatch,
            state,
            search: {
              ...state.search,
              classesId: classId,
              shopID: shopID,
              source: params.filter.source,
            },
          });
        }
      } else if (params.filter && params.filter.specifications) {
        await action.loadPageSpecification({
          pageIndex: pageIndex,
          pageSize: defualtPageSize(),
          dispatch: tableDispatch,
          state,
          search: { ...state.search, classesId: classId, shopID: shopID },
        });
      } else if (params.filter && params.filter.isStore) {
        await action.loadPage({
          pageIndex: pageIndex,
          pageSize: defualtPageSize(),
          dispatch: tableDispatch,
          state,
          search: {
            ...state.search,
            classesId: classId,
            isStore: params.filter.isStore ? params.filter.isStore : null,
          },
        });
      } else {
        //console.log(74)
        await action.loadPage({
          pageIndex: pageIndex,
          pageSize: defualtPageSize(),
          dispatch: tableDispatch,
          state,
          search: { ...state.search, classesId: classId },
        });
      }
    }
    
  };

  useRequest(() => action.init({ dispatch }));

  const checkSelectable = (record) => {
    let flag = false;
    if(chooseType == 'discount' || chooseType == 'special' || chooseType == 'coupon'){
      if(record.isNoMarketing){
        return true
      }else{
        return false
      }
    }
    if(chooseType == 'AlarmSet'){
      return false
    }
    // 积分兑换  不参与活动不能选择
    if(chooseType == 'exchange'){
      if(record.isNoMarketing){
          return true
      }
    }
    if (params.filter && params.filter.checkInvcount == false) {
      return false;
    }
    if (params.filter && params.filter.source) {
      //    return (record.sumNUmber <= 0)
      if (record.sumNumber <= 0) {
        flag = true;
      }
    }
    if (params.filter && params.filter.specifications) {
      if (record.invckCount <= 0 || record.sumNumber <= 0) {
        flag = true;
      }
    }
    if (params.filter && params.filter.isMarketing) {
      if (record.isNoMarketing) {
        flag = true;
      }
    }
    if (params.filter && params.filter.zeroInventory) {
      if (record.invckCount <= 0 || record.sumNumber <= 0) {
        flag = true;
      }
    }
    let inventoryType = params.filter?.inventoryType
    if(params.filter && (inventoryType == 'check' || inventoryType == 'overOrloss' || inventoryType == 'cost')){
      flag = false
    }
    return flag;
  };
  let tableRowSelection: any = {};
  if (type == "radio") {
    tableRowSelection = {
      rowSelection: {
        type: "radio",
        getCheckboxProps: (record) => ({
          disabled: checkSelectable(record), // Column configuration not to be checked
        }),
      },
    };
  }
 
  let saveKeysOption: any = {};
  if (savekeysNo) {
    saveKeysOption = {
      saveKeys: false,
    };
  }
  useEffect(() => {
    if (clearSelectedList) {
      action.clearSelectedRowKeys({ dispatch });
    }
    return () => {};
  });
  return (
    <Modal
      title="选择商品"
      visible={visibleModal}
      onOk={handleOk}
      onCancel={handleCancel}
      width={1000}
      cancelText="取消"
      okText="确定"
      footer={
        <>
          <Button
            type="primary"
            style={{
              backgroundColor: "#f7ba00",
              borderColor: "#f7ba00",
              color: "white",
            }}
            onClick={handleCancel}
          >
            取消
          </Button>
          <Button type="primary" onClick={handleOk}>
            确认
          </Button>
        </>
      }
    >
      <div className={styles.root}>
        <Search>
          <Select
            style={{ width: "12rem", marginLeft: 10 }}
            size="small"
            onChange={(v) => action.setCondition("Status", v, dispatch)}
            defaultValue={-1}
          >
            {state.statusList.map((v) => (
              <SelectAntd.Option key={v.id} value={v.id}>
                {v.value}
              </SelectAntd.Option>
            ))}
          </Select>
          <Input
            maxLength={20}
            allowClear
            placeholder="请输入商品名称、编码"
            onChange={(e) =>
              action.setCondition("Keywords", e.target.value, dispatch)
            }
            onPressEnter={()=>onSearch(1)}
          />
          <input type="button" value="快速查找" onClick={() => onSearch(1)} />
        </Search>

        <div style={{ marginTop: 10, height: 300 }}>
          <div className={styles.tree}>
            {/* <div style={{textAlign: 'right'}} onClick={()=>{action.setCondition("Classes", "", dispatch);onSearch(1)}}>
                            <span style={{color: '#f7ba00'}}>清除</span>
                        </div> */}
            {/* <Tree
              treeData={state.areaList}
              onSelect={(
                selectedKeys,
                { selected, selectedNodes, node, event }
              ) => {
                let id;
                if (selected) {
                  //判断是几级分类
                  if (node.children && node.children.length > 0) {
                    id = node.children.map((item) => {
                      return item.key as string;
                    });
                  } else {
                    id = [node.key as string];
                  }
                  action.setCondition("Classes", id, dispatch);
                } else {
                  id = [];
                  action.setCondition("Classes", "", dispatch);
                }
                onSearch(1, id);
              }}
            /> */}
            <TreeSelect
              style={{ width: "12rem", marginLeft: 10 }}
              showCheckedStrategy={TreeSelect.SHOW_PARENT}
              dropdownMatchSelectWidth={false}
              defaultValue={state.search.classesId}
              value={state.search.classesId}
              treeCheckable={true}
              treeData={state.areaList}
              onChange={(data) => {
                action.setCondition("Classes", data, dispatch);
                onSearch(1, data);
              }}
              placeholder="选择商品分类"
            />
          </div>
          <div className={styles.list}>
            <DataTable
              columns={columns}
              action={action}
              saveKeys={true}
              rowKey={rowKey?rowKey:'id'}
              rowSelection={{
                type: "checkbox",
                getCheckboxProps: (record) => ({
                  disabled: checkSelectable(record), // Column configuration not to be checked
                }),
              }}
              {...tableRowSelection}
              onLoad={() => onSearch(1)}
              onChange={(pageIndex) => {
                onSearch(pageIndex);
              }}
              selectColumn={true}
              {...saveKeysOption}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

type Filter = {
  zeroInventory?: boolean;
  specifications?: boolean;
  source?: string;
  isMarketing?: boolean;
  isStore?: boolean;
  checkInvcount?: boolean;
  type?: boolean;
  inventoryType?:string
};
interface IChooseGoods {
  visibleModal: boolean;
  close?: (status, r) => void;
  filter?: Filter;
  type?;
  shopID?;
  clearSelectedList?: boolean;
  savekeysNo?;
  rowKey?: string
  chooseType?:string

}
export function ChooseGoods(params: IChooseGoods) {
  return (
    <ChooseStoreProvider>
      <DataTableStoreProvider>
        <Goods {...params} />
      </DataTableStoreProvider>
    </ChooseStoreProvider>
  );
}
