import React, { useContext, useEffect } from "react";
import { Form as FormAntd, message } from "antd";
import { Layer } from "../../../../../component/layer/layer";
import { FormBasic } from "./from_basic";
import { Button } from "../../../../../component/button";
import { FormStore } from "../../../store/exchange/goods/form_store";
import { formAction } from "../../../action/exchange/goods/form_action";
import useRequest from "@ahooksjs/use-request";

interface IForm {
    children?: any;
    action: any;
    close: (hasUpdate?: boolean) => void;
    id?: string;
    subId: string;
    method?: string;
    audit: boolean;
    item;
}
export function Form({ close, method, id, subId, item, audit }: IForm) {
    const [form] = FormAntd.useForm();

    const { state, dispatch } = useContext(FormStore);

    const action = formAction;

    useEffect(() => {

        action.init({ state: state, dispatch, id:subId, form, method });

        return () => {

        }
    }, []);

    const title = () => {
        if ("Create" === method) {
            return "配置规则";
        }
        else if ("Edit" === method) {
            return "配置规则";
        }
        else {
            return "预览规则";
        }
    }
    const { loading, run } = useRequest((()=>action.addSubmit({ state, status: 1 },subId)),{
        manual: true,
        onSuccess:(result)=>{
            message.success('保存成功');
            action.clearTableList({ dispatch });
            close(true);
        },
        onError: (e)=>{
            message.error(e)
        }
    })
    return (
        <FormAntd form={form}>
            <Layer layerWith={3} >
                <Layer.Title onClick={() => close()}>{title()}</Layer.Title>
                <Layer.Body>
                    <FormBasic disabled={!("Create" == method || "Edit" == method)} title={title()} good={item} updateFormField={(field, value = '') => {
                        //为表单某个字段设置数据
                        form.setFieldsValue({
                            [field]: value,
                        });
                        //验证表单的某个字段
                        form.validateFields([field]);
                    }} />
                </Layer.Body>
                <Layer.Foot borderStyle={title() == '配置规则' ? { border: 'none' } : {}}>
                    {
                        ("Create" == method || "Edit" == method) && audit && (
                            <>   <Button type="primary" color="Red" onClick={async () => {
                                const s = await form.validateFields();
                                let r = false;
                                if ("Create" == method) {
                                    // r = await action.addSubmit({ state, status: 1 });
                                    run();
                                } else {
                                    // run();
                                    r = await action.updSubmit({ state, status: 1 }, subId);
                                }
                                if (r) {
                                    action.clearTableList({ dispatch });

                                    close(true);
                                }
                            }} loading={loading}>
                                保存
                            </Button>
                                <Button type="primary" color="Yellow" style={{ marginLeft: "1.25rem" }} onClick={async () => {
                                    action.clearTableList({ dispatch });
                                    close(true);
                                    // action.reset({dispatch})
                                }}>
                                    返回
                                </Button>

                            </>
                        )
                    }



                </Layer.Foot>

            </Layer>
        </FormAntd>
    )
}