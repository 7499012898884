import { useBoolean, useRequest } from "ahooks";
import { Input, Modal, Select, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext } from "react";
import { Search } from "../search";
import { DataTable } from "../table";
import {
  DataTableStore,
  DataTableStoreProvider,
} from "../table/store/data_table_store";
import { ChooseMemberStore, ChooseStoreProvider } from "./store/choose_store";
import styles from "./style/index.module.scss";
import { Select as SelectAntd } from "antd";
import { chooseCouponAction } from "./action/choose_action";
import { defualtPageSize } from "../../logic/common_logic";
import { Button } from "../../component/button";
let classId = "";

function ScaleDetailContainer(params: IChooseMember) {
  const { visibleModal, close, logId } = params;
  const { Option } = Select,
    action = chooseCouponAction;

  const { state, dispatch } = useContext(ChooseMemberStore);
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);

  const handleOk = () => {
    close && close(true, tableState.selectList);
  };

  const handleCancel = () => {
    close && close(false, null);
  };

  const columns: ColumnsType = [
    {
      title: "商品编码",
      dataIndex: "goodsNo",
      key: "2",
      align: "center",
    },
    {
      title: "商品名称",
      dataIndex: "goodsName",
      key: "3",
      align: "center",
    },
    {
      title: "PLU码",
      dataIndex: "barCode",
      key: "4",
      align: "center",
    },

    {
      title: "最新价格",
      dataIndex: "newPrice",
      key: "5",
      align: "center",
    },
  ];

  // useRequest(() => action.init({ dispatch }));
  const onSearch = async (pageIndex) => {
    await action.loadPage({
      pageIndex: pageIndex,
      pageSize: defualtPageSize(),
      dispatch: tableDispatch,
      search: state.search,
      logId,
    });
  };
  return (
    <Modal
      title="下发记录详细"
      visible={visibleModal}
      onOk={handleOk}
      onCancel={handleCancel}
      width={1000}
      footer={
        <Button type="primary" color="Red" onClick={handleCancel}>
          返回
        </Button>
      }
    >
      <div className={styles.root}>
        <div style={{ marginTop: 10 }}>
          <DataTable
            columns={columns}
            action={action}
            saveKeys={true}
            // rowKey={(record) => record.id}
            onLoad={() => {
              onSearch(1);
            }}
            onChange={(pageIndex) => {
              onSearch(pageIndex);
            }}
            scroll={{ y: 400 }}
          />
        </div>
      </div>
    </Modal>
  );
}

interface IChooseMember {
  visibleModal: boolean;
  logId: string;
  close?: (status, r) => void;
}
export function ScaleDetail(params: IChooseMember) {
  return (
    <ChooseStoreProvider>
      <DataTableStoreProvider>
        <ScaleDetailContainer {...params} />
      </DataTableStoreProvider>
    </ChooseStoreProvider>
  );
}
