import { Form, TreeSelect, Cascader } from "antd";
import { useEffect } from "react";
import { useState, useContext } from "react";
import { ApiCon } from "../../com/apiCon";
import { ISelectGoods } from "../../com/ISelectType";
import { SelectStore } from "../store/SelectStore";

function parseClassifyBySelect(v) {
  return v
    ? v.map((v) => {
        return {
          value: v.id,
          label: v.classesName,
          children: parseClassifyBySelect(v.subGoodsClassesVM),
        };
      })
    : [];
}

let getGoodsClassPromise: Promise<any>;
/** 获取所有商品分类 */
function getGoodsClass(): Promise<any[]> {
  if (!getGoodsClassPromise) {
    //加载数据
    getGoodsClassPromise = ApiCon.goodsClass().then((data) => {
      return parseClassifyBySelect(data);
    });
  }
  return getGoodsClassPromise;
}

/**
 * 选择商品
 */
export function SelectGoods() {
  const { state, dispatch } = useContext<{
    state: ISelectGoods;
    dispatch: (state: ISelectGoods) => void;
  }>(SelectStore);

  const [goodsClass, setGoodsClass] = useState([]);

  useEffect(() => {
    if (goodsClass.length == 0) {
      getGoodsClass().then((data) => {
        setGoodsClass(data);
      });
    }
  });

  return (
    <div className="item_">
      <Form.Item label="商品分类">
        <TreeSelect
          style={{ width: 175 }}
          showCheckedStrategy={TreeSelect.SHOW_PARENT}
          dropdownMatchSelectWidth={false}
          defaultValue={state.goodsClassID}
          value={state.goodsClassID}
          treeCheckable={true}
          treeData={goodsClass}
          onChange={(data) => {
            dispatch({
              ...state,
              // goodsClassID: data.length > 0 ? [data[data.length - 1]] : [],
              goodsClassID: data || [],
            });
          }}
          placeholder="选择商品分类"
        />
      </Form.Item>
      {/* <Form.Item label="商品分类">
        <Cascader allowClear autoComplete="off"  style={{ width: "160px" }} options={goodsClass} placeholder="请选择商品分类" 
            onChange={(v) =>{let id ;if(v.length ==1){id=v[0]}else{id=v[1]}
            // action.setValue({ dispatch, value: id, attribute: "ClassesId" })
            dispatch({
                ...state,
                goodsClassID: [id],
            });
            }} />
         </Form.Item> */}
    </div>
  );
}
