import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss'
import { useBoolean } from 'ahooks';
import {useState, forwardRef,useContext} from 'react'
import { TopicDataAction } from "../page/topic/action/TopicDataAction";
import { ITopicValue, TopicStore } from "../page/topic/store/topic";
interface IData{
  holder:string,
  color:string,
  bgColor:string,
  boxColor:string
}
 function Picker(props) {
     const {defaultColor, type, id} = props;
    const [displayColorPicker,displayColorPickerFunc] = useBoolean(false);
    const [color, setColor] = useState(defaultColor)
    const colorStyle = reactCSS({
        'default': {
          color: {
            width: '36px',
            height: '14px',
            borderRadius: '2px',
            background: `${color}`,
          },
          swatch: {
            padding: '5px',
            background: '#fff',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer',
          },
          popover: {
            position: 'absolute',
            zIndex: '2',
          },
          cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
          },
        },
});
const topicStoreState = useContext(TopicStore)
let actionArg: [ITopicValue, number] = [topicStoreState, id];
const data: IData = TopicDataAction.getData<IData>(...actionArg); 
const setColors = (colors) =>{
  switch(type){
    case 'color': data.color = colors; break;
    case 'bgcolor': data.bgColor = colors;break;
    case 'boxcolor': data.boxColor = colors;break;
  }
  TopicDataAction.setData(...actionArg, data);
}

    return(
        <div >
            <div style={ colorStyle.swatch } onClick={ ()=>{if(!displayColorPicker)displayColorPickerFunc.setTrue(); else displayColorPickerFunc.setFalse()} } >
                        <div style={ colorStyle.color } />
            </div>
            { displayColorPicker ? 
                <div style={ colorStyle.popover }>
                     <div style={ colorStyle.cover } onClick={()=>displayColorPickerFunc.setFalse() }/>
                      <SketchPicker  color={ color } onChange={(color)=>{setColors(color.hex);setColor(color.hex)}}  />
                </div>:null}
        </div>
    )
}

export let ColorPicker  = forwardRef(Picker);