import { message } from "antd";
// import axios from "axios";
import Cookies from "js-cookie";
import { DataListAction } from "../../../../component/data-list/action/data_table_action";
import { DataTableAction } from "../../../../component/table/action/data_table_action";
import { Tools, axios } from "../../../../config";
import { BrandLogic } from "../../../../logic/brand_logic";
import { ClassesLogic } from "../../../../logic/classes_logic";
import { GoodsLogic } from "../../../../logic/goods_logic";
import { InventoryLogic } from "../../../../logic/inventory_logic";
import { UnitLogic } from "../../../../logic/unit_logic";
import { saveAs } from 'file-saver';

export class FileShopAction extends DataTableAction {
    private classesLgc = new ClassesLogic();
    private unitLgc = new UnitLogic();
    private brandLgc = new BrandLogic();

    async init({ dispatch }) {
        // 商品分类
        dispatch({ type: "LoadClasses", payload: await this.classesLgc.selListBySelect() });
    }
    async delRow(id) {
        const r = await axios({
            method: "GET",
            url: Tools.getApiAddress("goods_del"),
            params: {
                goodsId: id
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })

        if (r.data.statusCode == 200) {
            message.success("删除成功")
        }
    }
    delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {
        selectList.map(async ({ id }) => {
            const r = await axios({
                method: "GET",
                url: Tools.getApiAddress("goods_del"),
                params: {
                    goodsId: id
                },
                headers: {
                    SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
                },
            })

            if (r.data.statusCode == 200) {
                message.success("删除成功")
            }
        })
    }
    async loadPage({ dispatch, pageIndex, pageSize, search, type }) {
        // // 商品分类
        // const classifyList = await new ClassesLogic().querysList();

        // // 商品单位
        // const unitList = await new UnitLogic().querysList() || [];
            this.setLoading({dispatch,loading: true})
        // 商品列表
        const { keywords, classesId, iszero, shop } = search;

        const params: any = {
            // "keyWords"?: string;
            // "goodsClass"?: string;
            // "shopID"?: string;
            "pageIndex": pageIndex,
            "pageSize": pageSize,
            seacherType: 2
            // "orderBy"?: string;
            // "sort"?: string;
        }
        if (keywords) {
            params.keyWords = keywords;
        }
        if (classesId) {
            params.goodsClass = classesId.toString() || '';
        }
        if (undefined !== iszero) {
            params.iszero = iszero;
        }
        if (undefined !== shop && shop[0]) {
            params.shopID = shop[0].id;
        }

        const r = await new InventoryLogic().selList(params)

        if (r.data.statusCode == 200) {
            const dataList = r.data.data.dataSource;

            const list = dataList.map((goods_v, i) => {
                // let clsItem = classifyList.filter(v => v.id == goods_v.goodsClassesID);
                // let uniItem = unitList.filter(v => v.id == goods_v.goodsUnitID);

                return {
                    ...goods_v,
                    // goodsClassify: clsItem.length && clsItem[0].classesName,
                    // unit: uniItem.length && uniItem[0].unitName,
                    key: i + 1
                }
            })

            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: list, pageSize,loading: false });
        } else{
            this.setLoading({dispatch, loading: false})
        }

    }

    setCondition({ dispatch, key, value }) {
        const params = {
        }
        params[key] = value;

        dispatch({ type: "SetSearch", payload: params })
    }
    // 导出
    async exportList({ search }) {
        // 商品列表
        const { keywords, classesId, iszero, shop } = search;

        const params: any = {
            // "keyWords"?: string;
            // "goodsClass"?: string;
            // "shopID"?: string;
            // "pageIndex": pageIndex,
            // "pageSize": pageSize,
            seacherType: 2
            // "orderBy"?: string;
            // "sort"?: string;
        }
        if (keywords) {
            params.keyWords = keywords;
        }
        if (classesId) {
            params.goodsClass = classesId;
        }
        if (undefined !== iszero) {
            params.iszero = iszero;
        }
        if (undefined !== shop && shop[0]) {
            params.shopID = shop[0].id;
        }

        const r = await new InventoryLogic().selExportList(params)

        if (r.status == 200) {
            let enc = new TextDecoder('utf-8')
            let data = enc.decode(new Uint8Array(r.data))
            if (data.indexOf('statusCode') != -1) {
                let datas = JSON.parse(data)
                if (datas.statusCode == 200) {
                    let blob = new Blob([r.data], { type: 'application/vnd.ms-excel' })
                    saveAs(blob, "实时库存(按门店).xlsx");
                } else {
                    message.error(datas.returnMessage)
                }
            } else {
                let blob = new Blob([r.data], { type: 'application/vnd.ms-excel' })
                saveAs(blob, "实时库存(按门店).xlsx");
            }
        }

    }
}
export const fileShopAction = new FileShopAction();