import { useBoolean } from "ahooks";
import { Button, Input, Modal, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect, useState } from "react";
import { ButtonPanel } from "../../../../component/button-panel/button_panel";
import { DataTable } from "../../../../component/table";

import img_edit from "../../../../assets/images/bianji.png";
import img_see from "../../../../assets/images/chakan1.png";
import img_del from "../../../../assets/images/shanchu.png";
import { fileAction } from "../../action/menu/file_action";
import { FileStore } from "../../store/menu/file_store";
import { FormStoreProvider } from "../../store/menu/form_store";
import { Form } from "./menu_form";
import { formAction } from "../../action/menu/form_action";
import { DataTableStore } from "../../../../component/table/store/data_table_store";
import { Search } from "../../../../component/search";
import { defualtPageSize, Progress } from "../../../../logic/common_logic";
import { AuthLogic } from "../../../../logic/auth_logic";
import { Control, See, Edit, Del } from "../../../../component/control-column";
import { ConfirmModal } from "../../../../component/modal";
import styles from "../../style/menu.module.scss";

let editId = "";
let seeId = "";

const formParams = {
  method: "Create",
  id: "",
};

export function MenuMannage(viewPower) {
  const [createState, createFunc] = useBoolean(false);
  const [confirmState, confirmFunc] = useBoolean(false);
  const [checkStrictly, setCheckStrictly] = React.useState(false);
  const [deleteState] = useBoolean(false);
  const [] = useBoolean(false);
  const action = fileAction;
  const { state, dispatch } = useContext(FileStore);
  const { state: tableState, dispatch: tableDispatch } =
    useContext(DataTableStore);
  const [menuButton, setData] = useState([]);
  useEffect(() => {
    (async function () {
      let r = await new AuthLogic().selButton(viewPower.viewPower);
      if (r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          r[i] = r[i].split("_")[r[i].split("_").length - 1];
        }
      }
      setData(r);
    })();

    return () => {};
  }, []);
  const columns: ColumnsType = [
    {
      title: "",
      dataIndex: "",
      key: "0",
      width: 60,
      align: "center",
    },
    {
      title: "操作",
      key: "1",
      align: "center",
      width: Control.width(3),
      render: Control.render(
        (v) =>
          menuButton.indexOf("VIEW") != -1 && (
            <See
              click={() => {
                editId = "";
                seeId = v.id;
                createFunc.setTrue();
              }}
            />
          ),
        (v) =>
          menuButton.indexOf("UPDATE") != -1 && (
            <Edit
              click={() => {
                editId = v.id;
                seeId = "";
                createFunc.setTrue();
              }}
            />
          ),
        (v) =>
          menuButton.indexOf("DELETE") != -1 && (
            <Del
              click={() => {
                formParams.id = v.id;
                confirmFunc.setTrue();
              }}
            />
          )
      ),
    },
    {
      title: "菜单名称",
      dataIndex: "name",
      key: "2",
      align: "center",
    },
    {
      title: "图标",
      dataIndex: "icon",
      key: "3",
      align: "center",
    },
    {
      title: "排序",
      dataIndex: "sortIndex",
      key: "4",
      align: "center",
    },
    {
      title: "是否外链",
      dataIndex: "keepAlive",
      key: "5",
      align: "center",
      render: (v) => (
        <>
          {v == "否" ? (
            <Tag color="error">{v}</Tag>
          ) : (
            <Tag color="success">{v}</Tag>
          )}
        </>
      ),
    },
    {
      title: "权限标识",
      dataIndex: "viewPower",
      key: "6",
      align: "center",
      width: 250,
    },
    {
      title: "路由地址",
      dataIndex: "path",
      key: "7",
      align: "center",
    },
    {
      title: "组件路径",
      dataIndex: "component",
      key: "8",
      align: "center",
    },
    {
      title: "是否缓存",
      dataIndex: "isFrame",
      key: "9",
      align: "center",
      render: (v) => (
        <>
          {v == "否" ? (
            <Tag color="error">{v}</Tag>
          ) : (
            <Tag color="success">{v}</Tag>
          )}
        </>
      ),
    },
    {
      title: "状态",
      dataIndex: "hidden",
      key: "10",
      align: "center",
    },
  ];
  const onSearch = async (pageIndex) => {
    await action.loadPage({
      pageIndex: pageIndex,
      pageSize: defualtPageSize(),
      dispatch: tableDispatch,
      search: state.search,
    });
  };
  return (
    <div className={styles.root}>
      <Search>
        <Input
          allowClear
          placeholder="请输入菜单名称"
          maxLength={20}
          onChange={(e) =>
            action.setCondition("Keywords", e.target.value, dispatch)
          }
        />
        <input type="button" value="快速查找" onClick={() => onSearch(1)} />
      </Search>
      <ButtonPanel>
        {menuButton.indexOf("ADD") != -1 && (
          <Button
            onClick={() => {
              formParams.id = "";
              formParams.method = "Create";

              editId = "";
              seeId = "";
              createFunc.setTrue();
            }}
          >
            新建菜单
          </Button>
        )}
        {menuButton.indexOf("EXPORT") != -1 && <Button>导出</Button>}
      </ButtonPanel>
      <DataTable
        columns={columns}
        action={fileAction}
        rowSelection={{ checkStrictly }}
        onLoad={async () => {
          await onSearch(1);
          Progress.done();
        }}
        onChange={(pageIndex) => {
          onSearch(pageIndex);
        }}
        scroll={{ scrollToFirstRowOnChange: true, autoY: true, x: 1060 }}
        loading={tableState.loading}
      />
      {createState && (
        <FormStoreProvider>
          <Form
            close={(hasUpdate) => {
              if (hasUpdate) {
                onSearch(tableState.current);
              }

              createFunc.setFalse();
            }}
            action={formAction}
            editId={editId}
            seeId={seeId}
          />
        </FormStoreProvider>
      )}

      <ConfirmModal
        visible={confirmState}
        title="温馨提示"
        subTitle="确认删除？"
        message="删除之后信息无法恢复，请慎重考虑"
        close={async (r) => {
          if (r) {
            await action.delRow({ dispatch, rowItem: formParams });
            onSearch(tableState.current);
          }
          confirmFunc.setFalse();
        }}
      />
    </div>
  );
}
