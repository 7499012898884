import React, { useContext, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
} from "antd";
import styles from "../../style/file_search.module.scss";
import Search from "antd/lib/input/Search";
import { useBoolean } from "ahooks";
import { ChooseShop } from "../../../../component/choose-shop";
import { ChooseGoods } from "../../../../component/choose-goods";
import { ExpiredAlarmStore } from "../../store/expired-alarm/file_store";
import { expiredAlarmAction } from "../../action/expired-alarm/expiredalarm_action";
const { Option } = Select;

export function ExpiredSearch({ onSearch }) {
  const [shopState, shopFunc] = useBoolean(false);
  const [goodsState, goodsFunc] = useBoolean(false);
  const { state, dispatch } = useContext(ExpiredAlarmStore);
  const action = expiredAlarmAction;
  //   const onangeChange = () => {
  //     action.setCondition({ dispatch, key: "shopName", value: "" });
  //     action.setCondition({ dispatch, key: "shop", value: "" });
  //   };
  //   const onGoodsChange = () => {
  //     action.setCondition({ dispatch, key: "goodsName", value: [] });
  //     action.setCondition({ dispatch, key: "goods", value: [] });
  //   };
  return (
    <div>
      <Form layout="horizontal">
        <div className={styles.row}>
          <div className={styles.cell}>
            <Form.Item label="选择状态">
              <Select
                style={{ width: "15rem" }}
                onChange={(v) => {
                  action.setCondition({
                    dispatch,
                    key: "expiredType",
                    value: v,
                  });
                }}
                defaultValue={-1}
                showSearch
              >
                <Option value={-1}>全部</Option>
                <Option value={1}>即将过期</Option>
                <Option value={2}>已过期</Option>
              </Select>
            </Form.Item>
          </div>
          <div className={styles.cell}>
                        <Form.Item label="选择门店">
                            <Input style={{ width: "15rem" }} value={state.search.shopName} placeholder="请选择" onClick={() => shopFunc.setTrue()} />
                            <span className="ant-input-suffix" style={{ marginLeft: '-24px', zIndex: 99 }} onClick={()=>{}}>
                                <span role="button" aria-label="close-circle" className="anticon anticon-close-circle ant-input-clear-icon"><svg viewBox="64 64 896 896" focusable="false" data-icon="close-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 01-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"></path></svg></span>
                            </span>
                        </Form.Item>
                    </div>
        </div>
        <div className={styles.row}>
          <Button type="primary" danger onClick={() => onSearch()}>
            搜 索
          </Button>
        </div>
      </Form>

       <ChooseShop
        visibleModal={shopState}
        close={(status, r) => {
          if (r) {
            // action.setCondition({
            //   dispatch,
            //   key: "shopName",
            //   value: r[0].shopName,
            // });
            // action.setCondition({ dispatch, key: "shop", value: r });
          }
          shopFunc.setFalse();
        }}
      />
    {/*  <ChooseGoods
        visibleModal={goodsState}
        close={(status, r) => {
          if (r) {
            action.setCondition({
              dispatch,
              key: "goodsName",
              value: r.map((v) => v.goodsName).toString(),
            });
            action.setCondition({ dispatch, key: "goods", value: r });
          }
          goodsFunc.setFalse();
        }}
      /> */}
    </div>
  );
}
