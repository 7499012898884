import React, { useEffect, useState } from "react";
import { Layout } from "../../component/layout";
import { TabsStyle, Tab, TabList, TabPanel } from "../../component/react-tabs";
import { Home } from "./view/home/home";
import { MenuLogic } from "../../logic/menu_logic";

function Marketing() {
  const [menuLists, setData] = useState([]);

  useEffect(() => {
    (async function () {
      let r = await new MenuLogic().menuData();
      var arr = [];
      if (r && r.length > 0) {
        r = r.filter(function (v) {
          return !v.hidden;
        });
        for (let i = 0; i < r.length; i++) {
          if (r[i].path == "/marketing" && r[i].children) {
            arr = r[i].children.filter(function (v) {
              return !v.hidden;
            });
          }
        }
      }
      setData(arr);
    })();

    return () => {};
  }, []);
  return (
    // <Layout fullWidth={true}>
    <TabsStyle style="Full">
      <Home />
    </TabsStyle>
    // </Layout>
  );
}
export default Marketing;
