import React, { useContext, useEffect } from "react";
import { Button } from "../../../component/button";
import { Layer } from "../../../component/layer/layer";
import { Form as FormAntd, message } from "antd";
import { formAction, FormAction } from "../action/form_action";
import { AdvStoreForm } from "../store/form_store";
import { FormBasic } from "./form_basic";
import { useBoolean } from "ahooks";

interface IForm {
    children?: any;
    action: FormAction;
    close: (hasUpdate?: boolean) => void;
    id?: string;
    method?: string;
    isdefault?:boolean
}
export function Form({ close, action, id, method ,isdefault}: IForm) {
    const [form] = FormAntd.useForm();

    const { state, dispatch: formDispatch } = useContext(AdvStoreForm);
    const [loading, loadFunc] = useBoolean(false)
    useEffect(() => {

        formAction.init({ state: state, dispatch: formDispatch, id, method, form });

        return () => {

        }
    }, []);

    const title = () => {
        if ("Create" === method) {
            return "新建开屏广告";
        }
        else if ("Edit" === method) {
            return "编辑开屏广告";
        }
        else {
            return "查看开屏广告";
        }
    }

    return (
        <FormAntd form={form}>
            <Layer layerWith={2} >
                <Layer.Title onClick={() => close()}>{title()}</Layer.Title>
                <Layer.Body>
                    <FormBasic disabled={title() == "查看开屏广告"} form={form}/>
                </Layer.Body>
                {
                    title() != "查看开屏广告" && (
                        <>
                            <Layer.Foot>
                                <Button type="primary" color="Red" style={{ marginRight: "1.25rem" }} onClick={async () => {
                                    await form.validateFields();
                                    if(loading){
                                        message.loading('正在提交数据')
                                        return false
                                    }
                                    let flag = false
                                    if ("Edit" === method) {
                                        loadFunc.setTrue()
                                      flag = await action.updSubmit({ state: state,dispatch:formDispatch,id });
                                      loadFunc.setFalse()
                                    } else {
                                        loadFunc.setTrue()
                                       flag = await action.addSubmit({ state: state,dispatch:formDispatch });
                                       loadFunc.setFalse()
                                    }

                                    close && close(flag);
                                }}>
                                    保存
                                </Button>
                                <Button type="primary" color="Yellow" onClick={() => action.reset({ dispatch: formDispatch, form })}>
                                    重置
                                </Button>
                            </Layer.Foot>
                        </>
                    )
                }
                {
                    title() == "查看开屏广告" && (
                        <>
                            <Layer.Foot>
                            </Layer.Foot>
                        </>
                    )
                }
            </Layer>
        </FormAntd>
    )
}