import moment from "moment";
import { createContext, useContext, useReducer } from "react";

export const FormStore = createContext<IContextProps>(null);

interface IContextProps {
  state: IState;
  dispatch: ({ type }: Dispatch) => void;
}

type Dispatch = { type; payload? };

class formItem {
  componentType: string;
  title?: string;
  value: string = "";
  i: string;
  x: number;
  y: number;
  w: number = 10;
  h: number = 1;
  titleFontSize?: number = 12;
  titleFontWeight?: number = 500;
  valueFontSize: number = 12;
  valueFontWeight: number = 500;
  isEllipsis: boolean = false;
  isDisplay: boolean = true;
  isBorder: string[] = [];
  verticalAlign: string = "center";
  textAlign: TextAlign = "start";
}
export class FormData {
  dataList: formItem[] = [];
}
export type TextAlign = "start" | "center" | "end";
interface IState {
  pageSize: number;
  formData: FormData;
  baseUnit: number;
  selectFormItem: formItem;
  activeIndex: number;
  baseUnitHeight: number;
  ticketsType: number;
  isLoading: boolean;
  isStencil: boolean;
}

export const initialArgs: IState = {
  ticketsType: 4,
  pageSize: 58,
  baseUnit: 20,
  baseUnitHeight: 20,
  formData: new FormData(),
  selectFormItem: new formItem(),
  activeIndex: null,
  isLoading: false,
  isStencil: false,
};

function reducer(state: IState, { type, payload }: Dispatch) {
  switch (type) {
    case "Loading": {
      return {
        ...state,
        isLoading: payload,
      };
    }
    case "SetPageSize": {
      let baseUnit = parseFloat(((payload.pageSize * 3.45) / 10).toFixed(2));

      return {
        ...state,
        pageSize: payload.pageSize,
        ticketsType: payload.type,
        baseUnit: baseUnit,
      };
    }
    case "SetActive": {
      return {
        ...state,
        activeIndex: payload.index,
        selectFormItem: payload.selectFormItem,
      };
    }
    case "SetFormData": {
      return {
        ...state,
        selectFormItem: {
          ...state.selectFormItem,
          ...payload,
        },
      };
    }
    case "SetDataList": {
      let list = state.formData.dataList || [];
      for (let i = 0; i < list.length; i++) {
        let item = payload.filter((layout) => layout.i == list[i].i)[0];
        list[i].x = item.x;
        list[i].y = item.y;
        list[i].w = item.w;
        list[i].h = item.h;
      }

      return {
        ...state,
        formData: {
          ...state.formData,
          dataList: list,
        },
      };
    }
    case "Add": {
      const item = new formItem();
      item.x = 0;
      let w = 0;
      for (let i = 0; i < state.formData.dataList.length; i++) {
        let item = state.formData.dataList[i];
        w += item.w;
      }
      w % 10 == 0 ? (item.y = w / 10 + 1) : (item.y = w / 10);
      //   item.y = state.formData.dataList.length;
      item.i = "n" + state.formData.dataList.length;
      return {
        ...state,
        selectFormItem: item,
      };
    }
    case "SaveComponent": {
      console.log(state);
      let list = state.formData.dataList;
      if (payload == "add") {
        list.push(state.selectFormItem);
      } else {
        list.splice(state.activeIndex, 1, state.selectFormItem);
      }
      return {
        ...state,
        formData: {
          ...state.formData,
          dataList: list,
        },
      };
    }
    case "DelItems":
      const list = state.formData.dataList || [];
      list.splice(payload, 1);
      return {
        ...state,
        formData: {
          ...state.formData,
          dataList: list,
        },
      };
    case "SetInitData": {
      const { ticketsJson, ticketsType, isStencil } = payload;
      const item = new FormData();
      state.ticketsType = ticketsType;
      const { pageSize, baseUnit, baseUnitHeight, dataList } =
        JSON.parse(ticketsJson);
      item.dataList = dataList;
      return {
        ...state,
        pageSize: pageSize,
        baseUnit: baseUnit,
        baseUnitHeight: baseUnitHeight,
        isStencil: isStencil,
        formData: item,
      };
    }
    case "Clear":
      const initState = {
        ticketsType: 4,
        pageSize: 58,
        baseUnit: 20,
        baseUnitHeight: 20,
        formData: new FormData(),
        selectFormItem: new formItem(),
        activeIndex: null,
      };
      return {
        ...state,
        ticketsType: 4,
        pageSize: 58,
        baseUnit: 20,
        baseUnitHeight: 20,
        formData: {
          dataList: [],
        },
        selectFormItem: new formItem(),
        activeIndex: null,
      };
    default:
      return state;
  }
}

export function FormStoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialArgs);

  const v = { state, dispatch };
  return <FormStore.Provider value={v}>{props.children}</FormStore.Provider>;
}
