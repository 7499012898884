// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../../../config";
import { ISelectGoods, ISelectPage, ISelectTime } from "./ISelectType";
import { message } from "antd";
import { saveAs } from 'file-saver';
import * as xlsx from "xlsx"

/**
 * api控制器
 */
export class ApiCon {
    /** 门店销售统计 */
    static ShopSaleReport(op: {
        /** null 全部 1门店 2商城 */
        saleType?: 1 | 2;
    } & ISelectTime & ISelectPage & ISelectGoods) {
        return this.axios('saleReport.ShopSaleReport', 'POST', op);
    }
    /** 门店销售统计 详情 */
    static ShopSaleReportDetails(op: {
        /** null 全部 1门店 2商城 */
        saleType?: 1 | 2;
    } & ISelectTime & ISelectPage & ISelectGoods) {
        return this.axios('saleReport.ShopSaleReportDetails', 'POST', op);
    }
    /** 进销存明细 */
    static InvoicingDetailsReport(op: {
        /** null 全部 1门店 2商城 */
        saleType?: 1 | 2;
    } & ISelectTime & ISelectPage & ISelectGoods) {
        return this.axios('saleReport.InvoicingDetailsReport', 'POST', op);
    }
    /** 门店利润 */
    static ShopProfitReport(op: ISelectTime) {
        return this.axios('saleReport.ShopProfitReport', 'POST', op);
    }
   
    /** 销售流水 */
    static SalesFlowReport(op: {
        /** 订单类型：1门店订单，2商城订单 3自助收银*/
        orderType?: 1 | 2 | 3;
        /** 1:待付款 2:待收货 3:待发货 4:待提货 5:已完成 6:退单 7:作废 */
        state?: 1 | 2 | 3 | 4 | 5 | 6 | 7;
        /** 支付方式：1支付宝，2微信，3银联，4现金，5钱包余额 */
        payType?: 1 | 2 | 3 | 4 | 5;
        /** 会员手机号 */
        phone?: string;
    } & ISelectTime & ISelectPage) {
        return this.axios('saleReport.SalesFlowReport', 'POST', op);
    }
    /** 商品销售排行 */
    static GoodsSaleRankReport(op: {
        /** 排行类型1:销售额排行 2:销量排行 3:毛利排行 */
        rankType?: 1 | 2 | 3;
    } & ISelectTime & ISelectPage & ISelectGoods) {
        return this.axios('saleReport.GoodsSaleRankReport', 'POST', op);
    }
     /** 商品分类金额统计 */
     static ClassesSalesRecord(op: {
        // rankType?: 1 | 2 | 3;
    } & ISelectTime & ISelectPage ) {
        return this.axios('saleReport.ClassesSalesRecord', 'POST', op);
    }
 /** 商品退款信息统计 */
 static GoodsRefundRecord(op: {
    // rankType?: 1 | 2 | 3;
} & ISelectTime & ISelectPage ) {
    return this.axios('saleReport.GetGoodsRefund', 'POST', op);
}


      /** 会员收支统计 */
      static MemberFlowRecord(op: {
        // rankType?: 1 | 2 | 3;
    } & ISelectTime & ISelectPage) {
        return this.axios('saleReport.GetMemberExpend', 'POST', op);
    }

    /** 获取商品分类 */
    static goodsClass() {
        return this.axios('goods_classes_queryrelation', 'GET');
    }
    /** 获取门店列表 */
    static getShopList() {
        return this.axios('shop_child_query', 'GET');
    }

    /** 发送axios请求 */
    private static axios(api, method: 'GET' | 'POST', op?): Promise<any> {
        return axios({
            method: method,
            url: Tools.getApiAddress(api),
            data: op,
            headers: {
                // 'Content-Type': 'multipart/form-data;charset=UTF-8',
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
                // SYSTOKEN: '0E2A0D5C-702D-4054-B37D-C901BE93DA32'
            },
        }).then((r) => {
            if (r.data.statusCode == 200) {
                return r.data.data;
            } else {
                //抛出异常
                throw r.data;
            }
        })
    }

         /**
        * 导出
        * @param search 查询条件
        * @param title 导出文件标题
        * @param api 请求地址
        */
      static    async exportList({ search,title,api }) {
            const r = await axios({
                method: "POST",
                url: Tools.getApiAddress(api),
                responseType: 'arraybuffer',
                data: { ...search
                },
                headers: {
                    SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
                },
            })
            if (r.status == 200) {
                let enc = new TextDecoder('utf-8')
                let data = enc.decode(new Uint8Array(r.data))
                if (data.indexOf('statusCode') != -1) {
                    let datas = JSON.parse(data)
                    if (datas.statusCode == 200) {
                        let blob = new Blob([r.data], { type: 'application/vnd.ms-excel' })
                        saveAs(blob, `${title}.xlsx`);
                    } else {
                        message.error(datas.returnMessage)
                    }
                } else {
                    let blob = new Blob([r.data], { type: 'application/vnd.ms-excel' })
                    saveAs(blob, `${title}.xlsx`);
                }
    
            }
    
        }

        /**
 *  原生JS导出为excel文件
 */
static async jsToExcel(id, name) {
    //window.location.href='<%=basePath%>pmb/excelShowInfo.do';
    //获取表格
    var exportFileContent = document.getElementById(id).outerHTML;
    //设置格式为Excel，表格内容通过btoa转化为base64，此方法只在文件较小时使用(小于1M)
    //exportFileContent=window.btoa(unescape(encodeURIComponent(exportFileContent)));
    //var link = "data:"+MIMEType+";base64," + exportFileContent;
    //使用Blob
    var blob = new Blob([exportFileContent], { type: "text/plain;charset=utf-8" });         //解决中文乱码问题
    blob = new Blob([String.fromCharCode(0xFEFF), blob], { type: blob.type });
    //设置链接
    var link = window.URL.createObjectURL(blob);
    var a = document.createElement("a");    //创建a标签
    a.download = name;  //设置被下载的超链接目标（文件名）   建议文件后缀为 .xls
    a.href = link;                            //设置a标签的链接
    document.body.appendChild(a);            //a标签添加到页面
    a.click();                                //设置a标签触发单击事件
    document.body.removeChild(a);            //移除a标签
}
 
 
/**
 *  用XLSX导出 (导出无样式) raw 对表格内容不做任何处理 需要在id这个node节点元素存在页面才行
 */
 static async exportExcel(id, name) {
    var exportFileContent = document.getElementById(id).cloneNode(true);
    var wb = xlsx.utils.table_to_book(exportFileContent, { sheet: "sheet1" ,raw: true});
    xlsx.writeFile(wb, name);
}
}
