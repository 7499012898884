import { createContext, useReducer } from "react";

class FormData {
  userID: string;
  userName: string;
  nickName: string;
  email: string;
  password: string;
  sex: any;
  avatarUrl: string;
  qq: string;
  phone: string;
  provinceID: string;
  province: string;
  cityID: string;
  city: string;
  countyID: string;
  county: string;
  address: string;
  remark: string;
  identityCard: string;
  birthday: any;
  enabled: boolean;
  oneSession: boolean;
  newPassword: string;
  confirmPassword: string;
  roleID: string;
  commissionRuleID: string //提成规则id
}

type Dispatch = { type; payload };

interface IState {
  formData: FormData;
  roletList: any[];
  commissionRuleList: any[]
}

const initialArgs = {
  formData: new FormData(),
  roletList: [],
  commissionRuleList: []
};

export type FormAttribute =
  | "ConfirmPassword"
  | "RoleID"
  | "NewPassword"
  | "UserID"
  | "UserName"
  | "NickName"
  | "Email"
  | "Password"
  | "Sex"
  | "AvatarUrl"
  | "QQ"
  | "Phone"
  | "ProvinceID"
  | "Province"
  | "CityID"
  | "City"
  | "CountyID"
  | "County"
  | "Address"
  | "Remark"
  | "IdentityCard"
  | "Birthday"
  | "Enabled"
  | "OneSession"
  | "commissionRuleID";

interface IContextProps {
  state: IState;
  dispatch: ({ type }: Dispatch) => void;
}

export const ListFormStore = createContext<IContextProps>(null);

function reducer(state: IState, { type, payload }: Dispatch) {
  switch (type) {
    // 角色列表
    case "RoletList":
      return {
        ...state,
        roletList: payload,
      };
    // 提成规则列表
    case "CommissionRuleList":
      return {
        ...state,
        commissionRuleList: payload,
      };
    // 填充表单
    case "SetForm": {
      const data = payload;
      const form = new FormData();
      {
        const {
          userID,
          roleID,
          userName,
          nickName,
          email,
          password,
          sex,
          avatarUrl,
          qq,
          phone,
          provinceID,
          province,
          cityID,
          city,
          countyID,
          county,
          address,
          remark,
          identityCard,
          birthday,
          enabled,
          oneSession,
          commissionRuleID
        } = data;
        form.userID = userID;
        form.roleID = roleID;
        form.userName = userName;
        form.nickName = nickName;
        form.email = email;
        form.password = password;
        form.sex = sex;
        form.avatarUrl = avatarUrl;
        form.qq = qq;
        form.phone = phone;
        form.provinceID = provinceID;
        form.province = province;
        form.cityID = cityID;
        form.city = city;
        form.countyID = countyID;
        form.county = county;
        form.address = address;
        form.remark = remark;
        form.identityCard = identityCard;
        form.birthday = birthday;
        form.enabled = enabled;
        form.oneSession = oneSession;
        form.commissionRuleID = commissionRuleID
      }

      return {
        ...state,
        formData: form,
      };
    }
    // 设置表单
    case "Change_FormData": {
      const { attribute, value } = payload,
        props: any = {};

      const key: FormAttribute = attribute;
      let attri = "";

      switch (key) {
        case "UserID":
          attri = "userID";
          break;
        case "Enabled":
          attri = "enabled";
          break;
        case "OneSession":
          attri = "oneSession";
          break;
        case "UserName":
          attri = "userName";
          break;
        case "Password":
          attri = "password";
          break;
        case "Sex":
          attri = "sex";
          break;
        case "QQ":
          attri = "qq";
          break;
        case "Email":
          attri = "email";
          break;
        case "Phone":
          attri = "phone";
          break;
        case "RoleID":
          attri = "roleID";
          break;
        case "IdentityCard":
          attri = "identityCard";
          break;
        case "Birthday":
          attri = "birthday";
          break;
        case "Address":
          attri = "address";
          break;
        case "ConfirmPassword":
          attri = "confirmPassword";
          break;
        case "NewPassword":
          attri = "newPassword";
          break;
        case "Remark":
          attri = "remark";
          break;
        case "commissionRuleID":
          attri = 'commissionRuleID'
          break;
        default:
          break;
      }

      props[attri] = value;

      return {
        ...state,
        formData: {
          ...state.formData,
          ...props,
        },
      };
    }
    default:
      return state;
  }
}

export function ListFormStoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialArgs);

  const v = { state, dispatch };
  return (
    <ListFormStore.Provider value={v}>{props.children}</ListFormStore.Provider>
  );
}
