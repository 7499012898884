import { message } from "antd";
import { GoodsEntity } from "../../../../entity/goods_entity";
import { CheckLogic } from "../../../../logic/check_logic";
import { defualtPageSize } from "../../../../logic/common_logic";
import { GoodsLogic, ISelList } from "../../../../logic/goods_logic";
import { UnitLogic } from "../../../../logic/unit_logic";
import { UserLogic } from "../../../../logic/user_logic";
import { IState, TableItem } from "../../store/inventory/inventory_goods_form_store";
import Cookies from 'js-cookie'
type Init = {
    state;
    dispatch;
    form;
    id: string;
    method: string;
}
interface InitBasic {
    dispatch;
    search;
}
interface InitEdit {
    state;
    dispatch;
    form;
    id;
}

class InventoryGoodsFormAction {

    async init({ state, dispatch, id, method, form }: Init) {
        await this.initBasic({ dispatch, search: state.search });

        if ("Create" !== method && id) {
            await this.initEdit({ dispatch, state, id, form })
        }
    }
    private async initBasic({ search, dispatch }: InitBasic) {
        this.clearTableList({ dispatch });
        let { shopID, shopName } = JSON.parse(Cookies.get('loginMsg'))
        this.setFormData({ dispatch, key: "shop", value: {id:shopID,shopName: shopName} });     

        // 用户信息
        {
            const r = await new UserLogic().selData();

            this.setFormData({ dispatch, key: "userName", value: r.userName });
        }
        // 商品搜索
        {
            const { keywords, classesId, statusId, typeId, brandId } = search;

            const params = {
                pageIndex: 1, pageSize: defualtPageSize(), goodsBrandID: brandId, goodsClassesID: classesId, goodsType: typeId, status: statusId, goodsNameOrNo: keywords
            }
            if (undefined == keywords) {
                delete params.goodsNameOrNo;
            }
            if (undefined == brandId) {
                delete params.goodsBrandID;
            }
            if (undefined == classesId) {
                delete params.goodsClassesID;
            }
            if (undefined == typeId) {
                delete params.goodsType;
            }
            if (undefined == statusId) {
                delete params.status;
            }

            const r = await new GoodsLogic().selListByZeroInventory(params);

            if (r.data.statusCode == 200) {
                const dataList = r.data.data.dataSource;
                let arr = []
                dataList.map((goods_v, i) => {
                    // if(goods_v.goods_SpecificationList&&goods_v.goods_SpecificationList.length>0){
                    //  for(let i =0;i<goods_v.goods_SpecificationList.length;i++){
                    //      let good = goods_v.goods_SpecificationList[i]
                    //          if(arr.filter(ss=>ss.goodsSepID == good.id).length>0){
                    //              continue;
                    //          }
                    //          delete goods_v.id;
                    //          arr.push({
                    //              key: i + 1,
                    //              ...goods_v,
                    //              ...good,
                    //              goodsUnitName: goods_v.unitName,
                    //              goodsSpecifications:[good],
                    //              goodsName:goods_v.goodsName,
                    //              goodsSepID: good.id,
                    //              id: good.goodsID,
                    //              value: goods_v.goodsName,
                    //              storePrice:good.storePrice
                    //          })
                        
                    //  }
                    // }
                    if((arr.filter(item=>item.id == goods_v.id)).length == 0){
                        arr.push(goods_v)
                    }
               
             })
                dispatch({ type: "SetSearchList", payload: arr });

            }
        }

    }
    private async initEdit({ dispatch, id }: InitEdit) {
        // 回填状态
        const item = await new CheckLogic().selData(id);

        //先把提取出规格对于的入库数据
        let goodsList: any[] = [];
        let goods;
        // item.detail.forEach((item) => {
        //     goods = goodsList.find((_item) => {
        //         return item.goodsID == _item.goodsID;
        //     });
        //     if (!goods) {
        //         goods = item;
        //         goodsList.push(goods);
        //     }
        //     (goods.goodsSpecifications___ || (goods.goodsSpecifications___ = [])).push({
        //         id: item.specificationID,
        //         sysNumber: item.sysNumber,
        //         checkNumber: item.checkNumber,
        //         inventoryAvg: item.inventoryAvg,
        //         differenceNumber: item.differenceNumber,
        //         differencePrice: item.differencePrice,
        //     });
        // });
        // item.detail = goodsList;
        // let _data;
        // //添加进商品规格
        // for (let _item of item.detail) {
        //     //请求商品详情数据
        //     _item.goodsSpecifications = (await new GoodsLogic().selData(_item.goodsID)).goodsSpecifications;
        //     //根据规格id合并入库数据
        //     _item.goodsSpecifications.forEach((__item) => {
        //         _data = _item.goodsSpecifications___.find((___item) => {
        //             return __item.id == ___item.id;
        //         });
        //         __item.sysNumber = _data.sysNumber;
        //         __item.checkNumber = _data.checkNumber;
        //         __item.inventoryAvg = _data.inventoryAvg;
        //         __item.differenceNumber = _data.differenceNumber;
        //         __item.differencePrice = _data.differencePrice;
        //     });
        // }
        let list = []
        // for(let good of item.detail) {
        //    let seplist =  (await new GoodsLogic().selData(good.goodsID)).goodsSpecifications
        //    let sep;
        //    if(seplist){
        //         sep = seplist.filter((s)=>s.id == good.specificationID)
        //         if(sep.length>0){
        //             sep[0]['sysNumber'] = good.sysNumber
        //             sep[0]['checkNumber'] = good.checkNumber
        //             sep[0]['inventoryAvg'] = good.inventoryAvg
        //             sep[0]['differenceNumber'] = good.differenceNumber
        //             sep[0]['differencePrice'] = good.differencePrice
        //         }
              
        //     }
        //     list.push({
        //         ...good,              
        //         goodsSpecifications: sep,
        //         id: good.specificationID
        //     })

        // }
        // item.detail = list;
        dispatch({ type: "SetForm", payload: item });
    }
    private paddingData(state: IState, status: 1 | 2 | 3) {
        let params: any = {};
        {
            const { formData, tableList } = state;

            {
                const { reasonId, remark, shop } = formData;
                params.cause = reasonId;
                // 1已审核 2未审核 3作废
                params.status = status;
                // 此单合计差异金额
                params.differencePrice = state.tableList.reduce((s, c) => s + Number(c.differencePrice), 0);
                params.remark = remark;
                params.shopID = shop.id;

                params.inventoryCheckDetailDtos = [];

                tableList.forEach((v, i) => {
                    // v.goodsSpecifications.forEach((s: any) => {
                        params.inventoryCheckDetailDtos.push({
                            "storageBatchNumber": v.storageBatchNumber,
                            "goodsID": v.goodsID,
                            "goodsUnit": v.unitNameId,
                            // "specificationID": v.id,
                            //
                            "sysNumber": v.systemNumber,
                            "checkNumber": v.checkNumber,
                            "inventoryAvg": v.averagePrice,
                            "differenceNumber": v.differenceNumber,
                            "differencePrice": v.differencePrice,
                            "remark": ""
                        })
                    // });
                })

            }
        }
        return params;
    }
    async addSubmit({ state, status }) {
        if (!state.formData.shop.id || state.formData.shop.id == undefined) {
            message.error("请选择门店");
            return false;
        }
        if (!state.formData.reasonId || state.formData.reasonId == undefined) {
            message.error("请选择理由");
            return false;
        }
        if (!state.tableList || !state.tableList.length) {
            message.error("请选择商品");
            return false;
        }
        let num = 0
        let num2 = 0 
        for (let i = 0; i < state.tableList.length; i++) {
            //console.log(state.tableList[i].goodsSpecifications)
            // for (let k = 0; k < state.tableList[i].goodsSpecifications.length; k++) {
                if (
                    // !state.tableList[i].sysNumber
                    // || state.tableList[i].sysNumber < 1
                    // || 
                    (state.tableList[i].checkNumber === undefined || state.tableList[i].checkNumber === null || state.tableList[i].checkNumber === '')
                    || state.tableList[i].checkNumber < 0
                    // || !state.tableList[i].differenceNumber
                    // || state.tableList[i].differenceNumber < 1
                    // || !state.tableList[i].inventoryAvg
                    // || state.tableList[i].inventoryAvg < 0
                    // || !state.tableList[i].differencePrice
                    // || state.tableList[i].differencePrice < 0
                ) {
                    num = num + 1
                }
                if(state.tableList[i].checkNumber > state.tableList[i].systemNumber){
                    num2++;
                }
            // }
        }
        if (num > 0) {
            message.error("商品盘点信息未填写完整");
            return false;
        }
        // if(num2>0){
        //     message.error('商品盘点')
        // }
        const r = await new CheckLogic().addData(this.paddingData(state, status));
        if (r.data.statusCode == 200) {
            message.success("保存成功");
        } else {
            message.error(r.data.message);
        }
        return true;
    }
    async updSubmit({ state, status }, id) {
        if (!state.formData.shop.id || state.formData.shop.id == undefined) {
            message.error("请选择门店");
            return false;
        }
        if (!state.formData.reasonId || state.formData.reasonId == undefined) {
            message.error("请选择理由");
            return false;
        }
        if (!state.tableList || !state.tableList.length) {
            message.error("请选择商品");
            return false;
        }
        let num = 0
        for (let i = 0; i < state.tableList.length; i++) {
            //console.log(state.tableList[i].goodsSpecifications)
            // for (let k = 0; k < state.tableList[i].goodsSpecifications.length; k++) {
                if (
                    // !state.tableList[i].sysNumber
                    // || state.tableList[i].sysNumber < 1
                    // ||
                    (state.tableList[i].checkNumber === undefined || state.tableList[i].checkNumber === null || state.tableList[i].checkNumber === '')
                    || state.tableList[i].checkNumber < 0
                    // || !state.tableList[i].differenceNumber
                    // || state.tableList[i].differenceNumber < 1
                    // || !state.tableList[i].inventoryAvg
                    // || state.tableList[i].inventoryAvg < 0
                    // || !state.tableList[i].differencePrice
                    // || state.tableList[i].differencePrice < 0
                ) {
                    num = num + 1
                }
            // }
        }
        if (num > 0) {
            message.error("商品盘点信息未填写完整");
            return false;
        }
        const params = this.paddingData(state, status);
        params.id = id;

        const r = await new CheckLogic().updData(params);
        if (r.data.statusCode == 200) {
            message.success("更新成功");
        } else {
            message.error(r.data.message);
        }
        return true;
    }
    async addTableItemBySimple({ dispatch, state }: { dispatch, state: IState }) {
        const { searchList, formData } = state;

        if (formData.keyword) {
            let goods;
            searchList.forEach(v => {
                if (v.id == formData.keyword) {
                    goods = v;
                }
            })
            if (goods) {
                const item = new TableItem(goods);

                //获取商品规格信息
                // item.goodsSpecifications = (await new GoodsLogic().selData(goods.id)).goodsSpecifications;

                item.countRealNumber(state.formData.checkNumber || 0);

                // const r = await new UnitLogic().selData(goods.goodsUnitID);
                // item.unitName = goods.unitName;
                dispatch({ type: "AddTableItem", payload: item });

                this.setFormData({ dispatch, key: "keyword", value: "" });
                this.setFormData({ dispatch, key: "checkNumber", value: "" });
            }
        }
    }
    addTableItem({ dispatch, list }) {
        list.forEach(async v => {
            const item = new TableItem(v);

            // const r = await new UnitLogic().selData(goods.goodsUnitID);

            //获取商品规格信息
            // item.goodsSpecifications = (await new GoodsLogic().selData(goods.id)).goodsSpecifications;

            // item.unitName = v.goodsUnitName;
            // item.goodsSpecifications = v.goods_SpecificationList
            dispatch({ type: "AddTableItem", payload: item });
        })
    }
    delTableItem(dispatch, index) {
        dispatch({ type: "DelTableItem", payload: index });
    }
    setFormData({ dispatch, key, value }) {
        const params = {
        }
        params[key] = value;

        dispatch({ type: "SetFormData", payload: params })
    }
    setKeywords({ dispatch, value, state }) {
        this.setFormData({ dispatch, key: "keyword", value: value });
console.log(value)
        state.searchList.forEach(e => {
            if (e.id == value) {
                this.setFormData({ dispatch, key: "keyword", value: value });
                dispatch({ type: "SetFormData", payload: { differencePrice: e.putNewPrice } })
            }

        })
    }
    changeTableItem({ dispatch, key, value, index }) {
        dispatch({ type: "ChangeTableItem", payload: { key, value, index } });
    }
    clearTableList({ dispatch }) {
        dispatch({ type: "ClearTableList" });
    }


    async getGoods({id,dispatch,state}){
        const that = this
        let param:ISelList = {
            goodsClassesID: [],
            goodsNameOrNo: id,
            pageIndex: 1,
            pageSize: 10,
            goodsBrandID:''
        }
            const r = await new GoodsLogic().selList(param)
            if( r.data.statusCode == 200 && r.data.data &&r.data.data.dataSource && r.data.data.dataSource.length>0){
                // const item = r.data.data.dataSource[0]
                const item = new TableItem(r.data.data.dataSource[0]);
                item.countRealNumber(state.formData.checkNumber || 0);
                dispatch({ type: "AddTableItem", payload: item });

                this.setFormData({ dispatch, key: "keyword", value: "" });
                this.setFormData({ dispatch, key: "checkNumber", value: "" });
            }
    }
}
export const inventoryGoodsFormAction = new InventoryGoodsFormAction();