import message from "antd/lib/message"
// import axios from "axios"
import Cookies from "js-cookie"
import { DataTableAction } from "../../../../component/table/action/data_table_action"
import { Tools, axios } from "../../../../config"
import { SeckillLogic } from "../../../../logic/seckill_logic"

class WeckillAction extends DataTableAction {

    async delRow(id) {
        const r = await new SeckillLogic().delData(id);

        if (r.data.statusCode == 200) {
            message.success("终止成功")
        }
    }
    delSelectList({ dispatch, selectList }: { dispatch: any; selectList: any; }) {
        selectList.map(async ({ id }) => {
            const r = await axios({
                method: "GET",
                url: Tools.getApiAddress("shop_delete"),
                params: {
                    shopId: id
                },
                headers: {
                    SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
                },
            })

            if (r.data.statusCode == 200) {
                message.success("删除成功")
            }
        })
    }
    
    async loadPage({ dispatch, pageIndex, pageSize, search }) {
        this.setLoading({dispatch,loading: true})
        const { goodsId, name, shopId, activityEndDate,orderBy,sort } = search;

        const params: any = {
            "pageIndex": pageIndex,
            "pageSize": pageSize,
            "name":name?name:'',
            "shopID":[],
            "goodsID":[],
            "memberClassID":[],
            "orderBy":'',
            "sort":'',
        }
       
        if(goodsId){
            if(goodsId.indexOf(',')){
                params.goodsID = goodsId.split(',')
            }else{
                params.goodsID.push(goodsId)
            }
        }
        if(shopId){
            params.shopID.push(shopId)
        }
        // 档案列表
        const r = await new SeckillLogic().selListByRecord(params);

        if (r.data.statusCode == 200) {
            const dataList = r.data.data.dataSource;

            const list = dataList.map((v, i) => {
                v.key = i + 1;
                v.beginTime = v.beginTime==null?'':v.beginTime.slice(0,10);
                v.endTime = v.endTime==null?'':v.endTime.slice(0,10);
                return v;
            })

            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: list, pageSize });
        }  this.setLoading({dispatch,loading: false})

    }
    async loadPageStart({ dispatch, pageIndex, pageSize, search }) {

        const { goodsId, name, shopId, activityEndDate,orderBy,sort } = search;

        const params: any = {
            "pageIndex": pageIndex,
            "pageSize": pageSize,
            "name":name?name:'',
            "shopID":shopId,
            "goodsID":[],
            "memberClassID":[],
            "orderBy":'',
            "sort":'',
        }       
        const r = await new SeckillLogic().selListSatrt(params);
        if (r.data.statusCode == 200) {
            const dataList = r.data.data.dataSource;

            const list = dataList.map((v, i) => {
                v.key = i + 1;
                v.beginTime = v.beginTime==null?'':v.beginTime.slice(0,10);
                v.endTime = v.endTime==null?'':v.endTime.slice(0,10);
                return v;
            })

            this.setPaging({ dispatch, pageIndex, total: r.data.data.totalCount, dataList: list, pageSize });
        }
    }

    setCondition({ dispatch, key, value }) {
        const params = {
        }
        params[key] = value;

        dispatch({ type: "SetSearch", payload: params })
    }
}
export const seckillAction = new WeckillAction();