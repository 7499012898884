import { createContext, useContext, useReducer } from "react";

export const ListStore = createContext<IContextProps>(null);

interface IContextProps {
  state: IState;
  dispatch: ({ type }: Dispatch) => void;
}

type Dispatch = { type; payload };

class Search {
  keywords: string;
  beginTime: string;
  endTime: string;
  shareMemberIDs: any[];
}

interface IState {
  search: Search;
  member: any[];
}

const initialArgs = {
  search: new Search(),
  member: [],
};

function reducer(state: IState, { type, payload }: Dispatch) {
  switch (type) {
    case "SetSearch":
      console.log(payload);
      return {
        ...state,
        search: {
          ...state.search,
          ...payload,
        },
      };
    case "SetMember":
      state.member = payload;
      return {
        ...state,
      };
    default:
      return state;
  }
}

export function ShareStoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialArgs);

  const v = { state, dispatch };
  return <ListStore.Provider value={v}>{props.children}</ListStore.Provider>;
}
