// import axios from "axios";
import Cookies from "js-cookie";
import { Tools, axios } from "../config";
import { effeative } from "./common_logic";

export class DistributionRecordsLogic {

    async selList(params) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("distributionList"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r.data.data;
    }
   
/**分页查询分享裂变记录 */
    async queryShareList(param){
        const r = await axios({
            method: "POST",
            url: Tools.getApiAddress("shareList"),
            data:param,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        })
        return r.data;
    }
/**获取裂变关系 */
async queryRelation(param){
    const r = await axios({
        method: "GET",
        url: Tools.getApiAddress("shareRelationImage"),
        params:param,
        headers: {
            SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
        },
    })
    return r
}

/**分页查询提现申请记录 */
    async queryWithDrawList(params){
        const r = effeative( await axios({
            method:"POST",
            url: Tools.getApiAddress('queryWithdrawList'),
            data:params,
            headers:{
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            }
        }))
        return r.data
    }
     /**提现审核 */

     async auditData(params){
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("withdrawalsAudit"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r
    }
    /**提现放款 */
    async withdraw(params){
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("confirmationOfReceipt"),
            params: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r
    }


    

    async addData(params) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("user_add"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r.data;
    }
        async updData(params) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("user_upd"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r.data;
    }
    async lookData(id: string) {
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("user_look"),
            params: {
                id
            },
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r.data.data;
    }
    async selData() {
        const r = effeative(await axios({
            method: "GET",
            url: Tools.getApiAddress("user_info"),
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r.data.data;
    }
    async resetData(params) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("user_psw_reset"),
            data: params,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }))
        return r.data;
    }

    /** 更新密码 */
    async undatePasswor(_op) {
        const r = effeative(await axios({
            method: "POST",
            url: Tools.getApiAddress("UpdatePassword"),
            data: _op,
            headers: {
                SYSTOKEN: Cookies.get("token"),'ClientSource':'web'
            },
        }));
        return r.data;
    }
}