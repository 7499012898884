import { message } from "antd";
import React from "react";
import { createRef } from "react";
import { ComPopUpWin } from "../../../../component/pop-up-windows/comPopUpWin";
import { IHotsData, IHotsReducerPar, PrecinctCon, PrecinctData } from "../../action/HotsAction";
import { ApiCon } from "../../com/apiCon";
import { V2, ElementTransform } from "../../com/trasform";
import styles from "./../../style/hots.module.scss";
import { EditHostPrecinct } from "./editHostPrecinct";
import { Precinct } from "./precinct";

export type moveTouchType = {
  /** 是否接收鼠标移动事件 */
  receive: boolean;
  /** 移动触控区域鼠标移动的回调，并以鼠标当前的位置作为第一个参数 */
  changeF?: (pos: V2) => void;
};

/** 选区最小尺寸 */
export const precinctMiniSize: V2 = new V2(20, 20);

/**
 * 编辑host
 * @param props
 */
export class EditHost extends React.Component<
  {
    close: () => void;
    hostData: IHotsData;
    conPrecinctData: (p: IHotsReducerPar) => void
  },
  {
    /** 操作选区列表 */
    precinctConList: PrecinctCon[];
    /** 移动检测 */
    moveTouch: moveTouchType;
    /** 开始位置，注意这里一定要是个相对于根元素的位置 */
    startPot: V2;
    /** 当前正在添加的选区索引 */
    onPrecinctId: number;
    /** 编辑url的选区id */
    editPercinctUrlId: number;
  }
> {

  /** 根元素引用 */
  private rootDivRef;

  /** 选区根元素变换信息 */
  private rootDivTranform: ElementTransform;
  /** 跟元素初试顶部位置 */
  private rootDivTranformScrollTop: number = 0;

  /** 当前鼠标位置 */
  private onMousePot: V2 = new V2();

  constructor(props) {
    super(props);
    this.state = {
      moveTouch: {
        receive: false,
      },
      precinctConList: [],
      startPot: null,
      onPrecinctId: -1,
      editPercinctUrlId: -1,
    };
    //
    this.rootDivRef = createRef();
    //指定一个绑定函数
    this.mouseUP = this.mouseUP.bind(this);
    this.mouseMove = this.mouseMove.bind(this);
  }

  //
  componentDidMount() {
    //监听鼠标移动事件
    window.addEventListener("mousemove", this.mouseMove as any);
    //获取根元素变换信息
    this.rootDivTranform = ElementTransform.getElementTransform(
      this.rootDivRef.current
    );
    this.rootDivTranformScrollTop = this.rootDivTranform.pos.y;
    //获取数据并填充
    let _precinctDataList: PrecinctData[] = [...this.props.hostData.precinctList];
    // //console.log(_precinctDataList);
    //如果没有数据则填充一个空数据
    _precinctDataList.length == 0 &&
      _precinctDataList.push(
        new PrecinctData(new V2(0, 0), new V2(0.5, 0.5), null, false)
      );
    //从百分比位置尺寸转成px位置和尺寸
    this.setState({
      precinctConList: _precinctDataList.map((item) => {
        return new PrecinctCon(
          V2.unProportion(item.pos, this.rootDivTranform.size),
          V2.unProportion(item.size, this.rootDivTranform.size),
          item.url,
          item.ifNewWinOpen,
          false
        );
      }),
    });
  }
  componentWillUnmount() {
    //移除鼠标事件监听
    window.removeEventListener("mousemove", this.mouseMove as any);
  }

  /** 设置选区 */
  private setPrecinct(id: number, data: PrecinctCon) {
    this.state.precinctConList[id] = data;
    this.rsetPrecinct();
  }

  /** 删除选区 */
  private removePrecinct(id: number) {
    this.state.precinctConList.splice(id, 1);
    this.rsetPrecinct();
  }

  /** 重置选区 */
  private rsetPrecinct() {
    this.setState({
      precinctConList: [...this.state.precinctConList],
    });
  }

  /** 滚动事件 */
  private onScroll(_top: number) {
    //重置根元素变换中的位置信息
    this.rootDivTranform.pos.y = this.rootDivTranformScrollTop - _top;
    //同时触发鼠标移动事件
    this.mouseMove({ clientX: this.onMousePot.x, clientY: this.onMousePot.y });
  }

  /** 鼠标按下 */
  private mouseDow(e: React.MouseEvent) {
    //添加监听事件
    window.addEventListener("mouseup", this.mouseUP as any);
    //
    this.setState({
      startPot: V2.subtract(
        new V2(e.clientX, e.clientY),
        this.rootDivTranform.pos
      ),
    });
    //显示移动touch区域并注入一个关注移动的回调方法
    this.setState({
      moveTouch: {
        receive: true,
        changeF: (onPos: V2) => {
          if (!this.state.startPot) {
            return;
          }
          //转相对位置
          let startPos: V2 = this.state.startPot;
          onPos = V2.subtract(onPos, this.rootDivTranform.pos);
          let pos: V2 = startPos.clone();
          let size: V2 = V2.subtract(onPos, pos);
          //根据size向量的方向重置位置，这样就可以反向选区了
          if (size.x < 0) {
            pos.x = onPos.x;
            size.x = -size.x;
            pos.x = Math.max(pos.x, 0);
            size.x = Math.min(size.x, startPos.x);
          } else {
            size.x = Math.min(size.x, this.rootDivTranform.size.x - pos.x);
          }
          if (size.y < 0) {
            pos.y = onPos.y;
            size.y = -size.y;
            pos.y = Math.max(pos.y, 0);
            size.y = Math.min(size.y, startPos.y);
          } else {
            size.y = Math.min(size.y, this.rootDivTranform.size.y - pos.y);
          }
          //
          if (this.state.onPrecinctId == -1) {
            //
            this.state.precinctConList.push(new PrecinctCon(pos, size, undefined, false, false));
            this.setState({
              precinctConList: [...this.state.precinctConList],
              onPrecinctId: this.state.precinctConList.length - 1,
            });
          } else {
            this.state.precinctConList[this.state.onPrecinctId].pos = pos;
            this.state.precinctConList[this.state.onPrecinctId].size =
              size;
            this.setPrecinct(this.state.onPrecinctId, this.state.precinctConList[this.state.onPrecinctId]);
          }
        },
      },
    });
  }
  /** 鼠标释放 */
  private mouseUP(e: React.MouseEvent) {
    //移除监听事件
    window.removeEventListener("mouseup", this.mouseUP as any);
    //记录下id
    let _id: number = this.state.onPrecinctId;
    //重置状态
    this.setState({
      startPot: null,
      onPrecinctId: -1,
    });
    //先判断id是否有效，因为可能不会生成选区
    if (_id != -1) {
      let _percinct = this.state.precinctConList[_id];
      //再判断选区大小
      if (
        _percinct.size.x >= precinctMiniSize.x &&
        _percinct.size.y >= precinctMiniSize.y
      ) {
        _percinct.ifCreate = false;
        this.setPrecinct(_id, _percinct);
      }
      //不满足要求则删除选区
      else {
        this.removePrecinct(_id);
      }
    }
    //关闭鼠标移动检测
    this.setState({
      moveTouch: {
        receive: false,
      },
    });
  }

  /** 鼠标移动 */
  private mouseMove(e: { clientX: number; clientY: number }) {
    //先判断是否已经获取到了选区根元素的变换信息
    if (!this.rootDivTranform) {
      return;
    }
    this.onMousePot.set(e.clientX, e.clientY);
    //执行移动区域检测中的回调
    this.state.moveTouch.receive &&
      this.state.moveTouch.changeF?.(this.onMousePot.clone());
  }

  /** 保存 */
  private save() {
    let _precinctDataList: PrecinctData[] =
      this.state.precinctConList.map((item) => {
        //把坐标和位置转出比例向量
        return new PrecinctData(
          V2.proportion(item.pos, this.rootDivTranform.size),
          V2.proportion(item.size, this.rootDivTranform.size),
          item.url,
          item.ifNewWinOpen
        );
      });
    //判断是否有选区没有设置链接
    if (_precinctDataList.some((item) => {
      return !Boolean(item.url);
    })) {
      message.error('部分选区还未设置url链接，请检查');
      return;
    }
    this.props.conPrecinctData({
      type: 'reset',
      payload: _precinctDataList,
    });
    // //console.log("保存数据", _precinctDataList);
    this.props.close();
  }

  //
  render() {
    return (
      <ComPopUpWin
        pos="top"
        opacity={0.4}
        onScroll={(top) => {
          this.onScroll(top);
        }}
      >
        {this.state.editPercinctUrlId > -1 && (
          <EditHostPrecinct
            close={() => {
              this.setState({
                editPercinctUrlId: -1,
              });
            }}
            getRootDIvTransform={() => {
              return this.rootDivTranform;
            }}
            id={this.state.editPercinctUrlId}
            data={this.state.precinctConList[this.state.editPercinctUrlId]}
            setPrecinct={(...arg) => {
              this.setPrecinct(...arg);
            }}
          />
        )}
        <div className={styles.edit_host_pop_up_win + " " + styles.pop_up_win}>
          <div className={styles.top}>
            <span>热区图片</span>
            <button
              onClick={() => {
                this.props.close();
              }}
            >
              X
            </button>
          </div>
          <div className={styles.content}>
            <div className={styles.top_scaleplate}></div>
            <div className={styles.left_scaleplate}></div>
            <div ref={this.rootDivRef} className={styles.img}>
              <img
                className={styles.img}
                src={ApiCon.getImageUrl(this.props.hostData.imgUrl)}
                alt=""
              />
              {/* 点击区域 */}
              <div
                className={styles.touch}
                onMouseDown={(e) => {
                  this.mouseDow(e);
                }}
              ></div>
              {/* 选区列表 */}
              {this.state.precinctConList.map((item, index) => {
                return (
                  <Precinct
                    key={index + "-"}
                    setMoveTouch={(moveTouch) => {
                      this.setState({
                        moveTouch: moveTouch,
                      });
                    }}
                    getRootDivTransform={() => {
                      return this.rootDivTranform;
                    }}
                    editUrl={(id) => {
                      this.setState({
                        editPercinctUrlId: id,
                      });
                    }}
                    id={index}
                    data={item}
                    setPrecinct={(...arg) => {
                      this.setPrecinct(...arg);
                    }}
                    removePrecinct={(...arg) => {
                      this.removePrecinct(...arg);
                    }}
                  />
                );
              })}
            </div>
          </div>
          <div className={styles.buttom}>
            <button
              onClick={() => {
                this.save();
              }}
            >
              保存
            </button>
            <button
              onClick={() => {
                this.props.close();
              }}
            >
              取消
            </button>
          </div>
        </div>
      </ComPopUpWin>
    );
  }
}
